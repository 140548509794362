import _ from 'lodash';

import { isValidId, isExists } from 'Utils/reducer';
import * as api from '../../api';
import * as dataSourceActions from '../dataSource/actions';
import * as widgetsSelectors from './selectors';
import * as dynamicPageSelectors from '../dynamicPage/selectors';
import * as widgetUtils from './utils';

export const WIDGETS_ADD = 'WIDGETS_ADD';
export const WIDGETS_REMOVE = 'WIDGETS_REMOVE';
export const WIDGETS_SET_REMOVING = 'WIDGETS_SET_REMOVING';
export const WIDGETS_SET_FETCHING = 'WIDGETS_SET_FETCHING';
export const WIDGETS_ERROR = 'WIDGETS_ERROR';
export const WIDGETS_CLEAR_ERROR = 'WIDGETS_CLEAR_ERROR';
export const WIDGETS_UPDATE_SETTINGS = 'WIDGETS_UPDATE_SETTINGS';
export const WIDGETS_SET_DATA_SOURCE_ID = 'WIDGETS_SET_DATA_SOURCE_ID';
export const WIDGETS_SETTINGS_SYNC = 'WIDGETS_SETTINGS_SYNC';
export const WIDGETS_SETTINGS_DATAVIZ_SYNC = 'WIDGETS_SETTINGS_DATAVIZ_SYNC';
export const WIDGETS_SETTINGS_SYNC_REQUEST = 'WIDGETS_SETTINGS_SYNC_REQUEST';
export const WIDGETS_SETTINGS_SYNC_SUCCESS = 'WIDGETS_SETTINGS_SYNC_SUCCESS';
export const WIDGETS_SETTINGS_SYNC_FAILURE = 'WIDGETS_SETTINGS_SYNC_FAILURE';
export const UPDATE_REGISTERED_SELECTED_WIDGET = 'UPDATE_REGISTERED_SELECTED_WIDGET';

/**
 * Add widget or widgets
 * @param {Array|Object} widgets - widgets
 * @param {Object} widgets.settings - widget settings
 * @param {Object} widgets.dataSourceId - widget dataSourceId
 * @param {Object} widgets.type - widget type
 * @param {Object} widgets.pageId - widget pageId
 */
export const widgetsAdd = (widgets) => ({
    type: WIDGETS_ADD,
    payload: widgets
});

/**
 * Removing widget or widgets
 * @param {Array|Number} widgets - array or string widgets id
 * @param {Boolean} isRemoving - removing flag
 */
export const widgetsSetRemoving = (widgets, isRemoving) => ({
    type: WIDGETS_SET_REMOVING,
    payload: {
        widgets,
        isRemoving
    }
});

/**
 * Remove widget or widgets
 * @param {Array|String} widgetIds - array or string widgets id
 */
export const widgetsRemove = (widgetIds) => ({
    type: WIDGETS_REMOVE,
    payload: widgetIds
});

/**
 * Set widget fetching
 * @param {Number} widgetId - widget id
 * @param {Boolean} fetching - flag fetching
 */
export const widgetsSetFetching = (widgetId, fetching) => ({
    type: WIDGETS_SET_FETCHING,
    payload: {
        widgetId,
        fetching
    }
});

/**
 * Set widget error
 * @param {Number} widgetId - widget id
 * @param {Object} error - error
 */
export const widgetsError = (widgetId, error) => ({
    type: WIDGETS_ERROR,
    payload: {
        widgetId,
        error
    },
    error: true
});

/**
 * Clear widgets error
 * @param {String|Array} widgets - array or id widgets
 */
export const widgetsClearError = (widgets) => ({
    type: WIDGETS_CLEAR_ERROR,
    payload: widgets
});

/**
 * Set widget new dataSourceId
 * @param {Number} widgetId - widgetId
 * @param {String} dataSourceId - dataSourceId
 */
export const widgetsSetDataSourceId = (widgetId, dataSourceId) => ({
    type: WIDGETS_SET_DATA_SOURCE_ID,
    payload: {
        widgetId,
        dataSourceId
    }
});

/**
 * Set widgets settings
 * @param {Number} widgetId - widgetId
 * @param {Object} settings - settings
 */
export const widgetsSetSettings = (widgetId, settings) => ({
    type: WIDGETS_UPDATE_SETTINGS,
    payload: {
        widgetId,
        settings
    }
});

/**
 * Set selected registered widget
 * @param {Number} widgetId - widget id
 */
export const setRegisteredSelectedWidget = (widgetId) => ({
    type: UPDATE_REGISTERED_SELECTED_WIDGET,
    payload:  {
        widgetId
    }
});

/**
 * Update widget settings
 * @param {String} syncActionType - action type
 * @param {Number} widgetId - widget id
 * @param {Object} settings - settings
 * @param {Boolean} bSync - sync settings with server
 */
const commonWidgetsUpdateSettings = (syncActionType, widgetId, settings, bSync = true) => dispatch => {
    dispatch(widgetsSetSettings(widgetId, settings));

    if (bSync) {
        dispatch({
            type: syncActionType,
            payload: {
                widgetId
            }
        });
    }
};

export const widgetsUpdateSettings = (widgetId, settings, bSync = true) => (dispatch, getState) => {
    const state = getState();
    const isDataVizDynamicPage = dynamicPageSelectors.isDataVizDynamicPage(state);

    if (isDataVizDynamicPage) {
        const isDataVizStartedTemplate = dynamicPageSelectors.isDataVizStartedTemplateSelector(state);
        const sync = bSync && isDataVizStartedTemplate;

        dispatch(commonWidgetsUpdateSettings(WIDGETS_SETTINGS_DATAVIZ_SYNC, widgetId, settings, sync));
    } else {
        dispatch(commonWidgetsUpdateSettings(WIDGETS_SETTINGS_SYNC, widgetId, settings, bSync));
    }
};

const commonWidgetsLoadData = (getWidgetDataRequest, widgetId, params, options = {}) => (dispatch, getState) => {
    const state = getState();
    const widgetsState = _.get(state, 'widgets');
    const redirectOn404 = options.redirectOn404;
    const withoutFetching = options.withoutFetching;

    if (isValidId(widgetId) && isExists(widgetsState, widgetId)) {
        const { pageId, type, dataSourceId, dataParams } = widgetsState[widgetId];
        const widgetDataParams = {
            dataSourceId,
            ...params,
            ...dataParams
        };

        dispatch(widgetsClearError(widgetId));
        !withoutFetching && dispatch(widgetsSetFetching(widgetId, true));

        return getWidgetDataRequest(pageId, [{ type, params: widgetDataParams }], {
            redirectOn404
        }).then(
            res => {
                const payload = _.get(res.data, 'payload.data', []);
                const dataSource = payload[0];

                if (dataSource) {
                    // if the received data is null, replace it with undefined
                    const data = dataSource.data || undefined;

                    dispatch(dataSourceActions.dataSourceAddOrSave({
                        id: dataSource.dataSourceId,
                        data,
                        info: dataSource.info
                    }));
                    if (dataSource.dataSourceId !== dataSourceId) {
                        dispatch(widgetsSetDataSourceId(widgetId, dataSource.dataSourceId));
                    }
                } else {
                    dispatch(dataSourceActions.dataSourceAddOrSave({ id: dataSourceId, data: undefined }));
                }
                !withoutFetching && dispatch(widgetsSetFetching(widgetId, false));
            },
            error => {
                !withoutFetching && dispatch(widgetsSetFetching(widgetId, false));

                if (!api.isCancel(error)) {
                    dispatch(widgetsError(widgetId, _.get(error, 'response.status')));
                }

                return Promise.reject(error);
            }
        );
    }

    return Promise.resolve();
};

/**
 * Load and reload widget data
 * @param {Number} widgetId - widget id
 * @param {Object} params - params for BE api
 * @param {Object} [options] - options
 * @param {Boolean} [options.redirectOn404] - if api will return 404 we should redirect to 404 error page
 * @return {Promise}
 */
export const widgetsLoadData = (widgetId, params, options = {}) => (dispatch, getState) => {
    const isDataVizDynamicPage = dynamicPageSelectors.isDataVizDynamicPage(getState());
    const getWidgetDataRequest = isDataVizDynamicPage ? api.getDataVizWidgetData : api.getWidgetData;

    return dispatch(commonWidgetsLoadData(getWidgetDataRequest, widgetId, params, options));
};

export const registeredOverviewChangeSelectedWidget = (widgetId) => (dispatch, getState) => {
    const availableSlots = dynamicPageSelectors.getSlotsForRegisteredActionPanelSelector(getState());
    const selectedSlot = _.find(availableSlots, ['isSelected', true]);

    if (selectedSlot && selectedSlot.widgetId === widgetId) {
        return Promise.resolve();
    }

    dispatch(setRegisteredSelectedWidget(widgetId));

    if (!selectedSlot) {
        dispatch({
            type: WIDGETS_SETTINGS_SYNC,
            payload: {
                widgetId
            }
        });
        return Promise.resolve();
    }

    const state = getState();
    const pageType = dynamicPageSelectors.getDynamicPageTypeSelector(state);
    const syncWidgets = widgetUtils.normalizeSyncWidgets(
        state,
        [
            widgetId,
            _.get(selectedSlot, 'widgetId')
        ]
    );

    dispatch({
        type: WIDGETS_SETTINGS_SYNC_REQUEST
    });

    return api.updateWidgets(pageType, syncWidgets)
        .then(
            () => {
                dispatch({
                    type: WIDGETS_SETTINGS_SYNC_SUCCESS
                });
            },
            error => {
                dispatch({
                    type: WIDGETS_SETTINGS_SYNC_FAILURE,
                    payload: error,
                    error: true
                });
                return Promise.reject(error);
            });
};

export const widgetAddToDataVizReport = (widgetId, params) => (dispatch, getState) => {
    const state = getState();
    const widget = widgetsSelectors.getWidgetSelector(state, widgetId);

    if (!widget) return Promise.reject(`Widget with id = "${widgetId}" was not found`);

    const widgets = [{
        widgetId: widget.widgetId,
        type: widget.type,
        settings: widget.settings,
        params
    }];

    return api.addWidgetsToDataVizReport(widgets);
};

