import React from 'react';
import PropTypes from 'prop-types';

import ColoredSquare from 'Components/Tables/components/ColoredSquare';

import './ColoredCheckBoxLabel.scss';

const ColoredCheckBoxLabel = ({ label, color }) => {
    return (
        <span className='colored-checkbox-label'>
            <span className='text'>{label}</span>
            <ColoredSquare value={color}/>
        </span>
    );
};

ColoredCheckBoxLabel.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string
};

export default ColoredCheckBoxLabel;
