import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { contactPropTypes } from '../../propTypes';

const ContactInfo = (props) => {
    const {
        className,
        name,
        title,
        position,
        address: { residence, city, country, state, zip } = {},
        email,
        phone
    } = props;
    const nameAndTitle = [name, title].filter(Boolean).join(', ');
    const stateZip = [state, zip].filter(Boolean).join(' ');
    const cityStateZip = [city, stateZip].filter(Boolean).join(', ');

    return (
        <div className={className}>
            <h3 className='title'>{nameAndTitle}</h3>
            <div>{position}</div>
            <div>{residence}</div>
            <div>{cityStateZip}</div>
            <div>{country}</div>
            {email && <div><FormattedMessage id='common.email'/>: <a href={`mailto:${email}`}>{email}</a></div>}
            <div>{phone}</div>
        </div>
    );
};

ContactInfo.propTypes = {
    ...contactPropTypes,
    className: PropTypes.string
};

export default ContactInfo;
