import SHARE_STATUSES from 'Constants/share-statuses';

export default [
    {
        value: SHARE_STATUSES.ALL,
        label: 'reporting.share.detail.status.allShares'
    },
    {
        value: SHARE_STATUSES.ISSUES,
        label: 'reporting.share.detail.status.issues'
    },
    {
        value: SHARE_STATUSES.SURRENDERED,
        label: 'reporting.share.detail.status.surrendered'
    },
    {
        value: SHARE_STATUSES.OPEN,
        label: 'reporting.share.detail.status.open'
    }
];

