export const columns = {
    TAX_STATUS: 'taxStatus',
    NUMBER_OF_SHAREHOLDERS: 'numberOfShareholders',
    PERCENT_OF_SHAREHOLDERS: 'percentOfShareholders',
    EXPIRING_YEAR_END: 'expiringYearEnd',
    TAX_STATUS_DETAIL: 'taxStatusDetail',
    TAX_STATUS_SUMMARY: 'taxStatusSummary'
};

export const levelTypes = {
    SUMMARY: 'summary',
    DETAILS: 'details'
};
