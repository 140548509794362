import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import _ from 'lodash';
import queryString from 'query-string';

import { getQueryParameterByName } from 'Utils/routing';

@withRouter
class LinkEx extends Component {
    static propTypes = {
        ...NavLink.propTypes,
        attr: PropTypes.object,
        isExternal: PropTypes.bool,
        exact: PropTypes.bool
    };

    static defaultProps = {
        onlyActiveOnIndex: false,
        isExternal: false
    };

    render() {
        const { to, href, activeStyle, isExternal,
            activeClassName, onClick, location, getElement,
            target, className, title, attr, exact } = this.props;
        let linkProps = {
            to,
            href,
            activeStyle,
            activeClassName,
            onClick,
            target,
            className,
            title,
            exact,
            ...attr
        };

        if (!isExternal) {
            const security = getQueryParameterByName('security');

            linkProps.isActive = () => {
                const linkPath = _.get(to, 'pathname') || to;

                return linkProps.exact
                    ? location.pathname === linkPath
                    : location.pathname.includes(linkPath);
            };

            if (security) {
                const toObj = {};
                let searchObj = {};

                if (_.isString(linkProps.to)) {
                    toObj.pathname = linkProps.to;
                } else if (_.isObject(linkProps.to)) {
                    toObj.pathname = linkProps.to.pathname;
                    toObj.state = linkProps.to.state;
                    searchObj = queryString.parse(linkProps.to.search);
                }

                searchObj.security = searchObj.security || security;
                toObj.search = queryString.stringify(searchObj);
                linkProps.to = toObj;
            }
        } else {
            linkProps = {
                href: linkProps.to
            };
        }

        const LinkComponent = isExternal ? 'a' : NavLink;

        return (
            <LinkComponent {...linkProps}>
                {getElement
                    ? <span ref={getElement}>
                        {this.props.children}
                    </span>
                    : this.props.children
                }
            </LinkComponent>
        );
    }
}

export default LinkEx;
