import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { getUserNotifications, logoutUser } from 'State/user/actions';
import * as userSelectors from 'State/user/selectors';
import * as featuresSelectors from 'State/features/selectors';
import ProfileMenuView from '../components/ProfileMenuView';
import polling from '../../polling';
import pollingFunctionTypes from 'Constants/polling-function-types';

let boundActionCreators;
const DEFAULT_POLLING_INTERVAL = 10000;
const mapStateToProps = (state) => ({
    userData: userSelectors.getUserDataSelector(state),
    userDownloadsNotification: userSelectors.getDownloadsNotificationSelector(state),
    userDataVizReportsNotification: userSelectors.getDataVizReportsNotificationSelector(state),
    currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
    accessToPa: userSelectors.hasAccessToPaSelector(state),
    currentSecurityId: userSelectors.getCurrentSecurityIdSelector(state)
});
const mapDispatchToProps = (dispatch) => {
    return boundActionCreators = bindActionCreators({ logoutUser, getUserNotifications }, dispatch);
};

@polling(() => boundActionCreators.getUserNotifications(), pollingFunctionTypes.USER_NOTIFICATIONS, DEFAULT_POLLING_INTERVAL)
@connect(mapStateToProps, mapDispatchToProps)
class ProfileMenu extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        setOpen: PropTypes.func.isRequired,
        logoutUser: PropTypes.func.isRequired,
        userDownloadsNotification: PropTypes.number,
        userDataVizReportsNotification: PropTypes.number,
        currentFeatures: PropTypes.object,
        userData: PropTypes.object,
        history: PropTypes.object
    };

    componentDidMount() {
        this.unsubscribe = this.props.history.listen(this.handleNavigate);
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    handleNavigate = () => {
        if (this.props.isOpen) {
            // To hide menu when browser history buttons used.
            if (typeof Event === 'function') {
                document.dispatchEvent(new MouseEvent('click'));
            } else {
                const event = document.createEvent('MouseEvent');

                event.initEvent('click', false, true);
                document.dispatchEvent(event);
            }
        }
    };

    handleToggle = () => {
        this.props.setOpen(!this.props.isOpen);
    };

    handleLogout = () => {
        this.props.logoutUser();
    };

    render() {
        return (
            <ProfileMenuView
                {...this.props}
                platformAdministrationUrl={PA_BASE_URL}
                onToggle={this.handleToggle}
                onLogoutUser={this.handleLogout}/>
        );
    }
}

export default withRouter(ProfileMenu);
