export default {
    'shareholderName': 335,
    'position': 140,
    'currentBalance': 105,
    'previousPosition': 140,
    'positionChange': 140,
    'os': 80,
    'mktVal': 120,
    'portfolioPercent': 100,
    '13fChange': 140,
    'ytdChange': 140,
    'costBasis': 135,
    'purchasingPower': 140,
    'assetsUnderManagement': 140,
    'turnover': 100,
    'certifiedShares': 140,
    'totalValue': 140,
    'bookShares': 140,
    'dripShares': 140,
    'fundName': 335,
    'email': 250,
    'accountNumber': 120,
    'default': 120,
    'positionDate': 100,
    'openedDate': 100,
    'previous13f': 140,
    'city': 100,
    'metroArea': 120,
    'state': 110,
    'country': 100,
    'style': 100,
    'status': 100,
    'activeVsPassive': 100,
    'proxyAdvisors': 100,
    'previousPositionDate': 100,
    'directDeposit': 120,
    'address': 120,
    'specialCode': 100,
    'expander': 45,
    '13f': 140,
    'watchlistExpander': 45
};
