import React from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

import dateFormats from 'Constants/date-formats';
import { CellCommon } from 'Components/widgets/_commonComponents/TableCellComponents';

const LastLoginDateCell = ({ value }) => {
    const dateValue = moment(value, dateFormats.SERVER_DATE_WITH_TIME_FORMAT);
    const resultValue = dateValue.isValid() ? dateValue.format(dateFormats.withTime) : value;

    const additionalData = DEMO_MODE ? { alignRight: true } : { alignCenter: true };

    return (
        <CellCommon
            additionalData={additionalData}
            value={resultValue}/>
    );
};

LastLoginDateCell.propTypes = {
    value: PropTypes.string
};

export default LastLoginDateCell;
