import { createSelector } from 'reselect';
import _ from 'lodash';

import { MAX_VISIBLE_COMPANIES, EXPORT_MAX_VISIBLE_COMPANIES } from './peerAnalysisConfig';
import formatterConstants from 'Constants/formatter-options';
import { getPeerDescription } from 'Utils/peers';

const minMarketValue = 0;
const countOfPinnedCompanies = 1;
const getWidgetSettings = props => props.widgetSettings;
const getWidgetData = props => props.widgetData;
const getOffset = (props, offset = 0) => offset;

const getPeers = createSelector(
    getWidgetData,
    widgetData => _.get(widgetData, 'peerCompanies', [])
);

const getSelectedShareholderIDs = createSelector(
    getWidgetSettings,
    widgetSettings => _.get(widgetSettings, 'selectedShareholders', []).map(sh => sh.shareholderId)
);

export const getSelectedPeers = createSelector(
    [getWidgetSettings, getPeers],
    (widgetSettings, peers) => {
        const selectedPeerIDs = _.get(widgetSettings, 'selectedPeers', []);

        return peers.filter(peer => selectedPeerIDs.includes(peer.id));
    }
);

export const getPeersForPopover = createSelector(
    [getPeers, getSelectedPeers],
    (availablePeers, selectedPeers) => _.orderBy(
        _.map(availablePeers, peer => ({
            ...peer,
            isSelected: selectedPeers.some(sPeer => sPeer.id === peer.id)
        })),
        ['name', 'ticker'],
        ['asc']
    )
);

export const getShareholderData = createSelector(
    getWidgetData,
    widgetData => {
        const data = _.get(widgetData, 'data', []);

        return _.groupBy(data, item => item.shareholderId);
    }
);

export const getCompanyData = createSelector(
    getWidgetData,
    widgetData => {
        const data = _.get(widgetData, 'data', []);

        return _.groupBy(data, item => item.companyId);
    }
);

export const getShareholders = createSelector(
    getWidgetData,
    widgetData => _.get(widgetData, 'shareholders', [])
);

export const getSelectedShareholders = createSelector(
    [getWidgetSettings, getShareholders, getSelectedShareholderIDs],
    (widgetSettings, shareholders, selectedShareholderIDs) => {
        const selectedShareholders = [];

        _.forEach(selectedShareholderIDs, shId => {
            const shareholder = _.find(shareholders, sh => sh.shareholderId === shId);

            if (shareholder) {
                selectedShareholders.push(shareholder);
            }
        });

        return selectedShareholders;
    }
);

export const getMaxOffset = createSelector(
    getSelectedPeers,
    ({ isPrintMode }) => isPrintMode,
    (selectedPeers, isPrintMode) => {
        const maxVisibleCompanies = isPrintMode ? EXPORT_MAX_VISIBLE_COMPANIES : MAX_VISIBLE_COMPANIES;
        // security company is alone
        const maxOffset = selectedPeers.length - (maxVisibleCompanies - countOfPinnedCompanies);

        return maxOffset >= 0 ? maxOffset : 0;
    }
);

const getSecurityCompany = createSelector(
    getWidgetData,
    widgetData => _.get(widgetData, 'securityCompany')
);

const getVisibleCompanies = createSelector(
    [getSelectedPeers, getSecurityCompany, getOffset, ({ isPrintMode }) => isPrintMode],
    (selectedPeers, securityCompany, offset, isPrintMode) => {
        const maxVisibleCompanies = isPrintMode ? EXPORT_MAX_VISIBLE_COMPANIES : MAX_VISIBLE_COMPANIES;

        return securityCompany ?
            [securityCompany, ...selectedPeers.slice(offset, maxVisibleCompanies + offset - countOfPinnedCompanies)]
            : selectedPeers.slice(offset);
    }
);

/**
 * need to pass offset
 */
export const getGroupsSettings = createSelector(
    [getSecurityCompany, getVisibleCompanies],
    (securityCompany, visibleCompanies) => {
        return _.map(visibleCompanies, company => ({
            id: company.id,
            label: getPeerDescription(company),
            canDeleteGroup: securityCompany.id !== company.id
        }));
    }
);

export const getTableData = createSelector(
    [getShareholders, getSelectedShareholders, getShareholderData, props => props.maxSelectedShareholders],
    (shareholders, selectedShareholders, shareholdersData, maxSelectedShareholders) => {
        const tableData = _.map(shareholders, shareholder => {
            const isSelected = selectedShareholders.some(sh => sh.shareholderId === shareholder.shareholderId);

            return ({
                ...shareholder,
                groups: _.keyBy(shareholdersData[shareholder.shareholderId], 'companyId'),
                isSelected,
                isDisabled: selectedShareholders.length >= maxSelectedShareholders && !isSelected
            });
        });

        return tableData;
    }
);

export const getDataVizTableData = createSelector(
    getTableData,
    tableData => _.filter(tableData, item => item.isSelected)
);

const mapMktVal = mktVal => mktVal / formatterConstants.MM_SCALE;

export const getChartSeries = createSelector(
    [getSelectedShareholders, getCompanyData, getVisibleCompanies],
    (selectedShareholders, companyData, visibleCompanies) => {
        return _.map(selectedShareholders, selectedShareholder => {
            return {
                shareholderId: selectedShareholder.shareholderId,
                name: selectedShareholder.shareholderName,
                // optimizable
                data: visibleCompanies
                    .map(company => {
                        // company can have no data
                        const foundCompanyData = companyData[company.id];

                        const companyAndShareholderData = foundCompanyData && foundCompanyData
                            .find(cd => cd.shareholderId === selectedShareholder.shareholderId);
                        // highchart needs null
                        const val = _.get(companyAndShareholderData, 'mktVal', null);

                        return _.isNumber(val) ? mapMktVal(val) : val;
                    })
            };
        });
    }
);

/**
 * need to pass offset
 */
export const getChartGroups = createSelector(
    getVisibleCompanies,
    ({ intl: { formatMessage } }) => formatMessage,
    (visibleCompanies, formatMessage) => _.map(visibleCompanies, company => company.name
        ? company.name
        : formatMessage({ id: 'user.profile.peers.unavailablePeer' })
    )
);

const getCompanies = createSelector(
    [getSelectedPeers, getSecurityCompany],
    (selectedPeers, securityCompany) => {
        return securityCompany ?
            [securityCompany, ...selectedPeers]
            : selectedPeers;
    }
);

export const getMktLimits = createSelector(
    [getWidgetData, getWidgetSettings, getCompanies, getSelectedShareholderIDs],
    (widgetData, widgetSettings, companies, selectedShareholderIDs) => {
        const companyIDs = _.map(companies, c => c.id);
        const data = _.get(widgetData, 'data');
        const companiesData = _.filter(data, d => companyIDs.includes(d.companyId) && selectedShareholderIDs.includes(d.shareholderId));
        const values = _.map(companiesData, d => mapMktVal(d.mktVal));

        return {
            min: minMarketValue,
            max: _.max(values)
        };
    }
);

export const getFindModalAvailability = createSelector(
    getSelectedShareholders,
    props => props.maxSelectedShareholders,
    (shareholders, maxSelectedShareholders) => shareholders.length < maxSelectedShareholders
);

export const getSavedColors = createSelector(
    [getWidgetSettings],
    (settings) => {
        const selectedShareholders = _.get(settings, 'selectedShareholders', []);

        // object where key is shareholderId and value is color
        const selectedShareholderColors = {};

        selectedShareholders.forEach(sh => {
            selectedShareholderColors[sh.shareholderId] = sh.color;
        });

        return selectedShareholderColors;
    }
);
