import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import ReactTable from 'react-table';
import { AutoSizer } from 'react-virtualized';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import {
    commonWidgetPropTypes,
    commonWidgetActions
} from 'Components/widgets/utils';
import { getRegisteredOverviewCustomComponent } from '../../utils';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import sortDirections from 'Constants/sort-directions';
import { sortArray, compareDatesDescending, getColumnsMinWidth } from 'Utils/utils';
import columnNames from 'Constants/registered-table-column-names';
import { DateFormatter } from 'Components/formatters';
import widgetTypes from 'Constants/widget-types';
import { withPrintModeState } from 'Hoc';
import { getSortDirection } from 'Components/Tables/utils';

import './RegisteredShareholderTableContent.scss';

@withPrintModeState
@mapProps(props => ({
    ...props,
    tableColumns: (() => {
        const selectedColumns = _.get(props, 'widgetSettings.selectedColumns', []);

        if (!_.includes(selectedColumns, columnNames.NO_DIVIDEND) && _.get(props, 'widgetSettings.widgetType') === widgetTypes.compareShareholdersDRDSPP) {
            selectedColumns.push(columnNames.NO_DIVIDEND);
        }

        return _.filter(props.columnsDefinitions, ({ columnName, hidden }) => {
            return _.includes(selectedColumns, columnName) && !hidden;
        });
    })()
}))
class RegisteredShareholderTableContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        columnsDefinitions: PropTypes.array.isRequired,
        secondarySorting: PropTypes.shape({
            field: PropTypes.string,
            direction: PropTypes.string
        }),
        data: PropTypes.array,
        getTheadThProps: PropTypes.func,
        getTdProps: PropTypes.func,
        getColumnsDefinition: PropTypes.func,
        getDefaultTdProps: PropTypes.func,
        isExceeded: PropTypes.bool,
        isPrint: PropTypes.bool,
        noDataMessageId: PropTypes.string,
        onChangeSortCriteria: PropTypes.func,
        isServerSorting: PropTypes.bool,
        footerProps: PropTypes.object,
        dropzoneProps: PropTypes.object,
        isDragActive: PropTypes.bool
    };

    static defaultProps = {
        isServerSorting: false
    };

    constructor(props) {
        super(props);

        this.setColumnsMinWidth(props.data, props.columnsDefinitions);
    }

    componentDidMount() {
        const { isExceeded } = this.props;

        isExceeded && this.props.onExceed();
    }

    handleOnSort = (sortProps) => {
        const sortBy = _.get(sortProps, '[0].id');
        const { widgetSettings: { sortCriteria: { field: prevSortBy, direction: prevDirection } }, isServerSorting, onChangeSortCriteria } = this.props;

        if (isServerSorting) {
            onChangeSortCriteria(sortBy, this.props.columnsDefinitions);
        } else {
            const sortCriteria = {
                field: sortBy,
                direction: getSortDirection(
                    sortBy,
                    prevSortBy,
                    prevDirection,
                    this.props.columnsDefinitions
                ),
                initial: false
            };

            this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
        }
    };

    setColumnsMinWidth = (data, columns) => {
        this.columnsWidth = {};

        _.forEach(columns, column => {
            this.columnsWidth[column.columnName] = data && data.length
                ? getColumnsMinWidth(data, column, column.minWidth || column.width, this.props.intl)
                : column.minWidth;
        });
    };

    getColumnsDefinition = (columns, mainColumnsWidth) => {
        const { intl: { formatMessage }, footerProps, widgetNoData } = this.props;
        const isFooterVisible = !widgetNoData && _.get(this.props, 'footerProps.visible');

        return columns
            .map((column) => {
                const Components = getRegisteredOverviewCustomComponent(column.columnName);
                const isNoDividendColumn = column.columnName === columnNames.NO_DIVIDEND;
                let style = isNoDividendColumn ? { left: mainColumnsWidth } : {};

                style = { ...style, ...column.style };

                return {
                    Header: () => (
                        <Components.customHeadingComponent
                            title={column.displayName ? formatMessage({ id: column.displayName }) : ''}
                            style={style}/>
                    ),
                    Cell: (props) => (
                        <Components.customComponent
                            value={props.value}
                            style={style}/>
                    ),
                    Footer: Components.customFooterComponent && isFooterVisible ? () => (
                        <Components.customFooterComponent
                            footerProps={footerProps}/>
                    ) : null,
                    accessor: column.columnName,
                    minWidth: this.props.isPrint ? column.minWidth : this.columnsWidth[column.columnName],
                    width: column.width,
                    sortable: !column.disableSort
                };
            });
    };

    getTheadThProps = (sortField, sortDirection, finalState, missProp, column) => {
        const defaultProps = {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };
        let additionalProps = {};

        if (this.props.getTheadThProps) {
            additionalProps = this.props.getTheadThProps(sortField, sortDirection, finalState, missProp, column) || {};
        }

        return {
            ...additionalProps,
            ...defaultProps
        };
    };

    getTdProps = (finalState, rowInfo, column) => {
        const defaultProps = this.props.getDefaultTdProps(column.id, rowInfo.row);
        const customProps = this.props.getTdProps && this.props.getTdProps(finalState, rowInfo, column) || {};

        return {
            ...defaultProps,
            ...customProps
        };
    };

    getNoDataProps = () => ({
        messageId: this.props.noDataMessageId || 'widgets.noDataMessage'
    });

    render() {
        const {
            data,
            secondarySorting = {},
            tableColumns,
            widgetSettings: { sortCriteria = {} } = {},
            widgetData,
            mainColumnsWidth,
            isServerSorting,
            isDragActive,
            dropzoneProps = {}
        } = this.props;
        const field = sortCriteria.sortField || sortCriteria.field;
        const direction = sortCriteria.sortDirection || sortCriteria.direction;
        const columns = this.getColumnsDefinition(tableColumns, mainColumnsWidth);
        const closeOfBusinessDate = _.get(widgetData, 'closeOfBusinessDate');
        const sortedValues = isServerSorting ? data : sortArray({
            array: data,
            primaryField: field,
            descending: direction === sortDirections.DESC,
            secondaryField: secondarySorting.field,
            secondaryDescending: secondarySorting.direction === sortDirections.DESC,
            primaryComparator: field === columnNames.DATE_OF_SALE ? compareDatesDescending : undefined,
            secondaryComparator: secondarySorting.field && secondarySorting.field === columnNames.DATE_OF_SALE ? compareDatesDescending : undefined,
            initial: sortCriteria.initial
        });

        return (
            <div className={cn('widget-content', { 'drag-active': isDragActive })} {...dropzoneProps}>
                {closeOfBusinessDate &&
                <div className='business-date'>
                    <FormattedMessage id='registered.shareholder.table.businessDate'/> <DateFormatter
                        value={closeOfBusinessDate}/>
                </div>}
                <AutoSizer disableHeight>
                    {
                        ({ width }) => (
                            <div style={{ width }}>
                                <ReactTable
                                    showPagination={false}
                                    resizable={false}
                                    LoadingComponent={() => null}
                                    minRows={0}
                                    ThComponent={TheadComponent}
                                    TdComponent={TdComponent}
                                    getNoDataProps={this.getNoDataProps}
                                    NoDataComponent={NoResults}
                                    getTheadThProps={this.getTheadThProps.bind(this, field, direction)}
                                    getTdProps={this.getTdProps.bind(this)}
                                    manual
                                    defaultPageSize={0}
                                    resolvedData={sortedValues}
                                    onSortedChange={this.handleOnSort}
                                    columns={columns || []}/>
                            </div>
                        )
                    }
                </AutoSizer>
                {isDragActive &&
                    <div className='drag-and-drop-overlay'/>
                }
            </div>
        );
    }
}

export default RegisteredShareholderTableContent;
