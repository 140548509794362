import React, { Component } from 'react';

import Layout from '../Layout';

export default class HorizontalLayout extends Component {
    static propTypes = {
        ...Layout.propTypes
    };

    render() {
        return (<Layout {...this.props}>
            {this.props.children}
        </Layout>);
    }
}
