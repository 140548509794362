import { createSelector } from 'reselect';
import _ from 'lodash';

const getDataSource = state => _.get(state, 'dataSource');
const getDataById = (state, id) => _.get(getDataSource(state), id);

export const getDataByIdSelector = createSelector(
    getDataById,
    dataSource => _.get(dataSource, 'data')
);

export const getDataInfoByIdSelector = createSelector(
    getDataById,
    dataSource => _.get(dataSource, 'info')
);
