import React from 'react';
import { FormattedMessage } from 'react-intl';

import './ViewNotesLink.scss';

const ViewNotesLink = () => {
    const handleClick = () => {
        const notesElement = document.getElementById('notes');

        notesElement && notesElement.scrollIntoView(true);
    };

    return (<FormattedMessage id='notes.link'>
        {message => <div className='view-notes-link' onClick={handleClick}>{message}</div>}
    </FormattedMessage>);
};

export default ViewNotesLink;
