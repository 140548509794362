import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export const getSuggestions = (params, { cancelToken } = {}) => {
    const requestData = utils.buildRequest({ search: params }, requestTypes.getSuggestions);

    return axios.post(apiRoutes.getSuggestions, requestData, {
        isCancelable: true,
        originalCancelToken: cancelToken
    });
};
