import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import Selector from 'Components/Selector';
import { RadioButton } from 'Components/RadioButton';

class SortByField extends Component {
    static propTypes = {
        input: PropTypes.object,
        sortFields: PropTypes.array
    };

    handleSortFieldChanged = (selectedOption) => {
        const { input, sortFields } = this.props;
        const selectedField = sortFields.find(field => field.id === selectedOption.value);

        input.onChange({
            sortDirection: selectedField.defaultSortDirection,
            sortField: selectedField.id,
            // null should be at the end for any sorting selected
            initial: true
        });
    };

    handleSortDirectionChanged = (event) => {
        const { input } = this.props;

        input.onChange({
            ...input.value,
            sortDirection: event.target.value
        });
    };

    render() {
        const { input, sortFields } = this.props;
        const options = sortFields.map(field => ({
            value: field.id,
            label: field.label
        }));
        const selectedValue = sortFields.find(field => field.id === input.value.sortField) || {};
        const sortDirections = _.get(selectedValue, 'sortDirections', []);

        return (
            <div className='reporting-sort-field'>
                <Selector
                    wrapperClass='sort-by-selector'
                    name='sortField'
                    options={options}
                    value={selectedValue.id}
                    clearable={false}
                    searchable={false}
                    openTop
                    onOptionChange={this.handleSortFieldChanged}/>
                {sortDirections.map(direction =>
                    (<RadioButton
                        key={direction.id}
                        name='sortDirections'
                        checked={input.value.sortDirection === direction.id}
                        value={direction.id}
                        label={direction.label}
                        onChange={this.handleSortDirectionChanged}/>)
                )}
            </div>
        );
    }
}

export default SortByField;
