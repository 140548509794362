import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import widgetModes from 'Constants/widget-modes';
import ShareholderElectionsChartView from './ShareholderElectionsChartView';
import ShareholderElectionsTableView from './ShareholderElectionsTableView';
import NoDataComponent from '../../Widget/components/NoDataComponent';

class ShareholderElectionsContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    render() {
        const { widgetSettings: { mode }, isFetching, widgetNoData } = this.props;
        const props = { ...this.props, markTotalOverflow: false };

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    {mode === widgetModes.CHART ?
                        <ShareholderElectionsChartView
                            {...props}/> :
                        <ShareholderElectionsTableView
                            {...props}/>
                    }
                </NoDataComponent>
            </div>
        );
    }
}

export default ShareholderElectionsContent;
