import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';

import reportExportFormats from 'Constants/export-report-formats';
import fileFormats from 'Constants/file-formats';
import reportTypes from 'Constants/report-types';
import shareholderTypes from 'Constants/shareholder-types';
import * as exportActions from 'State/exportReports/actions';
import { getWatchlistByIdSelector } from 'State/watchlist';
import LimitNote from 'Components/LimitNote';
import { RadioButtonsListField } from 'Components/RadioButtonsList';
import { columnDisplayNames } from 'Components/widgets/WatchlistWidget/columnDefinitions';

const shareholderBitwiseTypes = {
    [shareholderTypes.INSTITUTION]: 1,
    [shareholderTypes.INSIDER]: 2,
    [shareholderTypes.REGISTERED]: 4,
    [shareholderTypes.FUND]: 8
};

// 0 | 1 = 1
// 1 | 2 = 3
const getShareholderTypeSum = (shareholderTypeNames = []) => {
    // eslint-disable-next-line no-bitwise
    return shareholderTypeNames.reduce((acc, shareholderType) => (acc | shareholderBitwiseTypes[shareholderType]), 0);
};

const exportFormats = [
    {
        label: `${fileFormats[reportExportFormats.EXCEL]}*`,
        value: reportExportFormats.EXCEL,
        position: 'block'
    },
    {
        label: fileFormats[reportExportFormats.PDF],
        value: reportExportFormats.PDF,
        position: 'block'
    }
];

const WatchlistExportModal = ({ handleSubmit, submitting, valid, hideModal, exportParams: { sortCriteria } }) => {
    const sortDirectionId = `shareholder.list.sortBy.${sortCriteria.sortDirection}`;
    const sortFieldName = columnDisplayNames[sortCriteria.sortField];

    return (
        <form
            onSubmit={handleSubmit}
            className='export-report-modal export-shareholder-table-modal'>
            <div className='format-container'>
                <div className='form-section-title'>
                    <FormattedMessage id='modals.exportReport.label.sorted' />
                </div>
                <div>
                    <FormattedMessage id={sortFieldName} />, <FormattedMessage id={sortDirectionId} />
                </div>
                <div className='form-section-title'>
                    <FormattedMessage id='modals.exportReport.label.selectFormat' />
                </div>
                <RadioButtonsListField
                    onChange={() => {}}
                    name='selectedFormat'
                    list={exportFormats}/>
            </div>
            <div className='modal-actions'>
                <button
                    type='submit'
                    className='btn btn-large'
                    disabled={submitting || !valid}>
                    <FormattedMessage id='modals.exportReport.exportBtn' />
                </button>
                <button
                    type='button'
                    onClick={hideModal}
                    className='btn btn-large btn-link'>
                    <FormattedMessage id='common.cancel' />
                </button>
            </div>
            <LimitNote/>
        </form>
    );
};

WatchlistExportModal.propTypes = {
    onSuccess: PropTypes.func,
    okText: PropTypes.string,
    onCancel: PropTypes.func,
    cancelText: PropTypes.string,
    handleSubmit: PropTypes.func,
    hideModal: PropTypes.func,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    exportParams: PropTypes.shape({
        sortCriteria: PropTypes.shape({
            sortDirection: PropTypes.string,
            sortField: PropTypes.string
        })
    })
};

const mapStateToProps = (state, props) => ({
    watchlist: getWatchlistByIdSelector(props.exportParams.watchlistId)(state)
});

const mapDispatchToProps = {
    runReport: exportActions.runReport
};

const INITIAL_VALUES = {
    selectedFormat: reportExportFormats.EXCEL
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'watchlist-export',
        initialValues: INITIAL_VALUES,
        onSubmit: ({ selectedFormat }, _dispatch, { runReport, exportParams, watchlist }) => {
            const {
                sortCriteria,
                shareholderIds,
                availableDisplayTypes,
                columnsToDisplay,
                watchlistId
            } = exportParams;
            const shareholderType = getShareholderTypeSum(availableDisplayTypes);

            runReport(
                reportTypes.watchlistTemplate,
                null,
                selectedFormat,
                {
                    columnsToDisplay,
                    shareholderIds,
                    sortCriteria,
                    watchlistTitle: watchlist.title,
                    watchlistId,
                    shareholderType
                }
            );
        },
        onSubmitSuccess: (_values, _dispatch, { hideModal }) => {
            hideModal();
        }
    })
)(WatchlistExportModal);
