import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { getContext } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import { registeredOverviewChangeSelectedWidget } from 'State/widgets/actions';
import RegisteredOverviewTabsActionPanelView from './components/RegisteredOverviewTabsActionPanelView';
import widgetGroups from 'Constants/registered-shareholder-widget-groups';
import widgetTypes from 'Constants/widget-types';
import UploadIcon from './components/UploadIcon';

import './RegisteredOverviewTabsActionPanel.scss';

const mapDispatchToProps = {
    changeWidget: registeredOverviewChangeSelectedWidget
};

@connect(null, mapDispatchToProps)
@getContext({
    events: PropTypes.object.isRequired
})
class RegisteredOverviewTabsActionPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        changeWidget: PropTypes.func,
        intl: intlShape,
        events: PropTypes.object
    };

    state = {
        slotsList: [],
        selectedSlot: null
    };

    componentDidMount = () => {
        const { availableSlots = {}, intl } = this.props;
        const filteredSlotsArray = _.keys(availableSlots).reduce((slotsArray, slotKey) => {
            if (_.get(availableSlots[slotKey], 'widgetType')) {
                slotsArray.push(availableSlots[slotKey]);
            }

            return slotsArray;
        }, []);
        const slotsList = filteredSlotsArray.map((slot) => {
            const widgetType = slot.widgetType;
            const widgetId = slot.widgetId;
            const widgetName = intl.formatMessage({ id: `widgets.type.${widgetType}` });
            const isSelected = slot.isSelected;
            const groupName = widgetGroups[widgetType] && intl.formatMessage({ id: `widgets.type.${ widgetGroups[widgetType]}` });
            const widgetTooltip = intl.messages[`widgets.tooltip.${widgetType}`]
                ? intl.formatMessage({ id: `widgets.tooltip.${widgetType}` })
                : null;
            const TabComponent = widgetType !== widgetTypes.registeredShareholderClientUploads
                ? null
                : props => <UploadIcon {...props} documentsWidgetId={widgetId}/>;

            return {
                widgetName,
                widgetType,
                isSelected,
                groupName,
                widgetId,
                widgetTooltip,
                TabComponent
            };
        });
        const selectedSlot = _.find(slotsList, (slot) => slot.isSelected);

        if (!selectedSlot) {
            this.props.changeWidget(slotsList[0].widgetId);
        }
        this.setState({
            slotsList,
            selectedSlot: selectedSlot || slotsList[0]
        });
    };

    handleSlotSelect = (widgetId) => {
        this.props.changeWidget(widgetId);
    };

    render() {
        const { slotsList, selectedSlot } = this.state;

        return (
            <RegisteredOverviewTabsActionPanelView
                selectedSlot={selectedSlot}
                slotsList={slotsList}
                onSelect={this.handleSlotSelect}/>
        );
    }
}

export default injectIntl(RegisteredOverviewTabsActionPanel);
