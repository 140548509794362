import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import dateFormats from 'Constants/date-formats';
import store from '../../../context/store';
import slotSizes from 'Constants/slot-sizes';
import { getWidgetTypeSelector } from 'State/widgets/selectors';
import formatterConstants from 'Constants/formatter-options';
import { checkBackEndNull, round } from 'Utils/utils';
import { scaleFormatter } from '../../formatters/formatters';
import signTypes from 'Constants/sign-types';
import { markIncludesCeDeCoRows, markIncludesESPPRows } from './markData';
import { getSignClass, getSignClassNameFactory } from './signClass';
import {
    getManageableColumns,
    getManagerTransformColumns,
    getVisibleColumns,
    getColumnsToDisplayCount
} from './columns';

export const commonWidgetPropTypes = {
    widgetName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    widgetTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    widgetTitleTooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    widgetData: PropTypes.any,
    widgetDataInfo: PropTypes.object,
    widgetEvents: PropTypes.object,
    widgetSettings: PropTypes.any,
    widgetType: PropTypes.string,
    widgetPageParams: PropTypes.object,
    widgetDataParams: PropTypes.object,
    isFetching: PropTypes.bool,
    isRemoving: PropTypes.bool,
    isAdding: PropTypes.bool,
    isLocked: PropTypes.bool,
    isDataViz: PropTypes.bool,
    isPrintMode: PropTypes.bool,
    widgetError: PropTypes.any,
    userProductSources: PropTypes.array,
    currentFeatures: PropTypes.object,
    noPermissions: PropTypes.bool
};

export const commonWidgetActions = {
    onRemoveWidget: PropTypes.func,
    onAddWidget: PropTypes.func,
    onChangeWidget: PropTypes.func,
    onUpdateWidgetSettings: PropTypes.func,
    onUpdateWidgetData: PropTypes.func
};

export const isCurrentWidgetType = (widgetType, widgetName) => {
    const state = store.getState();
    const currentWidgetType = getWidgetTypeSelector(state, widgetName);

    return widgetType === currentWidgetType;
};

export const getSlotSizeByWidgetType = (widgetType, defaultSlotSize = 1) => {
    return slotSizes[widgetType] || defaultSlotSize;
};

export const getWidgetFooterPropsWithSignClass = ({ leftSide = [], rightSide = [], top = [], bottom = [] }) => {
    let index = 0;
    const expandArrayByClass = array => (
        _.map(array, note => (note.type && !note.hidden ?
            { ...note, signClass: getSignClass(signTypes[index++], note.signPositions) } :
            { ...note }))
    );

    return {
        leftSide: expandArrayByClass(leftSide),
        rightSide: expandArrayByClass(rightSide),
        top: expandArrayByClass(top),
        bottom: expandArrayByClass(bottom)
    };
};

export const getMarkedData = (data, markingFunctionList = [], widgetFooterProps) => {
    const getSignClassByMessageType = getSignClassNameFactory(widgetFooterProps);

    return _.reduce(markingFunctionList, (result, markingFunction) => {
        return markingFunction(result, getSignClassByMessageType);
    }, data);
};

export const getSelectedDate = (dates, props) => {
    const { widgetSettings, selectedDate, widgetData } = props;
    const eodTimestamp = _.get(widgetData, 'eodTimestamp');
    const eodTimestampFromSettings = _.get(widgetSettings, 'eodTimestamp');
    const selectedDateFromSettings = _.get(widgetSettings, 'selectedDate');

    if (eodTimestamp === eodTimestampFromSettings) {
        return dates && (selectedDateFromSettings && _.includes(dates, selectedDateFromSettings) ? selectedDateFromSettings : dates[0]);
    }

    return dates && (selectedDate ? selectedDate : dates[0]);
};

export const compareMktVals = (value, other) => {
    const valueIsBackEndNull = checkBackEndNull(value);
    const otherIsBackEndNull = checkBackEndNull(other);

    if (valueIsBackEndNull && otherIsBackEndNull) {
        return 0;
    }

    if (valueIsBackEndNull) {
        return 1;
    }

    if (otherIsBackEndNull) {
        return -1;
    }

    let formattedValue = scaleFormatter(value, formatterConstants.MM_SCALE);
    let formattedOther = scaleFormatter(other, formatterConstants.MM_SCALE);

    formattedValue = formattedValue > 1 || formattedValue < -1
        ? round(formattedValue)
        : formattedValue;
    formattedOther = formattedOther > 1 || formattedOther < -1
        ? round(formattedOther)
        : formattedOther;

    return formattedOther - formattedValue;
};

const isDateLessThanDefault = (data = {}) => {
    const defaultDate = moment(data.lastBusinessDate, dateFormats.SERVER_DATE_FORMAT);
    const currentDate = moment(data.date, dateFormats.SERVER_DATE_FORMAT);
    const widgetNoData = !_.get(data, 'dataView.data.length');

    return !widgetNoData && currentDate.isBefore(defaultDate);
};

export {
    markIncludesCeDeCoRows,
    markIncludesESPPRows,
    getSignClassNameFactory,
    getManageableColumns,
    getManagerTransformColumns,
    getVisibleColumns,
    getColumnsToDisplayCount,
    isDateLessThanDefault
};
