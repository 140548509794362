import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getIssuersAmount } from 'State/issuers/selectors';
import IssuerCheckBoxList from './components/IssuerCheckBoxList';
import SecuritySection from './components/SecuritySection';
import SecurityFilter from './components/SecurityFilter';

import './MultipleSecuritySelector.scss';

const mapStateToProps = state => ({
    issuersAmount: getIssuersAmount(state)
});

const ROW_HEIGHT = 36;
const VISIBLE_ROW_COUNT = 10;

@connect(mapStateToProps)
class MultipleSecuritySelector extends Component {
    static propTypes = {
        issuersAmount: PropTypes.number
    };

    render() {
        return (
            <div className='multiple-security-selector'>
                <SecurityFilter/>
                <div className='list-container'>
                    {this.props.issuersAmount !== 1 && <IssuerCheckBoxList
                        rowHeight={ROW_HEIGHT}
                        visibleRowCount={VISIBLE_ROW_COUNT}/>}
                    <SecuritySection
                        rowHeight={ROW_HEIGHT}
                        visibleRowCount={VISIBLE_ROW_COUNT}/>
                </div>
            </div>
        );
    }
}

export default MultipleSecuritySelector;
