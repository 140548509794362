import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import cn from 'classnames';

import columns from '../../constants/columns';
import tableFields from '../../constants/tableFields';
import DateFormatter from 'Components/formatters/DateFormatter';
import { getFormattedValue } from '../../utils';
import { abbreviationValues, abbreviationKeys } from 'Constants/number-abbreviations';

const FullQuoteTable = ({ tableData, intl: { formatMessage } }) => {
    const leftColumn = [];
    const rightColumn = [];
    const format = abbreviationValues[abbreviationKeys.millions];
    const symbol = formatMessage({ id: format.localization });
    const getItemClass = fieldName => cn('column-item-value', { ellipsis: fieldName === 'exch' });

    _.forEach(tableFields, (field) => {
        if (field.column === columns.LEFT) {
            leftColumn.push(field);
        } else {
            rightColumn.push(field);
        }
    });

    return (
        <div className='full-quote-table'>
            {[leftColumn, rightColumn].map((column, i) => (
                <div className='column' key={i}>
                    {_.sortBy(column, 'position').map(field => (
                        <div key={field.position} className='column-item'>
                            <FormattedMessage id={`full.quote.${field.name}`}>
                                {(text) => {
                                    return <span className='column-item-name'>{text}</span>;
                                }}
                            </FormattedMessage>
                            <span
                                className={getItemClass(field.name)}
                                title={field.name === 'marketIdCode' ? tableData[field.tooltip] : null}>
                                {getFormattedValue(tableData[field.name], field.type, field.formatterOptions)} {field.name === 'marketCap' && symbol}
                            </span>
                        </div>
                    ))}
                </div>
            ))}
            <div className='full-quote-table-footer'>
                <div className='market-close-date'>
                    <FormattedMessage id='full.quote.marketCloseDate'/>{' '}
                    <DateFormatter value={tableData.marketCloseDate}/>
                </div>
            </div>
        </div>
    );
};

FullQuoteTable.propTypes = {
    tableData: PropTypes.object,
    intl: intlShape
};

export default injectIntl(FullQuoteTable);
