import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PeriodRangeView from '../components/PeriodRangeView';

class PeriodRange extends PureComponent {
    static  propTypes = {
        selectedPeriod: PropTypes.string,
        onSelect: PropTypes.func
    };
    static defaultProps = {
        small: false
    };

    render() {
        return (
            <PeriodRangeView
                {...this.props}
                onSelect={this.props.onSelect}
                selectedPeriod={this.props.selectedPeriod}/>
        );
    }
}

export default PeriodRange;
