import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';

import * as api from '../../../../../api';
import getSuggestionsNormalize from './getSuggestionsNormalize';

const getSuggestionsEnhancer = (
    {
        delay = 350,
        normalizeResponse = getSuggestionsNormalize
    } = {}
) => WrappedComponent => {
    class GetSuggestionsComponent extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'getSuggestionsEnhancer');

        state = {
            isLoading: false,
            isCanceled: false
        };

        componentWillUnmount() {
            this.cancelToken && this.cancelToken.cancel();
        }

        setLoading = (isLoading) => this.setState({ isLoading });
        setCanceled = (isCanceled) => this.setState({ isCanceled });

        getSuggestions = _.debounce((params, resolve, reject) => {
            if (this.state.isCanceled) {
                reject({ isCanceled: true });
                return;
            }

            this.cancelToken && this.cancelToken.cancel();
            this.cancelToken = api.createCancelToken();

            api.getSuggestions(params, {
                cancelToken: this.cancelToken
            }).then(
                (response) => {
                    const normalizedResponse = normalizeResponse(response);

                    this.setLoading(false);
                    resolve(normalizedResponse);
                },
                (error) => {
                    if (!api.isCancel(error)) {
                        this.setLoading(false);
                    }

                    reject(error);
                });
        }, delay);

        cancelRequest = () => {
            this.setCanceled(true);
            this.setLoading(false);
            this.cancelToken && this.cancelToken.cancel();
        };

        request = (params) => {
            this.setCanceled(false);
            this.setLoading(true);

            return new Promise((resolve, reject) => {
                this.getSuggestions(params, resolve, reject);
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isLoading={this.state.isLoading}
                    request={this.request}
                    cancelRequest={this.cancelRequest}/>
            );
        }
    }

    return GetSuggestionsComponent;
};

export default getSuggestionsEnhancer;
