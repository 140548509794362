import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';

import TableEasy from 'Components/Tables/TableEasy';
import { labelCell, numberCell, formattedNumberCell, headerCell } from './cellRenderers';

const firstColumnMaxWidth = 150;
const secondColumnMinWidth = 140;
const CorporateShareholdersTableView = ({ tableData }) => (
    <div className='table-container'>
        <TableEasy
            className='widget-table'
            {...tableData}/>
    </div>
);

CorporateShareholdersTableView.propTypes = {
    tableData: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { data, intl: { formatMessage } } = props;
        const aggregate = _.get(data, 'aggregate', {});
        const tableData = {
            columns: [{
                dataKey: 'accountType',
                maxWidth: firstColumnMaxWidth,
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'corporate.shareholders.accountTitle' }),
                    <FormattedHTMLMessage id='corporate.shareholders.tooltip.accountTitle'/>
                ),
                bodyCellComponent: labelCell
            }, {
                dataKey: 'shareholdersNumber',
                minWidth: secondColumnMinWidth,
                columnClassName: 'text-right',
                title: formatMessage({ id: 'corporate.shareholders.shareholdersNumber' }),
                bodyCellComponent: numberCell
            }, {
                dataKey: 'sharesNumber',
                columnClassName: 'text-right',
                title: formatMessage({ id: 'corporate.shareholders.sharesNumber' }),
                bodyCellComponent: formattedNumberCell
            }],
            data: _.dropRight(data.data, 1), // remove "Other Shareholders" row
            aggregate: {
                shareholdersNumberTotal: aggregate.shareholdersNumberSpecialAccountTotal,
                sharesNumberTotal: aggregate.sharesNumberSpecialAccountTotal
            }
        };

        return { tableData };
    })
)(CorporateShareholdersTableView);
