import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes } from 'redux-form';
import _ from 'lodash';

import ExportRegisteredOverviewModalView from '../components/ExportRegisteredOverviewModalView';
import { runReport } from 'State/exportReports';
import * as widgetsSelectors from 'State/widgets/selectors';
import reportExportFormats from 'Constants/export-report-formats';
import amountOfRecords from 'Constants/amount-of-records';
import exportAmountOfRecords from '../constants/exportAmountOfRecords';
import exportTypes from '../constants/exportTypes';
import fileFormats from 'Constants/file-formats';

const FORM_NAME = 'exportRegisteredOverviewForm';
const INITIAL_VALUES = {
    selectedAmountOfRecords: amountOfRecords.DISPLAYED_RECORDS,
    selectedFormat: reportExportFormats.EXCEL
};
const mapStateToProps = (state, props) => ({
    formValues: getFormValues(FORM_NAME)(state),
    settings: widgetsSelectors.getWidgetSettingsSelector(state, props.exportParams.widgetName)
});
const mapDispatchToProps = { runReport };

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: FORM_NAME,
    initialValues: INITIAL_VALUES
})
class ExportRegisteredOverviewModal extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        exportParams: PropTypes.shape({
            widgetName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            columnsToDisplay: PropTypes.array
        }),
        hideModal: PropTypes.func,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage }, exportParams: { dateRange } } = this.props;
        const startDate = _.get(dateRange, 'startDate');
        const endDate = _.get(dateRange, 'endDate');
        const needToShowThirdOption = startDate || endDate;

        this.amountOfRecords = exportAmountOfRecords
            .map(item => ({
                ...item,
                label: formatMessage({ id: item.label })
            }))
            .filter(item => {
                return needToShowThirdOption
                    ? true : item.value !== amountOfRecords.ALL_RECORDS_BY_DATE_RANGE;
            });

        this.sortCriteria = _.get(props, 'exportParams.sortCriteria');
        this.exportFormats = exportTypes.map(format => ({
            label: format === reportExportFormats.EXCEL ? `${fileFormats[format]}*` : fileFormats[format],
            value: reportExportFormats[format],
            position: 'block'
        }));
    }

    handleExport = ({ selectedAmountOfRecords, selectedFormat }) => {
        const { hideModal } = this.props;
        const formData = this.getFormData(selectedAmountOfRecords);

        hideModal();
        this.props.onSuccess(selectedFormat, formData);
    };

    handleAmountOfRecordsChange = e => {
        const { exportParams: { defaultSortCriteria } } = this.props;

        if (e.currentTarget.value === amountOfRecords.ALL_ACCOUNTS) {
            this.sortCriteria = defaultSortCriteria;
        } else {
            this.sortCriteria = _.get(this.props, 'exportParams.sortCriteria');
        }
    };

    getFormData = selectedAmountOfRecords => {
        const { exportParams: { columnsToDisplay, dateRange, shareholderId, currentPage, pageSize } } = this.props;
        const sortCriteria = this.sortCriteria;

        return {
            shareholderId,
            selectedAmountOfRecords,
            filterCriteria: {
                currentPage,
                pageSize,
                dateRange
            },
            sortCriteria,
            columnsToDisplay
        };
    };

    render() {
        const { settings, hideModal } = this.props;

        return (
            <ExportRegisteredOverviewModalView
                {...this.props}
                amountOfRecords={this.amountOfRecords}
                sortCriteria={this.sortCriteria}
                widgetSettings={settings}
                exportFormats={this.exportFormats}
                onAmountOfRecordsChange={this.handleAmountOfRecordsChange}
                onExport={this.handleExport}
                onCancel={hideModal}/>
        );
    }
}

export default ExportRegisteredOverviewModal;
