import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../../../Spinner';

const WidgetBusy = ({ isFetching, loadingMessageKey }) => (
    <div className='widget-fetching'>
        <Spinner
            loadingMessageKey={loadingMessageKey}
            isFetching={isFetching}
            isBlocker/>
    </div>
);

WidgetBusy.propTypes = {
    isFetching: PropTypes.bool,
    loadingMessageKey: PropTypes.string
};

export default WidgetBusy;
