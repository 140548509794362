import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import formatterOptions from 'Constants/formatter-options';
import { changeFormatter } from './formatters';
import { NullableFormatterWithIntl } from './NullableFormatter';

const ChangeFormatter = (props) => {
    const {
        withTitle,
        decimalPoints,
        negativeWithBrackets,
        className
    } = props;

    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, negClass, intl }) => {
                const formattedValue = changeFormatter(intl, value, {
                    decimalPoints,
                    negativeWithBrackets
                });
                const title = withTitle ? formattedValue : null;

                return (<span title={title} className={cn(className, negClass)}>{formattedValue}</span>);
            }}
        </NullableFormatterWithIntl>
    );
};

ChangeFormatter.propTypes = {
    value: PropTypes.any,
    decimalPoints: PropTypes.number,
    negativeWithBrackets: PropTypes.bool,
    withTitle: PropTypes.bool,
    className: PropTypes.string
};

ChangeFormatter.defaultProps = {
    decimalPoints: formatterOptions.DEFAULT_DECIMAL_POINTS,
    negativeWithBrackets: true
};

export default ChangeFormatter;
