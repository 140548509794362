import { SET_APP_VERSION } from './actions';
import { getAppVersion } from './selectors';

export  const appVersionMiddleware = store => next => action => {
    // eslint-disable-next-line callback-return
    const returnValue = next(action);

    if (action.type === SET_APP_VERSION) {
        window.APP_VERSION = getAppVersion(store.getState());
    }

    return returnValue;
};
