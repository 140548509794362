import pageRoutes from 'Constants/page-routes';

export default [
    {
        labelKey: 'navigation.ownership.contacts',
        to: pageRoutes.ownershipContactsLanding
    },
    {
        labelKey: 'navigation.ownership.contacts.institutions',
        to: pageRoutes.ownershipContactsInstitutions
    }
];
