import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { hasAccessToPaSelector } from 'State/user';
import LinkEx from 'Components/LinkEx';

const mapStateToProps = state => ({
    accessToPa: hasAccessToPaSelector(state)
});

@connect(mapStateToProps)
class PlatformAdministrationLink extends Component {
    static propTypes = {
        accessToPa: PropTypes.bool.isRequired
    };

    render() {
        return this.props.accessToPa
            ? (
                <LinkEx isExternal to={PA_BASE_URL}>
                    <FormattedMessage id='common.platformAdministration.link'/>
                </LinkEx>
            )
            : null;
    }
}

export default PlatformAdministrationLink;
