import React, { Component } from 'react';
import { connect } from 'react-redux';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';

import { getRegisteredOverviewTdProps } from '../utils';
import RegisteredPagination from '../../../_commonComponents/RegisteredShareholderTable/components/RegisteredPagination';
import { getSortDirection } from 'Components/Tables/utils';
import { PAGE_SIZE } from '../constants/page-settings';
import { runReport } from 'State/exportReports';
import reportTypes from 'Constants/report-types';
import registeredReportTemplates from 'Components/widgets/_commonComponents/RegisteredShareholderTable/constants/registered-report-templates';
import reportExportFormats from 'Constants/export-report-formats';

const mapDispatchToProps = {
    runReport
};

export default WrappedComponent => {
    @connect(null, mapDispatchToProps)
    class WithPagination extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withPagination');

        static propTypes = {
            ...WrappedComponent.propTypes
        };

        constructor(props) {
            super(props);
            this.widgetEvents = {
                onBuildDataParams: () => ({
                    shareholderId: _.get(this.props, 'widgetPageParams.shareholderId'),
                    filterCriteria: {
                        currentPage: 1,
                        pageSize: PAGE_SIZE
                    },
                    sortCriteria: _.get(this.props, 'widgetSettings.sortCriteria')
                })
            };
        }

        componentDidMount() {
            const { onUpdateWidgetData, redirectOn404 } = this.props;
            const widgetDataParams = _.isFunction(this.widgetEvents.onBuildDataParams) ? this.widgetEvents.onBuildDataParams({}) : {};

            onUpdateWidgetData(widgetDataParams, { redirectOn404 });
        }

        handleChangeCurrentPage = (currentPage) => {
            const { widgetSettings: { sortCriteria } } = this.props;

            this.updateWidgetData({ currentPage, sortCriteria });
        };

        handleChangeSortCriteria = (sortBy, columnsDefinitions) => {
            const { widgetSettings: { sortCriteria: { sortField: prevSortBy, sortDirection: prevDirection } }, widgetData: { filterCriteria: { currentPage } } } = this.props;
            const sortCriteria = {
                sortField: sortBy,
                sortDirection: getSortDirection(
                    sortBy,
                    prevSortBy,
                    prevDirection,
                    columnsDefinitions
                )
            };

            this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
            this.updateWidgetData({ sortCriteria, currentPage });
        };

        handleExport = () => {
            const { widgetSettings = {}, widgetType, widgetPageParams, widgetData } = this.props;
            const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
            const selectedColumns = _.get(widgetSettings, 'selectedColumns', []);
            const reportTemplateType = reportTypes[registeredReportTemplates[widgetType]];
            const shareholderId = _.get(widgetPageParams, 'shareholderId');
            const currentPage = _.get(widgetData, 'filterCriteria.currentPage');

            this.props.runReport(
                reportTemplateType,
                null,
                reportExportFormats.EXCEL,
                {
                    shareholderId,
                    filterCriteria: {
                        currentPage,
                        pageSize: PAGE_SIZE
                    },
                    sortCriteria,
                    columnsToDisplay: selectedColumns
                }
            );
        };

        getDefaultTdProps = columnType => ({
            ...getRegisteredOverviewTdProps(columnType)
        });

        updateWidgetData = ({ sortCriteria, currentPage }) => this.props.onUpdateWidgetData({
            ...this.props.widgetPageParams,
            filterCriteria: {
                currentPage,
                pageSize: PAGE_SIZE
            },
            sortCriteria
        });

        render() {
            return (
                <WrappedComponent
                    widgetEvents={this.widgetEvents}
                    getDefaultTdProps={this.getDefaultTdProps}
                    onExport={this.handleExport}
                    filterPanel={RegisteredPagination}
                    onChangeCurrentPage={this.handleChangeCurrentPage}
                    pageSize={PAGE_SIZE}
                    onChangeSortCriteria={this.handleChangeSortCriteria}
                    isServerSorting
                    {...this.props}/>
            );
        }
    }

    return WithPagination;
};
