import { connect } from 'react-redux';

import ControlBooksResultTabs from './ControlBooksResultTabs';
import { changeDataType } from 'State/reportingControlBooks/actions';
import {
    dataTypeSelector,
    includeDetailedDataSelector,
    includeWeightedDataSelector
} from 'State/reportingControlBooks/selectors';

const mapStateToProps = state => ({
    dataType: dataTypeSelector(state),
    includeDetailedData: includeDetailedDataSelector(state),
    includeWeightedData: includeWeightedDataSelector(state)
});

const mapDispatchToProps = {
    changeDataType
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlBooksResultTabs);
