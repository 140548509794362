import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { NullableFormatter } from 'Components/formatters';

const StateCell = (props) => {
    const state = props.value;
    const country = _.get(props, 'rowData.country');
    const customClassName = _.get(props, 'rowData.className');

    return (
        <NullableFormatter value={state}>
            <div className='ellipsis'>
                <span className={customClassName} title={state}>{state}{country && ` (${country})`}</span>
            </div>
        </NullableFormatter>
    );
};

StateCell.propTypes = {
    value: PropTypes.string,
    rowData: PropTypes.shape({
        country: PropTypes.string
    }),
    children: PropTypes.element,
    title: PropTypes.bool,
    unidentifiedMessageId: PropTypes.string,
    className: PropTypes.string
};

export default StateCell;
