import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes } from 'Components/widgets/utils';
import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import { withPagination } from '../_commonComponents/RegisteredShareholderTable/hoc';

import './RegisteredShareholderClosedOtherType.scss';

@withPagination
@injectIntl
class RegisteredShareholderClosedOtherType extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        intl: intlShape
    };

    render() {
        const { intl: { formatMessage }, ...props } = this.props;
        const values = _.get(props, 'widgetData.values', []);
        const totalPages = _.get(props, 'widgetData.totalPages');
        const currentPage = _.get(props, 'widgetData.filterCriteria.currentPage');
        const footerProps = {
            visible: totalPages === currentPage,
            sharesTotal: _.get(props, 'widgetData.aggregate.sharesTotal'),
            label: formatMessage({ id: 'registered.shareholder.footer.sharesTotal' })
        };

        return (
            <RegisteredShareholderTable
                {...props}
                className='registered-shareholder-close-other-type'
                footerProps={footerProps}
                secondarySorting={secondarySorting}
                data={values}
                getTdProps={this.getTdProps}
                columnsDefinitions={columnsDefinitions}
                hideColumnsManager
                noDataMessageId='widgets.noDataMessage.matchingClosedTaxLotInfo'/>
        );
    }
}

export default RegisteredShareholderClosedOtherType;
