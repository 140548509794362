import React from 'react';
import PropsTypes from 'prop-types';

import returnParamTypes from 'Constants/return-param-types';
import { RecentSearchAccount, RecentSearchAccountPropType } from './RecentSearchAccount';

export const RecentSearchList = ({ returnPath, shareholders  }) => {
    return (
        <div className='recent-search-list'>
            {shareholders.map(account => (
                <RecentSearchAccount
                    key={account.shareholderName}
                    returnPath={returnPath}
                    account={account}/>
            ))}
        </div>
    );
};

RecentSearchList.propTypes = {
    shareholders: PropsTypes.arrayOf(RecentSearchAccountPropType),
    returnPath: returnParamTypes
};
