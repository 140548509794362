export default {
    SYMBOL_EMPTY_VALUE: '-',
    DEFAULT_DECIMAL_POINTS: 0,
    DEFAULT_PERCENT_DECIMAL_POINTS: 2,
    MM_SCALE: 1000000,
    THRESHOLD: 0.01,
    MKT_VAL_THRESHOLD: 1,
    DEFAULT_FRACTION_DIGITS: 2,
    MAX_DECIMAL_POINTS: 10
};
