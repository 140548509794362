import sortDirections from 'Constants/sort-directions';
import columnNames from '../../_commonComponents/Top25HoldingsTable/constants/top-25-holdings-column-names';

export default [
    {
        columnName: columnNames.HOLDING_NAME,
        minWidth: 158,
        order: 1,
        displayName: 'top25Holdings.table.holdingName',
        initialSortDirection: sortDirections.ASC
    },
    // {
    //     columnName: columnNames.PORTFOLIO_PERCENT,
    //     minWidth: 120,
    //     maxWidth: 120,
    //     order: 2,
    //     displayName: 'top25Holdings.table.portfolioPercent',
    //     initialSortDirection: sortDirections.DESC
    // },
    // {
    //     columnName: columnNames.CHANGE_PERCENT,
    //     minWidth: 120,
    //     maxWidth: 120,
    //     order: 3,
    //     displayName: 'top25Holdings.table.changePercent',
    //     initialSortDirection: sortDirections.DESC
    // },
    {
        columnName: columnNames.MKT_VAL,
        minWidth: 120,
        order: 4,
        displayName: 'top25Holdings.table.mktVal',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.MKT_VAL_CHANGE,
        minWidth: 120,
        order: 5,
        displayName: 'top25Holdings.table.mktValChange',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.OS_PERCENT,
        minWidth: 120,
        maxWidth: 120,
        order: 6,
        displayName: 'top25Holdings.table.osPercent',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.POSITION,
        minWidth: 120,
        order: 7,
        displayName: 'top25Holdings.table.position',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.OS_CHANGE_PERCENT,
        minWidth: 120,
        maxWidth: 120,
        order: 8,
        displayName: 'top25Holdings.table.osChangePercent',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.POSITION_CHANGE,
        minWidth: 173,
        order: 9,
        displayName: 'top25Holdings.table.positionChange',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.REPORT_DATE,
        minWidth: 120,
        maxWidth: 120,
        order: 10,
        displayName: 'top25Holdings.table.reportDate',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.SECTOR,
        minWidth: 158,
        order: 11,
        displayName: 'top25Holdings.table.sector',
        initialSortDirection: sortDirections.ASC
    }
];
