import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import cn from 'classnames';
import { cloneDeep } from 'lodash';

const withListByColumns = WrappedComponent => class extends Component {
    static displayName = wrapDisplayName(WrappedComponent, 'withListByColumns');

    static propTypes = {
        ...WrappedComponent.propTypes,
        columnClassName: PropTypes.string,
        list: PropTypes.array.isRequired,
        rows: PropTypes.number
    };

    render() {
        const { columnClassName, rows, ...rest } = this.props;
        const list = cloneDeep(this.props.list);
        const groupList = [];

        while (list.length > 0) {
            groupList.push(list.splice(0, rows));
        }

        const content = groupList.map((column, i) => (
            <WrappedComponent
                key={i}
                {...rest}
                list={column}/>
        ));

        return (
            <div className={cn(columnClassName, 'list-by-columns')}>
                {content}
            </div>
        );
    }
};

export default withListByColumns;
