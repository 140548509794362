import PropTypes from 'prop-types';
import React from 'react';
import { some } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { compose, withHandlers, withState } from '@shakacode/recompose';

import ColumnFilter from 'Components/Tables/components/ColumnFilter';
import { commonSortDescendingIcon, commonSortAscendingIcon } from 'Components/Tables/components/commonSortIcons';

const HeadingCellWithFiltration = (props) => {
    const {
        filterOptions,
        handleSubmitFilter,
        sortProperty, sortAscendingIcon, sortDescendingIcon,
        children
    } = props;

    const filterApplied = some(filterOptions, option => !option.isSelected);

    return (
        <div className='heading-cell-with-filtration'>
            {children}
            {filterOptions.length > 1 &&
                <div
                    onClick={(proxy) => proxy.stopPropagation()}
                    className='trigger-wrapper'>
                    <ColumnFilter onSubmit={handleSubmitFilter} values={filterOptions}/>
                    {filterApplied && <span className='filter-info'>(<FormattedMessage id='tables.filterApplied'/>)</span>}
                </div>
            }
            {sortProperty && sortProperty.sortAscending && sortAscendingIcon}
            {sortProperty && !sortProperty.sortAscending && sortDescendingIcon}
        </div>
    );
};

HeadingCellWithFiltration.propTypes = {
    title: PropTypes.string,
    onFiltration: PropTypes.func,
    handleSubmitFilter: PropTypes.func,
    updateOptions: PropTypes.func,
    filterOptions: PropTypes.array,
    sortAscendingIcon: PropTypes.object,
    sortDescendingIcon: PropTypes.object,
    sortProperty: PropTypes.object,
    children: PropTypes.node
};

HeadingCellWithFiltration.defaultProps = {
    filterOptions: [],
    sortAscendingIcon: commonSortAscendingIcon,
    sortDescendingIcon: commonSortDescendingIcon
};

const enhancedComponent = compose(withState(
    'filterOptions',
    'updateOptions',
    ({ filterOptions }) => filterOptions
),
withHandlers({
    handleSubmitFilter: props => options => {
        props.updateOptions(options);
        props.onFiltration(options);
    }
})
)(HeadingCellWithFiltration);

export default enhancedComponent;
