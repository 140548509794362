import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getFormValues, reduxForm, SubmissionError } from 'redux-form';
import _ from 'lodash';

import AdvancedSearchModalView from '../components/AdvancedSearchModalView';
import { searchByCertificateNo, searchByTaxID } from 'State/advancedSearch/actions';
import * as searchSelectors from 'State/advancedSearch/selectors';
import { hideModal } from 'State/modal';
import { PII } from 'Constants/search';
import searchMessageParams from '../constants/searchMessageParams';
import { isValidCert, isValidSSN } from 'Utils/validators';

import '../components/AdvancedSearchModal.scss';

const formName = 'advanced-search';
const searchFieldName = 'advancedSearch';

// validation for CERTIFICATE_NO is assumption while
const validator = {
    [PII.TAX_ID]: value => isValidSSN(value) ? undefined : 'error',
    [PII.CERTIFICATE_NO]: value => isValidCert(value) ? undefined : 'error'
};
const mapStateToProps = state => ({
    isFetching: searchSelectors.isFetchingAdvanced(state),
    formValues: getFormValues(formName)(state),
    query: getFormValues(formName)(state)
});
const mapDispatchToProps = {
    hideModal,
    searchByTaxID,
    searchByCertificateNo
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: formName
})
class AdvancedSearchModal extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        isFetching: PropTypes.bool,
        formValues: PropTypes.object,
        searchByTaxID: PropTypes.func,
        searchByCertificateNo: PropTypes.func,
        hideModal: PropTypes.func,
        change: PropTypes.func,
        reset: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.search = {
            [PII.TAX_ID]: props.searchByTaxID,
            [PII.CERTIFICATE_NO]: props.searchByCertificateNo
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const value = _.get(nextProps.formValues, 'parameterOfSearch');

        if (!value) {
            nextProps.change('advancedSearch', '');
        }
        if (nextProps.isFetching === false && this.props.isFetching === true) {
            this.props.reset();
            this.props.hideModal();
        }
    }

    handleSearch = (values) => {
        const { formValues } = this.props;
        const value = _.get(values, 'advancedSearch', '');
        const parameterOfSearch = _.get(formValues, 'parameterOfSearch');
        const validate = validator[parameterOfSearch];

        if (validate && validate(value)) {
            throw new SubmissionError({
                [searchFieldName]: 'error'
            });
        }
        const query = _.get(values, searchFieldName);

        this.search[parameterOfSearch](query);
    };

    render() {
        const { handleSearch, props } = this;
        const { intl, formValues, isFetching } = props;
        const parameterOfSearch = _.get(formValues, 'parameterOfSearch');

        const viewProps = {
            intl,
            isFetching,
            disabled: !parameterOfSearch,
            onSearch: handleSearch,
            searchFieldName,
            formName,
            message: searchMessageParams[parameterOfSearch]
        };

        return (
            <AdvancedSearchModalView {...viewProps}/>
        );
    }
}

export default AdvancedSearchModal;
