import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { LazyPopover } from 'Components/Popover';

import './TopShareholdersTooltip.scss';

const TopShareholdersTooltip = ({ title, tooltipTextId, className }) => {
    const trigger = (
        <span>{title}</span>
    );
    const popoverProps = {
        id: 'TOP-SHAREHOLDERS-POPOVER',
        trigger,
        triggerType: ['hover'],
        placement: 'top'
    };

    return (
        <LazyPopover {...popoverProps}>
            <div className={cn('top-shareholders-tooltip', className)}>
                <FormattedMessage id={tooltipTextId ? tooltipTextId : 'tables.headingWithTopShareholders.tooltip'}/>
            </div>
        </LazyPopover>
    );
};

TopShareholdersTooltip.propTypes = {
    title: PropTypes.string,
    tooltipTextId: PropTypes.string,
    className: PropTypes.string
};

export default TopShareholdersTooltip;
