import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Column } from 'react-virtualized';
import { injectIntl, intlShape } from 'react-intl';

import './totalLastCell.scss';

const totalLastCell = ({
    width,
    paddingRight,
    children,
    rowData,
    intl: { formatMessage }
}) => {
    const childrenArr = React.Children.toArray(children);

    return (
        <div
            className='ReactVirtualized__Table__row virtualized-total-last-cell'
            style={{
                width,
                paddingRight
            }}>
            { childrenArr.map((c, index) => {
                const p = c.props;
                let cell;

                // last is a total label
                if (index === childrenArr.length - 1) {
                    const totalMessage = formatMessage({ id: 'tableEasy.total', defaultValue: 'TOTAL' });
                    const cellData = rowData[p.dataKey];
                    const formattedNumber = p.cellRenderer({ cellData });

                    cell = <span>{totalMessage}: {formattedNumber}</span>;
                }

                // empty for non-last cells
                return (
                    <div
                        key={c.key}
                        className={cn('ReactVirtualized__Table__rowColumn', p.className)}
                        style={{
                            flexGrow: p.flexGrow,
                            flexShrink: p.flexShrink,
                            flexBasis: p.width,
                            maxWidth: p.maxWidth
                        }}>
                        {cell}
                    </div>
                );
            }) }
        </div>);
};

totalLastCell.propTypes = {
    width: PropTypes.number,
    paddingRight: PropTypes.number,
    rowData: PropTypes.object,
    children: (props, propName, componentName) => {
        const children = React.Children.toArray(props.children);

        for (let i = 0; i < children.length; i++) {
            if (children[i].type !== Column) {
                return new Error(`${componentName} only accepts children of type Column`);
            }
        }
    },
    intl: intlShape
};

totalLastCell.defaultProps = {
    width: 300,
    paddingRight: 0
};


export default injectIntl(totalLastCell);
