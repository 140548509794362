import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
    showExportPendingModal,
    showExportNoResultsModal,
    showReportFailedModal,
    showExportReportModal,
    showConfirmExportLargeReportModal,
    showExportRegisteredReportModal,
    showReportWithForbiddenSecurityModal,
    showReportRequestedModal
} from 'State/modal/actions';
import apiRoutes from 'Constants/api-routes';
import { downloadFile } from 'Utils/utils';
import { postMessageTypeMap } from 'Constants/post-message-types';
import { notifyError } from 'State/notifier/actions';

const downloadByGuid = downloadUrl => (dispatch, reportGuid) => {
    if (reportGuid) {
        downloadFile(`${downloadUrl}/${reportGuid}`, err => {
            const messageType = _.get(err, 'messageType');

            if (messageType === postMessageTypeMap.FILE_NOT_FOUND) {
                dispatch(notifyError(
                    null,
                    <FormattedMessage id='downloadDocument.notifier.fileNotFound'/>
                ));
            } else {
                dispatch(showReportFailedModal());
            }
        });
    } else {
        dispatch(showReportFailedModal());
    }
};

const downloadLaunchedReportByGuid = downloadByGuid(apiRoutes.downloadLaunchedReportUrl);

export {
    downloadLaunchedReportByGuid,
    showConfirmExportLargeReportModal,
    showReportFailedModal,
    showExportPendingModal,
    showExportNoResultsModal,
    showExportReportModal,
    showExportRegisteredReportModal,
    showReportWithForbiddenSecurityModal,
    showReportRequestedModal
};
