import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { runAsOfDateReport } from 'State/exportReports';
import { showClassicAsOfDateExportReportModal } from 'State/modal';
import ShareholderClassicAsOfDateLinkView from './ShareholderClassicAsOfDateLinkView';

import './ShareholderClassicAsOfDateLink.scss';

const mapDispatchToProps = {
    showClassicAsOfDateExportReportModal,
    runAsOfDateReport
};

const ShareholderClassicAsOfDateLink = props => {
    const handleGetReport = useCallback(() => {
        props.showClassicAsOfDateExportReportModal({
            titleKey: 'modals.exportClassicAsOfDateReport.title',
            onSuccess: ({ isSummary, asOfDate }) => props.runAsOfDateReport(isSummary, asOfDate)
        });
    }, [props.runAsOfDateReport, props.showClassicAsOfDateExportReportModal]);

    return <ShareholderClassicAsOfDateLinkView onGetReport={handleGetReport}/>;
};

ShareholderClassicAsOfDateLink.propTypes = {
    runAsOfDateReport: PropTypes.func,
    showClassicAsOfDateExportReportModal: PropTypes.func
};

export default connect(null, mapDispatchToProps)(memo(ShareholderClassicAsOfDateLink));
