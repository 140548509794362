import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { showConfirmModal } from 'State/modal';
import * as userActions from 'State/user/actions';
import * as savedReportTemplatesActions from 'State/savedReportTemplates/actions';
import StartedDataVizReportView from '../components/StartedDataVizReportView';
import pageRoutes from 'Constants/page-routes';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';

const mapDispatchToProps = {
    deleteDataVizTemplate: savedReportTemplatesActions.deleteDataVizTemplate,
    changeUserNotifications: userActions.changeUserNotifications,
    showConfirmModal
};

@withRouter
@connect(null, mapDispatchToProps)
class StartedDataVizReport extends Component {
    static propTypes = {
        dataVizStartedReport: PropTypes.object.isRequired,
        deleteDataVizTemplate: PropTypes.func,
        changeUserNotifications: PropTypes.func,
        showConfirmModal: PropTypes.func,
        history: PropTypes.object,
        location: PropTypes.object
    };

    handleDelete = () => {
        this.props.showConfirmModal({
            titleKey: 'modals.confirmModal.title.delete',
            messageKey: 'modals.confirmModal.message.delete',
            okTextKey: 'common.delete',
            onSuccess: this.successDelete,
            payload: this.props.dataVizStartedReport.reportName
        });
    };

    handleEdit = () => {
        const { dataVizStartedReport, history, location } = this.props;

        if (!dataVizStartedReport.isActive) {
            history.push(buildLocationWithSafeQueryParams(pageRoutes.dataViz, location));
        }
    };

    successDelete = () => {
        const { deleteDataVizTemplate, history, changeUserNotifications, location } = this.props;

        // delete DataPrez (old DataViz) Started template
        deleteDataVizTemplate(null).then(() => {
            history.replace(buildLocationWithSafeQueryParams('/reporting', location));
            changeUserNotifications({
                newDataVizReports: 0
            });
        });
    };

    render() {
        return (
            <StartedDataVizReportView
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                isActive={this.props.dataVizStartedReport.isActive}/>
        );
    }
}

export default StartedDataVizReport;
