import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { replaceToIndex } from 'State/routes/actions';
import { changeCurrentSecurity, refreshCurrentPage } from 'State/user/actions';
import { hideModal } from 'State/modal';
import SecuritySelector from 'Components/SecuritySelector';

import './SelectSecurityModal.scss';

const mapDispatchToProps = {
    changeCurrentSecurity,
    refreshCurrentPage,
    replaceToIndex,
    hideModal
};

@withRouter
@connect(null, mapDispatchToProps)
class SelectSecurityModal extends Component {
    static propTypes = {
        changeCurrentSecurity: PropTypes.func.isRequired,
        refreshCurrentPage: PropTypes.func,
        replaceToIndex: PropTypes.func,
        hideModal: PropTypes.func,
        history: PropTypes.object,
        location: PropTypes.object,
        securityIds: PropTypes.array
    };

    handleSelect = (securityId, isChanged, isSetDefault) => {
        if (isSetDefault) {
            this.props.changeCurrentSecurity(securityId)
                .then(() => {
                    this.props.hideModal();
                    if (!/security=/gi.test(this.props.location.search)) {
                        this.props.refreshCurrentPage().then(() => this.props.replaceToIndex());
                    } else {
                        this.props.replaceToIndex();
                    }
                });
        } else if (isChanged && !isSetDefault) {
            this.props.replaceToIndex(securityId);
        } else {
            this.props.hideModal();
        }
    };

    handleCancel = () => {
        this.props.hideModal();
    };

    render() {
        return (
            <div className='select-security-modal-content'>
                <SecuritySelector
                    onCancel={this.handleCancel}
                    onSelect={this.handleSelect}
                    securityIds={this.props.securityIds}/>
            </div>
        );
    }
}

export default SelectSecurityModal;
