import { createReducer } from 'Utils/reducer';
import {
    FIND_ANALYSIS_SHAREHOLDER_REQUEST,
    FIND_ANALYSIS_SHAREHOLDER_SUCCESS,
    FIND_ANALYSIS_SHAREHOLDER_FAILURE,
    CLEAR_FOUND_ANALYSIS_SHAREHOLDERS,
    GET_ANALYSIS_SHAREHOLDER_DATA_REQUEST,
    GET_ANALYSIS_SHAREHOLDER_DATA_SUCCESS,
    FIND_ANALYSIS_SHAREHOLDER_CANCEL_REQUEST
} from './actions';

const initialState = {
    shareholderData: {
        payload: null,
        isFetching: false,
        error: null
    },
    searchList: {
        payload: null,
        isFetching: false,
        error: null
    }
};

const actionHandlers = {
    [FIND_ANALYSIS_SHAREHOLDER_REQUEST]: (state) => ({
        ...initialState,
        searchList: {
            ...state.searchList,
            isFetching: true
        }
    }),
    [FIND_ANALYSIS_SHAREHOLDER_SUCCESS]: (state, { payload }) => ({
        ...state,
        searchList: {
            ...state.searchList,
            payload,
            isFetching: false
        }
    }),
    [FIND_ANALYSIS_SHAREHOLDER_FAILURE]: (state, { payload }) => ({
        ...state,
        searchList: {
            ...state.searchList,
            error: payload,
            isFetching: false
        }
    }),
    [FIND_ANALYSIS_SHAREHOLDER_CANCEL_REQUEST]:(state) => ({
        ...state,
        searchList: {
            ...state.searchList,
            isFetching: false
        },
        shareholderData: {
            ...state.shareholderData,
            isFetching: false
        }
    }),

    [GET_ANALYSIS_SHAREHOLDER_DATA_REQUEST]: (state) => ({
        ...state,
        shareholderData: {
            isFetching: true
        }
    }),
    [GET_ANALYSIS_SHAREHOLDER_DATA_SUCCESS]: (state, { payload }) => ({
        ...state,
        shareholderData: {
            payload,
            isFetching: false
        }
    }),

    [CLEAR_FOUND_ANALYSIS_SHAREHOLDERS]: () => initialState
};

export default createReducer(initialState, actionHandlers);
