import SHARE_TYPES from './shareTypes';

export const SHARE_TYPES_RADIO_VALUES = [
    {
        id: SHARE_TYPES.ALL,
        value: SHARE_TYPES.ALL,
        label: 'reporting.share.range.analysis.shareType.allShareTypes',
        name: 'shareType'
    },
    {
        id: SHARE_TYPES.CERTIFICATED_AND_BOOK,
        value: SHARE_TYPES.CERTIFICATED_AND_BOOK,
        label: 'reporting.share.range.analysis.shareType.certificatedAndBookShares',
        name: 'shareType'
    },
    {
        id: SHARE_TYPES.DIVIDEND_REINVESTMENT,
        value: SHARE_TYPES.DIVIDEND_REINVESTMENT,
        label: 'reporting.share.range.analysis.shareType.dividendReinvestmentShares',
        name: 'shareType'
    }
];
