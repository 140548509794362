import slotTypes from 'Constants/slot-types';

export default !DEMO_MODE ? {
    [1]: {
        'type': slotTypes.SINGLE,
        'isLocked': false,
        'widgets': []
    },
    [2]: {
        'type': slotTypes.SINGLE,
        'isLocked': false,
        'widgets': []
    },
    [3]: {
        'type': slotTypes.SINGLE,
        'isLocked': false,
        'widgets': []
    },
    [4]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [5]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    }
} : {
    [4]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    }
};
