import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from '@shakacode/recompose';
import { connect } from 'react-redux';

import { requireUserInfo } from 'Utils/auth';
import { getUserInfoSelector, getCurrentSecurityIdSelector } from 'State/user/selectors';
import { getUserTreasuryAccess } from 'State/user/actions';
import { getAvailableSecurities } from 'State/securities/selectors';
import { getCurrentFeaturesSelector } from 'State/features';
import { getUserWatchlists } from 'State/watchlist';
import featureTypes from 'Constants/feature-types';

const mapStateToProps = state => ({
    userInfo: getUserInfoSelector(state),
    securities: getAvailableSecurities(state),
    currentSecurityId: getCurrentSecurityIdSelector(state),
    currentFeatures: getCurrentFeaturesSelector(state)
});

const mapDispatchToProps = {
    getUserTreasuryAccess,
    getUserWatchlists
};

const withUserInfo = WrappedComponent => {
    @connect(mapStateToProps, mapDispatchToProps)
    class WithUserInfoComponent extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withUserInfo');

        static propTypes = {
            userInfo: PropTypes.object,
            securities: PropTypes.arrayOf(PropTypes.object),
            history: PropTypes.object,
            location: PropTypes.object,
            getUserTreasuryAccess: PropTypes.func,
            currentSecurityId: PropTypes.number,
            currentFeatures: PropTypes.object,
            getUserWatchlists: PropTypes.func,
            isLoadingWatchlist: PropTypes.func
        };

        componentDidMount() {
            requireUserInfo(this.props.location.pathname).then(() => {
                this.props.currentSecurityId && this.props.getUserTreasuryAccess();

                if (this.props.currentFeatures[featureTypes.SHAREHOLDER_WATCHLIST]) {
                    this.props.getUserWatchlists();
                }
            });
        }

        render() {
            const { userInfo, securities } = this.props;
            const isUserInfoAndSecuritiesReceived = securities.length > 0 && userInfo;

            return (
                isUserInfoAndSecuritiesReceived ? <WrappedComponent {...this.props}/> : null
            );
        }
    }

    return WithUserInfoComponent;
};

export default withUserInfo;
