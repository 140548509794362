import widgetTypes from 'Constants/widget-types';

export default {
    [widgetTypes.compareShareholdersAccountInfo]: 'compareShareholdersAccountInfoTemplate',
    [widgetTypes.compareShareholdersTransactionHistory]: 'compareShareholdersTransactionHistoryTemplate',
    [widgetTypes.compareShareholdersDRDSPP]: 'compareShareholdersDRDSPPTemplate',
    [widgetTypes.compareShareholdersPayments]: 'compareShareholdersPaymentsTemplate',
    [widgetTypes.compareShareholders1099DIV]: 'compareShareholders1099DIVTemplate',
    [widgetTypes.compareShareholders1099INT]: 'compareShareholders1099INTTemplate',
    [widgetTypes.compareShareholders1099B]: 'compareShareholders1099BTemplate',
    [widgetTypes.compareShareholders1099Misc]: 'compareShareholders1099MiscTemplate',
    [widgetTypes.compareShareholders1042S]: 'compareShareholders1042STemplate'
};
