import shareholderTypes from 'Constants/shareholder-types';
import shareTypes from 'Constants/share-types';
import accountTypes from 'Constants/account-types';
import accountStatuses from 'Constants/account-statuses';
import sortDirections from 'Constants/sort-directions';
import reportTypes from 'Constants/report-types';
import columnsToDisplayOptions from '../../pages/reporting/ReportingShareholderListPage/constants/columnsToDisplayOptions';
import columnNames from 'Constants/shareholders-columns-names';
import { addressStatusMap } from '../../pages/reporting/ReportingShareholderListPage/constants/addressStatusOptions';

export default {
    type: reportTypes.shareholderListTemplate,
    reportTemplateId: null,
    reportName: null,
    form: {
        shareholderType: shareholderTypes.ALL,
        shareType: shareTypes.ALL,
        accountType: accountTypes.ALL,
        accountStatus: accountStatuses.OPEN_ONLY,
        directDeposit: null,
        sharesOwned: {
            checked: false,
            min: null,
            max: null
        },
        dateRange: {
            checked: false,
            startDate: null,
            endDate: null
        },
        dateOfLastContact: {
            checked: false,
            period: null
        },
        addressStatus: addressStatusMap.ALL,
        columnsToDisplay: [...columnsToDisplayOptions.ALL.values.map(column => column.id),
            ...columnsToDisplayOptions.REGISTERED.values.map(column => column.id),
            ...columnsToDisplayOptions.INSTITUTION.values.map(column => column.id)], // all selected
        sortCriteria: {
            sortDirection: sortDirections.DESC,
            sortField: columnNames.POSITION,
            initial: false
        },
        shareholderLocation: {
            country: null,
            state: null,
            showForeign: false,
            zipCode: null
        },
        noOfRecords: null,
        isScheduled: false,
        schedule: {
            scheduleType: null,
            scheduleParam: null
        },
        isLastDayOfMonth: false,
        format: null
    }
};
