import cn from 'classnames';
import { createSelector } from 'reselect';
import _ from 'lodash';

import { MillionChangeFormatter } from 'Components/formatters';
import { mapBarValuesToData } from 'Components/Tables/TableEasy/utils';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';

const checkEmptyChangeValues = createSelector(
    ({ values }) => values,
    values => _.every(values, (v) => _.isNull(v.change))
);

export const getColumnSettings = createSelector(
    ({ primaryColumn }) => primaryColumn,
    checkEmptyChangeValues,
    ({ intl }) => intl,
    ({ hideChangeColumn }) => hideChangeColumn,
    (primaryColumn, isEmptyChangeValues, intl, hideChangeColumn) => {
        const columns = [
            primaryColumn,
            {
                dataKey: 'os',
                title: intl.formatMessage({ id: 'analysis.geographic.os' }),
                columnClassName: 'text-right',
                bodyCellComponent: TotalValueOsCell
            }
        ];

        if (!hideChangeColumn) {
            columns.push({
                dataKey: 'change',
                title: intl.formatMessage({ id: 'analysis.geographic.change' }),
                columnClassName: cn('text-right', {
                    'text-muted': isEmptyChangeValues,
                    'total-hidden': isEmptyChangeValues
                }),
                bodyCellComponent: MillionChangeFormatter
            });
        }

        return columns;
    }
);
export const getData = createSelector(
    ({ values }) => values,
    values => mapBarValuesToData(values, 'os')
);
