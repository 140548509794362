import React from 'react';
import CellWithPercent from './CellWithPercent';
import { PropTypes } from 'prop-types';

export const CellCumulativeOs = (props) => {
    const { additionalData: { isProxyView, isSortByPositionDesc }, value } = props;
    let resultValue = value;

    if (isProxyView && !isSortByPositionDesc) {
        resultValue = null;
    }

    return <CellWithPercent {...props} value={resultValue} />;
};

CellCumulativeOs.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    additionalData: PropTypes.object
};
