import sortDirections from 'Constants/sort-directions';

const columnNames = {
    RUN_DATE: 'runDate',
    DESCRIPTION: 'description',
    FORMAT: 'format',
    STATUS: 'status',
    SIZE: 'size',
    DOWNLOAD: 'download',
    DELETE: 'delete'
};

const columns = [
    {
        columnName: columnNames.RUN_DATE,
        order: 1,
        title: 'reporting.downloads.table.runDate',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.DESCRIPTION,
        order: 2,
        title: 'reporting.downloads.table.description',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.FORMAT,
        order: 3,
        title: 'reporting.downloads.table.format',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.STATUS,
        order: 4,
        title: 'reporting.downloads.table.status',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.SIZE,
        order: 5,
        title: 'reporting.downloads.table.size',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.DOWNLOAD,
        order: 6,
        title: 'reporting.downloads.table.download'
    },
    {
        columnName: columnNames.DELETE,
        order: 7,
        title: 'reporting.downloads.table.delete'
    }
];

export default {
    columnNames,
    columns
};
