import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import dateFormats from 'Constants/date-formats';
import performanceChartTypes from 'Components/PerformanceChart/constants/performance-chart-types';
import colorSchemas from 'Constants/color-schemas';
import periodRanges from 'Constants/period-ranges';
import { visibleValues } from 'Constants/performance-chart';
import { unitsTime } from './constants';

const reverseArray = (array = []) => {
    const result = _.clone(array);

    _.reverse(result);
    return result;
};

export const getPerformanceChartsArray = isShortNames => {
    return [
        {
            id: performanceChartTypes.MOVING_AVG_50,
            label: isShortNames
                ? 'performance.performanceChart.names.short.movingAvrg50'
                : 'performance.performanceChart.names.movingAvrg50'
        },
        {
            id: performanceChartTypes.MOVING_AVG_200,
            label: isShortNames
                ? 'performance.performanceChart.names.short.movingAvrg200'
                : 'performance.performanceChart.names.movingAvrg200'
        }
    ];
};

export const getPerformanceChartData = (widgetDataValues = [], colorScheme = colorSchemas['3']) => {
    const chartData = {};
    const widgetDataKeys = [
        {
            chartType: performanceChartTypes.PRICE,
            dataKey: visibleValues.price
        },
        {
            chartType: performanceChartTypes.MOVING_AVG_50,
            dataKey: visibleValues.movingAverage50d
        },
        {
            chartType: performanceChartTypes.MOVING_AVG_200,
            dataKey: visibleValues.movingAverage200d
        }
    ];
    const sortedValues = reverseArray(widgetDataValues);

    _.forEach(widgetDataKeys, (widgetDataKey, i) => {
        chartData[widgetDataKey.chartType] = {
            data: [],
            color: colorScheme[i % colorScheme.length]
        };
        _.forEach(sortedValues, value => {
            chartData[widgetDataKey.chartType].data.push({
                x: moment.utc(value.date).valueOf(),
                y: value[widgetDataKey.dataKey],
                additionalData: value
            });
        });
    });

    return chartData;
};

export const getPerformanceChartDataSelector = createSelector(
    (widgetDataValues) => widgetDataValues,
    (widgetDataValues, colorScheme) => colorScheme,
    getPerformanceChartData
);

const getPerformanceValues = createSelector(
    (widgetDataValues) => widgetDataValues,
    (widgetDataValues) => {
        const keysOfVisibleValues = _.values(visibleValues);

        return _.flatMap(widgetDataValues, value => {
            const chartVisibleValues = keysOfVisibleValues.map(key => _.get(value, key));

            return chartVisibleValues;
        });
    }
);

export const getPerformanceMinValue = createSelector(
    getPerformanceValues,
    (values) => Math.floor(_.min(values))
);

export const getPerformanceMaxValue = createSelector(
    getPerformanceValues,
    (values) => Math.ceil(_.max(values))
);

export const getVolumeChartDataSelector = createSelector(
    widgetDataValues => widgetDataValues,
    (widgetDataValues = []) => {
        const sortedValues = reverseArray(widgetDataValues);

        return sortedValues.map(value => ({
            x: moment.utc(value.date).valueOf(),
            y: value.volume
        }));
    }
);

const getVolumeValues = createSelector(
    (widgetDataValues) => widgetDataValues,
    (values) => values.map(v => v.volume)
);

export const getVolumeMinValue = createSelector(
    getVolumeValues,
    (values) => Math.floor(_.min(values))
);

export const getVolumeMaxValue = createSelector(
    getVolumeValues,
    (values) => _.max(values)
);

const getDateValues = createSelector(
    (widgetDataValues) => widgetDataValues,
    (values) => values.map(v => v.date)
);

export const getDateMinValue = createSelector(
    getDateValues,
    (values) => moment.utc(_.min(values)).valueOf()
);

export const getDateMaxValue = createSelector(
    getDateValues,
    (values) => moment.utc(_.max(values)).valueOf()
);

export const getChartDateFormatter = (period) => {
    let xLabelDateFormat;

    switch (period) {
        case (periodRanges.FIVE_DAYS):
        case (periodRanges.TEN_DAYS):
        case (periodRanges.ONE_MONTH):
        case (periodRanges.THREE_MONTH):
        case (periodRanges.SIX_MONTH):
        case (periodRanges.YTD):
            xLabelDateFormat = dateFormats.CHART_DAY_DATE_FORMAT;
            break;
        case (periodRanges.ONE_YEAR):
        case (periodRanges.TWO_YEARS):
        case (periodRanges.FIVE_YEARS):
            xLabelDateFormat = dateFormats.CHART_MONTH_DATE_FORMAT;
            break;
        default: {
            xLabelDateFormat = dateFormats.default;
        }
    }

    return function xLabelFormatter() {
        return moment.utc(this.value).format(xLabelDateFormat);
    };
};

// for small period of time added tick for each multiple value
// else ticks are not evenly by reason of gaps weekend dates
const getPositionsWithMultiplicity = (values, multiplicity) => values
    .filter((value, index) => !(index % multiplicity))
    .map(value => new Date(value.date).getTime());

const getPositionsWithInterval = (max, count, amountDateTimeTicks, unitTime) => {
    const positions = [];

    for (
        let tick = max, i = 0;
        i < amountDateTimeTicks;
        tick = moment(tick).subtract(count, unitTime).valueOf(), i++
    ) {
        positions.unshift(tick);
    }

    return positions;
};

export const getDateTickPositions = (values, period, amountDateTimeTicks, isSmallWidget) => {
    switch (period) {
        case (periodRanges.FIVE_DAYS):
            return getPositionsWithMultiplicity(values, 1);
        case (periodRanges.TEN_DAYS):
            return getPositionsWithMultiplicity(values, 2);
        case (periodRanges.ONE_YEAR):
            return getPositionsWithInterval(
                getDateMaxValue(values),
                isSmallWidget ? 2 : 1,
                isSmallWidget ? 6 : 12,
                unitsTime.MONTH
            );

        default: {
            const multiplicity = Math.floor(values.length / amountDateTimeTicks);

            return getPositionsWithMultiplicity(values, multiplicity <= 1 ? 2 : multiplicity);
        }
    }
};

export const getPerformanceTickPositions = (min, max, tickAmount) => {
    const intervalCount = tickAmount - 1;
    const stepSize = (max - min) / intervalCount;
    const positions = [];

    for (let i = 0; i <= intervalCount; i++) {
        positions.push(min + stepSize * i);
    }

    return positions;
};
