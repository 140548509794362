import PropTypes from 'prop-types';
import React from 'react';
import Highcharts from 'highcharts';
import ReactHighcharts from 'highcharts-react-official';
import { AutoSizer } from 'react-virtualized';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import defaultHighchartsConfig from 'Constants/default-highcharts-config';
import colorSchemas from 'Constants/color-schemas';
import { tooltipFormatter, labelFormatter } from './utils';
import { getPosition } from 'Utils/charts';

const volumeChartColorScheme = '4';

const VolumeChart = ({ data, customConfig, intl }) => {
    const defaultConfig = {
        chart: {
            plotBorderWidth: 1,
            animation: false,
            spacingLeft: 0,
            spacingRight: 35
        },
        title: {
            text: null
        },
        colors: colorSchemas[volumeChartColorScheme],
        tooltip: {
            useHTML: true,
            formatter: function getTooltip() {
                return tooltipFormatter(this, intl);
            },
            positioner: getPosition,
            hideDelay: 0,
            animation: false,
            borderRadius: 0,
            borderWidth: 0,
            padding: 0,
            shadow: false,
            enabled: true,
            backgroundColor: 'none',
            shared: true
        },
        xAxis: {
            type: 'datetime',
            gridLineWidth: 1,
            startOnTick: false,
            endOnTick: false,
            minPadding: 0.03,
            maxPadding: 0.03,
            labels: {
                padding: 17 // default is 5
            }
        },
        yAxis: {
            title: {
                enabled: false
            },
            labels: {
                distance: 0,
                formatter: function getLabel() {
                    return labelFormatter(this.value, intl);
                }
            },
            opposite: true
        },
        series: [{
            type: 'column',
            data,
            animation: false,
            pointPlacement: 'on',
            maxPointWidth: 60,
            turboThreshold: 0,
            dataGrouping: {
                // prevent grouping volume values
                // https://www.highcharts.com/docs/advanced-chart-features/data-grouping
                enabled: false
            }
        }]
    };

    const config = _.merge({}, defaultHighchartsConfig, defaultConfig, customConfig);

    return (
        <AutoSizer>
            {({ height, width }) => {
                config.chart.height = height;
                config.chart.width = width;

                return (
                    <ReactHighcharts
                        highcharts={Highcharts}
                        options={config}
                        containerProps={{
                            style: { height, width }
                        }}/>
                );
            }}
        </AutoSizer>
    );
};

VolumeChart.propTypes = {
    data: PropTypes.array,
    customConfig: PropTypes.object,
    intl: intlShape
};

export default injectIntl(VolumeChart);
