import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import { withState } from '@shakacode/recompose';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import CorporateShareholdersContent from './components/CorporateShareholdersContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import PeriodActionPanel from 'Components/widgets/_commonComponents/PeriodActionPanel';
import FooterWithInfo from 'Components/widgets/_commonComponents/FooterWithInfo';
import { getDates, getSelectedDate, getFilteredData } from './selectors';
import widgetModes from 'Constants/widget-modes';
import messageType from 'Constants/message-types';
import { getWidgetFooterPropsWithSignClass } from 'Components/widgets/utils';

import './CorporateShareholders.scss';

@withState(
    'selectedDate',
    'onMonthChange'
)
@injectIntl
class CorporateShareholders extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetTitle = props.intl.formatMessage({ id: 'corporate.shareholders.title' });
        this.widgetComponents = {
            WidgetContent: CorporateShareholdersContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetActionPanel: PeriodActionPanel,
            WidgetFooter: FooterWithInfo
        };
    }

    getWidgetFooterProps = (widgetData) => ({
        rightSide: [
            {
                messageKey: 'widgets.message.includesCeDeCo',
                type: messageType.INCLUDES_CEDECO,
                hidden: !this.showCeDeCoMessage(widgetData)
            }
        ]
    });

    showCeDeCoMessage = widgetData => {
        const isChartMode = _.get(this.props, 'widgetSettings.mode') === widgetModes.CHART;
        const widgetDataValues = _.get(widgetData, 'data');

        return isChartMode && _.some(widgetDataValues, 'includesCeDeCo');
    };

    render() {
        const dates = getDates(this.props);
        const selectedDate = getSelectedDate(this.props);
        const data = getFilteredData(this.props);
        const widgetFooterProps = getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(data));
        const widgetTitleTooltip = _.get(this.props, 'widgetSettings.mode') === widgetModes.TABLE
            ? <FormattedHTMLMessage id='corporate.shareholders.tooltip.title.tableView'/>
            : <FormattedHTMLMessage id='corporate.shareholders.tooltip.title.chartView'/>;
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetTitleTooltip,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            },
            widgetFooterProps,
            widgetNoData: !_.get(data, 'data.length'),
            data,
            dates,
            selectedDate
        };

        return (
            <Widget
                {...props}
                className='widget-corporate-shareholders'
                components={this.widgetComponents}/>
        );
    }
}

export default CorporateShareholders;
