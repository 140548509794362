import React from 'react';
import _ from 'lodash';
import { createSelector } from 'reselect';

import columnsDefinitions, { proxyColumnDefinitions } from '../../constants/columnsDefinitions';
import {
    filterByAvailableFor,
    getCurrentViewId,
    getCustomComponent
} from '../../utils';
import columnWidth from '../../constants/columnsWidth';
import shareholderTypes from 'Constants/shareholder-types';
import featureTypes from 'Constants/feature-types';
import Expander from './Expander';
import columnTypes from '../../constants/shareholder-column-types';
import { getColumnsMinWidth } from 'Utils/utils';
import shareholdersDefaultViews from '../../constants/defaultViews';

const getTableColumns = createSelector(
    ({ sortedValues }) => sortedValues,
    ({ currentView }) => currentView,
    ({ userProductSources }) => userProductSources,
    ({ userFeatures }) => userFeatures,
    ({ intl }) => intl,
    ({ isPrint }) => isPrint,
    ({ widgetSettings }) => getCurrentViewId(widgetSettings),
    // eslint-disable-next-line
    (sortedValues, currentView, userProductSources, userFeatures, intl, isPrint, currentViewId) => {
        const definitions = currentViewId === shareholdersDefaultViews.PROXY ? proxyColumnDefinitions : columnsDefinitions;
        const columns = filterByAvailableFor(definitions, userProductSources, userFeatures);
        const { formatMessage } = intl;

        return _.sortBy(columns, ['order'])
            .map(column => {
                const Components = getCustomComponent(column.columnType, currentViewId);

                return {
                    Header: () => (
                        <Components.customHeadingComponent
                            title={formatMessage({ id: column.displayName })}/>
                    ),
                    // eslint-disable-next-line react/no-multi-comp
                    Cell: (props) => (
                        <Components.customComponent
                            // eslint-disable-next-line react/prop-types
                            value={props.value}/>
                    ),
                    accessor: column.columnType,
                    minWidth: isPrint
                        ? columnWidth[column.columnType] || columnWidth.default
                        : getColumnsMinWidth(sortedValues, column, columnWidth[column.columnType] || columnWidth.default, intl),
                    show: currentView.values.indexOf(column.columnName) >= 0
                };
            });
    }
);

export const getFundTableColumns = createSelector(
    getTableColumns,
    (columns = []) => {
        // replacing shareholderName column with fundName
        const fundsColumns = columns.map((column) => {
            if (column.accessor === columnTypes.SHAREHOLDER_NAME) {
                return {
                    ...column,
                    accessor: columnTypes.FUND_NAME
                };
            }
            return column;
        });

        return fundsColumns;
    }
);

export const getSubTableColumns = createSelector(
    getTableColumns,
    (columns) => columns
);

const getExpander = createSelector(
    ({ expandedFundsShareholderIds }) => expandedFundsShareholderIds,
    ({ userFeatures }) => userFeatures,
    ({ widgetSettings }) => getCurrentViewId(widgetSettings),
    (expandedShareholderIds, userFeatures, currentViewId) => {
        const isProxyView = currentViewId === shareholdersDefaultViews.PROXY;
        const showExpander = userFeatures[featureTypes.FUNDS_VIEW] || isProxyView;

        return {
            expander: true,
            sortable: false,
            headerClassName: 'expander',
            width: columnWidth.expander,
            // eslint-disable-next-line react/no-multi-comp
            Expander: (props) => (<Expander
                {...props}
                expandedShareholderIds={expandedShareholderIds}
                handleIconClick={handleIconClick}
                showExpander={showExpander}/>)
        };
    }
);

export const getTableColumnsWithExpander = createSelector(
    getTableColumns,
    getExpander,
    (tableColumns, expander) => {
        return [expander, ...tableColumns];
    }
);

const handleIconClick = (shareholderType, isFundExists, proxy) => {
    if (shareholderType.toUpperCase() !== shareholderTypes.INSTITUTION || !isFundExists) {
        proxy.preventDefault();
        proxy.stopPropagation();
    }
};

export const getAvailableDefaultViewId = createSelector(
    ({ widgetSettings }) => widgetSettings,
    ({ userFeatures }) => userFeatures,
    (widgetSettings, userFeatures) => {
        const { views: { defaultViews = {} } = {} } = widgetSettings;

        return _.findKey(defaultViews, view => userFeatures[view.featureType]);
    }
);
