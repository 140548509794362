import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CurrentPositionView from '../CurrentPositionView';
import AddressesView from '../AddressesView';
import { DateFormatter, NullableFormatter } from 'Components/formatters';

class RegisteredShareholderInformationContent extends Component {
    static propTypes = {
        widgetData: PropTypes.shape({
            shareholderAcct: PropTypes.string,
            shareholderStatus: PropTypes.string,
            businessDate: PropTypes.string,
            addresses: PropTypes.array,
            currentPosition: PropTypes.object,
            email: PropTypes.string
        }),
        changeOtherAddressesStatus: PropTypes.func,
        getRef: PropTypes.func
    };

    render() {
        const {
            widgetData: {
                shareholderAcct,
                shareholderStatus,
                taxCategory,
                ssnTaxId,
                businessDate,
                addresses,
                currentPosition,
                email,
                badAddress,
                lastCalendarContactDate
            } = {},
            changeOtherAddressesStatus,
            getRef
        } = this.props;

        return (
            <div className='widget-content' ref={com => getRef(com)}>
                <div className='widget-title-container'>
                    <div className='header-info'>
                        <div className='acct-number-container'>
                            <span className='info'><FormattedMessage id='registeredOverview.acctNo'/> </span>
                            <NullableFormatter
                                value={shareholderAcct}
                                className='acct-number-value'/>
                        </div>
                        <div className='status-container'>
                            <span className='info'><FormattedMessage id='registeredOverview.status'/>: </span>
                            <span className='status-value'>{shareholderStatus}</span>
                        </div>
                    </div>
                    {!DEMO_MODE && (
                        <div className='tax-category-container'>
                            <span className='title'><FormattedMessage id='registered.shareholder.information.tax_category'/>: </span>
                            <NullableFormatter value={taxCategory} className='tax-category-value break-word'/>
                        </div>
                    )}
                    <div className='ssn-tax-container'>
                        <span className='title'><FormattedMessage id='registered.shareholder.information.ssn_tax_id'/>: </span>
                        <NullableFormatter value={ssnTaxId} className='tax-category-value break-word'/>
                    </div>
                </div>
                <AddressesView
                    data={addresses}
                    changeOtherAddressesStatus={changeOtherAddressesStatus}
                    badAddress={badAddress}/>
                <div className='email-container'>
                    <FormattedMessage id='registered.shareholder.information.email'>
                        {message => <span className='title'>{message}: </span>}
                    </FormattedMessage>
                    <NullableFormatter value={email}>
                        {<a href={`mailto:${email}`}>{email}</a>}
                    </NullableFormatter>
                </div>
                <div className='last-contact-date-container'>
                    <span className='title'><FormattedHTMLMessage id='registered.shareholder.information.lastCalendarContactDate'/>:</span>
                    <DateFormatter value={lastCalendarContactDate}/>
                </div>
                <h3>
                    <FormattedMessage id='registered.shareholder.information.current_position'/>
                </h3>
                <CurrentPositionView data={currentPosition}/>
                <div className='message-note'>
                    <FormattedMessage id='registered.shareholder.information.date_message'/>&nbsp;
                    <DateFormatter className='widget-date' value={businessDate}/>
                </div>
                {!DEMO_MODE && (<div className='message-note'>
                    <FormattedMessage id='registered.shareholder.information.delays_note'/>
                </div>)}
                <div className='message-note'>
                    <FormattedMessage id='registered.shareholder.information.drp_note'/>
                </div>
            </div>
        );
    }
}

export default RegisteredShareholderInformationContent;
