import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { compose } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';

import ExportClassicAsOfDateReportModal from './ExportClassicAsOfDateReportModal';
import reportTypes from './constants/classic-as-of-date-report-types';
import { inputDateValidation } from 'Components/InputDatePicker';

const FORM_NAME = 'exportClassicAsOfDateReportForm';
const INITIAL_VALUES = {
    reportType: reportTypes.FULL_REPORT
};
const mapStateToProps = (state) => ({
    formValues: getFormValues(FORM_NAME)(state)
});

export default compose(
    injectIntl,
    connect(mapStateToProps),
    reduxForm({
        form: FORM_NAME,
        initialValues: INITIAL_VALUES,
        validate: values => ({
            asOfDate: inputDateValidation({ date: values.asOfDate },
                { isCurrentDateAvailable: false }).date
        })
    })
)(ExportClassicAsOfDateReportModal);
