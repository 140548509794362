import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapProps } from '@shakacode/recompose';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';

import Widget from 'Components/widgets/Widget';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import columnDefinitions from './constants/columnDefinitions';
import { showCommonColumnsManagerModal } from 'State/modal';
import AuthorizedUserListHeader from './components/AuthorizedUserListHeader';
import AuthorizedUserListContent from './components/AuthorizedUserListContent';
import AuthorizedUserListControlPanel from './components/AuthorizedUserListControlPanel';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import { runReport, showExportWidgetModal } from 'State/exportReports';
import reportTypes from 'Constants/report-types';
import { getSecuritiesByIssuerId } from 'State/securities/selectors';
import { getCurrentSecuritySelector } from 'State/user';
import featureTypes from 'Constants/feature-types';
import exportReportFormats from 'Constants/export-report-formats';
import { isSameSelectedSecurities } from 'Utils/securities';

import './AuthorizedUserList.scss';

const MAX_SELECTED_SECURITIES_COUNT = 50;

const mapStateToProps = state => ({
    pageInfo: dynamicPageSelectors.getDynamicPageInfoSelector(state),
    getAvailableSecuritiesByIssuerId: getSecuritiesByIssuerId(state),
    currentSecurity: getCurrentSecuritySelector(state)
});
const mapDispatchToProps = {
    showCommonColumnsManagerModal,
    showExportWidgetModal,
    runReport
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@mapProps(props => ({
    ...props,
    columns: columnDefinitions.columns
}))
class AuthorizedUserList extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        showCommonColumnsManagerModal: PropTypes.func,
        showExportWidgetModal: PropTypes.func,
        intl: intlShape,
        columns: PropTypes.array
    };

    constructor(props) {
        super(props);

        const {
            currentSecurity,
            getAvailableSecuritiesByIssuerId
        } = props;

        this.state = {
            securitiesOptions: {
                currentSecurity: {
                    ...currentSecurity,
                    label: currentSecurity.name,
                    value: currentSecurity.id.toString()
                },
                availableSecurities: getAvailableSecuritiesByIssuerId(currentSecurity.issuerId)
                    .filter(s => s.features[featureTypes.AUTHORIZED_USER_LIST]),
                maxSelectedSecuritiesCount: MAX_SELECTED_SECURITIES_COUNT
            }
        };

        this.dataParams = { securities: [currentSecurity.id] };
        this.widgetComponents = {
            WidgetControlPanel: AuthorizedUserListControlPanel,
            WidgetContent: AuthorizedUserListContent,
            WidgetHeader: AuthorizedUserListHeader
        };

        this.widgetEvents = {
            handleUpdateWidgetParams: this.handleUpdateWidgetParams
        };
    }

    handleUpdateWidgetParams = (params) => {
        this.dataParams = { ...this.dataParams, ...params };
        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, ...params });
        this.props.onUpdateWidgetData({ ...this.dataParams });
    };

    handleExport = () => {
        const { widgetSettings: { sortCriteria }, columns } = this.props;
        const securities = this.dataParams.securities;
        const exportParams = {
            sortCriteria: {
                sortDirection: sortCriteria.direction,
                sortField: sortCriteria.field,
                initial: sortCriteria.initial
            },
            excludeRM: !!this.dataParams.excludeRM,
            columnsToDisplay: columns.map(column => column.columnName),
            securities
        };

        if (_.get(securities, 'length', 0) > 1) {
            this.props.runReport(
                reportTypes.authorizedUserListTemplate,
                null,
                exportReportFormats.EXCEL,
                exportParams
            );
        } else {
            this.props.showExportWidgetModal({
                reportTemplateType: reportTypes.authorizedUserListTemplate,
                exportParams,
                modalViewParams: {
                    showExcelLimit: true
                }
            });
        }
    };

    handleSecuritiesChange = selectedSecurities => {
        const prevSelectedSecurityIds = _.get(this.dataParams, 'securities', []);
        const selectedSecurityIds = selectedSecurities.map(s => s.id);

        if (!isSameSelectedSecurities(selectedSecurityIds, prevSelectedSecurityIds)) {
            this.dataParams = {
                ...this.dataParams,
                securities: selectedSecurityIds
            };
            this.props.onUpdateWidgetData({ ...this.dataParams });
        }
    };

    handleChangeExcludeRM = (event) => {
        const excludeRM = event.target.checked;

        this.dataParams = { ...this.dataParams, excludeRM };

        this.props.onUpdateWidgetData({ ...this.dataParams });
    }

    render() {
        const { widgetData } = this.props;
        const isPrimaryRMExists = !!_.findKey(_.get(widgetData, 'primaryRM'), p => !!p);
        const props = {
            ...this.props,
            widgetControlPanelSettings: {
                onColumnsManagerClick: this.handleColumnsManager,
                onExport: this.handleExport,
                onPrint: this.handlePrint
            },
            widgetNoData: !(isPrimaryRMExists || _.get(this.props, 'widgetData.tableData.length')),
            isPrimaryRMExists,
            securitiesOptions: this.state.securitiesOptions,
            onSecuritiesChange: this.handleSecuritiesChange,
            onChangeExcludeRM: this.handleChangeExcludeRM
        };

        return (
            <Widget
                {...props}
                className='authorized-users-widget'
                components={this.widgetComponents}
                events={this.widgetEvents}/>
        );
    }
}

export default AuthorizedUserList;
