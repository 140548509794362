import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { NullableFormatterWithIntl } from './NullableFormatter';
import formatterOptions from 'Constants/formatter-options';
import { changeFormatter, symbolFormatter, thresholdFormatter } from './formatters';
import { LabelWithTooltip } from 'Components/formatters';

const PercentFormatter = (props) => {
    const {
        decimalPoints,
        withPercent,
        negativeWithBrackets,
        withTitle,
        withThreshold,
        threshold,
        className,
        titleValue
    } = props;

    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, negClass, intl }) => {
                let formattedValue = value;
                let origFormattedValue = value;
                let title = null;
                let isValueThresholded = false;

                if (withThreshold) {
                    formattedValue = thresholdFormatter(formattedValue, threshold);
                    isValueThresholded = formattedValue !== origFormattedValue;
                }

                formattedValue = changeFormatter(intl, formattedValue, {
                    decimalPoints,
                    negativeWithBrackets
                });
                formattedValue = withPercent ? symbolFormatter(formattedValue, '%', true) : formattedValue;
                formattedValue = isValueThresholded ? `<${formattedValue}` : formattedValue;

                if (isValueThresholded || withTitle) {
                    origFormattedValue = intl.formatNumber(origFormattedValue, {
                        minimumFractionDigits: decimalPoints,
                        maximumFractionDigits: 10
                    });
                    origFormattedValue = withPercent ? symbolFormatter(origFormattedValue, '%', true) : origFormattedValue;
                    title = titleValue || origFormattedValue;
                }

                return (
                    <LabelWithTooltip
                        specialTitle={title}
                        value={formattedValue}
                        className={cn(className, negClass)}/>
                );
            }}
        </NullableFormatterWithIntl>
    );
};

PercentFormatter.propTypes = {
    value: PropTypes.any,
    decimalPoints: PropTypes.number,
    withPercent: PropTypes.bool,
    negativeWithBrackets: PropTypes.bool,
    withTitle: PropTypes.bool,
    withThreshold: PropTypes.bool,
    className: PropTypes.string,
    threshold: PropTypes.number,
    titleValue: PropTypes.number
};

PercentFormatter.defaultProps = {
    decimalPoints: formatterOptions.DEFAULT_PERCENT_DECIMAL_POINTS,
    withPercent: false,
    withThreshold: true,
    threshold: formatterOptions.THRESHOLD
};

export default PercentFormatter;
