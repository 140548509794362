import PropTypes from 'prop-types';
import React from 'react';

import FormWarning from '../FormWarning';
import FormError from '../FormError';

import './Textarea.scss';

const Textarea = (props) => {
    const { resize = 'none', label, labelType = 'vertical', placeholder, cols = 40, rows = 5, maxLength } = props;
    const labelClass = `textarea-label ${labelType}`;
    const validationClass = `validation-container ${labelType}`;
    const { input, meta: { touched, error, warning } } = props; // special props from ReduxForm

    const warnMessage = <FormWarning warning={warning}/>;
    const errorMessage = <FormError error={error}/>;

    return (
        <div className='flexbox'>
            <label className='textarea-component'>
                <span className={labelClass}>{label}</span>
                <textarea
                    {...input}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    style={{ resize }}
                    rows={rows}
                    cols={cols}/>
            </label>
            <div className={validationClass}>
                {touched && ((error && errorMessage) || (warning && warnMessage))}
            </div>
        </div>
    );
};

Textarea.propTypes = {
    resize: PropTypes.string,
    label: PropTypes.string,
    labelType: PropTypes.string,
    placeholder: PropTypes.string,
    input: PropTypes.object,
    meta: PropTypes.object,
    cols: PropTypes.number,
    rows: PropTypes.number,
    maxLength: PropTypes.number
};

export default Textarea;
