import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import apiRoutes from 'Constants/api-routes';
import { downloadFile } from 'Utils/utils';
import { postMessageTypeMap } from 'Constants/post-message-types';
import { notifyError } from 'State/notifier/actions';

const downloadByGuid = downloadUrl => (dispatch, documentGuid, params = {}) => {
    if (documentGuid) {
        const search = new URLSearchParams('');

        _.forOwn(params, (value, key) => {
            if (value === '' || _.isNil(value)) {
                search.delete(key); // remove empty value from search query
            } else {
                search.set(key, value);
            }
        });

        const searchString = !!search.toString() ? `?${search.toString()}` : '';

        downloadFile(`${downloadUrl}/${documentGuid}${searchString}`, (err) => {
            const messageType = _.get(err, 'messageType');

            if (messageType === postMessageTypeMap.FILE_NOT_FOUND) {
                dispatch(notifyError(
                    null,
                    <FormattedMessage id='downloadDocument.notifier.fileNotFound'/>
                ));
            } else {
                dispatch(notifyError(
                    null,
                    <FormattedMessage id='error.requestFailed'/>
                ));
            }
        });
    } else {
        throw new Error('Document guid is empty');
    }
};

const downloadDocumentByGuid = downloadByGuid(apiRoutes.downloadDocumentUrl);

export {
    downloadDocumentByGuid
};
