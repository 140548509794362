import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes } from 'redux-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { InputDateRangePickerField, inputDateRangeValidation } from 'Components/InputDateRangePicker';
import RegisteredPagination from '../RegisteredPagination';

import './RegisteredFilterPanel.scss';

const FORM_NAME = 'registeredDataFilterForm';
const getFormValuesSelector = getFormValues(FORM_NAME);
const mapStateToProps = state => ({
    formValues: getFormValuesSelector(state)
});


@connect(mapStateToProps)
@reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate: values => ({
        dateRange: inputDateRangeValidation({ ...values.dateRange, subtractDays: 1 },
            { isRequired: false })
    })
})
class RegisteredFilterPanel extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        intl: intlShape,
        onChangeDateRange: PropTypes.func,
        onChangeCurrentPage: PropTypes.func,
        infoMessage: PropTypes.element,
        disableDataRangePicker: PropTypes.bool
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = props;
        const dateRangeMaxDate = moment().subtract(1, 'days');

        this.dateRangeOptions = {
            startDateOptions: {
                label: formatMessage({ id: 'datePicker.from' }),
                name: 'dateRange.startDate',
                maxDate: dateRangeMaxDate
            },
            endDateOptions: {
                label: formatMessage({ id: 'datePicker.to' }),
                name: 'dateRange.endDate',
                maxDate: dateRangeMaxDate
            },
            formName: _.get(props, 'form')
        };
    }

    componentDidMount() {
        const { initialize } = this.props;

        initialize({
            dateRange: ''
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const prevDateRange = _.get(this.props.widgetData, 'filterCriteria.dateRange');
        const nextDateRange = _.get(nextProps.widgetData, 'filterCriteria.dateRange');

        if (prevDateRange && nextDateRange && prevDateRange.startDate !== nextDateRange.startDate) {
            this.props.change('dateRange.startDate', nextDateRange.startDate);
        }

        if (prevDateRange && nextDateRange && prevDateRange.endDate !== nextDateRange.endDate) {
            this.props.change('dateRange.endDate', nextDateRange.endDate);
        }
    }

    handleDateChange = props => {
        this.props.onChangeDateRange(props).then(() => {
            const dateRangeFromSelector = _.get(this.props.formValues, 'dateRange');
            const dateRange = _.get(this.props.widgetData, 'filterCriteria.dateRange');

            if (dateRangeFromSelector.startDate && !dateRangeFromSelector.endDate) {
                this.props.change('dateRange.endDate', dateRange.endDate);
            }
        });
    };

    render() {
        const {
            handleSubmit,
            isFetching,
            valid,
            disableDateRangePicker
        } = this.props;

        return (
            <div className='registered-filter-panel-wrapper'>
                <div className='registered-filter-panel'>
                    {<form onSubmit={handleSubmit(this.handleDateChange)}>
                        <div className='date-range-wrapper'>
                            <InputDateRangePickerField
                                disabled={disableDateRangePicker}
                                dateRangeOptions={this.dateRangeOptions}/>
                            <button type='submit' className='btn get-data-btn' disabled={!valid || isFetching || disableDateRangePicker}>Get</button>
                        </div>
                    </form>}
                    <RegisteredPagination
                        {...this.props}
                        onChangeCurrentPage={this.props.onChangeCurrentPage}/>
                </div>
            </div>
        );
    }
}

export default RegisteredFilterPanel;
