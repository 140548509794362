import columnNames from './constants/top-25-holdings-column-names';
import * as cellComponents from 'Components/widgets/_commonComponents/TableCellComponents';

export const getTop25HoldingsCustomComponent = (columnName) => {
    switch (columnName) {
        case columnNames.MKT_VAL:
        case columnNames.MKT_VAL_CHANGE:
            return {
                customComponent: cellComponents.CellWithMktValue,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.POSITION:
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.POSITION_CHANGE:
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellWithPositionChange
            };
        case columnNames.PORTFOLIO_PERCENT:
        case columnNames.CHANGE_PERCENT:
        case columnNames.OS_PERCENT:
        case columnNames.OS_CHANGE_PERCENT:
            return {
                customComponent: cellComponents.CellWithPercent,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.REPORT_DATE:
            return {
                customComponent: cellComponents.CellWithDate,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.HOLDING_NAME:
            return {
                customComponent: cellComponents.CellWithHolding,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.SECTOR: {
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        default:
            return {};
    }
};

export const getTop25HoldingsTdProps = (columnType, rowData, currentSecurityId) => {
    switch (columnType) {
        case columnNames.POSITION_CHANGE:
            return {
                className: 'cell-position-change'
            };
        case columnNames.OS_CHANGE_PERCENT:
            return {
                className: 'cell-os-change'
            };
        case columnNames.HOLDING_NAME:
            return {
                additionalData: {
                    currentSecurityId
                },
                rowData
            };
        case columnNames.MKT_VAL:
        case columnNames.MKT_VAL_CHANGE:
            return {
                decimalPoints: 0
            };
        default:
            return {};
    }
};

export const getTop25HoldingsTheadThProps = (column, period, disabledSelector, handleOnChangePeriod) => {
    if (column.id === columnNames.POSITION_CHANGE) {
        return {
            handlers: {
                handleOnChangePeriod
            },
            additionalData: {
                period,
                disabledSelector
            }
        };
    }
    return {};
};
