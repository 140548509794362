import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import { commonWidgetPropTypes, commonWidgetActions } from '../../../utils';
import WidgetPrint from '../../../Widget/components/WidgetPrint';
import Icon from 'Components/Icon';

export default class ShareRangeAnalysisResultsControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        widgetControlPanelSettings: PropTypes.shape({
            onPrint: PropTypes.func,
            onExport: PropTypes.func,
            onColumnsManagerClick: PropTypes.func
        })
    };

    render() {
        const { widgetControlPanelSettings, widgetNoData, widgetType } = this.props;
        const { onExport, onColumnsManagerClick } = widgetControlPanelSettings;
        const exportIconClass = cn('font-icon font-icon-download', { inactive: widgetNoData });

        return (
            <div className='widget-control-panel'>
                <WidgetPrint
                    className='action-icon'
                    widgetType={widgetType}/>
                <Icon
                    className={exportIconClass}
                    onClick={!widgetNoData ? onExport : null}/>
                <Icon
                    className='font-icon font-icon-view-three-columns'
                    onClick={onColumnsManagerClick}/>
            </div>
        );
    }
}
