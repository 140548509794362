import PropTypes from 'prop-types';

export const analysisShareholderShape = PropTypes.shape({
    shareholderId: PropTypes.string.isRequired,
    shareholderName: PropTypes.string,
    shareholderType: PropTypes.string.isRequired,
    notTop100: PropTypes.bool
});

export const operationTypes = {
    GET_HISTORICAL_ANALYSIS_SHAREHOLDER_DATA: 'GET_HISTORICAL_ANALYSIS_SHAREHOLDER_DATA',
    GET_PEER_ANALYSIS_SHAREHOLDER_DATA: 'GET_PEER_ANALYSIS_SHAREHOLDER_DATA',
    FIND_ANALYSIS_SHAREHOLDERS: 'FIND_ANALYSIS_SHAREHOLDERS'
};
