import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Spinner from 'Components/Spinner';
import {
    getIsFetchingUsersSelector,
    getUsersSelector,
    getShareReportIsFetching
} from 'State/savedReportTemplates/selectors';
import { getReportTemplateAvailableUsers } from 'State/savedReportTemplates/actions';
import { getFullName } from 'Utils/utils';
import ShareReportUserTemplateList from './ShareReportUserTemplateList';

import './ShareReportTemplateModal.scss';

const mapStateToProps = (state) => ({
    isFetching: getIsFetchingUsersSelector(state),
    userList: getUsersSelector(state),
    shareIsFetching: getShareReportIsFetching(state)
});

const mapDispatchToProps = {
    getReportTemplateAvailableUsers
};

@connect(mapStateToProps, mapDispatchToProps)
class ShareReportTemplateModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func,
        getReportTemplateAvailableUsers: PropTypes.func,
        isFetching: PropTypes.bool,
        userList: PropTypes.array,
        onSuccess: PropTypes.func,
        shareIsFetching: PropTypes.bool,
        report: PropTypes.object.isRequired
    }

    state = {
        selectedIds: []
    }

    componentDidMount() {
        const { report } = this.props;

        this.props.getReportTemplateAvailableUsers(report.reportTemplateId);
    }

    handleValueChange = (id, checked) => {
        if (checked) {
            this.setState(state => ({
                ...state,
                selectedIds: [...state.selectedIds, id]
            }));
        } else {
            this.setState(state => ({
                ...state,
                selectedIds: state.selectedIds.filter(selectedId => selectedId !== id)
            }));
        }
    }

    handleSave = () => {
        const { onSuccess, hideModal } = this.props;
        const { selectedIds } = this.state;

        onSuccess(selectedIds).then(() => {
            hideModal();
        });
    }

    normalizeOptions = (options) => {
        return options.map(option => ({
            id: option.id,
            label: getFullName(option.firstName, option.lastName)
        }));
    }

    render() {
        const { selectedIds } = this.state;
        const { hideModal, userList, isFetching, shareIsFetching } = this.props;

        const saveIsDisabled = selectedIds.length < 1 || shareIsFetching;

        return (
            <div className='share-report-template'>
                <div className='share-report-template__message-note'>
                    <FormattedMessage id='modals.shareReportTemplate.messageNote'/>
                    (<span className='color-primary'>{selectedIds.length} </span>
                    <FormattedMessage id='modals.shareReportTemplate.of' values={{ total: userList.length }}/>):
                </div>
                {isFetching
                    ? <Spinner isFetching/>
                    : <ShareReportUserTemplateList
                        values={this.normalizeOptions(userList)}
                        selectValueIds={selectedIds}
                        onValueChange={this.handleValueChange}
                        position='block'
                        className='checkbox-list'/>
                }
                <div className='modal-actions'>
                    <button
                        className='btn btn-large'
                        type='button'
                        onClick={this.handleSave}
                        disabled={saveIsDisabled}>
                        <FormattedMessage id='common.save'/>
                    </button>
                    <button
                        type='button'
                        onClick={hideModal}
                        className='btn btn-large'>
                        <FormattedMessage id='common.cancel'/>
                    </button>
                </div>
            </div>
        );
    }
}

export { ShareReportTemplateModal };
