import './AccessDeniedPage.scss';

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Logo from '../../components/Logo';
import PropTypes from 'prop-types';

class AccessDeniedPage extends Component {
    static propTypes = {
        ...Component.propTypes,
        history: PropTypes.object.isRequired
    };

    handleGoBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <div className='access-denied-page'>
                <div className='access-denied-logo'>
                    <Logo/>
                </div>
                <div className='access-denied-title'>
                    <FormattedMessage id='error.accessDenied.title'/>
                </div>
                <div className='access-denied-text'>
                    <FormattedMessage id='error.accessDenied.message'/>
                </div>
                <div className='access-denied-action'>
                    <button type='button' className='btn' onClick={this.handleGoBack}>
                        <FormattedMessage id='error.button.goBack'/>
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(AccessDeniedPage);
