import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import LinkEx from 'Components/LinkEx';
import ErrorsDetails from 'Components/ErrorsDetails';
import logsLocalStorage from 'Context/logsLocalStorage';
import pageRoutes from 'Constants/page-routes';
import './ErrorsLogsPage.scss';

class ErrorsLogsPage extends React.Component {
    static propTypes = {
        history: PropTypes.object
    };

    UNSAFE_componentWillMount() {
        if (!window.SHOW_DETAILS_ERROR) {
            this.props.history.replace(`${pageRoutes.error}/404`);
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const hasLogs = !!logsLocalStorage.getLogItems().length;

        return (
            <div className='errors-logs-page'>
                <div className='errors-logs-header'>
                    <h1 className='errors-logs-title'>
                        <FormattedMessage id='error.ErrorsDetails'/>
                    </h1>
                    {hasLogs &&
                        <div className='errors-logs-links'>
                            <LinkEx to='/'><FormattedMessage id='error.homeLink'/></LinkEx>
                            <a onClick={logsLocalStorage.handleDownloadLogFile}><FormattedMessage id='error.downloadErrorsLog'/></a>
                        </div>
                    }
                </div>
                <ErrorsDetails/>
            </div>
        );
    }
}

export default ErrorsLogsPage;
