import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import RegisteredOverviewPageHeaderView from '../components/RegisteredOverviewPageHeaderView';

const mapStateToProps = state => {
    return {
        info: dynamicPageSelectors.getDynamicPageInfoSelector(state),
        isFetching: dynamicPageSelectors.getDynamicPageFetchingSelector(state)
    };
};

@connect(mapStateToProps)
class RegisteredOverviewPageHeader extends Component {
    static propTypes = {
        info: PropTypes.object,
        isFetching: PropTypes.bool
    };

    render() {
        const { info, isFetching } = this.props;

        return info && !isFetching ?
            <RegisteredOverviewPageHeaderView pageInfo={info}/>
            : null;
    }
}

export default RegisteredOverviewPageHeader;
