import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import InfoPopup from 'Components/InfoPopup';
import Popup from 'Components/Popup';
import popupColumnDefinitions from '../../constants/popup-column-definitions';

const SecurityName = ({ currentSecurity, searchArea }) => {
    const securityToDisplay = currentSecurity || (searchArea.name && searchArea);

    if (securityToDisplay) {
        const popupValues = {
            issuer: _.get(securityToDisplay, 'issuerName'),
            astSecurityName: _.get(securityToDisplay, 'astSecurityName'),
            cusip: _.get(securityToDisplay, 'cusip'),
            astSecurityId: _.get(securityToDisplay, 'companyNumber'),
            ticker: _.get(securityToDisplay, 'ticker')
        };

        return _.some(popupColumnDefinitions, col => popupValues[col.columnName])
            ? (<Popup
                maxWidth='72rem'
                popperOptions={{
                    modifiers: {
                        hide: {
                            enabled: false
                        }
                    }
                }}
                content={<InfoPopup
                    columnDefinitions={popupColumnDefinitions}
                    values={popupValues}
                    className='security-info-popup'/>}>
                <div
                    className='security-name'
                    data-security-id={securityToDisplay.id}>
                    {securityToDisplay.name}
                </div>
            </Popup>)
            : (<div
                className='security-name'
                title={securityToDisplay.name}
                data-security-id={securityToDisplay.id}>
                {securityToDisplay.name}
            </div>);
    }

    return (
        searchArea.key ? (
            <div className='security-name'>
                <FormattedMessage id={searchArea.key}/>
            </div>
        ) : null
    );
};

SecurityName.propTypes = {
    currentSecurity: PropTypes.object,
    searchArea: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        key: PropTypes.string
    }),
    popupValues: PropTypes.object
};

export default SecurityName;
