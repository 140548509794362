import { VALIDATION_LENGTH } from 'Constants/search';

export function isValidGlobalSearchQuery(query) {
    const value = query.trim();

    return new RegExp(`^.{${VALIDATION_LENGTH.MIN_LENGTH},${VALIDATION_LENGTH.MAX_LENGTH}}$`).test(value);
}

export function isValidSSN(query) {
    const value = query.trim();

    return new RegExp(`^\\d{${VALIDATION_LENGTH.SSN_SEARCH_LENGTH}}$`).test(value);
}

export function isValidCert(query) {
    const value = query.trim();

    return new RegExp(
        `^\\d{${VALIDATION_LENGTH.CERTIFICATE_NO_MIN_LENGTH},${VALIDATION_LENGTH.CERTIFICATE_NO_MAX_LENGTH}}$`
    ).test(value);
}
