import React from 'react';
import PropTypes from 'prop-types';

import pageRoutes from 'Constants/page-routes';
import LinkEx from 'Components/LinkEx';

const ShareholderLink = ({ type, id, refToBackQuery, val }) => {
    const pathname = `${pageRoutes[type.toUpperCase()]}/${id}`;

    return (
        <LinkEx
            attr={{ 'data-type': type }}
            to={{ pathname, search: refToBackQuery }}>
            {val}
        </LinkEx>
    );
};

ShareholderLink.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
    refToBackQuery: PropTypes.string,
    val: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default ShareholderLink;
