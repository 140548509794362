import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';

import AnalysisHoldingStyleContent from './AnalysisHoldingStyleContent';
import { mapBarValuesToData } from 'Components/Tables/TableEasy/utils';
import widgetModes from 'Constants/widget-modes';
import { sortArray } from 'Utils/utils';

export default
mapProps((props) => {
    return {
        ...props,
        tableData: sortArray({
            array: mapBarValuesToData(_.get(props, 'widgetData.values', []), 'os'),
            primaryField: 'os'
        }),
        aggregate: {
            osTotal: _.get(props, 'widgetData.aggregate.osTotal'),
            changeTotal: _.get(props, 'widgetData.aggregate.changeTotal')
        },
        noData: props.widgetNoData,
        hideBarChart: props.widgetSettings.mode === widgetModes.TABLE
    };
})(AnalysisHoldingStyleContent);
