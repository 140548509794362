import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import './InfoModal.scss';

const InfoModalView = (props) => {
    const {
        isHtmlMessage, messageKey, messageValues, noteKey,
        okText, onOk, actionText, onAction, noteClassNames,
        modalClassNames, messageClassNames, actionsClassNames
    } = props;
    const MessageFormatter = isHtmlMessage ? FormattedHTMLMessage : FormattedMessage;

    return (
        <div className={modalClassNames}>
            <div className={messageClassNames}>
                <MessageFormatter
                    id={messageKey}
                    values={messageValues}/>
            </div>
            {noteKey &&
                <div className={noteClassNames}>
                    <FormattedMessage id={noteKey}/>
                </div>
            }
            <div className={actionsClassNames}>
                <button
                    type='button'
                    onClick={onOk}
                    className='btn btn-large ok-btn'>
                    {okText}
                </button>
                {actionText && onAction && (
                    <button
                        type='button'
                        onClick={onAction}
                        className='btn btn-large action-btn'>
                        {actionText}
                    </button>
                )}
            </div>
        </div>);
};

InfoModalView.propTypes = {
    isHtmlMessage: PropTypes.bool,
    messageKey: PropTypes.string.isRequired,
    messageValues: PropTypes.object,
    noteKey: PropTypes.string,
    okText: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    actionText: PropTypes.string,
    onAction: PropTypes.func,
    modalClassNames: PropTypes.string,
    messageClassNames: PropTypes.string,
    actionsClassNames: PropTypes.string,
    noteClassNames: PropTypes.string
};

export default InfoModalView;
