import columnNames from 'Constants/shareholders-columns-names';

export default {
    POSITION: columnNames.POSITION,
    SHAREHOLDER_LAST_NAME: 'shareholderLastName', // according to ASTTSTRT-49272, fake column, don't use
    SHAREHOLDER_NAME: columnNames.SHAREHOLDER_NAME,
    HOLDER_TYPE: columnNames.HOLDER_TYPE,
    COUNTRY: columnNames.COUNTRY,
    STATE: columnNames.STATE
};
