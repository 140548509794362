import { WIDGET_PRINT } from './actions';
import pageOrientationTypes from 'Constants/page-orientation-types';
import { identifyBrowser } from 'Utils/browser';
import widgetTypes from 'Constants/widget-types';

const addPageOrientation = (orientation = pageOrientationTypes.LANDSCAPE) => {
    const styleId = 'printPageOrientation';
    const prevStyle = document.getElementById(styleId);

    if (prevStyle) {
        prevStyle.remove();
    }

    const head = document.head || document.getElementsByTagName('head')[0];
    const css = `@page { size: A4 ${orientation} }`;
    const style = document.createElement('style');

    style.appendChild(document.createTextNode(css));
    style.type = 'text/css';
    style.id = styleId;
    head.appendChild(style);
};

const getPageHeight = browser => {
    switch (browser) {
        case 'ie':
        case 'safari':
            return 1600;
        case 'moz':
            return 1400;
        default:
            return 1800;
    }
};

const addRegisteredShareholderWidgetZoom = (widgetHeight, browser) => {
    const styleId = 'registeredShareholderInfoZoom';
    const prevStyle = document.getElementById(styleId);
    const printPageHeight = getPageHeight(browser);
    const widgetWidth = 420;
    let k1 = Math.round((printPageHeight / widgetHeight) * 100) / 100;

    if (prevStyle) {
        if (browser === 'ie') {
            prevStyle.parentNode.removeChild(prevStyle);
        } else {
            prevStyle.remove();
        }
    }

    if (k1 > 1.5) {
        k1 = 1.5;
    } else if (k1 <= 1) {
        return;
    }

    const k2 = (k1 - 1) / 2;
    const head = document.head || document.getElementsByTagName('head')[0];
    let css = '';

    if (browser === 'edge') {
        css = `@media print {
        .registeredShareholderInformation .first-slot {
            transform: matrix(${k1}, 0, 0, ${k1}, ${widgetWidth * k2 / 2}, ${widgetHeight * k2 / 2});
        }}`;
    } else if (browser === 'safari') {
        css = `@media print {
        .registeredShareholderInformation .first-slot {
            transform: matrix(${k1}, 0, 0, ${k1}, ${widgetWidth * k2 + 20}, ${widgetHeight * k2 + 50});
        }}`;
    } else {
        css = `@media print {
        .registeredShareholderInformation .first-slot {
            transform: matrix(${k1}, 0, 0, ${k1}, ${widgetWidth * k2}, ${widgetHeight * k2});
        }}`;
    }

    const style = document.createElement('style');

    style.appendChild(document.createTextNode(css));
    style.type = 'text/css';
    style.id = styleId;
    head.appendChild(style);
};

export const widgetPrintMiddleware = () => next => action => {
    if (action.type === WIDGET_PRINT) {
        const currentWidget = action.payload.widgetType;
        const appElement = document.getElementById('app');
        const currentClassName = appElement.className;

        if (currentClassName) {
            appElement.classList.remove(currentClassName);
        }
        appElement.classList.add(currentWidget);

        if (window.E2E_TESTS_MODE) {
            const tmpSpan = document.createElement('span');

            tmpSpan.id = 'tmpPrintSpan';
            tmpSpan.style.display = 'none';
            document.body.appendChild(tmpSpan);
            return;
        }

        if (identifyBrowser() === 'chrome') {
            if (currentWidget === widgetTypes.registeredShareholderInformation) {
                addPageOrientation(pageOrientationTypes.PORTRAIT);
            } else {
                addPageOrientation(pageOrientationTypes.LANDSCAPE);
            }
        }

        if (currentWidget === widgetTypes.registeredShareholderInformation) {
            addRegisteredShareholderWidgetZoom(action.payload.widgetHeight, identifyBrowser());
        }

        // fix for re-render TableStickyColumn
        setTimeout(() => {
            window.print();
        }, 0);
    }
    return next(action);
};
