import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CheckBox } from 'Components/CheckBox';

const SelectAllCheckBox = props => {
    const { checked, onClick, disabled, style, indeterminate } = props;

    return (
        <div style={style} className={cn('check-boxes-list-item select-all', { checked })}>
            <CheckBox
                checked={checked}
                disabled={disabled}
                indeterminate={indeterminate}
                onChange={(e) => onClick(e.target.checked)}/>
            <FormattedMessage id='common.selectAll'>
                {(formattedMessage) => <span className='label'>{formattedMessage}</span>}
            </FormattedMessage>
        </div>
    );
};

SelectAllCheckBox.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object
};

export default SelectAllCheckBox;
