import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';

import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import columnNames from 'Constants/registered-table-column-names';
import { compareShareholders } from '../_commonComponents/RegisteredShareholderTable/hoc';
import { commonWidgetPropTypes, commonWidgetActions, getManageableColumns } from 'Components/widgets/utils';
import { addSecurityColumn } from '../_commonComponents/RegisteredShareholderTable/utils';

import './CompareShareholdersDocuments.scss';

@withProps(({ widgetData: values }) => {
    const data = addSecurityColumn(values);

    return ({
        data: data && data.map(value => ({
            ...value,
            download: {
                documentId: value.documentId,
                hasUrl: value.hasUrl,
                uploaded: value.uploaded
            }
        })),
        columnsDefinitions
    });
})
@compareShareholders
class CompareShareholdersDocuments extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        columnsDefinitions: PropTypes.array
    };

    getTdProps = (finalState, rowInfo, column) => {
        if (column.id === columnNames.DOWNLOAD) {
            return {
                additionalData: {
                    shareholderId: _.get(rowInfo, 'row.shareholderId'),
                    securityId: _.get(rowInfo, 'row.securityId')
                }
            };
        }
    };

    render() {
        return (
            <RegisteredShareholderTable
                {...this.props}
                className='compare-shareholders-documents'
                getTdProps={this.getTdProps}
                secondarySorting={secondarySorting}
                manageableColumns={getManageableColumns(this.props.columnsDefinitions)}
                exportAvailable={false}
                disableValidation/>
        );
    }
}

export default CompareShareholdersDocuments;
