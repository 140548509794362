export default {
    align: 'left',
    itemDistance: 10,
    itemMarginBottom: 8,
    itemStyle: {
        cursor: 'default'
    },
    itemHoverStyle: {
        cursor: 'default',
        color: '#333333'
    }
};
