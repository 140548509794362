import { useCallback, useEffect, useRef } from 'react';

const PRINT_ID = 'print-el';
const APP_ROOT_ID = 'app-root';
const PRINT_STYLE_ID = 'print-style';

const removeContainer = () => {
    const printEl = document.getElementById(PRINT_ID);
    const styleEl = document.getElementById(PRINT_STYLE_ID);

    if (printEl) {
        printEl.remove();
    }

    if (styleEl) {
        styleEl.remove();
    }
};

const usePrintWidget = () => {
    const printRef = useRef();


    const onPrint = useCallback(() => {
        /**
         * @type HTMLElement | null
         */
        const element = printRef.current;
        const target = document.getElementById(APP_ROOT_ID);

        if (!element || !target) {
            return;
        }

        removeContainer();

        if (!document.getElementById(PRINT_STYLE_ID)) {
            const printStyle = document.createElement('style');

            printStyle.setAttribute('id', PRINT_STYLE_ID);
            printStyle.setAttribute('type', 'text/css');
            printStyle.appendChild(document.createTextNode(`
            #${APP_ROOT_ID} > #${PRINT_ID} { display:none; }
            @media print {
                #${APP_ROOT_ID} > #${PRINT_ID} { display:block; }
                #${APP_ROOT_ID} > *:not(#${PRINT_ID}) { display: none; }
            }`));

            document.head.appendChild(printStyle);
        }

        const clonedEl = element.cloneNode(true);

        clonedEl.setAttribute('id', PRINT_ID);

        target.appendChild(clonedEl);

        window.print();
    }, []);

    useEffect(() => {
        window.addEventListener('afterprint', removeContainer);

        return () => {
            window.removeEventListener('afterprint', removeContainer);
        };
    }, []);

    return { printRef, onPrint };
};

export default usePrintWidget;
