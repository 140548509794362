import PropTypes from 'prop-types';
import React from 'react';

import LinkEx from 'Components/LinkEx';

const BackLinkView = ({ name, to }) => (
    <LinkEx className='back-arrow' to={to}>
        {name}
    </LinkEx>
);

BackLinkView.propTypes = {
    name: PropTypes.string,
    to: PropTypes.object
};

export default BackLinkView;
