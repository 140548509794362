import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { withState, pure } from '@shakacode/recompose';
import _ from 'lodash';
import cn from 'classnames';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ScrollChecker } from 'Utils/utils';

import './Dropdown.scss';

@injectIntl
@withState(
    'isOpen',
    'setOpen',
    false
)
@pure
class Dropdown extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                label: PropTypes.string.isRequired
            })),
        selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        placeholder: PropTypes.string,
        isOpen: PropTypes.bool,
        setOpen: PropTypes.func,
        onSelect: PropTypes.func,
        styleData: PropTypes.object,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        const styleData = _.get(this.props, 'styleData', {});
        const width = styleData.width || '10rem';

        this.style = {
            ...styleData,
            width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        };
    }

    componentDidMount() {
        this.scrollChecker = new ScrollChecker(this.handleScroll);
    }

    componentWillUnmount() {
        this.scrollChecker && this.scrollChecker.destroy();
    }

    handleScroll = () => {
        if (this.props.isOpen) {
            this.menu.handleToggle();
        }
    };

    handleToggle = () => {
        this.props.setOpen(!this.props.isOpen);
    };

    handleOptionSelect = (option) => {
        const { menu, props: { onSelect } } = this;

        onSelect(option);
        menu.handleToggle();
    };

    getOverlay = () => {
        const { style, props, handleOptionSelect } = this;
        const { options, selectedValue } = props;
        const mappedOptions = options.map((option) => {
            return (
                <div
                    key={option.value}
                    className={cn(
                        'dropdown-option',
                        { active: option.value === selectedValue }
                    )}
                    style={style}
                    onClick={handleOptionSelect.bind(this, option)}>
                    {option.label}
                </div>
            );
        });

        return (
            <Popover id='DROPDOWN-CONTENT' className='dropdowm-menu'>
                {mappedOptions}
            </Popover>
        );
    };

    getTriggerWrapper = () => {
        const { style, props } = this;
        const { isOpen, selectedValue, options, intl: { formatMessage } } = props;
        const value = selectedValue
            ? _.get(options.find(option => option.value === selectedValue), 'label')
            : props.placeholder || formatMessage({ id: 'selector.placeholder' });

        return (
            <div
                className={cn(
                    'dropdown-trigger',
                    { open: isOpen },
                    { placeholder: !selectedValue }
                )}
                style={style}>
                {value}
                <div className='arrow-container'>
                    {isOpen
                        ? <i className='font-icon font-icon-arrow-up'/>
                        : <i className='font-icon font-icon-arrow-drop-down'/>
                    }
                </div>
            </div>
        );
    };

    render() {
        const { handleToggle, getOverlay, getTriggerWrapper } = this;

        return (
            <div className='dropdown'>
                <OverlayTrigger
                    delay={0}
                    ref={menu => this.menu = menu}
                    trigger='click'
                    placement='bottom'
                    rootClose
                    overlay={getOverlay()}
                    onEnter={handleToggle}
                    onExit={handleToggle}>
                    {getTriggerWrapper()}
                </OverlayTrigger>
            </div>
        );
    }
}

export default Dropdown;
