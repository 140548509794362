import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BaseRoute, AvailableRoute, pathRoutesConfig } from 'Routes';
import OwnershipLayout from 'Layouts/OwnershipLayout';
import pageRoutes from 'Constants/page-routes';
import featureTypes from 'Constants/feature-types';
import * as pages from 'Pages';

class OwnershipRoutes extends Component {
    static propTypes = {
        location: PropTypes.object
    };

    render() {
        const { ownershipNav } = pathRoutesConfig;

        return (
            <Switch>
                <BaseRoute
                    exact
                    path={`${pageRoutes.ownership}`}
                    render={props => (
                        <AvailableRoute
                            pathConfig={ownershipNav.pathConfig}
                            defaultPath={ownershipNav.defaultPath.path}
                            {...props}/>
                    )}/>
                <BaseRoute
                    layout={OwnershipLayout}
                    path={`${pageRoutes.ownershipShareholders}`}
                    requiredFeature={featureTypes.OWNERSHIP_SHAREHOLDERS}
                    component={pages.ShareholdersPage}/>
                <BaseRoute
                    layout={OwnershipLayout}
                    path={`${pageRoutes.historicalAnalysis}`}
                    requiredFeature={featureTypes.HISTORICAL_ANALYSIS}
                    component={pages.HistoricalAnalysisPage}/>
                <BaseRoute
                    layout={OwnershipLayout}
                    path={`${pageRoutes.peerAnalysis}`}
                    requiredFeature={featureTypes.PEER_ANALYSIS}
                    component={pages.PeerAnalysisPage}/>
                <BaseRoute
                    exact
                    path={`${pageRoutes.ownershipContacts}`}
                    render={props => <Redirect to={`${pageRoutes.ownershipContactsLanding}${props.location.search}`}/>}/>
                <BaseRoute
                    layout={OwnershipLayout}
                    path={`${pageRoutes.ownershipContactsLanding}/:shareholderId?`}
                    requiredFeature={featureTypes.CONTACTS}
                    component={pages.ContactsPage}/>
                <BaseRoute
                    layout={OwnershipLayout}
                    path={pageRoutes.meetingPlanner}
                    requireFeature={featureTypes.MEETING_PLANNER}
                    component={pages.MeetingPlanner} />
                <BaseRoute
                    layout={OwnershipLayout}
                    path={`${pageRoutes.ownershipContactsInstitutions}`}
                    requiredFeature={featureTypes.CONTACTS}
                    component={pages.InstitutionsPage}/>
                <Redirect to={`${pageRoutes.error}/404`}/>
            </Switch>
        );
    }
}

export default OwnershipRoutes;
