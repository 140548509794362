import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import cn from 'classnames';

import { commonWidgetPropTypes, commonWidgetActions } from '../../../../utils';
import Widget from '../../../../Widget';
import RegisteredShareholderAccordionTableContent from '../../components/RegisteredShareholderAccordionTableContent';
import { RegisteredOverviewWidgetLayout } from '../../../../Widget/components/layouts';
import RegisteredOverviewControlPanel from '../../../RegisteredOverviewControlPanel';
import RegisteredOverviewTabsActionPanel from '../../../RegisteredOverviewTabsActionPanel';
import { getSlotsForRegisteredActionPanelSelector } from 'State/dynamicPage/selectors';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import { isFetchingSelector } from 'State/exportReports/selectors';

import '../RegisteredShareholderTable/RegisteredShareholderTable.scss';

const mapStateToProps = (state, props) => ({
    ...props,
    currentFeatures: getCurrentFeaturesSelector(state),
    availableSlots: getSlotsForRegisteredActionPanelSelector(state),
    isFetchingReport: isFetchingSelector(state)
});

@injectIntl
@connect(mapStateToProps)
class RegisteredShareholderAccordionTable extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        exportAvailable: PropTypes.bool,
        widgetNoData: PropTypes.bool
    };

    static defaultProps = {
        exportAvailable: true
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: RegisteredOverviewControlPanel,
            WidgetContent: RegisteredShareholderAccordionTableContent,
            WidgetActionPanel: RegisteredOverviewTabsActionPanel,
            WidgetLayout: RegisteredOverviewWidgetLayout
        };
    }

    render() {
        const { exportAvailable, availableSlots, widgetEvents, onExport, widgetNoData } = this.props;
        const props = {
            ...this.props,
            className: cn('widget-registered-shareholder-table', this.props.className),
            widgetControlPanelSettings: {
                onExport,
                inactive: widgetNoData || this.props.isFetchingReport,
                exportAvailable,
                hideColumnsManager: true
            },
            widgetNoData,
            availableSlots
        };

        return (
            <Widget
                {...props}
                events={widgetEvents}
                components={this.widgetComponents}/>
        );
    }
}

export default RegisteredShareholderAccordionTable;
