import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';

import SingleSlotView from './components/SingleSlot';
import { notifyWarning } from 'State/notifier/actions';
import * as selectors from 'State/dynamicPage/selectors';

import './Slot.scss';

const mapStateToProps = (state, { slotId }) => ({
    // We have to know about previous slot for cases WHEN WE DO NOT NEED TO RENDER any WidgetBox and return null
    // In case if we have empty current slot and previous slot is empty and it's type is SIMPLE
    // TODO refactoring
    previousSlot: selectors.getSlotByIdSelector(state, slotId - 1) || {}
});
const mapDispatchToProps = { notifyWarning };

@connect(mapStateToProps, mapDispatchToProps)
@mapProps((props) => {
    const { type = null, widgets = [] } = props.previousSlot;

    return _.omit({
        ...props,
        isPreviousSlotEmpty: widgets.length === 0,
        previousSlotType: type
    }, 'previousSlot');
})
class Slot extends Component {
    static propTypes = {
        isPreviousSlotEmpty: PropTypes.bool,
        previousSlotType: PropTypes.string,
        notifyWarning: PropTypes.func,
        isLocked: PropTypes.bool,
        isEmpty: PropTypes.bool,

        widgets: PropTypes.array.isRequired,

        slotId: PropTypes.number.isRequired,
        className: PropTypes.string
    };

    render() {
        const { widgets, slotId, isPreviousSlotEmpty, previousSlotType, isLocked, className, isEmpty } = this.props;

        // we do not need render WidgetBox in this slot because previous slot is empty
        if (!(widgets || []).length && isPreviousSlotEmpty && previousSlotType !== null) {
            return null;
        }

        if (widgets.length > 1) {
            this.props.notifyWarning('Single Slot Warning', `Expected 1 widget, but got: ${widgets.length}`);
        }

        return (
            <SingleSlotView
                widgetName={widgets[0]}
                slotId={slotId}
                isLocked={isLocked}
                isEmpty={isEmpty}
                className={className}/>
        );
    }
}

export default Slot;
