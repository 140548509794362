import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';

import LinkEx from 'Components/LinkEx';
import featureTypes from 'Constants/feature-types';
import UserAvatar from 'Components/UserAvatar';
import MenuItemView from './MenuItemView';
import Icon from 'Components/Icon';
import pageRoutes from 'Constants/page-routes';

import './ProfileMenu.scss';

const ProfileMenuView = (props) => {
    const {
        onToggle,
        onLogoutUser,
        userDownloadsNotification,
        userDataVizReportsNotification,
        currentFeatures,
        accessToPa,
        platformAdministrationUrl,
        currentSecurityId,
        isOpen,
        userData: {
            firstName,
            lastName
        }
    } = props;
    const iconClassName = cn('font-icon', {
        'font-icon-arrow-up': isOpen,
        'font-icon-arrow-drop-down': !isOpen
    });

    return (
        <div className='profile-menu'>
            <LinkEx
                to={pageRoutes.myProfile}
                className='user-name ellipsis'
                title={`${firstName} ${lastName}`}>{`${firstName} ${lastName}`}</LinkEx>
            <div className='profile-avatar-container'>
                <UserAvatar className='profile-avatar' altMessageId='user.profile.card.avatar.alt'/>
                {(userDownloadsNotification > 0 || userDataVizReportsNotification > 0)
                && <span className='badge'>{userDownloadsNotification + userDataVizReportsNotification}</span>
                }
            </div>

            <Dropdown onToggle={onToggle} pullRight id='dropdown-profile-menu'>
                <Dropdown.Toggle className='toggle' useAnchor>
                    <i className={iconClassName}/>
                </Dropdown.Toggle>

                <Dropdown.Menu className='menu-list' bsRole='menu'>
                    {accessToPa &&
                    <MenuItemView to={platformAdministrationUrl} isExternal>
                        <i className='font-icon font-icon-entities'/>
                        <FormattedMessage id='profile.menu.platform.administration'/>
                    </MenuItemView>}
                    {accessToPa &&
                        <MenuItemView divider/>
                    }
                    {currentFeatures[featureTypes.CUSTOM_REPORTS] && userDataVizReportsNotification > 0 &&
                        <MenuItemView to={pageRoutes.dataViz}>
                            <i className='font-icon font-icon-note'/>
                            <FormattedMessage id='profile.menu.reports'/>
                            <span className='badge right'>{userDataVizReportsNotification}</span>
                        </MenuItemView>
                    }
                    {currentSecurityId && currentFeatures[featureTypes.DOWNLOAD] &&
                        <MenuItemView to={pageRoutes.downloads}>
                            <Icon className='font-icon font-icon-download' titleKey='common.icon.tooltip.download'/>
                            <FormattedMessage id='profile.menu.downloads'/>
                            {userDownloadsNotification > 0
                            && <span className='badge right'>{userDownloadsNotification}</span>
                            }
                        </MenuItemView>
                    }
                    {(currentSecurityId
                        && currentFeatures[featureTypes.DOWNLOAD]
                        || currentFeatures[featureTypes.CUSTOM_REPORTS]
                        && userDataVizReportsNotification > 0)
                        && <MenuItemView divider/>
                    }

                    <MenuItemView to={pageRoutes.myProfile}>
                        <UserAvatar className='profile-avatar' altMessageId='user.profile.card.avatar.alt'/>
                        <FormattedMessage id='profile.menu.profile'/>
                    </MenuItemView>

                    {currentFeatures[featureTypes.CONTACTS] &&
                        <MenuItemView to={pageRoutes.ownershipContactsLanding}>
                            <i className='font-icon font-icon-contact'/>
                            <FormattedMessage id='profile.menu.contacts'/>
                        </MenuItemView>
                    }
                    <MenuItemView divider/>
                    {/* ASTTSTRT-45730
                    <MenuItemView>
                        <i className='font-icon font-icon-comments'/>
                        <FormattedMessage id='profile.menu.faq'/>
                    </MenuItemView>
                    <MenuItemView>
                        <i className='font-icon font-icon-info-outline'/>
                        <FormattedMessage id='profile.menu.help'/>
                    </MenuItemView>*/}
                    {currentFeatures[featureTypes.GLOBAL_LINKS] &&
                    <MenuItemView to={pageRoutes.issuerResources}>
                        <i className='font-icon font-icon-bookmark'/>
                        <FormattedMessage id='profile.menu.issuer.resources'/>
                    </MenuItemView>
                    }
                    {currentFeatures[featureTypes.GLOBAL_LINKS] &&
                    < MenuItemView divider/>
                    }
                    <MenuItemView handleClick={onLogoutUser} button>
                        <i className='font-icon font-icon-next'/>
                        <FormattedMessage id='profile.menu.logout'/>
                    </MenuItemView>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

ProfileMenuView.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    onLogoutUser: PropTypes.func.isRequired,
    userDownloadsNotification: PropTypes.number,
    userDataVizReportsNotification: PropTypes.number,
    currentFeatures: PropTypes.object.isRequired,
    accessToPa: PropTypes.bool.isRequired,
    platformAdministrationUrl: PropTypes.string,
    userData: PropTypes.object,
    currentSecurityId: PropTypes.number
};

export default ProfileMenuView;
