import PropTypes from 'prop-types';
import React from 'react';
import { getContext, compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { getDynamicPageSlotsSelector } from 'State/dynamicPage/selectors';
import _ from 'lodash';

const mapStateToProps = (state) => ({
    slots: getDynamicPageSlotsSelector(state)
});

const DynamicLayout = ({ layouts, layoutType, slots }) => {
    const StaticLayout = _.isNumber(layoutType) ? layouts[layoutType] : () => null;

    return (
        <StaticLayout slots={slots}/>
    );
};

DynamicLayout.propTypes = {
    layouts: PropTypes.object,
    layoutType: PropTypes.number.isRequired,
    slots: PropTypes.object
};

export default compose(
    getContext({
        layouts: PropTypes.object
    }),
    connect(mapStateToProps)
)(DynamicLayout);
