import PropTypes from 'prop-types';
import React from 'react';

import { TableEasy } from 'Components/Tables';

const ShareholderAnalysisTableView = ({ widgetData: { tableView }, widgetFooterProps, columns }) => {
    return (
        <TableEasy
            className='widget-table'
            widgetFooterProps={widgetFooterProps}
            columns={columns}
            data={tableView.values}
            aggregate={tableView.aggregate}/>
    );
};

ShareholderAnalysisTableView.propTypes = {
    widgetData: PropTypes.object,
    columns: PropTypes.array,
    widgetFooterProps: PropTypes.object
};

export default ShareholderAnalysisTableView;
