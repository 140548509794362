import _ from 'lodash';

import { OI, TA } from 'Constants/product-source-types';
import shareholderTypes from 'Constants/shareholder-types';

export default [
    {
        value: shareholderTypes.ALL,
        label: 'shareholderTypes.all',
        availableFor: [OI]
    },
    {
        value: shareholderTypes.INSTITUTION,
        label: 'shareholderTypes.institutions',
        availableFor: [OI]
    },
    {
        value: shareholderTypes.INSIDER,
        label: 'shareholderTypes.insiders',
        availableFor: [OI]
    },
    {
        value: shareholderTypes.INSTITUTION_AND_INSIDER,
        label: 'shareholderTypes.institutionsAndInsiders',
        availableFor: (currentProductSources) => {
            const hasOI = _.includes(currentProductSources, OI);
            const hasTA = _.includes(currentProductSources, TA);

            return hasOI && hasTA;
        }
    },
    {
        value: shareholderTypes.REGISTERED,
        label: 'shareholderTypes.registered',
        availableFor: [TA]
    }
];
