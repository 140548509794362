import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from '../../layout';
import Slot from '../../Slot';

import './LayoutWithAside.scss';

const LayoutWithAside = (props) => {
    const { slots } = props;

    return (
        <Layout className='layout-with-aside'>
            <div className='first-slot'>
                <Slot
                    {...slots[1]}
                    slotId={1}/>
            </div>
            <div className='second-slot'>
                <Slot
                    {...slots[2]}
                    slotId={2}/>
            </div>
        </Layout>
    );
};

LayoutWithAside.propTypes = {
    slots: PropTypes.object
};

export default LayoutWithAside;
