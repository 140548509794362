import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './TextEllipsis.scss';

const titleAttr = 'title';

function handleMouseEnter({ currentTarget: el }) {
    const isEllipsisActive = el.offsetWidth < el.scrollWidth;
    const hasAttribute = el.hasAttribute(titleAttr);

    if (isEllipsisActive && !hasAttribute) {
        const val = el.getAttribute('data-title');

        el.setAttribute(titleAttr, val);
    }
    if (!isEllipsisActive && hasAttribute) {
        el.removeAttribute(titleAttr);
    }
}

const TextEllipsis = ({ title, children, className, ...rest }) =>
    // span with display: inline-block
    (<span
        {...rest}
        className={cn('text-ellipsis', className)}
        data-title={title}
        onMouseEnter={title && handleMouseEnter}>
        {children}
    </span>);

TextEllipsis.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string
};

export default TextEllipsis;
