import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import errorStrategies from 'Constants/error-stategies';

import queryString from 'query-string';

export const getUserWatchlists = () => {
    return axios.get(apiRoutes.getUserWatchlistsUrl, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const createUserWatchlist = (title) => {
    const requestData = { title };

    return axios.post(apiRoutes.getUserWatchlistsUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const addShareholderToWatchlist = (requestData, watchlistIds) => {
    const query = queryString.stringify({ watchlistIds }, { arrayFormat: 'comma' });

    return axios.post(`${apiRoutes.getUserWatchlistsUrl}/update?${query}`, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const removeShareholderFromWatchlist = (shareholderId, watchlistId) => {
    const url = apiRoutes.watchlistRemoveShareholderUrl
        .replace('{shareholderId}', shareholderId)
        .replace('{watchlistId}', watchlistId);

    return axios.post(url, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const renameWatchlist = (title, watchlistId) => {
    const url = apiRoutes.renameWatchlistUrl.replace('{watchlistId}', watchlistId);
    const requestData = {
        title
    };

    return axios.post(url, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};
