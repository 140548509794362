import React from 'react';
import { FormattedMessage } from 'react-intl';

import { US } from 'Constants/countries';

export default values => {
    const errors = {};

    if (values  && values.country === US) {
        const regExp = /^[0-9]{5}(?:-[0-9]{1,7})?$/;

        if (values.zipCode && !regExp.test(values.zipCode)) {
            errors.zipCode = <FormattedMessage id='shareholder.list.location.zipCode.error.format'/>;
        }
    }

    return errors;
};
