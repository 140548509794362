import sortDirections from 'Constants/sort-directions';

export const columnNames = {
    SHARE_RANGE: 'shareRange',
    NUMBER_OF_ACCOUNTS: 'numberOfAccounts',
    PERCENT_OF_ACCOUNTS: 'percentOfTotalAccounts',
    PERCENT_OF_ACCOUNTS_NO_ROUND: 'percentOfTotalAccountsNoRound',
    NUMBER_OF_SHARES: 'numberOfShares',
    PERCENT_OF_SHARES: 'percentOfTotalShares',
    PERCENT_OF_SHARES_NO_ROUND: 'percentOfTotalSharesNoRound'
};

const columns = [
    {
        columnName: columnNames.SHARE_RANGE,
        order: 1,
        displayName: 'reporting.share.range.analysis.results.table.header.shareRange',
        flexGrow: 1,
        manageable: false,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.NUMBER_OF_ACCOUNTS,
        order: 2,
        displayName: 'reporting.share.range.analysis.results.table.header.numberOfAccounts',
        flexGrow: 1,
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.PERCENT_OF_ACCOUNTS,
        order: 3,
        displayName: 'reporting.share.range.analysis.results.table.header.percentOfTotalAccounts',
        flexGrow: 1,
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.NUMBER_OF_SHARES,
        order: 4,
        displayName: 'reporting.share.range.analysis.results.table.header.numberOfShares',
        flexGrow: 1,
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.PERCENT_OF_SHARES,
        order: 5,
        displayName: 'reporting.share.range.analysis.results.table.header.percentOfTotalShares',
        flexGrow: 1,
        manageable: true,
        initialSortDirection: sortDirections.DESC
    }
];

export default {
    columnNames,
    columns
};
