import { connect } from 'react-redux';

import SearchSubmitButton from './SearchSubmitButton';
import { isSubmittableSearch } from 'State/advancedSearch';

const mapStateToProps = (state) => ({
    disabled: !isSubmittableSearch(state)
});

export default connect(mapStateToProps)(SearchSubmitButton);
