import React from 'react';
import PropTypes from 'prop-types';

const ShareholderName = ({ val }) => {
    return <span>{val}</span>;
};

ShareholderName.propTypes = {
    val: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default ShareholderName;
