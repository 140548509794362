import columnNames from 'Constants/shareholders-columns-names';
import { CellWithNumber, CellWithPercent, CellWithMktValue } from 'Components/widgets/_commonComponents/TableCellComponents';
import CellWithShareholderName from './components/CellWithShareholderName';

export const defaultGroupColumnsSettings = [
    {
        columnName: columnNames.POSITION,
        displayName: 'analysis.table.position',
        headerClassName: 'position',
        component: CellWithNumber,
        defaultSortDesc: false,
        withTitle: true
    },
    {
        columnName: columnNames.OS,
        displayName: 'analysis.table.os',
        headerClassName: 'os',
        className: 'os',
        component: CellWithPercent,
        width: 60,
        defaultSortDesc: true,
        withTitle: true
    },
    {
        columnName: columnNames.MKT_VAL,
        displayName: 'analysis.table.mktVal',
        headerClassName: 'mkt-val',
        className: 'mkt-val',
        component: CellWithMktValue,
        withTitle: true,
        defaultSortDesc: true
    }
];

export const defaultMainColumnSettings = {
    columnName: columnNames.SHAREHOLDER_NAME,
    displayName: 'analysis.table.topShareholders',
    component: CellWithShareholderName,
    headerClassName: 'header-shareholder-name',
    defaultSortDesc: false
};

export const columnSettings = [
    defaultMainColumnSettings,
    ...defaultGroupColumnsSettings
];
