import React, { useEffect } from 'react';
import { Field, reduxForm, propTypes as formPropTypes } from 'redux-form';
import { compose } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as watchlistActions from 'State/watchlist';
import TextInput from 'Components/TextInput';
import { WATCHLIST_TITLE_MAX_LENGTH, WATCHLIST_TITLE_MIN_LENGTH } from 'Constants/watchlist';

import './RenameWatchlistModal.scss';

const RenameWatchlistModal = ({ initialize, watchlist, handleSubmit, valid, hideModal, submitting, intl }) => {
    useEffect(() => {
        initialize({
            title: watchlist.title || ''
        });
    }, [watchlist.title, initialize]);

    return (
        <form onSubmit={handleSubmit}>
            <Field
                className='modal-message'
                type='text'
                component={TextInput}
                name='title'
                placeholder={intl.formatMessage({ id: 'watchlist.title.placeholder' })}
                labelType='vertical'/>
            <div className='modal-actions'>
                <button className='btn btn-large' type='submit' disabled={!valid || submitting}>
                    <FormattedMessage id='common.submit' />
                </button>
                <button type='button' className='btn btn-large' onClick={hideModal}>
                    <FormattedMessage id='common.cancel' />
                </button>
            </div>
        </form>
    );
};

RenameWatchlistModal.propTypes = {
    watchlist: PropTypes.object,
    ...formPropTypes
};

const mapDispatchToProps = {
    renameWatchlist: watchlistActions.renameWatchlist,
    createWatchlist: watchlistActions.createWatchlist,
    selectWatchlist: watchlistActions.selectWatchlist
};

export default compose(
    connect(undefined, mapDispatchToProps),
    injectIntl,
    reduxForm({
        form: 'rename-watchlist-form',
        validate: (values, { intl }) => {
            if (values && !values.title) {
                return { title: intl.formatMessage({ id: 'watchlist.title.valid.isRequired' }) };
            }

            if (values && values.title.trim().length !== values.title.length) {
                return { title: intl.formatMessage({ id: 'watchlist.title.valid.spaces' }) };
            }

            if (values && values.title.length < WATCHLIST_TITLE_MIN_LENGTH) {
                return { title: intl.formatMessage({ id: 'watchlist.title.valid.minLength' }, { min: WATCHLIST_TITLE_MIN_LENGTH }) };
            }

            if (values && values.title.length > WATCHLIST_TITLE_MAX_LENGTH) {
                return { title: intl.formatMessage({ id: 'watchlist.title.valid.maxLength' }, { max: WATCHLIST_TITLE_MAX_LENGTH }) };
            }

            return {};
        },
        onSubmit: async (values, _dispatch, { hideModal, renameWatchlist, createWatchlist, selectWatchlist, watchlist }) => {
            try {
                if (watchlist.id) {
                    await renameWatchlist(values.title.trim(), watchlist.id);
                    return;
                }

                const newWatchlist = await createWatchlist(values.title);

                selectWatchlist(newWatchlist.id);
            } finally {
                hideModal();
            }
        }

    }),
)(RenameWatchlistModal);
