import PropTypes from 'prop-types';
import React from 'react';

import ShareholderInformationField from '../ShareholderInformationField';

const ShareholderInformationList = ({ columns }) => (
    <div className='content-columns'>
        { columns.map((column, i) =>
            (<div key={i} className='content-column'>
                { column.map((row) =>
                    (<ShareholderInformationField name={row.name} key={row.key}>
                        {row.value}
                    </ShareholderInformationField>))
                }
            </div>))
        }
    </div>
);

ShareholderInformationList.propTypes = {
    columns: PropTypes.array
};

export default ShareholderInformationList;
