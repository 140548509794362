import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { NullableFormatterWithIntl } from './NullableFormatter';
import { changeFormatter } from './formatters';

const NumberFormatter = (props) => {
    const {
        isOriginalValue,
        decimalPoints,
        negativeWithBrackets,
        className,
        withTitle
    } = props;

    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, negClass, intl }) => {
                const formattedValue = changeFormatter(intl, value, {
                    isOriginalValue,
                    decimalPoints,
                    negativeWithBrackets
                });

                return (<span className={cn(className, negClass)} title={withTitle ? formattedValue : null}>{formattedValue}</span>);
            }}
        </NullableFormatterWithIntl>
    );
};

NumberFormatter.propTypes = {
    value: PropTypes.any,
    isOriginalValue: PropTypes.bool,
    decimalPoints: PropTypes.number,
    negativeWithBrackets: PropTypes.bool,
    className: PropTypes.string,
    withTitle: PropTypes.bool
};

export default NumberFormatter;
