import React, { useEffect, useMemo } from 'react';
import PropsTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';

import Icon from 'Components/Icon';
import * as modalActions from 'State/modal';
import * as watchlistAction from 'State/watchlist/actions';
import { getSelectedWatchlistSelector, getWatchlistSelector } from 'State/watchlist/selectors';
import { WATCHLIST_DEFAULT_TITLE } from 'Constants/watchlist';

import './WatchlistTab.scss';

const WatchlistTab = ({
    onSelectTab,
    showRenameWatchlistModal,
    widget,
    watchlists,
    selectedWatchlist,
    selectWatchlist
}) => {
    const isSelectedWidget = useMemo(() => _.get(widget, 'settings.isSelected'), [widget]);
    const widgetCurrentWatchlistId = _.get(widget, 'settings.currentWatchlistId');

    const handleClick = (watchlistId) => {
        selectWatchlist(watchlistId);

        onSelectTab(widget);
    };

    const isActive = (watchlistId) => {
        return isSelectedWidget && selectedWatchlist === watchlistId;
    };

    useEffect(() => {
        if (isSelectedWidget) {
            if (watchlists.length > 0) {
                if (watchlists.some(({ id }) => id === widgetCurrentWatchlistId)) {
                    selectWatchlist(widgetCurrentWatchlistId);
                    return;
                }

                selectWatchlist(watchlists[0].id);
            }

            selectWatchlist(null);
        }

        return () => {
            selectWatchlist(null);
        };
    }, [isSelectedWidget, widgetCurrentWatchlistId, watchlists, selectWatchlist]);

    if (watchlists.length === 0) {
        return (
            <li className='tab watchlist-tab'>
                <a
                    className={cn({ 'active': isSelectedWidget })}
                    onClick={() => onSelectTab(widget)}>
                    {WATCHLIST_DEFAULT_TITLE}
                    {isSelectedWidget &&
                        <Icon
                            className='font-icon font-icon-edit'
                            titleKey='watchlist.rename.button'
                            onClick={() => showRenameWatchlistModal({ title: WATCHLIST_DEFAULT_TITLE })}/>
                    }
                </a>
            </li>
        );
    }

    return watchlists.map((item) => (
        <li className='tab watchlist-tab' key={item.id}>
            <a
                className={cn({ 'active': isActive(item.id) })}
                onClick={() => handleClick(item.id)}>
                {item.title}
                {isActive(item.id) && (
                    <Icon
                        className='font-icon font-icon-edit'
                        titleKey='watchlist.rename.button'
                        onClick={() => showRenameWatchlistModal(item)}/>
                )}
            </a>
        </li>
    ));
};

WatchlistTab.propTypes = {
    item: PropsTypes.shape({
        title: PropsTypes.string
    }),
    showRenameWatchlistModal: PropsTypes.func,
    watchlists: PropsTypes.array,
    widget: PropsTypes.object,
    onSelectTab: PropsTypes.func
};

const mapStateToProps = (state) => ({
    watchlists: getWatchlistSelector(state),
    selectedWatchlist: getSelectedWatchlistSelector(state)
});

const mapDispatchToProps = {
    showRenameWatchlistModal: modalActions.showRenameWatchlistModal,
    selectWatchlist: watchlistAction.selectWatchlist
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistTab);
