import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import OverallOwnershipStatisticsContent from './components/OverallOwnershipStatisticsContent';
import FooterWithInfo from '../_commonComponents/FooterWithInfo';
import OverallOwnershipStatisticsControlPanel from './components/OverallOwnershipStatisticsControlPanel';
import { changeHolderInvestorTypeAnalysisRouteState } from 'State/routeState';
import { simpleColorizeWidgetData } from '../../../utils/colorize';
import pageRoutes from 'Constants/page-routes';
import shareholderTypes from 'Constants/shareholder-types';
import messageType from 'Constants/message-types';
import { getWidgetFooterPropsWithSignClass } from 'Components/widgets/utils';

import './OverallOwnershipStatistics.scss';

const TOTAL_PERCENT = 100;
const mapDispatchToProps = { changeHolderInvestorTypeAnalysisRouteState };

@connect(null, mapDispatchToProps)
class OverallOwnershipStatistics extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: OverallOwnershipStatisticsContent,
            WidgetControlPanel: props.isPrintMode ? null : OverallOwnershipStatisticsControlPanel,
            WidgetFooter: FooterWithInfo
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'overall.ownership.statistics.title' });
    }

    getWidgetFooterProps = () => {
        const widgetData = this.props.widgetData;

        return {
            leftSide: [
                {
                    messageKey: 'widgets.link.fullReport',
                    to: pageRoutes.reportingHolderInvestorTypeAnalysis,
                    customProps: {
                        onClick: this.onFullReportClick
                    },
                    hidden: this.props.isDataViz
                }
            ],
            rightSide: [
                {
                    messageKey: 'widgets.message.includesCeDeCo',
                    type: messageType.INCLUDES_CEDECO,
                    hidden: !this.showCeDeCoMessage(widgetData)
                },
                {
                    messageKey: 'widgets.totalOver100',
                    type: messageType.MORE_THAN_100_PERCENTS,
                    hidden: !(_.get(widgetData, 'aggregate.osTotal') > TOTAL_PERCENT)
                }
            ]
        };
    };

    onFullReportClick = () => {
        this.props.changeHolderInvestorTypeAnalysisRouteState({
            shareholderType: shareholderTypes.ALL
        });
    };

    showCeDeCoMessage = widgetData => {
        const widgetDataValues = _.get(widgetData, 'values');

        return _.some(widgetDataValues, 'includesCeDeCo');
    };

    render() {
        const widgetFooterProps = getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(this.props.widgetData));
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            },
            widgetFooterProps,
            widgetData: simpleColorizeWidgetData(this.props.widgetData, this.props.widgetSettings),
            widgetNoData: !_.get(this.props, 'widgetData.values.length')
        };

        return (
            <Widget
                {...props}
                className='widget-overall-ownership-statistics'
                components={this.widgetComponents}/>
        );
    }
}

export default injectIntl(OverallOwnershipStatistics);
