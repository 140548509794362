import widgetTypes from './widget-types';

export default {
    [widgetTypes.contactDetails]: 2,
    [widgetTypes.shareholdersTable]: 3,
    [widgetTypes.top25HoldingsTable]: 3,
    [widgetTypes.shareholdersTablePositions]: 2,
    [widgetTypes.geographicAnalysis]: 2,
    [widgetTypes.registeredShareholderInformation]: 2,
    [widgetTypes.registeredShareholderTransactionHistory]: 2,
    [widgetTypes.registeredShareholderDRDSPP]: 2,
    [widgetTypes.registeredShareholderPayments]: 2,
    [widgetTypes.registeredShareholderDocuments]: 2,
    [widgetTypes.registeredShareholder1099DIV]: 2,
    [widgetTypes.registeredShareholder1099INT]: 2,
    [widgetTypes.registeredShareholder1099B]: 2,
    [widgetTypes.registeredShareholder1099Misc]: 2,
    [widgetTypes.registeredShareholder1042S]: 2,
    [widgetTypes.holderInvestorTypeAnalysis]: 2,
    [widgetTypes.controlBooksResults]: 2,
    [widgetTypes.shareRangeAnalysisResults]: 2,
    [widgetTypes.peerAnalysis]: 3,
    [widgetTypes.historicalAnalysisTable]: 3,
    [widgetTypes.institutionProfile]: 2,
    [widgetTypes.contactsLanding]: 3,
    [widgetTypes.contactsInstitutions]: 3,
    [widgetTypes.performance]: 2,
    [widgetTypes.insiderTop25HoldingsTable]: 3
};
