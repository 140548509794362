import { createSelector } from 'reselect';
import _ from 'lodash';

import { mapBarValuesToPercent } from 'Components/Tables/TableEasy';
import * as widgetUtils from '../utils';

const getWidgetDataValues = props => _.get(props.widgetData, 'values');

export const getDates = createSelector(
    getWidgetDataValues,
    (values) => values && _.map(values, 'date'));

export const getSelectedDate = createSelector(
    getDates,
    props => props,
    widgetUtils.getSelectedDate);

export const getFilteredData = createSelector(
    getWidgetDataValues,
    getSelectedDate,
    (widgetData, selectedDate) => {
        const filteredData = _.find(widgetData, value => value.date === selectedDate);

        if (filteredData) {
            const { values = [], aggregate = {} } = filteredData;

            return {
                ...filteredData,
                values: mapBarValuesToPercent(values, 'noOfPayments', aggregate)
            };
        }

        return {};
    }
);
