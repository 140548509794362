import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export const manageCache = (payload) => {
    const requestData = utils.buildRequest(payload, requestTypes.manageCachePayload);

    return axios.post(apiRoutes.manageCacheUrl, requestData, {
        isCancelable: true
    });
};
