import moment from 'moment';
import { isNumber, isObject } from 'lodash';

export function withAdditionalPeriod(periods, durationInMonth, format) {
    if (!Array.isArray(periods) || periods.length < 1) return [];

    const additionalPeriod = moment(periods[0], format)
        .add(-durationInMonth, 'M')
        .format(format);

    return [additionalPeriod, ...periods];
}

export function generateBarPositiveInstitutionValues(aggregations, factor = 1, getAggregationName) {
    if (!Array.isArray(aggregations) || !isNumber(factor)) return [];

    return aggregations.map((aggregate) => {
        return {
            name: getAggregationName(aggregate),
            data: aggregate.values.map(set => {
                return set.value
                    ? set.value / factor
                    : null;
            })
        };
    });
}

export function generateBarNegativeInstitutionValues(dataObject, factor = 1, getAggregationName) {
    if (!isObject(dataObject) || !isNumber(factor)) return [];
    const values = dataObject.values || [];
    const aggregate = dataObject.aggregate || {};

    return values.concat({
        ...aggregate,
        name: getAggregationName(aggregate), // formatMessage({ id: `${localeAddress}${aggregate.name}` }),
        stack: 1
    })
        .map((value) => {
            return {
                ...value,
                name: value.name,
                data: value.values.map(periodValue => {
                    return periodValue.value
                        ? periodValue.value / factor
                        : null;
                })
            };
        });
}
