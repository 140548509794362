import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import SecuritySection from './SecuritySection';
import * as userSelectors from 'State/user/selectors';
import * as securitySelectors from 'State/securities/selectors';
import * as advancedSearchSelectors from 'State/advancedSearch/selectors';
import { showSelectSecurityModal } from 'State/modal';

export default compose(
    injectIntl,
    connect((state) => ({
        currentSecurity: userSelectors.getCurrentSecuritySelector(state),
        searchArea: advancedSearchSelectors.getSearchArea(state),
        securityList: securitySelectors.getAvailableSecurities(state) || []
    }), {
        showSelectSecurityModal
    })
)(SecuritySection);
