import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Icon from 'Components/Icon';

const ShareholderClassicAsOfDateLinkView = props => (
    <div className='classic-as-of-date-report'>
        <Icon className='font-icon font-icon-grid action-icon'/>
        <span className='classic-pdf-link' onClick={props.onGetReport}>
            <FormattedMessage id='saved.reports.classicAsOfDateShareholderList'/>
        </span>
    </div>
);

ShareholderClassicAsOfDateLinkView.propTypes = {
    onGetReport: PropTypes.func
};

export default memo(ShareholderClassicAsOfDateLinkView);
