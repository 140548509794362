import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';

import { RadioButtonField } from 'Components/RadioButton';
import Search from 'Components/Search';
import { PII } from 'Constants/search';

import './AdvancedSearchModal.scss';

const AdvancedSearchModalView = ({
    intl: { formatMessage },
    disabled,
    isFetching,
    onSearch,
    searchFieldName,
    formName,
    message: { key, values }
}) => {
    return (
        <div className='advanced-search-modal'>
            <RadioButtonField
                name='parameterOfSearch'
                id='parameterOfSearch'
                type='radio'
                label={formatMessage({ id: 'search.by.advanced.tax' })}
                value={PII.TAX_ID}/>
            <RadioButtonField
                name='parameterOfSearch'
                id='parameterOfSearch'
                type='radio'
                label={formatMessage({ id: 'search.by.advanced.cert' })}
                value={PII.CERTIFICATE_NO}/>
            <Search
                formName={formName}
                name={searchFieldName}
                placeholder=''
                onSearch={onSearch}
                disabled={disabled}
                autoComplete={false}
                isLoading={isFetching}
                isErrorMessageShowing={false}
                withFocus={!disabled}/>
            <div className='message-note'>
                { key &&
                    (<FormattedMessage
                        id={key}
                        values={values}/>)}
            </div>
        </div>
    );
};

AdvancedSearchModalView.propTypes = {
    intl: intlShape.isRequired,
    disabled: PropTypes.bool,
    noValue: PropTypes.bool,
    isFetching: PropTypes.bool,
    onSearch: PropTypes.func,
    searchFieldName: PropTypes.string,
    formName: PropTypes.string,
    message: PropTypes.shape({
        key: PropTypes.string,
        values: PropTypes.object
    })
};

AdvancedSearchModalView.defaultProps = {
    message: {}
};

export default AdvancedSearchModalView;
