export default {
    HOLDING_NAME: 'holding',
    PORTFOLIO_PERCENT: 'eqPort',
    CHANGE_PERCENT: 'chg',
    MKT_VAL: 'mktVal',
    MKT_VAL_CHANGE: 'mktValChg',
    OS_PERCENT: 'os',
    POSITION: 'position',
    OS_CHANGE_PERCENT: 'osChange',
    POSITION_CHANGE: 'positionChange',
    REPORT_DATE: 'reportDate',
    SECTOR: 'sectors'
};
