import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

import WidgetMenu from '../../Widget/components/WidgetMenu';
import { hasUnselectedWidgets } from '../utils';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import { getVisibleWidgetsAmount } from 'State/widgets/selectors';

import './WidgetBoxEmptyView.scss';

const MENU_TRANSLATION = -190;
const SLOT_PROPS = {
    widget: null
};
const MENU_SCROLL_PADDING = 23;
const makeMapStateToProps = (initialState, { slotId }) => {
    const availableWidgetTypesSelector = dynamicPageSelectors.makeAvailableWidgetTypesSelector(slotId);

    return (state) => ({
        availableWidgets: availableWidgetTypesSelector(state, slotId),
        blockAction: dynamicPageSelectors.getDynamicPageBlockActionSelector(state),
        pageInfo: dynamicPageSelectors.getDynamicPageInfoSelector(state),
        widgetsAmount: getVisibleWidgetsAmount(state)
    });
};

@connect(makeMapStateToProps)
class WidgetBoxEmptyView extends Component {
    static propTypes = {
        availableWidgets: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                isSelected: PropTypes.bool.isRequired
            })
        ).isRequired,
        onAddContent: PropTypes.func.isRequired,
        isFetching: PropTypes.bool,
        blockAction: PropTypes.bool,
        widgetsAmount: PropTypes.number,
        pageInfo: PropTypes.object
    };

    static defaultProps = {
        availableWidgets: []
    };

    getTriggerElement() {
        return (
            <div className='widget-box-empty-container'>
                <div className='widget-box-empty'>
                    <span className='add-label-container'>
                        <span>
                            <i className={cn('font-icon font-icon-add-circle-outline', { inactive: this.props.blockAction })}/>
                            <span className='add-label'>
                                <FormattedMessage
                                    id='widgets.addContent'/>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    setMenu = (menu) => {
        this.menu = menu;
    };

    scrollToMenu= () => {
        const element = ReactDOM
            .findDOMNode(this.menu)
            .getBoundingClientRect();

        if (window.innerHeight < element.bottom) {
            window.scrollTo(0, window.pageYOffset + element.top - MENU_SCROLL_PADDING);
        }
    };

    hasFreeSlot() {
        const { widgetsAmount } = this.props;
        const maxWidgetsAmount = _.get(this.props, 'pageInfo.maxWidgetsAmount');

        return maxWidgetsAmount ? widgetsAmount < maxWidgetsAmount : true;
    }

    render() {
        const trigger = this.getTriggerElement();
        const { blockAction } = this.props;
        const needToDisplay = hasUnselectedWidgets(this.props.availableWidgets) && this.hasFreeSlot();

        if (needToDisplay) {
            return blockAction
                ? trigger
                : (<WidgetMenu
                    ref={this.setMenu}
                    scrollToMenu={this.scrollToMenu}
                    triggerElement={trigger}
                    className='box-widget-menu'
                    widgets={this.props.availableWidgets}
                    onSelectWidget={this.props.onAddContent}
                    slotProps={SLOT_PROPS}
                    translation={MENU_TRANSLATION}/>);
        }
        return null;
    }
}

export default WidgetBoxEmptyView;
