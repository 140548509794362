import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import moment from 'moment';

import InputDatePicker from 'Components/InputDatePicker';

import './InputDateRangePicker.scss';

const InputDateRangePicker = (props) => {
    const { className, startDateProps, endDateProps, showYearDropdown, showMonthDropdown } = props;
    const fromRange = startDateProps.value;
    const minEnd = fromRange || endDateProps.minDate;
    const toRange = endDateProps.value;
    const maxDate = endDateProps.maxDate || moment(); // max date or today
    const maxStart = toRange && toRange.isBefore(maxDate, 'day') ?
        toRange : maxDate;
    const wrapperClassName = cn(className, 'input-date-range-picker');

    return (
        <div className={wrapperClassName}>
            <InputDatePicker
                {...startDateProps}
                labelType='inline'
                selectsStart
                maxDate={maxStart}
                startDate={fromRange}
                endDate={toRange}
                selected={fromRange}
                showYearDropdown={showYearDropdown}
                showMonthDropdown={showMonthDropdown}
                classNames='start-date-input'/>
            <InputDatePicker
                {...endDateProps}
                labelType='inline'
                minDate={minEnd}
                openToDate={maxStart}
                selectsEnd
                startDate={fromRange}
                endDate={toRange}
                selected={toRange}
                showYearDropdown={showYearDropdown}
                showMonthDropdown={showMonthDropdown}
                classNames='end-date-input'/>
        </div>
    );
};

InputDateRangePicker.propTypes = {
    startDateProps: PropTypes.shape(InputDatePicker.propTypes),
    endDateProps: PropTypes.shape(InputDatePicker.propTypes),
    className: PropTypes.string,
    showYearDropdown: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    disabled: PropTypes.bool
};

export default InputDateRangePicker;
