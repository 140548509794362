import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import { requestDocument } from 'State/reportingDocuments/actions';
import { fetchingDocumentIdSelector } from 'State/reportingDocuments/selectors';
import Icon from 'Components/Icon';
import NullableFormatter from 'Components/formatters/NullableFormatter';
import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';

const mapDispatchToProps = {
    requestDocument
};
const mapStateToProps = state => ({
    fetchingDocumentId: fetchingDocumentIdSelector(state)
});

@connect(mapStateToProps, mapDispatchToProps)
class CellDownloadAction extends Component {
    static propTypes = {
        value: PropTypes.any,
        additionalData: PropTypes.object,
        className: PropTypes.string,
        requestDocument: PropTypes.func,
        fetchingDocumentId: PropTypes.any
    };

    handleClick = () => {
        const {
            value,
            additionalData: { shareholderId, securityId } = {}
        } = this.props;
        const params = value.uploadStatus === uploadedDocumentStatuses.COMPLETE
            ? { uploaded: true } : {};

        if (this.isEnabled()) {
            this.props.requestDocument(value.documentId, shareholderId, securityId, params);
        }
    };

    isEnabled = () => this.props.value.uploadStatus === uploadedDocumentStatuses.COMPLETE || this.props.value.hasUrl;

    render() {
        const { className, value } = this.props;
        const { additionalData: { iconClass } = {} } = this.props;
        const isFetching = this.props.fetchingDocumentId === value.documentId;
        const cellClassName = cn('font-icon',
            className,
            { 'inactive': !this.isEnabled() },
            isFetching ? 'spinner-item' : iconClass  || 'font-icon-download');

        return (<NullableFormatter value={value.documentId}>
            <Icon
                className={cellClassName}
                titleKey='common.icon.tooltip.download'
                onClick={this.handleClick}/>
        </NullableFormatter>);
    }
}


export default CellDownloadAction;
