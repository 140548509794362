import { connect } from 'react-redux';

import TermsAndConditionsModal from './TermsAndConditionsModal';
import { getTermsAndConditions, getTermsAndConditionsSelector } from 'State/user';
import { hideModal } from 'State/modal';
import termsTypes from 'Constants/terms-types';

export default connect(
    (state) => ({
        terms: getTermsAndConditionsSelector(state, termsTypes.AST)
    }),
    {
        hideModal,
        getTermsAndConditions
    }
)(TermsAndConditionsModal);
