import React from 'react';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';
import pageRouts from 'Constants/page-routes';

const ReportingShareRangeAnalysisResultPageHeader = () => {
    return (
        <div className='reporting-share-range-analysis-results-page-header'>
            <LinkEx className='back-arrow' to={pageRouts.reportingShareRangeAnalysisSearch}>
                <FormattedMessage id='reporting.share.range.analysis.results.link'/>
            </LinkEx>
            <h2 className='heading'><FormattedMessage id='reporting.share.range.analysis.results.title'/></h2>
        </div>
    );
};

export default ReportingShareRangeAnalysisResultPageHeader;
