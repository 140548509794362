import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import axios from 'axios';
import _ from 'lodash';
import queryString from 'query-string';

import store from './store';
import hashHistory from './history';
import { showSessionTimeoutModal, showMultipleWidgetConflictModal } from 'State/modal/actions';
import * as userSelectors from 'State/user/selectors';
import * as userActions from 'State/user/actions';
import apiRoutes from 'Constants/api-routes';
import pageRoutes from 'Constants/page-routes';
import errorHandler from './errorHandler';
import requestTypes from 'Constants/request-types';
import httpStatus from 'Constants/http-status';
import { forceLogout } from 'Utils/utils';
import { buildLocationWithSecurity } from 'Utils/routing';
import { isSafari as identitySafari } from 'Utils/browser';
import errorStrategies from 'Constants/error-stategies';
import { notifyError } from 'State/notifier/actions';
import { isCancel, createCancelToken } from '../api/utils';
import cancelTokensStorage from './cancelTokensStorage';
import { notifyWebdriverPageFail, isDatavizExport } from 'Utils/datavizExportHelper';
import errorReasons from 'Constants/error-reasons';
import { refreshUserSession } from './session';

const { getState, dispatch } = store;
const isSafari = identitySafari();

/* interceptors for all requests */
// interceptor for setting headers in request
axios.interceptors.request.use(config => {
    const id = localStorage.getItem('ISP_USER_ID');
    const role = localStorage.getItem('ISP_USER_ROLE');
    const state = getState();
    const defaultSecurityId = userSelectors.getDefaultSecurityIdSelector(state);
    const customSecurityId = userSelectors.getCustomSecurityIdSelector(state);

    if (id && role) { // this is necessary only for STUB login page.
        _.set(config, 'headers.ISP_USER_ID', id);
        _.set(config, 'headers.ISP_USER_ROLE', role);
    }

    if (config.url !== apiRoutes.getUserInfoUrl && defaultSecurityId) {
        _.set(config, 'headers.DEFAULT_SECURITY', defaultSecurityId);
    }

    if (customSecurityId && (customSecurityId !== defaultSecurityId)) {
        _.set(config, 'headers.CUSTOM_SECURITY', customSecurityId);
    }

    if (!config.originalCancelToken) {
        // eslint-disable-next-line
        config.originalCancelToken = createCancelToken();
    }

    // eslint-disable-next-line
    config.cancelToken = config.originalCancelToken.token;

    // Do not add not-cancelable tokens
    if (config.isCancelable) {
        cancelTokensStorage.add(config.originalCancelToken);
    }

    if (!config.withoutRefreshSession) {
        refreshUserSession();
    }

    // eslint-disable-next-line
    config.withCredentials = true;

    return config;
});

/* interceptors for all responses */

const MAX_RETRIAL_ATTEMPTS_COUNT = 3;
const DELAY_BETWEEN_RETRY = 500;
const SESSION_TIMEOUT = SESSION_TIMEOUT_PERIOD || 900000; // 15 minutes average session timeout
let lastResponseSuccessTime = Date.now();

const getSessionIsExpired = () => (Date.now() - lastResponseSuccessTime) < SESSION_TIMEOUT;

axios.interceptors.response.use(null,
    error => {
        const requestUrl = _.get(error, ['config', 'url'], '');
        const isUploadRequest = requestUrl === apiRoutes.uploadDocumentUrl;

        if (isUploadRequest) {
            return Promise.reject(error);
        }

        const responseStatus = _.get(error, 'response.status');
        const configData = JSON.parse(_.get(error, 'config.data', null));
        const requestType = configData && configData.requestType;
        const isRetryable = _.get(error, 'config.isRetryable');

        // SKIP
        if (isCancel(error) || isRetryable === false) {
            return Promise.reject(error);
        }

        // REPEAT
        if (isNetworkError(error)
            || responseStatus === httpStatus.HTTP_502
            || responseStatus === httpStatus.HTTP_503) {
            const attemptNumber = _.get(error, 'config.currentAttemptNumber', 1);

            if (attemptNumber <= MAX_RETRIAL_ATTEMPTS_COUNT) {
                _.set(error, 'config.currentAttemptNumber', attemptNumber + 1);

                console.log(`RETRY Request: ${attemptNumber} ${requestType} => [${responseStatus || NETWORK_ERROR}]`);
                console.log(error);

                if (isNetworkError(error)) {
                    return refreshSecurityToken()
                        .then(
                            () => delayExec(() => axios(error.config), DELAY_BETWEEN_RETRY * attemptNumber),
                            () => {
                                if ((Date.now() - lastResponseSuccessTime) > SESSION_TIMEOUT) {
                                    _.set(error, 'response.status', httpStatus.HTTP_401);
                                    return Promise.reject(error);
                                }
                                return delayExec(() => axios(error.config), DELAY_BETWEEN_RETRY * attemptNumber);
                            });
                }

                // Make a delay between retrial requests
                return delayExec(() => axios(error.config), DELAY_BETWEEN_RETRY * attemptNumber);
            }
        }

        return Promise.reject(error);
    });


function delayExec(func, delay) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            func().then(res, rej);
        }, delay);
    });
}

function refreshSecurityToken() {
    return new Promise((res, rej) => {
        const elm = document.createElement('img');
        const handler = (resolver) => (event) => {
            document.body.removeChild(elm);
            resolver(event);
        };

        elm.onload = handler(res);
        elm.onerror = handler(rej);

        elm.style.position = 'absolute';
        elm.style.zIndex = '-1';

        elm.setAttribute('alt', 'na');
        elm.setAttribute('height', '1px');
        elm.setAttribute('width', '1px');
        elm.setAttribute('src', `${apiRoutes.ping}?_=${Date.now()}`);

        document.body.appendChild(elm);
    });
}

const isUploadRequest = res => {
    const requestUrl = _.get(res, ['config', 'url'], '');

    return [apiRoutes.uploadDocumentUrl, apiRoutes.uploadSecureDocumentUrl].includes(requestUrl);
};

const NETWORK_ERROR = 'Network Error';
const EXCHANGE_ERROR = 'Exchange Error';

/* interceptor for processing errors */
const OAM_LOGIN_PAGE_SIGNATURE = '<script type="text/javascript" charset="UTF-8" language="javascript" src="/oaam_server/js/oaam_uio.js"></script>';
const OAM_TOKEN_VALIDITY_SIGNATURE = '<body onLoad="document.myForm.submit()">';

const MAX_USER_NOTIFICATIONS_FAIL_COUNT = 4;
let userNotificationsFailCount = 0;

axios.interceptors.response.use(
    res => {
        if (isUploadRequest(res)) {
            return res;
        }

        const configData = JSON.parse(_.get(res, 'config.data', null));
        const requestType = configData && configData.requestType;
        const contentType = _.get(res, ['headers', 'content-type'], '');
        const data = _.get(res, 'data', '');
        const responseStatus = _.get(res, 'status');
        const attemptNumber = _.get(res, 'config.currentAttemptNumber', 1);
        const isRetryable = _.get(res, 'config.isRetryable');
        const isCheckExchange = _.get(res, 'config.isCheckExchange');

        // skip OAM token validity pages
        if (isCheckExchange === false ||
            (contentType.indexOf('text/') !== -1 &&
                (data.indexOf(OAM_LOGIN_PAGE_SIGNATURE) !== -1 ||
                    data.indexOf(OAM_TOKEN_VALIDITY_SIGNATURE) !== -1))) {
            return res;
        }

        /*
        check exchange, should contains two high level sections status, payload, requestType
         */
        const exchangeStatus = _.get(res, 'data.status');
        const shouldHandleError = !_.get(res, 'config.noHandleError');

        if (!exchangeStatus) {
            if (attemptNumber <= MAX_RETRIAL_ATTEMPTS_COUNT) {
                _.set(res, 'config.currentAttemptNumber', attemptNumber + 1);

                console.log(`RETRY Request after incorrect exchange: ${attemptNumber} ${requestType} => [${responseStatus}]`);
                console.log(res);

                if (shouldHandleError) {
                    errorHandler.handleIncorrectExchange(res);
                }

                if (isRetryable !== false) {
                    // Make a delay between retrial requests
                    return delayExec(() => axios(res.config), DELAY_BETWEEN_RETRY * attemptNumber);
                }
            }
            _.set(res, 'config.noHandleError', true);
            _.set(res, 'message', EXCHANGE_ERROR);
            return Promise.reject(res);
        }

        return res;
    }
);

axios.interceptors.response.use(
    null,
    error => {
        if (isNetworkError(error)) {
            return Promise.reject(error);
        }

        const pingRetryInterval = userSelectors.getPingRetryInterval(getState());
        const pingRetryCount = userSelectors.getPingRetryCount(getState());
        const responseStatus = _.get(error, 'response.status');
        const configData = JSON.parse(_.get(error, 'config.data', null));
        const requestType = configData && configData.requestType;
        const responseErrorReason = _.get(error, 'response.data.reasonType');
        const isPingTokenException = responseErrorReason === errorReasons.PING_TOKEN_EXCEPTION;
        const pingReasonRetry = _.get(error, 'response.config.pingReasonRetry', 0);
        const canRetried = pingReasonRetry < pingRetryCount;

        if (isPingTokenException && canRetried && !!pingRetryInterval) {
            console.log(`RETRY Request: ${requestType} => [${responseStatus || NETWORK_ERROR}, ${responseErrorReason}]`);

            _.set(error, 'config.pingReasonRetry', pingReasonRetry + 1);

            return delayExec(() => axios(error.config), pingRetryInterval);
        }

        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    // For Chrome OAM Session Timeout 302 redirects ends with 200 response containing html login page
    // For Safari OAM Session Timeout 302 redirects end with 200 response but it doesn't mean it is really session timeout
    res => {
        if (isUploadRequest(res)) {
            return res;
        }

        const configData = JSON.parse(_.get(res, 'config.data', null));
        const requestType = configData && configData.requestType;
        const contentType = _.get(res, ['headers', 'content-type'], '');
        const data = _.get(res, 'data', '');
        const responseStatus = _.get(res, 'status');
        const attemptNumber = _.get(res, 'config.currentAttemptNumber', 1);

        if (contentType.indexOf('text/') !== -1
            && data.indexOf(OAM_LOGIN_PAGE_SIGNATURE) !== -1) {
            if (isSafari) {
                return refreshSecurityToken()
                    .then(
                        () => delayExec(() => axios(res.config), DELAY_BETWEEN_RETRY * attemptNumber),
                        () => {
                            if ((Date.now() - lastResponseSuccessTime) > SESSION_TIMEOUT) {
                                _.set(res, 'response.status', httpStatus.HTTP_401);
                                return Promise.reject(res);
                            } else if (attemptNumber <= MAX_RETRIAL_ATTEMPTS_COUNT) {
                                _.set(res, 'config.currentAttemptNumber', attemptNumber + 1);

                                console.log(`RETRY Request after Token Validity: ${attemptNumber} ${requestType} => [${responseStatus}]`);
                                console.log(res);

                                // Make a delay between retrial requests
                                return delayExec(() => axios(res.config), DELAY_BETWEEN_RETRY * attemptNumber);
                            }
                            sessionTimeout(requestType);
                            return Promise.reject(res);
                        });
            }
            sessionTimeout(requestType);
            return Promise.reject(res);
        }

        if (contentType.indexOf('text/') !== -1
            && data.indexOf(OAM_TOKEN_VALIDITY_SIGNATURE) !== -1) {
            if (attemptNumber <= MAX_RETRIAL_ATTEMPTS_COUNT) {
                _.set(res, 'config.currentAttemptNumber', attemptNumber + 1);

                console.log(`RETRY Request after Token Validity: ${attemptNumber} ${requestType} => [${responseStatus}]`);
                console.log(res);

                // Make a delay between retrial requests
                return delayExec(() => axios(res.config), DELAY_BETWEEN_RETRY * attemptNumber);
            }

            return Promise.reject(res);
        }

        userNotificationsFailCount = 0;
        lastResponseSuccessTime = Date.now();

        return res;
    },
    (error) => {
        const responseStatus = _.get(error, 'response.status');

        if (isUploadRequest(error)) {
            return Promise.reject(error);
        }

        const configData = JSON.parse(_.get(error, 'config.data', null));
        const requestType = configData && configData.requestType;
        const shouldHandleError = !_.get(error, 'config.noHandleError');
        const responseErrorReason = _.get(error, 'response.data.reasonType');
        const currentLocation = hashHistory.location;
        const searchObject = queryString.parse(currentLocation.search);
        const customSecurityId = _.get(searchObject, 'security');
        const loginUrl = _.get(error, 'response.headers.login-url');

        // SKIP CANCELLED OPERATIONS
        if (isCancel(error)) {
            return Promise.reject(error);
        }

        if (shouldHandleError) {
            errorHandler.handleXhrError(error);
        }

        // SKIP DATAPREZ (old DATAVIZ) EXPORT
        if (isDatavizExport()) {
            const errorStrategy = getErrorStrategy(error, responseStatus, { isDataViz: true });

            if (errorStrategy === errorStrategies.CRITICAL) {
                notifyWebdriverPageFail(error);
            }

            return Promise.reject(error);
        }

        // CRITICAL SECURITY/SESSION
        switch (responseStatus) {
            case httpStatus.HTTP_400: {
                if (requestType === requestTypes.loginPayload) {
                    hashHistory.push(
                        buildLocationWithSecurity(
                            `${pageRoutes.error}/${errorReasons.SESSION_EXCEPTION}`,
                            customSecurityId)
                    );
                    return Promise.reject(error);
                }
                break;
            }
            case httpStatus.HTTP_401:
                if (requestType === requestTypes.loginPayload && loginUrl) {
                    window.location = loginUrl;
                } else {
                    const pingRetryCount = userSelectors.getPingRetryCount(getState());
                    const pingReasonRetry = _.get(error, 'response.config.pingReasonRetry', 0);
                    const canRetried = pingReasonRetry <= pingRetryCount;
                    const isPingTokenException = responseErrorReason === errorReasons.PING_TOKEN_EXCEPTION;
                    const sessionIsExpired = getSessionIsExpired();

                    console.log('RETRY', { canRetried, pingReasonRetry, pingRetryCount });

                    if (!isPingTokenException || sessionIsExpired || !canRetried) {
                        sessionTimeout(requestType);
                    }
                }
                return Promise.reject(error);

            case httpStatus.HTTP_403:
                switch (responseErrorReason) {
                    case errorReasons.ONLY_PA_ACCESS:
                        window.location = window.PA_BASE_URL;
                        return Promise.reject(error);
                    case errorReasons.NO_FEATURES:
                        hashHistory.push(pageRoutes.securityList);
                        return Promise.reject(error);
                    case errorReasons.USER_STATUS_EXCEPTION:
                    case errorReasons.NO_SECURITIES:
                    case errorReasons.ROLE_NOT_FOUND:
                    case errorReasons.ISP_USER_DOES_NOT_EXIST:
                        hashHistory.push(
                            buildLocationWithSecurity(
                                `${pageRoutes.error}/${responseStatus}`,
                                customSecurityId,
                                { reason: responseErrorReason })
                        );
                        return Promise.reject(error);
                    default:
                        break;
                }
                break;

            case httpStatus.HTTP_406:
                // user has not selected security
                dispatch(userActions.setDefaultSecurity(null));
                hashHistory.push(pageRoutes.securityList);
                return Promise.reject(error);

            case httpStatus.HTTP_409:
                if (responseErrorReason === errorReasons.MULTIPLE_WIDGET_CONFLICT) {
                    dispatch(showMultipleWidgetConflictModal());
                } else {
                    return dispatch(userActions.refreshCurrentPage())
                        .then(() => Promise.reject(error));
                }
                break;

            default:
                break;
        }

        // HEART-BEAT AND SESSION TIMEOUTS
        if (requestType === requestTypes.getUserNotificationsPayload && isNetworkError(error)) {
            userNotificationsFailCount++;

            return refreshSecurityToken()
                .then(
                    () => Promise.reject(error),
                    () => {
                        if (userNotificationsFailCount >= MAX_USER_NOTIFICATIONS_FAIL_COUNT) {
                            if ((Date.now() - lastResponseSuccessTime) < SESSION_TIMEOUT) {
                                hashHistory.push(buildLocationWithSecurity(`${pageRoutes.error}/NETWORK_ERROR`, customSecurityId));
                                userNotificationsFailCount = 0;
                            } else {
                                sessionTimeout(requestType);
                            }
                        }
                        return Promise.reject(error);
                    });
        }

        // CUSTOM HANDLER
        const errorStrategy = getErrorStrategy(error, responseStatus);
        const withoutNotification = _.get(error, 'config.withoutNotification');

        switch (errorStrategy) {
            case errorStrategies.SKIP:
                // SKIP
                break;

            case errorStrategies.CRITICAL:
                if (isNetworkError(error) || isExchangeError(error)) {
                    hashHistory.push(buildLocationWithSecurity(`${pageRoutes.error}/NETWORK_ERROR`, customSecurityId));
                } else {
                    // ERROR PAGE REDIRECT
                    const responseErrorQueryParam = responseErrorReason ? { reason: responseErrorReason } : {};
                    const pathname = `${pageRoutes.error}/${responseStatus || 'NO_RESPONSE'}`;
                    const toLocation = buildLocationWithSecurity(pathname, customSecurityId, responseErrorQueryParam);

                    // see constants/error-reasons.js
                    hashHistory.push(toLocation);
                }
                break;

            case errorStrategies.REDIRECT_HOME:
                // REDIRECT HOME
                hashHistory.push(buildLocationWithSecurity(pageRoutes.index, customSecurityId));

                // SHOW NOTIFICATION
                if (!withoutNotification) {
                    showNotification(error, requestType);
                }
                break;

            default:
                // SHOW NOTIFICATION
                showNotification(error, requestType);
                break;
        }

        return Promise.reject(error);
    });

axios.interceptors.response.use(
    res => {
        cancelTokensStorage.remove(_.get(res, 'config.originalCancelToken'));
        return res;
    },
    error => {
        cancelTokensStorage.remove(_.get(error, 'config.originalCancelToken'));
        return Promise.reject(error);
    });

function getErrorStrategy(error, responseStatus, options) {
    let errorStrategy = _.get(error, 'config.errorStrategy');

    if (errorStrategy && typeof errorStrategy === 'function') {
        errorStrategy = errorStrategy(error, responseStatus, options);
    }
    return errorStrategy;
}

function sessionTimeout(requestType) {
    const logoutRequestTypes = [
        requestTypes.loginPayload,
        requestTypes.getUserInfoPayload,
        requestTypes.acceptUserTerms
    ];

    if (logoutRequestTypes.indexOf(requestType) >= 0) {
        // this is necessary only for STUB login page.
        forceLogout();
    } else {
        dispatch(showSessionTimeoutModal());
    }

    errorHandler.handleSessionTimeout();
}

function isNetworkError(error) {
    return _.get(error, 'message') === NETWORK_ERROR;
}

function isExchangeError(error) {
    return _.get(error, 'message') === EXCHANGE_ERROR;
}

function showNotification(error, requestType) {
    let errorMessageKey;

    switch (requestType) {
        case requestTypes.widgetDataPayload:
        case requestTypes.dataVizWidgetDataPayload:
        case requestTypes.getReportTemplatesPayload:
        case requestTypes.allCountriesPayload:
        case requestTypes.statesPayload:
        case requestTypes.getTotalOutstandingSharesByDatePayload:
            errorMessageKey = 'error.errorRetrievingData';
            break;
        case requestTypes.searchPayload:
            errorMessageKey = 'error.serverError.message';
            break;
        case requestTypes.changeReportTemplatePayload:
            errorMessageKey = 'error.changeTemplate.message';
            break;
        case requestTypes.saveReportTemplatePayload:
            errorMessageKey = 'error.saveTemplate.message';
            break;
        case requestTypes.deleteReportTemplatePayload:
            errorMessageKey = 'error.deleteTemplate.message';
            break;
        default:
            errorMessageKey = 'error.requestFailed';
    }

    dispatch(notifyError(null, null, {
        component: () => (<span className='error-message'>
            <FormattedHTMLMessage id={errorMessageKey}/>
        </span>)
    }));
}

if (__MOCK_DATA__) {
    require('../mocks').initialize(axios);
}

export default axios;
