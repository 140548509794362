import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const MIN_WIDTH = 0.65;

const barChart = ({ value, hide }) => {
    return (
        <div
            className={cn(
                'bar-chart',
                {
                    'hide-bar-chart': hide
                })}
            style={{
                width: `${!value || value > MIN_WIDTH ? value : MIN_WIDTH}%`
            }}/>
    );
};

barChart.propTypes = {
    hide: PropTypes.bool,
    value: (props, propName, componentName) => {
        const value = props[propName];

        if (value !== undefined &&
            !(typeof value === 'number') || value < 0 || value > 100) {
            return new Error(
                `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Value should be from 0 to 100.`
            );
        }
    }
};

export default barChart;
