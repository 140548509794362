import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import widgetModes from 'Constants/widget-modes';
import reportTypes from 'Constants/report-types';
import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import Widget from '../../Widget';
import ShareholderWidgetControlPanel from '../../Widget/components/ShareholderWidgetControlPanel';
import { showExportWidgetModal } from 'State/exportReports';

import './Performance.scss';

const mapDispatchToProps = { showExportWidgetModal };

@connect(null, mapDispatchToProps)
class Performance extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        showExportWidgetModal: PropTypes.func,
        intl: intlShape.isRequired
    };

    constructor(props) {
        super(props);

        this.widgetTitle = props.intl.formatMessage({ id: 'performance.title' });
        this.widgetComponents = {
            ...props.widgetComponents,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel
        };
        this.widgetEvents = {
            onBuildDataParams: ({ isAddToDataViz }) => {
                if (isAddToDataViz) return {};

                return {
                    period: _.get(props, 'widgetSettings.period')
                };
            },
            onExport: this.handleExport
        };
    }

    handleExport = () => {
        const { widgetSettings } = this.props;
        const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
        const period = _.get(widgetSettings, 'period');

        this.props.showExportWidgetModal({
            reportTemplateType: reportTypes.performanceTableTemplate,
            exportParams: {
                period,
                sortCriteria: {
                    sortDirection: sortCriteria.direction,
                    sortField: sortCriteria.field
                }
            },
            modalViewParams: {
                showExcelLimit: true
            }
        });
    };

    render() {
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-performance',
                widgetTableTypeIcon: 'font-icon-view-list',
                isWidgetTypeIconActive: true
            },
            isExportEnabled: _.get(this.props, 'widgetSettings.mode') === widgetModes.TABLE,
            widgetNoData: !_.get(this.props, 'widgetData.values.length')
        };

        return (
            <Widget
                {...props}
                className='widget-performance'
                components={this.widgetComponents}
                events={this.widgetEvents}/>
        );
    }
}

export default Performance;
