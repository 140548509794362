import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import TableEasy from 'Components/Tables/TableEasy';
import { MillionChangeFormatter, NullableFormatter } from 'Components/formatters';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';

class AnalysisInvestorTypeContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        hideBarChart: PropTypes.bool
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = props;

        this.columns = [
            {
                dataKey: 'investorType',
                title: formatMessage({
                    id: 'analysis.investor.type.investorType',
                    defaultMessage: 'INVESTOR TYPE'
                }),
                bodyCellComponent: v => <NullableFormatter {...v} withTitle/>
            },
            {
                dataKey: 'os',
                title: formatMessage({
                    id: 'analysis.investor.type.os',
                    defaultMessage: '% OS'
                }),
                maxWidth: 55,
                columnClassName: 'text-right',
                bodyCellComponent: TotalValueOsCell
            },
            {
                dataKey: 'change',
                title: formatMessage({
                    id: 'analysis.investor.type.change',
                    defaultMessage: 'CHANGE'
                }),
                columnClassName: 'text-right',
                bodyCellComponent: MillionChangeFormatter
            }
        ];
    }

    render() {
        const { widgetData: { aggregate, values }, widgetFooterProps, widgetNoData, hideBarChart, isFetching, isPrintMode, isDataViz } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        widgetFooterProps={widgetFooterProps}
                        className='widget-table'
                        hideBarChart={hideBarChart}
                        columns={this.columns}
                        data={values}
                        renderAllRows={isDataViz}
                        showAllRows={isPrintMode}
                        aggregate={aggregate}/>
                </NoDataComponent>
            </div>
        );
    }
}

export default AnalysisInvestorTypeContent;
