export default [
    {
        key: 'modals.analysis.turnover.table.veryLow',
        value: '< 0.0625'
    },
    {
        key: 'modals.analysis.turnover.table.low',
        value: '0.0625 - 0.12499'
    },
    {
        key: 'modals.analysis.turnover.table.medium',
        value: '0.125 - 0.24999'
    },
    {
        key: 'modals.analysis.turnover.table.high',
        value: '0.25 - 0.5'
    },
    {
        key: 'modals.analysis.turnover.table.veryHigh',
        value: '> 0.5'
    }
];
