import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DynamicPage } from '../../components/DynamicPage';
import InstitutionProfilePageHeader from './containers/InstitutionProfilePageHeader';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from './slots';

import './InstitutionProfile.scss';

class InstitutionProfile extends Component {
    static propTypes = {
        match: PropTypes.object
    };

    render() {
        const dynamicPageType = PAGE_TYPES.INSTITUTION_PROFILE;

        return (
            <div className='institution-profile-page'>
                <InstitutionProfilePageHeader/>
                <DynamicPage
                    layoutType={layoutTypes.layoutMultiSlots}
                    slots={slots}
                    dynamicPageType={dynamicPageType}
                    params={this.props.match.params}/>
            </div>
        );
    }
}

export default InstitutionProfile;
