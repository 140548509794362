import './api.interceptors';
import store from './store';
import errorHandler from './errorHandler';
import logger from './logger';

export default {
    store,
    errorHandler,
    logger
};
