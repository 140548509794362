import { compose } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import SearchByOption from './SearchByOption';
import { allowToSelectPIIGetter, isFetchingAny } from 'State/advancedSearch';

export default compose(
    injectIntl,
    connect((state, { value }) => ({
        disabled: !allowToSelectPIIGetter(state)(value) || isFetchingAny(state)
    }))
)(SearchByOption);
