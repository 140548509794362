import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import { FormattedNumber, FormattedMessage, intlShape } from 'react-intl';
import _ from 'lodash';

import { colorizeValues } from 'Utils/colorize';
import { NullableFormatter, NumberFormatter } from 'Components/formatters';
import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import { stickyFooter } from 'Components/Tables/TableEasy';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { getMarkedData, markIncludesCeDeCoRows } from 'Components/widgets/utils';
import { labelCell } from './cellRenderer';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';

const RegisteredHolderTypeChartView = (props) => {
    const { pieData, widgetFooterProps, outstandingSharesTotal, legendData, isDataViz, intl } = props;

    return (
        <div>
            <div className='pie-content-container'>
                <PieChart
                    data={pieData}
                    customConfig={getPieTooltipConfig(intl)}
                    total={legendData.aggregate.osTotal}/>
            </div>
            <div className='os-total'>
                <FormattedMessage id={'registered.holder.type.totalOs'}>
                    {(text) => (
                        <span className='os-total-text'>{text}</span>
                    )}
                </FormattedMessage>
                <NullableFormatter value={outstandingSharesTotal}>
                    <FormattedNumber value={outstandingSharesTotal}/>
                </NullableFormatter>
            </div>
            <div className='legend-container'>
                <LegendGrid
                    widgetFooterProps={widgetFooterProps}
                    renderAllRows={isDataViz}
                    footerComponent={stickyFooter}
                    headerRowHeight={24}
                    totalMessageId='tableEasy.priorCOBTotal'
                    {...legendData}/>
            </div>
        </div>
    );
};


RegisteredHolderTypeChartView.propTypes = {
    pieData: PropTypes.array,
    outstandingSharesTotal: PropTypes.number,
    legendData: PropTypes.object,
    isDataViz: PropTypes.bool,
    intl: intlShape,
    widgetFooterProps: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { widgetNoData, widgetFooterProps, widgetSettings, intl: { formatMessage } } = props;
        const colorSchemeId = _.get(widgetSettings, 'colorScheme');
        const aggregate = _.get(props, 'widgetData.chartView.aggregate');
        const chartValues = _.get(props, 'widgetData.chartView.values');
        const outstandingSharesTotal = _.get(aggregate, 'outstandingSharesTotal');
        let pieData;
        let legendData;

        if (!widgetNoData) {
            const markedValues = getMarkedData(chartValues, [markIncludesCeDeCoRows], widgetFooterProps);
            const colorizedValues = colorizeValues(markedValues, colorSchemeId);

            pieData = colorizedValues.map(value => ({
                name: value.holderType,
                y: value.os,
                color: value.color,
                tooltipValue: value.os
            }));
            legendData = {
                columns: [
                    {
                        dataKey: 'holderType',
                        title: formatMessage({ id: 'registered.holder.type.holderType' }),
                        bodyCellComponent: labelCell
                    },
                    {
                        dataKey: 'os',
                        columnClassName: 'text-right',
                        maxWidth: 55,
                        title: formatMessage({ id: 'registered.holder.type.os' }),
                        bodyCellComponent: TotalValueOsCell
                    },
                    {
                        dataKey: 'shareholdersCount',
                        columnClassName: 'text-right',
                        title: formatMessage({ id: 'registered.holder.type.shareholdersCount' }),
                        bodyCellComponent: NumberFormatter
                    }
                ],
                data: colorizedValues,
                aggregate
            };
        }

        return { ...props, pieData, outstandingSharesTotal, legendData };
    })
)(RegisteredHolderTypeChartView);
