import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import widgetModes from 'Constants/widget-modes';
import RegisteredHolderTypeChartView from './RegisteredHolderTypeChartView';
import RegisteredHolderTypeTableView from './RegisteredHolderTypeTableView';
import NoDataComponent from '../../Widget/components/NoDataComponent';

class RegisteredHolderTypeContent extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        tableView: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.fullReport = this.props.intl.formatMessage({ id: 'widgets.link.fullReport' });
    }

    render() {
        const { widgetSettings: { mode }, isFetching, widgetNoData, widgetFooterProps } = this.props;
        const props = { ...this.props };

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <div>
                        {
                            mode === widgetModes.CHART ?
                                (<RegisteredHolderTypeChartView
                                    {...props}
                                    widgetFooterProps={widgetFooterProps}/>) :
                                (<RegisteredHolderTypeTableView
                                    {...props}
                                    widgetFooterProps={widgetFooterProps}/>)
                        }
                    </div>
                </NoDataComponent>
            </div>
        );
    }
}

export default RegisteredHolderTypeContent;
