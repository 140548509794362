import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
// import frLocaleData from 'react-intl/locale-data/fr';
import intlLocalesSupported from 'intl-locales-supported';
import context from './context';
import { initializeGTM } from './utils/gtmTags';

import './app.scss';

initializeGTM(window.GTM, window.GTM_PARAMS);

addLocaleData([...enLocaleData/* , ...frLocaleData */]);

const areIntlLocalesSupported = intlLocalesSupported(['en'/* , 'fr' */]);


const render = () => {
    // Re-require application root the only way to update view
    // after HMR
    const Root = require('./app.root').default;

    ReactDOM.render(
        <Provider store={context.store}>
            <PersistGate loading={null} persistor={context.store.persistor}>
                <Root locale={DEMO_MODE ? 'en-GB' : 'en'}/>
            </PersistGate>
        </Provider>,
        document.getElementById('app')
    );
};

const enableHMR = () => {
    if (module.hot) {
        module.hot.accept(undefined, () => {
            render();
        });
    }
};

// If browser doesn't support Intl (i.e. Safari), APIs are missing, or if the built-in Intl is missing locale data (i.e. IE10),
// then we manually import the intl polyfill and locale data.
if (!areIntlLocalesSupported) {
    Promise.all([
        import(/* webpackChunkName: "intl-polyfill" */ 'intl'),
        import(/* webpackChunkName: "intl-polyfill" */ 'intl/locale-data/jsonp/en')
        // import(/* webpackChunkName: "intl-polyfill" */ 'intl/locale-data/jsonp/fr'),
    ]).then(([{ default: IntlPolyfill }]) => {
        if (!window.Intl) {
            window.Intl = IntlPolyfill;
        }

        window.Intl.NumberFormat = IntlPolyfill.NumberFormat;
        window.Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;

        render();
        enableHMR();
    });
} else {
    render();
    enableHMR();
}
