import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';

import { RadioButton } from 'Components/RadioButton';
import { withFormInput } from '../../hoc';

class RadioButtonField extends Component {
    static propTypes = {
        enhancer: PropTypes.func
    };

    static defaultProps = {
        enhancer: withFormInput
    };

    constructor(props) {
        super(props);
        this.enhancer = this.props.enhancer(RadioButton);
    }

    render() {
        const props = _.omit(this.props, ['enhancer']);

        return (
            <Field
                {...props}
                component={this.enhancer}
                type='radio'/>);
    }
}

export default RadioButtonField;
