import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape, injectIntl } from 'react-intl';

import LinkEx from 'Components/LinkEx';

@injectIntl
class ContactsTableHeaderTabs extends Component {
    static propTypes = {
        intl: intlShape,
        links: PropTypes.arrayOf(PropTypes.shape({
            labelKey: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired
        })).isRequired,
        isShareholderId: PropTypes.bool
    };

    _renderLinks() {
        const { links, intl: { formatMessage }, isShareholderId } = this.props;

        return links.map((link) => {
            return (<LinkEx
                key={link.labelKey}
                activeClassName={isShareholderId ? 'active' : 'active disabled'}
                className='tab'
                to={link.to}>
                {formatMessage({ id: link.labelKey })}
            </LinkEx>);
        });
    }

    render() {
        return (
            <div className='contacts-table-links-panel'>
                {this._renderLinks()}
            </div>
        );
    }
}

export default ContactsTableHeaderTabs;
