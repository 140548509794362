export const DATA_SOURCE_ADD_OR_SAVE = 'DATA_SOURCE_ADD_OR_SAVE';
export const DATA_SOURCE_REMOVE = 'DATA_SOURCE_REMOVE';

/**
 * Add or save data or many data.
 * @param {Object|Array} dataSource - array or single data
 * @param {String} dataSource.dataSourceId - id data source
 * @param {Object} dataSource.data - data
 */
export const dataSourceAddOrSave = (dataSource) => ({
    type: DATA_SOURCE_ADD_OR_SAVE,
    payload: dataSource
});

/**
 * Remove data or many data
 * @param {String|Array} id - array or single data source id
 */
export const dataSourceRemove = (id) => ({
    type: DATA_SOURCE_REMOVE,
    payload: id
});
