import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class SearchSubmitButton extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        submit: PropTypes.func
    };
    static defaultProps = {
        disabled: false
    };

    render() {
        return (<button
            type='submit'
            className='btn'
            disabled={this.props.disabled}>
            <FormattedMessage id='common.search'/>
        </button>);
    }
}

export default SearchSubmitButton;
