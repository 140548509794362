import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { LazyPopover } from 'Components/Popover';

import './TopInvestorsTooltip.scss';

const TopInvestorsTooltip = ({ title }) => {
    const trigger = (
        <span>{title}</span>
    );
    const popoverProps = {
        id: 'TOP-INVESTORS-POPOVER',
        trigger,
        triggerType: ['hover'],
        placement: 'top'
    };

    return (
        <LazyPopover {...popoverProps}>
            <div className='top-investors-tooltip'>
                <FormattedMessage id='tables.headingWithTopInvestors.tooltip'/>
            </div>
        </LazyPopover>
    );
};

TopInvestorsTooltip.propTypes = {
    title: PropTypes.string
};

export default TopInvestorsTooltip;
