export const SET_FEATURES = 'SET_FEATURES';
export const SET_PRODUCT_SOURCES = 'SET_PRODUCT_SOURCES';

export const setFeatures = (features) => ({
    type: SET_FEATURES,
    payload: features
});

export const setProductSources = (productSources) => ({
    type: SET_PRODUCT_SOURCES,
    payload: productSources
});
