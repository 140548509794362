import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { get } from 'lodash';

import { PercentFormatter } from 'Components/formatters';

const CellWithPercent = (props) => {
    const { value, className } = props;
    const cellClass = cn('cell-with-percent', className);
    const withTitle = get(props, 'additionalData.withTitle');
    const titleValue = get(props, 'additionalData.titleValue');

    return (
        <div className={cellClass}>
            <PercentFormatter value={value} titleValue={titleValue} withTitle={withTitle}/>
        </div>
    );
};

CellWithPercent.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    additionalData: PropTypes.object
};

export default CellWithPercent;
