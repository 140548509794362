import { createReducer } from 'Utils/reducer';
import {
    SET_DROPZONE_INPUT_PROPS,
    UPLOAD_DOCUMENT_FETCHING
} from './actions';

const initialState = {
    inputProps: {},
    isFetching: false
};

const actionHandlers = {
    [SET_DROPZONE_INPUT_PROPS]: (state, { payload }) => ({
        ...state,
        inputProps: payload
    }),
    [UPLOAD_DOCUMENT_FETCHING]: (state, { payload }) => ({
        ...state,
        isFetching: payload
    })
};

export default createReducer(initialState, actionHandlers);
