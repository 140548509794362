import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import _ from 'lodash';

import TextInput from '../../TextInput';

const MAX_RECORDS = 1048576; // maximum rows in worksheet

export default class NoOfRecordsField extends Component {
    static propTypes = {
        input: PropTypes.shape(fieldInputPropTypes),
        meta: PropTypes.shape(fieldMetaPropTypes)
    };

    static parse = value => {
        const onlyDigitsString = _.isString(value) && value.replace(/^[0]+|\D+/g, '');

        if (onlyDigitsString === '') {
            return null;
        }
        const integerValue = _.toInteger(onlyDigitsString);

        return integerValue > MAX_RECORDS ? MAX_RECORDS : integerValue;
    };

    render() {
        const { input, meta } = this.props;

        return (
            <TextInput
                {...input}
                error={meta.touched && meta.error}/>
        );
    }
}
