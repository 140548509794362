import ShareholderTypeField from './ShareholderTypeField';
import AccountsField from './AccountsField';
import SharesOwnedField from './SharesOwnedField';
import ColumnsToDisplayField from './ColumnsToDisplayField';
import SortByField from './SortByField';
import NoOfRecordsField from './NoOfRecordsField';
import ShareholderLocationField from './ShareholderLocationField';
import DateOfLastContactField from './DateOfLastContactField';
import AddressStatusField from './AddressStatusField';

export default {
    ShareholderTypeField,
    AccountsField,
    SharesOwnedField,
    ColumnsToDisplayField,
    SortByField,
    NoOfRecordsField,
    ShareholderLocationField,
    DateOfLastContactField,
    AddressStatusField
};
