import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import './Spinner.scss';

class Spinner extends React.Component {
    static propTypes = {
        holderTag: PropTypes.string,
        contentTag: PropTypes.string,
        isFetching: PropTypes.bool,
        isBlocker: PropTypes.bool,
        loadingMessageKey: PropTypes.string,
        className: PropTypes.string
    };

    static defaultProps = {
        holderTag: 'div',
        contentTag: 'div'
    };

    render() {
        const SpinnerTag = this.props.holderTag;
        const ContentTag = this.props.contentTag;
        const { isFetching, isBlocker, loadingMessageKey = 'common.loading' } = this.props;
        const className = classNames('spinner', { 'blocker': isBlocker, 'loading': isFetching }, this.props.className);

        return (
            <SpinnerTag className={className}>
                {
                    isFetching ?
                        <ContentTag className='spinner-content'>
                            <FormattedMessage
                                id={loadingMessageKey}
                                defaultMessage='LOADING'/>
                            <div className='spinner-block'>
                                <div className='spinner-item' />
                            </div>
                        </ContentTag> : null
                }
            </SpinnerTag>);
    }
}
export default Spinner;
