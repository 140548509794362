import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Spinner  from '../Spinner';
import * as selectors from 'State/loadingBlock/selectors';

import './LoadingBlock.scss';

const mapStateToProps = (state) => ({
    isFetching: selectors.appIsFetchingSelector(state)
});

@connect(mapStateToProps)
class LoadingBlock extends React.Component {
    static propTypes = {
        isFetching: PropTypes.bool
    };

    render() {
        const props = {
            isFetching: this.props.isFetching,
            loadingMessageKey: 'loadingBlock.message',
            isBlocker: true,
            className: 'app-spinner'
        };

        return (
            <Spinner {...props} />
        );
    }
}
export default LoadingBlock;
