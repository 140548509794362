import { createSelector } from 'reselect';
import _ from 'lodash';

const getFundsById = (state, shareholderId) => _.get(state, ['shareholderTable', 'funds', shareholderId]);
const getPositionsById = (state, shareholderId) => _.get(state, ['shareholderTable', 'positions', shareholderId]);
const getExpandedShareholders = (state) => _.get(state, ['shareholderTable', 'expandedShareholders']);
const getFundsRequestErrorById = (state, shareholderId) => _.get(state, ['shareholderTable', 'fundsErrors', shareholderId]);
const getPositionsRequestErrorById = (state, shareholderId) => _.get(state, ['shareholderTable', 'positionsErrors', shareholderId]);

export const getInnerFetchingSelector = state => {
    const positionsFetching = _.get(state, 'shareholderTable.isFetchingPositions');
    const fundsFetching = _.get(state, 'shareholderTable.isFetchingFunds');

    return (
        !_.every(_.toArray(positionsFetching), position => !position) || !_.every(_.toArray(fundsFetching), fund => !fund)
    );
};

export const getFundsByIdSelector = createSelector(
    getFundsById,
    funds => funds
);

export const getPositionsByIdSelector = createSelector(
    getPositionsById,
    positions => positions
);

export const getExpandedShareholdersSelector = createSelector(
    getExpandedShareholders,
    expandedShareholders => expandedShareholders
);

const valuesEmpty = [];

export const getFilteredTableValuesSelector = createSelector(
    (values) => values || valuesEmpty,
    (values, displayTypes) => displayTypes,
    (values, displayTypes = []) => {
        const showAll = displayTypes.length === 0;

        return values.filter((value) => {
            return showAll ? true : displayTypes.some((type) => {
                const shareholderType = value.shareholderType;

                if (!_.isString(type) || !_.isString(shareholderType)) return false;

                return type.toUpperCase() === shareholderType.toUpperCase();
            });
        });
    }
);

export const getFundsRequestErrorByIdSelector = createSelector(
    getFundsRequestErrorById,
    error => error
);

export const getPositionsRequestErrorByIdSelector = createSelector(
    getPositionsRequestErrorById,
    error => error
);

const getContactsById = (state, shareholderId) => _.get(state, ['shareholderTable', 'contacts', shareholderId], []);

export const getContactsByIdSelector = createSelector(
    getContactsById,
    contacts => contacts
);

const getContactsErrorsById = (state, shareholderId) => _.get(state, ['shareholderTable', 'contactsErrors', shareholderId]);

export const getContactsRequestErrorByIdSelector = createSelector(
    getContactsErrorsById,
    error => error
);

const getContactsIsFetchingById = (state, shareholderId) => _.get(state, ['shareholderTable', 'isFetchingContacts', shareholderId]);

export const getContactsRequestIsFetchingByIdSelector = createSelector(
    getContactsIsFetchingById,
    isLoading => isLoading
);

export const getShareholdersTableData = (state) => _.get(state, ['shareholderTable', 'data']);

export const getShareholderIsFetching = (state) => _.get(state, ['shareholderTable', 'isShareholdersFetching']);

export const getShareholdersIsError = (state) => _.get(state, ['shareholderTable', 'isShareholdersError']);
