import _ from 'lodash';

class CancelTokensStorage {
    _tokens = [];

    add(cancelToken) {
        this._tokens = [...this._tokens, cancelToken];
    }

    remove(cancelToken) {
        this._tokens = _.filter(this._tokens, token => token !== cancelToken);
    }

    cancelAll() {
        this._tokens.forEach(token => token.cancel());
        this._tokens = [];
    }
}

export default new CancelTokensStorage();
