import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedNumber } from 'react-intl';
import { getContext, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import cn from 'classnames';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { hideModal, showColumnManagerModal, showExportShareholderTableModal } from 'State/modal';
import ViewsGroup from '../ViewsGroup';
import { getCurrentViewId, resolveSortingViewId } from '../../utils';
import shareholderDefaultViews from '../../constants/defaultViews';
import { CheckBox } from 'Components/CheckBox';
import { default as shareTypes } from 'Constants/shareholder-types';
import columnManagerMode from '../../constants/shareholder-column-manager-mode';
import { getFilteredTableValuesSelector } from 'State/shareholderTable/selectors';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';
import { DateFormatter } from 'Components/formatters';
import WidgetAddToReport from 'Components/widgets/Widget/components/WidgetAddToReport/WidgetAddToReport';
import WidgetRemove from 'Components/widgets/Widget/components/WidgetRemove';
import Icon from 'Components/Icon';
import featureTypes from 'Constants/feature-types';
import { localeCurrency } from 'Constants/currency';
import { someRegisteredShareholder } from 'Utils/shareholders';
import RegisteredShareholdersNote from 'Components/widgets/_commonComponents/RegisteredShareholdersNote';

import './ShareholderActionPanel.scss';

const mapStateToProps = (state) => ({
    blockAction: getDynamicPageBlockActionSelector(state),
    userFeatures: getCurrentFeaturesSelector(state),
    ...state.modal
});
const mapDispatchToProps = {
    hideModal,
    showColumnManagerModal,
    showExportShareholderTableModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@getContext({
    events: PropTypes.object.isRequired
})
@mapProps(props => ({
    ...props,
    onChangeView: props.events.onChangeView
}))
class ShareholderActionPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        events: PropTypes.object,
        showColumnManagerModal: PropTypes.func.isRequired,
        showExportShareholderTableModal: PropTypes.func.isRequired,
        hideModal: PropTypes.func.isRequired,
        sortedValues: PropTypes.array,
        availableDisplayTypes: PropTypes.array,
        blockAction: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.formatMessage = props.intl.formatMessage;
    }

    UNSAFE_componentWillMount() {
        this.setAvailableCurrentView();
    }

    handleActionExport = () => {
        const { sortedValues, availableDisplayTypes, availableShareholderTypesList, widgetData: { cedeCo } = {} } = this.props;

        this.props.showExportShareholderTableModal({
            titleKey: 'modals.exportReport.title',
            exportParams: {
                widgetName: this.props.widgetName,
                shareholderIds: sortedValues.map(value => value.shareholderId),
                availableDisplayTypes,
                availableShareholderTypesList,
                cedeCo
            }
        });
    };

    handleChangeView = (view) => {
        const settings = _.cloneDeep(this.props.widgetSettings);
        const prevCurrentViewId = getCurrentViewId(settings);

        if (this.props.onChangeView) {
            this.props.onChangeView({
                settings,
                view
            });
        }

        settings.views.currentViewId = view;

        const sortingViewId = resolveSortingViewId(view);

        if (_.has(settings, `viewsSortCriteria.${sortingViewId}`)) {
            settings.sortCriteria = { ...settings.viewsSortCriteria[sortingViewId] };
        }

        if (view === shareholderDefaultViews.PROXY || prevCurrentViewId === shareholderDefaultViews.PROXY) {
            settings.expandedInstitutions = {};
        }

        this.props.onUpdateWidgetSettings(settings);
    };

    handleActionSetting = (mode, isParentModal) => {
        this.props.showColumnManagerModal({
            titleKey: 'modals.columnsManagerModal.title',
            viewTextKey: 'modals.columnsManagerModal.viewText',
            saveTextKey: 'modals.columnsManagerModal.saveText',
            cancelTextKey: 'common.cancel',
            dummyCustomViewsTextKey: 'modals.columnsManagerModal.dummyCustomViewsText',
            widgetName: this.props.widgetName,
            isParentModal,
            mode,
            onCancel: () => {
                this.props.hideModal();
            }
        });
    };

    handleToggleCEDE = () => {
        const { widgetSettings = {} } = this.props;

        this.props.onUpdateWidgetSettings({
            ...widgetSettings,
            showCEDE: !widgetSettings.showCEDE
        });
    };

    handleSwitchToCreateMode = () => {
        this.handleActionSetting(columnManagerMode.CREATE);
    };

    handleSwitchToEditMode = () => {
        document.activeElement.blur(); // we must remove focus from Selector because ReactModal return focus on last active element after closing modal window
        this.handleActionSetting(columnManagerMode.EDIT, true);
    };

    setAvailableCurrentView = () => {
        const { widgetSettings, onUpdateWidgetSettings, userFeatures } = this.props;
        const { views: { currentViewId, defaultViews = {}, customViews = {} } = {} } = widgetSettings;
        const currentDefaultView = defaultViews[currentViewId];

        if (customViews[currentViewId]) {
            return;
        }

        // Always shows REGISTERED view for DEMO
        if (DEMO_MODE && currentViewId !== shareholderDefaultViews.REGISTERED) {
            onUpdateWidgetSettings({
                ...widgetSettings,
                views: {
                    ...widgetSettings.views,
                    currentViewId: shareholderDefaultViews.REGISTERED
                }
            });

            return;
        }

        if (!(currentDefaultView && userFeatures[currentDefaultView.featureType])) {
            const availableCurrentView = _.findKey(defaultViews, view => userFeatures[view.featureType]);

            if (availableCurrentView) {
                onUpdateWidgetSettings({
                    ...widgetSettings,
                    views: {
                        ...widgetSettings.views,
                        currentViewId: availableCurrentView
                    }
                });
            }
        }
    };

    _renderRegisteredAdditionalInfo = () => {
        if (!this.props.widgetData) return null;

        const cobDate = _.get(this.props.widgetData, 'aggregate.cobDate');
        const marketDataLastDate = _.get(this.props.widgetData, 'aggregate.marketDataLastDate');
        const price = _.get(this.props.widgetData, 'aggregate.price');
        const { widgetSettings: { showCEDE } = {}, widgetData: { cedeCo } = {}, availableDisplayTypes } = this.props;
        const cobDateFormatted = cobDate && (<DateFormatter value={cobDate}/>);
        const marketDataLastDateFormatted = marketDataLastDate && (<DateFormatter value={marketDataLastDate}/>);
        const isRegisteredFilter = availableDisplayTypes.length === 1 && availableDisplayTypes[0] === shareTypes.REGISTERED;

        const allDataAsOfMarketClose = this.formatMessage({ id: 'shareholders.table.panel.all.data.as.of.market.close' });
        const checkBoxLabel = `${this.formatMessage({ id: 'shareholders.table.panel.show' })} CEDE & Co.`;
        const priceLabel = this.formatMessage({ id: 'shareholders.table.panel.price' });
        const cobLabel = this.formatMessage({ id: 'shareholders.table.panel.cob' });

        return (
            <div className='additional-info'>
                {cedeCo && isRegisteredFilter && <CheckBox
                    onChange={this.handleToggleCEDE}
                    label={checkBoxLabel}
                    checked={showCEDE}
                    className='show-cede-and-co'/>}
                {cobDateFormatted &&
                <span className='message-note'>{cobLabel} {cobDateFormatted}</span>
                }
                {_.isNumber(price) &&
                <span className='message-note'>{priceLabel}:&nbsp;<FormattedNumber
                    value={price}
                    style='currency'
                    currency={localeCurrency[this.props.intl.locale].code}/> {allDataAsOfMarketClose} {marketDataLastDateFormatted}
                </span>
                }
            </div>
        );
    };

    _renderRegisteredShareholdersNote = () => {
        if (!this.props.widgetData) return null;

        const { widgetData: { values } } = this.props;

        const hasRegistered = someRegisteredShareholder(values);

        if (!hasRegistered) return null;

        return <RegisteredShareholdersNote />;
    };

    transformViews = (views, isCustom = false) => {
        return Object.keys(views).filter(key => {
            const view = views[key];

            return isCustom ? view.name : view.name && this.props.userFeatures[view.featureType];
        }).map(key => {
            const name = views[key].name;

            return {
                value: key,
                title: !isCustom ? this.formatMessage({ id: name }) : name
            };
        });
    };

    _renderMessageNote = () => {
        const currentViewId = getCurrentViewId(this.props.widgetSettings);

        const isRegisteredView = currentViewId === shareholderDefaultViews.REGISTERED;
        const isProxyView = currentViewId === shareholderDefaultViews.PROXY;
        const maxShareholdersNoteText = this.formatMessage({ id: 'shareholders.table.panel.maxShareholders.note' });
        const maxInvestorNoteText = this.formatMessage({ id: 'shareholders.table.panel.maxInvestor.note' });

        if (isProxyView) {
            return <div className='message-note text-left' title={maxInvestorNoteText}>{maxInvestorNoteText}</div>;
        }

        return (
            <>
                {isRegisteredView && this._renderRegisteredAdditionalInfo()}
                <div className='message-note text-left' title={maxShareholdersNoteText}>{maxShareholdersNoteText}</div>
            </>
        );
    }

    render() {
        const { views: { currentViewId, defaultViews = {}, customViews = {} } } = this.props.widgetSettings;
        const {
            blockAction,
            isDataViz, isPrintMode,
            onRemoveWidget,
            availableDisplayTypes,
            sortedValues = [],
            currentFeatures
        } = this.props;
        const createLink = (<span className='create-link' onClick={this.handleSwitchToEditMode}>
            {this.formatMessage({ id: 'shareholders.table.panel.create.custom.view' })}
        </span>);
        const isProxyView = currentViewId === shareholderDefaultViews.PROXY;
        const widgetNoData = !getFilteredTableValuesSelector(sortedValues, availableDisplayTypes).length;
        const exportIconClass = cn('font-icon font-icon-download', { inactive: widgetNoData });
        const customViewsDisabled = isProxyView && defaultViews.length === 1;

        return (
            <div className='action-panel'>
                <div className='actions-container'>
                    <div className='message-notes-container'>
                        {this._renderMessageNote()}
                    </div>
                    {!isPrintMode &&
                    <div className='actions-group'>
                        {!DEMO_MODE && <ViewsGroup
                            activeView={currentViewId}
                            onChange={this.handleChangeView}
                            defaultViews={this.transformViews(defaultViews)}
                            customViews={this.transformViews(customViews, true)}
                            isDataViz={isDataViz}
                            createLink={createLink}
                            customViewsShowed={!customViewsDisabled}/>}
                        {!isDataViz &&
                        <div className='actions'>
                            <Icon className={exportIconClass} onClick={!widgetNoData ? this.handleActionExport : null}/>
                            {currentFeatures[featureTypes.CUSTOM_REPORTS] &&
                            <WidgetAddToReport
                                onAddToReport={this.props.onAddWidgetToDataVizReport}
                                dataParamsBuilder={this.props.events.onBuildDataParams}/>}
                            {!isProxyView &&
                                <Icon
                                    className='font-icon font-icon-view-three-columns'
                                    onClick={this.handleSwitchToCreateMode}/>
                            }
                        </div>}
                    </div>}
                </div>
                {isDataViz && !isPrintMode &&
                <WidgetRemove
                    blockAction={blockAction}
                    onRemoveWidget={onRemoveWidget}
                    className='icon-remove'/>
                }
            </div>
        );
    }
}

export default ShareholderActionPanel;
