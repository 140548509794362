import _ from 'lodash';

import { createReducer, isValidId, isExists } from 'Utils/reducer';
import { DATA_SOURCE_ADD_OR_SAVE, DATA_SOURCE_REMOVE } from './actions';

const initialState = {};

const dataSourceActionHandlers = {
    [DATA_SOURCE_ADD_OR_SAVE]: (state, { payload }) => {
        let newState = state;

        if (_.isArray(payload)) {
            const _state = {};

            payload.forEach(({ id, data, info }) => {
                if (isValidId(id)) {
                    _state[id] = {
                        info,
                        data
                    };
                }
            });

            newState = { ...state, ..._state };
        } else if (_.isObject(payload)) {
            const { id, data, info } = payload;

            if (isValidId(id)) {
                newState = {
                    ...state,
                    [id]: {
                        info,
                        data
                    }
                };
            }
        }

        return newState;
    },
    [DATA_SOURCE_REMOVE]: (state, { payload }) => {
        let newState = state;

        if (_.isArray(payload)) {
            newState = { ...state };
            payload.forEach(id => {
                delete newState[id];
            });
        } else if (isValidId(payload) && isExists(state, payload)) {
            newState = { ...state };
            delete newState[payload];
        }

        return newState;
    }
};

export default createReducer(initialState, dataSourceActionHandlers);
