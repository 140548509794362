import React from 'react';

import dataTypes from './constants/data-types';
import DateFormatter from 'Components/formatters/DateFormatter';
import NumberFormatter from 'Components/formatters/NumberFormatter';
import PercentFormatter from 'Components/formatters/PercentFormatter';
import CurrencyFormatter from 'Components/formatters/CurrencyFormatter';


export const getFormattedValue = (value, type, formatterOptions = {}) => {
    switch (type) {
        case dataTypes.PERCENT:
            return (<PercentFormatter value={value} withPercent/>);
        case dataTypes.INTEGER:
            return (<NumberFormatter value={value} decimalPoints={0}/>);
        case dataTypes.DECIMAL:
            return (<NumberFormatter value={value} decimalPoints={2}/>);
        case dataTypes.TEXT:
            return value;
        case dataTypes.DATE:
            return (<DateFormatter value={value}/>);
        case dataTypes.MONEY:
            return (<CurrencyFormatter value={value} separately {...formatterOptions}/>);
        default:
            return;
    }
};
