import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';

import * as widgetsUtils from 'Components/widgets/utils';
import { Layout } from '../../layout';
import Slot from '../../Slot';
import CompanyInformation from '../../../pages/HomePage/components/CompanyInformation';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import './HomePageLayout.scss';

const mapStateToProps = state => ({
    widgetTypes: dynamicPageSelectors.widgetTypesBySlotsSelector(state),
    pageInfo: dynamicPageSelectors.getDynamicPageInfoSelector(state)
});

@connect(mapStateToProps)
class HomePageLayout extends Component {
    static propTypes = {
        slots: PropTypes.object,
        widgetTypes: PropTypes.object,
        pageInfo: PropTypes.object
    };

    getSlotSizeClassName = (slotId) => {
        const widgetType = _.get(this.props.widgetTypes[slotId], 'widgets[0]');
        const slotSize = widgetsUtils.getSlotSizeByWidgetType(widgetType);

        return `col-${slotSize}`;
    };

    getSlots = (asideSlots, slots, maxWidgetsAmount) => {
        return asideSlots.map(slotId => {
            if (!(_.keys(slots).length > maxWidgetsAmount && slots[slotId].widgets.length === 0)) {
                return (<Slot
                    key={slotId}
                    slotId={slotId}
                    className={cn('home-page-slot', this.getSlotSizeClassName(slotId))}
                    {...slots[slotId]}/>);
            }
            return null;
        });
    };

    render() {
        const { slots } = this.props;
        const asideSlots = _.map(_.keys(slots), key => +key);
        const maxWidgetsAmount = _.get(this.props, 'pageInfo.maxWidgetsAmount');
        const perfWidgetIndex = _.find(asideSlots, (slot, i) => this.getSlotSizeClassName(i + 1).includes('col-2'));
        let components = null;

        if (perfWidgetIndex) {
            if (perfWidgetIndex % 3 === 2) {
                const asideSlotsBeforePerf = asideSlots.splice(0, perfWidgetIndex - 2);
                const asideSlotsWithPerf = asideSlots.splice(0, 3);
                const slotsBeforePerf = this.getSlots(asideSlotsBeforePerf, slots, maxWidgetsAmount);
                const slotsWithPerfWidget = (
                    <div className='home-page-slot col-3 perf-right' key={asideSlotsWithPerf[0]}>
                        {this.getSlots(asideSlotsWithPerf, slots, maxWidgetsAmount)}
                    </div>);
                const slotsAfterPerf = this.getSlots(asideSlots, slots, maxWidgetsAmount);

                components = _.concat(slotsBeforePerf, slotsWithPerfWidget, slotsAfterPerf);
            } else {
                const asideSlotsBeforePerf = asideSlots.splice(0, perfWidgetIndex - 1);
                const asideSlotsWithPerf = asideSlots.splice(0, 3);
                const slotsBeforePerf = this.getSlots(asideSlotsBeforePerf, slots, maxWidgetsAmount);
                const slotsWithPerfWidget = (
                    <div className='home-page-slot col-3 perf-left' key={asideSlotsWithPerf[0]}>
                        {this.getSlots(asideSlotsWithPerf, slots, maxWidgetsAmount)}
                    </div>);
                const slotsAfterPerf = this.getSlots(asideSlots, slots, maxWidgetsAmount);

                components = _.concat(slotsBeforePerf, slotsWithPerfWidget, slotsAfterPerf);
            }
        } else {
            components = this.getSlots(asideSlots, slots, maxWidgetsAmount);
        }

        return (
            <div className='home-page-layout empty-space-container'>
                <CompanyInformation/>
                <Layout wrap>
                    {components}
                </Layout>
            </div>
        );
    }
}

export default HomePageLayout;
