import PropTypes from 'prop-types';
import _ from 'lodash';
import { prefix } from 'inline-style-prefixer';

export const commonPropTypes = {
    config: PropTypes.object,
    grow: PropTypes.number,
    shrink: PropTypes.number,
    basis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export const flexboxProps = ['wrap', 'reverse', 'column', 'grow', 'shrink', 'basis', 'flex'];
export const commonProps = ['config', ...flexboxProps];
export const layoutProps = ['flexbox', ...commonProps];

export function buildFlexStyles(props = {}) {
    const styles = {};

    if (!_.isUndefined(props.flex)) {
        styles.flex = props.flex;
    } else {
        if (!_.isUndefined(props.grow)) {
            styles.flexGrow = props.grow;
        }

        if (!_.isUndefined(props.shrink)) {
            styles.flexShrink = props.shrink;
        }

        if (!_.isUndefined(props.basis)) {
            styles.flexBasis = props.basis;
        }
    }

    return prefix(styles);
}
