import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hideModal } from 'State/modal';
import ExportPendingModalView from '../components/ExportPendingModalView';
import { buildLocationWithSecurity } from 'Utils/routing';
import pageRoutes from 'Constants/page-routes';
import { getSecurityByIdSelector } from 'State/securities/selectors';

const mapStateToProps = (state, props) => ({
    security: getSecurityByIdSelector(state, props.securityId),
    ...state.modal
});
const mapDispatchToProps = { hideModal };

@connect(mapStateToProps, mapDispatchToProps)
class ExportPendingModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func,
        security: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number
        })
    };

    render() {
        const { hideModal: handleHideModal, security } = this.props;

        return (
            <ExportPendingModalView
                onConfirm={handleHideModal}
                security={security}
                downloadUrl={buildLocationWithSecurity(pageRoutes.downloads, security && security.id)}/>
        );
    }
}

export default ExportPendingModal;
