import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export function getTotalOutstandingSharesByDate(closeOfBusinessDate, securities) {
    const requestData = utils.buildRequest({ closeOfBusinessDate, securities },
        requestTypes.getTotalOutstandingSharesByDatePayload);

    return axios.post(apiRoutes.getTotalOutstandingSharesByDateUrl, requestData, {
        isCancelable: true
    });
}
