import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Icon from 'Components/Icon';
import Popup from 'Components/Popup';

import './DateRangeInfo.scss';

const DateRangeInfo = ({ inactive }) => (
    <Popup
        content={
            <div className='date-range-info-content'>
                <FormattedMessage id='shareholder.list.dateRangeInfo.title' tagName='div'/>
                <FormattedHTMLMessage id='shareholder.list.dateRangeInfo.open' tagName='div'/>
                <FormattedHTMLMessage id='shareholder.list.dateRangeInfo.closed' tagName='div'/>
                <FormattedHTMLMessage id='shareholder.list.dateRangeInfo.openAndClosed' tagName='div'/>
            </div>
        }>
        <Icon
            className={cn('font-icon font-icon-info-outline date-range-info', { inactive })}
            withoutTitle/>
    </Popup>
);

DateRangeInfo.propTypes = {
    inactive: PropTypes.bool
};

export default DateRangeInfo;
