import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { connect } from 'react-redux';

import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';

import './WidgetNavigationPanel.scss';

const mapStateToProps = state => ({
    blockAction: getDynamicPageBlockActionSelector(state)
});

@connect(mapStateToProps)
class WidgetNavigationPanel extends Component {
    static propTypes = {
        widgets: PropTypes.arrayOf(PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            widgetType: PropTypes.string.isRequired,
            isCurrent: PropTypes.bool.isRequired
        })),
        availableWidgets: PropTypes.array,
        onSelect: PropTypes.func.isRequired,
        blockAction: PropTypes.bool
    };

    render() {
        const _renderViews = () => {
            const { widgets, availableWidgets, blockAction = false, onSelect } = this.props;
            const filteredWidgets = _.filter(widgets, widget => _.some(availableWidgets, { 'name': widget.widgetType }));

            return filteredWidgets.map((widget) => {
                const { widgetType, displayName, isCurrent } = widget;
                const searchingWidget = _.find(availableWidgets, { name: widgetType });
                const isSelected = searchingWidget.isSelected;
                const classNames = cn({
                    disabled: isSelected,
                    active: isCurrent,
                    inactive: !isSelected && blockAction
                });

                return (
                    <li className='nav-item' key={widgetType}>
                        <a
                            className={classNames}
                            onClick={() => {
                                if (!isSelected && !blockAction) {
                                    onSelect({ name: widgetType });
                                }
                            }}>
                            {displayName}
                        </a>
                    </li>
                );
            });
        };

        return (
            <div className='widget-navigation-panel nav horizontal'>
                {_renderViews()}
            </div>
        );
    }
}

export default WidgetNavigationPanel;
