import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import cn from 'classnames';

import { NumberFormatter, CurrencyFormatter, NullableFormatter } from 'Components/formatters';
import { INFO_SIGN } from 'Constants/symbol-constants';
import directDepositStatus from 'Constants/direct-deposit-status';
import directDebitStatus from 'Constants/direct-debit-status';

const CurrentPositionView = (props) => {
    const { intl: { formatMessage }, data = {} } = props;

    return (
        <div>
            <div className='position-block'>
                {DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.total' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.total} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {!DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.certificated' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.certificated} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.dr_dspp' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.drdspp} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {!DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.book' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.book} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.book' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.book} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {!DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.dr_dspp' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.drdspp} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.certificated' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.certificated} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {!DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.total' })}:
                        </span>
                        <span className='position-value'>
                            <NumberFormatter value={data.total} decimalPoints={3}/>
                        </span>
                    </div>
                )}
                {!DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.mktPrice' })}
                            <span className='note-mark'>{INFO_SIGN}</span>:
                        </span>
                        <span className='position-value'>
                            <CurrencyFormatter value={data.mktPrice} decimalPoints={4}/>
                        </span>
                    </div>
                )}
                {!DEMO_MODE && (
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.value' })}:
                        </span>
                        <span className='position-value'>
                            <CurrencyFormatter value={data.value} decimalPoints={2}/>
                        </span>
                    </div>
                )}
            </div>
            <div className='position-block'>
                <div className='position-block'>
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.holderType' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={DEMO_MODE ? 'INDIVIDUAL' : data.holderType}/>
                        </span>
                    </div>
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.specAcctCode' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.specAcctCode}/>
                        </span>
                    </div>
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.mailCode' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.mailCode}/>
                        </span>
                    </div>
                </div>
                <div className='position-block'>
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDeposit' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDeposit}/>
                        </span>
                    </div>
                    {data.directDeposit === directDepositStatus.YES && <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDeposit.bankRoutingNumber' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDepositBankRoutingNumber}/>
                        </span>
                    </div>}
                    {data.directDeposit === directDepositStatus.YES && <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDeposit.bankAccountNumber' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDepositBankAccountNumber}/>
                        </span>
                    </div>}
                    {data.directDeposit === directDepositStatus.YES && <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDeposit.bankAccountType' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDepositBankAccountType}/>
                        </span>
                    </div>}
                </div>
                {data.directDebit && <div className='position-block'>
                    <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDebit' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDebit}/>
                        </span>
                    </div>
                    {data.directDebit === directDebitStatus.YES && <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDebit.bankRoutingNumber' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDebitBankRoutingNumber}/>
                        </span>
                    </div>}
                    {data.directDebit === directDebitStatus.YES && <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDebit.bankAccountNumber' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDebitBankAccountNumber}/>
                        </span>
                    </div>}
                    {data.directDebit === directDebitStatus.YES && <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDebit.bankAccountType' })}:
                        </span>
                        <span className='position-value'>
                            <NullableFormatter value={data.directDebitBankAccountType}/>
                        </span>
                    </div>}
                    {data.directDebit === directDebitStatus.YES && <div className='position-row'>
                        <span className='position-title'>
                            {formatMessage({ id: 'registered.shareholder.information.directDebit.recurringDebitAmount' })}:
                        </span>
                        <span className='position-value'>
                            <CurrencyFormatter value={data.recurringDebitAmount} decimalPoints={2}/>
                        </span>
                    </div>}
                </div>}
                <div className='position-row drp-row'>
                    <span className='position-title'>
                        <span className={cn({ 'sign-double-asterisk-after': !DEMO_MODE, 'sign-asterisk-after': DEMO_MODE })}>
                            {formatMessage({ id: 'registered.shareholder.information.enrolledInDRP' })}
                        </span>
                        :
                    </span>
                    <span className='position-value'>
                        <NullableFormatter value={data.enrolledDRP}/>
                    </span>
                </div>
                <div className='position-row'>
                    <span className='position-title'>
                        {formatMessage({ id: 'registered.shareholder.information.block' })}:
                    </span>
                    <span className='position-value'>
                        <NullableFormatter value={data.block}/>
                    </span>
                </div>
            </div>
        </div>
    );
};

CurrentPositionView.propTypes = {
    intl: intlShape,
    data: PropTypes.object
};

export default injectIntl(CurrentPositionView);
