import React from 'react';
import PropTypes from 'prop-types';

const ControlBooksResultTabsView = props =>
    (<div className='control-books-tabs'>
        {props.getTabs()}
        <div className='control-books-tabs-overlay'/>
    </div>);

ControlBooksResultTabsView.propTypes = {
    getTabs: PropTypes.func
};

export default ControlBooksResultTabsView;
