import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';

import './ExportPendingModalView.scss';

const ExportPendingModalView = ({ onConfirm, downloadUrl, security }) => (
    <div className='info-modal export-pending-modal'>
        <div className='modal-message'>
            <p><FormattedMessage id='modals.exportPending.timeMessage'/></p>
            <p><FormattedMessage id='modals.exportPending.notifyMessage'/></p>
            <p>
                <FormattedMessage
                    id='modals.exportPending.downloadsMessage'
                    values={{
                        downloadsPageLink: <LinkEx
                            className='download-page-link'
                            to={downloadUrl}
                            onClick={onConfirm}>
                            <FormattedMessage
                                id='modals.exportPending.downloadsPageLink'/>
                        </LinkEx>,
                        forSecurity: security && <FormattedMessage
                            id='modals.exportPending.forSecurity'
                            values={{ security: security.name } || ''}/>
                    }}/>
            </p>
        </div>
        <div className='modal-actions'>
            <button type='button' onClick={onConfirm} className='btn btn-large ok-btn'>
                <FormattedMessage id='common.close'/>
            </button>
            <LinkEx
                className='btn btn-large downloads-btn'
                to={downloadUrl}
                onClick={onConfirm}>
                <FormattedMessage
                    id='modals.exportPending.downloadsPageButton'/>
            </LinkEx>
        </div>
    </div>
);

ExportPendingModalView.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    downloadUrl: PropTypes.object,
    security: PropTypes.shape({
        name: PropTypes.string
    })
};

export default ExportPendingModalView;
