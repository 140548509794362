import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName, withState } from '@shakacode/recompose';

export default (WrappedComponent) => {
    @withState(
        'isPrint',
        'setPrint',
        false
    )
    class WithPrintState extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withPrintModeState');

        static propTypes = {
            isPrint: PropTypes.bool,
            setPrint: PropTypes.func
        };

        constructor(props) {
            super(props);
            if (window.matchMedia) {
                this.mediaQueryList = window.matchMedia('print');
            }
        }

        componentDidMount() {
            if (this.mediaQueryList) {
                this.mediaQueryList.addListener(this.mediaQueryListHandler);
            }
            window.addEventListener('beforeprint', this.beforePrint);
            window.addEventListener('afterprint', this.afterPrint);
        }

        componentWillUnmount() {
            if (this.mediaQueryList) {
                this.mediaQueryList.removeListener(this.mediaQueryListHandler);
            }
            window.removeEventListener('beforeprint', this.beforePrint);
            window.removeEventListener('afterprint', this.afterPrint);
        }

        beforePrint = () => {
            this.props.setPrint(true);
        };

        afterPrint = () => {
            this.props.setPrint(false);
        };

        mediaQueryListHandler = (mql) => {
            if (mql.matches) {
                this.beforePrint();
            } else {
                this.afterPrint();
            }
        };

        render() {
            return (
                <WrappedComponent {...this.props}/>
            );
        }
    }

    return WithPrintState;
};
