import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { mapProps } from '@shakacode/recompose';
import cn from 'classnames';
import _ from 'lodash';

import Selector from 'Components/Selector';
import { CheckBox } from 'Components/CheckBox';
import ShareholderOptionRenderer from 'Components/widgets/_commonComponents/ShareholderOptionRenderer';
import columnDefinitions from '../constants/columnDefinitions';
import shareholderTypesArray from 'Constants/shareholder-types-array';
import reportTypes from 'Constants/report-types';
import shareholderTypes from 'Constants/shareholder-types';
import shareholderTypeToIconMap from 'Constants/shareholder-type-to-icon-map';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import { showExportWidgetModal } from 'State/exportReports';
import { filterItemsByUserProductSources } from 'Utils/utils';
import { showCommonColumnsManagerModal } from 'State/modal/actions';
import { commonWidgetPropTypes, commonWidgetActions, getVisibleColumns, getManagerTransformColumns } from '../../utils';
import WidgetPrint from '../../Widget/components/WidgetPrint';
import Icon from 'Components/Icon';

const defaultShareholderType = shareholderTypes.ALL;
const mapStateToProps = state => ({
    pageInfo: dynamicPageSelectors.getDynamicPageInfoSelector(state)
});
const mapDispatchToProps = { showCommonColumnsManagerModal, showExportWidgetModal };

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
@mapProps(props => {
    const formatMessage = props.intl.formatMessage;
    const visibleColumns = _.get(props, 'widgetSettings.visibleColumns', []);
    const columns = getManagerTransformColumns(columnDefinitions.columns, visibleColumns, formatMessage);

    return {
        ...props,
        columns
    };
})
class GeographicAnalysisControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        events: PropTypes.object.isRequired,
        pageInfo: PropTypes.object.isRequired,
        columns: PropTypes.array.isRequired,
        showCommonColumnsManagerModal: PropTypes.func.isRequired,
        showExportWidgetModal: PropTypes.func.isRequired,
        intl: intlShape,
        settings: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.formatMessage = props.intl.formatMessage;
        this.checkBoxCedeCoLabel = this.formatMessage({ id: 'reporting.geographic.analysis.checkboxCedeCo' });
        this.shareholderSelectorOptions = filterItemsByUserProductSources(shareholderTypesArray, props.userProductSources)
            .map(item => ({
                ...item,
                iconClass: shareholderTypeToIconMap[item.value],
                label: this.props.intl.formatMessage({ id: item.label })
            }));
    }

    UNSAFE_componentWillMount() {
        const { settings: { shareholderType } } = this.props;

        if (this.shareholderSelectorOptions.length === 1) {
            this.handleShareholderTypeChange(this.shareholderSelectorOptions[0]);
        } else {
            const hasType = _.some(this.shareholderSelectorOptions, { value: shareholderType });

            !hasType && this.handleShareholderTypeChange({ value: defaultShareholderType });
        }
    }

    handleShareholderTypeChange = (selectedItem) => {
        const { settings: { shareholderType } } = this.props;

        if (selectedItem.value !== shareholderType) {
            this.props.events.handleUpdateWidgetParams({ shareholderType: selectedItem.value });
        }
    };

    handleExport = () => {
        const { widgetSettings = {}, settings = {} } = this.props;
        const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
        const cobDate = _.get(this.props, 'pageInfo.closeOfBusinessDate');

        this.props.showExportWidgetModal({
            reportTemplateType: reportTypes.geographicAnalysisTemplate,
            exportParams: {
                shareholderType: settings.shareholderType,
                // todo: in future properties of sortCriteria object will be rename
                // from { sortDirection: ..., sortField: ... } to { direction: ..., field: ... }
                sortCriteria: {
                    sortDirection: sortCriteria.direction,
                    sortField: sortCriteria.field,
                    initial: sortCriteria.initial
                },
                columnsToDisplay: widgetSettings.visibleColumns,
                cedeCoHidden: widgetSettings.isCedeCoHidden,
                showOnly: settings.locationFilterType,
                cobDate
            },
            modalViewParams: {
                showExcelLimit: true
            }
        });
    };

    handleActionColumnManager = () => {
        this.props.showCommonColumnsManagerModal({
            titleKey: 'modals.columnsManagerModal.title',
            columns: this.props.columns,
            onSave: this.handleSaveColumns,
            disableValidation: true
        });
    };

    handleSaveColumns = (columns = []) => {
        const visibleColumns = getVisibleColumns(columns, columnDefinitions);

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            visibleColumns
        });
    };

    handleHideCedeCoChange = (e) => {
        const isCedeCoHidden = e.target.checked;

        this.props.events.handleUpdateWidgetParams({ isCedeCoHidden });
    };

    render() {
        const {
            settings: { shareholderType },
            widgetData,
            widgetType,
            widgetSettings: { isCedeCoHidden }
        } = this.props;
        const noData = !_.get(widgetData, 'tableData.length');
        const cedeCoSharesNumber = _.get(widgetData, 'cedeCoSharesNumber');
        const reportIconClasses = cn('font-icon font-icon-download action-icon', { 'inactive': noData });
        const isSelectDisabled = this.shareholderSelectorOptions.length === 1;
        const showCedeCoCheckbox = shareholderType === shareholderTypes.REGISTERED;

        return (
            <div className='geographic-analysis-control-panel'>
                <div className='left-details-container'>
                    <Selector
                        wrapperClass='geographic-analysis-selector'
                        options={this.shareholderSelectorOptions}
                        onOptionChange={this.handleShareholderTypeChange}
                        name='geographicAnalysisSelector'
                        value={shareholderType}
                        optionRenderer={ShareholderOptionRenderer}
                        valueRenderer={ShareholderOptionRenderer}
                        searchable={false}
                        clearable={false}
                        disabled={isSelectDisabled}/>
                    {cedeCoSharesNumber && showCedeCoCheckbox &&
                        <CheckBox
                            position='inline'
                            name='geographicAnalysisHideCeDe'
                            onChange={this.handleHideCedeCoChange}
                            checked={isCedeCoHidden}
                            label={this.checkBoxCedeCoLabel}/>
                    }
                </div>
                <div className='action-icons-container'>
                    <WidgetPrint
                        className='action-icon'
                        widgetType={widgetType}/>
                    <Icon
                        className={reportIconClasses}
                        onClick={noData ? null : this.handleExport}/>
                    <Icon
                        className='font-icon font-icon-view-three-columns action-icon'
                        onClick={this.handleActionColumnManager}/>
                </div>
            </div>
        );
    }
}

export default GeographicAnalysisControlPanel;
