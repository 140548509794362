import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import apiRoutes from 'Constants/api-routes';
import { downloadFile } from 'Utils/utils';
import { withDownloadFileError } from 'Hoc/withDownloadFileError';
import * as api from '../../../../../api';

import './UploadRequestTemplates.scss';
import PropsTypes from 'prop-types';
import { UploadRequestTemplate } from './UploadRequestTemplate';

const UploadRequestTemplatesView = ({ notifyDownloadError }) => {
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        api.getUploadRequestTemplates().then((res) => {
            setTemplates(_.get(res, 'data.payload.templates', []));
        });
    }, []);

    const downloadDocument = (documentId) => () => {
        downloadFile(`${apiRoutes.downloadSecureUploadTemplate}/${documentId}`, notifyDownloadError);
    };

    const renderDocuments = (documents) => {
        return documents.map((document, documentKey) => (
            <UploadRequestTemplate key={documentKey} onClick={downloadDocument(document.id)}>
                {document.title}
            </UploadRequestTemplate>
        ));
    };

    return (
        <div className='upload-request-templates'>
            {templates.map(({ groups }, key) => (<div className='upload-request-templates__column' key={key}>
                {groups.map(({ title, documents }, groupKey) => (
                    <div className='upload-request-templates__group' key={groupKey}>
                        {title ? <p className='upload-request-templates__group-header'>{title}</p> : <p className='upload-request-templates__group-header__placeholder' />}
                        {renderDocuments(documents)}
                    </div>))}
            </div>))}
        </div>
    );
};

UploadRequestTemplatesView.propTypes = {
    notifyDownloadError: PropsTypes.func.isRequired
};

export const UploadRequestTemplates = withDownloadFileError(UploadRequestTemplatesView);
