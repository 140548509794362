import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import FontFaceObserver from 'fontfaceobserver';

import { FONT_NAME, FONT_NAME_UK, fontStyles, fontWeights } from 'Constants/font-properties';
import { UNIDENTIFIED } from 'Constants/undefined-values';
import dateConstants from 'Constants/date-formats';
import formatterOptions from 'Constants/formatter-options';
import { postMessageTypes } from 'Constants/post-message-types';
import { parseUrl, removePortFromHost } from './routing';
import valueTypes from 'Constants/value-types';
import { securityFullNameFormatter, numberFormatter } from 'Components/formatters/formatters';
import { NullableFormatter } from 'Components/formatters';
import { identifyBrowser } from 'Utils/browser';
import { login, logout } from '../api/auth';

const API_BASE_HOST = parseUrl(API_BASE_URL).host;
const SYMBOL_WIDTH = 9;
const QUOTES_COUNT = 2;

export function forEachChild(children, handler, parent) {
    React.Children.forEach(children, (child) => {
        handler(child, parent);

        if (child.props && child.props.children) {
            forEachChild(child.props.children, handler, child);
        }
    });
}

export function isDevelopmentEnv(env) {
    return env === 'development' || env === 'develop';
}

export function compareSpecialNullSorting(firstValue, secondValue, initial) {
    const isFirstNull = checkBackEndNull(firstValue);
    const isSecondNull = checkBackEndNull(secondValue);

    // nulls should be last when first sorting
    if (initial && ((!isFirstNull && isSecondNull) || (isFirstNull && !isSecondNull))) {
        if (isSecondNull) {
            return -1;
        }
        if (isFirstNull) {
            return 1;
        }
    }
    return undefined;
}

export function sortArray({
    array,
    primaryField,
    descending = true,
    secondaryField,
    secondaryDescending = true,
    primaryComparator = compareDescending,
    secondaryComparator = compareDescending,
    initial = false
}) {
    if (!Array.isArray(array)) return [];

    const sortedArray = [...array];

    sortedArray.sort((firstElement, secondElement) => {
        let firstValue = _.get(firstElement, primaryField);
        let secondValue = _.get(secondElement, primaryField);
        let specialNullSortingResult = compareSpecialNullSorting(firstValue, secondValue, initial);

        if (specialNullSortingResult !== undefined) {
            return specialNullSortingResult;
        }

        let result = primaryComparator(firstValue, secondValue);

        if (result === 0 && !_.isUndefined(secondaryField) && primaryField !== secondaryField) {
            firstValue = _.get(firstElement, secondaryField);
            secondValue = _.get(secondElement, secondaryField);

            specialNullSortingResult = compareSpecialNullSorting(firstValue, secondValue, initial);
            if (specialNullSortingResult !== undefined) {
                return specialNullSortingResult;
            }

            result = secondaryComparator(firstValue, secondValue);

            return result * (secondaryDescending ? 1 : -1);
        }

        return result * (descending ? 1 : -1);
    });

    return sortedArray;
}

export function compareDescending(value, other) {
    if (value === other) {
        return 0;
    }

    if (_.isString(value) &&
        _.isString(other) &&
        value !== UNIDENTIFIED &&
        other !== UNIDENTIFIED) {
        return -value.toLowerCase().localeCompare(other.toLowerCase());
    }

    if (checkBackEndNull(value) && checkBackEndNull(other)) {
        return value === UNIDENTIFIED ? 1 : -1;
    }

    if (checkBackEndNull(value)) {
        return _.isString(other) && !moment(other, dateConstants.SERVER_DATE_FORMAT, true).isValid() ? -1 : 1;
    }

    if (checkBackEndNull(other)) {
        return _.isString(value) && !moment(value, dateConstants.SERVER_DATE_FORMAT, true).isValid() ? 1 : -1;
    }

    return value > other ? -1 : 1;
}

export function compareDatesDescending(value, other) {
    if (value === other) {
        return 0;
    }

    const isDate1 = moment(value).isValid();
    const isDate2 = moment(other).isValid();

    if (isDate1 && isDate2) {
        return moment(value).isAfter(other) ? -1 : 1;
    }

    if (isDate1) {
        return -1;
    }

    if (isDate2) {
        return 1;
    }

    if (_.isString(value) && _.isString(other) &&
        !checkBackEndNull(value) && !checkBackEndNull(other)) {
        return -value.toLowerCase().localeCompare(other.toLowerCase());
    }

    if (checkBackEndNull(value)) {
        return 1;
    }

    if (checkBackEndNull(other)) {
        return -1;
    }

    return value > other ? -1 : 1;
}

export function formatDateArray(array, format) {
    if (!Array.isArray(array) || !_.isString(format)) return [];

    return array.map((date) => moment(date).format(format));
}

export const filterItemsByUserProductSources = (values, userProductSources) => {
    return _.filter(values, value => {
        if (_.isArray(value.availableFor)) {
            return !!_.intersection(value.availableFor, userProductSources).length;
        }

        return value.availableFor(userProductSources);
    });
};

export const filterItemsByUserProductSourcesAndFeatures = (values, userProductSources, currentFeatures) => {
    return _.filter(values, value => {
        if (!_.isFunction(value.availableFor)) {
            const productSources = _.get(value, 'availableFor.productSources');
            const features = _.get(value, 'availableFor.features');

            return !![
                ..._.intersection(productSources, userProductSources),
                ..._.filter(features, feature => currentFeatures[feature])
            ].length;
        }

        return value.availableFor(userProductSources, currentFeatures);
    });
};

export function checkBackEndNull(value) {
    return _.isNil(value) || value === UNIDENTIFIED || _.isString(value) && value.trim() === '';
}

export function downloadFile(src, errorCallback) {
    const iframeId = 'iframeDownloader';
    let iframe = document.getElementById(iframeId);

    if (window.E2E_TESTS_MODE) {
        const tmpSpan = document.createElement('span');

        tmpSpan.id = 'tmpDownloadSpan';
        tmpSpan.style.display = 'none';
        tmpSpan.setAttribute('data-src', src);
        document.body.appendChild(tmpSpan);
        return;
    }

    if (iframe === null) {
        iframe = document.createElement('iframe');
        iframe.id = iframeId;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        window.addEventListener('message', event => {
            const isIE = identifyBrowser() === 'ie';
            const isBaseHost = isIE
                ? API_BASE_HOST.includes(removePortFromHost(parseUrl(event.origin).host))
                : parseUrl(event.origin).host === API_BASE_HOST;

            if (process.env.NODE_ENV === 'development' || (API_BASE_URL && isBaseHost)) {
                let data;
                let messageType;

                try {
                    data = _.isString(event.data) ? JSON.parse(event.data) : event.data;
                    messageType = _.get(data, 'messageType');
                } catch (ex) {
                    console.error(ex);
                    return;
                }

                if (postMessageTypes.includes(messageType) && _.isFunction(errorCallback)) {
                    errorCallback(data);
                }
            }
        });
    }
    iframe.src = src;
}

/**
 * @param {number} number - source number to rounding
 * @param {number} [decimalPlaces=0] - count of digits after comma
 */
export function round(number, decimalPlaces = formatterOptions.DEFAULT_DECIMAL_POINTS) {
    // mathematical rounding
    const divisor = Math.pow(10, decimalPlaces);
    const sign = Math.sign(number);
    const absValue = Math.abs(number * divisor);

    return sign * Math.round(absValue) / divisor;

    // banker's rounding
    // const divisor = Math.pow(10, decimalPlaces);
    // const normalizedNumber = +(number * divisor).toFixed(8); // Avoid rounding errors
    // const integer = Math.floor(normalizedNumber);
    // const fractionalPart = normalizedNumber - integer;
    // const error = 1e-8; // Allow for rounding errors in f
    // let divisible;
    //
    // if (fractionalPart > 0.5 - error && fractionalPart < 0.5 + error) {
    //     divisible = (integer % 2 === 0) ? integer : integer + 1;
    // } else {
    //     divisible = Math.round(normalizedNumber);
    // }
    //
    // return divisible / divisor;
}

export function scrollTo(el, marginTop = 0) {
    if (el) {
        const rect = el.getBoundingClientRect();
        const scrollTop = rect.top < 0 || rect.top > window.innerHeight ? rect.top - marginTop : 0;

        window.scrollBy(0, scrollTop);
    }
}

export class ScrollChecker {
    constructor(onHorizontalScroll) {
        this.onHorizontalScroll = onHorizontalScroll;

        this.containers = document.querySelectorAll('.scroll-handle');
        for (let i = 0; i < this.containers.length; i++) {
            const container = this.containers[i];

            container.addEventListener('scroll', this.handleScroll);
            container._prevScrollLeft = container.scrollLeft;
        }
    }

    destroy() {
        this.onHorizontalScroll = null;
        for (let i = 0; i < this.containers.length; i++) {
            this.containers[i].removeEventListener('scroll', this.handleScroll);
        }
        this.containers = null;
    }

    handleScroll = (e) => {
        const container = e.target;

        if (container._prevScrollLeft !== container.scrollLeft) {
            container._prevScrollLeft = container.scrollLeft;
            this.onHorizontalScroll();
        }
    }
}

export const forceLogout = () => {
    logout().then(res => {
        const logoutUrl = _.get(res, 'headers.logout-url');

        if (logoutUrl) window.location = logoutUrl;
    });
};

export const redirectToLogin = () => {
    login();
};

export function loadFonts() {
    _.forEach(fontWeights, weight => {
        _.forEach(fontStyles, style => {
            new FontFaceObserver(DEMO_MODE ? FONT_NAME_UK : FONT_NAME, {
                weight,
                style
            }).load().catch(() => {
            });
        });
    });
}

export function removeUnsafeContent(string) {
    return _.replace(string, /%00|(%3C|<|\u003c)[^(\u003c|<)]*>/g, '');
}

export function removeUnsafeContentFromElement(item) {
    try {
        const jsonString = JSON.stringify(item);
        const validString = removeUnsafeContent(jsonString);

        return JSON.parse(validString);
    } catch (ex) {
        console.log(ex);
    }
}

export const normalizeSecurityId = (id) => {
    const _id = parseInt(id, 10);

    return (_.isNumber(_id) && !_.isNaN(_id)) ? _id : null;
};

export function mapFeatureList(features) {
    const result = {};

    _.forEach(features, feature => {
        result[feature.type] = true;
    });

    return result;
}

export const getColumnsMinWidth = (data, column, initialWidth = 90, intl) => {
    const { widthParams, columnName } = column;
    let longestValue;

    if (!widthParams || !data.length) return initialWidth;

    if (widthParams.valueLength) {
        longestValue = widthParams.valueLength;
    } else {
        longestValue = _.max(data.map(row => {
            if (!row[columnName]) return 1;

            switch (widthParams.type) {
                case valueTypes.SECURITY_NAME: {
                    const name = securityFullNameFormatter(row.securityName, row.ticker, row.cusip, row.issuerId);

                    return name.length;
                }
                case valueTypes.NUMBER: {
                    const currencyLength = widthParams.withCurrency ? 1 : 0;
                    const formattedValue = numberFormatter(intl, row[columnName], {
                        decimalPoints: widthParams.decimalPoints
                    });

                    return formattedValue.length + currencyLength;
                }
                case valueTypes.TEXT: {
                    return row[columnName].length;
                }
                default: {
                    return JSON.stringify(row[columnName]).length - QUOTES_COUNT;
                }
            }
        }));
    }

    return Math.max(initialWidth, longestValue * SYMBOL_WIDTH);
};

export const convertMinutesInDays = (minutes) => Math.floor(minutes / 60 / 24);

export const getFullName = (firstName, lastName) => (
    `${NullableFormatter({ value: lastName }).props.children}, ${NullableFormatter({ value: firstName }).props.children}`
);

export const getPartialName = (firstName, lastName) => (
    `${NullableFormatter({ value: firstName[0] }).props.children}. ${NullableFormatter({ value: lastName }).props.children}`
);

export const setInProgress = () => {
    document.body.className += ' in-progress';
};

export const removeInProgress = () => {
    document.body.className = document.body.className.replace(/(in-progress)/g, '');
};
