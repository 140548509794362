import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { RadioButton } from 'Components/RadioButton';
import TextInput from '../../../../../pages/reporting/ReportingShareholderListPage/components/TextInput';
import amountOfRecords from 'Constants/amount-of-records';

const AmountOfAccountsField = (props) => {
    const { selectedAmountOfItems, list } = props;
    const { type: { input: typeInput }, records: { input: recordsInput, meta: recordsMeta } } = selectedAmountOfItems;

    return (
        <div className='radio-buttons'>
            {list.map(({ value: { type }, label, disabled }) =>
                (<div key={type}>
                    <RadioButton
                        {...typeInput}
                        label={label}
                        disabled={disabled}
                        checked={typeInput.value === type}
                        value={type}/>
                    {type === amountOfRecords.NO_OF_RECORDS && (
                        <TextInput
                            {...recordsInput}
                            className='records-input'
                            withFocus={typeInput.value === amountOfRecords.NO_OF_RECORDS}
                            error={recordsMeta.touched && recordsMeta.error}
                            disabled={typeInput.value !== amountOfRecords.NO_OF_RECORDS}/>
                    )}
                </div>)
            )}
        </div>
    );
};

AmountOfAccountsField.parse = (value, name) => {
    if (name === 'selectedAmountOfItems.records') {
        const newValue = _.isString(value) ? value.replace(/^[0]+|\D+/g, '') : value;

        return newValue === '' ? null : _.toInteger(newValue);
    }
    return value;
};

AmountOfAccountsField.propTypes = {
    selectedAmountOfItems: PropTypes.object,
    input: PropTypes.object,
    list: PropTypes.array
};

export default AmountOfAccountsField;
