import _ from 'lodash';

export default (values, props) => {
    const { intl: { formatMessage }, disableValidation } = props;
    const valuesList = _.values(values);
    const isValid = disableValidation
        ? true
        : !valuesList.length || _.some(valuesList, value => value);

    return isValid
        ? {}
        : { _error: formatMessage({ id: 'modals.commonColumnsManager.validateMessage' }) };
};
