import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Field, Fields, propTypes as reduxFormPropTypes } from 'redux-form';
import _ from 'lodash';

import { RadioButtonsListField } from 'Components/RadioButtonsList';
import LimitNote from 'Components/LimitNote';
import AmountOfAccountsField from './AmountOfAccountsField';
import IncludingFeatures from './IncludingFeatures';
import columnsDefinitions, { proxyColumnDefinitions } from 'Components/widgets/ShareholdersTable/constants/columnsDefinitions';
import { getCurrentSortCriteria, getCurrentViewId } from 'Components/widgets/ShareholdersTable/utils';
import shareholdersDefaultViews from 'Components/widgets/ShareholdersTable/constants/defaultViews';

import './ExportShareholderTableModal.scss';

const ExportShareholderTableModalView = (props) => {
    const {
        intl: { formatMessage },
        amountOfItems,
        includingFeatures = [],
        exportFormats,
        widgetSettings,
        onExport,
        onCancel,
        handleSubmit,
        submitting,
        invalid,
        showFeatures,
        onFormatChange,
        formValues
    } = props;
    const currentSortCriteria = getCurrentSortCriteria(widgetSettings);
    const currentViewId = getCurrentViewId(widgetSettings);
    const sortDirectionId = currentSortCriteria.sortDirection === 'desc' ?
        'shareholder.list.sortBy.desc' :
        'shareholder.list.sortBy.asc';
    const colDefs = currentViewId === shareholdersDefaultViews.PROXY ? proxyColumnDefinitions : columnsDefinitions;
    const sortFieldId = _.find(colDefs, column => column.columnType === currentSortCriteria.sortField).displayName;

    return (
        <form
            onSubmit={handleSubmit(onExport)}
            className='export-report-modal export-shareholder-table-modal'>
            <div className='format-container'>
                <Fields
                    names={['selectedAmountOfItems.type', 'selectedAmountOfItems.records']}
                    list={amountOfItems}
                    component={AmountOfAccountsField}
                    parse={AmountOfAccountsField.parse}/>
                <div className='form-section-title'>{formatMessage({ id: 'modals.exportReport.label.sorted' })}</div>
                <div>{`${formatMessage({ id: sortFieldId })}, ${formatMessage({ id: sortDirectionId })}`}</div>
                <div
                    className='form-section-title'>{formatMessage({ id: 'modals.exportReport.label.selectFormat' })}</div>
                <RadioButtonsListField
                    onChange={onFormatChange}
                    name='selectedFormat'
                    list={exportFormats}/>
                {showFeatures && includingFeatures.length > 0 &&
                <div>
                    <div
                        className='form-section-title'>{formatMessage({ id: 'modals.exportReport.label.include' })}</div>
                    <Field
                        name='includingFeatures'
                        includingFeatures={includingFeatures}
                        selectedFormat={_.get(formValues, 'selectedFormat')}
                        component={IncludingFeatures}/>
                </div>
                }
            </div>
            <div className='modal-actions'>
                <button
                    type='submit'
                    className='btn btn-large'
                    disabled={submitting || invalid}>
                    {formatMessage({ id: 'modals.exportReport.exportBtn' })}
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className='btn btn-large btn-link'>
                    {formatMessage({ id: 'common.cancel' })}
                </button>
            </div>
            <LimitNote/>
        </form>
    );
};

ExportShareholderTableModalView.propTypes = {
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
    amountOfItems: PropTypes.array,
    exportFormats: PropTypes.array,
    widgetSettings: PropTypes.object,
    showFeatures: PropTypes.bool,
    onExport: PropTypes.func,
    onCancel: PropTypes.func,
    onFormatChange: PropTypes.func
};

export default ExportShareholderTableModalView;
