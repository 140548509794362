import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import _ from 'lodash';

import defaultCellRenderer from './components/defaultCellRenderer';
import withPrintModeState from 'Hoc/withPrintModeState';

import './TableStickyColumn.scss';

const HEADER_ROW_HEIGHT = 36;
const BODY_ROW_HEIGHT = 24;
const MIN_CELL_WIDTH = 100;
const HEADER_ROWS_COUNT = 1;

@withPrintModeState
class TableStickyColumn extends Component {
    static propTypes = {
        className: PropTypes.string,
        headerRowHeight: PropTypes.number,
        bodyRowHeight: PropTypes.number,
        headerClassName: PropTypes.string,
        minColumnWidth: PropTypes.number,
        maxRowCount: PropTypes.number,
        columns: PropTypes.arrayOf(PropTypes.shape({
            locked: PropTypes.bool,
            dataKey: PropTypes.string.isRequired,
            columnData: PropTypes.object,
            headerCell: PropTypes.any,
            bodyCellRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
            width: PropTypes.number,
            headerClassName: PropTypes.string,
            bodyClassName: PropTypes.string,
            columnClassName: PropTypes.string
        })).isRequired,
        /* object key is dataKey from columns */
        data: PropTypes.arrayOf(PropTypes.object),
        isPrint: PropTypes.bool,
        showAllRows: PropTypes.bool
    };

    static defaultProps = {
        headerRowHeight: HEADER_ROW_HEIGHT,
        bodyRowHeight: BODY_ROW_HEIGHT,
        showAllRows: false,
        data: []
    };

    constructor(props) {
        const { data, maxRowCount } = props;
        const minColumnWidth = props.minColumnWidth || MIN_CELL_WIDTH;
        const rowCount = data.length > maxRowCount ? maxRowCount : data.length;

        super(props);
        this._assignDataAndSettings(props);
        this.minColumnWidth = minColumnWidth;
        this.requiredWidth = _.sum(this.columnSettings.map(s => s.width || minColumnWidth));
        this.heightShortTable = rowCount * BODY_ROW_HEIGHT + HEADER_ROW_HEIGHT + scrollbarSize();
        this.heightFullTable = this.props.data.length * BODY_ROW_HEIGHT + HEADER_ROW_HEIGHT;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this._assignDataAndSettings(nextProps);
    }

    getWidthSettings = (availableWidth) => {
        const scrollbarSizeValue = scrollbarSize();
        const isScroll = this.props.showAllRows ?
            false :
            this.requiredWidth + scrollbarSizeValue > availableWidth;

        return {
            tableWidth: isScroll
                ? availableWidth
                : this.requiredWidth + scrollbarSize(),
            commonColumnWidth: this.minColumnWidth,
            isScroll
        };
    };

    _assignDataAndSettings(props) {
        const { columns, data } = props;

        this.columnSettings = this._sortSettings(columns);
        this.dataArray = this._createDataArray(data, this.columnSettings);
    }

    _sortSettings(columns) {
        // we don't use sort method because it can be unstable
        const stickyColumns = [];
        const unStickyColumns = [];

        columns.forEach(col => {
            if (col.locked) {
                stickyColumns.push(col);
            } else {
                unStickyColumns.push(col);
            }
        });

        return stickyColumns.concat(unStickyColumns);
    }

    _createDataArray(data, columns) {
        const rowsCount = data.length; // data and fixed header

        // two dimensional array. [row][col]
        const dataArray = [];

        // header row
        dataArray.push(columns.map(c => c.headerCell));

        // body rows
        for (let rowIndex = 0; rowIndex < rowsCount; rowIndex++) {
            const row = columns.map(col => {
                const { dataKey, columnData } = col;
                const cellData = data[rowIndex][dataKey];
                const CustomRenderer = col.bodyCellRenderer || defaultCellRenderer;
                const customRendererProps = {
                    value: cellData,
                    columnData,
                    dataKey,
                    rowIndex
                };

                return <CustomRenderer {...customRendererProps} key={rowIndex}/>;
            });

            dataArray.push(row);
        }

        return dataArray;
    }

    _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const {
            bodyClassName, headerClassName, columnClassName,
            width = style.width
        } = this.columnSettings[columnIndex];
        const cellData = this.dataArray[rowIndex][columnIndex];
        const isHeader = rowIndex < HEADER_ROWS_COUNT;

        const commonClassName = cn('cell', columnClassName, { 'last-row-cell': rowIndex === this.dataArray.length - 1 });
        const className = isHeader ?
            cn(commonClassName, 'header-cell', headerClassName, this.props.headerClassName) :
            cn(commonClassName, bodyClassName);

        return (
            <div
                className={className}
                key={key}
                style={{ ...style, width }}>
                <span className='truncated-text'>
                    {cellData}
                </span>
            </div>
        );
    };

    overscanIndicesGetter = ({ cellCount }) => ({
        overscanStartIndex: 0,
        overscanStopIndex: cellCount - 1
    });

    render() {
        const { bodyRowHeight, headerRowHeight, isPrint, showAllRows } = this.props;
        const className = cn('table-sticky-column', this.props.className);

        return (
            <div className={className} style={{ height: isPrint || showAllRows ? this.heightFullTable : this.heightShortTable }}>
                <AutoSizer>
                    {({ width, height }) => {
                        const widthSettings = this.getWidthSettings(width);
                        const STYLE_BOTTOM_LEFT_GRID = {
                            height: widthSettings.isScroll
                                ? height - HEADER_ROW_HEIGHT - scrollbarSize()
                                : height - HEADER_ROW_HEIGHT
                        };
                        const getColumnWidth = ({ index }) => _.get(this.columnSettings, `[${index}].width`, widthSettings.commonColumnWidth);

                        return (
                            <MultiGrid
                                styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                                classNameTopRightGrid='table-header'
                                fixedColumnCount={1}
                                fixedRowCount={HEADER_ROWS_COUNT}
                                cellRenderer={this._cellRenderer}
                                columnWidth={getColumnWidth}
                                columnCount={_.get(this.dataArray, '[0].length', 0)}
                                rowHeight={({ index }) => index < HEADER_ROWS_COUNT ? headerRowHeight : bodyRowHeight}
                                rowCount={this.dataArray.length}
                                height={height}
                                width={widthSettings.tableWidth}
                                overscanIndicesGetter={isPrint || showAllRows ? this.overscanIndicesGetter : undefined}
                                enableFixedColumnScroll/>
                        );
                    }}
                </AutoSizer>
            </div>
        );
    }
}

export default TableStickyColumn;
