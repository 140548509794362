import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';

import './UserAvatar.scss';

const fileName = DEMO_MODE ? 'default-user-avatar-demo.svg?v=1' : 'default-user-avatar.svg?v=1';
const avatarUrl = `${PUBLIC_URL}/assets/images/${fileName}`;

@injectIntl
class UserAvatar extends Component {
    static propTypes = {
        width: PropTypes.string,
        height: PropTypes.string,
        className: PropTypes.string,
        altMessageId: PropTypes.string,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        // path for getting photo from be
        // `${getUserImageUrl}?type=photo`;
        this.imageUrl = avatarUrl;
    }

    render() {
        const { intl: { formatMessage }, className, altMessageId } = this.props;
        const imageClass = cn('profile-avatar', className);

        return (
            <div className='profile-avatar-container'>
                <img
                    className={imageClass}
                    src={this.imageUrl}
                    alt={formatMessage({ id: altMessageId })}/>
            </div>
        );
    }
}

export default UserAvatar;
