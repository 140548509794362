import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import { withState } from '@shakacode/recompose';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import TaxCertificationStatusContent from './components/TaxCertificationStatusContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import TaxCertificationStatusActionPanel from './components/TaxCertificationStatusActionPanel';
import FooterWithInfo from '../_commonComponents/FooterWithInfo';
import { getDates, getSelectedDate, getFilteredData } from './selectors';
import { getWidgetFooterPropsWithSignClass } from 'Components/widgets/utils';
import messageType from 'Constants/message-types';

import './TaxCertificationStatus.scss';

@withState(
    'selectedDate',
    'onMonthChange'
)
@injectIntl
class TaxCertificationStatus extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: TaxCertificationStatusContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetActionPanel: TaxCertificationStatusActionPanel,
            WidgetFooter: FooterWithInfo
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'tax.certification.status.title' });
        this.widgetTitleTooltip = <FormattedHTMLMessage id='tax.certification.status.tooltip.title'/>;
    }

    getWidgetFooterProps = (widgetData) => ({
        rightSide: [
            {
                messageKey: 'widgets.message.includesCeDeCo',
                type: messageType.INCLUDES_CEDECO,
                hidden: !this.showCeDeCoMessage(widgetData)
            }
        ]
    });

    showCeDeCoMessage = widgetData => {
        const { widgetSettings: { level } } = this.props;
        const widgetDataValues = _.get(widgetData, `${level}.values`);

        return _.some(widgetDataValues, 'includesCeDeCo');
    };

    render() {
        const dates = getDates(this.props);
        const selectedDate = getSelectedDate(this.props);
        const data = getFilteredData(this.props);
        const widgetFooterProps = getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(data));
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetTitleTooltip: this.widgetTitleTooltip,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            },
            widgetFooterProps,
            widgetNoData: !_.get(data[this.props.widgetSettings.level], 'values.length'),
            data,
            dates,
            selectedDate
        };

        return (
            <Widget
                {...props}
                className='widget-tax-certification-status'
                components={this.widgetComponents}/>
        );
    }
}

export default TaxCertificationStatus;
