import { compose, withProps } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';

import HeadingCellCommon from './HeadingCellCommon';

export const HeadingCellCommonWithIntl = compose(
    injectIntl,
    withProps((props) => ({
        title: props.intl.formatMessage({ id: props.title })
    }))
)(HeadingCellCommon);
