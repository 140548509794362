import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Fields } from 'redux-form';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import InputDateRangePicker from './InputDateRangePicker';
import { withFormDateRangePicker } from 'Hoc';
import { parseDateRange, formatDateRange } from 'Utils/dateHelpers';
import defaultDateFormats, { localeDateFormats } from 'Constants/date-formats';

class InputDateRangePickerField extends Component {
    static propTypes = {
        enhancer: PropTypes.func,
        dateRangeOptions: PropTypes.shape({
            startDateOptions: PropTypes.object,
            endDateOptions: PropTypes.object,
            checkboxOptions: PropTypes.object
        }),
        showYearDropdown: PropTypes.bool,
        showMonthDropdown: PropTypes.bool,
        intl: intlShape
    };

    static defaultProps = {
        enhancer: withFormDateRangePicker
    };

    constructor(props) {
        super(props);
        this.enhancer = this.props.enhancer(InputDateRangePicker);
    }

    render() {
        const { dateRangeOptions, showYearDropdown, showMonthDropdown, intl } = this.props;
        const props = _.omit(this.props, ['enhancer', 'intl']);
        const names = [];
        const formatNames = {};
        const dateFormats = (localeDateFormats[intl.locale] || defaultDateFormats);
        const dateFormat = dateFormats.DATEPICKER_FORMAT;
        const placeholder = dateFormats.DATEPICKER_FORMAT_PLACEHOLDER;
        const parse = this.enhancer.parse || _.partialRight(parseDateRange, dateFormat);
        const format = this.enhancer.format || formatDateRange;

        _.mapValues(dateRangeOptions, (fieldOptions, key) => {
            if (fieldOptions && fieldOptions.name) {
                names.push(fieldOptions.name);
                formatNames[key] = fieldOptions.name;
            }
        });

        const patchedDateRangeOptions = {
            ...dateRangeOptions,
            startDateOptions: {
                ...dateRangeOptions.startDateOptions,
                placeholder,
                dateFormat
            },
            endDateOptions: {
                ...dateRangeOptions.endDateOptions,
                placeholder,
                dateFormat
            }
        };

        return (
            <Fields
                {...props}
                dateRangeOptions={patchedDateRangeOptions}
                showYearDropdown={showYearDropdown}
                showMonthDropdown={showMonthDropdown}
                names={names}
                parse={parse(formatNames)}
                format={format(formatNames)}
                component={this.enhancer}/>);
    }
}

export default injectIntl(InputDateRangePickerField);
