import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import AnalysisTurnoverLegendModalTable from './AnalysisTurnoverLegendModalTable';

import './AnalysisTurnoverLegendModalView.scss';

const AnalysisTurnoverLegendModalView = (props) => {
    const {
        okText,
        onOk
    } = props;

    return (
        <div className='analysis-turnover-legend-modal-view'>
            <AnalysisTurnoverLegendModalTable />
            <div className='description-block'>
                <FormattedHTMLMessage id='modals.analysis.turnover.description'/>
            </div>
            <div className='modal-actions'>
                <button
                    type='button'
                    onClick={onOk}
                    className='btn btn-large'>
                    {okText}
                </button>
            </div>
        </div>
    );
};

AnalysisTurnoverLegendModalView.propTypes = {
    okText: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired
};

export default AnalysisTurnoverLegendModalView;
