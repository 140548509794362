import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mapProps } from '@shakacode/recompose';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';

import Widget from '../Widget';
import * as columnDefinitions from './constants/columnDefinitions';
import {
    dataTypeSelector,
    dateRange as getDateRange,
    getSelectedSecurities,
    includeDetailedDataSelector,
    includeWeightedDataSelector
} from 'State/reportingControlBooks/selectors';
import { showCommonColumnsManagerModal } from 'State/modal';
import { showExportWidgetModal, runReport } from 'State/exportReports';
import reportTypes from 'Constants/report-types';
import ControlBooksResultHeader from './components/ControlBooksResultHeader';
import ControlBooksResultContent from './components/ControlBooksResultContent';
import ControlBooksResultControlPanel from './components/ControlBooksResultControlPanel';
import { commonWidgetPropTypes, commonWidgetActions, getManagerTransformColumns } from '../utils';
import exportReportFormats from 'Constants/export-report-formats';

import './ControlBooksResult.scss';

const mapStateToProps = (state) => ({
    dateRange: getDateRange(state) || {},
    securities: getSelectedSecurities(state),
    includeDetailedData: includeDetailedDataSelector(state),
    includeWeightedData: includeWeightedDataSelector(state),
    dataType: dataTypeSelector(state)
});
const mapDispatchToProps = {
    showCommonColumnsManagerModal,
    showExportWidgetModal,
    runReport
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@mapProps(props => {
    const formatMessage = props.intl.formatMessage;
    const selectedColumns = _.get(props, ['widgetSettings', 'selectedColumns', props.dataType], []);
    const managerColumns = getManagerTransformColumns(
        columnDefinitions.getColumnsByDataType(props.dataType),
        selectedColumns,
        formatMessage);
    const columns = _.filter(
        columnDefinitions.getColumnsByDataType(props.dataType),
        column => _.includes(selectedColumns, column.columnName) || !column.manageable);
    const controlBooksResults = _.get(props, 'widgetData.controlBooksResults') || [];
    const messageKey = _.get(props, 'widgetData.messageKey');

    return {
        ...props,
        managerColumns,
        columns,
        messageKey,
        controlBooksResults
    };
})
class ControlBooksResult extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        showExportWidgetModal: PropTypes.func.isRequired,
        intl: intlShape,
        securities: PropTypes.array,
        includeDetailedData: PropTypes.bool,
        includeWeightedData: PropTypes.bool,
        dataType: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: ControlBooksResultControlPanel,
            WidgetContent: ControlBooksResultContent,
            WidgetHeader: ControlBooksResultHeader
        };

        this.widgetEvents = {
            onBuildDataParams: this.onBuildDataParams
        };
    }

    handleExport = () => {
        const { widgetData, securities = [], includeDetailedData, includeWeightedData, widgetSettings } = this.props;
        const dateRange = _.get(widgetData, 'aggregateValues.dateRange', {});

        if (securities.length > 1 || includeDetailedData || includeWeightedData) {
            this.props.runReport(
                reportTypes.controlBooksTemplate,
                null,
                exportReportFormats.EXCEL,
                {
                    columnsToDisplay: widgetSettings.selectedColumns,
                    dateRange,
                    securities,
                    includeDetailedData,
                    includeWeightedData
                }
            );
        } else {
            this.props.showExportWidgetModal({
                reportTemplateType: reportTypes.controlBooksTemplate,
                exportParams: {
                    columnsToDisplay: widgetSettings.selectedColumns,
                    dateRange,
                    securities,
                    includeDetailedData,
                    includeWeightedData
                },
                modalViewParams: {
                    showExcelLimit: true
                }
            });
        }
    };

    handleColumnsManager = () => {
        this.props.showCommonColumnsManagerModal({
            titleKey: 'modals.commonColumnsManager.title',
            onSave: this.onChangeColumns,
            columns: this.props.managerColumns,
            disableValidation: true,
            modalClassName: 'control-books-column-manager'
        });
    };

    onChangeColumns = (columns) => {
        const { dataType } = this.props;
        const selectedColumns = _.map(_.filter(columns, column => column.isSelected), column => column.name);
        const visibleColumns = [
            ..._.map(
                _.filter(columnDefinitions.getColumnsByDataType(dataType), column => !column.manageable),
                column => column.columnName
            ), ...selectedColumns
        ];

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            selectedColumns: {
                ...this.props.widgetSettings.selectedColumns,
                [dataType]: visibleColumns
            }
        });
    };

    onBuildDataParams = () => ({
        dateRange: this.props.dateRange,
        securities: this.props.securities,
        includeDetailedData: this.props.includeDetailedData,
        includeWeightedData: this.props.includeWeightedData
    });

    render() {
        const props = {
            ...this.props,
            widgetControlPanelSettings: {
                onColumnsManagerClick: this.handleColumnsManager,
                onExport: this.handleExport
            }
        };

        return (
            <Widget
                {...props}
                className='control-books-result'
                components={this.widgetComponents}
                events={this.widgetEvents}/>
        );
    }
}

export default ControlBooksResult;
