import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';

import * as userSelectors from 'State/user/selectors';
import * as featuresSelectors from 'State/features/selectors';
import { buildLocationWithSecurity } from 'Utils/routing';
import pageRoutes from '../constants/page-routes';

const isAllowByFeature = (currentFeatures, feature) => {
    if (!feature) {
        return false;
    }

    return currentFeatures[feature];
};

const isAllowByProductSources = (currentProductSources, productSources) => {
    if (!productSources) {
        return false;
    }

    const normalizedProductSources = _.isString(productSources) ? [productSources] : productSources;

    return _.intersection(normalizedProductSources, currentProductSources).length;
};

const checkPermission = ({
    currentFeatures, currentProductSources,
    feature, productSources,
    history, customSecurityId
}) => {
    if (!isAllowByFeature(currentFeatures, feature) && !isAllowByProductSources(currentProductSources, productSources)) {
        history.replace(buildLocationWithSecurity(pageRoutes.index, customSecurityId));
    }
};

const mapStateToProps = (state) => ({
    currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
    currentProductSources: featuresSelectors.getCurrentProductSourcesSelector(state),
    customSecurityId: userSelectors.getCustomSecurityIdSelector(state)
});

const restrictedComponent = ({ feature, productSources }) => WrappedComponent => {
    @withRouter
    @connect(mapStateToProps)
    class RestrictedComponent extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'restrictedComponent');

        static propTypes = {
            currentFeatures: PropTypes.object,
            currentProductSources: PropTypes.array,
            history: PropTypes.object,
            customSecurityId: PropTypes.number
        };

        componentDidMount() {
            checkPermission({ ...this.props, feature, productSources });
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            checkPermission({ ...nextProps, feature, productSources });
        }

        render() {
            return (
                <WrappedComponent {...this.props}/>
            );
        }
    }

    return RestrictedComponent;
};

export default restrictedComponent;
