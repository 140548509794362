export default {
    HTTP_200: 200,
    HTTP_302: 302,
    HTTP_400: 400,
    HTTP_401: 401,
    HTTP_403: 403,
    HTTP_404: 404,
    HTTP_406: 406,
    HTTP_409: 409,
    HTTP_422: 422,
    HTTP_423: 423,
    HTTP_500: 500,
    HTTP_502: 502,
    HTTP_503: 503
};
