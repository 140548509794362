import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import formatters from 'Constants/formatter-options';
import { NumberFormatter } from 'Components/formatters';

const CellWithNumber = (props) => {
    const { value, className, additionalData: { decimalPoints, withTitle, ellipsis, isOriginalValue } = {} } = props;
    const cellClass = cn('cell-with-number', className, { ellipsis });
    const numberDecimalPoints = _.isNumber(decimalPoints) ? decimalPoints : formatters.DEFAULT_DECIMAL_POINTS;

    return (
        <div className={cellClass}>
            <NumberFormatter
                value={value}
                isOriginalValue={isOriginalValue}
                decimalPoints={numberDecimalPoints}
                withTitle={ellipsis ? true : withTitle}/>
        </div>
    );
};

CellWithNumber.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    additionalData: PropTypes.shape({
        isOriginalValue: PropTypes.bool,
        decimalPoints: PropTypes.number,
        withTitle: PropTypes.bool,
        ellipsis: PropTypes.bool
    })
};

export default CellWithNumber;
