import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { initialize } from 'redux-form';
import * as _ from 'lodash';

import SearchBySecurity from 'Components/SearchBySecurity';
import SearchAcrossSelector from 'Components/SearchBySecurity/components/SearchAcross';
import MultipleSecuritySelector from 'Components/MultipleSecuritySelector/MultipleSecuritySelector';
import { changeCurrentSecurity } from 'State/user/actions';
import * as userSelectors from 'State/user/selectors';
import * as issuersSelectors from 'State/issuers/selectors';
import { checkCurrentSecurity, resetSelectedIssuersAndSecuritiesSearch } from 'State/advancedSearch';
import { PII, SEARCH_FORM_NAME } from 'Constants/search';

import './SearchPage.scss';

const mapStateToProps = state => ({
    issuersAmount: issuersSelectors.getIssuersAmount(state),
    currentSecurityId: userSelectors.getCurrentSecurityIdSelector(state)
});
const mapDispatchToProps = {
    changeCurrentSecurity,
    initialize,
    resetSelectedIssuersAndSecuritiesSearch,
    checkCurrentSecurity
};

@connect(mapStateToProps, mapDispatchToProps)
class SearchPage extends Component {
    static propTypes = {
        issuersAmount: PropTypes.number,
        initialize: PropTypes.func,
        currentSecurityId: PropTypes.number,
        resetSelectedIssuersAndSecuritiesSearch: PropTypes.func,
        checkCurrentSecurity: PropTypes.func
    };

    UNSAFE_componentWillMount() {
        this.props.resetSelectedIssuersAndSecuritiesSearch();
        this.props.initialize(SEARCH_FORM_NAME, {
            PII: PII.NONE,
            searchAcrossCurrent: true
        });
        this.props.checkCurrentSecurity();
    }

    render() {
        const { issuersAmount, currentSecurityId } = this.props;
        const securitySelectorTitleKey = issuersAmount === 1 ? 'security.selectSecurity.multi.security.title' : 'security.selectSecurity.multi.issuerAndSecurity.title';
        const hasSelectedSecurity = !_.isNil(currentSecurityId);

        return (<div className='search-page'>
            {hasSelectedSecurity && <h3 className='search-across-title'><FormattedMessage id='search.across'/>:</h3>}
            {hasSelectedSecurity && <SearchAcrossSelector hasSelectedSecurity={hasSelectedSecurity}/>}
            <div className='multiple-security-selector-header'>
                <h3 className='title'><FormattedMessage
                    id={securitySelectorTitleKey}/>
                </h3>
                <div className='message-note'>
                    <FormattedMessage id='security.selectSecurity.multi.description'/>
                </div>
            </div>
            <MultipleSecuritySelector/>
            <h3 className='title'><FormattedMessage id='search.by'/>:</h3>
            <SearchBySecurity/>
        </div>);
    }
}

export default SearchPage;
