import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Icon from 'Components/Icon';
import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';

export const DeleteUploadedFileCell = ({ row }) => {
    const status = _.get(row, 'status');

    if (status === uploadedDocumentStatuses.IN_PROGRESS) {
        return null;
    }

    return (
        <Icon className='font-icon font-icon-delete'/>
    );
};

DeleteUploadedFileCell.propTypes = {
    row: PropTypes.shape({
        status: PropTypes.string
    })
};
