import PropTypes from 'prop-types';
import React from 'react';

import Selector from 'Components/Selector';

const ShareholderTypeField = ({ input, items }) => {
    return (
        <Selector
            {...input}
            wrapperClass='shareholder-type-selector'
            onOptionChange={v => input.onChange(v)}
            clearable={false}
            searchable={false}
            options={items}/>
    );
};

ShareholderTypeField.parse = value => value && value.value;

ShareholderTypeField.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })
    ),
    input: PropTypes.object
};

export default ShareholderTypeField;
