import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import { isFetchingAny, isSecuritySelected, toggleMultipleSecurity } from 'State/advancedSearch';
import { securityFullNameFormatter } from 'Components/formatters/formatters';
import { CheckBox } from 'Components/CheckBox';
import pageRoutes from 'Constants/page-routes';

const mapStateToProps = (state, { security }) => ({
    checked: isSecuritySelected(state, security.id),
    disabled: isFetchingAny(state)
});
const mapDispatchToProps = { toggleMultipleSecurity };

@connect(mapStateToProps, mapDispatchToProps)
class SecurityCheckBox extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        checked: PropTypes.bool,
        security: PropTypes.object,
        style: PropTypes.object,
        toggleMultipleSecurity: PropTypes.func
    };

    handleChangeSecurity = (e) => {
        this.props.toggleMultipleSecurity(this.props.security, e.target.checked);
    };

    render() {
        const { security: { id, name, ticker, cusip, companyNumber }, disabled, checked, style } = this.props;
        const securityTitle = securityFullNameFormatter(name, ticker, cusip, companyNumber);
        const to = { pathname: pageRoutes.index, search: queryString.stringify({ security: id }) };

        return (
            <div style={style} className={cn('check-boxes-list-item', { checked })}>
                <CheckBox
                    checked={checked}
                    disabled={disabled}
                    onChange={this.handleChangeSecurity}/>
                <Link
                    to={to}
                    className='label'
                    title={securityTitle}>
                    {securityTitle}
                </Link>
            </div>
        );
    }
}

export default SecurityCheckBox;
