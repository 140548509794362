import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import { connect } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';

import {
    showSaveReportTemplateModal,
    showSaveScheduledReportTemplateModal,
    saveChangesModal
} from 'State/modal/actions';
import * as savedReportTemplatesSelectors from 'State/savedReportTemplates/selectors';
import reportExportFormats from 'Constants/export-report-formats';
import PagePrompt from 'Components/PagePrompt';
import { runReport } from 'State/exportReports';
import reportTypes from 'Constants/report-types';

const mapStateToProps = (state, ownProps) => {
    const reportTemplateId = +_.get(ownProps.match, 'params.id');

    return ({
        reportTemplateId: _.isNaN(reportTemplateId) ? null : reportTemplateId,
        report: savedReportTemplatesSelectors.getReportByIdSelector(state, ownProps, reportTemplateId)
    });
};
const mapDispatchToProps = {
    saveChangesModal,
    showSaveReportTemplateModal,
    showSaveScheduledReportTemplateModal,
    runReport
};

const reportEnhancer = WrappedComponent => {
    @connect(mapStateToProps, mapDispatchToProps)
    class ReportEnhancer extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'reportEnhancer');

        static propTypes = {
            ...reduxFormPropTypes,
            saveReportTemplate: PropTypes.func.isRequired,
            setDefaultTemplate: PropTypes.func.isRequired,
            changeTemplate: PropTypes.func.isRequired,
            getTemplate: PropTypes.func.isRequired,
            runReport: PropTypes.func,
            showSaveReportTemplateModal: PropTypes.func,
            saveChangesModal: PropTypes.func,
            templateData: PropTypes.object,
            isEditMode: PropTypes.bool,
            history: PropTypes.object
        };

        componentDidMount() {
            this.updateInitialValues(this.props.reportTemplateId);
        }

        componentDidUpdate(prevProps) {
            const prevReportTemplateId = prevProps.reportTemplateId;
            const reportTemplateId = this.props.reportTemplateId;

            if (prevReportTemplateId !== reportTemplateId) {
                this.updateInitialValues(reportTemplateId);
            }
        }

        handleSubmit = (formValues, params = {}) => {
            const { isEditMode, templateData } = this.props;
            const form = _.omit(formValues, 'formReportName');

            if (isEditMode) {
                return this.props.changeTemplate({
                    ...templateData,
                    reportName: formValues.formReportName,
                    form
                });
            }

            if (params.isSchedulerAvailable && templateData.type === reportTypes.shareholderListTemplate) {
                return this.props.showSaveScheduledReportTemplateModal({
                    titleKey: 'modals.saveNewReport.title',
                    onSuccess: this.props.saveReportTemplate,
                    payload: {
                        type: templateData.type,
                        form
                    }
                });
            }

            return this.props.showSaveReportTemplateModal({
                titleKey: 'modals.saveNewReport.title',
                onSuccess: this.props.saveReportTemplate,
                payload: {
                    type: templateData.type,
                    form
                }
            });
        };

        handleGet = (format) => (e, data = {}) => {
            if (e) e.preventDefault();
            const { formValues, templateData: { type } } = this.props;

            this.props.runReport(
                type,
                null,
                format,
                data.formValues || formValues);
        };

        handleGetPDF = this.handleGet(reportExportFormats.PDF);
        handleGetExcel = this.handleGet(reportExportFormats.EXCEL);

        updateInitialValues = (reportTemplateId) => {
            if (reportTemplateId) {
                this.props.getTemplate(reportTemplateId);
            } else {
                this.props.setDefaultTemplate();
            }
        };

        showSaveChangesModal = ({ isActive, onCancel, onConfirm }) => {
            const { templateData: { reportName } } = this.props;

            if (isActive) {
                return (
                    <PagePrompt
                        onConfirm={onCancel}
                        onClose={onCancel}
                        payload={reportName}
                        onCancel={onConfirm}/>
                );
            }
        };

        needToConfirmLeaving = () => {
            const { isEditMode, dirty, report } = this.props;

            return isEditMode && dirty && !!report;
        };

        render() {
            return (
                <div>
                    <WrappedComponent
                        {...this.props}
                        onSubmit={this.handleSubmit}
                        onGetPDF={this.handleGetPDF}
                        onGetExcel={this.handleGetExcel}/>
                    <NavigationPrompt when={this.needToConfirmLeaving}>
                        {this.showSaveChangesModal}
                    </NavigationPrompt>
                </div>
            );
        }
    }

    return ReportEnhancer;
};

export default reportEnhancer;
