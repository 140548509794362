import React from 'react';
import PropTypes from 'prop-types';

import { CheckBox } from 'Components/CheckBox';

export const SubscriptionName = ({ type, name, onChange, checked = false, disabled = false }) => {
    const handleChange = e => {
        onChange(type, e.target.checked);
    };

    if (disabled) {
        return <div className='subscription-name-no-checkbox'>{name}</div>;
    }

    return (
        <CheckBox
            className='subscription-checkbox'
            onChange={handleChange}
            label={name}
            checked={checked}/>
    );
};

SubscriptionName.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool
};
