import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { colorizeValues } from 'Utils/colorize';
import { headerCell, labelCell } from './renderers';
import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import { stickyFooter } from 'Components/Tables/TableEasy';
import { columns } from '../constants';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';

const ShareholderElectionsChartView = ({ pieData, legendData, intl, widgetNoDividend }) => (
    <div>
        <div className='pie-content-container'>
            <PieChart
                data={pieData}
                customConfig={getPieTooltipConfig(intl)}
                total={legendData.total[columns.PERCENT_OF_TOTAL_ELECTIONS]}/>
        </div>
        <div className='legend-container'>
            <LegendGrid
                footerComponent={stickyFooter}
                headerRowHeight={24}
                {...legendData}/>
        </div>
        {widgetNoDividend &&
        <div className='info-message no-dividend-info'>
            <FormattedMessage id='shareholder.elections.noDividend'/>
        </div>
        }
    </div>
);


ShareholderElectionsChartView.propTypes = {
    pieData: PropTypes.array,
    legendData: PropTypes.object,
    widgetNoDividend: PropTypes.bool,
    intl: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { widgetNoData, data, widgetSettings, intl: { formatMessage } } = props;
        const colorSchemeId = _.get(widgetSettings, 'colorScheme');
        const total = {
            percentOfTotalElections: _.get(data, 'aggregate.totalPercentOfElections')
        };
        const chartValues = _.get(data, 'values');
        let pieData;
        let legendData;

        if (!widgetNoData) {
            const colorizedValues = colorizeValues(chartValues, colorSchemeId);

            pieData = colorizedValues.map(value => ({
                name: value[columns.ELECTION_TYPE],
                y: value[columns.PERCENT_OF_TOTAL_ELECTIONS],
                tooltipValue: value[columns.PERCENT_OF_TOTAL_ELECTIONS],
                color: value.color
            }));
            legendData = {
                columns: [{
                    dataKey: columns.ELECTION_TYPE,
                    headerCellComponent: () => headerCell(
                        formatMessage({ id: 'shareholder.elections.electionType' }),
                        <FormattedHTMLMessage id='shareholder.elections.tooltip.electionType'/>
                    ),
                    bodyCellComponent: labelCell
                }, {
                    dataKey: columns.PERCENT_OF_TOTAL_ELECTIONS,
                    columnClassName: 'text-right',
                    width: 120,
                    title: formatMessage({ id: 'shareholder.elections.percentOfTotalElections' }),
                    headerCellComponent: () => headerCell(
                        formatMessage({ id: 'shareholder.elections.percentOfTotalElections' }),
                        <FormattedHTMLMessage id='shareholder.elections.tooltip.percentOfTotalElections'/>
                    ),
                    bodyCellComponent: TotalValueOsCell
                }],
                data: colorizedValues,
                total,
                markTotalOverflow: props.markTotalOverflow
            };
        }

        return { ...props, pieData, legendData };
    })
)(ShareholderElectionsChartView);
