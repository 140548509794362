import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import CommonColumnsManagerView from './components/CommonColumnsManagerView';

class CommonColumnsManager extends Component {
    static propTypes = {
        intl: intlShape,
        hideModal: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        formValues: PropTypes.object,
        onSave: PropTypes.func.isRequired,
        columns: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            isSelected: PropTypes.bool.isRequired
        })).isRequired,
        error: PropTypes.string,
        rows: PropTypes.number,
        modalClassName: PropTypes.string
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = this.props;

        this.viewText = formatMessage({ id: 'modals.commonColumnsManager.viewText' });
        this.cancelText = formatMessage({ id: 'common.cancel' });
    }

    componentDidMount() {
        this.props.initialize(this.getInitialValues());
    }

    handleSuccess = () => {
        this.props.onSave(this.getNewColumns());
        this.props.hideModal();
    };

    getInitialValues = () => {
        const view = {};
        const columns = this.props.columns;

        _.forEach(columns, (column) => {
            view[column.name] = column.isSelected;
        });
        return view;
    };

    getNewColumns = () => {
        const columns = _.cloneDeep(this.props.columns);
        const formValues = this.props.formValues;

        return _.map(columns, column => ({ ...column, isSelected: formValues[column.name] }));
    };

    render() {
        const { columns, error, rows, modalClassName } = this.props;
        const modalProps = {
            viewText: this.viewText,
            cancelText: this.cancelText,
            onCancel: this.props.hideModal,
            onSuccess: this.handleSuccess,
            columns: _.map(columns, ({ label, name }) => ({ label, name })),
            isValid: !error,
            error,
            rows,
            modalClassName
        };

        return (
            <CommonColumnsManagerView {...modalProps}/>
        );
    }
}

export default CommonColumnsManager;
