import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const ArrowScrollComponent = ({ onScroll, isLeft, style }) => {
    const className = cn('font-icon', isLeft
        ? 'font-icon-arrow-left'
        : 'font-icon-arrow-right');

    return (
        <div
            className='arrow-scroll-block'
            style={style}>
            <i
                className={className}
                onClick={onScroll} />
        </div>
    );
};

ArrowScrollComponent.propTypes = {
    onScroll: PropTypes.func.isRequired,
    isLeft: PropTypes.bool,
    style: PropTypes.object
};

ArrowScrollComponent.defaultProps = {
    isLeft: false
};

export default ArrowScrollComponent;
