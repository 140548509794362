import PropTypes from 'prop-types';
import React from 'react';

const CustomViewOptionRenderer = ({ label, createLink }) => {
    return createLink ? createLink : (<span>{label}</span>);
};

CustomViewOptionRenderer.propTypes = {
    label: PropTypes.string,
    createLink: PropTypes.object
};

export default CustomViewOptionRenderer;

