import { TA } from 'Constants/product-source-types';
import featureTypes from 'Constants/feature-types';
import shareholderTypes from 'Constants/shareholder-types';
import _ from 'lodash';

export default [
    {
        value: shareholderTypes.ALL,
        label: 'shareholderTypes.all',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        }
    },
    {
        value: shareholderTypes.INSTITUTION,
        label: 'shareholderTypes.institutions',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        }
    },
    {
        value: shareholderTypes.INSIDER,
        label: 'shareholderTypes.insiders',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        }
    },
    {
        value: shareholderTypes.INSTITUTION_AND_INSIDER,
        label: 'shareholderTypes.institutionsAndInsiders',
        availableFor: (currentProductSources, currentFeatures) => {
            const hasOIFilterFeature = currentFeatures[featureTypes.OI_PLUS_OPTIONS];
            const hasTA = _.includes(currentProductSources, TA);

            return hasOIFilterFeature && hasTA;
        }
    },
    {
        value: shareholderTypes.REGISTERED,
        label: 'shareholderTypes.registered',
        availableFor: {
            productSources: [TA]
        }
    }
];
