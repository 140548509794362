import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { NullableFormatterWithIntl } from './NullableFormatter';
import { changeFormatter, scaleFormatter } from './formatters';

const scale = 1000;

const MillionChangeFormatter = (props) => {
    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, negClass, intl }) => {
                let formattedValue = value;

                formattedValue = scaleFormatter(formattedValue, scale);
                formattedValue = changeFormatter(intl, formattedValue);

                return (<span className={cn(props.className, negClass)}>{formattedValue}</span>);
            }}
        </NullableFormatterWithIntl>
    );
};

MillionChangeFormatter.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string
};

export default MillionChangeFormatter;
