import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { withProps } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { SearchRangeComponentsValidation } from '../components/SearchRangeComponents';
import ReportingShareRangeAnalysisSearchPageView from '../components/ReportingShareRangeAnalysisSearchPageView';
import { SHARE_TYPES_RADIO_VALUES } from '../constants/shareTypesRadioValues';
import { INITIAL_VALUES } from '../constants/initialValues';
import restricted from 'Hoc/restricted';
import featureTypes from 'Constants/feature-types';
import pageRoutes from 'Constants/page-routes';
import * as featuresSelectors from 'State/features/selectors';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';

import '../components/ReportingShareRangeAnalysisSearchPage.scss';

const FORM_NAME = 'reportingShareRangeAnalysisForm';
const getFormValuesSelector = getFormValues(FORM_NAME);

const mapStateToProps = (state) => ({
    formValues: getFormValuesSelector(state),
    isCombinedProduct: featuresSelectors.isCombinedProductSelector(state)
});

@injectIntl
@restricted({ feature: featureTypes.SHARE_RANGE_ANALYSIS_RESULTS })
@connect(mapStateToProps)
@reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    initialValues: INITIAL_VALUES,
    validate: values => {
        const errors = {};

        errors.shareRange = SearchRangeComponentsValidation(values.shareRange);

        return errors;
    }
})
@withProps(props => {
    return {
        shareTypeOptions: SHARE_TYPES_RADIO_VALUES.map(value => ({
            ...value,
            label: props.intl.formatMessage({ id: value.label })
        }))
    };
})
@withRouter
class ReportingShareRangeAnalysisSearchPage extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        formValues: PropTypes.object,
        submitSearch: PropTypes.func,
        history: PropTypes.object,
        location: PropTypes.object,
        isCombinedProduct: PropTypes.bool
    };

    componentDidMount() {
        this.props.initialize(INITIAL_VALUES);
    }

    handleSubmit = () => {
        this.props.history.push(buildLocationWithSafeQueryParams(pageRoutes.reportingShareRangeAnalysisResults, this.props.location));
    };

    handleEnter = (fieldName, newValue) => {
        const { formValues } = this.props;
        const newRangeValues = { ...formValues.shareRange, [fieldName]: newValue };
        const errors = this.props.validate({ ...formValues, shareRange: newRangeValues });

        if (_.isEmpty(errors.shareRange)) {
            this.handleSubmit();
        }
    };

    handleChange = (field) => {
        this.props.touch(`shareRange.${field}`);
    };

    render() {
        return (
            <ReportingShareRangeAnalysisSearchPageView
                onSubmit={this.handleSubmit}
                onEnter={this.handleEnter}
                onChange={this.handleChange}
                {...this.props}/>
        );
    }
}

export default ReportingShareRangeAnalysisSearchPage;
