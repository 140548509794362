import * as api from '../../api';
import { downloadFile } from 'Utils/utils';
import apiRoutes from 'Constants/api-routes';

export const RESOURCES_REQUEST = 'RESOURCES_REQUEST';
export const RESOURCES_SUCCESS = 'RESOURCES_SUCCESS';
export const RESOURCES_FAILURE = 'RESOURCES_FAILURE';
export const DOCUMENT_DOWNLOAD_REQUEST = 'DOCUMENT_DOWNLOAD_REQUEST';
export const DOCUMENT_DOWNLOAD_SUCCESS = 'DOCUMENT_DOWNLOAD_SUCCESS';
export const DOCUMENT_DOWNLOAD_ERROR = 'DOCUMENT_DOWNLOAD_ERROR';

export const loadResources = () => dispatch => {
    dispatch({
        type: RESOURCES_REQUEST
    });

    return api.getResources().then(
        res => dispatch({
            type: RESOURCES_SUCCESS,
            payload: res.data.payload
        }),
        error => dispatch({
            type: RESOURCES_FAILURE,
            payload: error
        })
    );
};

export const downloadDocument = documentId => dispatch => {
    dispatch({
        type: DOCUMENT_DOWNLOAD_REQUEST
    });

    try {
        downloadFile(`${apiRoutes.downloadStaticDocumentUrl}/${documentId}`, (err) => {
            throw new Error(err);
        });
        dispatch({
            type: DOCUMENT_DOWNLOAD_SUCCESS
        });
    } catch (e) {
        dispatch({
            type: DOCUMENT_DOWNLOAD_ERROR
        });
    }
};
