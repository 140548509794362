import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';

import InputDatePicker from './InputDatePicker';
import { withFormDatePicker } from 'Hoc';
import { parseDate, formatDate } from 'Utils/dateHelpers';
import defaultDateFormats, { localeDateFormats } from 'Constants/date-formats';

class InputDatePickerField extends Component {
    static propTypes = {
        enhancer: PropTypes.func,
        dateOptions: PropTypes.object,
        disabled: PropTypes.bool,
        intl: intlShape
    };

    static defaultProps = {
        enhancer: withFormDatePicker
    };

    constructor(props) {
        super(props);
        this.enhancer = props.enhancer(InputDatePicker);
    }

    render() {
        const { dateOptions, enhancer, intl, ...restProps } = this.props;
        const dateFormats = (localeDateFormats[intl.locale] || defaultDateFormats);
        const dateFormat = dateFormats.DATEPICKER_FORMAT;
        const placeholder = dateFormats.DATEPICKER_FORMAT_PLACEHOLDER;
        const parseValue = (value) => parseDate(value, dateFormat);
        const parse = this.enhancer.parse || parseValue;
        const format = this.enhancer.format || formatDate;

        return (
            <Field
                placeholder={placeholder}
                dateFormat={dateFormat}
                {...restProps}
                {...dateOptions}
                component={this.enhancer}
                parse={parse}
                format={format}/>);
    }
}

export default injectIntl(InputDatePickerField);
