import React, { useCallback, memo } from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';

import { columnDefinitions } from '../../constants/columnDefinitions';
import { TheadComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import Spinner from 'Components/Spinner';
import TotalOutstandingSharesControlPanel from './components/TotalOutstandingSharesControlPanel';
import { dateFormatter } from 'Components/formatters/formatters';

const TotalOutstandingShares = props => {
    const { outstandingShares, isFetching, cobDate, onExport }  = props;
    const formattedCOBDate = dateFormatter(cobDate);

    const getColumnsDefinition = useCallback(columns => {
        const { formatMessage } = props.intl;

        return columns.map((column) => ({
            ...column,
            Header: column.Header(formatMessage({ id: column.displayName }))
        }));
    }, []);

    const getNoDataProps = useCallback(() => ({
        messageId: 'widgets.noDataMessage.simple'
    }), []);

    return (
        <div className='total-outstanding-shares'>
            <div className='table-panel'>
                <FormattedMessage id='reporting.control.books.date' values={{ date: formattedCOBDate }}>
                    {message => <span className='cob-date-message'>{message}</span>}
                </FormattedMessage>
                <TotalOutstandingSharesControlPanel
                    onExport={onExport}
                    widgetNoData={outstandingShares.length === 0}/>
            </div>
            {isFetching
                ? <Spinner isFetching={isFetching}/>
                : <ReactTable
                    showPagination={false}
                    resizable={false}
                    columns={getColumnsDefinition(columnDefinitions)}
                    className='outstanding-shares-table-rt'
                    LoadingComponent={() => null}
                    manual
                    ThComponent={TheadComponent}
                    sortable={false}
                    defaultPageSize={0}
                    getNoDataProps={getNoDataProps}
                    NoDataComponent={NoResults}
                    resolvedData={outstandingShares}/>
            }
        </div>
    );
};

TotalOutstandingShares.propTypes = {
    intl: intlShape,
    outstandingShares: PropTypes.array,
    isFetching: PropTypes.bool,
    cobDate: PropTypes.string,
    onExport: PropTypes.func
};

export default memo(TotalOutstandingShares);
