import  { TA, OI } from 'Constants/product-source-types';
import featureTypes from 'Constants/feature-types';

export default {
    formReportName: [TA, OI],
    securities: [TA, OI, featureTypes.OI_PLUS_OPTIONS],
    shareholderType: [featureTypes.OI_PLUS_OPTIONS],
    shareType: [TA],
    accountType: [TA],
    accountStatus: [TA],
    directDeposit: [TA],
    additionalOptions: [TA],
    dateRange: [TA],
    sharesOwned: [TA],
    dateOfLastContact: [TA, OI],
    columnsToDisplay: [TA, OI],
    sortCriteria: [TA, OI],
    shareholderLocation: [TA, OI],
    noOfRecords: [TA, OI],
    addressStatus: [TA, OI, featureTypes.OI_PLUS_OPTIONS],
    isScheduled: [TA, OI, featureTypes.OI_PLUS_OPTIONS],
    schedule: [TA, OI, featureTypes.OI_PLUS_OPTIONS],
    isLastDayOfMonth: [TA, OI, featureTypes.OI_PLUS_OPTIONS],
    format: [TA, OI, featureTypes.OI_PLUS_OPTIONS]
};
