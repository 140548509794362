import React from 'react';
import PropTypes from 'prop-types';

const ShareholderLinkWrapper = ({ children, ...props }) => {
    return (
        <div {...props}>{children}</div>
    );
};

ShareholderLinkWrapper.propTypes = {
    children: PropTypes.node
};

export default ShareholderLinkWrapper;
