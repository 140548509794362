import _ from 'lodash';

import * as api from '../../api/documents';
import * as utils from './utils';

export const REPORTING_DOCUMENTS_DOWNLOAD_REQUEST = 'REPORTING_DOCUMENTS_DOWNLOAD_REQUEST';
export const REPORTING_DOCUMENTS_DOWNLOAD_SUCCESS = 'REPORTING_DOCUMENTS_DOWNLOAD_SUCCESS';
export const REPORTING_DOCUMENTS_DOWNLOAD_ERROR = 'REPORTING_DOCUMENTS_DOWNLOAD_ERROR';
export const REPORTING_DOCUMENTS_DELETE_REQUEST = 'REPORTING_DOCUMENTS_DELETE_REQUEST';
export const REPORTING_DOCUMENTS_DELETE_SUCCESS = 'REPORTING_DOCUMENTS_DELETE_SUCCESS';
export const REPORTING_DOCUMENTS_DELETE_ERROR = 'REPORTING_DOCUMENTS_DELETE_ERROR';

export const requestDocument = (documentId, shareholderId, securityId, params) => dispatch => {
    dispatch({
        type: REPORTING_DOCUMENTS_DOWNLOAD_REQUEST,
        payload: { documentId, shareholderId }
    });

    return api.getDocumentGuid(documentId, shareholderId, securityId)
        .then(res => {
            const documentGuid = _.get(res, 'data.payload.guid');

            dispatch({
                type: REPORTING_DOCUMENTS_DOWNLOAD_SUCCESS,
                payload: { documentId, shareholderId, documentGuid }
            });
            try {
                utils.downloadDocumentByGuid(dispatch, documentGuid, params);
            } catch (e) {
                dispatch({
                    type: REPORTING_DOCUMENTS_DOWNLOAD_ERROR
                });
            }
        })
        .catch(() => {
            dispatch({
                type: REPORTING_DOCUMENTS_DOWNLOAD_ERROR
            });
        });
};

export const deleteDocument = (documentId, shareholderId) => dispatch => {
    dispatch({
        type: REPORTING_DOCUMENTS_DELETE_REQUEST,
        payload: { documentId, shareholderId }
    });

    return api.deleteDocument(documentId, shareholderId)
        .then(() => {
            dispatch({
                type: REPORTING_DOCUMENTS_DELETE_SUCCESS
            });
        })
        .catch(error => {
            dispatch({
                type: REPORTING_DOCUMENTS_DELETE_ERROR,
                error
            });

            return Promise.reject(error);
        });
};

