import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { every, debounce } from 'lodash';

import { peerShape } from 'Constants/peer';
import timeouts from 'Constants/config';
import * as userSelectors from 'State/user/selectors';
import {
    getPeers,
    findPeerByName,
    addPeer,
    removePeer,
    resetPeers,
    isPeersFetchingSelector,
    peersSelector
} from 'State/peers';
import {
    showConfirmModal,
    showInfoModal
} from 'State/modal';
import UserPeersView from '../components/UserPeersView';
import { getPeerDescription } from 'Utils/peers';


const mapStateToProps = (state) => {
    return {
        isFetching: isPeersFetchingSelector(state),
        maxCountPeers: userSelectors.getMaxSelectedPeers(state),
        peers: peersSelector(state),
        userData: userSelectors.getUserDataSelector(state)
    };
};
const mapDispatchToProps = {
    getPeers,
    findPeerByName,
    addPeer,
    removePeer,
    resetPeers,
    showConfirmModal,
    showInfoModal
};

@connect(mapStateToProps, mapDispatchToProps)
class UserPeers extends Component {
    static propTypes = {
        ...Component.propTypes,
        isFetching: PropTypes.bool,
        maxCountPeers: PropTypes.number,
        peers: PropTypes.arrayOf(peerShape),
        getPeers: PropTypes.func.isRequired,
        findPeerByName: PropTypes.func.isRequired,
        addPeer: PropTypes.func.isRequired,
        removePeer: PropTypes.func.isRequired,
        resetPeers: PropTypes.func.isRequired,
        showConfirmModal: PropTypes.func.isRequired,
        showInfoModal: PropTypes.func.isRequired,
        userData: PropTypes.object
    };

    componentDidMount() {
        if (!this.props.peers) {
            this.props.getPeers();
        }
    }

    handleRemovePeer = (peer) => {
        if (this.props.peers.length === 1) {
            this.props.showInfoModal({
                titleKey: 'modals.peers.warning.title',
                messageKey: 'modals.peers.warning.message'
            });
        } else {
            this.props.showConfirmModal({
                titleKey: 'modals.confirmModal.title.delete',
                messageKey: 'modals.confirmModal.message.delete',
                payload: getPeerDescription(peer),
                onSuccess: () => {
                    this.props.removePeer(peer.id);
                }
            });
        }
    };

    handleSearch = debounce(this.props.findPeerByName, timeouts.throttleTimeout);

    handleAddPeer = (newPeer) => {
        const { peers } = this.props;

        if (every(peers, peer => peer.id !== newPeer.id)) {
            this.props.addPeer(newPeer);
            return true;
        }
    };

    render() {
        const { isFetching, maxCountPeers, peers } = this.props;
        const viewProps = {
            maxCountPeers,
            isFetching,
            peers,
            onResetPeers: this.props.resetPeers,
            onRemovePeer: this.handleRemovePeer,
            onAddPeer: this.handleAddPeer,
            onSearchPeers: this.handleSearch
        };

        return (
            <UserPeersView {...viewProps}/>
        );
    }
}

export default UserPeers;
