import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';

import { colorizeValues, colorizeCombinedValues } from 'Utils/colorize';
import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import { stickyFooter } from 'Components/Tables/TableEasy';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { headerCell, LabelWithPossibleLinkCell, labelCell, numberCell } from './cellRenderers';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';
import { markIncludesCeDeCoRows, markIncludesESPPRows, getMarkedData } from 'Components/widgets/utils';
import { AGGREGATE_ROW, HEADER_ROW_HEIGHT } from '../constants';

const AuthorizedSharesChartView = ({ pieData, widgetFooterProps, legendData, intl, total }) => (
    <div>
        <div className='pie-content-container'>
            <PieChart
                data={pieData}
                customConfig={getPieTooltipConfig(intl)}
                total={total}
                withRound={false}/>
        </div>
        <div className='legend-container'>
            <LegendGrid
                widgetFooterProp={widgetFooterProps}
                footerComponent={stickyFooter}
                headerRowHeight={HEADER_ROW_HEIGHT}
                {...legendData}/>
        </div>
    </div>
);

AuthorizedSharesChartView.propTypes = {
    pieData: PropTypes.array,
    legendData: PropTypes.object,
    total: PropTypes.number,
    intl: PropTypes.object,
    widgetFooterProps: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { data, colorSchemeId, intl: { formatMessage }, widgetFooterProps, withLink } = props;
        const total = _.get(_.last(data), 'aggregate.percentAuthorized');
        const chartValues = [];

        _.forEach(data, v => chartValues.push(...v.values));
        const colorizedValues = colorizeValues(chartValues, colorSchemeId);
        const pieData = colorizedValues.map(value => ({
            name: value.allotment,
            y: value.percentAuthorized,
            tooltipValue: value.percentAuthorized,
            color: value.color
        }));

        const legendValues = [];

        _.forEach(data, v => legendValues.push(...v.values,
            {
                ...v.aggregate,
                rowClassName: AGGREGATE_ROW,
                isAggregate: true
            }));
        const colorizedLegendValues = colorizeCombinedValues(legendValues, colorSchemeId);
        const legendData = {
            columns: [{
                dataKey: 'allotment',
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'authorized.shares.allotment' }),
                    <FormattedHTMLMessage id='authorized.shares.tooltip.allotment'/>
                ),
                bodyCellComponent: withLink ? LabelWithPossibleLinkCell : labelCell,
                columnClassName: 'overfilling-row-container',
                ellipsis: false
            }, {
                dataKey: 'percentAuthorized',
                columnClassName: 'text-right',
                minWidth: 100,
                title: formatMessage({ id: 'authorized.shares.percentAuthorized' }),
                bodyCellComponent: TotalValueOsCell
            }, {
                dataKey: 'noOfShares',
                columnClassName: 'text-right',
                title: formatMessage({ id: 'authorized.shares.noOfShares' }),
                bodyCellComponent: numberCell
            }],
            data: getMarkedData(colorizedLegendValues, [markIncludesCeDeCoRows, markIncludesESPPRows], widgetFooterProps),
            ellipsis: true
        };

        return { pieData, legendData, intl: props.intl, total };
    })
)(AuthorizedSharesChartView);
