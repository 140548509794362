import { createReducer } from 'Utils/reducer';
import {
    REPORTING_DOCUMENTS_DOWNLOAD_REQUEST,
    REPORTING_DOCUMENTS_DOWNLOAD_SUCCESS,
    REPORTING_DOCUMENTS_DOWNLOAD_ERROR
} from './actions';

const initialState = {
    shareholderId: null,
    documentId: null,
    documentGuid: null,
    isFetching: false
};

const actionHandlers = {
    [REPORTING_DOCUMENTS_DOWNLOAD_REQUEST]: (state, { payload: { documentId, shareholderId } }) => ({
        ...initialState,
        shareholderId,
        documentId,
        isFetching: true
    }),
    [REPORTING_DOCUMENTS_DOWNLOAD_SUCCESS]: (state, { payload: { documentId, shareholderId, documentGuid } }) => ({
        shareholderId,
        documentId,
        documentGuid,
        isFetching: false
    }),
    [REPORTING_DOCUMENTS_DOWNLOAD_ERROR]: (state, { payload }) => ({
        ...initialState,
        error: payload
    })
};

export default createReducer(initialState, actionHandlers);
