import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import PropTypes from 'prop-types';

export default (WrappedComponent) => {
    class WithPrint extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withPrint');

        static propTypes = {
            pageTitle: PropTypes.bool,
            setPageTitle: PropTypes.func
        };

        state = {
            pageTitle: document.title,
            printPageTitle: undefined
        }

        componentDidMount() {
            window.addEventListener('beforeprint', this.changePageTitle);
            window.addEventListener('afterprint', this.restorePageTitle);
        }

        componentWillUnmount() {
            window.removeEventListener('beforeprint', this.changePageTitle);
            window.removeEventListener('afterprint', this.restorePageTitle);
        }

        handlePrint = () => {
            window.print();
        }

        handleSetPrintPageTitle = (pageTitle) => {
            this.setState(state => ({ ...state, printPageTitle: pageTitle || document.title }));
        }

        changePageTitle = () => {
            this.setState({ pageTitle: document.title });
            document.title = this.state.printPageTitle;
        }

        restorePageTitle = () => {
            document.title = this.state.pageTitle;
        }

        render() {
            return <WrappedComponent {...this.props} onPrint={this.handlePrint} onSetPrintPageTitle={this.handleSetPrintPageTitle} />;
        }
    }

    return WithPrint;
};
