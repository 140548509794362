import _ from 'lodash';

import defaultViews from '../constants/defaultViews';
import columnTypes from '../constants/shareholder-column-types';
import * as cellComponents from '../../_commonComponents/TableCellComponents';
import SORT_DIRECTION from 'Constants/sort-directions';
import featureByOverview from 'Constants/feature-by-overview';
import { default as shareholderTypes, shareholderTypeAvailability } from 'Constants/shareholder-types';
import { checkBackEndNull } from 'Utils/utils';
import { OI } from 'Constants/product-source-types';
import pageRoutes from 'Constants/page-routes';
import { buildRefToBackQuery } from 'Utils/routing';

export const hasAccessToShareholderType = (userProductSources, shareholderType, currentFeatures) => {
    return _.some(userProductSources, userProductSource =>
        _.includes(shareholderTypeAvailability[shareholderType].productSources, userProductSource)) ||
        !!_.filter(shareholderTypeAvailability[shareholderType].features, feature => currentFeatures[feature]).length;
};

export const resolveSortingViewId = (view) => {
    // we have 1 exceptional case
    // DETAILS and NUMBERS views should have common sorting
    // we take sorting for DETAILS view from NUMBERS field
    return view === defaultViews.DETAILS ? defaultViews.NUMBERS : view;
};

export const getCurrentViewId = (settings) => {
    return _.get(settings, 'views.currentViewId');
};

export const getCurrentSortCriteria = (settings) => {
    const currentViewId = _.get(settings, 'views.currentViewId');
    const sortCriteria = _.get(settings, ['viewsSortCriteria', resolveSortingViewId(currentViewId)]);

    return sortCriteria ||
        // default sorting if sort criteria is not found. It is not described in requirements
        {
            sortField: columnTypes.SHAREHOLDER_NAME,
            sortDirection: SORT_DIRECTION.DESC,
            initial: true
        };
};

export const filterByAvailableFor = (values, sourceArray, currentFeatures) =>
    _.filter(values, column => {
        const productSources = _.get(column, 'availableFor.productSources');
        const features = _.get(column, 'availableFor.features');

        return _.intersection(productSources, sourceArray).length > 0
            || _.filter(features, f => currentFeatures[f]).length > 0;
    });

export const getTdProps = (
    columnId,
    rowData = {},
    {
        onPositionToggle,
        features = {},
        productSources = [],
        sortCriteria,
        currentViewId
    }
) => {
    // Institutions are not clickable if user has no product from OI source
    let disableLink = false;

    if (rowData.shareholderType === shareholderTypes.INSTITUTION
        || rowData.shareholderType === shareholderTypes.FUND
        || rowData.shareholderType === 'UNKNOWN'
        || !rowData.shareholderType) {
        disableLink = !_.includes(productSources, OI);
    }

    if (rowData.accessible === false) {
        disableLink = true;
    }

    const refToBackQuery = buildRefToBackQuery({ pathname: pageRoutes.ownershipShareholders });

    switch (columnId) {
        case columnTypes.FUND_NAME:
            return {
                id: rowData.fundId,
                type: shareholderTypes.FUND,
                refToBackQuery,
                showLink: !disableLink && features[featureByOverview[shareholderTypes.FUND]]
            };
        case columnTypes.SHAREHOLDER_NAME:
            return {
                id: rowData.shareholderId,
                type: rowData.shareholderType,
                refToBackQuery,
                isCedeCo: rowData.isCedeCo,
                showLink: !disableLink && !rowData.isCedeCo && features[featureByOverview[rowData.shareholderType]]
            };
        case columnTypes.POSITION:
            return {
                id: rowData.shareholderId || rowData.fundId,
                date: rowData.positionDate,
                source: rowData.positionSource,
                // Because funds has shareholder type value equal to UNKNOWN
                shareholderType: rowData.fundId ? shareholderTypes.FUND : rowData.shareholderType,
                onPositionToggle,
                onlyValueTooltip: currentViewId === defaultViews.PROXY && rowData.isSubExists
            };
        case columnTypes.OS: {
            return {
                withTitle: true
            };
        }
        case columnTypes.COST_BASIS: {
            return {
                decimalPoints: 2
            };
        }
        case columnTypes.BOOK_SHARES:
        case columnTypes.TOTAL_VALUE:
        case columnTypes.DRIP_SHARES:
        case columnTypes.CURRENT_BALANCE:
        case columnTypes.CERTIFIED_SHARES: {
            return {
                decimalPoints: 3
            };
        }
        case columnTypes.ACCOUNT_NUMBER: {
            return {
                alignRight: true
            };
        }
        case columnTypes.STATE:
        case columnTypes.DIRECT_DEPOSIT:
        case columnTypes.ACTIVE_VS_PASSIVE:
        case columnTypes.PROXY_ADVISORS:
        case columnTypes.TURNOVER: {
            if (DEMO_MODE) {
                return {
                    alignRight: true
                };
            }

            return {
                alignCenter: true
            };
        }
        case columnTypes.PROXY_CONTACTS:
            return {
                id: rowData.shareholderId,
                name: rowData.shareholderName
            };
        case columnTypes.CUMULATIVE_OS: {
            const isSortByPosition = _.get(sortCriteria, 'sortField') === columnTypes.POSITION;
            const isSortDirectionDesc = _.get(sortCriteria, 'sortDirection') === SORT_DIRECTION.DESC;

            return {
                isProxyView: currentViewId === defaultViews.PROXY,
                isSortByPositionDesc: isSortByPosition && isSortDirectionDesc
            };
        }
        case columnTypes.COUNTRY: {
            if (DEMO_MODE) {
                return {
                    alignRight: true
                };
            }

            return  {};
        }
        case columnTypes.HOLDER_TYPE: {
            if (DEMO_MODE) {
                return {
                    alignRight: true
                };
            }

            return  {};
        }
        default:
            return {};
    }
};

export const getCustomComponent = (columnType, currentViewId) => {
    switch (columnType) {
        case columnTypes.PORTFOLIO_PERCENT:
        case columnTypes.OS:
            return {
                customComponent: cellComponents.CellWithPercent,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.SHAREHOLDER_NAME: {
            const customComponent = cellComponents.CellWithShareholderLink;
            let customHeadingComponent = cellComponents.HeadingCellTopShareholders;

            if (currentViewId === defaultViews.PROXY) {
                customHeadingComponent = cellComponents.HeadingCellTopInvestors;
            }

            return {
                customComponent,
                customHeadingComponent
            };
        }
        case columnTypes.FUND_NAME: {
            return {
                customComponent: cellComponents.CellWithShareholderLink,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnTypes.POSITION:
            return {
                customComponent: cellComponents.CellWithPosition,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.F13CHANGE:
        case columnTypes.YTD_CHANGE:
        case columnTypes.CHANGE:
            return {
                customComponent: cellComponents.CellWithChange,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.MKT_VAL :
            return {
                customComponent: cellComponents.CellWithMktValue,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.POSITION_DATE:
        case columnTypes.PREVIOUS_POSITION_DATE:
        case columnTypes.OPENED_DATE :
        case columnTypes.CLOSED_DATE:
            return {
                customComponent: cellComponents.CellWithDate,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.POSITION_CHANGE:
        case columnTypes.PREVIOUS_POSITION:
        case columnTypes.CURRENT_BALANCE:
        case columnTypes.CERTIFIED_SHARES :
        case columnTypes.BOOK_SHARES:
        case columnTypes.DRIP_SHARES :
        case columnTypes.F13:
        case columnTypes.PREVIOUS13F:
        case columnTypes.COST_BASIS:
        case columnTypes.PURCHASING_POWER:
        case columnTypes.ASSETS_UNDER_MANAGEMENT:
        case columnTypes.TOTAL_VALUE :
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.PROXY_CONTACTS:
            return {
                customComponent: cellComponents.CellWithContacts,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.PROXY_VOTING_POLICY:
            return {
                customComponent: cellComponents.CellWithProxyVotingPolicy,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnTypes.CUMULATIVE_OS:
            return {
                customComponent: cellComponents.CellCumulativeOs,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        default:
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
    }
};

export const compareAccountNumbers = (value, other) => {
    const valueIsBackEndNull = checkBackEndNull(value);
    const otherIsBackEndNull = checkBackEndNull(other);

    if (valueIsBackEndNull && otherIsBackEndNull) {
        return 0;
    }

    if (valueIsBackEndNull) {
        return -1;
    }

    if (otherIsBackEndNull) {
        return 1;
    }

    return other - value;
};

export const getShareholderType = (availableDisplayTypes = [], shareholderTypeList = []) => {
    if (availableDisplayTypes.length === shareholderTypeList.length) {
        return shareholderTypes.ALL;
    }

    if (availableDisplayTypes.length === 1) {
        return availableDisplayTypes[0];
    }

    if (availableDisplayTypes.includes(shareholderTypes.INSTITUTION) && availableDisplayTypes.includes(shareholderTypes.INSIDER)) {
        return shareholderTypes.INSTITUTION_AND_INSIDER;
    }

    if (availableDisplayTypes.includes(shareholderTypes.INSTITUTION) && availableDisplayTypes.includes(shareholderTypes.REGISTERED)) {
        return shareholderTypes.INSTITUTION_AND_REGISTERED;
    }

    if (availableDisplayTypes.includes(shareholderTypes.INSIDER) && availableDisplayTypes.includes(shareholderTypes.REGISTERED)) {
        return shareholderTypes.INSIDER_AND_REGISTERED;
    }
};
