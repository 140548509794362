import React, { Component } from 'react';
import { intlShape, injectIntl } from 'react-intl';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import Widget from '../../Widget';
import FullQuoteContent from '../components/FullQuoteContent';
import FullQuoteControlPanel from '../components/FullQuoteControlPanel';

import '../components/FullQuote.scss';

@injectIntl
class FullQuote extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        this.widgetTitle = props.intl.formatMessage({ id: 'full.quote.title' });
        this.widgetComponents = {
            WidgetControlPanel: props.isPrintMode ? null : FullQuoteControlPanel,
            WidgetContent: FullQuoteContent
        };
    }

    render() {
        return (
            <Widget
                {...this.props}
                className='widget-full-quote'
                widgetTitle={this.widgetTitle}
                components={this.widgetComponents}/>
        );
    }
}

export default FullQuote;
