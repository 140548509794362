import React from 'react';
import PropTypes from 'prop-types';

import apiRoutes from 'Constants/api-routes';
import { downloadFile } from 'Utils/utils';
import { withDownloadFileError } from 'Hoc/withDownloadFileError';

const MeetingPlannerTrackerDocument = ({ id, title, notifyDownloadError }) => {
    const handleClick = (documentId) => () => {
        downloadFile(`${apiRoutes.downloadMeetingPlannerDocumentUrl}/${documentId}`, notifyDownloadError);
    };

    return (
        <div className='meeting-planner-tracker__document' key={id} onClick={handleClick(id)}>
            {title}
        </div>
    );
};

MeetingPlannerTrackerDocument.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    notifyDownloadError: PropTypes.func
};

export default withDownloadFileError(MeetingPlannerTrackerDocument);
