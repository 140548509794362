import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes, SubmissionError } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import { reportEnhancer, restricted } from 'Hoc';
import { saveReportTemplate } from 'State/savedReportTemplates/actions';
import * as shareDetailPageSelectors from 'State/reportingShareDetail/selectors';
import {
    setDefaultTemplate,
    getTemplate,
    changeTemplate
} from 'State/reportingShareDetail/actions';
import * as exportReportsSelectors from 'State/exportReports/selectors';

import Spinner from 'Components/Spinner';
import { inputDateRangeValidation } from 'Components/InputDateRangePicker';
import { reportNameValidation } from '../../common/reportNameUtils';
import { scrollTo } from 'Utils/utils';
import ReportingShareDetailPageView from '../components/ReportingShareDetailPageView';
import shareTypesOptions from '../constants/shareTypesOptions';
import shareStatusOptions from '../constants/shareStatusOptions';
import fieldList from '../constants/fieldList';
import featureTypes from 'Constants/feature-types';
import reportNameErrors from 'Constants/report-name-errors';
import httpStatuses from 'Constants/http-status';

const MARGIN_TOP = 24;
const FORM_NAME = 'reportingShareDetailForm';
const getFormValuesSelector = getFormValues(FORM_NAME);

const mapStateToProps = state => ({
    formValues: getFormValuesSelector(state),
    initialValues: shareDetailPageSelectors.getInitialValuesSelector(state),
    templateData: shareDetailPageSelectors.getReportDataSelector(state),
    isEditMode: shareDetailPageSelectors.isEditModeSelector(state),
    isFetching: shareDetailPageSelectors.isFetchingSelector(state),
    isReportFetching: exportReportsSelectors.isFetchingSelector(state),
    formatFetchingReport: exportReportsSelectors.formatFetchingReportSelector(state)
});
const mapDispatchToProps = {
    getTemplate,
    setDefaultTemplate,
    changeTemplate,
    saveReportTemplate
};

@injectIntl
@restricted({ feature: featureTypes.SHARE_DETAIL })
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate: values => {
        const errors = {};

        errors.dateRange = inputDateRangeValidation({ ...values.dateRange, subtractDays: 1 }, { isRequired: true });
        errors.formReportName = reportNameValidation(values.formReportName);

        return errors;
    },
    onSubmitFail: (errors) => {
        const errorNames = _.keys(errors);
        const firstError = _.find(fieldList, field => errorNames.includes(field));
        const el = document.querySelector(`[name="${firstError}"]`);

        scrollTo(el, MARGIN_TOP);
    }
})
@reportEnhancer
class ReportingShareDetailPage extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        onSubmit: PropTypes.func.isRequired,
        onGetExcel: PropTypes.func.isRequired,
        onGetPDF: PropTypes.func.isRequired,
        isEditMode: PropTypes.bool,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = props;
        const dateRangeMaxDate = moment().subtract(1, 'days');

        this.dateRangeOptions = {
            startDateOptions: {
                label: formatMessage({ id: 'datePicker.from' }),
                name: 'dateRange.startDate',
                maxDate: dateRangeMaxDate
            },
            endDateOptions: {
                label: formatMessage({ id: 'datePicker.to' }),
                name: 'dateRange.endDate',
                maxDate: dateRangeMaxDate
            },
            formName: _.get(this.props, 'form')
        };
        this.shareTypesOptions = _.map(shareTypesOptions, type => ({
            ...type,
            label: formatMessage({ id: type.label })
        }));
        this.shareStatusOptions = _.map(shareStatusOptions, status => ({
            ...status,
            label: formatMessage({ id: status.label })
        }));
    }

    handleSubmit = (formValues) => {
        const { onSubmit, isEditMode, intl: { formatMessage } } = this.props;

        if (!isEditMode) return onSubmit(formValues);

        return onSubmit(formValues).then(() => toastr.success(
            formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.title' }),
            formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.message' })
        ), this.errorSaveTemplateHandler);
    };

    errorSaveTemplateHandler = (error) => {
        const { intl: { formatMessage } } = this.props;
        const response = _.get(error, 'response', {});
        const reason = _.get(response, 'data.reasonType', '');
        const errorMessage = reportNameErrors[reason];

        if (response.status === httpStatuses.HTTP_400 && errorMessage) {
            throw new SubmissionError({ formReportName: formatMessage({ id: errorMessage }) });
        }
    };

    render() {
        const { isFetching } = this.props;

        return (
            isFetching
                ? <Spinner isFetching={isFetching} isBlocker/>
                : <ReportingShareDetailPageView
                    {...this.props}
                    shareTypesOptions={this.shareTypesOptions}
                    shareStatusOptions={this.shareStatusOptions}
                    dateRangeOptions={this.dateRangeOptions}
                    onSubmit={this.handleSubmit}/>
        );
    }
}

export default ReportingShareDetailPage;
