import PropTypes from 'prop-types';
import React from 'react';

import PositionListTableRow from './PositionListTableRow';
import NoDataComponent from '../../../Widget/components/NoDataComponent';

const getKey = ({ source, positionDate }, i) => {
    return i + source + positionDate;
};

const PositionListTable = ({ list, maxDate, noDataMessage }) => (
    <div className='positions-list-table'>
        <NoDataComponent
            isNoData={!list.length}
            message={noDataMessage}>
            <table>
                <tbody>
                    {list.map((row, i) => (
                        <PositionListTableRow
                            row={row}
                            key={getKey(row, i)}
                            maxDate={maxDate}/>
                    ))}
                </tbody>
            </table>
        </NoDataComponent>
    </div>
);

PositionListTable.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        positionDate: PropTypes.string,
        source: PropTypes.string,
        position: PropTypes.number
    })).isRequired,
    maxDate: PropTypes.string,
    noDataMessage: PropTypes.string
};

export default PositionListTable;
