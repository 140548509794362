import SHARE_TYPES from 'Constants/share-types';

export default [
    {
        value: SHARE_TYPES.ALL,
        label: 'reporting.share.detail.shareType.allShares'
    },
    {
        value: SHARE_TYPES.CERTIFICATED,
        label: 'reporting.share.detail.shareType.certificated'
    },
    {
        value: SHARE_TYPES.BOOK,
        label: 'reporting.share.detail.shareType.book'
    }
];
