import _ from  'lodash';

import {
    DYNAMIC_PAGE_SYNC,
    DYNAMIC_PAGE_DATAVIZ_SYNC,
    DYNAMIC_PAGE_SYNC_REQUEST,
    DYNAMIC_PAGE_SYNC_SUCCESS,
    DYNAMIC_PAGE_SYNC_FAILURE
} from './actions';
import * as api from '../../api';
import * as pageSelectors from './selectors';
import * as widgetsSelectors from '../widgets/selectors';

const syncActionHandlers = {
    [DYNAMIC_PAGE_SYNC]: store => sync(api.updateWidgets, store),
    [DYNAMIC_PAGE_DATAVIZ_SYNC]: store => sync(api.updateDataVizWidgets, store)
};

const sync = (updateWidgetsRequest, { getState, dispatch }) => {
    const state = getState();
    const pageType = pageSelectors.getDynamicPageTypeSelector(state);
    const dynamicSlots = pageSelectors.getDynamicPageSlotsSelector(state);
    const staticSlots = pageSelectors.getDynamicPageStaticSlotsSelector(state);
    const pageWidgets = widgetsSelectors.getAllWidgetsByPageIdSelector(state, pageType);
    let slots = { ...staticSlots, ...dynamicSlots };

    if (_.isUndefined(pageType)) {
        return;
    }

    slots = _.map(slots, (s, position) => ({
        ...s,
        position
    }));

    const widgets = _.map(pageWidgets, w => {
        const slot = _.find(slots, s => {
            return _.includes(s.widgets, w.widgetId);
        });
        let position = _.get(slot, 'position');

        if (!_.isNumber(position)) {
            try {
                position = parseInt(position, 10);
            } catch (ex) {
                position = undefined;
            }
        }

        return {
            type: w.type,
            widgetId: w.widgetId,
            settings: w.settings,
            position
        };
    }).filter(w => _.isNumber(w.position) && !w.isAdding && !w.isRemoving);

    dispatch({
        type: DYNAMIC_PAGE_SYNC_REQUEST
    });

    updateWidgetsRequest(pageType, widgets)
        .then(
            () => {
                dispatch({
                    type: DYNAMIC_PAGE_SYNC_SUCCESS
                });
            },
            error => {
                dispatch({
                    type: DYNAMIC_PAGE_SYNC_FAILURE,
                    payload: error.message,
                    error: true
                });
                return Promise.reject(error);
            });
};

export const dynamicPageSyncMiddleware = store => next => action => {
    const handler = syncActionHandlers[action.type];

    if (_.isFunction(handler)) {
        handler(store);
    }

    return next(action);
};
