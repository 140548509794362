import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import _ from 'lodash';
import cn from 'classnames';

import { InputDatePickerField } from 'Components/InputDatePicker';
import { RadioButtonField } from 'Components/RadioButton';
import { InputDateRangePickerField } from 'Components/InputDateRangePicker';
import { CheckBoxField } from 'Components/CheckBox';
import viewTypes from 'Constants/control-books-view-types';
import SecuritiesField from 'Components/SecuritiesField';
import TotalOutstandingShares from './TotalOutstandingShares';
import ReportingControlBooksTreasuryAccountsLink from './ReportingControlBooksTreasuryAccountsLink';

import './ReportingControlBooksSearchPage.scss';

const ReportingControlBooksSearchPageView = (props) => {
    const {
        closeOfBusinessDate,
        outstandingShares,
        submitting,
        invalid,
        handleSubmit,
        onSave,
        formValues = {},
        dateRangeOptions,
        dateOptions,
        onTypeChange,
        isFetching,
        securitiesOptions,
        onSecuritiesChange,
        onExport
    } = props;
    const isCheckboxDisabled = _.get(formValues, 'securities.length', 0) > 1 ||
        formValues.shareDistributionType !== viewTypes.TRANSACTIONS_DATE_RANGE;

    return (
        <div className='reporting-control-books-page'>
            <div className='reporting-header-container'>
                <h2 className='reporting-header'>
                    <FormattedMessage id='reporting.control.books.title'/>
                </h2>
                <ReportingControlBooksTreasuryAccountsLink />
            </div>
            <FormattedMessage id='reporting.control.books.note.title'>
                {(title) => (
                    <FormattedMessage id='reporting.control.books.note.content'>
                        {(content) => (
                            <div className='control-books-note'>
                                <span className='note-title'>{title}&nbsp;</span>
                                {content}
                            </div>
                        )}
                    </FormattedMessage>
                )}
            </FormattedMessage>
            <form onSubmit={handleSubmit(onSave)} onChange={onTypeChange}>
                <div className='reporting-section'>
                    <div className='reporting-section-body securities-container'>
                        <FormattedMessage id='reporting.authorizedUserList.securities.label'/>
                        <SecuritiesField
                            onSecuritiesChange={onSecuritiesChange}
                            options={securitiesOptions}
                            selectedValues={formValues.securities}/>
                    </div>
                </div>
                <h4 className='reporting-header-alternative'>
                    <FormattedMessage id='reporting.control.books.view.title'/>
                </h4>
                <div className='reporting-section'>
                    <div className='reporting-section-body'>
                        <RadioButtonField
                            name='shareDistributionType'
                            value={viewTypes.OUTSTANDING_SHARES_DATE}/>
                        <InputDatePickerField
                            dateOptions={dateOptions}
                            disabled={formValues.shareDistributionType !== viewTypes.OUTSTANDING_SHARES_DATE}/>
                        <div className='reporting-actions'>
                            <button
                                type='submit'
                                className='btn btn-large btn-control-books-submit'
                                disabled={submitting || invalid || formValues.shareDistributionType !== viewTypes.OUTSTANDING_SHARES_DATE}>
                                <FormattedMessage id='reporting.control.books.submitForm'/>
                            </button>
                        </div>
                    </div>
                </div>
                <h4 className='reporting-header-alternative'>
                    <FormattedMessage id='reporting.control.books.transaction.title'/>
                </h4>
                <div className='reporting-section'>
                    <div className='reporting-section-body'>
                        <div className='date-range-section'>
                            <RadioButtonField
                                name='shareDistributionType'
                                value={viewTypes.TRANSACTIONS_DATE_RANGE}/>
                            <InputDateRangePickerField
                                dateRangeOptions={dateRangeOptions}/>
                            <div className='reporting-actions'>
                                <button
                                    type='submit'
                                    className='btn btn-large btn-control-books-submit'
                                    disabled={submitting || invalid || formValues.shareDistributionType !== viewTypes.TRANSACTIONS_DATE_RANGE}>
                                    <FormattedMessage id='reporting.control.books.submitForm'/>
                                </button>
                            </div>
                        </div>
                        <div className='additional-options-section'>
                            <div className='checkbox-section'>
                                <CheckBoxField
                                    name='includeDetailedData'
                                    label={<FormattedMessage id='reporting.control.books.transaction.includeDetailedData'/>}
                                    disabled={isCheckboxDisabled}/>
                                <CheckBoxField
                                    name='includeWeightedData'
                                    label={<FormattedMessage id='reporting.control.books.transaction.includeWeightedData'/>}
                                    disabled={isCheckboxDisabled}/>
                            </div>
                            <FormattedMessage id='reporting.control.books.transaction.additionalOptions.messageNote'>
                                {message =>
                                    (<span className={cn(
                                        'message-note',
                                        { disabled: formValues.shareDistributionType !== viewTypes.TRANSACTIONS_DATE_RANGE })}>
                                        {message}
                                    </span>)
                                }
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
            </form>
            <TotalOutstandingShares
                onExport={onExport}
                outstandingShares={outstandingShares}
                isFetching={isFetching}
                cobDate={closeOfBusinessDate}/>
        </div>
    );
};

ReportingControlBooksSearchPageView.propTypes = {
    ...reduxFormPropTypes,
    closeOfBusinessDate: PropTypes.string,
    shares: PropTypes.number,
    isFetching: PropTypes.bool,
    intl: intlShape,
    handleSubmit: PropTypes.func,
    onSave: PropTypes.func,
    dateRangeOptions: PropTypes.object,
    securitiesOptions: PropTypes.object,
    onSecuritiesChange: PropTypes.func,
    onExport: PropTypes.func
};

export default ReportingControlBooksSearchPageView;
