import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import formatters from 'Constants/formatter-options';
import { UNIDENTIFIED } from 'Constants/undefined-values';
import { checkBackEndNull } from 'Utils/utils';

/**
 * @return {string}
 */
const NullableFormatter = (props) => {
    const {
        value,
        nullValue = formatters.SYMBOL_EMPTY_VALUE,
        children,
        withTitle,
        className,
        intl,
        getRef
    } = props;

    if (value === UNIDENTIFIED) {
        const _withTitle = withTitle === undefined ? true : withTitle;

        return (
            <FormattedMessage id='unidentified'>
                {formattedMessage =>
                    <span title={_withTitle ? formattedMessage : null} className={className}>{formattedMessage}</span>}
            </FormattedMessage>
        );
    }

    if (checkBackEndNull(value)) {
        return (<span className={className}>{nullValue}</span>);
    }

    if (React.isValidElement(children)) {
        return (children);
    } else if (_.isFunction(children)) {
        return children({
            value,
            negClass: value < 0 ? 'negative' : '',
            intl
        });
    }

    return (<span title={withTitle ? value : null} className={className} ref={getRef && getRef}>{value}</span>);
};

NullableFormatter.propTypes = {
    value: PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    nullValue: PropTypes.any,
    withTitle: PropTypes.bool,
    unidentifiedMessageId: PropTypes.string,
    className: PropTypes.string,
    intl: intlShape,
    getRef: PropTypes.func
};

export default NullableFormatter;

export const NullableFormatterWithIntl = injectIntl(NullableFormatter);
