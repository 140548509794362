import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';

import { colorizeValues } from 'Utils/colorize';
import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import { stickyFooter } from 'Components/Tables/TableEasy';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { valueWithTooltipCell, headerCell } from './cellRenderers';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';
import { columns } from '../constants';

const HEADER_ROW_HEIGHT = 24;

const BadAddressesChartView = ({ pieData, legendData, intl }) => (
    <div>
        <div className='pie-content-container'>
            <PieChart
                data={pieData}
                customConfig={getPieTooltipConfig(intl)}
                total={legendData.aggregate.openAccountsPercentTotal}/>
        </div>
        <div className='legend-container'>
            <LegendGrid
                footerComponent={stickyFooter}
                headerRowHeight={HEADER_ROW_HEIGHT}
                {...legendData}/>
        </div>
    </div>
);

BadAddressesChartView.propTypes = {
    pieData: PropTypes.array,
    legendData: PropTypes.object,
    intl: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { data, colorSchemeId, intl: { formatMessage }, widgetFooterProps } = props;
        const aggregate = _.get(data, 'aggregate');
        const chartValues = _.get(data, 'data.pieChartData');
        const colorizedValues = colorizeValues(chartValues, colorSchemeId);

        const pieData = colorizedValues.map(value => ({
            name: value.addressStatus,
            y: value.openAccountsPercent,
            tooltipValue: value.openAccountsPercent,
            color: value.color
        }));
        const legendData = {
            columns: [{
                dataKey: columns.ADDRESS_STATUS,
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'bad.addresses.status' }),
                    <FormattedHTMLMessage id='bad.addresses.tooltip.chartView.addressStatus'/>
                ),
                title: formatMessage({ id: 'bad.addresses.status' }), //
                bodyCellComponent: valueWithTooltipCell
            }, {
                dataKey: columns.TOTAL_SHAREHOLDERS_PERCENT,
                minWidth: 165,
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'bad.addresses.total.shareholders.percent' }),
                    <FormattedHTMLMessage id='bad.addresses.tooltip.chartView.percentOfTotalShareholders'/>
                ),
                columnClassName: 'text-right',
                title: formatMessage({ id: 'bad.addresses.total.shareholders.percent' }), //
                bodyCellComponent: TotalValueOsCell
            }],
            data: colorizedValues,
            aggregate,
            widgetFooterProps
        };

        return { pieData, legendData, intl: props.intl };
    })
)(BadAddressesChartView);
