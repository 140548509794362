import React from 'react';
import PropsTypes from 'prop-types';

import dateFormats from 'Constants/date-formats';

import CellWithDate from './CellWithDate';

export const CellWithDateTime = ({ value, className }) => <CellWithDate value={value} className={className} format={dateFormats.withTime} />;

CellWithDateTime.propTypes = {
    value: PropsTypes.string.isRequired,
    className: PropsTypes.string
};
