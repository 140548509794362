import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import RadioButtonField from 'Components/RadioButton/RadioButtonField';
import { PII_TO_L10N } from 'Constants/search';

import './SearchByOption.scss';

class SearchByOption extends Component {
    static propTypes = {
        intl: intlShape,
        value: PropTypes.string.isRequired,
        disabled: PropTypes.bool
    };

    render() {
        const { value, disabled, intl } = this.props;
        const label = intl.formatMessage({ id: `search.by.${PII_TO_L10N[value]}` });

        return (
            <RadioButtonField
                className='search-by-option'
                name='PII'
                value={value}
                disabled={disabled}
                label={label}/>
        );
    }
}

export default SearchByOption;
