import React from 'react';
import { DynamicPage } from 'Components/DynamicPage';
import layoutTypes from 'Constants/layout-types';
import PAGE_TYPES from 'Constants/dynamic-page-types';

import slots from './slots';

const MeetingPlannerPage = () => (
    <div className='meeting-planner-page'>
        <DynamicPage
            layoutType={layoutTypes.layoutEasy}
            slots={slots}
            dynamicPageType={PAGE_TYPES.MEETING_PLANNER}/>
    </div>
);

export default MeetingPlannerPage;
