import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions, getWidgetFooterPropsWithSignClass } from '../utils';
import Widget from '../Widget';
import AnalysisInvestorTypeContent from './components/AnalysisInvestorTypeContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import FooterWithInfo from '../_commonComponents/FooterWithInfo';
import messageType from 'Constants/message-types';

const TOTAL_PERCENT = 100;

class AnalysisInvestorType extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetContent: AnalysisInvestorTypeContent,
            WidgetFooter: FooterWithInfo
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'analysis.investor.type.title' });
    }

    getWidgetFooterProps = () => {
        const widgetData = this.props.widgetData;

        return {
            rightSide: [
                {
                    messageKey: 'widgets.totalOver100',
                    type: messageType.MORE_THAN_100_PERCENTS,
                    hidden: !(_.get(widgetData, 'aggregate.osTotal') > TOTAL_PERCENT)
                }
            ]
        };
    };

    render() {
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetNoData: !_.get(this.props, 'widgetData.values.length'),
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-chart',
                widgetTableTypeIcon: 'font-icon-chart deactivated'
            },
            widgetFooterProps: getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps())
        };

        return (
            <Widget
                {...props}
                className='widget-analysis-investor-type'
                components={this.widgetComponents}/>
        );
    }
}

export default AnalysisInvestorType;
