import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import BarChart from 'Components/BarChart';
import barChartModes, { INACTIVE_LEGENDS_CONFIG } from 'Constants/barchart-modes';
import TableStickyColumnGroups from 'Components/Tables/TableStickyColumnGroups';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

const customConfig = {
    yAxis: {
        tickInterval: 1
    },
    ...INACTIVE_LEGENDS_CONFIG
};

class ActiveVsPassiveContent extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    render() {
        const {
            chartProps,
            barData,
            barPeriods,
            widgetChartVerticalLabel,
            chartMode,
            tableData,
            legendProps,
            tablePeriods,
            isFetching,
            noData,
            isPrintMode
        } = this.props;

        const chart = (
            <div className='chart-content'>
                <BarChart
                    chartProps={chartProps}
                    categories={barPeriods}
                    series={barData}
                    legendProps={legendProps}
                    customConfig={customConfig}
                    verticalTitle={widgetChartVerticalLabel}
                    mode={barChartModes.NEGATIVE_MODE}
                    isOriginalValue/>
            </div>
        );

        const table = (
            <div className='table-content'>
                <TableStickyColumnGroups
                    periods={tablePeriods}
                    showAllRows={isPrintMode}
                    data={tableData}/>
            </div>
        );

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={noData}>
                    {chartMode ? chart : table}
                </NoDataComponent>
            </div>
        );
    }
}

export default ActiveVsPassiveContent;
