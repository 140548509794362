import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { withState } from '@shakacode/recompose';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { getShareholderFunds, setExpandedShareholders } from 'State/shareholderTable';
import ShareholderPositions from '../ShareholderPositions';
import Spinner from 'Components/Spinner';
import { TdComponent } from 'Components/Tables/components/ReactTableComponents';
import { getTdProps as getCommonTdProps, compareAccountNumbers } from '../../utils';
import columnTypes from '../../constants/shareholder-column-types';
import {
    getFundsByIdSelector,
    getExpandedShareholdersSelector,
    getFundsRequestErrorByIdSelector
} from 'State/shareholderTable/selectors';
import featureTypes from 'Constants/feature-types';
import * as featuresSelectors from 'State/features/selectors';
import { sortArray } from 'Utils/utils';
import sortDirections from 'Constants/sort-directions';

import './FundsTable.scss';
import ShareholderIcon from '../ShareholderIcon';
import columnsWidth from '../../constants/columnsWidth';

const DISPLAYED_FUNDS_DEFAULT = 20;
const INCREASE_STEP_FUNDS = 20;
const EXPANDER_POSITIONS_FIELD = 'positions';
const NullComponent = () => null;
const expanderDefaults = { width: columnsWidth.expander };
const getVisibleFunds = createSelector(
    (props) => props.countDisplayedFunds,
    (props) => props.sortCriteria,
    (props, funds) => funds,
    (countDisplayedFunds, sortCriteria, funds) => {
        const { sortDirection, sortField } = sortCriteria;
        const sortedFunds = sortArray({
            array: funds,
            primaryField: sortField === columnTypes.SHAREHOLDER_NAME ? columnTypes.FUND_NAME : sortField,
            descending: sortDirection === sortDirections.DESC,
            secondaryField: columnTypes.FUND_NAME,
            secondaryDescending: false,
            primaryComparator: sortField === columnTypes.ACCOUNT_NUMBER ? compareAccountNumbers : undefined
        });

        return sortedFunds.slice(0, countDisplayedFunds);
    });

const mapStateToProps = (state, props) => {
    const funds = getFundsByIdSelector(state, props.shareholderId);
    const visibleFunds = funds && getVisibleFunds(props, funds);

    return {
        funds: visibleFunds,
        showingAll: funds && funds.length <= visibleFunds.length,
        error: getFundsRequestErrorByIdSelector(state, props.shareholderId),
        expandedShareholders: getExpandedShareholdersSelector(state),
        userFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
        userProductSources: featuresSelectors.getCurrentProductSourcesSelector(state)
    };
};
const mapDispatchToProps = { getShareholderFunds, setExpandedShareholders };

@withState('countDisplayedFunds', 'updateDisplayedFunds', DISPLAYED_FUNDS_DEFAULT)
@connect(mapStateToProps, mapDispatchToProps)
class FundsTable extends PureComponent {
    static propTypes = {
        funds: PropTypes.array,
        countDisplayedFunds: PropTypes.number,
        updateDisplayedFunds: PropTypes.func,
        showingAll: PropTypes.bool,
        error: PropTypes.object,
        shareholderId: PropTypes.string,
        columns: PropTypes.array,
        getShareholderFunds: PropTypes.func,
        userFeatures: PropTypes.object,
        userProductSources: PropTypes.array,
        positionsAvailable: PropTypes.bool,
        sortCriteria: PropTypes.object,
        expandedShareholders: PropTypes.object,
        setExpandedShareholders: PropTypes.func,
        isSubProxy: PropTypes.bool
    };

    state = {
        expanded: {}
    };

    componentDidMount() {
        if (!this.props.funds && !this.props.error) {
            this.props.getShareholderFunds(this.props.shareholderId, this.props.isSubProxy);
        }
    }

    componentWillUnmount() {
        const expandedShareholders = { ...this.props.expandedShareholders };
        const expandedShareholdersIds = Object.keys(expandedShareholders);

        Object.keys(this.state.expanded).forEach(fundId => expandedShareholdersIds.includes(fundId) && delete expandedShareholders[fundId]);

        this.props.setExpandedShareholders(expandedShareholders);
    }

    handleClickShowMore = () => {
        this.props.updateDisplayedFunds(this.props.funds.length + INCREASE_STEP_FUNDS);
    };

    getTdProps = (finalState, rowInfo, column) => {
        const { userFeatures, userProductSources } = this.props;

        return {
            additionalData: getCommonTdProps(column.id, rowInfo.original, {
                onPositionToggle: userFeatures[featureTypes.POSITIONS_HISTORY_VIEW] ? this.onPositionToggle : null,
                features: userFeatures,
                productSources: userProductSources
            })
        };
    };

    onPositionToggle = (fundId) => {
        const { userFeatures } = this.props;

        if (userFeatures[featureTypes.POSITIONS_HISTORY_VIEW]) {
            const expanded = { ...this.state.expanded };

            expanded[fundId] = {
                ...expanded[fundId],
                [EXPANDER_POSITIONS_FIELD]: !(_.get(expanded, `[${fundId}][${EXPANDER_POSITIONS_FIELD}]`))
            };

            // removing false values
            Object.keys(expanded[fundId]).every((key) => !expanded[fundId][key]) && delete expanded[fundId];

            this.props.setExpandedShareholders({ ...this.props.expandedShareholders, ...expanded });
            this.setState({ expanded });
        }
    };

    subComponent = (info) => {
        const { positionsAvailable } = this.props;
        const fundId = _.get(info, 'original.fundId');

        return (positionsAvailable
            ? <ShareholderPositions
                close={this.onPositionToggle}
                shareholderId={fundId}/>
            : null
        );
    };

    render() {
        const {
            funds,
            error,
            columns,
            showingAll
        } = this.props;

        const expanded = _.mapKeys(this.state.expanded, (value, key) => _.findIndex(funds, (fund) => {
            return fund.fundId === key;
        }));
        const isLoading = !funds && !error;
        const isNoData = funds === null || (funds && funds.length === 0);

        return (
            <div className='funds-table'>
                {funds && <div>
                    <ReactTable
                        data={funds}
                        showPagination={false}
                        resizable={false}
                        sortable={false}
                        LoadingComponent={NullComponent}
                        ThComponent={NullComponent}
                        NoDataComponent={NullComponent}
                        TdComponent={TdComponent}
                        getTdProps={this.getTdProps}
                        ExpanderComponent={() => <ShareholderIcon shareholderType='FUND' />}
                        expanderDefaults={expanderDefaults}
                        minRows={0}
                        manual
                        columns={columns}
                        expanded={expanded}
                        SubComponent={this.subComponent}/>
                    {!showingAll &&
                    <div className='rt-tr'>
                        <span
                            onClick={this.handleClickShowMore}
                            className='show-more'>
                            <FormattedMessage id='shareholders.table.increment.visible.funds.message'/>
                        </span>
                    </div>}
                </div>}
                {isNoData && <div className='rt-tr no-data'>
                    <FormattedMessage id='widgets.noDataMessage'/>
                </div>}
                {error && <div className='rt-tr error'>
                    <FormattedMessage id='widgets.errorMessage'/>
                </div>}
                {isLoading && <Spinner isFetching holderTag='div' contentTag='div'/>}
            </div>
        );
    }
}

export default FundsTable;
