import React, { Component } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { withState } from '@shakacode/recompose';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions, getWidgetFooterPropsWithSignClass } from '../utils';
import Widget from '../Widget';
import WidgetControlPanelDefault from '../Widget/components/WidgetControlPanelDefault';
import DateLastContactActionPanel from './components/DateLastContactActionPanel';
import DateLastContactContent from './components/DateLastContactContent';
import { getFilteredData } from './selectors';
import FooterWithInfo from 'Components/widgets/_commonComponents/FooterWithInfo';
import messageType from 'Constants/message-types';

import './DateLastContact.scss';

@withState(
    'selectedDate',
    'onMonthChange'
)
@injectIntl
class DateLastContact extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: DateLastContactContent,
            WidgetControlPanel: props.isPrintMode ? null : WidgetControlPanelDefault,
            WidgetActionPanel: DateLastContactActionPanel,
            WidgetFooter: FooterWithInfo
        };

        this.widgetTitle = props.intl.formatMessage({ id: 'date.last.contact.title' });
        this.widgetTitleTooltip = <FormattedHTMLMessage id='date.last.contact.tooltip.title'/>;
    }

    getWidgetFooterProps = () => ({
        bottom: [
            {
                messageKey: 'date.last.contact.messageNote.reportDiff',
                hidden: false,
                type: messageType.DIFFERENT_FROM_REPORT
            }
        ]
    });

    render() {
        const data = getFilteredData(this.props);
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetTitleTooltip: this.widgetTitleTooltip,
            widgetNoData: !_.get(data, 'values.length'),
            widgetFooterProps: getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps()),
            data
        };

        return (
            <Widget
                {...props}
                className='widget-date-last-contact'
                components={this.widgetComponents}/>
        );
    }
}

export default DateLastContact;
