import { createReducer } from 'Utils/reducer';
import { dataTypes } from 'Components/widgets/ControlBooksResult/constants/dataTypes';

import {
    REPORTING_GET_OUTSTANDING_SHARES_FETCHING,
    REPORTING_GET_OUTSTANDING_SHARES_SUCCESS,
    REPORTING_GET_OUTSTANDING_SHARES_ERROR,
    REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_FETCHING,
    REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_SUCCESS,
    REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_ERROR,
    CONTROL_BOOKS_DATA_TYPE_CHANGE
} from './actions';

const initialState = {
    isFetching: false,
    outstandingShares: [],
    closeOfBusinessDate: null,
    dataType: dataTypes.TRANSACTIONS
};

const actionHandlers = {
    [REPORTING_GET_OUTSTANDING_SHARES_FETCHING]: (state) => ({
        ...state,
        isFetching: true,
        outstandingShares: []
    }),
    [REPORTING_GET_OUTSTANDING_SHARES_SUCCESS]: (
        state,
        { payload: {
            totalOutstandingShares,
            closeOfBusinessDate,
            securityName
        } }) => ({
        ...state,
        isFetching: false,
        outstandingShares: [
            {
                totalOutstandingShares,
                securityName
            }
        ],
        closeOfBusinessDate
    }),
    [REPORTING_GET_OUTSTANDING_SHARES_ERROR]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_FETCHING]: state => ({
        ...state,
        isFetching: true
    }),
    [REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_SUCCESS]: (state, { payload: { totals = [], closeOfBusinessDate } }) => ({
        ...state,
        isFetching: false,
        outstandingShares: totals,
        closeOfBusinessDate
    }),
    [REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_ERROR]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [CONTROL_BOOKS_DATA_TYPE_CHANGE]: (state, { payload }) => ({
        ...state,
        dataType: payload
    })
};

export default createReducer(initialState, actionHandlers);
