import { createSelector } from 'reselect';
import { get } from 'lodash';

const getRouteState = (state) => state.routeState;

export const getHolderInvestorTypeAnalysisRouteState = createSelector(
    getRouteState,
    state => get(state, 'holderInvestorTypeAnalysis')
);

export const getGeographicAnalysisRouteState = createSelector(
    getRouteState,
    state => get(state, 'geographicAnalysis')
);
