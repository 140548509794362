import _ from 'lodash';

import { createReducer } from 'Utils/reducer';

import {
    REPORTING_GET_LAUNCHED_REPORTS_REQUEST,
    REPORTING_GET_LAUNCHED_REPORTS_SUCCESS,
    REPORTING_GET_LAUNCHED_REPORTS_ERROR,
    REPORTING_DELETE_REPORT_REQUEST,
    REPORTING_DELETE_REPORT_SUCCESS,
    REPORTING_DELETE_REPORT_ERROR,
    REPORTING_LAUNCH_REPORT_REQUEST,
    REPORTING_LAUNCH_REPORT_SUCCESS,
    REPORTING_LAUNCH_REPORT_ERROR
} from './actions';

const initialState = {
    reports: [],
    isFetching: false,
    error: null
};

const actionHandlers = {
    [REPORTING_GET_LAUNCHED_REPORTS_REQUEST]: (state, { payload: { isFetching, format } }) => ({
        ...state,
        isFetching,
        formatFetchingReport: format
    }),
    [REPORTING_GET_LAUNCHED_REPORTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        reports: payload.reports
    }),
    [REPORTING_GET_LAUNCHED_REPORTS_ERROR]: (state, { payload }) => ({
        ...initialState,
        error: payload
    }),
    [REPORTING_DELETE_REPORT_REQUEST]: (state, { payload }) => ({
        ...state,
        reports: _.map(state.reports, report => ({
            ...report,
            isDeleting: report.reportId === payload.reportId
        }))
    }),
    [REPORTING_DELETE_REPORT_SUCCESS]: (state, { payload }) => ({
        ...state,
        reports: _.filter(state.reports, report => report.reportId !== payload.reportId)
    }),
    [REPORTING_DELETE_REPORT_ERROR]: (state, { payload }) => ({
        ...state,
        reports: _.map(state.reports, report => ({
            ...report,
            isDeleting: false
        })),
        error: payload
    }),
    [REPORTING_LAUNCH_REPORT_REQUEST]: (state, { payload: { isFetching, format } }) => ({
        ...state,
        isFetching,
        formatFetchingReport: format
    }),
    [REPORTING_LAUNCH_REPORT_SUCCESS]: (state, { payload }) => ({
        ...state,
        reports: _.uniqBy([...state.reports, { ...payload }], 'reportId')
    }),
    [REPORTING_LAUNCH_REPORT_ERROR]: (state, { payload }) => ({
        ...state,
        error: payload
    })
};

export default createReducer(initialState, actionHandlers);
