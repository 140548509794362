import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';

import SearchView from '../components/SearchView';

const mapStateToProps = (state, ownProps) => ({
    form: ownProps.formName
});

@connect(mapStateToProps)
@reduxForm()
class Search extends React.Component {
    static propTypes = {
        ...reduxFormPropTypes,
        autoComplete: PropTypes.bool,
        validate: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.func),
            PropTypes.func
        ]),
        name: PropTypes.string,
        disabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        value: PropTypes.string
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { name, validate, untouch, change } = this.props;
        const { value } = nextProps;

        if (validate !== nextProps.validate) {
            change(name, '');
            untouch(name);
        }

        if (value) {
            change(name, value);
        }
    }

    handleBlur = () => {
        const { onBlur, reset } = this.props;

        if (onBlur) {
            onBlur();
        }
        reset();
    };

    render() {
        return (
            <SearchView
                {...this.props}
                onBlur={this.handleBlur}/>
        );
    }
}

export default Search;
