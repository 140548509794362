import PropTypes from 'prop-types';
import React, { Component } from 'react';

import termsTypes from 'Constants/terms-types';
import sanitizeHtml from 'Utils/sanitizeHtml';
import TermsAndConditionsModalView from './TermsAndConditionsModalView';

import './TermsAndConditionsModal.scss';

class TermsAndConditionsModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        terms: PropTypes.string,
        getTermsAndConditions: PropTypes.func.isRequired
    };

    componentDidMount() {
        if (!this.props.terms) {
            this.props.getTermsAndConditions(termsTypes.AST);
        }
    }

    handleClose = () => {
        this.props.hideModal();
    };

    render() {
        const { terms } = this.props;
        const clearTerms = terms && sanitizeHtml(terms);
        const termsText = (<div dangerouslySetInnerHTML={{ __html: clearTerms }}/>); // eslint-disable-line react/no-danger

        return (
            <TermsAndConditionsModalView
                termsText={terms && termsText}
                onClose={this.handleClose}/>
        );
    }
}

export default TermsAndConditionsModal;
