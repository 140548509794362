import React from 'react';
import PropsTypes from 'prop-types';

import LinkEx from 'Components/LinkEx';

const MenuItemView = ({ children, divider, button, ...rest }) => {
    if (divider) {
        return (<li className='divider'/>);
    }

    if (button) {
        return (<li onClick={rest.handleClick}><a>{children}</a></li>);
    }

    return (
        <li>
            <LinkEx {...rest}>
                {children}
            </LinkEx>
        </li>
    );
};

MenuItemView.propTypes = {
    children: PropsTypes.any,
    divider: PropsTypes.bool,
    button: PropsTypes.bool
};

export default MenuItemView;
