import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import cn from 'classnames';

import { showCommonColumnsManagerModal } from 'State/modal';
import Widget from 'Components/widgets/Widget';
import RegisteredShareholderTableContent from '../../components/RegisteredShareholderTableContent';
import { RegisteredOverviewWidgetLayout } from '../../../../Widget/components/layouts';
import RegisteredOverviewControlPanel from '../../../RegisteredOverviewControlPanel';
import RegisteredOverviewTabsActionPanel from '../../../RegisteredOverviewTabsActionPanel';
import {
    getManagerTransformColumns,
    commonWidgetPropTypes,
    commonWidgetActions,
    getVisibleColumns
} from 'Components/widgets/utils';
import { getSlotsForRegisteredActionPanelSelector } from 'State/dynamicPage/selectors';
import { isFetchingSelector } from 'State/exportReports/selectors';

import './RegisteredShareholderTable.scss';

const mapStateToProps = (state, props) => ({
    ...props,
    availableSlots: getSlotsForRegisteredActionPanelSelector(state),
    isFetchingReport: isFetchingSelector(state)
});
const mapDispatchToProps = {
    showCommonColumnsManagerModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@mapProps(props => {
    const formatMessage = props.intl.formatMessage;
    const selectedColumns = _.get(props, 'widgetSettings.selectedColumns', []);

    return {
        ...props,
        managerColumns: getManagerTransformColumns(props.manageableColumns || props.columnsDefinitions, selectedColumns, formatMessage)
    };
})
class RegisteredShareholderTable extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        exportAvailable: PropTypes.bool,
        data: PropTypes.any,
        columnManagerRows: PropTypes.number,
        columnsOrder: PropTypes.object,
        disableValidation: PropTypes.bool,
        isWidgetWithFilteredData: PropTypes.bool,
        isExceeded: PropTypes.bool,
        hideColumnsManager: PropTypes.bool
    };

    static defaultProps = {
        exportAvailable: true,
        disableValidation: false,
        isWidgetWithFilteredData: false
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: RegisteredOverviewControlPanel,
            WidgetContent: RegisteredShareholderTableContent,
            WidgetActionPanel: RegisteredOverviewTabsActionPanel,
            WidgetFilterPanel: props.filterPanel ? props.filterPanel : null,
            WidgetLayout: RegisteredOverviewWidgetLayout
        };
    }

    handleColumnsManager = () => {
        const { managerColumns, columnManagerRows, disableValidation } = this.props;

        this.props.showCommonColumnsManagerModal({
            titleKey: 'modals.commonColumnsManager.title',
            onSave: this.onChangeColumns,
            columns: managerColumns,
            rows: columnManagerRows,
            disableValidation
        });
    };

    onChangeColumns = columns => {
        const { columnsDefinitions } = this.props;
        const selectedColumns = getVisibleColumns(columns, columnsDefinitions);

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, selectedColumns });
    };

    render() {
        const { exportAvailable, data, availableSlots, widgetEvents, onExport, isExceeded, hideColumnsManager } = this.props;
        const widgetNoData = !_.get(data, 'length') || _.isNil(data) || isExceeded;
        const props = {
            ...this.props,
            className: cn('widget-registered-shareholder-table', this.props.className),
            widgetControlPanelSettings: {
                hideColumnsManager,
                onColumnsManagerClick: this.handleColumnsManager,
                onExport,
                inactive: widgetNoData || this.props.isFetchingReport,
                exportAvailable
            },
            widgetNoData,
            availableSlots
        };

        return (
            <Widget
                {...props}
                events={widgetEvents}
                components={this.widgetComponents}/>
        );
    }
}

export default RegisteredShareholderTable;
