import { dataSourceAddOrSave, getDataByIdSelector, getDataInfoByIdSelector } from '../dataSource';

export const removeWatchlistWidgetShareholder = ({ widgetDataSourceId, shareholderId }) => (dispatch, getState) => {
    const state = getState();
    const widgetData = getDataByIdSelector(state, widgetDataSourceId);
    const dataInfo = getDataInfoByIdSelector(state, widgetDataSourceId);

    dispatch(dataSourceAddOrSave({
        id: widgetDataSourceId,
        data: widgetData.filter((item) => item.shareholderId !== shareholderId),
        info: dataInfo
    }));
};
