import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import WidgetAddToReport from 'Components/widgets/Widget/components/WidgetAddToReport';
import WidgetRemove from 'Components/widgets/Widget/components/WidgetRemove';
import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';
import featureTypes from 'Constants/feature-types';

const mapStateToProps = state => ({
    blockAction: getDynamicPageBlockActionSelector(state)
});

@connect(mapStateToProps)
class FullQuoteControlPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        blockAction: PropTypes.bool
    };

    render() {
        const {
            isDataViz,
            blockAction,
            onAddWidgetToDataVizReport,
            onRemoveWidget,
            events: { onBuildDataParams } = {},
            currentFeatures
        } = this.props;
        const dataVizTools = isDataViz
            ? (<WidgetRemove
                blockAction={blockAction}
                onRemoveWidget={onRemoveWidget}/>)
            : (<WidgetAddToReport
                className='action-icon'
                onAddToReport={onAddWidgetToDataVizReport}
                dataParamsBuilder={onBuildDataParams}/>);

        return (
            <div className='widget-control-panel'>
                {currentFeatures[featureTypes.CUSTOM_REPORTS] && dataVizTools}
            </div>
        );
    }
}

export default FullQuoteControlPanel;
