import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { mapProps } from '@shakacode/recompose';

import { CONTEXTS } from 'Constants/search';
import Accordion from 'Components/Accordion';
import Spinner from 'Components/Spinner/Spinner';
import SearchResultItemTable from './SearchResultItemTable';
import { numberFormatter } from 'Components/formatters/formatters';
import {
    getFetchStatusFor,
    getSearchIssuersSelector,
    getSearchSecurities,
    getSearchResultForContext,
    loadMoreResults
} from 'State/advancedSearch';

import './SearchResultContextPanel.scss';

const mapStateToProps = (state, ownProps) => ({
    contextResult: getSearchResultForContext(state)(ownProps.contextType),
    isFetching: getFetchStatusFor(state)(ownProps.contextType),
    issuersCount: getSearchIssuersSelector(state).length,
    securityCount: getSearchSecurities(state).length
});

const mapDispatchToProps = {
    onRequestMore: loadMoreResults
};

@connect(mapStateToProps, mapDispatchToProps)
@mapProps(({ contextResult: { total, offset, values }, ...rest }) => ({
    total,
    offset,
    values,
    ...rest
}))
@injectIntl
class SearchResultContextPanel extends Component {
    static propTypes = {
        contextType: PropTypes.oneOf(Object.keys(CONTEXTS)).isRequired,
        total: PropTypes.number,
        values: PropTypes.arrayOf(PropTypes.object),
        onChangeStatus: PropTypes.func,
        onRequestMore: PropTypes.func,
        isFetching: PropTypes.bool,
        isFirst: PropTypes.bool,
        open: PropTypes.bool,
        issuersCount: PropTypes.number,
        securityCount: PropTypes.number,
        intl: intlShape
    };

    handleViewMore = () => {
        this.props.onRequestMore(this.props.contextType, this.props.values.length);
    };

    handleChangeStatus = (isOpen) => {
        this.props.onChangeStatus(this.props.contextType, isOpen);
    };

    render() {
        const {
            contextType, total, values, isFetching, isFirst, open,
            issuersCount, securityCount, intl
        } = this.props;
        const hasMore = values.length < total;

        return (<div className='search-result-context-panel' data-type={contextType}>
            <Accordion
                open={open}
                onChangeStatus={this.handleChangeStatus}
                title={<div className='panel-header'>
                    <div className='header-context-type'>
                        <FormattedMessage id={`search.context.${contextType}`}/> <span className='text-muted'>({numberFormatter(intl, total)})</span>
                    </div>
                    {isFirst && this.props.issuersCount > 1 && (<div className='header-issuer'>
                        <FormattedMessage id={'search.context.issuer'}/>
                    </div>)
                    }
                    {isFirst && this.props.securityCount > 1 && (<div className='header-security'>
                        <FormattedMessage id={'search.context.security'}/>
                    </div>)
                    }
                </div>}>
                <SearchResultItemTable
                    issuersCount={issuersCount}
                    securityCount={securityCount}
                    items={values}
                    contextType={contextType}/>
                {hasMore &&
                <div className='view-more-item'>
                    {isFetching
                        ? <Spinner isFetching={isFetching}/>
                        : <a onClick={this.handleViewMore}><FormattedMessage id='search.viewMoreResults'/></a>
                    }
                </div>
                }
            </Accordion>
        </div>);
    }
}

export default SearchResultContextPanel;
