import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { showShareReportTemplateModal } from 'State/modal';
import { shareReportTemplate as shareReportTemplateAction } from 'State/savedReportTemplates/actions';
import { notifyError, notifySuccess } from 'State/notifier';
import { getCurrentFeaturesSelector } from 'State/features';
import featureTypes from 'Constants/feature-types';

const mapStateToProps = state => ({
    currentFeatures: getCurrentFeaturesSelector(state)
});

const mapDispatchToProps = {
    showShareReportTemplateModal,
    shareReportTemplateAction,
    notifySuccess,
    notifyError
};

const shareReportTemplate = WrappedComponent => {
    @injectIntl
    @connect(mapStateToProps, mapDispatchToProps)
    class ShareReportTemplate extends Component {
        static propTypes = {
            intl: intlShape.isRequired,
            showShareReportTemplateModal: PropTypes.func.isRequired,
            shareReportTemplateAction: PropTypes.func.isRequired,
            notifySuccess: PropTypes.func.isRequired,
            notifyError: PropTypes.func.isRequired,
            currentFeatures: PropTypes.object.isRequired
        }

        handleShare = (report) => {
            const onSuccess = this.shareReportTemplate(report);

            this.props.showShareReportTemplateModal(report, onSuccess);
        }

        shareReportTemplate = (report) => (usersIds) => {
            const { intl: { formatMessage } } = this.props;

            return this.props.shareReportTemplateAction(report.reportTemplateId, usersIds)
                .then(() => {
                    this.props.notifySuccess(
                        null,
                        formatMessage(
                            { id: 'saved.reports.share.success' },
                            { reportName: report.reportName }
                        )
                    );
                }).catch(() => {
                    this.props.notifyError(
                        null,
                        formatMessage(
                            { id: 'saved.reports.share.error' },
                            { reportName: report.reportName }
                        )
                    );
                });
        }

        render() {
            const { currentFeatures } = this.props;
            const userCanShareReportTemplate = currentFeatures[featureTypes.REPORT_TEMPLATE_SHARING];

            return (<WrappedComponent
                {...this.props}
                onShare={this.handleShare}
                userCanShareReportTemplate={userCanShareReportTemplate}/>);
        }
    }

    return ShareReportTemplate;
};

export default shareReportTemplate;
