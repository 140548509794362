import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import ReportSchedulerView from './ReportSchedulerView';
import scheduleTypes from './constants/schedule-types';
import { weekDays, weekDayList } from './constants/week-days';
import reportExportFormats from 'Constants/export-report-formats';
import fileFormats from 'Constants/file-formats';

import './ReportScheduler.scss';

const ReportScheduler = props => {
    const options = useMemo(() => ({
        isScheduled: {
            name: 'isScheduled',
            label: props.intl.formatMessage({ id: 'reporting.reportScheduler.isScheduled.label' })
        },
        scheduleType: {
            name: 'schedule.scheduleType'
        },
        weekDayList: {
            name: 'schedule.scheduleParam'
        },
        dayOfMonth: {
            name: 'schedule.scheduleParam',
            label: props.intl.formatMessage({ id: 'reporting.reportScheduler.dayOfMonth.label' })
        },
        isLastDayOfMonth: {
            name: 'isLastDayOfMonth',
            label: props.intl.formatMessage({ id: 'reporting.reportScheduler.isLastDayOfMonth.label' })
        },
        exportFormat: {
            name: 'format'
        }
    }), [props.intl.formatMessage]);
    const typeList = useMemo(() => [
        {
            labelId: 'reporting.reportScheduler.daily.label',
            value: scheduleTypes.DAILY
        },
        {
            labelId: 'reporting.reportScheduler.weekly.label',
            value: scheduleTypes.WEEKLY
        },
        {
            labelId: 'reporting.reportScheduler.monthly.label',
            value: scheduleTypes.MONTHLY
        }
    ], []);
    const exportFormatList = useMemo(() => [{
        label: `${fileFormats[reportExportFormats.EXCEL]}*`,
        value: reportExportFormats.EXCEL,
        position: 'block'
    }, {
        label: fileFormats[reportExportFormats.PDF],
        value: reportExportFormats.PDF,
        position: 'block',
        disabled: props.isMultiSecurityReport
    }], [props.isMultiSecurityReport]);

    const handleIsScheduledClick = useCallback(e => {
        if (e.target.checked) {
            props.change(props.formName, options.scheduleType.name, scheduleTypes.DAILY);
            props.change(props.formName, options.exportFormat.name, reportExportFormats.EXCEL);
        } else {
            props.change(props.formName, options.scheduleType.name, null);
            props.change(props.formName, options.weekDayList.name, null);
            props.change(props.formName, options.dayOfMonth.name, null);
            props.change(props.formName, options.isLastDayOfMonth.name, false);
            props.change(props.formName, options.exportFormat.name, null);
        }
    }, []);

    const handleScheduleTypeChange = useCallback(e => {
        props.change(props.formName, options.isLastDayOfMonth.name, false);

        switch (e.target.value) {
            case scheduleTypes.WEEKLY:
                props.change(props.formName, options.weekDayList.name, weekDays.MONDAY);
                break;
            case scheduleTypes.MONTHLY:
                props.change(props.formName, options.dayOfMonth.name, '1');
                break;
            default:
                props.change(props.formName, options.weekDayList.name, null);
                props.change(props.formName, options.dayOfMonth.name, null);
        }
    }, []);

    const handleIsLastDayOfMonthChange = useCallback(e => {
        if (e.target.checked) {
            props.change(props.formName, options.dayOfMonth.name, null);
        } else {
            props.change(props.formName, options.dayOfMonth.name, '1');
        }
    }, []);

    return (<ReportSchedulerView
        withHeader={props.withHeader}
        collapsible={props.collapsible}
        collapsed={props.collapsed}
        toggleSchedulingExpansion={props.toggleSchedulingExpansion}
        options={options}
        typeList={typeList}
        weekDayList={weekDayList}
        exportFormatList={exportFormatList}
        formValues={props.formValues}
        onIsScheduledClick={handleIsScheduledClick}
        onScheduleTypeChange={handleScheduleTypeChange}
        onIsLastDayOfMonthChange={handleIsLastDayOfMonthChange} />);
};

ReportScheduler.propTypes = {
    formName: PropTypes.string,
    formValues: PropTypes.object,
    change: PropTypes.func,
    intl: intlShape,
    isMultiSecurityReport: PropTypes.bool,
    withHeader: PropTypes.bool,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    toggleSchedulingExpansion: PropTypes.func
};

export default ReportScheduler;
