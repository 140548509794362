import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Nav from 'Components/Nav';
import featureTypes from 'Constants/feature-types';
import * as featuresSelectors from 'State/features/selectors';
import * as savedReportTemplatesSelectors from 'State/savedReportTemplates/selectors';
import ReportDownloads from '../ReportDownloads/containers/ReportDownloads';
import StartedDataVizReport from '../StartedDataVizReport/containers/StartedDataVizReport';
import SavedReportTemplates from '../SavedReports/containders/SavedReportTemplates';
import Icon from 'Components/Icon';
import ShareholderClassicPdfLink from '../ShareholderClassicPdfLink';
import SubscriptionsPanel from '../SubscriptionsPanel';
import { pathRoutesConfig } from 'Routes';
import { buildNavLinks } from 'Utils/routing';
import * as selectors from './selectors';
import AuthorizedUsersLink from '../AuthorizedUsersLink';
import ShareholderClassicAsOfDateLink from '../ShareholderClassicAsOfDateLink';
import PermissionContent from 'Components/PermissionContent';

const mapStateToProps = state => ({
    currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
    getDataVizStartedReport: location => savedReportTemplatesSelectors.getDataVizStartedSelector(state, location),
    shareholderClassicIsAvailable: selectors.shareholderClassicIsAvailableSelector(state)
});

@injectIntl
@withRouter
@connect(mapStateToProps)
class ReportingNav extends Component {
    static propTypes = {
        intl: intlShape,
        currentFeatures: PropTypes.object,
        getDataVizStartedReport: PropTypes.func,
        location: PropTypes.object,
        shareholderClassicIsAvailable: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.legendTexts = {
            dataViz: props.intl.formatMessage({ id: 'reporting.nav.savedReports.dataViz' }),
            listReport: props.intl.formatMessage({ id: 'reporting.nav.savedReports.listReport' }),
            description: props.intl.formatMessage({ id: 'reporting.nav.savedReports.legendDescription' })
        };
    }

    getNavLinks = () => {
        const { intl: { formatMessage }, currentFeatures } = this.props;
        const { reportingNav } = pathRoutesConfig;

        return buildNavLinks({
            pathConfig: reportingNav.pathConfig,
            features: currentFeatures,
            formatMessage
        });
    };

    render() {
        const { getDataVizStartedReport, location, currentFeatures, shareholderClassicIsAvailable } = this.props;
        const dataVizStartedReport = getDataVizStartedReport(location);
        const navLinks = this.getNavLinks();

        return (
            <div className='reporting-nav'>
                <div className='reporting-nav-section'>
                    <Nav links={navLinks}/>
                </div>
                {(shareholderClassicIsAvailable || currentFeatures[featureTypes.AUTHORIZED_USER_LIST]) &&
                    <div className='reporting-nav-section'>
                        {!DEMO_MODE && shareholderClassicIsAvailable && <ShareholderClassicPdfLink/>}
                        <PermissionContent features={[featureTypes.CLASSIC_SHAREHOLDER_LIST_AS_OF_DATE]}>
                            <ShareholderClassicAsOfDateLink/>
                        </PermissionContent>
                        {currentFeatures[featureTypes.AUTHORIZED_USER_LIST] && <AuthorizedUsersLink/>}
                    </div>
                }
                {currentFeatures[featureTypes.DOWNLOAD] &&
                    <div className='reporting-nav-section'>
                        <ReportDownloads/>
                    </div>
                }
                {currentFeatures[featureTypes.CUSTOM_REPORTS] && dataVizStartedReport &&
                <div className='reporting-nav-section'>
                    <StartedDataVizReport dataVizStartedReport={dataVizStartedReport}/>
                </div>
                }
                {currentFeatures[featureTypes.SAVED_REPORTS_SECTION] &&
                <div className='reporting-nav-section saved-reports-section'>
                    <div className='saved-reports-title'>
                        <FormattedMessage id='saved.reports.title'/>
                    </div>
                    <div className='saved-reports-legend'>
                        <div className='legend-row'>
                            <div className='legend-container'>
                                <Icon className='font-icon font-icon-view-pie-chart' withoutTitle/>
                                <span className='legend-name'>{this.legendTexts.dataViz}</span>
                            </div>
                            <div className='legend-container'>
                                <Icon className='font-icon font-icon-view-list' withoutTitle/>
                                <span className='legend-name'>{this.legendTexts.listReport}</span>
                            </div>
                        </div>
                        <div className='legend-row message-note'>
                            {this.legendTexts.description}
                        </div>
                    </div>
                    <SavedReportTemplates/>
                </div>
                }
                <div className='reporting-nav-section'>
                    <SubscriptionsPanel/>
                </div>
            </div>
        );
    }
}

export default ReportingNav;
