const getConfig = userName => ({
    'user_id': userName,
    'send_page_view': false,
    'send_user_engagement': false,
    'send_first_visit': false,
    'send_scroll': false
});

const addGAScript = (id, userName) => {
    if (id) {
        const gaScript = document.createElement('script');

        gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        document.body.appendChild(gaScript);

        const script = document.createElement('script');
        const code = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${id}', ${JSON.stringify(getConfig(userName))});`;

        script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
    }
};

export default addGAScript;
