import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import shareholdersPositionsSources from 'Constants/shareholders-positions-sources';
import { DateFormatter, NumberFormatter } from 'Components/formatters';
import Icon from 'Components/Icon';

const PositionListTableRow = ({ row, maxDate }) => (
    <tr className={cn({ 'muted': row.positionDate !== maxDate })}>
        <td>
            <div className='position-source'>
                <span>{row.source}</span>
                {row.source === shareholdersPositionsSources.CUST ?
                    <Icon
                        className='font-icon font-icon-delete'
                        titleKey='shareholders.table.positions.removePosition'/>
                    : null
                }
            </div>
        </td>
        <td><DateFormatter value={row.positionDate}/></td>
        <td><NumberFormatter value={row.position}/></td>
    </tr>
);

PositionListTableRow.propTypes = {
    row: PropTypes.shape({
        positionDate: PropTypes.string,
        source: PropTypes.string,
        position: PropTypes.number
    }).isRequired,
    maxDate: PropTypes.string
};

export default PositionListTableRow;
