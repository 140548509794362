import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactHighcharts from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsTimeline from 'highcharts/modules/timeline';

import { buildHighchartsOptions } from './utils';
import { FormattedMessage } from 'react-intl';

highchartsTimeline(Highcharts);

const MeetingPlannerTrackerTimeLine = ({ timeline }) => {
    const [options, setOptions] = useState({});

    useEffect(() => {
        setOptions(buildHighchartsOptions(timeline));
    }, [timeline]);


    return (
        <div className='meeting-planner-tracker__time-line'>
            <h3><FormattedMessage id='meetingPlannerTracker.chart.header' /></h3>
            <ReactHighcharts
                highcharts={Highcharts}
                options={options}/>
        </div>
    );
};

MeetingPlannerTrackerTimeLine.propTypes = {
    timeline: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string
    }))
};

export default MeetingPlannerTrackerTimeLine;
