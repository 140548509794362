import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getContext } from '@shakacode/recompose';
import { connect } from 'react-redux';

import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import WidgetAddToReport from 'Components/widgets/Widget/components/WidgetAddToReport';
import WidgetRemove from 'Components/widgets/Widget/components/WidgetRemove';
import featureTypes from 'Constants/feature-types';

import './Top25HoldingsControlPanel.scss';

const mapStateToProps = state => ({
    blockAction: getDynamicPageBlockActionSelector(state)
});

@connect(mapStateToProps)
@getContext({
    events: PropTypes.object.isRequired
})
class Top25HoldingsActionPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        events: PropTypes.object,
        blockAction: PropTypes.bool
    };

    render() {
        const { isDataViz, blockAction, onRemoveWidget, currentFeatures } = this.props;
        const dataVizTools = isDataViz
            ? (<WidgetRemove
                blockAction={blockAction}
                onRemoveWidget={onRemoveWidget}/>)
            : (<WidgetAddToReport
                onAddToReport={this.props.onAddWidgetToDataVizReport}
                dataParamsBuilder={this.props.events.onBuildDataParams}/>);

        return (
            <div className='widget-control-panel'>
                {currentFeatures[featureTypes.CUSTOM_REPORTS] && dataVizTools}
            </div>
        );
    }
}

export default Top25HoldingsActionPanel;
