import { createReducer } from 'Utils/reducer';
import {
    GET_ALL_COUNTRIES_FETCHING,
    GET_ALL_COUNTRIES_SUCCESS,
    GET_ALL_COUNTRIES_ERROR,
    GET_STATES_BY_COUNTRY_ID_FETCHING,
    GET_STATES_BY_COUNTRY_ID_SUCCESS,
    GET_STATES_BY_COUNTRY_ID_ERROR
} from './actions';

const initialState = {
    countriesList: [],
    statesList: {}
};


const actionHandlers = {
    [GET_ALL_COUNTRIES_FETCHING]: state => ({
        ...state,
        isFetching: true
    }),
    [GET_ALL_COUNTRIES_SUCCESS]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        countriesList: payload
    }),
    [GET_ALL_COUNTRIES_ERROR]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [GET_STATES_BY_COUNTRY_ID_FETCHING]: state => ({
        ...state,
        isFetching: true
    }),
    [GET_STATES_BY_COUNTRY_ID_SUCCESS]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        statesList: { ...state.statesList, [payload.countryId]: [...payload.data] }
    }),
    [GET_STATES_BY_COUNTRY_ID_ERROR]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    })
};

export default createReducer(initialState, actionHandlers);
