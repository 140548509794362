import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes } from 'redux-form';

import classicShareholdersReportTypes from './constants/classic-shareholders-report-types';
import ExportClassicShareholderReportView from './components/ExportClassicShareholderReportModalView';

import './ExportClassicShareholderReportModal.scss';

const FORM_NAME = 'exportClassicShareholdersReportForm';
const INITIAL_VALUES = {
    reportType: classicShareholdersReportTypes.FULL_REPORT
};
const mapStateToProps = (state) => ({
    formValues: getFormValues(FORM_NAME)(state)
});

@injectIntl
@connect(mapStateToProps)
@reduxForm({
    form: FORM_NAME,
    initialValues: INITIAL_VALUES
})
class ExportClassicShareholderReportModal extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        hideModal: PropTypes.func,
        onSuccess: PropTypes.func,
        showExcelLimit: PropTypes.bool
    };

    handleExport = ({ reportType }) => {
        const { hideModal } = this.props;
        const values = {
            isSummary: reportType === classicShareholdersReportTypes.TOTALS_ONLY
        };

        hideModal();
        this.props.onSuccess(values);
    };

    render() {
        const { hideModal } = this.props;

        return (
            <ExportClassicShareholderReportView
                {...this.props}
                onExport={this.handleExport}
                onCancel={hideModal}/>
        );
    }
}

export default ExportClassicShareholderReportModal;
