import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.ISSUE_DATE,
        order: 1,
        minWidth: 120,
        displayName: 'registered.shareholder.table.issueDate',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.CERT_BOOK_NO,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.certBookNo',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SHARES_TYPE,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.sharesType',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SHARES,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.shares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.ISSUE_REASON,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.issueReason',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.STOP_CODE,
        order: 6,
        minWidth: 140,
        displayName: 'registered.shareholder.table.stopCode',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SURRENDER_DATE,
        order: 7,
        minWidth: 120,
        displayName: 'registered.shareholder.table.surrenderDate',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.SURRENDER_REASON,
        order: 8,
        minWidth: 120,
        displayName: 'registered.shareholder.table.surrenderReason',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    }
];

export const secondarySorting = {
    field: columnNames.SHARES,
    direction: sortDirections.DESC
};
