import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import BackLink from 'Components/BackLink';
import shareholderType from 'Constants/shareholder-types';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import featureTypes from 'Constants/feature-types';
import ViewNotesLink from 'Components/widgets/Notes/components/ViewNotesLink';

const mapStateToProps = state => ({
    currentFeatures: getCurrentFeaturesSelector(state)
});

const InstitutionProfilePageHeaderView = ({ pageInfo = {}, currentFeatures }) => {
    return (
        <div className='institution-profile-page-header'>
            <BackLink name={shareholderType.INSTITUTION}/>
            <div className='header-title-container'>
                <h2 className='header-title'>{pageInfo.shareholderTitle}</h2>
                {currentFeatures[featureTypes.NOTES] && <ViewNotesLink/>}
            </div>
        </div>
    );
};

InstitutionProfilePageHeaderView.propTypes = {
    pageInfo: PropTypes.object,
    currentFeatures: PropTypes.object
};

export default connect(mapStateToProps)(InstitutionProfilePageHeaderView);
