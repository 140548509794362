import React from 'react';
import { FormattedMessage } from 'react-intl';

import ContactsTable from 'Components/widgets/_commonComponents/ContactsTable';
import columnDefinitions from './constants/columnDefinitions';

const InstitutionsTable = props => {
    const widgetTitle = (<FormattedMessage id='institutionsTable.title'/>);
    const noDataMessageId = 'widgets.noDataMessage.matchingInstitutions';

    return (<ContactsTable
        {...props}
        widgetTitle={widgetTitle}
        columnDefinitions={columnDefinitions}
        noDataMessageId={noDataMessageId}/>);
};

export default InstitutionsTable;
