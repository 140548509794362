import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

import OptionWithCheckbox from './OptionWithCheckbox';

const ROW_HEIGHT = 42;
const VISIBLE_ROW_COUNT = 10;

class OptionList extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        getStyles: PropTypes.func,
        onSelect: PropTypes.func
    };

    componentDidUpdate() {
        this.ref && this.ref.forceUpdateGrid();
    }

    handleSelect = option => {
        const { onSelect } = this.props;

        onSelect(option);
    };

    setRef = (ref) => {
        this.ref = ref;
    };

    rowRenderer = ({ key, index, style }) => {
        const { options } = this.props;

        return (<div key={key} style={style}>
            <OptionWithCheckbox
                className='Select-option'
                option={options[index]}
                onSelect={this.handleSelect}/>
        </div>);
    };

    render() {
        const { options } = this.props;
        const visibleRowCount = options.length >= VISIBLE_ROW_COUNT
            ? VISIBLE_ROW_COUNT : options.length;

        return (
            <List
                ref={this.setRef}
                height={ROW_HEIGHT * visibleRowCount}
                rowCount={options.length}
                rowHeight={ROW_HEIGHT}
                rowRenderer={this.rowRenderer}
                width={438}/>
        );
    }
}

export default OptionList;
