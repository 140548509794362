import React, { useEffect } from 'react';
import { compose } from '@shakacode/recompose';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import {
    fetchSecureUploadRequestById,
    isRequestItemFetchingSelector,
    requestItemSelector,
    updateUploadRequest,
    requestFilesSelector,
    fetchUploadStatus,
    clearRequestState,
    getSecureUploadEmailRecipients
} from 'State/userSecureUpload';
import Spinner from 'Components/Spinner';
import pageRoutes from 'Constants/page-routes';
import { pathWithSecurity } from 'Utils/routing';

import './UserSecureUploadDetailsPage.scss';
import { UploadedFilesTable } from '../UserSecureUploadEditPage/components/UploadedFilesTable';
import { showViewEmailRecipientsModal } from 'State/modal';

const mapDispatchToProps = {
    fetchSecureUploadRequestById,
    updateUploadRequest,
    fetchUploadStatus,
    clearRequestState,
    getSecureUploadEmailRecipients,
    showViewEmailRecipientsModal
};

const mapStateToProps = state => ({
    isRequestItemFetching: isRequestItemFetchingSelector(state),
    requestItem: requestItemSelector(state),
    files: requestFilesSelector(state)
});

const UserSecureUploadDetailsPageView = ({
    match: { params: { requestId } },
    history,
    fetchSecureUploadRequestById: fetchRequestById,
    fetchUploadStatus: fetchFiles,
    clearRequestState: clearRequest,
    files,
    requestItem,
    isRequestItemFetching,
    getSecureUploadEmailRecipients: getEmailRecipients,
    showViewEmailRecipientsModal: showEmailRecipientsModal
}) => {
    useEffect(() => {
        fetchRequestById(Number(requestId)).catch(() => {
            history.replace('/404');
        });
        getEmailRecipients(requestId);
    }, [requestId]);

    useEffect(() => {
        fetchFiles(Number(requestId));
    }, [requestItem]);

    useEffect(() => {
        return () => {
            clearRequest();
        };
    }, []);

    if (isRequestItemFetching || !requestItem) {
        return <Spinner isFetching isBlocker/>;
    }

    const securityListLink = pathWithSecurity(pageRoutes.downloadsUserSecureUploadList);

    return (
        <div className='user-secure-upload-details-page'>
            <Link to={securityListLink} className='back-arrow'>
                <FormattedMessage id='userSecureUpload.backTo.listPage'/>
            </Link>
            <dl className='user-secure-upload-details'>
                <div className='subject-line-button-container'>
                    <div className='subject-line'>
                        <dt className='user-secure-upload-details__term'>
                            <FormattedMessage id='userSecureUpload.description.label'/>
                        </dt>
                        <dd className='user-secure-upload-details__description'>
                            {requestItem.description}
                        </dd>
                    </div>
                    <div>
                        <button onClick={showEmailRecipientsModal} type='button' className='btn btn-large'>
                            <FormattedMessage id='userSecureUpload.viewEmailRecipients.button' />
                        </button>
                    </div>
                </div>
                <dt className='user-secure-upload-details__term'>
                    <FormattedMessage id='userSecureUpload.uploadDocuments.label'/>
                </dt>
                <dd className='user-secure-upload-details__description'>
                    <UploadedFilesTable files={files} readonly alreadySent/>
                </dd>
                <dt className='user-secure-upload-details__term'>
                    <FormattedMessage id='userSecureUpload.comment.label'/>
                </dt>
                <dd className='user-secure-upload-details__description'>{requestItem.comment}</dd>
            </dl>
        </div>
    );
};

UserSecureUploadDetailsPageView.propTypes = {
    match: PropTypes.shape({ params: PropTypes.shape({ requestId: PropTypes.string.isRequired }) }).isRequired,
    history: PropTypes.object.isRequired,
    fetchSecureUploadRequestById: PropTypes.func.isRequired,
    fetchUploadStatus: PropTypes.func.isRequired,
    clearRequestState: PropTypes.func.isRequired,
    files: PropTypes.array.isRequired,
    requestItem: PropTypes.shape({
        description: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired
    }).isRequired,
    isRequestItemFetching: PropTypes.bool,
    getSecureUploadEmailRecipients: PropTypes.func,
    showViewEmailRecipientsModal: PropTypes.func
};

export const UserSecureUploadDetailsPage = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UserSecureUploadDetailsPageView);
