import _ from 'lodash';

import signPositions from 'Constants/sign-positions';

export const getSignClassNameFactory = ({ leftSide = [], rightSide = [], bottom = [] } = {}) => {
    const footerProps = _.concat(leftSide, rightSide, bottom);

    return (messageType, signPosition = signPositions.AFTER) => {
        const signType = _.get(_.find(footerProps, { type: messageType }), 'signClass.type');

        return _.get(getSignClass(signType, signPosition), 'className');
    };
};

export const getSignClass = (type, position = signPositions.BEFORE) => (
    type && position ?
        {
            className: `sign-${type}-${position}`,
            type,
            position
        } :
        null
);
