import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';

import TableEasy from 'Components/Tables/TableEasy';
import { getHeaderCellComponent, labelCell, unmarkedLabelCell, numberCell } from './cellRenderers';
import { columns, levelTypes } from '../constants';
import { getMarkedData, markIncludesCeDeCoRows } from 'Components/widgets/utils';

const TaxCertificationStatusTableView = ({ tableData }) => (
    <div className='table-container'>
        <TableEasy
            className='widget-table'
            {...tableData}/>
    </div>
);

TaxCertificationStatusTableView.propTypes = {
    tableData: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { intl: { formatMessage }, widgetFooterProps, data, widgetSettings: { level } } = props;
        const summaryColumns = [
            {
                flexGrow: 4,
                dataKey: columns.TAX_STATUS,
                title: formatMessage({ id: 'tax.certification.status.column.taxStatus' }),
                headerCellComponent: getHeaderCellComponent(columns.TAX_STATUS, levelTypes.SUMMARY),
                bodyCellComponent: labelCell
            },
            {
                flexGrow: 3,
                minWidth: 140,
                dataKey: columns.NUMBER_OF_SHAREHOLDERS,
                columnClassName: 'text-right',
                title: formatMessage({ id: 'tax.certification.status.column.numberOfShareholders' }),
                bodyCellComponent: numberCell
            },
            {
                flexGrow: 3,
                minWidth: 125,
                dataKey: columns.EXPIRING_YEAR_END,
                columnClassName: 'text-right',
                title: formatMessage({ id: 'tax.certification.status.column.expiringYearEnd' }),
                headerCellComponent: getHeaderCellComponent(columns.EXPIRING_YEAR_END, levelTypes.SUMMARY),
                bodyCellComponent: numberCell
            }
        ];
        const detailsColumns = [
            {
                dataKey: columns.TAX_STATUS_DETAIL,
                title: formatMessage({ id: 'tax.certification.status.column.taxStatus' }),
                headerCellComponent: getHeaderCellComponent(columns.TAX_STATUS, levelTypes.DETAILS),
                maxWidth: 180,
                bodyCellComponent: labelCell
            },
            {
                dataKey: columns.TAX_STATUS_SUMMARY,
                bodyCellComponent: unmarkedLabelCell
            },
            {
                dataKey: columns.NUMBER_OF_SHAREHOLDERS,
                columnClassName: 'text-right',
                minWidth: 140,
                title: formatMessage({ id: 'tax.certification.status.column.numberOfShareholders' }),
                bodyCellComponent: numberCell
            }
        ];
        const tableData = {
            columns: level === levelTypes.SUMMARY ? summaryColumns : detailsColumns,
            data: getMarkedData(data[level].values, [markIncludesCeDeCoRows], widgetFooterProps),
            aggregate: data[level].aggregate
        };

        return { tableData };
    })
)(TaxCertificationStatusTableView);
