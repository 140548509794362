import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import Box from '../Box';
import WidgetBox from '../../widgets/WidgetBox';
import { commonPropTypes, layoutProps, buildFlexStyles } from '../utils';

export default class Layout extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonPropTypes,
        flexbox: PropTypes.bool,
        wrap: PropTypes.bool,
        reverse: PropTypes.bool,
        column: PropTypes.bool
    };

    static defaultProps = {
        flexbox: true
    };

    getClassName() {
        const props = { ...this.props, ...this.props.config };

        return classNames({
            'flexbox': props.flexbox,
            'flexbox-wrap': props.wrap,
            'flexbox-reverse': props.reverse,
            'flexbox-column': props.column
        }, props.className || '');
    }

    renderChildren() {
        const items = (this.props.config || {}).items;
        let children;

        if (_.isArray(items)) {
            children = items.map(item => {
                let element;
                const key = item.id;

                switch (item.type) {
                    case 'layout':
                        element = <Layout key={key}  config={item}/>;
                        break;
                    case 'widget':
                        element = <WidgetBox key={key} config={item}/>;
                        break;
                    case 'box':
                        element = <Box key={key} config={item}/>;
                        break;
                    default:
                        element = null;
                        break;
                }
                return element;
            });
        } else {
            children = this.props.children;
        }

        return children;
    }

    render() {
        const className = this.getClassName();
        const styles = buildFlexStyles({ ...this.props, ...this.props.config });
        const childProps = _.omit(this.props, layoutProps);

        return (
            <div {...childProps} className={className} style={{ ...styles, ...this.props.style }}>
                {this.renderChildren()}
            </div>
        );
    }
}
