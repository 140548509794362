import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';

import dateFormat from 'Constants/date-formats';
import { isWorkDay } from 'Utils/dateHelpers';

export default (date = {}, { checkWorkingDay = false, isCurrentDateAvailable = true, canBeEmpty = false } = {}) => {
    const error = {};

    if (!_.has(date, 'checked') || date.checked) {
        const dateMoment = moment(date.date, dateFormat.SERVER_DATE_FORMAT);
        const todayDate = moment();

        if (!canBeEmpty && _.isNil(date.date)) {
            error.date = <FormattedMessage id='datePicker.error.empty'/>;
        } else if (dateMoment.isAfter(todayDate, 'days')) {
            error.date = <FormattedMessage id='datePicker.error.future'/>;
        } else if (checkWorkingDay && !isWorkDay(dateMoment)) {
            error.date = <FormattedMessage id='datePicker.error.dayOff'/>;
        } else if (!isCurrentDateAvailable && dateMoment.isSame(todayDate, 'days')) {
            error.date = <FormattedMessage id='datePicker.error.currentDate'/>;
        }
    }

    return error;
};
