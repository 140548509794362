import { LOCATION_CHANGE } from 'connected-react-router';
import _ from 'lodash';

import { createReducer } from 'Utils/reducer';

const initialState = {
    current: null,
    previous: null
};

const actionHandlers = {
    [LOCATION_CHANGE]: (state, { payload }) => {
        // REPLACE is internal router action. If router replace path in history we shouldn't change previous route
        const currentPathName = _.get(state, 'current.location.pathname');
        const newPathname = _.get(payload, 'location.pathname');

        return {
            ...state,
            previous: payload.action !== 'REPLACE' && currentPathName !== newPathname ? state.current : state.previous,
            current: payload
        };
    }
};

export default createReducer(initialState, actionHandlers);
