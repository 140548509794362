import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ReactTable from 'react-table';
import _ from 'lodash';

import sortDirections from 'Constants/sort-directions';
import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import { getColumnId, sortData } from './utils';
import NoGroupHeaderCell from './components/NoGroupHeaderCell';
import AdditionalGroupCell from './components/AdditionalGroupCell';
import { NoResults } from 'Components/Tables/components/ReactTableComponents';
import ThComponent from './components/ThComponent';
import TdComponent from './components/TdComponent';
import ArrowScrollComponent from './components/ArrowScrollComponent';
import MainColumnHeadingComponent from './components/MainColumnHeadingComponent';

import './AnalysisTable.scss';

@injectIntl
class AnalysisTable extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        groupsSettings: PropTypes.array,
        groupCount: PropTypes.number,
        onDeleteGroup: PropTypes.func,
        groupValueGetter: PropTypes.func,
        onScrollGroupsLeft: PropTypes.func,
        onScrollGroupsRight: PropTypes.func,
        additionalComponent: PropTypes.func,
        noSelectedText: PropTypes.string,
        scrollColumnWidth: PropTypes.number,
        mainColumnWidth: PropTypes.number,
        mainColumnSettings: PropTypes.object.isRequired,
        groupColumnsSettings: PropTypes.array.isRequired,
        groupHeaderCellComponent: PropTypes.func.isRequired,
        getTdProps: PropTypes.func,
        data: PropTypes.array,
        onSort: PropTypes.func.isRequired,
        initialSort: PropTypes.shape({
            sortColumn: PropTypes.string,
            sortField: PropTypes.string.isRequired,
            sortDirection: PropTypes.string.isRequired
        }).isRequired,
        onShowFindModal: PropTypes.func,
        findModalAvailable: PropTypes.bool,
        searchAvailable: PropTypes.bool,
        isMaxSelected: PropTypes.bool,
        maxSelected: PropTypes.number,
        groupWidth: PropTypes.number
    };

    static defaultProps = {
        groupsSettings: [],
        data: [],
        scrollColumnWidth: 50,
        mainColumnWidth: 300
    };

    constructor(props) {
        super(props);

        const {
            intl: { formatMessage },
            groupColumnsSettings,
            data, initialSort
        } = props;

        this.state = {
            sortCriteria: initialSort,
            sortedData: sortData(data, initialSort)
        };

        this.formatMessage = formatMessage;
        this.groupColumnWithoutWidth = _.reduce(groupColumnsSettings, (result, value) => value.width ? result : result + 1, 0);
        this.setColumns(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const props = this.props;

        if (nextProps.groupsSettings !== props.groupsSettings ||
            nextProps.onScrollGroupsLeft !== props.onScrollGroupsLeft ||
            nextProps.onScrollGroupsRight !== props.onScrollGroupsRight ||
            nextProps.findModalAvailable !== props.findModalAvailable ||
            nextProps.isMaxSelected !== props.isMaxSelected ||
            nextProps.groupWidth !== props.groupWidth) {
            this.setColumns(nextProps);
        }

        if (nextProps.data !== props.data) {
            this.setState({
                sortedData: sortData(nextProps.data, this.state.sortCriteria)
            });
        }
    }

    handleSort = (newSorted, column) => {
        const { data } = this.props;
        const desc = _.get(newSorted, `[${newSorted.length - 1}].desc`);
        const sortCriteria = _.get(column, 'sortCriteria', {});
        const updatedSortCriteria = {
            ...sortCriteria,
            sortDirection: desc ? sortDirections.DESC : sortDirections.ASC,
            initial: false
        };

        this.setState({
            sortCriteria: updatedSortCriteria,
            sortedData: sortData(data, updatedSortCriteria)
        });

        this.props.onSort(updatedSortCriteria);
    };

    getGroupColumns = (groupSettings, isInitColumns, newProps) => {
        const { groupValueGetter, groupColumnsSettings } = this.props;
        const groupColumnSettings = [];

        _.forEach(groupColumnsSettings, (column) => {
            const CellComponent = column.component;

            groupColumnSettings.push({
                id: getColumnId(groupSettings.id, column.columnName),
                sortable: groupSettings.id !== undefined,
                sortCriteria: {
                    sortColumn: groupSettings.id,
                    sortField: column.columnName
                },
                defaultSortDesc: column.defaultSortDesc,
                Header: isInitColumns && this.formatMessage({ id: `${column.displayName}` }),
                getHeaderProps: () => ({
                    sortCriteria: {
                        sortColumn: groupSettings.id,
                        sortField: column.columnName
                    }
                }),
                accessor: item => groupValueGetter
                    ? groupValueGetter(item, groupSettings, column.columnName)
                    : item[column.columnName],
                headerClassName: column.headerClassName,
                className: column.className,
                Cell: props => isInitColumns && (
                    <CellComponent {...props} className='ellipsis' additionalData={{ withTitle: column.withTitle }}/>
                ),
                width: column.width || newProps.groupWidth / this.groupColumnWithoutWidth
            });
        });
        return groupColumnSettings;
    };

    setColumns = (newProps) => {
        const {
            onDeleteGroup,
            groupsSettings,
            onScrollGroupsRight,
            onScrollGroupsLeft,
            additionalComponent,
            noSelectedText,
            groupCount,
            mainColumnWidth,
            scrollColumnWidth,
            groupHeaderCellComponent: GroupHeaderCellComponent,
            mainColumnSettings,
            findModalAvailable,
            searchAvailable,
            onShowFindModal,
            isMaxSelected,
            maxSelected
        } = newProps;
        const MainColumnCellComponent = mainColumnSettings.component;

        this.columns = [
            {
                Header: () => (
                    <AdditionalGroupCell
                        scrollColumnWidth={scrollColumnWidth}
                        isMaxSelected={isMaxSelected}
                        maxSelected={maxSelected}/>
                ),
                getHeaderProps: () => ({
                    onScrollGroupsLeft,
                    additionalComponent
                }),
                headerClassName: 'border-top additional-component',
                columns: [
                    {
                        id: getColumnId(null, mainColumnSettings.columnName),
                        Header: props => (
                            <MainColumnHeadingComponent
                                {...props}
                                onShowFindModal={onShowFindModal}
                                findModalAvailable={findModalAvailable}
                                searchAvailable={searchAvailable}
                                title={this.formatMessage({ id: `${mainColumnSettings.displayName}` })}/>
                        ),
                        accessor: mainColumnSettings.columnName,
                        sortCriteria: {
                            sortField: mainColumnSettings.columnName,
                            sortColumn: null
                        },
                        defaultSortDesc: mainColumnSettings.defaultSortDesc,
                        Cell: props => <MainColumnCellComponent {...props} />,
                        headerClassName: mainColumnSettings.headerClassName,
                        maxWidth: mainColumnWidth,
                        minWidth: mainColumnWidth,
                        sortable: true
                    }
                ]
            }
        ];

        for (let i = 0; i < groupCount; i++) {
            const isInitColumns = i < groupsSettings.length;
            const settings = i < groupsSettings.length
                ? groupsSettings[i]
                : {};

            this.columns.push({
                Header: () => i === groupsSettings.length
                    ? <NoGroupHeaderCell noSelectedText={noSelectedText}/>
                    : <GroupHeaderCellComponent/>,
                headerClassName: 'group-header-cell border-top',
                getHeaderProps: () => ({
                    groupId: settings.id,
                    groupLabel: settings.label,
                    canDeleteGroup: settings.canDeleteGroup,
                    onDeleteGroup
                }),
                columns: this.getGroupColumns(settings, isInitColumns, newProps)
            });
        }

        if (onScrollGroupsRight) {
            this.columns.push({
                Header: () => (
                    <ArrowScrollComponent
                        isLeft={false}
                        onScroll={onScrollGroupsRight}/>
                ),
                columns: [{
                    headerClassName: 'group-header-cell--display-none',
                    className: 'rt-td--display-none',
                    minWidth: scrollColumnWidth,
                    maxWidth: scrollColumnWidth
                }]
            });
        }
    };

    render() {
        const { getTdProps } = this.props;
        const { sortedData, sortCriteria } = this.state;
        const sort = {
            id: getColumnId(sortCriteria.sortColumn, sortCriteria.sortField),
            desc: sortCriteria.sortDirection === sortDirections.DESC
        };

        return (
            <ReactTable
                className='analysis-table'
                showPagination={false}
                resizable={false}
                onSortedChange={this.handleSort}
                manual
                minRows={0}
                defaultPageSize={100000}
                LoadingComponent={() => null}
                getTdProps={getTdProps}
                NoDataComponent={NoResults}
                TdComponent={TdComponent}
                ThComponent={ThComponent}
                data={sortedData}
                sorted={[
                    sort
                ]}
                columns={this.columns || []}/>
        );
    }
}

export default AnalysisTable;
