import { createReducer } from 'Utils/reducer';
import {
    USER_INFO_REQUEST_FAILURE,
    USER_INFO_REQUEST_SUCCESS,
    SET_DEFAULT_SECURITY_ID,
    SET_CUSTOM_SECURITY_ID,
    GET_USER_NOTIFICATIONS_SUCCESS,
    GET_USER_NOTIFICATIONS_FAILURE,
    CHANGE_USER_NOTIFICATIONS_SUCCESS,
    CHANGE_USER_NOTIFICATIONS_FAILURE,
    GET_USER_TERMS_AND_CONDITIONS_SUCCESS,
    GET_USER_TERMS_AND_CONDITIONS_FAILURE,
    ACCEPT_USER_SUCCESS,
    ACCEPT_USER_FAILURE,
    SET_ACCESS_TO_PA,
    SET_ACCEPT_TERMS,
    CLEAR_ACCEPT_TERMS,
    LOGOUT_USER,
    SESSION_TIMEOUT,
    SET_SECURITY_CHANGING,
    SET_HAS_NEW_ANNOUNCEMENT,
    SET_ANNOUNCEMENT_MESSAGE,
    GET_USER_TREASURY_ACCESS
} from './actions';
import { getQueryParameterByName } from 'Utils/routing';
import * as utils from 'Utils/utils';

const initialState = {
    info: null,
    notifications: null,
    error: null,
    acceptTerms: null,
    accessToPa: false,
    isLogout: false,
    isSessionTimeout: false,
    customSecurityId: utils.normalizeSecurityId(getQueryParameterByName('security')),
    isSecurityChanging: false,
    hasNewAnnouncement: false,
    announcementMessage: null,
    hasTreasuryAccountAccess: false
};

const actionHandlers = {
    [USER_INFO_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        info: payload
    }),
    [USER_INFO_REQUEST_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload
    }),
    [SET_DEFAULT_SECURITY_ID]: (state, { payload }) => ({
        ...state,
        info: {
            ...state.info,
            defaultSecurityId: payload
        }
    }),
    [SET_CUSTOM_SECURITY_ID]: (state, { payload }) => ({
        ...state,
        customSecurityId: payload
    }),
    [ACCEPT_USER_SUCCESS]: (state) => ({
        ...state,
        info: {
            ...state.info,
            isAccepted: true
        }
    }),
    [ACCEPT_USER_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload
    }),
    [SET_ACCESS_TO_PA]: (state, { payload }) => ({
        ...state,
        accessToPa: payload
    }),
    [SET_ACCEPT_TERMS]: (state, { payload }) => ({
        ...state,
        acceptTerms: payload
    }),
    [CLEAR_ACCEPT_TERMS]: (state) => ({
        ...state,
        acceptTerms: null
    }),
    [GET_USER_NOTIFICATIONS_SUCCESS]: (state, { payload }) => ({
        ...state,
        notifications: {
            ...payload
        }
    }),
    [GET_USER_NOTIFICATIONS_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload
    }),
    [CHANGE_USER_NOTIFICATIONS_SUCCESS]: (state, { payload }) => ({
        ...state,
        notifications: {
            ...state.notifications,
            ...payload
        }
    }),
    [CHANGE_USER_NOTIFICATIONS_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload
    }),
    [GET_USER_TERMS_AND_CONDITIONS_SUCCESS]: (state, { payload: { termsAndConditions, typeOfTerms } }) => ({
        ...state,
        termsAndConditions: {
            ...state.termsAndConditions,
            [typeOfTerms]: termsAndConditions
        }
    }),
    [GET_USER_TERMS_AND_CONDITIONS_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload
    }),
    [LOGOUT_USER]: (state) => ({
        ...state,
        isLogout: true
    }),
    [SESSION_TIMEOUT]: (state) => ({
        ...state,
        isSessionTimeout: true
    }),
    [SET_SECURITY_CHANGING]: (state, { payload }) => ({
        ...state,
        isSecurityChanging: payload
    }),
    [SET_HAS_NEW_ANNOUNCEMENT]: (state, { payload }) => ({
        ...state,
        hasNewAnnouncement: payload
    }),
    [SET_ANNOUNCEMENT_MESSAGE]: (state, { payload }) => ({
        ...state,
        announcementMessage: payload
    }),
    [GET_USER_TREASURY_ACCESS]: (state, { payload }) => ({
        ...state,
        hasTreasuryAccountAccess: payload
    })
};

export default createReducer(initialState, actionHandlers);
