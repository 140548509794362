import * as _ from 'lodash';
import { mapProps, withPropsOnChange, compose } from '@shakacode/recompose';

import AnalysisInvestorTypeContent from './AnalysisInvestorTypeContent';
import { sortArray } from 'Utils/utils';
import { mapBarValuesToData } from 'Components/Tables/TableEasy';
import widgetModes from 'Constants/widget-modes';

export default compose(
    mapProps((props) => {
        return {
            ...props,
            widgetData: {
                ...props.widgetData,
                values: sortArray({
                    array: mapBarValuesToData(_.get(props, 'widgetData.values', []), 'os'),
                    primaryField: 'os'
                })
            }
        };
    }),
    withPropsOnChange(['widgetSettings'], props => {
        return {
            hideBarChart: _.get(props, 'widgetSettings.mode') === widgetModes.TABLE
        };
    })
)(AnalysisInvestorTypeContent);
