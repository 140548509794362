import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import cn from 'classnames';

import dateFormats from 'Constants/date-formats';
import IconDatePicker from 'Components/IconDatePicker';
import { NullableFormatter } from 'Components/formatters';
import widgetModes from 'Constants/widget-modes';
import { commonWidgetActions, commonWidgetPropTypes, isDateLessThanDefault } from 'Components/widgets/utils';

import './CalendarActionPanel.scss';

class CalendarActionPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        onDateChange: PropTypes.func.isRequired,
        selectedDate: PropTypes.string,
        exchangeName: PropTypes.string,
        dateEnabled: PropTypes.bool
    };

    handleDateChange = value => {
        const { widgetSettings, widgetData: { eodTimestamp } = {} } = this.props;
        const selectedDate = moment(value).format(dateFormats.SERVER_DATE_FORMAT);
        const newWidgetSettings = {
            ...widgetSettings,
            selectedDate,
            eodTimestamp
        };

        this.props.onDateChange(selectedDate);
        this.props.onUpdateWidgetSettings(newWidgetSettings);
        this.props.onUpdateWidgetData({ date: selectedDate });
    };

    render() {
        const { selectedDate, exchangeName, widgetSettings: { mode },
            dateEnabled, widgetData, isCurrentDateAvailable } = this.props;
        const date = selectedDate && moment(selectedDate);
        const calendarProps = {
            value: date,
            onDateChange: this.handleDateChange,
            isCurrentDateAvailable
        };

        return (date ?
            (<div className='calendar-action-panel'>
                <FormattedMessage id='widgets.info.asOfDate'>
                    {message => (
                        <span className={cn('message-note', { 'alert-note': isDateLessThanDefault(widgetData) })}>
                            {`${message} ${date.format(dateFormats.default)}`}
                        </span>
                    )}
                </FormattedMessage>
                {dateEnabled && <IconDatePicker {...calendarProps}/>}
                {mode === widgetModes.TABLE && exchangeName &&
                    <FormattedMessage id='widgets.info.exchange'>
                        {message => (<div className='info-message exchange-info'>
                            <span>{`${message}: `}</span>
                            <NullableFormatter value={exchangeName}/>
                        </div>)}
                    </FormattedMessage>
                }
            </div>)
            : null
        );
    }
}

export default CalendarActionPanel;
