import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes } from 'redux-form';
import _ from 'lodash';

import { runReport } from 'State/exportReports';
import * as widgetsSelectors from 'State/widgets/selectors';
import { showConfirmExportLargeReportModal } from 'State/modal';
import * as featuresSelectors from 'State/features/selectors';
import reportTypes from 'Constants/report-types';
import fileFormats from 'Constants/file-formats';
import reportExportFormats from 'Constants/export-report-formats';
import amountOfRecords from 'Constants/amount-of-records';
import shareholderTypes from 'Constants/shareholder-types';
import pdfExportColumnsLimit from 'Constants/pdf-export-columns-limits';
import columnsDefinitions, { proxyColumnDefinitions } from 'Components/widgets/ShareholdersTable/constants/columnsDefinitions';
import columnTypes from 'Components/widgets/ShareholdersTable/constants/shareholder-column-types';
import { getColumnsToDisplayCount } from 'Components/widgets/utils';
import shareholderDefaultViews from 'Components/widgets/ShareholdersTable/constants/defaultViews';
import {
    getShareholderType,
    getCurrentViewId,
    getCurrentSortCriteria
} from 'Components/widgets/ShareholdersTable/utils';
import ExportShareholderTableModalView from '../components/ExportShareholderTableModalView';
import { AmountOfAccountsFieldValidation } from '../components/AmountOfAccountsField';
import exportAmountOfRecords from '../constants/exportAmountOfRecords';
import includingFeaturesList from '../constants/includingFeatures';
import shareholderExportTypes from '../constants/shareholder-export-types';
import { getExpandedShareholdersSelector } from 'State/shareholderTable';
import featureTypes from 'Constants/feature-types';

const reportTemplateId = null;
const SORTABLE_COLUMN_FIELD = 'order';
const HIDE_FEATURES_SHAREHOLDERS_TYPES = [shareholderTypes.INSIDER, shareholderTypes.REGISTERED];

const FORM_NAME = 'exportShareholderTableForm';
const INITIAL_VALUES = {
    selectedAmountOfItems: { type: amountOfRecords.TOP_100 },
    includingFeatures: [],
    selectedFormat: reportExportFormats.EXCEL
};
const mapStateToProps = (state, props) => ({
    formValues: getFormValues(FORM_NAME)(state),
    settings: widgetsSelectors.getWidgetSettingsSelector(state, props.exportParams.widgetName),
    userFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
    expandedShareholders: getExpandedShareholdersSelector(state)
});
const mapDispatchToProps = { runReport, showConfirmExportLargeReportModal };

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: FORM_NAME,
    initialValues: INITIAL_VALUES,
    validate: values => {
        const errors = {};

        errors.selectedAmountOfItems = AmountOfAccountsFieldValidation(values.selectedAmountOfItems);

        return errors;
    }
})
class ExportShareholderTableModal extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        showConfirmExportLargeReportModal: PropTypes.func.isRequired,
        exportParams: PropTypes.shape({
            shareholderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
            widgetName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            availableDisplayTypes: PropTypes.array,
            cedeCo: PropTypes.object
        }),
        hideModal: PropTypes.func,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage }, userFeatures, settings } = this.props;
        const currentViewId = getCurrentViewId(settings);

        this.amountOfItems = exportAmountOfRecords.map(item => ({
            ...item,
            label: formatMessage({ id: item.label })
        }));

        this.includingFeatures = [];

        if (currentViewId !== shareholderDefaultViews.PROXY) {
            this.includingFeatures.push(...includingFeaturesList
                .filter((feature) => userFeatures[feature.value])
                .map(item => ({
                    ...item,
                    label: formatMessage({ id: item.label })
                }))
            );
        }


        if (this.includingFeatures.length > 0) {
            this.includingFeatures.push({
                label: formatMessage({ id: 'modals.exportReport.label.contentsOnScreen' }),
                value: featureTypes.CONTENTS_ONSCREEN
            });
        }

        this.exportFormats = shareholderExportTypes.map(format => ({
            label: format === reportExportFormats.EXCEL ? `${fileFormats[format]}*` : fileFormats[format],
            value: reportExportFormats[format],
            position: 'block'
        }));
        this.showFeatures = this.showFeatures();
    }

    handleExport = ({ selectedAmountOfItems, includingFeatures, selectedFormat }) => {
        const { hideModal, settings: { views = {} } } = this.props;
        const columnsCount = getColumnsToDisplayCount(views);
        const formData = this.getFormData(includingFeatures, selectedAmountOfItems);
        const launchReport = this.launchReport(selectedFormat, formData);

        hideModal();

        if (selectedFormat === reportExportFormats.PDF &&
            columnsCount > pdfExportColumnsLimit[reportTypes.shareholderTableTemplate]) {
            this.props.showConfirmExportLargeReportModal(launchReport);
            return;
        }

        launchReport();
    };

    handleFormatChange = () => {
        this.props.change('includingFeatures', []);
    };

    getFormData = (includingFeatures, selectedAmountOfItems) => {
        const { exportParams: { availableDisplayTypes = [], availableShareholderTypesList, cedeCo } = {} } = this.props;
        const sortCriteria = getCurrentSortCriteria(_.get(this.props, 'settings'));
        const shareholderIds = _.get(this.props, 'exportParams.shareholderIds');
        const showCede = _.get(this.props, 'settings.showCEDE');
        const shareholderType = getShareholderType(availableDisplayTypes, availableShareholderTypesList);
        const currentViewId = getCurrentViewId(this.props.settings);
        const isRegisteredFilter = availableDisplayTypes.length === 1 && availableDisplayTypes[0] === shareholderTypes.REGISTERED;
        const amountOfRecordsSettings = _.find(exportAmountOfRecords, a => _.get(a, 'value.type') === selectedAmountOfItems.type);
        let noOfRecords = _.get(amountOfRecordsSettings, 'value.records');
        let fundsToDisplay;
        let historicToDisplay;

        if (includingFeatures.includes(featureTypes.CONTENTS_ONSCREEN)) {
            fundsToDisplay = Object.keys(_.get(this.props, 'settings.expandedInstitutions'));
            historicToDisplay = Object.keys(_.get(this.props, 'expandedShareholders'));
        }

        if (amountOfRecords.NO_OF_RECORDS === selectedAmountOfItems.type) {
            noOfRecords = selectedAmountOfItems.records;
        }

        return {
            sortCriteria,
            showCede: !!cedeCo && showCede && isRegisteredFilter && currentViewId === shareholderDefaultViews.REGISTERED,
            includingFeatures,
            noOfRecords,
            columnsToDisplay: this.getColumns(),
            shareholderIds,
            shareholderType,
            fundsToDisplay,
            historicToDisplay
        };
    };

    getColumns = () => {
        const customViews = this.getCustomViews();
        const defaultViews = this.getDefaultViews();
        const currentViewId = getCurrentViewId(this.props.settings);
        const currentView = defaultViews[currentViewId] ||
            customViews[currentViewId];
        let definitions = columnsDefinitions;

        if (currentViewId === shareholderDefaultViews.PROXY) {
            definitions = proxyColumnDefinitions;
        }

        const sortedColumns = _.sortBy(definitions, [SORTABLE_COLUMN_FIELD]);
        const columnNames = _.map(sortedColumns, column => column.columnName);

        // hardcode shareholder name column on BE
        return _.filter(columnNames,
            columnName => _.includes(currentView.values, columnName) && columnName !== columnTypes.SHAREHOLDER_NAME);
    };

    getCustomViews = () => {
        return _.get(this.props, 'settings.views.customViews');
    };

    getDefaultViews = () => {
        return _.get(this.props, 'settings.views.defaultViews');
    };

    launchReport = (selectedFormat, formData) => {
        const currentViewId = getCurrentViewId(this.props.settings);
        const { shareholderTableTemplate, proxyShareholderTemplate } = reportTypes;

        const reportType = currentViewId === shareholderDefaultViews.PROXY ? proxyShareholderTemplate : shareholderTableTemplate;

        return () => {
            this.props.runReport(reportType,
                reportTemplateId,
                selectedFormat,
                formData
            );
        };
    };

    showFeatures = () => {
        const { settings, exportParams: { availableDisplayTypes = [] } } = this.props;
        const displayTypes = _.get(settings, 'displayTypes', []);

        return !(_.every(availableDisplayTypes, type => _.includes(HIDE_FEATURES_SHAREHOLDERS_TYPES, type)) ||
            displayTypes.length &&  _.every(displayTypes, type => _.includes(HIDE_FEATURES_SHAREHOLDERS_TYPES, type)));
    };

    render() {
        const { settings, hideModal } = this.props;

        return (
            <ExportShareholderTableModalView
                {...this.props}
                amountOfItems={this.amountOfItems}
                exportFormats={this.exportFormats}
                includingFeatures={this.includingFeatures}
                widgetSettings={settings}
                showFeatures={this.showFeatures}
                onExport={this.handleExport}
                onCancel={hideModal}
                onFormatChange={this.handleFormatChange}/>
        );
    }
}

export default ExportShareholderTableModal;
