import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';

export default sectionTypes => WrappedComponent => {
    class WithAccordion extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withAccordion');

        state = {
            expandedSection: sectionTypes[0]
        };

        handleExpandSection = (sectionName) => {
            if (this.state.expandedSection === sectionName) {
                const expandedSectionIndex = sectionTypes.indexOf(sectionName);
                const nextSectionIndex = expandedSectionIndex + 1 === sectionTypes.length ? 0 : expandedSectionIndex + 1;

                this.setState({ expandedSection: sectionTypes[nextSectionIndex] });
            } else {
                this.setState({ expandedSection: sectionName });
            }
        };

        render() {
            return (
                <WrappedComponent
                    expandedSection={this.state.expandedSection}
                    onSectionClick={this.handleExpandSection}
                    sectionTypes={sectionTypes}
                    {...this.props}/>
            );
        }
    }

    return WithAccordion;
};
