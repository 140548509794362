import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.SHAREHOLDER_NAME,
        order: 1,
        minWidth: 210,
        displayName: 'registered.shareholder.table.shareholder',
        initialSortDirection: sortDirections.ASC,
        manageable: false
    },
    {
        columnName: columnNames.ISSUER,
        order: 2,
        minWidth: 210,
        displayName: 'registered.shareholder.table.issuer',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SECURITY,
        order: 3,
        minWidth: 210,
        displayName: 'registered.shareholder.table.security',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.SECURITY_NAME
        }
    },
    {
        columnName: columnNames.ACCOUNT_NUMBER,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.accountNumber',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.YEAR,
        order: 5,
        minWidth: 130,
        displayName: 'registered.shareholder.table.year',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            valueLength: 4
        }
    },
    {
        columnName: columnNames.ORDINARY_INCOME,
        order: 6,
        minWidth: 130,
        displayName: 'registered.shareholder.table.ordinaryIncome',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.QUALIFIED,
        order: 7,
        minWidth: 130,
        displayName: 'registered.shareholder.table.qualified',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.CAP_GAINS_INCOME,
        order: 8,
        minWidth: 130,
        displayName: 'registered.shareholder.table.capGainsIncome',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.CAP_GAINS_1250,
        order: 9,
        minWidth: 130,
        displayName: 'registered.shareholder.table.capGains1250',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.CAP_GAINS_1202,
        order: 10,
        minWidth: 130,
        displayName: 'registered.shareholder.table.capGains1202',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.NON_TAX_INCOME,
        order: 11,
        minWidth: 130,
        displayName: 'registered.shareholder.table.nonTaxIncome',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.FEDERAL_TAX,
        order: 12,
        minWidth: 130,
        displayName: 'registered.shareholder.table.federalTax',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.FOREIGN_TAX,
        order: 13,
        minWidth: 130,
        displayName: 'registered.shareholder.table.foreignTax',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.CASH_LIQUID,
        order: 14,
        minWidth: 130,
        displayName: 'registered.shareholder.table.cashLiquid',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.NON_CASH_LIQUID,
        order: 15,
        minWidth: 130,
        displayName: 'registered.shareholder.table.nonCashLiquid',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.SERVICE_CHARGE,
        order: 16,
        minWidth: 130,
        displayName: 'registered.shareholder.table.serviceCharge',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2
        }
    },
    {
        columnName: columnNames.EARNED_INCOME,
        order: 17,
        minWidth: 130,
        displayName: 'registered.shareholder.table.earnedIncome',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.EXEMPT_INT_DIV,
        order: 18,
        minWidth: 130,
        displayName: 'registered.shareholder.table.exemptIntDiv',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.PRIV_INT_DIV,
        order: 19,
        minWidth: 130,
        displayName: 'registered.shareholder.table.privIntDiv',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    }
];

export const secondarySorting = {
    field: columnNames.YEAR,
    direction: sortDirections.DESC
};
