import sortByColumnNames from './shareholder-list-columns-sortby';
import sortDirections from 'Constants/sort-directions';

const sortDirectionsArray = [
    {
        id: sortDirections.ASC,
        label: 'shareholder.list.sortBy.asc'
    },
    {
        id: sortDirections.DESC,
        label: 'shareholder.list.sortBy.desc'
    }
];

export default [
    {
        id: sortByColumnNames.POSITION,
        label: 'shareholder.list.sortBy.position',
        sortDirections: sortDirectionsArray,
        defaultSortDirection: sortDirections.DESC
    },
    {
        id: sortByColumnNames.SHAREHOLDER_LAST_NAME,
        label: 'shareholder.list.sortBy.name',
        sortDirections: sortDirectionsArray,
        defaultSortDirection: sortDirections.ASC
    },
    {
        id: sortByColumnNames.SHAREHOLDER_NAME,
        label: 'shareholder.list.sortBy.registration',
        sortDirections: sortDirectionsArray,
        defaultSortDirection: sortDirections.ASC
    },
    {
        id: sortByColumnNames.HOLDER_TYPE,
        label: 'shareholder.list.sortBy.holderType',
        enabledOn: sortByColumnNames.HOLDER_TYPE,
        sortDirections: sortDirectionsArray,
        defaultSortDirection: sortDirections.DESC
    },
    {
        id: sortByColumnNames.COUNTRY,
        label: 'shareholder.list.sortBy.country',
        enabledOn: sortByColumnNames.COUNTRY,
        sortDirections: sortDirectionsArray,
        defaultSortDirection: sortDirections.ASC
    },
    {
        id: sortByColumnNames.STATE,
        label: 'shareholder.list.sortBy.state',
        enabledOn: sortByColumnNames.STATE,
        sortDirections: sortDirectionsArray,
        defaultSortDirection: sortDirections.ASC
    }
];
