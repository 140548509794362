import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import PageHeader from '../PageHeader';
import Nav from '../Nav';
import SecuritySection from './components/SecuritySection';
import SearchSection from './components/SearchSection';
import { pathRoutesConfig } from 'Routes';
import { buildNavLinks } from 'Utils/routing';

import './AppHeader.scss';

class AppHeader extends Component {
    static propTypes = {
        intl: intlShape,
        currentFeatures: PropTypes.object,
        globalSettings: PropTypes.object,
        customSecurityId: PropTypes.number
    };

    getNavLinks = () => {
        const { intl: { formatMessage }, currentFeatures, globalSettings, customSecurityId } = this.props;
        const { getAppNav } = pathRoutesConfig;
        const appNav = getAppNav(globalSettings);

        return buildNavLinks({
            pathConfig: appNav.pathConfig,
            features: currentFeatures,
            formatMessage,
            customSecurityId
        });
    };

    render() {
        const navLinks = this.getNavLinks();

        return (
            <header className='app-header'>
                <PageHeader>
                    <div className='app-header-content'>
                        <SecuritySection/>
                        <SearchSection/>
                    </div>
                </PageHeader>
                {navLinks.length ? <Nav links={navLinks} horizontal/> : null}
            </header>
        );
    }
}

export default AppHeader;
