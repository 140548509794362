import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { mapProps, getContext } from '@shakacode/recompose';
import cn from 'classnames';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions, getVisibleColumns } from 'Components/widgets/utils';
import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';
import { showCommonColumnsManagerModal } from 'State/modal/actions';
import { showExportWidgetModal } from 'State/exportReports';
import ContactsTableSearch from './components/ContactsTableSearch';
import Pagination from 'Components/Pagination';
import WidgetAddToReport from 'Components/widgets/Widget/components/WidgetAddToReport';
import WidgetRemove from 'Components/widgets/Widget/components/WidgetRemove';
import WidgetPrint from 'Components/widgets/Widget/components/WidgetPrint';
import Icon from 'Components/Icon';
import { CheckBox } from 'Components/CheckBox';
import Popup from 'Components/Popup';
import suggestionTypes, { searchMinLength } from 'Constants/suggestions';
import featureTypes from 'Constants/feature-types';
import { despace } from 'Utils/string';
import keyCodes from 'Constants/key-codes';

const mapStateToProps = state => ({
    blockAction: getDynamicPageBlockActionSelector(state)
});

const mapDispatchToProps = {
    showCommonColumnsManagerModal,
    showExportWidgetModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@getContext({
    events: PropTypes.object
})
@mapProps(props => {
    const visibleColumns = _.get(props, 'widgetSettings.visibleColumns', []);
    const columns = props.columnDefinitions.columns
        .filter(column => column.manageable)
        .map(column => ({
            name: column.columnName,
            label: props.intl.formatMessage({ id: column.displayName }),
            isSelected: visibleColumns.indexOf(column.columnName) > -1
        }));

    return {
        ...props,
        columns
    };
})
class ContactsTableControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        columns: PropTypes.array.isRequired,
        showExportWidgetModal: PropTypes.func.isRequired,
        showCommonColumnsManagerModal: PropTypes.func,
        currentPage: PropTypes.number,
        filterTemplate: PropTypes.string,
        events: PropTypes.object,
        blockAction: PropTypes.bool,
        intl: intlShape
    };

    state = {
        filterTemplate: this.props.filterTemplate
    };

    handleExport = () => {
        const {
            widgetSettings: {
                sortCriteria,
                visibleColumns: columnsToDisplay
            },
            widgetPageParams: { shareholderId, reportTemplateType },
            filterTemplate,
            currentPage,
            columnDefinitions,
            myShareholdersOnly,
            rowLimit
        } = this.props;
        const { columnsOrder } = columnDefinitions;

        this.props.showExportWidgetModal({
            reportTemplateType,
            exportParams: {
                shareholderId,
                sortCriteria,
                columnsToDisplay: _.sortBy(columnsToDisplay, columnName => columnsOrder[columnName]),
                filterTemplate,
                rowLimit,
                currentPage,
                myShareholdersOnly
            },
            modalViewParams: {
                showExcelLimit: true
            }
        });
    };

    handleActionColumnManager = () => {
        this.props.showCommonColumnsManagerModal({
            titleKey: 'modals.columnsManagerModal.title',
            columns: this.props.columns,
            onSave: this.handleSaveColumns,
            disableValidation: true
        });
    };

    handleSaveColumns = (columns = []) => {
        const visibleColumns = getVisibleColumns(columns, this.props.columnDefinitions);

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            visibleColumns
        });
    };

    handleChangeCurrentPage = (currentPage) => {
        this.props.events.onUpdateWidgetData({ currentPage });
    };

    handleCheckboxChange = e => {
        const checked = e.target.checked;
        const filterTemplate = this.getFilterTemplateForDataUpdating();

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            myShareholdersOnly: checked
        });
        this.props.events.onUpdateWidgetData({
            myShareholdersOnly: checked,
            filterTemplate
        });
        this.searchInput.clearSearchValue();
    };

    handleFilterChange = inputValue => {
        this.setState({ filterTemplate: inputValue });
    };

    handleSelect = value => {
        if (value !== this.state.filterTemplate) {
            this.handleFilterChange(value);
            this.props.events.onUpdateWidgetData({
                filterTemplate: value
            });
        }
    };

    handleFilterSubmit = (e) => {
        const filterValue = despace(this.state.filterTemplate);

        e.preventDefault();
        if (filterValue.length < searchMinLength && filterValue.length > 0) {
            return;
        }

        this.handleFilterChange(filterValue);

        if (filterValue !== this.props.filterTemplate) {
            this.props.events.onUpdateWidgetData({
                filterTemplate: filterValue
            });
        }
    };

    handleMouseDown = e => {
        const filterValue = despace(this.state.filterTemplate);

        if (filterValue.length < searchMinLength && filterValue.length > 0) {
            e.preventDefault();
        }
    };

    handleKeyDown = e => {
        if (e.keyCode === keyCodes.enter) {
            this.handleFilterSubmit(e);
            const filterValue = despace(this.state.filterTemplate);

            if (filterValue.length >= searchMinLength || filterValue.length === 0) {
                this.searchInput.clearSearchValue();
            }
        }
    };

    getFilterTemplateForDataUpdating = () => {
        const filterValue = despace(this.state.filterTemplate);

        if (filterValue.length < searchMinLength && filterValue.length > 0) {
            return '';
        }

        return filterValue;
    };

    render() {
        const {
            blockAction,
            widgetData,
            widgetType,
            events,
            currentPage,
            rowLimit,
            isDataViz,
            isFetching,
            onRemoveWidget,
            onAddWidgetToDataVizReport,
            intl: { formatMessage },
            currentFeatures,
            myShareholdersOnly
        } = this.props;
        const exportInactive = !widgetData || !widgetData.values.length;
        const exportClassName = cn('font-icon font-icon-download action-icon', { inactive: exportInactive });

        return (
            <div className='contacts-table-control-panel'>
                {!isDataViz &&
                <div className='contacts-table-search'>
                    <form onSubmit={this.handleFilterSubmit}>
                        <ContactsTableSearch
                            ref={el => this.searchInput = el}
                            requestParams={{
                                suggestionType: suggestionTypes[widgetType],
                                size: 10,
                                filterCriteria: {
                                    myShareholdersOnly
                                }
                            }}
                            value={this.state.filterTemplate}
                            onChange={this.handleFilterChange}
                            onSelect={this.handleSelect}
                            onFilterSubmit={this.handleFilterSubmit}
                            onKeyDown={this.handleKeyDown}
                            isFetching={isFetching}
                            onMouseDown={this.handleMouseDown}/>
                        <Popup
                            content={<FormattedMessage id='contactsTable.myShareholders.popupMessage'>
                                {message => <div className='my-shareholders-popup'>{message}</div>}
                            </FormattedMessage>}>
                            <CheckBox
                                className='my-shareholders-checkbox'
                                checked={myShareholdersOnly}
                                onChange={this.handleCheckboxChange}
                                label={formatMessage({ id: 'contactsTable.myShareholders' })}/>
                        </Popup>
                    </form>
                </div>
                }
                <div className='contacts-actions-panel'>
                    {widgetData && !isDataViz &&
                    <Pagination
                        className='contacts-table-pagination'
                        currentPage={currentPage}
                        currentPageSize={rowLimit}
                        numberOfPages={widgetData.totalPages}
                        totalRecords={widgetData.totalRecords}
                        onChangeCurrentPage={this.handleChangeCurrentPage}
                        isPageSizeFixed/>
                    }
                    <div className='action-icons-container'>
                        {!isDataViz &&
                        <WidgetPrint
                            className='action-icon'
                            widgetType={widgetType}/>
                        }
                        {!isDataViz &&
                        <Icon
                            className={exportClassName}
                            onClick={!exportInactive ? this.handleExport : null}/>
                        }
                        {currentFeatures[featureTypes.CUSTOM_REPORTS] && !isDataViz &&
                        <WidgetAddToReport
                            className='action-icon'
                            onAddToReport={onAddWidgetToDataVizReport}
                            dataParamsBuilder={events.onBuildDataParams}/>
                        }
                        <Icon
                            className='font-icon font-icon-view-three-columns action-icon'
                            onClick={this.handleActionColumnManager}/>
                        {isDataViz &&
                        <WidgetRemove
                            blockAction={blockAction}
                            onRemoveWidget={onRemoveWidget}
                            className='action-icon'/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactsTableControlPanel;
