export default {
    SHAREHOLDER_NAME: 'shareholderName',
    ISSUER: 'issuer',
    SECURITY: 'security',
    ACCOUNT_NUMBER: 'accountNumber',
    ISSUE_REASON: 'issueReason',
    ISSUE_DATE: 'issueDate',
    CERT_BOOK_NO: 'certBookNo',
    SHARES_TYPE: 'sharesType',
    SHARES: 'shares',
    STOP_CODE: 'stopCode',
    SURRENDER_DATE: 'surrenderDate',
    SURRENDER_REASON: 'surrenderReason',
    TRANSACTION_DATE: 'transactionDate',
    TRANSACTION_TYPE: 'transactionType',
    SHARES_PRICE: 'sharesPrice',
    AMOUNT_INVESTED: 'amountInvested',
    SERVICE_CHARGE: 'serviceCharge',
    TOTAL_SHARES: 'totalShares',
    CHECK_NO: 'checkNo',
    PAYMENT_TYPE: 'paymentType',
    AMOUNT: 'amount',
    STATUS: 'status',
    STATUS_DATE: 'statusDate',
    DOCUMENT_DATE: 'documentDate',
    AUTHOR: 'author',
    DOCUMENT_TYPE: 'documentType',
    DESCRIPTION: 'description',
    DOWNLOAD: 'download',
    DELETE: 'delete',
    YEAR: 'year',
    ORDINARY_INCOME: 'ordinaryIncome',
    QUALIFIED: 'qualified',
    CAP_GAINS_INCOME: 'capGainsIncome',
    CAP_GAINS_1250: 'capGains1250',
    CAP_GAINS_1202: 'capGains1202',
    NON_TAX_INCOME: 'nonTaxIncome',
    FEDERAL_TAX: 'federalTax',
    FOREIGN_TAX: 'foreignTax',
    GROSS_AMOUNT: 'grossAmount',
    EXEMPT: 'exempt',
    PRIVATE: 'private',
    DATE_OF_SALE: 'dateOfSale',
    ROYAL_AMOUNT: 'royalAmount',
    OTHER_AMOUNT: 'otherAmount',
    FEDERAL_AMOUNT: 'federalAmount',
    TAX_WITHHELD: 'taxWithheld',
    TAX_RATE: 'taxRate',
    INCOME_CODE: 'incomeCode',
    CASH_LIQUID: 'cashLiquid',
    NON_CASH_LIQUID: 'nonCashLiquid',
    EARNED_INCOME: 'earnedIncome',
    EXEMPT_INT_DIV: 'exemptIntDiv',
    PRIV_INT_DIV: 'privIntDiv',
    ADDRESS: 'address',
    TOTAL: 'total',
    CERTIFICATED: 'certificated',
    BOOK: 'book',
    DRDSPP: 'drdspp',
    MKT_PRICE: 'mktPrice',
    VALUE: 'value',
    HOLDER_TYPE: 'holderType',
    SPEC_ACCT_CODE: 'specAcctCode',
    MAIL_CODE: 'mailCode',
    DIRECT_DEPOSIT: 'directDeposit',
    ENROLLED_IN_DRP: 'enrolledDRP',
    BLOCK: 'block',
    NO_DIVIDEND: 'noDividend',
    SECONDARY_SORTING: 'secondarySorting',
    TAX_LOT_ID: 'taxLotId',
    ACQUISITION_EFFECTIVE_DATE: 'acquisitionEffectiveDate',
    COVERED_INDICATOR: 'coveredIndicator',
    CERT_PREFIX: 'certPrefix',
    CERT_NUMBER: 'certNumber',
    CURRENT_SHARES: 'currentShares',
    ORIGINAL_COST_BASIS: 'originalCostBasis',
    CURRENT_COST_BASIS: 'currentCostBasis',
    SALE_CLOSE_DATE: 'saleCloseDate',
    ACQUISITION_DATE: 'acquisitionDate',
    CLOSED_SHARES: 'closedShares',
    BOOK_ACTIVITY_DATE: 'bookActivityDate',
    DEBIT_CREDIT: 'debitCredit',
    ADVICE_NO: 'adviceNo',
    SOURCE_CODE: 'sourceCode',
    BOOK_PRICE_USD: 'bookPriceUSD',
    COST_BASIS_PER_SHARE_USD: 'costBasisPerShareUSD',
    GROSS_PROCEEDS_USD: 'grossProceedsUSD',
    SHORT_LONG: 'shortLong',
    GAINS_LOSS_AMOUNT_USD: 'gainsLossAmountUSD',
    UPLOAD_STATUS: 'uploadStatus'
};
