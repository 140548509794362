import { createSelector } from 'reselect';
import _ from 'lodash';

export const getReportDataSelector = state => _.get(state, 'reportingShareDetail.reportData');
export const isFetchingSelector = state => _.get(state, 'reportingShareDetail.isFetching', false);

export const isEditModeSelector = createSelector(
    getReportDataSelector,
    reportData => !!_.get(reportData, 'reportTemplateId')
);

export const getInitialValuesSelector = createSelector(
    getReportDataSelector,
    reportData => _.get(reportData, 'form')
);
