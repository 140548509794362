import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

const NoResults = ({ messageId, className, style }) => (
    <div style={style} className={cn(className, 'no-results')}>
        <FormattedMessage id={messageId}/>
    </div>
);

NoResults.propTypes = {
    messageId: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.string
};

NoResults.defaultProps = {
    messageId: 'tables.noResultsFound'
};

export default NoResults;
