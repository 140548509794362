import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { getMarketDataSelector } from 'State/marketData/selectors';
import { getMarketData } from 'State/marketData/actions';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import { hideModal, showWithWidgetModal } from 'State/modal/actions';
import formattingConstants from 'Constants/formatter-options';
import featureTypes from 'Constants/feature-types';
import { CurrencyFormatter, DateFormatter, PercentFormatter } from 'Components/formatters';
import { checkBackEndNull } from 'Utils/utils';
import companyInformationDataKeys from '../constants/company-information-data-keys';

import './CompanyInformation.scss';

const CURRENCY_DECIMAL_POINTS = 2;

const mapStateToProps = state => ({
    marketData: getMarketDataSelector(state),
    userFeatures: getCurrentFeaturesSelector(state)
});

const mapDispatchToProps = {
    showWithWidgetModal,
    hideModal,
    getMarketData
};

const VIEW_FULL_QUOTE_WIDGET_SPECIAL_SLOT_ID = -1;

@connect(mapStateToProps, mapDispatchToProps)
class CompanyInformation extends Component {
    static propTypes = {
        marketData: PropTypes.object,
        userFeatures: PropTypes.object,
        showWithWidgetModal: PropTypes.func,
        hideModal: PropTypes.func,
        getMarketData: PropTypes.func
    };

    componentDidMount() {
        this.props.getMarketData();
    }

    componentWillUnmount() {
        this.props.hideModal();
    }

    handleFullQuoteModalOpen = () => {
        this.props.showWithWidgetModal({
            slotId: VIEW_FULL_QUOTE_WIDGET_SPECIAL_SLOT_ID,
            className: 'full-quote-modal'
        });
    };

    render() {
        const { marketData, userFeatures } = this.props;
        const { companyTicker, companyName, stockExchange, currentPrice, changePrice, marketCloseDate } = marketData || {};
        const existDataForCompany = _.some(companyInformationDataKeys, key => !checkBackEndNull(marketData[key]));
        const changePriceClass = cn('change-price', {
            neutral: changePrice === 0,
            positive: changePrice > 0,
            negative: changePrice < 0
        });

        return (
            <div className='company-information'>
                <div className='company-data'>
                    <div className='flexbox'>
                        <div className='company-name' title={`(${companyTicker}) ${companyName}`}>
                            {companyTicker && <span>({companyTicker}) </span>}
                            {companyName && <span>{companyName}</span>}
                        </div>
                        {stockExchange && <div className='stock-exchange'>({stockExchange})</div>}
                    </div>
                    <div className='flexbox'>
                        {currentPrice && <div className='current-price'>
                            <CurrencyFormatter
                                value={currentPrice}
                                decimalPoints={CURRENCY_DECIMAL_POINTS}/>
                        </div>
                        }
                        {_.isNumber(changePrice) && <div className={changePriceClass}>
                            <PercentFormatter
                                value={changePrice}
                                decimalPoints={formattingConstants.DEFAULT_PERCENT_DECIMAL_POINTS}
                                negativeWithBrackets={false}
                                withPercent
                                withThreshold={false}/>
                        </div>
                        }
                    </div>
                    {existDataForCompany && userFeatures[featureTypes.FULL_QUOTE] &&
                    <div className='full-quote' onClick={this.handleFullQuoteModalOpen}><FormattedMessage
                        id='home.fullQuote'/></div>
                    }
                </div>
                {marketCloseDate && (<div className='message-note'>
                    <FormattedMessage id='home.marketCloseDate'/>
                    <DateFormatter value={marketCloseDate}/>
                </div>)
                }
            </div>
        );
    }
}

export default CompanyInformation;
