import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';

import { RadioButtonsListField } from 'Components/RadioButtonsList';
import LimitNote from 'Components/LimitNote';

import './ExportRegisteredOverviewModalView.scss';

const ExportRegisteredOverviewModalView = props => {
    const {
        intl: { formatMessage },
        amountOfRecords,
        sortCriteria,
        onExport,
        onCancel,
        handleSubmit,
        submitting,
        invalid,
        onAmountOfRecordsChange,
        exportFormats
    } = props;
    const sortDirectionId = sortCriteria.sortDirection === 'desc' ? 'shareholder.list.sortBy.desc' : 'shareholder.list.sortBy.asc';
    const sortFieldId = `registered.shareholder.table.${sortCriteria.sortField}`;

    return (
        <form
            onSubmit={handleSubmit(onExport)}
            className='export-report-modal export-registered-overview-modal'>
            <div className='format-container'>
                <RadioButtonsListField
                    className='amount-of-records-list'
                    onChange={onAmountOfRecordsChange}
                    name='selectedAmountOfRecords'
                    list={amountOfRecords}/>
                <div className='form-section-title'>{formatMessage({ id: 'modals.exportReport.label.sorted' })}</div>
                <div>{`${formatMessage({ id: sortFieldId })}, ${formatMessage({ id: sortDirectionId })}`}</div>
                <div
                    className='form-section-title'>{formatMessage({ id: 'modals.exportReport.label.selectFormat' })}</div>
                <RadioButtonsListField
                    name='selectedFormat'
                    list={exportFormats}/>
            </div>
            <div className='modal-actions'>
                <button
                    type='submit'
                    className='btn btn-large'
                    disabled={submitting || invalid}>
                    {formatMessage({ id: 'modals.exportReport.exportBtn' })}
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className='btn btn-large'>
                    {formatMessage({ id: 'common.cancel' })}
                </button>
            </div>
            <LimitNote/>
        </form>
    );
};

ExportRegisteredOverviewModalView.propTypes = {
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
    amountOfRecords: PropTypes.array,
    sortCriteria: PropTypes.object,
    widgetSettings: PropTypes.object,
    exportFormats: PropTypes.array,
    onExport: PropTypes.func,
    onCancel: PropTypes.func,
    onAmountOfRecordsChange: PropTypes.func
};

export default ExportRegisteredOverviewModalView;
