import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage, intlShape } from 'react-intl';
import cn from 'classnames';

import TextInput from 'Components/TextInput';
import FormErrorSummary from 'Components/FormErrorSummary';
import SearchSubmitButton from './components/SearchSubmitButton';
import SearchByOption from './components/SearchByOption';
import { PII } from 'Constants/search';

import './SearchBySecurity.scss';

class SearchBySecurity extends Component {
    static propTypes = {
        onSearch: PropTypes.func,
        handleSubmit: PropTypes.func,
        selectedIssuers: PropTypes.array,
        selectedSecurities: PropTypes.array,
        toggleMultipleIssuer: PropTypes.func,
        toggleMultipleSecurity: PropTypes.func,
        className: PropTypes.string,
        selectedPII: PropTypes.string,
        isAvailableQueryInput: PropTypes.bool,
        isFetching: PropTypes.bool,
        clearSubmitErrors: PropTypes.func,
        intl: intlShape
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        // if there are validation message and disabled query input
        if (!nextProps.isAvailableQueryInput) {
            this.props.clearSubmitErrors();
        }
    }

    render() {
        const { selectedPII, isFetching, className, handleSubmit, intl: { formatMessage } } = this.props;

        return (<div className={cn('search-by-security-selector', className)}>
            <form onSubmit={handleSubmit}>
                <div className='radio-buttons'>
                    <SearchByOption
                        value={PII.NONE}/>
                    <SearchByOption
                        value={PII.TAX_ID}/>
                    <SearchByOption
                        value={PII.CERTIFICATE_NO}/>
                </div>
                <div className='search-input'>
                    <div className='search-input-field'>
                        <Field
                            name='query'
                            component={TextInput}
                            autoComplete={false}
                            disabled={!this.props.isAvailableQueryInput}
                            type='text'/>
                        {isFetching && <span className='spinner-container'><span className='Select-loading'/></span>}
                    </div>
                    <SearchSubmitButton/>
                    {!this.props.isAvailableQueryInput && !isFetching &&
                    <FormErrorSummary error={formatMessage({ id: 'search.across.form.error' })}/>}
                </div>
                {(selectedPII === PII.TAX_ID || selectedPII === PII.CERTIFICATE_NO) &&
                <FormattedMessage id='search.by.query.adv.note'>
                    {(msg) => <span className='message-note'>({msg})</span>}
                </FormattedMessage>}
            </form>
        </div>);
    }
}

export default SearchBySecurity;
