import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { getUserDataSelector } from 'State/user';
import { cacheCustomSecurity } from 'State/manageCache';
import MyProfilePageView from '../components/MyProfilePageView';

import '../components/MyProfilePage.scss';

const mapStateToProps = (state) => ({
    userData: getUserDataSelector(state)
});
const mapDispatchToProps = { cacheCustomSecurity };

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class MyProfilePage extends Component {
    static propTypes = {
        cacheCustomSecurity: PropTypes.func
    };

    handleOAMAction = (actionUrl) => () => {
        this.props.cacheCustomSecurity().then(() => {
            window.location = actionUrl;
        });
    };

    render() {
        return (
            <MyProfilePageView
                {...this.props}
                onOAMAction={this.handleOAMAction}/>
        );
    }
}

export default MyProfilePage;
