import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';

import TableEasy from 'Components/Tables/TableEasy';
import { labelCell, numberCell, valueWithTooltipCell, headerCell } from './cellRenderers';
import { columns } from '../constants';

const BadAddressesTableView = ({ tableData, isDataViz, isPrintMode }) => (
    <div className='table-container'>
        <TableEasy
            className='widget-table'
            renderAllRows={isDataViz}
            showAllRows={isPrintMode}
            {...tableData}/>
    </div>
);

BadAddressesTableView.propTypes = {
    tableData: PropTypes.object,
    isDataViz: PropTypes.bool,
    isPrintMode: PropTypes.bool
};

export default compose(
    mapProps(props => {
        const { data, intl: { formatMessage }, isDataViz, isPrintMode, widgetFooterProps } = props;

        const tableData = {
            columns: [{
                dataKey: columns.COUNTRY,
                flexGrow: 2.9,
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'bad.addresses.country' }),
                    <FormattedHTMLMessage id='bad.addresses.tooltip.tableView.countryAndState'/>
                ),
                title: formatMessage({ id: 'bad.addresses.country' }),
                bodyCellComponent: valueWithTooltipCell
            }, {
                dataKey: columns.STATE,
                flexGrow: 2.9,
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'bad.addresses.state' }),
                    <FormattedHTMLMessage id='bad.addresses.tooltip.tableView.countryAndState'/>
                ),
                columnClassName: 'overfilling-row-container',
                title: formatMessage({ id: 'bad.addresses.state' }),
                bodyCellComponent: labelCell
            }, {
                dataKey: columns.BAD_ADDRESSES_NUMBER,
                flexGrow: 4.2,
                maxWidth: 150,
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'bad.addresses.total.badAddresses.number' }),
                    <FormattedHTMLMessage id='bad.addresses.tooltip.tableView.badAddressesNumber'/>
                ),
                columnClassName: 'text-right',
                title: formatMessage({ id: 'bad.addresses.number' }),
                bodyCellComponent: numberCell
            }],
            data: _.filter(data.data.tableData, v => !_.isUndefined(v.badAddressesNumber)),
            aggregate: data.aggregate,
            markDifferenceFromReport: true,
            widgetFooterProps
        };

        return {
            tableData,
            isDataViz,
            isPrintMode
        };
    })
)(BadAddressesTableView);
