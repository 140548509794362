import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions, getManageableColumns } from 'Components/widgets/utils';
import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import { registeredOverview } from '../_commonComponents/RegisteredShareholderTable/hoc';

const COLUMNS_MANAGER_ROWS = 8;

@registeredOverview
class RegisteredShareholder1099DIV extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    render() {
        const props = this.props;

        return (
            <RegisteredShareholderTable
                {...props}
                data={props.widgetData}
                columnManagerRows={COLUMNS_MANAGER_ROWS}
                columnsDefinitions={columnsDefinitions}
                secondarySorting={secondarySorting}
                manageableColumns={getManageableColumns(columnsDefinitions)}
                disableValidation/>
        );
    }
}

export default RegisteredShareholder1099DIV;
