import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const TdComponent = (props) => {
    const { className, style, children, onClick, ...rest } = props;

    return (
        <div className={cn('rt-td', className)} style={{ ...style, msFlex: style.flex }} onClick={onClick}>
            {React.isValidElement(children) && React.cloneElement(children, { ...rest })}
        </div>
    );
};

TdComponent.propTypes = {
    style: PropTypes.object,
    additionalData: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string
};

export default TdComponent;
