import React, { Component } from 'react';
import PropsTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import fileFormats from 'Constants/file-formats';
import reportExportFormats from 'Constants/export-report-formats';
import { RadioButtonsListField } from 'Components/RadioButtonsList';

import './ExportShareholderContactsModalView.scss';

@injectIntl
class ExportShareholderContactsModalView extends Component {
    static propTypes = {
        intl: intlShape,
        onCancel: PropsTypes.func.isRequired,
        handleSubmit: PropsTypes.func.isRequired,
        onExport: PropsTypes.func.isRequired
    }

    items = [{
        label: `${fileFormats[reportExportFormats.EXCEL]}`,
        value: reportExportFormats.EXCEL,
        position: 'block'
    }, {
        label: fileFormats[reportExportFormats.PDF],
        value: reportExportFormats.PDF,
        position: 'block'
    }];

    render() {
        const { intl: { formatMessage }, onCancel, handleSubmit, onExport } = this.props;

        return (
            <form
                onSubmit={handleSubmit(onExport)}
                className='export-shareholder-contacts-modal'>
                <div className='format-container'>
                    <div className='form-section-title'>
                        {formatMessage({ id: 'modals.exportReport.label.selectFormat' })}
                    </div>
                    <RadioButtonsListField
                        name='selectedFormat'
                        list={this.items}/>
                </div>
                <div className='modal-actions'>
                    <button
                        className='btn btn-large'
                        type='submit'>
                        {formatMessage({ id: 'modals.exportReport.exportBtn' })}
                    </button>
                    <button
                        type='button'
                        onClick={onCancel}
                        className='btn btn-large'>
                        {formatMessage({ id: 'common.cancel' })}
                    </button>
                </div>
            </form>
        );
    }
}

export { ExportShareholderContactsModalView };
