/* eslint-disable react/no-multi-comp */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tooltip from 'Components/Tooltip';
import { NullableFormatter, NumberFormatter } from 'Components/formatters';
import { UNIDENTIFIED } from 'Constants/undefined-values';
import messageType from 'Constants/message-types';
import { getSignClassNameFactory } from '../../utils';

export const labelCell = v => <NullableFormatter value={v.value} withTitle className='overfilling-row'/>;
export const numberCell = v => {
    const getSignClassByMessageType = getSignClassNameFactory(v.rowData.widgetFooterProps);
    const className = v.rowData.isTotal && v.rowData.markDifferenceFromReport
        ? getSignClassByMessageType(messageType.DIFFERENT_FROM_REPORT)
        : null;

    return <NumberFormatter className={className} value={v.value} withTitle/>;
};

export const headerCell = (title, tooltip) => (<Tooltip
    content={tooltip ? tooltip : title}>
    <span className='ellipsis'>{title}</span>
</Tooltip>);

export const valueWithTooltipCell = v => {
    if (v.value === UNIDENTIFIED) {
        return (<Tooltip
            content={<FormattedMessage id='bad.addresses.tooltip.unidentified'/>}>
            <span>
                <NullableFormatter {...v} withTitle={false}/>
            </span>
        </Tooltip>);
    }
    return <NullableFormatter {...v} withTitle/>;
};

