import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './CreateNoteLink.scss';

const CreateNoteLink = props => {
    return (props.onCreateNote &&
        <div className='add-note-link'>
            <FormattedMessage id='notes.addButton'>
                {message => <span onClick={props.onCreateNote}>{message}</span>}
            </FormattedMessage>
        </div>
    );
};

CreateNoteLink.propTypes = {
    onCreateNote: PropTypes.func
};

export default memo(CreateNoteLink);
