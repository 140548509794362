import React, { Fragment } from 'react';
import { actions } from 'react-redux-toastr';
import _ from 'lodash';

import config from 'Constants/config';
import NOTIFICATION_TYPES from 'Constants/notification-types';

function buildNotifyAction(type) {
    return (title, message, options = {}) => {
        const defaultOptions = {
            type,
            position: 'top-right',
            options: {
                removeOnHover: true,
                timeOut: config.notifierTimeout,
                icon: type,
                showCloseButton: true,
                preventDuplicates: false,
                component: () => (<Fragment>
                    {title && <div className='rrt-title'>{title}</div>}
                    {message && <div className='rrt-text'>{message}</div>}
                </Fragment>)
            }
        };

        return actions.add({
            title: null,
            message: null,
            ..._.merge({}, defaultOptions, { options })
        });
    };
}

/**
 * Add success notification
 * @param {String} title - It's notification title.
 * @param {String} message - It's notification message.
 * @param {Object} [options] - options for notification see https://github.com/diegoddox/react-redux-toastr.
 * @returns {Object} - corresponding action
 */
export const notifySuccess = buildNotifyAction(NOTIFICATION_TYPES.SUCCESS);

/**
 * Add info notification
 * @param {String} title - It's notification title.
 * @param {String} message - It's notification message.
 * @param {Object} [options] - options for notification see https://github.com/diegoddox/react-redux-toastr.
 * @returns {Object} - corresponding action
 */
export const notifyInfo = buildNotifyAction(NOTIFICATION_TYPES.INFO);

/**
 * Add warning notification
 * @param {String} title - It's notification title.
 * @param {String} message - It's notification message.
 * @param {Object} [options] - options for notification see https://github.com/diegoddox/react-redux-toastr.
 * @returns {Object} - corresponding action
 */
export const notifyWarning = buildNotifyAction(NOTIFICATION_TYPES.WARNING);

/**
 * Add error notification
 * @param {String} title - It's notification title.
 * @param {String} message - It's notification message.
 * @param {Object} [options] - options for notification see https://github.com/diegoddox/react-redux-toastr.
 * @returns {Object} - corresponding action
 */
export const notifyError = buildNotifyAction(NOTIFICATION_TYPES.ERROR);

/**
 * Remove notifications by type
 * @param {String} type - Type notification see constants NOTIFICATION_TYPES.
 * @returns {Object} - corresponding action
 */
export const removeNotificationByType = (type) => {
    return actions.removeByType(type);
};

/**
 * Clean notifications
 * @returns {Object} - corresponding action
 */
export const cleanNotifications = () => {
    return actions.clean();
};
