import _ from 'lodash';
import { compose, mapProps } from '@shakacode/recompose';

import AnalysisTurnoverContent from './AnalysisTurnoverContent';
import { mapBarValuesToData }  from 'Components/Tables/TableEasy/utils';
import { sortArray } from 'Utils/utils';
import widgetModes from 'Constants/widget-modes';

export default compose(
    mapProps(props =>
        ({
            ...props,
            rows: sortArray({
                array: mapBarValuesToData(_.get(props, 'widgetData.values', []), 'os'),
                primaryField: 'os'
            }),
            aggregate: _.get(props, 'widgetData.aggregate'),
            hideBarChart: _.get(props, 'widgetSettings.mode') === widgetModes.TABLE
        })))(AnalysisTurnoverContent);
