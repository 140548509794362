import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import Widget from '../../Widget';
import WidgetControlPanelDefault from '../../Widget/components/WidgetControlPanelDefault';
import ShareholderInformationContent from './components/ShareholderInformationContent';

import './ShareholderInformation.scss';

class ShareholderInformation extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        bottomFieldComponent: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: props.isPrintMode ? null : WidgetControlPanelDefault,
            WidgetContent: ShareholderInformationContent,
            WidgetBottomField: props.bottomFieldComponent
        };

        this.widgetEvents = {
            onBuildDataParams: () => ({
                shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
            })
        };
    }

    render() {
        return (
            <Widget
                {...this.props}
                events={this.widgetEvents}
                className='widget-shareholder-information'
                components={this.widgetComponents}/>
        );
    }
}

export default ShareholderInformation;
