import { createSelector } from 'reselect';
import _ from 'lodash';

import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';

const MIN_COMPLETED_FILES = 1;

export const allRequestsSelector = state => state.userSecureUpload.requests;
export const isFetchingSelector = state => state.userSecureUpload.isFetching;
export const allRequestTypesSelector = state => state.userSecureUpload.requestTypes;
export const isFetchingRequestTypesSelector = state => state.userSecureUpload.isFetchingRequestTypes;
export const requestFilesSelector = state => state.userSecureUpload.requestFiles;
export const isRequestItemFetchingSelector = state => state.userSecureUpload.isRequestItemFetching;
export const requestItemSelector = state => state.userSecureUpload.requestItem;
export const requestIsSubmittedSelector = createSelector(requestItemSelector, (requestItem) => {
    if (!requestItem) {
        return false;
    }

    return requestItem.statusName === 'Submitted';
});
export const isFileUploadingSelector = state => state.userSecureUpload.isFileUploading;
export const emailRecipientsSelector = state => state.userSecureUpload.emailRecipients;
const getCompletedUploadedFilesSelector = state => state.userSecureUpload.requestFiles.filter(file => file.status === uploadedDocumentStatuses.COMPLETE);

const getSelectedRequestTypeSelector = createSelector([
    allRequestTypesSelector,
    requestItemSelector
], (requestTypes, requestItem) => {
    const requestTypeId = _.get(requestItem, 'requestTypeId');

    return _.find(requestTypes, { requestTypeId });
});

export const isValidAttachedFilesSelector = createSelector([
    getCompletedUploadedFilesSelector
], (completedFiles) => {
    const filesCount = completedFiles.length;

    return filesCount >= MIN_COMPLETED_FILES;
});

export const getRequestTypeMessage = createSelector(
    getSelectedRequestTypeSelector,
    (requestType) => _.get(requestType, 'message')
);
