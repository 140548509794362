import PropTypes from 'prop-types';
import React, { Children } from 'react';
import { injectIntl, intlShape } from 'react-intl';

const NoDataComponent = ({ isNoData, isFetching, children, message, intl: { formatMessage } }) => {
    const noDataMessage = isFetching
        ? null
        : (<div className='widget-no-data'>
            <div className='no-data-text'>
                { message || formatMessage({ id: 'widgets.noDataMessage' }) }
            </div>
        </div>);

    if (isNoData) {
        return noDataMessage;
    }

    return Children.count(children) > 1 ? <div>{children}</div> : children;
};

NoDataComponent.propTypes = {
    isFetching: PropTypes.bool,
    isNoData: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    intl: intlShape,
    message: PropTypes.string
};

export default injectIntl(NoDataComponent);
