import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const NotesHeader = props => {
    const headerMessageKey = `notes.header.${props.entityType}`;

    return (<h3 className='notes-header'><FormattedMessage id={headerMessageKey}/></h3>);
};

NotesHeader.propTypes = {
    entityType: PropTypes.string
};

export default NotesHeader;
