import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape } from 'react-intl';

import AnalysisTurnoverLegendModalView from '../components/AnalysisTurnoverLegendModalView';

class AnalysisTurnoverLegendModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        const { intl: { formatMessage } } = props;

        this.okText = formatMessage({ id: 'common.ok' });
    }

    render = () => {
        const props = {
            okText: this.okText,
            onOk: this.props.hideModal
        };

        return (
            <AnalysisTurnoverLegendModalView {...props}/>
        );
    }
}

export default AnalysisTurnoverLegendModal;
