export const CHANGE_HOLDER_INVESTOR_TYPE_ANALYSIS_ROUTE_STATE = 'CHANGE_HOLDER_INVESTOR_TYPE_ANALYSIS_ROUTE_STATE';
export const CHANGE_GEOGRAPHIC_ANALYSIS_ROUTE_STATE = 'CHANGE_GEOGRAPHIC_ANALYSIS_ROUTE_STATE';

export const changeHolderInvestorTypeAnalysisRouteState = (routeState) => (dispatch) => {
    dispatch({
        type: CHANGE_HOLDER_INVESTOR_TYPE_ANALYSIS_ROUTE_STATE,
        payload: {
            routeState
        }
    });
};

export const changeGeographicAnalysisRouteState = (routeState) => (dispatch) => {
    dispatch({
        type: CHANGE_GEOGRAPHIC_ANALYSIS_ROUTE_STATE,
        payload: {
            routeState
        }
    });
};

