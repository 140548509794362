import PropTypes from 'prop-types';
import React from 'react';

const ShareholderInformationField = ({ name, children }) => (
    <div className='field-info'>
        <span className='name'>{name}: </span>
        <span className='value'>
            {children}
        </span>
    </div>
);

ShareholderInformationField.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default ShareholderInformationField;
