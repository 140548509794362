import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pure, withState } from '@shakacode/recompose';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import Selector from 'Components/Selector';
import { CheckBox } from 'Components/CheckBox';
import TextInput from '../../TextInput';
import { US } from 'Constants/countries';
import { getAllCountriesList, getStatesList } from 'State/countriesAndStates/actions';
import { getCountryList, getStateList, isFetchingSelector } from 'State/countriesAndStates/selectors';

const mapStateToProps = (state) => ({
    countriesList: getCountryList(state),
    statesList: getStateList(state),
    isFetching: isFetchingSelector(state)
});
const mapDispatchToProps = { getAllCountriesList, getStatesList };

@injectIntl
@connect(
    mapStateToProps,
    mapDispatchToProps
)
@withState(
    'isZipDisabled',
    'disableZip',
    true
)
@pure
class ShareholderLocationField extends Component {
    static propTypes = {
        getAllCountriesList: PropTypes.func.isRequired,
        getStatesList: PropTypes.func.isRequired,
        disableZip: PropTypes.func,
        shareholderLocation: PropTypes.object,
        options: PropTypes.object,
        countriesList: PropTypes.array,
        statesList: PropTypes.object,
        isFetching: PropTypes.bool,
        isZipDisabled: PropTypes.bool,
        intl: intlShape
    };

    componentDidMount() {
        this.props.getAllCountriesList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newCountryId = nextProps.shareholderLocation.country.input.value;
        const oldCountryId = this.props.shareholderLocation.country.input.value;

        if (oldCountryId !== newCountryId) {
            const newCountry = this.props.countriesList.find(c => c.value === newCountryId);
            const isUSA = newCountry ? newCountry.value === US : false;

            if (newCountryId && !nextProps.statesList[newCountryId]) {
                this.props.getStatesList(newCountryId);
            }

            if (isUSA === this.props.isZipDisabled) {
                this.props.disableZip(!isUSA);
            }
        }
    }

    handleCheckboxChange = (e) => {
        const { shareholderLocation: { country: countryField, state: stateField, zipCode: zipField, showForeign: checkboxField } } = this.props;
        const checked = e.target.checked;

        if (checked) {
            countryField.input.onChange(null);
            stateField.input.onChange(null);
            zipField.input.onChange('');
        }
        checkboxField.input.onChange(checked);
    };

    handleCountryChange = (e) => {
        const { shareholderLocation: { country: countryField, state: stateField, zipCode: zipField } } = this.props;

        stateField.input.onChange(null);
        zipField.input.onChange('');
        countryField.input.onChange(e.value);
    };

    getOptions = (options) => {
        const allOption = {
            value: '',
            label: this.props.intl.formatMessage({ id: 'shareholder.list.location.all' })
        };

        if (!options) {
            return [allOption];
        }

        return [allOption, ...options];
    };

    render() {
        const {
            shareholderLocation: {
                country: countryField,
                showForeign: checkboxField,
                state: stateField,
                zipCode: zipField
            },
            options: {
                labels: {
                    country: countryTitle,
                    state: stateTitle,
                    zip: zipTitle,
                    showForeign: checkboxTitle
                }
            },
            countriesList,
            statesList,
            isFetching,
            isZipDisabled
        } = this.props;

        const isDisabled = checkboxField.input.value;

        return (
            <div className='location-container'>
                <div className='location-row'>
                    <div className='location-cell select-container'>
                        <label className='location-label'>{countryTitle}</label>
                        <Selector
                            value={countryField.input.value}
                            name={countryField.input.name}
                            options={this.getOptions(countriesList)}
                            wrapperClass='location-select-wrapper countries-selector'
                            clearable={false}
                            matchPos='start'
                            matchProp='label'
                            autosize={false}
                            isLoading={isFetching}
                            disabled={isDisabled || isFetching}
                            placeholder=' '
                            onOptionChange={this.handleCountryChange}/>
                    </div>
                    <CheckBox
                        {...checkboxField.input}
                        data-type='foreign-checkbox-input'
                        className='location-cell checkbox-container foreign-checkbox'
                        onChange={this.handleCheckboxChange}
                        label={checkboxTitle}
                        checked={checkboxField.input.value}/>
                </div>
                <div className='location-row'>
                    <div className='location-cell select-container'>
                        <label className='location-label'>{stateTitle}</label>
                        <Selector
                            value={stateField.input.value}
                            name={stateField.input.name}
                            options={this.getOptions(statesList[countryField.input.value])}
                            wrapperClass='location-select-wrapper states-selector'
                            clearable={false}
                            matchPos='start'
                            matchProp='label'
                            autosize={false}
                            isLoading={isFetching}
                            disabled={isDisabled || isFetching}
                            placeholder=' '
                            onOptionChange={e => stateField.input.onChange(e.value)}/>
                    </div>
                    <div className='location-cell text-container zip-code'>
                        <TextInput
                            {...zipField.input}
                            label={zipTitle}
                            className='location-text-input-container'
                            error={!zipField.meta.active && zipField.meta.error}
                            disabled={isDisabled || isZipDisabled}/>
                    </div>
                </div>
            </div>
        );
    }
}

ShareholderLocationField.parse = (value, name) => {
    if (name === 'shareholderLocation.zipCode') {
        const regExp = /^[0-9]{0,5}-?[0-9]{0,7}$/;

        if (value.length) {
            return regExp.test(value) ? value : undefined;
        }

        return null;
    }

    if (name === 'shareholderLocation.country' || name === 'shareholderLocation.state') {
        if (_.isUndefined(value)) return;

        return value || null;
    }
    return value;
};

export default ShareholderLocationField;
