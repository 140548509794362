import _ from 'lodash';

import * as api from '../../api';
import { getUserNotifications } from '../user/actions';

export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_FAILURE = 'GET_ALERTS_FAILURE';
export const SET_ALERTS_FETCHING = 'SET_ALERTS_FETCHING';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const DISMISS_ALERT = 'DISMISS_ALERT';

const setAlertsFetching = isFetching => ({
    type: SET_ALERTS_FETCHING,
    payload: isFetching
});

export const getAlerts = () => dispatch => {
    dispatch(setAlertsFetching(true));

    return api.getAlerts()
        .then(res => {
            const alerts = _.get(res, 'data.payload.alerts');

            dispatch({
                type: GET_ALERTS_SUCCESS,
                payload: alerts
            });

            return Promise.resolve(alerts);
        }, err => {
            dispatch({
                type: GET_ALERTS_FAILURE,
                payload: err
            });

            return Promise.reject(err);
        })
        .finally(() => dispatch(setAlertsFetching(false)));
};

export const dismissAlert = alertId => dispatch => {
    dispatch({
        type: DISMISS_ALERT,
        payload: alertId
    });

    return api.dismissAlert(alertId).then(() => {
        dispatch(getUserNotifications());
    });
};

export const clearAlerts = () => ({
    type: CLEAR_ALERTS
});
