import React from 'react';
import PropTypes from 'prop-types';

export const UploadRequestTemplate = ({ onClick, children }) => (
    <div className='upload-request-templates__document'>
        <span onClick={onClick}>{children}</span>
    </div>
);

UploadRequestTemplate.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any
};
