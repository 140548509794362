import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import FormErrorSummary from '../../FormErrorSummary';
import { CheckBoxesListField, CheckBoxesListFieldByColumns } from 'Components/CheckBoxesList';

import './CommonColumnsManagerView.scss';

const CommonColumnsManagerView = (props) => {
    const {
        viewText,
        cancelText,
        onCancel,
        onSuccess,
        columns,
        error,
        isValid,
        rows,
        modalClassName
    } = props;

    return (
        <div className={cn('common-columns-manager-view', modalClassName)}>
            {rows
                ? <CheckBoxesListFieldByColumns
                    position='block'
                    className='checkbox-list'
                    list={columns}
                    rows={rows}/>
                : <CheckBoxesListField
                    position='block'
                    className='checkbox-list'
                    list={columns}/>}
            <div className='btn-panel'>
                <div className='validate-block'>
                    {
                        isValid
                            ? null
                            : <FormErrorSummary error={error}/>
                    }
                </div>
                <button
                    type='submit'
                    className='btn btn-large'
                    onClick={onSuccess}
                    disabled={!isValid}>
                    {viewText}
                </button>
                <button
                    type='button'
                    className='btn btn-large'
                    onClick={onCancel}>
                    {cancelText}
                </button>
            </div>
        </div>
    );
};

CommonColumnsManagerView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    viewText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    isValid: PropTypes.bool.isRequired,
    error: PropTypes.string,
    rows: PropTypes.number,
    modalClassName: PropTypes.string
};

export default CommonColumnsManagerView;
