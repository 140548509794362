import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { List } from 'react-virtualized';
import _ from 'lodash';

import { securityFullNameFormatter } from 'Components/formatters/formatters';
import { withPropsOnChange } from '@shakacode/recompose';

@withPropsOnChange(['issuers', 'securities'],
    ({ issuers, securities }) => {
        let rows = [];

        issuers.forEach(issuer => {
            const issuerSec = _.filter(securities, sec => sec.issuerId === issuer.id);

            if (!!issuerSec.length) {
                rows = [
                    ...rows,
                    { isIssuer: true, data: issuer },
                    ...issuerSec.map(security => {
                        return {
                            isIssuer: false,
                            data: security
                        };
                    })
                ];
            }
        });

        return { rows };
    })
class SecurityListView extends Component {
    static propTypes = {
        securities: PropTypes.array.isRequired,
        issuers: PropTypes.array.isRequired,
        rows: PropTypes.array,
        showAllSecurities: PropTypes.bool.isRequired,
        onSelectedSecurity: PropTypes.func.isRequired,
        selectSecurityId: PropTypes.any,
        rowHeight: PropTypes.number.isRequired,
        visibleRowCount: PropTypes.number.isRequired
    };

    componentDidUpdate() {
        this.ref && this.ref.forceUpdateGrid();
    }

    handleSecurityClick = (security) => {
        return () => {
            this.props.onSelectedSecurity(security);
        };
    };

    setRef = (ref) => {
        this.ref = ref;
    };

    securityRowRenderer = (
        {
            key,         // Unique key within array of rows
            index,       // Index of row within collection
            style        // Style object to be applied to row (to position it)
        }) => {
        const { securities, selectSecurityId } = this.props;
        const security = securities[index];

        return this.renderSecurity(security, selectSecurityId, style, key);
    };

    securityIssuersRowRenderer = (
        {
            key,         // Unique key within array of rows
            index,       // Index of row within collection
            style        // Style object to be applied to row (to position it)
        }) => {
        const { selectSecurityId, rows } = this.props;
        const row = rows[index];

        if (row.isIssuer) {
            return (<div
                className='list-item issuer-title'
                style={style}
                key={key}>{row.data.companyName}</div>);
        }

        const security = row.data;

        return this.renderSecurity(security, selectSecurityId, style, key);
    };

    renderSecurity(security, selectSecurityId, style, key) {
        const securityTitle = securityFullNameFormatter(security.name, security.ticker, security.cusip, security.companyNumber);

        return (
            <div
                onClick={this.handleSecurityClick(security)}
                className={classNames('list-item', {
                    'list-item-selected': security.id === selectSecurityId,
                    'active': security.id !== selectSecurityId
                })}
                style={style}
                data-security-id={security.id}
                key={key}>
                <span title={securityTitle} className='list-item-text'>
                    {securityTitle}
                </span>
            </div>
        );
    }

    render() {
        const { securities, rows, rowHeight, visibleRowCount, showAllSecurities } = this.props;
        const rowCount = showAllSecurities
            // titles with securities
            ? rows.length
            : securities.length;

        return (
            <List
                className='securities-list list'
                height={visibleRowCount * rowHeight}
                ref={this.setRef}
                rowCount={rowCount}
                rowHeight={rowHeight}
                rowRenderer={showAllSecurities ? this.securityIssuersRowRenderer : this.securityRowRenderer}
                width={650}/>
        );
    }
}

export default SecurityListView;
