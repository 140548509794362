import PropTypes from 'prop-types';
import React from 'react';

import NullableFormatter from './NullableFormatter';
import { fileSizeFormatter } from './formatters';

const FileSizeFormatter = (props) => {
    const { decimalPoints } = props;

    return (
        <NullableFormatter value={props.value}>
            {({ value }) => {
                const formattedValue = fileSizeFormatter(value, decimalPoints);

                return (<span>{formattedValue}</span>);
            }}
        </NullableFormatter>
    );
};

FileSizeFormatter.propTypes = {
    value: PropTypes.number,
    decimalPoints: PropTypes.number
};

FileSizeFormatter.defaultProps = {
    decimalPoints: 2
};

export default FileSizeFormatter;
