import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'Components/Tooltip';

import './Popup.scss';

export default class Popup extends Component {
    static propTypes = {
        content: PropTypes.any.isRequired,
        children: PropTypes.any.isRequired
    };

    static defaultProps = {
        theme: 'ast-popup',
        delay: [0, 0],
        arrow: true,
        popperOptions: {
            modifiers: {
                preventOverflow: {
                    enabled: false
                },
                hide: {
                    enabled: false
                }
            }
        }
    };

    render() {
        return (<Tooltip
            {...this.props}>
            {this.props.children}
        </Tooltip>);
    }
}
