import React from 'react';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import AnalysisDataVizControlPanel from './AnalysisDataVizControlPanel';
import AnalysisRegularControlPanel from './AnalysisRegularControlPanel';

const AnalysisTableControlPanel = ({
    isDataViz,
    ...rest
}) => {
    const { onExport, inactive } = _.get(rest, 'widgetControlPanelSettings', {});
    const { onRemoveWidget, onAddWidgetToDataVizReport, showInfoModal, widgetTitle, currentFeatures } = rest;
    const onImpossibleAddToReport = () => {
        showInfoModal({
            messageKey: 'peer.historical.analysis.table.impossibleAddToReport',
            messageValues: {
                widget: widgetTitle
            }
        });
    };
    const props = {
        onExport,
        inactive,
        onRemoveWidget,
        onAddWidgetToDataVizReport,
        onImpossibleAddToReport,
        currentFeatures
    };

    return (
        isDataViz ?
            <AnalysisDataVizControlPanel {...props}/> :
            <AnalysisRegularControlPanel {...props} />
    );
};

AnalysisTableControlPanel.propTypes = {
    ...commonWidgetActions,
    ...commonWidgetPropTypes
};

export default AnalysisTableControlPanel;
