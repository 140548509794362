import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import InsiderOverviewPageHeaderView from '../components/InsiderOverviewPageHeaderView';

const mapStateToProps = state => ({
    info: dynamicPageSelectors.getDynamicPageInfoSelector(state),
    isFetching: dynamicPageSelectors.getDynamicPageFetchingSelector(state)
});

@withRouter
@injectIntl
@connect(mapStateToProps)
class InsiderOverviewPageHeader extends Component {
    static propTypes = {
        info: PropTypes.object,
        intl: intlShape,
        location: PropTypes.object,
        isFetching: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.formatMessage = props.intl.formatMessage;
    }

    render() {
        const { info, isFetching } = this.props;

        return info ?
            <InsiderOverviewPageHeaderView pageInfo={info} isFetching={isFetching}/>
            : null;
    }
}

export default InsiderOverviewPageHeader;
