import PropTypes from 'prop-types';
import React from 'react';

const positionRenderer = ({
    value
}) => {
    if (typeof value === 'object' && value.isAggregate) {
        return <span className='aggregate-cell'>{value.name}</span>;
    }

    return <span className='position-cell'>{value}</span>;
};

positionRenderer.propTypes = {
    value: PropTypes.any,
    columnData: PropTypes.any,
    dataKey: PropTypes.string,
    rowIndex: PropTypes.number
};

export default positionRenderer;
