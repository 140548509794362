import React, { useMemo } from 'react';
import _ from 'lodash';
import { compose } from '@shakacode/recompose';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropsTypes from 'prop-types';

import ScrollableReactTable from 'Components/Tables/ScrollableReactTable';
import { NoResults, TheadComponent, TdComponent } from 'Components/Tables/components/ReactTableComponents';
import { getColumnsMinWidth } from 'Utils/utils';
import {
    getCustomComponent,
    getTdProps
} from 'Components/widgets/ShareholdersTable/utils';
import columnWidth from 'Components/widgets/ShareholdersTable/constants/columnsWidth';
import { getWidgetDataSourceIdSelector } from 'State/widgets';
import NullComponent from 'Components/widgets/Widget/components/NullComponent';
import sortDirections from 'Constants/sort-directions';
import { getSortDirection } from 'Components/Tables/utils';
import columnTypes from 'Components/widgets/ShareholdersTable/constants/shareholder-column-types';
import Expander from 'Components/widgets/ShareholdersTable/components/ShareholderContent/Expander';
import { CellWithUndefinedTooltip } from 'Components/widgets/_commonComponents/TableCellComponents';
import shareholderColumnsNames from 'Constants/shareholders-columns-names';
import { columnDefinitions } from '../../columnDefinitions';
import useSortedShareholders from '../../hooks/useSortedShareholders';
import useSortCriteria from '../../hooks/useSortCriteria';
import useCurrentWatchlistId from '../../hooks/useCurrentWatchlistId';
import useAvailableShareholderTypes from '../../hooks/useAvailableShareholderTypes';
import DeleteCell from '../DeleteCell';

import './WatchlistContent.scss';

const WatchlistContent = ({
    userProductSources,
    currentFeatures,
    intl,
    isPrintMode,
    widgetData,
    widgetSettings,
    onUpdateWidgetSettings,
    widgetDataSourceId,
    availableColumns
}) => {
    const { availableDisplayTypes, availableShareholderTypesList } = useAvailableShareholderTypes({ userProductSources, userFeatures: currentFeatures, widgetSettings });
    const sortCriteria = useSortCriteria(widgetSettings);
    const watchlistId = useCurrentWatchlistId(widgetSettings);

    const sortedValues = useSortedShareholders({ widgetData, sortCriteria, availableDisplayTypes });

    const columns = useMemo(() => {
        const { formatMessage } = intl;

        const sortedColumns = availableColumns
            .map(column => {
                const Components = getCustomComponent(column.columnType);
                const minWidth = columnWidth[column.columnType] || columnWidth.default;

                return {
                    // eslint-disable-next-line react/no-multi-comp
                    Header: () => (
                        <Components.customHeadingComponent
                            title={formatMessage({ id: column.displayName })}/>
                    ),
                    // eslint-disable-next-line react/no-multi-comp
                    Cell: (props) => (
                        <CellWithUndefinedTooltip {...props} block={column.columnType !== shareholderColumnsNames.SHAREHOLDER_NAME}>
                            {/* eslint-disable-next-line react/prop-types */}
                            {({ value, additionalData }) => (<Components.customComponent value={value} additionalData={additionalData} />)}
                        </CellWithUndefinedTooltip>
                    ),
                    accessor: column.columnType,
                    minWidth: isPrintMode
                        ? minWidth
                        : getColumnsMinWidth(sortedValues, column, minWidth, intl)
                };
            });

        return [
            {
                expander: true,
                sortable: false,
                headerClassName: 'expander',
                width: columnWidth.watchlistExpander,
                // eslint-disable-next-line react/no-multi-comp
                Expander: (props) => (<Expander {...props} expandedShareholderIds={[]}/>)
            },
            ...sortedColumns,
            {
                id: 'delete',
                width: columnWidth.expander,
                Cell: DeleteCell,
                getProps: () => ({ watchlistId, widgetDataSourceId }),
                headerClassName: 'watchlist-table__delete-column',
                className: 'watchlist-table__delete-cell',
                sortable: false
            }
        ];
    }, [sortedValues, intl, availableColumns, watchlistId]);


    const handleSort = ([sortProperties]) => {
        const { sortField, sortDirection } = sortCriteria;
        const selectedSortField = sortProperties.id;
        const newSortCriteria = {
            sortDirection: getSortDirection(selectedSortField, sortField, sortDirection, columnDefinitions),
            sortField: selectedSortField,
            initial: false
        };

        onUpdateWidgetSettings({
            ...widgetSettings,
            viewsSortCriteria: {
                ...widgetSettings.viewsSortCriteria,
                [widgetSettings.currentWatchlistId]: newSortCriteria
            }
        });
    };

    const getFilterOptions = () => {
        return availableShareholderTypesList
            .map((type) => {
                return {
                    name: type,
                    label: intl.formatMessage({ id: `shareholders.type.${type.toLowerCase()}` }),
                    isSelected: availableDisplayTypes.some((displayType) => {
                        if (!_.isString(displayType) || !_.isString(type)) return false;

                        return displayType.toUpperCase() === type.toUpperCase();
                    })
                };
            });
    };

    const handleFiltration = (selected) => {
        const settings = { ...widgetSettings };
        const displayTypes = selected.filter(option => option.isSelected).map((type) => type.name);

        if (!_.isEqual((availableDisplayTypes).sort(), displayTypes.sort())) {
            settings.views[widgetSettings.currentWatchlistId] = {
                ...settings.views[widgetSettings.currentWatchlistId],
                displayTypes
            };
            onUpdateWidgetSettings(settings);
        }
    };

    const getTheadThProps = (finalState, missProp, column) => {
        const { sortField, sortDirection } = sortCriteria;
        const defaultProps = {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };

        switch (column.id) {
            case columnTypes.SHAREHOLDER_NAME:
                return {
                    onFiltration: handleFiltration,
                    filterOptions: getFilterOptions(),
                    tooltipTextId: 'watchlist.table.headingWithTopAccounts.tooltip',
                    tooltipClassName: 'watchlist-top-accounts-tooltip',
                    ...defaultProps
                };
            default:
                return defaultProps;
        }
    };

    const getTableTdProps = (finalState, rowInfo, column) => {
        return {
            additionalData: getTdProps(column.id, rowInfo.row, {
                features: currentFeatures,
                productSources: userProductSources,
                sortCriteria
            })
        };
    };

    const tableClassName = cn('watchlist-table scroll-handle', { 'without-scroll': isPrintMode });

    return (
        <div className='watchlist-widget__content'>
            <ScrollableReactTable
                showPagination={false}
                defaultPageSize={0}
                resizable={false}
                manual
                resolvedData={sortedValues}
                columns={columns}
                NoDataComponent={NoResults}
                TdComponent={TdComponent}
                ThComponent={TheadComponent}
                LoadingComponent={NullComponent}
                className={tableClassName}
                onSortedChange={handleSort}
                getTheadThProps={getTheadThProps}
                getTdProps={getTableTdProps}
                getTrProps={(_state, { row: { accessible } }) => ({
                    className: accessible === false ? '-inaccessible' : ''
                })}/>
        </div>
    );
};

WatchlistContent.propTypes = {
    userProductSources: PropsTypes.array,
    currentFeatures: PropsTypes.object,
    intl: intlShape,
    isPrintMode: PropsTypes.bool,
    widgetData: PropsTypes.arrayOf(PropsTypes.shape({
        values: PropsTypes.array
    })),
    widgetSettings: PropsTypes.object,
    onUpdateWidgetSettings: PropsTypes.func,
    widgetDataSourceId: PropsTypes.string,
    availableColumns: PropsTypes.array
};

const mapStateToProps = (state, props) => ({
    widgetDataSourceId: getWidgetDataSourceIdSelector(state, props.widgetName)
});

export default compose(
    connect(mapStateToProps, {}),
    injectIntl,
)(WatchlistContent);
