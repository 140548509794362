import _ from 'lodash';

import * as exportReport from '../../api/exportReport';
import * as utils from './utils';
import exportStatuses from 'Constants/export-statuses';
import exportFormats from 'Constants/export-report-formats';
import dpfExportColumnsLimits from 'Constants/pdf-export-columns-limits';
import httpStatus from 'Constants/http-status';
import errorReasons from 'Constants/error-reasons';
import { exportReportEvent, downloadReportEvent, exportInstantReportEvent } from '../../__ga__/events';
import { setInProgress, removeInProgress } from 'Utils/utils';

export const REPORTING_GET_LAUNCHED_REPORTS_REQUEST = 'REPORTING_GET_LAUNCHED_REPORTS_REQUEST';
export const REPORTING_GET_LAUNCHED_REPORTS_SUCCESS = 'REPORTING_GET_LAUNCHED_REPORTS_SUCCESS';
export const REPORTING_GET_LAUNCHED_REPORTS_ERROR = 'REPORTING_GET_LAUNCHED_REPORTS_ERROR';
export const REPORTING_DELETE_REPORT_REQUEST = 'REPORTING_DELETE_REPORT_REQUEST';
export const REPORTING_DELETE_REPORT_SUCCESS = 'REPORTING_DELETE_REPORT_SUCCESS';
export const REPORTING_DELETE_REPORT_ERROR = 'REPORTING_DELETE_REPORT_ERROR';
export const REPORTING_LAUNCH_REPORT_REQUEST = 'REPORTING_LAUNCH_REPORT_REQUEST';
export const REPORTING_LAUNCH_REPORT_SUCCESS = 'REPORTING_LAUNCH_REPORT_SUCCESS';
export const REPORTING_LAUNCH_REPORT_ERROR = 'REPORTING_LAUNCH_REPORT_ERROR';

const GET_REPORT_STATUS_TIMEOUT = 3000;

const reportingLaunchReportRequest = (actionType, isFetching, format) => ({
    type: actionType,
    payload: { isFetching, format }
});

const getExportProcess = (res, dispatch, reportTemplateType) => ({
    [exportStatuses.PENDING]: (securityId) => {
        exportReportEvent(reportTemplateType);
        dispatch(utils.showExportPendingModal(securityId));
        dispatch({
            type: REPORTING_LAUNCH_REPORT_SUCCESS,
            payload: _.get(res, 'data.payload.report')
        });
    },
    [exportStatuses.NO_RESULTS]: () => {
        exportReportEvent(reportTemplateType);
        dispatch(utils.showExportNoResultsModal());
    },
    [exportStatuses.READY]: () => {
        exportInstantReportEvent(reportTemplateType);
        const reportGuid = _.get(res, 'data.payload.reportGuid');

        utils.downloadLaunchedReportByGuid(dispatch, reportGuid);
    },
    [exportStatuses.FAILED]: () => {
        dispatch(utils.showReportFailedModal());
    }
});

export const showExportTemplateModal = ({ reportTemplateType, reportTemplateId, form = null, modalViewParams }) => dispatch => {
    return dispatch(utils.showExportReportModal({
        titleKey: 'modals.exportReport.title',
        onSuccess: (format) => {
            const isExistingAndLimited = !!reportTemplateId && _.has(dpfExportColumnsLimits, reportTemplateType);

            if (format === exportFormats.PDF && isExistingAndLimited && form) {
                const columnsLimit = dpfExportColumnsLimits[reportTemplateType];

                if (columnsLimit && form.columnsToDisplay && form.columnsToDisplay.length > columnsLimit) {
                    return dispatch(utils.showConfirmExportLargeReportModal(() =>
                        dispatch(runReport(reportTemplateType, reportTemplateId, format, form))
                    ));
                }
            }

            return dispatch(runReport(reportTemplateType, reportTemplateId, format, form));
        },
        modalViewParams
    }));
};

export const runReport = (reportTemplateType, reportTemplateId, format, form = null, securityId = null) => dispatch => {
    dispatch(reportingLaunchReportRequest(REPORTING_LAUNCH_REPORT_REQUEST, true, format));

    return exportReport.runReport(reportTemplateType, reportTemplateId, format, form)
        .then(
            res => {
                let exportStatus = _.get(res, 'data.payload.exportStatus');
                let exportProcessByStatus = getExportProcess(res, dispatch, reportTemplateType)[exportStatus];

                return new Promise((resolve, reject) => {
                    if (_.isFunction(exportProcessByStatus)) {
                        dispatch(reportingLaunchReportRequest(REPORTING_LAUNCH_REPORT_REQUEST, false));
                        exportProcessByStatus(securityId);
                        resolve();
                    } else if (exportStatus === exportStatuses.INSTANT) {
                        const reportId = _.get(res, 'data.payload.report.reportId');

                        setInProgress();
                        setTimeout(() => {
                            exportReport.getReportStatus(reportId)
                                .then(resReportStatus => {
                                    exportStatus = _.get(resReportStatus, 'data.payload.exportStatus');
                                    exportProcessByStatus = getExportProcess(resReportStatus, dispatch, reportTemplateType)[exportStatus];
                                    removeInProgress();

                                    dispatch(reportingLaunchReportRequest(REPORTING_LAUNCH_REPORT_REQUEST, false));
                                    if (_.isFunction(exportProcessByStatus)) {
                                        exportProcessByStatus(securityId);
                                    }
                                }, reject);
                        }, GET_REPORT_STATUS_TIMEOUT);
                    }
                });
            }
        )
        .catch(error => {
            const errorStatus = _.get(error, 'response.status');
            const errorReason = _.get(error, 'response.data.reasonType');

            dispatch(reportingLaunchReportRequest(REPORTING_LAUNCH_REPORT_REQUEST, false));
            dispatch({
                type: REPORTING_LAUNCH_REPORT_ERROR,
                payload: { error },
                error: true
            });
            removeInProgress();

            if (errorStatus === httpStatus.HTTP_403 && errorReason === errorReasons.SECURITY_ACCESS_ERROR) {
                dispatch(utils.showReportWithForbiddenSecurityModal());
            } else {
                dispatch(utils.showReportFailedModal());
            }

            return Promise.reject(error);
        });
};

export const downloadLaunchedReport = (reportId, subscriptionId, reportType, description, runDate) => dispatch =>
    exportReport.getLaunchedReportGuid(reportId, subscriptionId)
        .then(res => {
            const reportGuid = _.get(res, 'data.payload.reportGuid');

            utils.downloadLaunchedReportByGuid(dispatch, reportGuid);

            downloadReportEvent(reportType, description, runDate);
        })
        .catch(() => {
            dispatch(utils.showReportFailedModal());
        });

export const getLaunchedReportsPolling = () => (dispatch, getState) => {
    return exportReport.getLaunchedReports()
        .then(res => {
            const state = getState();
            const reports = _.get(res.data, 'payload.reports', []);

            if (!_.isEqual(state.exportedReports.reports, reports)) {
                dispatch({
                    type: REPORTING_GET_LAUNCHED_REPORTS_SUCCESS,
                    payload: {
                        reports
                    }
                });
            }
        });
};

export const getLaunchedReports = () => dispatch => {
    dispatch(reportingLaunchReportRequest(REPORTING_GET_LAUNCHED_REPORTS_REQUEST, true));

    return exportReport.getLaunchedReports()
        .then(res => {
            dispatch(reportingLaunchReportRequest(REPORTING_GET_LAUNCHED_REPORTS_REQUEST, false));
            dispatch({
                type: REPORTING_GET_LAUNCHED_REPORTS_SUCCESS,
                payload: {
                    reports: _.get(res.data, 'payload.reports', [])
                }
            });
        })
        .catch(error => {
            dispatch(reportingLaunchReportRequest(REPORTING_GET_LAUNCHED_REPORTS_REQUEST, false));
            dispatch({
                type: REPORTING_GET_LAUNCHED_REPORTS_ERROR,
                payload: { error },
                error: true
            });

            return Promise.reject(error);
        });
};

export const deleteLaunchedReport = (reportId, reportType) => dispatch => {
    dispatch({
        type: REPORTING_DELETE_REPORT_REQUEST,
        payload: { reportId }
    });

    return exportReport.deleteLaunchedReport(reportId, reportType)
        .then(res => {
            const payload = _.get(res.data, 'payload');

            if (payload && payload.isDeleted) {
                dispatch({
                    type: REPORTING_DELETE_REPORT_SUCCESS,
                    payload: { reportId: payload.reportId }
                });
            }
        })
        .catch(error => {
            dispatch({
                type: REPORTING_DELETE_REPORT_ERROR,
                payload: { error },
                error: true
            });

            return Promise.reject(error);
        });
};

export const showExportWidgetModal = ({
    titleKey = 'modals.exportReport.title',
    reportTemplateType,
    exportParams,
    modalViewParams,
    securityId
}) => dispatch => {
    dispatch(utils.showExportReportModal({
        modalViewParams,
        titleKey,
        onSuccess: (format) => dispatch(runReport(reportTemplateType, null, format, exportParams, securityId))
    }));
};

export const showExportRegisteredOverviewModal = ({
    titleKey = 'modals.exportReport.title',
    exportParams,
    reportTemplateType,
    securityId
}) => dispatch => {
    dispatch(utils.showExportRegisteredReportModal({
        titleKey,
        exportParams,
        onSuccess: (format, params) => dispatch(runReport(reportTemplateType, null, format, params, securityId))
    }));
};

export const runAsOfDateReport = (isSummary, asOfDate) => dispatch => {
    dispatch(reportingLaunchReportRequest(REPORTING_LAUNCH_REPORT_REQUEST, true));
    setInProgress();

    return exportReport.runAsOfDateReport(isSummary, asOfDate)
        .then(() => {
            exportReportEvent('classicShareholderListAsOfDateTemplate');
            dispatch(utils.showReportRequestedModal(isSummary));
            dispatch(reportingLaunchReportRequest(REPORTING_LAUNCH_REPORT_REQUEST, false));
            removeInProgress();
        })
        .catch(error => {
            const errorStatus = _.get(error, 'response.status');
            const errorReason = _.get(error, 'response.data.reasonType');

            dispatch(reportingLaunchReportRequest(REPORTING_LAUNCH_REPORT_REQUEST, false));
            dispatch({
                type: REPORTING_LAUNCH_REPORT_ERROR,
                payload: { error },
                error: true
            });
            removeInProgress();

            if (errorStatus === httpStatus.HTTP_403 && errorReason === errorReasons.SECURITY_ACCESS_ERROR) {
                dispatch(utils.showReportWithForbiddenSecurityModal());
            } else {
                dispatch(utils.showReportFailedModal());
            }

            return Promise.reject(error);
        });
};
