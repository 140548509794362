import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { getContext, mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';

import { commonWidgetPropTypes, commonWidgetActions, isCurrentWidgetType } from '../../utils';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import widgetTypes from 'Constants/widget-types';
import WidgetNavigationPanel from '../../_commonComponents/WidgetNavigationPanel';

const makeMapStateToProps = (initialState, { slotId }) => {
    const availableWidgetTypesSelector = dynamicPageSelectors.makeAvailableWidgetTypesSelector(slotId);

    return (state) => ({
        availableWidgets: availableWidgetTypesSelector(state, slotId)
    });
};

@connect(makeMapStateToProps)
@getContext({
    events: PropTypes.object.isRequired
})
@mapProps(props => ({
    ...props
}))
class InstitutionalShareholdersActionPanel extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        events: PropTypes.object
    };

    render() {
        const { intl, onChangeWidget, availableWidgets, widgetName } = this.props;

        const navigationPanelProps = {
            widgets:[
                {
                    displayName: intl.formatMessage({ id: 'active.vs.passive.name' }),
                    widgetType: widgetTypes.activeVsPassive,
                    isCurrent: isCurrentWidgetType(widgetTypes.activeVsPassive, widgetName)
                },
                {
                    displayName: intl.formatMessage({ id: 'activity.trend.name' }),
                    widgetType: widgetTypes.activityTrend,
                    isCurrent: isCurrentWidgetType(widgetTypes.activityTrend, widgetName)
                }
            ],
            availableWidgets,
            onSelect: onChangeWidget
        };

        return (
            <WidgetNavigationPanel {...navigationPanelProps}/>
        );
    }
}

export default injectIntl(InstitutionalShareholdersActionPanel);
