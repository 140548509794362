import React from 'react';
import PropTypes from 'prop-types';
import MeetingPlannerTrackerDocument from './MeetingPlannerTrackerDocument';

const MeetingPlannerTrackerDocumentList = ({ documentGroups }) => {
    return (
        <div className='meeting-planner-tracker__document-list'>
            {documentGroups.map(({ title, documents }) => (
                <div className='meeting-planner-tracker__document-group' key={title}>
                    <p className='meeting-planner-tracker__document-group-header'>{title}</p>
                    {documents.map((document) => <MeetingPlannerTrackerDocument {...document} key={document.id} />)}
                </div>
            ))}
        </div>
    );
};

MeetingPlannerTrackerDocumentList.propTypes = {
    documentGroups: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        documents: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired
            }).isRequired
        ).isRequired
    }).isRequired)
};

export default MeetingPlannerTrackerDocumentList;
