import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DynamicPage } from 'Components/DynamicPage';
import layoutTypes from 'Constants/layout-types';
import pageTypes from 'Constants/dynamic-page-types';

import '../../reporting/ReportingDataVizReportPage/ReportingDataVizReportPage.scss';
import './DataVizExportPage.scss';

const DataVizExportPageView = ({ reportTemplateId, dynamicPageSettings }) => {
    return (
        <div className='reporting-dataviz-report-page dataviz-export-page'>
            <h2 className='reporting-header'>
                <FormattedMessage id='reporting.dataVizReport.title'/>
            </h2>
            <DynamicPage
                layoutType={layoutTypes.layoutDataViz}
                params={{ reportTemplateId }}
                settings={dynamicPageSettings}
                dynamicPageType={pageTypes.REPORTING_DATAVIZ_REPORT}/>
        </div>
    );
};

DataVizExportPageView.propTypes = {
    reportTemplateId: PropTypes.number,
    dynamicPageSettings: PropTypes.object.isRequired
};

export default DataVizExportPageView;
