import { createReducer } from 'Utils/reducer';
import { SHOW_LOADING_BLOCK, HIDE_LOADING_BLOCK } from './actions';

const initialState = {
    queueOfRequests: 0
};

const actionHandlers = {
    [SHOW_LOADING_BLOCK]: (state) => ({
        ...state,
        queueOfRequests: state.queueOfRequests + 1
    }),
    [HIDE_LOADING_BLOCK]: (state) => ({
        ...state,
        queueOfRequests: state.queueOfRequests - 1
    })
};

export default createReducer(initialState, actionHandlers);
