import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import formatterConstants from 'Constants/formatter-options';
import { NullableFormatterWithIntl } from './NullableFormatter';
import { changeFormatter, scaleFormatter, symbolFormatter, thresholdFormatter } from './formatters';

const MktValueFormatter = (props) => {
    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, negClass, intl }) => {
                let formattedValue = value;

                const scaledValue = scaleFormatter(formattedValue, formatterConstants.MM_SCALE);

                formattedValue = thresholdFormatter(scaledValue, formatterConstants.MKT_VAL_THRESHOLD);
                const isValueThresholded = formattedValue !== scaledValue;

                formattedValue = changeFormatter(intl, formattedValue, {
                    decimalPoints: props.decimalPoints
                });
                let title;

                if (isValueThresholded) {
                    formattedValue = `<${formattedValue}`;
                    title = `${scaledValue}`;
                }

                if (props.withTitle) {
                    title = `${scaledValue}`;
                }

                formattedValue = symbolFormatter(formattedValue, props.currency);

                return (<span title={title} className={cn(props.className, negClass)}>{formattedValue}</span>);
            }}
        </NullableFormatterWithIntl>
    );
};

MktValueFormatter.propTypes = {
    value: PropTypes.any,
    decimalPoints: PropTypes.number,
    currency: PropTypes.string,
    className: PropTypes.string,
    withTitle: PropTypes.bool
};

MktValueFormatter.defaultProps = {
    decimalPoints: 0
};

export default MktValueFormatter;
