export const LOG_ITEMS_KEY = 'LOG_ITEMS';
export const ISP_USER_ID = 'ISP_USER_ID';
export const LOG_ITEMS_COUNT = 6;
export const LOG_FLUSH_INTERVAL = 30000;

export default {
    LOG_ITEMS_COUNT,
    LOG_ITEMS_KEY,
    ISP_USER_ID,
    LOG_FLUSH_INTERVAL
};
