import _ from 'lodash';
import { createSelector } from 'reselect';

export const getWatchlistSelector = state => _.get(state, 'shareholderWatchlist.data');
export const isLoadingWatchlistSelector = state => _.get(state, 'shareholderWatchlist.isLoading');
export const isErrorWatchlistSelector = state => !!_.get(state, 'shareholderWatchlist.error');
export const isCreatingWatchlistSelector = state => _.get(state, 'shareholderWatchlist.isCreating');

export const getFirstWatchlistSelector = createSelector(
    getWatchlistSelector,
    (watchlist) => _.first(watchlist)
);
export const isProcessingWatchlistSelector = state => _.get(state, 'shareholderWatchlist.processing', [])
    .length > 0;

export const getWatchlistByIdSelector = (watchlistId) => createSelector(
    getWatchlistSelector,
    (watchlists) => _.find(watchlists, ['id', watchlistId])
);

export const getSelectedWatchlistSelector = state => _.get(state, 'shareholderWatchlist.selectedWatchlist');
export const isFetchedWatchlistSelector = state => _.get(state, 'shareholderWatchlist.isFetched');
