import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isUndefined, isBoolean } from 'lodash';
import { withState, withHandlers } from '@shakacode/recompose';

import AccordionView from '../components/AccordionView';

@withState(
    'isOpen',
    'changeStatus',
    (props) => {
        return isUndefined(props.open) ? true : props.open;
    }
)
@withHandlers({
    triggerStatus: ({ changeStatus, isOpen, onChangeStatus }) => open => {
        changeStatus(!isBoolean(open) ? !isOpen : open);
        onChangeStatus && onChangeStatus(!isBoolean(open) ? !isOpen : open);
    }
})
class Accordion extends Component {
    static propTypes = {
        title: PropTypes.any,
        isOpen: PropTypes.bool,
        open: PropTypes.bool,
        triggerStatus: PropTypes.func,
        onChangeStatus: PropTypes.func,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]).isRequired
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.open !== this.props.open) {
            this.props.triggerStatus(nextProps.open);
        }
    }

    render() {
        return (
            <AccordionView {...this.props}/>
        );
    }
}

export default Accordion;
