import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { AutoSizer } from 'react-virtualized';
import Highcharts from 'highcharts';
import ReactHighcharts from 'highcharts-react-official';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import defaultHighchartsConfig from 'Constants/default-highcharts-config';
import { round } from 'Utils/utils';
import {
    chartDefaultConfig,
    seriesLineDefaultConfig,
    seriesBarDefaultConfig,
    xAxisLineDefaultConfig,
    xAxisBarDefaultConfig,
    yAxisLineDefaultConfig,
    yAxisBarDefaultConfig
} from './constants/analysis-chart-config';
import { getMappedLineData } from './utils';
import { getPosition, getPositionWithScroll } from 'Utils/charts';

import './AnalysisChart.scss';

const LINE_LABEL_DECIMAL_POINTS = 2;

@injectIntl
@mapProps(props => {
    const { groupCount, barData = [], lineData = [] } = props;
    let mappedSeries = [...barData];
    const filler = null;

    if (mappedSeries.length > 0) {
        mappedSeries[0].data = _.defaults(mappedSeries[0].data.slice(0, groupCount),
            _.fill(Array(groupCount), filler));
    } else {
        mappedSeries = [{ data: [..._.fill(Array(groupCount), filler)] }];
    }

    return {
        ...props,
        barData: _.map(mappedSeries, item => ({
            ...item,
            ...seriesBarDefaultConfig
        })),
        lineData: getMappedLineData(lineData, groupCount)
    };
})
class AnalysisChart extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        customConfig: PropTypes.object,
        yAxisBarTitle: PropTypes.string,
        yAxisLineTitle: PropTypes.string,
        groupCount: PropTypes.number.isRequired,
        groups: PropTypes.arrayOf(PropTypes.string),
        barData: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.arrayOf(PropTypes.number).isRequired
        })),
        className: PropTypes.string,
        minPlotWidth: PropTypes.number,
        maxPlotWidth: PropTypes.number,
        lineData: PropTypes.arrayOf(PropTypes.shape({
            series: PropTypes.arrayOf(PropTypes.shape({
                value: PropTypes.number,
                name: PropTypes.string
            }))
        })),
        chartContainer: PropTypes.object,
        isDataViz: PropTypes.bool
    };

    static defaultProps = {
        groups: [],
        minPlotWidth: 12,
        maxPlotWidth: 35
    };

    constructor(props) {
        super(props);

        const { yAxisBarTitle, yAxisLineTitle, intl: { formatNumber } } = props;

        const yAxisBarConfig = _.cloneDeep(yAxisBarDefaultConfig);
        const xAxisBarConfig = _.cloneDeep(xAxisBarDefaultConfig);
        const yAxisLineConfig = _.cloneDeep(yAxisLineDefaultConfig);

        yAxisBarConfig.title.text = yAxisBarTitle;
        yAxisBarConfig.labels.formatter = function yAxisBarLabelFormatter() {
            return this.value ? formatNumber(this.value) : null;
        };

        yAxisLineConfig.labels.formatter = function yAxisLineLabelFormatter() {
            return this.value
                ? formatNumber(round(this.value, LINE_LABEL_DECIMAL_POINTS),
                    { minimumFractionDigits: LINE_LABEL_DECIMAL_POINTS })
                : null;
        };
        yAxisLineConfig.title.text = yAxisLineTitle;

        this.chartConfig = _.cloneDeep(chartDefaultConfig);

        this.chartConfig.xAxis = [xAxisBarConfig, xAxisLineDefaultConfig];
        this.chartConfig.yAxis = [yAxisBarConfig, yAxisLineConfig];
    }

    getBarChartDefaultConfig = () => {
        const {
            barData,
            groups,
            lineData } = this.props;

        const seriesLineConfig = _.cloneDeep(seriesLineDefaultConfig);

        this.chartConfig.xAxis[0].categories = groups;
        seriesLineConfig.data = lineData;
        this.chartConfig.series = [seriesLineConfig, ...barData];

        return this.chartConfig;
    };

    render() {
        const {
            customConfig,
            barData,
            className,
            maxPlotWidth,
            minPlotWidth,
            chartContainer,
            isDataViz
        } = this.props;
        const config = _.merge({}, defaultHighchartsConfig, this.getBarChartDefaultConfig(), customConfig, {
            tooltip: {
                positioner: isDataViz
                    ? function positioner(w, h, p) {
                        return getPositionWithScroll.call(this, w, h, p, chartContainer);
                    } : getPosition
            }
        });

        return (
            <AutoSizer>
                {({ height, width }) => {
                    const chartWidth = width - config.chart.marginLeft - config.chart.marginRight;

                    config.chart.height = height;
                    config.chart.width = width;
                    config.plotOptions.series = {
                        ...config.plotOptions.series,
                        pointWidth: adaptColumnWidth(chartWidth,
                            barData ? barData.length : 1,
                            maxPlotWidth,
                            minPlotWidth),
                        minPointLength: 2
                    };

                    return (
                        <div className='analysis-chart'>
                            <ReactHighcharts
                                highcharts={Highcharts}
                                options={config}
                                containerProps={{ className, style: { height, width } }} />
                        </div>
                    );
                }}
            </AutoSizer>
        );
    }
}

function adaptColumnWidth(widgetWidth, plotsCount, maxPlotWidth, minPlotWidth) {
    const plotWidth = widgetWidth / plotsCount * 0.06;

    if (minPlotWidth > plotWidth) {
        return minPlotWidth;
    }
    if (plotWidth > maxPlotWidth) {
        return maxPlotWidth;
    }
    return plotWidth;
}

export default AnalysisChart;
