const addressStatusMap = {
    ALL: 'ALL',
    BAD: 'BAD',
    GOOD: 'GOOD'
};

const addressStatusOptions = [
    {
        value: addressStatusMap.ALL,
        label: 'shareholder.list.addressStatus.all'
    }, {
        value: addressStatusMap.BAD,
        label: 'shareholder.list.addressStatus.bad'
    }, {
        value: addressStatusMap.GOOD,
        label: 'shareholder.list.addressStatus.good'
    }
];

export {
    addressStatusMap,
    addressStatusOptions
};
