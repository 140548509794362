import PropTypes from 'prop-types';
import React from 'react';
import { Fields, propTypes as reduxFormPropTypes } from 'redux-form';
import { intlShape, FormattedNumber, FormattedMessage } from 'react-intl';

import { RadioButtonsListField } from 'Components/RadioButtonsList';
import SearchRangeComponents from './SearchRangeComponents';

const LIMIT_OF_REPORT_ROWS = 1000;

const ReportingShareRangeAnalysisSearchPageView = (props) => {
    const {
        onEnter,
        onChange,
        shareTypeOptions,
        handleSubmit,
        isFetching,
        submitting,
        invalid,
        onSubmit,
        intl,
        isCombinedProduct
    } = props;

    return (
        <div className='reporting-share-range-search-page'>
            <h2 className='reporting-header'>
                <span>{intl.formatMessage({ id: 'reporting.share.range.analysis.title' })}</span>
            </h2>
            {isCombinedProduct &&
            <h4 className='message-note'>
                <span>{intl.formatMessage({ id: 'reporting.share.range.analysis.subTitle' })}</span>
            </h4>
            }
            <form className='reporting-share-range-search-form' onSubmit={handleSubmit(onSubmit)}>
                <div className='fields-container'>
                    <div className='reporting-section'>
                        <div className='reporting-section-header'>
                            <span>{intl.formatMessage({ id: 'reporting.share.range.analysis.shareType.title' })}</span>
                        </div>
                        <div className='reporting-section-body'>
                            <RadioButtonsListField list={shareTypeOptions} />
                        </div>
                    </div>
                    <div className='reporting-section'>
                        <div className='reporting-section-header'>
                            <span>{intl.formatMessage({ id: 'reporting.share.range.analysis.shareRange.title' })}</span>
                        </div>
                        <Fields
                            names={['shareRange.start', 'shareRange.end', 'shareRange.increment']}
                            parse={SearchRangeComponents.parse}
                            handlers={{
                                onEnter,
                                onChange
                            }}
                            component={SearchRangeComponents} />
                    </div>
                </div>
                <div className='reporting-actions'>
                    <button type='submit' className='btn btn-large share-range-search-submit' disabled={submitting || invalid || isFetching}>
                        <span>{intl.formatMessage({ id: 'reporting.share.range.analysis.submitForm' })}</span>
                    </button>
                </div>
                <div className='message-note'>
                    <FormattedNumber value={LIMIT_OF_REPORT_ROWS}>
                        {limit => (
                            <FormattedMessage
                                id='reporting.share.range.analysis.limited.rows.message'
                                values={{ limit }}/>
                        )}
                    </FormattedNumber>
                </div>
            </form>
        </div>
    );
};

ReportingShareRangeAnalysisSearchPageView.propTypes = {
    ...reduxFormPropTypes,
    onSubmit: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    onChange: PropTypes.func,
    shareTypeOptions: PropTypes.array,
    isFetching: PropTypes.bool,
    isCombinedProduct: PropTypes.bool,
    intl: intlShape
};

export default ReportingShareRangeAnalysisSearchPageView;
