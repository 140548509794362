import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { MillionChangeFormatter, NullableFormatter } from 'Components/formatters';
import TableEasy from 'Components/Tables/TableEasy';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';

class AnalysisHoldingStyleContent extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl.formatMessage;
        const ratioTableMaxWidth = 55;

        this.columns = [
            {
                dataKey: 'holdingStyle',
                title: formatMessage({ id: 'analysis.holding.style.header.investor_type' }),
                bodyCellComponent: v => <NullableFormatter {...v} withTitle/>
            },
            {
                dataKey: 'os',
                title: formatMessage({ id: 'analysis.holding.style.header.os' }),
                columnClassName: 'text-right',
                maxWidth: ratioTableMaxWidth,
                bodyCellComponent: TotalValueOsCell
            },
            {
                dataKey: 'change',
                title: formatMessage({ id: 'analysis.holding.style.header.change' }),
                columnClassName: 'text-right',
                bodyCellComponent: MillionChangeFormatter
            }
        ];
    }

    render() {
        const { tableData, widgetFooterProps, aggregate, hideBarChart, noData, isFetching, isDataViz } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={noData}>
                    <TableEasy
                        widgetFooterProps={widgetFooterProps}
                        className='widget-table'
                        columns={this.columns}
                        data={tableData}
                        aggregate={aggregate}
                        renderAllRows={isDataViz}
                        hideBarChart={hideBarChart}/>
                </NoDataComponent>
            </div>
        );
    }
}

export default AnalysisHoldingStyleContent;
