import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';

export const getDocumentGuid = (documentId, shareholderId, securityId) => {
    const requestData = utils.buildRequest({
        documentId,
        shareholderId,
        securityId
    }, requestTypes.getDocumentGuid);

    return axios.post(apiRoutes.getDocumentGuidUrl, requestData, {
        isCancelable: false
    });
};

export const deleteDocument = (documentId, shareholderId) => {
    const requestData = utils.buildRequest({
        documentId,
        shareholderId
    }, requestTypes.deleteDocumentPayload);

    return axios.post(apiRoutes.deleteDocumentUrl, requestData, {
        isCancelable: false,
        errorStrategy: errorStrategies.SKIP
    });
};
