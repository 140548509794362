export const dataTypes = {
    TRANSACTIONS: 'TRANSACTIONS',
    DETAILED_TRANSACTIONS: 'DETAILED_TRANSACTIONS',
    WEIGHTED_SHARES: 'WEIGHTED_SHARES'
};

export const getDataTypesMap = ({ includeDetailedData, includeWeightedData }) => {
    const dataTypesMap = [
        dataTypes.TRANSACTIONS
    ];

    if (includeDetailedData) {
        dataTypesMap.push(dataTypes.DETAILED_TRANSACTIONS);
    }

    if (includeWeightedData) {
        dataTypesMap.push(dataTypes.WEIGHTED_SHARES);
    }

    return dataTypesMap;
};
