import React from 'react';
import PropTypes from 'prop-types';

import './termsText.scss';

const TermsText = ({ children }) => (
    <div className='terms-text'>
        {children}
    </div>
);

TermsText.propTypes = {
    children: PropTypes.node
};

export default TermsText;
