import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import _ from 'lodash';

const MIN_VALUE = 0.001;
const INITIAL_INCREMENT_MIN_VALUE = 1;

export default (shareRange = {}) => {
    const error = {};
    const { start, end, increment } = shareRange;
    const CALCULATED_INCREMENT_MIN_VALUE = Math.ceil((end - start) / 1000);
    const CALCULATED_END_MIN_VALUE = Math.max(1, start) || 1;

    if (_.isNil(start)) {
        error.start = <FormattedMessage id='reporting.share.range.analysis.shareRange.start.error.empty'/>;
    } else if (start < MIN_VALUE) {
        error.start = (<FormattedMessage
            id='reporting.share.range.analysis.shareRange.error.min'
            values={{ min: MIN_VALUE }}/>);
    } else if (!_.isNil(end) && start > end) {
        error.start =
            <FormattedMessage id='reporting.share.range.analysis.shareRange.start.error.afterEnd'/>;
    }

    if (_.isNil(end)) {
        error.end = <FormattedMessage id='reporting.share.range.analysis.shareRange.end.error.empty'/>;
    } else if ((end < CALCULATED_END_MIN_VALUE) || (!_.isNil(start) && end < start)) {
        if (start < 1) {
            error.end =
                <FormattedMessage id='reporting.share.range.analysis.shareRange.end.error.minValue.lessThanOne'/>;
        } else {
            error.end =
                (<FormattedMessage
                    id='reporting.share.range.analysis.shareRange.end.error.minValue'
                    values={{ startValue: <FormattedNumber value={start}/> }}/>);
        }
    } else if (!_.isNil(start) && end === start) {
        error.end =
            <FormattedMessage id='reporting.share.range.analysis.shareRange.end.error.equalStart'/>;
    }

    if (!_.isNil(increment) && increment < INITIAL_INCREMENT_MIN_VALUE) {
        error.increment = (<FormattedMessage
            id='reporting.share.range.analysis.shareRange.error.min'
            values={{ min: INITIAL_INCREMENT_MIN_VALUE }}/>);
    } else if (increment < CALCULATED_INCREMENT_MIN_VALUE) {
        error.increment = (<FormattedMessage
            id='reporting.share.range.analysis.shareRange.error.minIncrement'
            values={{ minIncrement: <FormattedNumber value={CALCULATED_INCREMENT_MIN_VALUE}/> }}/>);
    } else if (increment > end) {
        error.increment = (<FormattedMessage
            id='reporting.share.range.analysis.shareRange.error.maxIncrement'
            values={{ maxIncrement: <FormattedNumber value={end}/> }}/>);
    }

    return error;
};
