import _ from 'lodash';

import { successSaveNewReport } from 'State/savedReportTemplates/actions';
import defaultValues from './defaultValues';
import { getReportTemplate, changeReportTemplate } from '../../api/reportTemplate';

export const REPORTING_SHARE_DETAIL_INITIAL = 'REPORTING_SHARE_DETAIL_INITIAL';
export const REPORTING_SHARE_DETAIL_SET_FETCHING = 'REPORTING_SHARE_DETAIL_SET_FETCHING';
export const REPORTING_SHARE_DETAIL_INITIAL_ERROR = 'REPORTING_SHARE_DETAIL_INITIAL_ERROR';
export const REPORTING_SHARE_DETAIL_CHANGE_REQUEST = 'REPORTING_SHARE_DETAIL_CHANGE_REQUEST';
export const REPORTING_SHARE_DETAIL_CHANGE_SUCCESS = 'REPORTING_SHARE_DETAIL_CHANGE_SUCCESS';
export const REPORTING_SHARE_DETAIL_CHANGE_ERROR = 'REPORTING_SHARE_DETAIL_CHANGE_ERROR';

const reportingShareDetailSetFetching = isFetching => ({
    type: REPORTING_SHARE_DETAIL_SET_FETCHING,
    payload: isFetching
});

const reportingShareDetailChangeSetChanging = isChanging => ({
    type: REPORTING_SHARE_DETAIL_CHANGE_REQUEST,
    payload: isChanging
});

export const setDefaultTemplate = () => ({
    type: REPORTING_SHARE_DETAIL_INITIAL,
    payload: defaultValues
});

export const getTemplate = (reportTemplateId) => dispatch => {
    dispatch(reportingShareDetailSetFetching(true));

    return getReportTemplate(reportTemplateId)
        .then(res => {
            const template = _.get(res, 'data.payload.template', {});
            const templateForm = _.get(template, 'form', {});
            const reportName = template.reportName;
            const form = { ...templateForm, formReportName: reportName };

            dispatch(reportingShareDetailSetFetching(false));
            dispatch({
                type: REPORTING_SHARE_DETAIL_INITIAL,
                payload: { ...template, form }
            });
        }, error => {
            dispatch(reportingShareDetailSetFetching(false));
            dispatch({
                type: REPORTING_SHARE_DETAIL_INITIAL_ERROR,
                payload: error.message,
                error: true
            });

            return Promise.reject(error);
        });
};

export const changeTemplate = reportTemplateData => dispatch => {
    const form = { ...reportTemplateData.form, formReportName: reportTemplateData.reportName };

    dispatch(reportingShareDetailChangeSetChanging(true));

    return changeReportTemplate(reportTemplateData)
        .then(() => {
            dispatch(reportingShareDetailChangeSetChanging(false));
            dispatch({
                type: REPORTING_SHARE_DETAIL_CHANGE_SUCCESS,
                payload: { ...reportTemplateData, form }
            });
            dispatch(successSaveNewReport(reportTemplateData));
        }, error => {
            dispatch(reportingShareDetailChangeSetChanging(false));
            dispatch({
                type: REPORTING_SHARE_DETAIL_CHANGE_ERROR,
                payload: error.message,
                error: true
            });
            return Promise.reject(error);
        });
};
