import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import FormError from 'Components/FormError';

import 'Components/TextInput/TextInput.scss';

class TextInput extends Component {
    static propTypes = {
        type: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        labelType: PropTypes.string,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        withFocus: PropTypes.bool,
        disabled: PropTypes.bool,
        autoComplete: PropTypes.bool
    };

    static defaultProps = {
        type: 'text',
        labelType: 'horizontal',
        autoComplete: true
    };

    componentDidUpdate(prevProps) {
        this.props.withFocus && !prevProps.withFocus && this.input && this.input.focus();
    }

    getInputRef = (input) => {
        this.input = input;
    };

    render() {
        const { type, label, name, labelType, placeholder, className, withFocus, error, disabled, autoComplete, ...rest } = this.props;
        const labelClass = cn('text-input-label', { disabled }, labelType);
        const inputClass = cn({ 'error': !!error });

        return (
            <div className={cn('input-container', className)}>
                { label && (
                    <label className={labelClass} htmlFor={name}>
                        {label}
                    </label>
                ) }
                <div className='text-input'>
                    <input
                        {...rest}
                        id={name}
                        name={name}
                        type={type}
                        disabled={disabled}
                        ref={withFocus && this.getInputRef}
                        placeholder={placeholder}
                        className={inputClass}
                        autoComplete={autoComplete === false ? 'off' : 'on'}/>
                    {error && <FormError error={error}/>}
                </div>
            </div>
        );
    }
}

export default TextInput;
