export default [
    'formReportName',
    'shareholderType',
    'shareType',
    'accountType',
    'accountStatus',
    'directDeposit',
    'dateRange.checked',
    'dateRange.startDate',
    'dateRange.endDate',
    'sharesOwned.checked',
    'sharesOwned.min',
    'sharesOwned.max',
    'shareholderLocation.country',
    'shareholderLocation.state',
    'shareholderLocation.zipCode',
    'shareholderLocation.showForeign',
    'columnsToDisplay.ALL',
    'columnsToDisplay.REGISTERED',
    'columnsToDisplay.INSTITUTION',
    'sortCriteria',
    'noOfRecords'
];
