import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';

import TableEasy from 'Components/Tables/TableEasy';
import { LabelWithPossibleLinkCell, labelCell, numberCell, headerCell } from './cellRenderers';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { markIncludesCeDeCoRows, markIncludesESPPRows, getMarkedData } from 'Components/widgets/utils';
import { AGGREGATE_ROW } from '../constants';

const AuthorizedSharesTableView = ({ tableData }) => (
    <div className='table-container'>
        <TableEasy
            className='widget-table'
            {...tableData}/>
    </div>
);

AuthorizedSharesTableView.propTypes = {
    tableData: PropTypes.object,
    totalAuthorizedShares: PropTypes.number
};

export default compose(
    mapProps(props => {
        const { data = [], intl: { formatMessage }, withLink, widgetFooterProps } = props;
        const mappedData = [];

        _.forEach(data, v => mappedData.push(...v.values,
            {
                ...v.aggregate,
                rowClassName: AGGREGATE_ROW
            }));

        const tableData = {
            columns: [{
                dataKey: 'allotment',
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'authorized.shares.allotment' }),
                    <FormattedHTMLMessage id='authorized.shares.tooltip.allotment'/>
                ),
                bodyCellComponent: withLink ? LabelWithPossibleLinkCell : labelCell,
                columnClassName: 'overfilling-row-container'
            }, {
                dataKey: 'noOfShares',
                columnClassName: 'text-right',
                title: formatMessage({ id: 'authorized.shares.noOfShares' }),
                bodyCellComponent: numberCell
            }],
            data: getMarkedData(mappedData, [markIncludesCeDeCoRows, markIncludesESPPRows], widgetFooterProps)
        };
        const lastMappedValue = _.last(mappedData);
        let totalAuthorizedShares;

        if (lastMappedValue && lastMappedValue.rowClassName === AGGREGATE_ROW) {
            totalAuthorizedShares = _.get(lastMappedValue, 'noOfShares');

            _.set(_.last(mappedData), 'allotment', formatMessage({ id: 'authorized.shares.total.authorizedShares' }));
        }

        // if we have -1 it means that we have 'unlimited' total Authorized Shares
        if (totalAuthorizedShares !== -1) {
            tableData.columns.splice(1, 0, {
                dataKey: 'percentAuthorized',
                columnClassName: 'text-right',
                minWidth: 100,
                title: formatMessage({ id: 'authorized.shares.percentAuthorized' }),
                bodyCellComponent: TotalValueOsCell
            });
        }

        return { tableData, totalAuthorizedShares };
    })
)(AuthorizedSharesTableView);
