import columns from './columns';
import dataTypes from './data-types';

export default [
    {
        name: 'volume',
        column: columns.LEFT,
        position: 1,
        type: dataTypes.INTEGER
    },
    {
        name: 'marketIdCode',
        tooltip: 'exch',
        column: columns.LEFT,
        position: 2,
        type: dataTypes.TEXT
    },
    {
        name: 'open',
        column: columns.LEFT,
        position: 3,
        type: dataTypes.DECIMAL
    },
    {
        name: 'high',
        column: columns.LEFT,
        position: 4,
        type: dataTypes.DECIMAL
    },
    {
        name: 'low',
        column: columns.LEFT,
        position: 5,
        type: dataTypes.DECIMAL
    },
    {
        name: 'prevClose',
        column: columns.LEFT,
        position: 6,
        type: dataTypes.DECIMAL
    },
    {
        name: 'vwap',
        column: columns.LEFT,
        position: 7,
        type: dataTypes.DECIMAL
    },
    {
        name: 'wkH52',
        column: columns.LEFT,
        position: 8,
        type: dataTypes.DECIMAL
    },
    {
        name: 'wkL52',
        column: columns.LEFT,
        position: 9,
        type: dataTypes.DECIMAL
    },
    {
        name: 'chg3M',
        column: columns.LEFT,
        position: 10,
        type: dataTypes.PERCENT
    },
    {
        name: 'chg12M',
        column: columns.LEFT,
        position: 11,
        type: dataTypes.PERCENT
    },
    {
        name: 'yrTarget',
        column: columns.LEFT,
        position: 12,
        type: dataTypes.DECIMAL
    },
    {
        name: 'avg50D',
        column: columns.LEFT,
        position: 13,
        type: dataTypes.DECIMAL
    },
    {
        name: 'volPct',
        column: columns.RIGHT,
        position: 1,
        type: dataTypes.PERCENT
    },
    {
        name: 'peRatio',
        column: columns.RIGHT,
        position: 2,
        type: dataTypes.DECIMAL
    },
    {
        name: 'fwdYrPE',
        column: columns.RIGHT,
        position: 3,
        type: dataTypes.DECIMAL
    },
    {
        name: 'pbkVal',
        column: columns.RIGHT,
        position: 4,
        type: dataTypes.DECIMAL
    },
    {
        name: 'curYld',
        column: columns.RIGHT,
        position: 5,
        type: dataTypes.PERCENT
    },
    {
        name: 'eps',
        column: columns.RIGHT,
        position: 6,
        type: dataTypes.DECIMAL
    },
    {
        name: 'fy1Eps',
        column: columns.RIGHT,
        position: 7,
        type: dataTypes.DECIMAL
    },
    {
        name: 'bvps',
        column: columns.RIGHT,
        position: 8,
        type: dataTypes.DECIMAL
    },
    {
        name: 'annDiv',
        column: columns.RIGHT,
        position: 9,
        type: dataTypes.DECIMAL
    },
    {
        name: 'exDivDate',
        column: columns.RIGHT,
        position: 10,
        type: dataTypes.DATE
    },
    {
        name: 'divPay',
        column: columns.RIGHT,
        position: 11,
        type: dataTypes.DECIMAL
    },
    {
        name: 'divPayDate',
        column: columns.RIGHT,
        position: 12,
        type: dataTypes.DATE
    },
    {
        name: 'beta',
        column: columns.RIGHT,
        position: 13,
        type: dataTypes.DECIMAL
    },
    {
        name: 'marketCap',
        column: columns.RIGHT,
        position: 14,
        type: dataTypes.MONEY,
        formatterOptions: {
            decimalPoints: 2
        }
    }
];
