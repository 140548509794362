import { createReducer } from 'Utils/reducer';
import {
    FETCHING_SHAREHOLDERS_FUNDS_REQUEST,
    FETCHING_SHAREHOLDERS_FUNDS_SUCCESS,
    FETCHING_SHAREHOLDERS_FUNDS_FAILURE,
    FETCHING_SHAREHOLDERS_POSITIONS_REQUEST,
    FETCHING_SHAREHOLDERS_POSITIONS_SUCCESS,
    FETCHING_SHAREHOLDERS_POSITIONS_FAILURE,
    SET_EXPANDED_SHAREHOLDERS,
    RESET_SHAREHOLDER_TABLE,
    FETCHING_SHAREHOLDERS_CONTACTS_REQUEST,
    FETCHING_SHAREHOLDERS_CONTACTS_SUCCESS,
    FETCHING_SHAREHOLDERS_CONTACTS_FAILURE,
    FETCHING_SHAREHOLDERS_REQUEST,
    FETCHING_SHAREHOLDERS_SUCCESS,
    FETCHING_SHAREHOLDERS_FAILURE
} from './actions';

const initialState = {
    funds: {},
    fundsErrors: {},
    isFetchingFunds: {},
    isFetchingPositions: {},
    positions: {},
    positionsErrors: {},
    expandedShareholders: {},

    isFetchingContacts: {},
    contacts: {},
    contactsErrors: {},

    data: undefined,
    isShareholdersFetching: false,
    isShareholdersError: false
};

const actionHandlers = {
    [FETCHING_SHAREHOLDERS_FUNDS_REQUEST]: (state, { payload: { shareholdersId } }) => {
        return {
            ...state,
            isFetchingFunds: {
                ...state.isFetchingFunds,
                [shareholdersId]: true
            }
        };
    },
    [RESET_SHAREHOLDER_TABLE]: () => initialState,
    [FETCHING_SHAREHOLDERS_FUNDS_SUCCESS]: (state, { payload: { funds, shareholdersId } }) => ({
        ...state,
        funds: {
            ...state.funds,
            [shareholdersId]: funds
        },
        isFetchingFunds: {
            ...state.isFetchingFunds,
            [shareholdersId]: false
        }
    }),
    [FETCHING_SHAREHOLDERS_FUNDS_FAILURE]: (state, { payload: { error, shareholdersId } }) => ({
        ...state,
        fundsErrors: {
            ...state.fundsErrors,
            [shareholdersId]: error
        },
        isFetchingFunds: {
            ...state.isFetchingFunds,
            [shareholdersId]: false
        }
    }),
    [SET_EXPANDED_SHAREHOLDERS]: (state, { payload: { expandedShareholders } }) => ({
        ...state,
        expandedShareholders
    }),
    [FETCHING_SHAREHOLDERS_POSITIONS_REQUEST]: (state, { payload: { shareholdersId } = {} }) => ({
        ...state,
        isFetchingPositions: {
            ...state.isFetchingPositions,
            [shareholdersId]: true
        }
    }),
    [FETCHING_SHAREHOLDERS_POSITIONS_SUCCESS]: (state, { payload: { data, shareholdersId } = {} }) => ({
        ...state,
        positions: {
            ...state.positions,
            [shareholdersId]: data
        },
        isFetchingPositions: {
            ...state.isFetchingPositions,
            [shareholdersId]: false
        }
    }),
    [FETCHING_SHAREHOLDERS_POSITIONS_FAILURE]: (state, { payload: { error, shareholdersId } = {} }) => ({
        ...state,
        positionsErrors: {
            ...state.positionsErrors,
            [shareholdersId]: error
        },
        isFetchingPositions: {
            ...state.isFetchingPositions,
            [shareholdersId]: false
        }
    }),
    [FETCHING_SHAREHOLDERS_CONTACTS_REQUEST]: (state, { payload: { shareholderId } }) => ({
        ...state,
        isFetchingContacts: {
            ...state.isFetchingContacts,
            [shareholderId]: true
        }
    }),
    [FETCHING_SHAREHOLDERS_CONTACTS_SUCCESS]: (state, { payload: { contacts, shareholderId } }) => ({
        ...state,
        contacts: {
            ...state.contacts,
            [shareholderId]: contacts
        },
        isFetchingContacts: {
            ...state.isFetchingContacts,
            [shareholderId]: false
        }
    }),
    [FETCHING_SHAREHOLDERS_CONTACTS_FAILURE]: (state, { payload: { error, shareholderId } }) => ({
        ...state,
        contactsErrors: {
            ...state.contactsErrors,
            [shareholderId]: error
        },
        isFetchingContacts: {
            ...state.isFetchingContacts,
            [shareholderId]: false
        }
    }),
    [FETCHING_SHAREHOLDERS_REQUEST]: (state) => ({
        ...state,
        isShareholdersFetching: true,
        isShareholdersError: false
    }),
    [FETCHING_SHAREHOLDERS_SUCCESS]: (state, { payload }) => ({
        ...state,
        data: payload,
        isShareholdersFetching: false
    }),
    [FETCHING_SHAREHOLDERS_FAILURE]: (state) => ({
        ...state,
        isShareholdersError: true,
        isShareholdersFetching: false
    })
};

export default createReducer(initialState, actionHandlers);
