import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';

export const getSubscriptions = () => {
    const requestData = utils.buildRequest({}, requestTypes.getSubscriptionsPayload);

    return axios.post(apiRoutes.getSubscriptionsUrl, requestData, {
        errorStrategy: errorStrategies.DEFAULT,
        isCancelable: false
    });
};

export const changeSubscriptionStatus = (type, isActive) => {
    const requestData = utils.buildRequest({ type, isActive }, requestTypes.changeSubscriptionStatusPayload);

    return axios.post(apiRoutes.changeSubscriptionStatusUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};

export const changeEmailSubscriptionStatus = (type, isActive) => {
    const requestData = utils.buildRequest({ type, isActive }, requestTypes.changeEmailSubscriptionStatusPayload);

    return axios.post(apiRoutes.changeEmailSubscriptionStatusUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};
