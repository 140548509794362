import { columnNames } from './constants/columnsDefinitions';
import * as cellComponents from '../_commonComponents/TableCellComponents';
import dateFormats from 'Constants/date-formats';
import CellDeleteNote from './components/CellDeleteNote';
import shareholderType from 'Constants/shareholder-types';
import pageRoutes from 'Constants/page-routes';
import entityTypes from 'Constants/entity-types';

export const getCustomComponent = columnType => {
    switch (columnType) {
        case columnNames.DATE: {
            return {
                customComponent: cellComponents.CellWithDate,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnNames.DELETE: {
            return {
                customComponent: CellDeleteNote,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnNames.NOTE_TEXT: {
            return {
                customComponent: cellComponents.CellWithEllipsis,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        default:
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
    }
};

export const getTdProps = (columnType, row) => {
    switch (columnType) {
        case columnNames.DELETE: {
            return {
                className: 'icon-cell'
            };
        }
        case columnNames.NOTE_TEXT: {
            return {
                row,
                className: 'td-description'
            };
        }
        case columnNames.DATE: {
            return {
                format: dateFormats.withTime
            };
        }
        default: {
            return {};
        }
    }
};

export const getEntityTypeByRoute = pageUrl => {
    if (pageUrl.includes(pageRoutes[shareholderType.REGISTERED])) return entityTypes.REGISTERED;
    if (pageUrl.includes(pageRoutes.contact)) return entityTypes.CONTACT;
    if (pageUrl.includes(pageRoutes.institutionProfile)) return entityTypes.INSTITUTION;
};

export const getEntityIdByType = (params, entityType) => {
    switch (entityType) {
        case entityTypes.CONTACT:
            return params.contactId;
        case entityTypes.INSTITUTION:
            return params.shareholderId;
        default:
            return params.shareholderId;
    }
};
