import { dataTypes } from './dataTypes';

const columnNames = {
    AST_SECURITY_ID: 'astSecurityId',
    SECURITY_NAME: 'securityName',
    AS_OF_DATE: 'asOfDate',
    PROCESS_DATE: 'processDate',
    TRANSACTION_TYPE: 'transactionType',
    TRANSACTION_NUMBER: 'transactionNumber',
    TRANSACTION: 'transaction',
    REASON: 'reason',
    SHAREHOLDER_NAME: 'shareholderName',
    ACCOUNT_NUMBER: 'accountNo',
    BROKER_NO: 'brokerNo',
    SHARES_ISSUED: 'sharesIssued',
    SHARES_RETIRED: 'sharesRetired',
    ISSUED_AND_OUTSTANDING: 'issuedAndOutstanding',
    COMMENT: 'comment'
};

const columns = [
    {
        columnName: columnNames.AST_SECURITY_ID,
        displayName: 'reporting.control.books.results.table.astSecurityId',
        minWidth: 91,
        order: 1,
        manageable: false
    },
    {
        columnName: columnNames.SECURITY_NAME,
        displayName: 'reporting.control.books.results.table.securityName',
        minWidth: 150,
        order: 2,
        manageable: false
    },
    {
        columnName: columnNames.AS_OF_DATE,
        displayName: 'reporting.control.books.results.table.asOfDate',
        minWidth: 91,
        order: 3,
        manageable: false
    },
    {
        columnName: columnNames.PROCESS_DATE,
        displayName: 'reporting.control.books.results.table.processDate',
        minWidth: 91,
        order: 4,
        manageable: false
    },
    {
        columnName: columnNames.TRANSACTION_TYPE,
        displayName: 'reporting.control.books.results.table.transactionType',
        minWidth: 90,
        order: 5,
        manageable: true,
        onlyFor: [dataTypes.TRANSACTIONS, dataTypes.WEIGHTED_SHARES]
    },
    {
        columnName: columnNames.TRANSACTION_NUMBER,
        displayName: 'reporting.control.books.results.table.transactionNumber',
        minWidth: 90,
        order: 6,
        manageable: true,
        onlyFor: [dataTypes.TRANSACTIONS, dataTypes.WEIGHTED_SHARES]
    },
    {
        columnName: columnNames.TRANSACTION,
        displayName: 'reporting.control.books.results.table.transaction',
        minWidth: 90,
        order: 7,
        manageable: true,
        onlyFor: [dataTypes.DETAILED_TRANSACTIONS]
    },
    {
        columnName: columnNames.REASON,
        displayName: 'reporting.control.books.results.table.reason',
        minWidth: 110,
        order: 8,
        manageable: true,
        onlyFor: [dataTypes.DETAILED_TRANSACTIONS]
    },
    {
        columnName: columnNames.ACCOUNT_NUMBER,
        displayName: 'reporting.control.books.results.table.accountNumber',
        minWidth: 90,
        order: 9,
        manageable: true,
        onlyFor: [dataTypes.DETAILED_TRANSACTIONS]
    },
    {
        columnName: columnNames.SHAREHOLDER_NAME,
        displayName: 'reporting.control.books.results.table.shareholderName',
        minWidth: 150,
        order: 10,
        manageable: true,
        onlyFor: [dataTypes.DETAILED_TRANSACTIONS]
    },
    {
        columnName: columnNames.BROKER_NO,
        displayName: 'reporting.control.books.results.table.brokerNo',
        minWidth: 90,
        order: 11,
        manageable: true,
        onlyFor: [dataTypes.TRANSACTIONS, dataTypes.WEIGHTED_SHARES]
    },
    {
        columnName: columnNames.SHARES_ISSUED,
        displayName: 'reporting.control.books.results.table.sharesIssued',
        minWidth: 90,
        order: 12,
        manageable: true
    },
    {
        columnName: columnNames.SHARES_RETIRED,
        displayName: 'reporting.control.books.results.table.sharesRetired',
        minWidth: 90,
        order: 13,
        manageable: true
    },
    {
        columnName: columnNames.ISSUED_AND_OUTSTANDING,
        displayName: 'reporting.control.books.results.table.issuedAndOutStanding',
        minWidth: 110,
        order: 14,
        manageable: true,
        onlyFor: [dataTypes.TRANSACTIONS, dataTypes.WEIGHTED_SHARES]
    },
    {
        columnName: columnNames.COMMENT,
        displayName: 'reporting.control.books.results.table.comment',
        minWidth: 170,
        order: 15,
        manageable: true,
        onlyFor: [dataTypes.TRANSACTIONS, dataTypes.WEIGHTED_SHARES]
    }
];

const getColumnsByDataType = dataType =>
    columns.filter(column => !column.onlyFor || column.onlyFor.includes(dataType));

export {
    columnNames,
    getColumnsByDataType
};
