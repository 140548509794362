import { compose, lifecycle, mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';

import { withUploadNotifications } from 'Hoc';
import { requestFilesSelector } from 'State/userSecureUpload/selectors';
import * as uploadActions from 'State/userSecureUpload/actions';
import { getSecureUploadMaxFileSize } from 'State/user';
import { fileExtensionsMap, fileMIMETypesMap } from 'Constants/file-formats';
import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';
import { setInProgress, removeInProgress } from 'Utils/utils';
import polling from 'Components/polling';
import pollingFunctionTypes from 'Constants/polling-function-types';

import { UploadedFilesTable } from './components/UploadedFilesTable';

let boundActionCreators;
const mapDispatchToProps = dispatch => {
    return boundActionCreators = bindActionCreators({
        uploadFile: uploadActions.uploadDocument,
        fetchUploadStatus: uploadActions.fetchUploadStatus,
        deleteUploadedFile: uploadActions.deleteUploadedFile
    }, dispatch);
};

const mapStateToProps = state => ({
    files: requestFilesSelector(state),
    maxUploadFileSize: getSecureUploadMaxFileSize(state)
});

const DEFAULT_POLLING_INTERVAL = 5000;

export const UploadedFileList = compose(
    connect(mapStateToProps, mapDispatchToProps),
    polling(
        ({ requestId }) => boundActionCreators.fetchUploadStatus(Number(requestId)),
        pollingFunctionTypes.SECURE_UPLOAD_DOCUMENTS,
        DEFAULT_POLLING_INTERVAL
    ),
    lifecycle({
        componentDidUpdate(prevProps) {
            const inputNotInit = (_.size(this.props.files) === 0 && this.props.input.value === '');

            if (prevProps.files !== this.props.files && !inputNotInit) {
                this.props.input.onChange(this.props.files);
            }
        }
    }),
    withUploadNotifications,
    mapProps(({
        requestId,
        notifyUploadWrongType,
        notifyUploadEmptyFile,
        maxUploadFileSize,
        notifyUploadLargeFile,
        notifyUploadInProgress,
        showUploadModal,
        uploadFile,
        removeAllUploadNotification,
        notifyUploadSecurityFail,
        notifyUploadFail,
        notifyUploadError,
        notifyUploadSuccess,
        deleteUploadedFile,
        fetchUploadStatus,
        files,
        uploaded
    }) => {
        const uploadFiles = (droppedFiles = []) => {
            if (droppedFiles.length === 0) {
                removeInProgress();
                return;
            }

            const [currentFile, ...remainFiles] = droppedFiles;

            const currentFileType = _.get(currentFile, 'type');
            const currentFileSize = _.get(currentFile, 'size');
            const currentFileName = _.get(currentFile, 'name');

            const isValidFileType = currentFileType && _.some(fileMIMETypesMap, type => type === currentFileType);
            const isValidFileExt = currentFileName && _.some(fileExtensionsMap, ext => _.endsWith(_.toLower(currentFileName), ext));

            if (!isValidFileType || !isValidFileExt) {
                notifyUploadWrongType(currentFileName);
                uploadFiles(remainFiles);

                return;
            }

            if (!currentFileSize || currentFileSize === 0) {
                notifyUploadEmptyFile(currentFileName);
                uploadFiles(remainFiles);

                return;
            }

            if (currentFileSize > maxUploadFileSize) {
                notifyUploadLargeFile(currentFileName, maxUploadFileSize);
                uploadFiles(remainFiles);

                return;
            }

            notifyUploadInProgress(currentFileName);
            showUploadModal();

            return uploadFile(requestId, currentFile)
                .then(uploadStatus => {
                    removeAllUploadNotification();

                    switch (uploadStatus) {
                        case uploadedDocumentStatuses.SECURITY_CHECK_FAILED:
                            notifyUploadSecurityFail(currentFileName);
                            break;
                        case uploadedDocumentStatuses.FAILED:
                            notifyUploadFail(currentFileName);
                            break;
                        default:
                            notifyUploadSuccess(currentFileName);
                            break;
                    }
                })
                .catch(() => {
                    removeAllUploadNotification();
                    notifyUploadError(currentFileName);
                })
                .finally(() => {
                    uploadFiles(remainFiles);
                    fetchUploadStatus(Number(requestId));
                });
        };

        const onDrop = (droppedFiles = []) => {
            setInProgress();
            uploadFiles(droppedFiles);
        };

        const onDelete = (file) => {
            deleteUploadedFile(file.id);
        };

        return {
            onDrop,
            onDelete,
            files,
            uploaded,
            maxUploadFileSize
        };
    })
)(UploadedFilesTable);
