import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import WidgetBox from '../../../widgets/WidgetBox';

const SingleSlotView = ({ widgetName, slotId, isLocked, className, isEmpty }) => {
    const classNames = cn('slot', 'single-slot', className);

    return (
        <div
            className={classNames}
            data-is-locked-slot={isLocked || null}>
            {!isEmpty && <WidgetBox
                widgetName={widgetName}
                slotId={slotId}
                isLocked={isLocked}/>}
        </div>
    );
};

SingleSlotView.propTypes = {
    widgetName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isLocked: PropTypes.bool,
    slotId: PropTypes.number.isRequired,
    className: PropTypes.string,
    isEmpty: PropTypes.bool
};

export default SingleSlotView;
