const REGISTERED = 'registeredView';
const NUMBERS = 'numbersView';
const DETAILS = 'detailsView';
const PROXY = 'proxyView';

export default {
    REGISTERED,
    NUMBERS,
    DETAILS,
    PROXY
};
