import _ from 'lodash';

import { createReducer } from 'Utils/reducer';
import {
    SET_ISSUERS
} from './actions';

const initialState = {
    availableIssuers: [],
    selectedIssuer: null
};

const actionHandlers = {
    [SET_ISSUERS]: (state, { payload }) => ({
        ...state,
        availableIssuers: _.sortBy(payload, issuer => issuer.companyName.toLowerCase())
    })
};

export default createReducer(initialState, actionHandlers);
