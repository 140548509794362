export default {
    contactDetails: 'contactDetails',
    corporateShareholders: 'corporateShareholders',
    overallOwnershipStatistics: 'overallOwnershipStatistics',
    registeredHolderType: 'registeredHolderType',
    activityTrend: 'activityTrend',
    activeVsPassive: 'activeVsPassive',
    analysisInvestorType: 'analysisInvestorType',
    analysisHoldingStyle: 'analysisHoldingStyle',
    analysisManagerStyle: 'analysisManagerStyle',
    analysisCapGroup: 'analysisCapGroup',
    analysisTurnover: 'analysisTurnover',
    analysisGeographicCountry: 'analysisGeographicCountry',
    analysisGeographicMetro: 'analysisGeographicMetro',
    analysisGeographicState: 'analysisGeographicState',
    shareholdersTable: 'shareholdersTable',
    top25HoldingsTable: 'top25HoldingsTable',
    outstandingShares: 'outstandingShares', // TODO not used
    savedReportTemplates: 'savedReportTemplates',
    shareholdersTableFunds: 'shareholdersTableFunds',
    shareholdersTablePositions: 'shareholdersTablePositions',
    shareholderInformation: 'shareholderInformation',
    shareholderBuysAndSells: 'shareholderBuysAndSells',
    shareholderAnalysisTop10Sectors: 'shareholderAnalysisTop10Sectors',
    shareholderAnalysisTop10Industry: 'shareholderAnalysisTop10Industry',
    shareholderAnalysisDistribution: 'shareholderAnalysisDistribution', // TODO not used
    shareholderAnalysisRegion: 'shareholderAnalysisRegion', // TODO not used
    shareholderAnalysisTop10Country: 'shareholderAnalysisTop10Country',
    shareholderAnalysisTop10CapGroup: 'shareholderAnalysisTop10CapGroup',
    institutionProfile: 'institutionProfile',
    geographicAnalysis: 'geographicAnalysis',
    registeredShareholderInformation: 'registeredShareholderInformation',
    registeredShareholderTransactionHistory: 'registeredShareholderTransactionHistory',
    registeredShareholderDRDSPP: 'registeredShareholderDRDSPP',
    registeredShareholderPayments: 'registeredShareholderPayments',
    registeredShareholderDocuments: 'registeredShareholderDocuments',
    registeredShareholderClientUploads: 'registeredShareholderClientUploads',
    registeredShareholder1099DIV: 'registeredShareholder1099DIV',
    registeredShareholder1099INT: 'registeredShareholder1099INT',
    registeredShareholder1099B: 'registeredShareholder1099B',
    registeredShareholder1099Misc: 'registeredShareholder1099Misc',
    registeredShareholder1042S: 'registeredShareholder1042S',
    registeredShareholderCurrent: 'registeredShareholderCurrent',
    registeredShareholderPriorTADRSHistory: 'registeredShareholderPriorTADRSHistory',
    registeredShareholderClosedSaleType: 'registeredShareholderClosedSaleType',
    registeredShareholderClosedOtherType: 'registeredShareholderClosedOtherType',
    registeredShareholderNotes: 'registeredShareholderNotes',
    holderInvestorTypeAnalysis: 'holderInvestorTypeAnalysis',
    controlBooksResults: 'controlBooksResults',
    shareRangeAnalysisResults: 'shareRangeAnalysisResults',
    peerAnalysis: 'peerAnalysis',
    historicalAnalysisTable: 'historicalAnalysisTable',
    contactsLanding: 'contactsTable',
    contactsInstitutions: 'institutionsTable',
    fullQuote: 'fullQuote',
    performance: 'performance',
    performanceSmall: 'performanceSmall',
    insiderShareholderInformation: 'insiderShareholderInformation',
    insiderTop25HoldingsTable: 'insiderTop25HoldingsTable',
    shareholderElections: 'shareholderElections',
    outstandingPayments: 'outstandingPayments',
    badAddresses: 'badAddresses',
    taxCertificationStatus: 'taxCertificationStatus',
    dateLastContact: 'dateLastContact',
    authorizedShares: 'authorizedShares',
    compareShareholdersAccountInfo: 'compareShareholdersAccountInfo',
    compareShareholdersTransactionHistory: 'compareShareholdersTransactionHistory',
    compareShareholdersDRDSPP: 'compareShareholdersDRDSPP',
    compareShareholdersPayments: 'compareShareholdersPayments',
    compareShareholdersDocuments: 'compareShareholdersDocuments',
    compareShareholders1099DIV: 'compareShareholders1099DIV',
    compareShareholders1099INT: 'compareShareholders1099INT',
    compareShareholders1099B: 'compareShareholders1099B',
    compareShareholders1099Misc: 'compareShareholders1099Misc',
    compareShareholders1042S: 'compareShareholders1042S',
    authorizedUserList: 'authorizedUserList',
    notes: 'notes',
    shareholdersProxyContacts: 'shareholdersProxyContacts',
    shareholdersProxyTable: 'shareholdersProxyTable',
    subProxyTable: 'shareholdersProxySubsidiaries',
    meetingPlannerTracker: 'meetingPlannerTracker',
    shareholderWatchlist: 'shareholderWatchlist'
};
