import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'Components/Icon';

const WidgetRemove = ({ blockAction, onRemoveWidget, className }) => {
    const classNames = cn('font-icon font-icon-clear', className, { inactive: blockAction });

    return (
        <Icon
            className={classNames}
            titleKey='widgets.removeTitle'
            onClick={blockAction ? null : onRemoveWidget}/>
    );
};

WidgetRemove.propTypes = {
    blockAction: PropTypes.bool,
    onRemoveWidget: PropTypes.func,
    className: PropTypes.string
};

export default WidgetRemove;
