import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import './XigniteLink.scss';

const XigniteLink = ({ className, style }) => {
    const linkClassNames = cn('xignite-link', className);

    return (
        <a
            style={style}
            target='_blank'
            className={linkClassNames}
            href='http://www.xignite.com'>
            <FormattedMessage id='common.marketDataByXignite.link'/>
        </a>
    );
};

XigniteLink.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
};

export default XigniteLink;
