import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import SecuritiesField from 'Components/SecuritiesField';
import { CheckBox } from 'Components/CheckBox';

const AuthorizedUserListHeader = props => {
    const { securitiesOptions, onSecuritiesChange, selectedSecurities, onChangeExcludeRM } = props;

    return (<div className='securities-container'>
        <FormattedMessage id='reporting.authorizedUserList.securities.label'/>
        <SecuritiesField
            onSecuritiesChange={onSecuritiesChange}
            options={securitiesOptions}
            selectedValues={selectedSecurities}/>
        <CheckBox
            className='securities-container__checkbox' label='Exclude Relationship Manager'
            onChange={onChangeExcludeRM} />
    </div>);
};

AuthorizedUserListHeader.propTypes = {
    securitiesOptions: PropTypes.object,
    onSecuritiesChange: PropTypes.func,
    selectedSecurities: PropTypes.array,
    onChangeExcludeRM: PropTypes.func
};

export default AuthorizedUserListHeader;
