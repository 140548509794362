import directDepositOptions from 'Constants/direct-deposit-options';

export default [
    {
        value: directDepositOptions.ALL,
        label: 'shareholder.list.directDeposit.all'
    }, {
        value: directDepositOptions.WITH,
        label: 'shareholder.list.directDeposit.with'
    }, {
        value: directDepositOptions.WITHOUT,
        label: 'shareholder.list.directDeposit.without'
    }
];
