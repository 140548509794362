import React, { Component } from 'react';

import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from '../slots';

export default class HistoricalAnalysisPage extends Component {
    render() {
        return (<DynamicPage
            layoutType={layoutTypes.layoutEasy}
            slots={slots}
            dynamicPageType={PAGE_TYPES.HISTORICAL_ANALYSIS}/>);
    }
}
