import { abbreviationKeys, abbreviationValues } from 'Constants/number-abbreviations';

export const defaultVolumeFormat = abbreviationValues[abbreviationKeys.millions];

export const unitsTime = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
};
