import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Nav from 'Components/Nav';
import TreasuryAccountsLink from 'Components/TreasuryAccountsLink';

import './OwnershipPage.scss';

const OwnershipPageView = ({ children, links }) => {
    return (
        <div className='ownership-page empty-space-container'>
            {!DEMO_MODE && (
                <div className='ownership-nav'>
                    <Nav links={links} horizontal secondary/>
                    <TreasuryAccountsLink />
                </div>
            )}
            <div className='page-content'>
                {children}
            </div>
        </div>
    );
};

OwnershipPageView.propTypes = {
    ...Component.propTypes,
    links: PropTypes.array
};

export default OwnershipPageView;
