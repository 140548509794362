import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withState } from '@shakacode/recompose';
import _ from 'lodash';
import cn from 'classnames';
import { connect } from 'react-redux';
import queryString from 'query-string';

import httpStatus from 'Constants/http-status';
import errorReasons from 'Constants/error-reasons';
import AppFooter from 'Components/AppFooter';
import { EquinitiFooter } from 'Components/EquinitiFooter';
import { ModalRoot } from 'Components/Modals';
import Logo from 'Components/Logo';
import BaseErrorPage from '../components/BaseErrorPage';
import pageRoutes from 'Constants/page-routes';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';
import { redirectToLogin } from 'Utils/utils';

import './ErrorPage.scss';

const mapStateToProps = state => ({
    previousLocation: _.get(state, 'routes.previous.location.pathname')
});

@withRouter
@withState(
    'isLogOpen',
    'toggleLog',
    false
)
@connect(mapStateToProps)
class ErrorPage extends Component {
    static propTypes = {
        history: PropTypes.object,
        className: PropTypes.string,
        match: PropTypes.object,
        location: PropTypes.object,
        isLogOpen: PropTypes.bool,
        toggleLog: PropTypes.func,
        previousLocation: PropTypes.string
    };

    toggleLog = () => {
        const { isLogOpen, toggleLog } = this.props;

        toggleLog(!isLogOpen);
    };

    render() {
        const { className, match: { params }, location, history, isLogOpen, previousLocation } = this.props;

        // hiding error details for code with "_" at the end
        let hideDetails = params.code && params.code.endsWith('_');
        let errorCode = +(params.code && params.code.replace('_', ''));
        const errorReasonString = _.get(queryString.parse(location.search), 'reason');
        const errorReason = errorReasonString ? errorReasonString.toUpperCase() : null;

        let texts;
        let bottomErrorCode;
        const additionalClasses = {};

        switch (errorCode) {
            case httpStatus.HTTP_403:
                texts = {
                    errorTittleKey: 'error.accessDenied.title'
                };

                switch (errorReason) {
                    case errorReasons.USER_STATUS_EXCEPTION:
                    case errorReasons.NO_SECURITIES:
                    case errorReasons.ROLE_NOT_FOUND:
                    case errorReasons.ISP_USER_DOES_NOT_EXIST:
                        texts.errorMessageKey = 'error.accessDenied.noIssuerCentral';
                        texts.errorTittleKey = null;
                        texts.actionButtonTextKey = null;
                        errorCode = null;
                        bottomErrorCode = 'error.accessDenied.noIssuerCentral.bottomErrorCode';
                        additionalClasses.errorPage = 'error-page-no-issuer-central';
                        break;

                    default:
                        texts.errorMessageKey = 'error.accessDenied.message';
                        texts.actionButtonTextKey = 'error.button.goBack';
                }
                break;
            case httpStatus.HTTP_404:
                texts = {
                    errorTittleKey: 'error.notFound.title',
                    errorMessageKey: 'error.notFound.message',
                    actionButtonTextKey: 'error.button.goBack'
                };
                break;
            case httpStatus.HTTP_500:
                texts = {
                    errorMessageKey: null,
                    actionButtonTextKey: 'error.button.refreshPage'
                };

                switch (errorReason) {
                    case errorReasons.USER_COMPANY_NOT_CONFIGURED:
                        texts.errorTittleKey = 'error.serverError.userCompanyNotConfigured';
                        texts.actionButtonTextKey = null;
                        break;
                    case errorReasons.DENODO_NOT_AVAILABLE:
                        texts.errorTittleKey = 'error.serverError.denodoNotAvailable';
                        break;
                    case errorReasons.BUSINESS_SERVICES_NOT_AVAILABLE:
                        texts.errorTittleKey = 'error.serverError.businessServicesNotAvailable';
                        break;
                    case errorReasons.SOLR_NOT_AVAILABLE:
                        texts.errorTittleKey = 'error.serverError.solorNotAvailable';
                        break;
                    default:
                        texts.errorTittleKey = 'error.serverError.title';
                        texts.errorMessageKey = 'error.serverError.message';
                }
                break;

            case httpStatus.HTTP_502:
            case httpStatus.HTTP_503:
                texts = {
                    errorTittleKey: 'error.serverIsNotAvailable.title',
                    errorMessageKey: 'error.serverError.message',
                    actionButtonTextKey: 'error.button.refreshPage'
                };
                break;

            default:
                switch (params.code) {
                    case 'NETWORK_ERROR':
                        texts = {
                            errorTittleKey: 'error.networkError.title'
                        };
                        break;
                    case errorReasons.ACCESS_DENIED:
                        texts = {
                            errorMessageKey: 'error.authError.accessDenied',
                            actionButtonTextKey: null
                        };
                        hideDetails = true;
                        break;
                    case errorReasons.SESSION_EXCEPTION:
                        texts = {
                            errorTittleKey: 'error.authError.session.title',
                            errorMessageKey: 'error.authError.session.message',
                            actionButtonTextKey: 'error.authError.session.action'
                        };
                        hideDetails = true;
                        break;
                    default:
                        texts = {
                            errorTittleKey: 'error.unknownError.title',
                            errorMessageKey: 'error.serverError.message',
                            actionButtonTextKey: 'error.button.refreshPage'
                        };
                        break;
                }
                break;
        }

        const errorPageProps = {
            errorCode: errorCode || null,
            ...texts,
            handleAction: () => {
                if (params.code === 'NETWORK_ERROR') {
                    history.replace(buildLocationWithSafeQueryParams(pageRoutes.index, location));
                    window.location.reload();
                    return;
                }

                if (params.code === errorReasons.SESSION_EXCEPTION) {
                    redirectToLogin();
                    return;
                }

                if (errorCode === httpStatus.HTTP_404 || !previousLocation) {
                    history.goBack();
                    return;
                }

                history.replace(previousLocation);
            },
            bottomErrorCode,
            toggleLog: this.toggleLog,
            isLogOpen,
            hideDetails
        };

        return (
            <div className={cn('error-page', className, additionalClasses.errorPage)}>
                <div className='error-page-header'>
                    <Logo/>
                </div>
                <BaseErrorPage {...errorPageProps} />
                {DEMO_MODE ? <EquinitiFooter/> : <AppFooter />}
                <ModalRoot/>
            </div>
        );
    }
}

export default ErrorPage;
