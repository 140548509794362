import PropTypes from 'prop-types';
import React from 'react';
import { SortIndicator } from 'react-virtualized';
import { FormattedMessage } from 'react-intl';
import { some } from 'lodash';

import ColumnFilter from 'Components/Tables/components/ColumnFilter';

const TableHeaderFilter = ({ title, filterValues = [], onHandleFilter, sortDirection, sortBy, dataKey }) => {
    const filterApplied = some(filterValues, option => !option.isSelected);

    return (
        <div className='custom-table-header'>
            <div className='custom-table-header-title'>
                {title}
                <div
                    onClick={(proxy) => proxy.stopPropagation()}
                    className='trigger-wrapper'>
                    <ColumnFilter onSubmit={onHandleFilter} values={filterValues}/>
                </div>
                {filterApplied && <div className='filter-info'>(<FormattedMessage id='tables.filterApplied'/>)</div>}
            </div>
            {sortBy === dataKey && <SortIndicator sortDirection={sortDirection}/>}
        </div>
    );
};

TableHeaderFilter.propTypes = {
    title: PropTypes.string,
    onHandleFilter: PropTypes.func,
    sortDirection: PropTypes.string,
    sortBy: PropTypes.string,
    dataKey: PropTypes.string,
    filterValues: PropTypes.array
};

export default TableHeaderFilter;
