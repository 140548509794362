import * as api from '../../api/help';

export const HELP_REQUEST = 'HELP_REQUEST';
export const HELP_SUCCESS = 'HELP_SUCCESS';
export const HELP_ERROR = 'HELP_ERROR';

export const loadHelpTemplate = locale => dispatch => {
    dispatch({
        type: HELP_REQUEST
    });
    api.loadHelpTemplate(locale)
        .then(
            res => {
                dispatch({
                    type: HELP_SUCCESS,
                    payload: res.data
                });
            },
            error => {
                dispatch({
                    type: HELP_ERROR,
                    payload: { error },
                    error: true
                });

                return Promise.reject(error);
            }
        );
};
