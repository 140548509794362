import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { createReducer } from 'Utils/reducer';

import {
    SET_SHAREHOLDER_TO_COMPARE
} from './actions';

const initialState = {
    // shape: {shareholderId, securityId}
    shareholders: []
};


const actionHandlers = {
    [SET_SHAREHOLDER_TO_COMPARE]: (state, { payload }) => ({
        ...state,
        shareholders: payload
    })
};
const persisConfig = {
    key: 'compareShareholders',
    version: FE_VERSION,
    storage
};

export default persistReducer(persisConfig, createReducer(initialState, actionHandlers));
