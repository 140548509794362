import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';

import { columns, columnNames } from './columnDefinitions';
import {
    CellWithNumber,
    HeadingCellCommon,
    CellWithDate
} from 'Components/widgets/_commonComponents/TableCellComponents';
import ScrollableReactTable from 'Components/Tables/ScrollableReactTable';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import { getSortDirection } from 'Components/Tables/utils';
import { commonWidgetActions, commonWidgetPropTypes } from 'Components/widgets/utils';
import sortDirections from 'Constants/sort-directions';
import { sortArray } from 'Utils/utils';
import { TableStickyColumn } from 'Components/Tables';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

const NullComponent = () => null;
const MAX_ROWS = 25;
const MAX_ROWS_SMALL = 10;
const MIN_COLUMN_WIDTH = 60;

class PerformanceContentTable extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        intl: intlShape,
        small: PropTypes.bool,
        widgetData: PropTypes.shape({
            values: PropTypes.arrayOf(PropTypes.shape({
                date: PropTypes.string,
                open: PropTypes.number,
                close: PropTypes.number,
                high: PropTypes.number,
                low: PropTypes.number,
                volume: PropTypes.number
            }))
        })
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = props;

        this.noDataMessage = formatMessage({ id: 'performance.noDataMessage' });
        this.columns = columns.map(column => {
            const customCell = column.columnName !== columnNames.DATE ?
                (p) => {
                    return (<CellWithNumber
                        value={p.value}
                        additionalData={{
                            decimalPoints: column.columnName === columnNames.VOLUME ? 0 : 2
                        }}/>);
                } :
                (p) => <CellWithDate value={p.value}/>;

            return ({
                Header: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>,
                Cell: customCell,
                accessor: column.columnName,
                minWidth: MIN_COLUMN_WIDTH
            });
        });
        this.smallTableColumns = columns.map(column => {
            switch (column.columnName) {
                case columnNames.DATE:
                    return {
                        bodyCellRenderer: (p) => <CellWithDate value={p.value}/>,
                        dataKey: column.columnName,
                        headerCell: formatMessage({ id: column.displayName }),
                        headerClassName: 'rt-th-first',
                        bodyClassName: 'rt-td',
                        width: 90
                    };
                case columnNames.VOLUME:
                    return {
                        bodyCellRenderer: (p) => {
                            return (<CellWithNumber
                                value={p.value}
                                additionalData={{
                                    decimalPoints: column.columnName === columnNames.VOLUME ? 0 : 2
                                }}/>);
                        },
                        dataKey: column.columnName,
                        headerCell: formatMessage({ id: column.displayName }),
                        headerClassName: 'rt-th-last',
                        bodyClassName: 'rt-td-last',
                        width: 90
                    };
                default:
                    return {
                        bodyCellRenderer: (p) => {
                            return (<CellWithNumber
                                value={p.value}
                                additionalData={{
                                    decimalPoints: column.columnName === columnNames.VOLUME ? 0 : 2
                                }}/>);
                        },
                        dataKey: column.columnName,
                        headerCell: formatMessage({ id: column.displayName }),
                        headerClassName: 'rt-th',
                        bodyClassName: 'rt-td',
                        width: 90
                    };
            }
        });
    }

    handleSort({ id: sortBy }) {
        const { widgetSettings: { sortCriteria: { field: prevSortBy, direction: prevDirection } } } = this.props;
        const sortCriteria = {
            field: sortBy,
            direction: getSortDirection(
                sortBy,
                prevSortBy,
                prevDirection,
                columns
            )
        };

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
    }

    handleSortedChange = (sortProps) => {
        return !this.props.small && this.handleSort(sortProps[0]);
    };

    getTheadThProps = (finalState, missProp, column) => {
        const {
            widgetSettings: {
                sortCriteria: {
                    field: sortField,
                    direction: sortDirection
                }
            } = {}
        } = this.props;

        return {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };
    };

    getNoDataProps = () => ({
        messageId: 'performance.noDataMessage'
    });

    render() {
        const {
            small,
            isPrintMode,
            widgetData: { values = [] } = {},
            widgetSettings: {
                period,
                sortCriteria: {
                    field,
                    direction
                }
            } = {}
        } = this.props;
        const sortedValues = sortArray({
            array: values,
            primaryField: field,
            descending: direction === sortDirections.DESC
        });

        return (
            <div className='performance-content-table'>
                {!small &&
                <div className='labels period-label'>
                    <FormattedMessage id={`performance.periodRange.${period}`}/>
                </div>}
                {small
                    ? <NoDataComponent
                        message={this.noDataMessage}
                        isNoData={!sortedValues.length}
                        isFetching={false}>
                        <TableStickyColumn
                            data={sortedValues}
                            columns={this.smallTableColumns}
                            maxRowCount={MAX_ROWS_SMALL}
                            showAllRows={isPrintMode}
                            minColumnWidth={MIN_COLUMN_WIDTH}/>
                    </NoDataComponent>
                    : <ScrollableReactTable
                        showPagination={false}
                        resizable={false}
                        sortable
                        LoadingComponent={NullComponent}
                        columns={this.columns}
                        onSortedChange={this.handleSortedChange}
                        className='performance-content-table-rt'
                        manual
                        defaultPageSize={0}
                        TdComponent={TdComponent}
                        ThComponent={TheadComponent}
                        getTheadThProps={this.getTheadThProps}
                        NoDataComponent={NoResults}
                        getNoDataProps={this.getNoDataProps}
                        maxShowRows={isPrintMode ? null : MAX_ROWS}
                        resolvedData={sortedValues}/>}
            </div>
        );
    }
}

export default PerformanceContentTable;
