import React from 'react';
import PropTypes from 'prop-types';

import NullableFormatter from 'Components/formatters/NullableFormatter';
import CellWithLink from '../CellWithLink';

const TITLE = 'Proxy Policy Link';

export const CellWithProxyVotingPolicy = ({ value }) => {
    if (!value) {
        return <NullableFormatter />;
    }

    return (
        <CellWithLink
            isExternal
            target='_blank'
            to={value}
            title={TITLE}
            value={TITLE}/>
    );
};

CellWithProxyVotingPolicy.propTypes = {
    value: PropTypes.string
};
