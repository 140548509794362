import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import cn from 'classnames';

import LimitNote from 'Components/LimitNote';

const ConfirmModalView = (props) => {
    const {
        messageKey,
        messageValues,
        onSuccess,
        onCancel,
        okText,
        cancelText,
        isHtmlMessage,
        customClass,
        showExcelLimit
    } = props;
    const MessageFormatter = isHtmlMessage ? FormattedHTMLMessage : FormattedMessage;
    const modalClassName = cn('confirm-modal', customClass);

    return (
        <div className={modalClassName}>
            <div className='modal-message'>
                <MessageFormatter
                    id={messageKey}
                    values={messageValues}/>
            </div>
            <div className='modal-actions'>
                <button
                    type='button'
                    onClick={onSuccess}
                    className='btn btn-large ok-btn'>
                    {okText}
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className='btn btn-large cancel-btn'>
                    {cancelText}
                </button>
            </div>
            {showExcelLimit ? <LimitNote/> : null}
        </div>);
};

ConfirmModalView.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    messageKey: PropTypes.string,
    customClass: PropTypes.string,
    messageValues: PropTypes.object,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    isHtmlMessage: PropTypes.bool,
    showExcelLimit: PropTypes.bool
};

export default ConfirmModalView;
