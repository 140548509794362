import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';

const withFormInput = WrappedComponent => class extends Component {
    static displayName = wrapDisplayName(WrappedComponent, 'withFormInput');

    static propTypes = {
        ...WrappedComponent.propTypes,
        input: PropTypes.shape(fieldInputPropTypes)
    };

    render() {
        const { input, ...rest } = this.props;
        const restProps = _.omit(rest, ['meta']);

        return  <WrappedComponent {...input} {...restProps} />;
    }
};

export default withFormInput;
