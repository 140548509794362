import { columnNames } from './columnDefinitions';

export const HEADER_ROW_HEIGHT = 50;

export const BODY_ROW_HEIGHT = 40;

export const DECIMAL_POINTS = {
    [columnNames.NUMBER_OF_ACCOUNTS]: 0,
    [columnNames.NUMBER_OF_SHARES]: 3,
    percents: 2
};
