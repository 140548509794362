import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import Widget from 'Components/widgets/Widget';
import ContactsTableContent from './components/ContactsTableContent';
import ContactsTableControlPanel from './components/ContactsTableControlPanel';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import WidgetHeaderCompany from 'Components/widgets/Widget/components/WidgetHeaderCompanyDefault';
import ContactsTableHeaderTabs from './components/ContactsTableHeaderTabs';
import linkDefinitions from './constants/linkDefinitions';

import './ContactsTable.scss';

const ROW_LIMIT = 100;

@injectIntl
class ContactsTable extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        const shareholderId = _.get(props, 'widgetPageParams.shareholderId');

        this.widgetComponents = {
            WidgetHeaderCompany,
            WidgetControlPanel: props.isPrintMode ? null : ContactsTableControlPanel,
            WidgetContent: ContactsTableContent
        };

        this.widgetHeaderCompanySettings = {
            hideCompany: !shareholderId
        };

        this.state = {
            currentParams: {
                shareholderId,
                rowLimit: ROW_LIMIT,
                sortCriteria: _.get(props, 'widgetSettings.sortCriteria'),
                currentPage: 1,
                filterTemplate: '',
                myShareholdersOnly: shareholderId ? false : _.get(props, 'widgetSettings.myShareholdersOnly')
            }
        };

        this.widgetEvents = {
            onBuildDataParams: () => ({ tableSettings: this.state.currentParams }), // BE asks to add additional property "tableSettings"
            onUpdateWidgetData: this.onUpdateWidgetData
        };
    }

    onUpdateWidgetData = updatedParams => {
        const currentParams = this.state.currentParams;
        const params = _.assign({}, currentParams, updatedParams);

        if (_.has(updatedParams, 'filterTemplate') && currentParams.filterTemplate !== updatedParams.filterTemplate
            || _.has(updatedParams, 'myShareholdersOnly') && currentParams.myShareholdersOnly !== updatedParams.myShareholdersOnly) {
            params.currentPage = 1; // need to reset current page if filter was applied
        }

        this.setState({ currentParams: { ...params } });
        return this.props.onUpdateWidgetData({ tableSettings: params });
    };

    render() {
        const props = {
            ...this.props,
            widgetSettings: this.props.widgetSettings,
            currentPage: this.state.currentParams.currentPage,
            filterTemplate: this.state.currentParams.filterTemplate,
            myShareholdersOnly: this.state.currentParams.myShareholdersOnly,
            isShareholderContactsView: !!this.state.currentParams.shareholderId,
            widgetNoData: !_.get(this.props, 'widgetData.length'),
            widgetHeaderCompanySettings: this.widgetHeaderCompanySettings,
            rowLimit: ROW_LIMIT
        };

        if (!props.isPrintMode && !props.isDataViz) {
            this.widgetComponents.WidgetHeader = () => (<ContactsTableHeaderTabs
                isShareholderId={!!_.get(this.props, 'widgetPageParams.shareholderId')}
                links={linkDefinitions}/>);
        }

        return (
            <Widget
                {...props}
                className='widget-contacts-table'
                components={this.widgetComponents}
                events={this.widgetEvents}/>
        );
    }
}

export default ContactsTable;
