import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { initialize } from 'redux-form';
import { connect } from 'react-redux';

import SecurityListPageView from '../components/SecurityListPageView';
import * as securitySelectors from 'State/securities/selectors';
import * as issuersSelectors from 'State/issuers/selectors';
import { isSomeSecuritySelected, resetSelectedIssuersAndSecuritiesSearch } from 'State/advancedSearch';
import { PII, SEARCH_FORM_NAME } from 'Constants/search';

const mapStateToProps = state => ({
    securities: securitySelectors.getAvailableSecurities(state),
    issuersAmount: issuersSelectors.getIssuersAmount(state),
    isSomeSecuritySelected: isSomeSecuritySelected(state),
    isAdvancedSearchAvailable: securitySelectors.isAdvancedSearchAvailable(state)
});
const mapDispatchToProps = {
    initialize,
    resetSelectedIssuersAndSecuritiesSearch
};

@connect(mapStateToProps, mapDispatchToProps)
class SecurityListPage extends Component {
    static propTypes = {
        securities: PropTypes.array,
        isSomeSecuritySelected: PropTypes.bool,
        isAdvancedSearchAvailable: PropTypes.bool,
        issuersAmount: PropTypes.number,
        initialize: PropTypes.func,
        resetSelectedIssuersAndSecuritiesSearch: PropTypes.func
    };

    UNSAFE_componentWillMount() {
        this.props.resetSelectedIssuersAndSecuritiesSearch();
        this.props.initialize(SEARCH_FORM_NAME, {
            PII: PII.NONE
        });
    }

    render() {
        const isSecurities = this.props.securities && !!this.props.securities.length;

        return (
            <SecurityListPageView
                isSecurities={isSecurities}
                isSomeSecuritySelected={this.props.isSomeSecuritySelected}
                isAdvancedSearchAvailable={this.props.isAdvancedSearchAvailable}
                issuersAmount={this.props.issuersAmount}/>
        );
    }
}

export default SecurityListPage;
