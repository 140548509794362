import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { hideModal } from 'State/modal';
import SaveChangesModalView from '../components/SaveChangesModalView';

const mapStateToProps = (state) => {
    return { ...state.modal };
};
const mapDispatchToProps = { hideModal };

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
class SaveChangesModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        messageKey: PropTypes.string,
        okTextKey: PropTypes.string,
        cancelTextKey: PropTypes.string,
        payload: PropTypes.any,
        preventCloseOnSuccess: PropTypes.bool
    };

    onSuccess = () => {
        this.props.onSuccess();
        this.props.hideModal();
    };

    onCancel = () => {
        this.props.onCancel();
        this.props.hideModal();
    };

    render() {
        const { intl: { formatMessage }, ...props } = this.props;

        const newProps = {
            messageKey: props.messageKey,
            payload: props.payload,
            okText: formatMessage({ id: props.okTextKey || 'common.yes' }),
            cancelText: formatMessage({ id: props.cancelTextKey || 'common.cancel' }),
            onSuccess: this.onSuccess,
            onCancel: this.onCancel
        };

        return (
            <SaveChangesModalView {...newProps}/>
        );
    }
}

export default SaveChangesModal;
