import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'Components/Tooltip';

const WidgetHeader = ({ widgetTitle, widgetTitleTooltip }) => {
    const tooltip = widgetTitleTooltip ? widgetTitleTooltip : widgetTitle;

    return (
        widgetTitle
            ? <div className='widget-header ellipsis'>
                <Tooltip
                    content={<div>{tooltip}</div>}>
                    <span>{widgetTitle}</span>
                </Tooltip>
            </div>
            : null
    );
};


WidgetHeader.propTypes = {
    widgetTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    widgetTitleTooltip: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
};

export default WidgetHeader;
