import _ from 'lodash';
import { shareholderTypesList } from '../../../constants/shareholder-types';
import { hasAccessToShareholderType } from '../ShareholdersTable/utils';

const defaultSortCriteria = {
    sortField: 'shareholderName',
    sortDirection: 'desc',
    initial: true
};

export const getCurrentSortCriteria = (settings) => {
    const currentWatchlistId = _.get(settings, 'currentWatchlistId');
    const sortCriteria = _.get(settings, ['viewsSortCriteria', currentWatchlistId]);

    return sortCriteria || defaultSortCriteria;
};

export const getCurrentDisplayTypes = (settings) => {
    const currentWatchlistId = _.get(settings, 'currentWatchlistId');
    const currentDisplayTypes = _.get(settings, ['views', currentWatchlistId, 'displayTypes'], []);

    return currentDisplayTypes;
};

export const availableShareholderTypes = ({ userProductSources, userFeatures, displayTypes = [], shareholderTypes = shareholderTypesList }) => {
    const shareholderTypesFilter = shareholderType => hasAccessToShareholderType(userProductSources, shareholderType, userFeatures);

    const availableShareholderTypesList = shareholderTypes.filter(shareholderTypesFilter);
    const availableDisplayTypes = displayTypes.filter(shareholderTypesFilter);

    return {
        availableShareholderTypesList,
        availableDisplayTypes: availableDisplayTypes.length > 0 ? availableDisplayTypes : availableShareholderTypesList
    };
};
