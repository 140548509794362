import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { reduxForm, Field, formPropTypes, focus, touch } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import cn from 'classnames';

import pageRoutes from 'Constants/page-routes';
import {
    fetchSecureUploadRequestById,
    isRequestItemFetchingSelector,
    requestItemSelector,
    isFileUploadingSelector,
    updateUploadRequest,
    isValidAttachedFilesSelector,
    requestIsSubmittedSelector,
    clearRequestState,
    getSecureUploadEmailRecipients
} from 'State/userSecureUpload';
import {
    getSecureUploadRequestCommentMaxSize,
    getSecureUploadRequestDescriptionMaxSize,
    getSecureUploadRequestDescriptionMinSize
} from 'State/user';
import { notifySuccess } from 'State/notifier';
import Textarea from 'Components/Textarea';
import Spinner from 'Components/Spinner';
import { pathWithSecurity } from 'Utils/routing';

import TextInput from 'Components/TextInput';
import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';
import { UploadedFileList } from './UploadedFileList';
import { UploadRequestTemplates } from './components/UploadRequestTemplates';
import UploadRequestPrompt from './components/UploadRequestPrompt';
import { showSelectEmailRecipientsModal } from 'State/modal';
import Popover from 'Components/Popover';
import Icon from 'Components/Icon';

import './UserSecureUploadEditPage.scss';
import { emailRecipientsSelector } from '../../../state/userSecureUpload/selectors';

const formName = 'SECURE_UPLOAD_EDIT_FORM';
const MIN_COMPLETED_FILES = 1;

const mapDispatchToProps = {
    fetchSecureUploadRequestById,
    updateUploadRequest,
    clearRequestState,
    notifySuccess,
    showSelectEmailRecipientsModal,
    getSecureUploadEmailRecipients
};


const mapStateToProps = state => ({
    isRequestItemFetching: isRequestItemFetchingSelector(state),
    requestItem: requestItemSelector(state),
    isFileUploading: isFileUploadingSelector(state),
    filesIsValid: isValidAttachedFilesSelector(state),
    requestIsSubmitted: requestIsSubmittedSelector(state),
    secureUploadRequestDescriptionMaxSize: getSecureUploadRequestDescriptionMaxSize(state),
    secureUploadRequestDescriptionMinSize: getSecureUploadRequestDescriptionMinSize(state),
    secureUploadRequestCommentMaxSize: getSecureUploadRequestCommentMaxSize(state),
    emailRecipients: emailRecipientsSelector(state)
});

const validate = (values, { intl: { formatMessage }, secureUploadRequestDescriptionMinSize }) => {
    const errors = {};

    if (!values.description || !_.trim(values.description)) {
        errors.description = formatMessage({ id: 'userSecureUpload.description.valid.required' });

        return errors;
    }

    if (_.startsWith(values.description, ' ') || _.endsWith(values.description, ' ')) {
        errors.description = formatMessage({ id: 'userSecureUpload.description.valid.spaces' });

        return errors;
    }

    if (_.size(values.description) < secureUploadRequestDescriptionMinSize) {
        errors.description = formatMessage(
            { id: 'userSecureUpload.description.valid.short' },
            { min: secureUploadRequestDescriptionMinSize }
        );

        return errors;
    }

    if (_.isEmpty(values.files) || _.filter(values.files, ['status', uploadedDocumentStatuses.COMPLETE]).length < MIN_COMPLETED_FILES) {
        errors.files = formatMessage({ id: 'userSecureUpload.note' });
    }

    return errors;
};

const UserSecureUploadEditPageView = ({
    match,
    handleSubmit,
    fetchSecureUploadRequestById: fetchRequestById,
    requestItem,
    initialize,
    history,
    updateUploadRequest: updateRequest,
    clearRequestState: clearRequest,
    secureUploadRequestDescriptionMaxSize,
    secureUploadRequestCommentMaxSize,
    notifySuccess: showSuccess,
    showSelectEmailRecipientsModal: showEmailRecipientsModal,
    getSecureUploadEmailRecipients: getEmailRecipients,
    emailRecipients,
    submitting = false,
    isFileUploading = false,
    isRequestItemFetching = false,
    filesIsValid = false,
    valid = false,
    dirty = false
}) => {
    const cancel = useRef();
    const { params: { requestId } } = match;
    const securityListLink = pathWithSecurity(pageRoutes.downloadsUserSecureUploadList);

    useEffect(() => {
        const id = Number(requestId);

        if (isNaN(id)) {
            history.replace('/404');
        }

        getEmailRecipients(id);

        fetchRequestById(id).catch(() => {
            history.replace('/404');
        });
    }, [requestId]);

    useEffect(() => {
        if (requestItem) {
            initialize({
                description: requestItem.description,
                comment: requestItem.comment || ''
            });
        }
    }, [requestItem]);

    useEffect(() => {
        return () => {
            clearRequest();
        };
    }, []);

    const redirectToList = () => {
        cancel.current = true;
        history.push(securityListLink);
    };

    const submitForm = async data => {
        const selectedEmailRecipients = emailRecipients.filter((recipient) => recipient.added);

        const sendData = { ...data, emailRecipients: selectedEmailRecipients };

        await updateRequest(Number(requestId), sendData);

        showSuccess(null, <FormattedHTMLMessage id='userSecureUpload.notifier.save.success' />);
        redirectToList();
    };

    const handleCancel = () => {
        redirectToList();
    };

    const needConfirmToLeave = () => {
        if (cancel.current) {
            return false;
        }

        return dirty;
    };

    return (
        <div className='user-secure-upload-edit-page'>
            {isRequestItemFetching && <Spinner isFetching isBlocker />}
            <Link to={securityListLink} className='back-arrow'>
                <FormattedMessage id='userSecureUpload.backTo.listPage' />
            </Link>
            <h2 className='user-secure-upload-edit-page_header'>
                <FormattedMessage id='userSecureUpload.editPage.title' />
            </h2>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className='form-control__field'>
                    <div className='label-button-container'>
                        <div className='form-control__label'>
                            <FormattedMessage id='userSecureUpload.description.label' />
                            <FormattedMessage id='common.required'>
                                {(txt) => <span className='message-note'>({txt})</span>}
                            </FormattedMessage>
                        </div>
                        <div className='icon-button-container'>
                            <Popover
                                id='EMAIL_RECIPIENTS-POPOVER'
                                trigger={<Icon className='font-icon font-icon-info-outline' />}
                                triggerType={['click', 'hover']}
                                placement='top'>
                                <FormattedMessage id='userSecureUpload.selectEmailRecipients.tooltip' />
                            </Popover>
                            <button onClick={showEmailRecipientsModal} type='button' className='btn btn-large'>
                                <FormattedMessage id='userSecureUpload.selectEmailRecipients.button' />
                            </button>
                        </div>
                    </div>
                    <Field
                        name='description'
                        component={TextInput}
                        labelType='vertical'
                        maxLength={secureUploadRequestDescriptionMaxSize}
                        className='request-description-input' />
                    <div className='message-note'>
                        <FormattedMessage id='userSecureUpload.description.note' values={{ maxLength: secureUploadRequestDescriptionMaxSize }} />
                    </div>
                </div>
                <UploadRequestTemplates />
                <div className='form-control__field'>
                    <Field
                        name='files' component={({ input, meta }) =>
                            (<>
                                <UploadedFileList requestId={requestId} input={input} uploaded/>
                                <div className={cn('message-note', { 'negative': meta.error && meta.touched })}>
                                    <FormattedMessage id='userSecureUpload.note' />
                                </div>
                            </>)
                        } />
                </div>
                <div className='comments-actions-container'>
                    <div className={cn('form-control__field', 'comments_field')}>
                        <div className='form-control__label'>
                            <FormattedMessage id='userSecureUpload.comment.label' />
                        </div>
                        <Field
                            name='comment' component={Textarea} maxLength={secureUploadRequestCommentMaxSize}
                            rows={3} />
                    </div>
                    <div className='user-secure-upload-edit-page__actions' >
                        <button
                            className='btn btn-large'
                            type='submit'
                            disabled={!valid  || isFileUploading || submitting || !filesIsValid}>
                            <FormattedMessage id='common.send' />
                        </button>
                        <button type='button' className='btn btn-large' onClick={handleCancel}>
                            <FormattedMessage id='common.cancel' />
                        </button>
                    </div>
                </div>
            </form>
            <UploadRequestPrompt when={needConfirmToLeave} onSend={handleSubmit(submitForm)} />
        </div>
    );
};

UserSecureUploadEditPageView.propTypes = {
    match: PropTypes.object,
    requestIsSubmitted: PropTypes.bool,
    ...formPropTypes
};

export const UserSecureUploadEditPage = compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: formName,
        validate,
        onSubmitFail: (errors, dispatch) => {
            const fieldNames = _.keys(errors);

            if (fieldNames.length === 0) {
                return;
            }

            const firstSelector = `[name=${fieldNames[0]}]`;
            const element = document.querySelector(firstSelector);

            if (element) {
                element.focus();
                dispatch(focus(formName, fieldNames[0]));
                return;
            }

            if (errors.files) {
                dispatch(touch(formName, 'files'));

                const filesTable = document.querySelector('.files-field');

                if (filesTable) {
                    const button = filesTable.querySelector('button');

                    button && button.focus();

                    filesTable.scrollIntoView();
                }
            }
        }
    }),
    withRouter
)(UserSecureUploadEditPageView);
