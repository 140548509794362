const subscriptionTypes = {
    DAILY_TRANSFER_JOURNALS: 'dailyTransferJournals',
    ESCHEAT_REPORT: 'escheatReport',
    EOM_SHAREHOLDER_LIST: 'eomShareholderList',
    AST_UPLOAD: 'astUpload'
};

export default subscriptionTypes;

export const subscriptionsMap = {
    1: [subscriptionTypes.DAILY_TRANSFER_JOURNALS],
    2: [subscriptionTypes.ESCHEAT_REPORT],
    3: [subscriptionTypes.EOM_SHAREHOLDER_LIST],
    4: [subscriptionTypes.AST_UPLOAD]
};
