import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import WidgetAddToReport from 'Components/widgets/Widget/components/WidgetAddToReport';
import Icon from 'Components/Icon';
import featureTypes from 'Constants/feature-types';

const AnalysisRegularControlPanel = ({
    inactive,
    onExport,
    onAddWidgetToDataVizReport,
    onImpossibleAddToReport,
    currentFeatures
}) => {
    const exportIconClass = cn('font-icon font-icon-download', { inactive });
    const dataVizTools = inactive
        ? (<Icon
            className='font-icon font-icon-add-to-report'
            onClick={onImpossibleAddToReport}/>)
        : (<WidgetAddToReport onAddToReport={onAddWidgetToDataVizReport}/>);

    return (
        <div className='widget-control-panel'>
            <Icon
                className={exportIconClass}
                onClick={inactive ? null : onExport}/>
            {currentFeatures[featureTypes.CUSTOM_REPORTS] && dataVizTools}
        </div>
    );
};

AnalysisRegularControlPanel.propTypes = {
    inactive: PropTypes.bool,
    onExport: PropTypes.func,
    onAddWidgetToDataVizReport: PropTypes.func,
    onImpossibleAddToReport: PropTypes.func,
    currentFeatures: PropTypes.object
};

export default AnalysisRegularControlPanel;
