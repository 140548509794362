import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import SearchResultContextPanel from './SearchResultContextPanel';
import {
    getNonEmptyContexts,
    getSearchQuery,
    getTotalCount,
    isFetchingAny,
    getSearchArea,
    clearShareholdersToCompare,
    toggleAllShareholdersToCompare,
    isSearchTreasurySelector
} from 'State/advancedSearch';

import SearchActionPanel from './SearchActionPanel';

const mapStateToProps = (state) => ({
    isFetching: isFetchingAny(state),
    query: getSearchQuery(state),
    total: getTotalCount(state),
    searchArea: getSearchArea(state),
    nonEmptyContexts: getNonEmptyContexts(state),
    isSearchTreasury: isSearchTreasurySelector(state)
});

const mapDispatchToProps = { toggleAllShareholdersToCompare, clearShareholdersToCompare };

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class SearchResultContentSection extends Component {
    static propTypes = {
        intl: intlShape,
        query: PropTypes.string,
        total: PropTypes.number,
        isFetching: PropTypes.bool,
        nonEmptyContexts: PropTypes.array,
        clearShareholdersToCompare: PropTypes.func,
        searchArea: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            key: PropTypes.string
        }),
        isSearchTreasury: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            accordionStatus: props.nonEmptyContexts.reduce((acc, next) => ({ ...acc, [next]: true }), {}),
            collapseAllState: true
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.nonEmptyContexts !== nextProps.nonEmptyContexts) {
            this.setState({
                accordionStatus: nextProps.nonEmptyContexts.reduce((acc, next) => ({ ...acc, [next]: true }), {}),
                collapseAllState: true
            });
        }
    }

    componentWillUnmount() {
        this.props.clearShareholdersToCompare();
    }

    handleCollapseAll = () => {
        this.setState((prevState) => ({
            accordionStatus: this.props.nonEmptyContexts.reduce((acc, next) => ({
                ...acc,
                [next]: !prevState.collapseAllState
            }), {}),
            collapseAllState: !prevState.collapseAllState
        }));
    };

    handleChangeAccordionStatus = (context, isOpen) => {
        const accordionStatus = {
            ...this.state.accordionStatus,
            [context]: isOpen
        };
        const isAllCollapsed = _.every(_.values(accordionStatus), v => !v);
        const isAllExpanded = _.every(_.values(accordionStatus), v => v);

        this.setState((prevState) => {
            let collapseAllState = prevState.collapseAllState;
            // this handler is called 2 times, second time we should not change collapseAllState
            const isStateChanged = accordionStatus[context] !== prevState.accordionStatus[context];

            if (isAllCollapsed) {
                collapseAllState = false;
            }
            if (isAllExpanded) {
                collapseAllState = true;
            }

            return isStateChanged
                ? {
                    accordionStatus,
                    collapseAllState
                }
                : prevState;
        });
    };

    getQueryKey = () => this.props.total > 0 ? 'search.resultsTitle' : 'search.noResults';

    getFromText() {
        const { searchArea, intl } = this.props;

        return searchArea.name || searchArea.key && intl.formatMessage({ id: searchArea.key });
    }

    render() {
        const {
            query, total, nonEmptyContexts, isSearchTreasury
        } = this.props;
        const titleKey = query ? this.getQueryKey() : 'search.incorrectQuery';
        const fromText = this.getFromText();

        return (
            <div className='search-result-content-section'>
                <h2 className='search-title'>
                    {isSearchTreasury
                        ? <FormattedMessage id='search.treasuryResultsTitle' values={{ total, from: fromText }}/>
                        : <FormattedMessage id={titleKey} values={{ query, total, from: fromText }}/>
                    }
                </h2>
                {total > 0 && <SearchActionPanel
                    collapseAllState={this.state.collapseAllState}
                    onCollapseAll={this.handleCollapseAll}/>}
                {nonEmptyContexts.map((category, i) => (<SearchResultContextPanel
                    open={this.state.accordionStatus[category]}
                    onChangeStatus={this.handleChangeAccordionStatus}
                    key={category}
                    contextType={category}
                    isFirst={i === 0}/>)
                )}
            </div>
        );
    }
}

export default SearchResultContentSection;
