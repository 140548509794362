import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import MainLayout from 'Layouts/MainLayout';
import { withAccept, withAnnouncement } from 'Routes/hoc';
import BaseRoute from './BaseRoute';
import pageRoutes from 'Constants/page-routes';
import * as pages from 'Pages';
import { SecurityProtectedRoutes } from 'Routes';
import featureTypes from 'Constants/feature-types';

@withAccept
@withAnnouncement
class AcceptedUserRoutes extends Component {
    static propTypes = {
        location: PropTypes.object
    };

    render() {
        return (
            <Switch>
                <BaseRoute path={`${pageRoutes.securityList}`} component={pages.SecurityListPage}/>
                <MainLayout>
                    <Switch>
                        <BaseRoute path={`${pageRoutes.searchResult}`} component={pages.SearchResultPage}/>
                        <BaseRoute
                            path={`${pageRoutes.issuerResources}`}
                            component={pages.IssuerResourcesPage}
                            requiredFeature={featureTypes.GLOBAL_LINKS}/>
                        <BaseRoute path={`${pageRoutes.myProfile}`} component={pages.MyProfilePage}/>
                        <BaseRoute path={`${pageRoutes.userProfile}`} component={pages.UserProfilePage}/>
                        <BaseRoute path={`${pageRoutes.search}`} component={pages.SearchPage}/>
                        <BaseRoute path={`${pageRoutes.compareShareholders}`} component={pages.CompareShareholdersPage}/>
                        <BaseRoute path={`${pageRoutes.combineShareholders}`} component={pages.CombineShareholdersPage}/>
                        <SecurityProtectedRoutes/>
                        <Redirect to={`${pageRoutes.error}/404`}/>
                    </Switch>
                </MainLayout>
            </Switch>
        );
    }
}

export default AcceptedUserRoutes;
