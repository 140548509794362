import React, { useMemo } from 'react';
import _ from 'lodash';

import WatchlistContent from './components/WatchlistContent/index';
import WatchlistDataLoader from './components/WatchlistDataLoader';
import WatchlistActionPanel from './components/WatchlistActionPanel';
import WatchlistHeader from './components/WatchlistHeader';
import WatchlistFooter from './components/WatchlistFooter';
import { columnDefinitions } from './columnDefinitions';
import Widget from '../Widget';
import { commonWidgetActions, commonWidgetPropTypes } from '../utils';
import usePrintWidget from '../../../hooks/usePrintWidget';

import './WatchlistWidget.scss';

const widgetComponents = {
    WidgetHeader: WatchlistHeader,
    WidgetContent: WatchlistContent,
    WidgetDataLoader: WatchlistDataLoader,
    WidgetActionPanel: WatchlistActionPanel,
    WidgetFooter: WatchlistFooter
};

const WatchlistWidget = (props) => {
    const availableColumns = useMemo(
        () => {
            return _.sortBy(columnDefinitions, ['order']);
        },
        []
    );

    const { printRef, onPrint } = usePrintWidget();

    return (
        <Widget
            widgetLayoutRef={printRef}
            {...props}
            onPrint={onPrint}
            availableColumns={availableColumns}
            className='watchlist-widget'
            components={widgetComponents} />
    );
};

WatchlistWidget.propTypes = {
    ...commonWidgetPropTypes,
    ...commonWidgetActions
};

export default WatchlistWidget;
