export default {
    POSITION: 'position',
    CURRENT_BALANCE: 'currentBalance',
    POSITION_CHANGE: 'positionChange',
    PREVIOUS_POSITION: 'previousPosition',
    PREVIOUS_POSITION_DATE: 'previousPositionDate',
    COST_BASIS: 'costBasis',
    ACTIVE_VS_PASSIVE: 'activeVsPassive',
    PURCHASING_POWER: 'purchasingPower',
    SHAREHOLDER_NAME: 'shareholderName',
    FUND_NAME: 'fundName',
    F13: '13f',
    PREVIOUS13F: 'previous13f',
    F13CHANGE: '13fChange',
    YTD_CHANGE: 'ytdChange',
    PROXY_ADVISORS: 'proxyAdvisors',
    STATUS: 'status',
    STATE: 'state',
    DIRECT_DEPOSIT: 'directDeposit',
    MKT_VAL: 'mktVal',
    TOTAL_VALUE: 'totalValue',
    PORTFOLIO_PERCENT: 'portfolioPercent',
    ASSETS_UNDER_MANAGEMENT: 'assetsUnderManagement',
    TURNOVER: 'turnover',
    OPENED_DATE: 'openedDate',
    CLOSED_DATE: 'closedDate',
    POSITION_DATE: 'positionDate',
    CERTIFIED_SHARES: 'certifiedShares',
    BOOK_SHARES: 'bookShares',
    DRIP_SHARES: 'dripShares',
    OS: 'os',
    ACCOUNT_NUMBER: 'accountNumber',
    CUMULATIVE_OS: 'cumulativeOs',
    PROXY_VOTING_POLICY: 'proxyVotingPolicy',
    PROXY_CONTACTS: 'isContactExists',
    CHANGE: 'change',
    INVESTOR_NAME: 'investorName',
    COUNTRY: 'country',
    HOLDER_TYPE: 'holderType'
};
