import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';

export const getUserInfo = (securityId) => {
    const requestData = utils.buildRequest({ securityId }, requestTypes.getUserInfoPayload);

    return axios.post(apiRoutes.getUserInfoUrl, requestData, {
        errorStrategy: errorStrategies.CRITICAL,
        isCancelable: false
    });
};

export const getSecurities = (featuresOfInterest) => {
    const requestData = utils.buildRequest({ featuresOfInterest }, requestTypes.getSecuritiesPayload);

    return axios.post(apiRoutes.getSecuritiesUrl, requestData, {
        errorStrategy: errorStrategies.CRITICAL,
        isCancelable: false
    });
};

export const changeCurrentSecurity = securityId => {
    const requestData = utils.buildRequest({ id: securityId }, requestTypes.changeSecurityPayload);

    return axios.post(apiRoutes.changeSecurityUrl, requestData, {
        isCancelable: false
    });
};

export const acceptUser = () => {
    const requestData = utils.buildRequest({}, requestTypes.acceptUserTerms);

    return axios.post(apiRoutes.acceptUserTerms, requestData, {
        errorStrategy: errorStrategies.CRITICAL,
        isCancelable: false
    });
};

export function getUserNotifications() {
    const requestData = utils.buildRequest({}, requestTypes.getUserNotificationsPayload);

    return axios.post(apiRoutes.getUserNotificationsUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false,
        isRetryable: false,
        withoutRefreshSession: true
    });
}

export function changeUserNotifications(newNotificationValues) {
    const payload = {
        notifications: {
            ...newNotificationValues
        }
    };
    const requestData = utils.buildRequest(payload, requestTypes.changeUserNotificationsPayload);

    return axios.post(apiRoutes.changeUserNotificationsUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
}

export const getTermsAndConditions = typeOfTerms => {
    const payload = {
        typeOfTerms
    };
    const requestData = utils.buildRequest(payload, requestTypes.getTermsAndConditionsPayload);

    return axios.post(apiRoutes.getTermsAndConditionsUrl, requestData, {
        errorStrategy: errorStrategies.CRITICAL,
        isCancelable: false
    });
};

export const evictUserCache = () => {
    const requestData = utils.buildRequest({}, requestTypes.evictUserCachePayload);

    return axios.post(apiRoutes.evictUserCacheUrl, requestData, {
        isCancelable: true
    });
};

export const acceptAnnouncement = () => {
    const requestData = utils.buildRequest({}, requestTypes.acceptAnnouncementPayload);

    return axios.post(apiRoutes.acceptAnnouncementUrl, requestData, {
        isCancelable: false
    });
};

export const getTreasuryAccess = () => {
    const requestData = utils.buildRequest({}, requestTypes.getTreasuryAccessPayload);

    return axios.post(apiRoutes.getTreasuryAccessUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};
