import { forceLogout } from 'Utils/utils';
import store from './store';
import { getGlobalSettings } from 'State/user';
import { showConfirmModal, hideModal } from 'State/modal';
import { login } from '../api/auth';

const { getState, dispatch } = store;

let sessionTimer = null;
let sessionNotificationTimer = null;

const setUserSession = () => {
    const { sessionNotificationTimeout, sessionLogoutTimeout } = getGlobalSettings(getState());

    if (sessionLogoutTimeout) sessionTimer = setTimeout(() => forceLogout(), sessionLogoutTimeout * 1000);

    if (sessionNotificationTimeout) {
        sessionNotificationTimer = setTimeout(() => dispatch(
            showConfirmModal({
                messageKey: 'modals.sessionTimeoutNotification.message',
                okTextKey: 'common.yes',
                cancelTextKey: 'common.no',
                onSuccess: () => {
                    login(); // sending a dumb request just to refresh user session in PING
                    hideModal();
                },
                onCancel: forceLogout,
                shouldCloseOnOverlayClick: false
            })
        ), sessionNotificationTimeout * 1000);
    }
};

export const refreshUserSession = () => {
    sessionTimer && clearTimeout(sessionTimer);
    sessionNotificationTimer && clearTimeout(sessionNotificationTimer);

    setUserSession();
};
