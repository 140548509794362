/* eslint-disable react/no-multi-comp */
import React from 'react';

import { CellWithLink } from 'Components/widgets/_commonComponents/TableCellComponents';
import sortDirections from 'Constants/sort-directions';
import pageRoutes from 'Constants/page-routes';
import shareholderType from 'Constants/shareholder-types';

const columnNames = {
    NAME: 'name',
    TITLE: 'title',
    ORGANIZATION: 'organization',
    HOLDER_TYPE: 'holderType',
    PHONE: 'phone',
    EMAIL: 'email',
    CITY: 'city',
    COUNTRY: 'country'
};

// this values expect DB for sorting
const sortProps = {
    NAME: 'Name',
    TITLE: 'Title',
    ORGANIZATION: 'Organization',
    HOLDER_TYPE: 'Holder_Type',
    PHONE: 'Phone1',
    EMAIL: 'Email1',
    CITY: 'City',
    COUNTRY: 'Country'
};

const columns = [
    {
        displayName: 'contactsTable.columns.name',
        columnName: columnNames.NAME,
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.NAME,
        cellComponent: (cellProps, additionalProps) => {
            const { value: cellValue, row } = cellProps;
            const linkState = {
                isShareholderContactsView: additionalProps.isShareholderContactsView
            };

            return (
                <CellWithLink
                    to={`${pageRoutes.contact}/${row.shareholderId}/${row.contactId}`}
                    value={cellValue}
                    className='contact-details-link'
                    state={linkState}/>
            );
        }
    },
    {
        displayName: 'contactsTable.columns.title',
        columnName: columnNames.TITLE,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.TITLE
    },
    {
        displayName: 'contactsTable.columns.organization',
        columnName: columnNames.ORGANIZATION,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.ORGANIZATION,
        cellComponent: (cellProps, additionalProps) => {
            const { value: cellValue, row } = cellProps;
            const { shareholderId } = additionalProps;

            return (
                <CellWithLink
                    to={`${pageRoutes[shareholderType.INSTITUTION]}/${row.shareholderId}`}
                    value={cellValue}
                    returnPath={`${pageRoutes.ownershipContactsLanding}${shareholderId ? `/${shareholderId}` : ''}`}/>
            );
        }
    },
    {
        displayName: 'contactsTable.columns.holderType',
        columnName: columnNames.HOLDER_TYPE,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.HOLDER_TYPE
    },
    {
        displayName: 'contactsTable.columns.phone',
        columnName: columnNames.PHONE,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.PHONE
    },
    {
        displayName: 'contactsTable.columns.email',
        columnName: columnNames.EMAIL,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.EMAIL,
        cellComponent: cellProps => {
            const { value: cellValue } = cellProps;

            return (
                <CellWithLink
                    to={`mailto:${cellValue}`}
                    isExternal
                    value={cellValue}/>
            );
        }
    },
    {
        displayName: 'contactsTable.columns.city',
        columnName: columnNames.CITY,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.CITY
    },
    {
        displayName: 'contactsTable.columns.country',
        columnName: columnNames.COUNTRY,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.COUNTRY,
        additionalData: {
            alignCenter: true
        }
    }
];

const columnsOrder = {
    [columnNames.NAME]: 1,
    [columnNames.TITLE]: 2,
    [columnNames.ORGANIZATION]: 3,
    [columnNames.HOLDER_TYPE]: 4,
    [columnNames.PHONE]: 5,
    [columnNames.EMAIL]: 6,
    [columnNames.CITY]: 7,
    [columnNames.COUNTRY]: 8
};

export {
    columns,
    columnNames,
    columnsOrder,
    sortProps
};
