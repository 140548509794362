import PropTypes from 'prop-types';
import React, { Component } from 'react';

import StaticLayout from 'Components/StaticLayout';

class WithWidgetModal extends Component {
    static propTypes = {
        slotId: PropTypes.number
    };

    render() {
        const { slotId } = this.props;

        return (
            <div className='with-widget-modal'>
                <StaticLayout slotId={slotId}/>
            </div>
        );
    }
}

export default WithWidgetModal;
