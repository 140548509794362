import { useMemo } from 'react';

import { sortArray } from 'Utils/utils';
import sortDirections from 'Constants/sort-directions';

import columnTypes from '../../ShareholdersTable/constants/shareholder-column-types';
import { compareAccountNumbers } from '../../ShareholdersTable/utils';
import { compareMktVals } from '../../utils';
import _ from 'lodash';

const fieldComparator = {
    [columnTypes.ACCOUNT_NUMBER]: compareAccountNumbers,
    [columnTypes.MKT_VAL]: compareMktVals
};

const availableShareholderTypesFilter = (availableTypes) => (shareholder) => {
    return availableTypes.some((type) => {
        const { shareholderType, accessible } = shareholder;

        if (accessible === false) {
            return true;
        }

        if (!_.isString(type) || !_.isString(shareholderType)) return false;

        return type.toUpperCase() === shareholderType.toUpperCase();
    });
};


const useSortedShareholders = ({ widgetData = [], sortCriteria, availableDisplayTypes = [] }) => {
    return useMemo(() => {
        const comparator = fieldComparator[sortCriteria.sortField];

        const shareholderFilter = availableShareholderTypesFilter(availableDisplayTypes);

        const values = availableDisplayTypes.length === 0 ? widgetData : widgetData.filter(shareholderFilter);

        const sortedValueByCriteria = sortArray({
            array: values,
            primaryField: sortCriteria.sortField,
            descending: sortCriteria.sortDirection === sortDirections.DESC,
            secondaryField:  columnTypes.SHAREHOLDER_NAME,
            secondaryDescending: false,
            primaryComparator: comparator,
            initial: sortCriteria.inital
        });

        return _.orderBy(sortedValueByCriteria, ['accessible'], ['desc']);
    }, [widgetData, sortCriteria, availableDisplayTypes]);
};


export default useSortedShareholders;
