import { createReducer } from 'Utils/reducer';
import {
    USER_SECURE_UPLOAD_GET_ALL_REPORTS_REQUEST,
    USER_SECURE_UPLOAD_GET_ALL_REPORTS_SUCCESS,
    USER_SECURE_UPLOAD_GET_ALL_REPORTS_ERROR,
    USER_SECURE_UPLOAD_CREATE_DRAFT_REQUEST,
    USER_SECURE_UPLOAD_CREATE_DRAFT_ERROR,
    USER_SECURE_UPLOAD_FINISH_UPLOADING,
    USER_SECURE_UPLOAD_START_UPLOADING,
    USER_SECURE_UPLOAD_GET_REQUEST_SUCCESS,
    USER_SECURE_UPLOAD_GET_REQUEST_FETCHING,
    USER_SECURE_UPLOAD_GET_REQUEST_ERROR,
    USER_SECURE_UPLOAD_UPDATE_UPLOAD_STATUS,
    USER_SECURE_UPLOAD_CLEAR_REQUEST_STATE,
    USER_SECURE_UPLOAD_DELETE_UPLOADED_DOCUMENT,
    USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_FETCHING,
    USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_SUCCESS,
    USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_ERROR,
    USER_SECURE_UPLOAD_UPDATE_EMAIL_RECIPIENTS
} from './actions';

export const initialState = {
    requests: [],
    isFetching: false,
    error: null,
    isCreatingDraft: false,
    requestItem: null,
    isRequestItemFetching: false,
    requestFiles: [],
    isFileUploading: false,
    emailRecipients: []
};

const actionHandlers = {
    [USER_SECURE_UPLOAD_GET_ALL_REPORTS_REQUEST]: (state) => ({
        ...state,
        requests: [],
        isFetching: true,
        error: false
    }),
    [USER_SECURE_UPLOAD_GET_ALL_REPORTS_SUCCESS]: (state, { payload: requests }) => ({
        ...state,
        requests,
        isFetching: false
    }),
    [USER_SECURE_UPLOAD_GET_ALL_REPORTS_ERROR]: (state, { payload: error }) => ({
        ...state,
        isFetching: false,
        error
    }),
    [USER_SECURE_UPLOAD_CREATE_DRAFT_REQUEST]: (state) => ({
        ...state,
        isCreatingDraft: true
    }),
    [USER_SECURE_UPLOAD_CREATE_DRAFT_ERROR]: (state) => ({
        ...state,
        isCreatingDraft: false
    }),
    [USER_SECURE_UPLOAD_GET_REQUEST_FETCHING]: (state) => ({
        ...state,
        isRequestItemFetching: true
    }),
    [USER_SECURE_UPLOAD_GET_REQUEST_SUCCESS]: (state, { payload }) => ({
        ...state,
        requestItem: payload,
        isRequestItemFetching: false
    }),
    [USER_SECURE_UPLOAD_GET_REQUEST_ERROR]: (state) => ({
        ...state,
        isRequestItemFetching: false
    }),
    [USER_SECURE_UPLOAD_START_UPLOADING]: (state) => ({
        ...state,
        isFileUploading: true
    }),
    [USER_SECURE_UPLOAD_FINISH_UPLOADING]: (state) => ({
        ...state,
        isFileUploading: false
    }),
    [USER_SECURE_UPLOAD_UPDATE_UPLOAD_STATUS]: (state, { payload }) => ({
        ...state,
        requestFiles: payload
    }),
    [USER_SECURE_UPLOAD_CLEAR_REQUEST_STATE]: (state) => ({
        ...state,
        requestItem: null,
        requestFiles: []
    }),
    [USER_SECURE_UPLOAD_DELETE_UPLOADED_DOCUMENT]: (state, { payload: documentId }) => {
        return { ...state, requestFiles: state.requestFiles.filter(({ id }) => id !== documentId) };
    },
    [USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_FETCHING]: (state) => ({
        ...state,
        isFetching: true
    }),
    [USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        emailRecipients: payload,
        isFetching: false
    }),
    [USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_ERROR]: (state) => ({
        ...state,
        isFetching: false
    }),
    [USER_SECURE_UPLOAD_UPDATE_EMAIL_RECIPIENTS]: (state, { payload }) => ({
        ...state,
        emailRecipients: payload
    })
};

export default createReducer(initialState, actionHandlers);
