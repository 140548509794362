import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import TableEasy from 'Components/Tables/TableEasy';
import Spinner from 'Components/Spinner/Spinner';
import DownloadsMessageNote from './DownloadsMessageNote';

import './ReportingDownloadsPage.scss';

const ReportingDownloadsPageView = (props) => {
    const {
        columns,
        data,
        headerRowHeight,
        bodyRowHeight,
        isFetching,
        onSort,
        sortCriteria: { field: sortField, direction: sortDirection } } = props;

    return (
        <div className='reporting-downloads-page'>
            <h2 className='reporting-header'>
                <FormattedMessage id='reporting.downloads.title'/>
            </h2>
            <div className='reporting-section'>
                { isFetching
                    ? <Spinner isFetching={isFetching}/>
                    : <div>
                        <DownloadsMessageNote/>
                        <TableEasy
                            headerRowHeight={headerRowHeight}
                            bodyRowHeight={bodyRowHeight}
                            onSort={onSort}
                            sortBy={sortField}
                            sortDirection={sortDirection.toUpperCase()}
                            columns={columns}
                            showAllRows
                            data={data}/>
                    </div>
                }
            </div>
        </div>
    );
};

ReportingDownloadsPageView.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    headerRowHeight: PropTypes.number,
    bodyRowHeight: PropTypes.number,
    storagePeriod: PropTypes.number,
    transferJournalPeriod: PropTypes.number,
    isActiveTJSubscription: PropTypes.bool,
    isFetching: PropTypes.bool,
    sortCriteria: PropTypes.object,
    onSort: PropTypes.func
};

ReportingDownloadsPageView.defaultProps = {
    headerRowHeight: 40,
    bodyRowHeight: 40
};

export default ReportingDownloadsPageView;
