import React, { Component } from 'react';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { columnsDefinitions, sectionsDefinitions, sectionTypes } from './constants';
import { RegisteredShareholderAccordionTable } from '../_commonComponents/RegisteredShareholderTable';
import { withFilteredDataForAccordion, withAccordion } from '../_commonComponents/RegisteredShareholderTable/hoc/withAccordion';
import taxLotTypes from './constants/tax-lot-types';

import './RegisteredShareholderCurrent.scss';

@withAccordion(sectionTypes)
@withFilteredDataForAccordion
@injectIntl
class RegisteredShareholderCurrent extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    render() {
        const { intl: { formatMessage }, ...props } = this.props;
        const columnDefinitions = props.expandedSection === taxLotTypes.FUNGIBLE
            ? columnsDefinitions.filter(column => !column.onlyForNonfungible)
            : columnsDefinitions;
        const widgetData = _.get(props, `widgetData[${props.expandedSection}]`);
        const totalPages = _.get(widgetData, 'totalPages');
        const currentPage = _.get(widgetData, 'filterCriteria.currentPage');
        const footerProps = {
            visible: totalPages === currentPage,
            sharesTotal: _.get(widgetData, 'aggregate.totalShares'),
            label: formatMessage({ id: 'registered.shareholder.footer.sharesTotal' })
        };
        const widgetNoData = !_.findKey(props.widgetData, data => _.get(data, 'values.length') > 0);

        return (
            <RegisteredShareholderAccordionTable
                {...props}
                widgetData={widgetData}
                className='registered-shareholder-current'
                data={_.get(widgetData, 'values')}
                columnsDefinitions={columnDefinitions}
                sectionsDefinitions={sectionsDefinitions}
                footerProps={footerProps}
                widgetNoData={widgetNoData}/>
        );
    }
}

export default RegisteredShareholderCurrent;
