import taxLotTypes from './tax-lot-types';

export default [
    {
        id: taxLotTypes.FUNGIBLE,
        titleId: 'registeredOverview.taxLots.fungible.title',
        titleAdditionalInfoId: '',
        descriptionId: 'registeredOverview.taxLots.fungible.description'
    },
    {
        id: taxLotTypes.NON_FUNGIBLE,
        titleId: 'registeredOverview.taxLots.nonFungible.title',
        titleAdditionalInfoId: 'registeredOverview.taxLots.nonFungible.titleAdditionalInfo',
        descriptionId: 'registeredOverview.taxLots.nonFungible.description'
    }
];

export const sectionTypes = [
    taxLotTypes.FUNGIBLE,
    taxLotTypes.NON_FUNGIBLE
];
