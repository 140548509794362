import React, { Component } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { withState } from '@shakacode/recompose';
import { get, some, isNil } from 'lodash';

import { commonWidgetActions, commonWidgetPropTypes } from '../utils';
import Widget from '../Widget';
import ShareholderElectionsContent from './components/ShareholderElectionsContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import PeriodActionPanel from '../_commonComponents/PeriodActionPanel';
import { getDates, getFilteredData, getSelectedDate } from './selectors';

import './ShareholderElections.scss';

@withState(
    'selectedDate',
    'onMonthChange'
)
@injectIntl
class ShareholderElections extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: ShareholderElectionsContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetActionPanel: PeriodActionPanel
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'shareholder.elections.title' });
        this.widgetTitleTooltip = <FormattedHTMLMessage id='shareholder.elections.tooltip.title'/>;
    }

    render() {
        const { props } = this;
        const dates = getDates(props);
        const selectedDate = getSelectedDate(props);
        const data = getFilteredData(props);
        const widgetNoDividend = !get(props.widgetData, 'dividendPayer');
        const widgetEmptyData = !get(data, 'aggregate.totalNumberOfElections');
        const widgetEmptyAllData = !some(get(props.widgetData, 'values'), mounth => !isNil(get(mounth, 'aggregate.totalNumberOfElections')));
        const { widgetSettings } = props;

        const widgetProps = {
            ...props,
            widgetTitle: this.widgetTitle,
            widgetTitleTooltip: this.widgetTitleTooltip,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            },
            widgetNoData: !get(data, 'values.length'),
            widgetNoDividend,
            widgetEmptyData,
            isActionPanelDisabled: widgetEmptyAllData,
            widgetSettings: {
                ...widgetSettings,
                mode: widgetEmptyData ? 'table' : widgetSettings.mode
            },
            isTogglingModeBlocked: widgetEmptyData,
            isAddToReportDisabled: widgetEmptyData,
            data,
            dates,
            selectedDate
        };

        return (
            <Widget
                {...widgetProps}
                className='widget-shareholder-elections'
                components={this.widgetComponents}/>
        );
    }
}

export default ShareholderElections;
