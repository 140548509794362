import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';

import * as widgetsUtils from 'Components/widgets/utils';
import Slot from 'Components/Slot';
import { Layout } from 'Components/layout';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';

import './LayoutDataViz.scss';

const mapStateToProps = state => ({
    widgetTypes: dynamicPageSelectors.widgetTypesBySlotsSelector(state)
});

@connect(mapStateToProps)
class LayoutDataViz extends Component {
    static propTypes = {
        slots: PropTypes.object,
        widgetTypes: PropTypes.object
    };

    getSlotSizeClassName = (slotId) => {
        const widgetType = _.get(this.props.widgetTypes[slotId], 'widgets[0]');
        const slotSize = widgetsUtils.getSlotSizeByWidgetType(widgetType);

        return `col-${slotSize}`;
    };

    render() {
        return (
            <Layout className='layout-dataviz' wrap>
                {_.values(this.props.slots).map((slot, i) => (
                    <Slot
                        key={i}
                        slotId={i + 1}
                        className={cn('dataviz-slot', this.getSlotSizeClassName(i + 1))}
                        {...slot}/>
                ))}
            </Layout>
        );
    }
}

export default LayoutDataViz;
