import React from 'react';
import { FormattedMessage } from 'react-intl';

import ContactsTable from 'Components/widgets/_commonComponents/ContactsTable';
import * as columnDefinitions from './constants/columnDefinitions';

const ContactsLanding = props => {
    const widgetTitle = (<FormattedMessage id='contactsTable.title'/>);
    const noDataMessageId = 'widgets.noDataMessage.matchingContacts';

    return (<ContactsTable
        {...props}
        widgetTitle={widgetTitle}
        columnDefinitions={columnDefinitions}
        noDataMessageId={noDataMessageId}/>);
};

export default ContactsLanding;
