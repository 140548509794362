const WIDGET_TYPE_NOT_DETECTED = 'WIDGET_TYPE_NOT_DETECTED';
const WIDGET_SETTINGS_NOT_FOUND = 'WIDGET_SETTINGS_NOT_FOUND';

export const invalidCasesNotifiers = {
    [WIDGET_TYPE_NOT_DETECTED]: (widgetName, notifyAction) => {
        notify('WidgetBox TYPE warning', 'Widget was not detected', widgetName, notifyAction);
    },
    [WIDGET_SETTINGS_NOT_FOUND]: (widgetName, notifyAction) => {
        notify('WidgetBox SETTINGS warning', 'No settings were found', widgetName, notifyAction);
    }
};

export const checkWidgetReceivedPropsNotEnough = (props) => {
    const { widgetName, widgetType, widgetSettings } = props;

    if (widgetName && !widgetType) {
        return WIDGET_TYPE_NOT_DETECTED;
    }
    if (widgetType && !widgetSettings) {
        return WIDGET_SETTINGS_NOT_FOUND;
    }

    return false;
};

const notify = (caseTitle, caseMessage, widgetName, notifyAction) => {
    const errorMessage = `${caseMessage} for: ${widgetName}`;

    console.warn(errorMessage);

    if (typeof notifyAction === 'function') {
        notifyAction(caseTitle, errorMessage);
    }
};
