import { FILTERS } from 'Constants/search';
import _ from 'lodash';

import { securityFullNameFormatter } from 'Components/formatters/formatters';
import { getAvailableSecurities } from 'State/securities/selectors';
import { getAdvancedSearchSecurities } from 'State/advancedSearch/selectors';

const statusFilters = [FILTERS.Open, FILTERS.Closed];
const countryFilters = [FILTERS.US, FILTERS.Foreign, FILTERS.Undefined];
const filtersInLowerCase = _.mapKeys(FILTERS, val => val.toLowerCase());

const updateSubFilters = (subFilters) => (totalCriteria) => {
    return _.reduce(totalCriteria, (result, total, criteria) => {
        if (total && subFilters.includes(criteria)) {
            return {
                ...result,
                [criteria]: false
            };
        }
        return result;
    }, {});
};

const updateStatuses = updateSubFilters(statusFilters);
const updateCountries = updateSubFilters(countryFilters);

const normalizeTotalCriteria = (totalCriteria) => {
    return _.mapKeys(totalCriteria, (value, key) => filtersInLowerCase[key]);
};

// updating filters depends on results
export const updateFilters = (results) => {
    return _.reduce(results, (resultFilters, result) => {
        if (!result.total) {
            return resultFilters;
        }

        if (result.totalCriteria) {
            const totalCriteria = normalizeTotalCriteria(result.totalCriteria);

            return {
                ...resultFilters,
                statuses: {
                    ...resultFilters.statuses,
                    ...updateStatuses(totalCriteria)
                },
                countries: {
                    ...resultFilters.countries,
                    ...updateCountries(totalCriteria)
                },
                contexts: {
                    ...resultFilters.contexts,
                    [result.type]: false
                }
            };
        }

        return {
            ...resultFilters,
            contexts: {
                ...resultFilters.contexts,
                [result.type]: false
            }
        };
    }, {
        contexts: {}
    });
};

export const updateTotals = (results) => {
    return _.reduce(results, (resultTotals, result) => {
        const totalCriteria = normalizeTotalCriteria(result.totalCriteria);

        return ({
            ...resultTotals,
            ...totalCriteria,
            [result.type]: result.total
        });
    }, {});
};

export const updateStates = (results) => {
    return _.reduce(results, (resultStates, result) => {
        return result.states ? [
            ...resultStates,
            ...result.states
        ] : resultStates;
    }, []);
};

// helper function
export const omitFilters = (filters = {}) => ({
    contexts: filters.contexts && Object.keys(filters.contexts).filter(key => filters.contexts[key]),
    statuses: filters.statuses && Object.keys(filters.statuses).filter(key => filters.statuses[key]),
    countries: filters.countries && Object.keys(filters.countries).filter(key => filters.countries[key]),
    state: filters.state && filters.state.value
});

export const filterSecurityList = (list, filterString) => {
    return _.filter(list, sec => (
        securityFullNameFormatter(sec.name, sec.ticker, sec.cusip, sec.companyNumber).toLowerCase().includes(filterString)
        || _.toString(sec.companyNumber).includes(filterString)
    ));
};

export const getSearchArea = (state) => {
    const availableSecurities = getAvailableSecurities(state);
    const availableSecurityIDs = availableSecurities.map(s => s.id);

    const searchedSecurities = getAdvancedSearchSecurities(state);

    if (searchedSecurities.length === 1) {
        return {
            name: searchedSecurities[0].name,
            id: searchedSecurities[0].id,
            issuerName: searchedSecurities[0].issuerName,
            astSecurityName: searchedSecurities[0].astSecurityName,
            cusip: searchedSecurities[0].cusip,
            companyNumber: searchedSecurities[0].companyNumber,
            ticker: searchedSecurities[0].ticker
        };
    }
    const securitiesIds = searchedSecurities.map(s => s.id);
    const isSelectedAll = _.xor(availableSecurityIDs, securitiesIds).length === 0;

    if (isSelectedAll) {
        return { key: 'search.across.all' };
    }

    return { key: 'search.across.selected' };
};
