import _ from 'lodash';
import { createSelector } from 'reselect';

export const getAvailableIssuers = state => {
    return _.get(state, 'issuers.availableIssuers');
};

export const getSortedAvailableIssuers = createSelector(
    getAvailableIssuers,
    issuers => _.sortBy(issuers, issuer => issuer.companyName)
);

export const getIssuersAmount = createSelector(
    getAvailableIssuers,
    issuers => issuers.length
);

export const getIssuerById = (state, issuerId) => {
    const issuers = getAvailableIssuers(state);

    return _.find(issuers, issuer => issuer.id === issuerId);
};
