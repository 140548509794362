import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';

import TextInput from 'Components/TextInput';
import ReportScheduler from 'Components/ReportScheduler';
import LimitNote from 'Components/LimitNote';

import './SaveScheduledReportTemplateModal.scss';

const SaveScheduledReportTemplateModalView = props => {
    const {
        formatMessage, onSuccess, onCancel, formValues,
        okText, cancelText, reportNameNormalize,
        handleSubmit, submitting, invalid, formName, isMultiSecurityReport,
        saveAndShareText, onSaveAndShare, userCanShareReportTemplate
    } = props;

    const canShare = !isMultiSecurityReport && userCanShareReportTemplate;

    return (
        <div className='save-new-scheduled-report-modal'>
            <form onSubmit={handleSubmit(onSuccess)}>
                <Field
                    component={TextInput}
                    name='reportName'
                    placeholder={formatMessage({ id: 'modals.saveNewReport.placeholder' })}
                    label={formatMessage({ id: 'modals.saveNewReport.label' })}
                    labelType='vertical'
                    type='text'
                    tooltipMessage={formatMessage({ id: 'reporting.templateNameInputTooltip' })}
                    normalize={reportNameNormalize}/>
                <ReportScheduler formName={formName} isMultiSecurityReport={isMultiSecurityReport}/>
                <div className='text-message'>{formatMessage({ id: 'modals.saveNewReport.message' })}</div>
                <div className='btn-panel'>
                    <button
                        type='submit'
                        disabled={submitting || invalid}
                        className='btn btn-large'>
                        {okText}
                    </button>
                    {canShare && <button
                        type='button'
                        disabled={submitting || invalid}
                        className='btn btn-large'
                        onClick={handleSubmit(onSaveAndShare)}>
                        {saveAndShareText}
                    </button>}
                    <button
                        type='button'
                        className='btn btn-large btn-link'
                        onClick={onCancel}>
                        {cancelText}
                    </button>
                </div>
                {_.get(formValues, 'isScheduled') ? <LimitNote/> : null}
            </form>
        </div>
    );
};

SaveScheduledReportTemplateModalView.propTypes = {
    formatMessage: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    intl: PropTypes.object,
    reportNameNormalize: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    formName: PropTypes.string,
    formValues: PropTypes.object,
    isMultiSecurityReport: PropTypes.bool,
    saveAndShareText: PropTypes.string,
    onSaveAndShare: PropTypes.func,
    userCanShareReportTemplate: PropTypes.bool
};

export default memo(SaveScheduledReportTemplateModalView);
