import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { withProps, withPropsOnChange } from '@shakacode/recompose';

import shareholderTypes from 'Constants/shareholder-types';
import {
    commonWidgetPropTypes,
    commonWidgetActions,
    markIncludesCeDeCoRows,
    getMarkedData
} from 'Components/widgets/utils';
import { TableEasy } from 'Components/Tables';
import widgetModes from 'Constants/widget-modes';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import WidgetError from 'Components/widgets/Widget/components/WidgetError';
import { getColumnSettings, getData } from './selectors';

@withProps(props => ({
    values: _.get(props, 'activeShareholderData.values', []),
    aggregate: _.get(props, 'activeShareholderData.aggregate'),
    hideBarChart: _.get(props, 'widgetSettings.mode') === widgetModes.TABLE
}))
@withPropsOnChange(
    ['values'],
    props => ({
        columns: getColumnSettings(props),
        data: getData(props)
    })
)
class AnalysisGeographicContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        activeShareholderData: PropTypes.object
    };

    render() {
        const {
            data, columns,
            hideBarChart, aggregate,
            widgetNoData, isFetching, isDataViz, noPermissions,
            widgetSettings = {}, totalMessageId,
            widgetFooterProps
        } = this.props;
        const isRegisteredData = widgetSettings.activeShareholderType === shareholderTypes.REGISTERED;
        let tableData;

        if (isRegisteredData) {
            tableData = getMarkedData(data, [markIncludesCeDeCoRows], widgetFooterProps);
        } else {
            tableData = data;
        }

        return noPermissions ?
            (<WidgetError noPermissions={noPermissions} widgetSettings={widgetSettings}/>) :
            (<div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        widgetFooterProps={widgetFooterProps}
                        className='widget-table'
                        hideBarChart={hideBarChart}
                        columns={columns}
                        data={tableData}
                        renderAllRows={isDataViz}
                        aggregate={aggregate}
                        totalMessageId={totalMessageId}/>
                </NoDataComponent>
            </div>);
    }
}

export default AnalysisGeographicContent;
