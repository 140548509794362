import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import PeriodActionPanel from 'Components/widgets/_commonComponents/PeriodActionPanel';
import { commonWidgetActions, commonWidgetPropTypes } from 'Components/widgets/utils';
import Selector from 'Components/Selector';
import { contactYears } from '../../constants';
import { getWidgetDataValues, getDates, getSelectedDate, getSelectedContactYear } from '../../selectors';

import './DateLastContactActionPanel.scss';

class DateLastContactActionPanel extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    constructor(props) {
        super(props);
        this.periodYearsOptions = [{
            value: contactYears.MORE_1Y,
            label: props.intl.formatMessage({ id: 'date.last.contact.period.more1Y' })
        }, {
            value: contactYears.MORE_2Y,
            label: props.intl.formatMessage({ id: 'date.last.contact.period.more2Y' })
        }, {
            value: contactYears.MORE_3Y,
            label: props.intl.formatMessage({ id: 'date.last.contact.period.more3Y' })
        }];
    }

    handlePeriodChange = ({ value }) => {
        const { onUpdateWidgetSettings, widgetSettings } = this.props;
        const newWidgetSettings = {
            ...widgetSettings,
            contactYears: value
        };

        onUpdateWidgetSettings(newWidgetSettings);
    };

    render() {
        const dataValues = getWidgetDataValues(this.props);
        const dates = getDates(this.props);
        const selectedDate = getSelectedDate(this.props);
        const selectedContactYear = getSelectedContactYear(this.props);
        const hasWidgetData = _.get(dataValues, 'length');

        return (
            hasWidgetData
                ? (<div className='date-last-contact-action-panel'>
                    <PeriodActionPanel {...this.props} dates={dates} selectedDate={selectedDate}/>
                    <Selector
                        wrapperClass='period-years-selector'
                        name='periodYearsSelector'
                        options={this.periodYearsOptions}
                        onOptionChange={this.handlePeriodChange}
                        value={selectedContactYear}
                        clearable={false}
                        searchable={false}/>
                </div>)
                : null
        );
    }
}

export default DateLastContactActionPanel;
