import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { withContext } from '@shakacode/recompose';
import moment from 'moment';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import history from './context/history';
import appRoutes from './app.routes';
import locales from './locales';
import { widgets } from './components/widgets';
import { layouts } from './components/DynamicLayout';
import { modals } from './components/Modals';
import ReduxToastr from 'react-redux-toastr';
import LoadingBlock from 'Components/LoadingBlock';
import { loadFonts } from 'Utils/utils';
import { getQueryParameterByName } from 'Utils/routing';
import { CODE, REDIRECT_STATE } from 'Constants/auth';
import errorReasons from 'Constants/error-reasons';

import * as userSelectors from 'State/user/selectors';
import pageRoutes from './constants/page-routes';

const mapStateToProps = (state) => ({
    isLogout: userSelectors.isUserLogoutSelector(state),
    isSecurityChanging: userSelectors.isSecurityChangingSelector(state)
});

@withContext(
    {
        modals: PropTypes.object,
        widgets: PropTypes.object,
        layouts: PropTypes.object
    },
    () => ({ modals, widgets, layouts })
)
@connect(mapStateToProps, null)
class Root extends Component {
    static propTypes = {
        locale: PropTypes.string.isRequired,
        regionCode: PropTypes.string.isRequired,
        isLogout: PropTypes.bool,
        isSecurityChanging: PropTypes.bool
    };

    static defaultProps = {
        locale: 'en',
        regionCode: 'en',
        isLogout: false
    };

    constructor(props) {
        super(props);
        moment.locale(props.locale);

        const code = getQueryParameterByName('code');
        const state = getQueryParameterByName('state');
        const error = getQueryParameterByName('error');

        if (error === errorReasons.ACCESS_DENIED.toLowerCase()) {
            window.history.replaceState({}, '', `${window.location.origin}${window.location.pathname}`);
            history.replace(`${pageRoutes.error}/${errorReasons.ACCESS_DENIED}`);
        } else if (code) {
            localStorage.setItem(CODE, code);
            state && localStorage.setItem(REDIRECT_STATE, state.split('#')[0]);

            window.history.replaceState({}, '', `${window.location.origin}${window.location.pathname}`);
        }
    }

    componentDidMount() {
        // Fix. Handle click on touch devices. See more: https://github.com/kentor/react-click-outside/issues/4
        if ('ontouchstart' in document.documentElement) {
            document.body.style.cursor = 'pointer';
        }

        if (DEMO_MODE) {
            document.body.classList.add('--demo');
        } else {
            document.body.classList.remove('--demo');
        }

        loadFonts();
    }

    render() {
        const messages = locales[this.props.locale];

        return (
            <div id='app-root'>
                <IntlProvider locale={this.props.locale} messages={messages} defaultLocale={'en'}>
                    <div className='application-wrapper'>
                        <ReduxToastr
                            newestOnTop={false}
                            preventDuplicates
                            transitionIn='fadeIn'
                            transitionOut='fadeOut'
                            position='top-right'/>
                        <ConnectedRouter history={history}>
                            {this.props.isLogout || this.props.isSecurityChanging ? null : appRoutes}
                        </ConnectedRouter>
                        <LoadingBlock/>
                    </div>
                </IntlProvider>
            </div>
        );
    }
}

export default Root;
