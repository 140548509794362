import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import InstitutionalOverviewPageHeaderView from '../components/InstitutionalOverviewPageHeaderView';

const mapStateToProps = state => ({
    info: dynamicPageSelectors.getDynamicPageInfoSelector(state),
    isFetching: dynamicPageSelectors.getDynamicPageFetchingSelector(state)
});

@withRouter
@injectIntl
@connect(mapStateToProps)
class InstitutionalOverviewPageHeader extends Component {
    static propTypes = {
        info: PropTypes.object,
        intl: intlShape,
        location: PropTypes.object,
        isFetching: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.formatMessage = props.intl.formatMessage;
        this.institutionProfileLinkText = this.formatMessage({ id: 'institutionOverview.institutionProfileLink' });
    }

    render() {
        const { info, location: {  pathname, search }, isFetching } = this.props;
        const returnPath = { pathname, search };

        return info ?
            <InstitutionalOverviewPageHeaderView
                isFetching={isFetching}
                pageInfo={info}
                returnPath={returnPath}
                institutionProfileLinkText={this.institutionProfileLinkText}/>
            : null;
    }
}

export default InstitutionalOverviewPageHeader;
