import _ from 'lodash';

export const createReducer = (initialState = {}, actionHandlers = {}) => {
    return (state = initialState, action) => {
        const reduceFn = actionHandlers[action.type];

        if (!reduceFn) {
            return state;
        }

        return reduceFn(state, action);
    };
};

export const isValidId = (id) => {
    return !_.isUndefined(id);
};

export const isExists = (state, id) => {
    return _.has(state, id);
};
