import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TopShareholdersTooltip from '../../TopShareholdersTooltip';

const MainColumnHeadingComponent = ({
    onShowFindModal,
    title,
    findModalAvailable,
    searchAvailable
}) => {
    const className = cn('search-icon', { disabled: !findModalAvailable });

    return (
        <div className='main-column-heading'>
            <TopShareholdersTooltip title={title}/>
            { searchAvailable &&
                <span className={className}>
                    <i
                        className='font-icon font-icon-search'
                        onClick={(e) => {
                            e.stopPropagation();
                            findModalAvailable && onShowFindModal(e);
                        }}/>
                </span>
            }
        </div>
    );
};

MainColumnHeadingComponent.propTypes = {
    onShowFindModal: PropTypes.func,
    title: PropTypes.string.isRequired,
    findModalAvailable: PropTypes.bool,
    searchAvailable: PropTypes.bool
};

export default  MainColumnHeadingComponent;
