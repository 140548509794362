import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as featuresSelectors from 'State/features/selectors';

const mapStateToProps = (state) => ({
    currentFeatures: _.keys(featuresSelectors.getCurrentFeaturesSelector(state))
});

@connect(mapStateToProps)
class AvailableRoute extends Component {
    static propTypes = {
        location: PropTypes.object,
        currentFeatures: PropTypes.array,
        pathConfig: PropTypes.array,
        defaultPath: PropTypes.string
    };

    findAvailablePath = () => {
        const { currentFeatures, pathConfig, defaultPath } = this.props;
        const availablePath = _.find(pathConfig, (path) => _.intersection(currentFeatures, path.features).length > 0);

        return availablePath ? availablePath.path : defaultPath;
    };

    render() {
        const availablePath = this.findAvailablePath();

        return <Redirect to={`${availablePath}${this.props.location.search}`}/>;
    }
}

export default AvailableRoute;
