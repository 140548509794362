import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import shareholderTypes from 'Constants/shareholder-types';
import ShareholderInformation from '../_commonComponents/ShareholderInformation';
import BottomField from './components/BottomField';

@injectIntl
class InstitutionShareholderInformation extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    getWidgetTitle(shareholderType) {
        const { formatMessage } = this.props.intl;
        const titles = {
            [shareholderTypes.FUND]: 'shareholder.information.fund.title',
            [shareholderTypes.INSTITUTION]: 'shareholder.information.institution.title'
        };

        return titles[shareholderType] && formatMessage({ id: titles[shareholderType] });
    }

    render() {
        const { widgetData } = this.props;
        const shareholderType = _.get(widgetData, 'shareholderType');
        const props = {
            ...this.props,
            widgetTitle: this.getWidgetTitle(shareholderType),
            widgetNoData: _.isEmpty(widgetData),
            bottomFieldComponent: BottomField,
            onAddReport: () => console.log('add to report')
        };

        return (
            <ShareholderInformation {...props} />
        );
    }
}

export default InstitutionShareholderInformation;
