import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { AutoSizer } from 'react-virtualized';

import pageRoutes from 'Constants/page-routes';
import { getColorsData } from '../../../widgets/_commonComponents/AnalysisChart/utils';
import { commonWidgetActions, commonWidgetPropTypes } from '../../utils';
import {
    MAX_VISIBLE_COMPANIES,
    EXPORT_MAX_VISIBLE_COMPANIES,
    ARROW_WIDTH, MIN_LEGEND_WIDTH,
    LEGEND_WIDTH_FRACTION,
    CHART_LEGEND_WIDTH
} from '../peerAnalysisConfig';
import NoDataComponent from '../../Widget/components/NoDataComponent';
import AnalysisTable from '../../_commonComponents/AnalysisTable/AnalysisTable';
import AnalysisChart from '../../_commonComponents/AnalysisChart/AnalysisChart';
import { getTdProps } from '../../_commonComponents/AnalysisTable/utils';
import GroupHeaderCell from '../../_commonComponents/AnalysisTable/components/GroupHeaderCell';
import {
    defaultGroupColumnsSettings,
    defaultMainColumnSettings
} from '../../_commonComponents/AnalysisTable/defaultColumnSettings';
import AnalysisLegend from '../../_commonComponents/AnalysisLegend/AnalysisLegend';
import {
    getSavedColors,
    getChartGroups,
    getChartSeries,
    getGroupsSettings,
    getMaxOffset, getMktLimits, getPeersForPopover,
    getSelectedShareholders,
    getTableData,
    getDataVizTableData,
    getFindModalAvailability
} from '../peerDataSelectors';
import { peerShape } from 'Constants/peer';
import { showFindTopShareholdersModal, hideModal } from 'State/modal';
import { saveShareholderToData, cancelAnalysisSearchRequest } from 'State/analysisShareholders';
import { getPeerAnalysisMaxSelectedShareholders } from 'State/user/selectors';
import { colorizeNewShareholder } from 'Utils/analysisPeerAndHistorical';
import { analysisShareholderShape } from 'Constants/analysisShareholders';

const mapStateToProps = state => ({
    maxSelectedShareholders: getPeerAnalysisMaxSelectedShareholders(state)
});

const mapDispatchToProps = {
    showSearchModal: showFindTopShareholdersModal,
    saveShareholderToData,
    hideModal,
    cancelAnalysisSearchRequest
};

@connect(mapStateToProps, mapDispatchToProps)
class PeerAnalysisContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        widgetData: PropTypes.shape({
            data: PropTypes.arrayOf(PropTypes.shape({
                companyId: PropTypes.string.isRequired,
                shareholderId: PropTypes.string.isRequired,
                mktVal: PropTypes.number,
                os: PropTypes.number,
                position: PropTypes.number
            })).isRequired,
            securityCompany: peerShape.isRequired,
            peerCompanies: PropTypes.arrayOf(peerShape).isRequired,
            shareholders: PropTypes.arrayOf(analysisShareholderShape).isRequired
        }),
        widgetSettings: PropTypes.shape({
            selectedPeers: PropTypes.arrayOf(PropTypes.string).isRequired,
            selectedShareholders: PropTypes.arrayOf(PropTypes.shape({
                shareholderId: PropTypes.string.isRequired,
                color: PropTypes.number.isRequired
            })).isRequired,
            sortCriteria: PropTypes.shape({
                sortColumn: PropTypes.string, // null/shareholderId/"issuer"
                sortField: PropTypes.string.isRequired, // shareholderName/position/os/mktVal
                sortDirection: PropTypes.string.isRequired // asc/desc
            }).isRequired
        }),
        showSearchModal: PropTypes.func,
        cancelAnalysisSearchRequest: PropTypes.func,
        hideModal: PropTypes.func,
        maxSelectedShareholders: PropTypes.number,
        requestShareholderData: PropTypes.func,
        setMaxYAxisValue: PropTypes.func
    };

    constructor(props) {
        super(props);
        const offset = props.isDataViz && !_.isNil(props.widgetSettings.offset)
            ? props.widgetSettings.offset
            : getMaxOffset(props);
        const series = getChartSeries(props, offset);
        const colorsData = getColorsData(getSavedColors(props), _.map(series, sh => sh.shareholderId));

        this.formatMessage = props.intl.formatMessage;
        this.state = {
            groupsSettings: getGroupsSettings(props, offset),
            data: props.isDataViz ? getDataVizTableData(props) : getTableData(props),
            series,
            limits: getMktLimits(props),
            groups: getChartGroups(props, offset),
            selectedShareholders: getSelectedShareholders(props),
            availablePeers: getPeersForPopover(props),
            maxOffset: getMaxOffset(props),
            findModalAvailable: getFindModalAvailability(props),
            offset,
            colorBarState: colorsData.state,
            colors: colorsData.colors
        };

        this.chartLabel = this.formatMessage({ id: 'peer.analysis.chartYLabel' });
        this.noSelectedShareholdersMessage = this.formatMessage({ id: 'peer.analysis.noSelectedShareholders' });
        this.noPeersLabel = this.formatMessage({ id: 'peer.analysis.noPeers' });
        this.maxVisibleCompanies = props.isPrintMode ? EXPORT_MAX_VISIBLE_COMPANIES : MAX_VISIBLE_COMPANIES;
    }

    UNSAFE_componentWillMount() {
        if (!this.props.isDataViz && !_.isNil(this.props.widgetSettings.offset)) {
            const newSettings = _.omit(this.props.widgetSettings, 'offset');

            this.props.onUpdateWidgetSettings(newSettings);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.widgetData !== this.props.widgetData) {
            const offset = this.state.offset;
            const series = getChartSeries(nextProps, offset);
            const colorsData = getColorsData(getSavedColors(nextProps), _.map(series, sh => sh.shareholderId));

            this.setState({
                groupsSettings: getGroupsSettings(nextProps, offset),
                data: nextProps.isDataViz ? getDataVizTableData(nextProps) : getTableData(nextProps),
                series: getChartSeries(nextProps, offset),
                limits: getMktLimits(nextProps),
                groups: getChartGroups(nextProps, offset),
                selectedShareholders: getSelectedShareholders(nextProps),
                findModalAvailable: getFindModalAvailability(nextProps),
                maxOffset: getMaxOffset(nextProps),
                colorBarState: colorsData.state,
                colors: colorsData.colors
            });
        } else if (nextProps.widgetSettings !== this.props.widgetSettings) {
            const peersFromState = _.sortBy(this.state.availablePeers.filter(p => p.isSelected).map(peer => peer.id));
            const peersFromProps = _.sortBy(getPeersForPopover(nextProps).filter(p => p.isSelected).map(p => p.id));

            if (!_.isEqual(peersFromState, peersFromProps)) {
                this.updateSelectedPeers(peersFromProps);
            }
        }
    }

    handleShowModal = () => {
        const { widgetData: { shareholders }, widgetSettings: { selectedShareholders }, widgetType } = this.props;

        this.props.showSearchModal({
            onSuccess: this.handleConfirmModal,
            onClose: this.handleCloseModal,
            shareholders,
            widgetType,
            selectedShareholdersIds: selectedShareholders.map(selectedShareholder => selectedShareholder.shareholderId)
        });
    };

    handleCloseModal = () => {
        this.props.cancelAnalysisSearchRequest();
        this.props.hideModal();
    };

    handleDeletePeer = (peerId) => {
        const selectedPeers = this.props.widgetSettings.selectedPeers.filter(peer => peer !== peerId);

        this.updateSelectedPeers(selectedPeers);
    };

    handleSelectShareholder = (shareholder) => {
        const { selectedShareholders, colorBarState } = this.state;
        const { colorsData, newShareholders } = colorizeNewShareholder(shareholder, selectedShareholders, colorBarState);

        const widgetSettings = {
            ...this.props.widgetSettings,
            selectedShareholders: newShareholders
        };
        const newProps = {
            widgetData: this.props.widgetData,
            widgetSettings,
            maxSelectedShareholders: this.props.maxSelectedShareholders };

        const series = getChartSeries(newProps, this.state.offset);

        this.setState({
            ...this.state,
            data: this.props.isDataViz ? getDataVizTableData(newProps) : getTableData(newProps),
            series,
            limits: getMktLimits(newProps),
            findModalAvailable: getFindModalAvailability(newProps),
            selectedShareholders: getSelectedShareholders(newProps),
            colorBarState: colorsData.state,
            colors: colorsData.colors
        });

        this.props.onUpdateWidgetSettings(widgetSettings);
    };

    handleChangeOffset = (offsetChange) => {
        const { offset } = this.state;
        const newOffset = offset + offsetChange;

        this.setState({
            ...this.state,
            groupsSettings: getGroupsSettings(this.props, newOffset),
            series: getChartSeries(this.props, newOffset),
            groups: getChartGroups(this.props, newOffset),
            offset: newOffset
        });
        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            offset: newOffset
        });
    };
    handleConfirmModal = (shareholderPayload) => {
        const { widgetType, widgetDataSourceId } = this.props;

        if (shareholderPayload.data) {
            this.props.saveShareholderToData(shareholderPayload, widgetType, widgetDataSourceId);
        }
        this.handleSelectShareholder(shareholderPayload.shareholder);
    };

    handleChangePeerOffsetToRight = this.handleChangeOffset.bind(this, 1);

    handleChangePeerOffsetToLeft = this.handleChangeOffset.bind(this, -1);

    handleSort = (sortCriteria) => {
        const { widgetSettings, onUpdateWidgetSettings } = this.props;
        const newWidgetSettings = {
            ...widgetSettings,
            sortCriteria
        };

        onUpdateWidgetSettings(newWidgetSettings);
    };

    getTdTableProps = (finalState, rowInfo, column) => {
        const { maxSelectedShareholders, isDataViz } = this.props;
        const isDisabledCheckbox = _.get(rowInfo, 'original.isDisabled');
        const title = isDisabledCheckbox
            ? `${this.formatMessage(
                { id: 'peers.historical.analysis.table.maxSelectedCountMessage' },
                { maxSelected: maxSelectedShareholders })}\n${this.formatMessage({ id: 'peers.historical.analysis.table.mustDeleteMessage' })}`
            : null;

        return {
            ...getTdProps(column.id, rowInfo.original, {
                onSelect: this.handleSelectShareholder,
                title
            }),
            isCheckBoxDisplayed: !isDataViz,
            returnPath: pageRoutes.peerAnalysis
        };
    };

    getGroupWidth = (width, legendWidth, chartMarginRight) => {
        const groupColumnsFixedWidth = _.reduce(defaultGroupColumnsSettings, (result, value) => result + (value.width || 0), 0);

        return ((width - chartMarginRight - legendWidth) / this.maxVisibleCompanies) - groupColumnsFixedWidth;
    };

    getLegendWidth = width => {
        const legendWidth = width * LEGEND_WIDTH_FRACTION;

        return legendWidth < MIN_LEGEND_WIDTH ? MIN_LEGEND_WIDTH : legendWidth;
    };

    updateSelectedPeers = (selectedPeerIDs) => {
        const widgetSettings = {
            ...this.props.widgetSettings,
            selectedPeers: selectedPeerIDs
        };
        const newProps = { widgetData: this.props.widgetData, widgetSettings, intl: this.props.intl };
        const maxOffset = getMaxOffset(newProps);
        const offset = Math.min(this.state.offset, maxOffset);
        const availablePeers = getPeersForPopover(newProps);

        this.setState({
            ...this.state,
            groupsSettings: getGroupsSettings(newProps, offset),
            series: getChartSeries(newProps, offset),
            limits: getMktLimits(newProps),
            groups: getChartGroups(newProps, offset),
            availablePeers,
            maxOffset,
            offset
        });
        this.props.onUpdateWidgetSettings(widgetSettings);
    };

    groupValueGetter = (item, groupSettings, accessor) => _.get(item, `groups.${groupSettings.id}.${accessor}`);

    render() {
        const {
            widgetSettings = {},
            isFetching,
            noData,
            maxSelectedShareholders,
            isDataViz,
            setMaxYAxisValue
        } = this.props;

        const {
            groupsSettings,
            data,
            series,
            limits,
            groups,
            selectedShareholders,
            maxOffset,
            offset,
            findModalAvailable,
            colors
        } = this.state;
        const hasRightScroll = offset < maxOffset;
        const hasLeftScroll = offset > 0;
        const isMaxSelected = _.get(selectedShareholders, 'length') === maxSelectedShareholders;

        return (
            <div className='widget-content' ref={el => this.chartContainer = el}>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={noData}>
                    <div className='peer-analysis-content'>
                        <AutoSizer disableHeight>
                            {({ width }) => {
                                const legendWidth = this.getLegendWidth(width);
                                const chartMarginRight = (hasRightScroll || 0) && ARROW_WIDTH;
                                const groupWidth = this.getGroupWidth(width, legendWidth, chartMarginRight);

                                return (<div style={{ width }}>
                                    <div className='peer-analysis-chart'>
                                        <div className='peer-analysis-chart-legend' style={{ width: legendWidth - CHART_LEGEND_WIDTH }}>
                                            <AnalysisLegend
                                                data={selectedShareholders}
                                                onDelete={this.handleSelectShareholder}
                                                noDataMessage={this.noSelectedShareholdersMessage}
                                                colors={colors}
                                                isShowDeleteIcon={!isDataViz}/>
                                        </div>
                                        <AnalysisChart
                                            yAxisBarTitle={this.chartLabel}
                                            customConfig={{
                                                chart: {
                                                    marginLeft: legendWidth,
                                                    marginRight: chartMarginRight,
                                                    marginBottom: 0,
                                                    events: {
                                                        // eslint-disable-next-line
                                                        render: function() {
                                                            setMaxYAxisValue(_.get(this, 'yAxis[0].max'));
                                                        }
                                                    }
                                                },
                                                yAxis: [{
                                                    min: limits.min,
                                                    max: limits.max
                                                }],
                                                colors
                                            }}
                                            barData={series}
                                            groups={groups}
                                            groupCount={this.maxVisibleCompanies}
                                            chartContainer={this.chartContainer}
                                            isDataViz={isDataViz}/>
                                    </div>
                                    <AnalysisTable
                                        groupsSettings={groupsSettings}
                                        onScrollGroupsRight={(hasRightScroll || undefined) && this.handleChangePeerOffsetToRight}
                                        onScrollGroupsLeft={(hasLeftScroll || undefined) && this.handleChangePeerOffsetToLeft}
                                        onDeleteGroup={this.handleDeletePeer}
                                        getTdProps={this.getTdTableProps}
                                        groupHeaderCellComponent={GroupHeaderCell}
                                        mainColumnSettings={defaultMainColumnSettings}
                                        mainColumnWidth={legendWidth}
                                        groupColumnsSettings={defaultGroupColumnsSettings}
                                        noSelectedText={this.noPeersLabel}
                                        data={data}
                                        groupValueGetter={this.groupValueGetter}
                                        initialSort={widgetSettings.sortCriteria}
                                        onSort={this.handleSort}
                                        onShowFindModal={this.handleShowModal}
                                        findModalAvailable={findModalAvailable}
                                        searchAvailable={!isDataViz}
                                        isMaxSelected={isMaxSelected}
                                        maxSelected={maxSelectedShareholders}
                                        groupWidth={groupWidth}
                                        groupCount={this.maxVisibleCompanies}/>
                                </div>);
                            }}
                        </AutoSizer>
                    </div>
                </NoDataComponent>
            </div>
        );
    }
}

export default PeerAnalysisContent;
