import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';

import { HeadingCellCommon, CellCommon } from 'Components/widgets/_commonComponents/TableCellComponents';
import sortDirections from 'Constants/sort-directions';
import columnDefinitions, { columnNames, secondarySorting } from '../../constants/columnDefinitions';
import { getSortDirection } from 'Components/Tables/utils';
import { commonWidgetPropTypes, commonWidgetActions } from '../../../utils';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import { sortArray } from 'Utils/utils';
import { CellWithUserName, CellWithLastLoginDate } from '../cellRenderers';

class AuthorizedUserListContent extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        isPrimaryRMExists: PropTypes.bool
    };

    handleSort = sortProperties => {
        const { widgetSettings: { sortCriteria: { field, direction } } } = this.props;
        const sortCriteria = {
            field: sortProperties.id,
            direction: getSortDirection(
                sortProperties.id,
                field,
                direction,
                columnDefinitions.columns
            ),
            initial: false
        };

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
    };

    getCustomCellComponents = column => {
        const { intl: { formatMessage } } = this.props;
        const columnName = column.columnName;

        switch (columnName) {
            case columnNames.NAME:
                return {
                    bodyCellComponent: ({ value }) => <CellWithUserName value={value}/>,
                    headingCellComponent: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>
                };
            case columnNames.IC_LAST_LOGIN:
            case columnNames.DWAC_LAST_LOGIN:
            case columnNames.PROXY_LAST_LOGIN:
                return {
                    bodyCellComponent: ({ value }) => <CellWithLastLoginDate value={value}/>,
                    headingCellComponent: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>
                };
            case columnNames.EXTENDED_OI_DFK_ACCESS:
            case columnNames.DWAC_PERMISSION_LEVEL: {
                const additionalData = DEMO_MODE ? { alignRight: true } : { alignCenter: true };

                return {
                    bodyCellComponent: (props) => <CellCommon value={props.value} additionalData={additionalData}/>,
                    headingCellComponent: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>
                };
            }
            default: {
                const additionalData = DEMO_MODE ? { alignRight: true } : {};

                return {
                    bodyCellComponent: (props) => <CellCommon value={props.value} additionalData={additionalData}/>,
                    headingCellComponent: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>
                };
            }
        }
    };

    getThProps = (finalState, missProp, column) => {
        const { widgetSettings: { sortCriteria: { field, direction } } } = this.props;

        return {
            sortProperty: column.id === field ? { sortAscending: direction === sortDirections.ASC } : null
        };
    };

    getFooterValue = columnName => {
        const { widgetData: { primaryRM = {} } = {} } = this.props;

        switch (columnName) {
            case columnNames.NAME:
                return <CellCommon value={primaryRM.name}/>;
            case columnNames.TITLE:
                return <CellCommon value={primaryRM.title}/>;
            case columnNames.EMAIL:
                return <CellCommon value={primaryRM.email} additionalData={DEMO_MODE ? { alignRight: true } : {}} />;
            default:
                return null;
        }
    };

    getNoDataProps = () => ({
        messageId: 'widgets.noDataMessage.simple'
    });

    sortValues = (array, field, descending, initial) =>
        sortArray({
            array,
            primaryField: field,
            descending,
            secondaryField: secondarySorting.field,
            secondaryDescending: secondarySorting.direction === sortDirections.DESC,
            initial
        });

    render() {
        const { widgetSettings, widgetData = {}, isPrimaryRMExists }  = this.props;
        const { sortCriteria: { field, direction, initial } } = widgetSettings;
        const sortedData = this.sortValues(widgetData.tableData, field, direction === sortDirections.DESC, initial);

        return (
            <div className='authorized-users-table'>
                <ReactTable
                    showPagination={false}
                    resizable={false}
                    columns={(this.props.columns || []).map(column => {
                        const customComponents = this.getCustomCellComponents(column);

                        return ({
                            Header: customComponents.headingCellComponent,
                            Cell: customComponents.bodyCellComponent,
                            accessor: column.columnName,
                            minWidth: column.minWidth || 150,
                            Footer: isPrimaryRMExists
                                ? this.getFooterValue(column.columnName)
                                : null
                        });
                    })}
                    onSortedChange={(sortProps) => this.handleSort(sortProps[0])}
                    className='shareholders-table-rt'
                    LoadingComponent={() => null}
                    manual
                    defaultPageSize={0}
                    TdComponent={TdComponent}
                    ThComponent={TheadComponent}
                    getTheadThProps={this.getThProps}
                    getTdProps={this.getTdProps}
                    getNoDataProps={this.getNoDataProps}
                    NoDataComponent={isPrimaryRMExists ? () => null : NoResults}
                    resolvedData={sortedData}/>
            </div>
        );
    }
}

export default AuthorizedUserListContent;
