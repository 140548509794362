import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import TraceKit from 'tracekit';

import errorHandler from 'Context/errorHandler';

const withErrorBoundary = WrappedComponent => class extends Component {
    static displayName = wrapDisplayName(WrappedComponent, 'withErrorBoundary');

    componentDidCatch(error) {
        const exception = TraceKit.computeStackTrace(error);

        errorHandler.handleException(exception, true);
    }

    render() {
        return <WrappedComponent {...this.props}/>;
    }
};

export default withErrorBoundary;
