import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import FormWarning from '../FormWarning';
import FormError from '../FormError';
import FormErrorSummary from '../FormErrorSummary';
import Tooltip from 'Components/Tooltip';

import './TextInput.scss';

class TextInput extends Component {
    static propTypes = {
        type: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        labelType: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        input: PropTypes.object,
        meta: PropTypes.object,
        errorType: PropTypes.string,
        isErrorMessageShowing: PropTypes.bool,
        className: PropTypes.string,
        autoComplete: PropTypes.bool,
        withFocus: PropTypes.bool,
        dirtyValidation: PropTypes.bool,
        tooltipMessage: PropTypes.string,
        maxLength: PropTypes.number
    };

    static defaultProps = {
        type:'text',
        labelType:'horizontal',
        errorType:'inline',
        autoComplete: true,
        isErrorMessageShowing: true,
        withFocus: false
    };

    componentDidUpdate() {
        this.props.withFocus && this.input && this.input.focus();
    }

    getInputRef = (input) => {
        this.input = input;
    };

    render() {
        const {
            className,
            name,
            label,
            input,
            type,
            placeholder,
            disabled,
            autoComplete,
            isErrorMessageShowing,
            meta: { touched, dirty, error, warning },
            errorType,
            withFocus,
            labelType,
            dirtyValidation,
            tooltipMessage,
            maxLength
        } = this.props;

        const eventForValidation = dirtyValidation ? dirty : touched;
        const inputView = eventForValidation && error ? 'error' : '';
        const labelClass = classNames('text-input-label', { disabled }, labelType);
        const commonInput = (<input
            {...input}
            id={name}
            type={type}
            maxLength={maxLength}
            ref={withFocus && this.getInputRef}
            placeholder={placeholder}
            className={inputView}
            disabled={disabled}
            autoComplete={autoComplete === false ? 'off' : 'on'}/>);

        let warnMessage;
        let errorMessage;

        if (errorType === 'inline') {
            warnMessage = <FormWarning warning={warning}/>;
            errorMessage = <FormError error={error}/>;
        } else {
            errorMessage = <FormErrorSummary error={error}/>;
        }

        return (
            <div className={classNames('input-container', className)}>
                <label className={labelClass} htmlFor={name}>
                    {label}
                </label>
                <div className='text-input'>
                    {tooltipMessage
                        ? <Tooltip
                            content={<div>{tooltipMessage}</div>}>
                            {commonInput}
                        </Tooltip>
                        : commonInput}
                    {isErrorMessageShowing && eventForValidation && ((error && errorMessage) || (warning && warnMessage))}
                </div>
            </div>
        );
    }
}

export default TextInput;
