import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';

import { hideModal } from 'State/modal';
import { shareReportTemplate } from 'Hoc';
import { reportNameValidation } from '../../../pages/reporting/common/reportNameUtils';
import SaveScheduledReportTemplateModal from './SaveScheduledReportTemplateModal';
import formName from './constants/form-name';
import initialValues from './constants/initial-values';
import { dayOfMonthValidation } from '../../ReportScheduler/validation';

const mapStateToProps = (state) => ({
    ...state.modal,
    formValues: getFormValues(formName)(state)
});

const mapDispatchToProps = { hideModal };

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    reduxForm({
        form: formName,
        initialValues,
        validate: (values) => {
            const errors = {};

            errors.reportName = reportNameValidation(values.reportName);
            _.set(errors, 'schedule.scheduleParam', dayOfMonthValidation(values));

            return errors;
        }
    }),
    shareReportTemplate
)(SaveScheduledReportTemplateModal);
