import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';

import Selector from 'Components/Selector';
import { addressStatusMap } from '../../../constants/addressStatusOptions';
import { getFormValues } from 'redux-form';

const mapStateToProps = (state, props) => ({
    formValues: getFormValues(_.get(props, 'options.formName'))(state)
});

@connect(mapStateToProps)
class AddressStatusField extends Component {
    static propTypes = {
        input: PropTypes.object,
        options: PropTypes.object,
        formValues: PropTypes.object
    };

    constructor(props) {
        super(props);
        const { options, formValues } = props;
        const value = _.get(formValues, 'addressStatus');

        this.state = {
            value: options.disabled
                ? addressStatusMap.ALL : value
        };
    }


    componentDidUpdate(prevProps) {
        const { options } = this.props;
        const { options: prevOptions } = prevProps;

        if (options.disabled && !prevOptions.disabled) {
            this.setState({
                value: addressStatusMap.ALL
            });
        }
    }

    handleStatusChange = ({ value }) => {
        const { input } = this.props;

        input.onChange(value);
        this.setState({
            value
        });
    };

    render() {
        const { input, options } = this.props;
        const { value } = this.state;

        return (
            <div className='address-status'>
                <label className={cn('address-status-label', { disabled: options.disabled })}>
                    {options.label}
                </label>
                <Selector
                    {...input}
                    value={value}
                    wrapperClass='address-status-selector'
                    onOptionChange={this.handleStatusChange}
                    options={options.options}
                    disabled={options.disabled}
                    clearable={false}
                    searchable={false}/>
            </div>
        );
    }
}

export default AddressStatusField;
