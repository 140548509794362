import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { NumberFormatter } from 'Components/formatters';
import formatters from 'Constants/formatter-options';

const CellWithChange = (props) => {
    const { value, additionalData: { decimalPoints } = {} } = props;
    const  changeDecimalPoints = _.isNumber(decimalPoints) ? decimalPoints : formatters.DEFAULT_DECIMAL_POINTS;

    return (
        <div className='cell-with-change'>
            <NumberFormatter value={value} decimalPoints={changeDecimalPoints}/>
        </div>
    );
};

CellWithChange.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    additionalData: PropTypes.shape({
        decimalPoints: PropTypes.number
    })
};

export default CellWithChange;
