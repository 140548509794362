import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';

export const getException = () => {
    const requestData = utils.buildRequest({}, requestTypes.fullPagePayload);

    return axios.post(apiRoutes.getExceptionUrl, requestData, {
        errorStrategy: errorStrategies.CRITICAL,
        isCancelable: false
    });
};
