import _ from 'lodash';
import moment from 'moment';

import dateFormats from 'Constants/date-formats';

const formatType = type => type.replace(/([A-Z])/g, ' $1').trim().toLowerCase();
const capitalizeFirstLetter = str => str[0].toUpperCase() + str.slice(1);

const sendEvent = (type, name, params) => {
    if (_.isFunction(window.gtag)) {
        window.gtag(type, name, params);

        return;
    }

    if (window.dataLayer) {
        if (type === 'set') {
            // eslint-disable-next-line func-names
            window.dataLayer.push(function () {
                this.set(name, params);
            });

            return;
        }

        window.dataLayer.push({
            [type]: name,
            eventModel: params
        });
    }
};

export const pageViewEvent = pageType => {
    if (_.isString(pageType)) {
        const formattedType = formatType(pageType.replace('Page', ''));

        sendEvent('set', 'page_title', `${capitalizeFirstLetter(formattedType)} page`);
        sendEvent('event', `Opening page: ${formattedType}`, {
            'page_location': window.location.href,
            'page_path': window.location.pathname,
            'content_group': 'Page',
            'content_type': `${capitalizeFirstLetter(formattedType)} page`
        });
    }
};

export const addWidgetEvent = widgetType => {
    if (_.isString(widgetType)) {
        const formattedType = formatType(widgetType.replace('Widget', ''));

        sendEvent('event', 'Adding widget', {
            'content_group': 'Widget',
            'content_type': `${capitalizeFirstLetter(formattedType)} widget`
        });
    }
};

export const changeWidgetViewEvent = (widgetType, mode) => {
    if (_.isString(widgetType)) {
        const formattedType = formatType(widgetType.replace('Widget', ''));

        sendEvent('event', `Switching widget to ${mode} view`, {
            'content_group': 'Widget',
            'content_type': `${capitalizeFirstLetter(formattedType)} widget`
        });
    }
};

export const removeWidgetEvent = widgetType => {
    if (_.isString(widgetType)) {
        const formattedType = formatType(widgetType.replace('Widget', ''));

        sendEvent('event', 'Removing widget', {
            'content_group': 'Widget',
            'content_type': `${capitalizeFirstLetter(formattedType)} widget`
        });
    }
};

export const addWidgetToReportEvent = widgetType => {
    if (_.isString(widgetType)) {
        const formattedType = formatType(widgetType.replace('Widget', ''));

        sendEvent('event', 'Adding widget into the DataPrez report', {
            'content_group': 'Widget',
            'content_type': `${capitalizeFirstLetter(formattedType)} widget`
        });
    }
};

export const exportReportEvent = reportTemplateType => {
    if (_.isString(reportTemplateType)) {
        const reportType = reportTemplateType.replace('Template', '');
        const formattedReportType = formatType(reportType);

        sendEvent('event', 'Creating new report', {
            'content_group': 'Report',
            'content_type': `${capitalizeFirstLetter(formattedReportType)} report`
        });
    }
};

export const exportInstantReportEvent = reportTemplateType => {
    if (_.isString(reportTemplateType)) {
        const reportType = reportTemplateType.replace('Template', '');
        const formattedReportType = formatType(reportType);

        sendEvent('event', 'Downloading newly created report', {
            'content_group': 'Report',
            'content_type': `${capitalizeFirstLetter(formattedReportType)} report`
        });
    }
};

export const downloadReportEvent = (reportType, description, runDate) => {
    sendEvent('event', 'Downloading report from storage', {
        'content_group': 'Report',
        'content_type': 'Download report',
        'report_type': reportType,
        'report_description': description,
        'report_run_date': moment(runDate, dateFormats.SERVER_DATE_WITH_TIME_FORMAT).format(dateFormats.withTime)
    });
};
