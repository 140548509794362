import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { intlShape, injectIntl } from 'react-intl';

import {
    getSubscriptions,
    changeSubscriptionStatus,
    changeEmailSubscriptionStatus
} from 'State/subscriptions/actions';
import { subscriptionTypesSelector } from 'State/subscriptions/selectors';
import SubscriptionsPanelView from './components/SubscriptionsPanelView';
import pageRoutes from 'Constants/page-routes';
import { notifyError, notifySuccess } from 'State/notifier/actions';

const mapStateToProps = state => ({
    subscriptions: subscriptionTypesSelector(state)
});
const mapDispatchToProps = {
    getSubscriptions,
    changeSubscriptionStatus,
    changeEmailSubscriptionStatus,
    notifySuccess,
    notifyError
};

@injectIntl
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class SubscriptionsPanel extends Component {
    static propTypes = {
        subscriptions: PropTypes.array,
        getSubscriptions: PropTypes.func,
        changeSubscriptionStatus: PropTypes.func,
        changeEmailSubscriptionStatus: PropTypes.func,
        location: PropTypes.object,
        notifySuccess: PropTypes.func,
        notifyError: PropTypes.func,
        intl: intlShape
    };

    componentDidMount() {
        this.props.getSubscriptions();
    }

    handleChangeSubscription = (type, value) => {
        const { subscriptions, intl: { formatMessage } } = this.props;
        const subscription = _.find(subscriptions, s => s.type === type);
        const subscriptionName = _.get(subscription, 'name');
        const withAlert = _.get(subscription, 'isSubscribedEmail');

        this.props.changeSubscriptionStatus(type, value)
            .then(() => {
                const messageId = value
                    ? 'subscription.notifier.subscribe'
                    : `subscription.notifier.unsubscribe${withAlert ? '.withAlert' : ''}`;

                this.props.notifySuccess(null, formatMessage({ id: messageId }, { subscriptionName }));
            }, () => {
                const messageId = value
                    ? 'subscription.notifier.subscribe.error'
                    : `subscription.notifier.unsubscribe${withAlert ? '.withAlert' : ''}.error`;

                this.props.notifyError(null, formatMessage({ id: messageId }, { subscriptionName }));
            });
    };

    handleChangeEmailSubscriptionStatus = (type, value) => {
        const { subscriptions, intl: { formatMessage } } = this.props;
        const subscription = _.find(subscriptions, s => s.type === type);
        const subscriptionName = _.get(subscription, 'name');
        const isActive = _.get(subscription, 'isActive');

        this.props.changeEmailSubscriptionStatus(type, value)
            .then(() => {
                const messageId = value
                    ? `subscription.notifier.alert.activate${isActive ? '' : '.withSubscription'}`
                    : 'subscription.notifier.alert.deactivate';

                this.props.notifySuccess(null, formatMessage({ id: messageId }, { subscriptionName }));
            }, () => {
                const messageId = value
                    ? `subscription.notifier.alert.activate${isActive ? '' : '.withSubscription'}.error`
                    : 'subscription.notifier.alert.deactivate.error';

                this.props.notifyError(null, formatMessage({ id: messageId }, { subscriptionName }));
            });
    };

    render() {
        const { subscriptions, location } = this.props;
        const isDownloadPageOpen = location.pathname === pageRoutes.reportingDownloads
            || location.pathname === pageRoutes.downloadsList;

        return subscriptions.length > 0
            ? (<SubscriptionsPanelView
                subscriptions={subscriptions}
                isDownloadPageOpen={isDownloadPageOpen}
                onChangeSubscription={this.handleChangeSubscription}
                onChangeEmailSubscriptionStatus={this.handleChangeEmailSubscriptionStatus}/>)
            : null;
    }
}

export default SubscriptionsPanel;
