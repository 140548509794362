import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { saveChangesModal, hideModal } from 'State/modal/actions';

const mapDispatchToProps = { saveChangesModal, hideModal };

@connect(null, mapDispatchToProps)
class PagePrompt extends Component {
    static propTypes = {
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        saveChangesModal: PropTypes.func,
        hideModal: PropTypes.func,
        payload: PropTypes.string
    };

    componentDidMount() {
        const { onConfirm, onCancel, onClose, payload } = this.props;

        this.props.saveChangesModal({
            titleKey: 'modals.saveChanges.title',
            messageKey: 'modals.saveChanges.message',
            payload,
            okTextKey: 'common.yes',
            cancelTextKey: 'common.cancel',
            onSuccess: () => {
                this.props.hideModal();
                onConfirm();
            },
            onCancel,
            onClose: () => {
                this.props.hideModal();
                onClose();
            }
        });
    }


    render() {
        return null;
    }
}

export default PagePrompt;
