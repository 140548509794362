import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { dataTypes } from '../constants/dataTypes';
import CustomReactTable from 'Components/Tables/CustomReactTable';
import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import {
    getControlBooksResultCustomComponent,
    getControlBooksResultTdProps
} from '../utils';
import { dataTypeSelector } from 'State/reportingControlBooks/selectors';

const mapStateToProps = state => ({
    dataType: dataTypeSelector(state)
});

@connect(mapStateToProps)
class ControlBooksResultContent extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        controlBooksResults: PropTypes.array,
        dataType: PropTypes.string
    };

    getTdProps = (finalState, rowInfo, column) => {
        return getControlBooksResultTdProps(column.id);
    };

    render() {
        const {
            columns,
            messageKey,
            controlBooksResults,
            dataType
        } = this.props;
        const noDataKey = `widgets.noDataMessage.${ messageKey || 'matchingTransactions'}`;
        let data;

        switch (dataType) {
            case dataTypes.DETAILED_TRANSACTIONS:
                data = _.get(this.props, 'widgetData.controlBooksDetailedResults', []);
                break;
            case dataTypes.WEIGHTED_SHARES:
                data = _.get(this.props, 'widgetData.controlBooksWeightedResults', []);
                break;
            default:
                data = controlBooksResults;
        }

        return (
            <div className='reporting-control-books-results-page'>
                <FormattedMessage id='reporting.control.books.results.message.note'>
                    {message => <span className='message-note'>{message}</span>}
                </FormattedMessage>
                <CustomReactTable
                    data={data}
                    columns={columns}
                    noDataKey={noDataKey}
                    getTdProps={this.getTdProps}
                    getCustomComponent={getControlBooksResultCustomComponent}
                    sortable={false}/>
            </div>
        );
    }
}

export default ControlBooksResultContent;

