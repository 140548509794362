import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getContext, mapProps } from '@shakacode/recompose';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions, getVisibleColumns, getManagerTransformColumns } from '../../utils';
import shareholderTypesArray from 'Constants/shareholder-types-array';
import shareholderTypes from 'Constants/shareholder-types';
import columnDefinitions from '../constants/columnDefinitions';
import { filterItemsByUserProductSources } from 'Utils/utils';
import Selector from 'Components/Selector';
import * as featuresSelectors from 'State/features/selectors';
import { showExportWidgetModal } from 'State/exportReports';
import { showCommonColumnsManagerModal } from 'State/modal/actions';
import reportTypes from 'Constants/report-types';
import shareholderTypeToIconMap from 'Constants/shareholder-type-to-icon-map';
import ShareholderOptionRenderer from 'Components/widgets/_commonComponents/ShareholderOptionRenderer';
import WidgetPrint from '../../Widget/components/WidgetPrint';
import Icon from 'Components/Icon';

const defaultShareholderType = shareholderTypes.ALL;

const mapStateToProps = state => ({
    productSources: featuresSelectors.getCurrentProductSourcesSelector(state)
});
const mapDispatchToProps = { showCommonColumnsManagerModal, showExportWidgetModal };

@connect(mapStateToProps, mapDispatchToProps)
@getContext({
    events: PropTypes.object
})
@injectIntl
@mapProps(props => {
    const formatMessage = props.intl.formatMessage;
    const visibleColumns = _.get(props, 'widgetSettings.visibleColumns', []);
    const columns = getManagerTransformColumns(columnDefinitions.columns, visibleColumns, formatMessage);

    return {
        ...props,
        columns
    };
})
class HolderInvestorTypeAnalysisControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        productSources: PropTypes.array,
        intl: intlShape,
        events: PropTypes.object.isRequired,
        columns: PropTypes.array.isRequired,
        showCommonColumnsManagerModal: PropTypes.func.isRequired,
        showExportWidgetModal: PropTypes.func.isRequired,
        settings: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.formatMessage = props.intl.formatMessage;
        this.shareholderSelectorOptions = filterItemsByUserProductSources(shareholderTypesArray, props.productSources)
            .map(item => ({
                value: item.value,
                iconClass: shareholderTypeToIconMap[item.value],
                label: this.formatMessage({ id: item.label })
            }));
    }

    UNSAFE_componentWillMount() {
        const { settings: { shareholderType } = {} } = this.props;

        if (this.shareholderSelectorOptions.length === 1) {
            this.handleShareholderTypeChange(this.shareholderSelectorOptions[0]);
        } else {
            const hasType = _.some(this.shareholderSelectorOptions, { value: shareholderType });

            !hasType && this.handleShareholderTypeChange({ value: defaultShareholderType });
        }
    }

    handleShareholderTypeChange = (selectedItem) => {
        const { settings: { shareholderType } = {} } = this.props;

        if (selectedItem.value !== shareholderType) {
            this.props.events.handleUpdateWidgetParams({ shareholderType: selectedItem.value });
        }
    };

    handleActionColumnManager = () => {
        this.props.showCommonColumnsManagerModal({
            titleKey: 'modals.columnsManagerModal.title',
            columns: this.props.columns,
            onSave: this.handleSaveColumns,
            disableValidation: true
        });
    };

    handleExport = () => {
        const { widgetSettings = {}, settings: { shareholderType } = {} } = this.props;
        const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});

        this.props.showExportWidgetModal({
            reportTemplateType: reportTypes.holderAndInvestorTypeAnalysisTemplate,
            exportParams: {
                shareholderType,
                // todo: in future properties of sortCriteria object will be rename
                // from { sortDirection: ..., sortField: ... } to { direction: ..., field: ... }
                sortCriteria: {
                    sortDirection: sortCriteria.direction,
                    sortField: sortCriteria.field,
                    initial: sortCriteria.initial
                },
                columnsToDisplay: _.filter(widgetSettings.visibleColumns, column => column !== columnDefinitions.columnNames.TYPE)
            },
            modalViewParams: {
                showExcelLimit: true
            }
        });
    };

    handleSaveColumns = (columns = []) => {
        const visibleColumns = getVisibleColumns(columns, columnDefinitions);

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            visibleColumns
        });
    };

    render() {
        const { settings: { shareholderType } = {}, widgetData, widgetType } = this.props;
        const noData = !_.get(widgetData, 'length');
        const reportIconClasses = cn('font-icon font-icon-download action-icon', { 'inactive': noData });
        const isSelectDisabled = this.shareholderSelectorOptions.length === 1;

        return (
            <div className='holder-type-analysis-control-panel'>
                {this.shareholderSelectorOptions &&
                <Selector
                    name='shareholderTypeSelector'
                    options={this.shareholderSelectorOptions}
                    value={shareholderType}
                    optionRenderer={ShareholderOptionRenderer}
                    valueRenderer={ShareholderOptionRenderer}
                    onOptionChange={this.handleShareholderTypeChange}
                    searchable={false}
                    clearable={false}
                    disabled={isSelectDisabled}/>
                }
                <div className='action-icons-container'>
                    <WidgetPrint
                        className='action-icon'
                        widgetType={widgetType}/>
                    <Icon
                        className={reportIconClasses}
                        onClick={noData ? null : this.handleExport}/>
                    <Icon
                        className='font-icon font-icon-view-three-columns action-icon'
                        onClick={this.handleActionColumnManager}/>
                </div>
            </div>
        );
    }
}

export default HolderInvestorTypeAnalysisControlPanel;
