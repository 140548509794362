import { createReducer } from 'Utils/reducer';
import {
    GET_MARKET_DATA_SUCCESS,
    GET_MARKET_DATA_FAILURE
} from './actions';

const initialState = {
    data: {},
    error: null
};

const actionHandlers = {
    [GET_MARKET_DATA_SUCCESS]: (state, { payload }) => ({
        ...state,
        data: payload
    }),
    [GET_MARKET_DATA_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload
    })
};

export default createReducer(initialState, actionHandlers);
