export default {
    layoutEasy: 0,
    layoutThreeColumns: 1,
    layoutWithAside: 2,
    layoutWithLeftAside: 3,
    homePageLayout: 4,
    layoutDataViz: 5,
    layoutWithTabsPanel: 6,
    layoutMultiSlots: 7,
    shareholdersOverview: 8
};
