import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { withProps } from '@shakacode/recompose';

import SelectPeerPopoverContent from './SelectPeerPopoverContent';
import Popover from 'Components/Popover/Popover';
import { peerShape } from 'Constants/peer';
import { getPeerDescription } from 'Utils/peers';

import './SelectPeersPopover.scss';

@withProps(props => {
    return ({
        checkboxPeers: _.map(props.peers, (peer) => ({
            name: peer.id,
            label: peer.name,
            defaultChecked: peer.isSelected
        }))
    });
})
class SelectPeerPopover extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        peers: PropTypes.arrayOf(peerShape),
        checkboxPeers: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            defaultChecked: PropTypes.bool,
            name: PropTypes.string
        }))
    };

    constructor(props) {
        super(props);

        this.state = {
            options: this.mapOptions(props)
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            options: this.mapOptions(nextProps)
        });
    }

    handleChange = (e) => {
        const { checked, name } = e.target;

        const options = this.state.options.map(o => o.name === name ? { ...o, checked } : o);

        this.setState({ options });
    };

    handleSubmit = () => {
        this.handlePopoverClose();
        this.props.onSubmit(this.state.options.filter(o => o.checked).map(o => o.name));
    };

    handlePopoverClose = () => {
        this.popover && this.popover.hide && this.popover.hide();
    };

    handleHide = () => {
        this.setState({ options: this.mapOptions(this.props) });
    };

    mapOptions = (props) => {
        return _.map(props.peers, (peer) => {
            const label = getPeerDescription(peer);

            return {
                name: peer.id,
                label,
                checked: peer.isSelected,
                disabled: !_.isString(label) && !peer.isSelected,
                onChange: this.handleChange
            };
        });
    };

    render() {
        const { options } = this.state;

        return (
            <Popover
                id='select-peer'
                trigger={(<a className='peer-selector'><FormattedMessage id='peer.analysis.selectPeers'/></a>)}
                placement='bottom'
                triggerType='click'
                rootClose
                ref={(popover) => this.popover = popover}>
                <SelectPeerPopoverContent
                    onHide={this.handleHide}
                    options={options}
                    onSubmit={this.handleSubmit}
                    onClose={this.handlePopoverClose}/>
            </Popover>
        );
    }
}

export default SelectPeerPopover;
