import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Spinner from 'Components/Spinner';
import { getCachedCustomSecurity } from 'State/manageCache';

const mapDispatchToProps = { getCachedCustomSecurity };

@connect(null, mapDispatchToProps)
class UserProfilePage extends Component {
    static propTypes = {
        getCachedCustomSecurity: PropTypes.func
    };

    componentDidMount() {
        this.props.getCachedCustomSecurity();
    }

    render() {
        return (<Spinner isFetching isBlocker/>);
    }
}

export default UserProfilePage;
