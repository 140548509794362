import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hideModal } from 'State/modal';
import ExportNoResultsModalView from '../components/ExportNoResultsModalView';

const mapStateToProps = (state) => ({ ...state.modal });
const mapDispatchToProps = { hideModal };

@connect(mapStateToProps, mapDispatchToProps)
class ExportNoResultsModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func
    };

    render() {
        const { hideModal: handleHideModal } = this.props;

        return (
            <ExportNoResultsModalView onConfirm={handleHideModal}/>
        );
    }
}

export default ExportNoResultsModal;
