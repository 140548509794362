import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';

import dateFormat from 'Constants/date-formats';
import { isWorkDay } from 'Utils/dateHelpers';

const defaultOptions = { isRequired: true, checkWorkingDays: false };

export default (dateRange = {}, { isRequired = true, checkWorkingDays = false } = defaultOptions, dateParams = {}) => {
    const error = {};
    const { checked, startDate, endDate, subtractDays = 0 } = dateRange;

    if (!_.has(dateRange, 'checked') || checked) {
        const startDateMoment = moment(startDate, dateFormat.SERVER_DATE_FORMAT);
        const endDateMoment = moment(endDate, dateFormat.SERVER_DATE_FORMAT);
        const todayDate = moment();
        const todaySubtractedDate = subtractDays > 0 ? moment().subtract(subtractDays, 'day') : null;

        if (isRequired && _.isNil(startDate)) {
            error.startDate = <FormattedMessage id='datePicker.error.empty'/>;
        } else if (todaySubtractedDate
            && startDateMoment.isAfter(todaySubtractedDate, 'days')
            && startDateMoment.isSameOrBefore(todayDate, 'days')) {
            error.startDate = (<FormattedMessage
                id='datePicker.error.subtractDays.afterTo'
                values={{ days: subtractDays }}/>);
        } else if (startDateMoment.isAfter(endDateMoment) || startDateMoment.isAfter(todayDate, 'days')) {
            error.startDate = <FormattedMessage id='datePicker.error.afterTo'/>;
        } else if (checkWorkingDays && !isWorkDay(startDateMoment)) {
            error.startDate = <FormattedMessage id='datePicker.error.dayOff'/>;
        } else if (dateParams.minDate && startDateMoment.isBefore(dateParams.minDate)) {
            error.startDate = (<FormattedMessage
                id='datePicker.error.minDate.earlierThan'
                values={{ minDate: dateParams.minDate.format(dateFormat.default) }}/>);
        }

        if (isRequired && _.isNil(endDate)) {
            error.endDate = <FormattedMessage id='datePicker.error.empty'/>;
        } else if (todaySubtractedDate
            && endDateMoment.isAfter(todaySubtractedDate, 'days')
            && endDateMoment.isSameOrBefore(todayDate, 'days')) {
            error.endDate = (<FormattedMessage
                id='datePicker.error.subtractDays.beforeFrom'
                values={{ days: subtractDays }}/>);
        } else if (endDateMoment.isBefore(startDateMoment) || endDateMoment.isAfter(todayDate, 'days')) {
            error.endDate = <FormattedMessage id='datePicker.error.beforeFrom'/>;
        } else if (checkWorkingDays && !isWorkDay(endDateMoment)) {
            error.endDate = <FormattedMessage id='datePicker.error.dayOff'/>;
        } else if (dateParams.maxDate && endDateMoment.isAfter(dateParams.maxDate)) {
            error.endDate = (<FormattedMessage
                id='datePicker.error.maxDate.laterThan'
                values={{ maxDate: dateParams.maxDate.format(dateFormat.default) }}/>);
        }
    }

    return error;
};
