import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from '@shakacode/recompose';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as featuresSelectors from 'State/features/selectors';
import pageRoutes from 'Constants/page-routes';
import BaseRoute from 'Routes/BaseRoute';

const mapStateToProps = (state, props) => ({
    requiredFeatureDisabled: featuresSelectors.requiredFeatureDisabledSelector(state, props)
});

const withRequireFeature = WrappedComponent => {
    @connect(mapStateToProps)
    class WithRequireFeatureComponent extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withRequireFeature');

        static propTypes = {
            requiredFeatureDisabled: PropTypes.bool
        };

        render() {
            const { requiredFeatureDisabled, ...restProps } = this.props;

            return (
                requiredFeatureDisabled
                    ? <BaseRoute render={props => <Redirect to={`${pageRoutes.index}${props.location.search}`}/>}/>
                    : <WrappedComponent {...restProps}/>
            );
        }
    }

    return WithRequireFeatureComponent;
};

export default withRequireFeature;
