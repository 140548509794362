import PropTypes from 'prop-types';
// Disable lint rule due to frequent use of
// function-declaration.
// Use of fucntion-declaration is forced by
// highcharts callback context.
/* eslint-disable object-shorthand */
import React from 'react';
import Highcharts from 'highcharts';
import ReactHighcharts from 'highcharts-react-official';
import { AutoSizer } from 'react-virtualized';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';

import barChartModes from 'Constants/barchart-modes';
import defaultHighchartsConfig from 'Constants/default-highcharts-config';
import colorScheme from 'Constants/color-schemas';
import { changeFormatter } from '../formatters/formatters';
import { getPosition } from 'Utils/charts';

import './BarChart.scss';

const HALF_OF_BAR = 0.5;

const BarChart = (props) => {
    const {
        chartProps = {},
        mode = barChartModes.POSITIVE_MODE,
        categories,
        verticalTitle = null,
        className = '',
        customConfig = {},
        legendProps = {},
        series,
        intl,
        withRounding = true,
        isOriginalValue = false
    } = props;

    const classNames = cn('bar-chart', className);
    const barChartDefaultConfig = {
        chart: {
            type: 'column',
            spacingLeft: 20,
            animation: false,
            ...chartProps
        },
        colors: colorScheme['1'],
        xAxis: {
            labels: {
                // Due highcharts tick positioning is placed under the bar we move every tick for a half right
                formatter: function xAxisLabelFormatter() {
                    return categories[this.value + HALF_OF_BAR];
                }
            },
            gridLineWidth: 1,
            tickmarkPlacement: 'on',
            // Due highcharts tick positioning is placed under the bar we move every tick for a half left
            tickPositions: categories.map((category, index) => index - HALF_OF_BAR)
        },
        yAxis: {
            opposite: true,
            labels: {
                formatter: function yAxisLabelFormatter() {
                    return intl.formatNumber(this.value);
                }
            },
            title: {
                text: verticalTitle,
                margin: 20,
                rotation: 270
            }
        },
        legend: {
            enabled: true,
            ...legendProps
        },
        tooltip: {
            headerFormat: null,
            useHTML: true,
            formatter() {
                const tipsTemplate = this.points.map((value) => {
                    let valueY = value.y;

                    if (_.isNumber(value.y)) {
                        valueY = changeFormatter(intl, value.y, {
                            withRound: withRounding,
                            isOriginalValue
                        });
                    }

                    return `<div>
                            <span style="color:${value.series.color}">${value.series.name}</span>:
                            <span class="value ${value.y < 0 ? 'negative' : ''}">${valueY}</span>
                        </div>`;
                });

                return `<div class='ast-highcharts-tooltip' style="border-color:${this.points[0].color}">
                        ${tipsTemplate.join('')}
                    </div>`;
            },
            shared: true,
            positioner: getPosition,
            hideDelay: 0,
            animation: false,
            borderRadius: 0,
            borderWidth: 0,
            padding: 0,
            shadow: false,
            enabled: true,
            backgroundColor: 'none'
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0,
                stacking: 'normal',
                pointStart: 0,
                minPointLength: 1,
                states: {
                    inactive: {
                        opacity: 1
                    }
                }
            },
            series: {
                pointWidth: 15,
                animation: false
            }
        },
        series
    };

    switch (mode) {
        case barChartModes.NEGATIVE_MODE: {
            barChartDefaultConfig.plotOptions.series.pointWidth = 15;
            barChartDefaultConfig.plotOptions.series.groupPadding = 0.1;
            break;
        }
        case barChartModes.POSITIVE_MODE:
        default: {
            barChartDefaultConfig.yAxis.min = 0;
            barChartDefaultConfig.yAxis.tickInterval = 1;
            barChartDefaultConfig.yAxis.reversedStacks = false;
            break;
        }
    }

    const config = _.merge({}, defaultHighchartsConfig, barChartDefaultConfig, customConfig);

    return (
        <AutoSizer>
            {({ height, width }) => {
                config.chart.height = height;
                config.chart.width = width;
                config.plotOptions.series.pointWidth = adaptColumnWidth(width, mode);

                return (
                    <ReactHighcharts
                        highcharts={Highcharts}
                        options={config}
                        containerProps={{ className: classNames, style: { height, width } }}/>
                );
            }}
        </AutoSizer>
    );
};

function adaptColumnWidth(widgetWidth, mode) {
    switch (mode) {
        case barChartModes.NEGATIVE_MODE: {
            return widgetWidth * 0.06;
        }
        case barChartModes.POSITIVE_MODE:
        default: {
            return widgetWidth * 0.075;
        }
    }
}

BarChart.propTypes = {
    mode: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    series: PropTypes.arrayOf(PropTypes.object).isRequired,
    chartProps: PropTypes.object,
    legendProps: PropTypes.object,
    verticalTitle: PropTypes.string,
    intl: intlShape,
    className: PropTypes.string,
    customConfig: PropTypes.object,
    withRounding: PropTypes.bool,
    isOriginalValue: PropTypes.bool
};

export default injectIntl(BarChart);
/* eslint-enable object-shorthand */

