import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { TextCell } from './TextCell';
import Icon from 'Components/Icon/Icon';
import { ICONS } from 'Constants/search';

import './ShareholderCellWithIcon.scss';

const ShareholderCellWithIcon = (props) => {
    return (
        <div className='shareholder-cell-with-icon truncated-text'>
            <Icon className={cn('font-icon', ICONS[props.original.contextType])}/>
            <TextCell {...props}/>
        </div>
    );
};

ShareholderCellWithIcon.propTypes = {
    original: PropTypes.shape({
        contextType: PropTypes.string.isRequired
    })
};

export default ShareholderCellWithIcon;
