import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const HeadingNoDividendCell = (props) => {
    const { title, style, className, additionalData: { alignRight, alignCenter } = {} } = props;
    const cellClassName = cn(className, 'no-dividend-content', { 'cell-with-align-right': alignRight, 'cell-with-align-center': alignCenter });

    return (
        <div className={cellClassName} style={{ left: style.left }}>
            {title}
        </div>
    );
};

HeadingNoDividendCell.propTypes = {
    value: PropTypes.any,
    additionalData: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object
};
export default HeadingNoDividendCell;
