import { createReducer } from 'Utils/reducer';
import defaultValues from './defaultValues';

import {
    REPORTING_SHAREHOLDER_LIST_INITIAL,
    REPORTING_SHAREHOLDER_LIST_SET_FETCHING,
    REPORTING_SHAREHOLDER_LIST_INITIAL_ERROR,
    REPORTING_SHAREHOLDER_LIST_CHANGE_REQUEST,
    REPORTING_SHAREHOLDER_LIST_CHANGE_SUCCESS,
    REPORTING_SHAREHOLDER_LIST_CHANGE_ERROR
} from './actions';

const initialState = {
    reportData: defaultValues,
    isFetching: false,
    error: null
};

const actionHandlers = {
    [REPORTING_SHAREHOLDER_LIST_INITIAL]: (state, { payload }) => ({
        ...state,
        reportData: payload
    }),
    [REPORTING_SHAREHOLDER_LIST_SET_FETCHING]: (state, { payload }) => ({
        ...state,
        isFetching: payload
    }),
    [REPORTING_SHAREHOLDER_LIST_INITIAL_ERROR]: (state, { payload }) => ({
        ...state,
        error: payload
    }),
    [REPORTING_SHAREHOLDER_LIST_CHANGE_SUCCESS]: (state, { payload }) => ({
        ...state,
        reportData: payload
    }),
    [REPORTING_SHAREHOLDER_LIST_CHANGE_REQUEST]: (state, { payload }) => ({
        ...state,
        isChanging: payload
    }),
    [REPORTING_SHAREHOLDER_LIST_CHANGE_ERROR]: (state, { payload }) => ({
        ...state,
        error: payload
    })
};

export default createReducer(initialState, actionHandlers);
