import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../../../utils';
import { PercentFormatter, ThresholdNumberFormatter } from 'Components/formatters';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import ShareholderInformationField from '../ShareholderInformationField';
import ShareholderInformationList from '../ShareholderInformationList';

class ShareholderInformationContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        widgetData: PropTypes.object,
        widgetNoData: PropTypes.bool,
        contentClass: PropTypes.string,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        this.formatMessage = props.intl.formatMessage;
        this.formatNumber = props.intl.formatNumber;
        this.listSettings = [
            [
                {
                    dataKey: 'capGroup'
                },
                {
                    dataKey: 'type'
                },
                {
                    dataKey: 'style'
                },
                {
                    dataKey: 'turnover'
                },
                {
                    dataKey: 'avp'
                },
                {
                    dataKey: 'proxyAdv'
                },
                {
                    dataKey: 'sector'
                },
                {
                    dataKey: 'region'
                },
                {
                    dataKey: 'holdings'
                }
            ], [
                {
                    dataKey: 'equity',
                    view: (v) => <PercentFormatter withPercent value={v} />
                },
                {
                    dataKey: 'other',
                    view: (v) => <PercentFormatter withPercent value={v} />
                },
                {
                    dataKey: 'avgPB',
                    view: (v) => <ThresholdNumberFormatter value={v} symbol={'x'}/>
                },
                {
                    dataKey: 'divYld',
                    view: (v) => <PercentFormatter withPercent value={v} />
                },
                {
                    dataKey: 'beta',
                    view: (v) => <ThresholdNumberFormatter value={v}/>
                },
                {
                    dataKey: 'relStrength',
                    view: (v) => <ThresholdNumberFormatter value={v}/>
                },
                {
                    dataKey: 'avgPE',
                    view: (v) => <ThresholdNumberFormatter value={v} symbol={'x'}/>
                }
            ]
        ];
    }

    getColumnsData(widgetData) {
        const columnsData = _.map(this.listSettings, (column) => {
            const filteredColumn = _.filter(column, (item) => {
                const value = widgetData[item.dataKey];

                return !_.isNil(value) && value !== '';
            });

            return _.map(filteredColumn, (item) => {
                const value = widgetData[item.dataKey];

                return {
                    key: item.dataKey,
                    name: this.formatMessage({ id: `shareholder.information.${item.dataKey}` }),
                    value: item.view ? item.view(value) : value
                };
            });
        });

        return _.filter(columnsData, (column) => column.length);
    }

    renderTopField(widgetData) {
        const position = _.get(widgetData, 'position');

        if (!_.isNil(position)) {
            return (
                <ShareholderInformationField name={this.formatMessage({ id: 'shareholder.information.position' })}>
                    {this.formatMessage({ id: 'shareholder.information.position.value' }, { position })}
                </ShareholderInformationField>
            );
        }
        return null;
    }

    render() {
        const {
            widgetNoData,
            widgetData,
            isFetching,
            components: { WidgetBottomField },
            contentClass
        } = this.props;
        const classes = cn('widget-content', contentClass);

        return (
            <div className={classes}>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    { !widgetNoData && (<div>
                        { this.renderTopField(widgetData) }
                        <ShareholderInformationList columns={this.getColumnsData(widgetData)}/>
                        <WidgetBottomField {...this.props}/>
                    </div>) }
                </NoDataComponent>
            </div>
        );
    }
}

export default ShareholderInformationContent;

