import React from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import MeetingPlannerTrackerContent from './MeetingPlannerTrackerContent';

import './MeetingPlannerTracker.scss';

const widgetComponents = {
    WidgetContent: MeetingPlannerTrackerContent
};

const MeetingPlannerTracker = (props) => {
    return (
        <Widget
            {...props}
            className='meeting-planner-tracker'
            components={widgetComponents}/>
    );
};

MeetingPlannerTracker.propTypes = {
    ...commonWidgetPropTypes,
    ...commonWidgetActions
};

export default MeetingPlannerTracker;
