import configureStore from 'State/store';
import history from './history';
import { persistStore } from 'redux-persist';

const store = configureStore(history, {});

store.asyncReducers = {};

export default {
    ...store,
    persistor: persistStore(store)
};
