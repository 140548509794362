import _ from 'lodash';
import { replace } from 'connected-react-router';

import * as api from '../../api';
import * as userSelectors from 'State/user/selectors';
import { buildLocationWithSecurity } from 'Utils/routing';
import pageRoutes from 'Constants/page-routes';
import { cacheActions, cacheKeys } from 'Constants/cache';
import { isSecurityAvailable } from 'State/securities/selectors';


export const cacheCustomSecurity = () => (dispatch, getState) => {
    const customSecurity = userSelectors.getCustomSecurityIdSelector(getState());

    return api.manageCache({
        action: cacheActions.PUT,
        item: {
            key: cacheKeys.CUSTOM_SECURITY,
            value: customSecurity
        }
    });
};

export const getCachedCustomSecurity = () => (dispatch, getState) => {
    let customSecurityId;

    api.manageCache({
        action: cacheActions.GET,
        item: {
            key: cacheKeys.CUSTOM_SECURITY
        }
    }).then(
        res => {
            const cachedSecurity = _.get(res.data, 'payload.value');

            if (cachedSecurity && isSecurityAvailable(getState())(cachedSecurity)) {
                customSecurityId = cachedSecurity;
            }
        }
    ).finally(() => {
        dispatch(replace(buildLocationWithSecurity(pageRoutes.myProfile, customSecurityId)));
    });
};
