import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'Components/Icon';
import { CheckBoxesList } from 'Components/CheckBoxesList';
import LinkEx from 'Components/LinkEx';
import pageRoutes from 'Constants/page-routes';

class SelectPeerPopoverContent extends Component {
    static propTypes = {
        options: PropTypes.array,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        onHide: PropTypes.func
    };

    componentWillUnmount() {
        this.props.onHide();
    }

    render() {
        const { options, onClose, onSubmit } = this.props;

        return (<div className='select-peers-popover'>
            <div className='close-btn'>
                <Icon
                    className='font-icon font-icon-clear'
                    onClick={onClose}
                    titleKey='common.close'/>
            </div>
            <CheckBoxesList className='checkbox-list' position='block' list={options}/>
            {
                options.length ?
                    <div className='btn-panel'>
                        <button
                            onClick={onSubmit}
                            type='submit'
                            className='btn'>
                            <FormattedMessage id='common.apply'/>
                        </button>
                    </div> :
                    null
            }
            <p>
                <LinkEx to={pageRoutes.myProfile}>
                    <FormattedMessage id='peer.analysis.definePeers'/>
                </LinkEx>
            </p>
        </div>);
    }
}

export default SelectPeerPopoverContent;
