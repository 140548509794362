import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { HorizontalLayout, Layout, VerticalLayout } from 'Components/layout';
import Slot from 'Components/Slot';
import SlotTabs from 'Components/SlotTabs';
import {
    shareholderDynamicSlotsSelector,
    shareholderStaticSlotsSelector
} from 'State/dynamicPage/selectors';

import './ShareholdersOverviewLayout.scss';


const ShareholdersOverviewLayout = (props) => {
    const { dynamicSlots, staticSlots } = props;

    const topSlotsExist = dynamicSlots[1] || dynamicSlots[2] || dynamicSlots[3];

    return (
        <Layout className='layout-three-columns shareholders-overview-layout'>
            {topSlotsExist && (
                <div className='three-column-container row flexbox'>
                    {dynamicSlots[1] && (
                        <VerticalLayout className='column'>
                            <Slot
                                {...dynamicSlots[1]}
                                slotId={1}/>
                        </VerticalLayout>
                    )}

                    {dynamicSlots[2] && (
                        <VerticalLayout className='column'>
                            <Slot
                                {...dynamicSlots[2]}
                                slotId={2}/>
                        </VerticalLayout>
                    )}

                    {dynamicSlots[3] && (
                        <VerticalLayout className='column'>
                            <Slot
                                {...dynamicSlots[3]}
                                slotId={3}/>
                        </VerticalLayout>
                    )}
                </div>
            )}

            <div className='row flexbox'>
                <HorizontalLayout className='column'>
                    <SlotTabs slots={staticSlots} />
                </HorizontalLayout>
            </div>
        </Layout>
    );
};

ShareholdersOverviewLayout.propTypes = {
    dynamicSlots: PropTypes.object,
    staticSlots: PropTypes.object
};

export default connect((state) => ({
    dynamicSlots: shareholderDynamicSlotsSelector(state),
    staticSlots: shareholderStaticSlotsSelector(state)
}))(ShareholdersOverviewLayout);
