import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';

import pageRoutes from 'Constants/page-routes';
import LinkEx from 'Components/LinkEx/LinkEx';

import './SubscriptionsPanelView.scss';

import { CheckBox } from 'Components/CheckBox';
import { SubscriptionName } from './SubscriptionName';
import { SubscriptionTooltip } from './SubscriptionTooltip';

const SubscriptionsPanelView = props => {
    const {
        subscriptions,
        intl: { formatMessage, messages },
        onChangeSubscription,
        onChangeEmailSubscriptionStatus,
        isDownloadPageOpen
    } = props;
    const subscriptionList = subscriptions.map(subscription => subscription.type
        ? (
            <div className='subscription-row' key={subscription.type}>
                <SubscriptionTooltip tooltipMessage={messages[`subscription.tooltip.${_.camelCase(subscription.type)}`]}>
                    <SubscriptionName
                        type={subscription.type}
                        name={subscription.name}
                        checked={subscription.isActive}
                        disabled={subscription.isDisabledStatus}
                        onChange={onChangeSubscription} />
                </SubscriptionTooltip>
                <SubscriptionTooltip tooltipMessage={messages[`subscription.tooltip.${_.camelCase(subscription.type)}`]}>
                    <CheckBox
                        onChange={e => onChangeEmailSubscriptionStatus(subscription.type, e.target.checked)}
                        checked={subscription.isSubscribedEmail}
                        disabled={subscription.isDisabledEmailStatus}/>
                </SubscriptionTooltip>
            </div>
        )
        : null);

    return (
        <div className='subscriptions-panel'>
            <div className='subscriptions-panel-header'>
                <span>{formatMessage({ id: 'subscription.header.message' })}</span>
            </div>
            <div className='subscriptions-panel-list-header'>
                <FormattedHTMLMessage id='subscription.list.header.seeReports'/>
                <FormattedHTMLMessage id='subscription.list.header.receiveEmail'/>
            </div>
            {subscriptionList}
            <div className='subscriptions-panel-footer'>
                <span className='message-note'>{formatMessage({ id: 'subscription.footer.message' })}</span>
                {' '}
                {isDownloadPageOpen
                    ? <span>{formatMessage({ id: 'subscription.footer.link' })}</span>
                    : (<LinkEx to={pageRoutes.reportingDownloads}>
                        {formatMessage({ id: 'subscription.footer.link' })}
                    </LinkEx>)
                }
            </div>
        </div>
    );
};

SubscriptionsPanelView.propTypes = {
    subscriptions: PropTypes.array,
    onChangeSubscription: PropTypes.func,
    onChangeEmailSubscriptionStatus: PropTypes.func,
    intl: intlShape,
    isDownloadPageOpen: PropTypes.bool
};

export default injectIntl(SubscriptionsPanelView);
