import { createSelector } from 'reselect';
import _ from 'lodash';

import { VISIBLE_QUARTERS, EXPORT_VISIBLE_QUARTERS } from './historicalAnalysisTableConfig';
import formatterConstants from 'Constants/formatter-options';
import { getDateString } from './utils';

const minMarketValue = 0;

const getWidgetSettings = props => props.widgetSettings;
const getWidgetData = props => props.widgetData;
const getOffset = (props, offset = 0) => offset;

const mapMktVal = mktVal => mktVal / formatterConstants.MM_SCALE;

const getQuarters = createSelector(
    getWidgetData,
    widgetData => _.get(widgetData, 'quarters', [])
);

const getSelectedShareholderIDs = createSelector(
    getWidgetSettings,
    widgetSettings => _.get(widgetSettings, 'selectedShareholders', []).map(sh => sh.shareholderId)
);

export const getShareholderData = createSelector(
    getWidgetData,
    widgetData => {
        const data = _.get(widgetData, 'data', []);

        return _.groupBy(data, item => item.shareholderId);
    }
);

export const getShareholders = createSelector(
    getWidgetData,
    widgetData => _.get(widgetData, 'shareholders', [])
);

export const getSelectedShareholders = createSelector(
    [getWidgetSettings, getShareholders, getSelectedShareholderIDs],
    (widgetSettings, shareholders, selectedShareholderIDs) => {
        const selectedShareholders = [];

        _.forEach(selectedShareholderIDs, shId => {
            const shareholder = _.find(shareholders, sh => sh.shareholderId === shId);

            if (shareholder) {
                selectedShareholders.push(shareholder);
            }
        });

        return selectedShareholders;
    }
);

export const getMaxOffset = createSelector(
    getQuarters,
    ({ isPrintMode }) => isPrintMode,
    (selectedQuarters, isPrintMode) => isPrintMode
        ? selectedQuarters.length - EXPORT_VISIBLE_QUARTERS
        : selectedQuarters.length - VISIBLE_QUARTERS
);

const getVisibleQuarters = createSelector(
    [getQuarters, getOffset, ({ isPrintMode }) => isPrintMode],
    (selectedQuarters, offset, isPrintMode) => isPrintMode
        ? [...selectedQuarters.slice(offset, EXPORT_VISIBLE_QUARTERS + offset)]
        : [...selectedQuarters.slice(offset, VISIBLE_QUARTERS + offset)]
);

export const getMappedVisibleQuarters = createSelector(
    getVisibleQuarters,
    visibleQuarters => _.map(visibleQuarters, quarter => ({
        series: _.map(quarter.marketPrice, data => ({
            value: data.price,
            name: getDateString(data.date)
        }))
    }))
);

/**
 * need to pass offset
 */
export const getGroupsSettings = createSelector(
    getVisibleQuarters,
    visibleQuarters =>  _.map(visibleQuarters, quarter => ({
        id: quarter.date,
        label: getDateString(quarter.date)
    }))
);

export const getTableData = createSelector(
    [getShareholders, getSelectedShareholders, getShareholderData, props => props.maxSelectedShareholders],
    (shareholders, selectedShareholders, shareholdersData, maxSelectedShareholders) => {
        const tableData = _.map(shareholders, shareholder => {
            const isSelected = selectedShareholders.some(sh => sh.shareholderId === shareholder.shareholderId);

            return ({
                ...shareholder,
                groups: _.keyBy(shareholdersData[shareholder.shareholderId], 'quarter'),
                isSelected,
                isDisabled: selectedShareholders.length >= maxSelectedShareholders && !isSelected
            });
        });

        return tableData;
    }
);

export const getDataVizTableData = createSelector(
    getTableData,
    tableData => _.filter(tableData, item => item.isSelected)
);

const getQuartersData = createSelector(
    getWidgetData,
    widgetData => {
        const data = _.get(widgetData, 'data', []);

        return _.groupBy(data, item => item.quarter);
    }
);

export const getChartSeries = createSelector(
    [getSelectedShareholders, getQuartersData, getVisibleQuarters],
    (selectedShareholders, quartersData, visibleQuarters) => {
        return _.map(selectedShareholders, selectedShareholder => {
            return ({
                shareholderId: selectedShareholder.shareholderId,
                name: selectedShareholder.shareholderName,
                // optimizable
                data: visibleQuarters
                    .map(quarter => {
                        const quarterData = quartersData[quarter.date] || [];
                        const quarterAndShareholderData = quarterData
                            .find(qd => qd.shareholderId === selectedShareholder.shareholderId);
                        // highchart needs null
                        const val = _.get(quarterAndShareholderData, 'mktVal', null);

                        return _.isNumber(val) ? mapMktVal(quarterAndShareholderData.mktVal) : val;
                    })
            });
        });
    }
);

/**
 * need to pass offset
 */
export const getChartGroups = createSelector(
    getVisibleQuarters,
    visibleQuarters => _.map(visibleQuarters, quarter => getDateString(quarter.date))
);

const getMktPriceLimits = createSelector(
    getQuarters,
    quarters => {
        const values = [];

        _.forEach(quarters, quarter => {
            values.push(..._.map(quarter.marketPrice, data => data.price));
        });

        return {
            min: _.min(values),
            max: _.max(values)
        };
    }
);

export const getMktLimits = createSelector(
    [getWidgetData, getWidgetSettings, getQuarters, getMktPriceLimits, getSelectedShareholderIDs],
    (widgetData, widgetSettings, quarters, mktPriceLimits, selectedShareholderIDs) => {
        const quarterIDs = _.map(quarters, q => q.date);
        const data = _.get(widgetData, 'data');
        const quartersData = _.filter(data, d => quarterIDs.includes(d.quarter) && selectedShareholderIDs.includes(d.shareholderId));
        const values = _.map(quartersData, d => mapMktVal(d.mktVal));

        return {
            minMktValue: minMarketValue,
            maxMktValue: _.max(values),
            minMktPrice: mktPriceLimits.min,
            maxMktPrice: mktPriceLimits.max
        };
    }
);

export const getFindModalAvailability = createSelector(
    getSelectedShareholders,
    props => props.maxSelectedShareholders,
    (shareholders, maxSelectedShareholders) => shareholders.length < maxSelectedShareholders
);

export const getSavedColors = createSelector(
    [getWidgetSettings],
    (settings) => {
        const selectedShareholders = _.get(settings, 'selectedShareholders', []);
        // object where key is shareholderId and value is color
        const selectedShareholderColors = {};

        selectedShareholders.forEach(sh => {
            selectedShareholderColors[sh.shareholderId] = sh.color;
        });

        return selectedShareholderColors;
    }
);
