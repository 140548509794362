import React from 'react';
import cn from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { LazyPopover } from 'Components/Popover';
import Icon from 'Components/Icon';

export const HeadingCellFileStatus = ({ title, sortProperty, sortAscendingIcon, sortDescendingIcon, className }) => {
    const popoverProps = {
        id: 'FILE-STATUS-POPOVER',
        trigger: <Icon className='font-icon font-icon-info-outline' />,
        triggerType: ['hover'],
        placement: 'top'
    };

    return (
        <div className={cn('heading-cell', 'file-status-heading-cell', className)}>
            <div className='file-status-heading-cell__title'>
                <FormattedMessage id={title} defaultMessage={title} />
            </div>
            <LazyPopover {...popoverProps}>
                <div className='file-status-heading-cell__tooltip'>
                    <FormattedHTMLMessage id='tables.headingFileStatus.failed.tooltip' tagName='p' />
                    <FormattedHTMLMessage id='tables.headingFileStatus.securityCheckFailed.tooltip' tagName='p' />
                </div>
            </LazyPopover>
            {sortProperty && sortProperty.sortAscending && sortAscendingIcon}
            {sortProperty && !sortProperty.sortAscending && sortDescendingIcon}
        </div>
    );
};

HeadingCellFileStatus.propTypes = {
    title: PropTypes.string,
    sortAscendingIcon: PropTypes.element,
    sortDescendingIcon: PropTypes.element,
    sortProperty: PropTypes.object,
    className: PropTypes.string
};
