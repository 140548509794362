import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getSlotsForRegisteredOverviewLayoutSelector } from 'State/dynamicPage/selectors';
import { Layout } from '../../layout';
import Slot from '../../Slot';

import './LayoutWithLeftAside.scss';

const LayoutWithLeftAside = ({ slots }) => {
    return (
        <Layout className='layout-with-left-aside'>
            <div className='first-slot'>
                <Slot
                    {...slots[0]}
                    slotId={1}
                    isLocked/>
            </div>
            <div className='second-slot'>
                <Slot
                    {...slots[1]}
                    slotId={2}
                    isLocked/>
            </div>
        </Layout>
    );
};

LayoutWithLeftAside.propTypes = {
    slots: PropTypes.array
};

const mapStateToProps = (state, props) => {
    return {
        ...props,
        slots: getSlotsForRegisteredOverviewLayoutSelector(state, props.slots)
    };
};

export default connect(mapStateToProps)(LayoutWithLeftAside);
