import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';

import Tooltip from 'Components/Tooltip';

const withTooltip = WrappedComponent => class extends Component {
    static displayName = wrapDisplayName(WrappedComponent, 'withTooltip');

    static propTypes = {
        ...WrappedComponent.propTypes,
        tooltip: PropTypes.string
    };

    render() {
        const { tooltip, ...rest } = this.props;

        return tooltip
            ? (<Tooltip content={tooltip}>
                <WrappedComponent {...rest}/>
            </Tooltip>)
            : <WrappedComponent {...rest}/>;
    }
};

export default withTooltip;
