import React, { Component } from 'react';

import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from './slots';

export default class ShareholdersPage extends Component {
    render() {
        return (
            <div className='shareholders-page'>
                <DynamicPage
                    layoutType={layoutTypes.shareholdersOverview}
                    slots={slots}
                    dynamicPageType={PAGE_TYPES.OWNERSHIP_SHAREHOLDERS}/>
            </div>
        );
    }
}
