import * as _ from 'lodash';

import { compareDescending, compareSpecialNullSorting } from 'Utils/utils';
import columnNames from 'Constants/shareholders-columns-names';
import sortDirections from 'Constants/sort-directions';

export function getTdProps(columnId, item, { onSelect, title }) {
    switch (columnId) {
        case columnNames.SHAREHOLDER_NAME: {
            return {
                shareholder: item,
                onSelect,
                title
            };
        }
        default: {
            return {};
        }
    }
}

/**
 * @param data (Array) - shareholders data
 * @param sortColumn (String) - shareholderId
 * @param sortField (String) - field of shareholder (shareholderName/position/mktVal/os)
 * @param sortDirection (String) - sortDirection constant
 * @param initial (Boolean) - is initial sorting
 */
export const sortData = (data, { sortColumn, sortField, sortDirection, initial }) => {
    const sortByShareholderName = sortField === columnNames.SHAREHOLDER_NAME;
    const valueGetter = sortByShareholderName ?
        sortField :
        `groups.${sortColumn}.${sortField}`;
    const descending = sortDirection === sortDirections.DESC;

    const sortedData = data.slice().sort((firstElement, secondElement) => {
        //
        const isFirstPinned = firstElement.pinned;
        const isSecondPinned = secondElement.pinned;

        if (isFirstPinned !== isSecondPinned) {
            return isFirstPinned ? -1 : 1;
        }
        const firstValue = _.get(firstElement, valueGetter);
        const secondValue = _.get(secondElement, valueGetter);

        let specialNullSortingResult = compareSpecialNullSorting(firstValue, secondValue, initial);

        if (specialNullSortingResult !== undefined) {
            return specialNullSortingResult;
        }

        const result = compareDescending(firstValue, secondValue);

        if (result === 0 && !sortByShareholderName || _.isNil(firstValue) && _.isNil(secondValue)) {
            const firstShareholder = _.get(firstElement, columnNames.SHAREHOLDER_NAME);
            const secondShareholder = _.get(secondElement, columnNames.SHAREHOLDER_NAME);

            specialNullSortingResult = compareSpecialNullSorting(firstShareholder, secondShareholder, initial);
            if (specialNullSortingResult !== undefined) {
                return specialNullSortingResult;
            }

            return -compareDescending(firstShareholder, secondShareholder);
        }

        return result * (descending ? 1 : -1);
    });

    return sortedData;
};

export const getColumnId = (columnName, columnField) => {
    if (columnField === columnNames.SHAREHOLDER_NAME) {
        return columnField;
    }
    return columnField + columnName;
};
