import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { mapProps, getContext } from '@shakacode/recompose';
import _ from 'lodash';

import ScrollableReactTable from 'Components/Tables/ScrollableReactTable';
import sortDirections from 'Constants/sort-directions';
import { commonWidgetPropTypes, commonWidgetActions } from '../../../utils';
import { getSortDirection } from 'Components/Tables/utils';
import { HeadingCellCommon, CellCommon } from 'Components/widgets/_commonComponents/TableCellComponents';
import { NoResults } from 'Components/Tables/components/ReactTableComponents';

const MAX_SHOW_ROWS = 15;

@injectIntl
@getContext({
    events: PropTypes.object
})
@mapProps(props => {
    const visibleColumns = _.get(props, 'widgetSettings.visibleColumns', []);
    const columns = props.columnDefinitions.columns
        .filter(column => visibleColumns.indexOf(column.columnName) > -1);

    return {
        ...props,
        columns
    };
})
class ContactsTableContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        intl: intlShape,
        columns: PropTypes.array,
        events: PropTypes.object,
        columnDefinitions: PropTypes.object,
        noDataMessageId: PropTypes.string
    };

    handleSort = (tableSortProps, column) => {
        const { columnDefinitions, widgetSettings } = this.props;
        const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
        const updatedSortCriteria = {
            sortField: column.sortProp,
            sortDirection: getSortDirection(
                column.sortProp,
                sortCriteria.sortField,
                sortCriteria.sortDirection,
                columnDefinitions.columns,
                /* sortProperty */
                'sortProp')
        };

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            sortCriteria: updatedSortCriteria
        });
        this.props.events.onUpdateWidgetData({ sortCriteria: updatedSortCriteria });
    };

    getColumnCell = (cellProps, additionalData) => {
        const { columnDefinitions: { columnNames }, widgetPageParams: { shareholderId } } = this.props;
        const { value: cellValue, column } = cellProps;
        let additionalProps;

        switch (column.columnName) {
            case columnNames.NAME:
                additionalProps = {
                    isShareholderContactsView: this.props.isShareholderContactsView
                };
                break;
            case columnNames.ORGANIZATION:
                additionalProps = {
                    shareholderId
                };
                break;
            default:
                additionalProps = {};
        }

        return column.cellComponent
            ? column.cellComponent(cellProps, additionalProps)
            : <CellCommon value={cellValue} additionalData={additionalData}/>;
    };

    getColumns = () => {
        const { intl: { formatMessage }, widgetSettings, columns = [], isDataViz } = this.props;
        const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});

        return columns.map(column => {
            const sortProperty = !isDataViz && column.sortProp === sortCriteria.sortField ?
                { sortAscending: sortCriteria.sortDirection === sortDirections.ASC } :
                null;

            return {
                ...column,
                accessor: column.columnName,
                Header: () => (<HeadingCellCommon
                    title={formatMessage({ id: column.displayName })}
                    sortProperty={sortProperty}/>),
                Cell: cellProps => this.getColumnCell(cellProps, column.additionalData)
            };
        });
    };

    getNoDataProps = () => {
        const { noDataMessageId } = this.props;

        return ({
            messageId: noDataMessageId
        });
    };

    render() {
        const { isDataViz, isPrintMode, widgetData } = this.props;
        const values = _.get(widgetData, 'values', []);

        return (
            <ScrollableReactTable
                manual
                columns={this.getColumns()}
                resolvedData={values}
                onSortedChange={this.handleSort}
                getNoDataProps={this.getNoDataProps}
                NoDataComponent={NoResults}
                showPagination={false}
                showPageSizeOptions={false}
                LoadingComponent={() => null}
                resizable={false}
                sortable={!isDataViz}
                maxShowRows={isDataViz && !isPrintMode ? MAX_SHOW_ROWS : null}
                pageSize={values.length}/>
        );
    }
}

export default ContactsTableContent;
