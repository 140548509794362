import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { CurrencyFormatter } from 'Components/formatters';
import formatters from 'Constants/formatter-options';

const CellWithCurrency = (props) => {
    const { value, className, additionalData: { decimalPoints, isOriginalValue } = {} } = props;
    const cellClass = cn('cell-with-currency-usd', className);
    const currencyDecimalPoints = _.isNumber(decimalPoints) ? decimalPoints : formatters.DEFAULT_DECIMAL_POINTS;

    return (
        <div className={cellClass}>
            <CurrencyFormatter
                value={value}
                decimalPoints={currencyDecimalPoints}
                isOriginalValue={isOriginalValue}/>
        </div>
    );
};

CellWithCurrency.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    decimalPoints: PropTypes.number,
    className: PropTypes.string,
    additionalData: PropTypes.shape({
        decimalPoints: PropTypes.number,
        isOriginalValue: PropTypes.bool
    })
};

export default CellWithCurrency;
