import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import Widget from '../Widget';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import WidgetControlPanelDefault from '../Widget/components/WidgetControlPanelDefault';
import { savedReportsEnhancer } from 'Hoc';
import SavedReportTemplatesView from '../../../pages/reporting/ReportingPage/components/SavedReports/components/SavedReportTemplatesView';
import reportTypes from 'Constants/report-types';

import './SavedReportTemplatesWidget.scss';

@injectIntl
@savedReportsEnhancer
class SavedReportTemplatesWidget extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        onRunReport: PropTypes.func,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: SavedReportTemplatesView,
            WidgetControlPanel: WidgetControlPanelDefault
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'saved.reports.title' });
    }

    handleDelete = (report) => {
        this.props.onDelete(report, this.successDelete);
    };

    successDelete = () => {
        this.props.onUpdateWidgetData();
    };

    render() {
        const props = {
            ...this.props,
            reports: _.filter(_.get(this.props, 'widgetData.reports'), report => report.type !== reportTypes.dataVizStartedTemplate),
            widgetTitle: this.widgetTitle
        };

        return (
            <Widget
                {...props}
                className='widget-saved-report-templates'
                components={this.widgetComponents}
                onDelete={this.handleDelete}
                isPossibleAddToDataViz={false}/>
        );
    }
}

export default SavedReportTemplatesWidget;
