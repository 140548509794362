import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import WidgetErrorDefault from '../WidgetError';
import WidgetLayoutEnhancer from '../WidgetLayoutEnhancer';

import './RegisteredOverviewWidgetLayout.scss';

const RegisteredOverviewWidgetLayout = (props) => {
    const {
        WidgetControlPanel,
        WidgetActionPanel,
        WidgetFilterPanel,
        WidgetInfoPanel,
        WidgetContent,
        WidgetError,
        WidgetBusy,
        isError,
        isFetching
    } = props;
    const className = cn('widget', 'registered-overview', props.className || '', {
        'widget-error': isError
    });

    const ControlPanel = WidgetControlPanel ? <WidgetControlPanel {...props}/> : null;
    const ActionPanel = WidgetActionPanel ? <WidgetActionPanel {...props}/> : null;
    const FilterPanel = WidgetFilterPanel ? <WidgetFilterPanel {...props}/> : null;
    const InfoPanel = WidgetInfoPanel ? <WidgetInfoPanel/> : null;
    const Content = WidgetContent ? <WidgetContent {...props}/> : null;
    const Error = WidgetError ? <WidgetError {...props}/> : <WidgetErrorDefault {...props}/>;
    const Busy = WidgetBusy ? <WidgetBusy {...props}/> : null;
    const showContent = !isError && !isFetching;

    return (
        <div className={className} data-widget-type={props.widgetType}>
            <div className='widget-header-container'>
                {ActionPanel}
                {ControlPanel}
            </div>
            {InfoPanel}
            {FilterPanel}
            <div className='widget-block'>
                { showContent && Content }
                { isError && !isFetching && Error }
                { isFetching && Busy }
            </div>
        </div>
    );
};

RegisteredOverviewWidgetLayout.propTypes = {
    className: PropTypes.string,
    components: PropTypes.object,
    WidgetHeader: PropTypes.any,
    WidgetActionPanel: PropTypes.any,
    WidgetControlPanel: PropTypes.any,
    WidgetFilterPanel: PropTypes.any,
    WidgetInfoPanel: PropTypes.any,
    WidgetContent: PropTypes.any,
    WidgetError: PropTypes.any,
    WidgetBusy: PropTypes.any,
    WidgetDataLoader: PropTypes.any,
    isError: PropTypes.bool,
    isFetching: PropTypes.bool,
    widgetType: PropTypes.string
};

export default WidgetLayoutEnhancer(RegisteredOverviewWidgetLayout);
