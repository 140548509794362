import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes } from 'redux-form';

import ExportReportModalView from '../components/ExportReportModalView';
import fileFormats from 'Constants/export-report-formats';

import '../components/ExportReportModal.scss';

const FORM_NAME = 'exportTypeForm';
const INITIAL_VALUES = {
    selectedFormat: fileFormats.EXCEL
};
const mapStateToProps = (state) => ({
    formValues: getFormValues(FORM_NAME)(state)
});

@injectIntl
@connect(mapStateToProps)
@reduxForm({
    form: FORM_NAME,
    initialValues: INITIAL_VALUES
})
class ExportReportModal extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        hideModal: PropTypes.func,
        onSuccess: PropTypes.func,
        showExcelLimit: PropTypes.bool
    };

    handleExport = ({ selectedFormat }) => {
        const { hideModal } = this.props;

        hideModal();
        this.props.onSuccess(selectedFormat);
    };

    render() {
        const { hideModal } = this.props;

        return (
            <ExportReportModalView
                {...this.props}
                onExport={this.handleExport}
                onCancel={hideModal}/>
        );
    }
}

export default ExportReportModal;
