import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';

import { widgetSettingsMiddleware, widgetsReadyForPrintMiddleware } from './widgets/middlewares';
import { dynamicPageSyncMiddleware } from './dynamicPage/middlewares';
import { widgetPrintMiddleware } from './print/middlewares';
import { locationMiddleware } from './routes/middlewares';
import { appVersionMiddleware } from './appVersion/middlewares';

export function createReducer(history) {
    const reducers = require('./reducers').default;

    return combineReducers({
        ...reducers,
        router: connectRouter(history),
        form,
        toastr: toastrReducer
    });
}

export default function configureStore(history, initialState = {}) {
    const middleware = [
        routerMiddleware(history),
        thunk,
        locationMiddleware,
        widgetSettingsMiddleware,
        widgetsReadyForPrintMiddleware,
        dynamicPageSyncMiddleware,
        widgetPrintMiddleware,
        appVersionMiddleware
    ];
    const reducer = createReducer(history);
    const composeEnhancers = __DEVTOOLS__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        reducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware)),
    );

    // Replace store in HMR
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createReducer(history));
        });
    }

    return store;
}
