import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { mapProps } from '@shakacode/recompose';
import cn from 'classnames';

import LinkEx from 'Components/LinkEx';
import { hideModal } from 'State/modal';
import InfoModalView from '../components/InfoModalView';

const mapStateToProps = (state) => {
    return { ...state.modal };
};
const mapDispatchToProps = { hideModal };
const injectLink = (props) => {
    const { messageValues, links } = props;

    const linkComponents = {};

    if (messageValues && links) {
        Object.keys(links)
            .filter((linkKey) => !!messageValues[linkKey])
            .forEach((linkKey) =>
                linkComponents[linkKey] = (
                    <LinkEx
                        key={links[linkKey] + messageValues[linkKey]}
                        to={links[linkKey]}>
                        {messageValues[linkKey]}
                    </LinkEx>
                ));
    }

    return {
        ...props,
        messageValues: {
            ...messageValues,
            ...linkComponents
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
@mapProps((props) => injectLink(props))
@injectIntl
class InfoModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        isHtmlMessage: PropTypes.bool,
        messageKey: PropTypes.string.isRequired,
        messageValues: PropTypes.object,
        noteKey: PropTypes.string,
        links: PropTypes.object,
        okTextKey: PropTypes.string,
        actionTextKey: PropTypes.string,
        onAction: PropTypes.func,
        modalClassName: PropTypes.string,
        messageClassName: PropTypes.string,
        actionsClassName: PropTypes.string,
        noteClassName: PropTypes.string
    };

    render() {
        const {
            intl: { formatMessage },
            isHtmlMessage, messageKey, messageValues, noteKey, okTextKey,
            actionTextKey, modalClassName, messageClassName,
            actionsClassName, noteClassName, onAction
        } = this.props;

        const props = {
            isHtmlMessage,
            messageKey,
            messageValues,
            noteKey,
            okText: formatMessage({ id: okTextKey || 'common.ok' }),
            onOk: this.props.hideModal,
            actionText: actionTextKey && formatMessage({ id: actionTextKey }),
            onAction,
            modalClassNames: cn('info-modal', modalClassName),
            messageClassNames: cn('modal-message', messageClassName),
            noteClassNames: cn('modal-note message-note', noteClassName),
            actionsClassNames: cn('modal-actions', actionsClassName)
        };

        return (
            <InfoModalView {...props}/>
        );
    }
}
export default InfoModal;
