import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import Dropdown from 'Components/Dropdown';

import periodsDefinitions from '../Top25HoldingsTable/constants/periodsDefinitions';
import { commonSortDescendingIcon, commonSortAscendingIcon } from 'Components/Tables/components/commonSortIcons';

const styleData = {
    width: '4.7rem',
    padding: '.3rem 1rem .3rem .5rem',
    fontSize: '1.2rem'
};

class HeadingCellWithPositionChange extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        sortAscendingIcon: PropTypes.element,
        sortDescendingIcon: PropTypes.element,
        sortProperty: PropTypes.object,
        handlers: PropTypes.object,
        additionalData: PropTypes.shape({
            period: PropTypes.string,
            disabledSelector: PropTypes.bool
        })
    };

    static defaultProps = {
        sortAscendingIcon: commonSortAscendingIcon,
        sortDescendingIcon: commonSortDescendingIcon
    };

    constructor(props) {
        super(props);

        const { additionalData: { disabledSelector } = {} } = props;

        if (!disabledSelector) {
            this.periods = this.getPeriods();
        }
    }

    handleClick(e) {
        e.stopPropagation();
    }

    getPeriods() {
        return periodsDefinitions.map(period => ({
            label: period,
            value: period
        }));
    }

    getSelectedPeriod() {
        const { additionalData: { period } = {} } = this.props;

        return _.find(this.periods,
            periodObj => periodObj.value === period);
    }

    render() {
        const {
            title,
            className,
            style,
            handlers,
            sortProperty,
            sortAscendingIcon,
            sortDescendingIcon,
            additionalData: { disabledSelector, period } = {}
        } = this.props;
        const cellClass = cn('heading-cell-position-change', className);
        const selectPeriod = this.getSelectedPeriod();

        return (
            <div
                className={cellClass}
                style={style}>
                <span className='label'>{title}</span>
                {
                    disabledSelector
                        ? <span className='not-selected-period'>[ {period} ]</span>
                        : (<div
                            onClick={this.handleClick}
                            className='dropdown-wrapper'>
                            <Dropdown
                                selectedValue={selectPeriod ? selectPeriod.value : null}
                                options={this.periods}
                                styleData={styleData}
                                onSelect={handlers.handleOnChangePeriod}
                                classNameForItem='dropdown-item'/>
                        </div>)
                }
                {sortProperty && sortProperty.sortAscending && sortAscendingIcon}
                {sortProperty && !sortProperty.sortAscending && sortDescendingIcon}
            </div>
        );
    }
}

export default HeadingCellWithPositionChange;
