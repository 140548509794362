import featureType from 'Constants/feature-types';

export const CONTEXTS = {
    INSTITUTIONS: 'INSTITUTIONS',
    INSIDERS: 'INSIDERS',
    REGISTERED: 'REGISTERED',
    FUNDS: 'FUNDS',
    CONTACTS: 'CONTACTS'
};

export const CONTEXT_ORDER = [
    CONTEXTS.REGISTERED,
    CONTEXTS.INSIDERS,
    CONTEXTS.INSTITUTIONS,
    CONTEXTS.FUNDS,
    CONTEXTS.CONTACTS
];

export const PII = {
    NONE: 'NONE',
    TAX_ID: 'TAX_ID',
    CERTIFICATE_NO: 'CERTIFICATE_NO'
};

export const ICONS = {
    [CONTEXTS.INSTITUTIONS]: 'font-icon-institution',
    [CONTEXTS.INSIDERS]: 'font-icon-insider',
    [CONTEXTS.REGISTERED]: 'font-icon-registered',
    [CONTEXTS.FUNDS]: 'font-icon-funds',
    [CONTEXTS.CONTACTS]: 'font-icon-contact'
};

export const FILTERS = {
    Open: 'Open',
    Closed: 'Closed',
    US: 'US',
    Foreign: '!US',
    Undefined: 'Undefined'
};

export const VALIDATION_LENGTH = {
    MIN_LENGTH: 2,
    MAX_LENGTH: 130,
    SSN_SEARCH_LENGTH: 9,
    CERTIFICATE_NO_MIN_LENGTH: 1,
    CERTIFICATE_NO_MAX_LENGTH: 10
};

export const PAGE_SIZE = 10;
export const PAGE_INCREMENT = 100;

export const PII_TO_L10N = {
    [PII.CERTIFICATE_NO]: 'cert',
    [PII.TAX_ID]: 'tax',
    [PII.NONE]: 'global'
};

export const PII_TO_FEATURE = {
    [PII.CERTIFICATE_NO]: featureType.ADVANCED_SEARCH_CERT,
    [PII.TAX_ID]: featureType.ADVANCED_SEARCH_SSN,
    [PII.NONE]: featureType.ADVANCED_SEARCH_KEYWORDS
};

export const SEARCH_FORM_NAME = 'search';

export const MAX_SHAREHOLDERS_TO_COMPARE = 50;

export const MAX_SECURITY_COUNT = 1500;
