import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import AppFooter from 'Components/AppFooter';
import { EquinitiFooter } from 'Components/EquinitiFooter';
import { ModalRoot } from 'Components/Modals';
import PageHeader from 'Components/PageHeader';
import MultipleSecuritySelector from 'Components/MultipleSecuritySelector';
import ActionPanel from './ActionPanel';
import SearchBySecurity from 'Components/SearchBySecurity';

import './SecurityListPage.scss';

const SecurityListPageView = ({ isSecurities, isSomeSecuritySelected, isAdvancedSearchAvailable, issuersAmount }) => {
    const isSingleIssuer = issuersAmount === 1;
    const securitySelectorTitleKey = isSingleIssuer ? 'security.selectSecurity.multi.security.title' : 'security.selectSecurity.multi.issuerAndSecurity.title';

    return (
        <div className='app'>
            <PageHeader/>
            <section className='page-main-section'>
                <section className='page-content'>
                    <div className='security-list-page'>
                        <div className='central-block'>
                            {!isSingleIssuer &&
                            <div className='message-note'>
                                <FormattedMessage id='security.selectSecurity.infoMessage'/>
                            </div>}
                            {isSecurities &&
                            <div className='security-title'>
                                <h3 className='title'>
                                    <FormattedMessage
                                        id={securitySelectorTitleKey}/>
                                </h3>
                                <div className={cn('message-note', { right: !isSingleIssuer })}>
                                    <FormattedMessage id='security.selectSecurity.multi.description'/>
                                </div>
                            </div>}
                            {isSecurities
                                ? <div>
                                    <MultipleSecuritySelector/>
                                    <ActionPanel/>
                                </div>
                                : <div className='no-securities'>
                                    <FormattedMessage id='security.selectSecurity.noSecurities'/>
                                </div>}
                        </div>
                        {isAdvancedSearchAvailable &&
                        <div className='search-by-security-container'>
                            <div>
                                <h3 className={cn('title', { disabled: !isSomeSecuritySelected })}>
                                    <FormattedMessage id='security.selectSecurity.search'/></h3>
                                <SearchBySecurity/>
                            </div>
                        </div>
                        }
                    </div>
                </section>
            </section>
            {DEMO_MODE ? <EquinitiFooter/> : <AppFooter />}
            <ModalRoot/>
        </div>
    );
};

SecurityListPageView.propTypes = {
    isSecurities: PropTypes.bool,
    isSomeSecuritySelected: PropTypes.bool,
    isAdvancedSearchAvailable: PropTypes.bool,
    issuersAmount: PropTypes.number
};

export default SecurityListPageView;
