import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from '@shakacode/recompose';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as userSelectors from 'State/user/selectors';
import pageRoutes from 'Constants/page-routes';

const mapStateToProps = state => ({
    hasNoSecurity: userSelectors.hasNoSecuritySelector(state)
});

const withSecurity = WrappedComponent => {
    @connect(mapStateToProps)
    class WithSecurityComponent extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withSecurity');

        static propTypes = {
            hasNoSecurity: PropTypes.bool
        };

        render() {
            const { hasNoSecurity, ...props } = this.props;

            return (
                hasNoSecurity
                    ? <Redirect to={pageRoutes.securityList}/>
                    : <WrappedComponent {...props}/>
            );
        }
    }

    return WithSecurityComponent;
};

export default withSecurity;
