import { createReducer } from 'Utils/reducer';
import { SET_FEATURES, SET_PRODUCT_SOURCES } from './actions';

const initialState = {
    currentFeatures: {},
    currentProductSources: []
};

const actionHandlers = {
    [SET_FEATURES]: (state, { payload }) => ({
        ...state,
        currentFeatures: payload
    }),
    [SET_PRODUCT_SOURCES]: (state, { payload }) => {
        return {
            ...state,
            currentProductSources: payload
        };
    }
};

export default createReducer(initialState, actionHandlers);
