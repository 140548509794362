import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Selector from 'Components/Selector';

const PageSize = ({ currentPageSize, options, onOptionChange, totalRecords, isPageSizeFixed }) => (
    <div className='page-size-container'>
        <span className='pagination-text'>
            {totalRecords >= 0
                ? <FormattedMessage id='pagination.totalRecords' values={{ totalRecords }}/>
                : <FormattedMessage id='pagination.show'/>
            }
        </span>
        {!isPageSizeFixed &&
            <Selector
                className='page-size-selector'
                name='page-size-selector'
                value={currentPageSize.toString()}
                options={options}
                onOptionChange={onOptionChange}
                clearable={false}
                searchable={false}/>
        }
        {!isPageSizeFixed &&
            <span className='pagination-text'>
                <FormattedMessage id='pagination.perPage'/>
            </span>
        }
    </div>
);

PageSize.propTypes = {
    currentPageSize: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })
    ).isRequired,
    onOptionChange: PropTypes.func.isRequired,
    totalRecords: PropTypes.number,
    isPageSizeFixed: PropTypes.bool
};

PageSize.defaultProps = {
    isPageSizeFixed: false
};

export default PageSize;
