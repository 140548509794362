import { compose, withProps } from '@shakacode/recompose';

import CompareShareholders1042S from './CompareShareholders1042S';
import { compareShareholders } from '../_commonComponents/RegisteredShareholderTable/hoc';
import { addSecurityColumn } from '../_commonComponents/RegisteredShareholderTable/utils';
import columnsDefinitions from './constants/columnsDefinitions';

export default compose(
    withProps(({ widgetData: values }) => ({
        data: addSecurityColumn(values),
        columnsDefinitions
    })),
    compareShareholders
)(CompareShareholders1042S);
