// fix title of legend position to the left of the legend
export function loadChartHandler() {
    this.update({
        legend: {
            x: this.legend.title.width
        }
    });
    this.legend.title.translate(this.legend.title.translateX, this.legend.title.height + 3);
    this.legend.contentGroup.translate(this.legend.title.width, this.legend.contentGroup.translateY);
    this.legend.group.translate(0, this.legend.group.translateY);
    this.update({
        chart: {
            marginBottom: this.marginBottom - this.legend.title.height
        }
    });
}
