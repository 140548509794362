import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.SHAREHOLDER_NAME,
        order: 1,
        minWidth: 210,
        displayName: 'registered.shareholder.table.shareholder',
        initialSortDirection: sortDirections.ASC,
        manageable: false
    },
    {
        columnName: columnNames.ISSUER,
        order: 2,
        minWidth: 210,
        displayName: 'registered.shareholder.table.issuer',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SECURITY,
        order: 3,
        minWidth: 210,
        displayName: 'registered.shareholder.table.security',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.SECURITY_NAME
        }
    },
    {
        columnName: columnNames.ACCOUNT_NUMBER,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.accountNumber',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.YEAR,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.year',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            valueLength: 4
        }
    },
    {
        columnName: columnNames.GROSS_AMOUNT,
        order: 6,
        minWidth: 120,
        displayName: 'registered.shareholder.table.grossAmount',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.TAX_WITHHELD,
        order: 7,
        minWidth: 120,
        displayName: 'registered.shareholder.table.taxWithheld',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.TAX_RATE,
        order: 8,
        minWidth: 120,
        displayName: 'registered.shareholder.table.taxRate',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 5
        }
    },
    {
        columnName: columnNames.INCOME_CODE,
        order: 9,
        minWidth: 120,
        displayName: 'registered.shareholder.table.incomeCode',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    }
];

export const secondarySorting = {
    field: columnNames.YEAR,
    direction: sortDirections.DESC
};
