
import React from 'react';
import { compose } from '@shakacode/recompose';
import { Field, reduxForm, formPropTypes } from 'redux-form';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';

import TextInput from 'Components/TextInput';
import Popover from 'Components/Popover';

const formName = 'DF_KING_BENCHMARK';

const popoverProps = {
    id: 'MEETING-PLANER-POPOVER',
    className: 'meeting-planner-popover',
    trigger: (<i className='font-icon font-icon-info-outline'/>),
    triggerType: ['hover', 'click'],
    placement: 'bottom',
    containerPadding: 30
};


const MeetingPlannerTrackerForm = ({ intl, handleSubmit }) => {
    return (
        <form className='meeting-planner-tracker__form' onSubmit={handleSubmit} >
            <h3 className='form-title'>
                {intl.formatMessage({ id: 'meetingPlannerTracker.form.header' })}
                <Popover {...popoverProps}>
                    <FormattedHTMLMessage id='meetingPlannerTracker.form.tooltip' />
                </Popover>
            </h3>
            <div className='flexbox controls flexbox-row'>
                <div className='flexbox align-items-flex-end field-control'>
                    <Field
                        component={TextInput}
                        name='stateOfDirectors'
                        placeholder={intl.formatMessage({ id: 'meetingPlannerTracker.stateOfDirectors.placeholder' })}
                        label={intl.formatMessage({ id: 'meetingPlannerTracker.stateOfDirectors.label' })}
                        labelType='vertical'
                        type='text'
                        props={{ disabled: true }}/>
                </div>
                <div className='flexbox align-items-flex-end field-control'>
                    <Field
                        component={TextInput}
                        name='sayOnPay'
                        placeholder={intl.formatMessage({ id: 'meetingPlannerTracker.sayOnPay.placeholder' })}
                        label={intl.formatMessage({ id: 'meetingPlannerTracker.sayOnPay.label' })}
                        labelType='vertical'
                        type='text'
                        props={{ disabled: true }}/>
                </div>
            </div>
        </form>
    );
};

MeetingPlannerTrackerForm.propTypes = {
    intl: intlShape,
    ...formPropTypes
};

export default compose(
    injectIntl,
    reduxForm({
        form: formName,
        initialValues: {
            stateOfDirectors: '70%',
            sayOnPay: '70%'
        }
    }),
)(MeetingPlannerTrackerForm);

