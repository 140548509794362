import * as api from '../../api';

export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_ERROR = 'DELETE_NOTE_ERROR';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_ERROR = 'CREATE_NOTE_ERROR';

export const deleteNote = noteId => dispatch => {
    return api.deleteNote(noteId).then(
        () => Promise.resolve(dispatch({ type: DELETE_NOTE_SUCCESS })),
        () => Promise.reject(dispatch({ type: DELETE_NOTE_ERROR }))
    );
};

export const createNote = (noteText, entityId, entityType) => dispatch => {
    return api.createNote(noteText, entityId, entityType).then(
        () => Promise.resolve(dispatch({ type: CREATE_NOTE_SUCCESS })),
        () => Promise.reject(dispatch({ type: CREATE_NOTE_ERROR }))
    );
};
