import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import widgetModes from 'Constants/widget-modes';
import PeriodRange from 'Components/PeriodRange';
import PerformanceContentChart from '../PerformanceContentChart';
import PerformanceContentTable from 'Components/widgets/_commonComponents/Performance/components/PerformanceContentTable';

class PerformanceContent extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    handleSelectPeriod = period => {
        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            period
        });
        this.props.onUpdateWidgetData({ period });
    };

    render() {
        const { widgetSettings = {} } = this.props;
        const chart = (
            <PerformanceContentChart {...this.props}/>
        );
        const table = (
            <PerformanceContentTable {...this.props}/>
        );
        const isChartMode = widgetSettings.mode === widgetModes.CHART;
        const period = widgetSettings.period;

        return (
            <div className='performance-content'>
                {isChartMode ? chart : table}
                <div className='range-container'>
                    <PeriodRange
                        selectedPeriod={period}
                        onSelect={this.handleSelectPeriod}/>
                </div>
            </div>
        );
    }
}

export default PerformanceContent;
