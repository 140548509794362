import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';

import * as printActions from 'State/print/actions';
import Icon from 'Components/Icon';

const mapDispatchToProps = {
    printWidget: printActions.printWidget
};

@connect(null, mapDispatchToProps)
class WidgetPrint extends Component {
    static propTypes = {
        widgetType: PropTypes.string.isRequired,
        printWidget: PropTypes.func,
        className: PropTypes.string,
        getWidgetHeight: PropTypes.func
    };

    handlePrintWidget = () => {
        const widgetHeight = this.props.getWidgetHeight && this.props.getWidgetHeight();

        this.props.printWidget(this.props.widgetType, widgetHeight);
    };

    render() {
        const className = cn('font-icon font-icon-local-printshop', this.props.className);

        return (
            <Icon className={className} onClick={this.handlePrintWidget}/>
        );
    }
}

export default WidgetPrint;
