import { useEffect } from 'react';

export const ClickOutside = ({ children, ignoreEl, onClickOutside }) => {
    const clickOutsideHandler = (event) => {
        const path = (event.composedPath && event.composedPath()) || event.path;

        if (ignoreEl.current && !path.includes(ignoreEl.current)) {
            onClickOutside(event);
        }
    };

    useEffect(() => {
        window.addEventListener('pointerdown', clickOutsideHandler);

        return () => {
            window.removeEventListener('pointerdown', clickOutsideHandler);
        };
    }, [clickOutsideHandler]);

    return children;
};
