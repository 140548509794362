import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import TermsText from 'Components/TermsText';

const TermsAndConditionsModalView = ({ termsText, onClose }) => (
    <div className='terms-and-conditions-modal'>
        <div className='terms-and-conditions-modal-content'>
            <TermsText>
                {termsText}
            </TermsText>
        </div>
        <div className='modal-actions'>
            <button
                type='button'
                onClick={onClose}
                className='btn btn-large'>
                <FormattedMessage id='common.close'/>
            </button>
        </div>
    </div>
);

TermsAndConditionsModalView.propTypes = {
    termsText: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

export default TermsAndConditionsModalView;
