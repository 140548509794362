import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getSheetMaxRows } from 'State/user/selectors';
import exportLimits from 'Constants/export-limits';

import './LimitNote.scss';

const mapStateToProps = state => ({
    sheetMaxRows: getSheetMaxRows(state)
});

@connect(mapStateToProps)
class LimitNote extends Component {
    static propTypes = {
        sheetMaxRows: PropTypes.number
    };

    static defaultProps = {
        sheetMaxRows: exportLimits.EXCEL_MAX_ROWS
    };

    render() {
        const { sheetMaxRows } = this.props;

        return (
            <div className='limit-note message-note'>
                <FormattedNumber value={sheetMaxRows}>
                    {excelRowsLimit => (
                        <FormattedMessage
                            id='reporting.excelRowsLimit'
                            values={{ excelRowsLimit }}/>
                    )}
                </FormattedNumber>
            </div>
        );
    }
}

export default LimitNote;
