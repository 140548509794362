import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Spinner from 'Components/Spinner';
import IssuerResourcesPageView from './components/IssuerResourcesPageView';
import {
    loadResources,
    downloadDocument,
    getResourcesSelector,
    isFetchingSelector
} from 'State/resources';

import './IssuerResourcesPage.scss';

const mapStateToProps = state => ({
    resources: getResourcesSelector(state),
    isFetching: isFetchingSelector(state)
});
const mapDispatchToProps = {
    loadResources,
    downloadDocument
};

@connect(mapStateToProps, mapDispatchToProps)
class IssuerResourcesPage extends Component {
    static propTypes = {
        loadResources: PropTypes.func,
        downloadDocument: PropTypes.func,
        resources: PropTypes.array,
        isFetching: PropTypes.bool
    };

    componentDidMount() {
        this.props.loadResources();
    }

    handleDownloadResource = documentId => {
        this.props.downloadDocument(documentId);
    };

    render() {
        const { resources, isFetching } = this.props;

        return (<div className='issuer-resources-page'>
            <h3 className='title'><FormattedMessage id='issuer.resources.title'/></h3>
            {isFetching
                ? <Spinner isFetching={isFetching} isBlocker/>
                : <IssuerResourcesPageView
                    resources={resources}
                    onDownloadResource={this.handleDownloadResource}/>
            }
        </div>);
    }
}

export default IssuerResourcesPage;
