import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

const ShareholderOptionRenderer = (option) => {
    return (
        <div className='shareholder-type-option' data-type={option.value}>
            <span>{option.label}</span>
            {option.iconClass &&
            <i className={cn('font-icon', option.iconClass)}/>
            }
        </div>
    );
};

ShareholderOptionRenderer.propTypes = {
    option: PropTypes.shape({
        label: PropTypes.string.isRequired,
        iconClass: PropTypes.string
    })
};

export default ShareholderOptionRenderer;

