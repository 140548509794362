import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withUserInfo } from 'Routes/hoc';
import { BaseRoute, AvailableRoute, AcceptedUserRoutes, pathRoutesConfig } from 'Routes';
import pageRoutes from 'Constants/page-routes';
import * as pages from 'Pages';

@withUserInfo
class AuthorizedRoutes extends Component {
    static propTypes = {
        location: PropTypes.object,
        userInfo: PropTypes.object
    };

    render() {
        const { userInfo = {} } = this.props;
        const { getAppNav } = pathRoutesConfig;
        const appNav = getAppNav(userInfo.globalSettings);

        return (
            <Switch>
                <BaseRoute
                    exact
                    path={`${pageRoutes.index}`}
                    render={props => (
                        <AvailableRoute
                            pathConfig={appNav.pathConfig}
                            defaultPath={appNav.defaultPath.path}
                            {...props}/>
                    )}/>
                <BaseRoute path={`${pageRoutes.dataVizExport}/:reportTemplateId?`} component={pages.DataVizExportPage}/>
                <BaseRoute path={`${pageRoutes.contract}`} component={pages.ContractPage}/>
                <BaseRoute path={`${pageRoutes.announcement}`} component={pages.AnnouncementPage}/>
                <AcceptedUserRoutes/>
                <Redirect to={`${pageRoutes.error}/404`}/>
            </Switch>
        );
    }
}

export default AuthorizedRoutes;
