import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import { withProps } from '@shakacode/recompose';

import ScrollableReactTable from 'Components/Tables/ScrollableReactTable';
import NullComponent from 'Components/widgets/Widget/components/NullComponent';
import { NoResults, TdComponent, TheadComponent } from 'Components/Tables/components/ReactTableComponents';
import {
    CellCommon,
    CellWithStatus,
    HeadingCellCommonWithIntl,
    HeadingCellFileStatus
} from 'Components/widgets/_commonComponents/TableCellComponents';
import { fileSizeFormatter } from 'Components/formatters/formatters';
import { fileTypesString } from 'Constants/file-formats';
import { compareDatesDescending, sortArray } from 'Utils/utils';

import { DeleteUploadedFileCell } from './DeleteUploadedFileCell';
import { DocumentNameCell } from './DocumentNameCell';

const columnNames = {
    NAME: 'name',
    UPLOAD_DATE: 'uploadDate',
    SIZE: 'size',
    FORMAT: 'format',
    STATUS: 'status',
    DELETE: 'delete'
};

const getColumnDefinitions = (onDelete, alreadySent, uploaded) => [
    {
        accessor: columnNames.NAME,
        Header: <HeadingCellCommonWithIntl title='userSecureUpload.uploadDocuments.table.description' />,
        Cell: DocumentNameCell
    },
    {
        accessor: columnNames.FORMAT,
        Header: <HeadingCellCommonWithIntl title='userSecureUpload.uploadDocuments.table.format' />,
        Cell: CellCommon,
        maxWidth: 200
    },
    {
        accessor: columnNames.STATUS,
        Header: <HeadingCellFileStatus title='userSecureUpload.uploadDocuments.table.status' />,
        Cell: withProps(props => ({
            ...props,
            additionalData: { ...props.additionalData, withTooltip: true },
            alreadySent,
            uploaded
        }))(CellWithStatus),
        maxWidth: 200
    },
    onDelete ? {
        id: columnNames.DELETE,
        width: 60,
        Cell: DeleteUploadedFileCell,
        getProps: (__, rowInfo) => {
            const { row } = rowInfo;

            return {
                onClick: () => onDelete(row)
            };
        },
        className: 'cell-with-align-center'
    } : undefined
].filter(Boolean);

const initialSort = { sortBy: 'uploadDate', sortDesc: false };

const applyDateComparator = (condition = false) => condition ? compareDatesDescending : undefined;

export const UploadedFilesTable = ({ onDrop, files, onDelete, readonly = false, maxUploadFileSize = 0, alreadySent = false, uploaded }) => {
    const [sort, setSort] = useState(initialSort);

    const columns = useMemo(() => getColumnDefinitions(!readonly ? onDelete : undefined, alreadySent, uploaded), [onDelete, readonly, alreadySent, uploaded]);

    const handleSort = (params) => {
        const sortBy = _.get(params, '[0].id');
        const sortDesc = _.get(params, '[0].desc');

        setSort({ sortBy, sortDesc });
    };

    const getTheadThProps = useCallback((__, ___, column) => {
        return {
            sortProperty: column.id === sort.sortBy ? { sortAscending: !sort.sortDesc } : null
        };
    }, [sort]);

    const sortedValues = useMemo(() => sortArray({
        array: files,
        primaryField: sort.sortBy,
        descending: sort.sortDesc,
        primaryComparator: applyDateComparator(sort.sortBy === columnNames.UPLOAD_DATE)
    }), [sort, files]);

    return (
        <Dropzone
            onDrop={onDrop}
            readOnly={readonly}
            multiple
            noClick>
            {({ getRootProps, getInputProps, isDragActive, open }) => {
                return (
                    <>
                        <div className='files-field' {...getRootProps()}>
                            <input
                                {...getInputProps()}
                                value={''}
                                multiple
                                accept={fileTypesString}/>
                            {!readonly && (
                                <div className='files-field_dnd-description'>
                                    <div>
                                        <div className='form-control__label'>
                                            <FormattedMessage id='userSecureUpload.uploadDocuments.label' />
                                        </div>
                                        <FormattedMessage id='userSecureUpload.uploadDocuments.message' values={{ maxSize: fileSizeFormatter(maxUploadFileSize) }}>
                                            {message => <span className='description message-note'>{message}</span>}
                                        </FormattedMessage>
                                    </div>
                                    <button type='button' className='btn btn-large' onClick={open}>
                                        <FormattedMessage id='userSecureUpload.uploadDocuments.button' />
                                    </button>
                                </div>
                            )}
                            <ScrollableReactTable
                                showPagination={false}
                                resizable={false}
                                columns={columns}
                                className={cn('scroll-handle', { 'drag-active': isDragActive })}
                                LoadingComponent={NullComponent}
                                TdComponent={TdComponent}
                                ThComponent={TheadComponent}
                                getTheadThProps={getTheadThProps}
                                NoDataComponent={NoResults}
                                manual
                                defaultPageSize={0}
                                onSortedChange={handleSort}
                                resolvedData={sortedValues}/>
                        </div>
                        {isDragActive && <div className='drag-and-drop-overlay'/>}
                    </>
                );
            }}
        </Dropzone>
    );
};

UploadedFilesTable.propTypes = {
    onDrop: PropTypes.func,
    files: PropTypes.array,
    onDelete: PropTypes.func,
    readonly: PropTypes.bool,
    maxUploadFileSize: PropTypes.number,
    alreadySent: PropTypes.bool,
    uploaded: PropTypes.bool
};
