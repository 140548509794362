import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export const getAlerts = () => {
    const requestData = utils.buildRequest({}, requestTypes.getAlertsPayload);

    return axios.post(apiRoutes.getAlertsUrl, requestData, {
        isCancelable: true
    });
};

export const dismissAlert = alertId => {
    const requestData = utils.buildRequest({ alertId }, requestTypes.dismissAlertPayload);

    return axios.post(apiRoutes.dismissAlertUrl, requestData, {
        isCancelable: true
    });
};
