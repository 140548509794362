import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { TdComponent } from 'Components/Tables/components/ReactTableComponents';
import { CONTEXTS } from 'Constants/search';
import FieldFormatter from '../formatters/FieldFormatter';
import { resultFields } from '../config';
import { checkBackEndNull } from 'Utils/utils';
import { buildSearchItemLocation } from 'Utils/routing';
import { getSecurityByIdSelector, getSecurityWithFeatureSelector } from 'State/securities/selectors';
import { getIssuerById } from 'State/issuers/selectors';
import columns from '../constants/columnDefinitions';
import { securityFullNameFormatter } from 'Components/formatters/formatters';
import featureTypes from 'Constants/feature-types';
import RegisteredCheckbox from '../components/cellRenderers/RegisteredCheckbox';

import './SearchResultItemTable.scss';

const mapStateToProps = state => ({
    getSecurityById: id => getSecurityByIdSelector(state, id),
    getIssuerById: id => getIssuerById(state, id),
    havePermission: !!getSecurityWithFeatureSelector(state, featureTypes.COMPARE_REGISTERED_SHAREHOLDERS)
});

@connect(mapStateToProps)
class SearchResultItemTable extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        contextType: PropTypes.oneOf(Object.keys(CONTEXTS)).isRequired,
        getSecurityById: PropTypes.func,
        getIssuerById: PropTypes.func,
        issuersCount: PropTypes.number,
        securityCount: PropTypes.number,
        havePermission: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.columns = props.havePermission
            ? [
                {
                    accessor: 'checkbox',
                    width: 36,
                    Cell: args => <RegisteredCheckbox {...args}/>
                },
                ...columns
            ] : columns;
    }

    getColumns = () => {
        const columnToRemove = [];

        if (this.props.issuersCount < 2) {
            columnToRemove.push('issuerName');
        }
        if (this.props.securityCount < 2) {
            columnToRemove.push('securityName');
        }
        return this.columns.filter(c => !columnToRemove.includes(c.accessor));
    };

    render() {
        const { items, contextType, getSecurityById } = this.props;
        const mappedData = items.map(item => {
            const fieldSet = _.get(resultFields, contextType, []);
            const mappedFields = fieldSet.map(field => <FieldFormatter key={field} values={item} field={field}/>);
            const shareholderInfo = _.map(mappedFields, (Field, i) => {
                const { values, field } = Field.props;
                const sourceValue = _.get(values, field);
                const isNull = checkBackEndNull(sourceValue);
                const isLast = i === mappedFields.length - 1;
                const separator = !isLast ? '; ' : '';

                return !isNull &&
                    <span key={_.uniqueId()}>{Field}{separator}</span>;
            });
            const securityId = _.get(item, 'security.securityId');
            const toLocation = buildSearchItemLocation(contextType, item);
            const security = getSecurityById(securityId);
            const issuer = this.props.getIssuerById(_.get(item, 'security.issuerId'));
            const securityName = (<span key={security.name}>{securityFullNameFormatter(security.name, security.ticker, security.cusip, security.companyNumber)}</span>);
            const issuerName = _.get(issuer, 'companyName') ? <span key={issuer.companyName}>{issuer.companyName}</span> : null;

            return {
                shareholderInfo,
                issuerName,
                securityName,
                features: security.features,
                shareholderId: item.id,
                securityId,
                toLocation,
                contextType
            };
        });

        return (<ReactTable
            className='search-result-table'
            showPagination={false}
            resizable={false}
            LoadingComponent={() => null}
            minRows={0}
            TheadComponent={() => null}
            TdComponent={TdComponent}
            manual
            defaultPageSize={0}
            data={mappedData}
            columns={this.getColumns()}/>);
    }
}

export default SearchResultItemTable;
