import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Overlay, Popover } from 'react-bootstrap';

import PerformanceFilterList from '../PerformanceFilterList';

import './PerformanceChartFilter.scss';

class PerformanceChartFilter extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        values: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            color: PropTypes.string,
            isSelected: PropTypes.bool
        })).isRequired
    };

    state = {
        isOpen: false
    };

    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    handleOnOverlayHide = () => {
        this.setState({ isOpen: false });
    };

    handleListSubmit = (values) => {
        this.handleOnOverlayHide();
        this.props.onSubmit(values);
    };

    getOverlay = () => {
        return (
            <Popover
                id='performance-chart-filter-content'
                className='performance-chart-filter-content'>
                <PerformanceFilterList
                    onSubmit={this.handleListSubmit}
                    values={this.props.values}/>
            </Popover>
        );
    };

    render() {
        return (
            <div className='performance-chart-filter'>
                <div
                    onClick={this.handleToggle}
                    className='performance-chart-filter-header'
                    ref={target => this.target = target}>
                    <span className='text'>
                        <FormattedMessage id='performance.performanceChart.showTrends'/>
                    </span>
                    {this.state.isOpen
                        ? <i className='font-icon font-icon-arrow-up'/>
                        : <i className='font-icon font-icon-arrow-drop-down'/>
                    }
                </div>
                <Overlay
                    className='performance-chart-filter-content'
                    show={this.state.isOpen}
                    container={window.document.body}
                    target={() => ReactDOM.findDOMNode(this.target)}
                    placement='bottom'
                    rootClose
                    animation={false}
                    onHide={this.handleOnOverlayHide}>
                    {this.getOverlay()}
                </Overlay>
            </div>
        );
    }
}

export default PerformanceChartFilter;
