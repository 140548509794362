import PropTypes from 'prop-types';
import React from 'react';

import BackLink from 'Components/BackLink';
import WatchlistShareholderButton from 'Components/WatchlistShareholderButton';

const InsiderOverviewPageHeaderView = ({ pageInfo = {}, isFetching = false }) => {
    const { shareholderTitle, shareholderId, shareholderType } = pageInfo;

    return (
        <div className='insider-overview-page-header'>
            <BackLink />
            <div className='title-container'>
                <h2 className='title'>{shareholderTitle}</h2>
                {!isFetching && (
                    <WatchlistShareholderButton
                        shareholderId={shareholderId}
                        shareholderTitle={shareholderTitle}
                        shareholderType={shareholderType} />
                )}
            </div>
        </div>
    );
};

InsiderOverviewPageHeaderView.propTypes = {
    pageInfo: PropTypes.object,
    isFetching: PropTypes.bool
};

export default InsiderOverviewPageHeaderView;
