import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, change, initialize, reset, getFormValues } from 'redux-form';
import { withState } from '@shakacode/recompose';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import {
    hideModal,
    showSaveCustomViewModal,
    showColumnManagerModal,
    showConfirmModal,
    changeModalTitle
} from 'State/modal';
import * as widgetsSelectors from 'State/widgets/selectors';
import { widgetsUpdateSettings } from 'State/widgets/actions';
import * as featuresSelectors from 'State/features/selectors';
import holderTypes from 'Constants/shareholder-types';
import featureTypes from 'Constants/feature-types';
import shareholderDefaultViews from '../../../constants/defaultViews';
import columnsDefinitions from '../../../constants/columnsDefinitions';
import columnManagerMode from '../../../constants/shareholder-column-manager-mode';
import { filterByAvailableFor, getCurrentSortCriteria, getCurrentViewId } from '../../../utils';
import ColumnsManagerView from '../components/ColumnsManagerView';

const formName = 'form-columns-manager';

const mapStateToProps = (state, props) => {
    const settings = widgetsSelectors.getWidgetSettingsSelector(state, props.widgetName);

    return {
        ...state.modal,
        formValues: getFormValues(formName)(state),
        settings,
        userProductSources: featuresSelectors.getCurrentProductSourcesSelector(state),
        userFeatures: featuresSelectors.getCurrentFeaturesSelector(state)
    };
};
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            hideModal,
            widgetsUpdateSettings,
            showSaveCustomViewModal,
            showColumnManagerModal,
            showConfirmModal,
            changeModalTitle,
            initialize: (initialValues) => {
                dispatch(initialize(formName, initialValues));
            },
            change: (fieldName, value) => {
                dispatch(change(formName, fieldName, value));
            },
            reset: () => {
                dispatch(reset(formName));
            }
        },
        dispatch);
};

@injectIntl
@withState(
    'mode',
    'setMode',
    ({ mode }) => mode || columnManagerMode.CREATE
)
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: formName
})
class ColumnsManager extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        widgetName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        settings: PropTypes.object.isRequired,
        hideModal: PropTypes.func.isRequired,
        showColumnManagerModal: PropTypes.func.isRequired,
        showSaveCustomViewModal: PropTypes.func.isRequired,
        showConfirmModal: PropTypes.func.isRequired,
        changeModalTitle: PropTypes.func.isRequired,
        widgetsUpdateSettings: PropTypes.func.isRequired,
        setMode: PropTypes.func.isRequired,
        change: PropTypes.func.isRequired,
        initialize: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        formValues: PropTypes.object,
        mode: PropTypes.string,
        isParentModal: PropTypes.bool,
        userProductSources: PropTypes.array,
        userFeatures: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.columns = filterByAvailableFor(columnsDefinitions, props.userProductSources, props.userFeatures);
        this.currentViewIsDefault = _.valuesIn(shareholderDefaultViews).includes(getCurrentViewId(props.settings));
    }

    componentDidMount() {
        this.props.initialize(this.getInitialValues());
    }

    handleSuccess = () => {
        const newSettings = this.updateSettings(this.getFormValues());

        this.props.widgetsUpdateSettings(this.props.widgetName, newSettings);
        this.props.hideModal();
    };

    handleClickSaveView = () => {
        const { props, showColumnManager } = this;
        const { mode } = props;

        const payload = mode === columnManagerMode.EDIT
            ? { name: this.getNameEditingView() }
            : undefined;

        this.props.showSaveCustomViewModal({
            validators: { isUniqueName: this.validateCustomViewNameIsUnique },
            titleKey: 'modals.saveCustomView.title',
            onClose: props.isParentModal ? props.hideModal : showColumnManager,
            onCancel: props.isParentModal ? props.hideModal : showColumnManager,
            onSuccess: this.handleCreateCustomView,
            payload
        });
    };

    handleCreateCustomView = (nameView, isEdit = false) => {
        const { props, editingView, showColumnManager } = this;
        const { widgetName, settings } = props;
        const viewsColumns = this.getFormValues();

        const newSettings = _.cloneDeep(settings);
        const idCustomView = isEdit ? editingView : `CUSTOM_VIEW_${Date.now()}`;

        newSettings.views.customViews = {
            ...newSettings.views.customViews,
            [idCustomView]: {
                name: nameView.trim(),
                values: viewsColumns
            }
        };
        newSettings.viewsSortCriteria[idCustomView] = { ...getCurrentSortCriteria(this.props.settings) };

        this.props.widgetsUpdateSettings(widgetName, newSettings);
        if (this.props.isParentModal) {
            this.props.hideModal();
        } else {
            showColumnManager();
        }
        this.editingView = undefined;
    };

    handleClickDeleteCustomView = (idCustomView, nameCustomView) => {
        const { props, showColumnManager } = this;
        const { settings, widgetName } = props;
        const currentViewId = getCurrentViewId(this.props.settings);

        const handleSuccess = () => {
            const newSettings = _.cloneDeep(settings);

            delete newSettings.views.customViews[idCustomView];
            delete newSettings.viewsSortCriteria[idCustomView];
            if (idCustomView === currentViewId) {
                newSettings.views.currentViewId = this.getAvailableDefaultView();
            }
            this.props.widgetsUpdateSettings(widgetName, newSettings);
            showColumnManager();
        };

        this.props.showConfirmModal({
            titleKey: 'common.confirmDelete',
            messageKey: 'modals.confirmModal.message.delete',
            payload: nameCustomView,
            okTextKey: 'common.delete',
            cancelTextKey: 'common.cancel',
            onSuccess: handleSuccess,
            onClose: showColumnManager,
            onCancel: showColumnManager,
            preventCloseOnSuccess: true
        });
    };

    handleClickEditCustomView = (idCustomView) => {
        const { columns } = this;
        const { setMode, intl: { formatMessage } } = this.props;
        const customViews = this.getCustomViews();
        const modalTitle = `"${customViews[idCustomView].name}" ${formatMessage({ id: 'modals.columnsManagerModal.title' })}`;

        setMode(columnManagerMode.EDIT);
        this.props.changeModalTitle(modalTitle);
        const columnsOfCustomView = customViews[idCustomView].values;

        _.forEach(columns, (column) => {
            const name = column.columnName;

            this.props.change(name, _.includes(columnsOfCustomView, name));
        });
        // TODO: refactor
        this.editingView = idCustomView;
    };

    handleEditCancel = () => {
        const { isParentModal, setMode, intl: { formatMessage } } = this.props;
        const handleHideModal = this.props.hideModal;
        const handleReset = this.props.reset;
        const modalTitle = formatMessage({ id: 'modals.columnsManagerModal.title' });

        if (isParentModal) {
            handleHideModal();
        } else {
            setMode(columnManagerMode.CREATE);
            this.props.changeModalTitle(modalTitle);
        }
        handleReset();
        this.editingView = undefined;
    };

    getAvailableDefaultView = () => {
        const { userFeatures } = this.props;

        if (userFeatures[featureTypes.SHAREHOLDERS_TABLE_NUMBERS]) {
            return shareholderDefaultViews.NUMBERS;
        }
        if (userFeatures[featureTypes.SHAREHOLDERS_TABLE_DETAILS]) {
            return shareholderDefaultViews.DETAILS;
        }
        if (userFeatures[featureTypes.SHAREHOLDERS_TABLE_REGISTERED]) {
            return shareholderDefaultViews.REGISTERED;
        }

        return null;
    };

    getInitialValues = () => {
        const view = {};
        const { columns } = this;
        const customViews = this.getCustomViews();
        const defaultViews = this.getDefaultViews();
        const currentViewId = getCurrentViewId(this.props.settings);
        const currentView =
            defaultViews[currentViewId] ||
            customViews[currentViewId];
        const selectedColumns = currentView.values;

        _.forEach(columns, (column) => {
            const name = column.columnName;

            view[name] = _.includes(selectedColumns, name);
        });
        return view;
    };

    getFormValues = () => {
        const values = [];

        _.forIn(this.props.formValues, (value, key) => {
            if (value) {
                values.push(key);
            }
        });
        return values;
    };

    getFilteredColumns = (filter) => {
        const { intl: { formatMessage } } = this.props;

        return this.columns
            .filter((column) => {
                return column.manageable && column.holderType === filter;
            })
            .map((column) => {
                const displayNameKey = column.displayNameColumnManager || column.displayName;

                return {
                    name: column.columnName,
                    label: formatMessage({ id: displayNameKey })
                };
            });
    };

    getCustomViews = () => {
        return _.get(this.props, 'settings.views.customViews');
    };

    getNameEditingView = () => {
        const customViews = this.getCustomViews();

        return _.get(customViews, `${this.editingView}.name`);
    };

    getDefaultViews = () => {
        return _.get(this.props, 'settings.views.defaultViews');
    };

    getFormattedDefaultViewName = (intl, pathToName) => {
        const defaultViews = this.getDefaultViews();
        const viewNameKey = _.get(defaultViews, `${pathToName}.name`);

        if (viewNameKey) {
            return intl.formatMessage({ id: viewNameKey }).toLowerCase();
        }

        return undefined;
    };

    showColumnManager = () => {
        this.props.showColumnManagerModal({
            titleKey: 'modals.columnsManagerModal.title',
            widgetName: this.props.widgetName
        });
    };

    splitColumnsByHolderTypes = () => {
        const columns = {};

        _.forIn(holderTypes, (type) => {
            columns[type] = this.getFilteredColumns(type);
        });
        return columns;
    };

    updateSettings = (newViewValues) => {
        const settings = _.cloneDeep(this.props.settings);
        const defaultViews = this.getDefaultViews();
        const currentViewId = getCurrentViewId(settings);

        if (defaultViews[currentViewId]) {
            _.set(settings, `views.defaultViews.${currentViewId}.values`, newViewValues);
        } else {
            _.set(settings, `views.customViews.${currentViewId}.values`, newViewValues);
        }
        return settings;
    };

    validateCustomViewNameIsUnique = (value) => {
        const { intl } = this.props;
        const viewNameIsNotUniq = this.nameIsNotUnique(value);

        if (viewNameIsNotUniq) {
            return intl.formatMessage({
                id: 'saveCustomView.notUniqueName'
            });
        }
        return undefined;
    };

    nameIsNotUnique = (value) => {
        const customViewName = value.trim();
        const hasInCustomView = _.find(this.getCustomViews(), { name: customViewName });
        const nameEditingView = this.getNameEditingView();
        const { intl } = this.props;

        return hasInCustomView && nameEditingView !== customViewName ||
            customViewName.toLowerCase() === this.getFormattedDefaultViewName(intl, 'numbersView') ||
            customViewName.toLowerCase() === this.getFormattedDefaultViewName(intl, 'registeredView') ||
            customViewName.toLowerCase() === this.getFormattedDefaultViewName(intl, 'detailsView');
    };

    render() {
        const props = this.props;
        const { intl: { formatMessage } } = props;
        const columns = this.splitColumnsByHolderTypes();
        const data = {
            columns,
            customViews: this.getCustomViews()
        };
        const modalProps = {
            viewText: formatMessage({ id: 'modals.columnsManagerModal.viewText', defaultMessage: 'View' }),
            saveViewText: formatMessage({ id: 'modals.columnsManagerModal.saveViewText', defaultMessage: 'Save View' }),
            saveChangeText: formatMessage({
                id: 'modals.columnsManagerModal.saveChangesText',
                defaultMessage: 'Save Changes'
            }),
            cancelText: formatMessage({ id: 'common.cancel', defaultMessage: 'Cancel' }),
            onCancel: props.hideModal,
            onEditCancel: this.handleEditCancel,
            dummyCustomViewsText: formatMessage({
                id: 'modals.columnsManagerModal.dummyCustomViewsText',
                defaultMessage: 'You have no custom views'
            }),
            onSuccess: this.handleSuccess,
            onClickSaveView: this.handleClickSaveView,
            onDeleteCustomView: this.handleClickDeleteCustomView,
            onEditCustomView: this.handleClickEditCustomView,
            mode: props.mode,
            isShowViewButton: !this.currentViewIsDefault
        };

        return (
            <ColumnsManagerView {...data} {...modalProps}/>
        );
    }
}

export default ColumnsManager;
