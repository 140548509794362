import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import DateFormatter from 'Components/formatters/DateFormatter';

import './ReportingGeographicAnalysisPageHeader.scss';

const mapStateToProps = state => ({
    pageInfo: dynamicPageSelectors.getDynamicPageInfoSelector(state)
});

@connect(mapStateToProps)
class ReportingHolderTypeAnalysisPageHeader extends Component {
    static propTypes = {
        pageInfo: PropTypes.shape({
            closeOfBusinessDate: PropTypes.string
        })
    };

    render() {
        const closeOfBusinessDate = _.get(this.props, 'pageInfo.closeOfBusinessDate');

        return (
            <div className='reporting-geographic-analysis-page-header'>
                <h2 className='title'>
                    <FormattedMessage id='reporting.geographic.analysis.title'/>
                </h2>
                <span className='message-note'>
                    <FormattedMessage id='reporting.geographic.analysis.date'/> <DateFormatter value={closeOfBusinessDate}/>
                </span>
            </div>
        );
    }
}

export default ReportingHolderTypeAnalysisPageHeader;
