import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import _ from 'lodash';

import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import { stickyFooter } from 'Components/Tables/TableEasy';
import { NullableFormatter } from 'Components/formatters';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';

const OverallOwnershipStatisticsChartView = (props) => {
    const { pieData, legendData, intl: { formatMessage }, isDataViz, widgetFooterProps } = props;
    const outstandingSharesTotal = _.get(legendData, ['aggregate', 'outstandingSharesTotal']);

    return (
        <div>
            <div className='pie-content-container'>
                <PieChart
                    data={pieData}
                    customConfig={getPieTooltipConfig(props.intl)}
                    total={legendData.aggregate.osTotal}/>
            </div>
            <div className='total-value-container'>
                <span className='total-value-message'>{ formatMessage({ id: 'overall.ownership.statistics.totalValue' }) }:</span>
                <NullableFormatter value={outstandingSharesTotal}>
                    <FormattedNumber value={outstandingSharesTotal}/>
                </NullableFormatter>
            </div>
            <div className='legend-container'>
                <LegendGrid
                    widgetFooterProps={widgetFooterProps}
                    renderAllRows={isDataViz}
                    totalMessageId = 'tableEasy.priorCOBTotal'
                    footerComponent={stickyFooter}
                    {...legendData}/>
            </div>
        </div>
    );
};

OverallOwnershipStatisticsChartView.propTypes = {
    pieData: PropTypes.array,
    legendData: PropTypes.object,
    totalOutstandingShares: PropTypes.number,
    intl: PropTypes.object,
    isDataViz: PropTypes.bool,
    widgetFooterProps: PropTypes.object
};

export default OverallOwnershipStatisticsChartView;
