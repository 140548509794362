import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'Components/Icon';

const GroupHeaderCell = ({ groupLabel, canDeleteGroup, groupId, onDeleteGroup }) => {
    return (
        <div className='wrapper'>
            <span className='label'>{groupLabel}</span>
            { canDeleteGroup &&
                <Icon
                    className='font-icon font-icon-delete delete-icon'
                    onClick={() => onDeleteGroup && onDeleteGroup(groupId)} /> }
        </div>
    );
};

GroupHeaderCell.propTypes = {
    groupLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    canDeleteGroup: PropTypes.bool,
    groupId: PropTypes.string,
    onDeleteGroup: PropTypes.func,
    className: PropTypes.string
};

export default GroupHeaderCell;
