import _ from 'lodash';
import { push } from 'connected-react-router';

import * as api from '../../api';
import { hideLoadingBlock, showLoadingBlock } from 'State/loadingBlock/actions';
import { setFeatures, setProductSources } from 'State/features/actions';
import { setAppVersion } from 'State/appVersion/actions';
import { forceLogout, getFullName, mapFeatureList, sortArray } from 'Utils/utils';
import * as userSelectors from './selectors';
import logsLocalStorage from '../../context/logsLocalStorage';
import { setIssuers } from 'State/issuers';
import { clearPeers } from 'State/peers/actions';
import { resetSubscriptions } from 'State/subscriptions/actions';
import { resetShareholderTable } from 'State/shareholderTable';
import { buildLocationWithSecurity } from 'Utils/routing';
import featuresOfInterest from 'Constants/feature-of-interest';
import addGAScript from '../../__ga__';

export const USER_INFO_REQUEST_FAILURE = 'USER_INFO_REQUEST_FAILURE';
export const USER_INFO_REQUEST_SUCCESS = 'USER_INFO_REQUEST_SUCCESS';
export const SET_ACCESS_TO_PA = 'SET_ACCESS_TO_PA';
export const SET_ACCEPT_TERMS = 'SET_ACCEPT_TERMS';
export const CLEAR_ACCEPT_TERMS = 'CLEAR_ACCEPT_TERMS';
export const SET_DEFAULT_SECURITY_ID = 'SET_DEFAULT_SECURITY_ID';
export const SET_CUSTOM_SECURITY_ID = 'SET_CUSTOM_SECURITY_ID';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';
export const SET_SECURITY_CHANGING = 'SET_SECURITY_CHANGING';
export const SET_SECURITIES = 'SET_SECURITIES';
export const GET_SECURITIES_FAILURE = 'GET_SECURITIES_FAILURE';
export const ACCEPT_USER_SUCCESS = 'ACCEPT_USER_SUCCESS';
export const ACCEPT_USER_FAILURE = 'ACCEPT_USER_FAILURE';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';
export const CHANGE_USER_NOTIFICATIONS_SUCCESS = 'CHANGE_USER_NOTIFICATIONS_SUCCESS';
export const CHANGE_USER_NOTIFICATIONS_FAILURE = 'CHANGE_USER_NOTIFICATIONS_FAILURE';
export const GET_USER_TERMS_AND_CONDITIONS_SUCCESS = 'GET_USER_TERMS_AND_CONDITIONS_SUCCESS';
export const GET_USER_TERMS_AND_CONDITIONS_FAILURE = 'GET_USER_TERMS_AND_CONDITIONS_FAILURE';
export const SET_ANNOUNCEMENT_MESSAGE = 'SET_ANNOUNCEMENT_MESSAGE';
export const SET_HAS_NEW_ANNOUNCEMENT = 'SET_HAS_NEW_ANNOUNCEMENT';
export const GET_USER_TREASURY_ACCESS = 'GET_USER_TREASURY_ACCESS';

const setAnnouncementMessage = message => ({
    type: SET_ANNOUNCEMENT_MESSAGE,
    payload: message
});

const setHasNewAnnouncement = hasNewAnnouncement => ({
    type: SET_HAS_NEW_ANNOUNCEMENT,
    payload: hasNewAnnouncement
});

export const acceptAnnouncement = () => (dispatch, getState) => {
    dispatch(showLoadingBlock());

    return api.acceptAnnouncement().then(() => {
        const customSecurityId = userSelectors.getCustomSecurityIdSelector(getState());

        dispatch(setHasNewAnnouncement(false));
        dispatch(hideLoadingBlock());
        dispatch(push(buildLocationWithSecurity('/', customSecurityId)));

        return Promise.resolve();
    }, error => {
        dispatch(hideLoadingBlock());

        return Promise.reject(error);
    });
};

export const postponeAnnouncement = () => (dispatch, getState) => {
    const customSecurityId = userSelectors.getCustomSecurityIdSelector(getState());

    dispatch(setHasNewAnnouncement(false));
    dispatch(push(buildLocationWithSecurity('/', customSecurityId)));
};

export const setUserInfo = (userInfo) => ({
    type: USER_INFO_REQUEST_SUCCESS,
    payload: userInfo
});

export const setDefaultSecurity = (id) => ({
    type: SET_DEFAULT_SECURITY_ID,
    payload: id
});

export const setCustomSecurity = (id) => (dispatch) => {
    dispatch({
        type: SET_CUSTOM_SECURITY_ID,
        payload: id
    });
    return Promise.resolve(id);
};

export const setSecurityChanging = (flag) => (dispatch) => {
    dispatch({
        type: SET_SECURITY_CHANGING,
        payload: flag
    });
    return Promise.resolve(flag);
};

export const setAccessToPa = (accessToPa) => ({
    type: SET_ACCESS_TO_PA,
    payload: accessToPa
});

export const setAcceptTerms = (acceptTerms) => ({
    type: SET_ACCEPT_TERMS,
    payload: acceptTerms
});

export const setSecurities = (securities) => ({
    type: SET_SECURITIES,
    payload: securities
});

export const getSecurities = () => (dispatch) => {
    return api.getSecurities([
        featuresOfInterest.SEARCH_FEATURES,
        featuresOfInterest.PROFILE_FEATURES,
        featuresOfInterest.REPORTING_FEATURES
    ]).then(res => {
        const securitiesInfo = res.data.payload || {};
        const { securities, issuers } = securitiesInfo;
        const sortedSecurities = sortArray(
            {
                array: securities,
                primaryField: 'name',
                descending: false
            })
            .map(s => ({
                ...s,
                features: mapFeatureList(s.features)
            }));

        dispatch(setSecurities(sortedSecurities));
        dispatch(setIssuers(
            sortArray({
                array: issuers,
                primaryField: 'companyName',
                descending: false
            })
        ));
    },
    error => {
        dispatch({
            type: GET_SECURITIES_FAILURE,
            payload: { error }
        });

        return Promise.reject(error);
    });
};

export const getUserInfo = (securityId = null) => (dispatch, getState) => {
    const customSecurityId = userSelectors.getCustomSecurityIdSelector(getState());
    const announcementMessage = userSelectors.getAnnouncementMessageSelector(getState());

    return api.getUserInfo(securityId)
        .then(res => {
            const userInfo = res.data.payload || {};
            const {
                userId, firstName, lastName, title, email, systemRole,
                globalSettings, acceptTerms, accessToPa,
                defaultSecurityId, uploadSettings, isAccepted,
                hasNewMessage, newMessage
            } = userInfo;
            const beVersion = _.get(res.data, 'status.versions.beVersion');
            const jsonSchemaVersion = _.get(res.data, 'status.versions.jsonSchemaVersion');
            const feVersion = FE_VERSION;

            if (!isAccepted) {
                dispatch(setAcceptTerms(acceptTerms));
            }

            if (hasNewMessage && !announcementMessage) {
                dispatch(setHasNewAnnouncement(hasNewMessage));
                dispatch(setAnnouncementMessage(newMessage));
            }

            dispatch(setAccessToPa(accessToPa));
            if (userInfo && userInfo.defaultSecurityId || !_.isNil(customSecurityId)) {
                const features = mapFeatureList(userInfo.features);

                dispatch(setAppVersion({ feVersion, beVersion, jsonSchemaVersion }));
                dispatch(setFeatures(features));
                dispatch(setProductSources(_.get(userInfo, 'sourceTypes', [])));
            }

            dispatch(setUserInfo({
                userId,
                firstName,
                lastName,
                title,
                email,
                systemRole,
                defaultSecurityId,
                isAccepted,
                globalSettings,
                uploadSettings
            }));
            logsLocalStorage.initPollingLogs();

            addGAScript(globalSettings.googleAnalyticsId, getFullName(firstName, lastName));

            return res;
        }, error => {
            dispatch({
                type: USER_INFO_REQUEST_FAILURE,
                payload: error
            });

            return Promise.reject(error);
        });
};

export const clearSecurityRelatedData = () => (dispatch) => {
    dispatch(setUserInfo(null));
    dispatch(setFeatures({}));
    dispatch(setProductSources([]));
    dispatch(setSecurities([]));
    dispatch(resetShareholderTable());
    dispatch(resetSubscriptions());
    dispatch(clearPeers());
};

export const changeCurrentSecurity = (securityId) => dispatch => {
    dispatch(showLoadingBlock());

    return api.changeCurrentSecurity(securityId)
        .then(() => {
            dispatch(setDefaultSecurity(securityId));
            dispatch(hideLoadingBlock());
        }, error => {
            dispatch({
                type: USER_INFO_REQUEST_FAILURE,
                payload: error
            });
            dispatch(hideLoadingBlock());

            return Promise.reject(error);
        });
};

export const changeCustomSecurity = securityId => (dispatch) => {
    dispatch(showLoadingBlock());
    dispatch(setSecurityChanging(true));
    dispatch(clearSecurityRelatedData());

    return dispatch(setCustomSecurity(securityId)).then(() => {
        dispatch(setSecurityChanging(false));
        dispatch(hideLoadingBlock());
    });
};

export const refreshCurrentPage = () => (dispatch) => {
    dispatch(showLoadingBlock());
    dispatch(setSecurityChanging(true));
    dispatch(clearSecurityRelatedData());

    return Promise.resolve().then(() => {
        dispatch(setSecurityChanging(false));
        dispatch(hideLoadingBlock());
    });
};

export const acceptUser = () => (dispatch, getState) => {
    dispatch(showLoadingBlock());

    return api.acceptUser()
        .then(() => {
            const customSecurityId = userSelectors.getCustomSecurityIdSelector(getState());

            dispatch({
                type: ACCEPT_USER_SUCCESS
            });
            dispatch(hideLoadingBlock());

            dispatch({
                type: CLEAR_ACCEPT_TERMS
            });
            dispatch(push(buildLocationWithSecurity('/', customSecurityId)));
        }, error => {
            dispatch({
                type: ACCEPT_USER_FAILURE,
                payload: error
            });
            dispatch(hideLoadingBlock());

            return Promise.reject(error);
        });
};

export const getUserNotifications = () => (dispatch, getState) => {
    const isSessionTimeout = userSelectors.isSessionTimeoutSelector(getState());

    if (isSessionTimeout) {
        return;
    }

    return api.getUserNotifications()
        .then(res => {
            const state = getState();
            const notifications = _.get(res.data, 'payload.notifications');

            if (notifications && !_.isEqual(state.user.notifications, notifications)) {
                dispatch({
                    type: GET_USER_NOTIFICATIONS_SUCCESS,
                    payload: notifications
                });
            }
        }, error => {
            dispatch({
                type: GET_USER_NOTIFICATIONS_FAILURE,
                payload: error
            });

            return Promise.reject(error);
        });
};

export const changeUserNotifications = newNotifications => dispatch => {
    if (_.has(newNotifications, 'newDownloads')) {
        return api.changeUserNotifications(newNotifications)
            .then(() => {
                dispatch({
                    type: CHANGE_USER_NOTIFICATIONS_SUCCESS,
                    payload: newNotifications
                });
            }, error => {
                dispatch({
                    type: CHANGE_USER_NOTIFICATIONS_FAILURE,
                    payload: error
                });

                return Promise.reject(error);
            });
    }

    dispatch({
        type: CHANGE_USER_NOTIFICATIONS_SUCCESS,
        payload: newNotifications
    });

    return Promise.resolve();
};

export const logoutUser = () => dispatch => {
    dispatch({
        type: LOGOUT_USER
    });

    localStorage.removeItem('ISP_USER_ID');
    localStorage.removeItem('ISP_USER_ROLE');

    forceLogout();
};

export const setIsSessionTimeout = () => ({
    type: SESSION_TIMEOUT
});

export const getTermsAndConditions = typeOfTerms => dispatch => {
    dispatch(showLoadingBlock());

    return api.getTermsAndConditions(typeOfTerms)
        .then(
            res => {
                const termsAndConditions = _.get(res.data, 'payload.termsAndConditions');

                dispatch({
                    type: GET_USER_TERMS_AND_CONDITIONS_SUCCESS,
                    payload: {
                        termsAndConditions,
                        typeOfTerms
                    }
                });
                dispatch(hideLoadingBlock());
            },
            error => {
                dispatch({
                    type: GET_USER_TERMS_AND_CONDITIONS_FAILURE,
                    payload: error
                });
                dispatch(hideLoadingBlock());

                return Promise.reject(error);
            }
        );
};

export const evictUserCache = () => (/* dispatch */) => api.evictUserCache();

export const getUserTreasuryAccess = () => dispatch => api.getTreasuryAccess()
    .then(
        res => {
            const hasAccess = _.get(res.data, 'payload.hasAccess');

            dispatch({
                type: GET_USER_TREASURY_ACCESS,
                payload: hasAccess
            });
        }
    );
