const defaultDateFormats = {
    default: 'DD-MMM-YYYY',
    withTime: 'DD-MMM-YYYY HH:mm',
    MONTH_YEAR: 'MMM YYYY',
    SERVER_DATE_FORMAT: 'YYYY-MM-DD',
    SERVER_DATE_WITH_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    LOG_DATE_FORMAT: 'YYYY.MM.DD HH:mm:ss Z',
    LOG_FILE_DATE_FORMAT: 'YYYY-MM-DD_HH-mm_Z',
    CHART_DAY_DATE_FORMAT: 'DD-MMM',
    CHART_MONTH_DATE_FORMAT: 'MMM-YY',
    SELECTOR_DATE_FORMAT: 'MMMM YYYY',
    DATEPICKER_FORMAT: 'MM/DD/YYYY',
    DATEPICKER_FORMAT_PLACEHOLDER: 'mm/dd/yyyy'
};

export default defaultDateFormats;

export const localeDateFormats = {
    en: defaultDateFormats,
    'en-GB': {
        ...defaultDateFormats,
        DATEPICKER_FORMAT: 'DD/MM/YYYY',
        DATEPICKER_FORMAT_PLACEHOLDER: 'dd/mm/yyyy'
    }
};
