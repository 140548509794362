import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';

export const deleteNote = noteId => {
    const payload = { noteId };
    const requestData = utils.buildRequest(payload, requestTypes.deleteNotePayload);

    return axios.post(apiRoutes.deleteNoteUrl, requestData, {
        errorStrategy: errorStrategies.SKIP
    });
};

export const createNote = (noteText, entityId, entityType) => {
    const payload = {
        noteText,
        entityId,
        entityType
    };
    const requestData = utils.buildRequest(payload, requestTypes.createNotePayload);

    return axios.post(apiRoutes.createNoteUrl, requestData, {
        errorStrategy: errorStrategies.SKIP
    });
};
