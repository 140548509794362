import { compose } from '@shakacode/recompose';
import { getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import CommonColumnsManager from './CommonColumnsManager';
import { hideModal } from '../../state/modal';
import validate from './validation';

const formName = 'form-common-columns-manager';
const mapStateToProps = (state) => ({
    ...state.modal,
    formValues: getFormValues(formName)(state)
});
const mapDispatchToProps = {
    hideModal
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: formName,
        validate
    })
)(CommonColumnsManager);
