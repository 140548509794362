import { createReducer } from 'Utils/reducer';
import { RECENT_SEARCH_LOADING, RECENT_SEARCH_ERROR, RECENT_SEARCH_LOADED } from './actions';

const initialState = {
    data: [],
    isLoading: false,
    error: null
};

const actionHandlers = {
    [RECENT_SEARCH_LOADING]: (state) => ({
        ...state,
        isLoading: true,
        error: null
    }),
    [RECENT_SEARCH_ERROR]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: payload
    }),
    [RECENT_SEARCH_LOADED]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        error: null,
        data: payload
    })
};

export default createReducer(initialState, actionHandlers);
