import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'react-virtualized';
import { withPropsOnChange } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import {
    areAllVisibleSecuritiesSelected,
    areSecuritiesIndeterminate,
    getSortedAdvancedSearchIssuers,
    getVisibleSecurities,
    isFetchingAny,
    isSuitSecurityCount,
    toggleAllVisibleSecurities
} from 'State/advancedSearch';
import SelectAllCheckBox from './SelectAllCheckBox';
import SecurityCheckBox from './SecurityCheckBox';
import FormError from 'Components/FormError/FormError';
import { MAX_SECURITY_COUNT } from 'Constants/search';

const mapStateToProps = state => ({
    visibleSecurities: getVisibleSecurities(state),
    selectedIssuers: getSortedAdvancedSearchIssuers(state),
    allSelected: areAllVisibleSecuritiesSelected(state),
    disabled: !getVisibleSecurities(state).length || isFetchingAny(state),
    securitiesOverflow: !isSuitSecurityCount(state),
    indeterminate: areSecuritiesIndeterminate(state)
});
const mapDispatchToProps = { toggleAllVisibleSecurities };


@connect(mapStateToProps, mapDispatchToProps)
@withPropsOnChange(['selectedIssuers', 'visibleSecurities'],
    ({ selectedIssuers, visibleSecurities }) => {
        let rows = [];

        selectedIssuers.forEach(issuer => {
            const issuerSec = _.filter(visibleSecurities, sec => sec.issuerId === issuer.id);

            if (!!issuerSec.length) {
                rows = [
                    ...rows,
                    { isIssuer: true, data: issuer },
                    ...issuerSec.map(security => {
                        return {
                            isIssuer: false,
                            data: security
                        };
                    })
                ];
            }
        });

        return { rows };
    })
class SecuritySection extends Component {
    static propTypes = {
        allSelected: PropTypes.bool,
        disabled: PropTypes.bool,
        securitiesOverflow: PropTypes.bool,
        selectedIssuers: PropTypes.array,
        visibleSecurities: PropTypes.array,
        rows: PropTypes.array,
        rowHeight: PropTypes.number,
        visibleRowCount: PropTypes.number,
        toggleAllVisibleSecurities: PropTypes.func,
        indeterminate: PropTypes.bool
    };

    componentDidUpdate() {
        this.ref && this.ref.forceUpdateGrid();
    }

    handleSelectAllSecurities = (value) => {
        this.props.toggleAllVisibleSecurities(value);
    };

    setRef = (ref) => {
        this.ref = ref;
    };

    securityRowRenderer = ({ key, index, style, security }) => {
        const renderSecurity = security || this.props.visibleSecurities[index - 1];

        if (index === 0) {
            return (<SelectAllCheckBox
                key={key}
                style={style}
                indeterminate={this.props.indeterminate}
                checked={this.props.allSelected}
                disabled={this.props.disabled}
                onClick={this.handleSelectAllSecurities}/>);
        }
        return (<SecurityCheckBox
            style={style}
            security={renderSecurity}
            key={key}/>);
    };

    securityIssuersRowRenderer = ({ key, index, style }) => {
        const row = this.props.rows[index - 1];

        if (row && row.isIssuer) {
            return (<div
                style={style}
                key={key}
                className='check-boxes-list-item title'>
                {row.data.companyName}
            </div>);
        }

        const security = row && row.data;

        return this.securityRowRenderer({ key, index, style, security });
    };

    render() {
        const { visibleSecurities, rows, selectedIssuers } = this.props;
        const { rowHeight, visibleRowCount } = this.props;
        const groupByIssuer = selectedIssuers && selectedIssuers.length > 1;
        const rowCount = groupByIssuer ? rows.length + 1 : visibleSecurities.length + 1;

        return (<div className='securities-list'>
            <List
                className='check-boxes-list'
                ref={this.setRef}
                width={650}
                height={visibleRowCount * rowHeight}
                rowHeight={rowHeight}
                rowCount={rowCount}
                rowRenderer={groupByIssuer ? this.securityIssuersRowRenderer : this.securityRowRenderer}/>
            {this.props.securitiesOverflow &&
            <div className='error-container'><FormError error={
                <FormattedMessage id='search.extraSecurities' values={{ max: MAX_SECURITY_COUNT }}/>
            }/></div>}
        </div>);
    }
}

export default SecuritySection;
