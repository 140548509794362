import PropTypes from 'prop-types';
import React, { memo } from 'react';

import ReportRequestedModalView from './ReportRequestedModalView';
import { buildLocationWithSecurity } from 'Utils/routing';
import pageRoutes from 'Constants/page-routes';

import './ReportRequestedModal.scss';

const ReportRequestedModal = props => {
    const { hideModal: handleHideModal, isSummaryOnly } = props;

    return (
        <ReportRequestedModalView
            onConfirm={handleHideModal}
            downloadUrl={buildLocationWithSecurity(pageRoutes.downloads)}
            isSummaryOnly={isSummaryOnly}/>
    );
};

ReportRequestedModal.propTypes = {
    hideModal: PropTypes.func,
    isSummaryOnly: PropTypes.bool
};

export default memo(ReportRequestedModal);
