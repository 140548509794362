import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';

import Popover from 'Components/Popover';
import SecurityName from '../SecurityName';
import AlertsSection from '../AlertsSection';

const popoverProps = {
    id: 'REGISTERED-NOTE-POPOVER',
    className: 'select-security-popover',
    trigger: (<i className='font-icon font-icon-info-outline'/>),
    triggerType: ['click', 'hover'],
    placement: 'bottom',
    containerPadding: 30
};

class SecuritySection extends Component {
    static propTypes = {
        intl: intlShape,
        currentSecurity: PropTypes.object,
        showSelectSecurityModal: PropTypes.func,
        securityList: PropTypes.array,
        searchArea: PropTypes.object
    };

    handleSecuritySelectorClick = () => {
        this.props.showSelectSecurityModal();
    };

    render() {
        const {
            intl: { formatMessage }, currentSecurity, securityList,
            searchArea
        } = this.props;

        return (
            <div className='security-container'>
                <div className='security-container-content'>
                    <SecurityName
                        currentSecurity={currentSecurity}
                        searchArea={searchArea}/>
                    {securityList.length > 1 &&
                    <div className='security-container-selector'>
                        <Popover {...popoverProps}>
                            <div className='app-header-popover-tip-content'>
                                {formatMessage({ id: 'security.popover.partial.tip' })}
                            </div>
                        </Popover>
                        <div className='security-selector-trigger' onClick={this.handleSecuritySelectorClick}>
                            <FormattedMessage id='security.changeSecurity'/>
                        </div>
                    </div>
                    }
                    <AlertsSection/>
                </div>
            </div>
        );
    }
}

export default SecuritySection;
