import _ from 'lodash';

import { createReducer } from 'Utils/reducer';
import {
    SET_SECURITIES
} from 'State/user/actions';

const initialState = {
    availableSecurities: []
};

const actionHandlers = {
    [SET_SECURITIES]: (state, { payload }) => ({
        ...state,
        availableSecurities: _.sortBy(payload, security => security.name.toLowerCase())
    })
};

export default createReducer(initialState, actionHandlers);
