import React, { Component } from 'react';

import ReportingNav from './ReportingNav';

import './ReportingPage.scss';

const ReportingPageView = ({ children }) => {
    return (
        <div className='reporting-page empty-space-container'>
            <ReportingNav/>
            <div className='reporting-content'>
                {children}
            </div>
        </div>
    );
};

ReportingPageView.propTypes = {
    ...Component.propTypes
};

export default ReportingPageView;
