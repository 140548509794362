import PropTypes from 'prop-types';
import React from 'react';
import { Popover as ReactPopover, OverlayTrigger } from 'react-bootstrap';
import cn from 'classnames';

import './Popover.scss';

const triggerTypeProps = PropTypes.oneOf(['click', 'hover', 'focus']);

class Popover extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        trigger: PropTypes.element,
        className: PropTypes.string,
        triggerType: PropTypes.oneOfType([triggerTypeProps, PropTypes.arrayOf(triggerTypeProps)]),
        rootClose: PropTypes.bool,
        placement: PropTypes.string,
        children: PropTypes.object,
        onEnter: PropTypes.func,
        onHide: PropTypes.func,
        container: PropTypes.object,
        containerPadding: PropTypes.number,
        ellipsis: PropTypes.bool,
        block: PropTypes.bool
    };

    static defaultProps = {
        triggerType: 'click',
        placement: 'bottom',
        rootClose: true,
        containerPadding: 24
    };

    constructor(props) {
        super(props);

        this.defaultContainer = document.querySelector('#app');
    }

    componentDidMount() {
        window.addEventListener('resize', this.hide);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.hide);
    }

    hide = () => {
        this.overlay && this.overlay.hide && this.overlay.hide();
    };

    show = () => {
        this.overlay && this.overlay.show && this.overlay.show();
    };

    render() {
        const {
            triggerType,
            placement,
            trigger,
            id,
            children,
            rootClose,
            onEnter,
            className,
            container,
            containerPadding,
            ellipsis,
            block
        } = this.props;
        const popoverOverlay = (
            <ReactPopover
                className={className}
                id={id}>
                {children}
            </ReactPopover>);

        return (
            <div className={cn('ast-popover', { ellipsis, 'ast-popover--block': block })}>
                <OverlayTrigger
                    delay={0}
                    onEnter={onEnter}
                    trigger={triggerType}
                    rootClose={rootClose}
                    ref={(ref) => this.overlay = ref}
                    overlay={popoverOverlay}
                    containerPadding={containerPadding}
                    container={container || this.defaultContainer}
                    placement={placement}>
                    {trigger}
                </OverlayTrigger>
            </div>
        );
    }
}

export default Popover;
