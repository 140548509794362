import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';

export const saveLog = (payload) => {
    const requestData = utils.buildRequest(payload, requestTypes.saveLogs);

    return axios.post(apiRoutes.saveLogUrl, requestData, {
        noHandleError: true,
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false,
        isRetryable: false,
        withoutRefreshSession: true
    });
};
