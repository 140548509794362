import { compose, withState } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { injectIntl } from 'react-intl';

import SearchSection from './SearchSection';
import * as featuresSelectors from 'State/features/selectors';
import * as advancedSearchSelectors from 'State/advancedSearch/selectors';
import * as advancedSearchActions from 'State/advancedSearch/actions';
import * as securitySelectors from 'State/securities/selectors';
import { getCurrentSecurityIdSelector } from 'State/user';
import { hideModal, showAdvancedSearchModal } from 'State/modal';

const formName = 'global-search';

export default compose(
    injectIntl,
    withState('isFocus', 'setFocus', false),
    reduxForm({
        form: formName
    }),
    connect((state) => ({
        formName,
        isFetching: advancedSearchSelectors.isFetchingGlobal(state),
        availableSecurities: securitySelectors.getAvailableSecurities(state),
        currentSecurityId: getCurrentSecurityIdSelector(state),
        advancedSearchForSingleSecurity: advancedSearchSelectors.isAdvancedSearchAllowedForSingleSecurity(state),
        currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
        isAdvancedSearchAvailable: securitySelectors.isAdvancedSearchAvailable(state)
    }), {
        searchGlobally: advancedSearchActions.searchGlobally,
        cancelSearchRequest: advancedSearchActions.cancelSearchRequest,
        showAdvancedSearchModal,
        hideModal
    })
)(SearchSection);
