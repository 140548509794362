export const MAX_VISIBLE_ROWS = 10;

export const columns = {
    COUNTRY: 'country',
    STATE: 'state',
    NUMBER_OF_SHAREHOLDERS: 'shareholdersNumber'
};

export const contactYears = {
    MORE_1Y: '>1',
    MORE_2Y: '>2',
    MORE_3Y: '>3'
};
