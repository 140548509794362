import React from 'react';
import cn from 'classnames';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { CurrencyFormatter, PercentFormatter } from 'Components/formatters';
import formatterOptions from 'Constants/formatter-options';
import FullQuoteTable from '../FullQuoteTable/FullQuoteTable';

const CURRENCY_DECIMAL_POINTS = 2;

const FullQuoteContent = (props) => {
    const { widgetData = {} } = props;
    const changePriceClass = cn('last-prop', 'change-price', {
        neutral: widgetData.changePrice === 0,
        positive: widgetData.changePrice > 0,
        negative: widgetData.changePrice < 0
    });

    return (
        <div className='full-quote-content'>
            <div className='content-title'>
                <div className='content-title-left'>
                    <span className='title'>
                        ({widgetData.ticker}){' '}
                        {widgetData.companyName}{' '}
                    </span>
                    <span>({widgetData.exch})</span>
                </div>
                <div className='content-title-right'>
                    <span className='first-prop'>
                        <CurrencyFormatter
                            value={widgetData.currentPrice}
                            decimalPoints={CURRENCY_DECIMAL_POINTS}
                            negativeWithBrackets={false}/>
                    </span>
                    <span className={changePriceClass}>
                        <PercentFormatter
                            value={widgetData.changePrice}
                            decimalPoints={formatterOptions.DEFAULT_PERCENT_DECIMAL_POINTS}
                            negativeWithBrackets={false}
                            withPercent
                            withThreshold={false}/>
                    </span>
                </div>
            </div>
            <FullQuoteTable tableData={widgetData}/>
        </div>
    );
};

FullQuoteContent.propTypes = {
    ...commonWidgetPropTypes,
    ...commonWidgetActions
};

export default FullQuoteContent;
