import React, { useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Tooltip from 'Components/Tooltip';
import ControlBooksResultTabsView from './ControlBooksResultTabsView';
import { getDataTypesMap, dataTypes } from '../../constants/dataTypes';

import './ControlBooksResultTabs.scss';

const ControlBooksResultTabs = props => {
    useEffect(() => () => props.dataType !== dataTypes.TRANSACTIONS &&
        props.changeDataType(dataTypes.TRANSACTIONS), []);

    const getTabs = useCallback(() => getDataTypesMap(props).map(type => (
        <Tooltip content={<FormattedMessage id={`reporting.control.books.results.tabs.${type}.tooltip`}/>} key={type}>
            <div
                className={cn('control-books-tab', { active: props.dataType === type })}
                onClick={() => props.dataType !== type && props.changeDataType(type)}>
                <FormattedMessage id={`reporting.control.books.results.tabs.${type}`}/>
            </div>
        </Tooltip>)
    ), [props.changeDataType, props.dataType]);

    return (<ControlBooksResultTabsView getTabs={getTabs}/>);
};

ControlBooksResultTabs.propTypes = {
    dataType: PropTypes.string,
    changeDataType: PropTypes.func,
    includeWeightedData: PropTypes.bool
};

export default memo(ControlBooksResultTabs);
