import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, initialize, getFormValues, propTypes as reduxFormPropTypes } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import TextInput from 'Components/TextInput';

import './SaveCustomView.scss';

const CUSTOM_VIEW_NAME_MAX_LENGTH = 20;
const formName = 'form-add-custom-view';
const mapStateToProps = (state) => {
    return {
        formValues: getFormValues(formName)(state)
    };
};
const mapDispatchToProps = dispatch => {
    return {
        initialize: (initialValues) => {
            dispatch(initialize(formName, initialValues));
        }
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: formName
})
class SaveCustomView extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        intl: intlShape.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        valid: PropTypes.bool,
        dirty: PropTypes.bool,
        validators: PropTypes.object,
        initialize: PropTypes.func,
        payload: PropTypes.object,
        formValues: PropTypes.object
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = props;

        this.required = value => value ? undefined :
            formatMessage({
                id: 'required'
            });
        this.minLength = value => value && value.length < 3 ?
            formatMessage({
                id: 'saveCustomView.shortName'
            }) :
            undefined;
        this.onlyAlphanumericCharacters = value => !/^[\w\s]+$/.test(value) ?
            formatMessage({
                id: 'saveCustomView.onlyAlphanumericCharacters'
            }) :
            undefined;
        this.notOnlySpaces = value => value && _.trim(value) === '' ?
            formatMessage({
                id: 'saveCustomView.notOnlySpaces'
            }) :
            undefined;
        this.notBeginAndEndWithSpaces = value => value && !/^\w[\w\s]+\w$/.test(value) ?
            formatMessage({
                id: 'saveCustomView.notBeginAndEndWithSpaces'
            }) :
            undefined;
    }

    componentDidMount() {
        const { payload } = this.props;

        if (payload) {
            this.props.initialize({ nameCustomView: payload.name });
        }
    }

    normalizeCustomViewName(value, prevValue) {
        let newValue = value;

        if (value === ' ') newValue = '';
        if (value.length > CUSTOM_VIEW_NAME_MAX_LENGTH) return prevValue;

        return newValue;
    }

    onSubmit = ({ nameCustomView }) => {
        const { onSuccess, dirty } = this.props;

        onSuccess(nameCustomView, !dirty);
    };

    render() {
        const { intl, onCancel, handleSubmit, valid, validators: { isUniqueName } } = this.props;

        return (
            <div className='save-custom-view'>
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <Field
                        component={TextInput}
                        name='nameCustomView'
                        placeholder={intl.formatMessage({
                            id: 'modals.saveCustomView.placeholder'
                        })}
                        label={intl.formatMessage({
                            id: 'modals.saveCustomView.label'
                        })}
                        labelType='vertical'
                        type='text'
                        validate={[
                            this.required,
                            this.minLength,
                            this.onlyAlphanumericCharacters,
                            this.notOnlySpaces,
                            this.notBeginAndEndWithSpaces,
                            isUniqueName]}
                        normalize={this.normalizeCustomViewName}/>
                    <div className='btn-panel'>
                        <button
                            className='btn btn-large'
                            data-action='save'
                            type='submit'
                            disabled={!valid}>
                            {intl.formatMessage({ id: 'common.save' })}
                        </button>
                        <button
                            className='btn btn-large'
                            data-action='cancel'
                            onClick={onCancel}
                            type='button'>
                            {intl.formatMessage({ id: 'common.cancel' })}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default SaveCustomView;
