import { connect } from 'react-redux';

import AlertsSection from './AlertsSection';
import * as userSelectors from 'State/user/selectors';
import { hideModal, showAlertsModal } from 'State/modal/actions';

const mapStateToProps = state => ({
    userNewAlerts: userSelectors.getNewAlertNotificationsSelector(state)
});

const mapDispatchToProps = {
    hideModal,
    showAlertsModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsSection);
