import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import LinkEx from 'Components/LinkEx';
import { NullableFormatter } from 'Components/formatters';
import { buildRefToBackQuery } from 'Utils/routing';

const TARGET_BLANK = '_blank';
const TARGET_SELF = '_self';
const TARGET_TOP = '_top';

const CellWithLink = (props) => {
    const { value, to, isExternal, className, state, returnPath, target } = props;
    const title = props.title || value;
    const cellClassName = cn('cell-with-link ellipsis', className);
    const refToBackQuery = buildRefToBackQuery({ pathname: returnPath });

    const rel = target === TARGET_BLANK ? 'noopener noreferrer' : undefined;

    return (
        <div className={cellClassName}>
            <NullableFormatter value={value}>
                {isExternal
                    ? <a
                        href={to} title={title} target={target}
                        rel={rel}>{value}</a>
                    : <LinkEx to={{ pathname: to, state, search: refToBackQuery }} title={title}>{value}</LinkEx>
                }
            </NullableFormatter>
        </div>
    );
};

CellWithLink.propTypes = {
    value: PropTypes.any,
    to: PropTypes.string,
    title: PropTypes.string,
    isExternal: PropTypes.bool,
    className: PropTypes.string,
    state: PropTypes.object,
    returnPath: PropTypes.string,
    target: PropTypes.oneOf([TARGET_BLANK, TARGET_SELF, TARGET_TOP])
};

CellWithLink.defaultProps = {
    isExternal: false
};

export default CellWithLink;
