import React from 'react';
import PropTypes from 'prop-types';

import { FileSizeFormatter, NullableFormatter } from 'Components/formatters';

export const CellFileSize = ({ value }) => {
    return (
        <NullableFormatter value={value}>
            <span className='cell-with-number'>
                <FileSizeFormatter value={value}/>
            </span>
        </NullableFormatter>
    );
};

CellFileSize.propTypes = {
    value: PropTypes.number
};
