import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { AutoSizer } from 'react-virtualized';
import moment from 'moment';

import columnWidth from '../../constants/columnsWidth';
import { getShareholderPositions } from 'State/shareholderTable';
import { showConfirmModal } from 'State/modal';
import PositionsChart from './components/PositionsChart';
import { getPositionsByIdSelector, getPositionsRequestErrorByIdSelector } from 'State/shareholderTable/selectors';
import Icon from 'Components/Icon';
import Spinner from 'Components/Spinner';
import PositionListTable from './PositionListTable';

import './ShareholderPositions.scss';

const mapStateToProps = (state, props) => ({
    positions: getPositionsByIdSelector(state, props.shareholderId),
    error: getPositionsRequestErrorByIdSelector(state, props.shareholderId)
});
const mapDispatchToProps = {
    getShareholderPositions,
    showConfirmModal
};

const FULL_HEIGHT = 320;
const ROW_HEIGHT = 40;

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
class ShareholderPositions extends PureComponent {
    static propTypes = {
        shareholderId: PropTypes.string,
        positions: PropTypes.object,
        getShareholderPositions: PropTypes.func,
        showConfirmModal: PropTypes.func,
        close: PropTypes.func.isRequired,
        error: PropTypes.object,
        intl: intlShape
    };

    componentDidMount() {
        if (!this.props.positions && !this.props.error) {
            this.props.getShareholderPositions(this.props.shareholderId);
        }
    }

    handleClose = () => this.props.close(this.props.shareholderId);

    render() {
        const {
            positions: { list, positions, marketPrice } = {},
            error,
            intl: { formatMessage }
        } = this.props;
        const listWidth = columnWidth.expander + columnWidth.shareholderName + columnWidth.position;
        const maxDate = (_.maxBy(list, 'positionDate') || {}).positionDate;
        // we use moment to parse the date despite time zone and present the date as is.
        const chartPositions = _.sortBy((positions || []), 'quarterLastDay').map((p) => [moment(p.quarterLastDay).valueOf(), p.position]);
        // we don't have decision about showing the market price dates depends on timezone
        const chartMarketPrice = _.sortBy((marketPrice || []), 'priceDate').map((mp) => [new Date(mp.priceDate).getTime(), mp.price]);
        const isNoData = _.isNil(list) && _.isNil(positions) && _.isNil(marketPrice);
        const isLoading = _.isUndefined(this.props.positions) && !error;
        const rowHeight = !isLoading && !isNoData ? FULL_HEIGHT : ROW_HEIGHT;

        return (
            <div className='shareholder-positions-container' style={{ height: rowHeight }}>
                <div className='shareholder-positions' style={{ height: rowHeight }}>
                    <AutoSizer>
                        {({ width }) => (
                            <div>
                                {list && <div className='shareholder-positions-list'>
                                    <div
                                        className='positions-list'
                                        style={{ width: listWidth }}>
                                        <div className='positions-list-controls'>
                                            <div className='position-info'>
                                                <FormattedMessage id='shareholders.table.positions.info.message'/>
                                            </div>
                                        </div>
                                        <PositionListTable
                                            list={list}
                                            maxDate={maxDate}
                                            noDataMessage={formatMessage(
                                                { id: 'shareholders.table.positions.no.data' }
                                            )}/>
                                    </div>
                                    {positions &&
                                    <div
                                        className='positions-chart'
                                        style={{ width: width - listWidth, left: listWidth }}>
                                        <PositionsChart
                                            positions={chartPositions}
                                            marketPrices={chartMarketPrice}
                                            containerWidth={width}/>
                                    </div>
                                    }
                                    <div className='close-icon'>
                                        <Icon
                                            className='font-icon font-icon-clear'
                                            titleKey='shareholders.table.positions.close'
                                            onClick={this.handleClose}/>
                                    </div>
                                </div>}
                            </div>
                        )}
                    </AutoSizer>
                    {error && <span className='error'>
                        <FormattedMessage id={'widgets.errorMessage'}/>
                    </span>}
                    {isLoading && <Spinner isFetching holderTag={'div'} contentTag={'div'}/>}
                    {isNoData && !isLoading && !error && <div className='no-data'>
                        <FormattedMessage id={'widgets.noDataMessage'}/>
                    </div>}
                </div>
            </div>
        );
    }
}

export default ShareholderPositions;
