import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from '@shakacode/recompose';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import pageRoutes from 'Constants/page-routes';
import * as userSelectors from '../../state/user/selectors';
import BaseRoute from 'Routes/BaseRoute';

const mapStateToProps = state => ({
    notAccepted: userSelectors.notAcceptedSelector(state)
});

const withAccept = WrappedComponent => {
    @connect(mapStateToProps)
    class WithAcceptComponent extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withAccept');

        static propTypes = {
            notAccepted: PropTypes.bool
        };

        render() {
            const { notAccepted, ...restProps } = this.props;

            return (
                notAccepted
                    ? <BaseRoute render={props => <Redirect to={`${pageRoutes.contract}${props.location.search}`}/>}/>
                    : <WrappedComponent {...restProps}/>
            );
        }
    }

    return WithAcceptComponent;
};

export default withAccept;
