import PropTypes from 'prop-types';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';

import colorSchemas from 'Constants/color-schemas';
import widgetTypes from 'Constants/widget-types';
import {
    getPerformanceChartsArray,
    getPerformanceChartDataSelector,
    getPerformanceMinValue,
    getPerformanceMaxValue,
    getVolumeChartDataSelector,
    getVolumeMinValue,
    getVolumeMaxValue,
    getDateMinValue,
    getDateMaxValue,
    getChartDateFormatter,
    getDateTickPositions,
    getPerformanceTickPositions
} from '../utils';

const VOLUME_Y_TICK_AMOUNT = 6; // 5 displaying labels and first hidden label

const PERFORMANCE_SMALL_Y_TICK_AMOUNT = 7; // 6 displaying labels and first hidden label
const PERFORMANCE_Y_TICK_AMOUNT = 11; // 10 displaying labels and first hidden label

const PERFORMANCE_SMALL_X_TICK_AMOUNT = 5;
const PERFORMANCE_X_TICK_AMOUNT = 12;

const disabledHighstockControls = {
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    rangeSelector: {
        enabled: false,
        inputEnabled: false
    },
    navigator: {
        enabled: false
    },
    scrollbar: {
        enabled: false
    }
};

export const withCharts = WrappedComponent => withProps(props => {
    const isSmallWidget = props.widgetType === widgetTypes.performanceSmall;
    const widgetSettings = _.get(props, 'widgetSettings', {});
    const visibleCharts = widgetSettings.visibleCharts || [];
    const chartDateFormatter = getChartDateFormatter(widgetSettings.period);
    const widgetDataValues = _.get(props, 'widgetData.values', []);
    const chartsData = {
        performanceChart: getPerformanceChartDataSelector(widgetDataValues, colorSchemas[widgetSettings.performanceColorScheme])
    };
    const tickPositions = getDateTickPositions(
        widgetDataValues,
        widgetSettings.period,
        isSmallWidget ? PERFORMANCE_SMALL_X_TICK_AMOUNT : PERFORMANCE_X_TICK_AMOUNT,
        isSmallWidget
    );
    const common = {
        ...disabledHighstockControls,
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: chartDateFormatter,
                autoRotation: false,
                useHTML: true, // ignore overflow hidden on SVG
                style: {
                    whiteSpace: 'nowrap',
                    textOverflow: 'none'
                }
            },
            min: getDateMinValue(widgetDataValues),
            max: getDateMaxValue(widgetDataValues),
            tickPositions
        },
        yAxis: {
            labels: {
                align: 'left'
            }
        }
    };

    const performanceMinValue = getPerformanceMinValue(widgetDataValues);
    const performanceMaxValue = getPerformanceMaxValue(widgetDataValues);
    const performanceTickAmount = isSmallWidget ? PERFORMANCE_SMALL_Y_TICK_AMOUNT : PERFORMANCE_Y_TICK_AMOUNT;
    const performanceChartConfig = _.merge({}, common, {
        yAxis: {
            min: performanceMinValue,
            max: performanceMaxValue,
            showLastLabel: true,
            showFirstLabel: false,
            tickPositions: getPerformanceTickPositions(performanceMinValue, performanceMaxValue, performanceTickAmount)
        }
    });
    const volumeChartConfig = _.merge({}, common, {
        colorScheme: colorSchemas[widgetSettings.volumeColorScheme],
        yAxis: {
            softMin: getVolumeMinValue(widgetDataValues),
            softMax: getVolumeMaxValue(widgetDataValues),
            tickAmount: VOLUME_Y_TICK_AMOUNT,
            showLastLabel: true,
            showFirstLabel: false
        }
    });

    if (!isSmallWidget) {
        chartsData.volumeChart = getVolumeChartDataSelector(widgetDataValues);
    }

    return {
        charts: {
            selectableCharts: getPerformanceChartsArray(isSmallWidget).map(chart => ({
                id: chart.id,
                label: props.intl.formatMessage({ id: chart.label }),
                isSelected: visibleCharts.indexOf(chart.id) > -1,
                color: chartsData.performanceChart[chart.id].color
            })),
            data: chartsData,
            config: {
                performance: performanceChartConfig,
                volume: volumeChartConfig
            }
        }
    };
})(WrappedComponent);

export const chartsShape = PropTypes.shape({
    selectableCharts: PropTypes.array,
    data: PropTypes.object,
    config: PropTypes.object
});
