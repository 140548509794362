import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getSlotByIdSelector } from 'State/dynamicPage/selectors';
import { getWidgetSelector } from 'State/widgets/selectors';
import WidgetBox from '../widgets/WidgetBox/WidgetBox';

import './StaticLayout.scss';

const mapStateToProps = (state, props) => {
    const staticSlot = getSlotByIdSelector(state, props.slotId);
    const widget = staticSlot ? getWidgetSelector(state, staticSlot.widgets[0]) : null;

    return {
        widget
    };
};

@connect(mapStateToProps)
class StaticLayout extends Component {
    static propTypes = {
        slotId: PropTypes.number.isRequired,
        widget: PropTypes.object
    };

    render() {
        const { slotId, widget } = this.props;

        return (
            <div>
                {widget ?
                    <WidgetBox slotId={slotId} widgetName={widget.widgetId}/>
                    : <div className='static-layout-empty'>
                        <FormattedMessage id='static.layout.noWidget' />
                    </div>
                }
            </div>
        );
    }
}

export default StaticLayout;
