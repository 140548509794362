import React from 'react';
import { FormattedMessage } from 'react-intl';

import './InaccessibleShareholdersNote.scss';

const InaccessibleShareholdersNote = () => {
    return (
        <div className='inaccessible-shareholders-note message-note'>
            <FormattedMessage id='watchlist.inaccessible.note'/>
        </div>
    );
};

export default InaccessibleShareholdersNote;

