import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import ReportingHolderTypeAnalysisPageHeader from '../components/ReportingGeographicAnalysisPageHeader';
import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from './slots';

@injectIntl
class ReportingGeographicAnalysisPage extends Component {
    static propTypes = {
        intl: intlShape
    };

    render() {
        return (
            <div className='reporting-geographic-analysis-page'>
                <ReportingHolderTypeAnalysisPageHeader/>
                <DynamicPage
                    layoutType={layoutTypes.layoutEasy}
                    slots={slots}
                    dynamicPageType={PAGE_TYPES.REPORTING_GEOGRAPHIC_ANALYSIS}/>
            </div>
        );
    }
}

export default ReportingGeographicAnalysisPage;
