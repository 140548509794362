import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { abbreviationValues } from 'Constants/number-abbreviations';
import { NullableFormatterWithIntl } from './NullableFormatter';
import { changeFormatter, scaleFormatter, symbolFormatter } from './formatters';

const AssetChangeFormatter = (props) => {
    const {
        valueFormat, withSymbol, separately,
        className
    } = props;

    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, negClass, intl }) => {
                const format = abbreviationValues[valueFormat];
                const symbol = intl.formatMessage({ id: format.localization });
                let formattedValue = value;

                formattedValue = scaleFormatter(formattedValue, format.divider);
                formattedValue = changeFormatter(intl, formattedValue);
                formattedValue = symbolFormatter(formattedValue, withSymbol ? symbol : null, true, withSymbol && separately);

                return (<span className={cn(className, negClass)}>{formattedValue}</span>);
            }}
        </NullableFormatterWithIntl>
    );
};

AssetChangeFormatter.propTypes = {
    value: PropTypes.any,
    valueFormat: PropTypes.string.isRequired,
    withSymbol: PropTypes.bool,
    separately: PropTypes.bool,
    className: PropTypes.string
};

AssetChangeFormatter.defaultProps = {
    withSymbol: false
};

export default AssetChangeFormatter;
