import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import shareholderTypeToIconMap from 'Constants/shareholder-type-to-icon-map';
import Icon from 'Components/Icon';

const ShareholderTypeIcon = ({ value }) => {
    const className = cn('font-icon', 'shareholder-type-icon', shareholderTypeToIconMap[value]);

    return (
        <div className='shareholder-type-cell'>
            <Icon className={className}/>
        </div>
    );
};

ShareholderTypeIcon.propTypes = {
    value: PropTypes.any
};

export default ShareholderTypeIcon;
