import React from 'react';

import slots from './slots';
import layoutTypes from 'Constants/layout-types';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import { DynamicPage } from 'Components/DynamicPage';
import AuthorizedUserListHeader from './components/AuthorizedUserListHeader';

const ReportingAuthorizedUserListPage = () => (
    <div className='authorized-users-page'>
        <AuthorizedUserListHeader/>
        <DynamicPage
            layoutType={layoutTypes.layoutEasy}
            slots={slots}
            dynamicPageType={PAGE_TYPES.REPORTING_AUTHORIZED_USER_LIST}/>
    </div>
);

export default ReportingAuthorizedUserListPage;
