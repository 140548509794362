import { createSelector } from 'reselect';
import _ from 'lodash';

import widgetTypes from 'Constants/widget-types';
import { getInnerFetchingSelector } from '../shareholderTable';

const getWidgetById = (state, id) => {
    return _.get(state, `widgets.${id}`);
};

const getVisibleWidgets = (state) => {
    return _.filter(_.get(state, 'widgets'), widget => widget.type !== widgetTypes.fullQuote);
};

export const getWidgetByType = (state, type) => {
    return _.find(_.get(state, 'widgets'), widget => widget.type === type);
};

export const getVisibleWidgetsAmount = createSelector(
    getVisibleWidgets,
    widgets => _.keys(widgets).length
);

export const getWidgetSelector = createSelector(
    getWidgetById,
    widget => widget
);

export const getWidgetTypeSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'type')
);

export const getWidgetDataSourceIdSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'dataSourceId')
);

export const getWidgetSettingsSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'settings')
);

export const isWidgetFetchingSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'isFetching')
);

export const isWidgetRemovingSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'isRemoving')
);

export const isWidgetAddingSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'isAdding')
);

export const getWidgetErrorSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'error')
);

export const getWidgetPageParamsSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'pageParams')
);

export const getWidgetDataParamsSelector = createSelector(
    getWidgetById,
    widget => _.get(widget, 'dataParams')
);

export const getWidgetsSelector = state => _.get(state, 'widgets');

export const getAllWidgetsByPageIdSelector = createSelector(
    getWidgetsSelector,
    (state, pageId) => pageId,
    (widgets = {}, pageId) => {
        return _.filter(widgets, widget => {
            return widget.pageId === pageId;
        });
    }
);

export const isAllWidgetsLoaded = createSelector(
    getWidgetsSelector,
    state => getInnerFetchingSelector(state),
    (widgets, isInnerFetching) => _.every(_.toArray(widgets), widget => widget.type === widgetTypes.shareholdersTable ?
        !widget.isFetching && !isInnerFetching : !widget.isFetching)
);

export const getWidgetByTypeSelector = (state, type) => {
    return _.find(state.widgets, widget => widget.type === type);
};

export const getSelectedWidgetTypeSelector = createSelector(
    getWidgetsSelector,
    widgets => {
        const selectedWidget = _.find(_.toArray(widgets), widget => _.get(widget, 'settings.isSelected')) || {};

        return selectedWidget.type;
    }
);
