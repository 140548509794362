import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { DynamicPage } from 'Components/DynamicPage';
import slots from './slots';
import layoutTypes from 'Constants/layout-types';
import PAGE_TYPES from 'Constants/dynamic-page-types';

import './ContactDetailsPage.scss';

class ContactDetailsPage extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired
    };

    render() {
        const dynamicPageType = PAGE_TYPES.CONTACT_DETAILS;

        return (
            <div className='contact-details-page'>
                <DynamicPage
                    layoutType={layoutTypes.layoutMultiSlots}
                    slots={slots}
                    dynamicPageType={dynamicPageType}
                    params={this.props.match.params}/>
            </div>
        );
    }
}

export default ContactDetailsPage;
