import amountOfRecords from 'Constants/amount-of-records';

export default [
    {
        value: {
            type: amountOfRecords.TOP_25,
            records: 25
        },
        label: 'modals.exportReport.top25'
    },
    {
        value: {
            type: amountOfRecords.TOP_100,
            records: 100
        },
        label: 'modals.exportReport.allDisplayedRecords'
    }
];
