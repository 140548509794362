import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { textBlocksPropType } from '../../propTypes';

const TextBlocks = (props) => {
    const {
        className,
        titleKey,
        paragraphs
    } = props;

    return (
        <div className={className}>
            <h3 className='title'><FormattedMessage id={titleKey}/></h3>
            {paragraphs.map((p, index) =>
                <div className='text-block-item' key={index}>{p}</div>
            )}
        </div>
    );
};

TextBlocks.propTypes = {
    paragraphs: textBlocksPropType,
    titleKey: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default TextBlocks;
