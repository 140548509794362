import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import widgetTypes from 'Constants/widget-types';

const titleMessageKeys = {
    [widgetTypes.shareholdersTable]: 'shareholders.tab.name'
};

const WidgetTab = ({ onSelectTab, widget }) => {
    const isActive =  _.get(widget, 'settings.isSelected');

    return (
        <li className='tab'>
            <a className={cn({ 'active' : isActive })} onClick={() => onSelectTab(widget)}>
                <FormattedMessage id={titleMessageKeys[widget.type]} />
            </a>
        </li>
    );
};

WidgetTab.propTypes = {
    onSelectTab: PropTypes.func,
    widget: PropTypes.object
};

export default WidgetTab;
