import { connect } from 'react-redux';

import AppFooter from './AppFooter';
import { showTermsAndConditionsModal } from 'State/modal';


export default connect(
    null,
    {
        showTermsAndConditionsModal
    }
)(AppFooter);
