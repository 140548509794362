import React, { Component } from 'react';

import { DynamicPage } from 'Components/DynamicPage';
import ReportingHolderTypeAnalysisPageHeader from './components/ReportingHolderTypeAnalysisPageHeader';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import featureTypes from 'Constants/feature-types';
import restricted from 'Hoc/restricted';
import slots from './slots';

import './ReportingHolderTypeAnalysisPage.scss';


class ReportingHolderTypeAnalysisPage extends Component {
    render() {
        return (
            <div className='reporting-holder-type-analysis-page'>
                <ReportingHolderTypeAnalysisPageHeader/>
                <DynamicPage
                    layoutType={layoutTypes.layoutEasy}
                    slots={slots}
                    dynamicPageType={PAGE_TYPES.REPORTING_HOLDER_INVESTOR_TYPE_ANALYSIS}/>
            </div>
        );
    }
}

export default restricted({ feature: featureTypes.HOLDING_INVESTOR_TYPE_ANALYSIS })(ReportingHolderTypeAnalysisPage);
