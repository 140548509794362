import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import Widget from 'Components/widgets/Widget';
import GeographicAnalysisWidgetControlPanel from '../components/GeographicAnalysisControlPanel';
import GeographicAnalysisContent from '../components/GeographicAnalysisContent';
import NullComponent from 'Components/widgets/Widget/components/NullComponent';
import { changeGeographicAnalysisRouteState } from 'State/routeState';
import { getGeographicAnalysisRouteState } from 'State/routeState/selectors';

import '../components/GeographicAnalysis.scss';

const ROUTE_STATE_PARAMS = ['shareholderType', 'locationFilterType'];

const mapStateToProps = (state) => ({
    routeState: getGeographicAnalysisRouteState(state)
});

const mapDispatchToProps = { changeGeographicAnalysisRouteState };

@connect(mapStateToProps, mapDispatchToProps)
class GeographicAnalysis extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        routeState: PropTypes.object,
        changeGeographicAnalysisRouteState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.dataParams = {};

        // settings that can come from the route state
        this.settings = {};

        this.widgetComponents = {
            WidgetControlPanel: GeographicAnalysisWidgetControlPanel,
            WidgetContent: GeographicAnalysisContent,
            WidgetHeader: NullComponent
        };

        this.widgetEvents = {
            onBuildDataParams: this.onBuildDataParams,
            handleUpdateWidgetParams: this.handleUpdateWidgetParams
        };
    }

    UNSAFE_componentWillMount() {
        const { routeState = {}, widgetSettings = {} } = this.props;
        const filledParams = _.omitBy(routeState, param => !param);

        if (_.values(filledParams).length) {
            this.props.onUpdateWidgetSettings({
                ...this.props.widgetSettings,
                ...filledParams
            });
            this.props.changeGeographicAnalysisRouteState({
                ..._.mapValues(filledParams, () => null)
            });
        }

        _.forOwn(widgetSettings, (value, key) => {
            if (_.includes(ROUTE_STATE_PARAMS, key)) {
                this.settings[key] = routeState[key] ||
                    _.get(this.props, ['widgetSettings', key]);
            }
        });
    }

    handleUpdateWidgetParams = (params) => {
        this.dataParams = {
            ...this.dataParams,
            ...params
        };

        _.forOwn(params, (value, key) => {
            if (_.includes(ROUTE_STATE_PARAMS, key)) {
                this.settings[key] = params[key];
            }
        });

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, ...params });
        this.props.onUpdateWidgetData({ ...this.dataParams });
    };

    onBuildDataParams = () => {
        this.dataParams = {
            ...this.settings,
            isCedeCoHidden: this.props.widgetSettings.isCedeCoHidden
        };
        return this.dataParams;
    };

    render() {
        return (
            <Widget
                {...this.props}
                className='geographic-analysis'
                components={this.widgetComponents}
                events={this.widgetEvents}
                settings={this.settings}/>
        );
    }
}

export default GeographicAnalysis;
