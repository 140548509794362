import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';

import reportPaths from 'Constants/report-paths';
import reportTypes from 'Constants/report-types';
import exportReportFormats from 'Constants/export-report-formats';
import { deleteReportTemplate, shareReportTemplate } from 'State/savedReportTemplates/actions';
import { showConfirmModal, showShareReportTemplateModal } from 'State/modal';
import { runReport, showExportTemplateModal } from 'State/exportReports';
import { notifyError, notifySuccess } from 'State/notifier';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';

const mapDispatchToProps = {
    deleteReportTemplate,
    runReport,
    showConfirmModal,
    showExportTemplateModal,
    showShareReportTemplateModal,
    shareReportTemplate,
    notifySuccess,
    notifyError
};

const savedReportsEnhancer = WrappedComponent => {
    @withRouter
    @injectIntl
    @connect(null, mapDispatchToProps)
    class SavedReportTemplates extends Component {
        static propTypes = {
            deleteReportTemplate: PropTypes.func,
            runReport: PropTypes.func,
            showConfirmModal: PropTypes.func,
            showExportTemplateModal: PropTypes.func,
            history: PropTypes.object,
            location: PropTypes.object,
            intl: intlShape,
            showShareReportTemplateModal: PropTypes.func,
            shareReportTemplate: PropTypes.func,
            notifySuccess: PropTypes.func,
            notifyError: PropTypes.func
        };

        handleDelete = (report, onSuccessDeleteCallback) => {
            const messageKey = report.securityCount > 1
                ? 'modals.confirmModal.message.delete.reportTemplate.multipleSecurity'
                : 'modals.confirmModal.message.delete';

            this.props.showConfirmModal({
                titleKey: 'modals.confirmModal.title.delete',
                messageKey,
                isHtmlMessage: true,
                okTextKey: 'common.delete',
                onSuccess: () => this.props.deleteReportTemplate(report.reportTemplateId).then(() => {
                    toastr.success(
                        this.props.intl.formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.title' }),
                        this.props.intl.formatMessage({ id: 'reporting.listReport.notifier.templateDeleted.message' })
                    );
                    onSuccessDeleteCallback(report);
                }),
                payload: report.reportName
            });
        };

        handleEdit = report => {
            this.props.history.push(buildLocationWithSafeQueryParams(`${reportPaths[report.type]}/${report.reportTemplateId}`, this.props.location));
        };

        handleRunReport = ({ type, reportTemplateId, form, securityCount }) => {
            const isMultiSecurity = securityCount > 1;
            const isPDFDisabled = isMultiSecurity || type === reportTypes.shareDetailTemplate;
            const tooltipKeys = {
                onPDF: isMultiSecurity ? 'reporting.multiSecurity.disablePDF.tooltip' : null
            };

            if (type === reportTypes.dataVizTemplate) {
                this.props.runReport(type, reportTemplateId, exportReportFormats.ZIP);
            } else {
                this.props.showExportTemplateModal({
                    reportTemplateType: type,
                    reportTemplateId,
                    form,
                    modalViewParams: {
                        showExcelLimit: true,
                        isPDFDisabled,
                        tooltipKeys
                    }
                });
            }
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    onDelete={this.handleDelete}
                    onEdit={this.handleEdit}
                    onRunReport={this.handleRunReport}/>
            );
        }
    }

    return SavedReportTemplates;
};

export default savedReportsEnhancer;
