import _ from 'lodash';

import { isValidId, isExists } from 'Utils/reducer';
import * as widgetsSelectors from 'State/widgets/selectors';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';

export const buildWidget = (id, { settings, dataSourceId, pageParams, dataParams, type, pageId, isAdding = false }) => {
    return {
        widgetId: id,
        isFetching: false,
        isRemoving: false,
        isAdding,
        settings,
        dataSourceId,
        pageParams,
        dataParams,
        type,
        pageId,
        error: null
    };
};

export const setWidgetsFlag = (state, widgets, flag, flagValue) => {
    let newState = state;

    if (_.isArray(widgets)) {
        const _state = {};

        widgets.forEach(id => {
            if (isValidId(id) && isExists(state, widgets)) {
                _state[id] = {
                    ...state[id],
                    [flag]: flagValue
                };
            }
        });

        newState = { ...state, ..._state };
    } else if (isValidId(widgets) && isExists(state, widgets)) {
        const widgetId = widgets;

        newState = {
            ...state,
            [widgetId]: {
                ...state[widgetId],
                [flag]: flagValue
            }
        };
    }

    return newState;
};

export const setSelectedRegisteredOverviewWidget = (state, widgetId) => {
    return _.mapValues(state, (value, key) => {
        const settings = value.settings;

        settings.isSelected = key == widgetId; // eslint-disable-line
        return {
            ...value,
            settings
        };
    });
};

export const normalizeSyncWidgets = (state, widgetIds) => {
    const slots = dynamicPageSelectors.getDynamicPageSlotsSelector(state);
    const slotsWithPositions = _.map(slots, (slot, position) => ({
        ...slot,
        position: _.toInteger(position)
    }));

    return _.reduce(widgetIds, (result, widgetId) => {
        const widget = widgetsSelectors.getWidgetSelector(state, widgetId);

        if (!widget) {
            return result;
        }

        const slot = _.find(slotsWithPositions, s => _.includes(s.widgets, widgetId));
        const position = _.get(slot, 'position');

        result.push({
            type: widget.type,
            widgetId,
            settings: widget.settings,
            position
        });

        return result;
    }, []);
};
