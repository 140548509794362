import { compose } from '@shakacode/recompose';
import { clearSubmitErrors, reduxForm, SubmissionError } from 'redux-form';
import { injectIntl } from 'react-intl';

import SearchBySecurity from './SearchBySecurity';
import { connect } from 'react-redux';
import { PII, SEARCH_FORM_NAME, VALIDATION_LENGTH } from 'Constants/search';
import { isValidCert, isValidGlobalSearchQuery, isValidSSN } from 'Utils/validators';
import {
    getAdvancedSearchIssuers,
    getAdvancedSearchSecurities,
    getSelectedPII,
    isAvailableQueryInput,
    isFetchingAny,
    search,
    toggleMultipleIssuer,
    toggleMultipleSecurity
} from 'State/advancedSearch';

const mapStateToProps = state => ({
    isAvailableQueryInput: isAvailableQueryInput(state),
    selectedSecurities: getAdvancedSearchSecurities(state),
    selectedIssuers: getAdvancedSearchIssuers(state),
    selectedPII: getSelectedPII(state),
    isFetching: isFetchingAny(state)
});

const mapDispatchToProps = { search, toggleMultipleIssuer, toggleMultipleSecurity };

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: SEARCH_FORM_NAME,
        onChange: (values, dispatch) => {
            dispatch(clearSubmitErrors(SEARCH_FORM_NAME));
        },
        onSubmit: ({ PII: selectedPII, query: submittedQuery = '', searchAcrossCurrent }, dispatch, ownProps) => {
            const query = submittedQuery.trim();
            let errorMessage;

            switch (selectedPII) {
                case PII.NONE:
                    if (!isValidGlobalSearchQuery(query)) {
                        errorMessage = ownProps.intl.formatMessage({ id: 'search.valid.length' },
                            {
                                minLength: VALIDATION_LENGTH.MIN_LENGTH,
                                maxLength: VALIDATION_LENGTH.MAX_LENGTH
                            });
                    }
                    break;
                case PII.TAX_ID:
                    if (!isValidSSN(query)) {
                        errorMessage = ownProps.intl.formatMessage({ id: 'search.valid.ssn' });
                    }
                    break;
                case PII.CERTIFICATE_NO:
                    if (!isValidCert(query)) {
                        errorMessage = ownProps.intl.formatMessage({ id: 'search.valid.cert' });
                    }
                    break;
                default:
            }
            if (errorMessage) {
                throw new SubmissionError({
                    query: errorMessage
                });
            }
            return ownProps.search(selectedPII, query, searchAcrossCurrent);
        },
        pure: true
    })
)(SearchBySecurity);
