export default {
    CONTACT_DETAILS: 'contactDetailsPage',
    HOME: 'homePage',
    OWNERSHIP_SHAREHOLDERS: 'ownershipShareholdersPage',
    OWNERSHIP_HISTORICAL_ANALYSIS: 'ownershipHistoricalAnalysisPage',
    OWNERSHIP_PEER_ANALYSIS: 'ownershipPeerAnalysisPage',
    REPORTING: 'reportingPage',
    REGISTERED_OVERVIEW: 'registeredOverviewPage',
    INSTITUTIONAL_OVERVIEW: 'institutionOverviewPage',
    INSTITUTION_PROFILE: 'institutionProfilePage',
    INSIDER_OVERVIEW: 'insiderOverviewPage',
    REPORTING_HOLDER_INVESTOR_TYPE_ANALYSIS: 'holderInvestorTypeAnalysisPage',
    REPORTING_CONTROL_BOOKS_SEARCH: 'controlBooksSearchPage',
    REPORTING_CONTROL_BOOKS_RESULT: 'controlBooksResultsPage',
    REPORTING_SHARES_RANGE_ANALYSIS_RESULT: 'shareRangeAnalysisResultsPage',
    REPORTING_GEOGRAPHIC_ANALYSIS: 'geographicAnalysisPage',
    REPORTING_DATAVIZ_REPORT: 'dataVizReportPage',
    REPORTING_AUTHORIZED_USER_LIST: 'authorizedUserListPage',
    PEER_ANALYSIS: 'peerAnalysisPage',
    HISTORICAL_ANALYSIS: 'historicalAnalysisPage',
    CONTACTS: 'contactsPage',
    INSTITUTIONS: 'institutionsPage',
    COMPARE_SHAREHOLDERS: 'compareShareholdersPage',
    MEETING_PLANNER: 'meetingPlannerPage',
    USER_SECURE_UPLOAD: 'userSecureUpload'
};

