import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { fetchingDocumentIdSelector } from 'State/reportingDocuments/selectors';
import { deleteDocument } from 'State/reportingDocuments/actions';
import Icon from 'Components/Icon';
import NullableFormatter from 'Components/formatters/NullableFormatter';
import { showConfirmModal } from 'State/modal';
import { notifySuccess, notifyError } from 'State/notifier/actions';
import { addEllipsisToString } from 'Utils/string';
import stringMaxLength from 'Constants/string-max-length';
import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';

const mapStateToProps = state => ({
    fetchingDocumentId: fetchingDocumentIdSelector(state)
});
const mapDispatchToProps = {
    showConfirmModal,
    deleteDocument,
    notifySuccess,
    notifyError
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class CellDeleteAction extends Component {
    static propTypes = {
        intl: intlShape,
        value: PropTypes.any,
        additionalData: PropTypes.object,
        className: PropTypes.string,
        fetchingDocumentId: PropTypes.any,
        showConfirmModal: PropTypes.func,
        deleteDocument: PropTypes.func,
        notifySuccess: PropTypes.func,
        notifyError: PropTypes.func
    };

    handleClick = () => {
        const {
            value,
            additionalData: { shareholderId, onDeleteDocument } = {},
            intl: { formatMessage }
        } = this.props;

        if (value.uploadStatus === uploadedDocumentStatuses.IN_PROGRESS) return;

        this.props.showConfirmModal({
            titleKey: 'modals.confirmModal.title.deleteDocument',
            messageKey: 'modals.confirmModal.message.deleteDocument',
            okTextKey: 'common.yes',
            onSuccess: () => this.props.deleteDocument(value.documentId, shareholderId)
                .then(() => {
                    this.props.notifySuccess(
                        null,
                        formatMessage(
                            { id: 'registered.shareholder.documents.notification.deleteDocument.success' },
                            { fileName: value.description })
                    );

                    onDeleteDocument && onDeleteDocument();
                }, () => {
                    this.props.notifyError(
                        null,
                        formatMessage(
                            { id: 'registered.shareholder.documents.notification.deleteDocument.error' },
                            { fileName: value.description })
                    );
                }),
            payload: addEllipsisToString(value.description, stringMaxLength.FOR_MODAL)
        });
    };

    render() {
        const { className, value } = this.props;
        const { additionalData: { iconClass } = {} } = this.props;
        const isDisabled = value.uploadStatus === uploadedDocumentStatuses.IN_PROGRESS;
        const cellClassName = cn('font-icon', className, iconClass  || 'font-icon-delete');

        return value.canDelete
            ? (<NullableFormatter value={value.documentId}>
                <Icon
                    className={cellClassName}
                    titleKey='common.delete'
                    inactive={isDisabled}
                    onClick={this.handleClick}/>
            </NullableFormatter>)
            : null;
    }
}


export default CellDeleteAction;
