import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';
import { connect } from 'react-redux';

import reportTypes from 'Constants/report-types';
import shareholderColumnNames from 'Constants/shareholders-columns-names';
import { showExportWidgetModal } from 'State/exportReports';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import PeerAnalysisHeader from './components/PeerAnalysisHeader';
import AnalysisTableControlPanel from '../_commonComponents/AnalysisControlPanel/AnalysisControlPanel';
import { sortData } from '../_commonComponents/AnalysisTable/utils';
import { getTableData } from './peerDataSelectors';
import PeerAnalysisContent from './components/PeerAnalysisContent';
import { showInfoModal } from 'State/modal';
import { getWidgetDataSourceIdSelector } from 'State/widgets/selectors';

import './PeerAnalysis.scss';

const mapStateToProps = (state, props) => ({
    widgetDataSourceId: getWidgetDataSourceIdSelector(state, props.widgetName)
});

const mapDispatchToProps = {
    showExportWidgetModal,
    showInfoModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@withProps(({ widgetSettings, widgetData, intl, isPrintMode }) => {
    /**
     * We need default sorting by securityCompany (issuer).
     * Cause it is not permanently id,
     * we should set correct sortColumn even it was arrived as incorrect
     */
    const newWidgetSettings = _.cloneDeep(widgetSettings);
    const sortColumn = _.get(widgetSettings, 'sortCriteria.sortColumn');
    const sortField = _.get(widgetSettings, 'sortCriteria.sortField');
    const securityCompanyId = _.get(widgetData, 'securityCompany.id');
    const peerCompanyIds = _.get(widgetData, 'peerCompanies', []).map(p => p.id);
    const allCompanyIds = peerCompanyIds.concat(securityCompanyId);

    if (sortField !== shareholderColumnNames.SHAREHOLDER_NAME
        && securityCompanyId
        && !allCompanyIds.includes(sortColumn)) {
        _.set(newWidgetSettings, 'sortCriteria.sortColumn', securityCompanyId);
    }

    return ({
        widgetTitle: intl.formatMessage({ id: 'peer.analysis.title' }),
        components: {
            WidgetHeader: PeerAnalysisHeader,
            WidgetControlPanel: isPrintMode ? null : AnalysisTableControlPanel,
            WidgetContent: PeerAnalysisContent
        },
        widgetSettings: newWidgetSettings
    });
})
class PeerAnalysis extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        widgetDataSourceId: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.widgetEvents = {
            onBuildDataParams: this.onBuildDataParams
        };
    }

    handleExport = () => {
        const { widgetSettings } = this.props;
        const selectedShareholders = _.get(widgetSettings, 'selectedShareholders', []);
        const selectedPeers = _.get(widgetSettings, 'selectedPeers', []);
        const sortCriteria = _.get(widgetSettings, 'sortCriteria');
        const data = getTableData(this.props);
        const sortedData = sortData(data, sortCriteria);
        const shareholderIds = sortedData.map(shareholder => shareholder.shareholderId);

        this.props.showExportWidgetModal({
            reportTemplateType: reportTypes.peerAnalysisTemplate,
            exportParams: {
                shareholderIds,
                selectedShareholders,
                selectedPeers,
                sortCriteria,
                maxYAxisValue: this.maxYAxisValue
            }
        });
    };

    onBuildDataParams = () => {
        return {
            selectedShareholders: this.props.widgetSettings.selectedShareholders || []
        };
    };

    render() {
        const widgetNoData = !_.get(this.props, 'widgetData.data.length');
        const noShareholders = !_.get(this.props, 'widgetSettings.selectedShareholders.length');

        const props = {
            ...this.props,
            widgetControlPanelSettings: {
                onExport: this.handleExport,
                inactive: widgetNoData || noShareholders
            },
            setMaxYAxisValue: value => {
                this.maxYAxisValue = value;
            },
            widgetNoData
        };

        return (
            <Widget
                {...props}
                events={this.widgetEvents}
                className='widget-peer-analysis'/>
        );
    }
}

export default PeerAnalysis;
