import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { MillionChangeFormatter, NullableFormatter } from 'Components/formatters';
import TableEasy from 'Components/Tables/TableEasy';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';

class AnalysisTurnoverContent extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl.formatMessage;

        this.columns = [
            {
                dataKey: 'turnover',
                title: formatMessage({ id: 'analysis.turnover.turnover' }),
                bodyCellComponent: v => <NullableFormatter {...v} withTitle/>
            },
            {
                dataKey: 'os',
                title: formatMessage({ id: 'analysis.turnover.os' }),
                columnClassName: 'text-right',
                bodyCellComponent: TotalValueOsCell
            },
            {
                dataKey: 'change',
                title: formatMessage({ id: 'analysis.turnover.change' }),
                columnClassName: 'text-right',
                bodyCellComponent: MillionChangeFormatter
            }
        ];
    }

    render() {
        const { rows, widgetFooterProps, aggregate, hideBarChart, widgetNoData, isFetching, isDataViz } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        widgetFooterProps={widgetFooterProps}
                        className='widget-table'
                        hideBarChart={hideBarChart}
                        columns={this.columns}
                        aggregate={aggregate}
                        renderAllRows={isDataViz}
                        data={rows} />
                </NoDataComponent>
            </div>
        );
    }
}

export default AnalysisTurnoverContent;
