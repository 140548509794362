export default {
    ONLY_PA_ACCESS: 'ONLY_PA_ACCESS',
    USER_COMPANY_NOT_CONFIGURED: 'USER_COMPANY_NOT_CONFIGURED',
    ROLE_NOT_FOUND: 'ROLE_NOT_FOUND',
    ISP_USER_DOES_NOT_EXIST: 'ISP_USER_DOES_NOT_EXIST',
    NO_SECURITIES: 'NO_SECURITIES',
    MULTIPLE_WIDGET_CONFLICT: 'MULTIPLE_WIDGET_CONFLICT',
    NO_ISSUER_ID_FOR_CURRENT_SECURITY: 'NO_ISSUER_ID_FOR_CURRENT_SECURITY',
    DENODO_NOT_AVAILABLE: 'DENODO_NOT_AVAILABLE',
    BUSINESS_SERVICES_NOT_AVAILABLE: 'BUSINESS_SERVICES_NOT_AVAILABLE',
    SOLR_NOT_AVAILABLE: 'SOLOR_NOT_AVAILABLE',
    NO_FEATURES: 'NO_FEATURES',
    USER_STATUS_EXCEPTION: 'USER_STATUS_EXCEPTION',
    SECURITY_ACCESS_ERROR: 'SECURITY_ACCESS_ERROR',
    ACCESS_DENIED: 'ACCESS_DENIED',
    PING_TOKEN_EXCEPTION: 'PING_TOKEN_EXCEPTION',
    SESSION_EXCEPTION: 'SESSION_EXCEPTION'
};
