import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import CapGroup from '../_commonComponents/CapGroup';
import columnNames from '../_commonComponents/CapGroup/constants/column-names';
import messageType from 'Constants/message-types';
import { getWidgetFooterPropsWithSignClass } from 'Components/widgets/utils';

const TOTAL_PERCENT = 100;

@injectIntl
class ShareholderAnalysisTop10CapGroup extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetTitle = this.props.intl.formatMessage({ id: 'shareholder.analysis.top.10.cap.group.title' });

        this.widgetEvents = {
            onBuildDataParams: () => ({
                shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
            })
        };
    }

    getWidgetFooterProps = () => {
        const widgetData = this.props.widgetData;

        return {
            rightSide: [
                {
                    messageKey: 'widgets.totalOver100',
                    type: messageType.MORE_THAN_100_PERCENTS,
                    hidden: !(_.get(widgetData, 'aggregate.osTotal') > TOTAL_PERCENT)
                }
            ]
        };
    };

    render() {
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetFooterProps: getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps()),
            columnTitles: {
                [columnNames.CAP_GROUP]: 'shareholder.analysis.top.10.cap.group.capGroup',
                [columnNames.OS]: 'shareholder.analysis.top.10.cap.group.os',
                [columnNames.CHANGE]: 'shareholder.analysis.top.10.cap.group.change'
            }
        };

        return (
            <CapGroup
                {...props}
                events={this.widgetEvents}/>
        );
    }
}

export default ShareholderAnalysisTop10CapGroup;
