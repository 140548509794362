export const SHOW_LOADING_BLOCK = 'SHOW_LOADING_BLOCK';
export const HIDE_LOADING_BLOCK = 'HIDE_LOADING_BLOCK';

export const hideLoadingBlock = () => ({
    type: HIDE_LOADING_BLOCK
});

export const showLoadingBlock = () => ({
    type: SHOW_LOADING_BLOCK
});
