import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import formatters from 'Constants/formatter-options';
import ShareholderLinkWrapper from './ShareholderLinkWrapper';
import ShareholderLink from './ShareholderLink';
import ShareholderName from './ShareholderName';

const CellWithShareholderLink = (props) => {
    const { value, className, additionalData = {} } = props;
    const { id, type = '', showLink, refToBackQuery, isCedeCo } = additionalData;

    const cellClass = cn('cell-with-link', className);
    const val = value || formatters.SYMBOL_EMPTY_VALUE;

    const rootProps = {
        className: cellClass
    };

    if (isCedeCo) {
        // to prevent binding the attribute on every element
        rootProps['data-cede-and-co-row'] = ''; // attr without value
    }

    if (showLink) {
        return (
            <ShareholderLinkWrapper {...rootProps}>
                <ShareholderLink
                    type={type}
                    val={val}
                    refToBackQuery={refToBackQuery}
                    id={id} />
            </ShareholderLinkWrapper>
        );
    }

    return (
        <ShareholderLinkWrapper {...rootProps}>
            <ShareholderName val={val} />
        </ShareholderLinkWrapper>
    );
};

CellWithShareholderLink.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    additionalData: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        showLink: PropTypes.bool,
        isCedeCo: PropTypes.bool,
        refToBackQuery: PropTypes.string
    })
};

export default CellWithShareholderLink;
