import { LOCATION_CHANGE } from 'connected-react-router';

import { createReducer } from 'Utils/reducer';
import { SHOW_MODAL, CHANGE_MODAL_PARAMS, HIDE_MODAL } from './actions';
import modalTypes from '../../constants/modal-types';

const initialState = {
    type: null,
    params: null,
    className: null,
    shouldCloseOnOverlayClick: true,
    showCloseButton: true,
    modalsQueue: [],
    isModalFromQueue: false
};

const NAVIGATION_IGNORE_MODAL_TYPES = [modalTypes.saveChangesModal];

const hideModal = (state) => ({ ...state, ...initialState });

const getModalState = (payload = initialState) => ({
    type: payload.type,
    params: payload.params,
    className: payload.className,
    shouldCloseOnOverlayClick: payload.shouldCloseOnOverlayClick,
    showCloseButton: payload.showCloseButton,
    isModalFromQueue: payload.isModalFromQueue,
    noHeader: payload.noHeader
});

const actionHandlers = {
    [SHOW_MODAL]: (state, { payload }) => {
        if (state.type !== initialState.type && payload.queueProcessing) {
            return {
                ...state,
                modalsQueue: [
                    ...state.modalsQueue,
                    getModalState({
                        ...payload,
                        isModalFromQueue: true
                    })
                ]
            };
        }

        return ({
            ...state,
            ...getModalState({
                ...payload,
                isModalFromQueue: false
            })
        });
    },
    [CHANGE_MODAL_PARAMS]: (state, { payload: { params } }) => ({
        ...state,
        params: {
            ...state.params,
            ...params
        }
    }),
    [HIDE_MODAL]: (state) => {
        if (state.modalsQueue.length) {
            const modalPayload = state.modalsQueue[0];

            return {
                ...state,
                ...modalPayload,
                modalsQueue: state.modalsQueue.slice(1)
            };
        }

        return hideModal(state);
    },
    [LOCATION_CHANGE]: (state) => {
        return state.type && !NAVIGATION_IGNORE_MODAL_TYPES.includes(state.type) && !state.isModalFromQueue ? hideModal(state) : state;
    }
};

export default createReducer(initialState, actionHandlers);
