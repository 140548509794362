export default {
    POSITIVE_MODE: 'positiveMode',
    NEGATIVE_MODE: 'negativeMode'
};

export const INACTIVE_LEGENDS_CONFIG = {
    plotOptions: {
        series: {
            events: {
                legendItemClick: () => false
            }
        }
    }
};
