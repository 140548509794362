import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import widgetTypes from 'Constants/widget-types';
import { getWidgetByTypeSelector } from 'State/widgets/selectors';
import { registeredOverviewChangeSelectedWidget } from 'State/widgets/actions';

import './InfoPanel.scss';

const mapStateToProps = state => ({
    getWidgetByType: type => getWidgetByTypeSelector(state, type)
});
const mapDispatchToProps = {
    changeWidget: registeredOverviewChangeSelectedWidget
};

@connect(mapStateToProps, mapDispatchToProps)
class InfoPanel extends Component {
    static propTypes = {
        changeWidget: PropTypes.func,
        getWidgetByType: PropTypes.func
    };

    handleLinkClick = () => {
        const { getWidgetByType, changeWidget } = this.props;
        const taxLotsCurrent = getWidgetByType(widgetTypes.registeredShareholderCurrent);

        changeWidget(taxLotsCurrent.widgetId);
    };

    render() {
        return (
            <div className='prior-history-info-message message-note'>
                <FormattedMessage id='registered.shareholder.priorTADRSHistory.infoMessage'>
                    {message => <span className='prior-history-info-message-text'>{message}</span>}
                </FormattedMessage>
                <FormattedMessage id='registered.shareholder.priorTADRSHistory.infoMessage.link'>
                    {message => <span className='prior-history-info-message-link' onClick={this.handleLinkClick}>{message}</span>}
                </FormattedMessage>
            </div>
        );
    }
}

export default InfoPanel;
