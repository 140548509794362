import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { mapProps } from '@shakacode/recompose';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import ReactTable from 'react-table';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import columnDefinitions from '../constants/columnDefinitions';
import { getSortDirection } from 'Components/Tables/utils';
import { TheadComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import { getCustomComponent } from '../utils/utils';
import sortDirections from 'Constants/sort-directions';
import { sortArray } from 'Utils/utils';
import shareholderTypes from 'Constants/shareholder-types';

@injectIntl
@mapProps(props => {
    const visibleColumns = _.get(props, 'widgetSettings.visibleColumns', []);
    const columns = columnDefinitions.columns
        .filter(column => visibleColumns.indexOf(column.columnName) > -1 || !column.manageable);

    return {
        ...props,
        columns
    };
})
class HolderInvestorTypeAnalysisContent extends Component {
    static propTypes = {
        intl: intlShape,
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        columns: PropTypes.array.isRequired
    };

    handleOnSort = ({ id: sortBy }) => {
        const { widgetSettings: { sortCriteria: { field: prevSortBy, direction: prevDirection } } } = this.props;
        const sortCriteria = {
            field: sortBy,
            direction: getSortDirection(
                sortBy,
                prevSortBy,
                prevDirection,
                columnDefinitions.columns
            ),
            initial: false
        };

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
    };

    getColumnsDefinition = (columns) => {
        const { formatMessage } = this.props.intl;

        return columns
            .map((column) => {
                const Components = getCustomComponent(column.columnName);

                return {
                    Header: () => (
                        <Components.customHeadingComponent
                            title={column.displayName ? formatMessage({ id: column.displayName }) : ''}/>
                    ),
                    Cell: (props) => (
                        <Components.customComponent
                            additionalData={{
                                ...Components.additionalData,
                                ...this.getCellProps(column, props.row)
                            }}
                            value={props.value}/>
                    ),
                    accessor: column.columnName,
                    minWidth: column.minWidth,
                    width: column.width,
                    style: { msFlexPositive: column.minWidth }
                };
            });
    };

    getTheadThProps = (sortField, sortDirection, finalState, missProp, column) => {
        return {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };
    };

    getCellProps = (column, row) => {
        const shareholderType = _.get(this.props.widgetSettings, 'shareholderType');
        const { columnNames } = columnDefinitions;

        switch (column.columnName) {
            case columnNames.DESCRIPTION: {
                return shareholderType === shareholderTypes.REGISTERED &&
                row.cedeCoIncluded
                    ? { withInfo: true }
                    : {};
            }
            case columnNames.OS: {
                return {
                    titleValue: row[columnNames.OS_NO_ROUND],
                    withTitle: true
                };
            }
            default: {
                return {};
            }
        }
    };

    getNoDataProps = () => ({
        messageId: 'widgets.noDataMessage.matchingResults'
    });

    render() {
        const {
            widgetData,
            columns,
            widgetSettings: { sortCriteria: { field, direction, initial } = {}, shareholderType } = {}
        } = this.props;
        const tableColumns = this.getColumnsDefinition(columns);
        const { columnNames } = columnDefinitions;
        const sortingField = field === columnNames.OS ? columnNames.OS_NO_ROUND : field;
        const sortedValues = sortArray({
            array: widgetData,
            primaryField: sortingField,
            secondaryField: field !== columnNames.DESCRIPTION ? columnNames.DESCRIPTION : null,
            descending: direction === sortDirections.DESC,
            secondaryDescending: false,
            initial
        });
        const hasCedeCoRows = _.some(sortedValues, value => value.cedeCoIncluded);

        return (
            <div className='holder-type-analysis-content'>
                <ReactTable
                    className='special-first-cell'
                    showPagination={false}
                    resizable={false}
                    LoadingComponent={() => null}
                    minRows={0}
                    ThComponent={TheadComponent}
                    getNoDataProps={this.getNoDataProps}
                    NoDataComponent={NoResults}
                    getTheadThProps={this.getTheadThProps.bind(this, field, direction)}
                    manual
                    defaultPageSize={0}
                    resolvedData={sortedValues}
                    onSortedChange={(sortProps) => this.handleOnSort(sortProps[0])}
                    columns={tableColumns || []}/>
                {hasCedeCoRows && shareholderType === shareholderTypes.REGISTERED &&
                    <div className='message-note'>
                        <FormattedMessage id='holderInvestorTypeAnalysis.infoNote'/>
                    </div>}
            </div>
        );
    }
}

export default HolderInvestorTypeAnalysisContent;
