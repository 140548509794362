import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { mapProps, wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';
import { connect } from 'react-redux';

import { getCountOfIssuersToComparing, getShareholdersToComparing } from 'State/compareShareholders/selectors';
import { getCurrentSecuritySelector } from 'State/user';
import compareReportTemplates from '../constants/compare-report-templates';
import sortDirections from 'Constants/sort-directions';
import columnNames from 'Constants/registered-table-column-names';
import reportTypes from 'Constants/report-types';
import { showExportWidgetModal } from 'State/exportReports';
import { compareDatesDescending, sortArray } from 'Utils/utils';
import { getCompareShareholdersTdProps, getRegisteredOverviewTdProps, hideIssuerColumn } from '../utils';

const mapStateToProps = (state) => ({
    shareholdersToCompare: getShareholdersToComparing(state),
    currentSecurity: getCurrentSecuritySelector(state),
    isAvailableIssuerColumn: getCountOfIssuersToComparing(state) > 1
});
const mapDispatchToProps = {
    showExportWidgetModal
};

export default WrappedComponent => {
    @connect(mapStateToProps, mapDispatchToProps)
    @mapProps(({ isAvailableIssuerColumn, columnsDefinitions, ...rest }) => ({
        columnsDefinitions: isAvailableIssuerColumn
            ? columnsDefinitions
            : hideIssuerColumn(columnsDefinitions),
        isAvailableIssuerColumn,
        ...rest
    }))
    class CompareShareholders extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'compareShareholders');

        static propTypes = {
            ...WrappedComponent.propTypes,
            shareholdersToCompare: PropTypes.array,
            columnsDefinitions: PropTypes.array
        };

        constructor(props) {
            super(props);
            this.widgetEvents = {
                onBuildDataParams: () => ({
                    compare: props.shareholdersToCompare
                })
            };
        }

        componentDidMount() {
            const { onUpdateWidgetData, redirectOn404, widgetData, isFetching } = this.props;

            if (widgetData || isFetching) {
                return;
            }

            const widgetDataParams = _.isFunction(this.widgetEvents.onBuildDataParams) ? this.widgetEvents.onBuildDataParams({}) : {};

            onUpdateWidgetData(widgetDataParams, { redirectOn404 });
        }

        handleExport = () => {
            const { widgetSettings = {}, data, widgetType, shareholdersToCompare, currentSecurity, isAvailableIssuerColumn } = this.props;
            const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
            const selectedColumns = _.get(widgetSettings, 'selectedColumns', []);
            const reportTemplateType = reportTypes[compareReportTemplates[widgetType]];
            let securityId;

            if (!currentSecurity) {
                const sortedValues = sortArray({
                    array: data,
                    primaryField: sortCriteria.field,
                    descending: sortCriteria.direction === sortDirections.DESC,
                    // secondaryField: secondarySorting.field,
                    // secondaryDescending: secondarySorting.direction === sortDirections.DESC,
                    primaryComparator: sortCriteria.field === columnNames.DATE_OF_SALE ? compareDatesDescending : undefined
                    // secondaryComparator: secondarySorting.field && secondarySorting.field === columnNames.DATE_OF_SALE ? compareDatesDescending : undefined
                });

                // first security list
                securityId = _.get(sortedValues, '[0].securityId');
            }

            this.props.showExportWidgetModal({
                reportTemplateType,
                exportParams: {
                    compare: shareholdersToCompare,
                    sortCriteria: {
                        sortDirection: sortCriteria.direction,
                        sortField: sortCriteria.field,
                        initial: sortCriteria.initial
                    },
                    columnsToDisplay: isAvailableIssuerColumn // NO_DIVIDEND is invisible column for export
                        ? _.without(selectedColumns, columnNames.NO_DIVIDEND)
                        : _.without(selectedColumns, columnNames.ISSUER, columnNames.NO_DIVIDEND)
                },
                securityId
            });
        };

        getDefaultTdProps = columnType => ({
            ...getRegisteredOverviewTdProps(columnType),
            ...getCompareShareholdersTdProps(columnType)
        });

        render() {
            return (
                <WrappedComponent
                    getDefaultTdProps={this.getDefaultTdProps}
                    widgetEvents={this.widgetEvents}
                    onExport={this.handleExport}
                    {...this.props}/>
            );
        }
    }

    return CompareShareholders;
};
