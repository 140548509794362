import _ from 'lodash';

import {
    WIDGETS_SETTINGS_SYNC,
    WIDGETS_SETTINGS_DATAVIZ_SYNC,
    WIDGETS_SETTINGS_SYNC_REQUEST,
    WIDGETS_SETTINGS_SYNC_SUCCESS,
    WIDGETS_SETTINGS_SYNC_FAILURE,
    WIDGETS_SET_FETCHING
} from './actions';
import {
    FETCHING_SHAREHOLDERS_FUNDS_SUCCESS,
    FETCHING_SHAREHOLDERS_POSITIONS_SUCCESS
} from '../shareholderTable';
import * as selectors from './selectors';
import * as api from '../../api';
import * as pageSelectors from '../dynamicPage/selectors';
import * as widgetUtils from 'State/widgets/utils';
import { notifyWebdriverAllWidgetsLoaded } from 'Utils/datavizExportHelper';

const syncActionHandlers = {
    [WIDGETS_SETTINGS_SYNC]: (store, result, widgetId) => sync(api.updateWidgets, store, result, widgetId),
    [WIDGETS_SETTINGS_DATAVIZ_SYNC]: (store, result, widgetId) => sync(api.updateDataVizWidgets, store, result, widgetId)
};

const sync = (updateWidgetsRequest, { getState, dispatch }, result, widgetId) => {
    const state = getState();
    const pageType = pageSelectors.getDynamicPageTypeSelector(state);
    const syncWidgets = widgetUtils.normalizeSyncWidgets(state, [widgetId]);

    if (!syncWidgets.length) {
        return;
    }

    dispatch({
        type: WIDGETS_SETTINGS_SYNC_REQUEST
    });

    updateWidgetsRequest(pageType, syncWidgets)
        .then(
            () => {
                dispatch({
                    type: WIDGETS_SETTINGS_SYNC_SUCCESS
                });
            },
            error => {
                dispatch({
                    type: WIDGETS_SETTINGS_SYNC_FAILURE,
                    payload: error,
                    error: true
                });
            });
};

export const widgetSettingsMiddleware = store => next => action => {
    const result = next(action); // eslint-disable-line callback-return
    const handler = syncActionHandlers[action.type];

    if (_.isFunction(handler)) {
        handler(store, result, _.get(action, 'payload.widgetId'));
    }

    return result;
};

export const widgetsReadyForPrintMiddleware = store => next => action => {
    const result = next(action); // eslint-disable-line

    if ((action.type === WIDGETS_SET_FETCHING
        || action.type === FETCHING_SHAREHOLDERS_FUNDS_SUCCESS
        || action.type === FETCHING_SHAREHOLDERS_POSITIONS_SUCCESS)
        && !action.payload.fetching) {
        const state = store.getState();

        if (selectors.isAllWidgetsLoaded(state)) {
            notifyWebdriverAllWidgetsLoaded();
        }
    }

    return result;
};
