import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { BaseRoute, AuthorizedRoutes } from 'Routes';

import pageRoutes from 'Constants/page-routes';
import * as pages from 'Pages';

const routes = (
    <Switch>
        <BaseRoute path={`${pageRoutes.error}/:code?`} component={pages.ErrorPage}/>
        <BaseRoute path={`${pageRoutes.errors}`} component={pages.ErrorsLogsPage}/>
        <BaseRoute path={`${pageRoutes.accessDenied}`} component={pages.AccessDeniedPage}/>
        <AuthorizedRoutes/>
        <Redirect to={`${pageRoutes.error}/404`}/>
    </Switch>
);

export default routes;
