import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';

import reportTypes from './constants/classic-as-of-date-report-types';
import ExportClassicAsOfDateReportModalView from './components/ExportClassicAsOfDateReportModalView';

import './ExportClassicAsOfDateReportModal.scss';

const ExportClassicAsOfDateReportModal = props => {
    const [items] = useState([{
        label: props.intl.formatMessage({ id: 'modals.exportClassicShareholdersReport.type.fullReport' }),
        value: reportTypes.FULL_REPORT,
        position: 'block'
    }, {
        label: props.intl.formatMessage({ id: 'modals.exportClassicShareholdersReport.type.totalsOnly' }),
        value: reportTypes.TOTALS_ONLY,
        position: 'block'
    }]);
    const [dateOptions] = useState({
        name: 'asOfDate',
        label: props.intl.formatMessage({ id: 'modals.exportClassicAsOfDateReport.asOfDate.label' }),
        isCurrentDateAvailable: false
    });
    const handleExport = useCallback(({ reportType, asOfDate }) => {
        const { hideModal, onSuccess } = props;
        const values = {
            isSummary: reportType === reportTypes.TOTALS_ONLY,
            asOfDate
        };

        hideModal();
        onSuccess(values);
    }, [props.hideModal, props.onSuccess]);

    return (<ExportClassicAsOfDateReportModalView
        intl={props.intl}
        onSubmit={props.handleSubmit}
        valid={props.valid}
        items={items}
        dateOptions={dateOptions}
        onExport={handleExport}
        hideModal={props.hideModal}/>);
};

ExportClassicAsOfDateReportModal.propTypes = {
    ...reduxFormPropTypes,
    intl: intlShape,
    hideModal: PropTypes.func,
    onSuccess: PropTypes.func
};

export default memo(ExportClassicAsOfDateReportModal);
