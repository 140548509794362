import { createSelector } from 'reselect';
import _ from 'lodash';

import * as widgetsSelectors from '../widgets/selectors';
import { getSlotsWithDefaultSelected } from './utils';
import pageTypes from 'Constants/dynamic-page-types';
import defaultSlotsSelected from 'Components/widgets/_commonComponents/RegisteredShareholderTable/constants/default-slots-selected';

const getSlotById = (state, id) => _.get(state, ['dynamicPage', 'slots', id]);

export const compatibleWidgetsSelector = state => _.get(state, 'dynamicPage.compatibleWidgets');
export const getDynamicPageTypeSelector = state => _.get(state, 'dynamicPage.type');

export const getDynamicPageStaticSlotsSelector = createSelector(
    (state) => _.get(state, ['dynamicPage', 'slots']),
    slots => {
        const staticSlots = {};

        _.forOwn(slots, (value, key) => {
            if (+key < 0) {
                staticSlots[key] = value;
            }
        });

        return staticSlots;
    }
);

export const getDynamicPageSlotsSelector = createSelector(
    (state) => _.get(state, ['dynamicPage', 'slots']),
    slots => {
        const dynamicSlots = {};

        _.forOwn(slots, (value, key) => {
            if (+key > 0) {
                dynamicSlots[key] = value;
            }
        });

        return dynamicSlots;
    }
);

export const getDynamicPageLastSlotSelector = createSelector(
    getDynamicPageSlotsSelector,
    slots => {
        const pageSlotsIds = _.map(_.keys(slots), pageSlotTd => +pageSlotTd);

        return _.max(pageSlotsIds);
    }
);

export const getDynamicPageInfoSelector = state => _.get(state, 'dynamicPage.info');
export const getDynamicPageParamsSelector = state => _.get(state, 'dynamicPage.params');
export const getDynamicPageFetchingSelector = state => _.get(state, 'dynamicPage.isFetching');
export const getDynamicPageBlockActionSelector = state => _.get(state, 'dynamicPage.blockAction');

export const getSlotByIdSelector = createSelector(
    getSlotById,
    slot => slot
);

export const widgetTypesBySlotsSelector = createSelector(
    getDynamicPageSlotsSelector,
    widgetsSelectors.getWidgetsSelector,
    (slots, widgets) => {
        return _.mapValues(slots, slot => ({
            widgets: _.map(slot.widgets, widgetId => _.get(widgets, `${widgetId}.type`))
        }));
    }
);

export const prePopulatedWidgetTypesBySlotsSelector = createSelector(
    getDynamicPageSlotsSelector,
    widgetsSelectors.getWidgetsSelector,
    (slots, widgets) => {
        const prePopulatedWidgetTypes = [];

        _.forIn(slots, (slot) => {
            if (slot.isLocked) {
                slot.widgets.forEach(widgetId => {
                    prePopulatedWidgetTypes
                        .push(_.get(widgets, `${widgetId}.type`));
                });
            }
        });
        return prePopulatedWidgetTypes;
    }
);

export const makeAvailableWidgetTypesSelector = (slotId) => {
    return createSelector(
        widgetTypesBySlotsSelector,
        compatibleWidgetsSelector,
        prePopulatedWidgetTypesBySlotsSelector,
        (slotsWidgetsTypes, compatibleWidgets, prePopulatedWidgetTypes) => {
            const currentSlotWidgetsTypes = slotsWidgetsTypes[slotId];
            const filteredCompatibleWidgets = compatibleWidgets.filter(widgetType => !_.includes(prePopulatedWidgetTypes, widgetType));
            const selectedWidgets = _.reduce(slotsWidgetsTypes, (ret, slot, id) => {
                return `${slotId}` !== `${id}` ? _.uniq([...ret, ...slot.widgets]) : ret;
            }, []);

            return _.map(filteredCompatibleWidgets, (widgetType) => ({
                name: widgetType,
                // is selected means that widget is selected in current or another slot
                isSelected: _.includes(selectedWidgets, widgetType) || _.includes(currentSlotWidgetsTypes.widgets, widgetType)
            }));
        });
};

export const makeAvailableWidgetTypesForDynamicSlotsSelector = () => {
    return createSelector(
        widgetTypesBySlotsSelector,
        compatibleWidgetsSelector,
        prePopulatedWidgetTypesBySlotsSelector,
        (slotsWidgetsTypes, compatibleWidgets, prePopulatedWidgetTypes) => {
            const filteredCompatibleWidgets = compatibleWidgets.filter(widgetType => !_.includes(prePopulatedWidgetTypes, widgetType));
            const selectedWidgets = [];

            _.forEach(slotsWidgetsTypes, slot => {
                _.forEach(slot.widgets, widget => {
                    selectedWidgets.push(widget);
                });
            });

            return _.map(filteredCompatibleWidgets, (widgetType) => ({
                name: widgetType,
                // is selected means that widget is selected in current or another slot
                isSelected: _.includes(selectedWidgets, widgetType)
            }));
        });
};

export const getSlotsForRegisteredOverviewSelector = createSelector(
    state => _.get(state, ['dynamicPage', 'slots'], []),
    widgetsSelectors.getWidgetsSelector,
    getDynamicPageTypeSelector,
    (slots, widgets, pageType) => getSlotsWithDefaultSelected(slots, widgets, defaultSlotsSelected[pageType])
);

export const getSlotsForRegisteredOverviewLayoutSelector = createSelector(
    widgetsSelectors.getWidgetsSelector,
    (state, slots) => slots,
    (widgets, slots) => {
        const selectedSlots = getSlotsWithDefaultSelected(
            slots,
            widgets,
            defaultSlotsSelected[pageTypes.REGISTERED_OVERVIEW]
        );
        const slotsArray = Object.keys(selectedSlots).map((key) => selectedSlots[key]);
        const firstElement = slotsArray.shift();
        const selectedSlot = slotsArray.find((slot) => slot.isSelected);

        return [firstElement, selectedSlot];
    }
);

export const getSlotsForCompareShareholdersLayoutSelector = createSelector(
    widgetsSelectors.getWidgetsSelector,
    (state, slots) => slots,
    (widgets, slots) => {
        const selectedSlots = getSlotsWithDefaultSelected(
            slots,
            widgets,
            defaultSlotsSelected[pageTypes.COMPARE_SHAREHOLDERS]
        );
        const slotsArray = Object.keys(selectedSlots).map((key) => selectedSlots[key]);
        const selectedSlot = slotsArray.find((slot) => slot.isSelected);

        return [selectedSlot];
    }
);

export const getSlotsForRegisteredActionPanelSelector = createSelector(
    getSlotsForRegisteredOverviewSelector,
    widgetsSelectors.getWidgetsSelector,
    (slots, widgets) => {
        // Map slots array-like object to array
        // than we remove first element because
        // it always is left aside fixed widget
        return _.map(_.values(slots).slice(1), (slot) => {
            const slotWidget = _.find(widgets, (widget) => widget.widgetId === (_.get(slot, 'widgets[0]'))) || {};

            return {
                widgetType: slotWidget.type,
                widgetId: slotWidget.widgetId,
                isSelected: slot.isSelected
            };
        });
    }
);

export const getDynamicPageSlotsTypeSelector = state => _.get(state, 'dynamicPage.settings.isDynamicSlots', false);
export const isDataVizDynamicPage = state => _.get(state, 'dynamicPage.settings.isDataViz', false);
export const isPrintModeDynamicPage = state => _.get(state, 'dynamicPage.settings.isPrintMode', false);

export const getReportTemplateIdSelector = createSelector(
    getDynamicPageParamsSelector,
    pageParams => _.get(pageParams, 'reportTemplateId')
);

export const isDataVizStartedTemplateSelector = createSelector(
    getReportTemplateIdSelector,
    reportTemplateId => reportTemplateId === null
);

export const getWidgetsBySlotsSelector = createSelector(
    state => _.get(state, 'dynamicPage.slots'),
    widgetsSelectors.getWidgetsSelector,
    (slots, widgets) => {
        const slotsWithWidgets =  _.omitBy(slots, slot => {
            return !_.get(slot, 'widgets[0]');
        });

        return _.map(slotsWithWidgets, (slot, key) => {
            const widgetId = _.get(slot, 'widgets[0]');
            const widget = widgets[widgetId];

            return {
                widgetId: widget.widgetId,
                type: widget.type,
                settings: widget.settings,
                params: widget.dataParams,
                position: +key
            };
        });
    }
);

const DEFAULT_SELECTED_SLOT = 4;
const DYNAMIC_WIDGET_SLOTS = [1, 2, 3];

export const shareholderDynamicSlotsSelector = createSelector(
    getDynamicPageSlotsSelector,
    (slots) => {
        return _.pick(slots, DYNAMIC_WIDGET_SLOTS);
    }
);

export const shareholderStaticSlotsSelector = createSelector(
    widgetsSelectors.getWidgetsSelector,
    getDynamicPageSlotsSelector,
    (widgets, slots) => {
        return getSlotsWithDefaultSelected(
            _.omit(slots, DYNAMIC_WIDGET_SLOTS),
            widgets,
            DEFAULT_SELECTED_SLOT
        );
    }
);
