import viewTypes from 'Constants/control-books-view-types';

export default {
    dateRange: {
        startDate: null,
        endDate: null
    },
    outstandingSharesDate: {
        date: null
    },
    shareDistributionType: viewTypes.OUTSTANDING_SHARES_DATE,
    includeWeightedData: false
};
