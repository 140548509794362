import sortDirections from 'Constants/sort-directions';

const columnNames = {
    TYPE: 'type',
    DESCRIPTION: 'description',
    NUMBER_OF_ACCOUNTS: 'numberOfAccounts',
    OS: 'os',
    OS_NO_ROUND: 'osNoRound',
    SHARES: 'shares'
};

const columns = [
    {
        columnName: columnNames.TYPE,
        minWidth: 0,
        width: 40,
        disableSort: true,
        order: 1,
        manageable: false
    },
    {
        columnName: columnNames.DESCRIPTION,
        order: 2,
        minWidth: 16,
        displayName: 'holderInvestorTypeAnalysis.description',
        manageable: false,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.NUMBER_OF_ACCOUNTS,
        order: 3,
        minWidth: 4,
        displayName: 'holderInvestorTypeAnalysis.accounts',
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.OS,
        order: 4,
        minWidth: 4,
        displayName: 'holderInvestorTypeAnalysis.os',
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.SHARES,
        order: 5,
        minWidth: 5,
        displayName: 'holderInvestorTypeAnalysis.shares',
        manageable: true,
        initialSortDirection: sortDirections.DESC
    }
];

export default {
    columnNames,
    columns
};
