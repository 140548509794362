import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.DATE_OF_SALE,
        order: 1,
        minWidth: 120,
        displayName: 'registered.shareholder.table.dateOfSale',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.GROSS_AMOUNT,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.grossAmount',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.FEDERAL_TAX,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.federalTax',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.SHARES,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.shares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    }
];

export const secondarySorting = {
    field: columnNames.SHARES,
    direction: sortDirections.DESC
};
