import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import {
    commonWidgetPropTypes,
    commonWidgetActions,
    getWidgetFooterPropsWithSignClass
} from '../utils';
import Widget from '../Widget';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import AnalysisTurnoverContent from './components/AnalysisTurnoverContent';
import AnalysisTurnoverHeader from './components/AnalysisTurnoverHeader';
import FooterWithInfo from '../_commonComponents/FooterWithInfo';
import messageType from 'Constants/message-types';

import './AnalysisTurnover.scss';

const TOTAL_PERCENT = 100;

class AnalysisTurnover extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetContent: AnalysisTurnoverContent,
            WidgetFooter: FooterWithInfo,
            WidgetHeader: AnalysisTurnoverHeader
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'analysis.turnover.title' });
    }

    getWidgetFooterProps = (widgetData) => ({
        rightSide: [
            {
                messageKey: 'widgets.totalOver100',
                type: messageType.MORE_THAN_100_PERCENTS,
                hidden: !(_.get(widgetData, 'aggregate.osTotal') > TOTAL_PERCENT)
            }
        ]
    });

    render() {
        const widgetFooterProps = getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(this.props.widgetData));
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-chart',
                widgetTableTypeIcon: 'font-icon-chart deactivated'
            },
            widgetFooterProps,
            widgetNoData: !_.get(this.props, 'widgetData.values.length')
        };

        return (
            <Widget
                {...props}
                className='widget-analysis-turnover'
                components={this.widgetComponents}/>
        );
    }
}

export default AnalysisTurnover;
