import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import WidgetPrint from 'Components/widgets/Widget/components/WidgetPrint';
import Icon from 'Components/Icon';

const TotalOutstandingSharesControlPanel = props => {
    const { onExport, widgetNoData } = props;
    const exportIconClass = cn('font-icon font-icon-download', { inactive: widgetNoData });

    return (
        <div className='widget-control-panel'>
            <WidgetPrint
                widgetType='outstandingSharesTable'/>
            <Icon
                className={exportIconClass}
                onClick={!widgetNoData ? onExport : null}/>
        </div>
    );
};

TotalOutstandingSharesControlPanel.propTypes = {
    onExport: PropTypes.func,
    widgetNoData: PropTypes.bool
};

export default TotalOutstandingSharesControlPanel;
