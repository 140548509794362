import React from 'react';
import { PropTypes } from 'prop-types';

import './AppVersionView.scss';

const AppVersionView = props => {
    const { appVersion: { feVersion, beVersion, jsonSchemaVersion } } = props;

    return window.SHOW_APP_VERSION ?
        <div className='app-version'>
            <span className='layer'>FE version: </span>
            <span className='version'>{feVersion}</span>
            <span className='layer'> | BE version: </span>
            <span className='version'>{beVersion}</span>
            <span className='layer'> | JSON schema version: </span>
            <span className='version'>{jsonSchemaVersion}</span>
        </div>
        : null;
};

AppVersionView.propTypes = {
    appVersion: PropTypes.object
};

export default AppVersionView;
