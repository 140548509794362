import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { propTypes as reduxFormPropTypes, reduxForm } from 'redux-form';
import { withState } from '@shakacode/recompose';
import { get } from 'lodash';

import { inputDateRangeValidation } from 'Components/InputDateRangePicker';
import ExportHistoricalAnalysisModalView from '../components/ExportHistoricalAnalysisModalView';
import fileFormats from 'Constants/export-report-formats';
import reportTypes from 'Constants/report-types';
import exportViewTypes from '../constants/export-view-types';
import formFields from '../constants/form-fields';
import { runReport } from 'State/exportReports';

const FORM_NAME = 'historicalAnalysisExportForm';
const INITIAL_VALUES = {
    selectedFormat: fileFormats.EXCEL,
    selectedViewType: exportViewTypes.DISPLAYED,
    startDate: null,
    endDate: null
};
const reportTemplateId = null;
const mapDispatchToProps = { runReport };

@withState(
    'isDateRangeDisabled',
    'setDateRangeDisabled',
    true
)
@injectIntl
@connect(null, mapDispatchToProps)
@reduxForm({
    form: FORM_NAME,
    initialValues: INITIAL_VALUES,
    validate: (values, props) => {
        const selectedViewType = get(values, 'selectedViewType');
        const shouldValidateDate = selectedViewType === exportViewTypes.DATE_RANGE;

        return {
            dateRange: shouldValidateDate && inputDateRangeValidation(values.dateRange, { isRequired: true }, props.dateParams) // [TODO]: refactor to options
        };
    }
})
class ExportHistoricalAnalysisModal extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        hideModal: PropTypes.func,
        exportParams: PropTypes.shape({
            selectedShareholders: PropTypes.array.isRequired,
            shareholderIds: PropTypes.arrayOf(PropTypes.string).isRequired
        }),
        dateParams: PropTypes.shape({
            minDate: PropTypes.object,
            maxDate: PropTypes.object
        }),
        runReport: PropTypes.func,
        isDateRangeDisabled: PropTypes.bool,
        setDateRangeDisabled: PropTypes.func
    };

    handleChangeViewType = (e) => {
        const { setDateRangeDisabled, change, untouch } = this.props;
        const selectedViewType = e.target.value;
        const isDisplayedSelected = selectedViewType === exportViewTypes.DISPLAYED;

        setDateRangeDisabled(isDisplayedSelected);
        if (isDisplayedSelected) {
            change(formFields.START_DATE, null);
            change(formFields.END_DATE, null);
            untouch(formFields.START_DATE);
            untouch(formFields.END_DATE);
        }
    };

    handleExport = ({ selectedFormat, selectedViewType, dateRange }) => {
        const { hideModal, exportParams } = this.props;

        this.props.runReport(
            reportTypes.historicalAnalysisTemplate,
            reportTemplateId,
            selectedFormat,
            {
                ...exportParams,
                viewType: selectedViewType,
                ...dateRange
            }
        );
        hideModal();
    };

    render() {
        const { hideModal, isDateRangeDisabled, dateParams } = this.props;

        return (
            <ExportHistoricalAnalysisModalView
                {...this.props}
                onChangeTypeView={this.handleChangeViewType}
                isDateRangeDisabled={isDateRangeDisabled}
                dateParams={dateParams}
                onExport={this.handleExport}
                onCancel={hideModal}/>
        );
    }
}

export default ExportHistoricalAnalysisModal;
