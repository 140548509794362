export function bytesToSize(bytes, decimals) {
    if (bytes === 0) {
        return '0B';
    }

    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['B', 'K', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / (k ** i)).toFixed(dm)) + sizes[i];
}
