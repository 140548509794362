import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Accordion from 'Components/Accordion';
import { ADDRESS_TYPES } from 'Constants/registered-shareholder-addresses';

const AddressesView = (props) => {
    const { data = [], changeOtherAddressesStatus, intl: { formatMessage }, badAddress } = props;
    const unknownAddressMessage = formatMessage({ id: 'registered.shareholder.information.address_type.unknownAddress' });

    const mappedAddresses = data.map((address, i) => {
        return (
            <div
                key={i}>
                <h3 className='address-type'>
                    <FormattedMessage
                        id={'registered.shareholder.information.address'}
                        values={{ type: address.type.toLowerCase() }}
                        defaultMessage={unknownAddressMessage}/>
                </h3>
                <div className='address-container'>
                    <div>{address.address}</div>
                    <div>{address.city} {address.state} {address.zip}</div>
                </div>
            </div>
        );
    });


    const mainAddressPosition = data.findIndex((address) => {
        return address.type.toUpperCase() === ADDRESS_TYPES.REGISTERED;
    });
    const mainAddress = mainAddressPosition !== -1 ? mappedAddresses.splice(mainAddressPosition, 1) : null;

    const otherAddresses = mappedAddresses.length > 0 ? (
        <div className='widget-accordion'>
            <Accordion
                title={<FormattedMessage id='registered.shareholder.information.other_addresses'/>}
                onChangeStatus={changeOtherAddressesStatus}
                open={false}>
                {mappedAddresses}
            </Accordion>
        </div>
    ) : null;

    return (
        <div>
            {mainAddress}
            {badAddress &&
                <div className='bad-address-container'>
                    <FormattedMessage id='registered.shareholder.information.badAddress'>
                        {message => <span className='title'>{message}: </span>}
                    </FormattedMessage>
                    <FormattedMessage id='common.yes'/>
                </div>
            }
            {otherAddresses}
        </div>
    );
};

AddressesView.propTypes = {
    data: PropTypes.array,
    changeOtherAddressesStatus: PropTypes.func,
    intl: intlShape,
    badAddress: PropTypes.bool
};

export default injectIntl(AddressesView);
