import _ from 'lodash';

import suggestionsTypes from 'Constants/suggestions';

const normalizeOption = (data = {}) => {
    let label;

    switch (data.suggestionType) {
        case suggestionsTypes.institutionsTable:
        case suggestionsTypes.contactsTable:
            label = data.suggestion.name;
            break;
        default:
            label = data.name;
    }

    return {
        value: data.suggestion.id,
        label
    };
};

const getSuggestionsNormalize = (response) => {
    const results = _.get(response, 'data.payload.data');
    const options = _.map(results.values, (suggestion) => normalizeOption({ suggestion, suggestionType: results.suggestionType }));

    return options;
};

export default getSuggestionsNormalize;
