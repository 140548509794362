import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { getUserTreasuryAccessSelector } from 'State/user';
import { handleTreasurySearchRequest } from 'State/advancedSearch';

import './TreasuryAccountsLink.scss';

const TreasuryAccountsLink = ({ hasTreasuryAccess, handleTreasurySearchRequest: treasurySearch }) => {
    if (!hasTreasuryAccess) {
        return null;
    }

    const handleClick = (e) => {
        e.preventDefault();

        treasurySearch();
    };

    return (
        <FormattedMessage id='navigation.ownership.treasuryAccounts'>
            {(txt) => <a href='#' className='treasury-accounts-link' onClick={handleClick}>{txt}</a>}
        </FormattedMessage>
    );
};

TreasuryAccountsLink.propTypes = {
    hasTreasuryAccess: PropTypes.bool,
    handleTreasurySearchRequest: PropTypes.func
};

const mapStateToProps = (state) => ({
    hasTreasuryAccess: getUserTreasuryAccessSelector(state)
});

const mapDispatchToProps = {
    handleTreasurySearchRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(TreasuryAccountsLink);
