import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import ContactDetailsHeader from './components/ContactDetailsHeader';
import ContactDetailsControlPanel from './components/ContactDetailsControlPanel';
import ContactDetailsContent from './components/ContactDetailsContent';

import './ContactDetails.scss';

@withRouter
@injectIntl
@withProps(props => ({
    widgetTitle: props.intl.formatMessage({ id: 'contact.details.title' }),
    events: {
        onBuildDataParams: () => ({
            shareholderId: _.get(props, 'widgetPageParams.shareholderId'),
            contactId: _.get(props, 'widgetPageParams.contactId')
        })
    },
    components: {
        WidgetHeader: ContactDetailsHeader,
        WidgetControlPanel: props.isPrintMode ? null : ContactDetailsControlPanel,
        WidgetContent: ContactDetailsContent
    },
    widgetHeaderCompanySettings: {
        hideCompany: true
    }
}))
class ContactDetails extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        match: PropTypes.object,
        location: PropTypes.object
    };

    render() {
        return (
            <Widget
                {...this.props}
                redirectOn404={!this.props.isDataViz && !this.props.isPrintMode}
                className='widget-contact-details'/>
        );
    }
}

export default ContactDetails;
