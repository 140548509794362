import { createSelector } from 'reselect';
import _ from 'lodash';
import { matchPath } from 'react-router-dom';

import reportPaths from 'Constants/report-paths';
import reportTypes from 'Constants/report-types';

const reportsSelector = state => _.get(state, 'savedReports.reports');
const pathnameSelector = (state, location) => _.get(location, 'pathname');

const isActive = (pathname, report) => {
    if (report.type === reportTypes.dataVizStartedTemplate) {
        return pathname === reportPaths[reportTypes.dataVizTemplate];
    }

    const match = matchPath(pathname, {
        path: `${reportPaths[report.type]}/:id`,
        exact: true,
        strict: false
    });
    const templateId = _.get(match, 'params.id');

    return _.includes(pathname, reportPaths[report.type]) && templateId === report.reportTemplateId.toString();
};

const getReportsSelector = createSelector(
    reportsSelector,
    pathnameSelector,
    (reports, pathname) => {
        if (reports && reports.length) {
            return _.map(reports, report => ({
                ...report,
                isActive: isActive(pathname, report)
            }));
        }
        return [];
    }
);

export const getSavedReportsSelector = createSelector(
    getReportsSelector,
    reports => reports.filter(report => report.type !== reportTypes.dataVizStartedTemplate)
);

export const getDataVizStartedSelector = createSelector(
    getReportsSelector,
    reports => _.find(reports, { type: reportTypes.dataVizStartedTemplate })
);

export const getReportByIdSelector = createSelector(
    getReportsSelector,
    (state, location, reportTemplateId) => reportTemplateId,
    (reports, reportTemplateId) => _.find(reports, { reportTemplateId })
);

export const getFetchingStatusSelector = state => _.get(state, 'savedReports.isFetching');

export const getIsFetchingUsersSelector = state => _.get(state, 'savedReports.usersIsFetching', false);

export const getUsersSelector = state => _.get(state, 'savedReports.users', []);

export const getShareReportIsFetching = state => _.get(state, 'savedReports.shareReportTemplateIsFetching', false);
