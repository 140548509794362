export default {
    // Widgets
    PERFORMANCE_CHART: 'PERFORMANCE_CHART', // Performance Big
    PERFORMANCE_WIDGET: 'PERFORMANCE_WIDGET', // // Performance Small
    // [obsolete] RSI_CHART: 'RSI_CHART',
    // [obsolete] VOLUME_CHART: 'VOLUME_CHART',
    FULL_QUOTE: 'FULL_QUOTE',
    OVERALL_OWNERSHIP_STATISTICS: 'OVERALL_OWNERSHIP_STATISTICS',
    ACTIVE_VS_PASSIVE: 'ACTIVE_VS_PASSIVE',
    ACTIVITY_TREND: 'ACTIVITY_TREND',
    ANALYSIS_INVESTOR_TYPE: 'ANALYSIS_INVESTOR_TYPE',
    ANALYSIS_HOLDING_STYLE: 'ANALYSIS_HOLDING_STYLE',
    ANALYSIS_MANAGER_STYLE: 'ANALYSIS_MANAGER_STYLE',
    ANALYSIS_CAP_GROUP: 'ANALYSIS_CAP_GROUP',
    ANALYSIS_TURNOVER: 'ANALYSIS_TURNOVER',
    ANALYSIS_GEOGRAPHIC_COUNTRY: 'ANALYSIS_GEOGRAPHIC_COUNTRY',
    ANALYSIS_GEOGRAPHIC_METRO: 'ANALYSIS_GEOGRAPHIC_METRO',
    ANALYSIS_GEOGRAPHIC_STATE: 'ANALYSIS_GEOGRAPHIC_STATE',
    REGISTERED_HOLDER_TYPE: 'REGISTERED_HOLDER_TYPE',
    SAVED_REPORTS: 'SAVED_REPORTS',
    NOTES: 'NOTES',
    // TA widgets
    CORPORATE_SHAREHOLDERS: 'CORPORATE_SHAREHOLDERS',
    OUTSTANDING_PAYMENTS: 'OUTSTANDING_PAYMENTS',
    SHAREHOLDER_ELECTIONS: 'SHAREHOLDER_ELECTIONS',
    TAX_CERTIFICATION_STATUS: 'TAX_CERTIFICATION_STATUS',
    DATE_LAST_CONTACT: 'DATE_LAST_CONTACT',
    AUTHORIZED_SHARES: 'AUTHORIZED_SHARES',
    AUTHORIZED_SHARES_DATE_SELECTION: 'AUTHORIZED_SHARES_DATE_SELECTION',
    BAD_ADDRESSES: 'BAD_ADDRESSES',
    // Institution & Fund Overview widgets
    SHAREHOLDER_ANALYSIS_TOP_TEN_SECTORS: 'SHAREHOLDER_ANALYSIS_TOP_TEN_SECTORS', // Top Sectors
    SHAREHOLDER_ANALYSIS_TOP_TEN_COUNTRY: 'SHAREHOLDER_ANALYSIS_TOP_TEN_COUNTRY', // Distribution by Country
    SHAREHOLDER_ANALYSIS_INDUSTRY: 'SHAREHOLDER_ANALYSIS_INDUSTRY', // Top Industries
    INSTITUION_OVERVIEW_CAP_GROUP: 'INSTITUION_OVERVIEW_CAP_GROUP', // mistype on BE (or DA) - Cap Group Distribution
    // TODO: Add Buys and Sells

    // Global Search
    GLOBAL_SEARCH: 'GLOBAL_SEARCH',
    GLOBAL_SEARCH_CONTACTS: 'GLOBAL_SEARCH_CONTACTS',
    GLOBAL_SEARCH_FUNDS: 'GLOBAL_SEARCH_FUNDS',
    GLOBAL_SEARCH_INSIDERS: 'GLOBAL_SEARCH_INSIDERS',
    GLOBAL_SEARCH_INSTITUTIONS: 'GLOBAL_SEARCH_INSTITUTIONS',
    GLOBAL_SEARCH_REGISTERED: 'GLOBAL_SEARCH_REGISTERED',

    // Advanced search
    ADVANCED_SEARCH_KEYWORDS: 'ADVANCED_SEARCH_KEYWORDS',
    ADVANCED_SEARCH_SSN: 'ADVANCED_SEARCH_SSN',
    ADVANCED_SEARCH_CERT: 'ADVANCED_SEARCH_CERT',

    // Pages
    HOME: 'HOME',
    OWNERSHIP_SHAREHOLDERS: 'OWNERSHIP_SHAREHOLDERS',
    HISTORICAL_ANALYSIS: 'HISTORICAL_ANALYSIS',
    PEER_ANALYSIS: 'PEER_ANALYSIS',
    REGISTERED_OVERVIEW: 'REGISTERED_OVERVIEW',
    INSTITUTION_OVERVIEW: 'SHAREHOLDER_INSTITUTION_OVERVIEW',
    FUND_OVERVIEW: 'FUND_OVERVIEW',
    INSIDER_OVERVIEW: 'INSIDER_OVERVIEW',
    CONTACTS: 'CONTACTS',
    ISSUER_RESOURCES: 'ISSUER_RESOURCES',
    MEETING_PLANNER: 'MEETING_PLANNER',
    // [obsolete] USER_PROFILER: 'USER_PROFILER',

    // Registered overview widgets
    TAX_LOTS_CURRENT: 'TAX_LOTS_CURRENT',
    TAX_LOTS_PRIOR_TA_DRS_HISTORY: 'TAX_LOTS_PRIOR_TA_DRS_HISTORY',
    TAX_LOTS_CLOSED_SALE_TYPE: 'TAX_LOTS_CLOSED_SALE_TYPE',
    TAX_LOTS_CLOSED_OTHER_TYPE: 'TAX_LOTS_CLOSED_OTHER_TYPE',
    UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',

    // Reporting pages
    CUSTOM_REPORTS: 'CUSTOM_REPORTS', // DataViz Report
    SHAREHOLDER_LIST: 'SHAREHOLDER_LIST',
    HOLDING_INVESTOR_TYPE_ANALYSIS: 'HOLDING_INVESTOR_TYPE_ANALYSIS',
    SHARE_RANGE_ANALYSIS_RESULTS: 'SHARE_RANGE_ANALYSIS_RESULTS',
    GEOGRAPHIC_ANALYSIS: 'GEOGRAPHIC_ANALYSIS',
    SHARE_DETAIL: 'SHARE_DETAIL',
    OUTSTANDING_SHARES: 'OUTSTANDING_SHARES',
    DOWNLOAD: 'DOWNLOAD',
    COMPARE_REGISTERED_SHAREHOLDERS: 'COMPARE_REGISTERED_SHAREHOLDERS',
    COMBINE_ACCOUNTS: 'COMBINE_ACCOUNTS',
    CLASSIC_SHAREHOLDER_LIST_AS_OF_DATE: 'CLASSIC_SHAREHOLDER_LIST_AS_OF_DATE',
    AUTHORIZED_USER_LIST: 'AUTHORIZED_USER_LIST',
    SCHEDULED_REPORTS: 'SCHEDULED_REPORTS',
    REPORT_TEMPLATE_SHARING: 'REPORT_TEMPLATE_SHARING',
    // Reporting sections
    SAVED_REPORTS_SECTION: 'SAVED_REPORTS_SECTION',

    // Subscription features
    TRANSFER_JOURNALS: 'TRANSFER_JOURNALS',

    // Shareholders table
    SHAREHOLDERS_TABLE_DETAILS: 'SHAREHOLDERS_TABLE_DETAILS',
    SHAREHOLDERS_TABLE_NUMBERS: 'SHAREHOLDERS_TABLE_NUMBERS',
    SHAREHOLDERS_TABLE_REGISTERED: 'SHAREHOLDERS_TABLE_REGISTERED',
    SHAREHOLDERS_TABLE_PROXY: 'SHAREHOLDERS_TABLE_PROXY',

    // Shareholders table export options
    FUNDS_VIEW: 'FUNDS_VIEW',
    POSITIONS_HISTORY_VIEW: 'POSITIONS_HISTORY_VIEW',
    CONTENTS_ONSCREEN: 'CONTENTS_ONSCREEN',

    // Top 25 Holdings table
    TOP_HOLDINGS: 'TOP_HOLDINGS',

    // Shareholders Table & List filtering
    OI_PLUS_OPTIONS: 'OI_PLUS_OPTIONS',

    // Basic navigation
    GLOBAL_LINKS: 'GLOBAL_LINKS',

    // Secure upload
    SECURE_UPLOAD: 'SECURE_UPLOAD',

    // Disable Treasury Account link
    DISABLE_TREASURY_ACCOUNT_LINK: 'DISABLE_TREASURY_ACCOUNT_LINK',
    // Watchlist
    SHAREHOLDER_WATCHLIST: 'SHAREHOLDER_WATCHLIST',

    // Recent Search
    RECENT_SEARCH: 'RECENT_SEARCH'
};
