import { CONTEXTS } from 'Constants/search';
import shareholderTypes from 'Constants/shareholder-types';
import featureTypes from 'Constants/feature-types';

export const resultFields = {
    [CONTEXTS.REGISTERED]: [
        'name', 'acctnum', 'address.residence', 'status'
    ],
    [CONTEXTS.INSTITUTIONS]: [
        'name', 'address.residence', 'address.city', 'address.state', 'address.country', 'address.zip', 'position'
    ],
    [CONTEXTS.FUNDS]: [
        'name', 'address.residence', 'address.city', 'address.state', 'address.country', 'address.zip', 'position'
    ],
    [CONTEXTS.INSIDERS]: [
        'name', 'address.residence', 'address.city', 'address.state', 'address.country', 'address.zip', 'position'
    ],
    [CONTEXTS.CONTACTS]: [
        'name', 'companyName', 'address.residence', 'address.city', 'address.state', 'address.country', 'address.zip', 'phone', 'email'
    ]
};

export const contextToDetailsPageType = {
    [CONTEXTS.REGISTERED]: shareholderTypes.REGISTERED,
    [CONTEXTS.INSTITUTIONS]: shareholderTypes.INSTITUTION,
    [CONTEXTS.FUNDS]: shareholderTypes.FUND,
    [CONTEXTS.INSIDERS]: shareholderTypes.INSIDER,
    [CONTEXTS.CONTACTS]: 'contact'
};

export const contextToFeatureType = {
    [CONTEXTS.REGISTERED]: featureTypes.REGISTERED_OVERVIEW,
    [CONTEXTS.INSTITUTIONS]: featureTypes.INSTITUTION_OVERVIEW,
    [CONTEXTS.FUNDS]: featureTypes.FUND_OVERVIEW,
    [CONTEXTS.INSIDERS]: featureTypes.INSIDER_OVERVIEW,
    [CONTEXTS.CONTACTS]: featureTypes.CONTACTS
};
