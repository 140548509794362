import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';
import shareholderType from 'Constants/shareholder-types';
import pageRoutes from 'Constants/page-routes';
import { abbreviationKeys } from 'Constants/number-abbreviations';
import { NullableFormatter, AssetChangeFormatter, DateFormatter } from 'Components/formatters';
import { companyPropTypes } from '../../propTypes';

const CompanyInfo = (props) => {
    const {
        className,
        id,
        name,
        capGroup,
        type,
        style,
        turnover,
        avp,
        proxyAdv,
        sector,
        industry,
        region,
        yearInc,
        reportedAUMValue,
        reportedAUMDate
    } = props;

    return (
        <div className={className}>
            <h3 className='title'><LinkEx to={`${pageRoutes[shareholderType.INSTITUTION]}/${id}`}>{name}</LinkEx></h3>
            <table className='company-info-table'>
                <tbody>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.capGroup'/>:</td><td><NullableFormatter value={capGroup}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.type'/>:</td><td><NullableFormatter value={type}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.style'/>:</td><td><NullableFormatter value={style}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.turnover'/>:</td><td><NullableFormatter value={turnover}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.avp'/>:</td><td><NullableFormatter value={avp}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.proxyAdv'/>:</td><td><NullableFormatter value={proxyAdv}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.sector'/>:</td><td><NullableFormatter value={sector}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.industry'/>:</td><td><NullableFormatter value={industry}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.region'/>:</td><td><NullableFormatter value={region}/></td></tr>
                    <tr className='company-info-tr'><td><FormattedMessage id='shareholder.information.yearInc'/>:</td><td><NullableFormatter value={yearInc}/></td></tr>
                </tbody>
            </table>
            <span className='aum-label'><FormattedMessage id='shareholder.information.reportedAUM'/>: </span>
            <AssetChangeFormatter
                value={reportedAUMValue}
                valueFormat={abbreviationKeys.thousands}
                withSymbol
                separately/>{' '}
            (<DateFormatter value={reportedAUMDate}/>)
        </div>
    );
};

CompanyInfo.propTypes = {
    ...companyPropTypes,
    className: PropTypes.string
};

export default CompanyInfo;
