import PropTypes from 'prop-types';
import React from 'react';
import Highcharts from 'highcharts';
import ReactHighcharts from 'highcharts-react-official';
import { AutoSizer } from 'react-virtualized';
import cn from 'classnames';
import _ from 'lodash';

import './PieChart.scss';

/*
 * helper for correct displaying min sector's value for pie chart
 * fix for ASTTSTRT-7777
 */
const getSectorValue = (value, total) => Math.ceil((value / total) * 100);

const PieChart = (props) => {
    const { data, className, total, customConfig = {} } = props;
    const classNames = cn('pie-chart', className);
    const formattedData = data && data.map((point) => ({
        ...point,
        y: getSectorValue(point.y, total)
    })).filter(point => point.y > 0);

    const defaultConfig = {
        rangeSelector: {
            enabled: false,
            inputEnabled: false
        },
        navigator: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                dataLabels: {
                    enabled: false
                },
                borderWidth: 1,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        chart: {
            backgroundColor: 'rgba(225, 225, 225, 0)',
            plotBackgroundColor: 'rgba(225, 225, 225, 0)',
            plotBorderWidth: null,
            plotShadow: false,
            animation: false
        },
        title: {
            text: null
        },
        tooltip: {
            enabled: false
        },
        series: [{
            type: 'pie',
            innerSize: '59%',
            colorByPoint: true,
            animation: false,
            data: formattedData,
            states: {
                inactive: {
                    opacity: 1
                }
            }
        }]
    };

    const config = _.merge({}, defaultConfig, customConfig);

    return (
        <AutoSizer>
            {({ height, width }) => {
                const size = Math.min(height, width);

                config.chart.height = `${size}px`;
                config.chart.width = width;
                config.series[0].size = `${size - 10}px`;

                return (
                    <ReactHighcharts
                        highcharts={Highcharts}
                        options={config}
                        containerProps={{ className: classNames, style: { height: size, width } }}/>
                );
            }}
        </AutoSizer>
    );
};

PieChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        y: PropTypes.number
    })),
    total: PropTypes.number.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    customConfig: PropTypes.object
};

export default PieChart;

