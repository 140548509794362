import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';

import dateFormats from 'Constants/date-formats';
import StyledCalendar from './StyledCalendar';
import { isWorkDay } from 'Utils/dateHelpers';

class InputDatePicker extends Component {
    static propTypes = {
        label: PropTypes.string,
        labelType: PropTypes.oneOf(['block', 'inline']),
        disabled: PropTypes.bool,
        showYearDropdown: PropTypes.bool,
        showMonthDropdown: PropTypes.bool,
        placeholder: PropTypes.string,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        value: PropTypes.object,
        classNames: PropTypes.string,
        checkWorkingDay: PropTypes.bool,
        isCurrentDateAvailable: PropTypes.bool,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        dateFormat: PropTypes.string
    };

    static defaultProps = {
        labelType: 'block',
        checkWorkingDay: false,
        isCurrentDateAvailable: true,
        placeholder: dateFormats.DATEPICKER_FORMAT_PLACEHOLDER,
        showYearDropdown: true,
        showMonthDropdown: true,
        dateFormat: dateFormats.DATEPICKER_FORMAT
    };

    constructor(props) {
        super(props);
        const momentValue = moment(props.value, dateFormats.SERVER_DATE_FORMAT);

        this.state = {
            value: momentValue.isValid() ? momentValue.format(this.props.dateFormat) : ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.disabled !== this.props.disabled || !nextProps.value) {
            this.setState({ value: '' });
        } else if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value ? nextProps.value.format(nextProps.dateFormat) : '' });
        }
    }

    handleChangeInput = e => {
        this.setState({ value: e.target.value });
    };

    handleSelect = (momentValue) => {
        const newValue = momentValue ? momentValue.format(this.props.dateFormat) : '';

        this.setState({ value: newValue });
        this.props.onChange(newValue);
    };

    handleBlur = () => {
        this.props.onBlur(this.state.value);
    }

    render() {
        const {
            checkWorkingDay,
            isCurrentDateAvailable,
            label,
            labelType,
            disabled,
            error,
            classNames,
            value,
            placeholder,
            dateFormat,
            ...restProps
        } = this.props;
        const labelClass = cn('date-picker-label', { disabled });
        const componentClass = cn('date-picker-component', labelType);
        const maxDate = isCurrentDateAvailable ? moment() : moment().add(-1, 'day');
        const filterDate = checkWorkingDay ? isWorkDay : null;

        return (
            <div className={componentClass}>
                {label && <span className={labelClass}>{label}</span>}
                <DatePicker
                    autoComplete='off'
                    disabledKeyboardNavigation
                    maxDate={maxDate}
                    filterDate={filterDate}
                    selected={value ? moment(value) : null}
                    {...restProps}
                    customInput={<StyledCalendar
                        onChangeInput={this.handleChangeInput}
                        error={error}
                        inputClassName={classNames}/>
                    }
                    onChange={this.handleSelect}
                    onBlur={this.handleBlur}
                    value={this.state.value}
                    disabled={disabled}
                    placeholderText={placeholder}
                    dateFormat={dateFormat}
                    fixedHeight
                    scrollableYearDropdown
                    yearDropdownItemNumber={20}
                    renderDayContents={(date) => <span>{date}</span>}/>
            </div>
        );
    }
}

export default InputDatePicker;
