import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';
import { connect } from 'react-redux';

import registeredReportTemplates from '../constants/registered-report-templates';
import reportTypes from 'Constants/report-types';
import { showExportWidgetModal } from 'State/exportReports';
import { getRegisteredOverviewTdProps } from '../utils';

const mapDispatchToProps = {
    showExportWidgetModal
};

export default WrappedComponent => {
    @connect(null, mapDispatchToProps)
    class RegisteredOverview extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'registeredOverview');

        static propTypes = {
            ...WrappedComponent.propTypes
        };

        constructor(props) {
            super(props);
            this.widgetEvents = {
                onBuildDataParams: () => ({
                    shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
                })
            };
        }

        componentDidMount() {
            const { onUpdateWidgetData, redirectOn404, widgetData, isFetching } = this.props;

            if (widgetData || isFetching) {
                return;
            }

            const widgetDataParams = _.isFunction(this.widgetEvents.onBuildDataParams) ? this.widgetEvents.onBuildDataParams({}) : {};

            onUpdateWidgetData(widgetDataParams, { redirectOn404 });
        }

        handleExport = () => {
            const { widgetSettings = {}, widgetType, widgetPageParams } = this.props;
            const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
            const selectedColumns = _.get(widgetSettings, 'selectedColumns', []);
            const reportTemplateType = reportTypes[registeredReportTemplates[widgetType]];
            const shareholderId = _.get(widgetPageParams, 'shareholderId');

            this.props.showExportWidgetModal({
                reportTemplateType,
                exportParams: {
                    shareholderId,
                    // todo: in future properties of sortCriteria object will be rename
                    // from { sortDirection: ..., sortField: ... } to { direction: ..., field: ... }
                    sortCriteria: {
                        sortDirection: sortCriteria.direction,
                        sortField: sortCriteria.field,
                        initial: sortCriteria.initial
                    },
                    columnsToDisplay: selectedColumns
                }
            });
        };

        getDefaultTdProps = columnType => ({
            ...getRegisteredOverviewTdProps(columnType)
        });

        render() {
            return (
                <WrappedComponent
                    widgetEvents={this.widgetEvents}
                    onExport={this.handleExport}
                    getDefaultTdProps={this.getDefaultTdProps}
                    {...this.props}/>
            );
        }
    }

    return RegisteredOverview;
};
