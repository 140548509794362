import { columnNames } from './constants/columnDefinitions';
import * as cellComponents from 'Components/widgets/_commonComponents/TableCellComponents';

export const getControlBooksResultCustomComponent = (columnName) => {
    switch (columnName) {
        case columnNames.AS_OF_DATE:
        case columnNames.PROCESS_DATE:
        case columnNames.BEGINNING_BALANCE_DATE:
        case columnNames.COB_DATE:
            return {
                customComponent: cellComponents.CellWithDate,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.SHARES_ISSUED:
        case columnNames.SHARES_RETIRED:
        case columnNames.ISSUED_AND_OUTSTANDING:
        case columnNames.BEGINNING_SHARE_AMOUNT:
        case columnNames.COB_SHARE_AMOUNT:
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.TRANSACTION_TYPE:
        case columnNames.TRANSACTION_NUMBER:
        case columnNames.BROKER_NO:
        case columnNames.COMMENT:
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        default:
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
    }
};

export const getControlBooksResultTdProps = (columnName) => {
    switch (columnName) {
        case columnNames.TRANSACTION_NUMBER:
        case columnNames.BROKER_NO:
        case columnNames.ACCOUNT_NUMBER:
            return {
                additionalData: {
                    alignRight: true
                }
            };
        case columnNames.SHARES_ISSUED:
        case columnNames.SHARES_RETIRED:
        case columnNames.ISSUED_AND_OUTSTANDING:
        case columnNames.BEGINNING_SHARE_AMOUNT:
        case columnNames.COB_SHARE_AMOUNT:
            return {
                additionalData: {
                    decimalPoints: 3,
                    ellipsis: true,
                    alignRight: DEMO_MODE
                }
            };
        default:
            return {
                additionalData: {
                    alignRight: DEMO_MODE
                }
            };
    }
};
