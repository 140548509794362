import _ from 'lodash';

import * as api from '../../api/page';
import pagesTypes from 'Constants/dynamic-page-types';
import { getTotalOutstandingSharesByDate } from '../../api/outstandingShares';

export const REPORTING_GET_OUTSTANDING_SHARES_FETCHING = 'REPORTING_GET_OUTSTANDING_SHARES_FETCHING';
export const REPORTING_GET_OUTSTANDING_SHARES_SUCCESS = 'REPORTING_GET_OUTSTANDING_SHARES_SUCCESS';
export const REPORTING_GET_OUTSTANDING_SHARES_ERROR = 'REPORTING_GET_OUTSTANDING_SHARES_ERROR';
export const REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_FETCHING = 'REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_FETCHING';
export const REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_SUCCESS = 'REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_SUCCESS';
export const REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_ERROR = 'REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_ERROR';
export const CONTROL_BOOKS_DATA_TYPE_CHANGE = 'CONTROL_BOOKS_DATA_TYPE_CHANGE';

export function getOutstandingShares() {
    return dispatch => {
        dispatch({
            type: REPORTING_GET_OUTSTANDING_SHARES_FETCHING
        });

        return api.getPage(pagesTypes.REPORTING_CONTROL_BOOKS_SEARCH)
            .then(res => {
                dispatch({
                    type: REPORTING_GET_OUTSTANDING_SHARES_SUCCESS,
                    payload: _.get(res, 'data.payload.page.info', {})
                });
                return res;
            })
            .catch(error => {
                dispatch({
                    type: REPORTING_GET_OUTSTANDING_SHARES_ERROR,
                    payload: error
                });
                return Promise.reject(error);
            });
    };
}

export const getOutstandingSharesByCOBDate = (closeOfBusinessDate, securities) => dispatch => {
    dispatch({
        type: REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_FETCHING
    });
    return getTotalOutstandingSharesByDate(closeOfBusinessDate, securities)
        .then(res => {
            dispatch({
                type: REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_SUCCESS,
                payload: _.get(res, 'data.payload')
            });
        },
        error => {
            dispatch({
                type: REPORTING_GET_OUTSTANDING_SHARES_BY_DATE_ERROR,
                payload: { error },
                error: true
            });

            return Promise.reject(error);
        });
};

export const changeDataType = type => ({
    type: CONTROL_BOOKS_DATA_TYPE_CHANGE,
    payload: type
});
