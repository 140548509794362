import React, { Component } from 'react';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { MillionChangeFormatter, NullableFormatter } from 'Components/formatters';
import TableEasy from 'Components/Tables/TableEasy';
import { mapBarValuesToData } from 'Components/Tables/TableEasy/utils';
import widgetModes from 'Constants/widget-modes';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import columnNames from '../../constants/column-names';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';

@mapProps(props => {
    return {
        ...props,
        data: mapBarValuesToData(props.values, 'os'),
        hideBarChart: _.get(props, 'widgetSettings.mode') === widgetModes.TABLE,
        aggregate: {
            osTotal: _.get(props, 'widgetData.aggregate.osTotal'),
            changeTotal: _.get(props, 'widgetData.aggregate.changeTotal')
        }
    };
})
class CapGroupContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    constructor(props) {
        super(props);

        const { formatMessage } = this.props.intl;
        const { columnTitles } = this.props;

        this.columns = [
            {
                dataKey: columnNames.CAP_GROUP,
                title: formatMessage({ id: columnTitles[columnNames.CAP_GROUP] }),
                bodyCellComponent: v => <NullableFormatter {...v} withTitle/>
            },
            {
                dataKey: columnNames.OS,
                title: formatMessage({ id: columnTitles[columnNames.OS] }),
                maxWidth: 70,
                columnClassName: 'text-right',
                bodyCellComponent: TotalValueOsCell
            },
            {
                dataKey: columnNames.CHANGE,
                title: formatMessage({ id: columnTitles[columnNames.CHANGE] }),
                columnClassName: 'text-right',
                bodyCellComponent: MillionChangeFormatter
            }
        ];
    }

    render() {
        const { data, widgetFooterProps, hideBarChart, aggregate, widgetNoData, isFetching, intl: { formatMessage }, isDataViz } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        widgetFooterProps={widgetFooterProps}
                        noDataMessage={formatMessage({ id: 'widgets.noDataMessage' })}
                        className='widget-table'
                        hideBarChart={hideBarChart}
                        columns={this.columns}
                        data={data}
                        renderAllRows={isDataViz}
                        aggregate={aggregate}/>
                </NoDataComponent>
            </div>
        );
    }
}

export default CapGroupContent;
