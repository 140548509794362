import React from 'react';
import PropTypes from 'prop-types';

import WidgetMenu from '../WidgetMenu';
import Icon from 'Components/Icon';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';

const WidgetChange = (props) => {
    const { availableWidgets, onChangeWidget, onRemoveWidget, isLocked, blockAction, slotProps } = props;

    return (
        !isLocked && (blockAction
            ? <Icon className='font-icon font-icon-view inactive'/>
            : <WidgetMenu
                triggerElement={<Icon className='font-icon font-icon-view'/>}
                widgets={availableWidgets}
                onSelectWidget={onChangeWidget}
                onRemoveWidget={onRemoveWidget}
                slotProps={slotProps}/>)

    );
};

WidgetChange.propTypes = {
    ...commonWidgetActions,
    ...commonWidgetPropTypes,
    availableWidgets: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            isSelected: PropTypes.bool.isRequired
        })
    ).isRequired,
    slotProps: PropTypes.object.isRequired
};

export default WidgetChange;
