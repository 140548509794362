import * as api from '../../api';
import _ from 'lodash';

import { getDataByIdSelector, getDataInfoByIdSelector } from '../dataSource/selectors';
import { dataSourceAddOrSave } from '../dataSource/actions';
import widgetTypes from 'Constants/widget-types';

export const FIND_ANALYSIS_SHAREHOLDER_REQUEST = 'FIND_ANALYSIS_SHAREHOLDER_REQUEST';
export const FIND_ANALYSIS_SHAREHOLDER_SUCCESS = 'FIND_ANALYSIS_SHAREHOLDER_SUCCESS';
export const FIND_ANALYSIS_SHAREHOLDER_FAILURE = 'FIND_ANALYSIS_SHAREHOLDER_FAILURE';
export const FIND_ANALYSIS_SHAREHOLDER_CANCEL_REQUEST = 'FIND_ANALYSIS_SHAREHOLDER_CANCEL_REQUEST';
export const GET_ANALYSIS_SHAREHOLDER_DATA_REQUEST = 'GET_ANALYSIS_SHAREHOLDER_DATA_REQUEST';
export const GET_ANALYSIS_SHAREHOLDER_DATA_SUCCESS = 'GET_ANALYSIS_SHAREHOLDER_DATA_SUCCESS';
export const GET_ANALYSIS_SHAREHOLDER_DATA_FAILURE = 'GET_ANALYSIS_SHAREHOLDER_DATA_FAILURE';
export const CLEAR_FOUND_ANALYSIS_SHAREHOLDERS = 'CLEAR_FOUND_ANALYSIS_SHAREHOLDERS';

let dataCancelToken = null;

export const requestShareholderData = (shareholder, widgetType) => (dispatch) => {
    const apiCall = widgetType === widgetTypes.historicalAnalysisTable
        ? api.getHistoricalAnalysisShareholderData
        : api.getPeerAnalysisShareholderData;
    const newCancelToken = api.createCancelToken();

    if (dataCancelToken) {
        dataCancelToken.cancel();
    }
    dispatch({
        type: GET_ANALYSIS_SHAREHOLDER_DATA_REQUEST
    });

    dataCancelToken = newCancelToken;
    return apiCall(shareholder.shareholderId, newCancelToken)
        .then(
            (res) => {
                const data = _.get(res, 'data.payload.data');

                dispatch({
                    type: GET_ANALYSIS_SHAREHOLDER_DATA_SUCCESS,
                    payload: data
                });
            },
            error => {
                dispatch({
                    type: GET_ANALYSIS_SHAREHOLDER_DATA_FAILURE
                });

                return Promise.reject(error);
            }
        );
};

export const saveShareholderToData = ({ data, shareholder }, widgetType, widgetDataSourceId) => (dispatch, getState) => {
    const state = getState();
    const widgetData = getDataByIdSelector(state, widgetDataSourceId);
    const dataInfo = getDataInfoByIdSelector(state, widgetDataSourceId);

    const newWidgetData = {
        ...widgetData,
        data: [...widgetData.data, ...data],
        shareholders: widgetData.shareholders.concat({
            pinned: true, // To pin on top after the search
            ...shareholder
        })
    };

    dispatch(dataSourceAddOrSave({
        id: widgetDataSourceId,
        data: newWidgetData,
        info: dataInfo
    }));
};

let findCancelToken = null;

export const findAnalysisShareholderByName = (term) => (dispatch) => {
    const newCancelToken = api.createCancelToken();

    if (findCancelToken) {
        findCancelToken.cancel();
    }
    dispatch({
        type: FIND_ANALYSIS_SHAREHOLDER_REQUEST
    });

    findCancelToken = newCancelToken;

    return api.findAnalysisShareholderByName(term, newCancelToken)
        .then(
            res => {
                dispatch({
                    type: FIND_ANALYSIS_SHAREHOLDER_SUCCESS,
                    payload: _.get(res, 'data.payload.data')
                });

                findCancelToken = null;
            },
            error => {
                dispatch({
                    type: FIND_ANALYSIS_SHAREHOLDER_FAILURE,
                    payload: {
                        ...error,
                        error: true
                    }
                });

                return Promise.reject(error);
            }
        );
};

export const clearFoundAnalysisShareholders = () => (dispatch) => {
    dispatch({
        type: CLEAR_FOUND_ANALYSIS_SHAREHOLDERS
    });
};

export const cancelAnalysisSearchRequest = () => (dispatch) => {
    if (findCancelToken) {
        findCancelToken.cancel();
    }
    if (dataCancelToken) {
        dataCancelToken.cancel();
    }

    dispatch({
        type: FIND_ANALYSIS_SHAREHOLDER_CANCEL_REQUEST
    });
};
