import _ from 'lodash';

import { localeCurrency } from 'Constants/currency';
import { changeFormatter, dateFormatter } from '../formatters/formatters';

const Y_AXIS_OPPOSITE_DECIMAL_POINTS = 2;
const TOOLTIP_DECIMAL_POINTS = 2;

export const isChartVisible = (visibility = [], type) => {
    return _.includes(visibility, type);
};

export const tooltipFormatter = (value, intl) => {
    const point = value.points[0].point;
    const { open, close, high, low, volume } = point.additionalData;

    return `
        <div class="ast-highcharts-tooltip" style="border-color:${point.color}">
            <div>${dateFormatter(value.x)}</div>
            <div>
                ${intl.formatMessage({ id: 'performance.chart.tooltip.open' })}: ${changeFormatter(intl, open, { decimalPoints: TOOLTIP_DECIMAL_POINTS })}
            </div>
            <div>
                ${intl.formatMessage({ id: 'performance.chart.tooltip.close' })}: ${changeFormatter(intl, close, { decimalPoints: TOOLTIP_DECIMAL_POINTS })}
            </div>
            <div>
                ${intl.formatMessage({ id: 'performance.chart.tooltip.high' })}: ${changeFormatter(intl, high, { decimalPoints: TOOLTIP_DECIMAL_POINTS })}
            </div>
            <div>
                ${intl.formatMessage({ id: 'performance.chart.tooltip.low' })}: ${changeFormatter(intl, low, { decimalPoints: TOOLTIP_DECIMAL_POINTS })}
            </div>
            <div>
                ${intl.formatMessage({ id: 'performance.chart.tooltip.volume' })}: ${changeFormatter(intl, volume, { decimalPoints: 0 })}
            </div>
        </div>
    `;
};

export const yAxisOppositeFormatter = (value, intl) => {
    const formatOptions = {
        style: 'currency',
        currency: localeCurrency[intl.locale].code,
        maximumFractionDigits: Y_AXIS_OPPOSITE_DECIMAL_POINTS,
        minimumFractionDigits: Y_AXIS_OPPOSITE_DECIMAL_POINTS
    };

    return value ? intl.formatNumber(value, formatOptions) : '';
};
