import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import AppHeader from 'Components/AppHeader';
import AppFooter from 'Components/AppFooter';
import { EquinitiFooter } from 'Components/EquinitiFooter';
import { ModalRoot } from 'Components/Modals';
import { identifyBrowser } from 'Utils/browser';

import './MainLayout.scss';

@withRouter
class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.element
    };

    componentDidMount() {
        MainLayout.addBrowserInfoToBody(identifyBrowser());
    }

    static addBrowserInfoToBody(label) {
        if (!document.body.className.includes(label)) {
            document.body.className += ` ${label}`;
        }
    }

    render() {
        return (
            <div className='app'>
                <AppHeader/>
                <section className='page-main-section'>
                    <section className='page-content'>
                        {this.props.children}
                    </section>
                </section>
                {DEMO_MODE ? <EquinitiFooter/> : <AppFooter />}
                <ModalRoot/>
            </div>
        );
    }
}

export default MainLayout;
