import { createSelector } from 'reselect';
import { includes } from 'lodash';

const getAnalysisShareholdersState = (state) => state.analysisShareholders;
const getFindingShareholderAnalysisSelector = (state) => getAnalysisShareholdersState(state).searchList;
const getShareholderDataAnalysisSelector = (state) => getAnalysisShareholdersState(state).shareholderData;

export const isFetchingFindingAnalysisShareholdersSelector = (state) =>
    getFindingShareholderAnalysisSelector(state).isFetching;

export const isFetchingAnalysisShareholderDataSelector = (state) =>
    getShareholderDataAnalysisSelector(state).isFetching;

export const analysisShareholderDataSelector = (state) =>
    getShareholderDataAnalysisSelector(state).payload;

const foundAnalysisShareholdersSelector  = (state) =>
    getFindingShareholderAnalysisSelector(state).payload;

export const foundAnalysisShareholdersOptionsSelector = createSelector(
    foundAnalysisShareholdersSelector,
    (state, selectedShareholdersIds) => selectedShareholdersIds,
    (foundShareholders, selectedShareholdersIds) => {
        return foundShareholders ? foundShareholders.map(shareholder => ({
            value: shareholder,
            label: shareholder.shareholderName,
            disabled: includes(selectedShareholdersIds, shareholder.shareholderId)
        })) : undefined;
    }
);

