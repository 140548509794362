import sortDirections from 'Constants/sort-directions';

export const columnNames = {
    DATE: 'date',
    AUTHOR: 'author',
    NOTE_TEXT: 'noteText',
    DELETE: 'delete'
};

export default [
    {
        columnName: columnNames.DATE,
        order: 1,
        minWidth: 120,
        displayName: 'notes.columns.date',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.AUTHOR,
        order: 2,
        minWidth: 120,
        displayName: 'notes.columns.author',
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.NOTE_TEXT,
        disableSort: true,
        order: 3,
        minWidth: 300,
        displayName: 'notes.columns.noteText',
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.DELETE,
        disableSort: true,
        order: 4,
        width: 100,
        minWidth: 100,
        displayName: 'notes.columns.delete'
    }
];

export const secondarySorting = {
    field: columnNames.DATE,
    direction: sortDirections.DESC
};
