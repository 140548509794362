import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { Layout } from '../../layout';
import Slot from '../../Slot';

import './LayoutMultiSlots.scss';

const LayoutMultiSlots = (props) => {
    const { slots } = props;
    const renderedSlots = [];

    for (const i in slots) {
        if (slots.hasOwnProperty(i) && _.get(slots[i], 'widgets.length')) {
            renderedSlots.push(<Slot {...slots[i]} slotId={+i} key={i}/>);
        }
    }

    return (
        <Layout className='layout-multi-slots'>
            {renderedSlots}
        </Layout>
    );
};

LayoutMultiSlots.propTypes = {
    slots: PropTypes.object
};

export default LayoutMultiSlots;
