import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import RegisteredShareholderInformationContent from './components/RegisteredShareholderInformationContent';
import RegisteredOverviewControlPanel from '../_commonComponents/RegisteredOverviewControlPanel';
import { runReport } from 'State/exportReports';
import reportFormats from 'Constants/export-report-formats';
import reportTypes from 'Constants/report-types';
import { isFetchingSelector } from 'State/exportReports/selectors';

import './RegisteredShareholderInformation.scss';

const mapStateToProps = (state) => ({ isFetchingReport: isFetchingSelector(state) });
const mapDispatchToProps = { runReport };

@connect(mapStateToProps, mapDispatchToProps)
class RegisteredShareholderInformation extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        runReport: PropTypes.func.isRequired,
        isFetchingReport: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);

        this.includeOtherAddresses = false;

        this.widgetComponents = {
            WidgetControlPanel: RegisteredOverviewControlPanel,
            WidgetContent: RegisteredShareholderInformationContent
        };

        this.widgetEvents = {
            onBuildDataParams: () => ({
                shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
            })
        };
    }

    handleExport = () => {
        const { widgetPageParams, widgetType } = this.props;
        const shareholderId = _.get(widgetPageParams, 'shareholderId');

        this.props.runReport(
            reportTypes[widgetType],
            null,
            reportFormats.PDF,
            {
                shareholderId,
                includeOtherAddresses: this.includeOtherAddresses
            }
        );
    };

    getRef = com => {
        if (com) {
            this.component = com;
        }
    };

    getWidgetHeight = () => this.component && this.component.getBoundingClientRect().height;

    changeOtherAddressesStatus = (open) => {
        this.includeOtherAddresses = open;
    };

    render() {
        const props = {
            ...this.props,
            widgetControlPanelSettings: {
                hideColumnsManager: true,
                exportAvailable: true,
                onExport: this.handleExport,
                inactive: this.props.isFetchingReport,
                getWidgetHeight: this.getWidgetHeight
            }
        };

        return (
            <Widget
                {...props}
                getRef={this.getRef}
                events={this.widgetEvents}
                changeOtherAddressesStatus={this.changeOtherAddressesStatus}
                className='shareholder-information'
                components={this.widgetComponents}/>
        );
    }
}

export default RegisteredShareholderInformation;
