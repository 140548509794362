import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { LabelWithTooltip, NullableFormatter } from 'Components/formatters';

const labelCell = ({ value, className, rowData: { className: dataClassName } = {} } = {}) => (
    <LabelWithTooltip
        value={NullableFormatter({ value })}
        className={cn(className, dataClassName)}/>
);

labelCell.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    rowData: PropTypes.object
};

export {
    labelCell
};
