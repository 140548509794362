import React, { useState } from  'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import './SelectEmailRecipientsModal.scss';
import { CheckBox } from '../../CheckBox';
import { List } from 'react-virtualized';
import { emailRecipientsSelector } from '../../../state/userSecureUpload/selectors';
import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { updateSecureUploadEmailRecipients } from '../../../state/userSecureUpload/actions';
import _ from 'lodash';

const SelectEmailRecipientsModal = (props) => {
    const sortedRecipients = _.sortBy(props.emailRecipients, ['fullName']);
    const isFormDirty = sortedRecipients.some((recipient) => !!recipient.added);
    const initialStateForEmailRecipients = props.isReadOnly || isFormDirty ? sortEmailRecipientsByAdded(sortedRecipients) : sortedRecipients;
    const [recipients, setRecipients] = useState(initialStateForEmailRecipients);
    const ROW_HEIGHT = 30;
    const VISIBLE_ROW_COUNT = 10;

    const handleChange = (userId, isChecked) => {
        setRecipients((prevRecipients) => prevRecipients.map((recipient) => recipient.userId === userId ? { ...recipient, added: isChecked } : recipient));
    };

    return (<div className='select-email-recipients-modal'>
        <p className='select-email-recipients-subtitle'>
            <FormattedMessage id={props.isReadOnly ? 'modals.viewEmailRecipients.subtitle' : 'modals.selectEmailRecipients.subtitle'} />
        </p>
        <div className='recipient-list-container'>
            <div className='select-email-recipients-header'>
                <FormattedMessage id='modals.selectEmailRecipients.column.name' />
                <FormattedMessage id='modals.selectEmailRecipients.column.email' />
            </div>
            <List
                height={VISIBLE_ROW_COUNT * ROW_HEIGHT}
                rowHeight={ROW_HEIGHT}
                rowCount={recipients.length}
                width={700}
                rowRenderer={({ index, key, style }) => {
                    const user = recipients[index];

                    return (
                        <div className='user-list-item' key={key} style={style}>
                            <CheckBox
                                id={`email-recipient-${key}`}
                                onChange={(e) => {
                                    handleChange(user.userId, e.target.checked);
                                }}
                                checked={user.added}
                                disabled={props.isReadOnly}/>
                            <label className='user-full-name' htmlFor={`email-recipient-${key}`}>
                                {user.fullName}
                            </label>
                            <span className='user-email'>
                                <a className='link' href={`mailto:${user.email}`} title={user.email}>{user.email}</a>
                            </span>
                        </div>
                    );
                }}/>
        </div>
        <div className='modal-actions'>
            {!props.isReadOnly && <button
                type='button'
                onClick={async () => {
                    await props.updateSecureUploadEmailRecipients(recipients);
                    props.hideModal();
                }}
                className='btn btn-large ok-btn'>
                {props.intl.formatMessage({ id: props.saveTextKey })}
            </button>}
            <button
                type='button'
                onClick={props.hideModal}
                className='btn btn-large cancel-btn'>
                {props.intl.formatMessage({ id: props.cancelTextKey })}
            </button>
        </div>
    </div>);
};


const sortEmailRecipientsByAdded = (emailRecipients) => {
    // eslint-disable-next-line no-nested-ternary
    return emailRecipients.sort((a, b) => (a.added === b.added) ? 0 : a.added ? -1 : 1);
};

SelectEmailRecipientsModal.propTypes = {
    hideModal: PropTypes.func,
    saveTextKey: PropTypes.string,
    cancelTextKey: PropTypes.string,
    intl: intlShape,
    emailRecipients: PropTypes.array,
    updateSecureUploadEmailRecipients: PropTypes.func,
    isReadOnly: PropTypes.bool
};

const mapStateToProps = state => ({
    emailRecipients: emailRecipientsSelector(state)
});

const mapDispatchToProps = {
    updateSecureUploadEmailRecipients
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
)(SelectEmailRecipientsModal);

