import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';

import { deleteNote } from 'State/notes/actions';
import Icon from 'Components/Icon';
import NullableFormatter from 'Components/formatters/NullableFormatter';
import { showConfirmModal } from 'State/modal';
import { notifySuccess, notifyError } from 'State/notifier/actions';

const mapDispatchToProps = {
    showConfirmModal,
    deleteNote,
    notifySuccess,
    notifyError
};

@connect(null, mapDispatchToProps)
class CellDeleteNote extends Component {
    static propTypes = {
        value: PropTypes.any,
        additionalData: PropTypes.object,
        className: PropTypes.string,
        showConfirmModal: PropTypes.func,
        deleteNote: PropTypes.func,
        notifySuccess: PropTypes.func,
        notifyError: PropTypes.func
    };

    handleClick = () => {
        const {
            value,
            additionalData: { onDeleteNote } = {}
        } = this.props;

        this.props.showConfirmModal({
            className: 'delete-note-modal',
            messageKey: 'modals.confirmModal.message.deleteNote',
            okTextKey: 'common.delete',
            onSuccess: () => this.props.deleteNote(value.noteId)
                .then(() => {
                    this.props.notifySuccess(
                        null,
                        <FormattedHTMLMessage id={'notes.notifier.deleteNote.success'}/>
                    );

                    onDeleteNote && onDeleteNote();
                }, () => {
                    this.props.notifyError(
                        null,
                        <FormattedHTMLMessage id={'notes.notifier.deleteNote.error'}/>
                    );
                })
        });
    };

    render() {
        const { className, value } = this.props;
        const { additionalData: { iconClass } = {} } = this.props;
        const cellClassName = cn('font-icon', className, iconClass  || 'font-icon-delete');

        return value.canDelete
            ? (<NullableFormatter value={value.noteId}>
                <Icon
                    className={cellClassName}
                    titleKey='common.delete'
                    onClick={this.handleClick}/>
            </NullableFormatter>)
            : null;
    }
}


export default CellDeleteNote;
