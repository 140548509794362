import React from 'react';
import PropTypes from 'prop-types';

import { OwnershipPage } from 'Pages';

const OwnershipLayout = (props) => (
    <OwnershipPage>
        {props.children}
    </OwnershipPage>
);

OwnershipLayout.propTypes = {
    children: PropTypes.element
};

export default OwnershipLayout;
