import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import { commonWidgetActions, commonWidgetPropTypes } from '../../utils';
import NoDataComponent from '../../Widget/components/NoDataComponent';
import ContactInfo from './segments/ContactInfo';
import CompanyInfo from './segments/CompanyInfo';
import TextBlocks from './segments/TextBlocks';
import FundList from './segments/FundList';
import { companyPropTypes, contactPropTypes, fundsPropTypes, textBlocksPropType } from '../propTypes';

class ContactDetailsContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        widgetData: PropTypes.shape({
            contact: PropTypes.shape(contactPropTypes),
            company: PropTypes.shape(companyPropTypes),
            overview: textBlocksPropType,
            investmentApproach: textBlocksPropType,
            funds: fundsPropTypes.funds
        })
    };

    render() {
        const {
            isFetching,
            widgetData = {},
            intl: { formatMessage }
        } = this.props;
        const {
            contact,
            company,
            overview,
            investmentApproach,
            funds
        } = widgetData;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    message={formatMessage({ id: 'widgets.noDataMessage.simple' })}
                    isFetching={isFetching}
                    isNoData={!widgetData || _.isEmpty(widgetData)}>
                    <div className='container'>
                        <div className='column main'>
                            {contact && <ContactInfo className='segment' {...contact} /> }
                            {company && <CompanyInfo className='segment company-info' {...company} /> }
                            {overview && <TextBlocks
                                className='segment'
                                titleKey='contact.details.overview'
                                paragraphs={overview}/> }
                            {investmentApproach && <TextBlocks
                                className='segment'
                                titleKey='contact.details.investmentApproach'
                                paragraphs={investmentApproach}/> }
                        </div>
                        { funds && <div className='column'>
                            <FundList className='segment fund-list' funds={funds}/>
                        </div> }
                    </div>
                </NoDataComponent>
            </div>
        );
    }
}

export default ContactDetailsContent;
