import { getQueryParameterByName } from 'Utils/routing';

// BE DataPrez (old DataViz) print service passes dataviz_api_url to make all data requests
const apiUrl = getQueryParameterByName('dataviz_api_url');
let baseUrl = `${apiUrl || API_BASE_URL}/api`;

if (__MOCK_DATA__) {
    baseUrl = '/api';
}

export default {
    getLocale: '/locale',
    searchPrefix: '/search',

    getUserInfoUrl: `${baseUrl}/getUserInfo`,
    getSecuritiesUrl: `${baseUrl}/getSecurities`,
    changeSecurityUrl: `${baseUrl}/changeSecurity`,
    acceptUserTerms: `${baseUrl}/acceptUserTerms`,
    getUserImageUrl: `${baseUrl}/image`,
    getTermsAndConditionsUrl: `${baseUrl}/getTermsAndConditions`,
    acceptAnnouncementUrl: `${baseUrl}/acceptAnnouncement`,
    getTreasuryAccessUrl: `${baseUrl}/getTreasuryAccess`,

    loginUrl: `${baseUrl}/login`,
    logoutUrl: `${baseUrl}/logout`,

    getPageUrl: `${baseUrl}/getPage`,
    getWidgetDataUrl: `${baseUrl}/getWidgetData`,
    addWidgetsUrl: `${baseUrl}/addWidgets`,
    removeWidgetsUrl: `${baseUrl}/removeWidgets`,
    updateWidgetsUrl: `${baseUrl}/updateWidgets`,
    getMarketDataUrl: `${baseUrl}/getMarketData`,

    getDataVizPageUrl: `${baseUrl}/getDataVizPage`,
    saveDataVizTemplateUrl: `${baseUrl}/saveDataVizTemplate`,
    deleteDataVizTemplateUrl: `${baseUrl}/deleteDataVizTemplate`,
    getDataVizWidgetDataUrl: `${baseUrl}/getDataVizWidgetData`,
    addDataVizWidgetsUrl: `${baseUrl}/addWidgetsToDataVizReportInternally`,
    removeDataVizWidgetsUrl: `${baseUrl}/removeDataVizWidgets`,
    updateDataVizWidgetsUrl: `${baseUrl}/updateDataVizWidgets`,
    addWidgetsToDataVizReportUrl: `${baseUrl}/addWidgetsToDataVizReport`,

    logUrl: `${baseUrl}/log`,
    addWidgetToReportUrl: `${baseUrl}/addWidgetToReport`,
    searchUrl: `${baseUrl}/search`,
    searchTreasuryUrl: `${baseUrl}/searchTreasury`,
    getAllCountriesUrl: `${baseUrl}/getAllCountries`,
    getStatesByCountryIdUrl: `${baseUrl}/getStatesByCountryId`,
    getTotalOutstandingSharesByDateUrl: `${baseUrl}/getTotalOutstandingSharesByDate`,
    getReportTemplateUrl: `${baseUrl}/getTemplate`,
    getReportTemplatesUrl: `${baseUrl}/getTemplates`,
    saveReportTemplateUrl: `${baseUrl}/saveTemplate`,
    changeReportTemplateUrl: `${baseUrl}/changeTemplate`,
    deleteReportTemplateUrl: `${baseUrl}/deleteTemplate`,
    getReportTemplateUsersUrl: `${baseUrl}/getTemplateUsers`,
    shareReportTemplateUrl: `${baseUrl}/shareTemplate`,
    getLaunchedReportsUrl: `${baseUrl}/getLaunchedReports`,
    deleteLaunchedReportUrl: `${baseUrl}/deleteLaunchedReport`,
    getLaunchedReportGuidUrl: `${baseUrl}/getLaunchedReportGuid`,
    downloadLaunchedReportUrl: `${baseUrl}/downloadLaunchedReport`,
    getDocumentGuidUrl: `${baseUrl}/getDocumentGuid`,
    downloadDocumentUrl: `${baseUrl}/downloadDocument`,
    downloadStaticDocumentUrl: `${baseUrl}/downloadStaticDocument`,
    runReportUrl: `${baseUrl}/runReport`,
    getReportStatusUrl: `${baseUrl}/getReportStatus`,
    getUserNotificationsUrl: `${baseUrl}/getUserNotifications`,
    changeUserNotificationsUrl: `${baseUrl}/changeUserNotifications`,
    peerUrl: `${baseUrl}/peer`,
    saveLogUrl: `${baseUrl}/saveLog`,
    getExceptionUrl: `${baseUrl}/getException`,
    getHistoricalAnalysisShareholdersUrl: `${baseUrl}/getHistoricalAnalysisShareholders`,
    getPeerAnalysisShareholdersUrl: `${baseUrl}/getPeerAnalysisShareholders`,
    findAnalysisShareholdersUrl: `${baseUrl}/findAnalysisShareholders`,
    getResourcesUrl: `${baseUrl}/getResources`,
    getSuggestions: `${baseUrl}/getSuggestions`,
    getSubscriptionsUrl: `${baseUrl}/getSubscriptions`,
    changeSubscriptionStatusUrl: `${baseUrl}/changeSubscriptionStatus`,
    changeEmailSubscriptionStatusUrl: `${baseUrl}/changeEmailSubscriptionStatus`,
    manageCacheUrl: `${baseUrl}/manageCache`,
    requestAsOfDateReportUrl: `${baseUrl}/requestAsOfDateReport`,

    ping: `${baseUrl}/ping`, // this url uses only refresh token FF and IE
    checkAccountsSSNUrl: `${baseUrl}/checkAccountsSSN`,
    combineAccountsUrl: `${baseUrl}/combineAccounts`,
    evictUserCacheUrl: `${baseUrl}/evictUserCache`,
    uploadDocumentUrl: `${baseUrl}/uploadDocument`,
    deleteDocumentUrl: `${baseUrl}/deleteDocument`,

    deleteNoteUrl: `${baseUrl}/deleteNote`,
    createNoteUrl: `${baseUrl}/createNote`,

    getAlertsUrl: `${baseUrl}/getAlerts`,
    dismissAlertUrl: `${baseUrl}/dismissAlert`,
    downloadMeetingPlannerDocumentUrl: `${baseUrl}/downloadStaticDocument/planner`,

    getUploadRequestTypesUrl: `${baseUrl}/getUploadRequestTypes`,
    getUploadRequestListUrl: `${baseUrl}/getUploadRequestList`,
    getUploadRequestTemplatesUrl: `${baseUrl}/getUploadRequestTemplates`,
    downloadSecureUploadTemplate: `${baseUrl}/downloadStaticDocument/requesttemplate`,
    createUploadRequestDraftUrl: `${baseUrl}/createUploadRequestDraft`,
    getUploadRequestUrl: `${baseUrl}/getUploadRequest`,
    updateUploadRequestUrl: `${baseUrl}/updateUploadRequest`,
    uploadSecureDocumentUrl: `${baseUrl}/uploadSecureDocument`,
    getUploadRequestDocumentsUrl: `${baseUrl}/getUploadRequestDocuments`,
    deleteUploadRequestDocumentUrl: `${baseUrl}/deleteUploadRequestDocument`,
    downloadSecureUploadDocumentUrl: `${baseUrl}/downloadSecureUploadDocument`,
    getSecureUploadDocumentGuidUrl: `${baseUrl}/getSecureUploadDocumentGuid`,

    getUserWatchlistsUrl: `${baseUrl}/v2/watchlists`,
    watchlistRemoveShareholderUrl: `${baseUrl}/v2/watchlists/{watchlistId}/shareholders/{shareholderId}/exclude`,
    renameWatchlistUrl: `${baseUrl}/v2/watchlists/{watchlistId}/rename`,

    getRecentSearchUrl: `${baseUrl}/getShareholderRecentSearch`,
    addAccountToRecentSearchUrl: `${baseUrl}/updateShareholderRecentSearch`,

    getSecureUploadEmailRecipientsUrl: `${baseUrl}/getSecureUploadEmailRecipients`
};
