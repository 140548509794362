import ModalTypes from 'Constants/modal-types';
import { setIsSessionTimeout } from 'State/user/actions';
import { redirectToLogin } from 'Utils/utils';

export const SHOW_MODAL = 'modal/show';
export const CHANGE_MODAL_PARAMS = 'modal/changeParams';
export const HIDE_MODAL = 'modal/hide';

export function hideModal() {
    return {
        type: HIDE_MODAL
    };
}

/**
 * Show confirm modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {string} messageKey - modal description text localization key
 * @param {string} [customClass] - modal custom className
 * @param {function(): void} onSuccess - callback that handles the OK button click
 * @param {function(): void} [onCancel] - callback that handles the Cancel button click. When isn't passed, hide modal
 * @param {string} [okTextKey='common.ok'] - custom label localization key of OK button
 * @param {string} [cancelTextKey='common.cancel'] - custom label localization key of cancel button
 * @param {function(): void} [onClose] - callback that handles the X button click
 * @param {boolean} [isHtmlMessage] - use FormattedHTMLMessage or FormattedMessage for localize description
 * @param {boolean} [showExcelLimit] - show excel limit message or not
 * @param {string} [payload] - value for message
 * @param {string} [preventCloseOnSuccess] - flag, showing should to close the modal in case of success
 * @param {boolean} [queueProcessing] - enable add processing through the modal queue if another modal already opened
 * @param {string} [className] - root modal class name
 * @param {boolean} [shouldCloseOnOverlayClick] - should close on overlay click
 * @returns {Object} - corresponding action
 */
export function showConfirmModal({
    titleKey,
    messageKey,
    customClass,
    onSuccess,
    onCancel,
    okTextKey,
    cancelTextKey,
    onClose,
    isHtmlMessage = false,
    showExcelLimit = false,
    payload,
    preventCloseOnSuccess,
    queueProcessing = false,
    className,
    shouldCloseOnOverlayClick
}) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.confirm,
            shouldCloseOnOverlayClick,
            queueProcessing,
            className,
            params: {
                titleKey,
                messageKey,
                customClass,
                onSuccess,
                onCancel,
                okTextKey,
                cancelTextKey,
                onClose,
                isHtmlMessage,
                showExcelLimit,
                payload,
                preventCloseOnSuccess
            }
        }
    };
}

/**
 * Show info modal
 * @param {string} [titleKey] - modal title localization key
 * @param {string} messageKey - modal description text localization key
 * @param {object} [messageValues] - modal description text localization values
 * @param {string} [noteKey] - modal note text localization key
 * @param {object} [links] - links that will we inserted as message value
 * @param {string} [okTextKey='common.ok'] - custom label localization key of OK button
 * @param {string} [actionTextKey] - custom label localization key of additional button
 * @param {function(): void} [onAction] - callback that handles the additional button click
 * @param {boolean} [isHtmlMessage] - use FormattedHTMLMessage or FormattedMessage for localize description
 * @param {string} [modalClassName] - additional className for modal wrapper
 * @param {string} [messageClassName] - additional className for message wrapper
 * @param {string} [actionsClassName] - additional className for actions wrapper
 * @param {boolean} [queueProcessing] - enable add processing through the modal queue if another modal already opened
 * @returns {Object} - corresponding action
 */
export function showInfoModal({
    titleKey,
    messageKey,
    messageValues,
    noteKey,
    links,
    okTextKey,
    actionTextKey,
    onAction,
    isHtmlMessage = false,
    modalClassName,
    messageClassName,
    actionsClassName,
    queueProcessing = false }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.info,
            queueProcessing,
            params: {
                titleKey,
                messageKey,
                messageValues,
                noteKey,
                links,
                okTextKey,
                actionTextKey,
                onAction,
                isHtmlMessage,
                modalClassName,
                messageClassName,
                actionsClassName
            }
        }
    };
}

/**
 * Show column manager modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {function(): void} [onCancel] - callback that handles the Cancel button click
 * @param {Object} [widgetName] - data for content of modal
 * @param {Object} [mode] - column manager mode
 * @param {Object} [isParentModal] - flag for closing column manager modal
 * @returns {Object} - corresponding action
 */
export function showColumnManagerModal({
    titleKey,
    onCancel,
    widgetName,
    mode,
    isParentModal
}) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.columnManager,
            params: {
                titleKey,
                onCancel,
                widgetName,
                mode,
                isParentModal
            }
        }
    };
}

/**
 * Change title in current modal
 * @param {string} titleText - modal title text
 * @returns {Object} - corresponding action
 */
export function changeModalTitle(titleText) {
    return {
        type: CHANGE_MODAL_PARAMS,
        payload: {
            params: {
                titleText
            }
        }
    };
}

/**
 * Show Registered Overview common column manager modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {Array} [columns] - list of columns
 * @param {Function} [onSave] - callback on save click
 * @param {number} [rows] - amount of rows in one column
 * @param {boolean} [disableValidation] - turn off/on validation in column manager
 * @param {string} [modalClassName] - className for modal root element
 * @returns {Object} - corresponding action
 */
export function showCommonColumnsManagerModal({
    titleKey,
    columns,
    onSave,
    rows,
    disableValidation,
    modalClassName
}) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.commonColumnsManager,
            params: {
                titleKey,
                columns,
                onSave,
                rows,
                disableValidation,
                modalClassName
            }
        }
    };
}

/**
 * Show column manager modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {string} [saveTextKey] - custom label localization key of Save button
 * @param {string} [cancelTextKey='common.cancel'] - custom label localization key of Cancel button
 * @param {function(): void} [onSuccess] - callback that handles the Save button click
 * @param {function(): void} [onCancel] - callback that handles the Cancel button click
 * @param {function(): void} [onClose] - callback that handles the X button click
 * @param {object} [payload] - data for form
 * @param {object} [validators] - function for validation form
 * @returns {Object} - corresponding action
 */
export function showSaveCustomViewModal({
    titleKey,
    saveTextKey,
    cancelTextKey,
    onSuccess,
    onCancel,
    onClose,
    payload,
    validators
}) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.saveCustomView,
            params: {
                titleKey,
                saveTextKey,
                cancelTextKey,
                onSuccess,
                onCancel,
                onClose,
                payload,
                validators
            }
        }
    };
}

/**
 * Show advanced search modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {function} [onClose]
 * @returns {Object} - corresponding action
 */
export function showAdvancedSearchModal({ titleKey, onClose }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.advancedSearch,
            params: {
                titleKey,
                onClose
            }
        }
    };
}

/**
 * Show advanced search modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {Function} [onSuccess] - action-callback that handles the Export button click
 * @param {object} [modalViewParams={}] - modals parameters for view
 * @returns {Object} - corresponding action
 */
export function showExportReportModal({ titleKey, onSuccess, modalViewParams = {} }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportReport,
            params: {
                titleKey,
                onSuccess,
                ...modalViewParams
            }
        }
    };
}

export function showExportRegisteredReportModal({ titleKey, onSuccess, exportParams = {} }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportRegisteredOverview,
            params: {
                titleKey,
                exportParams,
                onSuccess
            }
        }
    };
}

/**
 * Show advanced search modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {Object} [exportParams] - params for exportCallback
 * @returns {Object} - corresponding action
 */
export function showExportShareholderTableModal({ titleKey, exportParams }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportShareholderTable,
            params: {
                titleKey,
                exportParams
            }
        }
    };
}

/**
 * Show export historical analysis modal with parameters
 * @param {Object} [exportParams] - params for exportCallback
 * @param {Object} [dateParams] - params for date range of the modal
 * @returns {Object} - corresponding action
 */
export function showExportHistoricalAnalysisModal({ exportParams, dateParams }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportHistoricalAnalysis,
            params: {
                titleKey: 'modals.exportReport.historicalAnalysis.title',
                exportParams,
                dateParams
            }
        }
    };
}

/**
 * Show export pending modal
 * @returns {Object} - corresponding action
 */
export function showExportPendingModal(securityId) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportReportPending,
            queueProcessing: true,
            params: {
                titleKey: 'modals.exportPending.title',
                securityId
            }
        }
    };
}

/**
 * Show export no results modal
 * @returns {Object} - corresponding action
 */
export function showExportNoResultsModal() {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportReportNoResults,
            queueProcessing: true,
            params: {
                titleKey: 'modals.exportNoResults.title'
            }
        }
    };
}

/**
 * Show export failed modal
 * @returns {Object} - corresponding action
 */
export function showReportFailedModal() {
    return showInfoModal({
        titleKey: 'modals.reportFailed.title',
        messageKey: 'modals.reportFailed.message',
        modalClassName: 'modal-report-failed',
        isHtmlMessage: true,
        queueProcessing: true
    });
}

export function showReportWithForbiddenSecurityModal() {
    return showInfoModal({
        titleKey: 'modals.reportFailed.forbiddenSecurity.title',
        messageKey: 'modals.reportFailed.forbiddenSecurity.message',
        noteKey: 'modals.reportFailed.forbiddenSecurity.note',
        modalClassName: 'modal-report-failed',
        isHtmlMessage: true,
        queueProcessing: true
    });
}

/**
 * Show confirm modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {function(): void} onSuccess - callback that handles the OK button click
 * @param {string} [payload] - values for new saving report
 * @param {string} [preventCloseOnSuccess] - flag, showing should to close the modal in case of success
 * @returns {Object} - corresponding action
 */
export function showSaveReportTemplateModal({
    titleKey,
    onSuccess,
    payload,
    preventCloseOnSuccess
}) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.saveReportTemplate,
            params: {
                titleKey,
                onSuccess,
                payload,
                preventCloseOnSuccess
            }
        }
    };
}

/**
 * Show confirm modal with parameters
 * @param {string} [titleKey] - modal title localization key
 * @param {function(): void} onSuccess - callback that handles the OK button click
 * @param {string} [payload] - values for new saving report
 * @param {string} [preventCloseOnSuccess] - flag, showing should to close the modal in case of success
 * @returns {Object} - corresponding action
 */
export function showSaveScheduledReportTemplateModal(
    {
        titleKey,
        onSuccess,
        payload,
        preventCloseOnSuccess
    }
) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.saveScheduledReportTemplate,
            params: {
                titleKey,
                onSuccess,
                payload,
                preventCloseOnSuccess
            }
        }
    };
}

/**
 * Show save changes modal
 * @param {string} [titleKey] - modal title localization key
 * @param {string} messageKey - modal description text localization key
 * @param {function(): void} onSuccess - callback that handles the OK button click
 * @param {function(): void} onCancel - callback that handles the Cancel button click. When isn't passed, hide modal
 * @param {string} [okTextKey='common.yes'] - custom label localization key of OK button
 * @param {string} [cancelTextKey='common.cancel'] - custom label localization key of cancel button
 * @param {function(): void} [onClose] - callback that handles the X button click
 * @param {string} [payload] - value for message
 * @returns {Object} - corresponding action
 */
export function saveChangesModal({
    titleKey,
    messageKey,
    onSuccess,
    onCancel,
    okTextKey,
    cancelTextKey,
    onClose,
    payload
}) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.saveChangesModal,
            params: {
                titleKey,
                messageKey,
                onSuccess,
                onCancel,
                okTextKey,
                cancelTextKey,
                onClose,
                payload
            }
        }
    };
}

export function showFindTopShareholdersModal({ onSuccess, ...rest }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.searchTopShareholdersModal,
            params: {
                titleKey: 'modals.findShareholderModal.title',
                onSuccess,
                ...rest
            },
            className: 'top-shareholders-search-modal'
        }
    };
}

export function showConfirmDeleteLeastWidgetModal(onSuccess) {
    return showConfirmModal({
        titleKey: 'modals.confirmModal.title.delete',
        messageKey: 'modals.dataVizReport.removeLeastModule.message',
        okTextKey: 'common.yes',
        cancelTextKey: 'common.no',
        isHtmlMessage: true,
        onSuccess
    });
}

export function showConfirmExportLargeReportModal(onSuccess) {
    return showConfirmModal({
        titleKey: 'modals.confirmModal.title.continue',
        messageKey: 'modals.exportReport.pdf.extraColumns.message',
        customClass: 'export-large-report-modal',
        okTextKey: 'common.yes',
        cancelTextKey: 'common.no',
        onSuccess,
        queueProcessing: true
    });
}

export function showMultipleWidgetConflictModal() {
    return showConfirmModal({
        customClass: 'modal-widget-conflict',
        titleKey: 'modals.confirmModal.title.multipleWidget',
        messageKey: 'modals.confirmModal.message.multipleWidget',
        okTextKey: 'common.continue',
        cancelTextKey: 'common.cancel',
        onSuccess: () => location.reload(),
        queueProcessing: true
    });
}

export function showWithWidgetModal({ slotId, widgetBuildParams, className }) {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.withWidgetModal,
            params: {
                slotId,
                widgetBuildParams
            },
            className
        }
    };
}

export const showSessionTimeoutModal = () => dispatch => {
    dispatch(setIsSessionTimeout());

    dispatch({
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.sessionTimeoutModal,
            shouldCloseOnOverlayClick: false,
            showCloseButton: false,
            params: {
                titleKey: 'modals.sessionTimeout.title',
                onClose: () => {
                    redirectToLogin();
                    dispatch(hideModal());
                }
            }
        }
    });
};

export const showAnalysisTurnoverLegendModal = ({ titleKey }) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.analysisTurnoverLegendModal,
            params: {
                titleKey
            }
        }
    };
};

export const showSelectSecurityModal = securityIds => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.selectSecurityModal,
            noHeader: true,
            showCloseButton: false,
            shouldCloseOnOverlayClick: false,
            className: 'select-security-modal',
            params: {
                securityIds
            }
        }
    };
};

export const showTermsAndConditionsModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: ModalTypes.termsAndConditionsModal
    }
});

export const showSelectAccountModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: ModalTypes.selectAccountModal,
        params: {
            titleKey: 'search.modals.selectAccount.title'
        }
    }
});

export const showClassicShareholdersExportReportModal = ({ titleKey, onSuccess, modalViewParams = {} }) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportClassicShareholderReportModal,
            params: {
                titleKey,
                onSuccess,
                ...modalViewParams
            }
        }
    };
};

export const showClassicAsOfDateExportReportModal = ({ titleKey, onSuccess, modalViewParams = {} }) => ({
    type: SHOW_MODAL,
    payload: {
        type: ModalTypes.exportClassicAsOfDateReportModal,
        className: 'export-classic-as-of-date-report-modal',
        params: {
            titleKey,
            onSuccess,
            ...modalViewParams
        }
    }
});

export const showAlertsModal = () => ({
    type: SHOW_MODAL,
    payload: {
        type: ModalTypes.alertsModal
    }
});

export const showReportRequestedModal = isSummaryOnly => ({
    type: SHOW_MODAL,
    payload: {
        type: ModalTypes.reportRequestedModal,
        queueProcessing: true,
        params: {
            titleKey: 'modals.reportRequested.title',
            isSummaryOnly
        }
    }
});

export const showCreateNoteModal = params => ({
    type: SHOW_MODAL,
    payload: {
        type: ModalTypes.createNoteModal,
        queueProcessing: true,
        params: {
            titleKey: 'modals.createNote.title',
            okTextKey: 'common.save',
            cancelTextKey: 'common.cancel',
            ...params
        }
    }
});

export const showNoteDetailsModal = note => ({
    type: SHOW_MODAL,
    payload: {
        type: ModalTypes.noteDetailsModal,
        queueProcessing: true,
        params: {
            titleKey: 'modals.noteDetails.title',
            note
        }
    }
});

export const showShareholdersContactsModal = (additionalData) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.shareholderContactsModal,
            noHeader: true,
            showCloseButton: false,
            className: 'shareholder-contacts-modal',
            params: {
                titleKey: 'modals.shareholderContacts.title',
                additionalData
            }
        }
    };
};
// For next feature
export const showExportShareholdersContactsModal = ({ shareholderId }) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportShareholderContactsModal,
            className: 'export-shareholder-contacts-modal',
            params: {
                titleKey: 'modals.exportReport.title',
                shareholderId
            }
        }
    };
};

export const showShareReportTemplateModal = (report = {}, onSuccess) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.shareReportTemplateModal,
            className: 'share-report-template-modal',
            params: {
                titleText: report.reportName,
                okTextKey: 'common.save',
                cancelTextKey: 'common.cancel',
                report,
                onSuccess
            }
        }
    };
};

export const showRenameWatchlistModal = (watchlist) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.renameWatchlistModal,
            className: 'rename-watchlist-modal',
            params: {
                titleKey: 'watchlist.modal.rename.title',
                watchlist,
                okTextKey: 'common.save',
                cancelTextKey: 'common.cancel'
            }
        }
    };
};

export const showExportWatchlistModal = ({ exportParams }) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.exportWatchlistModal,
            className: 'export-watchlist-modal',
            params: {
                titleKey: 'modals.exportReport.title',
                exportParams
            }
        }
    };
};

export const showSelectEmailRecipientsModal = (params) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.selectEmailRecipientsModal,
            className: 'select-email-recipients-modal',
            params: {
                titleKey: 'modals.selectEmailRecipients.title',
                saveTextKey: 'common.save',
                cancelTextKey: 'common.cancel',
                ...params
            }
        }
    };
};

export const showViewEmailRecipientsModal = (params) => {
    return {
        type: SHOW_MODAL,
        payload: {
            type: ModalTypes.selectEmailRecipientsModal,
            className: 'select-email-recipients-modal',
            params: {
                titleKey: 'modals.selectEmailRecipients.title',
                saveTextKey: 'common.save',
                cancelTextKey: 'common.cancel',
                isReadOnly: true,
                ...params
            }
        }
    };
};
