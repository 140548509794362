import { connect } from 'react-redux';

import AlertsModal from './AlertsModal';
import {
    getAlerts,
    dismissAlert,
    clearAlerts
} from 'State/alerts/actions';
import {
    isAlertsFetchingSelector,
    getAlertsSelector
} from 'State/alerts/selectors';
import { notifySuccess } from 'State/notifier/actions';

const mapStateToProps = state => ({
    isFetching: isAlertsFetchingSelector(state),
    alerts: getAlertsSelector(state)
});

const mapDispatchToProps = {
    getAlerts,
    dismissAlert,
    clearAlerts,
    notifySuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsModal);
