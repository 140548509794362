export const FONT_NAME = 'Open Sans';
export const FONT_NAME_UK = 'Inter';
export const fontWeights = [
    300,
    400,
    600,
    700,
    800
];
export const fontStyles = [undefined, 'italic'];

