import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import PerformanceChartFilter from 'Components/widgets/PerformanceSmall/components/PerformanceChartFilter';
import PerformanceChart from 'Components/PerformanceChart';
import { withCharts, chartsShape } from 'Components/widgets/_commonComponents/Performance/hoc/withCharts';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

@injectIntl
@withCharts
class PerformanceContentChartSmall extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        charts: chartsShape
    };

    constructor(props) {
        super(props);

        const { intl: { formatMessage } } = props;

        this.noDataMessage = formatMessage({ id: 'performance.noDataMessage' });
    }

    handleSubmit = (charts = []) => {
        const visibleCharts = charts
            .filter(chart => chart.isSelected)
            .map(chart => chart.id);

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            visibleCharts
        });
    };

    render() {
        const { charts, widgetSettings = {} } = this.props;
        const noChartData = !_.some(_.values(_.get(charts, 'data.performanceChart', {})), chart => _.get(chart, 'data.length'));

        return (
            <div className='performance-content-chart-small'>
                <NoDataComponent
                    message={this.noDataMessage}
                    isNoData={noChartData}
                    isFetching={false}>
                    <div>
                        <PerformanceChartFilter values={charts.selectableCharts} onSubmit={this.handleSubmit}/>
                        <div className='performance-chart-container'>
                            <PerformanceChart
                                visibleCharts={widgetSettings.visibleCharts}
                                data={charts.data.performanceChart}
                                customConfig={charts.config.performance}/>
                        </div>
                    </div>
                </NoDataComponent>
            </div>
        );
    }
}

export default PerformanceContentChartSmall;
