import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, propTypes as reduxFormPropTypes, getFormValues } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';

import { getOutstandingShares, getOutstandingSharesByCOBDate } from 'State/reportingControlBooks/actions';
import {
    outstandingShares,
    closeOfBusinessDate,
    getFetchingOutstandingShares
} from 'State/reportingControlBooks/selectors';
import { inputDateRangeValidation } from 'Components/InputDateRangePicker';
import { inputDateValidation } from 'Components/InputDatePicker';
import ReportingControlBooksSearchPageView from '../components/ReportingControlBooksSearchPageView';
import featureTypes from 'Constants/feature-types';
import restricted from 'Hoc/restricted';
import defaultValues from '../constants/defaultValues';
import viewTypes from 'Constants/control-books-view-types';
import pageRoutes from 'Constants/page-routes';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';
import { getSecuritiesByIssuerId } from 'State/securities/selectors';
import { getCurrentSecuritySelector } from 'State/user';
import reportTypes from 'Constants/report-types';
import { showExportWidgetModal } from 'State/exportReports';
import { columns } from '../constants/columnDefinitions';
import { sortArray } from 'Utils/utils';

const formName = 'reportingControlBooksForm';
const MAX_SELECTED_SECURITIES_COUNT = 50;

const mapStateToProps = (state) => ({
    outstandingShares: outstandingShares(state),
    closeOfBusinessDate: closeOfBusinessDate(state),
    isFetching: getFetchingOutstandingShares(state),
    formValues: getFormValues(formName)(state),
    getAvailableSecuritiesByIssuerId: getSecuritiesByIssuerId(state),
    currentSecurity: getCurrentSecuritySelector(state)
});

const mapDispatchToProps = {
    getOutstandingShares,
    getOutstandingSharesByCOBDate,
    showExportWidgetModal
};

@injectIntl
@withRouter
@restricted({ feature: featureTypes.OUTSTANDING_SHARES })
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: formName,
    destroyOnUnmount: false,
    initialValues: defaultValues,
    validate: values => {
        const errors = {};

        switch (values.shareDistributionType) {
            case viewTypes.OUTSTANDING_SHARES_DATE:
                errors.outstandingSharesDate = inputDateValidation(
                    values.outstandingSharesDate, { isCurrentDateAvailable: false });
                break;
            case viewTypes.TRANSACTIONS_DATE_RANGE:
                errors.dateRange = inputDateRangeValidation(
                    { ...values.dateRange, subtractDays: 1 }, { isRequired: true });
                break;
            default:
        }
        return errors;
    }
})
class ReportingControlBooksSearchPage extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        history: PropTypes.object,
        location: PropTypes.object,
        getOutstandingShares: PropTypes.func,
        getOutstandingSharesByCOBDate: PropTypes.func,
        intl: intlShape,
        shareDetailShareTypesValues: PropTypes.array,
        shareDetailStatusValues: PropTypes.array,
        isFetching: PropTypes.bool,
        currentSecurity: PropTypes.object,
        getAvailableSecuritiesByIssuerId: PropTypes.func
    };

    constructor(props) {
        super(props);
        const {
            intl: { formatMessage },
            currentSecurity,
            getAvailableSecuritiesByIssuerId
        } = this.props;

        this.securitiesOptions = {
            currentSecurity: {
                ...currentSecurity,
                label: currentSecurity.name,
                value: currentSecurity.id.toString()
            },
            availableSecurities: getAvailableSecuritiesByIssuerId(currentSecurity.issuerId)
                .filter(s => s.features[featureTypes.OUTSTANDING_SHARES]),
            maxSelectedSecuritiesCount: MAX_SELECTED_SECURITIES_COUNT
        };
        this.dateRangeOptions = {
            startDateOptions: {
                label: formatMessage({ id: 'datePicker.from' }),
                name: 'dateRange.startDate',
                disabled: true,
                maxDate: moment().subtract(1, 'days')
            },
            endDateOptions: {
                label: formatMessage({ id: 'datePicker.to' }),
                name: 'dateRange.endDate',
                disabled: true,
                maxDate: moment().subtract(1, 'days')
            }
        };
        this.dateOptions = {
            name: 'outstandingSharesDate.date',
            isCurrentDateAvailable: false
        };
    }

    componentDidMount() {
        const { currentSecurity } = this.props;

        this.props.getOutstandingShares();
        this.props.initialize({
            ...defaultValues,
            securities: [currentSecurity.id]
        });
    }

    handleSave = () => {
        const { formValues, untouch, history, location } = this.props;
        const selectedFormType = formValues.shareDistributionType;

        switch (selectedFormType) {
            case viewTypes.OUTSTANDING_SHARES_DATE:
                this.props.getOutstandingSharesByCOBDate(
                    formValues.outstandingSharesDate.date,
                    formValues.securities
                );
                untouch('dateRange.startDate', 'dateRange.endDate');
                break;
            case viewTypes.TRANSACTIONS_DATE_RANGE:
                untouch('outstandingSharesDate.date');
                history.push(buildLocationWithSafeQueryParams(pageRoutes.reportingControlBooksResults, location));
                break;
            default:
        }
    };

    handleSecuritiesChange = selectedSecurities => {
        const { change, formValues } = this.props;
        const selectedSecurityIds = sortArray({
            array: selectedSecurities,
            primaryField: 'name',
            descending: false
        }).map(s => s.id);

        change('securities', selectedSecurityIds);
        formValues.includeDetailedData && change('includeDetailedData', false);
        formValues.includeWeightedData && change('includeWeightedData', false);
    };

    handleTypeChange = event => {
        const type = event.target.value;

        switch (type) {
            case viewTypes.OUTSTANDING_SHARES_DATE:
                this.props.change('dateRange', {
                    startDate: null,
                    endDate: null
                });
                this.props.change('shareDistributionType', type);
                this.dateRangeOptions.startDateOptions.disabled = true;
                this.dateRangeOptions.endDateOptions.disabled = true;
                this.props.untouch('dateRange.startDate', 'dateRange.endDate');
                this.props.change('includeDetailedData', false);
                this.props.change('includeWeightedData', false);
                break;
            case viewTypes.TRANSACTIONS_DATE_RANGE:
                this.props.change('outstandingSharesDate.date', null);
                this.props.change('shareDistributionType', type);
                this.dateRangeOptions.startDateOptions.disabled = false;
                this.dateRangeOptions.endDateOptions.disabled = false;
                this.props.untouch('outstandingSharesDate.date');
                break;
            default:
        }
    };

    handleExport = () => {
        const { formValues } = this.props;

        this.props.showExportWidgetModal({
            reportTemplateType: reportTypes.totalOutstandingSharesTemplate,
            exportParams: {
                securities: formValues.securities,
                closeOfBusinessDate: this.props.closeOfBusinessDate,
                columnsToDisplay: columns
            },
            modalViewParams: {
                showExcelLimit: true
            }
        });
    };

    render() {
        return (
            <ReportingControlBooksSearchPageView
                {...this.props}
                onExport={this.handleExport}
                securitiesOptions={this.securitiesOptions}
                onSecuritiesChange={this.handleSecuritiesChange}
                dateRangeOptions={this.dateRangeOptions}
                dateOptions={this.dateOptions}
                onSave={this.handleSave}
                onTypeChange={this.handleTypeChange}/>
        );
    }
}

export default ReportingControlBooksSearchPage;
