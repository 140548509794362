import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getSelectedShareholderIdsSelector, combineAccounts } from 'State/advancedSearch';
import { RadioButtonsList } from 'Components/RadioButtonsList';

import './SelectAccountModal.scss';

const mapStateToProps = (state) => ({
    accounts: getSelectedShareholderIdsSelector(state)
});
const mapDispatchToProps = { combineAccounts };

@connect(mapStateToProps, mapDispatchToProps)
class SelectAccountModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func,
        combineAccounts: PropTypes.func,
        accounts: PropTypes.array,
        formValues: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.accounts = props.accounts.map(account => ({
            label: account,
            value: account,
            position: 'block'
        }));
        this.state = {};
    }

    handleAccountChange = (e) => {
        this.setState({ selectedAccount: e.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.combineAccounts(this.state.selectedAccount);
    };

    render() {
        const { hideModal } = this.props;

        return (
            <form onSubmit={this.handleSubmit} className='select-account-form'>
                <RadioButtonsList
                    name='account'
                    className='accounts-list'
                    onChange={this.handleAccountChange}
                    list={this.accounts}/>
                <div className='modal-actions'>
                    <button
                        className='btn btn-large'
                        type='submit'
                        disabled={!this.state.selectedAccount}>
                        <FormattedMessage id='search.modals.okText'/>
                    </button>
                    <button
                        type='button'
                        onClick={hideModal}
                        className='btn btn-large'>
                        <FormattedMessage id='common.cancel'/>
                    </button>
                </div>
            </form>
        );
    }
}
export default SelectAccountModal;
