import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import dateFormats from 'Constants/date-formats';
import MonthSelector from 'Components/MonthSelector';
import { commonWidgetActions, commonWidgetPropTypes } from 'Components/widgets/utils';

import './PeriodActionPanel.scss';

class PeriodActionPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        onMonthChange: PropTypes.func.isRequired,
        selectedDate: PropTypes.string,
        dates: PropTypes.array,
        onUpdateWidgetSettings: PropTypes.func,
        widgetSettings: PropTypes.object,
        isActionPanelDisabled: PropTypes.bool,
        isDataViz: PropTypes.bool
    };

    handleMonthChange = ({ value }) => {
        const { widgetSettings, widgetData: { eodTimestamp } = {} } = this.props;
        const selectedDate = moment(value).format(dateFormats.SERVER_DATE_FORMAT);
        const newWidgetSettings = {
            ...widgetSettings,
            selectedDate,
            eodTimestamp
        };

        this.props.onMonthChange(selectedDate);
        this.props.onUpdateWidgetSettings(newWidgetSettings);
    };

    render() {
        const { dates, selectedDate, isActionPanelDisabled } = this.props;
        const periodProps = {
            disabled: isActionPanelDisabled,
            selectedDate,
            dates,
            onChange: this.handleMonthChange
        };

        return (dates && dates.length && selectedDate ?
            (<div className='period-action-panel'>
                <MonthSelector {...periodProps}/>
                <FormattedMessage id='widgets.info.endOfMonth'>
                    {message => (
                        <span className='message-note'>({message})</span>
                    )}
                </FormattedMessage>
            </div>)
            : null
        );
    }
}

export default PeriodActionPanel;
