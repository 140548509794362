export default {
    reportName: '',
    isScheduled: false,
    schedule: {
        scheduleType: null,
        scheduleParam: null
    },
    isLastDayOfMonth: false,
    format: null
};
