import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { NullableFormatter } from 'Components/formatters';
import TextEllipsis from 'Components/TextEllipsis';
import { INFO_SIGN } from 'Constants/symbol-constants';

const DescriptionCell = ({
    value,
    className,
    additionalData: {
        alignRight,
        alignCenter,
        withTitle = true,
        withInfo = false
    } = {}
}) => {
    const cellClassName = cn(className, { 'cell-with-align-right': alignRight, 'cell-with-align-center': alignCenter });

    return (
        <div className='cell-with-description'>
            <TextEllipsis
                className={cellClassName}>
                <NullableFormatter value={value} withTitle={withTitle}/>
            </TextEllipsis>
            {withInfo && INFO_SIGN}
        </div>
    );
};

DescriptionCell.propTypes = {
    value: PropTypes.any,
    additionalData: PropTypes.object,
    className: PropTypes.string
};

export default DescriptionCell;
