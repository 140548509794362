import React, { Component } from 'react';
import { get } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';

import { RegisteredShareholderTable } from 'Components/widgets/_commonComponents/RegisteredShareholderTable';
import columnsDefinitions, { secondarySorting } from 'Components/widgets/RegisteredShareholderClosedSaleType/constants/columnsDefinitions';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { withPagination } from '../_commonComponents/RegisteredShareholderTable/hoc';

import './RegisteredShareholderClosedSaleType.scss';

@withPagination
@injectIntl
class RegisteredShareholderClosedSaleType extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    render() {
        const { intl: { formatMessage }, ...props } = this.props;
        const values = get(props, 'widgetData.values', []);
        const totalPages = get(props, 'widgetData.totalPages');
        const currentPage = get(props, 'widgetData.filterCriteria.currentPage');
        const footerProps = {
            visible: totalPages === currentPage,
            sharesTotal: get(props, 'widgetData.aggregate.sharesTotal'),
            label: formatMessage({ id: 'registered.shareholder.footer.sharesTotal' })
        };

        return (
            <RegisteredShareholderTable
                {...props}
                className='registered-shareholder-close-sale-type'
                footerProps={footerProps}
                secondarySorting={secondarySorting}
                data={values}
                columnsDefinitions={columnsDefinitions}
                noDataMessageId='widgets.noDataMessage.matchingClosedTaxLotInfo'
                hideColumnsManager/>
        );
    }
}

export default RegisteredShareholderClosedSaleType;
