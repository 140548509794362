import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import errorStrategies from 'Constants/error-stategies';

export const uploadDocument = (document, shareholderId) => {
    const formData = new FormData();

    formData.append('document', document);
    formData.append('shareholderId', shareholderId);

    return axios.post(apiRoutes.uploadDocumentUrl, formData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
