import PropTypes from 'prop-types';
import React from 'react';

import { NullableFormatter } from 'Components/formatters';

const CellWithUserName = ({ value }) => {
    return (
        <div className='ellipsis'>
            <NullableFormatter value={value} withTitle/>
        </div>
    );
};

CellWithUserName.propTypes = {
    value: PropTypes.any
};
export default CellWithUserName;
