import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';

const withList = WrappedComponent => class extends Component {
    static displayName = wrapDisplayName(WrappedComponent, 'withList');

    static propTypes = {
        ...WrappedComponent.propTypes,
        className: PropTypes.string,
        list: PropTypes.array.isRequired
    };

    render() {
        const { list, className, ...rest } = this.props;

        return (
            <div className={className}>
                {list.map((item, i) => (
                    <WrappedComponent
                        {...item}
                        {...rest}
                        key={i}/>
                ))}
            </div>
        );
    }
};

export default withList;
