import React from 'react';

import Tooltip from 'Components/Tooltip';
import PropTypes from 'prop-types';

const LabelWithTooltip = ({ className, value, specialTitle, onClick }) => {
    return (
        <Tooltip content={(
            <span className={className}>
                {specialTitle || value}
            </span>)}>
            <span onClick={onClick} className={className}>{value}</span>
        </Tooltip>);
};

LabelWithTooltip.propTypes = {
    value: PropTypes.any,
    specialTitle: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default LabelWithTooltip;
