import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, Fields, propTypes as reduxFormPropTypes } from 'redux-form';
import { FormattedMessage, FormattedHTMLMessage, intlShape } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

import PermissionContent from 'Components/PermissionContent';
import availableFieldsDefinition from '../constants/availableFieldsDefinition';
import shareholderListFields from './fields';
import SecuritiesField from 'Components/SecuritiesField';
import { RadioButtonsListField } from 'Components/RadioButtonsList';
import TextInput from 'Components/TextInput';
import { reportNameNormalize } from '../../common/reportNameUtils';
import reportExportFormats from 'Constants/export-report-formats';
import { InputDateRangePickerField } from 'Components/InputDateRangePicker';
import { withCheckboxFormDateRangePicker } from 'Hoc';
import LimitNote from 'Components/LimitNote';
import DateRangeInfo from './DateRangeInfo';
import Tooltip from 'Components/Tooltip';
import ReportScheduler from 'Components/ReportScheduler';
import Popover from 'Components/Popover';
import Icon from 'Components/Icon';

import './ReportingShareholderListPage.scss';

const ReportingShareholderListPageView = (props) => {
    const {
        isEditMode,
        onChangeShareholderType,
        onChangeColumnsToDisplayAllSection,
        handleSubmit,
        submitting,
        invalid,
        dirty,
        onGetPDF,
        onGetExcel,
        onSubmit,
        securitiesOptions,
        shareholderTypeOptions,
        shareTypeOptions,
        accountTypeOptions,
        accountStatusOptions,
        directDepositOptions,
        dateRangeOptions,
        sharesOwnedOptions,
        dateOfLastContactOptions,
        columnsToDisplayOptions,
        shareholderSortFields,
        shareholderLocationOptions,
        isCountriesFetching,
        isReportFetching,
        formatFetchingReport,
        addressStatusOptions,
        intl: { formatMessage },
        aggregatedInfo,
        isMultiSecurityReport,
        selectedSecurities,
        onSecuritiesChange
    } = props;

    const isAllowed = (productSources, currentProductSources) => {
        if (!productSources) return false;
        const productArray = [].concat(productSources);

        return _.intersection(productArray, currentProductSources).length;
    };

    const availabilityCount = [!!isAllowed(availableFieldsDefinition.shareType, aggregatedInfo.productSources),
        !!isAllowed(availableFieldsDefinition.accountType, aggregatedInfo.productSources),
        !!isAllowed(availableFieldsDefinition.accountStatus, aggregatedInfo.productSources),
        !!isAllowed(availableFieldsDefinition.directDeposit, aggregatedInfo.productSources),
        !!isAllowed(availableFieldsDefinition.additionalOptions, aggregatedInfo.productSources),
        !!isAllowed(availableFieldsDefinition.columnsToDisplay, aggregatedInfo.productSources)].filter((availability) => !!availability).length;

    const [isExpandedSections, setIsExpandedSections] = useState({
        shareType: true,
        accountType: true,
        accountStatus: true,
        directDeposit: true,
        additionalOptions: true,
        columnsToDisplay: true
    });
    const [expandAllActive, setExpandAllActive] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleSchedulingExpansion = () => {
        setIsCollapsed(!isCollapsed);
    };
    const isEditView = props.match.params.id;

    const toggleExpansion = (section) => {
        setIsExpandedSections({
            ...isExpandedSections,
            [section]: !isExpandedSections[section]
        });
    };

    const toggleExpandAll = () => {
        setExpandAllActive(!expandAllActive);
    };

    useEffect(() => {
        setIsExpandedSections({
            shareType: expandAllActive,
            accountType: expandAllActive,
            accountStatus: expandAllActive,
            directDeposit: expandAllActive,
            additionalOptions: expandAllActive,
            columnsToDisplay: expandAllActive
        });
        setIsCollapsed(expandAllActive);
    }, [expandAllActive]);

    useEffect(() => {
        if (isEditView) {
            if (Object.keys(isExpandedSections).filter((section) => isExpandedSections[section] === false).length === availabilityCount && !isCollapsed) {
                setExpandAllActive(false);
            }

            if (Object.keys(isExpandedSections).filter((section) => isExpandedSections[section] === true).length === availabilityCount && isCollapsed) {
                setExpandAllActive(true);
            }
        }

        if (!isEditView) {
            if (Object.keys(isExpandedSections).filter((section) => isExpandedSections[section] === false).length === availabilityCount && isCollapsed) {
                setExpandAllActive(false);
            }
            if (Object.keys(isExpandedSections).filter((section) => isExpandedSections[section] === true).length === availabilityCount) {
                setExpandAllActive(true);
            }
        }
    }, [isExpandedSections, isCollapsed]);

    return (
        <div className='reporting-shareholder-list-search-page'>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditMode &&
                    <div className='reporting-edit'>
                        <span className='reporting-edit-title'>
                            <FormattedMessage id='shareholder.list.edit' />:
                        </span>
                        <Field
                            name='formReportName'
                            component={TextInput}
                            className='reporting-edit-title-input'
                            tooltipMessage={formatMessage({ id: 'reporting.templateNameInputTooltip' })}
                            normalize={reportNameNormalize} />
                    </div>
                }
                <div className='fields-container'>
                    <div className='securities-shareholders-container'>
                        <div className='reporting-section'>
                            <div className='reporting-section-header'>
                                <FormattedMessage id='shareholder.list.securities' />:
                            </div>
                            <div className='reporting-section-body'>
                                <SecuritiesField
                                    onSecuritiesChange={onSecuritiesChange}
                                    options={securitiesOptions}
                                    selectedValues={selectedSecurities} />
                            </div>
                        </div>
                        <PermissionContent
                            features={availableFieldsDefinition.shareholderType}
                            currentFeatures={aggregatedInfo.features}>
                            <div className='reporting-section'>
                                <div className='reporting-section-header'>
                                    <FormattedMessage id='shareholder.list.shareholders' />:
                                </div>
                                <div className='reporting-section-body'>
                                    <Field
                                        name='shareholderType'
                                        onChange={onChangeShareholderType}
                                        items={shareholderTypeOptions}
                                        component={shareholderListFields.ShareholderTypeField}
                                        parse={shareholderListFields.ShareholderTypeField.parse} />
                                </div>
                            </div>
                        </PermissionContent>
                    </div>
                    <div className='expand-collapse-all-section'>
                        <span className='expand-collapse-all-text' onClick={toggleExpandAll}>
                            <FormattedMessage id={expandAllActive ? 'shareholder.list.expandAll' : 'shareholder.list.collapseAll'} />
                            {expandAllActive ? <i className='sort-icon font-icon font-icon-arrow-drop-down' /> : <i className='sort-icon font-icon font-icon-arrow-up' />}
                        </span>
                    </div>
                    <PermissionContent
                        productSources={availableFieldsDefinition.shareType}
                        currentProductSources={aggregatedInfo.productSources}>
                        <div className='reporting-section'>
                            <div className='shareholder-list-section-header'>
                                <Popover
                                    id='ShAREHOLDER-LIST-SECTION-POPOVER'
                                    trigger={<Icon className='font-icon font-icon-info-outline' />}
                                    triggerType={['click', 'hover']}
                                    placement='top'>
                                    <FormattedMessage id='shareholder.list.section.icon' />
                                </Popover>
                                <FormattedMessage id='shareholder.list.shareType' />:
                                <span
                                    className={`font-icon font-icon-paging-right ${!isExpandedSections.shareType && 'icon-rotate'}`}
                                    onClick={() => toggleExpansion('shareType')} />
                            </div>
                            <div className='reporting-section-body'>
                                <div className={isExpandedSections.shareType && 'expanded'}>
                                    <RadioButtonsListField
                                        name='shareType'
                                        className='radio-buttons share-type-radio-buttons'
                                        list={shareTypeOptions} />
                                </div>
                            </div>
                        </div>
                    </PermissionContent>
                    <PermissionContent
                        productSources={availableFieldsDefinition.accountType}
                        currentProductSources={aggregatedInfo.productSources}>
                        <div className='reporting-section'>
                            <div className='shareholder-list-section-header'>
                                <Popover
                                    id='ShAREHOLDER-LIST-SECTION-POPOVER'
                                    trigger={<Icon className='font-icon font-icon-info-outline' />}
                                    triggerType={['click', 'hover']}
                                    placement='top'>
                                    <FormattedMessage id='shareholder.list.section.icon' />
                                </Popover>
                                <FormattedMessage id='shareholder.list.accountType' />:
                                <span
                                    className={`font-icon font-icon-paging-right ${!isExpandedSections.accountType && 'icon-rotate'}`}
                                    onClick={() => toggleExpansion('accountType')} />
                            </div>
                            <div className='reporting-section-body'>
                                <div className={isExpandedSections.accountType && 'expanded'}>
                                    <Field
                                        name='accountType'
                                        items={accountTypeOptions}
                                        component={shareholderListFields.AccountsField} />
                                </div>
                            </div>
                        </div>
                    </PermissionContent>
                    <PermissionContent
                        productSources={availableFieldsDefinition.accountStatus}
                        currentProductSources={aggregatedInfo.productSources}>
                        <div className='reporting-section'>
                            <div
                                className={cn('shareholder-list-section-header', { disabled: _.every(accountStatusOptions, 'disabled') })}>
                                <Popover
                                    id='ShAREHOLDER-LIST-SECTION-POPOVER'
                                    trigger={<Icon className='font-icon font-icon-info-outline' />}
                                    triggerType={['click', 'hover']}
                                    placement='top'>
                                    <FormattedMessage id='shareholder.list.section.icon' />
                                </Popover>
                                <FormattedMessage id='shareholder.list.status' />:
                                <span
                                    className={`font-icon font-icon-paging-right ${!isExpandedSections.accountStatus && 'icon-rotate'}`}
                                    onClick={() => toggleExpansion('accountStatus')} />
                            </div>
                            <div className={isExpandedSections.accountStatus && 'expanded'}>
                                <div className='reporting-section-body'>
                                    <RadioButtonsListField
                                        name='accountStatus'
                                        className='radio-buttons account-status-radio-buttons'
                                        list={accountStatusOptions} />
                                </div>
                                <div className='reporting-section-body'>
                                    <div
                                        className={cn('reporting-section-header', { disabled: _.get(dateRangeOptions, 'checkboxOptions.disabled') })}>
                                        <FormattedMessage id='shareholder.list.status.dateRange' />:
                                    </div>
                                    <InputDateRangePickerField
                                        enhancer={withCheckboxFormDateRangePicker}
                                        dateRangeOptions={dateRangeOptions} />
                                    <DateRangeInfo
                                        inactive={_.get(dateRangeOptions, 'checkboxOptions.disabled')} />
                                </div>
                            </div>
                        </div>
                    </PermissionContent>
                    <PermissionContent
                        productSources={availableFieldsDefinition.directDeposit}
                        currentProductSources={aggregatedInfo.productSources}>
                        <div className='reporting-section'>
                            <div
                                className={cn('shareholder-list-section-header', { disabled: _.every(directDepositOptions, 'disabled') })}>
                                <Popover
                                    id='ShAREHOLDER-LIST-SECTION-POPOVER'
                                    trigger={<Icon className='font-icon font-icon-info-outline' />}
                                    triggerType={['click', 'hover']}
                                    placement='top'>
                                    <FormattedMessage id='shareholder.list.section.icon' />
                                </Popover>
                                <FormattedMessage id='shareholder.list.directDeposit' />:
                                <span
                                    className={`font-icon font-icon-paging-right ${!isExpandedSections.directDeposit && 'icon-rotate'}`}
                                    onClick={() => toggleExpansion('directDeposit')} />
                            </div>
                            <div className={`reporting-section-body ${isExpandedSections.directDeposit && 'expanded'}`}>
                                <RadioButtonsListField
                                    name='directDeposit'
                                    className='radio-buttons direct-deposit-radio-buttons'
                                    list={directDepositOptions} />
                            </div>
                        </div>
                    </PermissionContent>
                    <PermissionContent
                        productSources={availableFieldsDefinition.additionalOptions}
                        currentProductSources={aggregatedInfo.productSources}>
                        <div className='reporting-section'>
                            <div className='shareholder-list-section-header'>
                                <Popover
                                    id='ShAREHOLDER-LIST-SECTION-POPOVER'
                                    trigger={<Icon className='font-icon font-icon-info-outline' />}
                                    triggerType={['click', 'hover']}
                                    placement='top'>
                                    <FormattedMessage id='shareholder.list.section.icon' />
                                </Popover>
                                <FormattedMessage id='shareholder.list.additionalOptions' />:
                                <span
                                    className={`font-icon font-icon-paging-right ${!isExpandedSections.additionalOptions && 'icon-rotate'}`}
                                    onClick={() => toggleExpansion('additionalOptions')} />
                            </div>
                            <div className={`reporting-section-body ${isExpandedSections.additionalOptions && 'expanded'}`}>
                                <Fields
                                    names={['sharesOwned.checked', 'sharesOwned.min', 'sharesOwned.max']}
                                    items={sharesOwnedOptions}
                                    component={shareholderListFields.SharesOwnedField}
                                    parse={shareholderListFields.SharesOwnedField.parse} />
                            </div>
                        </div>
                        <div className={isExpandedSections.additionalOptions && 'expanded'}>
                            <div className='reporting-section'>
                                <div className='reporting-section-body'>
                                    <Fields
                                        names={['shareholderLocation.country', 'shareholderLocation.state', 'shareholderLocation.zipCode', 'shareholderLocation.showForeign']}
                                        options={shareholderLocationOptions}
                                        component={shareholderListFields.ShareholderLocationField}
                                        parse={shareholderListFields.ShareholderLocationField.parse} />
                                </div>
                            </div>
                            <PermissionContent
                                productSources={availableFieldsDefinition.dateOfLastContact}
                                currentProductSources={aggregatedInfo.productSources}>
                                <div className='reporting-section-body'>
                                    <Fields
                                        names={['dateOfLastContact.checked', 'dateOfLastContact.period']}
                                        options={dateOfLastContactOptions}
                                        component={shareholderListFields.DateOfLastContactField}
                                        parse={shareholderListFields.DateOfLastContactField.parse} />
                                </div>
                            </PermissionContent>
                            <PermissionContent
                                productSources={availableFieldsDefinition.dateOfLastContact}
                                currentProductSources={aggregatedInfo.productSources}>
                                <div className='reporting-section-body'>
                                    <Field
                                        name='addressStatus'
                                        options={addressStatusOptions}
                                        component={shareholderListFields.AddressStatusField} />
                                </div>
                            </PermissionContent>
                            <div className='reporting-section reporting-section-records'>
                                <div className='reporting-section-header'>
                                    <FormattedMessage id='shareholder.list.noOfRecords' />:
                                </div>
                                <Field
                                    name='noOfRecords'
                                    component={shareholderListFields.NoOfRecordsField}
                                    parse={shareholderListFields.NoOfRecordsField.parse} />
                            </div>
                        </div>
                    </PermissionContent>
                    <div className='reporting-section'>
                        <div className='shareholder-list-section-header'>
                            <Popover
                                id='ShAREHOLDER-LIST-SECTION-POPOVER'
                                trigger={<Icon className='font-icon font-icon-info-outline' />}
                                triggerType={['click', 'hover']}
                                placement='top'>
                                <FormattedMessage id='shareholder.list.section.icon' />
                            </Popover>
                            <FormattedMessage id='shareholder.list.columnsToDisplay' />:
                            <span
                                className={`font-icon font-icon-paging-right ${!isExpandedSections.columnsToDisplay && 'icon-rotate'}`}
                                onClick={() => toggleExpansion('columnsToDisplay')} />
                        </div>
                        <div className={`reporting-section-body reporting-include-section ${isExpandedSections.columnsToDisplay && 'expanded'}`}>
                            <Field
                                className='reporting-include-column ALL'
                                name='columnsToDisplay.ALL'
                                onChange={onChangeColumnsToDisplayAllSection}
                                component={shareholderListFields.ColumnsToDisplayField}
                                columnsToDisplayOptions={columnsToDisplayOptions.ALL} />
                            <PermissionContent
                                productSources={columnsToDisplayOptions.REGISTERED.availableFor.productSources}
                                currentProductSources={aggregatedInfo.productSources}>
                                <Field
                                    className='reporting-include-column REGISTERED'
                                    name='columnsToDisplay.REGISTERED'
                                    component={shareholderListFields.ColumnsToDisplayField}
                                    columnsToDisplayOptions={columnsToDisplayOptions.REGISTERED} />
                            </PermissionContent>
                            <PermissionContent
                                features={columnsToDisplayOptions.INSTITUTION.availableFor.features}
                                currentFeatures={aggregatedInfo.features}>
                                <Field
                                    className='reporting-include-column INSTITUTION'
                                    name='columnsToDisplay.INSTITUTION'
                                    component={shareholderListFields.ColumnsToDisplayField}
                                    columnsToDisplayOptions={columnsToDisplayOptions.INSTITUTION} />
                            </PermissionContent>
                        </div>
                    </div>
                    <div className='reporting-section reporting-section-sort'>
                        <div className='reporting-section-header'>
                            <FormattedMessage id='shareholder.list.sortBy' />:
                        </div>
                        <div className='reporting-section-body reporting-section-sort-body'>
                            <Field
                                name='sortCriteria'
                                component={shareholderListFields.SortByField}
                                sortFields={shareholderSortFields} />
                        </div>
                    </div>
                </div>
                {isEditMode && aggregatedInfo.isSchedulerAvailable &&
                    <ReportScheduler
                        withHeader
                        collapsible
                        toggleSchedulingExpansion={toggleSchedulingExpansion}
                        collapsed={isCollapsed}
                        formName={props.form}
                        isMultiSecurityReport={isMultiSecurityReport} />
                }
                <div className='shareholder-list-actions'>
                    <button
                        type='button'
                        className='btn btn-large btn-shareholder-list-excel'
                        onClick={onGetExcel}
                        disabled={submitting || invalid || isCountriesFetching ||
                            isReportFetching && formatFetchingReport === reportExportFormats.EXCEL}>
                        <FormattedMessage id='reporting.getExcel' />*
                    </button>
                    {isMultiSecurityReport
                        ? (<Tooltip content={<FormattedMessage id='reporting.multiSecurity.disablePDF.tooltip' />}>
                            <button
                                type='button'
                                className='btn btn-large btn-shareholder-list-pdf disabled'>
                                <FormattedMessage id='reporting.getPDF' />
                            </button>
                        </Tooltip>)
                        : (<button
                            type='button'
                            className='btn btn-large btn-shareholder-list-pdf'
                            onClick={onGetPDF}
                            disabled={submitting || invalid || isCountriesFetching ||
                                isReportFetching && formatFetchingReport === reportExportFormats.PDF}>
                            <FormattedMessage id='reporting.getPDF' />
                        </button>)
                    }
                    {isEditMode
                        ? <button
                            type='submit'
                            className='btn btn-large btn-shareholder-list-save'
                            disabled={submitting || invalid || !dirty || isCountriesFetching}>
                            <FormattedMessage id='shareholder.list.saveChanges' />
                        </button>
                        : <button
                            type='submit'
                            className='btn btn-large btn-shareholder-list-save'
                            disabled={submitting || invalid || isCountriesFetching}>
                            <FormattedMessage id='shareholder.list.save' />
                        </button>
                    }
                    <Popover
                        id='ShAREHOLDER-LIST-SAVE-BUTTON-POPOVER'
                        trigger={<Icon className='font-icon font-icon-info-outline' />}
                        triggerType={['click', 'hover']}
                        placement='top'>
                        <FormattedHTMLMessage id={isEditMode ? 'shareholder.list.save.button.popover' : 'shareholder.list.save.and.schedule.button.popover'} tagName='ul' />
                    </Popover>
                </div>
                <LimitNote />
            </form>
        </div>
    );
};

ReportingShareholderListPageView.propTypes = {
    ...reduxFormPropTypes,
    onSubmit: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    reportName: PropTypes.string,
    accountTypeOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    }))),
    accountStatusOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })),
    directDepositOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })),
    shareholderTypeOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })),
    shareTypesOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })),
    dateRangeOptions: PropTypes.object,
    sharesOwnedOptions: PropTypes.object,
    dateOfLastContactOptions: PropTypes.object,
    columnsToDisplayOptions: PropTypes.object,
    shareholderSortFields: PropTypes.array,
    shareholderLocationOptions: PropTypes.object,
    isCountriesFetching: PropTypes.bool,
    isReportFetching: PropTypes.bool,
    addressStatusOptions: PropTypes.object,
    formatFetchingReport: PropTypes.string,
    intl: intlShape,
    securitiesOptions: PropTypes.object,
    isMultiSecurityReport: PropTypes.bool
};

export default ReportingShareholderListPageView;
