import { useMemo } from 'react';
import { availableShareholderTypes } from '../utils';
import useCurrentWatchlistDisplayTypes from './useCurrentWatchlistDisplayTypes';
import shareholderTypes from 'Constants/shareholder-types';

const watchlistShareholderTypes = [shareholderTypes.INSTITUTION, shareholderTypes.INSIDER, shareholderTypes.REGISTERED, shareholderTypes.FUND];

const useAvailableShareholderTypes = ({ userProductSources, userFeatures, widgetSettings = {} }) => {
    const displayTypes = useCurrentWatchlistDisplayTypes(widgetSettings);

    return useMemo(
        () => availableShareholderTypes({
            userProductSources,
            userFeatures,
            displayTypes,
            shareholderTypes: watchlistShareholderTypes
        }),
        [userFeatures, userProductSources, displayTypes]
    );
};

export default useAvailableShareholderTypes;
