import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { CheckBox } from 'Components/CheckBox';
import Selector from 'Components/Selector';
import Icon from 'Components/Icon';
import { Panel } from 'react-bootstrap';
import { CONTEXTS, FILTERS } from 'Constants/search';
import {
    getContextList,
    getCountryList,
    getMappedFilterIssuersWithSecurities,
    getSearchTotals,
    getStates,
    getStatusList,
    getIsNewGlobalSearchRequest
} from 'State/advancedSearch/selectors';
import {
    setContext,
    setCountry,
    setIssuerFilter,
    setSecurityFilter,
    setState,
    setStatus,
    setIsNewGlobalSearchRequest
} from 'State/advancedSearch/actions';
import { securityFullNameFormatter, numberFormatter } from 'Components/formatters/formatters';

import './SearchFilter.scss';

const onToggle = () => {};
const mapStateToProps = (state) => ({
    contexts: getContextList(state),
    statuses: getStatusList(state),
    countries: getCountryList(state),
    states: getStates(state),
    totals: getSearchTotals(state),
    mappedFilterIssuers: getMappedFilterIssuersWithSecurities(state),
    isNewGlobalSearchRequest: getIsNewGlobalSearchRequest(state)
});
const mapDispatchToProps = {
    setContext,
    setStatus,
    setCountry,
    setState,
    setIssuerFilter,
    setSecurityFilter,
    setIsNewGlobalSearchRequest
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
class SearchFilter extends PureComponent {
    static propTypes = {
        intl: intlShape,
        contexts: PropTypes.object,
        statuses: PropTypes.object,
        countries: PropTypes.object,
        states: PropTypes.shape({
            selected: PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string
            }),
            availableStates: PropTypes.array
        }),
        mappedFilterIssuers: PropTypes.array,
        totals: PropTypes.object,
        setContext: PropTypes.func,
        setCountry: PropTypes.func,
        setState: PropTypes.func,
        setStatus: PropTypes.func,
        setIssuerFilter: PropTypes.func,
        setSecurityFilter: PropTypes.func,
        setIsNewGlobalSearchRequest: PropTypes.func,
        isNewGlobalSearchRequest: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = this.getCollapsibleState(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isNewGlobalSearchRequest, mappedFilterIssuers } = this.props;
        const currentFilterIssuersNames = mappedFilterIssuers.map(issuer => issuer.companyName);
        const nextFilterIssuersNames = nextProps.mappedFilterIssuers.map(issuer => issuer.companyName);

        if (currentFilterIssuersNames.length !== nextFilterIssuersNames.length
            || !_.isEqual(currentFilterIssuersNames.sort(), nextFilterIssuersNames.sort())
            || isNewGlobalSearchRequest) {
            this.setState(this.getCollapsibleState(nextProps));
        }
    }

    handleChangeContext = (event) => {
        const { target: { checked, name } } = event;

        this.props.setContext({ checked, name });
    };

    handleChangeStatus = (event) => {
        const { target: { checked, name } } = event;

        this.props.setStatus({ checked, name });
    };

    handleChangeCountry = (event) => {
        const { target: { checked, name } } = event;

        this.props.setCountry({ checked, name });
    };

    handleChangeIssuerFilter = (event, securities) => {
        const { target: { checked, id } } = event;

        this.props.setIssuerFilter({ checked, id, securities });
    };

    handleChangeSecurityFilter = (event, issuerSecurities) => {
        const { target: { checked, id } } = event;

        this.props.setSecurityFilter({ checked, id, issuerSecurities });
    };

    handleChangeState = (state) => {
        this.props.setState(state);
    };

    handleChangeExpandedFilters = (filterName) => {
        this.setState((prevState) => {
            let collapseAllState = prevState.collapseAllState;
            const newExpandedFilters = {
                ...prevState.expandedFilters,
                [filterName]: !prevState.expandedFilters[filterName]
            };
            const isAllCollapsed = _.every(_.values(newExpandedFilters), v => !v);
            const isAllExpanded = _.every(_.values(newExpandedFilters), v => v);

            if (isAllCollapsed) {
                collapseAllState = false;
            }
            if (isAllExpanded) {
                collapseAllState = true;
            }

            return {
                expandedFilters: newExpandedFilters,
                collapseAllState
            };
        });
    };

    handleExpandAll = () => {
        this.setState((prevState) => {
            const { expandedFilters } = prevState;
            const newExpandedFilters = {};

            _.forOwn(expandedFilters, (value, key) => {
                newExpandedFilters[key] = !prevState.collapseAllState;
            });

            return {
                expandedFilters: newExpandedFilters,
                collapseAllState: !prevState.collapseAllState
            };
        });
    };

    getRegisteredSubFilters = () => {
        const { statuses, countries } = this.props;
        const registeredSubFilters = [];

        if (statuses) {
            registeredSubFilters.push(..._.keys(statuses));
        }
        if (countries) {
            registeredSubFilters.push(..._.keys(countries));
        }

        return registeredSubFilters;
    };

    getCollapsibleState = (props) => {
        const expandedFilters = {};
        const numberIssuersWithSomeSecurities = props.mappedFilterIssuers.filter(issuer =>
            issuer.securities.length > 1).length;
        const isCollapsibleRegistered = this.getRegisteredSubFilters().length > 1;

        this.props.setIsNewGlobalSearchRequest(false);

        props.mappedFilterIssuers.forEach(issuer => {
            if (issuer.securities.length > 1) {
                expandedFilters[issuer.id] = true;
            }
        });
        if (isCollapsibleRegistered) {
            expandedFilters[CONTEXTS.REGISTERED] = true;
        }

        return {
            expandedFilters,
            isCollapsibleRegistered,
            needCollapseAll: isCollapsibleRegistered && numberIssuersWithSomeSecurities > 0 ||
                             numberIssuersWithSomeSecurities > 1,
            collapseAllState: true
        };
    };

    render() {
        const {
            intl: { formatMessage }, intl,
            contexts = {}, statuses = {}, countries = {}, states = {}, totals, mappedFilterIssuers
        } = this.props;
        const { expandedFilters, needCollapseAll, isCollapsibleRegistered, collapseAllState } = this.state;
        const isIssuerFilters = mappedFilterIssuers.length > 1;
        const labelForTotal = (textId, totalId) => (
            <span>
                {formatMessage({ id: textId })}
                <span className='criterion-total'> ({numberFormatter(intl, totals[totalId])})</span>
            </span>
        );

        return (
            <div className='search-filter'>
                {!_.isEmpty(contexts) &&
                <div className='filter-header'>
                    <h3><FormattedMessage id='search.filter.title'/></h3>
                    {needCollapseAll &&
                    <div className='expand-all-tool' onClick={this.handleExpandAll}>
                        <FormattedMessage id={collapseAllState ? 'search.filter.collapseAll' : 'search.filter.expandAll'}/>
                    </div>}
                </div>}
                {contexts[CONTEXTS.REGISTERED] !== undefined &&
                <div>
                    <div className='expanded-filter'>
                        <CheckBox
                            checked={contexts[CONTEXTS.REGISTERED]}
                            name={CONTEXTS.REGISTERED}
                            onChange={this.handleChangeContext}
                            className='criterion'
                            label={labelForTotal(`search.filter.context.${CONTEXTS.REGISTERED}`, CONTEXTS.REGISTERED)}/>
                        {isCollapsibleRegistered &&
                        <Icon
                            className={
                                expandedFilters[CONTEXTS.REGISTERED]
                                    ? 'font-icon font-icon-arrow-up'
                                    : 'font-icon font-icon-arrow-drop-down'}
                            onClick={() => {
                                this.handleChangeExpandedFilters(CONTEXTS.REGISTERED);
                            }}/>}
                    </div>
                    <Panel
                        expanded={expandedFilters[CONTEXTS.REGISTERED] || !isCollapsibleRegistered}
                        onToggle={onToggle}>
                        <Panel.Body collapsible >
                            <div className='second-layer'>
                                {statuses[FILTERS.Open] !== undefined &&
                                <CheckBox
                                    checked={statuses[FILTERS.Open]}
                                    name={FILTERS.Open}
                                    onChange={this.handleChangeStatus}
                                    className='criterion'
                                    label={labelForTotal(`search.filter.status.${FILTERS.Open}`, FILTERS.Open)}/>}
                                {statuses[FILTERS.Closed] !== undefined &&
                                <CheckBox
                                    checked={statuses[FILTERS.Closed]}
                                    name={FILTERS.Closed}
                                    onChange={this.handleChangeStatus}
                                    className='criterion'
                                    label={labelForTotal(`search.filter.status.${FILTERS.Closed}`, FILTERS.Closed)}/>}
                                {countries[FILTERS.US] !== undefined &&
                                <div>
                                    <CheckBox
                                        checked={countries[FILTERS.US]}
                                        name={FILTERS.US}
                                        onChange={this.handleChangeCountry}
                                        className='criterion'
                                        label={labelForTotal(`search.filter.country.${FILTERS.US}`, FILTERS.US)}/>
                                    <Selector
                                        name='State'
                                        placeholder={formatMessage({ id: 'search.filter.state' })}
                                        disabled={!countries[FILTERS.US]}
                                        options={states.availableStates}
                                        value={states.selected}
                                        autosize={false}
                                        onOptionChange={this.handleChangeState}/>
                                </div>}
                                {countries[FILTERS.Foreign] !== undefined &&
                                <CheckBox
                                    checked={countries[FILTERS.Foreign]}
                                    name={FILTERS.Foreign}
                                    onChange={this.handleChangeCountry}
                                    className='criterion'
                                    label={labelForTotal(`search.filter.country.${FILTERS.Foreign}`, FILTERS.Foreign)}/>}
                                {countries[FILTERS.Undefined] !== undefined &&
                                <CheckBox
                                    checked={countries[FILTERS.Undefined]}
                                    name={FILTERS.Undefined}
                                    onChange={this.handleChangeCountry}
                                    className='criterion'
                                    label={labelForTotal(`search.filter.country.${FILTERS.Undefined}`, FILTERS.Undefined)}/>}
                                {DEMO_MODE && (
                                    <CheckBox
                                        checked={countries[FILTERS.Undefined]}
                                        name={FILTERS.Undefined}
                                        onChange={this.handleChangeCountry}
                                        className='criterion'
                                        label={labelForTotal('search.filter.country.SAYE', FILTERS.Undefined)}/>
                                )}
                                {DEMO_MODE && (
                                    <CheckBox
                                        checked={countries[FILTERS.Undefined]}
                                        name={FILTERS.Undefined}
                                        onChange={this.handleChangeCountry}
                                        className='criterion'
                                        label={labelForTotal('search.filter.country.SIP', FILTERS.Undefined)}/>
                                )}
                                {DEMO_MODE && (
                                    <CheckBox
                                        checked={countries[FILTERS.Undefined]}
                                        name={FILTERS.Undefined}
                                        onChange={this.handleChangeCountry}
                                        className='criterion'
                                        label={labelForTotal('search.filter.country.CSN', FILTERS.Undefined)}/>
                                )}
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>}
                <div>
                    {contexts[CONTEXTS.INSIDERS] !== undefined && <CheckBox
                        checked={contexts[CONTEXTS.INSIDERS]}
                        name={CONTEXTS.INSIDERS}
                        onChange={this.handleChangeContext}
                        className='criterion'
                        label={labelForTotal(`search.filter.context.${CONTEXTS.INSIDERS}`, CONTEXTS.INSIDERS)}/>}
                    {contexts[CONTEXTS.INSTITUTIONS] !== undefined && <CheckBox
                        checked={contexts[CONTEXTS.INSTITUTIONS]}
                        name={CONTEXTS.INSTITUTIONS}
                        onChange={this.handleChangeContext}
                        className='criterion'
                        label={labelForTotal(`search.filter.context.${CONTEXTS.INSTITUTIONS}`, CONTEXTS.INSTITUTIONS)}/>}
                    {contexts[CONTEXTS.FUNDS] !== undefined && <CheckBox
                        checked={contexts[CONTEXTS.FUNDS]}
                        name={CONTEXTS.FUNDS}
                        onChange={this.handleChangeContext}
                        className='criterion'
                        label={labelForTotal(`search.filter.context.${CONTEXTS.FUNDS}`, CONTEXTS.FUNDS)}/>}
                    {contexts[CONTEXTS.CONTACTS] !== undefined && <CheckBox
                        checked={contexts[CONTEXTS.CONTACTS]}
                        name={CONTEXTS.CONTACTS}
                        onChange={this.handleChangeContext}
                        className='criterion'
                        label={labelForTotal(`search.filter.context.${CONTEXTS.CONTACTS}`, CONTEXTS.CONTACTS)}/>}
                    {mappedFilterIssuers.map((issuer) => {
                        const totalIssuer = _.find(totals.issuers, tIssuer => tIssuer.id === issuer.id);
                        const isFiltersShown = _.get(totals, 'securities.length', 1) !== 1;

                        return totalIssuer && isFiltersShown && <div key={issuer.id}>
                            <div className='expanded-filter'>
                                {isIssuerFilters
                                    ? <CheckBox
                                        checked={issuer.checked}
                                        name={issuer.companyName}
                                        id={issuer.id}
                                        title={`${issuer.companyName} (${totalIssuer.count})`}
                                        onChange={e => this.handleChangeIssuerFilter(e, issuer.securities)}
                                        className='criterion ellipsis'
                                        label={
                                            <span>
                                                {issuer.companyName}
                                                <span className='criterion-total'> ({numberFormatter(intl, totalIssuer.count)})</span>
                                            </span>
                                        }/>
                                    : <span className='no-criterion ellipsis'>
                                        {issuer.companyName}
                                        <span className='criterion-total'> ({numberFormatter(intl, totalIssuer.count)})</span>
                                    </span>}

                                {issuer.securities.length > 1 &&
                                <Icon
                                    className={
                                        expandedFilters[issuer.id]
                                            ? 'font-icon font-icon-arrow-up'
                                            : 'font-icon font-icon-arrow-drop-down'}
                                    onClick={() => {
                                        this.handleChangeExpandedFilters(issuer.id);
                                    }}/>}
                            </div>

                            <Panel
                                expanded={expandedFilters[issuer.id] || issuer.securities.length <= 1}
                                onToggle={onToggle}>
                                <Panel.Body collapsible>
                                    <div className='second-layer'>
                                        {issuer.securities.map(sec => {
                                            const securityTitle = securityFullNameFormatter(sec.name, sec.ticker, sec.cusip, sec.companyNumber);
                                            const secTotal = _.find(totals.securities, tSec => tSec.id === sec.id).count;

                                            return (
                                                <CheckBox
                                                    checked={sec.checked}
                                                    name={sec.name}
                                                    key={sec.id}
                                                    id={sec.id}
                                                    title={`${securityTitle} (${secTotal})`}
                                                    onChange={e => this.handleChangeSecurityFilter(e, issuer.securities)}
                                                    className='criterion ellipsis'
                                                    label={
                                                        <span>
                                                            {securityTitle}
                                                            <span className='criterion-total'>
                                                                {` (${numberFormatter(intl, secTotal)})`}
                                                            </span>
                                                        </span>
                                                    }/>);
                                        })}
                                    </div>
                                </Panel.Body>
                            </Panel>
                        </div>;
                    })}
                </div>
            </div>
        );
    }
}

export default SearchFilter;
