import colorScheme from 'Constants/color-schemas';
import { tooltipFormatter } from '../utils';

const yAxisTickAmount = 11;

export const LINE_COLOR = colorScheme['1'][5];
export const LINE_MAX_VALUE = 1000;

export const chartDefaultConfig = {
    chart: {
        animation: false,
        marginLeft: 80,
        marginRight: 0,
        marginBottom: 1,
        alignTicks: false
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        column: {
            dataLabels: {
                enabled: false
            },
            borderWidth: 0
        },
        series: {
            animation: false
        }
    },
    tooltip: {
        useHTML: true,
        hideDelay: 0,
        animation: false,
        formatter: tooltipFormatter,
        borderRadius: 0,
        borderWidth: 0,
        padding: 0,
        shadow: false,
        enabled: true,
        backgroundColor: 'none'
    }
};

export const xAxisLineDefaultConfig = {
    labels: {
        enabled: false
    },
    gridLineWidth: 0,
    min: 0,
    max: LINE_MAX_VALUE
};

export const xAxisBarDefaultConfig = {
    labels: {
        enabled: false
    },
    gridLineWidth: 1,
    plotBands: [{
        from: -0.5,
        to: 0.5,
        color: 'rgba(203, 196, 190, .3)'
    },
    {
        from: 1.5,
        to: 2.5,
        color: 'rgba(203, 196, 190, .3)'
    }]
};

export const yAxisLineDefaultConfig = {
    labels: {
        x: 10,
        y: -1
    },
    allowDecimals: false,
    title: {
        align: 'high',
        offset: 10,
        rotation: 0,
        x: 0,
        y: -20,
        style: {
            'font-weight': 'bold'
        }
    },
    opposite: true,
    tickAmount: yAxisTickAmount
};

export const yAxisBarDefaultConfig = {
    labels: {},
    title: {
        margin: 20,
        rotation: 270
    },
    allowDecimals: false,
    tickAmount: yAxisTickAmount
};

export const seriesBarDefaultConfig = {
    type: 'column',
    yAxis: 0,
    xAxis: 0
};

export const seriesLineDefaultConfig = {
    type: 'line',
    yAxis: 1,
    xAxis: 1,
    marker: {
        enabled: false
    },
    color: LINE_COLOR,
    tooltip: {
        pointFormatter: function marketPriceTooltipFormatter() {
            return this.y;
        }
    }
};
