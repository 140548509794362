import _ from 'lodash';

export function getPosition(w, h, p) {
    const el = this.label.getBBox();
    const container = this.chart.container.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const offsetLeft = _.get(this.chart, 'plotLeft', 0);
    const offsetTop = _.get(this.chart, 'plotTop', 0);
    const pos = {};

    if (container.top + offsetTop + p.plotY >= el.height + 20) {
        pos.y = container.top + offsetTop + p.plotY > windowHeight
            ? windowHeight - el.height - container.top - 20
            : p.plotY + offsetTop - el.height - 10;
    } else {
        pos.y = container.top + offsetTop + p.plotY <= 0
            ? 20 - container.top
            : p.plotY + offsetTop + 20;
    }

    if (container.left + offsetLeft + p.plotX <= 10 + el.width / 2) {
        pos.x = p.plotX - (p.plotX + container.left) + 5;
    } else if (windowWidth - container.left - offsetLeft - p.plotX - el.width / 2 <= 15) {
        pos.x = windowWidth - el.width - container.left - 15;
    } else {
        pos.x = p.plotX + offsetLeft - el.width / 2;
    }

    return pos;
}

export function getPositionWithScroll(w, h, p, chartContainer) {
    const el = this.label.getBBox();
    const container = chartContainer
        ? chartContainer.getBoundingClientRect()
        : this.chart.container.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const offsetLeft = _.get(this.chart, 'plotLeft', 0);
    const leftPos = chartContainer
        ? offsetLeft - chartContainer.scrollLeft
        : offsetLeft;
    const scrollLeft = _.get(chartContainer, 'scrollLeft', 0);
    const offsetTop = _.get(this.chart, 'plotTop', 0);
    const pos = {};

    if (offsetTop + p.plotY >= el.height + 20
        && container.top + offsetTop + p.plotY >= el.height + 20
        || windowHeight - container.top - p.plotY < el.height + 45) {
        pos.y = container.top + offsetTop + p.plotY > windowHeight
            ? windowHeight - el.height - container.top - 20
            : p.plotY + offsetTop - el.height - 10;
    } else {
        pos.y = container.top + offsetTop + p.plotY <= 0
            ? 20 - container.top
            : p.plotY + offsetTop + 20;
    }

    if ((leftPos + p.plotX <= el.width / 2
        || container.left + leftPos + p.plotX <= 10 + el.width / 2)
        && windowWidth - container.left - leftPos - p.plotX - el.width > 15) {
        pos.x = container.left < 0
            ? p.plotX - (p.plotX + leftPos + container.left) + offsetLeft + 5
            : p.plotX - (p.plotX + leftPos) + offsetLeft + 5;
    } else if ((container.width - leftPos - p.plotX - el.width / 2 <= 15
        || windowWidth - container.left - leftPos - p.plotX - el.width / 2 <= 15)
        && container.left + leftPos + p.plotX > 10 + el.width) {
        pos.x = container.width < windowWidth - container.left
            ? container.width - el.width + scrollLeft - 15
            : windowWidth - el.width - container.left + scrollLeft - 15;
    } else {
        pos.x = p.plotX + offsetLeft - el.width / 2;
    }

    return pos;
}
