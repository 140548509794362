import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { NullableFormatter } from 'Components/formatters';

const CellCommon = (props) => {
    const { value, className, additionalData: { alignRight, alignCenter, withTitle = true } = {} } = props;
    const cellClassName = cn(className, 'ellipsis', { 'cell-with-align-right': alignRight, 'cell-with-align-center': alignCenter });

    return (
        <div className={cellClassName}>
            <NullableFormatter value={value} withTitle={withTitle}/>
        </div>
    );
};

CellCommon.propTypes = {
    value: PropTypes.any,
    additionalData: PropTypes.object,
    className: PropTypes.string
};
export default CellCommon;
