import shareholderColumnsNames from 'Constants/shareholders-columns-names';
import featureTypes from 'Constants/feature-types';
import { TA } from 'Constants/product-source-types';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

const ALL_PRODUCTS = {
    productSources: [TA],
    features: [featureTypes.OI_PLUS_OPTIONS]
};

export const columnDisplayNames = {
    [shareholderColumnsNames.SHAREHOLDER_NAME]: 'watchlist.table.accountName',
    [shareholderColumnsNames.ACCOUNT_NUMBER]: 'shareholders.table.accountNumber',
    [shareholderColumnsNames.CURRENT_BALANCE]: 'watchlist.table.currentBalance',
    [shareholderColumnsNames.CERTIFIED_SHARES]: 'shareholders.table.certifiedShares',
    [shareholderColumnsNames.BOOK_SHARES]: 'shareholders.table.bookShares',
    [shareholderColumnsNames.DRIP_SHARES]: 'shareholders.table.dripShares',
    [shareholderColumnsNames.TOTAL_VALUE]: 'shareholders.table.totalValue',
    [shareholderColumnsNames.OS]: 'shareholders.table.os'

};

export const columnDefinitions = [
    {
        columnName: shareholderColumnsNames.SHAREHOLDER_NAME,
        holderType: 'ALL',
        columnType: 'shareholderName',
        availableFor: ALL_PRODUCTS,
        order: 1,
        displayName: columnDisplayNames[shareholderColumnsNames.SHAREHOLDER_NAME],
        manageable: false,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: shareholderColumnsNames.CURRENT_BALANCE,
        holderType: 'ALL',
        columnType: 'currentBalance',
        availableFor: ALL_PRODUCTS,
        order: 8,
        displayName: columnDisplayNames[shareholderColumnsNames.CURRENT_BALANCE],
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: shareholderColumnsNames.OS,
        holderType: 'ALL',
        columnType: 'os',
        availableFor: ALL_PRODUCTS,
        order: 13,
        displayName: columnDisplayNames[shareholderColumnsNames.OS],
        displayNameColumnManager: 'shareholders.table.columnsManager.os',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2
        }
    },
    {
        columnName: shareholderColumnsNames.TOTAL_VALUE,
        holderType: 'REGISTERED',
        columnType: 'totalValue',
        availableFor: ALL_PRODUCTS,
        order: 12,
        displayName: columnDisplayNames[shareholderColumnsNames.TOTAL_VALUE],
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: shareholderColumnsNames.ACCOUNT_NUMBER,
        holderType: 'REGISTERED',
        order: 7,
        availableFor: ALL_PRODUCTS,
        columnType: 'accountNumber',
        displayName: columnDisplayNames[shareholderColumnsNames.ACCOUNT_NUMBER],
        displayNameColumnManager: 'shareholders.table.columnsManager.accountNumber',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.CERTIFIED_SHARES,
        holderType: 'REGISTERED',
        order: 9,
        availableFor: ALL_PRODUCTS,
        columnType: 'certifiedShares',
        displayName: columnDisplayNames[shareholderColumnsNames.CERTIFIED_SHARES],
        displayNameColumnManager: 'shareholders.table.columnsManager.certifiedShares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: shareholderColumnsNames.BOOK_SHARES,
        holderType: 'REGISTERED',
        order: 10,
        availableFor: ALL_PRODUCTS,
        columnType: 'bookShares',
        displayName: columnDisplayNames[shareholderColumnsNames.BOOK_SHARES],
        displayNameColumnManager: 'shareholders.table.columnsManager.bookShares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: shareholderColumnsNames.DRIP_SHARES,
        holderType: 'REGISTERED',
        order: 11,
        availableFor: ALL_PRODUCTS,
        columnType: 'dripShares',
        displayName: columnDisplayNames[shareholderColumnsNames.DRIP_SHARES],
        displayNameColumnManager: 'shareholders.table.columnsManager.dripShares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    }
];
