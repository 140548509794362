import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';
import pageRoutes from 'Constants/page-routes';

import './SearchLink.scss';

const SearchLink = ({ hasOneSecurity, advancedSearchForSingleSecurity, onClickAdvancedSearch }) => {
    if (hasOneSecurity) {
        if (advancedSearchForSingleSecurity) {
            return (<a className='search-link' onClick={onClickAdvancedSearch}>
                <FormattedMessage id='advanced.search.single.link'/>
            </a>);
        }
        return null;
    }

    return (
        <LinkEx className='search-link' to={pageRoutes.search}>
            <FormattedMessage id='advanced.search.multiple.link'/>
        </LinkEx>
    );
};

SearchLink.propTypes = {
    hasOneSecurity: PropTypes.bool.isRequired,
    advancedSearchForSingleSecurity: PropTypes.bool.isRequired,
    onClickAdvancedSearch: PropTypes.func.isRequired
};

export default SearchLink;
