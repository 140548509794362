import React, { Component } from 'react';
import _ from 'lodash';

import { commonPropTypes, commonProps, buildFlexStyles } from '../utils';

import './Box.scss';

export default class Box extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonPropTypes
    };

    render() {
        const styles = buildFlexStyles({ ...this.props, ...this.props.config });
        const className = `box ${this.props.className || ''}`;
        const childProps = _.omit(this.props, commonProps);

        return (
            <div {...childProps} className={className} style={{ ...styles, ...this.props.style }}>
                {this.props.children}
            </div>
        );
    }
}
