import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getContext } from '@shakacode/recompose';

import * as widgetsSelectors from 'State/widgets/selectors';
import * as dataSourceSelectors from 'State/dataSource/selectors';
import * as featuresSelectors from 'State/features/selectors';
import * as pageSelectors from 'State/dynamicPage/selectors';
import { commonPropTypes, buildFlexStyles } from '../../layout/utils';
import { notifyWarning } from 'State/notifier/actions';
import * as dynamicPageActions from 'State/dynamicPage/actions';
import * as widgetsActions from 'State/widgets/actions';
import WidgetBoxEmptyView from './components/WidgetBoxEmptyView';
import * as invalidCases from './invalidCases';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import { getWidgetBoxProps } from './utils';
import httpStatuses from 'Constants/http-status';
import { addWidgetToReportEvent } from '../../../__ga__/events';

import './WidgetBox.scss';

const mapStateToProps = (state, { widgetName }) => {
    const dataSourceId = widgetsSelectors.getWidgetDataSourceIdSelector(state, widgetName);

    return {
        widgetData: dataSourceSelectors.getDataByIdSelector(state, dataSourceId),
        widgetDataInfo: dataSourceSelectors.getDataInfoByIdSelector(state, dataSourceId),
        widgetSettings: widgetsSelectors.getWidgetSettingsSelector(state, widgetName),
        widgetPageParams: widgetsSelectors.getWidgetPageParamsSelector(state, widgetName),
        widgetDataParams: widgetsSelectors.getWidgetDataParamsSelector(state, widgetName),
        widgetType: widgetsSelectors.getWidgetTypeSelector(state, widgetName),
        isFetching: widgetsSelectors.isWidgetFetchingSelector(state, widgetName),
        isRemoving: widgetsSelectors.isWidgetRemovingSelector(state, widgetName),
        isAdding: widgetsSelectors.isWidgetAddingSelector(state, widgetName),
        isDataViz: pageSelectors.isDataVizDynamicPage(state),
        isPrintMode: pageSelectors.isPrintModeDynamicPage(state),
        widgetError: widgetsSelectors.getWidgetErrorSelector(state, widgetName),
        userProductSources: featuresSelectors.getCurrentProductSourcesSelector(state),
        currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state)
    };
};
const mapDispatchToProps = {
    dynamicPageRemoveWidget: dynamicPageActions.dynamicPageRemoveWidget,
    dynamicPageAddWidget: dynamicPageActions.dynamicPageAddWidget,
    dynamicPageChangeWidget: dynamicPageActions.dynamicPageChangeWidget,
    widgetLoadData: widgetsActions.widgetsLoadData,
    widgetUpdateSettings: widgetsActions.widgetsUpdateSettings,
    widgetAddToDataVizReport: widgetsActions.widgetAddToDataVizReport,
    notifyWarning
};

@connect(mapStateToProps, mapDispatchToProps)
@getContext({ widgets: PropTypes.object })
class WidgetBox extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonPropTypes,
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        slotId: PropTypes.number.isRequired
    };

    componentDidMount() {
        const { widgetName } = this.props;

        const errorCase = invalidCases.checkWidgetReceivedPropsNotEnough(this.props);

        if (errorCase) {
            invalidCases.invalidCasesNotifiers[errorCase](widgetName, this.props.notifyWarning);
        }
    }

    handleRemoveWidget = () => {
        const { slotId, widgetName } = this.props;

        this.props.dynamicPageRemoveWidget(slotId, widgetName);
    };

    handleAddWidget = (widget) => {
        const widgetType = widget.name;
        const { slotId } = this.props;

        this.props.dynamicPageAddWidget(slotId, widgetType);
    };

    handleChangeWidget = (widget, options) => {
        const { slotId, widgetName } = this.props;
        const newWidgetType = widget.name;

        this.props.dynamicPageChangeWidget(slotId, newWidgetType, widgetName, options);
    };

    handleUpdateWidgetSettings = (newSettings) => {
        const { widgetName, widgetUpdateSettings } = this.props;

        widgetUpdateSettings(widgetName, newSettings);
    };

    handleUpdateWidgetData = (params, options) => {
        const { widgetName, widgetLoadData } = this.props;

        return widgetLoadData(widgetName, params, options);
    };

    handleAddWidgetToDataVizReport = (params) => {
        const { widgetName, widgetAddToDataVizReport, widgetType } = this.props;

        addWidgetToReportEvent(widgetType);
        return widgetAddToDataVizReport(widgetName, params);
    };

    renderWidget = (props) => {
        const Widget = props.widgets[props.widgetType];
        const widgetProps = {
            widgetName: props.widgetName,
            widgetData: props.widgetData,
            widgetDataInfo: props.widgetDataInfo,
            widgetType: props.widgetType,
            widgetSettings: props.widgetSettings,
            widgetPageParams: props.widgetPageParams,
            widgetDataParams: props.widgetDataParams,
            isFetching: props.isFetching,
            isRemoving: props.isRemoving,
            isAdding: props.isAdding,
            widgetError: props.widgetError,
            userProductSources: props.userProductSources,
            currentFeatures: props.currentFeatures,
            slotId: props.slotId,
            isLocked: props.isLocked,
            isDataViz: props.isDataViz,
            isPrintMode: props.isPrintMode,
            noPermissions: props.widgetError === httpStatuses.HTTP_403,

            onRemoveWidget: this.handleRemoveWidget,
            onAddWidget: this.handleAddWidget,
            onChangeWidget: this.handleChangeWidget,
            onUpdateWidgetSettings: this.handleUpdateWidgetSettings,
            onUpdateWidgetData: this.handleUpdateWidgetData,
            onAddWidgetToDataVizReport: this.handleAddWidgetToDataVizReport
        };

        return Widget
            ? <Widget {...widgetProps} />
            : <WidgetBoxEmptyView
                slotId={this.props.slotId}
                onAddContent={this.handleAddWidget}/>;
    };

    render() {
        const styles = buildFlexStyles(this.props);
        const className = `widget-box ${this.props.className || ''}`;
        const childProps = getWidgetBoxProps(this.props);

        return (
            <div {...childProps} className={className} style={{ ...styles, ...this.props.style }}>
                {this.renderWidget(this.props)}
            </div>
        );
    }
}

export default WidgetBox;
