import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'Components/Icon';

const AlertsSectionView = props => (
    <div className='alerts-section'>
        <Icon
            className={cn('font-icon font-icon-alerts', { inactive: props.userNewAlerts === 0 })}
            onClick={props.onIconClick}/>
        {props.userNewAlerts > 0 && <span className='badge'>{props.userNewAlerts}</span>}
    </div>
);

AlertsSectionView.propTypes = {
    userNewAlerts: PropTypes.number,
    onIconClick: PropTypes.func
};

export default memo(AlertsSectionView);
