import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import scheduleTypes from './constants/schedule-types';

export const dayOfMonthValidation = values => {
    if (values.isScheduled && !_.get(values, 'schedule.scheduleType') !== scheduleTypes.DAILY &&
        !values.isLastDayOfMonth && !_.get(values, 'schedule.scheduleParam')) {
        return <FormattedMessage id='reporting.reportScheduler.dayOfMonth.error.empty'/>;
    }
};
