import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withState } from '@shakacode/recompose';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import SecurityListView from './components/SecurityListView';
import IssuerListView from './components/IssuerListView';
import { CheckBox } from 'Components/CheckBox';
import { filterSecurityList } from 'State/advancedSearch/utils';
import * as securitySelectors from 'State/securities/selectors';
import * as issuerSelectors from 'State/issuers/selectors';
import * as userSelectors from 'State/user/selectors';
import { despace } from 'Utils/string';

import './SecuritySelector.scss';

const ROW_HEIGHT = 41;
const VISIBLE_ROW_COUNT = 10;

const mapStateToProps = state => ({
    securities: securitySelectors.getAvailableSecurities(state),
    issuers: issuerSelectors.getAvailableIssuers(state),
    currentSecurity: userSelectors.getCurrentSecuritySelector(state),
    getSpecificSecurities: ids => securitySelectors.getSecuritiesByIds(state, ids)
});

@withState(
    'saveAsDefault',
    'setSaveAsDefault',
    false
)
@injectIntl
@connect(mapStateToProps)
class SecuritySelector extends Component {
    static propTypes = {
        securities: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        })),
        issuers: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            companyName: PropTypes.string
        })),
        setSaveAsDefault: PropTypes.func,
        currentSecurity: PropTypes.any,
        saveAsDefault: PropTypes.bool,
        getSecurities: PropTypes.func,
        onSelect: PropTypes.func,
        onCancel: PropTypes.func,
        intl: intlShape,
        getSpecificSecurities: PropTypes.func,
        securityIds: PropTypes.array
    };

    static defaultProps = {
        issuers: [],
        securities: []
    };

    constructor(props) {
        super(props);
        this.state = {
            currentIssuerId: null,
            currentSecurityId: null,
            showAllSecurities: false,
            securities: [],
            filter: ''
        };

        // we has selected security
        if (props.currentSecurity) {
            this.state.currentSecurityId = props.currentSecurity.id;
            this.state.currentIssuerId = props.currentSecurity.issuerId;
            this.state.securities = props.securities.filter(sec => sec.issuerId === this.state.currentIssuerId);
        } else if (props.issuers && props.issuers.length) { // if we don't have selected security we have to choise to select first issuer
            this.state.currentIssuerId = props.issuers[0].id;
            this.state.securities = props.securities.filter(sec => sec.issuerId === this.state.currentIssuerId);
        }

        if (_.get(props, 'securityIds.length') > 0) {
            this.state = {
                currentIssuerId: null,
                currentSecurityId: null,
                showAllSecurities: false,
                securities: props.getSpecificSecurities(props.securityIds),
                filter: ''
            };
        }
    }

    handleSelectedSecurity = (security) => {
        this.setState({
            currentSecurityId: security.id
        });
    };

    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
    };

    handleSelectedIssuer = (issuer) => {
        if (issuer.id !== this.state.currentIssuerId) {
            this.setState({
                currentIssuerId: issuer.id,
                currentSecurityId: null,
                securities: filterSecurityList(this.props.securities.filter(sec => sec.issuerId === issuer.id), this.state.filter),
                showAllSecurities: false
            });
        }
    };

    handleShowAllSecurities = () => {
        if (!this.state.showAllSecurities) {
            this.setState({
                showAllSecurities: true,
                currentIssuerId: null,
                currentSecurityId: null,
                securities: filterSecurityList(this.props.securities, this.state.filter)
            });
        }
    };

    handleChangeFilter = (e) => {
        const filter = despace(e.target.value.toLowerCase());
        const issuerSecurities = this.state.currentIssuerId ? this.props.securities.filter(sec => sec.issuerId === this.state.currentIssuerId)
            : this.props.securities;

        this.setState({
            filter,
            securities: filterSecurityList(issuerSecurities, filter)
        });
    };

    handleChangeSaveAsDefault = (e) => {
        this.props.setSaveAsDefault(e.target.checked);
    };

    handleViewSecurity = () => {
        const { currentSecurityId } = this.state;
        const prevSecurityId = this.props.currentSecurity && this.props.currentSecurity.id;
        const isChanged = prevSecurityId !== currentSecurityId;

        this.props.onSelect(currentSecurityId, isChanged, this.props.saveAsDefault);
    };

    isEnableSelect() {
        return !_.isNil(this.state.currentSecurityId) &&
            !_.isUndefined(this.state.currentSecurityId);
    }

    render() {
        const withSpecificSecurities = _.get(this.props, 'securityIds.length') > 0;

        return (
            <div className='security-selector'>
                <div className='security-selector-content'>
                    <div className='top-panel'>
                        <div className='security-modal-title'>
                            {this.props.issuers.length !== 1 && <h3 className='title'><FormattedMessage
                                id='security.selectSecurity.multi.issuerAndSecurity.title'/></h3>}
                            {this.props.issuers.length === 1 &&
                            <h3 className='title'><FormattedMessage id='security.selectSecurity.multi.security.title'/>
                            </h3>}
                            <span className='message-note'>
                                <FormattedMessage id='security.selectSecurity.multi.description'/>
                            </span>
                        </div>
                        {!withSpecificSecurities &&
                            <div className='security-filter'>
                                <input
                                    maxLength={130}
                                    onFocus={this.handleShowAllSecurities}
                                    onChange={this.handleChangeFilter}
                                    className='security-filter-input'
                                    type='text'
                                    placeholder={this.props.intl.formatMessage({ id: 'security.findSecurity' })}/>
                                <i className='font-icon font-icon-search'/>
                            </div>
                        }
                    </div>
                    <div className='security-selector-container'>
                        {this.props.issuers.length !== 1 && !withSpecificSecurities &&
                        <IssuerListView
                            showAllSecurities={this.state.showAllSecurities}
                            issuers={this.props.issuers}
                            rowHeight={ROW_HEIGHT}
                            visibleRowCount={VISIBLE_ROW_COUNT}
                            selectIssuerId={this.state.currentIssuerId}
                            onSelectedIssuer={this.handleSelectedIssuer}
                            onShowAllSecurities={this.handleShowAllSecurities}/>}
                        <SecurityListView
                            rowHeight={ROW_HEIGHT}
                            visibleRowCount={VISIBLE_ROW_COUNT}
                            showAllSecurities={this.state.showAllSecurities}
                            securities={_.filter(this.state.securities, sec => !_.isEmpty(sec))}
                            issuers={this.props.issuers}
                            selectSecurityId={this.state.currentSecurityId}
                            onSelectedSecurity={this.handleSelectedSecurity}/>
                    </div>
                </div>
                <div className='action-panel'>
                    <div className='action-group'>
                        <button
                            disabled={!this.isEnableSelect()}
                            onClick={this.handleViewSecurity}
                            className='btn'
                            type='submit'>
                            <FormattedMessage id='security.button.view'/>
                        </button>
                        {!withSpecificSecurities &&
                            <CheckBox
                                checked={this.props.saveAsDefault}
                                onChange={this.handleChangeSaveAsDefault}
                                disabled={!this.isEnableSelect()}
                                label={<FormattedMessage id='security.defaultSecurity'/>}/>
                        }
                        <button
                            onClick={this.handleCancel}
                            className='btn btn-link'
                            type='button'>
                            <FormattedMessage id='common.cancel'/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default SecuritySelector;
