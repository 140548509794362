import _ from 'lodash';
import qs from 'query-string';

import { CONTEXTS } from 'Constants/search';
import { contextToDetailsPageType } from '../pages/SearchResultPage/config';
import pageRoutes from 'Constants/page-routes';

/**
 * The method build location for router with safe query params
 * @param pathname
 * @param location
 * @return {{pathname: *, query: *}}
 */
export function buildLocationWithSafeQueryParams(pathname, location) {
    return {
        pathname,
        search: location.search
    };
}

/**
 * build location with considering security
 * @param pathname
 * @param securityId
 * @param query
 * @return {{pathname: *, query: *}} - location
 */
export function buildLocationWithSecurity(pathname, securityId, query) {
    const newSearchObj = _.isObject(query) ? query : qs.parse(query);

    if (securityId) {
        newSearchObj.security = securityId;
    }

    const toLocation = {
        pathname: _.startsWith(pathname, '/') ? pathname : `/${pathname}`,
        search: qs.stringify(newSearchObj)
    };

    return toLocation;
}

export function getQueryParameterByName(name) {
    const searchQuery = qs.parseUrl(location.search);
    const hashQuery = qs.parseUrl((location.hash || '').replace('#', ''));
    const query = {
        ..._.get(searchQuery, 'query', {}),
        ..._.get(hashQuery, 'query', {})
    };

    return _.get(query, `${name}`, null);
}

/**
 * @param {Object | undefined} returnPath
 * @param {string | undefined} returnPath.pathname - url to return
 * @param {string | undefined} returnPath.search - url query parameters
 * @returns {string} href with return params
 */
export function buildRefToBackQuery(returnPath = {}) {
    const queryObj = {};

    if (returnPath.pathname) {
        queryObj.ret = btoa(returnPath.pathname);
    }

    if (returnPath.search) {
        queryObj.retSearch = btoa(returnPath.search);
    }

    return qs.stringify(queryObj);
}

export function parseUrl(url) {
    const a = document.createElement('a');

    a.href = url;
    return a;
}

export const removePortFromHost = hostUrl => hostUrl.split(':')[0];

export function normalizeUrlProtocol(url) {
    return !/^https?:\/\//i.test(url) ? `http://${url}` : url;
}

/**
 * make location for search item
 * @param contextType
 * @param item - search item
 * @param withBackLink
 * @return {*}
 */
export const buildSearchItemLocation = (contextType, item, withBackLink = true) => {
    const securityId = _.get(item, 'security.securityId');
    const baseUrl = pageRoutes[contextToDetailsPageType[contextType]];
    let pathname;
    const itemId = _.isString(item.id) ? item.id.trim() : item.id;
    const encodedId = encodeURIComponent(itemId);

    if (!baseUrl || !item.id) {
        return;
    }

    if (contextType === CONTEXTS.CONTACTS) {
        if (!item.jobId) {
            return;
        }
        pathname = `${baseUrl}/${encodedId}/${item.jobId}`;
    } else {
        pathname = `${baseUrl}/${encodedId}`;
    }

    const returnPath = { pathname: pageRoutes.searchResult };

    return buildLocationWithSecurity(pathname, securityId, withBackLink ? buildRefToBackQuery(returnPath) : null);
};

const addCustomSecurityToLink = (link, security) => {
    if (!security) {
        return link;
    }

    const fullPath = `^(${location.origin}${_.replace(location.pathname, /\/$/, '')})`;

    if (link.match(fullPath)) {
        const parsedLink = parseUrl(link);

        const [hashPath, hashQuery] = parsedLink.hash.split('?');
        const parsedHash = qs.parse(hashQuery);

        parsedLink.hash = `${hashPath}?${qs.stringify({ ...parsedHash, security })}`;

        return parsedLink.href;
    }

    return link;
};

export const buildNavLinks = ({
    pathConfig,
    features,
    formatMessage,
    customSecurityId
}) => {
    return _.reduce(pathConfig, (result, link) => {
        if ((link.path || link.href) && (link.label || link.labelId) && (
            _.isEmpty(link.features) ||
            _.intersection(_.keys(features), link.features).length > 0
        )) {
            result.push({
                label: link.label || formatMessage({ id: link.labelId }),
                ...(link.withLogout && { withLogout: link.withLogout }),
                ...(link.path && { to: link.path }),
                ...(link.href && { href: addCustomSecurityToLink(link.href, customSecurityId) }),
                ...link.properties
            });
        }

        return result;
    }, []);
};


export const pathWithSecurity = (path) => {
    const security = getQueryParameterByName('security');

    if (security) {
        return `${path}?security=${security}`;
    }

    return path;
};
