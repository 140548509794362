import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'react-virtualized';

import { getSortedAvailableIssuers } from 'State/issuers/selectors';
import {
    areAllIssuersSelected,
    isFetchingAny,
    toggleAllIssuers,
    areIssuersIndeterminate
} from 'State/advancedSearch';

import IssuerCheckBox from './IssuerCheckBox';
import SelectAllCheckBox from './SelectAllCheckBox';

const mapStateToProps = state => ({
    issuers: getSortedAvailableIssuers(state),
    allSelected: areAllIssuersSelected(state),
    indeterminate: areIssuersIndeterminate(state),
    disabled: isFetchingAny(state)
});
const mapDispatchToProps = { toggleAllIssuers };

@connect(mapStateToProps, mapDispatchToProps)
class IssuerCheckBoxList extends Component {
    static propTypes = {
        rowHeight: PropTypes.number,
        visibleRowCount: PropTypes.number,
        issuers: PropTypes.array,
        allSelected: PropTypes.bool,
        disabled: PropTypes.bool,
        indeterminate: PropTypes.bool,
        toggleAllIssuers: PropTypes.func
    };

    componentDidUpdate() {
        this.ref && this.ref.forceUpdateGrid();
    }

    handleSelectAllIssuers = (value) => {
        this.props.toggleAllIssuers(value);
    };

    setRef = (ref) => {
        this.ref = ref;
    };

    rowRenderer = ({ key, index, style }) => {
        const issuer = this.props.issuers[index - 1];

        if (index === 0) {
            return (<SelectAllCheckBox
                key={key}
                style={style}
                checked={this.props.allSelected}
                disabled={this.props.disabled}
                indeterminate={this.props.indeterminate}
                onClick={this.handleSelectAllIssuers}/>);
        }
        return (<IssuerCheckBox
            style={style}
            disabled={this.props.disabled}
            issuer={issuer}
            key={key}/>);
    };

    render() {
        const { rowHeight, visibleRowCount } = this.props;
        const rowCount = this.props.issuers.length + 1;

        return (
            <List
                className='issuers-list check-boxes-list'
                ref={this.setRef}
                width={410}
                height={visibleRowCount * rowHeight}
                rowHeight={rowHeight}
                rowCount={rowCount}
                rowRenderer={this.rowRenderer}/>
        );
    }
}

export default IssuerCheckBoxList;
