import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export const checkAccountsSSN = (accounts, securityId) => {
    const payload = { accounts, securityId };
    const requestData = utils.buildRequest(payload, requestTypes.checkAccountsSSNPayload);

    return axios.post(apiRoutes.checkAccountsSSNUrl, requestData, {
        isCancelable: true
    });
};

export const combineAccounts = (accounts, to, securityId) => {
    const payload = { accounts, to, securityId };
    const requestData = utils.buildRequest(payload, requestTypes.combineAccountsPayload);

    return axios.post(apiRoutes.combineAccountsUrl, requestData);
};
