import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import _ from 'lodash';

class TdComponent extends Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.isPropsEqual = _.isEqual(nextProps, this.props);
    }

    shouldComponentUpdate() {
        return !this.isPropsEqual;
    }

    render() {
        const { className, style, children, onClick, ...rest } = this.props;

        return (
            <div className={cn('rt-td', className)} style={style} onClick={onClick}>
                {React.isValidElement(children) && React.cloneElement(children, { ...rest })}
            </div>
        );
    }
}

TdComponent.propTypes = {
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string
};

export default TdComponent;
