import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import widgetErrorTypes from 'Constants/widget-error-types';

const WidgetError = ({ noPermissions, errorMessage, intl: { formatMessage }, widgetSettings: { errorType = widgetErrorTypes.MODULE } }) => (
    <div className='widget-error'>
        <div className='error-message'>
            {errorMessage || noPermissions
                ? formatMessage({ id: 'widgets.notPermissionMessage' }, {
                    errorType: formatMessage({ id: `widget.errorType.${errorType}` })
                })
                : formatMessage({ id: 'widgets.errorMessage' })
            }
        </div>
    </div>
);

WidgetError.propTypes = {
    intl: intlShape,
    noPermissions: PropTypes.bool,
    errorMessage: PropTypes.string,
    widgetSettings: PropTypes.object
};

export default injectIntl(WidgetError);
