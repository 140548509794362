import PropTypes from 'prop-types';

export const companyPropTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    capGroup: PropTypes.string,
    type: PropTypes.string,
    style: PropTypes.string,
    turnover: PropTypes.string,
    avp: PropTypes.string,
    proxyAdv: PropTypes.string,
    sector: PropTypes.string,
    industry: PropTypes.string,
    region: PropTypes.string,
    yearInc: PropTypes.number,
    reportedAUMValue: PropTypes.number,
    reportedAUMDate: PropTypes.string
};
export const contactPropTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    position: PropTypes.string,
    address: PropTypes.shape({
        residence: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        country: PropTypes.string
    }),
    email: PropTypes.string,
    phone: PropTypes.string
};

export const fundsPropTypes = {
    funds: PropTypes.arrayOf(PropTypes.shape({
        fundId: PropTypes.string,
        fundName: PropTypes.string.isRequired
    }))
};

export const textBlocksPropType = PropTypes.arrayOf(PropTypes.string);
