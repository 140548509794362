import pageRoutes from 'Constants/page-routes';
import featureTypes from 'Constants/feature-types';

const getAppNav = (globalSettings = {}) => ({
    pathConfig: [
        {
            path: pageRoutes.home,
            labelId: 'navigation.header.home',
            features: [featureTypes.HOME]
        },
        {
            path: pageRoutes.ownership,
            labelId: 'navigation.header.ownership',
            features: [
                featureTypes.OWNERSHIP_SHAREHOLDERS,
                featureTypes.HISTORICAL_ANALYSIS,
                featureTypes.PEER_ANALYSIS,
                featureTypes.CONTACTS,
                featureTypes.MEETING_PLANNER
            ]
        },
        {
            path: pageRoutes.reporting,
            labelId: 'navigation.header.reporting',
            features: [
                featureTypes.SHAREHOLDER_LIST,
                featureTypes.HOLDING_INVESTOR_TYPE_ANALYSIS,
                featureTypes.SHARE_RANGE_ANALYSIS_RESULTS,
                featureTypes.GEOGRAPHIC_ANALYSIS,
                featureTypes.SHARE_DETAIL,
                featureTypes.OUTSTANDING_SHARES
            ]
        },
        {
            path: pageRoutes.downloads,
            labelId: 'reporting.nav.downloads',
            features: [
                featureTypes.DOWNLOAD,
                featureTypes.SECURE_UPLOAD
            ],
            properties: {
                exact: true
            }
        },
        {
            path: pageRoutes.issuerResources,
            labelId: 'profile.menu.issuer.resources',
            features: [
                featureTypes.GLOBAL_LINKS
            ]
        },
        {
            href: globalSettings.learnMoreLink,
            label: globalSettings.learnMoreText,
            properties: {
                navItemClassName: 'nav-item-bold nav-item-middle'
            },
            features: [
                featureTypes.GLOBAL_LINKS
            ]
        },
        !DEMO_MODE && {
            href: window.DWAC_CENTRAL_URL,
            labelId: 'navigation.header.dwac',
            properties: {
                navItemClassName: 'push'
            },
            features: [
                featureTypes.GLOBAL_LINKS
            ],
            withLogout: true
        },
        {
            href: window.PROXY_CENTRAL_URL,
            labelId: 'navigation.header.proxy',
            features: [
                featureTypes.GLOBAL_LINKS
            ],
            withLogout: true
        }
    ].filter(Boolean),
    defaultPath: {
        path: pageRoutes.securityList
    }
});

const ownershipNav = {
    pathConfig: [
        {
            path: pageRoutes.ownershipShareholders,
            labelId: 'navigation.ownership.shareholders',
            features: [featureTypes.OWNERSHIP_SHAREHOLDERS]
        }, {
            path: pageRoutes.historicalAnalysis,
            labelId: 'navigation.ownership.historicalanalysis',
            features: [featureTypes.HISTORICAL_ANALYSIS]
        }, {
            path: pageRoutes.peerAnalysis,
            labelId: 'navigation.ownership.peeranalysis',
            features: [featureTypes.PEER_ANALYSIS]
        }, {
            path: pageRoutes.ownershipContacts,
            labelId: 'navigation.ownership.contacts',
            features: [featureTypes.CONTACTS]
        }, {
            path: pageRoutes.meetingPlanner,
            labelId: 'navigation.ownership.meetingPlanner',
            features: [featureTypes.MEETING_PLANNER]
        }
    ],
    defaultPath: {
        path: pageRoutes.reporting
    }
};

const reportingNav = {
    pathConfig: [
        {
            path: pageRoutes.reportingShareholderList,
            labelId: 'navigation.reporting.shareholderlist',
            features: [featureTypes.SHAREHOLDER_LIST]
        }, {
            path: pageRoutes.reportingHolderInvestorTypeAnalysis,
            labelId: 'navigation.reporting.holdertypeanalysis',
            features: [featureTypes.HOLDING_INVESTOR_TYPE_ANALYSIS]
        }, {
            path: pageRoutes.reportingShareRangeAnalysis,
            labelId: 'navigation.reporting.sharerangeanalysis',
            features: [featureTypes.SHARE_RANGE_ANALYSIS_RESULTS]
        }, {
            path: pageRoutes.reportingGeographicAnalysis,
            labelId: 'navigation.reporting.geographicanalysis',
            features: [featureTypes.GEOGRAPHIC_ANALYSIS]
        }, {
            path: pageRoutes.reportingShareDetail,
            labelId: 'navigation.reporting.sharedetail',
            features: [featureTypes.SHARE_DETAIL]
        }, {
            path: pageRoutes.reportingControlBooks,
            labelId: 'navigation.reporting.controlbooks',
            features: [featureTypes.OUTSTANDING_SHARES]
        }
    ],
    defaultPath: {
        path: pageRoutes.securityList
    }
};

const reportingRoutes = {
    pathConfig: [
        {
            path: pageRoutes.reportingShareholderList,
            features: [featureTypes.SHAREHOLDER_LIST]
        }, {
            path: pageRoutes.reportingHolderInvestorTypeAnalysis,
            features: [featureTypes.HOLDING_INVESTOR_TYPE_ANALYSIS]
        }, {
            path: pageRoutes.reportingShareRangeAnalysis,
            features: [featureTypes.SHARE_RANGE_ANALYSIS_RESULTS]
        }, {
            path: pageRoutes.reportingGeographicAnalysis,
            features: [featureTypes.GEOGRAPHIC_ANALYSIS]
        }, {
            path: pageRoutes.reportingShareDetail,
            features: [featureTypes.SHARE_DETAIL]
        }, {
            path: pageRoutes.reportingControlBooks,
            features: [featureTypes.OUTSTANDING_SHARES]
        }
    ],
    defaultPath: {
        path: pageRoutes.securityList
    }
};

const downloadNav = {
    pathConfig: [
        !DEMO_MODE && {
            path: pageRoutes.downloadsList,
            labelId: 'reporting.nav.downloads',
            features: [
                featureTypes.DOWNLOAD
            ]
        },
        !DEMO_MODE && {
            path: pageRoutes.downloadsUserSecureUploadList,
            labelId: 'userSecureUpload.nav.title',
            features: [
                featureTypes.SECURE_UPLOAD
            ]
        }
    ].filter(Boolean),
    defaultPath: {
        path: pageRoutes.securityList
    }
};

export default {
    getAppNav,
    ownershipNav,
    reportingNav,
    reportingRoutes,
    downloadNav
};
