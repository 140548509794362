import React, { Component } from 'react';

import widgetModes from 'Constants/widget-modes';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import CorporateShareholdersTableView from './CorporateShareholdersTableView';
import CorporateShareholdersChartView from './CorporateShareholdersChartView';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

class CorporateShareholdersContent extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    render() {
        const { widgetSettings: { mode, colorScheme }, widgetFooterProps, isFetching, widgetNoData, data } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    {mode === widgetModes.CHART
                        ? <CorporateShareholdersChartView
                            widgetFooterProps={widgetFooterProps}
                            intl={this.props.intl}
                            colorSchemeId={colorScheme}
                            data={data}/>
                        : <CorporateShareholdersTableView
                            widgetFooterProps={widgetFooterProps}
                            intl={this.props.intl}
                            data={data}/>
                    }
                </NoDataComponent>
            </div>
        );
    }
}


export default CorporateShareholdersContent;
