/* eslint-disable react/no-multi-comp*/
import React from 'react';

import Tooltip from 'Components/Tooltip';
import { NullableFormatter, NumberFormatter } from 'Components/formatters';

export const labelCell = cellProps => (
    <NullableFormatter value={cellProps.value} className={cellProps.className} withTitle/>
);
export const numberCell = cellProps => (<NumberFormatter value={cellProps.value} withTitle/>);
export const headerCell = (title, tooltip) => (
    <span className='ellipsis'>
        <Tooltip
            content={tooltip ? tooltip : title}>
            <span>{title}</span>
        </Tooltip>
    </span>
);
