import PropTypes from 'prop-types';
import React from 'react';

import BackLink from 'Components/BackLink';
import WatchlistShareholderButton from 'Components/WatchlistShareholderButton';
import shareholderTypes from 'Constants/shareholder-types';

const RegisteredOverviewPageHeaderView = ({ pageInfo = {} }) => {
    const { shareholderTitle, shareholderId } = pageInfo;

    const shareholderType = shareholderTypes.REGISTERED;

    return (
        <div className='institutional-overview-page-header'>
            <div className='content'>
                <BackLink/>
                <div className='institutional-overview-page-header__title'>
                    <h2 className='title'>{shareholderTitle}</h2>
                    <WatchlistShareholderButton
                        shareholderId={shareholderId}
                        shareholderTitle={shareholderTitle}
                        shareholderType={shareholderType} />
                </div>
            </div>
        </div>
    );
};

RegisteredOverviewPageHeaderView.propTypes = {
    pageInfo: PropTypes.shape({
        shareholderTitle: PropTypes.string,
        shareholderId: PropTypes.string
    })
};

export default RegisteredOverviewPageHeaderView;
