import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.TRANSACTION_DATE,
        order: 1,
        minWidth: 120,
        displayName: 'registered.shareholder.table.transactionDate',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.TRANSACTION_TYPE,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.transactionType',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SHARES,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.shares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.SHARES_PRICE,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.sharesPrice',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2
        }
    },
    {
        columnName: columnNames.AMOUNT_INVESTED,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.amountInvested',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2
        }
    },
    {
        columnName: columnNames.SERVICE_CHARGE,
        order: 6,
        minWidth: 120,
        displayName: 'registered.shareholder.table.serviceCharge',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2
        }
    },
    {
        columnName: columnNames.TOTAL_SHARES,
        order: 7,
        minWidth: 120,
        displayName: 'registered.shareholder.table.totalShares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    }
];

export const secondarySorting = {
    field: columnNames.SHARES,
    direction: sortDirections.DESC
};
