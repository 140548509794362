import { changeFormatter, dateFormatter } from '../formatters/formatters';
import { defaultVolumeFormat } from 'Components/widgets/_commonComponents/Performance/constants';

export const tooltipFormatter = (point, intl) => {
    const { x: originalDate, y: originalVolume } = point;
    const formattedVolume = changeFormatter(intl, originalVolume);

    return `
        <div class="ast-highcharts-tooltip" style="border-color:${point.points[0].point.color}">
            <div>${dateFormatter(originalDate)}</div>
            <div>${intl.formatMessage({ id: 'volume.chart.tooltip.volume' })}: ${formattedVolume}</div>
        </div>
    `;
};

export const labelFormatter = (value, intl) => {
    const format = defaultVolumeFormat;
    const scaledValue = value / format.divider;
    const symbol = value !== 0 ? intl.formatMessage({ id: format.localization }) : '';

    return `${intl.formatNumber(scaledValue)}${symbol}`;
};
