import * as _ from 'lodash';
import { widgetSettings } from '@ast/default-settings';

import widgetTypes from 'Constants/widget-types';
import ContactDetails from './ContactDetails';
import CorporateShareholders from './CorporateShareholders';
import OverallOwnershipStatistics from './OverallOwnershipStatistics';
import RegisteredHolderType from './RegisteredHolderType';
import ActiveVsPassive from './ActiveVsPassive';
import ActivityTrend from './ActivityTrend';
import AnalysisInvestorType from './AnalysisInvestorType';
import AnalysisHoldingStyle from './AnalysisHoldingStyle';
import AnalysisManagerStyle from './AnalysisManagerStyle';
import AnalysisCapGroup from './AnalysisCapGroup';
import AnalysisTurnover from './AnalysisTurnover';
import ShareholdersTable, { settingsBuilder as shareholdersTableSettingsBuilder } from './ShareholdersTable';
import Top25HoldingsTable from './InstitutionTop25HoldingsTable';
import InsiderTop25HoldingsTable from './InsiderTop25HoldingsTable';
import AnalysisGeographicCountry from './AnalysisGeographicCountry';
import AnalysisGeographicState from './AnalysisGeographicState';
import AnalysisGeographicMetro from './AnalysisGeographicMetro';
import SavedReportTemplatesWidget from './SavedReportTemplatesWidget';
import ShareholderInformation from './InstitutionShareholderInformation';
import InsiderShareholderInformation from './InsiderShareholderInformation';
import ShareholderBuysAndSells from './ShareholderBuysAndSells';
import ShareholderAnalysisTop10CapGroup from './ShareholderAnalysisTop10CapGroup';
import ShareholderAnalysisTop10Country from './ShareholderAnalysisTop10Country';
import ShareholderAnalysisTop10Sectors from './ShareholderAnalysisTop10Sectors';
import ShareholderAnalysisTop10Industry from './ShareholderAnalysisTop10Industry';
import InstitutionProfile from './InstitutionProfile';
import GeographicAnalysis from './GeographicAnalysis';
import RegisteredShareholderTransactionHistory from './RegisteredShareholderTransactionHistory';
import RegisteredShareholderPayments from './RegisteredShareholderPayments';
import RegisteredShareholderDRDSPP from './RegisteredShareholderDRDSPP';
import RegisteredShareholderDocuments from './RegisteredShareholderDocuments';
import RegisteredShareholderClientUploads from './RegisteredShareholderClientUploads';
import RegisteredShareholder1099DIV from './RegisteredShareholder1099DIV';
import RegisteredShareholder1099INT from './RegisteredShareholder1099INT';
import RegisteredShareholder1099B from './RegisteredShareholder1099B';
import RegisteredShareholder1099Misc from './RegisteredShareholder1099Misc';
import RegisteredShareholder1042S from './RegisteredShareholder1042S';
import RegisteredShareholderCurrent from './RegisteredShareholderCurrent';
import RegisteredShareholderPriorTADRSHistory from './RegisteredShareholderPriorTADRSHistory';
import RegisteredShareholderClosedSaleType from './RegisteredShareholderClosedSaleType';
import RegisteredShareholderClosedOtherType from './RegisteredShareholderClosedOtherType';
import HolderInvestorTypeAnalysis from './HolderInvestorTypeAnalysis';
import ControlBooksResult from './ControlBooksResult';
import ShareRangeAnalysisResults from './ShareRangeAnalysisResults';
import RegisteredShareholderInformation from './RegisteredShareholderInformation';
import PeerAnalysis from './PeerAnalysis';
import HistoricalAnalysisTable from './HistoricalAnalysisTable';
import ContactsLanding from './ContactsLanding';
import InstitutionsTable from './InstitutionsTable';
import FullQuote from './FullQuote';
import Performance from './Performance';
import PerformanceSmall from './PerformanceSmall';
import PlaceholderWidget from './PlaceholderWidget';
import ShareholderElections from './ShareholderElections';
import OutstandingPayments from './OutstandingPayments';
import BadAddresses from './BadAddresses';
import TaxCertificationStatus from './TaxCertificationStatus';
import DateLastContact from './DateLastContact';
import AuthorizedShares from './AuthorizedShares';
import CompareShareholdersAccountInfo from './CompareShareholdersAccountInfo';
import CompareShareholdersTransactionHistory from './CompareShareholdersTransactionHistory';
import CompareShareholdersPayments from './CompareShareholdersPayments';
import CompareShareholdersDRDSPP from './CompareShareholdersDRDSPP';
import CompareShareholdersDocuments from './CompareShareholdersDocuments';
import CompareShareholders1099DIV from './CompareShareholders1099DIV';
import CompareShareholders1099INT from './CompareShareholders1099INT';
import CompareShareholders1099B from './CompareShareholders1099B';
import CompareShareholders1099Misc from './CompareShareholders1099Misc';
import CompareShareholders1042S from './CompareShareholders1042S';
import AuthorizedUserList from './AuthorizedUserList';
import Notes from './Notes';
import MeetingPlannerTracker from './MeetingPlannerTracker';
import WatchlistWidget from './WatchlistWidget';

export const widgets = {
    [widgetTypes.overallOwnershipStatistics]: OverallOwnershipStatistics,
    [widgetTypes.contactDetails]: ContactDetails,
    [widgetTypes.corporateShareholders]: CorporateShareholders,
    [widgetTypes.registeredHolderType]: RegisteredHolderType,
    [widgetTypes.activeVsPassive]: ActiveVsPassive,
    [widgetTypes.activityTrend]: ActivityTrend,
    [widgetTypes.analysisInvestorType]: AnalysisInvestorType,
    [widgetTypes.analysisHoldingStyle]: AnalysisHoldingStyle,
    [widgetTypes.analysisManagerStyle]: AnalysisManagerStyle,
    [widgetTypes.analysisCapGroup]: AnalysisCapGroup,
    [widgetTypes.savedReportTemplates]: SavedReportTemplatesWidget,
    [widgetTypes.shareholdersTable]: ShareholdersTable,
    [widgetTypes.top25HoldingsTable]: Top25HoldingsTable,
    [widgetTypes.insiderTop25HoldingsTable]: InsiderTop25HoldingsTable,
    [widgetTypes.analysisTurnover]: AnalysisTurnover,
    [widgetTypes.analysisGeographicCountry]: AnalysisGeographicCountry,
    [widgetTypes.analysisGeographicState]: AnalysisGeographicState,
    [widgetTypes.analysisGeographicMetro]: AnalysisGeographicMetro,
    [widgetTypes.shareholderInformation]: ShareholderInformation,
    [widgetTypes.insiderShareholderInformation]: InsiderShareholderInformation,
    [widgetTypes.shareholderBuysAndSells]: ShareholderBuysAndSells,
    [widgetTypes.shareholderAnalysisTop10CapGroup]: ShareholderAnalysisTop10CapGroup,
    [widgetTypes.shareholderAnalysisTop10Country]: ShareholderAnalysisTop10Country,
    [widgetTypes.shareholderAnalysisTop10Sectors]: ShareholderAnalysisTop10Sectors,
    [widgetTypes.institutionProfile]: InstitutionProfile,
    [widgetTypes.shareholderAnalysisTop10Industry]: ShareholderAnalysisTop10Industry,
    [widgetTypes.geographicAnalysis]: GeographicAnalysis,
    [widgetTypes.holderInvestorTypeAnalysis]: HolderInvestorTypeAnalysis,
    [widgetTypes.controlBooksResults]: ControlBooksResult,
    [widgetTypes.registeredShareholderInformation]: RegisteredShareholderInformation,
    [widgetTypes.registeredShareholderTransactionHistory]: RegisteredShareholderTransactionHistory,
    [widgetTypes.registeredShareholderDRDSPP]: RegisteredShareholderDRDSPP,
    [widgetTypes.registeredShareholderPayments]: RegisteredShareholderPayments,
    [widgetTypes.registeredShareholderDocuments]: RegisteredShareholderDocuments,
    [widgetTypes.registeredShareholderClientUploads]: RegisteredShareholderClientUploads,
    [widgetTypes.registeredShareholder1099DIV]: RegisteredShareholder1099DIV,
    [widgetTypes.registeredShareholder1099INT]: RegisteredShareholder1099INT,
    [widgetTypes.registeredShareholder1099B]: RegisteredShareholder1099B,
    [widgetTypes.registeredShareholder1099Misc]: RegisteredShareholder1099Misc,
    [widgetTypes.registeredShareholder1042S]: RegisteredShareholder1042S,
    [widgetTypes.shareRangeAnalysisResults]: ShareRangeAnalysisResults,
    [widgetTypes.peerAnalysis]: PeerAnalysis,
    [widgetTypes.historicalAnalysisTable]: HistoricalAnalysisTable,
    [widgetTypes.contactsLanding]: ContactsLanding,
    [widgetTypes.contactsInstitutions]: InstitutionsTable,
    [widgetTypes.fullQuote]: FullQuote,
    [widgetTypes.performance]: Performance,
    [widgetTypes.performanceSmall]: PerformanceSmall,
    [widgetTypes.shareholderElections]: ShareholderElections,
    [widgetTypes.outstandingPayments]: OutstandingPayments,
    [widgetTypes.badAddresses]: BadAddresses,
    [widgetTypes.taxCertificationStatus]: TaxCertificationStatus,
    [widgetTypes.dateLastContact]: DateLastContact,
    [widgetTypes.authorizedShares]: AuthorizedShares,
    [widgetTypes.compareShareholdersAccountInfo]: CompareShareholdersAccountInfo,
    [widgetTypes.compareShareholdersTransactionHistory]: CompareShareholdersTransactionHistory,
    [widgetTypes.compareShareholdersDRDSPP]: CompareShareholdersDRDSPP,
    [widgetTypes.compareShareholdersPayments]: CompareShareholdersPayments,
    [widgetTypes.compareShareholdersDocuments]: CompareShareholdersDocuments,
    [widgetTypes.compareShareholders1099DIV]: CompareShareholders1099DIV,
    [widgetTypes.compareShareholders1099INT]: CompareShareholders1099INT,
    [widgetTypes.compareShareholders1099B]: CompareShareholders1099B,
    [widgetTypes.compareShareholders1099Misc]: CompareShareholders1099Misc,
    [widgetTypes.compareShareholders1042S]: CompareShareholders1042S,
    [widgetTypes.registeredShareholderCurrent]: RegisteredShareholderCurrent,
    [widgetTypes.registeredShareholderPriorTADRSHistory]: RegisteredShareholderPriorTADRSHistory,
    [widgetTypes.registeredShareholderClosedOtherType]: RegisteredShareholderClosedOtherType,
    [widgetTypes.registeredShareholderClosedSaleType]: RegisteredShareholderClosedSaleType,
    [widgetTypes.authorizedUserList]: AuthorizedUserList,
    [widgetTypes.notes]: Notes,
    [widgetTypes.meetingPlannerTracker]: MeetingPlannerTracker,

    // todo:
    [widgetTypes.shareholderAnalysisDistribution]: PlaceholderWidget,
    [widgetTypes.shareholderAnalysisRegion]: PlaceholderWidget,
    [widgetTypes.outstandingShares]: '',
    [widgetTypes.shareholderWatchlist]: WatchlistWidget
};

export const widgetSettingsBuilder = (pageId, widgetType, initialSettings = {}, userFeatures) => {
    const builder = settingsBuilders[widgetType];
    const defaultWidgetSettings = widgetSettings[widgetType];
    let settings;

    if (builder) {
        settings = builder(pageId, defaultWidgetSettings, userFeatures);
    } else {
        settings = defaultWidgetSettings;
    }

    return _.defaultsDeep({}, initialSettings, settings);
};

const settingsBuilders = {
    [widgetTypes.shareholdersTable]: shareholdersTableSettingsBuilder
};
