import PropTypes from 'prop-types';
import React from 'react';
import { Popover } from 'react-bootstrap';

import './FormWarning.scss';

const FormWarning = props => {
    return (
        <div className='form-warning'>
            <Popover
                id='form-warning-message'
                show
                placement='bottom'
                arrowOffsetLeft={20} >
                <span className='form-warning-message'>{props.warning}</span>
            </Popover>
        </div>
    );
};

FormWarning.propTypes = {
    warning: PropTypes.string
};

export default FormWarning;
