import PropTypes from 'prop-types';
import React from 'react';

import './ColoredSquare.scss';

const ColoredSquare = (props) => {
    const squareStyle = {
        backgroundColor: props.value,
        border: `0 solid ${props.value}`
    };

    return (
        <div
            className='colored-square'
            style={squareStyle}/>
    );
};

ColoredSquare.propTypes = {
    value: PropTypes.string
};
ColoredSquare.defaultProps = {
    value: 'transparent'
};

export default ColoredSquare;
