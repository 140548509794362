/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

import LinkEx from 'Components/LinkEx';
import { UNLIMITED, NOT_APPLICABLE } from 'Constants/undefined-values';
import Tooltip from 'Components/Tooltip';
import { NullableFormatter, NumberFormatter, LabelWithTooltip } from 'Components/formatters';
import { buildLocationWithSecurity } from 'Utils/routing';
import pageRoutes from 'Constants/page-routes';
import * as modal from 'State/modal';
import { handleTreasurySearchRequest } from 'State/advancedSearch';

const mapDispatchToProps = {
    showSelectSecurityModal: modal.showSelectSecurityModal,
    onTreasurySearchRequest: handleTreasurySearchRequest
};

const labelWithPossibleLinkCell = ({
    value,
    className,
    rowData: { className: dataClassName, esppSecurityIds, customParams } = {},
    showSelectSecurityModal,
    onTreasurySearchRequest } = {}
) => {
    if (_.get(esppSecurityIds, 'length') > 1) {
        const handleClick = () => {
            showSelectSecurityModal(esppSecurityIds);
        };

        return (
            <LabelWithTooltip
                value={NullableFormatter({ value })}
                onClick={handleClick}
                className={cn(className, dataClassName, 'active-link sign-color-default overfilling-row')}/>
        );
    } else if (_.get(esppSecurityIds, 'length') === 1) {
        return (
            <LinkEx to={buildLocationWithSecurity(pageRoutes.index, esppSecurityIds[0])}>
                <LabelWithTooltip
                    value={NullableFormatter({ value })}
                    className={cn(className, dataClassName, 'sign-color-default overfilling-row')}/>
            </LinkEx>
        );
    }

    if (_.get(customParams, 'includesTreasury')) {
        return (<LabelWithTooltip
            value={NullableFormatter({ value })}
            onClick={onTreasurySearchRequest}
            className={cn(className, dataClassName, 'active-link sign-color-default overfilling-row')}/>);
    }

    return (
        <LabelWithTooltip
            value={NullableFormatter({ value })}
            className={cn(className, dataClassName, 'overfilling-row')}/>
    );
};

labelWithPossibleLinkCell.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    rowData: PropTypes.object,
    showSelectSecurityModal: PropTypes.func,
    onTreasurySearchRequest: PropTypes.func
};

export const labelCell = ({
    value,
    className,
    rowData: { className: dataClassName } = {} } = {}
) => (
    <LabelWithTooltip
        value={NullableFormatter({ value })}
        className={cn(className, dataClassName, 'overfilling-row')}/>
);

labelCell.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    rowData: PropTypes.object
};

export const numberCell = v => {
    // if we have -1 it means that we have 'unlimited' total and 'N/A' unissued shares
    if (v.value === -1) {
        const isAggregateRow = v.rowData.rowClassName === 'aggregate-row';
        const messageKey = isAggregateRow ? UNLIMITED : NOT_APPLICABLE;

        return (
            <FormattedMessage id={messageKey}>
                {formattedMessage =>
                    <span title={formattedMessage}>{formattedMessage}</span>}
            </FormattedMessage>);
    }

    return (<NumberFormatter
        value={v.value}
        decimalPoints={3}
        withTitle/>);
};

export const headerCell = (title, tooltip) => (
    <span className='ellipsis'>
        <Tooltip
            content={tooltip ? tooltip : title}>
            <span>{title}</span>
        </Tooltip>
    </span>
);

export const LabelWithPossibleLinkCell = connect(null, mapDispatchToProps)(labelWithPossibleLinkCell);
