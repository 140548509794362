import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import './ButtonsGroup.scss';

const ButtonsGroup = (props) => {
    const { onSelect, buttons = [] } = props;

    const content = buttons.map((button, i) => {
        const className = cn('btn', button.isSelected ? 'active' : null);

        return (
            <button
                key={i}
                data-value={button.value}
                data-subtab-type={button.widgetType || null}
                className={className}
                onClick={() => onSelect(button.value)}
                type='button'>
                {button.title}
            </button>
        );
    });

    return (
        <div className='buttons-group'>
            {content}
        </div>
    );
};

ButtonsGroup.propTypes = {
    onSelect: PropTypes.func.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired
    }))
};

export default ButtonsGroup;
