import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import ReactTable from 'react-table';
import { AutoSizer } from 'react-virtualized';
import { intlShape, injectIntl } from 'react-intl';

import {
    commonWidgetPropTypes,
    commonWidgetActions
} from 'Components/widgets/utils';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import sortDirections from 'Constants/sort-directions';
import { sortArray, getColumnsMinWidth } from 'Utils/utils';
import { getSortDirection } from 'Components/Tables/utils';
import { getCustomComponent } from './utils';
import columnsDefinitions  from './constants/columnsDefinitions';
import CreateNoteLink from './components/CreateNoteLink';
import NotesInfoPanel from './components/NotesInfoPanel';

import './Notes.scss';

@injectIntl
class NotesContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        secondarySorting: PropTypes.shape({
            field: PropTypes.string,
            direction: PropTypes.string
        }),
        data: PropTypes.array,
        getTheadThProps: PropTypes.func,
        getTdProps: PropTypes.func,
        getColumnsDefinition: PropTypes.func,
        getDefaultTdProps: PropTypes.func,
        isPrint: PropTypes.bool,
        noDataMessageId: PropTypes.string,
        onChangeSortCriteria: PropTypes.func,
        isServerSorting: PropTypes.bool,
        intl: intlShape,
        onCreateNote: PropTypes.func,
        maxRows: PropTypes.number
    };

    static defaultProps = {
        isServerSorting: false
    };

    constructor(props) {
        super(props);

        this.setColumnsMinWidth(props.data, columnsDefinitions);
    }

    handleOnSort = (sortProps) => {
        const sortBy = _.get(sortProps, '[0].id');
        const { widgetSettings: { sortCriteria: { field: prevSortBy, direction: prevDirection } }, isServerSorting, onChangeSortCriteria } = this.props;

        if (isServerSorting) {
            onChangeSortCriteria(sortBy, columnsDefinitions);
        } else {
            const sortCriteria = {
                field: sortBy,
                direction: getSortDirection(
                    sortBy,
                    prevSortBy,
                    prevDirection,
                    columnsDefinitions
                ),
                initial: false
            };

            this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
        }
    };

    setColumnsMinWidth = (data, columns) => {
        this.columnsWidth = {};

        _.forEach(columns, column => {
            this.columnsWidth[column.columnName] = data && data.length
                ? getColumnsMinWidth(data, column, column.minWidth || column.width, this.props.intl)
                : column.minWidth;
        });
    };

    getColumnsDefinition = columns => {
        const { intl: { formatMessage } } = this.props;

        return columns
            .map((column) => {
                const Components = getCustomComponent(column.columnName);
                const style = { ...style, ...column.style };

                return {
                    Header: () => (
                        <Components.customHeadingComponent
                            title={column.displayName ? formatMessage({ id: column.displayName }) : ''}
                            style={style}/>
                    ),
                    Cell: (props) => (
                        <Components.customComponent
                            value={props.value}
                            style={style}/>
                    ),
                    Footer: null,
                    accessor: column.columnName,
                    minWidth: this.columnsWidth[column.columnName],
                    width: column.width,
                    sortable: !column.disableSort
                };
            });
    };

    getTheadThProps = (sortField, sortDirection, finalState, missProp, column) => {
        const defaultProps = {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };
        let additionalProps = {};

        if (this.props.getTheadThProps) {
            additionalProps = this.props.getTheadThProps(sortField, sortDirection, finalState, missProp, column) || {};
        }

        return {
            ...additionalProps,
            ...defaultProps
        };
    };

    getTdProps = (finalState, rowInfo, column) => {
        const defaultProps = this.props.getDefaultTdProps(column.id, rowInfo.row);
        const customProps = this.props.getTdProps && this.props.getTdProps(finalState, rowInfo, column) || {};

        return {
            ...defaultProps,
            ...customProps
        };
    };

    getNoDataProps = () => ({
        messageId: this.props.noDataMessageId || 'widgets.noDataMessage'
    });

    render() {
        const {
            data,
            secondarySorting = {},
            widgetSettings: { sortCriteria = {} } = {},
            mainColumnsWidth,
            isServerSorting,
            widgetData,
            intl: { formatMessage }
        } = this.props;
        const field = sortCriteria.sortField || sortCriteria.field;
        const direction = sortCriteria.sortDirection || sortCriteria.direction;
        const columns = this.getColumnsDefinition(columnsDefinitions, mainColumnsWidth);
        const sortedValues = isServerSorting ? data : sortArray({
            array: data,
            primaryField: field,
            descending: direction === sortDirections.DESC,
            secondaryField: secondarySorting.field,
            secondaryDescending: secondarySorting.direction === sortDirections.DESC,
            initial: sortCriteria.initial
        });
        const totals = _.get(widgetData, 'totals');
        const dataLength = _.get(widgetData, 'values.length', 0);

        return (
            <div className='widget-content' id='notes'>
                <NotesInfoPanel maxRows={this.props.maxRows}/>
                <div className='widget-content-header'>
                    <CreateNoteLink onCreateNote={this.props.onCreateNote}/>
                    { _.isNumber(totals) &&
                        <span className='total-records'>
                            {formatMessage({ id: 'notes.total' }, { count: dataLength, total: totals })}
                        </span>
                    }
                </div>
                <AutoSizer disableHeight>
                    {
                        ({ width }) => (
                            <div style={{ width }}>
                                <ReactTable
                                    showPagination={false}
                                    resizable={false}
                                    LoadingComponent={() => null}
                                    minRows={0}
                                    ThComponent={TheadComponent}
                                    TdComponent={TdComponent}
                                    getNoDataProps={this.getNoDataProps}
                                    NoDataComponent={NoResults}
                                    getTheadThProps={this.getTheadThProps.bind(this, field, direction)}
                                    getTdProps={this.getTdProps.bind(this)}
                                    manual
                                    defaultPageSize={0}
                                    resolvedData={sortedValues}
                                    onSortedChange={this.handleOnSort}
                                    columns={columns || []}/>
                            </div>
                        )
                    }
                </AutoSizer>
                <CreateNoteLink onCreateNote={this.props.onCreateNote}/>
            </div>
        );
    }
}

export default NotesContent;
