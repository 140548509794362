import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';

import { getCurrentFeaturesSelector } from 'State/features/selectors';
import { setDropzoneInputProps, uploadDocument } from 'State/uploadDocument/actions';
import featureTypes from 'Constants/feature-types';
import { notifyError, notifySuccess, notifyInfo, notifyWarning, removeNotificationByType } from 'State/notifier/actions';
import { getDynamicPageParamsSelector } from 'State/dynamicPage/selectors';
import { fileExtensionsMap, fileMIMETypesMap } from 'Constants/file-formats';
import { fileSizeFormatter } from 'Components/formatters/formatters';
import { getMaxUploadFileSizeSizeSelector } from 'State/user/selectors';
import { isUploadDocumentFetchingSelector } from 'State/uploadDocument/selectors';
import { showInfoModal } from 'State/modal';
import { setInProgress, removeInProgress } from 'Utils/utils';
import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';

const mapStateToProps = state => ({
    currentFeatures: getCurrentFeaturesSelector(state),
    pageParams: getDynamicPageParamsSelector(state),
    maxFileSize: getMaxUploadFileSizeSizeSelector(state),
    isUploadDocumentFetching: isUploadDocumentFetchingSelector(state)
});
const mapDispatchToProps = {
    setDropzoneInputProps,
    uploadDocument,
    notifyError,
    showInfoModal,
    notifySuccess,
    notifyInfo,
    notifyWarning,
    removeNotificationByType
};

const withDropzone = WrappedComponent => {
    @injectIntl
    @connect(mapStateToProps, mapDispatchToProps)
    class WithDropzone extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withDropzone');

        static propTypes = {
            ...WrappedComponent.propTypes,
            currentFeatures: PropTypes.object,
            setDropzoneInputProps: PropTypes.func,
            uploadDocument: PropTypes.func,
            pageParams: PropTypes.object,
            notifyError: PropTypes.func,
            intl: intlShape,
            maxFileSize: PropTypes.number,
            isUploadDocumentFetching: PropTypes.bool,
            showInfoModal: PropTypes.func,
            notifySuccess: PropTypes.func,
            notifyInfo: PropTypes.func,
            notifyWarning: PropTypes.func,
            removeNotificationByType: PropTypes.func
        };

        componentDidMount() {
            this.props.setDropzoneInputProps(this.dropzoneInputProps);
        }

        handleDrop = acceptedFiles => {
            const { isUploadDocumentFetching } = this.props;

            if (!isUploadDocumentFetching && _.get(acceptedFiles, 'length') > 0) {
                setInProgress();
                this.uploadFiles(acceptedFiles);
            }
        };

        uploadFiles = (files = []) => {
            const { intl: { formatMessage }, pageParams = {}, maxFileSize } = this.props;
            const currentFileType = _.get(files[0], 'type');
            const currentFileSize = _.get(files[0], 'size');
            const currentFileName = _.get(files[0], 'name');
            const remainFiles = files.slice(1);

            const isValidFileType = currentFileType && _.some(fileMIMETypesMap, type => type === currentFileType);
            const isValidFileExt = currentFileName && _.some(fileExtensionsMap, ext => _.endsWith(_.toLower(currentFileName), ext));

            if (!isValidFileType || !isValidFileExt) {
                this.props.notifyError(
                    null,
                    formatMessage(
                        { id: 'uploadDocuments.notifier.wrongFileType' },
                        { fileName: files[0].name })
                );

                if (remainFiles.length > 0) {
                    this.uploadFiles(remainFiles);
                } else {
                    removeInProgress();
                }

                return;
            }

            if (!currentFileSize || currentFileSize === 0) {
                this.props.notifyError(
                    null,
                    formatMessage(
                        { id: 'uploadDocuments.notifier.emptyFile' },
                        { fileName: files[0].name })
                );

                if (remainFiles.length > 0) {
                    this.uploadFiles(remainFiles);
                } else {
                    removeInProgress();
                }

                return;
            }

            if (currentFileSize > maxFileSize) {
                this.props.notifyError(
                    null,
                    formatMessage(
                        { id: 'uploadDocuments.notifier.toLargeFileSize' },
                        {
                            fileName: files[0].name,
                            maxSize: fileSizeFormatter(maxFileSize, 0)
                        })
                );

                if (remainFiles.length > 0) {
                    this.uploadFiles(remainFiles);
                } else {
                    removeInProgress();
                }

                return;
            }

            this.props.notifyInfo(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.inProgress' },
                    { fileName: files[0].name }),
                {
                    timeOut: 0,
                    className: 'notification-info',
                    removeOnHover: false
                }
            );

            this.props.showInfoModal({
                titleKey: 'modals.clientUpload.title',
                messageKey: 'modals.clientUpload.message',
                okTextKey: 'modals.clientUpload.okText',
                isHtmlMessage: true
            });

            return this.props.uploadDocument(files[0], pageParams.shareholderId)
                .then(uploadStatus => {
                    this.props.removeNotificationByType('info');

                    if (uploadStatus === uploadedDocumentStatuses.SECURITY_CHECK_FAILED) {
                        this.props.notifyWarning(
                            null,
                            formatMessage(
                                { id: 'uploadDocuments.notifier.securityCheckFailed' },
                                { fileName: files[0].name })
                        );
                    } else if (uploadStatus === uploadedDocumentStatuses.FAILED) {
                        this.props.notifyError(
                            null,
                            formatMessage(
                                { id: 'uploadDocuments.notifier.failed' },
                                { fileName: files[0].name })
                        );
                    } else {
                        this.props.notifySuccess(
                            null,
                            formatMessage(
                                { id: 'uploadDocuments.notifier.success' },
                                { fileName: files[0].name })
                        );
                    }
                })
                .catch(() => {
                    this.props.removeNotificationByType('info');
                    this.props.notifyError(
                        null,
                        formatMessage(
                            { id: 'uploadDocuments.notifier.error' },
                            { fileName: files[0].name })
                    );
                })
                .finally(() => {
                    if (remainFiles.length > 0) {
                        this.uploadFiles(remainFiles);
                    } else {
                        removeInProgress();
                        this.props.onUpdateWidgetData({ shareholderId: pageParams.shareholderId });
                    }
                });
        };

        render() {
            const { currentFeatures, isUploadDocumentFetching } = this.props;
            const hasPermission = currentFeatures[featureTypes.UPLOAD_DOCUMENT];

            return hasPermission
                ? (<Dropzone
                    onDrop={this.handleDrop}
                    multiple
                    noClick>
                    {({ getRootProps, getInputProps, isDragActive }) => {
                        const dropzoneProps = {
                            ...getRootProps(),
                            tabIndex: undefined
                        };

                        this.dropzoneInputProps = {
                            ...getInputProps(),
                            multiple: true,
                            onChange: this.handleDrop
                        };

                        return (
                            <WrappedComponent
                                {...this.props}
                                dropzoneProps={dropzoneProps}
                                isDragActive={!isUploadDocumentFetching && isDragActive}/>
                        );
                    }}
                </Dropzone>)
                : <WrappedComponent {...this.props}/>;
        }
    }

    return WithDropzone;
};

export default withDropzone;
