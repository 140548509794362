import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { changeHolderInvestorTypeAnalysisRouteState } from 'State/routeState';
import { getHolderInvestorTypeAnalysisRouteState } from 'State/routeState/selectors';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import HolderInvestorTypeAnalysisWidgetControlPanel from './components/HolderInvestorTypeAnalysisControlPanel';
import HolderInvestorTypeAnalysisContent from './components/HolderInvestorTypeAnalysisContent';
import NullComponent from '../Widget/components/NullComponent';

import './HolderInvestorTypeAnalysis.scss';

const ROUTE_STATE_PARAMS = ['shareholderType'];

const mapStateToProps = (state) => ({
    routeState: getHolderInvestorTypeAnalysisRouteState(state)
});

const mapDispatchToProps = { changeHolderInvestorTypeAnalysisRouteState };

@connect(mapStateToProps, mapDispatchToProps)
class HolderInvestorTypeAnalysis extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        routeState: PropTypes.object,
        changeHolderInvestorTypeAnalysisRouteState: PropTypes.func
    };

    constructor(props) {
        super(props);

        // settings that can come from the route state
        this.settings = {};

        this.widgetComponents = {
            WidgetControlPanel: HolderInvestorTypeAnalysisWidgetControlPanel,
            WidgetContent: HolderInvestorTypeAnalysisContent,
            WidgetHeader: NullComponent
        };

        this.widgetEvents = {
            onBuildDataParams: this.onBuildDataParams,
            handleUpdateWidgetParams: this.handleUpdateWidgetParams
        };
    }

    UNSAFE_componentWillMount() {
        const { routeState = {}, widgetSettings = {} } = this.props;
        const filledParams = _.omitBy(routeState, param => !param);

        if (_.values(filledParams).length) {
            this.props.onUpdateWidgetSettings({
                ...this.props.widgetSettings,
                ...filledParams
            });
            this.props.changeHolderInvestorTypeAnalysisRouteState({
                ..._.mapValues(filledParams, () => null)
            });
        }

        _.forOwn(widgetSettings, (value, key) => {
            if (_.includes(ROUTE_STATE_PARAMS, key)) {
                this.settings[key] = routeState[key] ||
                    _.get(this.props, ['widgetSettings', key]);
            }
        });
    }

    handleUpdateWidgetParams = (params) => {
        this.dataParams = {
            ...this.dataParams,
            ...params
        };

        _.forOwn(params, (value, key) => {
            if (_.includes(ROUTE_STATE_PARAMS, key)) {
                this.settings[key] = params[key];
            }
        });

        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            ...params
        });
        this.props.onUpdateWidgetData({ ...this.dataParams });
    };

    onBuildDataParams = () => {
        this.dataParams = {
            ...this.settings
        };
        return this.dataParams;
    };

    render() {
        return (
            <Widget
                {...this.props}
                className='holder-investor-type-analysis'
                components={this.widgetComponents}
                events={this.widgetEvents}
                settings={this.settings}/>
        );
    }
}

export default HolderInvestorTypeAnalysis;
