import React, { Component } from 'react';
import { getFormValues, reduxForm, propTypes as reduxFormPropTypes } from 'redux-form';
import { connect } from 'react-redux';

import { runReport } from 'State/exportReports';
import reportExportFormats from 'Constants/export-report-formats';
import reportTypes from 'Constants/report-types';
import PropTypes from 'prop-types';
import { ExportShareholderContactsModalView } from './components/ExportShareholderContactsModalView';

const FORM_NAME = 'exportShareholderContactsForm';
const INITIAL_VALUES = {
    selectedFormat: reportExportFormats.EXCEL
};

const mapStateToProps = (state) => ({
    formValues: getFormValues(FORM_NAME)(state)
});

const mapDispatchToProps = { runReport };

@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: FORM_NAME,
    initialValues: INITIAL_VALUES
})
class ExportShareholderContactsModal extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        hideModal: PropTypes.func,
        runReport: PropTypes.func,
        shareholderId: PropTypes.string
    };

    handleExport = ({ selectedFormat }) => {
        const { hideModal, shareholderId } = this.props;

        hideModal();

        this.props.runReport(reportTypes.proxyContactTemplate,
            null,
            selectedFormat,
            { shareholderId }
        );
    };

    render() {
        const { hideModal, handleSubmit } = this.props;

        return (
            <ExportShareholderContactsModalView
                onExport={this.handleExport}
                onCancel={hideModal}
                handleSubmit={handleSubmit}/>
        );
    }
}

export { ExportShareholderContactsModal };
