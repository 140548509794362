import PropTypes from 'prop-types';

export const peerShape = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    ticker: PropTypes.string
});

export const operationTypes = {
    GET_PEERS: 'GET_PEERS',
    ADD_PEER: 'ADD_PEER',
    DEL_PEER: 'DEL_PEER',
    RESET_PEERS: 'RESET_PEERS',
    FIND_PEERS: 'FIND_PEERS'
};

export const minLengthTerm = 3;
export const maxLengthTerm = 100;
