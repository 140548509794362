import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withState } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { debounce, get } from 'lodash';

import { logoutUser, acceptUser, getAcceptTerms } from 'State/user';
import PageHeader from 'Components/PageHeader';
import AppFooter from 'Components/AppFooter';
import { EquinitiFooter } from 'Components/EquinitiFooter';
import TermsText from 'Components/TermsText';
import { ModalRoot } from 'Components/Modals';
import LoadingBlock from 'Components/LoadingBlock';
import sanitizeHtml from 'Utils/sanitizeHtml';
import timeouts from 'Constants/config';

import './ContractPage.scss';

const CONTENT_HEIGHT = 500;
const CONTENT_PADDING = 40;
const mapStateToProps = state => ({
    acceptTerms: getAcceptTerms(state)
});
const mapDispatchToProps = { logoutUser, acceptUser };

@withState('disabled', 'setIsDisabled', true)
@connect(mapStateToProps, mapDispatchToProps)
class ContractPage extends Component {
    static propTypes = {
        acceptTerms: PropTypes.string,
        logoutUser: PropTypes.func.isRequired,
        acceptUser: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        setIsDisabled: PropTypes.func.isRequired
    };

    componentDidMount() {
        setTimeout(() => {
            const scrollHeight = get(this.content, 'scrollHeight', 0);

            if (scrollHeight + CONTENT_PADDING <= CONTENT_HEIGHT) {
                this.props.setIsDisabled(false);
            }
        }, 500);
    }

    handleContractScroll = (e) => {
        e.persist();
        this.onContractScroll(e);
    };

    handleAccept = () => {
        this.props.acceptUser();
    };

    handleDecline = () => {
        this.props.logoutUser();
    };

    onContractScroll = debounce((e) => {
        const { scrollTop, scrollHeight, offsetHeight } = e.nativeEvent.target;

        if (scrollTop + CONTENT_PADDING >= scrollHeight - offsetHeight) {
            this.props.setIsDisabled(false);
        }
    }, timeouts.throttleTimeout);

    render() {
        const { disabled, acceptTerms } = this.props;
        const clearAcceptTerms = acceptTerms && sanitizeHtml(acceptTerms);
        const acceptTermsText = (<div dangerouslySetInnerHTML={{ __html: clearAcceptTerms }}/>); // eslint-disable-line react/no-danger

        return (
            <div className='contract-page'>
                <PageHeader isProfileMenu={false}/>
                <div className='modal-overlay'>
                    <div className='contract-container'>
                        <div
                            className='contract-content'
                            ref={(content) => {
                                this.content = content;
                            }}
                            onScroll={this.handleContractScroll}>
                            <TermsText>
                                {acceptTermsText}
                            </TermsText>
                        </div>
                        <div className='btn-panel'>
                            <button
                                type='button'
                                className='btn btn-large accept-btn'
                                disabled={disabled}
                                onClick={this.handleAccept}>
                                <FormattedMessage id='contract.accept' />
                            </button>
                            <button
                                type='button'
                                className='btn btn-large'
                                disabled={disabled}
                                onClick={this.handleDecline}>
                                <FormattedMessage id='contract.decline' />
                            </button>
                        </div>
                    </div>
                </div>
                {DEMO_MODE ? <EquinitiFooter/> : <AppFooter />}
                <ModalRoot/>
                <LoadingBlock/>
            </div>
        );
    }
}

export default ContractPage;
