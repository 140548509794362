import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import cn from 'classnames';

import './AccordionView.scss';

const onToggle = () => {};

const AccordionView = (props) => {
    const { triggerStatus, isOpen, title, children } = props;

    return (
        <div className='accordion'>
            <div onClick={triggerStatus} className='accordion-header'>
                <span className={cn('font-icon',
                    isOpen ? 'font-icon-arrow-drop-down' : 'font-icon-paging-right'
                )}/>
                {title}
            </div>
            <Panel
                expanded={isOpen}
                onToggle={onToggle}>
                <Panel.Body collapsible>
                    {children}
                </Panel.Body>
            </Panel>
        </div>
    );
};

AccordionView.propTypes = {
    triggerStatus: PropTypes.func,
    isOpen: PropTypes.bool,
    title: PropTypes.any,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default AccordionView;
