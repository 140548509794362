import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { hideModal } from 'State/modal';
import ConfirmModalView from './ConfirmModalView';

const mapStateToProps = (state) => {
    return { ...state.modal };
};
const mapDispatchToProps = { hideModal };

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
class ConfirmModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        isHtmlMessage: PropTypes.bool,
        messageKey: PropTypes.string,
        customClass: PropTypes.string,
        onCancel: PropTypes.func,
        okTextKey: PropTypes.string,
        cancelTextKey: PropTypes.string,
        payload: PropTypes.any,
        preventCloseOnSuccess: PropTypes.bool,
        showExcelLimit: PropTypes.bool
    };

    onSuccessThenClose = () => {
        const { onSuccess, preventCloseOnSuccess } = this.props;

        if (!preventCloseOnSuccess) {
            this.props.hideModal();
        }
        onSuccess();
    };

    render() {
        const { intl: { formatMessage }, isHtmlMessage,  ...props } = this.props;

        const newProps = {
            messageKey: props.messageKey || 'modals.confirmModal.message',
            customClass: props.customClass,
            isHtmlMessage,
            messageValues: {
                itemName: props.payload || ''
            },
            okText: formatMessage({ id: props.okTextKey || 'common.ok' }),
            cancelText: formatMessage({ id: props.cancelTextKey || 'common.cancel' }),
            onSuccess: this.onSuccessThenClose,
            onCancel: props.onCancel || props.hideModal,
            showExcelLimit: props.showExcelLimit
        };

        return (
            <ConfirmModalView {...newProps}/>
        );
    }
}

export default ConfirmModal;
