import React, { useMemo, useCallback } from 'react';
import * as _ from 'lodash';
import { withProps, compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import Widget from 'Components/widgets/Widget';
import reportTypes from 'Constants/report-types';
import NotesContent from './NotesContent';
import NotesControlPanel from './components/NotesControlPanel';
import { getTdProps, getEntityTypeByRoute, getEntityIdByType } from './utils';
import RegisteredOverviewTabsActionPanel from '../_commonComponents/RegisteredOverviewTabsActionPanel';
import { isFetchingSelector, runReport } from 'State/exportReports';
import { getSlotsForRegisteredActionPanelSelector } from 'State/dynamicPage/selectors';
import { columnNames } from './constants/columnsDefinitions';
import entityTypes from 'Constants/entity-types';
import { showCreateNoteModal, hideModal } from 'State/modal';
import { createNote } from 'State/notes/actions';
import { notifySuccess, notifyError } from 'State/notifier/actions';
import NotesHeader from './components/NotesHeader';
import fileFormats from 'Constants/export-report-formats';
import { getSortDirection } from '../../Tables/utils';

import './Notes.scss';

const MAX_ROW_COUNT = 50;

const mapStateToProps = state => ({
    availableSlots: getSlotsForRegisteredActionPanelSelector(state),
    isFetchingReport: isFetchingSelector(state)
});

const mapDispatchToProps = {
    runReport,
    showCreateNoteModal,
    hideModal,
    createNote,
    notifySuccess,
    notifyError
};

const Notes = props => {
    const widgetParams = {
        entityId: props.entityId,
        entityType: props.entityType,
        filterCriteria: {
            currentPage: 1,
            pageSize: MAX_ROW_COUNT
        },
        sortCriteria: props.widgetSettings.sortCriteria
    };
    const widgetEvents = useMemo(() => ({
        onBuildDataParams: () => widgetParams
    }), []);

    const widgetComponents = useMemo(() => ({
        WidgetActionPanel: props.entityType === entityTypes.REGISTERED && RegisteredOverviewTabsActionPanel,
        WidgetContent: NotesContent,
        WidgetControlPanel: NotesControlPanel,
        WidgetHeader: props.entityType !== entityTypes.REGISTERED && NotesHeader
    }), [props.entityType]);

    const handleCreateNote = useCallback(() => {
        const { entityId, entityType } = props;

        props.showCreateNoteModal({
            onSuccess: noteText =>
                props.createNote(noteText, entityId, entityType).then(
                    () => {
                        props.hideModal();
                        props.notifySuccess(
                            null,
                            <FormattedHTMLMessage id={'notes.notifier.createNote.success'}/>
                        );
                        props.onUpdateWidgetData(widgetParams);
                    },
                    () => props.notifyError(
                        null,
                        <FormattedHTMLMessage id={'notes.notifier.createNote.error'}/>
                    )
                ),
            onCancel: props.hideModal
        });
    }, [props.widgetSettings.sortCriteria]);

    const handleExport = useCallback(() => {
        const { widgetSettings = {}, entityId, entityType, widgetPageParams } = props;
        const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
        const selectedColumns = _.get(widgetSettings, 'selectedColumns', []);
        const reportTemplateType = reportTypes.notesTemplate;
        const shareholderId = entityType === entityTypes.CONTACT
            ? _.get(widgetPageParams, 'shareholderId') : undefined;
        const exportParams = {
            entityId,
            entityType,
            shareholderId,
            sortCriteria: {
                sortDirection: sortCriteria.sortDirection,
                sortField: sortCriteria.sortField,
                initial: sortCriteria.initial
            },
            columnsToDisplay: selectedColumns
        };

        props.runReport(reportTemplateType, null, fileFormats.PDF, exportParams);
    }, [props.widgetSettings, props.entityId, props.entityType]);

    const widgetControlPanelSettings = useMemo(() => ({
        onExport: handleExport,
        inactive: !_.get(props.data, 'length') || props.isFetchingReport,
        className: props.entityType === entityTypes.REGISTERED ? 'registered-overview-control-panel' : undefined
    }), [props.isFetchingReport, props.entityType, props.widgetData]);

    const getDefaultTdProps = useCallback((columnType, row) => {
        if (columnType === columnNames.DELETE) {
            return {
                ...getTdProps(columnType, row),
                additionalData: {
                    onDeleteNote: () => props.onUpdateWidgetData(widgetParams)
                }
            };
        }

        return ({
            ...getTdProps(columnType, row)
        });
    }, [props.onUpdateWidgetData, props.widgetSettings.sortCriteria]);

    const handleChangeSortCriteria = useCallback((sortBy, columnsDefinitions) => {
        const { widgetSettings: { sortCriteria: { sortField: prevSortBy, sortDirection: prevDirection } } } = props;
        const sortCriteria = {
            sortField: sortBy,
            sortDirection: getSortDirection(
                sortBy,
                prevSortBy,
                prevDirection,
                columnsDefinitions
            )
        };

        props.onUpdateWidgetSettings({ ...props.widgetSettings, sortCriteria });
        props.onUpdateWidgetData({ ...widgetParams, sortCriteria  });
    }, [props.widgetSettings, props.onUpdateWidgetSettings]);

    return (
        <Widget
            {...props}
            className='notes'
            onCreateNote={handleCreateNote}
            getDefaultTdProps={getDefaultTdProps}
            widgetControlPanelSettings={widgetControlPanelSettings}
            events={widgetEvents}
            components={widgetComponents}
            onChangeSortCriteria={handleChangeSortCriteria}
            isServerSorting
            maxRows={MAX_ROW_COUNT}/>
    );
};

Notes.propTypes = {
    ...commonWidgetPropTypes,
    ...commonWidgetActions,
    runReport: PropTypes.func,
    availableSlots: PropTypes.array,
    isFetchingReport: PropTypes.bool,
    showCreateNoteModal: PropTypes.func,
    hideModal: PropTypes.func,
    createNote: PropTypes.func,
    notifySuccess: PropTypes.func,
    notifyError: PropTypes.func
};

export default compose(
    withRouter,
    withProps(props => {
        const entityType = getEntityTypeByRoute(props.location.pathname);
        const entityId = getEntityIdByType(_.get(props, 'widgetPageParams', {}), entityType);

        return ({
            data: _.get(props, 'widgetData.values', []).map(
                note => ({
                    ...note,
                    author: note.authorFullName,
                    delete: {
                        entityId,
                        entityType,
                        noteId: note.id,
                        canDelete: note.canDelete
                    }
                })
            ),
            entityId,
            entityType
        });
    }),
    connect(mapStateToProps, mapDispatchToProps)
)(Notes);
