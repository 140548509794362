import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import UserAvatar from 'Components/UserAvatar';

import './PersonalInfo.scss';

const PersonalInfo = ({
    userData: {
        firstName,
        lastName,
        title,
        email,
        systemRole
    },
    onOAMAction }) => {
    return (
        <div className='personal-info'>

            <div className='personal-info-header'>
                <h2>
                    <FormattedMessage id='user.profile.card.title'/>
                </h2>
            </div>

            <div className='personal-info-content'>
                <div className='personal-info-image'>
                    <UserAvatar className='profile-avatar' altMessageId='user.profile.card.avatar.alt'/>
                </div>
                <div className='personal-info-data-container'>
                    <div className='personal-info-data'>
                        <div className='user-name'>{`${firstName} ${lastName}`}</div>
                        <div className='user-title'>{title}</div>
                        <div className='user-email'>
                            {email &&
                            <a href={`mailto:${email}`}>{email}</a>
                            }
                        </div>
                        {systemRole &&
                        <div className='user-role ellipsis' title={systemRole}>
                            <FormattedMessage id='user.profile.card.role' values={{ value: systemRole }}/>
                        </div>}

                    </div>
                    <ul>
                        {window.CHANGE_PASSWORD_URL && <li className='change-password'>
                            <span className='link' onClick={onOAMAction(window.CHANGE_PASSWORD_URL)}>
                                <FormattedMessage id='user.profile.links.change.password'/></span>
                        </li>}
                    </ul>
                </div>
            </div>
        </div>
    );
};

PersonalInfo.propTypes = {
    userData: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        title: PropTypes.string,
        email: PropTypes.string,
        systemRole: PropTypes.string
    }),
    onOAMAction: PropTypes.func
};

export default PersonalInfo;
