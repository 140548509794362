import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import widgetModes from 'Constants/widget-modes';
import TaxCertificationStatusChartView from './TaxCertificationStatusChartView';
import TaxCertificationStatusTableView from './TaxCertificationStatusTableView';
import NoDataComponent from '../../Widget/components/NoDataComponent';

class TaxCertificationStatusContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    render() {
        const { widgetSettings: { mode }, widgetFooterProps, isFetching, widgetNoData } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    {mode === widgetModes.CHART ?
                        <TaxCertificationStatusChartView
                            widgetFooterProps={widgetFooterProps}
                            {...this.props}/> :
                        <TaxCertificationStatusTableView
                            widgetFooterProps={widgetFooterProps}
                            {...this.props}/>
                    }
                </NoDataComponent>
            </div>
        );
    }
}

export default TaxCertificationStatusContent;
