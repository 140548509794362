import TraceKit from 'tracekit';
import { push } from 'connected-react-router';
import _ from 'lodash';

import logger from './logger';
import store from './store';
import pageRoutes from 'Constants/page-routes';

const ERROR_SOURCE = {
    JAVASCRIPT: 'JAVASCRIPT',
    NETWORK: 'NETWORK',
    SESSION_TIMEOUT: 'SESSION_TIMEOUT',
    EXCHANGE: 'EXCHANGE'
};

/**
 * See TraceKit source https://github.com/csnover/TraceKit/blob/master/tracekit.js#L16
 * @type {string}
 */
const UNKNOWN_FUNCTION = '?';

/**
 * Helper wrap function without lost original stack
 * @param _func function for wrapped
 * @param origStack is original stack
 * @returns {func._wrapped|*} wrapped function
 */
function wrapFn(_func, origStack) {
    const func = _func;

    if (!func._wrapped) {
        func._wrapped = function _wrapped() {
            try {
                func.apply(this, arguments);
            } catch (e) {
                const exception = TraceKit.computeStackTrace(e);

                exception.stack = origStack;
                handleException(exception, true);

                throw e;
            }
        };
    }
    return func._wrapped;
}

/**
 * Helper wrap time function without lost original stack
 * @param orig is original callback
 * @returns {wrap}
 */
function wrapTimeFn(orig) {
    return function wrap() {
        const args = new Array(arguments.length);

        for (let i = 0; i < args.length; ++i) {
            args[i] = arguments[i];
        }
        const originalCallback = args[0];

        if (_.isFunction(originalCallback)) {
            const origStack = TraceKit.computeStackTrace(new Error()).stack;
            const splicedStack = origStack && origStack.splice(1);

            args[0] = wrapFn(originalCallback, splicedStack);
        }

        if (orig.apply) {
            return orig.apply(this, args);
        }
        return orig(args[0], args[1]);
    };
}

window.setTimeout = wrapTimeFn(window.setTimeout);
window.setInterval = wrapTimeFn(window.setInterval);

TraceKit.remoteFetching = false;

TraceKit.report.subscribe((exception) => {
    const stackLen = exception.stack.length;

    const lastStack = exception.stack[stackLen - 1];

    if (stackLen && lastStack.func !== '_wrapped' && lastStack.func !== UNKNOWN_FUNCTION) {
        handleException(exception, true);
    }
});

/**
 * Handle unhandled exception
 * @param {Error} exception - exception
 * @param {boolean} isCritical = false - flag is exception critical. if flag equaled true it would show error page.
 */
function handleException(exception, isCritical = false) {
    const errorPayload = {};

    try {
        errorPayload.isCritical = isCritical;
        errorPayload.name = exception.name;
        errorPayload.errorSource = ERROR_SOURCE.JAVASCRIPT;
        errorPayload.errorMessage = exception.message;
        errorPayload.stack = exception.stack;

        if (isCritical) {
            store.dispatch(push(pageRoutes.error));
        }
        logger.error(errorPayload);
    } catch (ex) {
        console.log(ex);
    }
}

/**
 * Handle http errors
 * @param {Error} error - http error
 */
function handleXhrError(error) {
    let isCritical = false;
    const errorPayload = {};
    const response = error.response || {};
    const request = response.request || {};
    const computedTrace = TraceKit.computeStackTrace(error);

    try {
        // TODO analyse all status codes
        if (error.status >= 500) {
            isCritical = true;
        }

        errorPayload.isCritical = isCritical;
        errorPayload.name = computedTrace.name;
        errorPayload.errorSource = ERROR_SOURCE.NETWORK;
        errorPayload.errorMessage = error.message;
        errorPayload.stack = computedTrace.stack;
        errorPayload.httpMethod = error.config.method;
        errorPayload.requestParams = errorPayload.httpMethod === 'GET' ? error.config.params : error.config.data;
        errorPayload.requestUrl = error.config.url;
        errorPayload.statusCode = response.status;
        errorPayload.statusText = response.statusText;
        errorPayload.responseHeaders = response.headers;
        errorPayload.responseText = request.responseText;
        errorPayload.widgetId = error.config.widgetId;

        logger.error(errorPayload);
    } catch (ex) {
        console.log(ex);
    }
}

/**
 * Handle incorrect exchange
 * @param {Object} xhr - http response
 */
function handleIncorrectExchange(xhr) {
    const errorPayload = {};
    const request = xhr.request || {};
    const computedTrace = TraceKit.computeStackTrace(xhr);

    try {
        errorPayload.isCritical = false;
        errorPayload.name = computedTrace.name;
        errorPayload.errorSource = ERROR_SOURCE.EXCHANGE;
        errorPayload.errorMessage = computedTrace.message;
        errorPayload.stack = computedTrace.stack;
        errorPayload.httpMethod = xhr.config.method;
        errorPayload.requestParams = errorPayload.httpMethod === 'GET' ? xhr.config.params : xhr.config.data;
        errorPayload.requestUrl = xhr.config.url;
        errorPayload.statusCode = xhr.status;
        errorPayload.statusText = xhr.statusText;
        errorPayload.responseHeaders = xhr.headers;
        errorPayload.responseText = request.responseText;
        errorPayload.widgetId = xhr.config.widgetId;

        logger.error(errorPayload);
    } catch (ex) {
        console.log(ex);
    }
}

function handleSessionTimeout() {
    logger.warn({
        errorSource: ERROR_SOURCE.SESSION_TIMEOUT
    });
}

export default {
    handleException,
    handleXhrError,
    handleSessionTimeout,
    handleIncorrectExchange
};
