import React, { Component } from 'react';

import Layout from '../Layout';

export default class VerticalLayout extends Component {
    static propTypes = {
        ...Layout.propTypes
    };

    render() {
        return (<Layout {...this.props} column>
            {this.props.children}
        </Layout>);
    }
}
