import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withProps } from '@shakacode/recompose';
import cn from 'classnames';
import { clearFields } from 'redux-form';
import _ from 'lodash';

import { CheckBox } from 'Components/CheckBox';

const mapDispatchToProps = dispatch => {
    return {
        clearFormFields: (formName, ...fieldsNames) => {
            dispatch(clearFields(formName, false, false, ...fieldsNames));
        }
    };
};

const withCheckboxFormDateRangePicker = (Comp) => {
    @withProps(props => {
        const { dateRangeOptions: { formName, startDateOptions, endDateOptions, checkboxOptions } } = props;
        const startField = _.get(props, startDateOptions.name);
        const endField = _.get(props, endDateOptions.name);
        const checkboxField = _.get(props, checkboxOptions.name);

        return {
            formName,
            startField,
            endField,
            checkboxField
        };
    })
    @connect(null, mapDispatchToProps)
    class WithCheckboxFormDateRangePicker extends Component {
        static propTypes = {
            formName: PropTypes.string.isRequired,
            dateRangeOptions: PropTypes.shape({
                startDateOptions: PropTypes.shape({
                    label: PropTypes.string,
                    placeholder: PropTypes.string,
                    name: PropTypes.string
                }),
                endDateOptions: PropTypes.shape({
                    label: PropTypes.string,
                    placeholder: PropTypes.string,
                    name: PropTypes.string
                }),
                checkboxOptions: PropTypes.shape({
                    label: PropTypes.string,
                    name: PropTypes.string
                })
            }),
            className: PropTypes.string,
            startField: PropTypes.object.isRequired,
            endField: PropTypes.object.isRequired,
            checkboxField: PropTypes.object.isRequired,
            clearFormFields: PropTypes.func,
            names: PropTypes.arrayOf(PropTypes.string)
        };

        handleCheckboxChange = (e) => {
            const { formName, startField, endField, checkboxField, clearFormFields } = this.props;
            const isChecked = e.target.checked;

            if (!isChecked) {
                clearFormFields(formName, startField.input.name, endField.input.name);
            }
            startField.input.onChange();
            endField.input.onChange();
            checkboxField.input.onChange(isChecked);
        };

        render() {
            const { className, dateRangeOptions: { startDateOptions, endDateOptions, checkboxOptions } } = this.props;
            const { startField, endField, checkboxField } = this.props;
            const startErrorMessage = !startField.meta.active && startField.meta.error;
            const endErrorMessage = !endField.meta.active && endField.meta.error;
            const wrapperClass = cn(className, 'date-range-with-checkbox', 'date-range');
            const startDateProps = {
                ...startDateOptions,
                ...startField.input,
                disabled: !checkboxField.input.value || checkboxOptions.disabled,
                error: startField.meta.touched && startErrorMessage
            };
            const endDateProps = {
                ...endDateOptions,
                ...endField.input,
                disabled: !checkboxField.input.value || checkboxOptions.disabled,
                error: endField.meta.touched && endErrorMessage
            };

            return (
                <div className={wrapperClass}>
                    <CheckBox
                        name={checkboxField.input.name}
                        value={checkboxField.input.value}
                        onChange={this.handleCheckboxChange}
                        label={checkboxOptions.label}
                        checked={!!checkboxField.input.value}
                        disabled={checkboxOptions.disabled}/>
                    <Comp startDateProps={startDateProps} endDateProps={endDateProps}/>
                </div>
            );
        }
    }

    return WithCheckboxFormDateRangePicker;
};

export default withCheckboxFormDateRangePicker;
