import { createReducer } from 'Utils/reducer';
import {
    WATCHLIST_CREATED,
    WATCHLIST_CREATING,
    WATCHLIST_CREATING_ERROR,
    WATCHLIST_ERROR,
    WATCHLIST_LOADED,
    WATCHLIST_LOADING,
    WATCHLIST_PROCESSED,
    WATCHLIST_PROCESSING,
    WATCHLIST_PROCESSING_ERROR,
    WATCHLIST_RENAMED,
    WATCHLIST_RENAMING,
    WATCHLIST_SHAREHOLDER_REMOVED,
    WATCHLIST_RENAMING_ERROR,
    WATCHLIST_SELECT_WATCHLIST
} from './actions';

const initialState = {
    data: [],
    processing: [],
    isLoading: false,
    isFetched: false,
    error: null,
    isCreating: false,
    renaming: [],
    selectedWatchlist: null
};

const actionHandlers = {
    [WATCHLIST_LOADING]: (state) => ({
        ...state,
        isLoading: true,
        error: null
    }),
    [WATCHLIST_ERROR]: (state, action) => ({
        ...state,
        isLoading: false,
        isFetched: true,
        error: action.error
    }),
    [WATCHLIST_LOADED]: (state, action) => ({
        ...state,
        isLoading: false,
        isFetched: true,
        data: action.payload
    }),
    [WATCHLIST_CREATING]: (state) => ({
        ...state,
        isCreating: true
    }),
    [WATCHLIST_CREATED]: (state, action) => ({
        ...state,
        isCreating: false,
        data: [...state.data, action.payload]
    }),
    [WATCHLIST_CREATING_ERROR]: (state) => ({
        ...state,
        isCreating: false
    }),
    [WATCHLIST_PROCESSING]: (state, action) => ({
        ...state,
        processing: [...action.payload.watchlistIds]
    }),
    [WATCHLIST_PROCESSED]: (state, action) => ({
        ...state,
        data: state.data.map(watchlist => {
            if (action.payload.watchlistIds.includes(watchlist.id)) {
                return {
                    ...watchlist,
                    shareholders: [...(watchlist.shareholders || []), { id: action.payload.shareholderId }]
                };
            }

            return watchlist;
        }),
        processing: state.processing.filter(watchlistId => !action.payload.watchlistIds.includes(watchlistId))
    }),
    [WATCHLIST_SHAREHOLDER_REMOVED]: (state, action) => ({
        ...state,
        data: state.data.map(watchlist => {
            if (action.payload.watchlistIds.includes(watchlist.id)) {
                return {
                    ...watchlist,
                    shareholders: (watchlist.shareholders || []).filter(({ id }) => id !== action.payload.shareholderId)
                };
            }

            return watchlist;
        }),
        processing: state.processing.filter(watchlistId => !action.payload.watchlistIds.includes(watchlistId))
    }),
    [WATCHLIST_PROCESSING_ERROR]: (state, action) => ({
        ...state,
        processing: state.processing.filter(watchlistId => !action.payload.watchlistIds.includes(watchlistId))
    }),
    [WATCHLIST_RENAMING]: (state, action) => ({
        ...state,
        renaming: [...state.renaming, action.payload.watchlistId]
    }),
    [WATCHLIST_RENAMED]: (state, action) => ({
        ...state,
        renaming: state.renaming.filter(id => id !== action.payload.watchlistId),
        data: state.data.map((item) => {
            if (item.id === action.payload.watchlistId) {
                return { ...item, title: action.payload.title };
            }

            return item;
        })
    }),
    [WATCHLIST_RENAMING_ERROR]: (state, action) => ({
        ...state,
        renaming: state.renaming.filter(id => id !== action.payload.watchlistId)
    }),
    [WATCHLIST_SELECT_WATCHLIST]: (state, action) => ({
        ...state,
        selectedWatchlist: action.payload.id
    })
};

export default createReducer(initialState, actionHandlers);
