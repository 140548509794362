import { WidgetLayoutDefault } from './layouts';
import WidgetHeaderCompanyDefault from './WidgetHeaderCompanyDefault';
import WidgetHeaderDefault from './WidgetHeader';
import WidgetErrorDefault from './WidgetError';
import WidgetBusyDefault from './WidgetBusy';
import WidgetDataLoaderDefault from './WidgetDataLoader';
import NullComponent from './NullComponent';

const components = {
    WidgetLayout: WidgetLayoutDefault,
    WidgetHeaderCompany: WidgetHeaderCompanyDefault,
    WidgetHeader: WidgetHeaderDefault,
    WidgetControlPanel: NullComponent,
    WidgetActionPanel: NullComponent,
    WidgetContent: NullComponent,
    WidgetError: WidgetErrorDefault,
    WidgetBusy: WidgetBusyDefault,
    WidgetDataLoader: WidgetDataLoaderDefault
};

export default components;
