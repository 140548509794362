import React from 'react';

import PersonalInfo from './PersonalInfo';
import UserPeers from './UserPeers';
import featureTypes from 'Constants/feature-types';
import PermissionContent from 'Components/PermissionContent';

const MyProfilePageView = (props) => {
    return (
        <div className='user-profile-page'>
            <div className='column'>
                <div className='user-profile-container'>
                    <PersonalInfo {...props}/>
                </div>
            </div>
            <PermissionContent features={[featureTypes.PEER_ANALYSIS]}>
                <div className='column'>
                    <div className='user-profile-container'>
                        <UserPeers/>
                    </div>
                </div>
            </PermissionContent>
        </div>
    );
};

export default MyProfilePageView;
