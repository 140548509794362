import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.YEAR,
        order: 1,
        minWidth: 120,
        displayName: 'registered.shareholder.table.year',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 4
        }
    },
    {
        columnName: columnNames.ROYAL_AMOUNT,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.royalAmount',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.OTHER_AMOUNT,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.otherAmount',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.FEDERAL_AMOUNT,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.federalAmount',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    }
];

export const secondarySorting = {
    field: columnNames.YEAR,
    direction: sortDirections.DESC
};
