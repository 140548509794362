import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import pageSizes from './constants/defaultPageSizes';
import { PageSize, PageSelector } from './components';

import './Pagination.scss';

class Pagination extends Component {
    static propTypes = {
        currentPage: PropTypes.number.isRequired,
        numberOfPages: PropTypes.number.isRequired,
        onChangeCurrentPage: PropTypes.func,
        currentPageSize: PropTypes.number,
        showPageSizes: PropTypes.bool,
        pageSizes: PropTypes.array,
        onChangePageSize: PropTypes.func,
        className: PropTypes.string,
        totalRecords: PropTypes.number,
        isPageSizeFixed: PropTypes.bool
    };

    static defaultProps = {
        showPageSizes: true,
        pageSizes
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedPage: props.currentPage
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentPage !== this.state.selectedPage) {
            this.setState({ selectedPage: nextProps.currentPage });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setCurrentPage(this.state.selectedPage);
        // this.selectorInput.blur();
        e.target.elements[0].blur();
    };

    handleChangeSelectedPage = (e) => {
        const selectedPage = +e.target.value > 0 ? +e.target.value : '';

        this.setState({ selectedPage });
    };

    handlePageSizeChanged = (option) => {
        const selectedPage = 1; // reset selected page

        this.setState({ selectedPage });
        this.props.onChangePageSize(+option.value, selectedPage);
    };

    handleClickLastPage = () => {
        this.setCurrentPage(this.props.numberOfPages);
    };

    handleClickFirstPage = () => {
        this.setCurrentPage(1);
    };

    handleClickNextPage = () => {
        this.setCurrentPage(this.state.selectedPage + 1);
    };

    handleClickPrevPage = () => {
        this.setCurrentPage(this.state.selectedPage - 1);
    };

    handleBlur = () => {
        this.state.selectedPage ||
            this.setState(() => ({ selectedPage: this.props.currentPage }));
    };

    setCurrentPage = (value) => {
        const { currentPage, numberOfPages, onChangeCurrentPage } = this.props;
        const selectedPage = +value > 0 ? Math.min(+value, numberOfPages) : 1;

        this.setState({ selectedPage });
        if (selectedPage !== currentPage) {
            onChangeCurrentPage(selectedPage);
        }
    };

    getPageSizeOptions = () => {
        return this.props.pageSizes.map(size => ({
            label: size.toString(),
            value: size.toString()
        }));
    };

    render() {
        const { numberOfPages, className, showPageSizes, currentPageSize, currentPage, totalRecords, isPageSizeFixed } = this.props;
        const paginationClassName = cn('pagination', className);

        return numberOfPages > 0
            ? (<div className={paginationClassName}>
                {showPageSizes &&
                <PageSize
                    currentPageSize={currentPageSize}
                    totalRecords={totalRecords}
                    options={this.getPageSizeOptions()}
                    onOptionChange={this.handlePageSizeChanged}
                    isPageSizeFixed={isPageSizeFixed}/>}
                <PageSelector
                    currentPage={currentPage}
                    numberOfPages={numberOfPages}
                    selectedPage={this.state.selectedPage}
                    onChangeSelectedPage={this.handleChangeSelectedPage}
                    onClickFirstPage={this.handleClickFirstPage}
                    onClickPrevPage={this.handleClickPrevPage}
                    onClickNextPage={this.handleClickNextPage}
                    onClickLastPage={this.handleClickLastPage}
                    onBlur={this.handleBlur}
                    onSubmit={this.handleSubmit} />
            </div>
            ) : null;
    }
}

export default Pagination;
