import _ from 'lodash';

import { createReducer } from 'Utils/reducer';
import {
    GET_ALERTS_SUCCESS,
    GET_ALERTS_FAILURE,
    SET_ALERTS_FETCHING,
    CLEAR_ALERTS,
    DISMISS_ALERT
} from './actions';

const initialState = {
    isFetching: false,
    alerts: [],
    error: null
};

const actionHandlers = {
    [GET_ALERTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        alerts: payload,
        error: null
    }),
    [GET_ALERTS_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload
    }),
    [SET_ALERTS_FETCHING]: (state, { payload }) => ({
        ...state,
        isFetching: payload
    }),
    [CLEAR_ALERTS]: state => ({
        ...state,
        alerts: []
    }),
    [DISMISS_ALERT]: (state, { payload }) => ({
        ...state,
        alerts: _.filter(state.alerts, alert => alert.alertId !== payload)
    })
};

export default createReducer(initialState, actionHandlers);
