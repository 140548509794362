/* eslint-disable object-shorthand */
import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import moment from 'moment';
import { mapProps } from '@shakacode/recompose';
import Highcharts from 'highcharts';
import ReactHighcharts from 'highcharts-react-official';
import _ from 'lodash';

import dateFormats from 'Constants/date-formats';
import colorSchemas from 'Constants/color-schemas';

const COLOR_SCHEMA = colorSchemas[2];

const LEGEND_SYMBOL_SIZE = 14;
const Y_AXIS_EXTREMES_PADDING_FACTOR = 0.3;

const MARGIN_RIGHT = 50;

@mapProps((props) => {
    const { positions, marketPrices = [] } = props;

    let maxPosition = null;
    let minPosition = null;
    let maxMarketPrice = null;
    let minMarketPrice = null;

    const plotBands = [];
    const mappedPositions = [];

    marketPrices.forEach((marketPrice) => {
        const value = marketPrice[1];

        if (value > maxMarketPrice) maxMarketPrice = value;
        if (!_.isNil(value) && (value < minMarketPrice || _.isNil(minMarketPrice))) minMarketPrice = value;
    });

    positions.forEach((position) => {
        const value = position[1];
        const date = new Date(position[0]);

        // Day 0 in date constructor set last day in previous month
        const quarterStart = new Date(date.getFullYear(), date.getMonth() - 2, 1).getTime();
        const quarterEnd = new Date(date.getFullYear(), date.getMonth() + 1, 1).getTime();

        mappedPositions.push([
            quarterStart,
            value
        ]);
        mappedPositions.push([
            quarterEnd,
            value
        ]);

        if (value === null) {
            plotBands.push({
                from: quarterStart,
                to: quarterEnd,
                color: COLOR_SCHEMA.PLOT_BAND_COLOR
            });
        }

        if (value > maxPosition) maxPosition = value;
        if (!_.isNil(value) && (value < minPosition || _.isNil(minPosition))) minPosition = value;
    });

    const medianPosition = (maxPosition - minPosition) / 2;
    const paddingPosition = medianPosition * Y_AXIS_EXTREMES_PADDING_FACTOR;
    const medianMarketPrice = (maxMarketPrice - minMarketPrice) / 2;
    const paddingMarketPrice = medianMarketPrice * Y_AXIS_EXTREMES_PADDING_FACTOR;

    return {
        ...props,
        plotBands,
        positionsData: mappedPositions,
        // Add one unit for case when max = min,
        // so line will be in center of yAxis
        maxYAxisPosition: maxPosition + paddingPosition + 1,
        minYAxisPosition: minPosition - paddingPosition - 1,
        maxYAxisMarketPrice: maxMarketPrice + paddingMarketPrice + 1,
        minYAxisMarketPrice: minMarketPrice - paddingMarketPrice - 1
    };
})
@injectIntl
class PositionsChart extends React.PureComponent {
    static propTypes = {
        positions: PropTypes.array.isRequired,
        marketPrices: PropTypes.array,
        chartData: PropTypes.array,
        plotBands: PropTypes.array,
        maxYAxisPosition: PropTypes.number,
        minYAxisPosition: PropTypes.number,
        maxYAxisMarketPrice: PropTypes.number,
        minYAxisMarketPrice: PropTypes.number,
        positionsData: PropTypes.array,
        containerWidth: PropTypes.number,
        intl: intlShape
    };

    adjustChart() {
        _.each(
            document.querySelectorAll('.positions-chart .highcharts-legend-item path.highcharts-graph'),
            (item) => item.setAttribute('stroke-width', LEGEND_SYMBOL_SIZE.toString())
        );
    }

    render() {
        const {
            plotBands,
            positionsData,
            marketPrices,
            maxYAxisPosition,
            minYAxisPosition,
            maxYAxisMarketPrice,
            minYAxisMarketPrice,
            positions,
            containerWidth,
            intl: {
                formatMessage,
                formatNumber
            }
        } = this.props;

        const options = {
            series: [
                {
                    data: positionsData,
                    name: formatMessage({ id: 'shareholders.table.positions.positions' }),
                    yAxis: 'positions',
                    step: 'center',
                    zIndex: -1
                },
                {
                    data: marketPrices,
                    name: formatMessage({ id: 'shareholders.table.positions.market_prices' }),
                    yAxis: 'market-price',
                    color: COLOR_SCHEMA.LINE_COLOR_SECONDARY
                }
            ],
            xAxis: {
                labels: {
                    formatter: function positionFormatter() {
                        const position = positions.find((pos) => {
                            const date = new Date(pos[0]);

                            return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).getTime() === this.value;
                        });

                        if (position) {
                            const value = position[1];
                            const date = position[0];
                            const isValueExist = !_.isNil(value);
                            const formattedValue = isValueExist ? formatNumber(value) : formatMessage({ id: 'shareholders.table.positions.no.volume.data' });
                            const formattedDate = moment(date).format(dateFormats.default);
                            const valueMarkup = `<span class='${isValueExist ? '' : 'no-volume'}'>${formattedValue}</span>`;
                            const dateMarkup = `<span class='position-date'>${formattedDate}</span>`;
                            const content = `${valueMarkup}${dateMarkup}`;

                            return `<div class='chart-position'>${content}</div>`;
                        }
                    },
                    useHTML: true,
                    align: 'left',
                    x: -containerWidth / (positions.length * 2)
                },
                showFirstLabel: false,
                gridLineWidth: 1,
                startOnTick: true,
                endOnTick: true,
                minRange: 54432000000,
                tickLength: 50,
                tickPositions: positions.map((pos) => {
                    const date = new Date(pos[0]);

                    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).getTime();
                }),
                plotBands
            },
            yAxis: [
                {
                    id: 'positions',
                    title: {
                        enabled: false
                    },
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0,
                    min: minYAxisPosition,
                    max: maxYAxisPosition
                },
                {
                    id: 'market-price',
                    opposite: true,
                    title: {
                        enabled: false
                    },
                    offset: 25,
                    labels: {
                        align: 'right',
                        y: 5,
                        formatter: function marketPriceFormatter() {
                            return formatNumber(this.value, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        }
                    },
                    tickAmount: 7,
                    min: minYAxisMarketPrice,
                    max: maxYAxisMarketPrice,
                    showLastLabel: false
                }
            ],
            chart: {
                height: 320,
                marginLeft: 0,
                marginTop: 1,
                marginRight: MARGIN_RIGHT,
                backgroundColor: COLOR_SCHEMA.BACKGROUND_COLOR,
                scrollablePlotArea: {
                    minWidth: containerWidth
                },
                events: {
                    render: this.adjustChart.bind(this)
                },
                reflow: false,
                className: 'positions-chart',
                animation: false
            },
            plotOptions: {
                line: {
                    color: COLOR_SCHEMA.LINE_COLOR_PRIMARY,
                    enableMouseTracking: false,
                    events: {
                        legendItemClick: function legendItemClickHandler() {
                            return false;
                        }
                    },
                    animation: false
                },
                series: {
                    animation: false
                }
            },
            credits: {
                enabled: false
            },
            title: {
                style: {
                    fontSize: '1.4rem'
                },
                align: 'left',
                x: 10,
                y: 10,
                floating: true,
                text: formatMessage({ id: 'shareholders.table.positions.title' })
            },
            legend: {
                enabled: true,
                floating: true,
                align: 'right',
                verticalAlign: 'top',
                squareSymbol: true,
                reversed: true,
                y: -10,
                x: -45,
                itemDistance: 30,
                itemStyle: {
                    fontSize: '1.4rem'
                },
                symbolHeight: LEGEND_SYMBOL_SIZE,
                symbolWidth: LEGEND_SYMBOL_SIZE,
                symbolRadius: 0
            }
        };

        return (
            <ReactHighcharts
                highcharts={Highcharts}
                options={options}/>
        );
    }
}

export default PositionsChart;
/* eslint-enable object-shorthand */
