import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import Select from 'react-select';
import { withState } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
    foundAnalysisShareholdersOptionsSelector,
    isFetchingFindingAnalysisShareholdersSelector,
    isFetchingAnalysisShareholderDataSelector,
    analysisShareholderDataSelector,
    clearFoundAnalysisShareholders,
    requestShareholderData,
    findAnalysisShareholderByName
} from 'State/analysisShareholders';
import { analysisShareholderShape } from 'Constants/analysisShareholders';
import timeouts from 'Constants/config';
import { despace } from 'Utils/string';

import './SearchTopShareholdersModal.scss';

const SEARCH_START = 3;

const mapStateToProps = (state, props) => {
    const { selectedShareholdersIds } = props;

    return {
        foundShareholdersOptions: foundAnalysisShareholdersOptionsSelector(state, selectedShareholdersIds),
        isSearchFetching: isFetchingFindingAnalysisShareholdersSelector(state),
        shareholderData: analysisShareholderDataSelector(state),
        isShareholderDataFetching: isFetchingAnalysisShareholderDataSelector(state)
    };
};

const mapDispatchToProps = {
    clearFoundAnalysisShareholders,
    findAnalysisShareholderByName,
    requestShareholderData
};

@withState(
    'selected',
    'setSelected',
    null
)
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class SearchTopShareholdersModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func,
        selected: PropTypes.object,
        setSelected: PropTypes.func,
        shareholders: PropTypes.array,
        selectedShareholdersIds: PropTypes.array,
        intl: intlShape,
        clearFoundAnalysisShareholders: PropTypes.func,
        foundShareholdersOptions: PropTypes.arrayOf(PropTypes.shape({
            value: analysisShareholderShape,
            label: PropTypes.string,
            disabled: PropTypes.bool
        })),
        isSearchFetching: PropTypes.bool,
        shareholderData: PropTypes.array,
        isShareholderDataFetching: PropTypes.bool,
        widgetType: PropTypes.string,
        findAnalysisShareholderByName: PropTypes.func,
        requestShareholderData: PropTypes.func,
        onSuccess: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.formatMessage = props.intl.formatMessage;
        this.noResultsText = this.formatMessage({ id: 'modals.findShareholderModal.neResultsText' });
        this.compareText = this.formatMessage({ id: 'modals.findShareholderModal.compare' });
        this.minLengthText = this.formatMessage({ id: 'modals.findShareholderModal.minLength' }, { value: SEARCH_START });
    }

    componentWillUnmount() {
        this.props.clearFoundAnalysisShareholders();
    }

    handleInputChange = (value) => {
        const query = despace(value);

        if (query && query.length >= SEARCH_START) {
            this.findByShareholderName(query);
        } else {
            this.props.clearFoundAnalysisShareholders();
        }

        this.props.setSelected(null);
    };

    handleChange = option => {
        const { widgetType, shareholders } = this.props;

        this.select.setState({
            inputValue: option.label
        });
        this.props.setSelected(option);
        const shareholder = option && option.value;
        const existedShareholder = _.find(shareholders, sh => sh.shareholderId === shareholder.shareholderId);

        if (!existedShareholder) {
            this.props.requestShareholderData(
                shareholder,
                widgetType);
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        const { selected, shareholderData, onSuccess, hideModal } = this.props;
        let shareholder = null;

        if (shareholderData) {
            shareholder = { notTop100: true, ...selected.value };
        } else if (selected) {
            shareholder = selected.value;
        } else {
            return;
        }

        onSuccess({ data: shareholderData, shareholder });
        hideModal();
    };

    getSelect = select => {
        this.select = select;
    };

    findByShareholderName = _.debounce(this.props.findAnalysisShareholderByName, timeouts.throttleTimeout);

    filterOptions = (options, filter = '') => {
        const { selected } = this.props;

        if (filter.trim().length < SEARCH_START && !selected) {
            return [
                {
                    label: this.minLengthText,
                    disabled: true
                }
            ];
        }

        return options;
    };

    render() {
        const { selected, foundShareholdersOptions, isSearchFetching, isShareholderDataFetching } = this.props;

        return (
            <div className='top-shareholders-search-modal-content'>
                <form onSubmit={this.handleSubmit}>
                    <Select
                        ref={this.getSelect}
                        name='shareholders-input'
                        onChange={this.handleChange}
                        onInputChange={this.handleInputChange}
                        scrollMenuIntoView
                        resetValue=''
                        onBlurResetsInput={false}
                        onCloseResetsInput={false}
                        autoFocus
                        clearable={false}
                        isLoading={isSearchFetching || isShareholderDataFetching}
                        options={foundShareholdersOptions}
                        noResultsText={this.noResultsText}
                        placeholder=''
                        autosize={false}
                        arrowRenderer={() => (<i className='font-icon font-icon-search arrow-role'/>)}
                        filterOptions={this.filterOptions}/>
                    <div className='modal-actions'>
                        <button
                            type='submit'
                            disabled={!selected || isSearchFetching || isShareholderDataFetching}
                            className='btn'>
                            { this.compareText }
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default SearchTopShareholdersModal;
