import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withState } from '@shakacode/recompose';
import _ from 'lodash';

import { CheckBox } from 'Components/CheckBox';
import { replaceToIndex } from 'State/routes/actions';
import { changeCurrentSecurity, refreshCurrentPage } from 'State/user';
import { getAdvancedSearchSecurities } from 'State/advancedSearch/selectors';
import PlatformAdministrationLink from 'Components/PlatformAdministrationLink';

const mapStateToProps = state => ({
    selectedSecurities: getAdvancedSearchSecurities(state)
});
const mapDispatchToProps = {
    changeCurrentSecurity,
    refreshCurrentPage,
    replaceToIndex
};

@withState(
    'saveAsDefault',
    'setSaveAsDefault',
    false
)
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ActionPanel extends Component {
    static propTypes = {
        changeCurrentSecurity: PropTypes.func,
        refreshCurrentPage: PropTypes.func,
        replaceToIndex: PropTypes.func,
        selectedSecurities: PropTypes.array,
        setSaveAsDefault: PropTypes.func,
        saveAsDefault: PropTypes.bool,
        history: PropTypes.object
    };

    handleChangeSaveAsDefault = (e) => {
        this.props.setSaveAsDefault(e.target.checked);
    };

    handleViewSecurity = () => {
        const securityId = _.get(this.props.selectedSecurities[0], 'id');

        if (this.props.saveAsDefault) {
            this.props.changeCurrentSecurity(securityId).then(() => {
                this.props.refreshCurrentPage().then(() => this.props.replaceToIndex());
            });
        } else {
            this.props.replaceToIndex(securityId);
        }
    };

    render() {
        const { selectedSecurities, saveAsDefault } = this.props;
        const isDisabled = selectedSecurities.length !== 1;

        return (
            <div className='action-panel'>
                <div className='action-group'>
                    <button
                        disabled={isDisabled}
                        onClick={this.handleViewSecurity}
                        className='btn'
                        type='submit'>
                        <FormattedMessage id='security.button.view'/>
                    </button>
                    <CheckBox
                        checked={saveAsDefault}
                        onClick={this.handleChangeSaveAsDefault}
                        disabled={isDisabled}
                        label={<FormattedMessage id='security.defaultSecurity'/>}/>
                    <PlatformAdministrationLink/>
                </div>
            </div>
        );
    }
}

export default ActionPanel;
