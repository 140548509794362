import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';

import { colorizeValues } from 'Utils/colorize';
import { labelCell, unmarkedLabelCell, getHeaderCellComponent } from './cellRenderers';
import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import { stickyFooter } from 'Components/Tables/TableEasy';
import { columns, levelTypes } from '../constants';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { getMarkedData, markIncludesCeDeCoRows } from 'Components/widgets/utils';

const TaxCertificationStatusChartView = ({ pieData, legendData, intl }) => (
    <div>
        <div className='pie-content-container'>
            <PieChart
                data={pieData}
                customConfig={getPieTooltipConfig(intl)}
                total={legendData.aggregate.percentOfShareholdersTotal}/>
        </div>
        <div className='legend-container'>
            <LegendGrid
                footerComponent={stickyFooter}
                headerRowHeight={24}
                {...legendData}/>
        </div>
    </div>
);


TaxCertificationStatusChartView.propTypes = {
    pieData: PropTypes.array,
    legendData: PropTypes.object,
    intl: PropTypes.object
};

export default compose(
    mapProps(props => {
        const {
            widgetFooterProps,
            data,
            widgetSettings: { colorScheme, level },
            intl: { formatMessage }
        } = props;
        const colorizedValues = colorizeValues(data[level].values, colorScheme);
        const summaryColumns = [
            {
                dataKey: columns.TAX_STATUS,
                title: formatMessage({ id: 'tax.certification.status.column.taxStatus' }),
                headerCellComponent: getHeaderCellComponent(columns.TAX_STATUS, levelTypes.SUMMARY),
                bodyCellComponent: labelCell
            },
            {
                dataKey: columns.PERCENT_OF_SHAREHOLDERS,
                columnClassName: 'text-right',
                minWidth: 130,
                title: formatMessage({ id: 'tax.certification.status.column.percentOfShareholders' }),
                bodyCellComponent: TotalValueOsCell
            }
        ];
        const detailsColumns = [
            {
                dataKey: columns.TAX_STATUS_DETAIL,
                title: formatMessage({ id: 'tax.certification.status.column.taxStatus' }),
                headerCellComponent: getHeaderCellComponent(columns.TAX_STATUS, levelTypes.DETAILS),
                maxWidth: 200,
                bodyCellComponent: labelCell
            },
            {
                dataKey: columns.TAX_STATUS_SUMMARY,
                bodyCellComponent: unmarkedLabelCell
            },
            {
                dataKey: columns.PERCENT_OF_SHAREHOLDERS,
                columnClassName: 'text-right',
                minWidth: 130,
                title: formatMessage({ id: 'tax.certification.status.column.percentOfShareholders' }),
                bodyCellComponent: TotalValueOsCell
            }
        ];

        const pieData = colorizedValues.map(value => ({
            name: level === levelTypes.SUMMARY ? value[columns.TAX_STATUS] : value[columns.TAX_STATUS_DETAIL],
            y: value[columns.PERCENT_OF_SHAREHOLDERS],
            tooltipValue: value[columns.PERCENT_OF_SHAREHOLDERS],
            color: value.color
        }));
        const legendData = {
            columns: level === levelTypes.SUMMARY ? summaryColumns : detailsColumns,
            data: getMarkedData(colorizedValues, [markIncludesCeDeCoRows], widgetFooterProps),
            aggregate: data[level].aggregate
        };

        return { ...props, pieData, legendData };
    })
)(TaxCertificationStatusChartView);
