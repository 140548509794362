import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AlertsModalHeader from './AlertsModalHeader';
import AlertsModalSection from './AlertsModalSection';

const AlertsModalView = props => {
    const currentAlert = props.alerts[props.pageNumber - 1];

    return (<>
        <AlertsModalHeader
            pageNumber={props.pageNumber}
            pageAmount={props.alerts.length}
            onFirstAlertClick={props.onFirstAlertClick}
            onPrevAlertClick={props.onPrevAlertClick}
            onNextAlertClick={props.onNextAlertClick}
            onLastAlertClick={props.onLastAlertClick}/>
        <AlertsModalSection {...currentAlert}/>
        <div className='alerts-modal-actions'>
            {props.getReplyLink()
                ? (<a href={props.getReplyLink()} className='btn btn-large btn-custom'>
                    <FormattedMessage id='modals.alerts.action.reply'/>
                </a>)
                : (<div className='btn btn-large btn-custom disabled'>
                    <FormattedMessage id='modals.alerts.action.reply'/>
                </div>)
            }
            <a href={props.getForwardLink()} className='btn btn-large btn-custom'>
                <FormattedMessage id='modals.alerts.action.forward'/>
            </a>
            <button
                type='button'
                onClick={props.onCopyAlert}
                className='btn btn-large'>
                <FormattedMessage id='modals.alerts.action.copy'/>
            </button>
            <button
                type='button'
                onClick={props.onDismissAlert}
                className='btn btn-large'>
                <FormattedMessage id='modals.alerts.action.dismiss'/>
            </button>
        </div>
    </>);
};

AlertsModalView.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.shape({
        alertId: PropTypes.number.isRequired,
        authorFirstName: PropTypes.string.isRequired,
        authorLastName: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        alertText: PropTypes.string.isRequired,
        email: PropTypes.string,
        phone: PropTypes.string
    })),
    pageNumber: PropTypes.number,
    onFirstAlertClick: PropTypes.func,
    onPrevAlertClick: PropTypes.func,
    onNextAlertClick: PropTypes.func,
    onLastAlertClick: PropTypes.func,
    getReplyLink: PropTypes.func,
    getForwardLink: PropTypes.func,
    onCopyAlert: PropTypes.func,
    onDismissAlert: PropTypes.func
};

export default memo(AlertsModalView);
