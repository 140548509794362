import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import Tooltip from 'Components/Tooltip';
import ButtonsGroup from 'Components/ButtonsGroup';

const mapGroupChildrenToButton = (children) => ({
    isSelected: children.isSelected,
    value: children.widgetId,
    title: children.widgetName,
    widgetType: children.widgetType
});

const RegisteredOverviewTabsActionPanelView = (props) => {
    const { slotsList = [], onSelect, selectedSlot } = props;
    const slotGroups = _.groupBy(slotsList, (slot) => slot.groupName || slot.widgetName);
    const selectedGroup = _.get(selectedSlot, 'groupName') || _.get(selectedSlot, 'widgetName');
    const selectedGroupChildren = slotGroups[selectedGroup];
    const selectedGroupChildless = _.get(selectedGroupChildren, '0.widgetName') === selectedGroup;
    const highLevelTabs = _.values(_.mapValues(slotGroups, (group, key) => {
        const firstWidget = group[0];
        const className = cn('tab', key === selectedGroup ? 'active' : null);

        return firstWidget.widgetTooltip
            ? (<Tooltip
                key={key}
                content={<div>{firstWidget.widgetTooltip}</div>}>
                <div
                    data-tab-type={firstWidget.widgetType}
                    className={className}
                    onClick={() => {
                        onSelect(firstWidget.widgetId);
                    }}>
                    {firstWidget.TabComponent
                        ? <firstWidget.TabComponent message={key}/>
                        : key
                    }
                </div>
            </Tooltip>)
            : (<div
                data-tab-type={firstWidget.widgetType}
                key={key}
                className={className}
                onClick={() => {
                    onSelect(firstWidget.widgetId);
                }}>
                {firstWidget.TabComponent && key === selectedGroup
                    ? <firstWidget.TabComponent message={key}/>
                    : key
                }
            </div>);
    }));
    const highLevelTabContent = (
        <div className='high-level-tabs'>
            {highLevelTabs}
        </div>
    );
    const subLevelTabContent = selectedGroupChildless ? null : (
        <div className='sub-level-tabs'>
            <ButtonsGroup
                onSelect={onSelect}
                buttons={selectedGroupChildren.map(mapGroupChildrenToButton)}/>
        </div>);

    return (
        <div className='registered-overview-tabs'>
            {highLevelTabContent}
            {subLevelTabContent}
        </div>
    );
};

RegisteredOverviewTabsActionPanelView.propTypes = {
    slotsList: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    selectedSlot: PropTypes.object
};

export default RegisteredOverviewTabsActionPanelView;
