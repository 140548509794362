import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withState } from '@shakacode/recompose';

import PageHeader from 'Components/PageHeader';
import AppFooter from 'Components/AppFooter';
import { EquinitiFooter } from 'Components/EquinitiFooter';
import { ModalRoot } from 'Components/Modals';
import LoadingBlock from 'Components/LoadingBlock';
import { getAnnouncementMessageSelector, acceptAnnouncement, postponeAnnouncement } from 'State/user';
import sanitizeHtml from 'Utils/sanitizeHtml';

import './AnnouncementPage.scss';
import { debounce } from 'lodash';
import timeouts from '../../constants/config';

const CONTENT_HEIGHT = 500;
const CONTENT_PADDING = 40;

const mapStateToProps = state => ({
    announcementMessage: getAnnouncementMessageSelector(state)
});

const mapDispatchToProps = {
    acceptAnnouncement,
    postponeAnnouncement
};

@connect(mapStateToProps, mapDispatchToProps)
@withState('disabled', 'setIsDisabled', true)
class AnnouncementPage extends Component {
    static propTypes = {
        announcementMessage: PropTypes.string,
        postponeAnnouncement: PropTypes.func,
        acceptAnnouncement: PropTypes.func,
        hideModal: PropTypes.func,
        disabled: PropTypes.bool,
        setIsDisabled: PropTypes.func
    };

    componentDidMount() {
        setTimeout(() => {
            const { scrollHeight } = this.content;

            if (scrollHeight + CONTENT_PADDING <= CONTENT_HEIGHT) {
                this.props.setIsDisabled(false);
            }
        }, 500);
    }

    handleContractScroll = (e) => {
        e.persist();
        this.onContractScroll(e);
    };

    handleAcceptMessage = () => {
        this.props.acceptAnnouncement();
    };

    handlePostponeMessage = () => {
        this.props.postponeAnnouncement();
    };

    onContractScroll = debounce((e) => {
        const { scrollTop, scrollHeight, offsetHeight } = e.nativeEvent.target;

        if (scrollTop + CONTENT_PADDING >= scrollHeight - offsetHeight) {
            this.props.setIsDisabled(false);
        }
    }, timeouts.throttleTimeout);

    render() {
        const { announcementMessage, disabled } = this.props;
        const clearAnnouncementMessage = announcementMessage && sanitizeHtml(announcementMessage);
        const announcementMessageText = (<div dangerouslySetInnerHTML={{ __html: clearAnnouncementMessage }}/>); // eslint-disable-line react/no-danger

        return (
            <div className='announcement-page'>
                <PageHeader isProfileMenu={false}/>
                <div className='modal-overlay'>
                    <div className='announcement'>
                        <div
                            className='announcement-content'
                            ref={(content) => {
                                this.content = content;
                            }}
                            onScroll={this.handleContractScroll}>
                            {announcementMessageText}
                        </div>
                        <div className='btn-panel'>
                            <button
                                type='button'
                                className='btn btn-large accept-btn'
                                disabled={disabled}
                                onClick={this.handleAcceptMessage}>
                                <FormattedMessage id='modals.announcement.accept' />
                            </button>
                            <button
                                type='button'
                                className='btn btn-large'
                                onClick={this.handlePostponeMessage}>
                                <FormattedMessage id='modals.announcement.close' />
                            </button>
                        </div>
                    </div>
                </div>
                {DEMO_MODE ? <EquinitiFooter/> : <AppFooter />}
                <ModalRoot/>
                <LoadingBlock/>
            </div>
        );
    }
}

export default AnnouncementPage;
