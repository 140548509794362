import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

const useValidateSelectedWidget = ({ widgets, widgetsSetSettings }) => {
    const [isValid, setIsValid] = useState(false);

    const validateWidgets = useCallback(() => {
        if (widgets.length < 1) {
            return;
        }

        const selectedWidget = widgets.find((widget) => _.get(widget, ['settings', 'isSelected']));

        if (!selectedWidget) {
            const firstWidget = widgets[0];

            widgetsSetSettings(firstWidget.widgetId, {
                ...firstWidget.settings,
                isSelected: true
            });

            return;
        }
    }, [widgets, widgetsSetSettings]);

    useEffect(() => {
        setIsValid(false);
        validateWidgets();
        setIsValid(true);
    }, [setIsValid, validateWidgets]);

    return isValid;
};

export default useValidateSelectedWidget;
