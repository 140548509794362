import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Layout } from '../../layout';
import Slot from '../../Slot';
import { getSlotsForCompareShareholdersLayoutSelector } from 'State/dynamicPage/selectors';

const mapStateToProps = (state, props) => ({
    slots: getSlotsForCompareShareholdersLayoutSelector(state, props.slots)
});

const LayoutWithTabsPanel = ({ slots }) => (
    <Layout className='layout-with-tabs-panel'>
        <Slot
            {...slots[0]}
            slotId={1}/>
    </Layout>
);

LayoutWithTabsPanel.propTypes = {
    slots: PropTypes.array
};

export default connect(mapStateToProps)(LayoutWithTabsPanel);
