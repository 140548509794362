import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import components from './components';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import { addWidgetEvent, changeWidgetViewEvent, removeWidgetEvent } from '../../../__ga__/events';

import './Widget.scss';

export default class Widget extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        components: PropTypes.object,
        events: PropTypes.object,
        widgetTitle: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ])
    };

    static childContextTypes = {
        components: PropTypes.object.isRequired,
        events: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        const { components: userComponents = {}, events: userEvents = {} } = props;

        this.components = { ...components, ...userComponents };
        this.events = userEvents;
    }

    getChildContext() {
        return {
            components: this.components,
            events: this.events
        };
    }

    componentDidMount() {
        if (_.get(this.props, 'isAdding')) {
            addWidgetEvent(this.props.widgetType);
        }
    }

    componentDidUpdate(prevProps) {
        if (_.get(prevProps, 'widgetSettings.mode') && _.get(this.props, 'widgetSettings.mode') &&
            (_.get(prevProps, 'widgetSettings.mode') !== _.get(this.props, 'widgetSettings.mode'))) {
            changeWidgetViewEvent(this.props.widgetType, this.props.widgetSettings.mode);
        }

        if (_.get(this.props, 'isRemoving')) {
            removeWidgetEvent(this.props.widgetType);
        }
    }

    render() {
        return <this.components.WidgetLayout {...this.props} />;
    }
}
