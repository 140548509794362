import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { List } from 'react-virtualized';
import { FormattedMessage } from 'react-intl';

class IssuerListView extends Component {
    static propTypes = {
        issuers: PropTypes.array,
        onSelectedIssuer: PropTypes.func,
        showAllSecurities: PropTypes.bool,
        onShowAllSecurities: PropTypes.func.isRequired,
        selectIssuerId: PropTypes.any,
        rowHeight: PropTypes.number.isRequired,
        visibleRowCount: PropTypes.number.isRequired
    };

    componentDidUpdate() {
        this.ref && this.ref.forceUpdateGrid();
    }

    handleIssuerClick = (issuer) => {
        return () => {
            this.props.onSelectedIssuer && this.props.onSelectedIssuer(issuer);
        };
    };

    setRef = (ref) => {
        this.ref = ref;
    };

    rowRenderer = (
        {
            key,   // Unique key within array of rows
            index, // Index of row within collection
            style  // Style object to be applied to row (to position it)
        }
    ) => {
        if (index === 0) {
            return (
                <div
                    key={key}
                    style={style}
                    data-issuer-id='all'
                    className={classNames('list-item', {
                        'list-item-selected': this.props.showAllSecurities
                    })} onClick={this.props.onShowAllSecurities}>
                    <FormattedMessage id='security.selectSecurity.selectAllIssuers'/>
                </div>
            );
        }

        const issuer = this.props.issuers[index - 1];

        return (
            <div
                key={key}
                style={style}
                onClick={this.handleIssuerClick(issuer)}
                data-issuer-id={issuer.id}
                className={classNames('list-item', {
                    'list-item-selected': issuer.id === this.props.selectIssuerId,
                    'active': issuer.id !== this.props.selectIssuerId
                })}>
                <span title={issuer.companyName} className='list-item-text'>{issuer.companyName}</span>
            </div>
        );
    };

    render() {
        const { issuers = [], rowHeight, visibleRowCount } = this.props;
        const rowCount = issuers.length + 1;

        return (
            <List
                className='issuers-list list'
                ref={this.setRef}
                height={visibleRowCount * rowHeight}
                rowHeight={rowHeight}
                rowCount={rowCount}
                rowRenderer={this.rowRenderer}
                width={410}/>
        );
    }
}

export default IssuerListView;
