import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.SHAREHOLDER_NAME,
        order: 1,
        minWidth: 210,
        displayName: 'registered.shareholder.table.shareholder',
        initialSortDirection: sortDirections.ASC,
        manageable: false
    },
    {
        columnName: columnNames.ISSUER,
        order: 2,
        minWidth: 210,
        displayName: 'registered.shareholder.table.issuer',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SECURITY,
        order: 3,
        minWidth: 210,
        displayName: 'registered.shareholder.table.security',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.SECURITY_NAME
        }
    },
    {
        columnName: columnNames.ACCOUNT_NUMBER,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.accountNumber',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.DOCUMENT_DATE,
        order: 5,
        minWidth: 135,
        displayName: 'registered.shareholder.table.documentDate',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.DOCUMENT_TYPE,
        order: 6,
        minWidth: 120,
        displayName: 'registered.shareholder.table.documentType',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.DESCRIPTION,
        order: 7,
        minWidth: 170,
        displayName: 'registered.shareholder.table.description',
        initialSortDirection: sortDirections.ASC,
        manageable: true
    },
    {
        columnName: columnNames.DOWNLOAD,
        order: 8,
        width: 100,
        minWidth: 100,
        displayName: 'registered.shareholder.table.download',
        disableSort: true,
        manageable: false
    }
];

export const secondarySorting = {
    field: columnNames.DOCUMENT_DATE,
    direction: sortDirections.DESC
};
