import { TA } from 'Constants/product-source-types';
import featureTypes from 'Constants/feature-types';
import sortDirections from 'Constants/sort-directions';
import shareholderColumnsNames from 'Constants/shareholders-columns-names';
import valueTypes from 'Constants/value-types';

const ALL_PRODUCTS = {
    productSources: [TA],
    features: [featureTypes.OI_PLUS_OPTIONS]
};

/*
* WARNING
* Please, pay attention!
* Field "order" responsible for column's order in table,
* but actual order in array responsible for checkbox's order in column manager. DON'T CHANGE it if there is no need.
*/

const shareholderNameColumnDefinition = {
    columnName: shareholderColumnsNames.SHAREHOLDER_NAME,
    holderType: 'ALL',
    columnType: 'shareholderName',
    availableFor: ALL_PRODUCTS,
    order: 1,
    displayName: 'shareholders.table.shareholders',
    manageable: false,
    initialSortDirection: sortDirections.ASC
};

const positionColumnDefinition = {
    columnName: shareholderColumnsNames.POSITION,
    holderType: 'ALL',
    columnType: 'position',
    availableFor: {
        features: [featureTypes.OI_PLUS_OPTIONS]
    },
    order: 2,
    displayName: 'shareholders.table.position',
    manageable: false,
    initialSortDirection: sortDirections.DESC,
    widthParams: {
        type: valueTypes.NUMBER,
        decimalPoints: 0
    }
};

const osColumnDefinition = {
    columnName: shareholderColumnsNames.OS,
    holderType: 'ALL',
    columnType: 'os',
    availableFor: ALL_PRODUCTS,
    order: 13,
    displayName: 'shareholders.table.os',
    displayNameColumnManager: 'shareholders.table.columnsManager.os',
    manageable: true,
    initialSortDirection: sortDirections.DESC,
    widthParams: {
        type: valueTypes.NUMBER,
        decimalPoints: 2
    }
};

const recent13fColumnDefinition = {
    columnName: shareholderColumnsNames.F13,
    holderType: 'INSTITUTION',
    columnType: '13f',
    availableFor: {
        features: [featureTypes.OI_PLUS_OPTIONS]
    },
    order: 16,
    displayName: 'shareholders.table.13f',
    manageable: true,
    initialSortDirection: sortDirections.DESC,
    widthParams: {
        type: valueTypes.NUMBER,
        decimalPoints: 0
    }
};

const costBasisColumnDefinition = {
    columnName: shareholderColumnsNames.COST_BASIS,
    holderType: 'INSTITUTION',
    columnType: 'costBasis',
    availableFor: {
        features: [featureTypes.OI_PLUS_OPTIONS]
    },
    order: 20,
    displayName: 'shareholders.table.costBasis',
    manageable: true,
    initialSortDirection: sortDirections.DESC,
    widthParams: {
        type: valueTypes.NUMBER,
        decimalPoints: 2,
        withCurrency: true
    }
};

const proxyAdvisorsColumnDefinition = {
    columnName: shareholderColumnsNames.PROXY_ADVISORS,
    holderType: 'INSTITUTION',
    order: 27,
    availableFor: {
        features: [featureTypes.OI_PLUS_OPTIONS]
    },
    columnType: 'proxyAdvisors',
    displayName: 'shareholders.table.proxyAdvisors',
    displayNameColumnManager: 'shareholders.table.columnsManager.proxyAdvisors',
    manageable: true,
    initialSortDirection: sortDirections.ASC,
    widthParams: {
        type: valueTypes.TEXT
    }
};

export default [
    shareholderNameColumnDefinition,
    positionColumnDefinition,
    {
        columnName: shareholderColumnsNames.CURRENT_BALANCE,
        holderType: 'ALL',
        columnType: 'currentBalance',
        availableFor: {
            productSources: [TA]
        },
        order: 8,
        displayName: 'shareholders.table.currentBalance',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    osColumnDefinition,
    {
        columnName: shareholderColumnsNames.HOLDER_TYPE,
        holderType: 'ALL',
        order: 24,
        columnType: 'holderType',
        availableFor: ALL_PRODUCTS,
        displayName: 'shareholders.table.holderType',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.STATE,
        holderType: 'ALL',
        order: 30,
        availableFor: ALL_PRODUCTS,
        columnType: 'state',
        displayName: 'shareholders.table.state',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.COUNTRY,
        holderType: 'ALL',
        order: 31,
        availableFor: ALL_PRODUCTS,
        columnType: 'country',
        displayName: 'shareholders.table.country',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.POSITION_DATE,
        holderType: 'INSTITUTION',
        columnType: 'positionDate',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 3,
        displayName: 'shareholders.table.positionDate',
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: shareholderColumnsNames.PREVIOUS_POSITION,
        holderType: 'INSTITUTION',
        columnType: 'previousPosition',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 4,
        displayName: 'shareholders.table.previous',
        displayNameColumnManager: 'shareholders.table.columnsManager.previous',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    {
        columnName: shareholderColumnsNames.PREVIOUS_POSITION_DATE,
        holderType: 'INSTITUTION',
        columnType: 'previousPositionDate',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 5,
        displayName: 'shareholders.table.previousDate',
        displayNameColumnManager: 'shareholders.table.columnsManager.previousDate',
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: shareholderColumnsNames.POSITION_CHANGE,
        holderType: 'INSTITUTION',
        columnType: 'positionChange',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 6,
        displayName: 'shareholders.table.change',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    {
        columnName: shareholderColumnsNames.MKT_VAL,
        holderType: 'INSTITUTION',
        columnType: 'mktVal',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 14,
        displayName: 'shareholders.table.mktVal',
        displayNameColumnManager: 'shareholders.table.columnsManager.mktVal',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    // {
    //     columnName: shareholderColumnsNames.PORTFOLIO_PERCENT,
    //     holderType: 'INSTITUTION',
    //     columnType: 'portfolioPercent',
    //     availableFor: {
    //         features: [featureTypes.OI_PLUS_OPTIONS]
    //     },
    //     order: 15,
    //     displayName: 'shareholders.table.ofPortfolio',
    //     manageable: true,
    //     initialSortDirection: sortDirections.DESC,
    //     widthParams: {
    //         type: valueTypes.NUMBER,
    //         decimalPoints: 2
    //     }
    // },
    recent13fColumnDefinition,
    {
        columnName: shareholderColumnsNames.PREVIOUS13F,
        holderType: 'INSTITUTION',
        columnType: 'previous13f',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 17,
        displayName: 'shareholders.table.previous13f',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    {
        columnName: shareholderColumnsNames.F13CHANGE,
        holderType: 'INSTITUTION',
        columnType: '13fChange',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 18,
        displayName: 'shareholders.table.13fChange',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    {
        columnName: shareholderColumnsNames.YTD_CHANGE,
        holderType: 'INSTITUTION',
        columnType: 'ytdChange',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 19,
        displayName: 'shareholders.table.YTDChange',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    costBasisColumnDefinition,
    {
        columnName: shareholderColumnsNames.ASSETS_UNDER_MANAGEMENT,
        holderType: 'INSTITUTION',
        order: 23,
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        columnType: 'assetsUnderManagement',
        displayName: 'shareholders.table.assetsUnderManagement',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0,
            withCurrency: true
        }
    },
    {
        columnName: shareholderColumnsNames.PURCHASING_POWER,
        holderType: 'INSTITUTION',
        order: 22,
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        columnType: 'purchasingPower',
        displayName: 'shareholders.table.purchasingPower',
        displayNameColumnManager: 'shareholders.table.columnsManager.purchasingPower',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0,
            withCurrency: true
        }
    },
    {
        columnName: shareholderColumnsNames.STYLE,
        holderType: 'INSTITUTION',
        order: 25,
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        columnType: 'style',
        displayName: 'shareholders.table.style',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.ACTIVE_VS_PASSIVE,
        holderType: 'INSTITUTION',
        order: 26,
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        columnType: 'activeVsPassive',
        displayName: 'shareholders.table.activeVsPassive',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    proxyAdvisorsColumnDefinition,
    {
        columnName: shareholderColumnsNames.TURNOVER,
        holderType: 'INSTITUTION',
        order: 28,
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        columnType: 'turnover',
        displayName: 'shareholders.table.turnover',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.CITY,
        holderType: 'INSTITUTION',
        columnType: 'city',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 29,
        displayName: 'shareholders.table.city',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.METRO_AREA,
        holderType: 'INSTITUTION',
        order: 21,
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        columnType: 'metroArea',
        displayName: 'shareholders.table.metroArea',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.ACCOUNT_NUMBER,
        holderType: 'REGISTERED',
        order: 7,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'accountNumber',
        displayName: 'shareholders.table.accountNumber',
        displayNameColumnManager: 'shareholders.table.columnsManager.accountNumber',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.CERTIFIED_SHARES,
        holderType: 'REGISTERED',
        order: 9,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'certifiedShares',
        displayName: 'shareholders.table.certifiedShares',
        displayNameColumnManager: 'shareholders.table.columnsManager.certifiedShares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: shareholderColumnsNames.BOOK_SHARES,
        holderType: 'REGISTERED',
        order: 10,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'bookShares',
        displayName: 'shareholders.table.bookShares',
        displayNameColumnManager: 'shareholders.table.columnsManager.bookShares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: shareholderColumnsNames.DRIP_SHARES,
        holderType: 'REGISTERED',
        order: 11,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'dripShares',
        displayName: 'shareholders.table.dripShares',
        displayNameColumnManager: 'shareholders.table.columnsManager.dripShares',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: shareholderColumnsNames.TOTAL_VALUE,
        holderType: 'REGISTERED',
        columnType: 'totalValue',
        availableFor: {
            productSources: [TA]
        },
        order: 12,
        displayName: 'shareholders.table.totalValue',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: shareholderColumnsNames.STATUS,
        holderType: 'REGISTERED',
        order: 33,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'status',
        displayName: 'shareholders.table.status',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.OPENED_DATE,
        holderType: 'REGISTERED',
        order: 32,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'openedDate',
        displayName: 'shareholders.table.openedDate',
        manageable: true,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: shareholderColumnsNames.CLOSED_DATE,
        holderType: 'REGISTERED',
        order: 34,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'closedDate',
        displayName: 'shareholders.table.closedDate',
        manageable: false,
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: shareholderColumnsNames.DIRECT_DEPOSIT,
        holderType: 'REGISTERED',
        order: 35,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'directDeposit',
        displayName: 'shareholders.table.directDeposit',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.SPECIAL_CODE,
        holderType: 'REGISTERED',
        order: 36,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'specialCode',
        displayName: 'shareholders.table.specialCode',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.ADDRESS,
        holderType: 'REGISTERED',
        order: 37,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'address',
        displayName: 'shareholders.table.address',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.SSN_TAX_ID,
        holderType: 'REGISTERED',
        order: 38,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'ssnTaxId',
        displayName: 'shareholders.table.ssnTaxId',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.REGISTERED_ADDRESS,
        holderType: 'REGISTERED',
        order: 39,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'registeredAddress',
        displayName: 'shareholders.table.address.registered',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.DIVIDEND_ADDRESS,
        holderType: 'REGISTERED',
        order: 40,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'dividendAddress',
        displayName: 'shareholders.table.address.dividend',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.SEASONAL_ADDRESS,
        holderType: 'REGISTERED',
        order: 41,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'seasonalAddress',
        displayName: 'shareholders.table.address.seasonal',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.PROXY_ADDRESS,
        holderType: 'REGISTERED',
        order: 42,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'proxyAddress',
        displayName: 'shareholders.table.address.proxy',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.K1_ADDRESS,
        holderType: 'REGISTERED',
        order: 43,
        availableFor: {
            productSources: [TA]
        },
        columnType: 'k1Address',
        displayName: 'shareholders.table.address.k1',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    }
];

export const proxyColumnDefinitions = [
    {
        ...shareholderNameColumnDefinition,
        displayName: 'shareholders.table.investors'
    },
    {   ...positionColumnDefinition,
        displayName: 'shareholders.table.proxyPosition'
    },
    {
        ...osColumnDefinition,
        displayName: 'shareholders.table.proxyOs'
    },
    {
        columnName: shareholderColumnsNames.CUMULATIVE_OS,
        holderType: 'ALL',
        columnType: 'cumulativeOs',
        availableFor: ALL_PRODUCTS,
        order: 13,
        displayName: 'shareholders.table.cumulativeOs',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2
        }
    },
    recent13fColumnDefinition,
    {
        columnName: shareholderColumnsNames.CHANGE,
        holderType: 'INSTITUTION',
        columnType: 'change',
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        order: 18,
        displayName: 'shareholders.table.change',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    costBasisColumnDefinition,
    {
        columnName: shareholderColumnsNames.ACTIVIST,
        holderType: 'ALL',
        columnType: 'activist',
        availableFor: ALL_PRODUCTS,
        order: 23,
        displayName: 'shareholders.table.activist',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: shareholderColumnsNames.PROXY_VOTING_POLICY,
        holderType: 'ALL',
        columnType: 'proxyVotingPolicy',
        availableFor: ALL_PRODUCTS,
        order: 25,
        displayName: 'shareholders.table.proxyVotingPolicy',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            valueLength: 20
        }
    },
    proxyAdvisorsColumnDefinition,
    {
        columnName: shareholderColumnsNames.PROXY_CONTACTS,
        holderType: 'ALL',
        columnType: 'isContactExists',
        availableFor: ALL_PRODUCTS,
        order: 50,
        displayName: 'shareholders.table.proxyContacts',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            valueLength: 20
        }
    }
];
