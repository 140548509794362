import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import cn from 'classnames';

const TheadComponent = ({ style, toggleSort, children, sortProperty, className, ...rest }) => {
    const customComponent = get(children, ['0', 'props', 'children']);

    return (
        <div
            className={cn('rt-th', className, { 'active': sortProperty })}
            style={{ ...style, msFlex: style.flex }}
            onClick={toggleSort}>
            {React.isValidElement(customComponent) && React.cloneElement(customComponent, {
                sortProperty,
                ...rest
            })}
        </div>
    );
};

TheadComponent.propTypes = {
    style: PropTypes.object,
    toggleSort: PropTypes.func,
    children: PropTypes.any,
    sortProperty: PropTypes.object,
    onFiltration: PropTypes.func,
    sortDescendingIcon: PropTypes.element,
    sortAscendingIcon: PropTypes.element,
    className: PropTypes.string,
    filterOptions: PropTypes.array
};

export default TheadComponent;
