import PropTypes from 'prop-types';
import React from 'react';
import { fieldInputPropTypes } from 'redux-form';

import { RadioButton } from 'Components/RadioButton';

const AccountsField = ({ input, items }) => {
    return (
        <div className='radio-buttons accounts-radio-buttons'>
            {items.map((column, index) =>
                (<div className='radio-buttons-column' key={index}>
                    {column.map(({ value, label, disabled }) => (
                        <RadioButton
                            {...input}
                            key={value}
                            label={label}
                            disabled={disabled}
                            position='block'
                            checked={input.value === value}
                            value={value}/>
                    ))}
                </div>)
            )}
        </div>
    );
};

AccountsField.propTypes = {
    input: PropTypes.shape(fieldInputPropTypes),
    items: PropTypes.arrayOf(PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            disabled: PropTypes.bool
        }))
    )
};

export default AccountsField;
