import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

import './InfoPopup.scss';

const InfoPopup = ({ columnDefinitions = [], values = {}, className }) => {
    const rows = columnDefinitions.map(col => values[_.get(col, 'columnName')]
        ? (<div key={_.get(col, 'displayName')} className='info-popup-row'>
            <FormattedMessage id={_.get(col, 'displayName')}>
                {text => <div className='info-popup-label'>{text}:</div>}
            </FormattedMessage>
            <div className='info-popup-text'>{values[_.get(col, 'columnName')]}</div>
        </div>) : null);

    return _.filter(rows, row => row).length > 0 ? <div className={cn('info-popup', className)}>{rows}</div> : null;
};

InfoPopup.propTypes = {
    columnDefinitions: PropTypes.arrayOf(PropTypes.object),
    values: PropTypes.object,
    className: PropTypes.string
};

export default InfoPopup;
