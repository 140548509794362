import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropsTypes from 'prop-types';
import { compose } from '@shakacode/recompose';

import {
    getFirstWatchlistSelector,
    isCreatingWatchlistSelector,
    isProcessingWatchlistSelector
} from 'State/watchlist/selectors';
import * as watchlistActions from 'State/watchlist/actions';
import * as notifierActions from 'State/notifier/actions';
import { CheckBox } from 'Components/CheckBox';
import { WATCHLIST_DEFAULT_TITLE, WATCHLIST_LIMIT } from 'Constants/watchlist';

const WatchlistShareholderAddButton = ({
    shareholderId,
    shareholderTitle,
    shareholderType,
    firstWatchlist,
    isCreating,
    isProcessing,
    createWatchlist,
    addShareholderToSelectedWatchlist,
    notifySuccess,
    notifyError,
    className,
    getUserWatchlist,
    intl
}) => {
    const [checked, setChecked] = useState(false);
    const { title: firstWatchlistTitle } = firstWatchlist || {};

    const handleClick = async (event) => {
        if (event.target.checked) {
            setChecked(true);
        } else {
            setChecked(false);
        }

        let watchlist;

        try {
            [watchlist] = await getUserWatchlist();

            let watchlistId = watchlist ? watchlist.id : null;

            if (!watchlistId) {
                const newWatchlist = await createWatchlist(WATCHLIST_DEFAULT_TITLE);

                watchlistId = newWatchlist.id;
            }

            await addShareholderToSelectedWatchlist({ shareholderId, shareholderTitle, shareholderType }, [watchlistId]);

            notifySuccess(
                null,
                <FormattedHTMLMessage id='watchlist.notifier.addShareholder.success' />
            );
        } catch (e) {
            setChecked(false);

            if (watchlist && watchlist.shareholders.length + 1 > WATCHLIST_LIMIT) {
                notifyError(
                    <FormattedMessage id='watchlist.notifier.full.error.header' />,
                    <FormattedHTMLMessage id='watchlist.notifier.full.error'/>
                );

                return;
            }

            notifyError(
                <FormattedMessage id='watchlist.notifier.addShareholder.error.header' />,
                <FormattedHTMLMessage id='watchlist.notifier.addShareholder.error'/>
            );
        }
    };

    return (
        <CheckBox
            checked={checked}
            label={<FormattedMessage
                id='watchlist.button.add.title'
                values={{ title: firstWatchlistTitle || WATCHLIST_DEFAULT_TITLE }}/>}
            title={intl.formatMessage({ id:'watchlist.button.add.tooltip' })}
            onChange={handleClick}
            disabled={isCreating || isProcessing}
            className={className}/>
    );
};

WatchlistShareholderAddButton.propTypes = {
    shareholderId: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
    shareholderTitle: PropsTypes.string,
    shareholderType: PropsTypes.string,
    firstWatchlist: PropsTypes.shape({
        id: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number])
    }),
    isCreating: PropsTypes.bool,
    isProcessing: PropsTypes.bool,
    createWatchlist: PropsTypes.func,
    addShareholderToSelectedWatchlist: PropsTypes.func,
    notifySuccess: PropsTypes.func,
    notifyError: PropsTypes.func,
    className: PropsTypes.string,
    getUserWatchlist: PropsTypes.func,
    intl: intlShape
};

const mapStateToProps = state => ({
    firstWatchlist: getFirstWatchlistSelector(state),
    isCreating: isCreatingWatchlistSelector(state),
    isProcessing: isProcessingWatchlistSelector(state)
});

const mapDispatchToProps = ({
    getUserWatchlist: watchlistActions.getUserWatchlists,
    createWatchlist: watchlistActions.createWatchlist,
    addShareholderToSelectedWatchlist: watchlistActions.addShareholderToSelectedWatchlist,
    notifySuccess: notifierActions.notifySuccess,
    notifyError: notifierActions.notifyError
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(WatchlistShareholderAddButton);

