import PropTypes from 'prop-types';
import React, { useCallback, useMemo, memo } from 'react';
import { intlShape } from 'react-intl';
import { SubmissionError } from 'redux-form';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import SaveScheduledReportTemplateModalView from './SaveScheduledReportTemplateModalView';
import { reportNameNormalize } from '../../../pages/reporting/common/reportNameUtils';
import reportNameErrors from 'Constants/report-name-errors';
import httpStatuses from 'Constants/http-status';
import formName from './constants/form-name';
import { getSchedulerParams } from 'Utils/reportScheduler';

const SaveScheduledReportTemplateModal = props => {
    const { intl: { formatMessage } } = props;

    const handleSaveReport = useCallback(formValues => {
        const { onSuccess, preventCloseOnSuccess, payload } = props;

        return onSuccess(getSchedulerParams({ ...payload, ...formValues })).then(
            (res) => {
                if (!preventCloseOnSuccess) {
                    props.hideModal();
                }

                toastr.remove();
                toastr.success(
                    formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.title' }),
                    formatMessage({ id: 'reporting.listReport.notifier.templateCreated.message' })
                );

                return res;
            },
            errorSaveTemplateHandler
        );
    }, [props.onSuccess, props.preventCloseOnSuccess, props.payload]);

    const errorSaveTemplateHandler = useCallback(error => {
        const response = _.get(error, 'response', {});
        const reason = _.get(response, 'data.reasonType', '');
        const errorMessage = reportNameErrors[reason];

        if (response.status === httpStatuses.HTTP_400 && errorMessage) {
            throw new SubmissionError({ reportName: formatMessage({ id: errorMessage }) });
        }
    }, []);

    const handleSaveAndShare = useCallback((formValues) => {
        const { onShare } = props;

        return handleSaveReport(formValues).then(res => {
            const reportName = _.get(res, 'data.payload.reportName');
            const reportTemplateId = _.get(res, 'data.payload.reportTemplateId');

            if (reportName && reportTemplateId) {
                onShare({ reportName, reportTemplateId });
            }
        });
    }, [handleSaveReport, props.onShare]);

    const newProps = useMemo(() => ({
        formName,
        formatMessage,
        okText: formatMessage({ id: 'common.save' }),
        cancelText: formatMessage({ id: 'common.cancel' }),
        saveAndShareText: formatMessage({ id: 'common.saveAndShare' }),
        onSuccess: handleSaveReport,
        onSaveAndShare: handleSaveAndShare,
        onCancel: props.hideModal,
        reportNameNormalize,
        handleSubmit: props.handleSubmit,
        isMultiSecurityReport: _.get(props, 'payload.form.securities.length', 0) > 1
    }), [
        props.hideModal,
        props.handleSubmit,
        handleSaveReport,
        _.get(props, 'payload.form.securities.length'),
        _.get(props, 'payload.userCanShareReport', false)
    ]);

    return (
        <SaveScheduledReportTemplateModalView
            {...newProps}
            submitting={props.submitting}
            invalid={props.invalid}
            formValues={props.formValues}
            userCanShareReportTemplate={props.userCanShareReportTemplate}/>
    );
};

SaveScheduledReportTemplateModal.propTypes = {
    hideModal: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    messageKey: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    okTextKey: PropTypes.string,
    cancelTextKey: PropTypes.string,
    payload: PropTypes.object,
    preventCloseOnSuccess: PropTypes.bool,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    formValues: PropTypes.object,
    onShare: PropTypes.func,
    userCanShareReportTemplate: PropTypes.bool
};

export default memo(SaveScheduledReportTemplateModal);
