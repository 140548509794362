import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import ReactTable from 'react-table';
import cn from 'classnames';

import { getSortDirection } from 'Components/Tables/utils';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import sortDirections from 'Constants/sort-directions';

import './CustomReactTable.scss';

@injectIntl
class CustomReactTable extends Component {
    static propTypes = {
        columns: PropTypes.array.isRequired,
        getCustomComponent: PropTypes.func.isRequired,
        onChangeSorting: PropTypes.func,
        sortCriteria: PropTypes.shape({
            field: PropTypes.string,
            direction: PropTypes.string
        }),
        data: PropTypes.array,
        getTheadThProps: PropTypes.func,
        getTdProps: PropTypes.func,
        noDataKey: PropTypes.string,
        intl: intlShape,
        className: PropTypes.string,
        sortable: PropTypes.bool
    };

    static defaultProps = {
        sortable: true
    };

    handleOnSort = ({ id: sortBy }) => {
        const { columns, sortCriteria: { field: prevSortBy, direction: prevDirection } = {} } = this.props;
        const sortCriteria = {
            field: sortBy,
            direction: getSortDirection(
                sortBy,
                prevSortBy,
                prevDirection,
                columns
            ),
            initial: false
        };

        this.props.onChangeSorting(sortCriteria);
    };

    getColumnsDefinition = (columns) => {
        const { intl: { formatMessage }, getCustomComponent, sortable } = this.props;

        return columns
            .map((column) => {
                const Components = getCustomComponent(column.columnName);

                return {
                    Header: () => (
                        <Components.customHeadingComponent
                            title={column.displayName ? formatMessage({ id: column.displayName }) : ''}/>
                    ),
                    Cell: (props) => (
                        <Components.customComponent
                            value={props.value}/>
                    ),
                    accessor: column.columnName,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                    width: column.width,
                    sortable: sortable && !column.disableSort
                };
            });
    };

    getTheadThProps = (sortField, sortDirection, finalState, missProp, column) => {
        const defaultProps = {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };
        let additionalProps = {};

        if (this.props.getTheadThProps) {
            additionalProps = this.props.getTheadThProps(sortField, sortDirection, finalState, missProp, column) || {};
        }

        return {
            ...additionalProps,
            ...defaultProps
        };
    };

    getNoDataProps = () => ({
        messageId: this.props.noDataKey || 'widgets.noDataMessage'
    });

    render() {
        const {
            getTdProps,
            data,
            columns,
            sortCriteria: { field, direction } = {},
            className
        } = this.props;
        const columnsDefinitions = this.getColumnsDefinition(columns);

        return (
            <ReactTable
                className={cn('custom-react-table scroll-handle', className)}
                showPagination={false}
                resizable={false}
                LoadingComponent={() => null}
                minRows={0}
                ThComponent={TheadComponent}
                TdComponent={TdComponent}
                getNoDataProps={this.getNoDataProps}
                NoDataComponent={NoResults}
                getTheadThProps={this.getTheadThProps.bind(this, field, direction)}
                getTdProps={getTdProps}
                manual
                defaultPageSize={0}
                resolvedData={data}
                onSortedChange={(sortProps) => this.handleOnSort(sortProps[0])}
                columns={columnsDefinitions || []}/>
        );
    }
}

export default CustomReactTable;
