import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const NotesInfoPanel = props => (
    <FormattedMessage id='notes.messageNote' values={{ maxRows: props.maxRows }}>
        {message => <div className='description message-note'>{message}</div>}
    </FormattedMessage>
);

NotesInfoPanel.propTypes = {
    maxRows: PropTypes.number
};

export default memo(NotesInfoPanel);
