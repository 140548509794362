import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { mapProps, withState } from '@shakacode/recompose';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';

import Widget from 'Components/widgets/Widget';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import columnDefinitions from '../constants/columnDefinitions';
import { showCommonColumnsManagerModal } from 'State/modal';
import ShareRangeAnalysisResultsHeader from '../components/ShareRangeAnalysisResultsHeader';
import ShareRangeAnalysisResultsContent from '../components/ShareRangeAnalysisResultsContent';
import ShareRangeAnalysisResultsWidgetControlPanel from '../components/ShareRangeAnalysisResultsControlPanel';
import { commonWidgetPropTypes, commonWidgetActions, getManagerTransformColumns } from '../../utils';
import { showExportWidgetModal } from 'State/exportReports';
import reportTypes from 'Constants/report-types';
import defaultSettings from '../settings';

import '../components/ShareRangeAnalysisResults.scss';

const FORM_NAME = 'reportingShareRangeAnalysisForm';
const getFormValuesSelector = getFormValues(FORM_NAME);

const mapStateToProps = state => ({
    shareRangeOptions: getFormValuesSelector(state) || {},
    pageInfo: dynamicPageSelectors.getDynamicPageInfoSelector(state)
});
const mapDispatchToProps = {
    showCommonColumnsManagerModal,
    showExportWidgetModal
};


@withState(
    'sortCriteria',
    'setSortCriteria',
    defaultSettings.sortCriteria
)
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@mapProps(props => {
    const formatMessage = props.intl.formatMessage;
    const visibleColumns = _.get(props, 'widgetSettings.visibleColumns', []);
    const managerColumns = getManagerTransformColumns(columnDefinitions.columns, visibleColumns, formatMessage);
    const columns = _.filter(
        columnDefinitions.columns,
        column => _.includes(visibleColumns, column.columnName) || !column.manageable);

    return {
        ...props,
        managerColumns,
        columns
    };
})
class ShareRangeAnalysisResults extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        showCommonColumnsManagerModal: PropTypes.func,
        showExportWidgetModal: PropTypes.func,
        shareRangeOptions: PropTypes.object,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.dataParams = {};

        this.widgetComponents = {
            WidgetControlPanel: ShareRangeAnalysisResultsWidgetControlPanel,
            WidgetContent: ShareRangeAnalysisResultsContent,
            WidgetHeader: ShareRangeAnalysisResultsHeader
        };

        this.widgetEvents = {
            onBuildDataParams: this.onBuildDataParams,
            handleUpdateWidgetParams: this.handleUpdateWidgetParams
        };
    }

    handleUpdateWidgetParams = (params) => {
        this.dataParams = { ...this.dataParams, ...params };

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, ...params });
        this.props.onUpdateWidgetData({ ...this.dataParams });
    };

    handleColumnsManager = () => {
        this.props.showCommonColumnsManagerModal({
            titleKey: 'modals.commonColumnsManager.title',
            onSave: this.onChangeColumns,
            columns: this.props.managerColumns,
            disableValidation: true
        });
    };

    handleExport = () => {
        const { sortCriteria, columns, widgetData = {} } = this.props;

        this.props.showExportWidgetModal({
            reportTemplateType: reportTypes.shareRangeAnalysisTemplate,
            exportParams: {
                shareType: widgetData.shareType,
                shareRange: widgetData.shareRange,
                sortCriteria: {
                    sortDirection: sortCriteria.direction,
                    sortField: sortCriteria.field,
                    initial: sortCriteria.initial
                },
                columnsToDisplay: columns.map(column => column.columnName)
            },
            modalViewParams: {
                showExcelLimit: true
            }
        });
    };

    onBuildDataParams = () => {
        const start = _.get(this.props.shareRangeOptions, 'shareRange.start');
        const end = _.get(this.props.shareRangeOptions, 'shareRange.end');
        const increment = _.get(this.props.shareRangeOptions, 'shareRange.increment') || end - start;

        this.dataParams = {
            shareType: _.get(this.props.shareRangeOptions, 'shareType'),
            shareRange: {
                start,
                end,
                increment
            }
        };

        return this.dataParams;
    };

    onChangeColumns = (columns) => {
        const visibleColumns = _.map(_.filter(columns, column => column.isSelected), column => column.name);

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, visibleColumns });
    };

    render() {
        const props = {
            ...this.props,
            widgetControlPanelSettings: {
                onColumnsManagerClick: this.handleColumnsManager,
                onExport: this.handleExport,
                onPrint: this.handlePrint
            },
            widgetNoData: !_.get(this.props, 'widgetData.tableData.length')
        };

        return (
            <Widget
                {...props}
                className='reporting-share-range-analysis-results-page'
                components={this.widgetComponents}
                events={this.widgetEvents}/>
        );
    }
}

export default ShareRangeAnalysisResults;
