import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import ShareholderInformation from '../_commonComponents/ShareholderInformation';
import BottomField from './components/BottomField';

@injectIntl
class InstitutionShareholderInformation extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    render() {
        const { widgetData, intl: { formatMessage } } = this.props;
        const props = {
            ...this.props,
            widgetTitle: formatMessage({ id: 'shareholder.information.insider.title' }),
            widgetNoData: _.isEmpty(widgetData),
            bottomFieldComponent: BottomField,
            contentClass: 'one-column',
            onAddReport: () => console.log('add to report')
        };

        return (
            <ShareholderInformation {...props} />
        );
    }
}

export default InstitutionShareholderInformation;
