import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import errorStrategies from 'Constants/error-stategies';
import * as utils from './utils';

export const getRecentSearch = () => {
    const requestData = utils.buildRequest({}, requestTypes.getShareholderRecentSearchPayload);

    return axios.post(apiRoutes.getRecentSearchUrl, requestData, {
        errorStrategy: errorStrategies.DEFAULT,
        isRetryable: false
    });
};

export const addAccountToRecentSearch = ({ shareholderId, shareholderName, shareholderType }) => {
    const requestData = utils.buildRequest(
        { shareholderId, shareholderName, shareholderType },
        requestTypes.updateShareholderRecentSearchPayload
    );

    return axios.post(apiRoutes.addAccountToRecentSearchUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};
