import React from 'react';
import { change } from 'redux-form';
import { push } from 'connected-react-router';
import { FormattedHTMLMessage } from 'react-intl';
import * as _ from 'lodash';

import * as multiSearchApi from '../../api/multiSearch';
import * as api from '../../api';

import { getCurrentSecuritySelector, getCustomSecurityIdSelector } from 'State/user';
import { getAvailableSecurities } from  'State/securities/selectors';

import {
    getAdvancedSearchSecurities,
    getFilterCriteria,
    getLastSearchParameters,
    getSearchTotals,
    getSelectedShareholdersToCompare,
    getSelectedShareholderIdsSelector,
    getSecurityIdOfSelectedShareholders,
    getVisibleSecurities,
    isMaxSelectedShareholderToCompare,
    isSearchInCurrentSecurity
} from 'State/advancedSearch/selectors';
import pageRoutes from 'Constants/page-routes';
import featureType from 'Constants/feature-types';
import { CONTEXTS, PAGE_INCREMENT, PAGE_SIZE, PII, SEARCH_FORM_NAME } from 'Constants/search';
import { getAvailableIssuers, getIssuerById } from 'State/issuers/selectors';
import { omitFilters, getSearchArea } from './utils';
import { buildLocationWithSafeQueryParams, buildLocationWithSecurity, buildSearchItemLocation } from 'Utils/routing';
import { setShareholdersToCompare } from 'State/compareShareholders';
import hashHistory from 'Context/history';
import { getSecurityByIdSelector } from '../securities/selectors';
import { notifyError } from 'State/notifier/actions';
import { showInfoModal, showSelectAccountModal } from 'State/modal';

export const SEARCH_SET_FETCHING = 'SEARCH_SET_FETCHING';
export const SEARCH_RESET_SELECTED_ISSUERS_SECURITIES = 'SEARCH_RESET_SELECTED_ISSUERS_SECURITIES';
export const TOGGLE_ADVANCED_SEARCH_ISSUER = 'TOGGLE_ADVANCED_SEARCH_ISSUER';
export const TOGGLE_ALL_ISSUERS = 'TOGGLE_ALL_ISSUERS';
export const TOGGLE_ADVANCED_SEARCH_SECURITY = 'TOGGLE_ADVANCED_SEARCH_SECURITY';
export const TOGGLE_ALL_SECURITIES = 'TOGGLE_ALL_SECURITIES';
export const SEARCH_FILTER_SET_STATUS = 'SEARCH_FILTER_SET_STATUS';
export const SEARCH_FILTER_SET_COUNTRY = 'SEARCH_FILTER_SET_COUNTRY';
export const SEARCH_FILTER_SET_STATE = 'SEARCH_FILTER_SET_STATE';
export const SEARCH_FILTER_SET_CONTEXT = 'SEARCH_FILTER_SET_CONTEXT';
export const SEARCH_FILTER_SET_ISSUER = 'SEARCH_FILTER_SET_ISSUER';
export const SEARCH_FILTER_SET_SECURITY = 'SEARCH_FILTER_SET_SECURITY';
export const UPDATE_SECURITIES_FILTER = 'UPDATE_SECURITIES_FILTER';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_MORE_FETCHING = 'SEARCH_MORE_FETCHING';
export const SEARCH_MORE_SUCCESS = 'SEARCH_MORE_SUCCESS';
export const TOGGLE_SHAREHOLDER_TO_COMPARE = 'TOGGLE_SHAREHOLDER_TO_COMPARE';
export const TOGGLE_ALL_SHAREHOLDERS = 'TOGGLE_ALL_SHAREHOLDERS';
export const CLEAR_TOGGLED_SHAREHOLDERS_TO_COMPARE = 'CLEAR_TOGGLED_SHAREHOLDERS_TO_COMPARE';
export const SET_IS_NEW_GLOBAL_SEARCH_REQUEST = 'SET_IS_NEW_GLOBAL_SEARCH_REQUEST';

export const resetSelectedIssuersAndSecuritiesSearch = () => ({
    type: SEARCH_RESET_SELECTED_ISSUERS_SECURITIES
});

export const setIsNewGlobalSearchRequest = value => dispatch => {
    dispatch({
        type: SET_IS_NEW_GLOBAL_SEARCH_REQUEST,
        payload: value
    });
};

export const toggleMultipleIssuer = (toggleIssuer, value) => (dispatch, getState) => {
    dispatch({
        type: TOGGLE_ADVANCED_SEARCH_ISSUER,
        payload: { toggleIssuer, value }
    });
    toggleSearchAcrossCurrent(getState, dispatch);
};

export const toggleAllIssuers = (value) => (dispatch, getState) => {
    dispatch({
        type: TOGGLE_ALL_ISSUERS,
        payload: { toggleIssuers: getAvailableIssuers(getState()), value }
    });
    toggleSearchAcrossCurrent(getState, dispatch);
};

function toggleSearchAcrossCurrent(getState, dispatch) {
    const state = getState();
    const isCurrentSelected = isSearchInCurrentSecurity(state);

    dispatch(change(SEARCH_FORM_NAME, 'searchAcrossCurrent', isCurrentSelected));
}

export const checkIsCurrentSelected = () => (dispatch, getState) => {
    toggleSearchAcrossCurrent(getState, dispatch);
};

export const toggleMultipleSecurity = (toggleSecurity, value) => (dispatch, getState) => {
    dispatch({
        type: TOGGLE_ADVANCED_SEARCH_SECURITY,
        payload: { toggleSecurity, value }
    });
    toggleSearchAcrossCurrent(getState, dispatch);
};

export const toggleAllSecurities = (value) => (dispatch, getState) => {
    dispatch({
        type: TOGGLE_ALL_SECURITIES,
        payload: { toggleSecurities: getAvailableSecurities(getState()), value }
    });
    toggleSearchAcrossCurrent(getState, dispatch);
};

export const toggleAllVisibleSecurities = (value) => (dispatch, getState) => {
    const state = getState();
    const checkedSecurities = getAdvancedSearchSecurities(state);
    const visibleSecurities = getVisibleSecurities(state);

    dispatch({
        type: TOGGLE_ALL_SECURITIES,
        payload: {
            toggleSecurities: value
                ? _.unionBy(checkedSecurities, visibleSecurities, 'id')
                : _.differenceBy(checkedSecurities, visibleSecurities, 'id'),
            value: true
        }
    });
    toggleSearchAcrossCurrent(getState, dispatch);
};

export const checkCurrentSecurity = () => (dispatch, getState) => {
    const state = getState();
    const currentSecurity = getCurrentSecuritySelector(state);

    if (!currentSecurity) return;

    const currentIssuer = getIssuerById(state, currentSecurity.issuerId);

    if (!currentIssuer) return;
    dispatch(toggleAllIssuers(false));
    dispatch(toggleMultipleIssuer(currentIssuer, true));

    dispatch(toggleAllSecurities(false));
    dispatch(toggleMultipleSecurity(currentSecurity, true));
    dispatch(updateSecuritiesFilter());
    dispatch(change(SEARCH_FORM_NAME, 'securitiesFilter', ''));
};

export const uncheckCurrentSecurity = () => (dispatch) => {
    dispatch(toggleAllIssuers(false));
    dispatch(toggleAllSecurities(false));
};

export const successSearch = (payload, pii, securities, filtering, isSearchTreasury = false) => (dispatch, getState) => {
    const aggregate = _.get(payload, 'data.aggregate', {});
    const results = _.get(payload, 'data.results');
    const globalState = getState();
    const issuers = [];
    const selectedSecurities = [];
    const customSecurityId = getCustomSecurityIdSelector(globalState);
    let followRegisterDetails = false;

    if (!filtering) {
        // filters updating
        securities.forEach(secId => {
            const sec = _.find(globalState.securities.availableSecurities, s => s.id === secId);
            const selectedIssuer = _.find(globalState.issuers.availableIssuers, is => is.id === sec.issuerId);

            selectedSecurities.push(sec);
            if (!_.includes(issuers, selectedIssuer) && selectedIssuer) {
                issuers.push(selectedIssuer);
            }
        });
        // end filters updating

        const isExactMatch = (pii === PII.TAX_ID || pii === PII.CERTIFICATE_NO || isSearchTreasury)
            && aggregate.total === 1
            && _.get(results, '[0].type') === CONTEXTS.REGISTERED;

        if (isExactMatch) {
            const securityId = _.get(results, '[0].values[0].security.securityId');
            const features = _.get(getSecurityByIdSelector(globalState, securityId), 'features', {});
            const hasAccess = features[featureType.REGISTERED_OVERVIEW];

            if (hasAccess) {
                followRegisterDetails = true;
            }
        }
    }
    const searchArea = isSearchTreasury
        ? getCurrentSecuritySelector(globalState)
        : getSearchArea(globalState);

    dispatch({
        type: SEARCH_SUCCESS,
        payload: {
            results,
            searchArea,
            total: aggregate.total,
            totalIssuers: aggregate.issuers,
            totalSecurities: aggregate.securities,
            query: aggregate.query,
            token: aggregate.token,
            securities: selectedSecurities,
            issuers,
            pii,
            needFiltersUpdate: !filtering,
            isSearchTreasury
        }
    });

    if (followRegisterDetails) {
        const detailsPageLocation = buildSearchItemLocation(CONTEXTS.REGISTERED, _.get(results, '[0].values[0]'), false);

        if (detailsPageLocation) {
            dispatch(push(detailsPageLocation));
        } else {
            dispatch(notifyError(null, null, {
                component: () => (
                    <span className='error-message'><FormattedHTMLMessage id='error.requestFailed'/></span>)
            }));
        }
    } else {
        const searchResultPage = {
            ...buildLocationWithSecurity(pageRoutes.searchResult, customSecurityId)
        };

        dispatch(push(searchResultPage));
    }
};

let cancelTokens = [];

function handleSearchRequest(fullSearchQuery, newCancelToken, dispatch, filtering) {
    const isAdvanced = fullSearchQuery.pii !== PII.NONE;
    const context = isAdvanced ? 'advanced' : 'global';

    dispatch(setFetchingStatus(context, true));
    dispatch(clearShareholdersToCompare);
    if (!filtering) {
        dispatch(setIsNewGlobalSearchRequest(true));
    }

    return multiSearchApi.getSearchResults(fullSearchQuery,
        {
            originalCancelToken: newCancelToken
        }
    ).then(res => {
        _.remove(cancelTokens, cancelToken => cancelToken === _.get(res, 'config.originalCancelToken'));
        dispatch(successSearch(res.data.payload, fullSearchQuery.pii, fullSearchQuery.securities, filtering));
    }).finally(() => {
        dispatch(setFetchingStatus(context, false));
    });
}

export const cancelSearchRequest = () => () => {
    if (cancelTokens.length > 0) {
        cancelTokens.forEach(token => token.cancel());
        cancelTokens = [];
    }
};

function addNewToken() {
    const newCancelToken = api.createCancelToken();

    if (_.get(cancelTokens, 'length') > 0) {
        cancelTokens.forEach(token => token.cancel());
        cancelTokens = [];
    }
    cancelTokens.push(newCancelToken);
    return newCancelToken;
}

export const setFetchingStatus = (context, status) => ({
    type: SEARCH_SET_FETCHING,
    payload: { context, status }
});

export const search = (pii, query, onlyCurrentSecurity) => (dispatch, getState) => {
    const trimmedQuery = query.trim();
    const newCancelToken = addNewToken();

    if (onlyCurrentSecurity) {
        dispatch(checkCurrentSecurity());
    }

    const globalState = getState();
    const selectedSecurities = getAdvancedSearchSecurities(globalState).map(s => s.id);
    const visibleSecurities = getVisibleSecurities(globalState).map(s => s.id);
    const securities = _.intersection(selectedSecurities, visibleSecurities);

    if (onlyCurrentSecurity) {
        dispatch(checkCurrentSecurity());
    }

    const fullSearchQuery = {
        pii,
        query: trimmedQuery,
        securities,
        pagination: {
            offset: 0,
            size: PAGE_SIZE
        },
        contexts: [],
        filterCriteria: {
            statuses: [],
            countries: [],
            state: null
        }
    };

    return handleSearchRequest(fullSearchQuery, newCancelToken, dispatch, false);
};

export const searchWithFilter = () => (dispatch, getState) => {
    const newCancelToken = addNewToken();

    const pagination = {
        offset: 0,
        size: PAGE_SIZE
    };
    const globalState = getState();
    const filterCriteria = getFilterCriteria(globalState);
    const lastSearch = getLastSearchParameters(globalState);
    const totals = getSearchTotals(globalState);
    const {
        statuses = [],
        countries = [],
        contexts = [],
        state = null
    } = omitFilters(filterCriteria);
    const filterSecurities = [];

    _.keys(filterCriteria.securities).forEach(secId => {
        if (filterCriteria.securities[secId]) {
            filterSecurities.push(secId);
        }
    });

    const fullSearchQuery = {
        pii: lastSearch.pii,
        query: lastSearch.query,
        token: lastSearch.token,
        securities: !_.isEmpty(filterSecurities) ? filterSecurities : totals.securities.map(sec => sec.id),
        pagination,
        contexts,
        filterCriteria: {
            statuses,
            countries,
            state
        }
    };

    return handleSearchRequest(fullSearchQuery, newCancelToken, dispatch, true);
};

export const successMoreResults = (data) => ({
    type: SEARCH_MORE_SUCCESS,
    payload: _.get(data, 'payload.data')
});

export const loadMoreResults = (context, offset) => (dispatch, getState) => {
    const newCancelToken = addNewToken();

    const globalState = getState();
    const filterCriteria = getFilterCriteria(globalState);
    const lastSearch = getLastSearchParameters(globalState);
    const totals = getSearchTotals(globalState);
    const {
        statuses = [],
        countries = [],
        state = null
    } = omitFilters(filterCriteria);
    const filterSecurities = [];

    _.keys(filterCriteria.securities).forEach(secId => {
        if (filterCriteria.securities[secId]) {
            filterSecurities.push(secId);
        }
    });

    const fullSearchQuery = {
        pii: lastSearch.pii,
        query: lastSearch.query,
        token: lastSearch.token,
        securities: !_.isEmpty(filterSecurities) ? filterSecurities : totals.securities.map(sec => sec.id),
        pagination: {
            offset,
            size: PAGE_INCREMENT
        },
        contexts: [context],
        filterCriteria: {
            statuses,
            countries,
            state
        }
    };

    dispatch(setFetchingStatus(context, true));

    return multiSearchApi.getSearchResults(
        fullSearchQuery,
        {
            originalCancelToken: newCancelToken
        }).then(res => {
        _.remove(cancelTokens, cancelToken => cancelToken === _.get(res, 'config.originalCancelToken'));
        dispatch(successMoreResults(res.data));
    }).finally(() => {
        dispatch(setFetchingStatus(context, false));
    });
};

export const searchGlobally = (query) => {
    return search(PII.NONE, query, true);
};
export const searchByTaxID = (query) => {
    return search(PII.TAX_ID, query, true);
};
export const searchByCertificateNo = (query) => {
    return search(PII.CERTIFICATE_NO, query, true);
};

export const updateSecuritiesFilter = (filter) => dispatch => {
    dispatch({
        type: UPDATE_SECURITIES_FILTER,
        payload: { filter }
    });
};

export const setContext = payload => dispatch => {
    dispatch({
        type: SEARCH_FILTER_SET_CONTEXT,
        payload
    });

    return dispatch(searchWithFilter());
};

export const setStatus = payload => dispatch => {
    dispatch({
        type: SEARCH_FILTER_SET_STATUS,
        payload
    });

    return dispatch(searchWithFilter());
};

export const setCountry = payload => dispatch => {
    dispatch({
        type: SEARCH_FILTER_SET_COUNTRY,
        payload
    });

    return dispatch(searchWithFilter());
};

export const setState = payload => dispatch => {
    dispatch({
        type: SEARCH_FILTER_SET_STATE,
        payload
    });

    return dispatch(searchWithFilter());
};

export const setIssuerFilter = payload => dispatch => {
    dispatch({
        type: SEARCH_FILTER_SET_ISSUER,
        payload
    });

    return dispatch(searchWithFilter());
};

export const setSecurityFilter = payload => dispatch => {
    dispatch({
        type: SEARCH_FILTER_SET_SECURITY,
        payload
    });

    return dispatch(searchWithFilter());
};

export const toggleShareholderToCompare = (shareholder) => ({
    type: TOGGLE_SHAREHOLDER_TO_COMPARE,
    payload: shareholder
});

export const toggleAllShareholdersToCompare = () => (dispatch, getState) => {
    const isMax = isMaxSelectedShareholderToCompare(getState());

    dispatch({
        type: TOGGLE_ALL_SHAREHOLDERS,
        payload: !isMax
    });
};

export const clearShareholdersToCompare = () => ({
    type: CLEAR_TOGGLED_SHAREHOLDERS_TO_COMPARE
});

export const submitShareholdersToCompare = () => (dispatch, getState) => {
    dispatch(setShareholdersToCompare(getSelectedShareholdersToCompare(getState())));
    const currentLocation = hashHistory.location;

    dispatch(push(buildLocationWithSafeQueryParams(pageRoutes.compareShareholders, currentLocation)));
};

export const checkShareholdersSSN = () => (dispatch, getState) => {
    const state = getState();
    const accounts = getSelectedShareholderIdsSelector(state);
    const securityId = getSecurityIdOfSelectedShareholders(state);

    return api.checkAccountsSSN(accounts, securityId)
        .then(
            res => {
                const isCheckingSuccessful = _.get(res, 'data.payload.isSuccessful');

                if (isCheckingSuccessful) {
                    dispatch(showSelectAccountModal(accounts));
                } else {
                    dispatch(showInfoModal({
                        titleKey: 'search.modals.cannotCombine.title',
                        messageKey: 'search.modals.cannotCombine.message',
                        okTextKey: 'search.modals.okText',
                        modalClassName: 'fail-combine-modal'
                    }));
                }
            }
        );
};

export const combineAccounts = (to) => (dispatch, getState) => {
    const state = getState();
    const accounts = getSelectedShareholderIdsSelector(state);
    const securityId = getSecurityIdOfSelectedShareholders(state);
    const customSecurityId = getCustomSecurityIdSelector(state);
    const location = buildLocationWithSecurity(pageRoutes.combineShareholders, customSecurityId);

    return api.combineAccounts(accounts, to, securityId)
        .then(
            () => {
                dispatch(push(location));
            }
        );
};

export const handleTreasurySearchRequest = () => (dispatch, getState) => {
    const state = getState();
    const context = 'global';
    const newCancelToken = addNewToken();
    const currentSecurity = getCurrentSecuritySelector(state);

    dispatch(setFetchingStatus(context, true));
    dispatch(clearShareholdersToCompare);
    dispatch(setIsNewGlobalSearchRequest(true));

    return multiSearchApi.getTreasurySearchResults(newCancelToken)
        .then(res => {
            _.remove(cancelTokens, cancelToken => cancelToken === _.get(res, 'config.originalCancelToken'));
            dispatch(successSearch(res.data.payload, null, [currentSecurity.id], null, true));
        }).finally(() => {
            dispatch(setFetchingStatus(context, false));
        });
};
