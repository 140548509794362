import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';

const ReportRequestedModalView = ({ onConfirm, downloadUrl, isSummaryOnly }) => (
    <div className='info-modal report-requested-modal'>
        <div className='modal-message'>
            <p>{isSummaryOnly
                ? <FormattedMessage id='modals.reportRequested.summaryOnly.message'/>
                : <FormattedMessage id='modals.reportRequested.fullReport.message'/>
            }</p>
            <p>
                <FormattedMessage id='modals.reportRequested.message'/>
            </p>
            <p><FormattedMessage
                id='modals.exportPending.downloadsMessage'
                values={{
                    downloadsPageLink: <LinkEx
                        className='download-page-link'
                        to={downloadUrl}
                        onClick={onConfirm}>
                        <FormattedMessage
                            id='modals.exportPending.downloadsPageLink'/>
                    </LinkEx>,
                    forSecurity: ''
                }}/>
            </p>
        </div>
        <div className='modal-actions'>
            <button type='button' onClick={onConfirm} className='btn btn-large ok-btn'>
                <FormattedMessage id='common.close'/>
            </button>
            <LinkEx
                className='btn btn-large downloads-btn'
                to={downloadUrl}
                onClick={onConfirm}>
                <FormattedMessage
                    id='modals.exportPending.downloadsPageButton'/>
            </LinkEx>
        </div>
    </div>
);

ReportRequestedModalView.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    downloadUrl: PropTypes.object,
    isSummaryOnly: PropTypes.bool
};

export default memo(ReportRequestedModalView);
