import _ from 'lodash';

import colorScheme from 'Constants/color-schemas';
import { LINE_MAX_VALUE } from './constants/analysis-chart-config';

const barColorScheme = colorScheme['1'];

export const getMappedLineData = (lineData, groupCount) => {
    const groupXRange = LINE_MAX_VALUE / groupCount;
    let mappedLineData = [];

    _.forEach(lineData, (groupData, groupKey) => {
        const dayXRange = groupXRange / _.get(groupData, 'series.length') || 1;

        _.forEach(groupData.series, (data, valueKey) => {
            mappedLineData = [...mappedLineData, {
                x: groupXRange * groupKey + dayXRange * valueKey,
                y: data.value,
                name: data.name
            }];
        });
    });

    return mappedLineData;
};

const getNextColor = (currentColorsState) => {
    const currentColorIndexes = _.values(currentColorsState);

    if (_.keys(currentColorsState).length < barColorScheme.length) {
        return _.find(barColorScheme, (color, index) => !_.includes(currentColorIndexes, index));
    }

    return barColorScheme[_.minBy(_.values(_.groupBy(currentColorIndexes, colorIndex => colorIndex)), values => values.length)[0]];
};

export const getColorsData = (savedColorsState = {}, ids) => {
    const data = {
        state: {},
        colors: []
    };

    _.forEach(ids, id => {
        const indexColor = savedColorsState[id];

        if (_.isNumber(indexColor) && barColorScheme.length > indexColor) {
            data.state[id] = savedColorsState[id];
        }
    });

    _.forEach(ids, id => {
        if (_.isNumber(data.state[id])) {
            data.colors.push(barColorScheme[savedColorsState[id]]);
        } else {
            const color = getNextColor(data.state);

            data.state[id] = _.indexOf(barColorScheme, color);
            data.colors.push(color);
        }
    });

    return data;
};

export function tooltipFormatter() {
    const { color, category, name, y, series, shapeType } = this.point;
    const isBar = shapeType === 'rect';

    return `<div class="ast-highcharts-tooltip" style="border-color:${color}">
        <div style="font-size:10px">${isBar ? category : name}</div>
            ${isBar ? `<span style="color:${color}">\u25CF</span> ${series.name}: <b>${y}</b>` : y}
        </div>`;
}

