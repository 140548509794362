import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';
import PeerList from './PeerList';
import SearchPeersPanel from './SearchPeersPanel';
import Spinner from 'Components/Spinner';
import { peerShape } from 'Constants/peer';
import pageRoutes from 'Constants/page-routes';

import './UserPeers.scss';

const UserPeers = ({
    peers,
    maxCountPeers,
    isFetching,
    onResetPeers,
    onAddPeer,
    onSearchPeers,
    onRemovePeer
}) => {
    return (
        <div className='user-peers'>
            <div className='user-peers-header'>
                <div className='user-peers-caption-block'>
                    <h2 className='user-peers-caption'><FormattedMessage id='user.profile.peers.title'/></h2>
                    {maxCountPeers &&
                        <span className='message-note'>
                            <FormattedMessage
                                id='user.profile.peers.info.maxCountPeers'
                                values={{ maxCountPeers }}/>
                        </span>}
                </div>
                <a className='reset-link' onClick={onResetPeers}>
                    <FormattedMessage id='user.profile.peers.reset.link' />
                </a>
            </div>
            <div>
                {peers
                    ? <PeerList peers={peers} onRemovePeer={onRemovePeer} isFetching={isFetching}/>
                    : <Spinner isFetching/>}
            </div>
            {(!peers || peers.length < maxCountPeers) && !isFetching &&
                <SearchPeersPanel
                    onSearch={onSearchPeers}
                    onSuccess={onAddPeer}/>
            }
            <div className='info-panel'>
                { peers && peers.length === maxCountPeers &&
                <div>
                    <div className='message-note'>
                        <FormattedMessage
                            id='user.profile.peers.info.maxCountPeers.selected'
                            values={{ maxCountPeers }}/>
                    </div>
                    <div className='message-note'>
                        <FormattedMessage id='user.profile.peers.info.maxCountPeers.todo'/>
                    </div>
                </div>
                }
                <div className='message-note'>
                    <FormattedMessage id='user.profile.peers.info.source'/>
                </div>
                <p>
                    <LinkEx to={pageRoutes.peerAnalysis}>
                        <FormattedMessage id='user.profile.peers.link.peeranalysis'/>
                    </LinkEx>
                </p>
            </div>
        </div>
    );
};

UserPeers.propTypes = {
    peers: PropTypes.arrayOf(peerShape),
    maxCountPeers: PropTypes.number,
    isFetching: PropTypes.bool,
    onResetPeers: PropTypes.func.isRequired,
    onRemovePeer: PropTypes.func.isRequired,
    onAddPeer: PropTypes.func.isRequired,
    onSearchPeers: PropTypes.func.isRequired,
    isShowResetButton: PropTypes.bool
};

export default UserPeers;
