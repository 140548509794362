import { branch, compose, renderNothing, withProps } from '@shakacode/recompose';
import { connect } from 'react-redux';

import TreasuryAccountsLink from 'Components/TreasuryAccountsLink';
import { requiredFeatureDisabledSelector } from 'State/features';
import featureTypes from 'Constants/feature-types';

export default compose(
    withProps({
        requiredFeature: featureTypes.DISABLE_TREASURY_ACCOUNT_LINK
    }),
    connect((state, props) => ({
        featureNotExist: requiredFeatureDisabledSelector(state, props)
    })),
    branch(
        ({ featureNotExist }) => !featureNotExist,
        renderNothing,
    )
)(TreasuryAccountsLink);
