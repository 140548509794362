import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import errorStrategies from 'Constants/error-stategies';

import * as utils from './utils';

export const getUploadRequestTypes = () => {
    const requestData = utils.buildRequest({  }, requestTypes.getUploadRequestTypesPayload);

    return axios.post(apiRoutes.getUploadRequestTypesUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const getUploadRequestList = () => {
    const requestData = utils.buildRequest({  }, requestTypes.getUploadRequestListPayload);

    return axios.post(apiRoutes.getUploadRequestListUrl, requestData, {
        errorStrategy: errorStrategies.DEFAULT,
        isRetryable: false
    });
};

export const getUploadRequestTemplates = (requestTypeId) => {
    const requestData = utils.buildRequest({ requestTypeId }, requestTypes.getUploadRequestTemplatesPayload);

    return axios.post(apiRoutes.getUploadRequestTemplatesUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const createUploadRequestDraft = (requestTypeId) => {
    const requestData = utils.buildRequest({ requestTypeId }, requestTypes.createUploadRequestDraftPayload);

    return axios.post(apiRoutes.createUploadRequestDraftUrl, requestData, {
        errorStrategy: errorStrategies.DEFAULT,
        isRetryable: false
    });
};

export const getUploadRequest = (requestId) => {
    const requestData = utils.buildRequest({ requestId }, requestTypes.getUploadRequestPayload);

    return axios.post(apiRoutes.getUploadRequestUrl, requestData, {
        errorStrategy: errorStrategies.DEFAULT,
        isRetryable: false
    });
};

export const updateUploadRequest = (data) => {
    const requestData = utils.buildRequest({ ...data }, requestTypes.updateUploadRequestPayload);

    return axios.post(apiRoutes.updateUploadRequestUrl, requestData, {
        errorStrategy: errorStrategies.DEFAULT,
        isRetryable: false
    });
};

export const uploadDocumentRequest = (requestId, document) => {
    const formData = new FormData();

    formData.append('document', document);
    formData.append('requestId', requestId);

    return axios.post(apiRoutes.uploadSecureDocumentUrl, formData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getUploadRequestDocuments = (requestId) => {
    const requestData = utils.buildRequest({ requestId }, requestTypes.getUploadRequestDocumentsPayload);

    return axios.post(apiRoutes.getUploadRequestDocumentsUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const deleteUploadRequestDocument = (documentId) => {
    const requestData = utils.buildRequest({ documentId }, requestTypes.deleteUploadRequestDocumentPayload);

    return axios.post(apiRoutes.deleteUploadRequestDocumentUrl, requestData, {
        errorStrategy: errorStrategies.DEFAULT,
        isRetryable: false
    });
};

export const getSecureUploadDocumentGuid = (documentId) => {
    const requestData = utils.buildRequest({ documentId }, requestTypes.getSecureUploadDocumentGuidPayload);

    return axios.post(apiRoutes.getSecureUploadDocumentGuidUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};

export const getSecureUploadEmailRecipients = (requestId) => {
    const requestData = utils.buildRequest({}, requestTypes.secureUploadEmailRecipients);

    return axios.post(`${apiRoutes.getSecureUploadEmailRecipientsUrl}/${requestId}`, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isRetryable: false
    });
};
