import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import TableEasy from 'Components/Tables/TableEasy';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import { NullableFormatter, NumberFormatter } from 'Components/formatters';
import widgetModes from 'Constants/widget-modes';
import Tooltip from 'Components/Tooltip';

class OutstandingPaymentsContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        tableView: PropTypes.bool,
        onMonthChange: PropTypes.func,
        dates: PropTypes.array,
        selectedDate: PropTypes.string
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = props;

        this.columns = [{
            dataKey: 'type',
            title: formatMessage({ id: 'outstanding.payments.type' }),
            headerCellComponent: () => (<Tooltip
                content={<FormattedHTMLMessage id='outstanding.payments.tooltip.type'/>}>
                <span>{formatMessage({ id: 'outstanding.payments.type' })}</span>
            </Tooltip>),
            bodyCellComponent: v => {
                if (v.value.toLowerCase() === 'other') {
                    return (
                        <Tooltip
                            content={<span>{formatMessage({ id: 'outstanding.payments.tooltip.other' })}</span>}>
                            <span>{v.value}</span>
                        </Tooltip>
                    );
                }
                return <NullableFormatter {...v} withTitle/>;
            }
        }, {
            dataKey: 'noOfPayments',
            columnClassName: 'text-right',
            title: formatMessage({ id: 'outstanding.payments.noOfPayments' }),
            bodyCellComponent: v => <NumberFormatter {...v} withTitle/>
        }];
    }

    render() {
        const {
            widgetSettings: { mode },
            data: { aggregate, values },
            isFetching,
            widgetNoData
        } = this.props;
        const hideBarChart = mode === widgetModes.TABLE;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        className='widget-table'
                        hideBarChart={hideBarChart}
                        columns={this.columns}
                        data={values}
                        aggregate={aggregate}/>
                </NoDataComponent>
            </div>);
    }
}

export default OutstandingPaymentsContent;
