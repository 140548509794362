import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';

export default [
    {
        columnName: columnNames.DOCUMENT_DATE,
        order: 1,
        minWidth: 120,
        displayName: 'registered.shareholder.table.documentDate',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.AUTHOR,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.author',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.DOCUMENT_TYPE,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.documentType',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.DESCRIPTION,
        order: 4,
        minWidth: 170,
        displayName: 'registered.shareholder.table.description',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.UPLOAD_STATUS,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.status',
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.DOWNLOAD,
        disableSort: true,
        order: 6,
        width: 100,
        minWidth: 100,
        manageable: false,
        displayName: 'registered.shareholder.table.download'
    },
    {
        columnName: columnNames.DELETE,
        disableSort: true,
        order: 7,
        width: 85,
        minWidth: 85,
        manageable: false,
        displayName: 'registered.shareholder.table.delete'
    }
];

export const secondarySorting = {
    field: columnNames.DOCUMENT_TYPE,
    direction: sortDirections.ASC
};
