import _ from 'lodash';

import shareholderTypes from 'Constants/shareholder-types';
import { columnsDefinitions } from './constants/columnsToDisplayOptions';
import scheduleTypes from 'Components/ReportScheduler/constants/schedule-types';

const SORTABLE_COLUMN_FIELD = 'order';

export const getReportFormValues = (formValues, countriesAndStates, shareholderTypeOptions, isSchedulerAvailable) => {
    const {
        columnsToDisplay,
        shareholderType,
        shareholderLocation: { country, state }
    } = formValues;
    const currentShareholderType = shareholderType || _.get(shareholderTypeOptions, '[0].value');
    const _columnsToDisplay = {
        ...columnsToDisplay,
        REGISTERED: isDisabledColumnType(shareholderTypes.REGISTERED, currentShareholderType) ? [] : columnsToDisplay.REGISTERED,
        INSTITUTION: isDisabledColumnType(shareholderTypes.INSTITUTION, currentShareholderType) ? [] : columnsToDisplay.INSTITUTION
    };
    const columns = _.flatten(_.values(_columnsToDisplay));
    const sortedColumns = _.sortBy(columnsDefinitions, [SORTABLE_COLUMN_FIELD]);
    const columnNames = _.map(sortedColumns, column => column.columnName);
    const scheduleType = formValues.isLastDayOfMonth ? scheduleTypes.MONTHLY_LAST_DAY : _.get(formValues, 'schedule.scheduleType');

    if (countriesAndStates) {
        const countryObject = country && _.find(countriesAndStates.countriesList, c => c.id === country);
        const stateObject = state && _.find(countriesAndStates.statesList[countryObject.id], s => s.id === state);

        return {
            ..._.omit(formValues, ['isScheduled', 'isLastDayOfMonth']),
            schedule: {
                scheduleParam: isSchedulerAvailable ? formValues.schedule.scheduleParam : null,
                scheduleType: isSchedulerAvailable ? scheduleType : null
            },
            shareholderType: currentShareholderType,
            shareholderLocation: {
                ...formValues.shareholderLocation,
                country: countryObject && {
                    name: countryObject.title,
                    abbreviation: countryObject.id
                },
                state: stateObject && {
                    name: stateObject.title,
                    abbreviation: stateObject.id
                }
            },
            columnsToDisplay: _.filter(columnNames,
                columnName => _.includes(columns, columnName))
        };
    }

    return {
        ..._.omit(formValues, ['isScheduled', 'isLastDayOfMonth']),
        schedule: {
            ..._.map(formValues, 'schedule'),
            scheduleType
        },
        shareholderType: currentShareholderType,
        columnsToDisplay: _.filter(columnNames,
            columnName => _.includes(columns, columnName))
    };
};

export const isDisabledColumnType = (type, selectedType) => {
    let isDisabled = false;

    if (type === shareholderTypes.REGISTERED) {
        isDisabled =
            selectedType === shareholderTypes.INSTITUTION ||
            selectedType === shareholderTypes.INSIDER ||
            selectedType === shareholderTypes.INSTITUTION_AND_INSIDER;
    } else if (type === shareholderTypes.INSTITUTION) {
        isDisabled = selectedType === shareholderTypes.REGISTERED;
    }

    return isDisabled;
};
