import PropTypes from 'prop-types';
import React from 'react';

const HighlightFormatter = (props) => {
    const { value, separator } = props;

    const parts = value.split(separator);

    for (let i = 1; i < parts.length; i += 2) {
        parts[i] = <span className='strong' key={i}>{parts[i]}</span>;
    }
    return <span>{parts}</span>;
};

HighlightFormatter.propTypes = {
    value: PropTypes.string.isRequired,
    separator: PropTypes.object.isRequired
};

export default HighlightFormatter;
