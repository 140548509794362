import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.BOOK_ACTIVITY_DATE,
        order: 1,
        minWidth: 130,
        displayName: 'registered.shareholder.table.bookActivityDate',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.SHARES,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.shares',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.DEBIT_CREDIT,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.debitCredit',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.ADVICE_NO,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.adviceNo',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            valueLength: 10
        }
    },
    {
        columnName: columnNames.SOURCE_CODE,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.sourceCode',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.NUMBER
        }
    },
    {
        columnName: columnNames.BOOK_PRICE_USD,
        order: 7,
        minWidth: 120,
        displayName: 'registered.shareholder.table.bookPriceUSD',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 6
        }
    }
];

export const secondarySorting = {
    field: columnNames.SHARES,
    direction: sortDirections.DESC
};
