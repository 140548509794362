import PropTypes from 'prop-types';
import React from 'react';
import { intlShape, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import DateFormatter from 'Components/formatters/DateFormatter';
import { SHARE_TYPES_TITLES } from '../../constants/constantValues';

const ShareRangeAnalysisResultsHeader = (props) => {
    const shareType = _.get(props, ['widgetData', 'shareType'], '');
    const shareRange = _.get(props, ['widgetData', 'shareRange'], {});
    const closeOfBusinessDate = _.get(props, ['pageInfo', 'closeOfBusinessDate'], '');
    const hasAllData = !!shareType && !_.isNil(shareRange.start) &&
        !_.isNil(shareRange.end) && !_.isNil(shareRange.increment) && !!closeOfBusinessDate;

    return (
        <div className='widget-header'>
            { hasAllData &&
                <FormattedMessage
                    id={'reporting.share.range.analysis.results.subtitle'}
                    values={{
                        type: props.intl.formatMessage({ id: SHARE_TYPES_TITLES[shareType] }),
                        start: shareRange.start,
                        end: shareRange.end,
                        increment: shareRange.increment
                    }}/>
            }
            { hasAllData &&
                <div className='message-note'>
                    <FormattedMessage id='reporting.share.range.analysis.results.date'/> {' '}
                    <DateFormatter value={closeOfBusinessDate}/>
                </div>
            }
        </div>
    );
};

ShareRangeAnalysisResultsHeader.propTypes = {
    ...commonWidgetPropTypes,
    ...commonWidgetActions,
    pageInfo: PropTypes.object,
    intl: intlShape
};

export default ShareRangeAnalysisResultsHeader;
