import _ from 'lodash';
import { createSelector } from 'reselect';

import * as userSelectors from 'State/user/selectors';
import { OI, TA } from 'Constants/product-source-types';

const demoModeFilterFeatures = [
    'HOME', 'HISTORICAL_ANALYSIS',
    'SHARE_RANGE_ANALYSIS_RESULTS', 'PEER_ANALYSIS', 'CONTACTS', 'GEOGRAPHIC_ANALYSIS', 'SHARE_DETAIL',
    'HOLDING_INVESTOR_TYPE_ANALYSIS', 'MEETING_PLANNER', 'CLASSIC_SHAREHOLDER_LIST_AS_OF_DATE',
    'CUSTOM_REPORTS', 'SHAREHOLDERS_TABLE_PROXY'
];

export const getCurrentFeaturesSelector = state => _.omit(_.get(state, 'features.currentFeatures'), DEMO_MODE ? demoModeFilterFeatures : []);

export const requiredFeatureDisabledSelector = createSelector(
    userSelectors.isSecurityChangingSelector,
    getCurrentFeaturesSelector,
    (state, props) => props.requiredFeature,
    (isSecurityChanging, features, requiredFeature) => {
        return requiredFeature && !isSecurityChanging && !features[requiredFeature];
    }
);

export const getCurrentProductSourcesSelector = state => _.get(state, 'features.currentProductSources');

export const isCombinedProductSelector = createSelector(
    getCurrentProductSourcesSelector,
    (currentProductSources) => {
        const hasOI = _.includes(currentProductSources, OI);
        const hasTA = _.includes(currentProductSources, TA);

        return hasOI && hasTA;
    }
);

