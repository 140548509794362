import React from 'react';
import PropTypes from 'prop-types';
import UndefinedShareholderTooltip from '../UndefinedShareholderTooltip';

const CellWithUndefinedTooltip = (props) => {
    const accessible = props.row.accessible;
    const { children, block, ...childrenProps } = props;

    if (accessible === false) {
        return (
            <UndefinedShareholderTooltip block={block}>
                {children(childrenProps)}
            </UndefinedShareholderTooltip>
        );
    }

    return children(childrenProps);
};

CellWithUndefinedTooltip.propTypes = {
    children: PropTypes.func,
    row: PropTypes.shape({
        accessible: PropTypes.bool
    }),
    block: PropTypes.bool
};

export default CellWithUndefinedTooltip;
