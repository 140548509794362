import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import pageRoutes from 'Constants/page-routes';
import shareholderTypes from 'Constants/shareholder-types';
import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import Widget from '../../Widget';
import AnalysisGeographicContent from './components/AnalysisGeographicContent';
import ShareholderWidgetControlPanel from '../../Widget/components/ShareholderWidgetControlPanel';
import AnalysisGeographicActionPanel from './components/AnalysisGeographicActionPanel';
import { changeGeographicAnalysisRouteState } from 'State/routeState';
import FooterWithInfo from '../FooterWithInfo';
import widgetTypes from 'Constants/widget-types';
import messageType from 'Constants/message-types';
import { getWidgetFooterPropsWithSignClass } from 'Components/widgets/utils';
import LinkEx from 'Components/LinkEx';

import './AnalysisGeographic.scss';

const TOTAL_PERCENT = 100;
const mapDispatchToProps = {
    changeGeographicAnalysisRouteState
};

@connect(null, mapDispatchToProps)
class AnalysisGeographic extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        footerProps: PropTypes.object,
        changeGeographicAnalysisRouteState: PropTypes.func,
        geographicAnalysisLocationFilterType: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.formatMessage = props.intl.formatMessage;
        this.widgetTitle = this.formatMessage({ id: 'analysis.geographic.title' });
        this.widgetComponents = {
            WidgetActionPanel: AnalysisGeographicActionPanel,
            WidgetContent: AnalysisGeographicContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetFooter: FooterWithInfo
        };
    }

    handleFullReportClick = () => {
        const { widgetSettings, widgetData, geographicAnalysisLocationFilterType } = this.props;
        const activeShareholder = AnalysisGeographic.getActiveShareholder(widgetData, widgetSettings);
        const shareholderType = _.get(activeShareholder, 'shareholderType', widgetSettings.activeShareholderType);

        this.props.changeGeographicAnalysisRouteState({
            shareholderType,
            locationFilterType: geographicAnalysisLocationFilterType
        });
    };

    static getActiveShareholder = (widgetData, widgetSettings) => {
        if (widgetData && widgetData.length && widgetSettings) {
            const activeShareholderItem = _.find(widgetData, item => {
                return item.shareholderType === widgetSettings.activeShareholderType;
            });

            // if activeShareholderType is incorrect - select first
            return activeShareholderItem || widgetData[0];
        }
        return null;
    };

    getWidgetFooterProps = (widgetData, shareholderType) => {
        const osTotal = _.get(widgetData, 'aggregate.osTotal');
        const additionalLeftSideProps = _.get(this.props, 'footerProps.leftSide', []);
        const additionalRightSideProps = _.get(this.props, 'footerProps.rightSide', []);
        const additionalTopProps = _.get(this.props, 'footerProps.top', []);
        const additionalBottomProps = _.get(this.props, 'footerProps.bottom', []);
        const widgetType = this.props.widgetType;

        return {
            top: [
                {
                    messageKey: 'analysis.geographic.totalInfoMessage',
                    hidden: !widgetData || widgetType === widgetTypes.analysisGeographicMetro
                },
                {
                    render: () => (
                        <div key='widgets.link.fullReport'>
                            <LinkEx
                                className='full-report-link'
                                to={pageRoutes.reportingGeographicAnalysis}
                                onClick={this.handleFullReportClick}>
                                {this.formatMessage({ id: 'widgets.link.fullReport' })}
                            </LinkEx>
                            <span className='message-note'>
                                {this.formatMessage({ id: 'analysis.geographic.fullReportText' })}
                            </span>
                        </div>
                    ),
                    hidden: this.props.isDataViz || widgetType === widgetTypes.analysisGeographicMetro
                },
                ...additionalTopProps
            ],
            leftSide: [...additionalLeftSideProps],
            rightSide: [
                {
                    messageKey: 'widgets.message.includesCeDeCo',
                    type: messageType.INCLUDES_CEDECO,
                    hidden: !this.showCeDeCoMessage(widgetData, shareholderType)
                },
                {
                    messageKey: 'widgets.totalOver100',
                    type: messageType.MORE_THAN_100_PERCENTS,
                    hidden: !(osTotal > TOTAL_PERCENT)
                },
                ...additionalRightSideProps
            ],
            bottom: [...additionalBottomProps]
        };
    };

    showCeDeCoMessage = (activeShareholderData, activeShareholderType) => {
        const widgetDataValues = _.get(activeShareholderData, 'values');

        return _.some(widgetDataValues, 'includesCeDeCo') && activeShareholderType === shareholderTypes.REGISTERED;
    };

    render() {
        const { widgetData, widgetSettings } = this.props;
        const activeShareholder = AnalysisGeographic.getActiveShareholder(widgetData, widgetSettings);
        const activeShareholderData = _.get(activeShareholder, 'data');
        const activeShareholderType = _.get(activeShareholder, 'shareholderType', widgetSettings.activeShareholderType);
        const widgetFooterProps = getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(activeShareholderData, activeShareholderType));
        const props = {
            ...this.props,
            widgetSettings: {
                ...widgetSettings,
                activeShareholderType
            },
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-chart',
                widgetTableTypeIcon: 'font-icon-chart deactivated'
            },
            widgetFooterProps,
            widgetNoData: !_.get(activeShareholderData, 'values.length'),
            activeShareholderData
        };

        return (
            <Widget
                {...props}
                className='widget-analysis-geographic'
                widgetTitle={this.widgetTitle}
                components={this.widgetComponents}/>
        );
    }
}

export default AnalysisGeographic;
