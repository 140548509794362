import { createSelector } from 'reselect';
import _ from 'lodash';

import { getAvailableSecurities } from 'State/securities/selectors';

export const getShareholdersToComparing = (state) => state.compareShareholders.shareholders;

export const getCountOfIssuersToComparing = createSelector(
    getShareholdersToComparing,
    getAvailableSecurities,
    (compareShareholders, availableSecurities) => {
        const securityToComparing = _.intersectionWith(
            availableSecurities,
            compareShareholders,
            (security, shareholder) => security.id === shareholder.securityId
        );

        return _.uniqBy(securityToComparing, 'issuerId').length;
    }
);

