import _ from 'lodash';

import { commonWidgetPropTypes } from '../utils';
import { commonProps } from '../../layout/utils';

const omitProps = [
    ...Object.keys(commonWidgetPropTypes),
    'widgets',
    'dispatch',
    'dynamicPageRemoveWidget',
    'dynamicPageAddWidget',
    'dynamicPageChangeWidget',
    'printWidget',
    'widgetLoadData',
    'widgetPartialLoadData',
    'widgetUpdateSettings',
    'widgetAddToDataVizReport',
    'slotId',
    'notifyWarning',
    'slots',
    ...commonProps
];

export const getWidgetBoxProps = props => _.omit(props, omitProps);

export const hasUnselectedWidgets = (availableWidgets) => !!_.filter(availableWidgets, widget => !widget.isSelected).length;
