import { withProps } from '@shakacode/recompose';
import _ from 'lodash';

import ActivityTrendContent from './ActivityTrendContent';
import { withAdditionalPeriod, generateBarNegativeInstitutionValues } from 'Components/BarChart';
import widgetModes from 'Constants/widget-modes';
import { generateTableValues } from 'Components/Tables/TableStickyColumnGroups';
import { formatDateArray } from 'Utils/utils';
import legendBarProps from 'Components/widgets/_commonConfigs/institutionalShareholders/legendProps';

const DATE_FORMAT = 'MM/YY';
const MONTH_IN_PERIOD = 3;
const BAR_DISPLAY_COEFFICIENT = 1000;

export default withProps((props) => {
    const { intl: { formatMessage } } = props;
    const data = _.cloneDeep(props.widgetData) || [];
    const unformattedPeriods = _.get(props, 'widgetData[0].aggregate.values', []).map((value) => value.period);
    const formattedPeriods = formatDateArray(unformattedPeriods, DATE_FORMAT);
    const getAggregationNameForTable = trend => formatMessage({ id: `activity.trend.${_.get(trend, 'aggregate.name')}` });
    const getAggregationNameForChart = aggregate => formatMessage({ id: `activity.trend.chart.${aggregate.name}` });

    if (data.length) {
        _.set(data[0], 'aggregate.name', data[0].aggregate.name);

        _.forEach(data[0].values, (value, index) => {
            _.set(data[0], `values[${index}].name`, formatMessage({ id: `activity.trend.${value.name}` }));
        });
    }

    return {
        barPeriods: withAdditionalPeriod(formattedPeriods, MONTH_IN_PERIOD, DATE_FORMAT),
        barData: generateBarNegativeInstitutionValues(data[0], BAR_DISPLAY_COEFFICIENT, getAggregationNameForChart),
        tableData: generateTableValues(data, DATE_FORMAT, getAggregationNameForTable),
        tablePeriods: formattedPeriods,
        legendProps: legendBarProps,
        chartMode: props.widgetSettings.mode === widgetModes.CHART,
        noData: !data.some(item => !!_.get(item, 'aggregate.values.length'))
    };
})(ActivityTrendContent);
