import PropTypes from 'prop-types';
import React, { memo } from 'react';
import cn from 'classnames';

import { NullableFormatter } from 'Components/formatters';

const CellWithEllipsisView = props => {
    const { value, cellClassName, withTitle, getRef, onNoteTextClick }  = props;

    return (
        <div className={cn('cell-with-ellipsis', cellClassName)} onClick={onNoteTextClick}>
            <NullableFormatter value={value} withTitle={withTitle} getRef={getRef}/>
        </div>
    );
};

CellWithEllipsisView.propTypes = {
    value: PropTypes.any,
    withTitle: PropTypes.bool,
    cellClassName: PropTypes.string,
    getRef: PropTypes.func,
    onNoteTextClick: PropTypes.func
};

export default memo(CellWithEllipsisView);
