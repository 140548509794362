import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { mapProps } from '@shakacode/recompose';
import cn from 'classnames';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import WidgetPrint from '../../Widget/components/WidgetPrint';
import Icon from 'Components/Icon';

@mapProps(props => ({
    ...props,
    onExport: _.get(props, 'widgetControlPanelSettings.onExport'),
    onColumnsManagerClick: _.get(props, 'widgetControlPanelSettings.onColumnsManagerClick')
}))
class ControlBooksResultControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        widgetControlPanelSettings: PropTypes.shape({
            onExport: PropTypes.func,
            onColumnsManagerClick: PropTypes.func
        })
    };

    render() {
        const { onExport, onColumnsManagerClick, widgetData, widgetType, includeWeightedData, includeDetailedData } = this.props;
        const summaryResultIsEmpty = !_.get(widgetData, 'controlBooksResults.length');
        const weightedResultIsEmpty = includeWeightedData && !_.get(widgetData, 'controlBooksWeightedResults.length');
        const detailedResultIsEmpty = includeDetailedData && !_.get(widgetData, 'controlBooksDetailedResults.length');
        const noData = summaryResultIsEmpty || weightedResultIsEmpty || detailedResultIsEmpty;
        const exportIconClass = cn('font-icon font-icon-download', { inactive: noData });

        return (
            <div className='widget-control-panel bordered'>
                <WidgetPrint
                    widgetType={widgetType}/>
                <Icon
                    className={exportIconClass}
                    titleKey={noData ? 'reporting.control.books.results.export.noData' : undefined}
                    onClick={!noData ? onExport : null}/>
                <Icon
                    className='font-icon font-icon-view-three-columns'
                    onClick={onColumnsManagerClick}/>
            </div>
        );
    }
}

export default ControlBooksResultControlPanel;
