import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DatVizExportPageView from '../components/DataVizExportPageView';

const dynamicPageSettings = {
    isDataViz: true,
    isPrintMode: true
};

export default class DataVizExportPage extends Component {
    static propTypes = {
        dynamicPageSetDataViz: PropTypes.func,
        dynamicPageSetPrintMode: PropTypes.func,
        match: PropTypes.object
    };

    render() {
        const reportTemplateId = +this.props.match.params.reportTemplateId || null;

        return (
            <DatVizExportPageView
                dynamicPageSettings={dynamicPageSettings}
                reportTemplateId={reportTemplateId}/>
        );
    }
}
