const columnNames = {
    ISSUER: 'issuer',
    AST_SECURITY_NAME: 'astSecurityName',
    CUSIP: 'cusip',
    AST_SECURITY_ID: 'astSecurityId',
    TICKER: 'ticker'
};

export default [
    {
        displayName: 'security.popup.columns.issuer',
        columnName: columnNames.ISSUER
    },
    {
        displayName: 'security.popup.columns.astSecurityName',
        columnName: columnNames.AST_SECURITY_NAME
    },
    {
        displayName: 'security.popup.columns.cusip',
        columnName: columnNames.CUSIP
    },
    {
        displayName: 'security.popup.columns.astSecurityId',
        columnName: columnNames.AST_SECURITY_ID
    },
    {
        displayName: 'security.popup.columns.ticker',
        columnName: columnNames.TICKER
    }
];
