import SHARE_TYPES from './shareTypes';

export const INITIAL_VALUES = {
    shareType: SHARE_TYPES.ALL,
    shareRange: {
        start: null,
        end: null,
        increment: null
    }
};
