import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose, mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import { AvailableRoute, BaseRoute, pathRoutesConfig } from 'Routes';
import { buildNavLinks } from 'Utils/routing';
import Nav from 'Components/Nav';
import { getCurrentFeaturesSelector } from 'State/features';
import DownloadsLayout from 'Layouts/DownloadsLayout';
import pageRoutes from 'Constants/page-routes';
import { ReportingDownloadsPage } from 'Pages';
import featureTypes from 'Constants/feature-types';

import { UserSecureUploadEditPage } from '../UserSecureUploadEditPage';
import { UserSecureUploadPage } from '../UserSecureUploadPage';
import { UserSecureUploadDetailsPage } from '../UserSecureUploadDetailsPage';

import './DownloadsPage.scss';

const mapStateToProps = (state) => ({
    currentFeatures: getCurrentFeaturesSelector(state)
});

export const DownloadsPageView = ({ navLinks }) => {
    return (
        <div className='downloads-layout'>
            <Nav links={navLinks} horizontal secondary/>
            <Switch>
                <BaseRoute
                    exact
                    path={`${pageRoutes.downloads}`}
                    render={props => (
                        <AvailableRoute
                            pathConfig={pathRoutesConfig.downloadNav.pathConfig}
                            defaultPath={pathRoutesConfig.downloadNav.defaultPath.path}
                            {...props}/>
                    )}/>
                <BaseRoute
                    layout={DownloadsLayout}
                    path={`${pageRoutes.downloadsList}`}
                    requiredFeature={featureTypes.DOWNLOAD}
                    component={ReportingDownloadsPage}/>
                <BaseRoute
                    exact
                    path={`${pageRoutes.downloadsUserSecureUploadList}`}
                    requiredFeature={featureTypes.SECURE_UPLOAD}
                    component={UserSecureUploadPage} />
                <BaseRoute
                    path={`${pageRoutes.downloadsUserSecureUploadEdit}`}
                    requiredFeature={featureTypes.SECURE_UPLOAD}
                    component={UserSecureUploadEditPage} />
                <BaseRoute
                    exact
                    path={`${pageRoutes.downloadsUserSecureUploadDetails}`}
                    requiredFeature={featureTypes.SECURE_UPLOAD}
                    component={UserSecureUploadDetailsPage} />
            </Switch>
        </div>
    );
};

DownloadsPageView.propTypes = {
    navLinks: PropTypes.array
};

export const DownloadsPage = compose(
    injectIntl,
    connect(mapStateToProps, null),
    mapProps(props => ({
        navLinks: buildNavLinks({
            pathConfig: pathRoutesConfig.downloadNav.pathConfig,
            features: props.currentFeatures,
            formatMessage: props.intl.formatMessage
        })
    })),
)(DownloadsPageView);
