import React from 'react';

import './Logo.scss';

const logoUrl = !DEMO_MODE ? `${PUBLIC_URL}/assets/images/EQIssuerCentral.svg` : `${PUBLIC_URL}/assets/images/EquinitiLogo.png`;

const Logo = () => {
    return (
        <img className='ast-logo' src={logoUrl} alt='logo'/>
    );
};

export default Logo;
