import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import AnalysisGeographic from 'Components/widgets/_commonComponents/AnalysisGeographic';
import { TA } from 'Constants/product-source-types';
import { NullableFormatter } from 'Components/formatters';

class AnalysisGeographicMetro extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        const { userProductSources } = this.props;

        this.hasTAProduct = _.isArray(userProductSources) && _.includes(userProductSources, TA);
        this.formatMessage = props.intl.formatMessage;
        this.primaryColumn = {
            dataKey: 'metro',
            title: this.formatMessage({ id: 'analysis.geographic.metro.state' }),
            columnClassName: 'ellipsis',
            bodyCellComponent: v => <NullableFormatter {...v} withTitle/>
        };
    }

    getWidgetFooterProps = () => {
        const { widgetData } = this.props;

        return {
            bottom: [
                {
                    messageKey: 'analysis.geographic.metro.noteInstitutionsInsiders',
                    hidden: !widgetData || !this.hasTAProduct
                }
            ]
        };
    };

    render() {
        const { widgetError, noPermissions } = this.props;
        const newProps = {
            ...this.props,
            widgetError: noPermissions ? null : widgetError
        };

        return (
            <AnalysisGeographic
                {...newProps}
                footerProps={this.getWidgetFooterProps()}
                primaryColumn={this.primaryColumn}/>
        );
    }
}

export default AnalysisGeographicMetro;
