import React from 'react';
import { FormattedMessage } from 'react-intl';

import amountOfRecords from 'Constants/amount-of-records';

export default values => {
    const errors = {};

    if (values && values.type === amountOfRecords.NO_OF_RECORDS && values.records === null) {
        errors.records = <FormattedMessage id='shareholder.list.noOfRecords.error'/>;
    }

    return errors;
};
