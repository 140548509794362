import _ from 'lodash';

export const getManageableColumns = (columnsDefinitions = []) => _.filter(columnsDefinitions, column => column.manageable);

export const getManagerTransformColumns = (columnsDefinitions = [], selectedColumns = [], formatMessage = () => {}) => {
    return columnsDefinitions
        .filter(column => column.manageable)
        .map(column => ({
            name: column.columnName,
            label: formatMessage({ id: column.displayName }),
            isSelected: _.includes(selectedColumns, column.columnName)
        }));
};

export const getVisibleColumns = (columns = [], columnDefinitions = []) => {
    const selectedColumns = columns
        .filter(column => column.isSelected)
        .map(column => column.name);
    const columnDef = columnDefinitions.columns || columnDefinitions;
    const notManageableColumns = columnDef
        .filter(column => _.isBoolean(column.manageable) && !column.manageable)
        .map(column => column.columnName);

    return _.sortBy(selectedColumns.concat(notManageableColumns),
        columnName => {
            const columnNameDif = _.find(columnDef, column => column.columnName === columnName);

            return _.get(columnNameDif, 'order');
        });
};

export const getColumnsToDisplayCount = (views = {}) => {
    const { currentViewId, customViews, defaultViews } = views;

    if (!currentViewId || !customViews || !defaultViews) return;

    return customViews[currentViewId] ?
        customViews[currentViewId].values.length :
        defaultViews[currentViewId].values.length;
};
