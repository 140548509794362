import _ from 'lodash';

import { createReducer } from 'Utils/reducer';
import {
    DYNAMIC_PAGE_INITIALIZE,
    DYNAMIC_PAGE_DESTROY,
    DYNAMIC_PAGE_ADD_WIDGET,
    DYNAMIC_PAGE_REMOVE_WIDGET,
    DYNAMIC_PAGE_SHRINK_WIDGETS,
    DYNAMIC_PAGE_DELETE_EMPTY_SLOTS,
    DYNAMIC_PAGE_BLOCK_ACTION,
    FETCHING_DYNAMIC_PAGE_REQUEST,
    FETCHING_DYNAMIC_PAGE_SUCCESS,
    FETCHING_DYNAMIC_PAGE_FAILURE,
    DYNAMIC_PAGE_ADD_SLOT,
    DYNAMIC_PAGE_REMOVE_SLOT
} from './actions';
import { shrinkWidgets, deleteEmptySlots } from './utils';

const initialState = {
    type: null,
    slots: null,
    params: {},
    settings: {},
    info: {},
    isFetching: false,
    error: null,
    blockAction: false // blocking layout when we add or remove widget
};

const dynamicPageActionHandlers = {
    [DYNAMIC_PAGE_INITIALIZE]: (state, { payload }) => ({
        ...state,
        error: null,
        isFetching: false,
        ...payload
    }),

    [DYNAMIC_PAGE_DESTROY]: () => {
        return _.cloneDeep(initialState);
    },

    // TODO check slot is sinle or multi
    [DYNAMIC_PAGE_ADD_WIDGET]: (state, { payload: { slotId, widgetName } }) => {
        const slot = _.get(state, `slots[${slotId}]`);

        if (!slot) return state;

        return {
            ...state,
            slots: {
                ...state.slots,
                [slotId]: {
                    ...state.slots[slotId],
                    widgets: [...state.slots[slotId].widgets, widgetName]
                }
            }
        };
    },

    [DYNAMIC_PAGE_REMOVE_WIDGET]: (state, { payload: { slotId, widgetName } }) => {
        const slot = _.get(state, `slots[${slotId}]`);

        if (!slot) return state;

        return {
            ...state,
            slots: {
                ...state.slots,
                [slotId]: {
                    ...state.slots[slotId],
                    widgets: _.remove(state.slots[slotId].widgets, (widget) => widget !== widgetName)
                }
            }
        };
    },

    [DYNAMIC_PAGE_ADD_SLOT]: (state, { payload: { slot, slotId } }) => {
        const slots = _.get(state, 'slots', {});

        return {
            ...state,
            slots: {
                ...slots,
                [slotId]: slot
            }
        };
    },

    [DYNAMIC_PAGE_REMOVE_SLOT]: (state, { payload: { slotId } }) => {
        const slot = _.get(state, `slots[${slotId}]`);

        if (!slot) return state;

        return {
            ...state,
            slots: _.omit(state.slots, [slotId])
        };
    },

    [DYNAMIC_PAGE_SHRINK_WIDGETS]: shrinkWidgets,

    [DYNAMIC_PAGE_DELETE_EMPTY_SLOTS]: deleteEmptySlots,

    [FETCHING_DYNAMIC_PAGE_REQUEST]: (state, { payload }) => {
        return {
            ...state,
            type: payload,
            error: null,
            isFetching: true
        };
    },

    [FETCHING_DYNAMIC_PAGE_SUCCESS]: (state) => {
        return {
            ...state,
            error: null,
            isFetching: false
        };
    },

    [FETCHING_DYNAMIC_PAGE_FAILURE]: (state, { payload }) => {
        return {
            ...state,
            error: payload,
            isFetching: false
        };
    },

    [DYNAMIC_PAGE_BLOCK_ACTION]: (state, { payload: blockAction }) => {
        return {
            ...state,
            blockAction
        };
    }
};

export default createReducer(initialState, dynamicPageActionHandlers);
