import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { compose, withProps } from '@shakacode/recompose';

import Icon from 'Components/Icon';
import * as watchlistActions from 'State/watchlist/actions';
import * as notifierActions from 'State/notifier/actions';
import * as widgetActions from 'State/watchlistWidget';

const DeleteCell = ({
    row,
    columnProps,
    removeShareholderFromWatchlist,
    removeWatchlistWidgetShareholder,
    notifySuccess,
    notifyError
}) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const handleClick = async () => {
        if (isDeleting) {
            return;
        }

        setIsDeleting(true);

        try {
            const { watchlistId, widgetDataSourceId } = columnProps.rest;
            const { shareholderId } = row;

            await removeShareholderFromWatchlist(shareholderId, watchlistId);
            removeWatchlistWidgetShareholder({ widgetDataSourceId, shareholderId });

            notifySuccess(
                null,
                <FormattedHTMLMessage id='watchlist.notifier.removeShareholder.success'/>
            );
        } catch (e) {
            notifyError(
                <FormattedMessage id='watchlist.notifier.removeShareholder.error.header' />,
                <FormattedHTMLMessage id='watchlist.notifier.removeShareholder.error'/>
            );
        }

        setIsDeleting(false);
    };

    return (
        <Icon
            className='font-icon font-icon-delete'
            titleKey='common.delete'
            inactive={isDeleting}
            onClick={handleClick} />
    );
};

DeleteCell.propTypes = {
    row: PropTypes.shape({
        shareholderId: PropTypes.string
    }),
    columnProps: PropTypes.shape({
        watchlistId: PropTypes.number,
        widgetDataSourceId: PropTypes.string
    }),
    removeShareholderFromWatchlist: PropTypes.func,
    removeWatchlistWidgetShareholder: PropTypes.func,
    notifySuccess: PropTypes.func,
    notifyError: PropTypes.func
};

const mapDispatchToProps = {
    removeShareholderFromWatchlist: watchlistActions.removeShareholderFromWatchlist,
    removeWatchlistWidgetShareholder: widgetActions.removeWatchlistWidgetShareholder,
    notifySuccess: notifierActions.notifySuccess,
    notifyError: notifierActions.notifyError
};


export default compose(
    withProps(),
    connect(undefined, mapDispatchToProps)
)(DeleteCell);
