const schemas = {
    // pie-chart schema
    '0': [
        '#941F40',
        '#f37721',
        '#d81a32',
        '#fcb633',
        '#599f08',
        '#008ad0',
        '#dcb6c1',
        '#f7a46b',
        '#b8697f',
        '#fdcf77',
        '#988a7d'
    ],
    // bar-chart schema
    '1': [
        '#941F40',
        '#f37721',
        '#d81a32',
        '#fcb633',
        '#599f08',
        '#008ad0',
        '#dcb6c1',
        '#f7a46b',
        '#b8697f',
        '#fdcf77',
        '#988a7d'
    ],
    // position-chart schema
    '2': {
        BACKGROUND_COLOR: '#f8f6f4',
        LINE_COLOR_PRIMARY: '#599f08',
        PLOT_BAND_COLOR: '#ffffff',
        SCROLLBAR_COLOR: '#b2a89d',
        SCROLLBAR_BACKDROP_COLOR: '#e9e3dc',
        LINE_COLOR_SECONDARY: '#008AD0'
    },
    // performance-chart schema
    '3': [
        '#008ad0',
        '#d81a32',
        '#f37721',
        '#fcb633',
        '#599f08',
        '#dcb6c1',
        '#f7a46b',
        '#b8697f',
        '#fdcf77',
        '#988a7d'
    ],
    // volume-chart schema
    '4': [
        '#988a7d',
        '#f37721',
        '#d81a32',
        '#fcb633',
        '#599f08',
        '#dcb6c1',
        '#f7a46b',
        '#b8697f',
        '#fdcf77',
        '#008ad0'
    ]
};

export default schemas;
