import modalTypes from 'Constants/modal-types';
import ConfirmModal from './ConfirmModal';
import AddNoteModal from './AddNoteModal';
import InfoModal from './InfoModal';
import ColumnManager from '../widgets/ShareholdersTable/components/ColumnsManager';
import SaveCustomView from '../widgets/ShareholdersTable/components/SaveCustomView';
import AnalysisTurnoverLegendModal from '../widgets/AnalysisTurnover/components/AnalysisTurnoverLegendModal';
import AdvancedSearchModal from './AdvancedSearchModal';
import CommonColumnsManager from '../CommonColumnsManager';
import ExportReportModal from './ExportReportModal';
import ExportShareholderTableModal from './ExportShareholderTableModal';
import ExportHistoricalAnalysisModal from './ExportHistoricalAnalysisModal';
import ExportPendingModal from './ExportPendingModal';
import ExportNoResultsModal from './ExportNoResultsModal';
import SaveReportTemplateModal from './SaveReportTemplateModal';
import SaveChangesModal from './SaveChangesModal';
import SearchTopShareholdersModal from './SearchTopShareholdersModal';
import WithWidgetModal from './WithWidgetModal';
import SessionTimeoutModal from './SessionTimeoutModal';
import SelectSecurityModal from './SelectSecurityModal';
import ExportRegisteredOverviewModal from './ExportRegisteredOverviewModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import SelectAccountModal from './SelectAccountModal';
import ExportClassicShareholderReportModal from './ExportClassicShareholderReportModal';
import AlertsModal from './AlertsModal';
import ExportClassicAsOfDateReportModal from './ExportClassicAsOfDateReportModal';
import ReportRequestedModal from './ReportRequestedModal';
import CreateNoteModal from './CreateNoteModal';
import NoteDetailsModal from './NoteDetailsModal';
import SaveScheduledReportTemplateModal from './SaveScheduledReportTemplateModal';
import { ShareholderContactsModal } from './ShareholderContactsModal';
import { ExportShareholderContactsModal } from './ExportShareholderContactsModal';
import ShareReportTemplateModal from './ShareReportTemplateModal';
import RenameWatchlistModal from './RenameWatchlistModal';
import WatchlistExportModal from './WatchlistExportModal';
import SelectEmailRecipientsModal from './SelectEmailRecipientsModal';

export ModalRoot from './ModalRoot';

export const modals = {
    [modalTypes.addNote]: AddNoteModal,
    [modalTypes.confirm]: ConfirmModal,
    [modalTypes.info]: InfoModal,
    [modalTypes.columnManager]: ColumnManager,
    [modalTypes.saveCustomView]: SaveCustomView,
    [modalTypes.advancedSearch]: AdvancedSearchModal,
    [modalTypes.commonColumnsManager]: CommonColumnsManager,
    [modalTypes.saveReportTemplate]: SaveReportTemplateModal,
    [modalTypes.saveScheduledReportTemplate]: SaveScheduledReportTemplateModal,
    [modalTypes.saveChangesModal]: SaveChangesModal,
    [modalTypes.searchTopShareholdersModal]: SearchTopShareholdersModal,
    [modalTypes.withWidgetModal]: WithWidgetModal,
    [modalTypes.sessionTimeoutModal]: SessionTimeoutModal,
    [modalTypes.analysisTurnoverLegendModal]: AnalysisTurnoverLegendModal,
    [modalTypes.selectSecurityModal]: SelectSecurityModal,
    [modalTypes.termsAndConditionsModal]: TermsAndConditionsModal,
    [modalTypes.selectAccountModal]: SelectAccountModal,
    [modalTypes.exportReport]: ExportReportModal,
    [modalTypes.exportShareholderTable]: ExportShareholderTableModal,
    [modalTypes.exportHistoricalAnalysis]: ExportHistoricalAnalysisModal,
    [modalTypes.exportReportPending]: ExportPendingModal,
    [modalTypes.exportReportNoResults]: ExportNoResultsModal,
    [modalTypes.exportRegisteredOverview]: ExportRegisteredOverviewModal,
    [modalTypes.exportClassicShareholderReportModal]: ExportClassicShareholderReportModal,
    [modalTypes.alertsModal]: AlertsModal,
    [modalTypes.exportClassicAsOfDateReportModal]: ExportClassicAsOfDateReportModal,
    [modalTypes.reportRequestedModal]: ReportRequestedModal,
    [modalTypes.createNoteModal]: CreateNoteModal,
    [modalTypes.noteDetailsModal]: NoteDetailsModal,
    [modalTypes.shareholderContactsModal]: ShareholderContactsModal,
    [modalTypes.exportShareholderContactsModal]: ExportShareholderContactsModal,
    [modalTypes.shareReportTemplateModal]: ShareReportTemplateModal,
    [modalTypes.renameWatchlistModal]: RenameWatchlistModal,
    [modalTypes.exportWatchlistModal]: WatchlistExportModal,
    [modalTypes.selectEmailRecipientsModal]: SelectEmailRecipientsModal
};
