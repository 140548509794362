import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import AlertsSectionView from './AlertsSectionView';

import './AlertsSection.scss';

const AlertsSection = props => {
    const handleIconClick = useCallback(() => {
        props.userNewAlerts > 0 && props.showAlertsModal();
    }, [props.userNewAlerts]);

    return (<AlertsSectionView
        onIconClick={handleIconClick}
        userNewAlerts={props.userNewAlerts}/>);
};

AlertsSection.propTypes = {
    userNewAlerts: PropTypes.number,
    showAlertsModal: PropTypes.func
};

export default memo(AlertsSection);
