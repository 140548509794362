import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

export default values => {
    const errors = {};

    if (values && values.checked) {
        if (_.isNil(values.min)) {
            errors.min = <FormattedMessage id='shareholder.list.sharesOwned.min.error.empty'/>;
        } else if (_.isNaN(values.min)) {
            errors.min = <FormattedMessage id='shareholder.list.sharesOwned.min.error.nan'/>;
        } else if (values.min > values.max && !_.isNil(values.max)) {
            errors.min = <FormattedMessage id='shareholder.list.sharesOwned.min.error.moreMax'/>;
        } else if (values.min < 0) {
            errors.min = <FormattedMessage id='shareholder.list.sharesOwned.min.error.negative'/>;
        }

        if (_.isNil(values.max)) {
            errors.max = <FormattedMessage id='shareholder.list.sharesOwned.max.error.empty'/>;
        } else if (_.isNaN(values.max)) {
            errors.max = <FormattedMessage id='shareholder.list.sharesOwned.max.error.nan'/>;
        } else if (values.max < values.min && !_.isNil(values.min)) {
            errors.max = <FormattedMessage id='shareholder.list.sharesOwned.max.error.lessMin'/>;
        } else if (values.max < 0) {
            errors.max = <FormattedMessage id='shareholder.list.sharesOwned.max.error.negative'/>;
        }
    }

    return errors;
};
