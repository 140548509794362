import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';

import { RadioButtonsListField } from 'Components/RadioButtonsList';
import classicShareholdersReportTypes from '../constants/classic-shareholders-report-types';

const ExportClassicShareholdersReportView = props => {
    const { intl: { formatMessage }, onExport, onCancel, handleSubmit } = props;
    const items = [{
        label: formatMessage({ id: 'modals.exportClassicShareholdersReport.type.fullReport' }),
        value: classicShareholdersReportTypes.FULL_REPORT,
        position: 'block'
    }, {
        label: formatMessage({ id: 'modals.exportClassicShareholdersReport.type.totalsOnly' }),
        value: classicShareholdersReportTypes.TOTALS_ONLY,
        position: 'block'
    }];

    return (
        <form
            onSubmit={handleSubmit(onExport)}
            className='export-classic-shareholder-report-modal'>
            <div className='format-container'>
                <RadioButtonsListField
                    name='reportType'
                    list={items}/>
            </div>
            <div className='modal-actions'>
                <button
                    className='btn btn-large'
                    type='submit'>
                    {formatMessage({ id: 'modals.exportReport.exportBtn' })}
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className='btn btn-large'>
                    {formatMessage({ id: 'common.cancel' })}
                </button>
            </div>
        </form>
    );
};

ExportClassicShareholdersReportView.propTypes = {
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
    onExport: PropTypes.func
};

export default ExportClassicShareholdersReportView;
