import React, { Component } from 'react';
import * as _ from 'lodash';
import { withProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';

import { commonWidgetPropTypes, commonWidgetActions, getManageableColumns } from 'Components/widgets/utils';
import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import columnNames from 'Constants/registered-table-column-names';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import { registeredOverview } from '../_commonComponents/RegisteredShareholderTable/hoc';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import { getFullName } from 'Utils/utils';
import featureTypes from 'Constants/feature-types';
import { withDropzone } from 'Hoc';
import polling from 'Components/polling';
import pollingFunctionTypes from 'Constants/polling-function-types';
import { getUploadedDocumentsPolling } from 'State/uploadDocument/actions';

import './RegisteredShareholderClientUploads.scss';

let boundActionCreators;
const DEFAULT_POLLING_INTERVAL = 5000;
const mapStateToProps = state => ({
    currentFeatures: getCurrentFeaturesSelector(state)
});
const mapDispatchToProps = dispatch => {
    return boundActionCreators = bindActionCreators({
        getUploadedDocumentsPolling
    }, dispatch);
};

@withProps(props => ({
    data: {
        shareholderId: _.get(props, 'widgetPageParams.shareholderId'),
        tableData: _.get(props, 'widgetData', []).map(
            document => ({
                ...document,
                author: document.authorFirstName
                    ? getFullName(document.authorFirstName, document.authorLastName)
                    : document.authorLastName,
                download: {
                    documentId: document.documentId,
                    uploadStatus: document.uploadStatus
                },
                delete: {
                    documentId: document.documentId,
                    uploadStatus: document.uploadStatus,
                    canDelete: document.canDelete,
                    description: document.description
                }
            }))
    }
}))
@registeredOverview
@connect(mapStateToProps, mapDispatchToProps)
@withDropzone
@polling(props => boundActionCreators.getUploadedDocumentsPolling(props), pollingFunctionTypes.UPLOADED_DOCUMENTS, DEFAULT_POLLING_INTERVAL)
class RegisteredShareholderClientUploads extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        currentFeatures: PropTypes.object
    };

    getTdProps = (finalState, rowInfo, column) => {
        if (column.id === columnNames.DOWNLOAD) {
            return {
                additionalData: {
                    shareholderId: _.get(this.props, 'data.shareholderId')
                }
            };
        } else if (column.id === columnNames.DELETE) {
            const shareholderId = _.get(this.props, 'data.shareholderId');

            return {
                additionalData: {
                    shareholderId,
                    onDeleteDocument: () => this.props.onUpdateWidgetData({ shareholderId })
                }
            };
        }
    };

    render() {
        const { data, currentFeatures } = this.props;
        const infoMessage = () => (
            <FormattedMessage id='registeredOverview.infoMessage.uploadedDocuments'>
                {message => <span className='description message-note'>{message}</span>}
            </FormattedMessage>
        );

        return (
            <RegisteredShareholderTable
                {...this.props}
                className='registered-shareholder-client-uploads'
                data={data.tableData}
                getTdProps={this.getTdProps}
                columnsDefinitions={columnsDefinitions}
                secondarySorting={secondarySorting}
                exportAvailable={false}
                manageableColumns={getManageableColumns(columnsDefinitions)}
                disableValidation
                WidgetInfoPanel={currentFeatures[featureTypes.UPLOAD_DOCUMENT] ? infoMessage : null}/>
        );
    }
}

export default RegisteredShareholderClientUploads;
