import moment from 'moment';
import _ from 'lodash';

import { saveLog } from '../api';
import { LOG_ITEMS_COUNT, LOG_ITEMS_KEY, ISP_USER_ID, LOG_FLUSH_INTERVAL } from 'Constants/logs-constants';
import dateFormats from 'Constants/date-formats';
import { removeUnsafeContentFromElement } from 'Utils/utils';

let isFlushing = false;

function addLogItem(logItem) {
    let logs = getLogItems();

    logs.unshift(logItem);
    logs = logs.slice(0, LOG_ITEMS_COUNT);

    saveLogItemsLocally(logs);

    // Flush to server
    flushNewLogsToServer();
}

function logsToPayload(logItems) {
    return logItems.map(item => ({
        logData: item,
        dateTime: item.date,
        userAgent: item.userAgent,
        userLogin: item.userLogin,
        userId: _.get(item, 'userInfo.userId', null),
        securityId: _.get(item, 'userInfo.currentSecurity.id', null)
    }));
}

function getLogItems() {
    const logItems = localStorage.getItem(LOG_ITEMS_KEY);

    return logItems ? JSON.parse(localStorage.getItem(LOG_ITEMS_KEY)) : [];
}

function saveLogItemsLocally(logItems) {
    try {
        localStorage.setItem(LOG_ITEMS_KEY, JSON.stringify(logItems));
    } catch (e) {
        const lastLog = logItems[0];
        const localStorageError = {
            logData: {
                logs: logItems,
                message: `Save logs to Local Storage failed! Error: ${e.message}`
            },
            dateTime: moment().format(dateFormats.LOG_DATE_FORMAT),
            userLogin: lastLog.userLogin,
            userId: _.get(lastLog, 'userInfo.userId', null),
            securityId: _.get(lastLog, 'userInfo.currentSecurity.id', null)
        };
        const payload = [
            ...logsToPayload([lastLog]),
            localStorageError
        ];

        console.log(`Save logs to Local Storage failed! Error: ${e.message}`);

        saveLog({
            logs: payload
        });

        localStorage.setItem(LOG_ITEMS_KEY, []);
    }
}

const handleDownloadLogFile = () => {
    const logs = getLogItems();
    const userLogin = localStorage.getItem(ISP_USER_ID) || 'Unknown';
    const file = new Blob([JSON.stringify(logs, null, '\t')], { type: 'application/json' });
    const fileName = `[${moment().format(dateFormats.LOG_FILE_DATE_FORMAT)}]_[${userLogin}]_errors-log.json`;

    // For IE blob download
    if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(file, fileName);
        return;
    }

    const element = document.createElement('a');

    element.href = URL.createObjectURL(file);
    element.download = fileName;
    element.style.display = 'none';

    // Firefox fix: link should be visible to click.
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
};

function flushNewLogsToServer() {
    if (isFlushing) return Promise.resolve();

    let logs = getLogItems();
    const logItemsToFlush = _.filter(logs, i => !i.isFlushedToServer);
    const maxTimestamp = _.max(_.map(logs, i => i.timestamp));

    if (logItemsToFlush.length) {
        const logsPayload = removeUnsafeContentFromElement({
            logs: logsToPayload(logItemsToFlush)
        });

        isFlushing = true;

        return saveLog(logsPayload)
            .then(() => {
                logs = getLogItems();
                // some new logs could be added during the saveLog execution
                // update only saved log items
                const updatedLogs = _.map(logs, item => {
                    if (item.timestamp <= maxTimestamp) {
                        return {
                            ...item,
                            isFlushedToServer: true
                        };
                    }
                    return item;
                });

                saveLogItemsLocally(updatedLogs);

                isFlushing = false;
            },
            () => {
                isFlushing = false;
            });
    }

    return Promise.resolve();
}

function pollingLogs() {
    setTimeout(() => {
        flushNewLogsToServer();
        if (window.requestAnimationFrame) {
            window.requestAnimationFrame(pollingLogs);
        } else {
            pollingLogs();
        }
    }, LOG_FLUSH_INTERVAL);
}

function initPollingLogs() {
    flushNewLogsToServer();
    pollingLogs();
}

export default {
    getLogItems,
    addLogItem,
    handleDownloadLogFile,
    initPollingLogs
};
