import React from 'react';
import { FormattedMessage } from 'react-intl';

export const getPeerDescription = (peer) => {
    return peer && peer.name
        ? `${peer.name}${peer.ticker ? ` (${peer.ticker})` : ''}`
        : (<span className='unavailable-peer'>
            <FormattedMessage id='user.profile.peers.unavailablePeer'/>
        </span>);
};
