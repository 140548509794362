import slotTypes from 'Constants/slot-types';

export default {
    [1]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [2]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    }
};
