import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';
import * as _ from 'lodash';
import { withPropsOnChange, withState } from '@shakacode/recompose';
import { AutoSizer, Column, Table } from 'react-virtualized';

import headerRowRenderer from './components/headerRowRenderer';
import rowRenderer from './components/rowRenderer';
import stickyFooter from './components/stickyFooter';
import EmptyView from '../components/EmptyView';

import './TableEasy.scss';

const HEADER_ROW_HEIGHT = 36;
const BODY_ROW_HEIGHT = 24;
const NO_DATA_MESSAGE_HEIGHT = 48;
const DEFAULT_ROWS_COUNT = 10;

@withState(
    'footerPaddingRight',
    'setFooterPadding',
    0
)
@withPropsOnChange(['data', 'columns', 'aggregate'],
    ({ aggregate, columns }) => {
        if (aggregate && columns.length) {
            const totalsArr = columns.map(col => {
                const dataKey = col.dataKey;
                const totalValue = _.get(aggregate, `${dataKey}Total`);

                if (!_.isUndefined(totalValue)) {
                    return [
                        col.dataKey, totalValue
                    ];
                }
            }).filter(Boolean);

            const total = _.fromPairs(totalsArr);

            return { total };
        }
    }
)
class TableEasy extends Component {
    static propTypes = {
        className: PropTypes.string,
        hideBarChart: PropTypes.bool,
        hideHeader: PropTypes.bool,
        transparentHeader: PropTypes.bool,
        headerRowHeight: PropTypes.number,
        bodyRowHeight: PropTypes.number,
        maxVisibleRows: PropTypes.number,
        noDataMessageHeight: PropTypes.number,
        headerClassName: PropTypes.string,
        footerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        widgetFooterProps: PropTypes.object,
        columns: PropTypes.arrayOf(PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            title: PropTypes.string,
            bodyCellComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
            headerCellComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
            flexGrow: PropTypes.number,
            maxWidth: PropTypes.number,
            minWidth: PropTypes.number,
            headerClassName: PropTypes.string,
            className: PropTypes.string,
            columnClassName: PropTypes.string
        })).isRequired,
        data: PropTypes.arrayOf(PropTypes.shape({
            /**
             * Percent value from 0 to 100
             */
            barValue: (props, propName, componentName) => {
                const value = props[propName];

                if (value !== undefined &&
                    !(typeof value === 'number') || value < 0 || value > 100) {
                    return new Error(
                        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Value should be from 0 to 100.`
                    );
                }
            }
            /* dataKey 1 */
            /* dataKey 2 */
        })).isRequired,
        total: PropTypes.object,
        markTotalOverflow: PropTypes.bool,
        onSort: PropTypes.func,
        sortBy: PropTypes.string,
        sortDirection: PropTypes.string,
        noDataMessage: PropTypes.string,
        footerPaddingRight: PropTypes.number,
        setFooterPadding: PropTypes.func,
        showAllRows: PropTypes.bool,
        renderAllRows: PropTypes.bool,
        totalMessageId: PropTypes.string,
        ellipsis: PropTypes.bool,
        markDifferenceFromReport: PropTypes.bool
    };

    static defaultProps = {
        hideBarChart: false,
        hideHeader: false,
        transparentHeader: false,
        footerComponent: stickyFooter,
        markTotalOverflow: true,
        headerRowHeight: HEADER_ROW_HEIGHT,
        bodyRowHeight: BODY_ROW_HEIGHT,
        maxVisibleRows: DEFAULT_ROWS_COUNT,
        noDataMessageHeight: NO_DATA_MESSAGE_HEIGHT
    };

    componentDidUpdate() {
        this.tableRef.scrollToRow(0);
    }

    // Calculate scrollbarWidth manually because Table calculate and update it before the rows rendered.
    // https://stackoverflow.com/questions/43372207/sticky-footer-in-table-of-react-virtualized
    handleRowsRendered = () => {
        const { data, bodyRowHeight, footerPaddingRight, setFooterPadding } = this.props;
        const bodyHeight = data.length * bodyRowHeight;
        const scrollbarWidth = this.tableRef.Grid.props.height < bodyHeight ? this.tableRef.Grid._scrollbarSize : 0;

        if (footerPaddingRight !== scrollbarWidth) {
            setFooterPadding(scrollbarWidth);
        }
    };

    static getColumnWidths = (rowWidth, parts) => {
        const sum = parts.reduce((prev, current) => prev + current, 0);
        const widthOfOnePart = rowWidth / sum;

        return parts.map(part => part * widthOfOnePart);
    };

    /**
     * Specific function
     * returns 4.5 for 0, 1.5 for 1st, 4 for 2nd
     */
    static getDefaultForColumnNumber = (index) => {
        return 3 / 2 * (index ** 2) - 7 / 2 * index + 4;
    };

    getWidths = (rowWidth) => {
        const parts = this.props.columns.map((c, i) => c.flexGrow || TableEasy.getDefaultForColumnNumber(i));

        return TableEasy.getColumnWidths(rowWidth, parts);
    };

    getColumns = (columnsData, rowWidth) => {
        return columnsData.map((col, colIndex) => {
            const widths = this.getWidths(rowWidth);
            const CustomComponent = col.bodyCellComponent;
            const CustomHeaderComponent = col.headerCellComponent;

            let cellRenderer;
            let headerRenderer;

            if (CustomComponent) {
                cellRenderer = ({ cellData, rowData }) => (<CustomComponent value={cellData} rowData={rowData}/>);
            }

            if (CustomHeaderComponent) {
                headerRenderer = ({ dataKey, label, sortDirection, sortBy }) =>
                    (<CustomHeaderComponent
                        dataKey={dataKey}
                        title={label}
                        sortDirection={sortDirection}
                        sortBy={sortBy}/>);
            }

            return (
                <Column
                    key={col.dataKey}
                    label={col.title}
                    dataKey={col.dataKey}
                    disableSort={col.disableSort}
                    cellRenderer={cellRenderer}
                    headerRenderer={headerRenderer}
                    className={cn(col.className, col.columnClassName)}
                    headerClassName={cn(col.headerClassName, col.columnClassName)}
                    flexGrow={col.flexGrow || TableEasy.getDefaultForColumnNumber(colIndex)}
                    flexShrink={col.flexShrink}
                    maxWidth={col.maxWidth}
                    minWidth={col.minWidth}
                    width={widths[colIndex]}/>
            );
        });
    };

    wrapperEmptyViewComponent = () => {
        return <EmptyView {...this.props} />;
    };

    _setRef = (ref) => {
        this.tableRef = ref;
    };

    overscanIndicesGetter = ({ cellCount }) => ({
        overscanStartIndex: 0,
        overscanStopIndex: cellCount - 1
    });

    render() {
        const { columns, data, total, markTotalOverflow, totalMessageId, ellipsis,
            className, showAllRows, renderAllRows, widgetFooterProps, markDifferenceFromReport } = this.props;
        const { hideBarChart, hideHeader, transparentHeader, bodyRowHeight, maxVisibleRows, headerRowHeight,
            noDataMessageHeight, headerClassName, onSort, sortBy, sortDirection, footerPaddingRight } = this.props;

        const Footer = this.props.footerComponent;
        const dataLength = data.length;
        const rowsCountVisible = showAllRows && dataLength > maxVisibleRows ? dataLength : Math.min(maxVisibleRows, dataLength);
        const tableHeight = headerRowHeight + (dataLength
            ? bodyRowHeight * rowsCountVisible
            : noDataMessageHeight);

        return (
            <div className={cn('table-easy', {
                'table-easy-with-footer': total
            })}>
                <AutoSizer disableHeight>
                    {({ width }) => {
                        const tableColumns = this.getColumns(columns, width);

                        return (
                            <div style={{ height: tableHeight }}>
                                <Table
                                    width={width}
                                    height={tableHeight}
                                    className={cn({
                                        'transparent-header': transparentHeader
                                    }, className)}
                                    gridClassName={cn({
                                        'hide-bar-chart': hideBarChart
                                    })}
                                    ref={this._setRef}
                                    headerRowRenderer={hideHeader ? () => null : headerRowRenderer}
                                    headerClassName={headerClassName}
                                    headerHeight={headerRowHeight}
                                    rowHeight={bodyRowHeight}
                                    rowRenderer={rowRenderer}
                                    overscanIndicesGetter={renderAllRows ? this.overscanIndicesGetter : undefined}
                                    rowCount={dataLength}
                                    sort={onSort}
                                    sortBy={sortBy}
                                    sortDirection={sortDirection}
                                    onRowsRendered={this.handleRowsRendered}
                                    noRowsRenderer={this.wrapperEmptyViewComponent}
                                    rowGetter={({ index }) => data[index]}>
                                    {tableColumns}
                                </Table>
                                {total &&
                                dataLength > 0 &&
                                <Footer
                                    widgetFooterProps={widgetFooterProps}
                                    markDifferenceFromReport={markDifferenceFromReport}
                                    rowData={total}
                                    width={width}
                                    markTotalOverflow={markTotalOverflow}
                                    paddingRight={footerPaddingRight}
                                    totalMessageId={totalMessageId}
                                    ellipsis={ellipsis}>
                                    {tableColumns}
                                </Footer>}
                            </div>
                        );
                    }}
                </AutoSizer>
            </div>
        );
    }
}

export default TableEasy;
