export default {
    lang: {
        numericSymbols: null
    },
    global: {
        // until the time zone problem is solved with customer
        useUTC: true
    },
    title: {
        text: null
    },
    credits: {
        enabled: false
    },
    xAxis: {
        labels: {
            style: { color: '#000000' }
        }
    },
    yAxis: {
        labels: {
            style: { color: '#000000' }
        }
    },
    legend: {
        itemStyle: { 'fontWeight': 'normal' },
        squareSymbol: true,
        symbolRadius: 0
    }
};
