import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import reportExportFormats from 'Constants/export-report-formats';

const ReportingActionsSection = ({ handleGetExcel, disabled, handleGetPDF, isEditMode, dirty, isReportFetching, formatFetchingReport }) => (
    <div className='reporting-actions'>
        {!!handleGetExcel &&
            <button
                type='button'
                onClick={handleGetExcel}
                className='btn btn-large get-excel-btn'
                disabled={disabled || isReportFetching && formatFetchingReport === reportExportFormats.EXCEL}>
                <FormattedMessage id='reporting.getExcel'/>*
            </button>
        }
        {!!handleGetPDF &&
            <button
                type='button'
                onClick={handleGetPDF}
                className='btn btn-large get-pdf-btn'
                disabled={disabled || isReportFetching && formatFetchingReport === reportExportFormats.PDF}>
                <FormattedMessage id='reporting.getPDF'/>
            </button>
        }
        {isEditMode
            ? <button
                type='submit'
                className='btn btn-large'
                disabled={disabled || !dirty}>
                <FormattedMessage id='reporting.saveChanges'/>
            </button>
            : <button
                type='submit'
                className='btn btn-large save-btn'
                disabled={disabled}>
                <FormattedMessage id='reporting.save'/>
            </button>
        }
    </div>
);

ReportingActionsSection.propTypes = {
    handleGetPDF: PropTypes.func,
    handleGetExcel: PropTypes.func,
    isEditMode: PropTypes.bool,
    disabled: PropTypes.bool,
    isReportFetching: PropTypes.bool,
    formatFetchingReport: PropTypes.string,
    dirty: PropTypes.bool
};

export default ReportingActionsSection;
