import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { CheckBox } from 'Components/CheckBox';
import ColoredCheckBoxLabel from 'Components/widgets/_commonComponents/Performance/components/ColoredCheckBoxLabel';

class PerformanceFilterList extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        values: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            color: PropTypes.string,
            isSelected: PropTypes.bool
        })).isRequired
    };

    constructor(props) {
        super(props);
        const { values } = props;
        const isAllSelected = _.every(values, { isSelected: true });

        this.state = {
            values,
            isAllSelected
        };
    }

    handleChangeAllSelected = (e) => {
        const values = this.state.values.map(value => ({
            ...value,
            isSelected: e.target.checked
        }));

        this.setState({ values, isAllSelected: e.target.checked });
    };

    handleChangeValue = (changedValue, isChecked) => {
        const values = this.state.values.map(value => ({
            ...value,
            isSelected: changedValue === value ? isChecked : value.isSelected
        }));
        const isAllSelected = _.every(values, value => value.isSelected);

        this.setState({ values, isAllSelected });
    };

    handleSubmit = () => {
        this.props.onSubmit(this.state.values);
    };

    getValueLabel = (value) => {
        return (
            <ColoredCheckBoxLabel
                label={value.label}
                color={value.color}/>
        );
    };

    render() {
        const { isAllSelected } = this.state;

        return (
            <div className='performance-filter-list'>
                <CheckBox
                    label='Show All'
                    position='block'
                    checked={isAllSelected}
                    onChange={this.handleChangeAllSelected}/>
                {this.state.values.map(value =>
                    (<CheckBox
                        key={value.id}
                        label={this.getValueLabel(value)}
                        position='block'
                        onChange={e => this.handleChangeValue(value, e.target.checked)}
                        checked={value.isSelected}/>)
                )}
                <button
                    onClick={this.handleSubmit}
                    type='submit'
                    className='btn'>
                    <FormattedMessage id='common.apply'/>
                </button>
            </div>
        );
    }
}

export default PerformanceFilterList;
