import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import { MillionChangeFormatter, NullableFormatter } from 'Components/formatters';
import TableEasy from 'Components/Tables/TableEasy';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';

class AnalysisManagerStyleContent extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl.formatMessage;

        this.columns = [
            {
                dataKey: 'managerStyle',
                title: formatMessage({ id: 'analysis.manager.style.managerStyle' }),
                bodyCellComponent: v => <NullableFormatter {...v} withTitle/>
            },
            {
                dataKey: 'os',
                title: formatMessage({ id: 'analysis.manager.style.os' }),
                maxWidth: 55,
                columnClassName: 'text-right',
                bodyCellComponent: TotalValueOsCell
            },
            {
                dataKey: 'change',
                title: formatMessage({ id: 'analysis.manager.style.change' }),
                columnClassName: 'text-right',
                bodyCellComponent: MillionChangeFormatter
            }
        ];
    }

    render() {
        const { aggregate, widgetFooterProps, rows, hideBarChart, isFetching, widgetNoData, isDataViz } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        widgetFooterProps={widgetFooterProps}
                        className='widget-table'
                        hideBarChart={hideBarChart}
                        columns={this.columns}
                        data={rows}
                        renderAllRows={isDataViz}
                        aggregate={aggregate}/>
                </NoDataComponent>
            </div>
        );
    }
}

export default AnalysisManagerStyleContent;
