import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import { operationTypes } from 'Constants/analysisShareholders';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export const getHistoricalAnalysisShareholderData = (shareholderId, originalCancelToken) => {
    const payload = {
        shareholderId,
        operation: operationTypes.GET_HISTORICAL_ANALYSIS_SHAREHOLDER_DATA
    };
    const requestData = utils.buildRequest(
        payload,
        requestTypes.historicalAnalysisShareholders);

    return axios.post(apiRoutes.getHistoricalAnalysisShareholdersUrl,
        requestData,
        {
            originalCancelToken,
            isCancelable: true
        });
};
