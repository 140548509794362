import React from 'react';
import PropsTypes from 'prop-types';
import cn from 'classnames';

import './ShareholderContactDetails.scss';

export const ShareholderContactDetails = ({ contact, className }) => {
    const { name, title, email, phone1, phone2, phone3, corporateEmail } = contact;

    return (
        <section className={cn('shareholder-contact-details', className)}>
            <h3 className='shareholder-contact-details__username'>{name}</h3>
            <div className='shareholder-contact-details__description'>
                <p>{name}</p>
                {title && <p>{title}</p>}
                {email && <p>{email}</p>}
                {phone1 && <p>{phone1}</p>}
                {phone2 && <p>{phone2}</p>}
                {phone3 && <p>{phone3}</p>}
                {corporateEmail && <p>{corporateEmail}</p>}
            </div>
        </section>
    );
};

ShareholderContactDetails.propTypes = {
    contact: PropsTypes.shape({
        name: PropsTypes.string.isRequired,
        title: PropsTypes.string,
        email: PropsTypes.string,
        phone1: PropsTypes.string,
        phone2: PropsTypes.string,
        phone3: PropsTypes.string,
        corporateEmail: PropsTypes.string
    }).isRequired,
    className: PropsTypes.string
};
