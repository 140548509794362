import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { LazyPopover } from 'Components/Popover';

import './UndefinedShareholderTooltip.scss';

const UndefinedShareholderTooltip = ({ children, block }) => {
    const triggerClassName = cn('undefined-shareholder-tooltip__popover-trigger', {
        'undefined-shareholder-tooltip__popover-trigger--block': block
    });

    const popoverProps = {
        id: 'UNDEFINED-SHAREHOLDER-POPOVER',
        trigger: <span className={triggerClassName}>{children}</span>,
        triggerType: ['click', 'hover'],
        block
    };

    return (
        <LazyPopover {...popoverProps} placement='top'>
            <div className='undefined-shareholder-tooltip__popover-tip-content'>
                <FormattedMessage id='watchlist.undefined.shareholder.tooltip' />
            </div>
        </LazyPopover>
    );
};

UndefinedShareholderTooltip.propTypes = {
    children: PropTypes.node,
    block: PropTypes.bool
};

export default UndefinedShareholderTooltip;
