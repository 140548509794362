import slotTypes from 'Constants/slot-types';

export default {
    [1]: {
        type: slotTypes.SINGLE,
        isLocked: true,
        widgets: []
    },
    [2]: {
        isLocked: true,
        widgets: [],
        isEmpty: true
    },
    [3]: {
        isLocked: true,
        widgets: [],
        isEmpty: true
    },
    [4]: {
        type: slotTypes.SINGLE,
        isLocked: true,
        widgets: []
    }
};
