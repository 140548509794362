import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSelectedWatchlistSelector } from 'State/watchlist';
import * as widgetActions from 'State/widgets/actions';
import { getCurrentSortCriteria } from '../../utils';
import useSyncShareholders from '../../hooks/useSyncShareholders';

const WatchlistDataLoader = ({
    onUpdateWidgetData,
    redirectOn404,
    isFetching,
    widgetSettings,
    widgetData,
    isError,
    currentWatchlistId,
    widgetName,
    widgetsUpdateSettings
}) => {
    const sortCriteria = getCurrentSortCriteria(widgetSettings);

    useEffect(() => {
        if (isFetching || !currentWatchlistId) {
            return;
        }

        if (widgetSettings.currentWatchlistId !== currentWatchlistId) {
            widgetsUpdateSettings(widgetName, {
                ...widgetSettings,
                currentWatchlistId
            });
        }

        onUpdateWidgetData({ currentWatchlistId, sortCriteria }, { redirectOn404 });
    }, [currentWatchlistId, widgetSettings.currentWatchlistId, widgetName]);

    useSyncShareholders({
        widgetData,
        isError,
        isFetching,
        currentWatchlistId
    });

    return null;
};

WatchlistDataLoader.propTypes = {
    onUpdateWidgetData: PropTypes.func.isRequired,
    redirectOn404: PropTypes.bool,
    isFetching: PropTypes.bool,
    widgetSettings: PropTypes.shape({
        currentWatchlistId: PropTypes.number
    }),
    widgetName: PropTypes.string,
    widgetsSetSettings: PropTypes.func
};

const mapStateToProps = state => ({
    currentWatchlistId: getSelectedWatchlistSelector(state)
});

const mapDispatchToProps = ({
    widgetsUpdateSettings: widgetActions.widgetsUpdateSettings
});

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistDataLoader);
