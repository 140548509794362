import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';

import HighlightFormatter from './HighlightFormatter';

const SEPARATOR = /#[@]{1,2}#/;

const FieldFormatter = ({ values, field, intl: { formatMessage } }) => {
    const sourceValue = get(values, field);

    switch (field) {
        case 'acctnum':
            return (<HighlightFormatter
                value={formatMessage({ id: 'search.acctnum' }, { acctnum: sourceValue })}
                separator={SEPARATOR}/>);
        case 'position':
            return (
                <FormattedMessage
                    id='search.position'
                    values={{ position: sourceValue }}/>
            );
        default:
    }

    return <HighlightFormatter value={sourceValue} separator={SEPARATOR}/>;
};

FieldFormatter.propTypes = {
    values: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    intl: intlShape
};

export default injectIntl(FieldFormatter);
