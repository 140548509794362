import PropTypes from 'prop-types';

const defaultCellRenderer = ({
    value
    // columnData,
    // dataKey,
    // rowIndex
}) => {
    if (value === null || value === undefined) {
        return '';
    }

    return String(value);
};

defaultCellRenderer.propTypes = {
    value: PropTypes.any,
    columnData: PropTypes.any,
    dataKey: PropTypes.string,
    rowIndex: PropTypes.number
};

export default defaultCellRenderer;
