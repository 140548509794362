import React from 'react';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';
import _ from 'lodash';

import {
    getFirstWatchlistSelector,
    isErrorWatchlistSelector,
    isFetchedWatchlistSelector,
    isLoadingWatchlistSelector
} from 'State/watchlist';
import Spinner from 'Components/Spinner';
import { getCurrentFeaturesSelector } from 'State/features';
import featureTypes from 'Constants/feature-types';
import WatchlistShareholderAddButton from './components/WatchlistShareholderAddButton';
import WatchlistShareholderRemoveButton from './components/WatchlistShareholderRemoveButton';

import './WatchlistShareholderButton.scss';

const WatchlistShareholderButton = ({
    shareholderId,
    shareholderTitle,
    shareholderType,
    firstWatchlist,
    isLoading,
    isError,
    currentFeatures,
    isFetched
}) => {
    if (!currentFeatures[featureTypes.SHAREHOLDER_WATCHLIST]) {
        return null;
    }

    if (isLoading && !isFetched) {
        return <Spinner/>;
    }

    if (isError) {
        return null;
    }

    const shareholderInWatchlist = _.find(
        _.get(firstWatchlist, 'shareholders', []),
        (shareholder) => shareholder.id === shareholderId
    );

    if (shareholderInWatchlist) {
        return (
            <WatchlistShareholderRemoveButton
                shareholderId={shareholderId}
                shareholderTitle={shareholderTitle}
                className='watchlist-shareholder-button'/>
        );
    }

    return (
        <WatchlistShareholderAddButton
            shareholderId={shareholderId}
            shareholderTitle={shareholderTitle}
            shareholderType={shareholderType}
            className='watchlist-shareholder-button'/>
    );
};

WatchlistShareholderButton.propTypes = {
    shareholderId: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
    shareholderTitle: PropsTypes.string,
    shareholderType: PropsTypes.string,
    firstWatchlist: PropsTypes.object,
    isLoading: PropsTypes.bool,
    isError: PropsTypes.bool,
    currentFeatures: PropsTypes.object,
    isFetched: PropsTypes.bool
};

const mapStateToProps = (state) => ({
    firstWatchlist: getFirstWatchlistSelector(state),
    isLoading: isLoadingWatchlistSelector(state),
    isError: isErrorWatchlistSelector(state),
    currentFeatures: getCurrentFeaturesSelector(state),
    isFetched: isFetchedWatchlistSelector(state)
});

const mapDispatchToProps = ({});

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistShareholderButton);
