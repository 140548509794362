import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import dateFormats from 'Constants/date-formats';
import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import { numberFormatter } from 'Components/formatters/formatters';
import ControlBooksResultTabs from './ControlBooksResultTabs';

const PREVIOUS_BALANCE_DECIMAL_POINTS = 3;

const localDateFormatter = (value) => {
    const date = moment(value);

    return value && date.isValid() ? date.format(dateFormats.default) : null;
};

const ControlBooksResultHeader = (props) => {
    const { intl } = props;
    const startDate = _.get(props, 'widgetData.aggregateValues.dateRange.startDate');
    const endDate = _.get(props, 'widgetData.aggregateValues.dateRange.endDate');
    const date = _.get(props, 'widgetData.aggregateValues.balanceCob.date');
    const value = _.get(props, 'widgetData.aggregateValues.balanceCob.value');
    const securitiesCount = _.get(props, 'securities.length');

    return (
        <div className='widget-header'>
            <div className='widget-info-container'>
                { startDate && endDate && <FormattedMessage
                    id={'reporting.control.books.results.subtitle'}
                    values={{
                        startDate: localDateFormatter(startDate),
                        endDate: localDateFormatter(endDate)
                    }}/>
                }
                {
                    securitiesCount && <FormattedMessage
                        id={'reporting.control.books.results.securityCount'}
                        values={{
                            count: securitiesCount
                        }}/>
                }
            </div>
            { securitiesCount === 1 && date && _.isNumber(value) && (
                <div className='balance-cob-message'>
                    <FormattedMessage
                        id='reporting.control.books.results.balanceCob'
                        values={{
                            date: localDateFormatter(date),
                            value: numberFormatter(intl, value, {
                                decimalPoints: PREVIOUS_BALANCE_DECIMAL_POINTS
                            })
                        }}/>
                </div>) }
            <ControlBooksResultTabs/>
        </div>
    );
};

ControlBooksResultHeader.propTypes = {
    ...commonWidgetPropTypes,
    ...commonWidgetActions,
    intl: intlShape,
    includeWeightedData: PropTypes.bool
};

export default injectIntl(ControlBooksResultHeader);
