import React from 'react';
import PropTypes from 'prop-types';

import { ReportingPage } from 'Pages';

const ReportingLayout = (props) => (
    <ReportingPage>
        {props.children}
    </ReportingPage>
);

ReportingLayout.propTypes = {
    children: PropTypes.element
};

export default ReportingLayout;
