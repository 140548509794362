import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import { logout } from '../../api/auth';

import LinkEx from 'Components/LinkEx';

export default class Nav extends Component {
    static propTypes = {
        links: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string,
            to: PropTypes.string,
            navItemClassName: PropTypes.string
        })).isRequired,
        horizontal: PropTypes.bool,
        secondary: PropTypes.bool
    };

    _renderLinks() {
        const { links } = this.props;

        return links.map((link) => {
            return (<li key={link.label} className={cn('nav-item', link.navItemClassName)}>
                {link.href
                    ? <a onClick={link.withLogout ? () => logout() : null} href={link.href} target={link.isExternal ? '_blank' : '_self'}>{link.label}</a>
                    : <LinkEx
                        onClick={link.withLogout ? () => logout() : null}
                        activeClassName='active'
                        to={link.to}
                        exact={link.exact}>
                        {link.label}
                    </LinkEx>
                }
            </li>);
        });
    }

    render() {
        const { horizontal, secondary } = this.props;
        const ulClassName = cn('nav', { horizontal, secondary });

        return (
            <div className='nav-panel'>
                <ul className={ulClassName}>
                    {this._renderLinks()}
                </ul>
            </div>
        );
    }
}
