import moment from 'moment';
import _ from 'lodash';

import dateFormat from 'Constants/date-formats';

export const parseDate = (value, format = dateFormat.DATEPICKER_FORMAT) => {
    if (_.isString(value)) {
        const date = moment(value, format);

        return date.isValid() ? date.format(dateFormat.SERVER_DATE_FORMAT) : null;
    }
    return value ? value.format(dateFormat.SERVER_DATE_FORMAT) : null;
};

export const parseDateRange = (names, format = dateFormat.DATEPICKER_FORMAT) => (value, name) => {
    const { startDateOptions: startName, endDateOptions: endName } = names;

    if (name === startName || name === endName) {
        return parseDate(value, format);
    }
    return value;
};

export const formatDate = (value) => {
    if (_.isString(value)) {
        const date = moment(value, dateFormat.SERVER_DATE_FORMAT);

        return date.isValid() ? date : null;
    }
    return value;
};

export const formatDateRange = (names) => (value, name) => {
    const { startDateOptions: startName, endDateOptions: endName } = names;

    if (name === startName || name === endName) {
        return formatDate(value);
    }
    return value;
};

export const isWorkDay = (date) => {
    const day = date.day();

    return day !== 0 && day !== 6;
};
