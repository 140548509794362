import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { CheckBox } from 'Components/CheckBox';
import exportReportTypes from 'Constants/export-report-formats';
import featureTypes from 'Constants/feature-types';

const isChecked = (values, id) => {
    return values.indexOf(id) > -1;
};

const handleOnChange = (checked, value, values, input) => {
    const newValues = checked ?
        [...values, value] :
        values.filter(val => val !== value);

    input.onChange(newValues);
};

const IncludingFeatures = (props) => {
    const { includingFeatures, input, isDisabled = false, selectedFormat } = props;
    const values = _.get(input, 'value', []);
    const isDisabledBasicField = !!values.length
        && values.includes(featureTypes.CONTENTS_ONSCREEN);
    const isDisabledSpecialField = !!values.length
        && !values.includes(featureTypes.CONTENTS_ONSCREEN)
        || selectedFormat !== exportReportTypes.PDF;

    return (
        <div>
            {
                includingFeatures.map((feature) => {
                    const isDisabledField = isDisabled
                    || feature.value === featureTypes.CONTENTS_ONSCREEN ? isDisabledSpecialField : isDisabledBasicField;

                    return (<CheckBox
                        key={feature.value}
                        value={feature.value}
                        position='block'
                        label={feature.label}
                        checked={!isDisabledField && isChecked(values, feature.value)}
                        disabled={isDisabledField}
                        onChange={(e) => handleOnChange(e.target.checked, e.target.value, values, input)}/>);
                })
            }
        </div>
    );
};

IncludingFeatures.propTypes = {
    includingFeatures: PropTypes.array,
    input: PropTypes.object,
    isDisabled: PropTypes.bool,
    selectedFormat: PropTypes.string,
    includedFeatures: PropTypes.array
};

export default IncludingFeatures;
