import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import Icon from 'Components/Icon';

export default class ReportingDownloadsActionView extends Component {
    static propTypes = {
        data: PropTypes.shape({
            reportId: PropTypes.number,
            status: PropTypes.string
        }).isRequired,
        isAvailable: PropTypes.func.isRequired,
        onClickAction: PropTypes.func.isRequired,
        className: PropTypes.string,
        titleKey: PropTypes.string
    };

    handleClick = () => {
        const { data, onClickAction } = this.props;
        const isSubscription = !!data.subscriptionId;

        onClickAction(data, isSubscription);
    };

    render() {
        const { className, data, isAvailable, titleKey } = this.props;
        const isSubscription = !!data.subscriptionId;
        const classes = cn('font-icon', className,
            {
                inactive: !isAvailable(data.status, data.isDeleting, isSubscription)
            }
        );

        return (
            <Icon
                titleKey={titleKey}
                className={classes}
                onClick={this.handleClick}/>
        );
    }
}
