import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';
import { connect } from 'react-redux';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import widgetTypes from 'Constants/widget-types';
import shareholderTypes from 'Constants/shareholder-types';
import WidgetNavigationPanel from '../../../WidgetNavigationPanel';
import ShareholdersTypes from './ShareholdersTypes';
import { commonWidgetPropTypes, commonWidgetActions, isCurrentWidgetType } from 'Components/widgets/utils';
import { geographicAnalysisMapSettingsStrategy } from '../../utils/map-settings-strategies';

import './AnalysisGeographicActionPanel.scss';

const makeMapStateToProps = (initialState, { slotId }) => {
    const availableWidgetTypesSelector = dynamicPageSelectors.makeAvailableWidgetTypesSelector(slotId);

    return (state) => ({
        availableWidgets: availableWidgetTypesSelector(state, slotId)
    });
};

@connect(makeMapStateToProps)
class AnalysisGeographicActionPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        availableWidgets: PropTypes.array,
        onChangeWidget: PropTypes.func,
        widgetData: PropTypes.any,
        widgetSettings: PropTypes.any,
        onUpdateWidgetSettings: PropTypes.func
    };

    getShareholdersTypesProps = () => {
        const { intl, widgetSettings, widgetData, onUpdateWidgetSettings } = this.props;
        const activeShareholderType = _.get(widgetSettings, 'activeShareholderType');

        return {
            currentValue: {
                value: activeShareholderType,
                label: intl.formatMessage({ id: `shareholder.type.${shareholderTypes[activeShareholderType]}` })
            },
            values: _.map(widgetData, item => ({
                value: item.shareholderType,
                label: intl.formatMessage({ id: `shareholder.type.${shareholderTypes[item.shareholderType]}` })
            })),
            onChange: (selectedShareholderType) => {
                const newWidgetSettings = {
                    ...widgetSettings,
                    activeShareholderType: selectedShareholderType
                };

                onUpdateWidgetSettings(newWidgetSettings);
            }
        };
    };

    getNavigationPanelProps = () => {
        const { intl, onChangeWidget, availableWidgets, widgetName } = this.props;
        const _availableWidgets = [...availableWidgets];
        const widgets = [
            {
                displayName: intl.formatMessage({ id: 'analysis.geographic.country.type' }),
                widgetType: widgetTypes.analysisGeographicCountry,
                isCurrent: isCurrentWidgetType(widgetTypes.analysisGeographicCountry, widgetName)
            }, {
                displayName: intl.formatMessage({ id: 'analysis.geographic.state.type' }),
                widgetType: widgetTypes.analysisGeographicState,
                isCurrent: isCurrentWidgetType(widgetTypes.analysisGeographicState, widgetName)
            }, {
                displayName: intl.formatMessage({ id: 'analysis.geographic.metro.type' }),
                widgetType: widgetTypes.analysisGeographicMetro,
                isCurrent: isCurrentWidgetType(widgetTypes.analysisGeographicMetro, widgetName)
            }
        ];

        _.forEach(widgets, (widget) => {
            const isMissed = widget.isCurrent && !_.some(availableWidgets, item => item.name === widget.widgetType);

            if (isMissed) {
                _availableWidgets.push({
                    name: widget.widgetType,
                    isSelected: true
                });
            }
        });

        return {
            widgets,
            availableWidgets: _availableWidgets,
            onSelect: (widgetParams) => onChangeWidget(widgetParams, {
                mapSettingsStrategy: geographicAnalysisMapSettingsStrategy
            })
        };
    };

    render() {
        return (
            <div className='analysis-geographic-action-panel'>
                <WidgetNavigationPanel {...this.getNavigationPanelProps()}/>
                {this.props.widgetData && <ShareholdersTypes {...this.getShareholdersTypesProps()}/>}
            </div>
        );
    }
}

export default AnalysisGeographicActionPanel;
