export const WIDGET_PRINT = 'WIDGET_START_PRINT';

export const printWidget = (widgetType, widgetHeight) => dispatch => {
    dispatch({
        type: WIDGET_PRINT,
        payload: {
            widgetType,
            widgetHeight
        }
    });
};
