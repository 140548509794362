import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import _ from 'lodash';
import { mapProps } from '@shakacode/recompose';

import TableEasy, { mapBarValuesToData } from 'Components/Tables/TableEasy';
import { NullableFormatter } from 'Components/formatters';
import widgetModes from '../../constants/widget-modes';

const OverallOwnershipStatisticsTableView = props => {
    const { legendData, widgetFooterProps, intl: { formatMessage }, isDataViz, hideBarChart } = props;
    const outstandingSharesTotal = _.get(legendData, ['aggregate', 'outstandingSharesTotal']);

    return (
        <div className='table-container'>
            <div className='total-value-container'>
                <span className='total-value-message'>
                    { formatMessage({ id: 'overall.ownership.statistics.totalValue' }) }:
                </span>
                {_.isNumber(outstandingSharesTotal)
                    ? <FormattedNumber value={outstandingSharesTotal} />
                    : (<span>{NullableFormatter({ value: outstandingSharesTotal })}</span>)
                }
            </div>
            <TableEasy
                className='widget-table'
                totalMessageId = 'tableEasy.priorCOBTotal'
                widgetFooterProps={widgetFooterProps}
                renderAllRows={isDataViz}
                hideBarChart={hideBarChart}
                {...legendData}/>
        </div>
    );
};

OverallOwnershipStatisticsTableView.propTypes = {
    legendData: PropTypes.object,
    totalOutstandingShares: PropTypes.number,
    intl: PropTypes.object,
    isDataViz: PropTypes.bool,
    widgetFooterProps: PropTypes.object,
    hideBarChart: PropTypes.bool
};

export default mapProps(props => ({
    ...props,
    legendData: {
        ...props.legendData,
        data: mapBarValuesToData(props.legendData.data, 'os')
    },
    hideBarChart: _.get(props, 'widgetSettings.mode') !== widgetModes.BAR_CHART
}))(OverallOwnershipStatisticsTableView);
