import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactTable from 'react-table';

import './ScrollableReactTable.scss';

const MIN_TABLE_CELL_DATA_INDENT = 5;

// import { whyDidYouUpdate } from 'Utils/whyDidYouUpdateDecorator';
//
// @whyDidYouUpdate
class ScrollableReactTable extends PureComponent {
    static propTypes = {
        data: PropTypes.array,
        resolvedData: PropTypes.array,
        columns: PropTypes.array,
        maxShowRows: PropTypes.number,
        TableComponent: PropTypes.func
    };

    componentDidMount() {
        this.adjustTable();
    }

    setTableBodyMaxHeight = () => {
        const sortedArrows = [...this.table.querySelectorAll('.rt-tr-group')]  // here we get NodeList and convert it into regular array
            .sort((a, b) => a.clientHeight - b.clientHeight);
        const minRowHeight = sortedArrows[0] ? sortedArrows[0].clientHeight : 0;

        if (minRowHeight) {
            this.tableBody.style.maxHeight = `${minRowHeight * this.props.maxShowRows}px`;
        }
    };

    setTableDataCellPadding = () => {
        const tableDataCells = this.tableBody.querySelectorAll('.rt-td:last-child');
        const tableHeaderCells = this.table.querySelectorAll('.rt-th:last-child');

        this.scrollWidth = this.tableBody.offsetWidth - this.tableBody.clientWidth;
        if (tableDataCells.length > this.props.maxShowRows) {
            const indentRight = MIN_TABLE_CELL_DATA_INDENT + this.scrollWidth;
            const diffCompensation = Math.abs(this.tableBody.clientWidth - this.tableBody.scrollWidth); // IE10 fix

            if (diffCompensation > 1) {
                for (let i = 0; i < tableDataCells.length; i++) {
                    if (tableDataCells[i].innerHTML) {
                        tableDataCells[i].style.paddingRight = `${indentRight}px`;
                    }
                }
            }

            for (let i = 0; i < tableHeaderCells.length; i++) {
                if (tableHeaderCells[i].innerHTML) {
                    tableHeaderCells[i].style.marginRight = `${this.scrollWidth}px`;
                }
            }
        }
    };

    getTableBody = el => {
        this.table = el ? el.querySelector('.ReactTable') : null;
        this.tableBody = el ? el.querySelector('.rt-tbody') : null;
    };

    adjustTable = () => {
        if (this.tableBody && this.tableBody.clientWidth) {
            this.setTableBodyMaxHeight();
            this.setTableDataCellPadding();
        }
    };

    render() {
        const TableComponent = this.props.TableComponent || ReactTable;

        return (this.props.maxShowRows
            ? <div className='scrollable-react-table' ref={this.getTableBody}>
                <TableComponent {...this.props}/>
            </div>
            : <TableComponent {...this.props}/>);
    }
}

export default ScrollableReactTable;
