import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withProps } from '@shakacode/recompose';
import _ from 'lodash';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import * as resentSearchActions from 'State/recentSearch/actions';

const normalizeShareholderInfo = (info) => {
    const { shareholderType, shareholderId, shareholderTitle } = info;

    return {
        shareholderType,
        shareholderName: shareholderTitle,
        shareholderId
    };
};

const mapStateToProps = state => ({
    info: dynamicPageSelectors.getDynamicPageInfoSelector(state),
    isFetching: dynamicPageSelectors.getDynamicPageFetchingSelector(state)
});

const mapDispatchToProps = ({
    recentSearchAddAccount: resentSearchActions.addAccount
});

const pageConnector = connect(mapStateToProps, mapDispatchToProps);


const pageSendEvent = lifecycle({
    componentDidUpdate(prevProps) {
        const {  sendEventHandler, isFetching, info } = this.props;

        if (!isFetching && _.get(info, 'shareholderId') && info.shareholderId !== prevProps.info.shareholderId) {
            sendEventHandler(info);
        }
    },
    componentDidMount() {
        const {  sendEventHandler, isFetching, info } = this.props;

        if (!isFetching && _.get(info, 'shareholderId')) {
            sendEventHandler(info);
        }
    }
});

const sendEventHandler = props => info => {
    const { recentSearchAddAccount, shareholderType } = props;

    const shareholderInfo = normalizeShareholderInfo(info);

    recentSearchAddAccount({
        ...shareholderInfo,
        shareholderType: shareholderType || shareholderInfo.shareholderType
    });
};

export default ({ shareholderType } = {}) => compose(
    withProps(() => ({
        shareholderType
    })),
    pageConnector,
    withHandlers({ sendEventHandler }),
    pageSendEvent
);
