import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import BuysAndSellsContent from './components/ShareholderBuysAndSellsContent/ShareholderBuysAndSellsContent';
import WidgetControlPanelDefault from '../Widget/components/WidgetControlPanelDefault';

import './ShareholderBuysAndSells.scss';

@injectIntl
class ShareholderBuysAndSells extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: BuysAndSellsContent,
            WidgetControlPanel: props.isPrintMode ? null : WidgetControlPanelDefault
        };

        this.widgetEvents = {
            onBuildDataParams: () => ({
                shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
            })
        };
        this.widgetTitle = this.props.intl.formatMessage({ id: 'shareholders.buys.and.sells.title' });
    }

    render() {
        const { widgetData } = this.props;

        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetNoData: !_.get(widgetData, 'data.length')
        };

        return (
            <Widget
                {...props}
                events={this.widgetEvents}
                className='widget-buys-and-sells'
                components={this.widgetComponents}/>
        );
    }
}

export default ShareholderBuysAndSells;
