import React, { Component } from 'react';
import { FormattedHTMLMessage, intlShape } from 'react-intl';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
    commonWidgetPropTypes,
    commonWidgetActions,
    getWidgetFooterPropsWithSignClass,
    isDateLessThanDefault
} from '../utils';
import Widget from '../Widget';
import AuthorizedSharesContent from './components/AuthorizedSharesContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import CalendarActionPanel from 'Components/widgets/_commonComponents/CalendarActionPanel';
import FooterWithInfo from 'Components/widgets/_commonComponents/FooterWithInfo';
import widgetModes from 'Constants/widget-modes';
import messageType from 'Constants/message-types';
import featureTypes from 'Constants/feature-types';

import './AuthorizedShares.scss';

class AuthorizedShares extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        currentFeatures: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.widgetTitle = props.intl.formatMessage({ id: 'authorized.shares.title' });
        this.widgetTitleTooltip = <FormattedHTMLMessage id='authorized.shares.tooltip.title'/>;
        this.widgetComponents = {
            WidgetContent: AuthorizedSharesContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetActionPanel: CalendarActionPanel,
            WidgetFooter: FooterWithInfo
        };
        this.widgetEvents = {
            onBuildDataParams: () => {
                return this.props.isDataViz ? ({
                    date: _.get(this.props, 'widgetSettings.selectedDate', null)
                }) : {};
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const data = _.get(nextProps, 'widgetData.dataView.data');
        const authorizedSharesTotal = _.get(_.last(data), 'aggregate.noOfShares');
        const widgetMode = _.get(nextProps, 'widgetSettings.mode');
        const eodTimestamp = _.get(nextProps, 'widgetData.eodTimestamp');
        const eodTimestampFromSettings = _.get(nextProps, 'widgetSettings.eodTimestamp');

        if (!_.isNil(eodTimestampFromSettings) && !_.isNil(eodTimestamp) && eodTimestamp !== eodTimestampFromSettings) {
            this.props.onUpdateWidgetSettings({
                ...nextProps.widgetSettings,
                selectedDate: null,
                eodTimestamp
            });
            this.props.onUpdateWidgetData({
                date: null
            });
        }

        if (authorizedSharesTotal <= 0 && widgetMode !== widgetModes.TABLE) {
            this.props.onUpdateWidgetSettings({ ...nextProps.widgetSettings, mode: widgetModes.TABLE });
        }
    }

    getWidgetFooterProps = (widgetData, selectedDate, effectiveDate, isDataViz) => ({
        rightSide: [{
            messageKey: this.isUserWithESPPPlanSecurities(widgetData) && !isDataViz
                ? 'widgets.message.includesESPPWithAction'
                : 'widgets.message.includesESPP',
            type:  messageType.INCLUDES_ESPP,
            hidden: !this.showESPPMessage(widgetData)
        }, {
            messageKey: 'widgets.message.includesCeDeCo',
            type: messageType.INCLUDES_CEDECO,
            hidden: !this.showCeDeCoMessage(widgetData)
        }],
        bottom: [
            {
                messageKey: 'authorized.shares.note.incorrectNoOfShares',
                hidden: !this.showIncorrectNoOfSharesMessage(widgetData)
            },
            {
                messageKey: this.isAuthorizedSharesOnly(widgetData)
                    ? 'authorized.shares.note.authorizedSharesOnly'
                    : 'authorized.shares.note.disabledLinks',
                hidden: !isDateLessThanDefault(widgetData),
                className: 'alert-note'
            }
        ]
    });

    showCeDeCoMessage = widgetData => {
        const widgetDataValues = _.get(widgetData, 'dataView.data');

        return _.some(widgetDataValues, ({ values, aggregate }) =>
            _.some(values, 'includesCeDeCo') || _.get(aggregate, 'includesCeDeCo'));
    };

    showESPPMessage = widgetData => {
        const widgetDataValues = _.get(widgetData, 'dataView.data');

        return _.some(widgetDataValues, ({ values }) => _.some(values, 'customParams.includesESPP'));
    };

    showIncorrectNoOfSharesMessage = (widgetData) => {
        const lastTableData = _.last(_.get(widgetData, 'dataView.data'));

        return _.get(lastTableData, 'aggregate.noOfShares') === 0;
    };

    isAuthorizedSharesOnly = widgetData => {
        const data = _.get(widgetData, 'dataView.data');
        const widgetNoData = !_.get(data, 'length');
        const totalIssuedShares = _.find(data, view => _.get(view, 'aggregate.allotment') ===
            this.props.intl.formatMessage({ id: 'authorized.shares.total.issuedShares' }));
        const noOfShares = _.get(totalIssuedShares, 'aggregate.noOfShares');

        return !widgetNoData && _.isNil(noOfShares);
    };

    isUserWithESPPPlanSecurities = widgetData => {
        const data = _.get(widgetData, 'dataView.data');

        return _.some(data, ({ values }) =>
            _.some(values, v => _.get(v, 'customParams.includesESPP') && _.get(v, 'customParams.esppSecurityIds.length')));
    };

    render() {
        const data = this.props.widgetData;
        const tableData = _.get(data, 'dataView.data');
        const authorizedSharesTotal = _.get(_.last(tableData), 'aggregate.noOfShares');
        const dateEnabled = this.props.currentFeatures[featureTypes.AUTHORIZED_SHARES_DATE_SELECTION];
        const selectedDate = dateEnabled && _.get(this.props, 'selectedDate') || _.get(this.props.widgetData, 'date');
        const effectiveDate = _.get(data, 'effectiveDate');
        const widgetFooterProps = getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(data, selectedDate, effectiveDate));
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetTitleTooltip: this.widgetTitleTooltip,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            },
            widgetFooterProps,
            exchangeName: _.get(data, 'exchangeName'),
            isTogglingModeBlocked: authorizedSharesTotal === 0,
            isTogglingModeEnabled: authorizedSharesTotal !== -1, // if we have -1 it means that we have 'unlimited' total Authorized Shares
            widgetNoData: !_.get(tableData, 'length'),
            data,
            selectedDate,
            dateEnabled,
            isCurrentDateAvailable: false
        };

        return (
            <Widget
                {...props}
                className='widget-authorized-shares'
                components={this.widgetComponents}
                events={this.widgetEvents}/>
        );
    }
}

export default AuthorizedShares;
