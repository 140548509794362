import { compose, withState } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import AuthorizedShares from './AuthorizedShares';
import { getCurrentFeaturesSelector } from 'State/features';

const mapStateToProps = state => ({
    currentFeatures: getCurrentFeaturesSelector(state)
});

export default compose(
    withState(
        'selectedDate',
        'onDateChange'
    ),
    connect(mapStateToProps),
    injectIntl
)(AuthorizedShares);
