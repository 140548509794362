import sanitizeHtml from 'sanitize-html';

// sanitizeHtml.defaults = {
//     allowedTags: [ 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
//         'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
//         'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre' ],
//     allowedAttributes: {
//         a: [ 'href', 'name', 'target' ],
//         // We don't currently allow img itself by default, but this
//         // would make sense if we did
//         img: [ 'src' ]
//     },
//     // Lots of these won't come up by default because we don't allow them
//     selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
//     // URL schemes we permit
//     allowedSchemes: [ 'http', 'https', 'ftp', 'mailto' ],
//     allowedSchemesByTag: {},
//     allowProtocolRelative: true,
//     nonTextTags: [ 'script', 'style', 'textarea', 'noscript' ]
// };

const config = {
    allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i',
        'strong', 'em', 'strike', 'code', 'hr', 'br', 'p', 'table', 'thead', 'caption',
        'tbody', 'tr', 'th', 'td', 'pre', 'span', 'col', 'colgroup', 'tfoot',
        'section', 'header', 'article', 'img', 'sub', 'sup'],
    allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        p: ['align'],
        img: ['src', 'alt'],
        '*': ['data-*', 'style']
    },
    allowedClasses: {
        p: ['underline', 'scroll'],
        span: ['underline'],
        header: ['expand']
    },
    selfClosing: ['span']
};

export default htmlString => (sanitizeHtml(htmlString, config));
