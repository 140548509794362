export default {
    addNote: 'addNote',
    editNote: 'editNote',
    event: 'event',
    newEvent: 'newEvent',
    editEvent: 'editEvent',
    confirm: 'confirm',
    info: 'info',
    columnManager: 'columnManager',
    saveCustomView: 'saveCustomView',
    advancedSearch: 'advancedSearch',
    commonColumnsManager: 'commonColumnsManager',
    saveReportTemplate: 'saveReportTemplate',
    saveChangesModal: 'saveChangesModal',
    searchTopShareholdersModal: 'searchTopShareholdersModal',
    withWidgetModal: 'withWidgetModal',
    sessionTimeoutModal: 'sessionTimeoutModal',
    analysisTurnoverLegendModal: 'analysisTurnoverLegendModal',
    selectSecurityModal: 'selectSecurityModal',
    termsAndConditionsModal: 'termsAndConditionsModal',
    selectAccountModal: 'selectAccountModal',
    exportReport: 'exportReport',
    exportShareholderTable: 'exportShareholderTable',
    exportHistoricalAnalysis: 'exportHistoricalAnalysis',
    exportReportPending: 'exportReportPending',
    exportReportNoResults: 'exportReportNoResults',
    exportRegisteredOverview: 'exportRegisteredOverview',
    exportClassicShareholderReportModal: 'exportClassicShareholderReportModal',
    exportClassicAsOfDateReportModal: 'exportClassicAsOfDateReportModal',
    alertsModal: 'alertsModal',
    reportRequestedModal: 'reportRequestedModal',
    createNoteModal: 'createNoteModal',
    noteDetailsModal: 'noteDetailsModal',
    saveScheduledReportTemplate: 'saveScheduledReportTemplate',
    shareholderContactsModal: 'shareHolderContactsModal',
    exportShareholderContactsModal: 'exportShareHolderContactsModal',
    shareReportTemplateModal: 'shareReportTemplateModal',
    renameWatchlistModal: 'renameWatchlistModal',
    exportWatchlistModal: 'exportWatchlistModal',
    selectEmailRecipientsModal: 'selectEmailRecipientsModal'
};
