import pageTypes from 'Constants/dynamic-page-types';
import widgetTypes from 'Constants/widget-types';
import { getWidgetData } from './widget';

export function getShareholdersFunds(shareholdersId) {
    const widgets = [{
        type: widgetTypes.shareholdersTableFunds,
        params: {
            dataSourceId: 'shareholdersTableFundsData',
            shareholderId: shareholdersId
        }
    }];

    return getWidgetData(pageTypes.OWNERSHIP_SHAREHOLDERS, widgets);
}

export function getShareholderPositions(shareholdersId) {
    const widgets = [{
        type: widgetTypes.shareholdersTablePositions,
        params: {
            dataSourceId: 'shareholdersTablePositionsData',
            shareholderId: shareholdersId
        }
    }];

    return getWidgetData(pageTypes.OWNERSHIP_SHAREHOLDERS, widgets);
}

export function getShareholdersContacts(shareholdersId) {
    const widgets = [{
        type: widgetTypes.shareholdersProxyContacts,
        params: {
            dataSourceId: 'shareholdersTableContactsData',
            shareholderId: shareholdersId
        }
    }];

    return getWidgetData(pageTypes.OWNERSHIP_SHAREHOLDERS, widgets);
}

export function getShareholdersSubProxy(shareholdersId) {
    const widgets = [{
        type: widgetTypes.subProxyTable,
        params: {
            dataSourceId: 'subProxyTableData',
            shareholderId: shareholdersId
        }
    }];

    return getWidgetData(pageTypes.OWNERSHIP_SHAREHOLDERS, widgets);
}
