import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { intlShape } from 'react-intl';

import { RadioButtonsListField } from 'Components/RadioButtonsList';
import { InputDatePickerField } from 'Components/InputDatePicker';

const ExportClassicShareholdersReportView = props => {
    const { intl: { formatMessage }, onExport, hideModal, onSubmit, items, dateOptions, valid } = props;

    return (
        <form
            onSubmit={onSubmit(onExport)}
            className='export-classic-as-of-date-report-modal-content'>
            <div className='as-of-date-container'>
                <InputDatePickerField
                    labelType='inline'
                    dateOptions={dateOptions}/>
            </div>
            <div className='format-container'>
                <RadioButtonsListField
                    name='reportType'
                    list={items}/>
            </div>
            <div className='modal-actions'>
                <button
                    className='btn btn-large'
                    disabled={!valid}
                    type='submit'>
                    {formatMessage({ id: 'modals.exportClassicAsOfDateReport.submit.text' })}
                </button>
                <button
                    type='button'
                    onClick={hideModal}
                    className='btn btn-large'>
                    {formatMessage({ id: 'common.cancel' })}
                </button>
            </div>
            <div className='note'>
                {formatMessage({ id: 'modals.exportClassicAsOfDateReport.note' })}
            </div>
        </form>
    );
};

ExportClassicShareholdersReportView.propTypes = {
    intl: intlShape,
    onSubmit: PropTypes.func,
    valid: PropTypes.bool,
    items: PropTypes.array,
    dateOptions: PropTypes.object,
    onExport: PropTypes.func,
    hideModal: PropTypes.func
};

export default memo(ExportClassicShareholdersReportView);
