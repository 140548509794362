import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getContext } from '@shakacode/recompose';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import WidgetAddToReport from './WidgetAddToReport';
import WidgetChange from './WidgetChange';
import WidgetRemove from './WidgetRemove';
import featureTypes from 'Constants/feature-types';

const makeMapStateToProps = (initialState, { slotId }) => {
    const availableWidgetTypesSelector = dynamicPageSelectors.makeAvailableWidgetTypesSelector(slotId);

    return (state) => ({
        availableWidgets: availableWidgetTypesSelector(state, slotId),
        blockAction: dynamicPageSelectors.getDynamicPageBlockActionSelector(state)
    });
};

@getContext({
    events: PropTypes.object
})
@connect(makeMapStateToProps)
class WidgetControlPanelDefault extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        onChangeWidget: PropTypes.func,
        onRemoveWidget: PropTypes.func,
        onAddReport: PropTypes.func,
        isLocked: PropTypes.bool,
        isDataViz: PropTypes.bool,
        blockAction: PropTypes.bool,
        additionalControlPanel: PropTypes.element,
        availableWidgets: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                isSelected: PropTypes.bool.isRequired
            })
        ).isRequired
    };

    static defaultProps = {
        isPossibleAddToDataViz: true
    };

    render() {
        const {
            isLocked,
            blockAction,
            onChangeWidget,
            onRemoveWidget,
            availableWidgets,
            onAddWidgetToDataVizReport,
            events: { onBuildDataParams } = {},
            additionalControlPanel,
            isPossibleAddToDataViz,
            isDataViz,
            widgetType,
            currentFeatures
        } = this.props;

        return (
            <div className='widget-control-panel widget-control-panel-default'>
                {currentFeatures[featureTypes.CUSTOM_REPORTS] && isPossibleAddToDataViz && !isDataViz &&
                <WidgetAddToReport
                    onAddToReport={onAddWidgetToDataVizReport}
                    dataParamsBuilder={onBuildDataParams}/>}
                {additionalControlPanel}
                {!isDataViz
                    ? <WidgetChange
                        isLocked={isLocked}
                        blockAction={blockAction}
                        availableWidgets={availableWidgets}
                        onChangeWidget={onChangeWidget}
                        onRemoveWidget={onRemoveWidget}
                        slotProps={{
                            widget: widgetType
                        }}/>
                    : <WidgetRemove
                        blockAction={blockAction}
                        onRemoveWidget={onRemoveWidget}/>}
            </div>
        );
    }
}

export default WidgetControlPanelDefault;
