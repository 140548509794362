import React from 'react';
import { FormattedMessage } from 'react-intl';

import Tooltip from 'Components/Tooltip';
import { NullableFormatter } from 'Components/formatters';
import { UNIDENTIFIED } from 'Constants/undefined-values';

export const valueWithTooltipCell = v => {
    if (v.value === UNIDENTIFIED) {
        return (<Tooltip
            content={<FormattedMessage id='date.last.contact.column.tooltip.unidentified'/>}>
            <span>
                <NullableFormatter {...v} withTitle={false}/>
            </span>
        </Tooltip>);
    }
    return <NullableFormatter {...v} withTitle/>;
};
