import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Popover from './Popover';

class LazyPopover extends Component {
    static propTypes = {
        trigger: PropTypes.element.isRequired,
        children: PropTypes.object.isRequired
    };

    state = { isLoaded: false };

    applyPopover = () => {
        if (!this.state.isLoaded) {
            this.setState({ isLoaded: true });
        }
    };

    render() {
        return this.state.isLoaded ? (
            <Popover
                {...this.props}>
                {this.props.children}
            </Popover>
        ) : React.cloneElement(this.props.trigger, {
            onMouseOver: this.applyPopover,
            onClick: this.applyPopover
        });
    }
}

export default LazyPopover;
