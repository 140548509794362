import _ from 'lodash';

import * as api from '../../api/subscriptions';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';
export const CHANGE_SUBSCRIPTION_STATUS = 'CHANGE_SUBSCRIPTION_STATUS';
export const CHANGE_EMAIL_SUBSCRIPTION_STATUS = 'CHANGE_EMAIL_SUBSCRIPTION_STATUS';
export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION';

const AST_UPLOAD_SUBSCRIPTION_TYPE = 'AST_UPLOAD';

export const getSubscriptions = () => dispatch => {
    return api.getSubscriptions()
        .then(res => {
            const subscriptions = _.get(res, 'data.payload.subscriptions', []);

            dispatch({
                type: GET_SUBSCRIPTIONS,
                payload: subscriptions.filter(({ type }) => type !== AST_UPLOAD_SUBSCRIPTION_TYPE)
            });
        }, error => {
            dispatch({
                type: GET_SUBSCRIPTIONS_ERROR,
                payload: error
            });

            return Promise.reject(error);
        });
};

export const changeSubscriptionStatus = (type, isActive) => dispatch => {
    return api.changeSubscriptionStatus(type, isActive)
        .then(res => {
            const subscription = _.get(res, 'data.payload', {});

            dispatch({
                type: CHANGE_SUBSCRIPTION_STATUS,
                payload: subscription
            });

            return Promise.resolve(subscription);
        });
};

export const changeEmailSubscriptionStatus = (type, isSubscribedEmail) => dispatch => {
    return api.changeEmailSubscriptionStatus(type, isSubscribedEmail)
        .then(res => {
            const subscription = _.get(res, 'data.payload', {});

            dispatch({
                type: CHANGE_EMAIL_SUBSCRIPTION_STATUS,
                payload: subscription
            });

            return Promise.resolve(subscription);
        });
};

export const resetSubscriptions = () => {
    return {
        type: RESET_SUBSCRIPTION
    };
};
