import React from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'Components/Icon';
import LinkEx from 'Components/LinkEx';
import pageRoutes from 'Constants/page-routes';

import './AuthorizedUsersLink.scss';

const AuthorizedUsersLink = () => {
    return (
        <div className='authorized-users'>
            <Icon className='font-icon font-icon-authorized action-icon'/>
            <LinkEx
                activeClassName='active'
                to={pageRoutes.reportingAuthorizedUserList}
                className='authorized-users-link'>
                <FormattedMessage id='reporting.nav.authorizedUserList'/>
            </LinkEx>
        </div>
    );
};

export default AuthorizedUsersLink;
