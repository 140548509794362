import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

const SimpleMessage =  ({ messageKey, messageValues, signClass, className }) => (
    <FormattedMessage id={messageKey} values={messageValues}>
        {text => (
            <div className={cn('message-note', className, signClass ? signClass.className : null)}>{text}</div>
        )}
    </FormattedMessage>
);

SimpleMessage.propTypes = {
    messageKey: PropTypes.string.isRequired,
    signClass: PropTypes.object,
    messageValues: PropTypes.object,
    className: PropTypes.string
};

export default SimpleMessage;
