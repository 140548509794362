import React from 'react';
import { connect } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import PropTypes from 'prop-types';

import * as modalActions from 'State/modal/actions';

const UploadRequestPrompt = ({ when, saveChangesModal, hideModal, onSend }) => {
    const promptModal = ({ isActive, onCancel, onConfirm }) => {
        if (isActive) {
            saveChangesModal({
                titleKey: 'modals.uploadRequestPrompt.title',
                messageKey: 'modals.uploadRequestPrompt.message',
                okTextKey: 'common.send',
                cancelTextKey: 'common.cancel',
                onSuccess: () => {
                    hideModal();
                    onCancel();
                    onSend();
                },
                onCancel: onConfirm,
                onClose: () => {
                    hideModal();
                    onConfirm();
                }
            });
        }

        return null;
    };


    return (
        <NavigationPrompt when={when}>
            {promptModal}
        </NavigationPrompt>
    );
};

UploadRequestPrompt.propTypes = {
    when: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    saveChangesModal: PropTypes.func,
    hideModal: PropTypes.func,
    onSend: PropTypes.func
};

const mapDispatchToProps = {
    saveChangesModal: modalActions.saveChangesModal,
    hideModal: modalActions.hideModal
};

export default connect(undefined, mapDispatchToProps)(UploadRequestPrompt);
