import React, { Component } from 'react';

import { getException } from '../../api';

export default class FailPage extends Component {
    componentDidMount() {
        getException().catch(() => {});
    }

    render() {
        return (
            <div>Getting error...</div>
        );
    }
}

