import { createReducer } from 'Utils/reducer';
import {
    CHANGE_HOLDER_INVESTOR_TYPE_ANALYSIS_ROUTE_STATE,
    CHANGE_GEOGRAPHIC_ANALYSIS_ROUTE_STATE
} from './actions';

const initialState = {
    holderInvestorTypeAnalysis: {},
    geographicAnalysis: {}
};

const actionHandlers = {
    [CHANGE_HOLDER_INVESTOR_TYPE_ANALYSIS_ROUTE_STATE]: (state, { payload: { routeState } }) => ({
        ...state,
        holderInvestorTypeAnalysis: {
            ...state.holderInvestorTypeAnalysis,
            ...routeState
        }
    }),
    [CHANGE_GEOGRAPHIC_ANALYSIS_ROUTE_STATE]: (state, { payload: { routeState } }) => ({
        ...state,
        geographicAnalysis: {
            ...state.geographicAnalysis,
            ...routeState
        }
    })
};

export default createReducer(initialState, actionHandlers);
