import PropTypes from 'prop-types';
import React from 'react';

import { shareholderTypesOrder } from 'Constants/shareholder-types';
import Selector from 'Components/Selector';

import './ShareholdersTypes.scss';

const ShareholdersTypes = (props) => {
    const { values, currentValue, onChange, isFixedValue = false } = props;
    const options = values && values.length > 1
        ? shareholderTypesOrder
            .map(type => values.find(({ value }) => value === type))
            .filter(Boolean)
        : [];
    const handleSelectType = (type) => {
        if (type.value !== currentValue.value) {
            onChange(type.value);
        }
    };


    return (
        <div className='shareholders-types'>
            {isFixedValue || !values || values.length === 1
                ? <label className='shareholder-type-label' data-type={currentValue.value}>{currentValue.label}</label>
                : <Selector
                    name='shareholders-types'
                    value={currentValue}
                    options={options}
                    clearable={false}
                    searchable={false}
                    onOptionChange={handleSelectType}/>
            }
        </div>
    );
};

ShareholdersTypes.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    })),
    currentValue: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    }).isRequired,
    isFixedValue: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default ShareholdersTypes;
