import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { wrapDisplayName } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as userSelectors from 'State/user/selectors';

const MIN_INTERVAL = 5000;
const events = [
    'click',
    'focus',
    'change',
    'mousemove',
    'touchmove',
    'keyup',
    'scroll',
    'load'
];

const mapStateToProps = state => ({
    currentSecurity: userSelectors.getCurrentSecuritySelector(state),
    requestIntervals: userSelectors.getRequestIntervals(state)
});

export default (pollingFunction, typeOfFunction, defaultInterval) => (WrappedComponent) => {
    @connect(mapStateToProps)
    class Polling extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'polling');

        static propTypes = {
            currentSecurity: PropTypes.object,
            requestIntervals: PropTypes.object
        };

        componentDidMount() {
            if (window.E2E_TESTS_MODE) {
                this.startPolling();
            } else {
                events.map(e => window.addEventListener(e, this.throttle));
            }
        }

        componentWillUnmount() {
            if (window.E2E_TESTS_MODE) {
                this.stopPolling();
            } else {
                events.map(e => window.removeEventListener(e, this.throttle));
                this.throttle.cancel();
            }
        }

        isIntervalValid = (interval) => _.isNumber(interval) && interval >= MIN_INTERVAL;

        interval = this.isIntervalValid(this.props.requestIntervals[typeOfFunction])
            ? this.props.requestIntervals[typeOfFunction]
            : defaultInterval;

        throttle = _.throttle(() => {
            if (_.get(this.props, 'currentSecurity.id')) {
                pollingFunction(this.props);
            }
        }, this.interval);

        startPolling = () => {
            this.timerId = setTimeout(() => {
                this.throttle();
                this.startPolling();
            }, MIN_INTERVAL);
        };

        stopPolling = () => {
            this.timerId && clearTimeout(this.timerId);
        };

        render() {
            const newProps = _.omit(this.props, ['currentSecurity', 'requestIntervals']);

            return (
                <WrappedComponent {...newProps}/>
            );
        }
    }

    return Polling;
};
