import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { CheckBoxField } from 'Components/CheckBox';
import { RadioButtonsListField } from 'Components/RadioButtonsList';
import scheduleTypes from './constants/schedule-types';
import TextInput from '../TextInput';
import dayOfMonthNormalize from './utils';
import Popover from 'Components/Popover';
import Icon from 'Components/Icon';


const ReportSchedulerView = props => {
    const activeScheduleType = _.get(props.formValues, props.options.scheduleType.name);

    return (<div className='report-scheduler'>
        {props.withHeader &&
            <div className={props.collapsible ? 'shareholder-list-section-header' : 'header-section'}>
                {props.collapsible && <Popover
                    id='ShAREHOLDER-LIST-SECTION-POPOVER'
                    trigger={<Icon className='font-icon font-icon-info-outline' />}
                    triggerType={['click', 'hover']}
                    placement='top'>
                    <FormattedMessage id='shareholder.list.section.icon' />
                </Popover>}
                <FormattedMessage id={props.collapsible ? 'reporting.reportScheduler.editMode.header' : 'reporting.reportScheduler.header'}>
                    {message => <div className='report-scheduler-header'>{message}</div>}
                </FormattedMessage>
                {props.collapsible && <span
                    className={`font-icon font-icon-paging-right ${!props.collapsed && 'icon-rotate'}`}
                    onClick={props.toggleSchedulingExpansion} />}
            </div>
        }
        <div className={props.collapsible && props.collapsed && 'collapsed'}>
            <CheckBoxField
                name={props.options.isScheduled.name}
                onChange={props.onIsScheduledClick}
                label={props.options.isScheduled.label} />
            <RadioButtonsListField
                name={props.options.scheduleType.name}
                list={props.typeList}
                disabled={!props.formValues[props.options.isScheduled.name]}
                onChange={props.onScheduleTypeChange}
                className='schedule-type-list' />
            {_.get(props.formValues, props.options.scheduleType.name) &&
                <FormattedMessage id={`reporting.reportScheduler.infoMessage.${activeScheduleType}`}>
                    {message => <div className='report-scheduler-message'>{message}</div>}
                </FormattedMessage>
            }
            {activeScheduleType === scheduleTypes.WEEKLY &&
                <RadioButtonsListField
                    name={props.options.weekDayList.name}
                    list={props.weekDayList}
                    className='week-day-list' />
            }
            {activeScheduleType === scheduleTypes.MONTHLY &&
                <div className='month-day-container'>
                    <Field
                        className='month-input'
                        component={TextInput}
                        name={props.options.dayOfMonth.name}
                        label={props.options.dayOfMonth.label}
                        normalize={dayOfMonthNormalize}
                        disabled={props.formValues[props.options.isLastDayOfMonth.name]} />
                    <CheckBoxField
                        onChange={props.onIsLastDayOfMonthChange}
                        name={props.options.isLastDayOfMonth.name}
                        label={props.options.isLastDayOfMonth.label} />
                </div>
            }
            {props.formValues[props.options.isScheduled.name] &&
                <div className='export-format-container'>
                    <FormattedMessage id='reporting.reportScheduler.exportFormat.header'>
                        {message => <div className='export-format-header'>{message}</div>}
                    </FormattedMessage>
                    <RadioButtonsListField
                        className='export-format-list'
                        name={props.options.exportFormat.name}
                        list={props.exportFormatList} />
                </div>
            }
        </div>
    </div>);
};

ReportSchedulerView.propTypes = {
    options: PropTypes.object,
    formValues: PropTypes.object,
    onIsScheduledClick: PropTypes.func,
    typeList: PropTypes.array,
    weekDayList: PropTypes.array,
    exportFormatList: PropTypes.array,
    onScheduleTypeChange: PropTypes.func,
    onIsLastDayOfMonthChange: PropTypes.func,
    withHeader: PropTypes.bool,
    collapsible: PropTypes.bool,
    collapsed: PropTypes.bool,
    toggleSchedulingExpansion: PropTypes.func
};

export default memo(ReportSchedulerView);
