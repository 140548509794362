import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import widgetModes from 'Constants/widget-modes';
import ShareholderAnalysisTableView from './ShareholderAnalysisTableView';
import ShareholderAnalysisChartView from './ShareholderAnalysisChartView';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { labelCell } from './cellRenderer';

const ShareholderAnalysisContent = (props) => {
    const { widgetNoData, isFetching } = props;
    const mode = _.get(props, 'widgetSettings.mode', widgetModes.CHART);

    return (
        <div className='widget-content'>
            <NoDataComponent
                isFetching={isFetching}
                isNoData={widgetNoData}>
                { mode === widgetModes.CHART
                    ? <ShareholderAnalysisChartView {...props} />
                    : <ShareholderAnalysisTableView {...props} /> }
            </NoDataComponent>
        </div>
    );
};

ShareholderAnalysisContent.propTypes = {
    ...commonWidgetActions,
    ...commonWidgetPropTypes
};

export default ({ primaryDataKey, l10nPrefix }) => compose(
    injectIntl,
    mapProps(props => {
        const { widgetNoData, widgetData, intl: { formatMessage } } = props;
        const chartView = _.get(widgetData, 'chartView');
        let pieData;
        let columns;

        if (!widgetNoData) {
            pieData = chartView.values.map(value => ({
                name: value[primaryDataKey],
                y: value.os,
                color: value.color,
                tooltipValue: value.os
            }));
            columns = [
                {
                    dataKey: primaryDataKey,
                    title: formatMessage({ id: `shareholder.analysis.top10.${l10nPrefix}.primary` }),
                    bodyCellComponent: labelCell
                },
                {
                    dataKey: 'os',
                    title: formatMessage({ id: `shareholder.analysis.top10.${l10nPrefix}.os` }),
                    columnClassName: 'text-right',
                    bodyCellComponent: TotalValueOsCell,
                    maxWidth: 70
                }
            ];
        }

        return { ...props, pieData, columns };
    })
)(ShareholderAnalysisContent);
