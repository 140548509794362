import React, { Component } from 'react';
import { mapProps } from '@shakacode/recompose';
import { intlShape } from 'react-intl';
import _ from 'lodash';
import ReactTable from 'react-table';

import { CellWithNumber, HeadingCellCommon, CellWithPercent } from 'Components/widgets/_commonComponents/TableCellComponents';
import sortDirections from 'Constants/sort-directions';
import columnDefinitions, { columnNames } from '../../constants/columnDefinitions';
import { getSortDirection } from 'Components/Tables/utils';
import { commonWidgetPropTypes, commonWidgetActions } from '../../../utils';
import { DECIMAL_POINTS, ALIGN_RIGHT, SIDE_RESULTS, WITH_TITLE } from '../../constants/constantValues';
import CellWithShareRange from '../../components/CellWithShareRange';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import { sortArray } from '../../../../../utils/utils';

const NO_ROUND_VALUES = {
    [columnNames.PERCENT_OF_ACCOUNTS]: columnNames.PERCENT_OF_ACCOUNTS_NO_ROUND,
    [columnNames.PERCENT_OF_SHARES]: columnNames.PERCENT_OF_SHARES_NO_ROUND
};

@mapProps(props => {
    const tableData = _.get(props, 'widgetData.tableData', []);

    return {
        ...props,
        tableData: _.map(tableData, (row) => {
            let start = row.start;
            let endBySort = row.end;

            if (row.number === SIDE_RESULTS.UNDER) {
                start = props.intl.formatMessage({ id: 'reporting.share.range.analysis.results.under' });
            } else if (row.number === SIDE_RESULTS.OVER) {
                start = props.intl.formatMessage({ id: 'reporting.share.range.analysis.results.over' });
                endBySort = row.end + 1; // Due to ASTTSTRT-50947. Removing this magic will broke sorting in IE 11+
            }

            return {
                ...row,
                shareRange: {
                    start,
                    end: row.end
                },
                endBySort
            };
        })
    };
})
class ShareRangeAnalysisResultsContent extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    handleSort = (sortProperties) => {
        const {
            setSortCriteria,
            sortCriteria: { field, direction } = {}
        } = this.props;

        setSortCriteria({
            field: sortProperties.id,
            direction: getSortDirection(
                sortProperties.id,
                field,
                direction,
                columnDefinitions.columns
            ),
            initial: false
        });
    };

    getCustomCellComponents = (column) => {
        const { intl: { formatMessage } } = this.props;

        const columnName = column.columnName;

        switch (columnName) {
            case columnNames.SHARE_RANGE:
                return {
                    bodyCellComponent: ({ value }) => <CellWithShareRange value={value}/>,
                    headingCellComponent: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>
                };
            case columnNames.PERCENT_OF_ACCOUNTS:
            case columnNames.PERCENT_OF_SHARES:
                return {
                    bodyCellComponent: ({ value }) => <CellWithPercent value={value}/>,
                    headingCellComponent: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>
                };
            default:
                return {
                    bodyCellComponent: (props) => <CellWithNumber value={props.value}/>,
                    headingCellComponent: () => <HeadingCellCommon title={formatMessage({ id: column.displayName })}/>
                };
        }
    };

    getThProps = (finalState, missProp, column) => {
        const { sortCriteria: { field, direction } } = this.props;

        return {
            sortProperty: column.id === field ? { sortAscending: direction === sortDirections.ASC } : null
        };
    };

    getTdProps = (finalState, rowInfo, column) => {
        return {
            additionalData: {
                decimalPoints: DECIMAL_POINTS[column.id],
                alignRight: ALIGN_RIGHT[column.id],
                withTitle: WITH_TITLE[column.id],
                titleValue: _.includes([columnNames.PERCENT_OF_ACCOUNTS, columnNames.PERCENT_OF_SHARES], column.id)
                    ? _.get(rowInfo, `row.${NO_ROUND_VALUES[column.id]}`)
                    : null
            }
        };
    };

    getFooterValue = (columnName) => {
        const { widgetData: { tableAggregate = {} } = {}, intl: { formatMessage, formatNumber } } = this.props;

        switch (columnName) {
            case columnNames.SHARE_RANGE:
                return formatMessage({ id: 'reporting.share.range.analysis.total' }).toUpperCase();
            case columnNames.NUMBER_OF_SHARES:
                return formatNumber(tableAggregate.numberOfSharesTotal, {
                    minimumFractionDigits: DECIMAL_POINTS[columnNames.NUMBER_OF_SHARES]
                });
            case columnNames.NUMBER_OF_ACCOUNTS:
                return formatNumber(tableAggregate.numberOfAccountsTotal);
            default:
                return null;
        }
    };

    getNoDataProps = () => ({
        messageId: 'widgets.noDataMessage.matchingShares'
    });

    sortValues = (array, field, descending, initial) => {
        let primaryField;
        const endKey = `${columnNames.SHARE_RANGE}.end`;
        // const primaryField = field === columnNames.SHARE_RANGE ? endKey : field;

        switch (field) {
            case columnNames.SHARE_RANGE: {
                primaryField = 'endBySort';
                break;
            }
            case columnNames.PERCENT_OF_ACCOUNTS: {
                primaryField = columnNames.PERCENT_OF_ACCOUNTS_NO_ROUND;
                break;
            }
            case columnNames.PERCENT_OF_SHARES: {
                primaryField = columnNames.PERCENT_OF_SHARES_NO_ROUND;
                break;
            }
            default: {
                primaryField = field;
                break;
            }
        }

        return sortArray({
            array,
            primaryField,
            descending,
            secondaryField: endKey,
            secondaryDescending: false,
            initial
        });
    };

    render() {
        const { tableData = [] } = this.props;
        const { sortCriteria: { field, direction, initial } } = this.props;

        const sortedData = this.sortValues(tableData, field, direction === sortDirections.DESC, initial);

        return (
            <div className='reporting-share-range-analysis-results-table'>
                <ReactTable
                    showPagination={false}
                    resizable={false}
                    columns={(this.props.columns || []).map(column => {
                        const customComponents = this.getCustomCellComponents(column);

                        return ({
                            Header: customComponents.headingCellComponent,
                            Cell: customComponents.bodyCellComponent,
                            accessor: column.columnName,
                            minWidth: column.columnName === 'shareRange' ? 2 : 1,
                            Footer: !!sortedData.length
                                ? <span>{this.getFooterValue(column.columnName)}</span>
                                : null
                        });
                    })}
                    onSortedChange={(sortProps) => this.handleSort(sortProps[0])}
                    className='shareholders-table-rt'
                    LoadingComponent={() => null}
                    manual
                    defaultPageSize={0}
                    TdComponent={TdComponent}
                    ThComponent={TheadComponent}
                    getTheadThProps={this.getThProps}
                    getTdProps={this.getTdProps}
                    getNoDataProps={this.getNoDataProps}
                    NoDataComponent={NoResults}
                    resolvedData={sortedData}/>
            </div>
        );
    }
}

export default ShareRangeAnalysisResultsContent;
