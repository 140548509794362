import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';

export const getLaunchedReports = () => {
    const requestData = utils.buildRequest({}, requestTypes.getLaunchedReportsPayload);

    return axios.post(apiRoutes.getLaunchedReportsUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false,
        isRetryable: false,
        withoutRefreshSession: true
    });
};

export const deleteLaunchedReport = (reportId, reportType) => {
    const requestData = utils.buildRequest({ reportId, reportType }, requestTypes.deleteLaunchedReportPayload);

    return axios.post(apiRoutes.deleteLaunchedReportUrl, requestData, {
        isCancelable: false
    });
};

export const runReport = (reportTemplateType, reportTemplateId, format, form) => {
    const requestData = utils.buildRequest({
        reportTemplateType,
        report: {
            reportTemplateId,
            format,
            form
        }
    }, requestTypes.runReportPayload);

    return axios.post(apiRoutes.runReportUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};

/**
 * Request for launched report Guid
 * @param {number} [reportId] - report id
 * @param {string} subscriptionId - subscription id for subscriptions (ASTTSTRT-56871)
 */
export const getLaunchedReportGuid = (reportId, subscriptionId = null) => {
    const requestData = utils.buildRequest({ reportId, subscriptionId }, requestTypes.getLaunchedReportGuidPayload);

    return axios.post(apiRoutes.getLaunchedReportGuidUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};

export const getReportStatus = (reportId) => {
    const requestData = utils.buildRequest({ reportId }, requestTypes.getReportStatusPayload);

    return axios.post(apiRoutes.getReportStatusUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};

export const runAsOfDateReport = (isSummary, asOfDate) => {
    const requestData = utils.buildRequest({
        isSummaryOnly: isSummary,
        asOfDate: asOfDate || ''
    }, requestTypes.requestAsOfDateReportPayload);

    return axios.post(apiRoutes.requestAsOfDateReportUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};
