import dataSource from './dataSource/reducer';
import modal from './modal/reducer';
import loadingBlock from './loadingBlock/reducer';
import widgets from './widgets/reducer';
import dynamicPage from './dynamicPage/reducer';
import helpPage from './helpPage/reducer';
import reportingShareDetail from './reportingShareDetail/reducer';
import reportingControlBooks from './reportingControlBooks/reducer';
import reportingShareholderList from './reportingShareholderList/reducer';
import reportingDocuments from './reportingDocuments/reducer';
import countriesAndStates from './countriesAndStates/reducer';
import compareShareholders from './compareShareholders/reducer';
import exportedReports from './exportReports/reducer';
import savedReports from './savedReportTemplates/reducer';
import shareholderTable from './shareholderTable/reducer';
import user from './user/reducer';
import securities from './securities/reducer';
import features from './features/reducer';
import peers from './peers/reducer';
import routeState from './routeState/reducer';
import appVersion from './appVersion/reducer';
import routes from './routes/reducer';
import analysisShareholders from './analysisShareholders/reducer';
import issuers from './issuers/reducer';
import advancedSearch from './advancedSearch/reducer';
import resources from './resources/reducer';
import subscriptions from './subscriptions/reducer';
import marketData from './marketData/reducer';
import uploadDocument from './uploadDocument/reducer';
import alerts from './alerts/reducer';
import userSecureUpload from './userSecureUpload/reducer';
import shareholderWatchlist from './watchlist/reducer';
import recentSearch from './recentSearch/reducer';

export default {
    dynamicPage,
    helpPage,
    loadingBlock,
    modal,
    dataSource,
    widgets,
    reportingShareDetail,
    reportingControlBooks,
    reportingShareholderList,
    reportingDocuments,
    countriesAndStates,
    compareShareholders,
    exportedReports,
    savedReports,
    shareholderTable,
    user,
    securities,
    features,
    peers,
    routeState,
    appVersion,
    routes,
    analysisShareholders,
    issuers,
    advancedSearch,
    resources,
    subscriptions,
    marketData,
    uploadDocument,
    alerts,
    userSecureUpload,
    shareholderWatchlist,
    recentSearch
};
