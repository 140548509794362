import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';
import httpStatus from 'Constants/http-status';

const getPageRequest = (requestUrl, requestType) =>
    (pageId, params = {}, { redirect404ToHome, withoutNotification } = {}) => {
        const payload = {
            page: {
                pageId,
                params
            }
        };
        const requestData = utils.buildRequest(payload, requestType, { operationId: pageId });

        return axios.post(requestUrl, requestData, {
            errorStrategy: (error, responseStatus, errOptions = {}) => {
                if (errOptions.isDataViz === true) {
                    return errorStrategies.CRITICAL;
                } else if (responseStatus === httpStatus.HTTP_403
                    || responseStatus === httpStatus.HTTP_400
                    || (responseStatus === httpStatus.HTTP_404 && redirect404ToHome)) {
                    return errorStrategies.REDIRECT_HOME;
                }

                return errorStrategies.CRITICAL;
            },
            withoutNotification,
            isCancelable: true
        });
    };

export const getPage = getPageRequest(apiRoutes.getPageUrl, requestTypes.fullPagePayload);
export const getDataVizPage = getPageRequest(apiRoutes.getDataVizPageUrl, requestTypes.getDataVizPayload);
