/* eslint-disable react/no-multi-comp*/
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import cn from 'classnames';

import { LabelWithTooltip, NullableFormatter, NumberFormatter } from 'Components/formatters';
import Tooltip from 'Components/Tooltip';

export const labelCell = ({ value, className, rowData: { className: dataClassName } = {} } = {}) => (
    <LabelWithTooltip
        value={NullableFormatter({ value })}
        className={cn(className, dataClassName)}/>
);

labelCell.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    rowData: PropTypes.object
};

export const unmarkedLabelCell = ({ value, className } = {}) => (
    <LabelWithTooltip value={NullableFormatter({ value })} className={className}/>
);

unmarkedLabelCell.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string
};

export const numberCell = cellProps => (<NumberFormatter value={cellProps.value} withTitle/>);

export const getHeaderCellComponent = (column, level) => (cellProps) => (<Tooltip
    content={<FormattedHTMLMessage id={`tax.certification.status.tooltip.${column}.${level}`}/>}>
    <span>{cellProps.title}</span>
</Tooltip>);
