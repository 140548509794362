import React, { Component } from 'react';
import * as _ from 'lodash';
import { withProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { commonWidgetPropTypes, commonWidgetActions, getManageableColumns } from 'Components/widgets/utils';
import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import columnNames from 'Constants/registered-table-column-names';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import { registeredOverview } from '../_commonComponents/RegisteredShareholderTable/hoc';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import { getFullName } from 'Utils/utils';

import './RegisteredShareholderDocuments.scss';

const mapStateToProps = state => ({
    currentFeatures: getCurrentFeaturesSelector(state)
});

@withProps(props => ({
    data: {
        shareholderId: _.get(props, 'widgetPageParams.shareholderId'),
        tableData: _.get(props, 'widgetData', []).map(
            document => ({
                ...document,
                author: document.authorFirstName
                    ? getFullName(document.authorFirstName, document.authorLastName)
                    : document.authorLastName,
                download: {
                    documentId: document.documentId,
                    hasUrl: document.hasUrl
                }
            }))
    }
}))
@registeredOverview
@connect(mapStateToProps)
class RegisteredShareholderDocuments extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        currentFeatures: PropTypes.object
    };

    getTdProps = (finalState, rowInfo, column) => {
        if (column.id === columnNames.DOWNLOAD) {
            return {
                additionalData: {
                    shareholderId: _.get(this.props, 'data.shareholderId')
                }
            };
        } else if (column.id === columnNames.DELETE) {
            const shareholderId = _.get(this.props, 'data.shareholderId');

            return {
                additionalData: {
                    shareholderId,
                    onDeleteDocument: () => this.props.onUpdateWidgetData({ shareholderId })
                }
            };
        }
    };

    render() {
        const { data } = this.props;

        return (
            <RegisteredShareholderTable
                {...this.props}
                className='registered-shareholder-documents'
                data={data.tableData}
                getTdProps={this.getTdProps}
                columnsDefinitions={columnsDefinitions}
                secondarySorting={secondarySorting}
                exportAvailable={false}
                manageableColumns={getManageableColumns(columnsDefinitions)}
                disableValidation/>
        );
    }
}

export default RegisteredShareholderDocuments;
