import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage } from 'react-intl';

import { colorizeValues } from 'Utils/colorize';
import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import { stickyFooter } from 'Components/Tables/TableEasy';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { labelCell, numberCell, headerCell } from './cellRenderers';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';
import { getMarkedData, markIncludesCeDeCoRows } from 'Components/widgets/utils';

const HEADER_ROW_HEIGHT = 24;

const CorporateShareholdersChartView = ({ pieData, legendData, intl }) => (
    <div>
        <div className='pie-content-container'>
            <PieChart
                data={pieData}
                customConfig={getPieTooltipConfig(intl)}
                total={legendData.aggregate.sharesPercentTotal}/>
        </div>
        <div className='legend-container'>
            <LegendGrid
                footerComponent={stickyFooter}
                headerRowHeight={HEADER_ROW_HEIGHT}
                {...legendData}/>
        </div>
    </div>
);

CorporateShareholdersChartView.propTypes = {
    pieData: PropTypes.array,
    legendData: PropTypes.object,
    intl: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { data, widgetFooterProps, colorSchemeId, intl: { formatMessage } } = props;
        const aggregate = _.get(data, 'aggregate');
        const chartValues = _.get(data, 'data');
        const colorizedValues = colorizeValues(chartValues, colorSchemeId);

        const pieData = colorizedValues.map(value => ({
            name: value.accountType,
            y: value.sharesPercent,
            tooltipValue: value.sharesPercent,
            color: value.color
        }));
        const legendData = {
            columns: [{
                dataKey: 'accountType',
                maxWidth: 200,
                headerCellComponent: () => headerCell(
                    formatMessage({ id: 'corporate.shareholders.accountTitle' }),
                    <FormattedHTMLMessage id='corporate.shareholders.tooltip.accountTitle'/>
                ),
                bodyCellComponent: labelCell
            }, {
                dataKey: 'shareholdersNumber',
                minWidth: 140,
                columnClassName: 'text-right',
                title: formatMessage({ id: 'corporate.shareholders.shareholdersNumber' }),
                bodyCellComponent: numberCell
            }, {
                dataKey: 'sharesPercent',
                columnClassName: 'text-right',
                title: formatMessage({ id: 'corporate.shareholders.sharesPercent' }),
                bodyCellComponent: TotalValueOsCell
            }],
            data: getMarkedData(colorizedValues, [markIncludesCeDeCoRows], widgetFooterProps),
            aggregate
        };

        return { pieData, legendData, intl: props.intl };
    })
)(CorporateShareholdersChartView);
