import _ from 'lodash';

export const settingsBuilder = (pageId, settings, userFeatures) => {
    const availableDefaultViews = {};
    const selectedDefaultView = settings.views.defaultViews[settings.views.currentViewId];

    _.forEach(settings.views.defaultViews, (viewValue, viewKey) => {
        if (userFeatures[viewValue.featureType]) {
            availableDefaultViews[viewKey] = viewValue;
        }
    });

    const mappedSettings = {
        ...settings,
        views: {
            ...settings.views,
            defaultViews: availableDefaultViews
        }
    };

    if (selectedDefaultView && !userFeatures[selectedDefaultView.featureType]) { // if selected unavailable view
        mappedSettings.views.currentViewId = Object.keys(availableDefaultViews)[0]; // select first from available
    }

    return mappedSettings;
};
