import { connect } from 'react-redux';

import DownloadsMessageNote from './DownloadsMessageNote';
import { getReportingDownloadKeepReport } from 'State/user';
import { activeSubscriptionsSelector } from 'State/subscriptions';

const mapStateToProps = state => ({
    reportsStoragePeriod: getReportingDownloadKeepReport(state),
    activeSubscriptions: activeSubscriptionsSelector(state)
});

export default connect(mapStateToProps)(DownloadsMessageNote);
