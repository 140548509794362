import {
    RESOURCES_REQUEST,
    RESOURCES_SUCCESS,
    RESOURCES_FAILURE,
    DOCUMENT_DOWNLOAD_REQUEST,
    DOCUMENT_DOWNLOAD_SUCCESS,
    DOCUMENT_DOWNLOAD_ERROR
} from './actions';
import { createReducer } from 'Utils/reducer';

const initialState = {
    columns: [],
    isFetching: false,
    isDownloadFetching: false,
    error: null
};

const resourcesActionHandlers = {
    [RESOURCES_REQUEST]: state => ({
        ...state,
        error: null,
        isFetching: true
    }),
    [RESOURCES_SUCCESS]: (state, { payload }) => ({
        ...state,
        error: null,
        isFetching: false,
        ...payload
    }),
    [RESOURCES_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload,
        isFetching: false
    }),
    [DOCUMENT_DOWNLOAD_REQUEST]: state => ({
        ...state,
        isDownloadFetching: true
    }),
    [DOCUMENT_DOWNLOAD_SUCCESS]: state => ({
        ...state,
        isDownloadFetching: false
    }),
    [DOCUMENT_DOWNLOAD_ERROR]: (state, { payload }) => ({
        ...state,
        error: payload,
        isDownloadFetching: false
    })
};

export default createReducer(initialState, resourcesActionHandlers);
