export default {
    shareholderListTemplate: 'shareholderListTemplate',
    shareholderTableTemplate: 'shareholderTableTemplate',
    proxyShareholderTemplate: 'proxyShareholderTemplate',
    holderAndInvestorTypeAnalysisTemplate: 'holderAndInvestorTypeAnalysisTemplate',
    shareRangeAnalysisTemplate: 'shareRangeAnalysisTemplate',
    geographicAnalysisTemplate: 'geographicAnalysisTemplate',
    shareDetailTemplate: 'shareDetailTemplate',
    dataVizTemplate: 'dataVizTemplate',
    dataVizStartedTemplate: 'dataVizStartedTemplate',
    dataVizTemp: 'dataVizTemp',
    contactsTableTemplate: 'contactsTableTemplate',
    institutionsTableTemplate: 'institutionsTableTemplate',
    controlBooksTemplate: 'controlBooksTemplate',
    peerAnalysisTemplate: 'peerAnalysisTemplate',
    historicalAnalysisTemplate: 'historicalAnalysisTemplate',
    registeredShareholder1099MiscTemplate: '1099MiscTemplate',
    registeredShareholderTransactionHistoryTemplate: 'transactionHistoryTemplate',
    registeredShareholderPaymentsTemplate: 'paymentsTemplate',
    registeredShareholder1099DIVTemplate: '1099DIVTemplate',
    registeredShareholder1099INTTemplate: '1099INTTemplate',
    registeredShareholder1099BTemplate: '1099BTemplate',
    registeredShareholderDRDSPPTemplate: 'DR/DSPPTemplate',
    registeredShareholder1042STemplate: '1042STemplate',
    registeredShareholderPriorTADRSHistoryTemplate: 'priorTADRSHistoryTemplate',
    registeredShareholderClosedSaleTypeTemplate: 'closedSaleTypeTemplate',
    registeredShareholderClosedOtherTypeTemplate: 'closedOtherTypeTemplate',
    registeredShareholderInformation: 'shareholderInfoTemplate',
    registeredShareholderCurrentTemplate: 'registeredShareholderCurrentTemplate',
    performanceTableTemplate: 'performanceTableTemplate',
    compareShareholders1099MiscTemplate: 'compare1099MiscTemplate',
    compareShareholdersTransactionHistoryTemplate: 'compareTransactionHistoryTemplate',
    compareShareholders1099DIVTemplate: 'compare1099DIVTemplate',
    compareShareholders1099INTTemplate: 'compare1099INTTemplate',
    compareShareholders1099BTemplate: 'compare1099BTemplate',
    compareShareholdersDRDSPPTemplate: 'compareDRDSPPTemplate',
    compareShareholders1042STemplate: 'compare1042STemplate',
    compareShareholdersPaymentsTemplate: 'comparePaymentsTemplate',
    compareShareholdersAccountInfoTemplate: 'compareAccountInfoTemplate',
    classicShareholderListTemplate: 'classicShareholderListTemplate',
    authorizedUserListTemplate: 'authorizedUserListTemplate',
    totalOutstandingSharesTemplate: 'totalOutstandingSharesTemplate',
    notesTemplate: 'notesTemplate',
    proxyContactTemplate: 'proxyContactTemplate',
    watchlistTemplate: 'watchlistTemplate'
};
