import NullComponent from 'Components/widgets/Widget/components/NullComponent';
import * as cellComponents from 'Components/widgets/_commonComponents/TableCellComponents';
import columnDefinitions from '../constants/columnDefinitions';
import ShareholderTypeIcon from '../components/ShareholderTypeIcon';
import DescriptionCell from '../components/DescriptionCell';

const columnNames = columnDefinitions.columnNames;
const SHARES_DECIMAL_POINTS = 3;

export const getCustomComponent = (columnType) => {
    switch (columnType) {
        case columnNames.TYPE:
            return {
                customComponent: ShareholderTypeIcon,
                customHeadingComponent: NullComponent
            };
        case columnNames.DESCRIPTION:
            return {
                customComponent: DescriptionCell,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.OS:
            return {
                customComponent: cellComponents.CellWithPercent,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.NUMBER_OF_ACCOUNTS:
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        case columnNames.SHARES:
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellCommon,
                additionalData: {
                    decimalPoints: SHARES_DECIMAL_POINTS
                }
            };
        default:
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
    }
};
