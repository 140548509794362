import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippy.js/react';

import './Tooltip.scss';

const Tooltip = ({ children, ...rest }) => (
    <Tippy {...rest}>{children}</Tippy>
);

Tooltip.defaultProps = {
    duration: 0,
    delay: [300, 0],
    theme: 'ast-tooltip',
    trigger: 'mouseenter focus',
    a11y: false,
    animateFill: false,
    performance: true
};

Tooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

export default Tooltip;
