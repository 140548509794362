import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { branch, mapProps, renderNothing } from '@shakacode/recompose';

import CheckBox from 'Components/CheckBox/CheckBox';
import {
    isSelectedShareholderToCompare,
    toggleShareholderToCompare
} from 'State/advancedSearch';
import { CONTEXTS } from 'Constants/search';


const mapStateToProps = (state, { shareholderId, securityId }) => ({
    checked: isSelectedShareholderToCompare(state)({ shareholderId, securityId })
});

const mapDispatchToProps = { toggleShareholderToCompare };

@mapProps(({ original: { shareholderId, securityId, contextType } }) => ({
    shareholderId, securityId, contextType
}))
@branch(
    ({ contextType }) => contextType !== CONTEXTS.REGISTERED,
    renderNothing
)
@connect(mapStateToProps, mapDispatchToProps)
class RegisteredCheckbox extends Component {
    static propTypes = {
        shareholderId: PropTypes.string,
        securityId: PropTypes.number,
        checked: PropTypes.bool,
        toggleShareholderToCompare: PropTypes.func
    };

    handleChange = () => {
        this.props.toggleShareholderToCompare({ shareholderId: this.props.shareholderId, securityId: this.props.securityId });
    };

    render() {
        const { checked } = this.props;

        return (
            <div className='registered-checkbox'>
                <CheckBox checked={checked} onChange={this.handleChange}/>
            </div>
        );
    }
}

export default RegisteredCheckbox;
