import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { wrapDisplayName } from '@shakacode/recompose';

const withFormDatePicker = WrappedComponent => class extends Component {
    static displayName = wrapDisplayName(WrappedComponent, 'withFormDatePicker');

    static propTypes = {
        ...WrappedComponent.propTypes,
        dateOptions: PropTypes.shape({
            label: PropTypes.string,
            placeholder: PropTypes.string,
            name: PropTypes.string
        }),
        input: PropTypes.shape(fieldInputPropTypes),
        meta: PropTypes.shape(fieldMetaPropTypes)
    };

    render() {
        const { meta, input, ...restProps } = this.props;
        const error = meta.touched && !meta.active && meta.error;

        return (
            <WrappedComponent
                {...input}
                {...restProps}
                error={error}/>
        );
    }
};

export default withFormDatePicker;
