import reportFormats from './export-report-formats';

const fileMIMETypes = {
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PDF: 'application/pdf',
    CSV: 'text/csv',
    RTF: 'application/rtf',
    RTFD: 'text/rtf',
    TXT: 'text/plain'
};

export const fileMIMETypesMap = [
    fileMIMETypes.DOC,
    fileMIMETypes.DOCX,
    fileMIMETypes.XLS,
    fileMIMETypes.XLSX,
    fileMIMETypes.PDF,
    fileMIMETypes.CSV,
    fileMIMETypes.RTF,
    fileMIMETypes.RTFD,
    fileMIMETypes.TXT
];

export const fileTypesString = fileMIMETypesMap.join(',');

export const fileExtensionsMap = [
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.txt',
    '.pdf',
    '.csv',
    '.rtf'
];

export default {
    [reportFormats.PDF]: 'PDF',
    [reportFormats.EXCEL]: 'Excel',
    [reportFormats.ZIP]: 'ZIP',
    [reportFormats.WORD]: 'Word',
    [reportFormats.TXT]: 'TXT'
};
