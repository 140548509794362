import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import LinkEx from 'Components/LinkEx';
import Icon from 'Components/Icon';
import Popup from 'Components/Popup';
import pageRoutes from 'Constants/page-routes';

import './StartedDataVizReport.scss';

const StartedDataVizReportView = ({ onEdit, onDelete, isActive }) => (
    <div className='started-dataviz-report'>
        <LinkEx className='started-dataviz-link' activeClassName='active' to={pageRoutes.dataViz}>
            <FormattedMessage id='reporting.nav.dataVizStarted'/>
        </LinkEx>
        <div className='report-action-icons'>
            <Popup
                content={<FormattedMessage id='common.icon.tooltip.font-icon-edit'/>}>
                <Icon
                    className={cn('font-icon', 'font-icon-edit', 'action-icon', { inactive: isActive })}
                    onClick={onEdit}
                    withoutTitle/>
            </Popup>
            <Popup
                content={<FormattedMessage id='common.icon.tooltip.font-icon-delete'/>}>
                <Icon
                    className='font-icon font-icon-delete action-icon'
                    onClick={onDelete}
                    withoutTitle/>
            </Popup>
        </div>
    </div>
);

StartedDataVizReportView.propTypes = {
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isActive: PropTypes.bool
};

export default StartedDataVizReportView;
