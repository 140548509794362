import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

import Icon from 'Components/Icon';

const PageSelector = ({
    currentPage,
    numberOfPages,
    selectedPage,
    onChangeSelectedPage,
    onClickFirstPage,
    onClickPrevPage,
    onClickNextPage,
    onClickLastPage,
    onBlur,
    onSubmit
}) => {
    const inputPage = _.isString(selectedPage) ? selectedPage : selectedPage.toString();
    const prevIconInactiveClass = cn({ 'inactive': currentPage === 1 });
    const nextIconInactiveClass = cn({ 'inactive': currentPage === numberOfPages });

    return numberOfPages > 1 && (
        <div className='page-selector'>
            <div className='icon-pagination-container'>
                <Icon
                    className={cn('font-icon font-icon-double-paging-left icon-pagination', prevIconInactiveClass)}
                    onClick={onClickFirstPage}/>
                <Icon
                    className={cn('font-icon font-icon-paging-left icon-pagination icon-pagination-offset', prevIconInactiveClass)}
                    onClick={onClickPrevPage}/>
            </div>
            <form
                onSubmit={onSubmit}
                className='page-selector-form'>
                <span className='pagination-text'>
                    <FormattedMessage id='pagination.page'/>:
                </span>
                <input
                    type='text'
                    value={inputPage}
                    onChange={onChangeSelectedPage}
                    onBlur={onBlur}
                    className='page-selector-input'/>
                <span className='pagination-text'>
                    <FormattedMessage
                        id='pagination.numberOfPages'
                        values={{ numberOfPages }}/>
                </span>
            </form>
            <div className='icon-pagination-container'>
                <Icon
                    className={cn('font-icon font-icon-paging-right icon-pagination icon-pagination-offset', nextIconInactiveClass)}
                    onClick={onClickNextPage}/>
                <Icon
                    className={cn('font-icon font-icon-double-paging-right icon-pagination', nextIconInactiveClass)}
                    onClick={onClickLastPage}/>
            </div>
        </div>
    );
};

PageSelector.propTypes = {
    currentPage: PropTypes.number.isRequired,
    numberOfPages: PropTypes.number,
    selectedPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChangeSelectedPage: PropTypes.func,
    onClickFirstPage: PropTypes.func,
    onClickPrevPage: PropTypes.func,
    onClickNextPage: PropTypes.func,
    onClickLastPage: PropTypes.func,
    onBlur: PropTypes.func,
    onSubmit: PropTypes.func
};

export default PageSelector;
