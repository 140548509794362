import _ from 'lodash';

import * as api from '../../api';

export const RECENT_SEARCH_LOADING = 'RECENT_SEARCH_LOADING';
export const RECENT_SEARCH_ERROR = 'RECENT_SEARCH_ERROR';
export const RECENT_SEARCH_LOADED = 'RECENT_SEARCH_LOADED';

export const getRecentSearch = () => dispatch => {
    dispatch({ type: RECENT_SEARCH_LOADING });

    return api.getRecentSearch().then(({ data }) => {
        dispatch({ type: RECENT_SEARCH_LOADED, payload: _.get(data, 'payload.shareholders', []) });
    }).catch((e) => {
        dispatch({ type: RECENT_SEARCH_ERROR, payload: e });
    });
};

export const addAccount = ({ shareholderType, shareholderName, shareholderId }) => () => {
    return api.addAccountToRecentSearch({ shareholderType, shareholderName, shareholderId })
        .catch(() => {});
};
