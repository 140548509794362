import React from 'react';
import PropTypes from 'prop-types';

import DownloadsPanel from './components/DownloadsPanel';

import './DownloadsLayout.scss';

const ReportingLayout = props => (
    <div className='reporting-page empty-space-container'>
        <DownloadsPanel/>
        <div className='reporting-content'>
            {props.children}
        </div>
    </div>
);

ReportingLayout.propTypes = {
    children: PropTypes.element
};

export default ReportingLayout;
