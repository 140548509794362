import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { periodTypes } from '../constants/config';

import './PeriodRange.scss';

const PeriodRangeView = ({ small, onSelect, selectedPeriod }) => {
    const componentClass = cn('period-range', { small });
    const isActivePeriod = (periodType) => periodType === selectedPeriod;
    const getPeriodClass = (periodType) => cn('period', { active: isActivePeriod(periodType) });

    return (
        <div className={componentClass}>
            {periodTypes.map(type => (
                <div
                    className={getPeriodClass(type)}
                    data-period={type}
                    key={type}
                    onClick={() => {
                        !isActivePeriod(type) && onSelect(type);
                    }}>
                    {type}
                </div>
            ))}
            {/* temporary hided in MVP https://jirapct.epam.com/jira/browse/ASTTSTRT-19960
            <div className='period'>
                <i className='font-icon font-icon-events'/>
            </div>
            */}
        </div>
    );
};

PeriodRangeView.propTypes = {
    small: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedPeriod: PropTypes.string
};

export default PeriodRangeView;
