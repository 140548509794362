import { createSelector } from 'reselect';
import { get, map, includes, orderBy } from 'lodash';

import { getPeerDescription } from 'Utils/peers';

const getPeersState = (state) => state.peers;

export const isPeersFetchingSelector = createSelector(
    getPeersState,
    state => get(state, 'isFetching')
);

export const isLoadingPeersSelector = createSelector(
    getPeersState,
    state => get(state, 'isLoading')
);

export const peersSelector = createSelector(
    getPeersState,
    state => {
        const peers = get(state, 'peers');

        return peers ?
            orderBy(peers, ['name', 'ticker'], ['asc']) :
            peers;
    }
);

export const foundPeersSelector = createSelector(
    getPeersState,
    state => {
        const peers = get(state, 'foundPeers');

        return peers ?
            orderBy(peers, ['name', 'ticker'], ['asc']) :
            peers;
    }
);

export const foundPeersOptionsSelector = createSelector(
    peersSelector,
    foundPeersSelector,
    (peers, foundPeers) => {
        const selectedPeerIds = map(peers, peer => peer.id);

        return map(foundPeers, peer => {
            const label = getPeerDescription(peer);

            return {
                name: peer.name,
                value: {
                    ...peer,
                    label
                },
                label,
                disabled: includes(selectedPeerIds, peer.id)
            };
        });
    }
);
