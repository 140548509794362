import React, { Component } from 'react';
import { withProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { commonWidgetPropTypes, commonWidgetActions, compareMktVals } from '../utils';
import Widget from '../Widget';
import NullComponent from '../Widget/components/NullComponent';
import ShareholderActionPanel from './components/ShareholderActionPanel';
import ShareholderContent from './components/ShareholderContent';
import * as featuresSelectors from 'State/features/selectors';
import { getFilteredTableValuesSelector, getShareholdersTableData } from 'State/shareholderTable/selectors';
import { hasAccessToShareholderType, compareAccountNumbers, getCurrentSortCriteria, getCurrentViewId } from './utils';
import columnTypes from './constants/shareholder-column-types';
import { default as shareholderTypes, shareholderTypesList } from 'Constants/shareholder-types';
import { sortArray } from 'Utils/utils';
import sortDirections from 'Constants/sort-directions';
import { getRegularShareholders, getProxyShareholders } from 'State/shareholderTable';
import shareholderDefaultViews from './constants/defaultViews';
import * as widgetsActions from 'State/widgets/actions';

import './ShareholdersTable.scss';

const mapStateToProps = (state) => ({
    userProductSources: featuresSelectors.getCurrentProductSourcesSelector(state),
    currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
    widgetData: getShareholdersTableData(state)
});

const getAvailableTypesSelector = createSelector(
    ({ displayTypes }) => displayTypes,
    ({ userProductSources }) => userProductSources,
    ({ currentFeatures }) => currentFeatures,
    ({ showAllTypes }) => showAllTypes,
    (displayTypes, userProductSources, currentFeatures, showAllTypes) => {
        const availableShareholderTypesList = shareholderTypesList.filter(shareholderType =>
            hasAccessToShareholderType(userProductSources, shareholderType, currentFeatures));

        let availableDisplayTypes = _.filter(displayTypes, displayType =>
            hasAccessToShareholderType(userProductSources, displayType, currentFeatures));

        if (showAllTypes || availableDisplayTypes.length === 0) {
            availableDisplayTypes = [...availableShareholderTypesList];
        }

        return {
            availableShareholderTypesList,
            availableDisplayTypes
        };
    }
);

const sortedValuesSelector = createSelector(
    ({ values, availableDisplayTypes }) => getFilteredTableValuesSelector(values, availableDisplayTypes),
    ({ sortCriteria }) => sortCriteria,
    ({ cedeCoValue }) => cedeCoValue,
    ({ currentViewId }) => currentViewId,
    (filteredValues, sortCriteria, cedeCoValue, currentViewId) => {
        const visibleValues = cedeCoValue
            ? [...filteredValues, { ...cedeCoValue, isCedeCo: true }]
            : filteredValues;
        let comparator;

        switch (sortCriteria.sortField) {
            case columnTypes.ACCOUNT_NUMBER:
                comparator = compareAccountNumbers;
                break;
            case columnTypes.MKT_VAL:
                comparator = compareMktVals;
                break;
            default:
                break;
        }

        const isSortedByPositionInProxy = sortCriteria.sortField === columnTypes.POSITION && currentViewId === shareholderDefaultViews.PROXY;
        const secondaryField = isSortedByPositionInProxy ? columnTypes.CUMULATIVE_OS : columnTypes.SHAREHOLDER_NAME;
        const secondaryDescending = isSortedByPositionInProxy ? sortCriteria.sortDirection !== sortDirections.DESC : false;

        const sortedValues = sortArray({
            array: visibleValues,
            primaryField: sortCriteria.sortField,
            descending: sortCriteria.sortDirection === sortDirections.DESC,
            secondaryField,
            secondaryDescending,
            primaryComparator: comparator,
            initial: sortCriteria.inital
        });

        return sortedValues;
    }
);

const mapDispatchToProps = {
    onWidgetsLoadData: widgetsActions.widgetsLoadData,
    onGetRegularShareholders: getRegularShareholders,
    onGetProxyShareholders: getProxyShareholders
};

@connect(mapStateToProps, mapDispatchToProps)
@withProps((props) => {
    const {
        availableShareholderTypesList,
        availableDisplayTypes
    } = getAvailableTypesSelector({
        displayTypes: _.get(props, 'widgetSettings.displayTypes'),
        userProductSources: props.userProductSources,
        currentFeatures: props.currentFeatures,
        showAllTypes: getCurrentViewId(_.get(props, 'widgetSettings')) === shareholderDefaultViews.PROXY
    }
    );
    const {
        widgetSettings: {
            views: { currentViewId },
            showCEDE
        } = {},
        widgetData: {
            cedeCo,
            values = []
        } = {}
    } = props;
    let cedeCoValue;
    const sortCriteria = getCurrentSortCriteria(props.widgetSettings);
    const widgetSettings = { ...props.widgetSettings, sortCriteria };

    if (cedeCo && showCEDE && currentViewId === shareholderDefaultViews.REGISTERED
        && availableDisplayTypes.length === 1 && availableDisplayTypes[0] === shareholderTypes.REGISTERED) {
        cedeCoValue = cedeCo;
    }

    const sortedValues = sortedValuesSelector({
        values,
        availableDisplayTypes,
        cedeCoValue,
        sortCriteria,
        currentViewId: getCurrentViewId(_.get(props, 'widgetSettings'))
    });

    return {
        widgetSettings,
        sortedValues,
        availableShareholderTypesList,
        availableDisplayTypes
    };
})
class ShareholdersTable extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetHeader: false,
            WidgetControlPanel: NullComponent, // TODO fix remove
            WidgetActionPanel: ShareholderActionPanel,
            WidgetContent: ShareholderContent
        };

        this.widgetEvents = {
            onBuildDataParams: ({ isAddToDataViz }) => {
                if (isAddToDataViz) return {};

                return {
                    sortCriteria: getCurrentSortCriteria(props.widgetSettings)
                };
            }
        };
    }

    componentDidUpdate(prevProps) {
        const { widgetSettings: { views: prevViews } } = prevProps;
        const { widgetSettings: { views } } = this.props;

        if (prevViews.currentViewId !== views.currentViewId) {
            this.handleUpdateWidgetData(this.widgetEvents.onBuildDataParams({}));
        }
    }

    handleUpdateWidgetData = (params) => {
        const { onGetRegularShareholders, onGetProxyShareholders, widgetSettings: { views: { currentViewId }  } } = this.props;
        let widgetDataLoader = onGetRegularShareholders;

        if (currentViewId === shareholderDefaultViews.PROXY) {
            widgetDataLoader = onGetProxyShareholders;
        }

        widgetDataLoader(params);
    }

    render() {
        return (
            <Widget
                className='shareholders-table'
                {...this.props}
                onUpdateWidgetData={this.handleUpdateWidgetData}
                events={this.widgetEvents}
                components={this.widgetComponents} />
        );
    }
}

export default ShareholdersTable;
