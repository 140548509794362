import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SelectPeerPopover from './SelectPeersPopover';
import { getPeersForPopover } from '../peerDataSelectors';
import { commonWidgetActions } from '../../utils';

class PeerAnalysisHeader extends Component {
    static propTypes = {
        ...commonWidgetActions,
        widgetSettings: PropTypes.shape({
            selectedPeers: PropTypes.arrayOf(PropTypes.string).isRequired,
            selectedShareholders: PropTypes.arrayOf(PropTypes.string).isRequired
        }),
        // cause prop validation error. Could not parse commonWidgetActions
        onUpdateWidgetSettings: PropTypes.func.isRequired,
        isPrintMode: PropTypes.bool
    };

    handleSubmit = (selectedPeers) => {
        this.props.onUpdateWidgetSettings({
            ...this.props.widgetSettings,
            selectedPeers
        });
    };

    render() {
        const { widgetTitle, isPrintMode } = this.props;

        return (<div className='widget-header'>
            <div className='widget-title'>{widgetTitle}</div>
            {
                !isPrintMode &&
                <SelectPeerPopover peers={getPeersForPopover(this.props)} onSubmit={this.handleSubmit}/>
            }
        </div>);
    }
}

PeerAnalysisHeader.propTypes = {
    widgetTitle: PropTypes.string
};

export default PeerAnalysisHeader;
