import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';

import Popover from 'Components/Popover';
import { CheckBox } from 'Components/CheckBox';
import Icon from 'Components/Icon';
import filterIcons from 'Constants/shareholder-icons';
import { ScrollChecker } from 'Utils/utils';

import './ColumnFilter.scss';

const checkboxClass = 'flex';

@injectIntl
class ColumnFilter extends React.PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        values: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            isSelected: PropTypes.bool
        })).isRequired,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.showSelectAll = props.values.length > 1;
        this.state = {
            optionsState: props.values
        };
    }

    componentDidMount() {
        this.scrollChecker = new ScrollChecker(this.handlePopoverClose);
    }

    componentWillUnmount() {
        this.scrollChecker && this.scrollChecker.destroy();
    }

    handleChange = (name, e) => {
        const value = e.target.checked;
        const { optionsState } = this.state;
        const newOptionsState = optionsState.map(option => {
            return option.name !== name ? option : { ...option, isSelected: value };
        });

        this.setState({
            optionsState: newOptionsState
        });
    };

    handleToggleAllSelected = (e) => {
        const value = e.target.checked;
        const { optionsState } = this.state;
        const newOptionsState = optionsState.map(option => {
            return { ...option, isSelected: value };
        });

        this.setState({
            optionsState: newOptionsState
        });
    };

    handleSubmit = () => {
        const { optionsState } = this.state;

        this.closePopover();
        this.props.onSubmit(optionsState);
    };

    handleOpenPopover = () => {
        this.setState({
            optionsState: this.props.values
        });
    };

    handlePopoverClose = () => {
        this.closePopover();
    };

    getAreSelectedAll = () => {
        return this.state.optionsState.every((checkbox) => checkbox.isSelected);
    };

    closePopover = () => {
        this.popover && this.popover.hide && this.popover.hide();
    };

    render() {
        const { optionsState } = this.state;
        const areSelectedAll = this.getAreSelectedAll();
        const { intl } = this.props;
        const applyDisabled = optionsState.every(option => !option.isSelected) && !areSelectedAll;

        const checkboxComponents = optionsState.map(option => {
            const { name, label } = option;
            const icon = filterIcons[name];
            const customClass = cn(checkboxClass, name.toLowerCase());
            const checkBoxLabel = icon
                ? (<span className='label-text'>{label}<i className={`font-icon ${icon} text-muted`}/></span>)
                : label;
            const onChange = (e) => {
                this.handleChange(name, e);
            };

            return (
                <CheckBox
                    data-type={name}
                    key={name}
                    checked={option.isSelected}
                    onChange={onChange}
                    className={customClass}
                    label={checkBoxLabel}/>
            );
        });

        return (
            <div className='column-filter'>
                <Popover
                    id='column-filter'
                    trigger={(<i className='font-icon font-icon-filter'/>)}
                    placement='bottom'
                    onEnter={this.handleOpenPopover}
                    triggerType='click'
                    rootClose
                    ref={(popover) => this.popover = popover}>
                    <div className='filter-lists'>
                        <div className='close-btn'>
                            <Icon
                                className='font-icon font-icon-clear'
                                onClick={this.handlePopoverClose}
                                titleKey='common.close'/>
                        </div>
                        {this.showSelectAll &&
                        (<CheckBox
                            data-type='ALL'
                            className={`select-all ${checkboxClass}`}
                            checked={areSelectedAll}
                            onChange={this.handleToggleAllSelected}
                            label={<span
                                className='label-text'>{intl.formatMessage({ id: 'shareholders.table.show_all' })}</span>}/>)}
                        {checkboxComponents}
                        <div className='btn-panel'>
                            <button
                                onClick={this.handleSubmit}
                                type='submit'
                                className='btn'
                                disabled={applyDisabled}>
                                <FormattedMessage id={'common.apply'}/>
                            </button>
                        </div>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default ColumnFilter;
