import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';
import shareholderType from 'Constants/shareholder-types';
import pageRoutes from 'Constants/page-routes';
import { fundsPropTypes } from '../../propTypes';

const FundList = (props) => {
    const {
        className,
        funds
    } = props;

    return (
        <div className={className}>
            <h3 className='title'><FormattedMessage id='contact.details.relatedFunds'/>:</h3>
            {funds && funds.length !== 0 && funds.map(fund =>
                (<LinkEx
                    className='link'
                    to={fund.fundId && `${pageRoutes[shareholderType.FUND]}/${fund.fundId}`}
                    key={fund.fundId || fund.fundName}>
                    {fund.fundName}
                </LinkEx>)
            )}
            {!(funds && funds.length !== 0) && <FormattedMessage id='widgets.noDataMessage.simple'/>}
        </div>
    );
};

FundList.propTypes = {
    ...fundsPropTypes,
    className: PropTypes.string
};

export default FundList;
