import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import PerformanceWidget from 'Components/widgets/_commonComponents/Performance';
import PerformanceHeaderWithDate from './components/PerformanceHeaderWithDate';
import PerformanceContentSmall from './components/PerformanceContentSmall';

@injectIntl
class PerformanceSmall extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetHeader: PerformanceHeaderWithDate,
            WidgetContent: PerformanceContentSmall
        };
    }

    render() {
        return (
            <PerformanceWidget
                {...this.props}
                widgetComponents={this.widgetComponents}/>
        );
    }
}

export default PerformanceSmall;
