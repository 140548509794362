import accountTypes from 'Constants/account-types';

export default [
    [
        {
            value: accountTypes.ALL,
            label: 'shareholder.list.accountType.all'
        },
        {
            value: accountTypes.EMPLOYEE,
            label: 'shareholder.list.accountType.employee'
        }
    ], [
        {
            value: accountTypes.TREASURY,
            label: 'shareholder.list.accountType.treasury'
        },
        {
            value: accountTypes.DIRECTORS_AND_OFFICERS,
            label: 'shareholder.list.accountType.directorsAndOfficers'
        }
    ], [
        {
            value: accountTypes.NOMINEE,
            label: 'shareholder.list.accountType.nominee'
        }
    ]
];
