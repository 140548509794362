import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Selector from 'Components/Selector';
import dateFormats from 'Constants/date-formats';

import './MonthSelector.scss';

const MonthSelector = ({ dates = [], onChange, selectedDate, disabled }) => {
    const options = dates.map(date => ({
        value: date,
        label: moment(date).format(dateFormats.SELECTOR_DATE_FORMAT)
    }));

    return (
        <Selector
            disabled={disabled}
            wrapperClass='month-selector'
            name='monthSelector'
            options={options}
            onOptionChange={onChange}
            value={selectedDate}
            clearable={false}
            searchable={false}/>
    );
};

MonthSelector.propTypes = {
    onChange: PropTypes.func,
    dates: PropTypes.array,
    selectedDate: PropTypes.string,
    disabled: PropTypes.bool
};

export default MonthSelector;
