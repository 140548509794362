import AnalysisManagerStyleContent from './AnalysisManagerStyleContent';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';

import { mapBarValuesToData } from 'Components/Tables/TableEasy/utils';
import { sortArray } from 'Utils/utils';
import widgetModes from 'Constants/widget-modes';

export default mapProps(props => ({
    ...props,
    rows: sortArray({
        array: mapBarValuesToData(_.get(props, 'widgetData.values', []), 'os'),
        primaryField: 'os'
    }),
    hideBarChart: _.get(props, 'widgetSettings.mode') === widgetModes.TABLE,
    aggregate: _.get(props, 'widgetData.aggregate')
}))(AnalysisManagerStyleContent);
