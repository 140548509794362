import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import Select from 'react-select';
import { foundPeersOptionsSelector, isLoadingPeersSelector, clearFoundPeers } from 'State/peers';
import { withState } from '@shakacode/recompose';
import { peerShape, minLengthTerm, maxLengthTerm } from 'Constants/peer';
import keyCodes from 'Constants/key-codes';
import { despace } from 'Utils/string';

const mapStateToProps = (state) => {
    return {
        foundPeersOptions: foundPeersOptionsSelector(state),
        isLoading: isLoadingPeersSelector(state)
    };
};
const mapDispatchToProps = { clearFoundPeers };

@withState(
    'selectedPeer',
    'setSelectedPeer',
    null
)
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class SearchPeersPanel extends Component {
    static propTypes = {
        onSearch: PropTypes.func,
        onSuccess: PropTypes.func,
        foundPeersOptions: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: peerShape,
            label: PropTypes.string,
            disabled: PropTypes.bool
        })),
        selectedPeer: peerShape,
        setSelectedPeer: PropTypes.func,
        clearFoundPeers: PropTypes.func,
        isLoading: PropTypes.bool,
        intl: intlShape.isRequired
    };

    componentWillUnmount() {
        this.props.clearFoundPeers();
    }

    handleSearch = (value) => {
        const { onSearch } = this.props;
        const query = value && despace(value);

        if (query && this.isValueValid(query)) {
            onSearch(query);
        } else {
            this.props.clearFoundPeers();
        }

        return value;
    };

    handleSelect = (option) => {
        this.select.setState({
            inputValue: option.label
        });
        this.props.setSelectedPeer(option.value);
        this.props.onSearch(option.name);
    };

    handleAddPeer = () => {
        const { onSuccess, selectedPeer } = this.props;

        if (selectedPeer && this.select.state.inputValue === selectedPeer.label) {
            const wasAdding = onSuccess(selectedPeer);

            if (wasAdding) {
                if (this.select) {
                    this.select.setState({
                        inputValue: ''
                    });
                }
                this.props.setSelectedPeer(null);
                this.props.clearFoundPeers();
            }
        }
    };

    handleKeyDown = (event) => {
        if (event.keyCode === keyCodes.enter) {
            if (this.select && !this.select.state.isOpen) {
                this.handleAddPeer();
            }
        }
    };

    getSelect = (select) => {
        this.select = select;
    };

    getFilterOptions = (opts, filterValue) => {
        const { selectedPeer, intl: { formatMessage } } = this.props;

        if (!this.isValueValid(despace(filterValue)) && !selectedPeer) {
            return [{
                label: formatMessage({ id: 'modals.findShareholderModal.minLength' }, { value: minLengthTerm }),
                disabled: true
            }];
        }

        return opts;
    };

    isValueValid = value => value &&
        value.length >= minLengthTerm;

    render() {
        const { foundPeersOptions, intl: { formatMessage }, isLoading } = this.props;

        return (
            <div className='search-peers-panel'>
                <div className='find-section'>
                    <Select
                        ref={this.getSelect}
                        name='peer-selector'
                        options={foundPeersOptions}
                        onChange={this.handleSelect}
                        onInputChange={this.handleSearch}
                        onInputKeyDown={this.handleKeyDown}
                        inputProps={{ maxLength: maxLengthTerm }}
                        isLoading={isLoading}
                        clearable={false}
                        onBlurResetsInput={false}
                        placeholder=''
                        autosize={false}
                        noResultsText={!isLoading && formatMessage({ id: 'user.profile.peers.search.noResultsText' })}
                        autoFocus
                        filterOptions={this.getFilterOptions}/>
                    {/* search icon uses as decorator */}
                    <i className='font-icon font-icon-search'/>
                </div>
                <button
                    type='button'
                    className='btn ok-btn'
                    onClick={this.handleAddPeer}>
                    <FormattedMessage id='common.add'/>
                </button>
            </div>
        );
    }
}

export default SearchPeersPanel;
