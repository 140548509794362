import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { intlShape, injectIntl } from 'react-intl';

import { subscriptionsMap } from 'Constants/subscription-types';

@injectIntl
class ReportingDescriptionCell extends Component {
    static propTypes = {
        data: PropTypes.object,
        value: PropTypes.string,
        className: PropTypes.string,
        isAvailable: PropTypes.func,
        onClickAction: PropTypes.func.isRequired,
        intl: intlShape
    };

    handleClick = () => {
        const { data, onClickAction } = this.props;

        onClickAction(data);
    };

    render() {
        const { data, value, isAvailable, className, intl: { formatMessage } } = this.props;
        const classes = cn('report-description', 'ellipsis', className,
            {
                inactive: !isAvailable(data.status, data.isDeleting)
            }
        );
        const subscriptionType = subscriptionsMap[data.subscriptionId];
        const title = subscriptionType
            ? formatMessage({ id: `subscription.description.tooltip.${subscriptionType}` })
            : value;

        return <span className={classes} title={title} onClick={this.handleClick}>{value}</span>;
    }
}

export default ReportingDescriptionCell;
