import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MenuList from './MenuList';

import Popover from 'Components/Popover';

import './WidgetMenu.scss';

export default class WidgetMenu extends Component {
    static propTypes = {
        triggerElement: PropTypes.element.isRequired,
        widgets: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                // is selected means that widget is selected in current or another slot
                isSelected: PropTypes.bool.isRequired
            })
        ).isRequired,
        onSelectWidget: PropTypes.func.isRequired,
        onRemoveWidget: PropTypes.func,
        className: PropTypes.string,
        translation: PropTypes.number,
        scrollToMenu: PropTypes.func
    };

    static defaultProps = {
        widgets: []
    };

    handleOpenChangeWidgetMenuOpen = () => {
        this.props.scrollToMenu && this.props.scrollToMenu();
        this.popover.show();
    };

    handleChangeWidget = widget => () => {
        if (!widget.isSelected) {
            this.popover.hide();
            // TODO: enable adding widget
            this.props.onSelectWidget(widget);
        }
    };

    handleRemoveWidget = () => {
        this.popover.hide();
        // TODO: enable adding widget
        this.props.onRemoveWidget();
    };

    getPopover = () => {
        return this.popover;
    };

    render() {
        const isRemoveWidget = !!this.props.onRemoveWidget;

        return (
            <div className='widget-menu'>
                <Popover
                    id='widget-menu'
                    className={this.props.className}
                    trigger={this.props.triggerElement}
                    placement='bottom'
                    ref={ref => this.popover = ref}
                    onEnter={this.handleOpenChangeWidgetMenuOpen}
                    triggerType='click'>
                    <MenuList
                        {...this.props}
                        getPopover={this.getPopover}
                        onChangeWidget={this.handleChangeWidget}
                        onRemoveWidget={isRemoveWidget ? this.handleRemoveWidget : undefined}
                        translation={this.props.translation}/>
                </Popover>
            </div>
        );
    }
}
