import axios from 'axios';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

const getResourcesRequest = (requestUrl, requestType) =>
    () => {
        const requestData = utils.buildRequest({}, requestType);

        return axios.post(requestUrl, requestData, {
            isCancelable: true
        });
    };

export const getResources = getResourcesRequest(apiRoutes.getResourcesUrl, requestTypes.getResourcesPayload);
