import axios from 'axios';
import * as _ from 'lodash';
import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';
import httpStatus from 'Constants/http-status';
import errorReasons from 'Constants/error-reasons';

export const getSearchResults = ({ pii, query, token, contexts, securities, pagination, filterCriteria }, options = {}) => {
    const payload = {
        search: {
            pii,
            query,
            token,
            contexts,
            securities,
            pagination,
            filterCriteria
        }
    };
    const { originalCancelToken } = options;

    const requestData = utils.buildRequest(payload, requestTypes.searchPayload);

    return axios.post(apiRoutes.searchUrl, requestData, {
        errorStrategy: (error, responseStatus) => {
            // TODO: check errorReason
            const errorMessage = _.get(error, 'response.data.reasonType');

            if (responseStatus === httpStatus.HTTP_403) {
                if (!(errorMessage && errorMessage.toUpperCase() === errorReasons.NO_ISSUER_ID_FOR_CURRENT_SECURITY)) {
                    return errorStrategies.REDIRECT_HOME;
                }
            }

            return errorStrategies.DEFAULT;
        },
        originalCancelToken,
        isCancelable: true
    });
};

export const getTreasurySearchResults = originalCancelToken => {
    const requestData = utils.buildRequest({}, requestTypes.searchTreasuryPayload);

    return axios.post(apiRoutes.searchTreasuryUrl, requestData, {
        errorStrategy: (error, responseStatus) => {
            // TODO: check errorReason
            const errorMessage = _.get(error, 'response.data.reasonType');

            if (responseStatus === httpStatus.HTTP_403) {
                if (!(errorMessage && errorMessage.toUpperCase() === errorReasons.NO_ISSUER_ID_FOR_CURRENT_SECURITY)) {
                    return errorStrategies.REDIRECT_HOME;
                }
            }

            return errorStrategies.DEFAULT;
        },
        originalCancelToken,
        isCancelable: true
    });
};
