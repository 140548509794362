import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

import { NumberFormatter } from 'Components/formatters';

const CellWithShareRange = (props) => {
    const { value = {}, className, additionalData: { decimalPoints } = {} } = props;
    const cellClass = cn('cell-with-share-range', className);
    const isStartNumber = _.isNumber(value.start);

    return (
        <div
            className={cellClass}>
            <div className='share-range'>
                {isStartNumber ?
                    <NumberFormatter
                        value={value.start}
                        decimalPoints={decimalPoints}/>
                    : <span>{value.start}</span>
                }
            </div>
            <div className='share-range'>
                <NumberFormatter
                    value={value.end}
                    decimalPoints={decimalPoints}/>
            </div>
        </div>
    );
};

CellWithShareRange.propTypes = {
    value: PropTypes.shape({
        start: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        end: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    additionalData: PropTypes.shape({
        decimalPoints: PropTypes.number
    }),
    className: PropTypes.string
};

export default CellWithShareRange;
