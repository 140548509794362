import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import Icon from 'Components/Icon';
import Spinner from 'Components/Spinner';
import {
    getContactsByIdSelector, getContactsRequestErrorByIdSelector,
    getContactsRequestIsFetchingByIdSelector,
    getShareholderContacts
} from 'State/shareholderTable';
import { withPrint } from 'Hoc';

import { ShareholderContactDetails } from './components/ShareholderContactDetails';

import './ShareholderContactsModal.scss';

const mapStateToProps = (state, props) => {
    const { additionalData: { id: shareholderId } } = props;

    return {
        contacts: getContactsByIdSelector(state, shareholderId),
        isFetching: getContactsRequestIsFetchingByIdSelector(state, shareholderId),
        error: getContactsRequestErrorByIdSelector(state, shareholderId)
    };
};

const mapDispatchToProps = {
    getShareholderContacts
};

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
@withPrint
class ShareholderContactsModal extends React.Component {
    static propTypes = {
        contacts: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                title: PropTypes.string,
                phone1: PropTypes.string,
                phone2: PropTypes.string,
                phone3: PropTypes.string,
                email: PropTypes.string,
                corporateEmail: PropTypes.string
            })
        ),
        isFetching: PropTypes.bool,
        additionalData: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        }).isRequired,
        hideModal: PropTypes.func,
        intl: intlShape,
        getShareholderContacts: PropTypes.func,
        error: PropTypes.string,
        onPrint: PropTypes.func,
        onSetPrintPageTitle: PropTypes.func
    }

    componentDidMount() {
        const { additionalData: { id: shareholderId }, onSetPrintPageTitle, intl: { formatMessage } } = this.props;
        const pageTitle = formatMessage({ id: 'pageTitle.dfkProxyContacts' });

        this.props.getShareholderContacts(shareholderId);
        onSetPrintPageTitle(pageTitle);
    }

    render() {
        const { contacts, additionalData: { name }, hideModal, isFetching, error, onPrint } = this.props;
        const showContent = !isFetching && !error;

        return (
            <>
                <div className='modal-header'>
                    <h2 className='modal-header-left'>
                        <FormattedMessage id='modals.shareholderContacts.title' values={{ name }} />
                        <span className='shareholder-contacts-modal__actions'>
                            <Icon className='font-icon font-icon-local-printshop' onClick={onPrint} />
                        </span>
                    </h2>
                    <Icon
                        onClick={hideModal}
                        className='font-icon font-icon-clear close'
                        titleKey='common.close'/>
                </div>
                <div className='shareholder-contacts-modal__content'>
                    {isFetching && <Spinner isFetching={isFetching} isBlocker/>}
                    {showContent && contacts.map(contact => <ShareholderContactDetails contact={contact} key={contact.name}/>)}
                </div>
            </>
        );
    }
}

export { ShareholderContactsModal };
