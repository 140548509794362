import React from 'react';
import { FormattedMessage } from 'react-intl';

import SubscriptionsPanel from 'Pages/reporting/ReportingPage/components/SubscriptionsPanel';

const DownloadsPanel = () => {
    return (
        <div className='downloads-panel'>
            <div className='header'>
                <FormattedMessage id='reporting.nav.downloads'/>
            </div>
            <SubscriptionsPanel/>
        </div>
    );
};

export default DownloadsPanel;
