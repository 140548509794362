import _ from 'lodash';

import scheduleTypes from '../components/ReportScheduler/constants/schedule-types';

export const getSchedulerParams = formValues => ({
    reportName: formValues.reportName,
    type: formValues.type,
    form: {
        ...formValues.form,
        format: formValues.format,
        schedule: {
            ...formValues.schedule,
            scheduleType: formValues.isLastDayOfMonth ? scheduleTypes.MONTHLY_LAST_DAY : _.get(formValues, 'schedule.scheduleType')
        }
    }
});
