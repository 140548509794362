import _ from 'lodash';

import { createReducer } from 'Utils/reducer';
import {
    GET_SAVED_REPORT_TEMPLATES_REQUEST,
    GET_SAVED_REPORT_TEMPLATES_SUCCESS,
    GET_SAVED_REPORT_TEMPLATES_FAILURE,
    SAVE_REPORT_TEMPLATE_REQUEST,
    SAVE_REPORT_TEMPLATE_SUCCESS,
    SAVE_REPORT_TEMPLATE_FAILURE,
    DELETE_REPORT_TEMPLATE_REQUEST,
    DELETE_REPORT_TEMPLATE_SUCCESS,
    DELETE_REPORT_TEMPLATE_FAILURE,
    GET_REPORT_TEMPLATE_USERS_REQUEST,
    GET_REPORT_TEMPLATE_USERS_SUCCESS,
    GET_REPORT_TEMPLATE_USERS_FAILURE,
    SHARE_REPORT_TEMPLATE_REQUEST,
    SHARE_REPORT_TEMPLATE_SUCCESS,
    SHARE_REPORT_TEMPLATE_FAILURE
} from './actions';

const initialState = {
    reports: null,
    usersIsFetching: false,
    users: [],
    shareReportTemplateIsFetching: false
};

const actionHandlers = {
    [GET_SAVED_REPORT_TEMPLATES_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        reports: [],
        error: null
    }),
    [GET_SAVED_REPORT_TEMPLATES_SUCCESS]: (state, { payload: { reports } }) => ({
        ...state,
        isFetching: false,
        reports,
        error: null
    }),
    [GET_SAVED_REPORT_TEMPLATES_FAILURE]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        reports: [],
        error: payload
    }),
    [SAVE_REPORT_TEMPLATE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        error: null
    }),
    [SAVE_REPORT_TEMPLATE_SUCCESS]: (state, { payload }) => {
        const reports = [...state.reports];
        const index = _.findIndex(reports, item => item.reportTemplateId === payload.reportTemplateId);

        if (index > -1) {
            reports.splice(index, 1, payload);
        } else {
            reports.unshift(payload);
        }

        return {
            ...state,
            isFetching: false,
            error: null,
            reports
        };
    },
    [SAVE_REPORT_TEMPLATE_FAILURE]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [DELETE_REPORT_TEMPLATE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        error: null
    }),
    [DELETE_REPORT_TEMPLATE_SUCCESS]: (state, { payload: { reportTemplateId } }) => ({
        ...state,
        isFetching: false,
        reports: _.filter(state.reports, r => r.reportTemplateId !== reportTemplateId),
        error: null
    }),
    [DELETE_REPORT_TEMPLATE_FAILURE]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    }),
    [GET_REPORT_TEMPLATE_USERS_REQUEST]: (state) => ({
        ...state,
        usersIsFetching: true,
        users: []
    }),
    [GET_REPORT_TEMPLATE_USERS_SUCCESS]: (state, { payload: users }) => ({
        ...state,
        usersIsFetching: false,
        users
    }),
    [GET_REPORT_TEMPLATE_USERS_FAILURE]: (state) => ({
        ...state,
        usersIsFetching: false
    }),
    [SHARE_REPORT_TEMPLATE_REQUEST]: (state) => ({
        ...state,
        shareReportTemplateIsFetching: true
    }),
    [SHARE_REPORT_TEMPLATE_SUCCESS]: (state) => ({
        ...state,
        shareReportTemplateIsFetching: false
    }),
    [SHARE_REPORT_TEMPLATE_FAILURE]: (state) => ({
        ...state,
        shareReportTemplateIsFetching: false
    })
};

export default createReducer(initialState, actionHandlers);
