import PropTypes from 'prop-types';
import React from 'react';

const AnalysisTurnoverHeaderView = ({ widgetTitle, handleInfoIconClick, isDataViz }) => (
    <div className='widget-header ellipsis'>
        <span className='ellipsis' title={widgetTitle}>{widgetTitle}</span>
        { !isDataViz && (<i className='font-icon font-icon-info-outline' onClick={handleInfoIconClick} />) }
    </div>
);

AnalysisTurnoverHeaderView.propTypes = {
    widgetTitle: PropTypes.string,
    handleInfoIconClick: PropTypes.func,
    isDataViz: PropTypes.bool
};

export default AnalysisTurnoverHeaderView;
