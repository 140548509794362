import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import { FormattedNumber } from 'react-intl';
import _ from 'lodash';

import TableEasy from 'Components/Tables/TableEasy';
import { NullableFormatter, NumberFormatter } from 'Components/formatters';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import { getMarkedData, markIncludesCeDeCoRows } from 'Components/widgets/utils';
import { labelCell } from './cellRenderer';

const RegisteredHolderTypeTableView = (props) => {
    const { outstandingSharesTotal, widgetFooterProps, tableData, intl: { formatMessage }, isDataViz } = props;

    return (
        <div className='table-container'>
            <div className='os-total'>
                <span className='os-total-text'>
                    {formatMessage({ id: 'registered.holder.type.totalOs' })}
                </span>
                <NullableFormatter value={outstandingSharesTotal}>
                    <FormattedNumber value={outstandingSharesTotal}/>
                </NullableFormatter>
            </div>
            <TableEasy
                widgetFooterProps={widgetFooterProps}
                className='widget-table'
                renderAllRows={isDataViz}
                totalMessageId='tableEasy.priorCOBTotal'
                {...tableData}/>
        </div>
    );
};

RegisteredHolderTypeTableView.propTypes = {
    outstandingSharesTotal: PropTypes.number,
    tableData: PropTypes.object,
    intl: PropTypes.object,
    isDataViz: PropTypes.bool,
    widgetFooterProps: PropTypes.object
};

export default compose(
    mapProps(props => {
        const { widgetNoData, widgetFooterProps, intl: { formatMessage } } = props;
        const aggregate = _.get(props, 'widgetData.tableView.aggregate');
        const allValues = _.get(props, 'widgetData.tableView.values');
        const outstandingSharesTotal = _.get(aggregate, 'outstandingSharesTotal');
        let tableData;

        if (!widgetNoData) {
            tableData = {
                columns: [
                    {
                        dataKey: 'holderType',
                        title: formatMessage({ id: 'registered.holder.type.holderType' }),
                        bodyCellComponent: labelCell
                    },
                    {
                        dataKey: 'os',
                        columnClassName: 'text-right',
                        maxWidth: 55,
                        title: formatMessage({ id: 'registered.holder.type.os' }),
                        bodyCellComponent: TotalValueOsCell
                    },
                    {
                        dataKey: 'shareholdersCount',
                        columnClassName: 'text-right',
                        title: formatMessage({ id: 'registered.holder.type.shareholdersCount' }),
                        bodyCellComponent: NumberFormatter
                    }
                ],
                data: getMarkedData(allValues, [markIncludesCeDeCoRows], widgetFooterProps),
                aggregate
            };
        }

        return { ...props, outstandingSharesTotal, tableData };
    })
)(RegisteredHolderTypeTableView);
