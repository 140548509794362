import React, { Component } from 'react';

import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from './slots';

import './HomePage.scss';

const dynamicPageSettings = {
    isDynamicSlots: true
};

class HomePage extends Component {
    render() {
        return (
            <div className='home-page'>
                <DynamicPage
                    layoutType={layoutTypes.homePageLayout}
                    slots={slots}
                    settings={dynamicPageSettings}
                    dynamicPageType={PAGE_TYPES.HOME}/>
            </div>
        );
    }
}
export default HomePage;
