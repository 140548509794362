import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'Components/Tooltip';

export const SubscriptionTooltip = ({ tooltipMessage, children }) => {
    if (tooltipMessage) {
        const tooltipContent = (
            <div className='subscription-tooltip'>{tooltipMessage}</div>
        );

        return (
            <Tooltip content={tooltipContent}>
                {children}
            </Tooltip>
        );
    }

    return children;
};

SubscriptionTooltip.propTypes = {
    tooltipKey: PropTypes.string,
    children: PropTypes.node
};
