import PropTypes from 'prop-types';
import React from 'react';

import LinkEx from 'Components/LinkEx';
import pageRoutes from 'Constants/page-routes';
import shareholderTypes from 'Constants/shareholder-types';
import formatters from 'Constants/formatter-options';
import { CheckBox } from 'Components/CheckBox';
import Icon from 'Components/Icon';
import { buildRefToBackQuery } from 'Utils/routing';

const CellWithShareholderName = ({ shareholder, value, onSelect, title, isCheckBoxDisplayed, returnPath }) => {
    const {
        shareholderId,
        shareholderType,
        isSelected,
        isDisabled
    } = shareholder;
    const input = {
        onChange: () => onSelect && onSelect(shareholder),
        checked: isSelected,
        disabled: isDisabled,
        title
    };
    const val = value || formatters.SYMBOL_EMPTY_VALUE;
    const pathname = `${pageRoutes[shareholderType.toUpperCase()]}/${shareholderId}`;
    const refToBackQuery = buildRefToBackQuery({ pathname: returnPath });

    return (
        <div className='cell-with-shareholder-info'>
            {isCheckBoxDisplayed &&
                <CheckBox {...input} />}
            {shareholderType === shareholderTypes.INSIDER &&
                <Icon className='font-icon font-icon-insider' /> }
            {shareholderType && shareholderId
                ? <LinkEx
                    className='shareholder-name'
                    to={{
                        pathname,
                        search: refToBackQuery
                    }}
                    title={val}>{val}</LinkEx>
                : <span title={val}>{val}</span>
            }
        </div>
    );
};

CellWithShareholderName.propTypes = {
    shareholder: PropTypes.shape({
        shareholderId: PropTypes.string,
        shareholderType: PropTypes.string,
        isSelected: PropTypes.bool,
        isDisabled: PropTypes.bool
    }),
    value: PropTypes.string,
    onSelect: PropTypes.func,
    isCheckBoxDisplayed: PropTypes.bool,
    title: PropTypes.string,
    returnPath: PropTypes.string
};

export default CellWithShareholderName;
