import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import shareholderTypeToIconMap from 'Constants/shareholder-type-to-icon-map';
import shareholderTypes from 'Constants/shareholder-types';
import pageRoutes from 'Constants/page-routes';
import { buildRefToBackQuery } from 'Utils/routing';
import returnParamTypes from 'Constants/return-param-types';
import Icon from 'Components/Icon';
import LinkEx from 'Components/LinkEx';


const shareholderIcons = {
    ...shareholderTypeToIconMap,
    [shareholderTypes.FUND]: 'font-icon-security-group'
};

export const RecentSearchAccount = ({
    account: { shareholderType, shareholderName, shareholderId, accessible = false },
    returnPath = {} }
) => {
    const pathname = `${pageRoutes[shareholderType.toUpperCase()]}/${shareholderId}`;
    const refToBackQuery = buildRefToBackQuery(returnPath);

    const toShareholderPage = {
        pathname,
        search: refToBackQuery
    };

    return (
        <div className='recent-search-account'>
            <Icon className={cn('recent-search-account__icon font-icon', shareholderIcons[shareholderType])} inactive={!accessible} />
            <LinkEx
                to={toShareholderPage}
                className={cn('recent-search-account__link', { inactive: !accessible })}
                title={shareholderName}>
                <div className='ellipsis'>{shareholderName}</div>
                <div className='ellipsis'>{shareholderId}</div>
            </LinkEx>
        </div>
    );
};

export const RecentSearchAccountPropType = PropTypes.shape({
    shareholderType: PropTypes.string,
    shareholderName: PropTypes.string,
    shareholderId: PropTypes.string,
    accessible: PropTypes.bool
});

RecentSearchAccount.propTypes = {
    account: RecentSearchAccountPropType,
    returnPath: returnParamTypes
};
