import PropTypes from 'prop-types';
import React from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import cn from 'classnames';

import TableEasy from 'Components/Tables/TableEasy';
import { headerCell, labelCell, numberCell } from './renderers';
import { columns } from '../constants';

const ShareholderElectionsTableView = (props) => {
    const { tableData, widgetNoDividend, widgetEmptyData } = props;
    const containerClassName = cn('table-container', { disable: widgetEmptyData });

    return (
        <div>
            <div className={containerClassName}>
                <TableEasy
                    className='widget-table'
                    {...tableData}/>
            </div>
            {widgetNoDividend &&
            <div className='info-message no-dividend-info'>
                <FormattedMessage id='shareholder.elections.noDividend'/>
            </div>
            }
        </div>
    );
};

ShareholderElectionsTableView.propTypes = {
    widgetNoDividend: PropTypes.bool,
    widgetEmptyData: PropTypes.bool,
    tableData: PropTypes.object,
    isDataViz: PropTypes.bool
};

export default compose(
    mapProps(props => {
        const { widgetNoData, intl: { formatMessage }, data } = props;
        const total = {
            numberOfElections: _.get(data, 'aggregate.totalNumberOfElections')
        };
        let tableData;

        if (!widgetNoData) {
            tableData = {
                columns: [
                    {
                        dataKey: columns.ELECTION_TYPE,
                        headerCellComponent: () => headerCell(
                            formatMessage({ id: 'shareholder.elections.electionType' }),
                            <FormattedHTMLMessage id='shareholder.elections.tooltip.electionType'/>
                        ),
                        bodyCellComponent: labelCell
                    },
                    {
                        dataKey: columns.NUMBER_OF_ELECTIONS,
                        columnClassName: 'text-right',
                        width: 120,
                        headerCellComponent: () => headerCell(
                            formatMessage({ id: 'shareholder.elections.numberOfElections' }),
                            <FormattedHTMLMessage id='shareholder.elections.tooltip.numberOfElections'/>
                        ),
                        bodyCellComponent: numberCell
                    }
                ],
                data: _.get(data, 'values'),
                total,
                markTotalOverflow: props.markTotalOverflow
            };
        }

        return { ...props, tableData };
    })
)(ShareholderElectionsTableView);
