import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import PerformanceWidget from 'Components/widgets/_commonComponents/Performance';
import PerformanceContent from './components/PerformanceContent';

@injectIntl
class Performance extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: PerformanceContent
        };
    }

    render() {
        return (
            <PerformanceWidget
                {...this.props}
                widgetComponents={this.widgetComponents}/>
        );
    }
}

export default Performance;
