import React, { Component } from 'react';
import { intlShape, FormattedHTMLMessage } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions, getWidgetFooterPropsWithSignClass } from '../utils';
import Widget from '../Widget';
import BadAddressesContent from './components/BadAddressesContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import PeriodActionPanel from 'Components/widgets/_commonComponents/PeriodActionPanel';
import { getDates, getSelectedDate, getFilteredData } from './selectors';
import widgetModes from 'Constants/widget-modes';
import FooterWithInfo from 'Components/widgets/_commonComponents/FooterWithInfo';
import messageType from 'Constants/message-types';

import './BadAddresses.scss';

class BadAddresses extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: BadAddressesContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetActionPanel: PeriodActionPanel,
            WidgetFooter: FooterWithInfo
        };
    }

    getWidgetFooterProps = isTableMode => ({
        bottom: [
            {
                messageKey: 'bad.addresses.messageNote.reportDiff',
                hidden: !isTableMode,
                type: messageType.DIFFERENT_FROM_REPORT
            }
        ]
    });

    render() {
        const dates = getDates(this.props);
        const selectedDate = getSelectedDate(this.props);
        const data = getFilteredData(this.props);
        const isTableMode = _.get(this.props, 'widgetSettings.mode') === widgetModes.TABLE;
        const dataType = isTableMode ? 'tableData' : 'pieChartData';
        const widgetTitleTooltip = isTableMode
            ? <FormattedHTMLMessage id='bad.addresses.tooltip.tableView.title'/>
            : <FormattedHTMLMessage id='bad.addresses.tooltip.chartView.title'/>;
        const widgetTitle = isTableMode
            ? (<div>
                <span><FormattedHTMLMessage id='bad.addresses.table.title'/></span>
                <span className='secondary-title'><FormattedHTMLMessage
                    id='bad.addresses.table.title.description'/></span>
            </div>)
            : <FormattedHTMLMessage id='bad.addresses.chart.title'/>;
        const props = {
            ...this.props,
            widgetTitle,
            widgetTitleTooltip,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            },
            widgetFooterProps: getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(isTableMode)),
            widgetNoData: !_.get(data, `data.${dataType}.length`),
            data,
            dates,
            selectedDate
        };

        return (
            <Widget
                {...props}
                className='widget-bad-addresses'
                components={this.widgetComponents}/>
        );
    }
}

export default BadAddresses;
