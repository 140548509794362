import PropTypes from 'prop-types';
import React from 'react';

import { NullableFormatterWithIntl } from './NullableFormatter';
import currencyConstants, { localeCurrency } from 'Constants/currency';
import { currencyFormatter } from './formatters';

const CurrencyFormatter = (props) => {
    const {
        decimalPoints,
        separately,
        negativeWithBrackets,
        withRound,
        isOriginalValue
    } = props;

    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, negClass, intl }) => {
                const formattedValue = currencyFormatter(intl, value, decimalPoints, {
                    currency: localeCurrency[intl.locale].symbol,
                    right: false,
                    separately,
                    negativeWithBrackets,
                    withRound,
                    isOriginalValue
                });

                return (<span className={negClass}>{formattedValue}</span>);
            }}
        </NullableFormatterWithIntl>
    );
};

CurrencyFormatter.propTypes = {
    value: PropTypes.any,
    decimalPoints: PropTypes.number,
    currency: PropTypes.string,
    separately: PropTypes.bool,
    negativeWithBrackets: PropTypes.bool,
    withRound: PropTypes.bool,
    isOriginalValue: PropTypes.bool
};

CurrencyFormatter.defaultProps = {
    currency: currencyConstants.dollar,
    withRound: true,
    isOriginalValue: false
};

export default CurrencyFormatter;
