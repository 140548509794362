import { getFormValues, isValid } from 'redux-form';
import { createSelector } from 'reselect';
import _ from 'lodash';

import featureType from 'Constants/feature-types';
import {
    CONTEXT_ORDER,
    CONTEXTS,
    MAX_SECURITY_COUNT,
    PII_TO_FEATURE,
    SEARCH_FORM_NAME,
    MAX_SHAREHOLDERS_TO_COMPARE
} from 'Constants/search';
import { getAvailableIssuers, getIssuersAmount } from 'State/issuers/selectors';
import { getAvailableSecurities } from 'State/securities/selectors';
import { getCurrentSecurityIdSelector } from 'State/user/selectors';
import { filterSecurityList } from './utils';
import pageRoutes from 'Constants/page-routes';

export const getAdvancedSearchSecurities = createSelector(
    (state) => _.get(state, 'advancedSearch.securities', []),
    (securities) => {
        return securities;
    }
);

export const getFilterString = state => _.get(state, 'advancedSearch.filter', '');

export const getAdvancedSearchIssuers = state => _.get(state, 'advancedSearch.issuers', []);

export const getVisibleSecurities = state => {
    const selectedIssuers = getAdvancedSearchIssuers(state);
    const filter = getFilterString(state);

    if (!!selectedIssuers.length) {
        let visibleSecurities = [];

        _.forEach(selectedIssuers, issuer => {
            visibleSecurities = _.concat(visibleSecurities, getSecuritiesByIssuerId(state, issuer.id));
        });

        return filterSecurityList(visibleSecurities, filter);
    }
    return filterSecurityList(getAvailableSecurities(state), filter);
};

export const getSelectedSecurities = createSelector(
    getAdvancedSearchSecurities,
    getVisibleSecurities,
    (checkedSecurities, visibleSecurities) => _.intersectionBy(checkedSecurities, visibleSecurities, 'id'));

export const isSearchInCurrentSecurity = createSelector(
    getCurrentSecurityIdSelector,
    getSelectedSecurities,
    (currentSecurityId, selectedSecurity) => selectedSecurity.length === 1 && selectedSecurity[0].id === currentSecurityId);

export const isAdvancedSearchAllowedForSingleSecurity = createSelector(
    getAvailableSecurities,
    (secs) => {
        // check that we have 1 security
        if (secs.length !== 1) {
            return false;
        }
        const { features } = secs[0];

        return !!(features[featureType.ADVANCED_SEARCH_CERT] || features[featureType.ADVANCED_SEARCH_SSN]);
    }
);

export const isSuitSecurityCount = createSelector(
    getAdvancedSearchSecurities,
    securities => {
        return securities.length <= MAX_SECURITY_COUNT;
    }
);

export const getSortedAdvancedSearchIssuers = createSelector(
    getAdvancedSearchIssuers,
    issuers => _.sortBy(issuers, issuer => issuer.companyName)
);

export const getSelectedIssuersAmount = createSelector(
    getAdvancedSearchIssuers,
    issuers => issuers.length
);

export const isIssuerSelected = (state, issuerId) => {
    const issuers = getAdvancedSearchIssuers(state);

    return _.some(issuers, issuer => issuer.id === issuerId);
};

export const areAllIssuersSelected = createSelector(
    getSelectedIssuersAmount,
    getIssuersAmount,
    (selectedIssuersAmount, allIssuersAmount) => allIssuersAmount === selectedIssuersAmount
);

export const areIssuersIndeterminate = createSelector(
    getSelectedIssuersAmount,
    getIssuersAmount,
    (selectedIssuersAmount, allIssuersAmount) => allIssuersAmount > selectedIssuersAmount && selectedIssuersAmount > 0
);

export const getSecuritiesByIssuerId = (state, issuerId) => {
    const securities = getAvailableSecurities(state);
    const filter = getFilterString(state);
    const issuerSecurities = _.filter(securities, security => security.issuerId === issuerId);

    return !filter ? issuerSecurities : filterSecurityList(issuerSecurities, filter);
};

export const isSecuritySelected = (state, securityId) => {
    const securities = getAdvancedSearchSecurities(state);

    return _.some(securities, security => security.id === securityId);
};

export const areAllSecuritiesSelected = createSelector(
    getAvailableSecurities,
    getAdvancedSearchSecurities,
    (availableSecurities, selectedSecurities) => {
        const firstIds = _.sortBy(availableSecurities.map(s => s.id));
        const secondIds = _.sortBy(selectedSecurities.map(s => s.id));

        return !_.isEmpty(firstIds) ? _.isEqual(firstIds, secondIds) : false;
    }
);

export const areAllVisibleSecuritiesSelected = createSelector(
    getVisibleSecurities,
    getAdvancedSearchSecurities,
    (visibleSecurities, selectedSecurities) => {
        const firstIds = _.sortBy(visibleSecurities.map(s => s.id));
        const secondIds = _.sortBy(selectedSecurities.map(s => s.id));

        return !_.isEmpty(firstIds) ? _.every(firstIds, id => _.includes(secondIds, id)) : false;
    }
);

export const areSecuritiesIndeterminate = createSelector(
    getVisibleSecurities,
    getAdvancedSearchSecurities,
    (visibleSecurities, selectedSecurities) => selectedSecurities.length > 0
        && selectedSecurities.length < visibleSecurities.length
);

export const isSomeSecuritySelected = createSelector(
    getAdvancedSearchSecurities,
    (selectedSecurities) => {
        return selectedSecurities.length > 0;
    }
);

export const getSelectedSecuritiesFeatures = createSelector(
    getSelectedSecurities,
    (securities) => {
        // map from all selected securities
        const featureArr = _.union(...securities.map(s => {
            return s.features ? Object.keys(s.features) : [];
        }));
        const featureMap = {};

        featureArr.forEach(f => {
            featureMap[f] = true;
        });

        return featureMap;
    }
);

export const getSelectedPII = createSelector(
    getFormValues(SEARCH_FORM_NAME),
    (values) => {
        return values && values.PII;
    }
);

export const allowToSelectPIIGetter = createSelector(
    getSelectedSecuritiesFeatures,
    (userFeatures) => {
        return (pii) => {
            const requiredFeature = PII_TO_FEATURE[pii];

            return userFeatures[requiredFeature];
        };
    }
);

export const allowedSelectedPII = createSelector(
    getSelectedPII,
    allowToSelectPIIGetter,
    (selectedPII, allowToSelectPIIChecker) => {
        return allowToSelectPIIChecker(selectedPII);
    }
);

export const getFetchStatusFor = (state) => (context) => {
    return _.get(state, ['advancedSearch', 'fetchingStatus', context]);
};


export const isFetchingGlobal = (state) => getFetchStatusFor(state)('global');

export const isFetchingAdvanced = (state) => getFetchStatusFor(state)('advanced');

export const isFetchingAny = createSelector(
    isFetchingAdvanced,
    isFetchingGlobal,
    (adv, global) => adv || global
);

export const isAvailableQueryInput = createSelector(
    getSelectedSecurities,
    allowedSelectedPII,
    isSuitSecurityCount,
    isFetchingAny,
    (selectedSecurities, hasAccess, isSuitCount, submitting) => {
        return selectedSecurities.length > 0
            && hasAccess
            && isSuitCount
            && !submitting;
    }
);

export const isSubmittableSearch = createSelector(
    isAvailableQueryInput,
    isValid(SEARCH_FORM_NAME),
    (isAvailableQueryInputValue, isValidForm) => isAvailableQueryInputValue && isValidForm
);

export const getSearchQuery = (state) => _.get(state, 'advancedSearch.lastSearch.query');

export const getTotalCount = (state) => _.get(state, 'advancedSearch.total', 0);

export const getSearchResults = (state) => _.get(state, 'advancedSearch.results');

export const getSearchResultForContext = createSelector(
    getSearchResults,
    (results) => {
        return (contextType) => {
            return _.find(results, (c) => c.type === contextType);
        };
    }
);

export const getNonEmptyContexts = createSelector(
    getSearchResults,
    (results) => {
        const contexts = [];

        // sorting one array according another array
        CONTEXT_ORDER.forEach(contextType => {
            const found = _.find(results, (c) => c.type === contextType);

            if (found && found.total) {
                contexts.push(contextType);
            }
        });

        return contexts;
    }
);

export const getLastSearchParameters = state => _.get(state, 'advancedSearch.lastSearch');

export const getFilterCriteria = state => _.get(state, 'advancedSearch.lastSearch.filterCriteria');

export const getSearchStates = state => _.get(state, 'advancedSearch.states');

export const getSearchTotals = state => _.get(state, 'advancedSearch.filterTotals');

export const getSearchSecurities = state => _.get(state, 'advancedSearch.securities');

export const getFilterIssuers = state => _.get(state, 'advancedSearch.issuers');

export const getSearchIssuersSelector = createSelector(
    getFilterIssuers,
    getSearchSecurities,
    (issuers, securities) => {
        const result = [];

        issuers.map(issuer => _.find(securities, security => {
            security.issuerId === issuer.id &&
            !_.includes(result, issuer) &&
            result.push(issuer);
        }));

        return result;
    }
);

export const getContextList = createSelector(
    getFilterCriteria,
    filters => _.get(filters, 'contexts')
);

export const getStatusList = createSelector(
    getFilterCriteria,
    filters => _.get(filters, 'statuses')
);

export const getCountryList = createSelector(
    getFilterCriteria,
    filters => _.get(filters, 'countries')
);

export const getStates = createSelector(
    getFilterCriteria,
    getSearchStates,
    (filters, stateList = []) => ({
        selected: _.get(filters, 'state'),
        availableStates: stateList.map(state => ({
            value: state.id,
            label: state.title
        }))
    })
);

export const getIssuersForFilter = createSelector(
    getFilterCriteria,
    filters => _.get(filters, 'issuers')
);

export const getSecuritiesForFilter = createSelector(
    getFilterCriteria,
    filters => _.get(filters, 'securities')
);

export const getSearchArea = (state) => {
    if (_.get(state, 'routes.current.location.pathname', '').includes(pageRoutes.searchResult)) {
        return _.get(state, 'advancedSearch.searchArea');
    }
    return {};
};

export const getMappedFilterIssuersWithSecurities = createSelector(
    getIssuersForFilter,
    getSecuritiesForFilter,
    getAvailableIssuers,
    getAvailableSecurities,
    getFilterString,
    (filterIssuers, filterSecurities, availableIssuers, securities, filter) => {
        const compareById = (item, id) => item.id === +id;
        const intersectedIssuers = _.intersectionWith(availableIssuers, _.keys(filterIssuers), compareById);

        return intersectedIssuers.map(issuer => {
            const issuerSecurities = _.filter(securities, security => security.issuerId === issuer.id);
            const filteredIssuerSecurities = !filter ? issuerSecurities : filterSecurityList(issuerSecurities, filter);
            const intersectedSecurities = _.intersectionWith(filteredIssuerSecurities, _.keys(filterSecurities), compareById);

            return {
                ...issuer,
                checked: filterIssuers[issuer.id],
                securities: intersectedSecurities.map(sec => ({
                    ...sec,
                    checked: filterSecurities[sec.id]
                }))
            };
        });
    }
);

export const getSelectedShareholdersToCompare = (state) => state.advancedSearch.selectedShareholdersToCompare;

export const getSelectedShareholderIdsSelector = (state) =>
    getSelectedShareholdersToCompare(state).map(({ shareholderId }) => shareholderId);

export const getSecurityIdOfSelectedShareholders = (state) => {
    const selectedAccounts = getSelectedShareholdersToCompare(state);
    const allAccountsWithSameSecurity = _.every(selectedAccounts, ({ securityId }) => securityId === selectedAccounts[0].securityId);

    return allAccountsWithSameSecurity ? selectedAccounts[0].securityId : null;
};

export const isSelectedShareholderToCompare = (state) => (shareholder) => {
    return _.findIndex(getSelectedShareholdersToCompare(state), (s) => {
        return _.isEqual(
            s,
            shareholder
        );
    }) !== -1;
};

export const isMaxSelectedShareholderToCompare = (state) => {
    const lengthOfSelected = getSelectedShareholdersToCompare(state).length;

    return lengthOfSelected === MAX_SHAREHOLDERS_TO_COMPARE
        || lengthOfSelected === _.get(getSearchResultForContext(state)(CONTEXTS.REGISTERED), 'values.length');
};

export const isAllSelectedShareholderToCompare = (state) => {
    const lengthOfSelected = getSelectedShareholdersToCompare(state).length;

    return lengthOfSelected === _.get(getSearchResultForContext(state)(CONTEXTS.REGISTERED), 'values.length');
};

export const areShareholdersToCompareIndeterminate = (state) => {
    const lengthOfSelected = getSelectedShareholdersToCompare(state).length;

    if (lengthOfSelected === 0) {
        return false;
    }

    return lengthOfSelected < _.get(getSearchResultForContext(state)(CONTEXTS.REGISTERED), 'values.length');
};

export const registeredResultLength = (state) => {
    return _.get(getSearchResultForContext(state)(CONTEXTS.REGISTERED), 'values.length', 0);
};

export const countOfSelectedShareholderSelector = (state) => {
    return getSelectedShareholdersToCompare(state).length;
};

export const getIsNewGlobalSearchRequest = state => _.get(state, 'advancedSearch.isNewGlobalSearchRequest');

export const isCompareSelectedDisabledSelector = createSelector(
    countOfSelectedShareholderSelector,
    (countOfSelectedShareholders) => countOfSelectedShareholders < 2 || countOfSelectedShareholders > 50
);

export const isCombineAccountsDisabledSelector = createSelector(
    getSelectedShareholdersToCompare,
    (selectedShareholders) => selectedShareholders.length < 2 || selectedShareholders.length > 50 ||
            !_.every(selectedShareholders, ({ securityId }) => securityId === selectedShareholders[0].securityId)
);

export const isSearchTreasurySelector = state => _.get(state, 'advancedSearch.isSearchTreasury');
