import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import ReactHighcharts from 'highcharts-react-official';
import cn from 'classnames';
import { AutoSizer } from 'react-virtualized';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { tooltipFormatter, isChartVisible, yAxisOppositeFormatter } from './utils';
import defaultHighchartsConfig from 'Constants/default-highcharts-config';
import performanceChartTypes from './constants/performance-chart-types';
import { getPosition } from 'Utils/charts';

const PerformanceChart = ({ className, data, visibleCharts, intl, customConfig }) => {
    const classNames = cn('performance-chart', className);
    const priceSerie = _.get(data, performanceChartTypes.PRICE, {});
    const moving50dSerie = _.get(data, performanceChartTypes.MOVING_AVG_50, {});
    const moving200dSerie = _.get(data, performanceChartTypes.MOVING_AVG_200, {});

    const defaultConfig = {
        chart: {
            animation: false,
            spacingLeft: 0,
            spacingRight: 35
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, new Highcharts.Color(priceSerie.color).setOpacity(0.3).get('rgba')],
                        [1, new Highcharts.Color(priceSerie.color).setOpacity(0).get('rgba')]
                    ]
                },
                dataGrouping: {
                    // prevent omitting open, high, low and close values that passed in additionalValues
                    // https://www.highcharts.com/docs/advanced-chart-features/data-grouping
                    enabled: false
                },
                lineWidth: 1,
                threshold: null
            },
            series: {
                turboThreshold: 0,
                marker: {
                    enabled: false
                },
                animation: false,
                showInLegend: false
            }
        },
        legend: {
            align: 'left'
        },
        tooltip: {
            useHTML: true,
            positioner: getPosition,
            hideDelay: 0,
            animation: false,
            formatter: function getTooltip() {
                return tooltipFormatter(this, intl);
            },
            borderRadius: 0,
            borderWidth: 0,
            padding: 0,
            shadow: false,
            enabled: true,
            backgroundColor: 'none',
            shared: true
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 1,
            gridLineWidth: 1,
            crosshair: false,
            offset: 0,
            minPadding: 0.03,
            maxPadding: 0.03,
            labels: {
                padding: 17 // default is 5
            }
        },
        yAxis: {
            labels: {
                formatter: function yAxisFormatter() {
                    return yAxisOppositeFormatter(this.value, intl);
                },
                style: { color: '#000000' },
                distance: 0
            },
            title: {
                text: null
            },
            opposite: true,
            startOnTick: true,
            endOnTick: false,
            minPadding: 0.1
        },
        series: [{
            name: '50d Moving Average',
            id: performanceChartTypes.MOVING_AVG_50,
            type: 'spline',
            data: moving50dSerie.data,
            color: moving50dSerie.color,
            enableMouseTracking: false,
            visible: isChartVisible(visibleCharts, performanceChartTypes.MOVING_AVG_50)
        }, {
            name: '200d Moving Average',
            id: performanceChartTypes.MOVING_AVG_200,
            type: 'spline',
            data: moving200dSerie.data,
            color: moving200dSerie.color,
            enableMouseTracking: false,
            visible: isChartVisible(visibleCharts, performanceChartTypes.MOVING_AVG_200)
        }, {
            name: 'Price',
            id: performanceChartTypes.PRICE,
            type: 'area',
            data: priceSerie.data,
            color: priceSerie.color,
            marker: {
                symbol: 'circle'
            }
        }]
    };

    const config = _.merge({}, defaultHighchartsConfig, defaultConfig, customConfig);

    return (
        <AutoSizer>
            {({ height, width }) => {
                config.chart.height = height;
                config.chart.width = width;

                return (
                    <ReactHighcharts
                        highcharts={Highcharts}
                        options={config}
                        containerProps={{ className: classNames, style: { height, width } }}/>
                );
            }}
        </AutoSizer>
    );
};

PerformanceChart.propTypes = {
    data: PropTypes.object,
    className: PropTypes.string,
    customConfig: PropTypes.object,
    visibleCharts: PropTypes.array,
    intl: intlShape
};

export default injectIntl(PerformanceChart);
