import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MeetingPlannerTrackerDocumentList from './MeetingPlannerTrackerDocumentList';
import MeetingPlannerTrackerTimeLine from './MeetingPlannerTrackerTimeLine';
import MeetingPlannerTrackerForm from './MeetingPlannerTrackerForm';
import NoDataComponent from '../Widget/components/NoDataComponent';

const MeetingPlannerTrackerContent = (props) => {
    const documentGroups = _.get(props, 'widgetData.documentGroups', []);
    const timeline = _.get(props, 'widgetData.timeline', []);

    const isNoData = documentGroups.length === 0 || timeline.length === 0;

    return (
        <NoDataComponent isNoData={isNoData}>
            <div className='meeting-planner-tracker-content'>
                <MeetingPlannerTrackerDocumentList documentGroups={documentGroups} />
                <div className='meeting-planner-tracker__main'>
                    <MeetingPlannerTrackerForm />
                    {timeline && <MeetingPlannerTrackerTimeLine timeline={timeline} />}
                </div>
            </div>
        </NoDataComponent>
    );
};

MeetingPlannerTrackerContent.propTypes = {
    widgetData: PropTypes.shape({
        documents: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired
            }).isRequired
        )
    })
};

export default MeetingPlannerTrackerContent;
