import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';

import TextInput from '../../../ReportingShareholderListPage/components/TextInput';
import keyCodes from 'Constants/key-codes';

@injectIntl
class SearchRangeComponents extends Component {
    static propTypes = {
        shareRange: PropTypes.shape({
            start: PropTypes.object,
            end: PropTypes.object,
            increment: PropTypes.object
        }),
        handlers: PropTypes.shape({
            onEnter: PropTypes.func,
            onChange: PropTypes.func
        }),
        intl: intlShape
    };

    constructor(props) {
        super(props);
        const {
            shareRange: {
                start: startField,
                end: endField,
                increment: incrementField
            }
        } = props;

        this.state = {
            start: this.toFormattedNumber(startField.input.value),
            end: this.toFormattedNumber(endField.input.value),
            increment: this.toFormattedNumber(incrementField.input.value)
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            shareRange: { start, end, increment }
        } = nextProps;

        this.reinitializeState({
            start,
            end,
            increment
        });
    }

    handleTextChange = (e) => {
        const name = e.target.name.split('.').pop();
        const value = e.target.value;

        if (value.length) {
            const regExp = name === 'increment' ?
                /^(?:0|[1-9][0-9]{0,8})$/
                : /^(?:0|[1-9][0-9]{0,8})?(?:\.[0-9]{0,3})?$/;

            const isValid = regExp.test(value);

            if (!isValid) return;
        }
        this.setState({
            ...this.state,
            [name]: value
        });

        this.props.handlers.onChange(name);
        this.props.shareRange[name].input.onChange(e);
    };

    handleFocus = (e) => {
        const name = e.target.name.split('.').pop();
        const value = e.target.value.replace(/,/g, '');

        this.setState({
            ...this.state,
            [name]: value
        });

        this.props.shareRange[name].input.onFocus(e);
    };

    handleKeyDown = (e) => {
        if (e.keyCode === keyCodes.enter) {
            const activeField = _.find(this.props.shareRange, (field) => field.meta.active);
            const activeName = activeField && activeField.input.name.split('.').pop();
            const activeValue = this.state[activeName];

            activeField.input.onChange(activeValue);

            this.props.handlers.onEnter(activeName, _.toNumber(activeValue));
        }
    };

    toFormattedNumber = (value) => {
        return value ?
            this.props.intl.formatNumber(value, {
                minDecimalPoints: 0,
                maxDecimalPoints: 3
            }) : value;
    };

    reinitializeState = (fields) => {
        const newState = {};

        _.forOwn(fields, (value, key) => {
            if (!fields[key].meta.active && !_.isNaN(fields[key].input.value)) {
                newState[key] = this.toFormattedNumber(fields[key].input.value);
            }
        });

        this.setState({ ...newState });
    };

    render() {
        const { shareRange: { start, end, increment }, intl: { formatMessage } } = this.props;

        return (
            <div className='reporting-section-body'>
                <TextInput
                    {...start.input}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleTextChange}
                    onFocus={this.handleFocus}
                    error={start.meta.touched && start.meta.error}
                    label={formatMessage({ id: 'reporting.share.range.analysis.shareRange.start' })}
                    labelType='vertical'
                    className='share-range-input start'
                    autoComplete={false}
                    value={this.state.start}/>
                <div className='share-range-input-delimiter'>
                    <span>{formatMessage({ id: 'reporting.share.range.analysis.shareRange.to' })}</span>
                </div>
                <TextInput
                    {...end.input}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleTextChange}
                    onFocus={this.handleFocus}
                    error={end.meta.touched && end.meta.error}
                    label={formatMessage({ id: 'reporting.share.range.analysis.shareRange.end' })}
                    labelType='vertical'
                    className='share-range-input end'
                    autoComplete={false}
                    value={this.state.end}/>
                <TextInput
                    {...increment.input}
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleTextChange}
                    onFocus={this.handleFocus}
                    error={increment.meta.touched && increment.meta.error}
                    label={formatMessage({ id: 'reporting.share.range.analysis.shareRange.increment' })}
                    labelType='vertical'
                    className='share-range-input increment block'
                    autoComplete={false}
                    value={this.state.increment}/>
            </div>
        );
    }
}

SearchRangeComponents.parse = (value) => {
    return value === '' || value === '.' ? null : _.toNumber(value.replace(/,/g, ''));
};

export default SearchRangeComponents;
