import moment from 'moment';

import formatterOptions from 'Constants/formatter-options';
import { round } from 'Utils/utils';
import { despace } from 'Utils/string';
import dateFormats from 'Constants/date-formats';
import { bytesToSize } from './utils';

// number => number

export const scaleFormatter = (value, scale = 0) => {
    if (scale) {
        return value / scale;
    }

    return value;
};

export const thresholdFormatter = (value, threshold = formatterOptions.THRESHOLD) => {
    return Math.abs(value) > threshold ? value : (Math.sign(value) * threshold);
};

export const fileSizeFormatter = (value, decimalPoints = 2) => {
    return bytesToSize(value, decimalPoints);
};

// string => string

export const symbolFormatter = (value, symbol = null, right = false, separately = false) => {
    if (symbol) {
        if (right) {
            return `${value}${separately ? ' ' : ''}${symbol}`;
        }
        return `${symbol}${separately ? ' ' : ''}${value}`;
    }

    return `${value}`;
};

// number => string

export const changeFormatter = (intl, value, params = {}) => {
    const formatNumber = intl.formatNumber;
    const {
        decimalPoints = formatterOptions.DEFAULT_DECIMAL_POINTS,
        negativeWithBrackets = true,
        withRound = true,
        isOriginalValue = false
    } = params;
    let formattedNumber;

    if (isOriginalValue) {
        if (negativeWithBrackets) {
            formattedNumber = formatNumber(Math.abs(value), { minimumFractionDigits: 0, maximumFractionDigits: 20 });
        } else {
            formattedNumber = formatNumber(value, { minimumFractionDigits: 0, maximumFractionDigits: 20 });
        }
    } else {
        formattedNumber = withRound
            ? formatNumber(Math.abs(round(value, decimalPoints)), { minimumFractionDigits: decimalPoints })
            : formatNumber(value, { minimumFractionDigits: decimalPoints, maximumFractionDigits: formatterOptions.MAX_DECIMAL_POINTS });
    }

    if (value < 0 && negativeWithBrackets) {
        return `(${formattedNumber})`;
    }

    return `${formattedNumber}`;
};

export const numberFormatter = (intl, value, params = {}) => {
    const formatNumber = intl.formatNumber;
    const {
        decimalPoints = formatterOptions.DEFAULT_DECIMAL_POINTS,
        isOriginalValue = false
    } = params;
    let formattedNumber;

    if (isOriginalValue) {
        formattedNumber = formatNumber(value, { minimumFractionDigits: 0, maximumFractionDigits: 20 });
    } else {
        formattedNumber = formatNumber(value, { minimumFractionDigits: decimalPoints });
    }

    return `${formattedNumber}`;
};

/**
 *
 * @param intl
 * @param value
 * @param decimalPoints
 * @param options - additional options
 * @param options.currency
 * @param options.right
 * @param options.separately
 * @param options.negativeWithBrackets
 * @param options.withRound
 * @param options.isOriginalValue
 * @return {*}
 */
export const currencyFormatter = (intl, value, decimalPoints, options) => {
    const changeValue = changeFormatter(intl, value, {
        decimalPoints,
        negativeWithBrackets: options.negativeWithBrackets,
        withRound: options.withRound,
        isOriginalValue: options.isOriginalValue
    });

    return symbolFormatter(changeValue, options.currency, options.right, options.separately);
};

// date => string

export const dateFormatter = (value, format = dateFormats.default) => {
    // by default moment parses local time, but BE sends not
    // problem with timezones should be clarified by BA
    const date = moment.utc(value);
    let formattedDate = null;

    if (value) {
        formattedDate = date.isValid() ? date.format(format) : value;
    }

    return formattedDate;
};

export const securityFullNameFormatter = (securityName, ticker, cusip, issuerId) => {
    const despacedSecurityName = despace(securityName);
    const despacedTicker = despace(ticker);
    const despacedCusip = despace(cusip);
    const despacedIssuerId = despace(issuerId);

    const tickerPart = despacedTicker ? ` (${despacedTicker})` : '';
    const cusipPart = despacedCusip ? ` - ${despacedCusip}` : '';

    return (despacedTicker || despacedCusip)
        ? `${despacedSecurityName}${tickerPart}${cusipPart}`
        : `${despacedSecurityName} - ${despacedIssuerId}`;
};

