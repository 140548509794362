import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { mapProps } from '@shakacode/recompose';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import TableEasy from 'Components/Tables/TableEasy';
import sortDirections from 'Constants/sort-directions';
import shareholderTypes from 'Constants/shareholder-types';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import { getSortDirection } from 'Components/Tables/utils';
import { NumberFormatter, PercentFormatter, NullableFormatter } from 'Components/formatters';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import TableHeaderFilter from '../components/TableHeaderFilter';
import columnDefinitions, { columnNames } from '../constants/columnDefinitions';
import { LOCATION_FILTER_VALUES } from '../constants/locationFilterValues';
import { HEADER_ROW_HEIGHT, BODY_ROW_HEIGHT, DECIMAL_POINTS } from '../constants/constantValues';
import { INFO_SIGN } from 'Constants/symbol-constants';
import { sortArray } from 'Utils/utils';

const shareholderTypesForShowingTotalNote = [
    shareholderTypes.ALL,
    shareholderTypes.INSIDER,
    shareholderTypes.INSTITUTION,
    shareholderTypes.INSTITUTION_AND_INSIDER
];
const NO_ROUND_VALUES = {
    [columnNames.PERCENT_OF_ACCOUNTS]: columnNames.PERCENT_OF_ACCOUNTS_NO_ROUND,
    [columnNames.PERCENT_OF_SHARES]: columnNames.PERCENT_OF_SHARES_NO_ROUND
};

const sortTableData = (tableData, sortOptions) => {
    const sortingField = _.includes([columnNames.PERCENT_OF_ACCOUNTS, columnNames.PERCENT_OF_SHARES], sortOptions.field)
        ? NO_ROUND_VALUES[sortOptions.field]
        : sortOptions.field;

    return sortArray({
        array: tableData,
        primaryField: sortingField,
        descending: sortOptions.direction === sortDirections.DESC,
        secondaryField: columnDefinitions.columnNames.LOCATION,
        secondaryDescending: false,
        initial: sortOptions.initial
    });
};

@injectIntl
@mapProps(props => {
    const sort = _.get(props, 'widgetSettings.sortCriteria');
    const locationFilterType = _.get(props, 'settings.locationFilterType');
    const filterOptions = [];

    let tableData = _.get(props, 'widgetData.tableData', [])
        .filter(row => locationFilterType === LOCATION_FILTER_VALUES.ALL.value ||
            row.locationType === locationFilterType);

    tableData = sortTableData(tableData, sort);

    _.forOwn(LOCATION_FILTER_VALUES, option => {
        if (option !== LOCATION_FILTER_VALUES.ALL) {
            filterOptions.push({
                label: (<span className='label-text'>{props.intl.formatMessage({ id: option.title })}</span>),
                name: option.value,
                isSelected: locationFilterType === LOCATION_FILTER_VALUES.ALL.value ||
                option.value === locationFilterType
            });
        }
    });

    return {
        ...props,
        tableData,
        filterOptions
    };
})
class GeographicAnalysisContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        columns: PropTypes.array,
        filterOptions: PropTypes.array,
        intl: intlShape,
        settings: PropTypes.object
    };

    handleOnSort = ({ sortBy }) => {
        const { widgetSettings: { sortCriteria: { field: prevSortBy, direction: prevDirection } } } = this.props;
        const sortCriteria = {
            field: sortBy,
            direction: getSortDirection(
                sortBy,
                prevSortBy,
                prevDirection,
                columnDefinitions.columns
            ),
            initial: false
        };

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
    };

    handleFilterSelection = (selection) => {
        const { filterOptions, events: { handleUpdateWidgetParams } } = this.props;
        const selectedOptions = selection.filter(opt => opt.isSelected);
        const locationFilterType = !selectedOptions.length || selectedOptions.length === filterOptions.length ?
            LOCATION_FILTER_VALUES.ALL.value : selectedOptions[0].name;

        handleUpdateWidgetParams({ locationFilterType });
    };

    getBodyCell = (decimalPoints, className, FormatterComponent) => {
        return ({ value, rowData }) => {
            return (<div className={className}>
                <FormatterComponent
                    value={value}
                    decimalPoints={decimalPoints}/>
                {rowData && rowData.cedeCoIncluded && INFO_SIGN}
            </div>);
        };
    };

    getCustomColumnsSettings = (columnName) => {
        switch (columnName) {
            case columnNames.LOCATION:
                return {
                    bodyCellComponent: this.getBodyCell(null, null, NullableFormatter),
                    headerCellComponent: ({ title, dataKey, sortDirection, sortBy }) =>
                        (<TableHeaderFilter
                            title={title}
                            dataKey={dataKey}
                            sortDirection={sortDirection.toUpperCase()}
                            sortBy={sortBy}
                            filterValues={this.props.filterOptions}
                            onHandleFilter={this.handleFilterSelection}/>)
                };
            case columnNames.NUMBER_OF_ACCOUNTS:
            case columnNames.NUMBER_OF_SHARES:
                return {
                    columnClassName: 'text-right',
                    bodyCellComponent: this.getBodyCell(DECIMAL_POINTS[columnName], 'cell-with-number', NumberFormatter)
                };
            case columnNames.PERCENT_OF_ACCOUNTS:
            case columnNames.PERCENT_OF_SHARES:
                return {
                    columnClassName: 'text-right',
                    bodyCellComponent: ({ value, rowData }) => (
                        <div className='cell-with-percent'>
                            <PercentFormatter
                                value={value}
                                decimalPoints={DECIMAL_POINTS.percents}
                                titleValue={rowData[NO_ROUND_VALUES[columnName]]}
                                withTitle/>
                            {rowData && rowData.cedeCoIncluded && INFO_SIGN}
                        </div>)
                };
            default:
                return {};
        }
    };

    getColumns = () => {
        const visibleColumns = _.get(this.props, 'widgetSettings.visibleColumns', []);
        const columns = [];

        _.forEach(columnDefinitions.columns, column => {
            if (visibleColumns.indexOf(column.columnName) > -1 || !column.manageable) {
                const customSettings = this.getCustomColumnsSettings(column.columnName);

                columns.push({
                    ...column,
                    ...customSettings,
                    dataKey: column.columnName,
                    title: this.props.intl.formatMessage({ id: column.displayName })
                });
            }
        });

        return columns;
    };

    render() {
        const { intl: { formatMessage }, widgetData, tableData, isFetching, settings: { shareholderType } = {} } = this.props;
        const { widgetSettings: { sortCriteria: { field: sortField, direction: sortDirection } } } = this.props;
        const hasCedeCoRows = _.some(tableData, row => row.cedeCoIncluded);
        const showTotalNote = _.includes(shareholderTypesForShowingTotalNote, shareholderType) && _.get(tableData, 'length') > 0;

        return widgetData ? (
            <div className='geographic-analysis-content'>
                <TableEasy
                    noDataMessage={'widgets.noDataMessage.matchingResults'}
                    headerRowHeight={HEADER_ROW_HEIGHT}
                    bodyRowHeight={BODY_ROW_HEIGHT}
                    hideBarChart
                    onSort={this.handleOnSort}
                    sortBy={sortField}
                    sortDirection={sortDirection.toUpperCase()}
                    columns={this.getColumns()}
                    data={tableData}
                    showAllRows
                    markTotalOverflow={showTotalNote}
                    aggregate={tableData.length && widgetData.tableAggregate}/>
                <div className='content-footer'>
                    {hasCedeCoRows &&
                    <div className='message-note'>
                        <FormattedMessage id='reporting.geographic.analysis.finePrint'/>
                        <span className='shares-number'>
                            <NumberFormatter
                                value={widgetData.cedeCoSharesNumber}
                                decimalPoints={DECIMAL_POINTS[columnDefinitions.columnNames.NUMBER_OF_SHARES]}/>
                        </span>
                        {formatMessage({ id: 'reporting.geographic.analysis.shares' })}
                    </div>
                    }
                    {showTotalNote &&
                    (<span className='message-note'>
                        <FormattedMessage id='reporting.geographic.analysis.totalNote'/>
                    </span>)}
                </div>
            </div>
        ) : <NoDataComponent
            message={formatMessage({ id: 'widgets.noDataMessage.matchingResults' })}
            isFetching={isFetching}
            isNoData/>;
    }
}

export default GeographicAnalysisContent;
