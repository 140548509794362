import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { reduxForm, getFormValues, SubmissionError } from 'redux-form';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

import { hideModal } from 'State/modal';
import { shareReportTemplate } from 'Hoc';
import SaveReportTemplateModalView from './SaveReportTemplateModalView';
import { reportNameValidation, reportNameNormalize } from '../../../pages/reporting/common/reportNameUtils';
import reportNameErrors from 'Constants/report-name-errors';
import httpStatuses from 'Constants/http-status';
import reportTypes from 'Constants/report-types';

const formName = 'form-save-report';

const mapStateToProps = (state) => ({
    ...state.modal,
    formValues: getFormValues(formName)(state)
});
const mapDispatchToProps = { hideModal };

@connect(mapStateToProps, mapDispatchToProps)
@injectIntl
@reduxForm({
    form: formName,
    validate: (values) => {
        const errors = {};

        errors.reportName = reportNameValidation(values.reportName);

        return errors;
    }
})
@shareReportTemplate
class SaveReportTemplateModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        messageKey: PropTypes.string,
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        okTextKey: PropTypes.string,
        cancelTextKey: PropTypes.string,
        payload: PropTypes.object,
        preventCloseOnSuccess: PropTypes.bool,
        onShare: PropTypes.func,
        userCanShareReportTemplate: PropTypes.bool,
        type: PropTypes.string.isRequired
    };

    handleSaveReport = ({ reportName }) => {
        const { onSuccess, preventCloseOnSuccess, payload, intl: { formatMessage } } = this.props;

        return onSuccess({ ...payload, reportName }).then(
            (res) => {
                if (!preventCloseOnSuccess) {
                    this.props.hideModal();
                }
                toastr.remove();
                toastr.success(
                    formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.title' }),
                    formatMessage({ id: 'reporting.listReport.notifier.templateCreated.message' })
                );

                return res;
            },
            this.errorSaveTemplateHandler
        );
    };

    handleSaveAndShare = (formValues) => {
        const { onShare } = this.props;

        return this.handleSaveReport(formValues).then(res => {
            const reportName = _.get(res, 'data.payload.reportName');
            const reportTemplateId = _.get(res, 'data.payload.reportTemplateId');

            if (reportName && reportTemplateId) {
                onShare({ reportName, reportTemplateId });
            }
        });
    };

    errorSaveTemplateHandler = (error) => {
        const { intl: { formatMessage } } = this.props;
        const response = _.get(error, 'response', {});
        const reason = _.get(response, 'data.reasonType', '');
        const errorMessage = reportNameErrors[reason];

        if (response.status === httpStatuses.HTTP_400 && errorMessage) {
            throw new SubmissionError({ reportName: formatMessage({ id: errorMessage }) });
        }
    };

    render() {
        const { intl: { formatMessage }, userCanShareReportTemplate } = this.props;

        const reportType = _.get(this.props, 'payload.type');
        const isMultiSecurityReport = _.get(this.props, 'payload.form.securities.length', 0) > 1;
        const canShare = !isMultiSecurityReport && userCanShareReportTemplate && reportType === reportTypes.shareholderListTemplate;

        const newProps = {
            ...this.props,
            okText: formatMessage({ id: 'common.save' }),
            cancelText: formatMessage({ id: 'common.cancel' }),
            saveAndShareText: formatMessage({ id: 'common.saveAndShare' }),
            onSuccess: this.handleSaveReport,
            onCancel: this.props.hideModal,
            onSaveAndShare: this.handleSaveAndShare,
            reportNameNormalize,
            canShare
        };

        return (
            <SaveReportTemplateModalView {...newProps}/>
        );
    }
}

export default SaveReportTemplateModal;
