import PropTypes from 'prop-types';
import React from 'react';
import { Layout, VerticalLayout, HorizontalLayout } from '../../layout';
import Slot from '../../Slot';

import './LayoutThreeColumns.scss';

const LayoutThreeColumns = (props) => {
    const { slots } = props;

    const topSlotsExist = slots[1] || slots[2] || slots[3];

    return (
        <Layout className='layout-three-columns'>
            {topSlotsExist && (
                <div className='three-column-container row flexbox'>
                    {slots[1] && (
                        <VerticalLayout className='column'>
                            <Slot
                                {...slots[1]}
                                slotId={1}/>
                        </VerticalLayout>
                    )}

                    {slots[2] && (
                        <VerticalLayout className='column'>
                            <Slot
                                {...slots[2]}
                                slotId={2}/>
                        </VerticalLayout>
                    )}

                    {slots[3] && (
                        <VerticalLayout className='column'>
                            <Slot
                                {...slots[3]}
                                slotId={3}/>
                        </VerticalLayout>
                    )}
                </div>
            )}

            <div className='row flexbox'>
                <HorizontalLayout className='column'>
                    <Slot
                        {...slots[4]}
                        slotId={4}/>
                </HorizontalLayout>
            </div>
        </Layout>
    );
};

LayoutThreeColumns.propTypes = {
    slots: PropTypes.object
};

export default LayoutThreeColumns;
