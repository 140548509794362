import PropTypes from 'prop-types';
import React from 'react';

import { peerShape } from 'Constants/peer';
import Spinner from 'Components/Spinner';
import Icon from 'Components/Icon';
import { getPeerDescription } from 'Utils/peers';

const PeerList = ({ peers, onRemovePeer, isFetching }) => (
    <ul className='list-of-peers'>
        {peers.map(peer => (
            <li className='peer' key={peer.id}>
                <span className='peer-description'>{getPeerDescription(peer)}</span>
                <Icon
                    className='font-icon font-icon-delete'
                    onClick={() => {
                        onRemovePeer(peer);
                    }}/>
            </li>
        ))}
        <Spinner isBlocker isFetching={isFetching}/>
    </ul>
);

PeerList.propTypes = {
    peers: PropTypes.arrayOf(peerShape),
    onRemovePeer: PropTypes.func,
    isFetching: PropTypes.bool
};

export default PeerList;
