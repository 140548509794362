import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import cn from 'classnames';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import WidgetPrint from '../../Widget/components/WidgetPrint/WidgetPrint';
import Icon from 'Components/Icon';

import './RegisteredOverviewControlPanel.scss';

@mapProps(props => ({
    ...props,
    onExport: _.get(props, 'widgetControlPanelSettings.onExport'),
    onColumnsManagerClick: _.get(props, 'widgetControlPanelSettings.onColumnsManagerClick'),
    hideColumnsManager: _.get(props, 'widgetControlPanelSettings.hideColumnsManager'),
    inactive: _.get(props, 'widgetControlPanelSettings.inactive'),
    exportAvailable: _.get(props, 'widgetControlPanelSettings.exportAvailable'),
    getWidgetHeight: _.get(props, 'widgetControlPanelSettings.getWidgetHeight')
}))
class RegisteredOverviewControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        widgetControlPanelSettings: PropTypes.shape({
            onPrint: PropTypes.func,
            onExport: PropTypes.func,
            onColumnsManagerClick: PropTypes.func,
            hideColumnsManager: PropTypes.bool,
            inactive: PropTypes.bool
        })
    };

    render() {
        const {
            onExport,
            onColumnsManagerClick,
            widgetType,
            hideColumnsManager,
            inactive,
            exportAvailable,
            getWidgetHeight
        } = this.props;
        const exportIconClass = cn('font-icon font-icon-download', { inactive });

        return (
            <div className='registered-overview-control-panel'>
                <WidgetPrint
                    widgetType={widgetType}
                    getWidgetHeight={getWidgetHeight}/>
                {exportAvailable &&
                <Icon
                    className={exportIconClass}
                    onClick={inactive ? null : onExport}/>}
                {!hideColumnsManager &&
                (<Icon
                    className='font-icon font-icon-view-three-columns'
                    onClick={onColumnsManagerClick}/>)}
            </div>
        );
    }
}

export default RegisteredOverviewControlPanel;
