import { get } from 'lodash';

import { getQueryParameterByName } from './routing';
import reportNameErrors from 'Constants/report-name-errors';
import httpStatus from 'Constants/http-status';

export function isDatavizExport() {
    return !!getQueryParameterByName('dataviz_api_url');
}

// Notify Selenium Webdriver that page is ready for taking widgets screenshots
export function notifyWebdriverAllWidgetsLoaded() {
    window.datavizExportReady = true;
}

// Notify Selenium Webdriver that page is failed
export function notifyWebdriverPageFail(error) {
    window.datavizExportError = JSON.stringify(error);
}

export function isReportNameError(error) {
    const statusCode = get(error, 'response.status');
    const reason = get(error, 'response.data.reasonType', '');

    return statusCode === httpStatus.HTTP_400 && reportNameErrors[reason];
}
