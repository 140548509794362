import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { NullableFormatter } from 'Components/formatters';

const CellWithHolding = (props) => {
    const { value, className, additionalData: { currentSecurityId, withTitle = true } = {}, rowData } = props;
    const cellClass = cn('cell-with-holding',
        { 'current-company': currentSecurityId === +rowData.companyId },
        className);

    return (
        <div
            title={value}
            className={cn(cellClass, 'ellipsis')}>
            <NullableFormatter value={value} withTitle={withTitle}/>
        </div>
    );
};

CellWithHolding.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    rowData: PropTypes.object,
    additionalData: PropTypes.object
};

export default CellWithHolding;
