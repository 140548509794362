import PropTypes from 'prop-types';
import React from 'react';
import { fromPairs } from 'lodash';

import TableStickyColumn from '../TableStickyColumn';
import positionRenderer from './components/positionRenderer';
import numberRenderer from './components/numberRenderer';

import './TableStickyColumnGroups.scss';

const TableStickyColumnGroups = ({ data, periods, showAllRows }) => {
    const columns = [{
        dataKey: 'position',
        headerCell: 'Position',
        sticky: true,
        width: 150,
        bodyCellRenderer: positionRenderer,
        columnClassName: 'text-left position-column'
    },
    ...periods.map(p => ({
        dataKey: p,
        headerCell: p,
        width: 100,
        bodyCellRenderer: numberRenderer
    }))
    ];


    const mappedData = data.map(r => ({
        position: r.isAggregate ? { isAggregate: true, name: r.name } : r.name,
        ...fromPairs(r.values.map(v => ([v.period, v.value])))
    }));

    return (
        <TableStickyColumn
            className='text-right table-sticky-column-group'
            data={mappedData}
            showAllRows={showAllRows}
            columns={columns}/>
    );
};


TableStickyColumnGroups.propTypes = {
    periods: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(
            PropTypes.shape({
                period: PropTypes.string,
                value: PropTypes.number
            })
        ).isRequired
    })),
    showAllRows: PropTypes.bool
};

export default TableStickyColumnGroups;
