import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';

import Icon from 'Components/Icon';
import Popup from 'Components/Popup';
import reportTypes from 'Constants/report-types';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import featureTypes from 'Constants/feature-types';

import ShareTooltip from './ShareTooltip';

import './SavedReportTemplates.scss';

const isDataVizIcon = reportType => reportType === reportTypes.dataVizTemplate;
const getReportIconClass = reportType => cn('font-icon report-type-icon', {
    'font-icon-view-list': !isDataVizIcon(reportType),
    'font-icon-view-pie-chart': isDataVizIcon(reportType)
});

const SavedReportTemplatesView = (props) => {
    const { intl: { formatMessage }, reports, isFetching, onEdit, onDelete, onRunReport, currentFeatures, onShare, userCanShareReportTemplate } = props;

    const isPossibleEditReport = (reportType) => {
        switch (reportType) {
            case reportTypes.dataVizTemplate: {
                return currentFeatures[featureTypes.CUSTOM_REPORTS];
            }
            case reportTypes.shareholderListTemplate: {
                return currentFeatures[featureTypes.SHAREHOLDER_LIST];
            }
            case reportTypes.shareDetailTemplate: {
                return currentFeatures[featureTypes.SHARE_DETAIL];
            }
            default: {
                return true;
            }
        }
    };

    const isSharableReport = (report) => {
        return report.type === reportTypes.shareholderListTemplate && userCanShareReportTemplate && report.securityCount === 1;
    };

    const isPossibleExportReport = (reportType) => reportType !== reportTypes.dataVizTemplate;

    return (
        <NoDataComponent
            isFetching={isFetching}
            isNoData={!reports.length}
            message={formatMessage({ id: 'saved.reports.noData' })}>
            <div className='saved-reports-list'>
                {reports.map(report =>
                    (<div
                        key={report.reportTemplateId}
                        className='report'>
                        <Icon className={getReportIconClass(report.type)}/>
                        {
                            isPossibleExportReport(report.type) ?
                                <Popup
                                    content={<span>{formatMessage({ id: 'saved.reports.run' })} {report.reportName}</span>}>
                                    <div
                                        className='report-name ellipsis'
                                        onClick={() => onRunReport(report)}>
                                        {report.reportName}
                                    </div>
                                </Popup>
                                :
                                <div
                                    className='report-name ellipsis'
                                    onClick={() => !report.isActive ? onEdit(report) : null}>
                                    {report.reportName}
                                </div>
                        }
                        <div className='report-action-icons'>
                            {isSharableReport(report) && (
                                <Popup content={<ShareTooltip reportName={report.reportName} />}>
                                    <Icon className='font-icon font-icon-person-add action-icon' withoutTitle onClick={() => onShare(report)} />
                                </Popup>
                            )}
                            {
                                isPossibleEditReport(report.type) &&
                                <Popup
                                    content={<span>{formatMessage({ id: 'saved.reports.edit' })} {report.reportName}</span>}>
                                    <Icon
                                        className={cn('font-icon', 'font-icon-edit', 'action-icon', { inactive: report.isActive })}
                                        onClick={() => !report.isActive ? onEdit(report) : null}
                                        withoutTitle/>
                                </Popup>
                            }
                            <Popup
                                content={<span>{formatMessage({ id: 'saved.reports.delete' })} {report.reportName}</span>}>
                                <Icon
                                    className='font-icon font-icon-delete action-icon'
                                    onClick={() => onDelete(report)}
                                    withoutTitle/>
                            </Popup>
                        </div>
                    </div>)
                )}
            </div>
        </NoDataComponent>
    );
};

SavedReportTemplatesView.propTypes = {
    widgetNoData: PropTypes.bool,
    isFetching: PropTypes.bool,
    reports: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onRunReport: PropTypes.func,
    intl: intlShape,
    currentFeatures: PropTypes.object,
    onShare: PropTypes.func,
    userCanShareReportTemplate: PropTypes.bool
};

export default injectIntl(SavedReportTemplatesView);
