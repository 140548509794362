/* eslint-disable react/no-multi-comp */
import React from 'react';

import ShareholderCellWithIcon from '../components/cellRenderers/ShareholderCellWithIcon';
import { TextCell } from '../components/cellRenderers/TextCell';

export default [
    {
        accessor: 'shareholderInfo',
        minWidth: 220,
        Cell: ShareholderCellWithIcon
    },
    {
        accessor: 'issuerName',
        minWidth: 100,
        Cell: args => <TextCell {...args}/>
    },
    {
        accessor: 'securityName',
        minWidth: 120,
        Cell: args => <TextCell {...args}/>
    }
];
