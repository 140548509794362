import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { CheckBox } from 'Components/CheckBox';
import './SearchAcrossSelector.scss';

class SearchAcrossSelector extends Component {
    static propTypes = {
        hasSelectedSecurity: PropTypes.bool.isRequired,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        checkCurrentSecurity: PropTypes.func,
        uncheckCurrentSecurity: PropTypes.func,
        intl: intlShape
    };

    handleSelectCurrent = () => {
        if (this.props.checked) {
            this.props.uncheckCurrentSecurity();
        } else {
            this.props.checkCurrentSecurity();
        }
    };

    render() {
        const { hasSelectedSecurity, checked, disabled, intl: { formatMessage } } = this.props;

        return (
            <div className='search-across-selector'>
                {hasSelectedSecurity &&
                <CheckBox
                    name='searchAcrossCurrent'
                    className='search-across-option'
                    label={formatMessage({ id: 'search.across.current' })}
                    checked={checked}
                    disabled={disabled}
                    onChange={this.handleSelectCurrent}/>}
            </div>
        );
    }
}

export default SearchAcrossSelector;
