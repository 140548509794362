import queryString from 'query-string';
import _ from 'lodash';

import pageTitleKeys from './constants/pageTitleKeys';

export const mapComponentProps = ({
    intl: { formatMessage },
    location: { search },
    name,
    defaultTo
}) => {
    const { ret: pathname, retSearch: searchQuery } = queryString.parse(search);
    const to = {};
    let titleKey;

    // if current url contains ref to back use it, else defaultTo
    if (pathname) {
        to.pathname = atob(decodeURIComponent(pathname));
        if (searchQuery) {
            to.search = atob(decodeURIComponent(searchQuery));
        }
        titleKey = name || to.pathname;
    } else {
        to.pathname = defaultTo;
        titleKey = name || defaultTo;
    }

    const pageTitleKey = _.find(_.keys(pageTitleKeys), item => titleKey && titleKey.includes(item));

    if (pageTitleKey) {
        return {
            to,
            name: formatMessage(
                { id: 'common.backTo' },
                { name: formatMessage({ id: pageTitleKeys[pageTitleKey] }) }
            )
        };
    }
};
