import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';
import { connect } from 'react-redux';

import registeredReportTemplates from '../constants/registered-report-templates';
import reportTypes from 'Constants/report-types';
import widgetTypes from 'Constants/widget-types';
import { runReport, showExportWidgetModal, showExportRegisteredOverviewModal } from 'State/exportReports';
import { getRegisteredOverviewTdProps } from '../utils';
import sortingRules from '../constants/sorting-rules';
import RegisteredFilterPanel from '../../../_commonComponents/RegisteredShareholderTable/components/RegisteredFilterPanel';
import { showConfirmModal } from 'State/modal';
import { getMaxRegisteredOverviewRecords } from 'State/user';
import { getSortDirection } from 'Components/Tables/utils';
import { PAGE_SIZE } from '../constants/page-settings';

const mapStateToProps = state => {
    return {
        maxRecords: getMaxRegisteredOverviewRecords(state)
    };
};
const mapDispatchToProps = {
    showExportRegisteredOverviewModal,
    showConfirmModal,
    showExportWidgetModal,
    runReport
};

export default WrappedComponent => {
    @connect(mapStateToProps, mapDispatchToProps)
    class WithFilteredData extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'withFilteredData');

        static propTypes = {
            ...WrappedComponent.propTypes
        };

        constructor(props) {
            super(props);
            const dateRange = {
                startDate: null,
                endDate: null
            };

            this.widgetEvents = {
                onBuildDataParams: () => ({
                    shareholderId: _.get(this.props, 'widgetPageParams.shareholderId'),
                    filterCriteria: {
                        currentPage: 1,
                        pageSize: PAGE_SIZE,
                        // for first request to avoid getting specific date range with data
                        requestEndDate: WithFilteredData.isNeededToUpdateEndDate(dateRange),
                        dateRange
                    },
                    sortCriteria: _.get(this.props, 'widgetSettings.sortCriteria')
                })
            };
        }

        componentDidMount() {
            const { onUpdateWidgetData, redirectOn404 } = this.props;
            const widgetDataParams = _.isFunction(this.widgetEvents.onBuildDataParams) ? this.widgetEvents.onBuildDataParams({}) : {};

            onUpdateWidgetData(widgetDataParams, { redirectOn404 });
        }

        handleExport = () => {
            const { widgetSettings = {}, widgetType, widgetPageParams, widgetData } = this.props;
            const sortCriteria = _.get(widgetSettings, 'sortCriteria', {});
            const selectedColumns = _.get(widgetSettings, 'selectedColumns', []);
            const reportTemplateType = reportTypes[registeredReportTemplates[widgetType]];
            const defaultSortCriteria = sortingRules[reportTemplateType];
            const shareholderId = _.get(widgetPageParams, 'shareholderId');
            const dateRange = _.get(widgetData, 'filterCriteria.dateRange');
            const currentPage = _.get(widgetData, 'filterCriteria.currentPage');

            this.props.showExportRegisteredOverviewModal({
                reportTemplateType,
                exportParams: {
                    shareholderId,
                    defaultSortCriteria,
                    dateRange,
                    currentPage,
                    pageSize: PAGE_SIZE,
                    // todo: in future properties of sortCriteria object will be rename
                    // from { sortDirection: ..., sortField: ... } to { direction: ..., field: ... }
                    sortCriteria,
                    columnsToDisplay: selectedColumns
                }
            });
        };

        handleChangeCurrentPage = (currentPage) => {
            const { widgetSettings: { sortCriteria }, widgetData } = this.props;
            const dateRange =  _.get(widgetData, 'filterCriteria.dateRange');

            this.updateWidgetData({ dateRange, currentPage, sortCriteria });
        };

        handleChangeDateRange = ({ dateRange: input }) => {
            const { widgetSettings: { sortCriteria } } = this.props;
            const dateRange = {
                startDate: _.get(input, 'startDate') || null,
                endDate: _.get(input, 'endDate') || null
            };

            return this.updateWidgetData({ dateRange, currentPage: 1, sortCriteria });
        };

        handleChangeSortCriteria = (sortBy, columnsDefinitions) => {
            const { widgetSettings: { sortCriteria: { sortField: prevSortBy, sortDirection: prevDirection } }, widgetData: { filterCriteria: { currentPage, dateRange } } } = this.props;
            const sortCriteria = {
                sortField: sortBy,
                sortDirection: getSortDirection(
                    sortBy,
                    prevSortBy,
                    prevDirection,
                    columnsDefinitions
                )
            };

            this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
            this.updateWidgetData({ sortCriteria, currentPage, dateRange });
        };

        getDefaultTdProps = columnType => ({
            ...getRegisteredOverviewTdProps(columnType)
        });

        getNoDataMessageId = () => {
            const { widgetType } = this.props;
            const isDataEmpty = this.isDataWithoutFiltersEmpty();

            switch (widgetType) {
                case widgetTypes.registeredShareholderDRDSPP:
                case widgetTypes.registeredShareholderPayments:
                case widgetTypes.registeredShareholderTransactionHistory: {
                    return `registered.shareholder.information.noData.${widgetType}.${isDataEmpty ? 'common' : 'byDateRange'}`;
                }
                default: {
                    return 'widgets.noDataMessage.matchingSelectedDateRange';
                }
            }
        };

        static isNeededToUpdateEndDate(dateRange) {
            return !!(dateRange.startDate && !dateRange.endDate);
        }

        updateWidgetData = ({ sortCriteria, currentPage, dateRange }) => this.props.onUpdateWidgetData({
            ...this.props.widgetPageParams,
            filterCriteria: {
                // let getting date range in response
                requestEndDate: WithFilteredData.isNeededToUpdateEndDate(dateRange),
                dateRange,
                currentPage,
                pageSize: PAGE_SIZE
            },
            sortCriteria
        });

        isDataWithoutFiltersEmpty = () => {
            const { widgetData } = this.props;
            const isThereSettedDateRange =
                !!_.get(widgetData, 'filterCriteria.dateRange.endDate')
                || !!_.get(widgetData, 'filterCriteria.dateRange.startDate');
            const isThereSomeData = !!_.get(widgetData, 'values.length');

            return !isThereSettedDateRange && !isThereSomeData;
        };

        render() {
            return (
                <WrappedComponent
                    widgetEvents={this.widgetEvents}
                    onExport={this.handleExport}
                    getDefaultTdProps={this.getDefaultTdProps}
                    filterPanel={RegisteredFilterPanel}
                    onChangeCurrentPage={this.handleChangeCurrentPage}
                    pageSize={PAGE_SIZE}
                    disableDateRangePicker={this.isDataWithoutFiltersEmpty()}
                    onChangeDateRange={this.handleChangeDateRange}
                    onChangeSortCriteria={this.handleChangeSortCriteria}
                    noDataMessageId={this.getNoDataMessageId()}
                    isServerSorting
                    {...this.props}/>
            );
        }
    }

    return WithFilteredData;
};
