// used for passing format as string
export const abbreviationKeys = {
    thousands: 'M',
    millions: 'MM'
};

export const abbreviationValues = {
    [abbreviationKeys.thousands]: {
        localization: 'numberAbbreviation.thousands',
        divider: 1000
    },
    [abbreviationKeys.millions]: {
        localization: 'numberAbbreviation.millions',
        divider: 1000000
    }
};
