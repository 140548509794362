import PropTypes from 'prop-types';
import React from 'react';

const NoGroupHeaderCell = ({ noSelectedText }) => {
    return (
        <div className='no-group-header-cell'>
            <span className='label'>{noSelectedText}</span>
        </div>
    );
};

NoGroupHeaderCell.propTypes = {
    noSelectedText: PropTypes.string
};

export default NoGroupHeaderCell;
