import { LOCATION_CHANGE } from 'connected-react-router';

import { getQueryParameterByName } from 'Utils/routing';
import * as utils from 'Utils/utils';
import * as userActions from 'State/user/actions';
import * as userSelectors from 'State/user/selectors';

export const locationMiddleware = store => next => action => {
    if (action.type === LOCATION_CHANGE) {
        const securityId = getQueryParameterByName('security');
        const newCustomSecurityId = utils.normalizeSecurityId(securityId);
        const currentCustomSecurityId = userSelectors.getCustomSecurityIdSelector(store.getState());

        // custom security changed
        if (currentCustomSecurityId !== newCustomSecurityId) {
            store.dispatch(userActions.changeCustomSecurity(newCustomSecurityId));
        }
    }

    return next(action);
};
