import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BackLinkView from './BackLinkView';

class BackLink extends Component {
    static propTypes = {
        name: PropTypes.string,
        to: PropTypes.object
    };

    render() {
        const { name, to } = this.props;

        return to && to.pathname ? (
            <BackLinkView name={name} to={to}/>
        ) : null;
    }
}

export default BackLink;
