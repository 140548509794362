import React, { useEffect } from 'react';
import PropsTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { branch, compose, renderNothing } from '@shakacode/recompose';
import { connect } from 'react-redux';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';

import {
    isErrorRecentSearchSelector,
    isLoadingRecentSearchSelector,
    recentSearchDataSelector,
    isEmptyRecentSearchSelector
} from 'State/recentSearch/selectors';
import * as recentSearchActions from 'State/recentSearch/actions';
import { getCurrentFeaturesSelector } from 'State/features';
import featureTypes from 'Constants/feature-types';
import { RecentSearchList } from './RecentSearchList';
import Spinner from 'Components/Spinner';
import Icon from 'Components/Icon';
import Popover from 'Components/Popover';

import './RecentSearch.scss';

const RecentSearchView = ({
    shareholders,
    getRecentSearch,
    className,
    isLoading = false,
    isEmpty = false,
    hideLoader = false,
    returnPath
}) => {
    useEffect(() => {
        getRecentSearch();
    }, []);

    const isShowNothing = !isLoading && isEmpty;
    const isShowLoader = isLoading && !(hideLoader && !isEmpty);

    return (
        <div className={cn('recent-search', className)}>
            <div className='recent-search__header'>
                <FormattedMessage id='recentSearch.header'>
                    {(txt) => <span className='recent-search__title'>{txt}</span>}
                </FormattedMessage>
                <Popover
                    id='RECENT-SEARCH-POPOVER'
                    trigger={<Icon className='font-icon font-icon-info-outline' />}
                    triggerType={['click', 'hover']}
                    placement='top'
                    className='recent-search-popover'>
                    <FormattedMessage id='recentSearch.tooltip' />
                </Popover>
            </div>
            {isShowLoader && <Spinner isFetching className='recent-search__spinner' />}
            {isShowNothing && (
                <FormattedMessage id='recentSearch.noResults' >
                    {(txt) => <div className='recent-search__no-result'>{txt}</div>}
                </FormattedMessage>
            )}
            {!isShowLoader && <RecentSearchList shareholders={shareholders} returnPath={returnPath} />}
        </div>
    );
};

RecentSearchView.propTypes = {
    shareholders: PropsTypes.array,
    className: PropsTypes.string,
    getRecentSearch: PropsTypes.func,
    isLoading: PropsTypes.bool,
    isEmpty: PropsTypes.bool,
    returnPath: PropsTypes.object,
    hideLoader: PropsTypes.bool
};

const mapStateToProps = state => ({
    shareholders: recentSearchDataSelector(state),
    isLoading: isLoadingRecentSearchSelector(state),
    isError: isErrorRecentSearchSelector(state),
    isEmpty: isEmptyRecentSearchSelector(state),
    userFeatures: getCurrentFeaturesSelector(state)
});

const mapDispatchToProps = {
    getRecentSearch: recentSearchActions.getRecentSearch
};


export const RecentSearch = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    branch(
        ({ userFeatures }) => !userFeatures[featureTypes.RECENT_SEARCH],
        renderNothing
    )
)(RecentSearchView);
