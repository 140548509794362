import * as _ from 'lodash';
import { getColorsData } from '../components/widgets/_commonComponents/AnalysisChart/utils';

export const colorizeNewShareholder = (shareholder, selectedShareholders, colorBarState) => {
    // remove if exists, else add
    const isExists = selectedShareholders.some((sh) => sh.shareholderId === shareholder.shareholderId);
    const newShareholders = isExists
        ? _.filter(selectedShareholders, (sh) => sh.shareholderId !== shareholder.shareholderId)
        : selectedShareholders.concat(shareholder);
    const colorsData = getColorsData(colorBarState, mapToIds(newShareholders));

    const newColorizedShareholders = newShareholders.map(
        ({
            shareholderId,
            shareholderType,
            shareholderName,
            notTop100
        }) =>
            ({
                shareholderId,
                shareholderType,
                shareholderName,
                notTop100,
                color: colorsData.state[shareholderId]
            })
    );

    return { colorsData, newShareholders: newColorizedShareholders };
};

const mapToIds = shs => shs.map(sh => sh.shareholderId);
