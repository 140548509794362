import PropTypes from 'prop-types';
import { getContext, mapProps, compose } from '@shakacode/recompose';

const WidgetLayoutEnhancer = (component) => compose(
    getContext({
        components: PropTypes.object.isRequired
    }),
    mapProps(props => (
        {
            ...props,
            WidgetHeaderCompany: props.components.WidgetHeaderCompany,
            WidgetHeader: props.components.WidgetHeader,
            WidgetControlPanel: props.components.WidgetControlPanel,
            WidgetActionPanel: props.components.WidgetActionPanel,
            WidgetActionPanels: props.components.WidgetActionPanels,
            WidgetFilterPanel: props.components.WidgetFilterPanel,
            WidgetContent: props.components.WidgetContent,
            WidgetFooter: props.components.WidgetFooter,
            WidgetError: props.components.WidgetError,
            WidgetBusy: props.components.WidgetBusy,
            WidgetDataLoader: props.components.WidgetDataLoader,
            isError: !!props.widgetError
        })
    )
)(component);

export default WidgetLayoutEnhancer;
