import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import PerformanceChart from 'Components/PerformanceChart';
import VolumeChart from 'Components/VolumeChart';
import ColoredCheckBoxLabel from 'Components/widgets/_commonComponents/Performance/components/ColoredCheckBoxLabel';
import { withCharts, chartsShape } from 'Components/widgets/_commonComponents/Performance/hoc/withCharts';
import { CheckBox } from 'Components/CheckBox';
import { DateFormatter } from 'Components/formatters';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

@injectIntl
@withCharts
class PerformanceContentChart extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        charts: chartsShape,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        const { intl: { formatMessage } } = props;

        this.noDataMessage = formatMessage({ id: 'performance.noDataMessage' });
    }

    handleChartVisibilityChange = (changedChart, isChecked) => {
        const { charts: { selectableCharts }, widgetSettings } = this.props;
        const visibleCharts = selectableCharts
            .filter(chart => chart.isSelected)
            .map(chart => chart.id);

        if (isChecked) {
            visibleCharts.push(changedChart.id);
        } else {
            _.remove(visibleCharts, chartId => chartId === changedChart.id);
        }

        this.props.onUpdateWidgetSettings({
            ...widgetSettings,
            visibleCharts
        });
    };

    render() {
        const { charts, widgetSettings = {}, widgetData = {} } = this.props;
        const noPerformanceData = !_.some(_.values(_.get(charts, 'data.performanceChart', {})), chart => _.get(chart, 'data.length'));
        const noVolumeData = !_.get(charts, 'data.volumeChart.length');

        return (
            <div className='performance-content-chart'>
                <div className='labels'>
                    <div className='period-label'>
                        <FormattedMessage id={`performance.periodRange.${widgetSettings.period}`}/>
                    </div>
                    {!noPerformanceData &&
                    <div className='close-date'>
                        <DateFormatter value={widgetData.marketCloseDate}/>
                    </div>}
                </div>
                <div className='performance-chart-container'>
                    <NoDataComponent
                        message={this.noDataMessage}
                        isNoData={noPerformanceData}
                        isFetching={false}>
                        <PerformanceChart
                            visibleCharts={widgetSettings.visibleCharts}
                            data={charts.data.performanceChart}
                            customConfig={charts.config.performance}/>
                    </NoDataComponent>
                </div>
                <div className='chart-checkboxes-container'>
                    {charts.selectableCharts.map(chart =>
                        (<CheckBox
                            key={chart.id}
                            className='chart-checkbox'
                            onChange={e => this.handleChartVisibilityChange(chart, e.target.checked)}
                            checked={chart.isSelected}
                            label={<ColoredCheckBoxLabel
                                label={chart.label}
                                color={chart.color}/>}/>)
                    )}
                </div>
                <div className='volume-chart-content'>
                    <div className='volume-title'>
                        <FormattedMessage id='performance.volumeChart.title'/>
                    </div>
                    <div className='volume-chart-container'>
                        <NoDataComponent
                            message={this.noDataMessage}
                            isNoData={noVolumeData}
                            isFetching={false}>
                            <VolumeChart
                                data={charts.data.volumeChart}
                                customConfig={charts.config.volume}/>
                        </NoDataComponent>
                    </div>
                </div>
            </div>
        );
    }
}

export default PerformanceContentChart;
