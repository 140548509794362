import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import ReportingShareRangeAnalysisResultPageHeader from '../components/ReportingShareRangeAnalysisResultPageHeader';
import layoutTypes from 'Constants/layout-types';
import featureTypes from 'Constants/feature-types';
import restricted from 'Hoc/restricted';
import slots from './slots';
import { getShareRangeOptions } from 'Components/widgets/ShareRangeAnalysisResults/selectors';
import optionsValidation from '../../ReportingShareRangeAnalysisSearchPage/components/SearchRangeComponents/validation';
import pageRoutes from 'Constants/page-routes';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';

const mapStateToProps = (state) => ({
    shareRangeOptions: getShareRangeOptions(state) || {}
});

@withRouter
@connect(mapStateToProps)
@restricted({ feature: featureTypes.SHARE_RANGE_ANALYSIS_RESULTS })
class ReportingShareRangeAnalysisResultsPage extends Component {
    static propTypes = {
        shareRangeOptions: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.object
    };

    UNSAFE_componentWillMount() {
        const { shareRangeOptions, location } = this.props;

        this.isOptionsValid = _.isEmpty(optionsValidation(shareRangeOptions.shareRange));

        if (!this.isOptionsValid) {
            this.props.history.push(buildLocationWithSafeQueryParams(pageRoutes.reportingShareRangeAnalysisSearch, location));
        }
    }

    render() {
        return (
            <div className='reporting-share-range-analysis-results-page'>
                <ReportingShareRangeAnalysisResultPageHeader/>
                {this.isOptionsValid &&
                    <DynamicPage
                        layoutType={layoutTypes.layoutEasy}
                        slots={slots}
                        dynamicPageType={PAGE_TYPES.REPORTING_SHARES_RANGE_ANALYSIS_RESULT}/>
                }
            </div>
        );
    }
}

export default ReportingShareRangeAnalysisResultsPage;
