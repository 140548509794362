import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import FormError from '../FormError';

export default class StyledCalendar extends Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        onChange: PropTypes.func,
        onChangeInput: PropTypes.func,
        placeholder: PropTypes.string,
        error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        className: PropTypes.string,
        inputClassName: PropTypes.string
    };

    handleChange = (e) => {
        this.props.onChange(e);
        this.props.onChangeInput(e);
    };

    focus = () => this.input && this.input.focus();

    render() {
        const { disabled, error, className, inputClassName, onClick, onChangeInput, value, placeholder, ...restProps } = this.props;
        const inputClass = cn(className, inputClassName, { error: !!error });
        const iconClasses = cn('font-icon font-icon-events', { inactive: disabled });
        const placeholderClass = cn('placeholder', { empty: value === '' });

        return (
            <div className='date-picker-input'>
                <input
                    type='text'
                    {...restProps}
                    placeholder={placeholder}
                    value={value}
                    onChange={this.handleChange}
                    onClick={onClick}
                    disabled={disabled}
                    ref={el => this.input = el}
                    className={inputClass}/>
                {placeholder && <span className={placeholderClass}>{placeholder}</span>}
                <i
                    className={iconClasses}
                    onClick={onClick}/>
                {error && <FormError error={error}/>}
            </div>
        );
    }
}
