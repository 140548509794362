import React from 'react';
import LinkEx from 'Components/LinkEx';
import { FormattedMessage } from 'react-intl';

import ErrorsDetails from 'Components/ErrorsDetails';
import { LOG_ITEMS_COUNT } from 'Constants/logs-constants';
import pageRoutes from 'Constants/page-routes';
import logsLocalStorage from '../../../context/logsLocalStorage';

const ExpandedError = () => (
    <div className='expanded-error'>
        <div className='expanded-error-header'>
            <h1 className='expanded-error-title'>
                <FormattedMessage id='error.lastErrorDetails'/>
            </h1>
            <div className='expanded-error-links'>
                <LinkEx to={pageRoutes.errors}><FormattedMessage id='error.showRemainingErrors' values={{ count: LOG_ITEMS_COUNT }}/></LinkEx>
                <a onClick={logsLocalStorage.handleDownloadLogFile}><FormattedMessage id='error.downloadErrorsLog'/></a>
            </div>
        </div>
        <ErrorsDetails onlyLastError/>
    </div>
);

export default ExpandedError;
