import { compose, mapProps } from '@shakacode/recompose';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import BackLink from './BackLink';
import { mapComponentProps } from './utils';

export default compose(
    withRouter,
    injectIntl,
    mapProps(mapComponentProps)
)(BackLink);
