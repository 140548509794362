import featureTypes from 'Constants/feature-types';

export default [
    {
        label: 'modals.exportReport.label.includeFunds',
        value: featureTypes.FUNDS_VIEW
    },
    {
        label: 'modals.exportReport.label.includePositionHistory',
        value: featureTypes.POSITIONS_HISTORY_VIEW
    }
];
