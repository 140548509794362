import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';
import _ from 'lodash';

import LimitNote from 'Components/LimitNote';
import { RadioButtonsListField } from 'Components/RadioButtonsList';
import reportExportFormats from 'Constants/export-report-formats';
import fileFormats from 'Constants/file-formats';

import './ExportReportModal.scss';

const ExportReportModalView = (props) => {
    const { intl: { formatMessage }, onExport, onCancel, handleSubmit, showExcelLimit, isPDFDisabled, tooltipKeys } = props;
    const items = [{
        label: `${fileFormats[reportExportFormats.EXCEL]}${showExcelLimit ? '*' : ''}`,
        value: reportExportFormats.EXCEL,
        position: 'block'
    }, {
        label: fileFormats[reportExportFormats.PDF],
        value: reportExportFormats.PDF,
        position: 'block',
        disabled: isPDFDisabled,
        tooltip: _.get(tooltipKeys, 'onPDF') ? formatMessage({ id: tooltipKeys.onPDF }) : null
    }];

    return (
        <form
            onSubmit={handleSubmit(onExport)}
            className='export-report-modal'>
            <div className='format-container'>
                <RadioButtonsListField
                    name='selectedFormat'
                    list={items}/>
            </div>
            <div className='modal-actions'>
                <button
                    className='btn btn-large'
                    type='submit'>
                    {formatMessage({ id: 'modals.exportReport.exportBtn' })}
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className='btn btn-large btn-link'>
                    {formatMessage({ id: 'common.cancel' })}
                </button>
            </div>
            {showExcelLimit ? <LimitNote/> : null}
        </form>
    );
};

ExportReportModalView.propTypes = {
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
    onExport: PropTypes.func,
    showExcelLimit: PropTypes.bool
};

export default ExportReportModalView;
