import { useEffect } from 'react';

import * as api from '../../../../api';

const useSyncShareholders = ({ widgetData = [], isFetching = false, isError = false, currentWatchlistId }) => {
    useEffect(() => {
        const isLoaded = !isFetching && !isError;

        if (currentWatchlistId && isLoaded && widgetData.length > 0) {
            const requestData = widgetData.map((item) => ({
                id: item.shareholderId,
                name: item.shareholderName,
                shareholderType: item.shareholderType
            }));

            api.addShareholderToWatchlist(requestData, [currentWatchlistId]);
        }
    }, [widgetData, currentWatchlistId, isFetching, isError]);
};

export default useSyncShareholders;
