import PropTypes from 'prop-types';
import { Component } from 'react';
import { getContext } from '@shakacode/recompose';
import _ from 'lodash';

@getContext({
    events: PropTypes.object
})
class WidgetDataLoader extends Component {
    static propTypes = {
        widgetData: PropTypes.any,
        onUpdateWidgetData: PropTypes.func.isRequired,
        isFetching: PropTypes.bool,
        isDataViz: PropTypes.bool,
        isError: PropTypes.bool,
        events: PropTypes.object.isRequired,
        redirectOn404: PropTypes.bool
    };

    componentDidMount() {
        const { widgetData, isFetching, onUpdateWidgetData, events: { onBuildDataParams }, redirectOn404, isDataViz, isError } = this.props;

        if (widgetData || isFetching ||
            (isDataViz && isError)) { // don't load data again because we stay dataPrez (old dataViz) page
            return;
        }

        const widgetDataParams = _.isFunction(onBuildDataParams) ? onBuildDataParams({}) : {};

        onUpdateWidgetData(widgetDataParams, { redirectOn404 });
    }

    render() {
        return null;
    }
}

export default WidgetDataLoader;
