import React from 'react';
import { connect } from 'react-redux';

import { commonWidgetPropTypes } from 'Components/widgets/utils';
import { getWatchlistByIdSelector } from 'State/watchlist';

import './WatchlistHeader.scss';

const WatchlistHeader = ({ watchlist }) => {
    return (
        <div className='widget-header watchlist__header'>
            {watchlist && watchlist.title}
        </div>
    );
};

WatchlistHeader.propTypes = {
    ...commonWidgetPropTypes
};

const mapStateToProps = (state, props) => ({
    watchlist: getWatchlistByIdSelector(props.widgetSettings.currentWatchlistId)(state)
});

export default connect(mapStateToProps)(WatchlistHeader);
