export const LOCATION_FILTER_VALUES = {
    ALL: {
        value: 'ALL'
    },
    US_STATES: {
        title: 'reporting.geographic.analysis.filterOptions.usStates',
        value: 'US_STATES'
    },
    COUNTRIES: {
        title: 'reporting.geographic.analysis.filterOptions.countries',
        value: 'COUNTRIES'
    }
};
