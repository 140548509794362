import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { intlShape, FormattedNumber } from 'react-intl';

import formatterOptions from 'Constants/formatter-options';
import ShareholderInformationField from '../../../_commonComponents/ShareholderInformation/components/ShareholderInformationField';

const BottomField = ({ widgetData, intl: { formatMessage } }) => {
    const totalMarketValue = get(widgetData, 'totalMarketValue');

    return (
        <ShareholderInformationField name={formatMessage({ id: 'shareholder.information.totalMarketValue' })}>
            <FormattedNumber
                minimumFractionDigits={formatterOptions.DEFAULT_DECIMAL_POINTS}
                maximumFractionDigits={formatterOptions.DEFAULT_DECIMAL_POINTS}
                value={totalMarketValue}/>
        </ShareholderInformationField>
    );
};

BottomField.propTypes = {
    widgetData: PropTypes.object,
    intl: intlShape
};

export default BottomField;
