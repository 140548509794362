import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { PercentFormatter } from 'Components/formatters';
import { TOTAL_PERCENT } from './constants';
import { getSignClassNameFactory } from 'Components/widgets/utils';
import messageType from 'Constants/message-types';

const TotalValueOsCell = (props) => {
    const { value, rowData: { isTotal, markTotalOverflow, widgetFooterProps } = {} } = props;
    const getSignClassByMessageType = getSignClassNameFactory(widgetFooterProps);
    const markTotalOverflowClass = value > TOTAL_PERCENT && isTotal && markTotalOverflow
        ? getSignClassByMessageType(messageType.MORE_THAN_100_PERCENTS)
        : null;

    return (<PercentFormatter
        {...props}
        className={cn(markTotalOverflowClass, props.className)}
        withTitle/>);
};

TotalValueOsCell.propTypes = {
    intl: PropTypes.object,
    value: PropTypes.number,
    rowData: PropTypes.shape({
        isTotal: PropTypes.bool,
        markTotalOverflow: PropTypes.bool,
        markDifferentFromReport: PropTypes.bool
    }),
    className: PropTypes.string
};

export default TotalValueOsCell;
