import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import holderTypes from 'Constants/shareholder-types';
import columnManagerMode from '../../../constants/shareholder-column-manager-mode';
import Box from 'Components/layout/Box';
import { CheckBoxesListField, CheckBoxesListFieldByColumns } from 'Components/CheckBoxesList';
import Icon from 'Components/Icon';

import './ColumnsManager.scss';

const AMOUNT_OF_CHECKBOX_IN_COLUMN = 11;

const renderCustomViews = (customViews, dummy, onDeleteCustomView, onEditCustomView) => {
    const template = [];

    if (customViews && !_.isEmpty(customViews)) {
        _.forIn(customViews, (value, key) => {
            const handleDeleteClick = () => {
                onDeleteCustomView(key, value.name);
            };
            const handleEditClick = () => {
                onEditCustomView(key);
            };

            template.push(
                <div key={key} className='custom-views-section-item'>
                    <span className='custom-view-name' title={value.name}>{value.name}</span>
                    <div className='flexbox'>
                        <Icon className='font-icon font-icon-edit' onClick={handleEditClick}/>
                        <Icon className='font-icon font-icon-delete' onClick={handleDeleteClick}/>
                    </div>
                </div>
            );
        });
    } else {
        template.push(
            <div key='empty'>{dummy}</div>
        );
    }

    return template;
};

const ColumnsManagerView = (props) => {
    const {
        viewText,
        saveViewText,
        saveChangeText,
        cancelText,
        onCancel,
        onEditCancel,
        onSuccess,
        onClickSaveView,
        onDeleteCustomView,
        onEditCustomView,
        isShowViewButton
    } = props;
    const { columns, customViews, dummyCustomViewsText, mode } = props;

    return (
        <div className='column-manager-modal'>
            <form>
                <div className='flexbox column-lists'>
                    <Box>
                        <div className='column-list'>
                            <div className='column-list-label'>All Holders:</div>
                            <CheckBoxesListField className='checkbox-list' position='block' list={columns[holderTypes.ALL]}/>
                        </div>
                    </Box>
                    {columns[holderTypes.REGISTERED].length > 0 &&
                        <Box>
                            <div className='column-list'>
                                <div className='column-list-label'>Registered Holders:</div>
                                <CheckBoxesListField
                                    className='checkbox-list'
                                    position='block'
                                    list={columns[holderTypes.REGISTERED]}/>
                            </div>
                        </Box>
                    }
                    {columns[holderTypes.INSTITUTION].length > 0 &&
                        <Box>
                            <div className='column-list'>
                                <div className='column-list-label'>Institutional Holders:</div>
                                <div className='flexbox'>
                                    <CheckBoxesListFieldByColumns
                                        position='block'
                                        className='checkbox-list'
                                        list={columns[holderTypes.INSTITUTION]}
                                        rows={AMOUNT_OF_CHECKBOX_IN_COLUMN}/>
                                </div>
                            </div>
                        </Box>
                    }
                </div>
                { mode === columnManagerMode.CREATE

                    ? <div>
                        <div className='btn-panel'>
                            {isShowViewButton &&
                                <button className='btn btn-large' type='button' onClick={onSuccess}>
                                    {viewText}
                                </button>}
                            <button
                                className='btn btn-large'
                                data-action='save-view'
                                type='button'
                                onClick={onClickSaveView}>
                                {saveViewText}
                            </button>
                            <button
                                className='btn btn-large btn-link'
                                data-action='cancel'
                                type='button'
                                onClick={onCancel}>
                                {cancelText}
                            </button>
                        </div>
                    </div>

                    : <div className='btn-panel'>
                        <button className='btn btn-large' type='button' onClick={onClickSaveView}>
                            {saveChangeText}
                        </button>
                        <button className='btn btn-large btn-link' type='button' onClick={onEditCancel}>
                            {cancelText}
                        </button>
                    </div>
                }
            </form>
            { mode === columnManagerMode.CREATE &&
                <div className='custom-views-section'>
                    <div className='custom-views-section-header'>Saved Views</div>
                    <div className='custom-views-list'>
                        {renderCustomViews(customViews, dummyCustomViewsText, onDeleteCustomView, onEditCustomView)}
                    </div>
                </div>
            }
        </div>
    );
};

ColumnsManagerView.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onEditCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onClickSaveView: PropTypes.func.isRequired,
    onDeleteCustomView: PropTypes.func.isRequired,
    onEditCustomView: PropTypes.func.isRequired,
    viewText: PropTypes.string.isRequired,
    saveViewText: PropTypes.string.isRequired,
    saveChangeText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    dummyCustomViewsText: PropTypes.string.isRequired,
    columns: PropTypes.object.isRequired,
    customViews: PropTypes.object,
    mode: PropTypes.string,
    isShowViewButton: PropTypes.bool
};

export default ColumnsManagerView;
