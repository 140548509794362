import widgetTypes from 'Constants/widget-types';

export default {
    [widgetTypes.registeredShareholder1099Misc]: 'registeredShareholder1099MiscTemplate',
    [widgetTypes.registeredShareholderTransactionHistory]: 'registeredShareholderTransactionHistoryTemplate',
    [widgetTypes.registeredShareholder1099DIV]: 'registeredShareholder1099DIVTemplate',
    [widgetTypes.registeredShareholder1099INT]: 'registeredShareholder1099INTTemplate',
    [widgetTypes.registeredShareholder1099B]: 'registeredShareholder1099BTemplate',
    [widgetTypes.registeredShareholderDRDSPP]: 'registeredShareholderDRDSPPTemplate',
    [widgetTypes.registeredShareholder1042S]: 'registeredShareholder1042STemplate',
    [widgetTypes.registeredShareholderPayments]: 'registeredShareholderPaymentsTemplate',
    [widgetTypes.registeredShareholderPriorTADRSHistory]: 'registeredShareholderPriorTADRSHistoryTemplate',
    [widgetTypes.registeredShareholderClosedOtherType]: 'registeredShareholderClosedOtherTypeTemplate',
    [widgetTypes.registeredShareholderClosedSaleType]: 'registeredShareholderClosedSaleTypeTemplate',
    [widgetTypes.registeredShareholderCurrent]: 'registeredShareholderCurrentTemplate'
};
