import shareholderType from './shareholder-types';

export default {
    index: '/',
    home: '/home',
    securityList: '/securitylist',
    contract: '/contract',
    announcement: '/announcement',
    search: '/search',
    searchResult: '/search/result',
    myProfile: '/myprofile',
    userProfile: '/userprofile',
    institutionProfile: '/institutionprofile',
    [shareholderType.REGISTERED]: '/registeredoverview',
    [shareholderType.INSTITUTION]: '/institutionaloverview',
    [shareholderType.INSIDER]: '/insideroverview',
    [shareholderType.FUND]: '/institutionaloverview',
    contact: '/contactdetails',
    help: '/help',
    // reporting routes
    reporting: '/reporting',
    dataViz: '/reporting/dataprezreport',
    dataVizOld: '/reporting/datavizreport', // old url for dataViz report.
    reportingDownloads: '/reporting/downloads',
    reportingShareholderList: '/reporting/shareholderlist',
    reportingHolderInvestorTypeAnalysis: '/reporting/holdertypeanalysis',
    reportingShareDetail: '/reporting/sharedetail',
    reportingGeographicAnalysis: '/reporting/geographicanalysis',
    reportingShareRangeAnalysis: '/reporting/sharerangeanalysis',
    reportingShareRangeAnalysisSearch: '/reporting/sharerangeanalysis/search',
    reportingShareRangeAnalysisResults: '/reporting/sharerangeanalysis/results',
    reportingControlBooks: '/reporting/controlbooks',
    reportingControlBooksSearch: '/reporting/controlbooks/search',
    reportingControlBooksResults: '/reporting/controlbooks/results',
    reportingAuthorizedUserList: '/reporting/authorizeduserlist',
    // ownership routes
    ownership: '/ownership',
    ownershipShareholders: '/ownership/shareholders',
    historicalAnalysis: '/ownership/historicalanalysis',
    peerAnalysis: '/ownership/peeranalysis',
    ownershipContacts: '/ownership/contacts',
    ownershipContactsLanding: '/ownership/contacts/landing',
    ownershipContactsInstitutions: '/ownership/contacts/institutions',

    compareShareholders: '/compareshareholders',
    combineShareholders: '/combineshareholders',
    issuerResources: '/issuerresources',
    terms: '/terms',
    error: '/error',
    errors: '/errors',
    accessDenied: '/accessdenied',
    dataVizExport: '/datavizexport',
    fail: '/fail',
    meetingPlanner: '/ownership/meeting-planner',
    // downloads routing
    downloads: '/downloads',
    downloadsList: '/downloads/list',
    downloadsUserSecureUploadList: '/downloads/usersecureupload',
    downloadsUserSecureUploadEdit: '/downloads/usersecureupload/:requestId/edit',
    downloadsUserSecureUploadDetails: '/downloads/usersecureupload/:requestId'
};
