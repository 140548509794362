import { numberFormatter, thresholdFormatter } from 'Components/formatters/formatters';
import formatterOptions from 'Constants/formatter-options';
import { getPosition } from 'Utils/charts';

export const getPieTooltipConfig = (intl) => ({
    tooltip: {
        enabled: true,
        headerFormat: null,
        useHTML: true,
        formatter() {
            const { color, name, tooltipValue } = this.point;
            let formattedValue = thresholdFormatter(tooltipValue, formatterOptions.THRESHOLD);
            const isValueThresholded = formattedValue !== tooltipValue;

            formattedValue = isValueThresholded
                ? `<${formattedValue}`
                : numberFormatter(intl, tooltipValue, {
                    decimalPoints: formatterOptions.DEFAULT_PERCENT_DECIMAL_POINTS
                });

            return `<div class="ast-highcharts-tooltip" style="border-color:${color}">
                    <span style='color:${color}'>${name}</span>
                    (<span class='value'>${formattedValue}%</span>)
                </div>`;
        },
        positioner: getPosition,
        hideDelay: 0,
        animation: false,
        borderRadius: 0,
        borderWidth: 0,
        padding: 0,
        shadow: false,
        backgroundColor: 'none'
    }
});
