import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import './EmptyView.scss';

const EmptyView = ({ noDataMessage, columns, defaultColumnWidth }) => {
    const width = _.sumBy(columns, column => column.width || defaultColumnWidth);

    return (
        <div style={{ minWidth: width }} className='empty-view'>
            <FormattedMessage id={noDataMessage}/>
        </div>
    );
};

EmptyView.propTypes = {
    noDataMessage: PropTypes.string,
    columns: PropTypes.array,
    defaultColumnWidth: PropTypes.number
};

EmptyView.defaultProps = {
    noDataMessage: 'tables.noResultsFound'
};

export default EmptyView;
