import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import { isIssuerSelected, toggleMultipleIssuer } from 'State/advancedSearch';
import { CheckBox } from 'Components/CheckBox';

const mapStateToProps = (state, { issuer }) => ({
    checked: isIssuerSelected(state, issuer.id)
});
const mapDispatchToProps = { toggleMultipleIssuer };

@connect(mapStateToProps, mapDispatchToProps)
class IssuerCheckBox extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        checked: PropTypes.bool,
        issuer: PropTypes.object,
        style: PropTypes.object,
        toggleMultipleIssuer: PropTypes.func
    };

    handleChangeIssuer = (e) => {
        this.props.toggleMultipleIssuer(this.props.issuer, e.target.checked);
    };

    render() {
        const { issuer, disabled, checked, style } = this.props;

        return (
            <div style={style} className={cn('check-boxes-list-item', { checked })}>
                <CheckBox
                    disabled={disabled}
                    checked={checked}
                    onChange={this.handleChangeIssuer}/>
                <span className='label' title={issuer.companyName}>{issuer.companyName}</span>
            </div>
        );
    }
}

export default IssuerCheckBox;
