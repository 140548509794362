import PropTypes from 'prop-types';
import React from 'react';

import { NullableFormatterWithIntl } from './NullableFormatter';
import formatterOptions from 'Constants/formatter-options';
import { symbolFormatter, thresholdFormatter } from './formatters';

const ThresholdNumberFormatter = (props) => {
    const {
        minimumFractionDigits,
        maximumFractionDigits,
        withTitle,
        symbol,
        threshold,
        className
    } = props;

    return (
        <NullableFormatterWithIntl value={props.value}>
            {({ value, intl }) => {
                let formattedValue = value;
                let origFormattedValue = value;
                let title;
                let isValueThresholded = false;

                formattedValue = thresholdFormatter(formattedValue, threshold);
                isValueThresholded = formattedValue !== origFormattedValue;

                formattedValue = intl.formatNumber(formattedValue, {
                    minimumFractionDigits,
                    maximumFractionDigits
                });
                formattedValue = symbol ? symbolFormatter(formattedValue, symbol, true) : formattedValue;
                title = withTitle ? formattedValue : null;

                if (isValueThresholded) {
                    formattedValue = `<${formattedValue}`;
                    origFormattedValue = intl.formatNumber(origFormattedValue, {
                        minimumFractionDigits,
                        maximumFractionDigits: 10
                    });
                    origFormattedValue = symbol ? symbolFormatter(origFormattedValue, symbol, true) : origFormattedValue;
                    title = `${origFormattedValue}`;
                }

                return (<span title={title} className={className}>{formattedValue}</span>);
            }}
        </NullableFormatterWithIntl>
    );
};

ThresholdNumberFormatter.propTypes = {
    value: PropTypes.any,
    minimumFractionDigits: PropTypes.number,
    maximumFractionDigits: PropTypes.number,
    symbol: PropTypes.string,
    className: PropTypes.string,
    withTitle: PropTypes.bool,
    threshold: PropTypes.number
};

ThresholdNumberFormatter.defaultProps = {
    minimumFractionDigits: formatterOptions.DEFAULT_FRACTION_DIGITS,
    maximumFractionDigits: formatterOptions.DEFAULT_FRACTION_DIGITS,
    threshold: formatterOptions.THRESHOLD
};

export default ThresholdNumberFormatter;
