import colorScheme from 'Constants/color-schemas';

export const buildHighchartsOptions = (data = []) => ({
    chart: {
        type: 'timeline'
    },
    xAxis: {
        labels: {
            style: {
                fontSize: 16,
                fontWeight: 'bold',
                color: '#000000'
            }
        },
        lineWidth: 0,
        top: '-40%'
    },
    yAxis: {
        visible: false
    },
    credits: {
        enabled: false
    },
    title: {
        text: undefined
    },
    colors: colorScheme['0'],
    tooltip: { enabled: false },
    series: [{
        dataLabels: {
            formatter: function labelFormat() {
                return this.point.label || '';
            },
            alternate: false,
            verticalAlign: 'bottom',
            distance: -30
        },
        data
    }]
});

