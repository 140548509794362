import qs from 'query-string';

const gtmScripts = (id, params) => {
    const paramsString = params ? `&${qs.stringify(params)}` : '';

    const iframe = `
        <iframe src="https://www.googletagmanager.com/ns.html?${id}${paramsString}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;

    const script = `
        (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${paramsString}';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
    `;

    const createScripts = () => {
        const scriptTag = document.createElement('script');
        const noScriptTag = document.createElement('noscript');

        scriptTag.innerHTML = script;
        noScriptTag.innerHTML = iframe;

        return { scriptTag, noScriptTag };
    };

    return createScripts();
};

export const initializeGTM = (id, params) => {
    if (!id) {
        return;
    }

    const { scriptTag, noScriptTag } = gtmScripts(id, params);

    document.head.insertBefore(scriptTag, document.head.childNodes[0]);
    document.body.insertBefore(noScriptTag, document.body.childNodes[0]);
};
