import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';

import dateFormats from 'Constants/date-formats';
import { commonWidgetPropTypes } from 'Components/widgets/utils';

const PerformanceHeaderWithDate = ({ widgetTitle, widgetData }) => {
    const marketCloseDate = _.get(widgetData, 'marketCloseDate');

    return (
        <div className='widget-header-with-date'>
            <div className='widget-header'>{widgetTitle}</div>
            {marketCloseDate &&
            <div className='message-note'>
                <FormattedMessage id='performance.marketClose' values={{ date: moment(marketCloseDate).format(dateFormats.default) }}/>
            </div>}
        </div>
    );
};

PerformanceHeaderWithDate.propTypes = {
    ...commonWidgetPropTypes
};

export default PerformanceHeaderWithDate;
