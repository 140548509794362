import SHARE_TYPES from '../../../../pages/reporting/ReportingShareRangeAnalysisSearchPage/constants/shareTypes';
import { columnNames } from './columnDefinitions';

export const SHARE_TYPES_TITLES = {
    [SHARE_TYPES.ALL]: 'reporting.share.range.analysis.shareType.allShareTypes',
    [SHARE_TYPES.CERTIFICATED_AND_BOOK]: 'reporting.share.range.analysis.shareType.certificatedAndBookShares',
    [SHARE_TYPES.BOOK]: 'reporting.share.range.analysis.shareType.bookShares',
    [SHARE_TYPES.DIVIDEND_REINVESTMENT]: 'reporting.share.range.analysis.shareType.dividendReinvestmentShares'
};

export const DECIMAL_POINTS = {
    [columnNames.SHARE_RANGE]: 3,
    [columnNames.NUMBER_OF_ACCOUNTS]: 0,
    [columnNames.NUMBER_OF_SHARES]: 3,
    [columnNames.PERCENT_OF_ACCOUNTS]: 2,
    [columnNames.PERCENT_OF_SHARES]: 2
};

export const ALIGN_RIGHT = {
    [columnNames.SHARE_RANGE]: false,
    [columnNames.NUMBER_OF_ACCOUNTS]: true,
    [columnNames.NUMBER_OF_SHARES]: true,
    [columnNames.PERCENT_OF_ACCOUNTS]: true,
    [columnNames.PERCENT_OF_SHARES]: true
};

export const WITH_TITLE = {
    [columnNames.SHARE_RANGE]: false,
    [columnNames.NUMBER_OF_ACCOUNTS]: false,
    [columnNames.NUMBER_OF_SHARES]: false,
    [columnNames.PERCENT_OF_ACCOUNTS]: true,
    [columnNames.PERCENT_OF_SHARES]: true
};

export const SIDE_RESULTS = {
    OVER: -1,
    UNDER: 0
};

export const FORM_NAME = 'reportingShareRangeAnalysisForm';
