import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import { operationTypes } from 'Constants/analysisShareholders';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export const findAnalysisShareholderByName = (term, originalCancelToken) => {
    const payload = {
        term,
        operation: operationTypes.FIND_ANALYSIS_SHAREHOLDERS
    };
    const requestData = utils.buildRequest(payload, requestTypes.findAnalysisShareholders);

    return axios.post(apiRoutes.findAnalysisShareholdersUrl,
        requestData,
        {
            originalCancelToken,
            isCancelable: true
        });
};
