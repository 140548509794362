import PropTypes from 'prop-types';
import React from 'react';

import LinkEx from 'Components/LinkEx';
import BackLink from 'Components/BackLink';
import pageRoutes from 'Constants/page-routes';
import returnParamTypes from 'Constants/return-param-types';
import { buildRefToBackQuery } from 'Utils/routing';
import WatchlistShareholderButton from 'Components/WatchlistShareholderButton';

const InstitutionalOverviewPageHeaderView = ({ pageInfo = {}, institutionProfileLinkText, returnPath, isFetching = false }) => {
    const { shareholderId, shareholderTitle, shareholderType } = pageInfo;

    const institutionProfileLink = `${pageRoutes.institutionProfile}/${shareholderId}`;

    return (
        <div className='institutional-overview-page-header'>
            <BackLink/>
            <div className='title-container'>
                <h2 className='title'>{shareholderTitle}</h2>
                {!isFetching && (<WatchlistShareholderButton
                    shareholderId={shareholderId}
                    shareholderTitle={shareholderTitle}
                    shareholderType={shareholderType}/>
                )}
                <LinkEx
                    className='institution-profile-link'
                    to={{ pathname: institutionProfileLink, search: buildRefToBackQuery(returnPath) }}>
                    {institutionProfileLinkText}
                </LinkEx>
            </div>
        </div>
    );
};

InstitutionalOverviewPageHeaderView.propTypes = {
    pageInfo: PropTypes.object,
    returnPath: returnParamTypes.isRequired,
    onBackLinkClick: PropTypes.func,
    institutionProfileLinkText: PropTypes.string,
    isFetching: PropTypes.bool
};

export default InstitutionalOverviewPageHeaderView;
