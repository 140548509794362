import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { convertMinutesInDays } from 'Utils/utils';

const DownloadsMessageNote = props => {
    const storagePeriod = convertMinutesInDays(props.reportsStoragePeriod);
    const subscriptionsForNotes = props.activeSubscriptions.filter(s => s.storageTime !== storagePeriod);
    const messages = subscriptionsForNotes.map(s => (
        <FormattedMessage
            id='reporting.downloads.subscription.note'
            key={s.type}
            values={{
                name: s.name,
                storageTime: s.storageTime
            }}/>
    ));

    return (<p className='message-note'>
        <FormattedMessage
            id='reporting.downloads.note'
            values={{ storagePeriod }}/>
        {messages}

    </p>);
};

DownloadsMessageNote.propTypes = {
    reportsStoragePeriod: PropTypes.number,
    activeSubscriptions: PropTypes.array
};

DownloadsMessageNote.defaultProps = {
    reportsStoragePeriod: 0,
    activeSubscriptions: []
};

export default DownloadsMessageNote;
