import _ from 'lodash';
import cn from 'classnames';

import messageTypes from 'Constants/message-types';

export const markIncludesCeDeCoRows = (values, getSignClassByMessageType, messageType = messageTypes.INCLUDES_CEDECO) => {
    const signClass = getSignClassByMessageType(messageType);

    return _.map(values, (value = {}) => ({
        ...value,
        className: cn(value.className, {
            [signClass]: _.get(value, 'includesCeDeCo')
        })
    }));
};

export const markIncludesESPPRows = (values, getSignClassByMessageType, messageType = messageTypes.INCLUDES_ESPP) => {
    const signClass = getSignClassByMessageType(messageType);

    return _.map(values, (value = {}) => ({
        ...value,
        className: cn(value.className, {
            [signClass]: _.get(value, 'customParams.includesESPP')
        }),
        esppSecurityIds: _.get(value, 'customParams.esppSecurityIds')
    }));
};
