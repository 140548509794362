import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import LinkEx from 'Components/LinkEx';

const LinkMessage =  ({ to, messageKey, customProps }) => (
    <LinkEx to={to} {...customProps}>
        <FormattedMessage id={messageKey}/>
    </LinkEx>
);

LinkMessage.propTypes = {
    messageKey: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    customProps: PropTypes.object
};

export default LinkMessage;
