import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import Icon from 'Components/Icon';
import shareholderTypes from 'Constants/shareholder-types';

const getIconClass = (shareholderType, expanded, isFundExists, showExpander) => {
    const type = shareholderType.toUpperCase();

    switch (type) {
        case shareholderTypes.REGISTERED: {
            return 'font-icon font-icon-registered';
        }
        case shareholderTypes.INSIDER: {
            return 'font-icon font-icon-insider';
        }
        case shareholderTypes.INSTITUTION: {
            if (isFundExists && showExpander) {
                return cn('font-icon', expanded ?
                    'font-icon-collapse' :
                    'font-icon-expand');
            }
            return 'font-icon font-icon-institution';
        }
        case shareholderTypes.FUND: {
            return 'font-icon font-icon-security-group';
        }
        default: {
            return null;
        }
    }
};

const ShareholderIcon = ({ shareholderType, expanded, showExpander, isFundExists, onInteraction }) => {
    const className = getIconClass(
        shareholderType,
        expanded,
        isFundExists,
        showExpander
    );

    return (<Icon
        className={className}
        onClick={onInteraction && onInteraction.bind(null, shareholderType, isFundExists)}/>);
};

ShareholderIcon.propTypes = {
    shareholderType: PropTypes.string,
    expanded: PropTypes.bool,
    showExpander: PropTypes.bool,
    onInteraction: PropTypes.func,
    isFundExists: PropTypes.bool
};

export default ShareholderIcon;
