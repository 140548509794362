import { CellCommon, CellWithDateTime } from 'Components/widgets/_commonComponents/TableCellComponents';

import { ColumnHeader } from './components/ColumnHeader';
import { ActionCell } from './components/ActionCell';

export const columnNames = {
    DESCRIPTION: 'description',
    UPDATE_DATE: 'updateDateTime'
};

export const columnDefinitions = [
    {
        accessor: columnNames.DESCRIPTION,
        displayName: 'userSecureUpload.table.requestDescription',
        Cell: CellCommon,
        Header: ColumnHeader
    },
    {
        accessor: columnNames.UPDATE_DATE,
        displayName: 'userSecureUpload.table.requestDate',
        maxWidth: 200,
        Cell: CellWithDateTime,
        Header: ColumnHeader,
        headerClassName: 'cell-with-align-right'
    },
    {
        id: 'action',
        maxWidth: 50,
        width: 50,
        Cell: ActionCell,
        className: 'icon-cell'
    }
];
