import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import { LabelWithTooltip, NullableFormatter } from 'Components/formatters';

const CountryCell = (props) => {
    const country = props.value;
    const customClassName = _.get(props, 'rowData.className');

    return (
        <LabelWithTooltip
            value={NullableFormatter({ value:country })}
            className={customClassName}/>
    );
};

CountryCell.propTypes = {
    value: PropTypes.string,
    rowData: PropTypes.shape({
        country: PropTypes.string
    }),
    className: PropTypes.string
};

export default CountryCell;
