import PropTypes from 'prop-types';
import React from 'react';
import { isNumber } from 'lodash';
import cn from 'classnames';

import BarChart from './barChart';

/**
 * Specific row renderer for Table.
 */
const defaultRowRenderer = ({
    className,
    columns,
    // index,
    // isScrolling,
    key,
    // onRowClick,
    // onRowDoubleClick,
    // onRowMouseOver,
    // onRowMouseOut,
    rowData,
    style
}) => {
    return (
        <div
            className={cn(className, rowData.rowClassName)}
            key={key}
            role='row'
            style={style}>
            {columns}
            {isNumber(rowData.barValue) && <BarChart value={rowData.barValue}/>}
        </div>
    );
};

defaultRowRenderer.propTypes = {
    className: PropTypes.any,
    columns: PropTypes.any,
    index: PropTypes.any,
    isScrolling: PropTypes.any,
    key: PropTypes.any,
    onRowClick: PropTypes.any,
    onRowDoubleClick: PropTypes.any,
    onRowMouseOver: PropTypes.any,
    onRowMouseOut: PropTypes.any,
    rowData: PropTypes.any,
    style: PropTypes.any
};

export default defaultRowRenderer;
