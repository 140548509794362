import { createSelector } from 'reselect';
import _ from 'lodash';

import * as widgetUtils from '../utils';

const getWidgetDataValues = props => _.get(props.widgetData, 'values');

export const getDates = createSelector(
    getWidgetDataValues,
    (values) => values && _.map(values, 'date'));

export const getSelectedDate = createSelector(
    getDates,
    props => props,
    widgetUtils.getSelectedDate);

export const getFilteredData = createSelector(
    getWidgetDataValues,
    getSelectedDate,
    (values, selectedDate) => {
        return _.find(values, value => value.date === selectedDate) || {};
    }
);
