import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CheckBox } from 'Components/CheckBox';
import Selector from 'Components/Selector';
import columnNames from 'Constants/shareholders-columns-names';
import addressTypeOptions, { defaultAddressType } from '../../../constants/addressTypeOptions';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';

const isChecked = (values, value = {}) => {
    const DEFAULT_VALUES = values.length > 0 && values.includes(columnNames.ADDRESS) ? [...values, defaultAddressType] : values;

    if (value.id === columnNames.ADDRESS) {
        return _.intersection(DEFAULT_VALUES, addressTypeOptions).length > 0;
    }

    if (value.id === columnNames.BAD_ADDRESS && !DEFAULT_VALUES.includes(columnNames.REGISTERED_ADDRESS)) {
        return false;
    }
    return DEFAULT_VALUES.indexOf(value.id) > -1;
};

const isDisabled = (values, value = {}) => {
    const DEFAULT_VALUES = values.length > 0 && values.includes(columnNames.ADDRESS) ? [...values, defaultAddressType] : values;

    if (value.id === columnNames.BAD_ADDRESS) {
        return values.length === 0 ||
            (DEFAULT_VALUES.includes(columnNames.PROXY_ADDRESS) ||
                DEFAULT_VALUES.includes(columnNames.DIVIDEND_ADDRESS) || DEFAULT_VALUES.includes(columnNames.SEASONAL_ADDRESS) || DEFAULT_VALUES.includes(columnNames.K1_ADDRESS));
    }

    return false;
};

@withProps(({ input, columnsToDisplayOptions }) => {
    const { values: valuesOption = [], separateOnColumnsBy, disabled = false } = columnsToDisplayOptions;
    const { value: values = [] } = input;
    let checkedValueCount = 0;

    const mappedValues = valuesOption.map((value) => {
        const isCheckboxChecked = isChecked(values, value);
        const isCheckboxDisabled = isDisabled(values, value);

        isCheckboxChecked && !isCheckboxDisabled && checkedValueCount++;

        return ({
            ...value,
            disabled: disabled || isCheckboxDisabled,
            checked: isCheckboxChecked
        });
    });

    return {
        columns: separateOnColumnsBy > 0 ? _.chunk(mappedValues, separateOnColumnsBy) : [mappedValues],
        isAllChecked: checkedValueCount > 0 && checkedValueCount === _.filter(mappedValues, v => !v.disabled).length,
        isIndeterminate: checkedValueCount > 0 && checkedValueCount < _.filter(mappedValues, v => !v.disabled).length,
        mappedValues
    };
})
class ColumnsToDisplayField extends Component {
    static propTypes = {
        input: PropTypes.shape(fieldInputPropTypes),
        meta: PropTypes.shape(fieldMetaPropTypes),
        title: PropTypes.string,
        className: PropTypes.string,
        columnsToDisplayOptions: PropTypes.shape({
            values: PropTypes.array,
            separateOnColumnsBy: PropTypes.number,
            disabled: PropTypes.bool,
            label: PropTypes.string
        }),
        columns: PropTypes.arrayOf(PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                label: PropTypes.string,
                disabled: PropTypes.bool,
                checked: PropTypes.bool
            })
        )),
        isAllChecked: PropTypes.bool,
        isIndeterminate: PropTypes.bool,
        mappedValues: PropTypes.array
    };

    handleOnChange = (e) => {
        const { input: { value: values = [], onChange } } = this.props;
        const { target: { checked, value } } = e;
        const newValues = checked ?
            [...values, value] :
            values.filter(val => val !== value);

        onChange(newValues);
    };

    handleOnChangeAddressSelector = (value) => {
        const { input: { value: values = [], onChange } } = this.props;
        const newValues = _.without(values, ...addressTypeOptions);

        if (value.value !== columnNames.REGISTERED_ADDRESS) {
            onChange([..._.filter(newValues, v => v !== columnNames.BAD_ADDRESS), value.value]);
        } else {
            onChange([...newValues, value.value]);
        }
    };

    handleOnChangeAddressCheckbox = (e) => {
        const { input: { value: values = [], onChange } } = this.props;
        const { target: { checked } } = e;
        const newValues = checked ?
            [...values, this.getAddressValue()] :
            _.without(values, columnNames.BAD_ADDRESS, columnNames.ADDRESS, ...addressTypeOptions);

        onChange(newValues);
    };

    handleOnChangeAllCheckbox = e => {
        const { target: { checked } } = e;
        const { input: { onChange }, mappedValues } = this.props;
        const newValues = [];

        if (checked) {
            mappedValues.map(v =>
                v.id === columnNames.ADDRESS
                    ? newValues.push(this.getAddressValue())
                    : newValues.push(v.id));
        }

        onChange(newValues);
    };

    getAddressValue = () => {
        const { value: values = [] } = this.props.input;
        const addressType = _.intersection(values, addressTypeOptions)[0];

        return addressType || defaultAddressType;
    };

    render() {
        const {
            columns,
            className,
            columnsToDisplayOptions: { label, disabled = false },
            isAllChecked,
            isIndeterminate
        } = this.props;
        const includeSectionClassName = cn('holder-columns-section', className);
        const titleClassName = cn('holder-columns-section-title', { disabled });

        return (
            <div className={includeSectionClassName}>
                <div className={titleClassName}>{label}</div>
                <div className='select-all-checkbox'>
                    <CheckBox
                        position='inline'
                        checked={isAllChecked}
                        indeterminate={isIndeterminate}
                        disabled={disabled}
                        label={<FormattedMessage id='common.selectAll' />}
                        onChange={this.handleOnChangeAllCheckbox} />
                </div>
                <div className='holder-columns'>
                    {columns.map((column, i) =>
                        (<div key={i} className='holder-column'>
                            {column.map(checkbox => {
                                if (checkbox.id === columnNames.ADDRESS) {
                                    return (
                                        <div className='address-type' key={checkbox.id}>
                                            <CheckBox
                                                position='inline'
                                                value={checkbox.id}
                                                checked={checkbox.checked}
                                                disabled={checkbox.disabled}
                                                onChange={this.handleOnChangeAddressCheckbox} />
                                            <Selector
                                                wrapperClass='address-type-selector'
                                                name='addressType'
                                                options={checkbox.options}
                                                value={this.getAddressValue()}
                                                clearable={false}
                                                searchable={false}
                                                disabled={checkbox.disabled || !checkbox.checked}
                                                onOptionChange={this.handleOnChangeAddressSelector} />
                                        </div>
                                    );
                                }
                                return (<CheckBox
                                    key={checkbox.id}
                                    position='block'
                                    value={checkbox.id}
                                    checked={checkbox.checked}
                                    disabled={checkbox.disabled}
                                    onChange={this.handleOnChange}
                                    label={checkbox.label} />);
                            })}
                        </div>)
                    )}
                </div>
            </div>
        );
    }
}

export default ColumnsToDisplayField;
