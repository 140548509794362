import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import AnalysisGeographic from '../_commonComponents/AnalysisGeographic';
import StateCell from './StateCell';
import { LOCATION_FILTER_VALUES } from '../GeographicAnalysis/constants/locationFilterValues';
import shareholderTypes from 'Constants/shareholder-types';

class AnalysisGeographicState extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.formatMessage = props.intl.formatMessage;
        this.primaryColumn = {
            dataKey: 'state',
            title: this.formatMessage({ id: 'analysis.geographic.state.state' }),
            bodyCellComponent: StateCell
        };
    }

    render() {
        const { widgetData, widgetSettings } = this.props;
        const activeShareholder = AnalysisGeographic.getActiveShareholder(widgetData, widgetSettings);
        const hideChangeColumn = _.get(activeShareholder, 'shareholderType') === shareholderTypes.REGISTERED;

        return (
            <AnalysisGeographic
                {...this.props}
                primaryColumn={this.primaryColumn}
                totalMessageId='tableEasy.priorCOBTotal'
                hideChangeColumn={hideChangeColumn}
                geographicAnalysisLocationFilterType={LOCATION_FILTER_VALUES.US_STATES.value}/>
        );
    }
}

export default AnalysisGeographicState;
