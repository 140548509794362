/* eslint-disable react/no-multi-comp*/
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import cn from 'classnames';
import _ from 'lodash';

import {
    commonWidgetPropTypes,
    commonWidgetActions,
    getMarkedData,
    markIncludesCeDeCoRows
} from 'Components/widgets/utils';
import { LabelWithTooltip, NullableFormatter, NumberFormatter } from 'Components/formatters';
import widgetModes from 'Constants/widget-modes';
import OverallOwnershipStatisticsTableView from './OverallOwnershipStatisticsTableView';
import OverallOwnershipStatisticsChartView from './OverallOwnershipStatisticsChartView';
import TotalValueOsCell from 'Components/widgets/_commonComponents/TotalValueOsCell';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

const OverallOwnershipStatisticsContent = (props) => {
    const { widgetSettings: { mode }, pieData, legendData, isFetching, widgetNoData } = props;

    return (
        <div className='widget-content'>
            <NoDataComponent
                isFetching={isFetching}
                isNoData={widgetNoData}>
                <div>
                    { mode === widgetModes.CHART ?
                        (<OverallOwnershipStatisticsChartView
                            {...props}
                            pieData={pieData}
                            legendData={legendData}/>) :
                        (<OverallOwnershipStatisticsTableView
                            {...props}
                            legendData={legendData}/>)
                    }
                </div>
            </NoDataComponent>
        </div>);
};

OverallOwnershipStatisticsContent.propTypes = {
    ...Component.propTypes,
    ...commonWidgetActions,
    ...commonWidgetPropTypes,
    tableView: PropTypes.bool
};

export default compose(
    mapProps(props => {
        const { widgetNoData, widgetFooterProps, intl: { formatMessage } } = props;
        const values = _.get(props, 'widgetData.values');
        const aggregate = _.get(props, 'widgetData.aggregate');
        let pieData;
        let legendData;

        if (!widgetNoData) {
            pieData = values.map(value => ({
                name: value.holderType,
                y: value.os,
                tooltipValue: value.os,
                color: value.color
            }));
            legendData = {
                columns: [
                    {
                        dataKey: 'holderType',
                        title: formatMessage({ id: 'overall.ownership.statistics.holderType' }),
                        // eslint-disable-next-line react/prop-types
                        bodyCellComponent: ({ value, className, rowData: { className: dataClassName } = {} } = {}) => (
                            <LabelWithTooltip
                                value={NullableFormatter({ value })}
                                className={cn(className, dataClassName)}/>
                        )
                    },
                    {
                        dataKey: 'os',
                        columnClassName: 'text-right',
                        title: formatMessage({ id: 'overall.ownership.statistics.os' }),
                        bodyCellComponent: TotalValueOsCell
                    },
                    {
                        dataKey: 'shareholdersCount',
                        columnClassName: 'text-right',
                        minWidth: 140,
                        title: formatMessage({ id: 'overall.ownership.statistics.shareholdersCount' }),
                        bodyCellComponent: NumberFormatter
                    }
                ],
                data: getMarkedData(values, [markIncludesCeDeCoRows], widgetFooterProps),
                aggregate
            };
        }

        return { ...props, pieData, legendData };
    })
)(OverallOwnershipStatisticsContent);
