import React from 'react';
import PropTypes from 'prop-types';

const defaultHeaderRowRenderer = ({ className, columns, style }) => (
    <div className={className} role='row' style={style}>
        {columns}
    </div>
);

defaultHeaderRowRenderer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.any,
    columns: PropTypes.any
};

export default defaultHeaderRowRenderer;
