import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import SimpleMessage from './components/SimpleMessage';
import LinkMessage from './components/LinkMessage';

import './FooterWithInfo.scss';

const FooterWithInfo = ({ widgetFooterProps: { leftSide = [], rightSide = [], top = [], bottom = [] } }) => {
    const topMessages = _.filter(top, ['hidden', false]).map(note => note.render
        ? note.render()
        : (<SimpleMessage {...note} key={note.messageKey}/>)
    );
    const leftSideMessages = _.filter(leftSide, ['hidden', false]).map(note => (
        <LinkMessage {...note} key={note.messageKey}/>
    ));
    const rightSideMessages = _.filter(rightSide, ['hidden', false]).map(note => (
        <SimpleMessage {...note} key={note.messageKey}/>
    ));
    const bottomMessages = _.filter(bottom, ['hidden', false]).map(note => (
        <SimpleMessage {...note} key={note.messageKey}/>
    ));

    return (
        _.isEmpty(leftSideMessages) && _.isEmpty(rightSideMessages) && _.isEmpty(topMessages) && _.isEmpty(bottomMessages)
            ? null
            : (
                <div className='footer-with-info'>
                    {topMessages}
                    <div className='sides-container'>
                        <div className='left-side text-left'>
                            {leftSideMessages}
                        </div>
                        <div className='right-side text-right'>
                            {rightSideMessages}
                        </div>
                    </div>
                    {bottomMessages}
                </div>
            ));
};

FooterWithInfo.propTypes = {
    widgetFooterProps: PropTypes.shape({
        leftSide: PropTypes.arrayOf(PropTypes.object),
        rightSide: PropTypes.arrayOf(PropTypes.object),
        top: PropTypes.arrayOf(PropTypes.object),
        bottom: PropTypes.arrayOf(PropTypes.object)
    })
};

export default FooterWithInfo;
