import _ from 'lodash';

import * as api from '../../api/reportTemplate';
import * as savedReportTemplatesSelectors from './selectors';
import * as modalActions from '../modal/actions';
import httpStatus from 'Constants/http-status';

export const GET_SAVED_REPORT_TEMPLATES_REQUEST = 'GET_SAVED_REPORT_TEMPLATES_REQUEST';
export const GET_SAVED_REPORT_TEMPLATES_SUCCESS = 'GET_SAVED_REPORT_TEMPLATES_SUCCESS';
export const GET_SAVED_REPORT_TEMPLATES_FAILURE = 'GET_SAVED_REPORT_TEMPLATES_FAILURE';

function requestGetSavedReports() {
    return {
        type: GET_SAVED_REPORT_TEMPLATES_REQUEST
    };
}

function successGetSavedReports(reports) {
    return {
        type: GET_SAVED_REPORT_TEMPLATES_SUCCESS,
        payload: reports
    };
}

function errorGetSavedReports(error) {
    return {
        type: GET_SAVED_REPORT_TEMPLATES_FAILURE,
        payload: error,
        error: true
    };
}

export function getReportTemplates() {
    return (dispatch) => {
        dispatch(requestGetSavedReports());

        return api.getReportTemplates()
            .then(res => {
                dispatch(successGetSavedReports(res.data.payload));
                return res;
            })
            .catch(error => {
                dispatch(errorGetSavedReports(error));
                return Promise.reject(error);
            });
    };
}

export const SAVE_REPORT_TEMPLATE_REQUEST = 'SAVE_REPORT_TEMPLATE_REQUEST';
export const SAVE_REPORT_TEMPLATE_SUCCESS = 'SAVE_REPORT_TEMPLATE_SUCCESS';
export const SAVE_REPORT_TEMPLATE_FAILURE = 'SAVE_REPORT_TEMPLATE_FAILURE';

function requestSaveNewReport() {
    return {
        type: SAVE_REPORT_TEMPLATE_REQUEST
    };
}

export function successSaveNewReport(newReport) {
    return {
        type: SAVE_REPORT_TEMPLATE_SUCCESS,
        payload: newReport
    };
}

function errorSaveNewReport(error) {
    return {
        type: SAVE_REPORT_TEMPLATE_FAILURE,
        payload: error,
        error: true
    };
}

const commonSaveReportTemplate = saveReportTemplateRequest => reportTemplate => {
    return dispatch => {
        dispatch(requestSaveNewReport());

        return saveReportTemplateRequest(reportTemplate)
            .then(res => {
                dispatch(successSaveNewReport({ ...res.data.payload, form: reportTemplate.form }));
                return res;
            })
            .catch(error => {
                dispatch(errorSaveNewReport(error));
                return Promise.reject(error);
            });
    };
};

export const saveShareholderListReportTemplate = reportTemplate => {
    return dispatch => {
        dispatch(requestSaveNewReport());

        return api.saveReportTemplate(reportTemplate)
            .then(res => {
                dispatch(successSaveNewReport({
                    ...res.data.payload,
                    form: reportTemplate.form,
                    securityCount: _.get(reportTemplate.form, 'securities.length', 0)
                }));
                return res;
            })
            .catch(error => {
                dispatch(errorSaveNewReport(error));
                return Promise.reject(error);
            });
    };
};

export const saveReportTemplate = commonSaveReportTemplate(api.saveReportTemplate);
export const saveDataVizTemplate = commonSaveReportTemplate(api.saveDataVizTemplate);
export const DELETE_REPORT_TEMPLATE_REQUEST = 'DELETE_REPORT_TEMPLATE_REQUEST';
export const DELETE_REPORT_TEMPLATE_SUCCESS = 'DELETE_REPORT_TEMPLATE_SUCCESS';
export const DELETE_REPORT_TEMPLATE_FAILURE = 'DELETE_REPORT_TEMPLATE_FAILURE';

function requestDeleteReport() {
    return {
        type: DELETE_REPORT_TEMPLATE_REQUEST
    };
}

function successDeleteReport(reportTemplateId) {
    return {
        type: DELETE_REPORT_TEMPLATE_SUCCESS,
        payload: { reportTemplateId }
    };
}

function errorDeleteReport(error) {
    return {
        type: DELETE_REPORT_TEMPLATE_FAILURE,
        payload: error,
        error: true
    };
}

const commonDeleteReportTemplate = deleteReportTemplateRequest => reportTemplateId => {
    return (dispatch, getState) => {
        dispatch(requestDeleteReport());

        return deleteReportTemplateRequest(reportTemplateId)
            .then(res => {
                let deleteTemplateId = reportTemplateId;

                if (reportTemplateId === null) { // DataPrez (old DataViz) started template
                    const dataVizStartedTemplate = savedReportTemplatesSelectors.getDataVizStartedSelector(getState());

                    deleteTemplateId = dataVizStartedTemplate.reportTemplateId;
                }

                dispatch(successDeleteReport(deleteTemplateId));
                return res;
            })
            .catch(error => {
                const responseStatus = _.get(error, 'response.status');

                if (responseStatus === httpStatus.HTTP_423) {
                    dispatch(modalActions.showInfoModal({
                        titleKey: 'modals.errorDeleteReportTemplate.title',
                        messageKey: 'modals.errorDeleteReportTemplate.message'
                    }));
                }
                dispatch(errorDeleteReport(error));
                return Promise.reject(error);
            });
    };
};

export const deleteReportTemplate = commonDeleteReportTemplate(api.deleteReportTemplate);
export const deleteDataVizTemplate = commonDeleteReportTemplate(api.deleteDataVizTemplate);

export const GET_REPORT_TEMPLATE_USERS_REQUEST = 'GET_REPORT_TEMPLATE_USERS_REQUEST';
export const GET_REPORT_TEMPLATE_USERS_SUCCESS = 'GET_REPORT_TEMPLATE_USERS_SUCCESS';
export const GET_REPORT_TEMPLATE_USERS_FAILURE = 'GET_REPORT_TEMPLATE_USERS_FAILURE';

const requestGetAvailableUsers = () => ({
    type: GET_REPORT_TEMPLATE_USERS_REQUEST
});

const successGetAvailableUsers = (users) => ({
    type: GET_REPORT_TEMPLATE_USERS_SUCCESS,
    payload: users
});

const failureGetAvailableUsers = () => ({
    type: GET_REPORT_TEMPLATE_USERS_FAILURE
});

export const getReportTemplateAvailableUsers = (reportTemplateId) => {
    return (dispatch) => {
        dispatch(requestGetAvailableUsers());

        return api.getReportTemplateUsers(reportTemplateId)
            .then(({ data }) => {
                const users = _.get(data, 'payload.users', []);

                dispatch(successGetAvailableUsers(users));
            })
            .catch((error) => {
                dispatch(failureGetAvailableUsers());

                return Promise.reject(error);
            });
    };
};

export const SHARE_REPORT_TEMPLATE_REQUEST = 'SHARE_REPORT_TEMPLATE_REQUEST';
export const SHARE_REPORT_TEMPLATE_SUCCESS = 'SHARE_REPORT_TEMPLATE_SUCCESS';
export const SHARE_REPORT_TEMPLATE_FAILURE = 'SHARE_REPORT_TEMPLATE_FAILURE';

const requestShareReportTemplate = () => ({
    type: SHARE_REPORT_TEMPLATE_REQUEST
});

const successShareReportTemplate = () => ({
    type: SHARE_REPORT_TEMPLATE_SUCCESS
});

const failureShareReportTemplate = () => ({
    type: SHARE_REPORT_TEMPLATE_FAILURE
});

export const shareReportTemplate = (reportTemplateId, userIds) => {
    return (dispatch) => {
        dispatch(requestShareReportTemplate());

        return api.shareReportTemplate(reportTemplateId, userIds)
            .then(() => {
                dispatch(successShareReportTemplate());
            })
            .catch((error) => {
                dispatch(failureShareReportTemplate());

                return Promise.reject(error);
            });
    };
};
