import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import Pagination from 'Components/Pagination';

import './RegisteredPagination.scss';

class RegisteredPagination extends Component {
    static propTypes = {
        onChangeCurrentPage: PropTypes.func
    };

    render() {
        const currentPage = _.get(this.props, 'widgetData.filterCriteria.currentPage') || 1;
        const totalPages = _.get(this.props, 'widgetData.totalPages');
        const totalCount = _.get(this.props, 'widgetData.total');
        const pageSize = _.get(this.props, 'pageSize');
        const isDataViz = _.get(this.props, 'isDataViz');
        const widgetData = _.get(this.props, 'widgetData');

        return (
            <div className='registered-pagination'>
                <div className='right-aside'>
                    { _.isNumber(totalCount) &&
                        <span>
                            <FormattedMessage id='pagination.total' values={{ value: totalCount }}/>
                        </span>
                    }
                    {widgetData && !isDataViz &&
                    <Pagination
                        showPageSizes={false}
                        currentPage={currentPage}
                        numberOfPages={totalPages}
                        currentPageSize={pageSize}
                        onChangeCurrentPage={this.props.onChangeCurrentPage}/>
                    }
                </div>
            </div>
        );
    }
}

export default RegisteredPagination;
