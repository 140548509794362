import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { clearFields } from 'redux-form';
import { connect } from 'react-redux';

import { CheckBox } from 'Components/CheckBox';
import Selector from 'Components/Selector';
import { contactYears } from 'Components/widgets/DateLastContact/constants';

const mapDispatchToProps = dispatch => {
    return {
        clearFormFields: (formName, ...fieldsNames) => {
            dispatch(clearFields(formName, false, false, ...fieldsNames));
        }
    };
};

@connect(null, mapDispatchToProps)
class DateOfLastContactField extends Component {
    static propTypes = {
        dateOfLastContact: PropTypes.shape({
            checked: PropTypes.object,
            period: PropTypes.object
        }),
        options: PropTypes.object,
        clearFormFields: PropTypes.func
    };

    constructor(props) {
        const {
            dateOfLastContact: { checked: checkedField, period: periodField }
        } = props;

        super(props);
        this.state = {
            period: contactYears.MORE_1Y
        };

        periodField.input.onChange('');
        checkedField.input.onChange(false);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { dateOfLastContact: { period: periodField } } = nextProps;

        this.setState({
            period: periodField.input.value ?
                periodField.input.value : contactYears.MORE_1Y
        });
    }

    handleCheckboxChange = (e) => {
        const {
            dateOfLastContact: { checked: checkedField, period: periodField },
            clearFormFields,
            options: { formName }
        } = this.props;
        const isChecked = e.target.checked;

        if (!isChecked) {
            clearFormFields(formName, periodField.input.name);
            periodField.input.onChange('');
        } else {
            periodField.input.onChange(contactYears.MORE_1Y);
        }

        checkedField.input.onChange(isChecked);
    };

    handlePeriodChange = ({ value }) => {
        const { dateOfLastContact: { period: periodField } } = this.props;

        periodField.input.onChange(value);
    };

    render() {
        const { dateOfLastContact: { checked: checkedField }, options } = this.props;
        const isChecked = !!checkedField.input.value;

        return (
            <div className='date-of-last-contact'>
                <CheckBox
                    {...checkedField.input}
                    onChange={this.handleCheckboxChange}
                    label={`${options.checkbox}:`}
                    disabled={options.disabled}
                    checked={isChecked}/>
                <Selector
                    value={this.state.period}
                    name='periodYearsSelector'
                    wrapperClass='period-years-selector'
                    onOptionChange={this.handlePeriodChange}
                    options={options.options}
                    disabled={!isChecked}
                    clearable={false}
                    searchable={false}/>
            </div>
        );
    }
}

DateOfLastContactField.parse = (value, name) => {
    if (name === 'dateOfLastContact.period') {
        return value === '' ? null : value;
    }

    return value;
};

export default DateOfLastContactField;
