import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { List } from 'react-virtualized';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import { CheckBox } from 'Components/CheckBox';

class ShareReportUserTemplateList extends Component {
    static propTypes = {
        values: PropTypes.array,
        onValueChange: PropTypes.func,
        selectValueIds: PropTypes.array,
        rowHeight: PropTypes.number,
        visibleRowCount: PropTypes.number,
        headerKey: PropTypes.string
    };

    static defaultProps = {
        rowHeight: 35,
        visibleRowCount: 10
    };

    componentDidUpdate() {
        this.ref && this.ref.forceUpdateGrid();
    }

    handleValueChange = (id, checked) => this.props.onValueChange
        && this.props.onValueChange(id, checked);

    setRef = (ref) => {
        this.ref = ref;
    };

    rowRenderer = (
        {
            key,   // Unique key within array of rows
            index, // Index of row within collection
            style  // Style object to be applied to row (to position it)
        }
    ) => {
        const { values, selectValueIds } = this.props;
        const value = values[index];

        return (
            <div
                key={key}
                style={style}
                data-id={value.id}
                className='list-item'>
                <CheckBox
                    checked={!!_.find(selectValueIds, id => id === value.id)}
                    onChange={e => this.handleValueChange(value.id, e.target.checked)}
                    label={<span title={value.label} className='list-item-text'>{value.label}</span>}/>

            </div>
        );
    };

    render() {
        const { values = [], rowHeight, visibleRowCount } = this.props;
        const rowCount = values.length;

        return (
            <Fragment>
                <div className='share-report-template__user-list-header'>
                    <FormattedMessage id='modals.shareReportTemplate.userListHeader'/>
                </div>
                <List
                    className='share-report-template__user-list list'
                    ref={this.setRef}
                    height={visibleRowCount * rowHeight}
                    rowHeight={rowHeight}
                    rowCount={rowCount}
                    rowRenderer={this.rowRenderer}
                    width={430}/>
            </Fragment>
        );
    }
}

export default ShareReportUserTemplateList;
