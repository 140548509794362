import widgetTypes from './widget-types';

export const groups = {
    TAX_INFO: 'taxInfo',
    TAX_LOTS: 'taxLots'
};

export default {
    [widgetTypes.registeredShareholder1099DIV]: groups.TAX_INFO,
    [widgetTypes.registeredShareholder1099INT]: groups.TAX_INFO,
    [widgetTypes.registeredShareholder1099B]: groups.TAX_INFO,
    [widgetTypes.registeredShareholder1099Misc]: groups.TAX_INFO,
    [widgetTypes.registeredShareholder1042S]: groups.TAX_INFO,
    [widgetTypes.compareShareholders1099DIV]: groups.TAX_INFO,
    [widgetTypes.compareShareholders1099INT]: groups.TAX_INFO,
    [widgetTypes.compareShareholders1099B]: groups.TAX_INFO,
    [widgetTypes.compareShareholders1099Misc]: groups.TAX_INFO,
    [widgetTypes.compareShareholders1042S]: groups.TAX_INFO,
    [widgetTypes.registeredShareholderCurrent]: groups.TAX_LOTS,
    [widgetTypes.registeredShareholderPriorTADRSHistory]: groups.TAX_LOTS,
    [widgetTypes.registeredShareholderClosedSaleType]: groups.TAX_LOTS,
    [widgetTypes.registeredShareholderClosedOtherType]: groups.TAX_LOTS
};
