import React, { Component } from 'react';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import Widget from '../../Widget';
import Top25HoldingsActionPanel from './components/Top25HoldingsControlPanel';
import Top25HoldingsContent from './components/Top25HoldingsContent';

import './Top25HoldingsTable.scss';

class Top25HoldingsTable extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: !props.isPrintMode ? Top25HoldingsActionPanel : null,
            WidgetContent: Top25HoldingsContent
        };

        this.widgetEvents = {
            onBuildDataParams: () => ({
                shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
            })
        };
    }

    render() {
        return (
            <Widget
                className='top-25-holdings-table'
                {...this.props}
                events={this.widgetEvents}
                components={this.widgetComponents}/>
        );
    }
}

export default Top25HoldingsTable;
