import _ from 'lodash';

import { getAllCountries, getStatesByCountyId } from '../../api/countriesAndStates';

export const GET_ALL_COUNTRIES_FETCHING = 'GET_ALL_COUNTRIES_FETCHING';
export const GET_ALL_COUNTRIES_SUCCESS = 'GET_ALL_COUNTRIES_SUCCESS';
export const GET_ALL_COUNTRIES_ERROR = 'GET_ALL_COUNTRIES_ERROR';
export const GET_STATES_BY_COUNTRY_ID_FETCHING = 'GET_STATES_BY_COUNTRY_ID_FETCHING';
export const GET_STATES_BY_COUNTRY_ID_SUCCESS = 'GET_STATES_BY_COUNTRY_ID_SUCCESS';
export const GET_STATES_BY_COUNTRY_ID_ERROR = 'GET_STATES_BY_COUNTRY_ID_ERROR';

export const getAllCountriesList = () => dispatch => {
    dispatch({
        type: GET_ALL_COUNTRIES_FETCHING
    });

    return getAllCountries()
        .then(
            res => {
                const countriesData = _.get(res, 'data.payload.data');

                dispatch({
                    type: GET_ALL_COUNTRIES_SUCCESS,
                    payload: countriesData
                });
            },
            error => {
                dispatch({
                    type: GET_ALL_COUNTRIES_ERROR,
                    payload: { error },
                    error: true
                });
            }
        );
};

export const getStatesList = countryId => dispatch => {
    dispatch({
        type: GET_STATES_BY_COUNTRY_ID_FETCHING
    });

    return getStatesByCountyId(countryId)
        .then(
            res => {
                const statesData = _.get(res, 'data.payload');

                dispatch({
                    type: GET_STATES_BY_COUNTRY_ID_SUCCESS,
                    payload: statesData
                });
            },
            error => {
                dispatch({
                    type: GET_STATES_BY_COUNTRY_ID_ERROR,
                    payload: { error },
                    error: true
                });
            }
        );
};
