export default {
    dollar: '$'
};

export const localeCurrency = {
    'en': {
        code: 'USD',
        symbol: '$'
    },
    'en-GB': {
        code: 'GBP',
        symbol: '£'
    }
};
