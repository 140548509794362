import shareholderColumnsNames from 'Constants/shareholders-columns-names';
import { addressOptions } from './columnsToDisplayOptions';

export const defaultAddressType = addressOptions[0].value;

export default [
    shareholderColumnsNames.REGISTERED_ADDRESS,
    shareholderColumnsNames.DIVIDEND_ADDRESS,
    shareholderColumnsNames.SEASONAL_ADDRESS,
    shareholderColumnsNames.PROXY_ADDRESS,
    shareholderColumnsNames.K1_ADDRESS
];
