import axios from 'axios';

export const buildRequest = (payload, requestType, header) => ({
    requestType,
    header: { ...header },
    payload
});

/**
 * Create cancellation token
 * @return {Object}
 */
export const createCancelToken = () => {
    return axios.CancelToken.source();
};

export const isCancel = res => {
    return axios.isCancel(res);
};
