import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';
import httpStatus from 'Constants/http-status';
import { isReportNameError } from 'Utils/datavizExportHelper';

const saveReportErrorStrategy = {
    errorStrategy: (error) => {
        if (isReportNameError(error)) {
            return errorStrategies.SKIP;
        }
        return errorStrategies.DEFAULT;
    }
};

export function getReportTemplate(reportTemplateId) {
    const requestData = utils.buildRequest({ reportTemplateId }, requestTypes.getReportTemplatePayload);

    return axios.post(apiRoutes.getReportTemplateUrl, requestData, {
        errorStrategy: (error, responseStatus) => {
            if (responseStatus === httpStatus.HTTP_404) {
                return errorStrategies.CRITICAL;
            }
            return errorStrategies.DEFAULT;
        },
        isCancelable: true
    });
}

export function getReportTemplates() {
    const requestData = utils.buildRequest({}, requestTypes.getReportTemplatesPayload);

    return axios.post(apiRoutes.getReportTemplatesUrl, requestData, {
        // TODO: Check on Saved Reports Widget
        isCancelable: false
    });
}

export function saveReportTemplate(reportTemplate) {
    const requestData = utils.buildRequest({ template: reportTemplate }, requestTypes.saveReportTemplatePayload);

    return axios.post(apiRoutes.saveReportTemplateUrl, requestData, {
        isCancelable: false,
        ...saveReportErrorStrategy
    });
}

export function changeReportTemplate(reportTemplate) {
    const requestData = utils.buildRequest({ template: reportTemplate }, requestTypes.changeReportTemplatePayload);

    return axios.post(apiRoutes.changeReportTemplateUrl, requestData, {
        isCancelable: false,
        ...saveReportErrorStrategy
    });
}

export function deleteReportTemplate(reportTemplateId) {
    const requestData = utils.buildRequest({ reportTemplateId }, requestTypes.deleteReportTemplatePayload);

    return axios.post(apiRoutes.deleteReportTemplateUrl, requestData, {
        isCancelable: false,
        errorStrategy: (error, responseStatus) => {
            if (responseStatus === httpStatus.HTTP_423) {
                return errorStrategies.SKIP;
            }
            return errorStrategies.DEFAULT;
        }
    });
}

export function saveDataVizTemplate({ reportTemplateId, reportTemplateName, widgets = [] }) {
    const requestData = utils.buildRequest({
        reportTemplateId,
        reportTemplateName,
        widgets
    }, requestTypes.saveDataVizTemplatePayload);

    return axios.post(apiRoutes.saveDataVizTemplateUrl, requestData, {
        isCancelable: false,
        ...saveReportErrorStrategy
    });
}

export function deleteDataVizTemplate(reportTemplateId) {
    const requestData = utils.buildRequest({ reportTemplateId }, requestTypes.deleteDataVizTemplatePayload);

    return axios.post(apiRoutes.deleteDataVizTemplateUrl, requestData, {
        isCancelable: false,
        errorStrategy: (error, responseStatus) => {
            if (responseStatus === httpStatus.HTTP_423) {
                return errorStrategies.SKIP;
            }
            return errorStrategies.DEFAULT;
        }
    });
}

export function getReportTemplateUsers(reportTemplateId) {
    const requestData = utils.buildRequest({ reportTemplateId }, requestTypes.getReportTemplateUsersPayload);

    return axios.post(apiRoutes.getReportTemplateUsersUrl, requestData, {
        isCancelable: true
    });
}

export function shareReportTemplate(reportTemplateId, userIds) {
    const requestData = utils.buildRequest({ reportTemplateId, userIds }, requestTypes.shareReportTemplatePayload);

    return axios.post(apiRoutes.shareReportTemplateUrl, requestData, { isCancelable: true });
}

