import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ExportNoResultsModalView = ({ onConfirm }) => (
    <div className='info-modal export-no-results-modal'>
        <div className='modal-message'>
            <div>
                <FormattedMessage id='modals.exportNoResults.noResultsMessage'/>
            </div>
            <div>
                <FormattedMessage id='modals.exportNoResults.tryAgainMessage'/>
            </div>
        </div>
        <div className='modal-actions'>
            <button type='button' onClick={onConfirm} className='btn btn-large ok-btn'>
                <FormattedMessage id='common.ok'/>
            </button>
        </div>
    </div>
);

ExportNoResultsModalView.propTypes = {
    onConfirm: PropTypes.func.isRequired
};

export default ExportNoResultsModalView;
