import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from './slots';
import CompareShareholdersPageHeader from './components/CompareShareholdersPageHeader';
import { getSecurityWithFeatureSelector } from 'State/securities/selectors';
import featureTypes from 'Constants/feature-types';
import pageRoutes from 'Constants/page-routes';

import './CompareShareholdersPage.scss';

const mapStateToProps = state => ({
    havePermission: !!getSecurityWithFeatureSelector(state, featureTypes.COMPARE_REGISTERED_SHAREHOLDERS)
});

@connect(mapStateToProps)
class CompareShareholdersPage extends Component {
    static propTypes = {
        havePermission: PropTypes.bool
    };

    render() {
        return (
            this.props.havePermission
                ? <div className='compare-shareholders-page'>
                    <CompareShareholdersPageHeader />
                    <DynamicPage
                        layoutType={layoutTypes.layoutWithTabsPanel}
                        slots={slots}
                        dynamicPageType={PAGE_TYPES.COMPARE_SHAREHOLDERS}/>
                </div>
                : <Redirect to={`${pageRoutes.home}`}/>
        );
    }
}

export default CompareShareholdersPage;
