import _ from 'lodash';
import { createSelector } from 'reselect';
import featureTypes from 'Constants/feature-types';

const getSecurityById = (state, id) => _.find(_.get(state, 'securities.availableSecurities'), security => security.id === id);
const getSecurityWithFeature = (state, featureType) => _.find(_.get(state, 'securities.availableSecurities'),
    security => security.features[featureType]);

export const getAvailableSecurities = state => {
    return _.get(state, 'securities.availableSecurities');
};

export const getSecurityByIdSelector = createSelector(
    getSecurityById,
    security => security
);

export const getSecurityWithFeatureSelector = createSelector(
    getSecurityWithFeature,
    security => security
);

export const getSecuritiesByIds = (state, ids) => ids.map(id => getSecurityById(state, id));

const getIsAvailableAdvancedSearchFeature = (availableSecurities) => (feature) => {
    return _.some(availableSecurities, {
        features: {
            [feature]: true
        }
    });
};

export const isAdvancedSearchAvailable = createSelector(
    getAvailableSecurities,
    (availableSecurities) => {
        const isAvailableAdvancedSearchFeature = getIsAvailableAdvancedSearchFeature(availableSecurities);

        return isAvailableAdvancedSearchFeature(featureTypes.ADVANCED_SEARCH_KEYWORDS)
            || isAvailableAdvancedSearchFeature(featureTypes.ADVANCED_SEARCH_CERT)
            || isAvailableAdvancedSearchFeature(featureTypes.ADVANCED_SEARCH_SSN);
    }
);

export const isSecurityAvailable = createSelector(
    getAvailableSecurities,
    securities => securityId => {
        return !!_.find(securities, security => security.id === securityId);
    }
);

export const getSecuritiesByIssuerId = createSelector(
    getAvailableSecurities,
    securities => issuerId =>
        _.filter(securities, security => security.issuerId === issuerId)
);

export const getSecuritiesByIdsSelector = createSelector(
    getAvailableSecurities,
    securities => (ids = []) =>
        _.filter(securities, security => ids.includes(security.id))
);
