import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

const MAX_LENGTH = 30;
const VALID_CHARACTERS_REG = /^[\w\s.!:;&-]+$/;

export default (value) => {
    if (!_.isString(value) || !value.length) {
        return <FormattedMessage id='reporting.reportName.error.empty'/>;
    }

    if (_.startsWith(value, ' ') || _.endsWith(value, ' ')) {
        return <FormattedMessage id='reporting.reportName.error.spaces'/>;
    }

    if (!VALID_CHARACTERS_REG.test(value)) {
        return <FormattedMessage id='reporting.reportName.error.invalidCharacters'/>;
    }

    if (value.length > MAX_LENGTH) {
        return <FormattedMessage id='reporting.reportName.error.maxLength'/>;
    }

    return null;
};
