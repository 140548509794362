import { TA } from './product-source-types';
import featureTypes from './feature-types';

const all = 'ALL';
const registered = 'REGISTERED';
const institution = 'INSTITUTION';
const insider = 'INSIDER';
const institutionalAndInsider = 'INSTITUTION_AND_INSIDER';
const institutionalAndRegistered = 'INSTITUTION_AND_REGISTERED';
const insiderAndRegistered = 'INSIDER_AND_REGISTERED';
const fund = 'FUND';

export const shareholderTypes = {
    ALL: all,
    REGISTERED: registered,
    INSTITUTION: institution,
    INSIDER: insider,
    INSTITUTION_AND_INSIDER: institutionalAndInsider,
    FUND: fund,
    INSTITUTION_AND_REGISTERED: institutionalAndRegistered,
    INSIDER_AND_REGISTERED: insiderAndRegistered
};

export const shareholderTypeAvailability = {
    [shareholderTypes.INSTITUTION]: {
        features: [featureTypes.OI_PLUS_OPTIONS]
    },
    [shareholderTypes.INSIDER]: {
        features: [featureTypes.OI_PLUS_OPTIONS]
    },
    [shareholderTypes.REGISTERED]: {
        productSources: [TA]
    },
    [shareholderTypes.FUND]: {
        features: [featureTypes.FUND_OVERVIEW]
    }
};

export const shareholderTypesList = [institution, insider, registered];

// uses in geographic analysis control panel
export const shareholderTypesOrder = [shareholderTypes.ALL, shareholderTypes.INSTITUTION, shareholderTypes.REGISTERED];

export default shareholderTypes;
