import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export function getMarketData() {
    const requestData = utils.buildRequest({}, requestTypes.getMarketDataPayload);

    return axios.post(apiRoutes.getMarketDataUrl, requestData, {
        isCancelable: true
    });
}
