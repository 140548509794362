import reportTypes from 'Constants/report-types';
import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';

export default {
    [reportTypes.registeredShareholderTransactionHistoryTemplate]: {
        sortField: columnNames.ISSUE_DATE,
        sortDirection: sortDirections.DESC,
        initial: true
    },
    [reportTypes.registeredShareholderClosedSaleTypeTemplate]: {
        sortField: columnNames.SALE_CLOSE_DATE,
        sortDirection: sortDirections.DESC,
        initial: true
    },
    [reportTypes.registeredShareholderDRDSPPTemplate]: {
        sortField: columnNames.TRANSACTION_DATE,
        sortDirection: sortDirections.DESC,
        initial: true
    },
    [reportTypes.registeredShareholderPaymentsTemplate]: {
        sortField: columnNames.ISSUE_DATE,
        sortDirection: sortDirections.DESC,
        initial: true
    },
    [reportTypes.registeredShareholderClosedOtherType]: {
        sortField: columnNames.SALE_CLOSE_DATE,
        sortDirection: sortDirections.DESC,
        initial: true
    }
};
