import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';
import WidgetRemove from 'Components/widgets/Widget/components/WidgetRemove';

const mapStateToProps = state => ({
    blockAction: getDynamicPageBlockActionSelector(state)
});

@connect(mapStateToProps)
class AnalysisDataVizControlPanel extends Component {
    static propTypes = {
        blockAction: PropTypes.bool,
        onRemoveWidget: PropTypes.func
    };

    render() {
        const { blockAction, onRemoveWidget } = this.props;

        return (
            <div className='widget-control-panel'>
                <WidgetRemove
                    blockAction={blockAction}
                    onRemoveWidget={onRemoveWidget}/>
            </div>
        );
    }
}

export default AnalysisDataVizControlPanel;
