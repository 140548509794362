import { createReducer } from 'Utils/reducer';
import {
    CLEAR_PEERS,
    GET_PEERS_REQUEST,
    GET_PEERS_SUCCESS,
    GET_PEERS_FAILURE,
    FIND_PEER_REQUEST,
    FIND_PEER_SUCCESS,
    FIND_PEER_FAILURE,
    CLEAR_FOUND_PEERS,
    SET_CANCEL_TOKEN_PEERS,
    ADD_PEER_REQUEST,
    ADD_PEER_SUCCESS,
    ADD_PEER_FAILURE,
    REMOVE_PEER_REQUEST,
    REMOVE_PEER_SUCCESS,
    REMOVE_PEER_FAILURE,
    RESET_PEERS_REQUEST,
    RESET_PEERS_SUCCESS,
    RESET_PEERS_FAILURE
} from './actions';

const initialState = {
    error: null,
    isFetching: false,
    foundPeers: null,
    peers: null
};

const actionHandlers = {
    [CLEAR_PEERS]: () => initialState,
    [GET_PEERS_REQUEST]: (state) => ({
        ...state,
        isFetching: true
    }),
    [GET_PEERS_SUCCESS]: (state, { payload: { data } }) => ({
        ...state,
        peers: data,
        isFetching: false
    }),
    [GET_PEERS_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload,
        isFetching: false
    }),

    [FIND_PEER_REQUEST]: (state) => ({
        ...state,
        isLoading: true
    }),
    [FIND_PEER_SUCCESS]: (state, { payload: { data } }) => ({
        ...state,
        foundPeers: data,
        isLoading: false
    }),
    [FIND_PEER_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload,
        isLoading: false
    }),
    [CLEAR_FOUND_PEERS]: (state) => ({
        ...state,
        foundPeers: null
    }),
    [SET_CANCEL_TOKEN_PEERS]:(state, { payload: cancelToken }) => ({
        ...state,
        cancelToken
    }),

    [ADD_PEER_REQUEST]: (state) => ({
        ...state,
        isFetching: true
    }),
    [ADD_PEER_SUCCESS]: (state, { payload: { peer } }) => ({
        ...state,
        peers: [...state.peers, peer],
        foundPeers: null,
        isFetching: false
    }),
    [ADD_PEER_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload,
        foundPeers: null,
        isFetching: false
    }),

    [REMOVE_PEER_REQUEST]: (state) => ({
        ...state,
        isFetching: true
    }),
    [REMOVE_PEER_SUCCESS]: (state, { payload: { peerId } }) => ({
        ...state,
        peers: state.peers.filter(peer => peer.id !== peerId),
        isFetching: false
    }),
    [REMOVE_PEER_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload,
        isFetching: false
    }),

    [RESET_PEERS_REQUEST]: (state) => ({
        ...state,
        isFetching: true
    }),
    [RESET_PEERS_SUCCESS]: (state, { payload: { data } }) => ({
        ...state,
        peers: data,
        isFetching: false
    }),
    [RESET_PEERS_FAILURE]: (state, { payload }) => ({
        ...state,
        error: payload,
        isFetching: false
    })
};

export default createReducer(initialState, actionHandlers);
