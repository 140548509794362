import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import cn from 'classnames';

import CustomReactTable from 'Components/Tables/CustomReactTable';
import ScrollableReactTable from 'Components/Tables/ScrollableReactTable';
import { commonWidgetPropTypes, commonWidgetActions, compareMktVals } from 'Components/widgets/utils';
import columnNames from '../../constants/top-25-holdings-column-names';
import { compareDatesDescending, sortArray } from 'Utils/utils';
import sortDirections from 'Constants/sort-directions';
import shareholderTypes from 'Constants/shareholder-types';
import {
    getTop25HoldingsCustomComponent,
    getTop25HoldingsTdProps,
    getTop25HoldingsTheadThProps
} from '../../utils';
import { getCurrentSecurityIdSelector } from 'State/user';

const FUND_PERIOD = '6M';
const MAX_SHOW_ROWS = 15;

const mapStateToProps = (state) => ({
    currentSecurityId: getCurrentSecurityIdSelector(state)
});

@connect(mapStateToProps)
@mapProps(props => {
    const shareholderType = _.get(props.widgetData, 'type');
    const isFund = shareholderType === shareholderTypes.FUND.toUpperCase();
    const period = isFund ? FUND_PERIOD : _.get(props, 'widgetSettings.period');

    return {
        ...props,
        sortCriteria: _.get(props, 'widgetSettings.sortCriteria'),
        period,
        disabledSelector: isFund,
        rows: (() => {
            const widgetData = _.get(props, 'widgetData.data') || [];
            const sortField = _.get(props, 'widgetSettings.sortCriteria.field');
            const sortDirection = _.get(props, 'widgetSettings.sortCriteria.direction');
            const initial = _.get(props, 'widgetSettings.sortCriteria.initial');
            let primaryComparator;

            switch (sortField) {
                case columnNames.MKT_VAL:
                case columnNames.MKT_VAL_CHANGE: {
                    primaryComparator = compareMktVals;
                    break;
                }
                case columnNames.REPORT_DATE: {
                    primaryComparator = compareDatesDescending;
                    break;
                }
                default: {
                    primaryComparator = undefined;
                    break;
                }
            }

            return sortArray({
                array: widgetData.map(data => {
                    return _.mapValues(data, (value, key) => {
                        switch (key) {
                            case columnNames.OS_CHANGE_PERCENT:
                            case columnNames.POSITION_CHANGE:
                                return _.isObject(value) ? value[period] : null;
                            case columnNames.SECTOR:
                                return _.isArray(value) ? value.join(', ') : null;
                            default:
                                return value;
                        }
                    });
                }),
                primaryField: sortField,
                descending: sortDirection === sortDirections.DESC,
                secondaryField: columnNames.HOLDING_NAME,
                secondaryDescending: false,
                primaryComparator,
                initial
            });
        })()
    };
})
class Top25HoldingsContent extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        disabledSelector: PropTypes.bool,
        currentSecurityId: PropTypes.number,
        intl: intlShape.isRequired
    };

    handleOnSort = (sortCriteria) => {
        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, sortCriteria });
    };

    handleOnChangePeriod = ({ value: period }) => {
        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, period });
    };

    getTheadThProps = (sortField, sortDirection, finalState, missProp, column) => {
        const { period, disabledSelector } = this.props;

        return getTop25HoldingsTheadThProps(column, period, disabledSelector, this.handleOnChangePeriod);
    };

    getTdProps = (finalState, rowInfo, column) => {
        const { currentSecurityId } = this.props;

        return getTop25HoldingsTdProps(column.id, rowInfo.row, currentSecurityId);
    };

    render() {
        const { rows, sortCriteria, isDataViz, isPrintMode, columns } = this.props;

        return (
            <ScrollableReactTable
                maxShowRows={isDataViz && !isPrintMode ? MAX_SHOW_ROWS : null}
                TableComponent={CustomReactTable}
                noDataKey='tables.noResultsFound'
                onChangeSorting={this.handleOnSort}
                sortCriteria={sortCriteria}
                data={rows}
                columns={columns}
                getTheadThProps={this.getTheadThProps}
                getTdProps={this.getTdProps}
                getCustomComponent={getTop25HoldingsCustomComponent}
                className={cn({ 'without-scroll': !isDataViz })}/>
        );
    }
}

export default injectIntl(Top25HoldingsContent);
