import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export function getAllCountries() {
    const requestData = utils.buildRequest({}, requestTypes.allCountriesPayload);

    return axios.post(apiRoutes.getAllCountriesUrl, requestData, {
        isCancelable: true
    });
}

export function getStatesByCountyId(countryId) {
    const requestData = utils.buildRequest({ countryId }, requestTypes.statesPayload);

    return axios.post(apiRoutes.getStatesByCountryIdUrl, requestData, {
        isCancelable: true
    });
}
