import PropTypes from 'prop-types';
import React from 'react';
import { Popover } from 'react-bootstrap';

import './FormError.scss';

const FormError = props => {
    return (
        <Popover
            id='form-error-message'
            placement='bottom'
            className='form-error'
            arrowOffsetLeft={20}>
            <span className='form-error-message'>{props.error}</span>
        </Popover>
    );
};

FormError.propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default FormError;
