import { contactYears } from 'Components/widgets/DateLastContact/constants';

export default [
    {
        value: contactYears.MORE_1Y,
        label: 'date.last.contact.period.more1Y'
    },
    {
        value: contactYears.MORE_2Y,
        label: 'date.last.contact.period.more2Y'
    },
    {
        value: contactYears.MORE_3Y,
        label: 'date.last.contact.period.more3Y'
    }
];
