import React from 'react';
import PropTypes from 'prop-types';

import ShareholderIcon from '../ShareholderIcon';

const Expander = ({ showExpander, handleIconClick, expandedShareholderIds, row }) => {
    const { shareholderType, shareholderId, isFundExists, isSubExists } = row;
    const expanded = expandedShareholderIds.some((id) => id === shareholderId);

    return (
        <ShareholderIcon
            shareholderType={shareholderType}
            expanded={expanded}
            showExpander={showExpander}
            isFundExists={isFundExists || isSubExists}
            onInteraction={handleIconClick}/>
    );
};

Expander.propTypes = {
    expandedShareholderIds: PropTypes.array.isRequired,
    showExpander: PropTypes.bool,
    row: PropTypes.object,
    handleIconClick: PropTypes.func
};

export default Expander;
