/* eslint-disable react/no-multi-comp */
import React from 'react';

import { HeadingCellCommon, CellCommon, CellWithNumber } from 'Components/widgets/_commonComponents/TableCellComponents';

const SHARES_DECIMAL_POINTS = 3;
const columnNames = {
    SECURITY_NAME: 'securityName',
    TOTAL_OUTSTANDING_SHARES: 'totalOutstandingShares'
};

const columns = [
    columnNames.SECURITY_NAME,
    columnNames.TOTAL_OUTSTANDING_SHARES
];

const columnDefinitions = [
    {
        displayName: 'reporting.outstanding.shares.table.column.header.securityName',
        accessor: columnNames.SECURITY_NAME,
        minWidth: 400,
        Header: title => <HeadingCellCommon title={title}/>,
        Cell: cellProps => <CellCommon value={cellProps.value}/>
    },
    {
        displayName: 'reporting.outstanding.shares.table.column.header.totalOutstandingShares',
        accessor: columnNames.TOTAL_OUTSTANDING_SHARES,
        minWidth: 210,
        maxWidth: 210,
        Header: title => (<HeadingCellCommon
            title={title}
            className='cell-with-align-right'/>),
        Cell: cellProps => (<CellWithNumber
            value={cellProps.value}
            additionalData={{ decimalPoints: SHARES_DECIMAL_POINTS }}/>)
    }
];

export {
    columnDefinitions,
    columns
};
