import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { reduxForm, SubmissionError, propTypes as reduxFormPropTypes } from 'redux-form';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

import reportPaths from 'Constants/report-paths';
import reportTypes from 'Constants/report-types';
import reportNameErrors from 'Constants/report-name-errors';
import exportReportFormats from 'Constants/export-report-formats';
import * as userActions from 'State/user/actions';
import * as savedReportTemplatesActions from 'State/savedReportTemplates/actions';
import * as savedReportTemplatesSelectors from 'State/savedReportTemplates/selectors';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import * as widgetsSelectors from 'State/widgets/selectors';
import { saveChangesModal } from 'State/modal/actions';
import { runReport } from 'State/exportReports/actions';
import { printWidget } from 'State/print/actions';
import PagePrompt from 'Components/PagePrompt';
import ReportingDataVizReportPageControlPanelView from '../components/ReportingDataVizReportPageControlPanelView';
import { isReportNameError } from 'Utils/datavizExportHelper';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';
import { reportNameValidation } from '../../common/reportNameUtils';

const templateWasChanged = (initialWidgets, widgets) => {
    if (_.size(initialWidgets) !== _.size(widgets)) {
        return true;
    }

    return _.some(initialWidgets, (initialWidget, key) => {
        const widget = widgets[key];

        if (widget) {
            return initialWidget.widgetId !== widget.widgetId
                || !_.isEqual(initialWidget.settings, widget.settings);
        }
        return true;
    });
};
const getInitialWidgets = widgets => {
    return _.mapValues(widgets, widget => ({
        widgetId: widget.widgetId,
        settings: widget.settings
    }));
};

const FORM_NAME = 'reportingDataVizReportForm';
const mapStateToProps = (state, ownProps) => {
    const dynamicPageInfo = dynamicPageSelectors.getDynamicPageInfoSelector(state);

    return ({
        isFetching: dynamicPageSelectors.getDynamicPageFetchingSelector(state),
        report: savedReportTemplatesSelectors.getReportByIdSelector(state, ownProps.reportTemplateId),
        widgets: widgetsSelectors.getWidgetsSelector(state),
        widgetsBySlots: dynamicPageSelectors.getWidgetsBySlotsSelector(state),
        initialValues: {
            reportTemplateName: _.get(dynamicPageInfo, 'reportTemplateName')
        }
    });
};
const mapDispatchToProps = {
    saveDataVizTemplate: savedReportTemplatesActions.saveDataVizTemplate,
    changeUserNotifications: userActions.changeUserNotifications,
    saveChangesModal,
    printWidget,
    runReport
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: FORM_NAME,
    validate: values => {
        const errors = {};

        errors.reportTemplateName = reportNameValidation(values.reportTemplateName);

        return errors;
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})
class ReportingDataVizReportPageControlPanel extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        reportTemplateId: PropTypes.number,
        widgets: PropTypes.object,
        widgetsBySlots: PropTypes.array,
        saveDataVizTemplate: PropTypes.func,
        saveChangesModal: PropTypes.func,
        changeUserNotifications: PropTypes.func,
        runReport: PropTypes.func,
        isStarted: PropTypes.bool,
        isFetching: PropTypes.bool,
        history: PropTypes.object,
        location: PropTypes.object,
        intl: intlShape
    };

    state = {
        templateWasSaved: false,
        templateIsDirty: false,
        initialWidgets: null
    };

    UNSAFE_componentWillUpdate() {
        const { isStarted, changeUserNotifications } = this.props;

        if (isStarted && this.state.templateWasSaved) {
            changeUserNotifications({
                newDataVizReports: 0
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { widgets } = this.props;
        const { initialWidgets } = this.state;

        // don't track changes in widgets if dataPrez (old dataViz) template is started
        if (this.props.isStarted) {
            return;
        }

        // dataPrez (old dataViz) page was changed
        if (this.props.reportTemplateId !== prevProps.reportTemplateId) {
            this.setState({
                initialWidgets: null,
                templateWasSaved: false
            });
        }

        if (!_.isEmpty(widgets) &&
            (widgets !== prevProps.widgets || initialWidgets !== prevState.initialWidgets)) {
            if (!initialWidgets) {
                this.setState({
                    initialWidgets: getInitialWidgets(widgets)
                });
            } else {
                this.setState({
                    templateIsDirty: templateWasChanged(initialWidgets, widgets)
                });
            }
        }
    }

    handleSave = ({ reportTemplateName }) => {
        const { isStarted, reportTemplateId, saveDataVizTemplate, widgetsBySlots } = this.props;

        return saveDataVizTemplate({
            reportTemplateId,
            reportTemplateName,
            widgets: isStarted ? [] : widgetsBySlots
        })
            .then((res) => {
                const savedReportTemplateId = _.get(res, 'data.payload.reportTemplateId');

                return this.successSaveTemplateHandler(reportTemplateName, savedReportTemplateId);
            })
            .catch(this.errorSaveTemplateHandler);
    };

    handleExport = () => {
        const { isStarted, reportTemplateId, widgetsBySlots, initialValues: { reportTemplateName } } = this.props;

        if (isStarted) {
            this.props.runReport(reportTypes.dataVizStartedTemplate, reportTemplateId, exportReportFormats.ZIP);
        } else if (this.state.templateIsDirty) {
            this.props.runReport(reportTypes.dataVizTemp, null, exportReportFormats.ZIP, {
                reportTemplateName,
                widgets: widgetsBySlots
            });
        } else {
            this.props.runReport(reportTypes.dataVizTemplate, reportTemplateId, exportReportFormats.ZIP);
        }
    };

    handlePrint = () => {
        this.props.printWidget('dataViz');
    };

    successSaveTemplateHandler = (reportTemplateName, savedReportTemplateId) => {
        const { isStarted, intl: { formatMessage }, history, location } = this.props;

        this.props.initialize({ reportTemplateName });

        if (isStarted) {
            this.setState({ templateWasSaved: true }, () => {
                history.replace(buildLocationWithSafeQueryParams(`${reportPaths[reportTypes.dataVizTemplate]}/${savedReportTemplateId}`, location));
            });
        } else {
            toastr.success(
                formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.title' }),
                formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.message' })
            );

            this.setState({ initialWidgets: null });
        }
    };

    errorSaveTemplateHandler = error => {
        const { intl: { formatMessage } } = this.props;
        const reason = _.get(error, 'response.data.reasonType', '');
        const errorMessage = reportNameErrors[reason];

        if (isReportNameError(error)) {
            throw new SubmissionError({
                reportTemplateName: formatMessage({ id: errorMessage })
            });
        }
    };

    showSaveChangesModal = ({ isActive, onCancel, onConfirm }) => {
        const { initialValues: { reportTemplateName } } = this.props;

        if (isActive) {
            return (
                <PagePrompt
                    onConfirm={onCancel}
                    onClose={onCancel}
                    payload={reportTemplateName}
                    onCancel={onConfirm}/>
            );
        }
    };

    needToConfirmLeaving = () => {
        return (this.state.templateIsDirty || this.props.dirty) && this.props;
    };

    render() {
        const { isFetching, isStarted, widgetsBySlots = [] } = this.props;

        return !isFetching
            ? <div>
                <ReportingDataVizReportPageControlPanelView
                    onSave={this.handleSave}
                    onExport={this.handleExport}
                    onPrint={this.handlePrint}
                    isStarted={isStarted}
                    hasWidgets={!!widgetsBySlots.length}
                    templateIsDirty={this.state.templateIsDirty}
                    {...this.props}/>
                <NavigationPrompt when={this.needToConfirmLeaving}>
                    {this.showSaveChangesModal}
                </NavigationPrompt>
            </div>
            : null;
    }
}

export default ReportingDataVizReportPageControlPanel;
