import { connect } from 'react-redux';

import ReportRequestedModal from './ReportRequestedModal';
import { hideModal } from 'State/modal';

const mapStateToProps = state => ({
    ...state.modal
});
const mapDispatchToProps = { hideModal };


export default connect(mapStateToProps, mapDispatchToProps)(ReportRequestedModal);
