import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SubmissionError } from 'redux-form';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import Search from 'Components/Search';
import SearchLink from '../SearchLink';
import featureTypes from 'Constants/feature-types';
import { VALIDATION_LENGTH } from 'Constants/search';
import { isValidGlobalSearchQuery } from 'Utils/validators';
import ClickOutside from 'Components/ClickOutside';
import { RecentSearch } from 'Components/RecentSearch';

const searchFieldName = 'globalSearch';

class SearchSection extends Component {
    static propTypes = {
        formName: PropTypes.string.isRequired,
        currentSecurityId: PropTypes.number,
        currentFeatures: PropTypes.object,
        availableSecurities: PropTypes.array,
        advancedSearchForSingleSecurity: PropTypes.bool,
        isAdvancedSearchAvailable: PropTypes.bool,
        showAdvancedSearchModal: PropTypes.func,
        searchGlobally: PropTypes.func,
        userFeatures: PropTypes.object,
        intl: intlShape,
        isFocus: PropTypes.bool,
        isFetching: PropTypes.bool,
        cancelSearchRequest: PropTypes.func,
        hideModal: PropTypes.func,
        setFocus: PropTypes.func,
        reset: PropTypes.func,
        setIsNewRequest: PropTypes.func,
        formMeta: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.searchBoxRef = React.createRef();
    }

    state = {
        showRecentSearch: false
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isFetching === false && this.props.isFetching === true) {
            this.props.reset();
        }
    }

    handleClickAdvancedSearch = () => {
        this.props.showAdvancedSearchModal({
            titleKey: 'search.by.title',
            onClose: this.handleCloseModal
        });
    };

    handleCloseModal = () => {
        this.props.cancelSearchRequest();
        this.props.hideModal();
    };

    handleClickSearch = (values) => {
        const { intl: { formatMessage } } = this.props;
        const value = _.get(values, searchFieldName, '');
        const searchInput = document.querySelector('.search-input input');

        if (!isValidGlobalSearchQuery(value)) {
            throw new SubmissionError({
                [searchFieldName]: formatMessage(
                    { id: 'search.valid.length' },
                    { minLength: VALIDATION_LENGTH.MIN_LENGTH, maxLength: VALIDATION_LENGTH.MAX_LENGTH }
                )
            });
        }

        this.props.searchGlobally(value);
        searchInput && searchInput.blur();
    };

    handleSearchIconClick = () => {
        this.props.setFocus(true);
    };

    handleBlur = () => {
        this.props.setFocus(false);
    };

    handleOpenRecentSearch = () => {
        this.setState(prevState => ({
            ...prevState,
            showRecentSearch: true
        }));
    }

    handleCloseRecentSearch = () => {
        this.setState(prevState => ({
            ...prevState,
            showRecentSearch: false
        }));
    }

    render() {
        const {
            formName,
            currentSecurityId, advancedSearchForSingleSecurity,
            availableSecurities,
            isFocus, isFetching,
            currentFeatures,
            isAdvancedSearchAvailable
        } = this.props;
        const { showRecentSearch } = this.state;
        const hasOneSecurity = availableSecurities.length === 1;

        return (
            <div className='search-section'>
                {!_.isNil(currentSecurityId) && currentFeatures[featureTypes.GLOBAL_SEARCH] && (
                    <ClickOutside ignoreEl={this.searchBoxRef} onClickOutside={this.handleCloseRecentSearch}>
                        <div onClick={this.handleOpenRecentSearch} ref={this.searchBoxRef}>
                            <Search
                                formName={formName}
                                name={searchFieldName}
                                autoComplete={false}
                                onSearch={this.handleClickSearch}
                                onSearchIconClick={this.handleSearchIconClick}
                                withFocus={isFocus}
                                isLoading={isFetching}
                                onBlur={this.handleBlur}/>
                            {showRecentSearch && <RecentSearch className='float-recent-search' />}
                        </div>
                    </ClickOutside>
                )}
                {currentFeatures[featureTypes.GLOBAL_LINKS] && isAdvancedSearchAvailable &&
                <SearchLink
                    hasOneSecurity={hasOneSecurity}
                    advancedSearchForSingleSecurity={advancedSearchForSingleSecurity}
                    onClickAdvancedSearch={this.handleClickAdvancedSearch}/>}
            </div>
        );
    }
}

export default SearchSection;
