import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { commonSortDescendingIcon, commonSortAscendingIcon } from 'Components/Tables/components/commonSortIcons';

const HeadingCellCommon = (props) => {
    const { sortProperty, title, sortAscendingIcon, sortDescendingIcon, className } = props;

    return (
        <div className={cn('heading-cell', className)}>
            {title}
            {sortProperty && sortProperty.sortAscending && sortAscendingIcon}
            {sortProperty && !sortProperty.sortAscending && sortDescendingIcon}
        </div>
    );
};

HeadingCellCommon.propTypes = {
    columnId: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object,
    extraData: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.object,
    date: PropTypes.any,
    sortAscendingIcon: PropTypes.element,
    sortDescendingIcon: PropTypes.element,
    sortProperty: PropTypes.object,
    onClick: PropTypes.func
};

HeadingCellCommon.defaultProps = {
    sortAscendingIcon: commonSortAscendingIcon,
    sortDescendingIcon: commonSortDescendingIcon
};

export default HeadingCellCommon;
