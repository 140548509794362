import React, { Component } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { commonWidgetPropTypes, commonWidgetActions, getManageableColumns } from 'Components/widgets/utils';
import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import { withFilteredData } from '../_commonComponents/RegisteredShareholderTable/hoc';

@withFilteredData
class RegisteredShareholderPayments extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    render() {
        const props = this.props;
        const values = props.isExceeded
            ? []
            : get(props, 'widgetData.values', []);
        const infoMessage = () => (
            <FormattedMessage id='registeredOverview.infoMessage.payments' values={{ value: props.pageSize }}>
                {message => <span className='description message-note'>{message}</span>}
            </FormattedMessage>
        );

        return (
            <RegisteredShareholderTable
                {...props}
                secondarySorting={secondarySorting}
                data={values}
                columnsDefinitions={columnsDefinitions}
                manageableColumns={getManageableColumns(columnsDefinitions)}
                WidgetInfoPanel={infoMessage}
                disableValidation/>
        );
    }
}

export default RegisteredShareholderPayments;
