import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import Spinner from 'Components/Spinner';
import AlertsModalView from './components/AlertsModalView';
import { getFullName } from 'Utils/utils';

import './AlertsModal.scss';

const AlertsModal = props => {
    const [pageNumber, setPageNumber] = useState(1);

    const handleFirstAlertClick = useCallback(() => {
        pageNumber > 1 && setPageNumber(1);
    }, [pageNumber]);

    const handlePrevAlertClick = useCallback(() => {
        pageNumber > 1 && setPageNumber(prevPageNumber => prevPageNumber - 1);
    }, [pageNumber]);

    const handleNextAlertClick = useCallback(() => {
        pageNumber < props.alerts.length && setPageNumber(prevPageNumber => prevPageNumber + 1);
    }, [pageNumber, props.alerts]);

    const handleLastAlertClick = useCallback(() => {
        pageNumber < props.alerts.length && setPageNumber(props.alerts.length);
    }, [pageNumber, props.alerts]);

    const getReplyLink = useCallback(() => {
        const currentAlert = _.get(props, ['alerts', pageNumber - 1], {});

        return currentAlert.email
            ? `mailto:${currentAlert.email}?body=${currentAlert.alertText}`
            : null;
    }, [pageNumber, props.alerts]);

    const getForwardLink = useCallback(() => {
        const currentAlert = _.get(props, ['alerts', pageNumber - 1], {});
        const emailBody = `On behalf of ${getFullName(currentAlert.authorFirstName, currentAlert.authorLastName)}\n${currentAlert.alertText}`;
        const alertEmail = currentAlert.email ? 'cc='.concat(currentAlert.email, '&') : '';

        return `mailto:?${alertEmail}body=${encodeURIComponent(emailBody)}`;
    }, [pageNumber, props.alerts]);

    const handleCopyAlert = useCallback(e => {
        e.preventDefault();

        const el = document.getElementById('alertText');
        const range = document.createRange();

        range.selectNode(el);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();

        props.notifySuccess(null, <FormattedMessage id='modals.alerts.notifier.copy.success'/>);
    }, [pageNumber, props.alerts]);

    const handleDismissAlert = useCallback(e => {
        const alertId = _.get(props, ['alerts', pageNumber - 1, 'alertId']);

        e.preventDefault();

        pageNumber === props.alerts.length && setPageNumber(prevPageNumber => prevPageNumber - 1);
        props.dismissAlert(alertId).then(() => {
            props.alerts.length === 1 && props.hideModal();
        });
    }, [pageNumber, props.alerts]);

    useEffect(() => {
        props.getAlerts();

        return () => props.clearAlerts();
    }, []);

    return (<div className='alerts-modal'>
        {props.isFetching || props.alerts.length === 0
            ? <Spinner isFetching/>
            : <AlertsModalView
                alerts={props.alerts}
                pageNumber={pageNumber}
                onFirstAlertClick={handleFirstAlertClick}
                onPrevAlertClick={handlePrevAlertClick}
                onNextAlertClick={handleNextAlertClick}
                onLastAlertClick={handleLastAlertClick}
                getReplyLink={getReplyLink}
                getForwardLink={getForwardLink}
                onCopyAlert={handleCopyAlert}
                onDismissAlert={handleDismissAlert}/>
        }
    </div>);
};

AlertsModal.propTypes = {
    getAlerts: PropTypes.func,
    dismissAlert: PropTypes.func,
    clearAlerts: PropTypes.func,
    isFetching: PropTypes.bool,
    alerts: PropTypes.array,
    hideModal: PropTypes.func,
    notifySuccess: PropTypes.func
};

AlertsModal.defaultProps = {
    alerts: []
};

export default memo(AlertsModal);
