import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Spinner from 'Components/Spinner';
import { RecentSearch } from 'Components/RecentSearch';
import SearchFilterView from '../components/SearchFilter';
import * as searchSelectors from 'State/advancedSearch/selectors';
import SearchResultContentSection from '../components/SearchResultContentSection';

import './SearchResultPage.scss';

const mapStateToProps = (state) => ({
    isFetching: searchSelectors.isFetchingAny(state),
    query: searchSelectors.getSearchQuery(state),
    isSearchTreasury: searchSelectors.isSearchTreasurySelector(state)
});

@withRouter
@connect(mapStateToProps)
class SearchResultPage extends Component {
    static propTypes = {
        isFetching: PropTypes.bool,
        query: PropTypes.string,
        isSearchTreasury: PropTypes.bool,
        location: PropTypes.object
    };

    render() {
        const {
            isFetching,
            isSearchTreasury,
            location: {  pathname, search }
        } = this.props;
        const returnPath = {  pathname, search };

        return (
            <div className='search-result-page'>
                <div className='aside-panel'>
                    {!isSearchTreasury && <SearchFilterView/>}
                    <RecentSearch className='search-result-recent-search' hideLoader returnPath={returnPath} />
                </div>
                <div className='main-content'>
                    { isFetching ?
                        <Spinner isFetching={isFetching}/>
                        : <SearchResultContentSection/>}
                </div>
            </div>
        );
    }
}

export default SearchResultPage;
