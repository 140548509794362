import _ from 'lodash';

import colorSchemas from 'Constants/color-schemas';

export const colorizeValues = (values, colorSchemeId) => {
    const colorScheme = colorSchemas[colorSchemeId];

    if (values && colorScheme) {
        return _.map(values, (value, i) => ({
            id: _.uniqueId(`value_indexed_${i}_`),
            ...value,
            color: colorScheme[i % colorScheme.length]
        }));
    }

    return values;
};
/*
For widgets that have widgetData like this:
"data": {
    "aggregate": { ... },
    "values": [ ... ]
}
 */
export const simpleColorizeWidgetData = (widgetData, widgetSettings) => {
    const values = _.get(widgetData, 'values');
    const colorSchemeId = _.get(widgetSettings, 'colorScheme');

    if (values && colorSchemeId) {
        return {
            ...widgetData,
            values: colorizeValues(values, colorSchemeId)
        };
    }
    return widgetData;
};

/*
For widgets that have widgetData like this:
"data": [
    {
        "aggregate": { ... },
        "values": [ ... ]
    },
    {
        "aggregate": { ... },
        "values": [ ... ]
    }...
]
 */
export const colorizeCombinedValues = (values, colorSchemeId) => {
    const colorScheme = colorSchemas[colorSchemeId];

    if (values && colorScheme) {
        let groupNumber = 0;

        return _.map(values, (value, i) => {
            value.isAggregate && groupNumber++;
            return {
                id: _.uniqueId(`value_indexed_${i}_`),
                ...value,
                color: value.isAggregate ? undefined : colorScheme[(i - groupNumber) % colorScheme.length]
            };
        });
    }

    return values;
};
