import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { DateFormatter } from 'Components/formatters';
import { NOT_APPLICABLE } from 'Constants/undefined-values';

const CellWithDate = (props) => {
    const { value, className, format } = props;
    const cellClass = cn('cell-with-date', className);

    return (
        <div className={cellClass}>
            {value !== NOT_APPLICABLE
                ? <DateFormatter value={value} format={format}/>
                : <FormattedMessage id={NOT_APPLICABLE}/>}
        </div>
    );
};

CellWithDate.propTypes = {
    value: PropTypes.any,
    format: PropTypes.string,
    className: PropTypes.string
};

export default CellWithDate;
