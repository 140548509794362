import React, { Component } from 'react';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';

import './PlaceholderWidget.scss';

class PlaceholderWidget extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: () => {
                return (
                    <div className='widget-content'>Widget Placeholder</div>
                );
            },
            WidgetControlPanel: ShareholderWidgetControlPanel
        };

        this.widgetTitle = 'Widget placeholder';
    }

    render() {
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            }
        };

        return (
            <Widget
                {...props}
                className='widget-placeholder'
                components={this.widgetComponents}/>
        );
    }
}

export default PlaceholderWidget;
