import PropTypes from 'prop-types';
import React from 'react';
import { withProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';

import { commonWidgetPropTypes, commonWidgetActions } from '../../../utils';
import columnsDefinitions from '../../constants/columnsDefinitions';
import {
    resolveSortingViewId,
    getTdProps, getCurrentSortCriteria, getCurrentViewId
} from '../../utils';
import { getSortDirection } from 'Components/Tables/utils';
import { getShareholderIsFetching, getShareholdersIsError, setExpandedShareholders } from 'State/shareholderTable';
import columnTypes from '../../constants/shareholder-column-types';
import * as featuresSelectors from 'State/features/selectors';
import sortDirections from 'Constants/sort-directions';
import FundsTable from '../FundsTable';
import ShareholderPositions from '../ShareholderPositions';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import { getExpandedShareholdersSelector } from 'State/shareholderTable/selectors';
import shareholderTypes from 'Constants/shareholder-types';
import featureTypes from 'Constants/feature-types';
import ScrollableReactTable from 'Components/Tables/ScrollableReactTable';
import {
    getFundTableColumns,
    getTableColumnsWithExpander,
    getAvailableDefaultViewId,
    getSubTableColumns
} from './selectors';
import { withPrintModeState } from 'Hoc';
import NullComponent from 'Components/widgets/Widget/components/NullComponent';
import Spinner from 'Components/Spinner';

const EXPANDER_FUNDS_FIELD = 'funds';
const EXPANDER_SUBS_FIELD = 'subs';
const EXPANDER_POSITIONS_FIELD = 'positions';
const MAX_SHOW_ROWS = 15;

const mapStateToProps = (state) => ({
    expandedShareholders: getExpandedShareholdersSelector(state),
    userProductSources: featuresSelectors.getCurrentProductSourcesSelector(state),
    userFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
    isLoading: getShareholderIsFetching(state),
    isError: getShareholdersIsError(state)
});
const mapDispatchToProps = { setExpandedShareholders };

@withPrintModeState
@connect(mapStateToProps, mapDispatchToProps)
@withProps(props => {
    const {
        widgetSettings: {
            sortCriteria = {},
            views: { defaultViews, customViews },
            expandedInstitutions
        } = {},
        sortedValues,
        expandedShareholders: expandedPositions = {}
    } = props;
    const { sortField, sortDirection } = sortCriteria;
    const expandedFunds = _.reduce(expandedInstitutions, (result, value, key) => {
        const institutionHasFound = !!_.find(sortedValues, { shareholderId: `${key}`, isFundExists: true });

        return _.set(result, `${key}.funds`, institutionHasFound);
    }, {});

    const expandedSubs = _.reduce(expandedInstitutions, (result, value, key) => {
        const institutionHasFound = !!_.find(sortedValues, { shareholderId: `${key}`, isSubExists: true });

        return _.set(result, `${key}.subs`, institutionHasFound);
    }, {});

    const expandedShareholdersMap = _.defaultsDeep({}, expandedFunds, expandedSubs, expandedPositions);
    const expandedShareholdersId = Object.keys(expandedShareholdersMap);
    const expandedFundsShareholderIds = expandedShareholdersId.filter(id => expandedShareholdersMap[id].funds || expandedShareholdersMap[id].subs);
    const manualExpandedRows = {};
    const currentViewId = _.get(props.widgetSettings, 'views.currentViewId') ||
        getAvailableDefaultViewId(props);
    const currentView = defaultViews[currentViewId] || customViews[currentViewId] || {};
    const positionsAvailable = _.includes(currentView.values, columnTypes.POSITION);

    if (expandedShareholdersId.length > 0) {
        sortedValues.forEach((value, i) => {
            if (expandedShareholdersId.some(id => value.shareholderId === id)) {
                manualExpandedRows[i] = {};
            }
        });
    }

    return {
        expandedFundsShareholderIds,
        positionsAvailable,
        sortCriteria,
        sortField,
        sortDirection,
        manualExpandedRows,
        expandedShareholdersMap,
        currentView
    };
})
class ShareholderContent extends React.Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape.isRequired,
        expandedShareholders: PropTypes.object,
        userProductSources: PropTypes.array,
        sortedValues: PropTypes.array,
        availableDisplayTypes: PropTypes.array,
        availableShareholderTypesList: PropTypes.array
    };

    handleSort = ([sortProperties]) => {
        const settings = this.props.widgetSettings;
        const { sortField, sortDirection } = this.props;
        const selectedSortField = sortProperties.id;
        const sortCriteria = {
            sortDirection: getSortDirection(selectedSortField, sortField, sortDirection, columnsDefinitions),
            sortField: selectedSortField,
            initial: false
        };

        this.props.onUpdateWidgetSettings({
            ...settings,
            sortCriteria,
            viewsSortCriteria: {
                ...settings.viewsSortCriteria,
                [resolveSortingViewId(settings.views.currentViewId)]: { ...sortCriteria }
            }
        });
    };

    handleFundsToggle = (expandedRows, currentlyChanged) => {
        const { userFeatures = {}, sortedValues } = this.props;
        const shareholder = sortedValues[currentlyChanged[0]];
        const { shareholderId, shareholderType } = shareholder;
        const isExpandable = userFeatures[featureTypes.FUNDS_VIEW] && shareholderType === shareholderTypes.INSTITUTION;

        if (isExpandable) {
            const {
                sortedValues: values,
                widgetSettings = {}
            } = this.props;
            const expandedInstitutions = { ...(widgetSettings.expandedInstitutions || {}) };

            if (expandedInstitutions[shareholderId]) {
                delete expandedInstitutions[shareholderId];
            } else {
                expandedInstitutions[shareholderId] = true;
            }

            // Clear shareholders that not presented in current security
            Object.keys(expandedInstitutions).forEach((key) => {
                !values.some((sh) => sh.shareholderId === key) && delete expandedInstitutions[key];
            });

            this.props.onUpdateWidgetSettings({
                ...widgetSettings,
                expandedInstitutions
            });
        }
    };

    handleFiltration = (selected) => {
        const settings = { ...this.props.widgetSettings };
        const displayTypes = selected.filter(option => option.isSelected).map((type) => type.name);

        if (!_.isEqual((this.props.availableDisplayTypes).sort(), displayTypes.sort())) {
            settings.displayTypes = displayTypes;
            this.props.onUpdateWidgetSettings(settings);
        }
    };

    getFilterOptions = () => {
        const { intl, availableDisplayTypes, availableShareholderTypesList } = this.props;

        return availableShareholderTypesList
            .map((type) => {
                return {
                    name: type,
                    label: intl.formatMessage({ id: `shareholders.type.${type.toLowerCase()}` }),
                    isSelected: availableDisplayTypes.some((displayType) => {
                        if (!_.isString(displayType) || !_.isString(type)) return false;

                        return displayType.toUpperCase() === type.toUpperCase();
                    })
                };
            });
    };

    getTableTdProps = (finalState, rowInfo, column) => {
        const { userFeatures, userProductSources, widgetSettings } = this.props;

        const sortCriteria = getCurrentSortCriteria(widgetSettings);
        const currentViewId = getCurrentViewId(widgetSettings);

        return {
            additionalData: getTdProps(column.id, rowInfo.row, {
                onPositionToggle: userFeatures[featureTypes.POSITIONS_HISTORY_VIEW] ? this.onPositionsToggle : null,
                features: userFeatures,
                productSources: userProductSources,
                sortCriteria,
                currentViewId
            })
        };
    };

    getTheadThProps = (finalState, missProp, column) => {
        const { sortField, sortDirection } = this.props;
        const defaultProps = {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };

        switch (column.id) {
            case columnTypes.SHAREHOLDER_NAME:
                return {
                    onFiltration: this.handleFiltration,
                    filterOptions: this.getFilterOptions(),
                    ...defaultProps
                };
            default:
                return defaultProps;
        }
    };

    getColumns = () => {
        return getTableColumnsWithExpander(this.props);
    }

    onPositionsToggle = (shareholderId) => {
        const { userFeatures } = this.props;

        if (userFeatures[featureTypes.POSITIONS_HISTORY_VIEW]) {
            const expanded = { ...this.props.expandedShareholders };

            expanded[shareholderId] = {
                ...expanded[shareholderId],
                [EXPANDER_POSITIONS_FIELD]: !(_.get(expanded, `[${shareholderId}][${EXPANDER_POSITIONS_FIELD}]`))
            };

            // removing false values
            Object.keys(expanded[shareholderId]).every((key) => !expanded[shareholderId][key]) && delete expanded[shareholderId];

            this.props.setExpandedShareholders(expanded);
        }
    };

    subComponent = (info) => {
        const {
            positionsAvailable, expandedShareholdersMap, sortCriteria
        } = this.props;
        const shareholderId = _.get(info, 'row.shareholderId');

        if (expandedShareholdersMap[shareholderId][EXPANDER_SUBS_FIELD]) {
            return (
                <div>
                    <FundsTable
                        shareholderId={shareholderId}
                        isSubProxy
                        columns={getSubTableColumns(this.props)}
                        positionsAvailable={positionsAvailable}
                        sortCriteria={sortCriteria}/>
                </div>
            );
        }

        if (positionsAvailable && expandedShareholdersMap[shareholderId][EXPANDER_POSITIONS_FIELD]) {
            return (
                <div>
                    <ShareholderPositions
                        close={this.onPositionsToggle}
                        shareholderId={shareholderId}/>
                </div>
            );
        }

        if (expandedShareholdersMap[shareholderId][EXPANDER_FUNDS_FIELD]) {
            return (
                <div>
                    <FundsTable
                        shareholderId={shareholderId}
                        columns={getFundTableColumns(this.props)}
                        positionsAvailable={positionsAvailable}
                        sortCriteria={sortCriteria}/>
                </div>
            );
        }
    };

    render() {
        const {
            isDataViz, isPrintMode,
            sortedValues,
            manualExpandedRows,
            isError,
            isLoading
        } = this.props;

        const NoDataComponent = !isLoading && sortedValues.length === 0 ? NoResults : NullComponent;
        let LoadingComponent = NullComponent;

        if (isLoading) {
            LoadingComponent = () => <Spinner isFetching holderTag='div' contentTag='div'/>;
        }

        if (isError) {
            return (
                <div className='rt-tr error'>
                    <FormattedMessage id='widgets.errorMessage'/>
                </div>
            );
        }

        return (
            <ScrollableReactTable
                showPagination={false}
                resizable={false}
                columns={this.getColumns()}
                onSortedChange={this.handleSort}
                onExpandedChange={this.handleFundsToggle}
                className={cn('shareholders-table-rt scroll-handle', { 'without-scroll': isPrintMode })}
                LoadingComponent={LoadingComponent}
                getTdProps={this.getTableTdProps}
                getTheadThProps={this.getTheadThProps}
                TdComponent={TdComponent}
                ThComponent={TheadComponent}
                NoDataComponent={NoDataComponent}
                manual
                defaultPageSize={0}
                resolvedData={sortedValues}
                maxShowRows={isDataViz && !isPrintMode ? MAX_SHOW_ROWS : null}
                expanded={manualExpandedRows}
                SubComponent={this.subComponent}/>
        );
    }
}

export default injectIntl(ShareholderContent);
