import PropTypes from 'prop-types';
import React from 'react';

import dateFormats from 'Constants/date-formats';
import NullableFormatter from './NullableFormatter';
import { dateFormatter } from './formatters';

const DateFormatter = (props) => {
    const { value, format, className } = props;
    const formattedValue = dateFormatter(value, format);

    return (
        <NullableFormatter className={className} value={formattedValue}/>
    );
};

DateFormatter.propTypes = {
    value: PropTypes.any,
    format: PropTypes.string,
    className: PropTypes.string
};

DateFormatter.defaultProps = {
    format: dateFormats.default
};

export default DateFormatter;
