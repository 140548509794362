import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { withTooltip } from 'Hoc';

import './RadioButton.scss';

const RadioButton = (props) => {
    const { label, labelId, className, disabled, position = 'inline', ...rest } = props;
    const labelClass = cn('radio-component', position, className, { disabled });
    const restProps = _.omit(rest, 'availableFor');

    return (
        <label className={labelClass}>
            <input
                {...restProps}
                className='radio-input'
                type='radio'
                disabled={disabled}/>
            <i className='font-icon font-icon-radio-no-selected'/>
            <i className='font-icon font-icon-radio-selected'/>
            {label && <span className='radio-label'>{label}</span>}
            {labelId &&
            <FormattedMessage id={labelId}>
                {(formattedMessage) => <span className='radio-label'>{formattedMessage}</span>}
            </FormattedMessage>
            }
        </label>
    );
};

RadioButton.propTypes = {
    label: PropTypes.string,
    labelId: PropTypes.string,
    position: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string
};

export default withTooltip(RadioButton);
