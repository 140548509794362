import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import LinkEx from 'Components/LinkEx';
import pageRoutes from 'Constants/page-routes';

import './ReportDownloads.scss';

const ReportDownloadsView = ({ downloadNotifications }) => {
    return (
        <div className='report-downloads'>
            <LinkEx activeClassName='active' to={pageRoutes.reportingDownloads} className='report-downloads-link'>
                <FormattedMessage id='reporting.nav.downloads'/>
                { _.isNil(downloadNotifications) &&
                    (<div className='spinner-item-block'>
                        <div className='spinner-item'/>
                    </div>) }
                { downloadNotifications > 0 && <span className='badge'>{downloadNotifications}</span> }
            </LinkEx>
        </div>
    );
};

ReportDownloadsView.propTypes = {
    downloadNotifications: PropTypes.number
};

export default ReportDownloadsView;
