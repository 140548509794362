import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Icon from 'Components/Icon';
import { commonWidgetActions, commonWidgetPropTypes } from 'Components/widgets/utils';
import * as featuresSelectors from 'State/features';
import * as modalActions from 'State/modal/actions';
import shareholderColumnsNames from 'Constants/shareholders-columns-names';
import useSortCriteria from '../../hooks/useSortCriteria';
import useSortedShareholders from '../../hooks/useSortedShareholders';
import useAvailableShareholderTypes from '../../hooks/useAvailableShareholderTypes';
import useCurrentWatchlistId from '../../hooks/useCurrentWatchlistId';

const WatchlistActionPanel = ({
    widgetData,
    widgetSettings,
    userProductSources,
    userFeatures,
    showExportWatchlistModal,
    availableColumns,
    onPrint
}) => {
    const { availableDisplayTypes, availableShareholderTypesList } = useAvailableShareholderTypes({    userProductSources, userFeatures, widgetSettings });
    const watchlistId = useCurrentWatchlistId(widgetSettings);
    const sortCriteria = useSortCriteria(widgetSettings);
    const sortedValues = useSortedShareholders({ widgetData, sortCriteria, availableDisplayTypes });
    const widgetNoData = sortedValues.length === 0;
    const exportIconClass = cn('font-icon font-icon-download', { inactive: widgetNoData });
    const printIconClass = cn('font-icon font-icon-local-printshop', { inactive: widgetNoData });

    const onExport = () => {
        showExportWatchlistModal({
            titleKey: 'modals.exportReport.title',
            exportParams: {
                watchlistId,
                sortCriteria,
                columnsToDisplay: availableColumns
                    .map(column => column.columnName)
                    .filter(name => name !== shareholderColumnsNames.SHAREHOLDER_NAME),
                shareholderIds: sortedValues.map(value => value.shareholderId),
                availableDisplayTypes,
                availableShareholderTypesList
            }
        });
    };

    const handleExport = () => {
        if (!widgetNoData) {
            onExport();
        }
    };

    const handlePrint = () => {
        if (!widgetNoData) {
            onPrint();
        }
    };

    return (
        <div className='action-panel'>
            <div className='actions-container'>
                <div className='message-notes-container'>
                    <FormattedMessage id='watchlist.message.add_shareholder'>
                        {(msg) => <p className='watchlist-instruction message-note'>{msg}</p>}
                    </FormattedMessage>
                </div>
                <div className='actions-group'>
                    <div className='actions'>
                        <Icon className={printIconClass} onClick={handlePrint}/>
                        <Icon className={exportIconClass} onClick={handleExport}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

WatchlistActionPanel.propTypes = {
    ...commonWidgetPropTypes,
    ...commonWidgetActions
};

const mapStateToProps = state => ({
    userProductSources: featuresSelectors.getCurrentProductSourcesSelector(state),
    userFeatures: featuresSelectors.getCurrentFeaturesSelector(state)
});

const mapDispatchToProps = ({
    showExportWatchlistModal: modalActions.showExportWatchlistModal
});

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistActionPanel);
