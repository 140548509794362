import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BaseRoute, AvailableRoute, pathRoutesConfig } from 'Routes';
import ReportingLayout from 'Layouts/ReportingLayout';
import pageRoutes from 'Constants/page-routes';
import featureTypes from 'Constants/feature-types';
import * as pages from 'Pages';

class ReportingRoutes extends Component {
    static propTypes = {
        location: PropTypes.object
    };

    render() {
        const { reportingRoutes } = pathRoutesConfig;

        return (
            <Switch>
                <BaseRoute
                    exact
                    path={`${pageRoutes.reporting}`}
                    render={props => (
                        <AvailableRoute
                            pathConfig={reportingRoutes.pathConfig}
                            defaultPath={reportingRoutes.defaultPath.path}
                            {...props}/>
                    )}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingShareholderList}/:id?`}
                    requiredFeature={featureTypes.SHAREHOLDER_LIST}
                    component={pages.ReportingShareholderListPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingHolderInvestorTypeAnalysis}`}
                    requiredFeature={featureTypes.HOLDING_INVESTOR_TYPE_ANALYSIS}
                    component={pages.ReportingHolderTypeAnalysisPage}/>
                <BaseRoute
                    exact
                    path={`${pageRoutes.reportingShareRangeAnalysis}`}
                    render={props => <Redirect to={`${pageRoutes.reportingShareRangeAnalysisSearch}${props.location.search}`}/>}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingShareRangeAnalysisSearch}`}
                    requiredFeature={featureTypes.SHARE_RANGE_ANALYSIS_RESULTS}
                    component={pages.ReportingShareRangeAnalysisSearchPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingShareRangeAnalysisResults}`}
                    requiredFeature={featureTypes.SHARE_RANGE_ANALYSIS_RESULTS}
                    component={pages.ReportingShareRangeAnalysisResultsPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingGeographicAnalysis}`}
                    requiredFeature={featureTypes.GEOGRAPHIC_ANALYSIS}
                    component={pages.ReportingGeographicAnalysisPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingShareDetail}/:id?`}
                    requiredFeature={featureTypes.SHARE_DETAIL}
                    component={pages.ReportingShareDetailPage}/>
                <BaseRoute
                    exact
                    path={`${pageRoutes.reportingControlBooks}`}
                    render={props => <Redirect to={`${pageRoutes.reportingControlBooksSearch}${props.location.search}`}/>}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingControlBooksSearch}`}
                    requiredFeature={featureTypes.OUTSTANDING_SHARES}
                    component={pages.ReportingControlBooksSearchPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingControlBooksResults}`}
                    requiredFeature={featureTypes.OUTSTANDING_SHARES}
                    component={pages.ReportingControlBooksResultsPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.reportingDownloads}`}
                    requiredFeature={featureTypes.DOWNLOAD}
                    component={pages.ReportingDownloadsPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={`${pageRoutes.dataViz}/:id?`}
                    requiredFeature={featureTypes.CUSTOM_REPORTS}
                    component={pages.ReportingDataVizReportPage}/>
                <BaseRoute
                    layout={ReportingLayout}
                    path={pageRoutes.reportingAuthorizedUserList}
                    requiredFeature={featureTypes.AUTHORIZED_USER_LIST}
                    component={pages.ReportingAuthorizedUserListPage}/>
                <Redirect path={`${pageRoutes.dataVizOld}/:id?`} to={`${pageRoutes.dataViz}/:id?`}/>
                <Redirect to={`${pageRoutes.error}/404`}/>
            </Switch>
        );
    }
}

export default ReportingRoutes;
