import React from 'react';
import PropTypes from 'prop-types';

import TopShareholdersTooltip from '../TopShareholdersTooltip';
import HeadingCellWithFiltration from './HeadingCellWithFiltration';

const HeadingCellTopShareholders = ({ title, tooltipTextId, tooltipClassName, ...filtrationProps }) => {
    return (
        <HeadingCellWithFiltration {...filtrationProps}>
            <TopShareholdersTooltip title={title} tooltipTextId={tooltipTextId} className={tooltipClassName}/>
        </HeadingCellWithFiltration>
    );
};

HeadingCellTopShareholders.propTypes = {
    title: PropTypes.string,
    sortAscendingIcon: PropTypes.object,
    sortDescendingIcon: PropTypes.object,
    sortProperty: PropTypes.object,
    tooltipTextId: PropTypes.string,
    tooltipClassName: PropTypes.string
};

export default HeadingCellTopShareholders;
