function isIe11AndBelow() {
    const ua = window.navigator.userAgent;

    return ua.indexOf('Trident/7.0') > 0
        || ua.indexOf('Trident/6.0') > 0
        || ua.indexOf('Trident/5.0') > 0;
}

export function isEdge() {
    const ua = window.navigator.userAgent;

    return ua.indexOf('Edge') > 0;
}

function isMozillaFireFox() {
    const ua = window.navigator.userAgent;

    return ua.indexOf('Firefox') > 0;
}

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
}

function isChrome() {
    const ua = window.navigator.userAgent;

    return ua.indexOf('Chrome') > 0;
}

export function identifyBrowser() {
    let browserName;

    if (isIe11AndBelow()) {
        browserName = 'ie';
    } else if (isEdge()) {
        browserName = 'edge';
    } else if (isMozillaFireFox()) {
        browserName = 'moz';
    } else if (isSafari()) {
        browserName = 'safari';
    } else if (isChrome()) {
        browserName = 'chrome';
    }

    return browserName;
}
