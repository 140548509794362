import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import XigniteLink from 'Components/XigniteLink';

import './AppFooter.scss';

class AppFooter extends Component {
    static propTypes = {
        showTermsAndConditionsModal: PropTypes.func.isRequired
    };

    handleTermsAndConditionsClick = () => {
        this.props.showTermsAndConditionsModal();
    };

    render() {
        const year = (new Date()).getFullYear();

        return (
            <footer className='app-footer'>
                <div>
                    <FormattedMessage id='appFooter.certificate' values={{ year }}/>
                    <FormattedMessage id='appFooter.separator'/>
                    <a
                        href={window.TERMS_URL}
                        className='footer-link'
                        data-terms-link='true'
                        target='_blank'>
                        <FormattedMessage id='appFooter.termsAndConditions'/>
                    </a>
                    {window.PRIVACY_POLICY_URL &&
                    <FormattedMessage id='appFooter.separator'/>
                    }
                    {window.PRIVACY_POLICY_URL &&
                    <a
                        href={window.PRIVACY_POLICY_URL}
                        data-privacy-policy-link='true'
                        className='footer-link'
                        target='_blank'>
                        <FormattedMessage id='appFooter.privacyPolicy'/>
                    </a>
                    }
                    <FormattedMessage id='appFooter.separator'/>
                    <span
                        className='footer-link'
                        data-subscription-link='true'
                        onClick={this.handleTermsAndConditionsClick}>
                        <FormattedMessage id='appFooter.subscriptionAgreement'/>
                    </span>
                </div>
                <div>
                    <XigniteLink/>
                    <FormattedMessage id='appFooter.separator'/>
                    <FormattedMessage id='appFooter.contractInformation'/>
                </div>
            </footer>
        );
    }
}

export default AppFooter;
