import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReportDownloadsView from '../components/ReportDownloadsView';
import * as userSelectors from 'State/user/selectors';

const mapStateToProps = state => ({
    downloadNotifications: userSelectors.getDownloadsNotificationSelector(state)
});

@withRouter
@connect(mapStateToProps)
class ReportDownloads extends Component {
    static propTypes = {
        downloadNotifications: PropTypes.number
    };

    render() {
        return <ReportDownloadsView downloadNotifications={this.props.downloadNotifications}/>;
    }
}

export default ReportDownloads;
