import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';

import pageRoutes from 'Constants/page-routes';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import RegisteredHolderTypeContent from './components/RegisteredHolderTypeContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import { changeHolderInvestorTypeAnalysisRouteState } from 'State/routeState';
import shareholderTypes from 'Constants/shareholder-types';
import FooterWithInfo from '../_commonComponents/FooterWithInfo';
import { getWidgetFooterPropsWithSignClass } from 'Components/widgets/utils';
import messageType from 'Constants/message-types';

import './RegisteredHolderType.scss';

const TOTAL_PERCENT = 100;
const mapDispatchToProps = { changeHolderInvestorTypeAnalysisRouteState };

@connect(null, mapDispatchToProps)
@injectIntl
class RegisteredHolderType extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        changeHolderInvestorTypeAnalysisRouteState: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: RegisteredHolderTypeContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetFooter: FooterWithInfo
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'registered.holder.type.title' });
    }

    getWidgetFooterProps = () => {
        const { widgetData, widgetSettings: { mode } } = this.props;

        return {
            leftSide: [
                {
                    messageKey: 'widgets.link.fullReport',
                    to: pageRoutes.reportingHolderInvestorTypeAnalysis,
                    customProps: {
                        onClick: this.onFullReportClick
                    },
                    hidden: this.props.isDataViz
                }
            ],
            rightSide: [
                {
                    messageKey: 'widgets.message.includesCeDeCo',
                    type: messageType.INCLUDES_CEDECO,
                    hidden: !this.showCeDeCoMessage(widgetData)
                },
                {
                    messageKey: 'widgets.totalOver100',
                    type: messageType.MORE_THAN_100_PERCENTS,
                    hidden: !(_.get(widgetData, `${mode}View.aggregate.osTotal`) > TOTAL_PERCENT)
                }
            ]
        };
    };

    onFullReportClick = () => {
        this.props.changeHolderInvestorTypeAnalysisRouteState({
            shareholderType: shareholderTypes.REGISTERED
        });
    };

    showCeDeCoMessage = () => {
        const widgetDataValues = _.get(this.props, 'widgetData.chartView.values');

        return _.some(widgetDataValues, 'includesCeDeCo');
    };

    render() {
        const widgetFooterProps = getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps(this.props.widgetData));
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            },
            widgetFooterProps,
            widgetNoData: !_.get(this.props, 'widgetData.chartView.values.length')
        };

        return (
            <Widget
                {...props}
                className='widget-registered-holder-type'
                components={this.widgetComponents}/>
        );
    }
}

export default RegisteredHolderType;
