import _ from 'lodash';
import { createSelector } from 'reselect';

import * as securitiesSelectors from '../securities/selectors';

export const getUserInfoSelector = state => {
    return _.get(state, 'user.info');
};

export const getUserNotificationsSelector = state => {
    return _.get(state, 'user.notifications');
};

export const getTermsAndConditionsSelector = (state, typeOfTerms) => _.get(state, `user.termsAndConditions.${typeOfTerms}`);

export const isUserLogoutSelector = state => {
    return _.get(state, 'user.isLogout');
};

export const isSecurityChangingSelector = state => {
    return _.get(state, 'user.isSecurityChanging');
};

export const hasAccessToPaSelector = state => {
    return _.get(state, 'user.accessToPa', false);
};

export const isSessionTimeoutSelector = state => {
    return _.get(state, 'user.isSessionTimeout');
};

export const getDownloadsNotificationSelector = createSelector(
    getUserNotificationsSelector,
    notifications => _.get(notifications, 'newDownloads', 0)
);

export const getDataVizReportsNotificationSelector = createSelector(
    getUserNotificationsSelector,
    notifications => _.get(notifications, 'newDataVizReports', 0)
);

export const getNewAlertNotificationsSelector = createSelector(
    getUserNotificationsSelector,
    notifications => _.get(notifications, 'newAlerts', 0)
);

/**
 * return default security id
 */
export const getDefaultSecurityIdSelector = createSelector(
    getUserInfoSelector,
    userInfo => _.get(userInfo, 'defaultSecurityId')
);

/**
 * return custom security id
 * @param state
 */
export const getCustomSecurityIdSelector = state => {
    return _.get(state, 'user.customSecurityId');
};

/**
 * return current security id
 */
export const getCurrentSecurityIdSelector = createSelector(
    getDefaultSecurityIdSelector,
    getCustomSecurityIdSelector,
    (defaultSecurityId, customSecurityId) => {
        return customSecurityId || defaultSecurityId;
    }
);

/**
 * return current security
 */
export const getCurrentSecuritySelector = createSelector(
    getCurrentSecurityIdSelector,
    securitiesSelectors.getAvailableSecurities,
    (securityId, securities) => {
        return _.find(securities, { id: securityId });
    }
);

export const hasNoSecuritySelector = createSelector(
    getCurrentSecuritySelector,
    getUserInfoSelector,
    (security, userInfo) => {
        return userInfo && !security;
    }
);

export const getIsAcceptedSelector = state => _.get(state, 'user.info.isAccepted');

export const getAcceptTerms = state => _.get(state, 'user.acceptTerms');

export const hasNewAnnouncementSelector = state => _.get(state, 'user.hasNewAnnouncement');

export const getAnnouncementMessageSelector = state => _.get(state, 'user.announcementMessage');

export const notAcceptedSelector = createSelector(
    getUserInfoSelector,
    getIsAcceptedSelector,
    (userInfo, isAccepted) => {
        return userInfo && !isAccepted;
    }
);

export const getUserDataSelector = createSelector(
    getUserInfoSelector,
    userInfo => ({
        userId: _.get(userInfo, 'userId'),
        firstName: _.get(userInfo, 'firstName') || '', // firstName can be null
        lastName: _.get(userInfo, 'lastName') || '', // lastName can be null
        title: _.get(userInfo, 'title'),
        email: _.get(userInfo, 'email'),
        systemRole: _.get(userInfo, 'systemRole')
    })
);

export const getGlobalSettings = createSelector(
    getUserInfoSelector,
    userInfo => _.get(userInfo, 'globalSettings', {})
);

export const getReportingDownloadKeepReport = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.reportsStorageTime
);

export const getMaxSelectedPeers = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.usersProfileMaxSelectedPeers
);

export const getPeerAnalysisMaxSelectedShareholders = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.peerAnalysisMaxSelectedShareholders
);

export const getHistoricalAnalysisMaxSelectedShareholders = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.historicalAnalysisMaxSelectedShareholders
);

// Should be no more than max number of rows on Excel worksheet - 1048576. See: https://goo.gl/e5imsp
export const getSheetMaxRows = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.sheetMaxRows
);

export const getMaxRegisteredOverviewRecords = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.maxRegisteredOverviewRecords
);

// incorrect work of createSelector, need to investigate
export const getRequestIntervals = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.requestIntervals
);

export const getUserTreasuryAccessSelector = state => _.get(state, 'user.hasTreasuryAccountAccess');

export const getPingRetryInterval = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.pingRetryInterval
);

export const getPingRetryCount = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.pingRetryCount
);

export const getSecureUploadRequestDescriptionMaxSize = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.secureUploadRequestDescriptionMaxSize
);

export const getSecureUploadRequestDescriptionMinSize = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.secureUploadRequestDescriptionMinSize
);

export const getSecureUploadRequestCommentMaxSize = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.secureUploadRequestCommentMaxSize
);

export const getSecureUploadMaxFileSize = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.secureUploadMaxFileSize
);

export const getMaxUploadFileSizeSizeSelector = createSelector(
    getGlobalSettings,
    globalSettings => globalSettings.clientUploadMaxFileSize
);
