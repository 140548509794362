import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppVersionView from '../component/AppVersionView';
import { getAppVersion } from 'State/appVersion/selectors';

const mapStateToProps = state => ({
    appVersion: getAppVersion(state)
});

@connect(mapStateToProps)
class AppVersion extends Component {
    render() {
        return (
            <AppVersionView {...this.props}/>
        );
    }
}

export default AppVersion;
