import React from 'react';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { compose } from '@shakacode/recompose';

import { getFirstWatchlistSelector, isProcessingWatchlistSelector } from 'State/watchlist';
import * as watchlistActions from 'State/watchlist/actions';
import * as notifierActions from 'State/notifier/actions';
import { CheckBox } from 'Components/CheckBox';
import { WATCHLIST_DEFAULT_TITLE } from 'Constants/watchlist';

const WatchlistShareholderRemoveButton = ({
    shareholderId,
    firstWatchlist,
    isProcessing,
    removeShareholderFromWatchlist,
    notifySuccess,
    notifyError,
    className,
    intl
}) => {
    const { title: firstWatchlistTitle, id: firstWatchlistId } = firstWatchlist;

    const handleClick = async () => {
        try {
            await removeShareholderFromWatchlist(shareholderId, firstWatchlistId);

            notifySuccess(
                null,
                <FormattedHTMLMessage id='watchlist.notifier.removeShareholder.success'/>
            );
        } catch (e) {
            notifyError(
                <FormattedMessage id='watchlist.notifier.removeShareholder.error.header' />,
                <FormattedHTMLMessage id='watchlist.notifier.removeShareholder.error'/>
            );
        }
    };

    return (
        <CheckBox
            checked
            className={className}
            label={<FormattedMessage id='watchlist.button.remove.title' values={{ title: firstWatchlistTitle || WATCHLIST_DEFAULT_TITLE }} />}
            title={intl.formatMessage({ id:'watchlist.button.remove.tooltip' })}
            disabled={isProcessing}
            onChange={handleClick} />
    );
};

WatchlistShareholderRemoveButton.propTypes = {
    shareholderId: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number]),
    firstWatchlist: PropsTypes.shape({
        id: PropsTypes.oneOfType([PropsTypes.string, PropsTypes.number])
    }),
    isProcessing: PropsTypes.bool,
    removeShareholderFromWatchlist: PropsTypes.func,
    notifySuccess: PropsTypes.func,
    notifyError: PropsTypes.func,
    className: PropsTypes.string,
    intl: intlShape
};

const mapStateToProps = state => ({
    firstWatchlist: getFirstWatchlistSelector(state),
    isProcessing: isProcessingWatchlistSelector(state)
});

const mapDispatchToProps = ({
    removeShareholderFromWatchlist: watchlistActions.removeShareholderFromWatchlist,
    notifySuccess: notifierActions.notifySuccess,
    notifyError: notifierActions.notifyError
});

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(WatchlistShareholderRemoveButton);
