import { createSelector } from 'reselect';
import _ from 'lodash';

const recentSearchState = state => state.recentSearch;

export const isLoadingRecentSearchSelector = createSelector(recentSearchState, ({ isLoading }) => isLoading);

export const isErrorRecentSearchSelector = createSelector(recentSearchState, ({ error }) => !!error);

export const recentSearchDataSelector = createSelector(recentSearchState, ({ data }) => data);

export const isEmptyRecentSearchSelector = createSelector(recentSearchState, ({ data }) => _.isEmpty(data));
