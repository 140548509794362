import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withSecurity } from 'Routes/hoc';
import { BaseRoute, ReportingRoutes, OwnershipRoutes } from 'Routes';
import pageRoutes from 'Constants/page-routes';
import featureTypes from 'Constants/feature-types';
import shareholderType from 'Constants/shareholder-types';
import * as pages from 'Pages';

@withSecurity
class SecurityProtectedRoutes extends Component {
    static propTypes = {
        location: PropTypes.object
    };

    render() {
        return (
            <Switch>
                <BaseRoute
                    path={`${pageRoutes.home}`}
                    component={pages.HomePage}
                    requiredFeature={featureTypes.HOME}/>
                <BaseRoute
                    path={`${pageRoutes[shareholderType.INSTITUTION]}/:shareholderId`}
                    component={pages.InstitutionalOverview}
                    requiredFeature={featureTypes.INSTITUTION_OVERVIEW}/>
                <BaseRoute
                    path={`${pageRoutes[shareholderType.INSIDER]}/:shareholderId`}
                    component={pages.InsiderOverview}
                    requiredFeature={featureTypes.INSIDER_OVERVIEW}/>
                <BaseRoute
                    path={`${pageRoutes[shareholderType.REGISTERED]}/:shareholderId`}
                    component={pages.RegisteredOverview}
                    requiredFeature={featureTypes.REGISTERED_OVERVIEW}/>
                <BaseRoute
                    path={`${pageRoutes.institutionProfile}/:shareholderId`}
                    component={pages.InstitutionProfile}/>
                <BaseRoute
                    path={`${pageRoutes.contact}/:shareholderId/:contactId`}
                    component={pages.ContactDetailsPage}/>
                <BaseRoute path={pageRoutes.downloads} component={pages.DownloadsPage} />
                {/* <BaseRoute path={`${pageRoutes.help}`} component={pages.HelpPage}/> */}
                <BaseRoute path={`${pageRoutes.fail}/:reason?`} component={pages.FailPage}/>
                <Switch>
                    <BaseRoute
                        path={`${pageRoutes.ownership}`}
                        component={OwnershipRoutes}/>
                    <BaseRoute
                        path={`${pageRoutes.reporting}`}
                        component={ReportingRoutes}/>
                    <Redirect to={`${pageRoutes.error}/404`}/>
                </Switch>
                <Redirect to={`${pageRoutes.error}/404`}/>
            </Switch>
        );
    }
}

export default SecurityProtectedRoutes;

