import { TA } from 'Constants/product-source-types';
import shareholderTypes from 'Constants/shareholder-types';
import shareholderColumnsNames from 'Constants/shareholders-columns-names';
import featureTypes from 'Constants/feature-types';
import shareholderTableColumnsDefinitions from 'Components/widgets/ShareholdersTable/constants/columnsDefinitions';

const SEPARATE_BY_NUMBER = 15;

export const addressOptions = [
    {
        value: shareholderColumnsNames.REGISTERED_ADDRESS,
        label: 'shareholders.table.address.registered'
    },
    {
        value: shareholderColumnsNames.SEASONAL_ADDRESS,
        label: 'shareholders.table.address.seasonal'
    },
    {
        value: shareholderColumnsNames.PROXY_ADDRESS,
        label: 'shareholders.table.address.proxy'
    },
    {
        value: shareholderColumnsNames.K1_ADDRESS,
        label: 'shareholders.table.address.k1'
    },
    {
        value: shareholderColumnsNames.DIVIDEND_ADDRESS,
        label: 'shareholders.table.address.dividend'
    }
];
const columns = {
    ALL: [
        {
            id: shareholderColumnsNames.POSITION,
            label: 'shareholder.list.columnsToDisplay.position'
        },
        {
            id: shareholderColumnsNames.OS,
            label: 'shareholders.table.columnsManager.os'
        },
        {
            id: shareholderColumnsNames.HOLDER_TYPE,
            label: 'shareholders.table.holderType'
        },
        {
            id: shareholderColumnsNames.STATE,
            label: 'shareholders.table.state.column.manager'
        },
        {
            id: shareholderColumnsNames.COUNTRY,
            label: 'shareholders.table.country'
        }
    ],
    INSTITUTION: [
        {
            id: shareholderColumnsNames.POSITION_DATE,
            label: 'shareholders.table.positionDate'
        },
        {
            id: shareholderColumnsNames.PREVIOUS_POSITION,
            label: 'shareholders.table.columnsManager.previous'
        },
        {
            id: shareholderColumnsNames.PREVIOUS_POSITION_DATE,
            label: 'shareholders.table.columnsManager.previousDate'
        },
        {
            id: shareholderColumnsNames.POSITION_CHANGE,
            label: 'shareholders.table.change'
        },
        {
            id: shareholderColumnsNames.MKT_VAL,
            label: 'shareholders.table.columnsManager.mktVal'
        },
        // {
        //     id: shareholderColumnsNames.PORTFOLIO_PERCENT,
        //     label: 'shareholders.table.ofPortfolio'
        // },
        {
            id: shareholderColumnsNames.F13,
            label: 'shareholders.table.13f'
        },
        {
            id: shareholderColumnsNames.PREVIOUS13F,
            label: 'shareholders.table.previous13f'
        },
        {
            id: shareholderColumnsNames.F13CHANGE,
            label: 'shareholders.table.13fChange'
        },
        {
            id: shareholderColumnsNames.YTD_CHANGE,
            label: 'shareholders.table.YTDChange'
        },
        {
            id: shareholderColumnsNames.COST_BASIS,
            label: 'shareholders.table.costBasis'
        },
        {
            id: shareholderColumnsNames.PURCHASING_POWER,
            label: 'shareholders.table.columnsManager.purchasingPower'
        },
        {
            id: shareholderColumnsNames.ASSETS_UNDER_MANAGEMENT,
            label: 'shareholders.table.assetsUnderManagement'
        },
        {
            id: shareholderColumnsNames.STYLE,
            label: 'shareholders.table.style'
        },
        {
            id: shareholderColumnsNames.ACTIVE_VS_PASSIVE,
            label: 'shareholders.table.activeVsPassive'
        },
        {
            id: shareholderColumnsNames.PROXY_ADVISORS,
            label: 'shareholders.table.columnsManager.proxyAdvisors'
        },
        {
            id: shareholderColumnsNames.TURNOVER,
            label: 'shareholders.table.turnover'
        },
        {
            id: shareholderColumnsNames.CITY,
            label: 'shareholders.table.city'
        },
        {
            id: shareholderColumnsNames.METRO_AREA,
            label: 'shareholders.table.metroArea'
        }
    ],
    REGISTERED: [
        {
            id: shareholderColumnsNames.ACCOUNT_NUMBER,
            label: 'shareholders.table.columnsManager.accountNumber'
        },
        {
            id: shareholderColumnsNames.CERTIFIED_SHARES,
            label: 'shareholders.table.columnsManager.certifiedShares'
        },
        {
            id: shareholderColumnsNames.BOOK_SHARES,
            label: 'shareholders.table.columnsManager.bookShares'
        },
        {
            id: shareholderColumnsNames.DRIP_SHARES,
            label: 'shareholders.table.columnsManager.dripShares'
        },
        {
            id: shareholderColumnsNames.TOTAL_VALUE,
            label: 'shareholders.table.totalValue'
        },
        {
            id: shareholderColumnsNames.STATUS,
            label: 'shareholders.table.status'
        },
        {
            id: shareholderColumnsNames.OPENED_DATE,
            label: 'shareholders.table.openedDate'
        },
        {
            id: shareholderColumnsNames.CLOSED_DATE,
            label: 'shareholders.table.closedDate'
        },
        {
            id: shareholderColumnsNames.DIRECT_DEPOSIT,
            label: 'shareholders.table.directDeposit'
        },
        {
            id: shareholderColumnsNames.SPECIAL_CODE,
            label: 'shareholders.table.specialCode'
        },
        {
            id: shareholderColumnsNames.SSN_TAX_ID,
            label: 'shareholders.table.ssnTaxId'
        },
        {
            id: shareholderColumnsNames.ADDRESS,
            options: addressOptions
        },
        {
            id: shareholderColumnsNames.BAD_ADDRESS,
            label: 'shareholder.list.column.badAddress'
        },
        {
            id: shareholderColumnsNames.EMAIL_ADDRESS,
            label: 'shareholder.list.column.emailAddress'
        },
        {
            id: shareholderColumnsNames.DATE_OF_LAST_CONTACT,
            label: 'shareholder.list.column.dateOfLastContact'
        }
    ]
};

export const columnsDefinitions = [
    ...shareholderTableColumnsDefinitions,
    {
        columnName: shareholderColumnsNames.BAD_ADDRESS,
        holderType: 'REGISTERED',
        order: 44
    },
    {
        columnName: shareholderColumnsNames.EMAIL_ADDRESS,
        holderType: 'REGISTERED',
        order: 45
    },
    {
        columnName: shareholderColumnsNames.DATE_OF_LAST_CONTACT,
        holderType: 'REGISTERED',
        order: 46
    }
];

export default {
    ALL: {
        values: columns.ALL,
        availableFor: {
            productSources: [TA],
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        label: 'shareholder.list.allHolders',
        shareholderType: shareholderTypes.ALL,
        separateOnColumnsBy: SEPARATE_BY_NUMBER
    },
    REGISTERED: {
        values: columns.REGISTERED,
        availableFor: {
            productSources: [TA]
        },
        label: 'shareholder.list.registeredHolders',
        shareholderType: shareholderTypes.REGISTERED,
        separateOnColumnsBy: SEPARATE_BY_NUMBER
    },
    INSTITUTION: {
        values: columns.INSTITUTION,
        availableFor: {
            features: [featureTypes.OI_PLUS_OPTIONS]
        },
        label: 'shareholder.list.institutionalHolders',
        shareholderType: shareholderTypes.INSTITUTION,
        separateOnColumnsBy: SEPARATE_BY_NUMBER
    }
};
