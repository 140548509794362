import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';

const FONT_ICON_REGEX = /font-icon-[-\w]+/;
// remove react-intl missing error message in console
const DEFAULT_TITLE = 'DEFAULT_TITLE';

const Icon = (props) => {
    const { titleKey, intl: { formatMessage }, className, withoutTitle, inactive, ...iconProps } = props;
    const tooltipKey = titleKey || `common.icon.tooltip.${FONT_ICON_REGEX.exec(className || '')}`;
    const message = formatMessage({ id: tooltipKey, defaultMessage: DEFAULT_TITLE });
    const title = (message === DEFAULT_TITLE || withoutTitle) ? null : message;
    const iconClassName = cn(className, { inactive });

    return (<i
        {...iconProps}
        className={iconClassName}
        title={title}/>);
};

Icon.propTypes = {
    withoutTitle: PropTypes.bool,
    titleKey: PropTypes.string,
    className: PropTypes.string,
    inactive: PropTypes.bool,
    intl: intlShape
};

Icon.defaultProps = {
    withoutTitle: false
};

export default injectIntl(Icon);
