/* eslint-disable react/no-multi-comp */
/* eslint-disable complexity */
import React from 'react';
import _ from 'lodash';

import columnNames from 'Constants/registered-table-column-names';
import * as cellComponents from '../TableCellComponents';
import { securityFullNameFormatter } from 'Components/formatters/formatters';
import dateFormats from 'Constants/date-formats';

export const getRegisteredOverviewCustomComponent = (columnType) => {
    switch (columnType) {
        case columnNames.DATE_OF_SALE:
        case columnNames.TRANSACTION_DATE:
        case columnNames.SURRENDER_DATE:
        case columnNames.DOCUMENT_DATE:
        case columnNames.STATUS_DATE:
        case columnNames.ISSUE_DATE:
        case columnNames.ACQUISITION_EFFECTIVE_DATE:
        case columnNames.ACQUISITION_DATE:
        case columnNames.BOOK_ACTIVITY_DATE: {
            return {
                customComponent: cellComponents.CellWithDate,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnNames.EXEMPT:
        case columnNames.PRIVATE:
        case columnNames.ROYAL_AMOUNT:
        case columnNames.OTHER_AMOUNT:
        case columnNames.FEDERAL_AMOUNT:
        case columnNames.TAX_WITHHELD:
        case columnNames.ORDINARY_INCOME:
        case columnNames.QUALIFIED:
        case columnNames.CAP_GAINS_INCOME:
        case columnNames.CAP_GAINS_1250:
        case columnNames.CAP_GAINS_1202:
        case columnNames.NON_TAX_INCOME:
        case columnNames.FEDERAL_TAX:
        case columnNames.FOREIGN_TAX:
        case columnNames.AMOUNT:
        case columnNames.GROSS_AMOUNT:
        case columnNames.CASH_LIQUID:
        case columnNames.NON_CASH_LIQUID:
        case columnNames.EARNED_INCOME:
        case columnNames.EXEMPT_INT_DIV:
        case columnNames.PRIV_INT_DIV:
        case columnNames.MKT_PRICE:
        case columnNames.VALUE: {
            return {
                customComponent: cellComponents.CellWithCurrency,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnNames.SHARES:
        case columnNames.TOTAL_SHARES:
        case columnNames.TOTAL:
        case columnNames.CERTIFICATED:
        case columnNames.BOOK:
        case columnNames.DRDSPP:
        case columnNames.SHARES_PRICE:
        case columnNames.AMOUNT_INVESTED:
        case columnNames.SERVICE_CHARGE:
        case columnNames.TAX_RATE:
        case columnNames.ORIGINAL_COST_BASIS:
        case columnNames.CURRENT_COST_BASIS:
        case columnNames.GAINS_LOSS_AMOUNT_USD:
        case columnNames.GROSS_PROCEEDS_USD:
        case columnNames.COST_BASIS_PER_SHARE_USD:
        case columnNames.BOOK_PRICE_USD: {
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnNames.DOWNLOAD: {
            return {
                customComponent: cellComponents.CellDownloadAction,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnNames.DELETE: {
            return {
                customComponent: cellComponents.CellDeleteAction,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }
        case columnNames.NO_DIVIDEND: {
            return {
                customComponent: cellComponents.NoDividendCell,
                customHeadingComponent: cellComponents.HeadingNoDividendCell
            };
        }
        case columnNames.SALE_CLOSE_DATE: {
            return {
                customComponent: cellComponents.CellWithDate,
                customHeadingComponent: cellComponents.HeadingCellCommon,
                customFooterComponent: (props) =>
                    _.get(props, 'footerProps.visible')
                        ? (<cellComponents.CellCommon value={_.get(props, 'footerProps.label')}/>)
                        : null
            };
        }
        case columnNames.CLOSED_SHARES:
        case columnNames.CURRENT_SHARES: {
            return {
                customComponent: cellComponents.CellWithNumber,
                customHeadingComponent: cellComponents.HeadingCellCommon,
                customFooterComponent: (props) => (
                    <cellComponents.CellWithNumber
                        value={_.get(props, 'footerProps.sharesTotal')}
                        additionalData={{
                            decimalPoints: 3
                        }}/>
                )
            };
        }
        case columnNames.TAX_LOT_ID: {
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon,
                customFooterComponent: (props) => (
                    <cellComponents.CellCommon value={_.get(props, 'footerProps.label')}/>
                )
            };
        }
        case columnNames.UPLOAD_STATUS: {
            return {
                customComponent: cellComponents.CellWithStatus,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
        }

        // Should implement in ASTTSTRT-32956
        // case columnNames.SHAREHOLDER_NAME: {
        //     return {
        //         customComponent: cellComponents.CellWithLink,
        //         customHeadingComponent: cellComponents.HeadingCellCommon
        //     };
        // }
        default:
            return {
                customComponent: cellComponents.CellCommon,
                customHeadingComponent: cellComponents.HeadingCellCommon
            };
    }
};

export const getRegisteredOverviewTdProps = (columnType /* ,row */) => {
    switch (columnType) {
        case columnNames.EXEMPT:
        case columnNames.PRIVATE:
        case columnNames.ROYAL_AMOUNT:
        case columnNames.OTHER_AMOUNT:
        case columnNames.FEDERAL_AMOUNT:
        case columnNames.TAX_WITHHELD:
        case columnNames.ORDINARY_INCOME:
        case columnNames.QUALIFIED:
        case columnNames.CAP_GAINS_INCOME:
        case columnNames.CAP_GAINS_1250:
        case columnNames.CAP_GAINS_1202:
        case columnNames.NON_TAX_INCOME:
        case columnNames.FEDERAL_TAX:
        case columnNames.FOREIGN_TAX:
        case columnNames.GROSS_AMOUNT:
        case columnNames.CASH_LIQUID:
        case columnNames.NON_CASH_LIQUID:
        case columnNames.EARNED_INCOME:
        case columnNames.EXEMPT_INT_DIV:
        case columnNames.PRIV_INT_DIV:
        case columnNames.SERVICE_CHARGE:
        case columnNames.AMOUNT_INVESTED:
        case columnNames.AMOUNT:
        case columnNames.VALUE:
        case columnNames.GROSS_PROCEEDS_USD: {
            return {
                additionalData: {
                    decimalPoints: 2
                }
            };
        }
        case columnNames.SHARES:
        case columnNames.TOTAL_SHARES:
        case columnNames.TOTAL:
        case columnNames.CERTIFICATED:
        case columnNames.DRDSPP:
        case columnNames.GAINS_LOSS_AMOUNT_USD:
        case columnNames.CLOSED_SHARES:
        case columnNames.CURRENT_SHARES:
        case columnNames.BOOK: {
            return {
                additionalData: {
                    decimalPoints: 3
                }
            };
        }
        case columnNames.SHARES_PRICE:
        case columnNames.MKT_PRICE: {
            return {
                additionalData: {
                    decimalPoints: 4
                }
            };
        }
        case columnNames.TAX_RATE: {
            return {
                additionalData: {
                    decimalPoints: 5
                }
            };
        }
        case columnNames.ORIGINAL_COST_BASIS:
        case columnNames.CURRENT_COST_BASIS:
        case columnNames.COST_BASIS_PER_SHARE_USD:
        case columnNames.BOOK_PRICE_USD: {
            return {
                additionalData: {
                    decimalPoints: 6
                }
            };
        }
        case columnNames.ACCOUNT_NUMBER:
        case columnNames.INCOME_CODE: {
            return {
                additionalData: {
                    alignRight: true
                }
            };
        }
        case columnNames.DEBIT_CREDIT:
        case columnNames.ADVICE_NO:
        case columnNames.SOURCE_CODE:
        case columnNames.SHORT_LONG:
        case columnNames.COVERED_INDICATOR:
        case columnNames.CERT_NUMBER:
        case columnNames.CERT_PREFIX: {
            return {
                additionalData: {
                    alignCenter: true
                }
            };
        }
        case columnNames.DOWNLOAD:
        case columnNames.DELETE: {
            return {
                className: 'icon-cell'
            };
        }
        case columnNames.DESCRIPTION: {
            return {
                className: 'td-description'
            };
        }
        case columnNames.ACQUISITION_DATE:
        case columnNames.ACQUISITION_EFFECTIVE_DATE: {
            return {
                className: 'align-center'
            };
        }
        case columnNames.DOCUMENT_DATE: {
            return {
                format: dateFormats.withTime
            };
        }
        // Should implement in ASTTSTRT-32956
        // case columnNames.SHAREHOLDER_NAME: {
        //     return {
        //         to: `${pageRoutes[shareholderType.REGISTERED]}/${row.shareholderId}`
        //     };
        // }
        default: {
            return {};
        }
    }
};

export const getCompareShareholdersTdProps = columnType => {
    switch (columnType) {
        case columnNames.YEAR:
        case columnNames.CHECK_NO: {
            return {
                additionalData: {
                    alignRight: true
                }
            };
        }

        default: {
            return {};
        }
    }
};

export const addSecurityColumn = (values) => values && values.map(value => ({
    ...value,
    [columnNames.SECURITY]: securityFullNameFormatter(value.securityName, value.ticker, value.cusip, value.issueId)
}));

export const hideIssuerColumn = (columnsDefinitions) => _.map(columnsDefinitions, (column) => {
    return column.columnName === columnNames.ISSUER ? {
        ...column,
        manageable: false,
        hidden: true
    } : column;
});
