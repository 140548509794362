import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import Widget from '../../Widget';
import CapGroupContent from './components/CapGroupContent';
import ShareholderWidgetControlPanel from '../../Widget/components/ShareholderWidgetControlPanel';
import FooterWithInfo from '../../_commonComponents/FooterWithInfo';

@injectIntl
class CapGroup extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetContent: CapGroupContent,
            WidgetFooter: FooterWithInfo
        };
    }

    render() {
        const values = _.get(this.props, 'widgetData.values', []).filter(item => !!item.os || !!item.change);
        const props = {
            ...this.props,
            widgetTitle: this.props.widgetTitle,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-chart',
                widgetTableTypeIcon: 'font-icon-chart deactivated'
            },
            widgetNoData: !values.length,
            values
        };

        return (
            <Widget
                {...props}
                className='widget-analysis-cap-group'
                components={this.widgetComponents}/>
        );
    }
}

export default CapGroup;
