import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import _ from 'lodash';

import { buildLocationWithSafeQueryParams } from 'Utils/routing';
import reportPaths from 'Constants/report-paths';
import reportTypes from 'Constants/report-types';
import pageRoutes from 'Constants/page-routes';
import { savedReportsEnhancer, shareReportTemplate } from 'Hoc';
import { getReportTemplates } from 'State/savedReportTemplates/actions';
import { getSavedReportsSelector, getFetchingStatusSelector } from 'State/savedReportTemplates/selectors';
import { getCurrentFeaturesSelector } from 'State/features';
import SavedReportTemplatesView from '../components/SavedReportTemplatesView';

const mapStateToProps = (state) => ({
    getReports: location => getSavedReportsSelector(state, location),
    isFetching: getFetchingStatusSelector(state),
    currentFeatures: getCurrentFeaturesSelector(state)
});
const mapDispatchToProps = {
    getReportTemplates
};

@connect(mapStateToProps, mapDispatchToProps)
@savedReportsEnhancer
@shareReportTemplate
class SavedReportTemplates extends Component {
    static propTypes = {
        getReportTemplates: PropTypes.func,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        onRunReport: PropTypes.func,
        history: PropTypes.object,
        location: PropTypes.object,
        match: PropTypes.object,
        currentFeatures: PropTypes.object,
        getReports: PropTypes.func,
        onShare: PropTypes.func,
        userCanShareReportTemplate: PropTypes.bool
    };

    componentDidMount() {
        this.props.getReportTemplates();
    }

    handleDelete = report => {
        this.props.onDelete(report, this.successDelete);
    };

    successDelete = report => {
        const { history, location } = this.props;
        const path = report.type === reportTypes.dataVizTemplate ? pageRoutes.reporting : reportPaths[report.type];
        const match = matchPath(location.pathname, {
            path: `${reportPaths[report.type]}/:editedReportId`,
            exact: true,
            strict: false
        });
        const editedReportId = _.get(match, 'params.editedReportId');

        if (+editedReportId === report.reportTemplateId) {
            history.replace(buildLocationWithSafeQueryParams(path, location));
        }
    };

    render() {
        const { getReports, location, userCanShareReportTemplate } = this.props;
        const reports = getReports(location);

        return (
            <SavedReportTemplatesView
                {...this.props}
                reports={reports}
                onDelete={this.handleDelete}
                userCanShareReportTemplate={userCanShareReportTemplate}/>
        );
    }
}

export default SavedReportTemplates;
