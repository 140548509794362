import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import dateFormats from 'Constants/date-formats';
import { DateFormatter } from 'Components/formatters';
import { getFullName } from 'Utils/utils';

import './NoteDetailsModal.scss';

const NoteDetailsModal = props => {
    const note = props.note || {};

    return (<div className='note-details-modal'>
        <div className='note-info'>
            <div className='info-section'>
                <div className='title'>
                    <FormattedMessage id='modals.noteDetails.author'/>
                </div>
                <div>{getFullName(note.authorFirstName, note.authorLastName)}</div>
            </div>
            <div className='info-section'>
                <div className='title'>
                    <FormattedMessage id='modals.noteDetails.date'/>
                </div>
                <DateFormatter value={note.date} format={dateFormats.withTime}/>
            </div>
        </div>
        <div className='note-text-section'>{note.noteText}</div>
        <div className='modal-actions'>
            <button
                type='button'
                onClick={() => props.hideModal()}
                className='btn btn-large cancel-btn'>
                <FormattedMessage id='common.close'/>
            </button>
        </div>
    </div>);
};

NoteDetailsModal.propTypes = {
    note: PropTypes.object,
    hideModal: PropTypes.func.isRequired
};

export default NoteDetailsModal;
