import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';

import { showShareholdersContactsModal } from 'State/modal';
import NullableFormatter from '../../../../formatters/NullableFormatter';

const mapDispatchToProps = {
    showContactsTableModal: showShareholdersContactsModal
};

@connect(null, mapDispatchToProps)
class CellWithContacts extends React.Component {
    static propTypes = {
        value: PropTypes.bool,
        className: PropTypes.string,
        showContactsTableModal: PropTypes.func,
        additionalData: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string
        })
    };

    handleShowModal = (e) => {
        e.preventDefault();

        this.props.showContactsTableModal(this.props.additionalData);
    }

    render() {
        const { className, value } = this.props;
        const cellClass = cn('cell-with-contacts', className);

        if (!value) {
            return <NullableFormatter />;
        }

        return (
            <a className={cellClass} onClick={this.handleShowModal} title='Contacts'>Contacts</a>
        );
    }
}

export { CellWithContacts };
