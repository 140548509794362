import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import './SaveChangesModalView.scss';

const SaveChangesModalView = ({ messageKey, payload, onSuccess, onCancel, okText, cancelText }) => (
    <div className='save-changes-modal'>
        <div className='modal-message'>
            <FormattedHTMLMessage id={messageKey} values={{ itemName: payload }}/>
        </div>
        <div className='btn-panel'>
            <button
                type='button'
                onClick={onSuccess}
                className='btn btn-large'>
                {okText}
            </button>
            <button
                type='button'
                onClick={onCancel}
                className='btn btn-large'>
                {cancelText}
            </button>
        </div>
    </div>
);

SaveChangesModalView.propTypes = {
    messageKey: PropTypes.string,
    payload: PropTypes.string,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string
};

export default SaveChangesModalView;
