import { compose, withState } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';

import BadAddresses from './BadAddresses';

export default compose(
    withState(
        'selectedDate',
        'onMonthChange'
    ),
    injectIntl
)(BadAddresses);
