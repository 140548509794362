import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import ExpandedError from './ExpandedError';
import logsLocalStorage from '../../../context/logsLocalStorage';

const BaseErrorPage = props => {
    const { errorCode, errorTittleKey, errorMessageKey, bottomErrorCode, actionButtonTextKey, handleAction, isLogOpen, toggleLog, hideDetails } = props;
    const showDetails = !!window.SHOW_DETAILS_ERROR && !hideDetails && !!logsLocalStorage.getLogItems().length;

    return (
        <div className='error-page-content'>
            {errorCode &&
            <div className='error-code'>{errorCode}</div>
            }
            {errorTittleKey &&
            <div className='error-message-title'>
                <FormattedHTMLMessage id={errorTittleKey}/>
            </div>
            }
            {errorMessageKey &&
            <div className='error-message-text'>
                <FormattedHTMLMessage id={errorMessageKey}/>
                {bottomErrorCode &&
                <div className='bottom-error-code'>
                    <FormattedHTMLMessage id={bottomErrorCode}/>
                </div>
                }
            </div>
            }
            {actionButtonTextKey &&
            <div className='error-action'>
                <button
                    type='button'
                    className='btn'
                    onClick={handleAction}>
                    <FormattedMessage id={actionButtonTextKey}/>
                </button>
            </div>
            }
            {isLogOpen && showDetails &&
                <a className='error-details-toggle' onClick={toggleLog}>
                    <FormattedMessage id='error.hideDetails'/>
                </a>
            }
            {!isLogOpen && showDetails &&
                <a className='error-details-toggle' onClick={toggleLog}>
                    <FormattedMessage id='error.showDetails'/>
                </a>
            }
            {isLogOpen && showDetails &&
                <ExpandedError/>
            }
        </div>
    );
};

BaseErrorPage.propTypes = {
    handleAction: PropTypes.func,
    errorCode: PropTypes.number,
    bottomErrorCode: PropTypes.string,
    errorTittleKey: PropTypes.string,
    errorMessageKey: PropTypes.string,
    actionButtonTextKey: PropTypes.string,
    isLogOpen: PropTypes.bool,
    toggleLog: PropTypes.func,
    hideDetails: PropTypes.bool
};

BaseErrorPage.defaultProps = {
    handleAction: PropTypes.func,
    errorTittleKey: 'error.title',
    errorMessageKey: 'error.serverError.message',
    actionButtonTextKey: 'error.button.refreshPage'
};

export default BaseErrorPage;
