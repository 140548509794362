import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';

import OptionList from './components/OptionList';
import reservedValues from './constants/reserved-values';
import { getSecuritiesByIdsSelector } from 'State/securities/selectors';

import './SecuritiesField.scss';

const mapStateToProps = state => ({
    getSecuritiesByIds: getSecuritiesByIdsSelector(state)
});

@connect(mapStateToProps)
@injectIntl
class SecuritiesField extends Component {
    static propTypes = {
        options: PropTypes.object,
        intl: intlShape,
        selectedValues: PropTypes.array,
        getSecuritiesByIds: PropTypes.func,
        onSecuritiesChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        selectedValues: []
    };

    constructor(props) {
        super();

        const { options, selectedValues, getSecuritiesByIds } = props;
        const selectedSecurities = getSecuritiesByIds(selectedValues);
        const { currentSecurity } = options;

        if (selectedSecurities.length > 0) {
            this.state = {
                selectedSecurities: selectedSecurities.map(s => ({
                    ...s,
                    value: s.id.toString()
                }))
            };
        } else {
            this.state = {
                selectedSecurities: [currentSecurity]
            };
        }
    }

    componentDidUpdate(prevProps) {
        const { selectedValues, getSecuritiesByIds } = this.props;
        const prevSelectedValues = prevProps.selectedValues;

        if (!_.isEqual(prevSelectedValues, selectedValues)) {
            const selectedSecurities = getSecuritiesByIds(selectedValues);

            this.setState({
                selectedSecurities: selectedSecurities.map(s => ({
                    ...s,
                    label: s.name,
                    value: s.id.toString()
                }))
            });
        }
    }

    handleOptionChange = v => {
        const { selectedSecurities } = this.state;

        if (v.value === reservedValues.ALL) {
            this.handleAllOptionChange();
        } else {
            const isSecuritySelected = _.find(selectedSecurities, s => s.value === v.value);
            const newSelectedSecurities = isSecuritySelected
                ? _.filter(selectedSecurities, s => s.value !== v.value)
                : [...selectedSecurities, v];

            this.setState({
                selectedSecurities: [...newSelectedSecurities]
            });
        }
    };

    handleAllOptionChange = () => {
        const { selectedSecurities } = this.state;
        const { options } = this.props;
        const maxCount = options.maxSelectedSecuritiesCount > options.availableSecurities.length
            ? options.availableSecurities.length : options.maxSelectedSecuritiesCount;
        const isAllChecked = selectedSecurities.length === maxCount;

        if (!isAllChecked) {
            const selectorOptions = this.getOptions();
            const selectedSecurityValues = selectedSecurities.map(s => s.value);
            const topUnselectedSecurities = _.filter(selectorOptions,
                o => !selectedSecurityValues.includes(o.value) && o.value !== reservedValues.ALL)
                .slice(0, options.maxSelectedSecuritiesCount - selectedSecurities.length);

            this.setState({
                selectedSecurities: [...selectedSecurities, ...topUnselectedSecurities]
            });
        } else {
            this.setState({
                selectedSecurities: [options.currentSecurity]
            });
        }
    };

    handleClose = () => {
        const { onSecuritiesChange } = this.props;
        const { selectedSecurities } = this.state;

        onSecuritiesChange(selectedSecurities);
    };

    getOptions = () => {
        const { options, intl: { formatMessage } } = this.props;
        const { selectedSecurities } = this.state;
        const selectAllLabel = options.maxSelectedSecuritiesCount >= options.availableSecurities.length
            ? formatMessage({ id: 'common.selectAll' })
            : formatMessage(
                { id: 'shareholder.list.securities.selectTop' },
                { count: options.maxSelectedSecuritiesCount });
        const maxCount = options.maxSelectedSecuritiesCount > options.availableSecurities.length
            ? options.availableSecurities.length : options.maxSelectedSecuritiesCount;

        return [
            {
                label: selectAllLabel,
                value: reservedValues.ALL,
                isSelected: selectedSecurities.length === maxCount,
                indeterminate: selectedSecurities.length < maxCount && selectedSecurities.length > 1
            },
            ...options.availableSecurities.map(security => ({
                ...security,
                label: security.name,
                value: security.id.toString(),
                isSelected: !!_.find(selectedSecurities, s => s.value === security.id.toString()),
                disabled: selectedSecurities.length === options.maxSelectedSecuritiesCount &&
                    !_.find(selectedSecurities, s => s.id === security.id),
                isDefault: security.id === options.currentSecurity.id
            }))
        ];
    };

    render() {
        const { options, intl: { formatMessage } } = this.props;
        const { selectedSecurities } = this.state;
        const selectValue = selectedSecurities.length > 1
            ? formatMessage({ id: 'shareholder.list.securities.label' }, { count: selectedSecurities.length })
            : options.currentSecurity.label;

        return (<div className='selector securities-selector'>
            <Select
                value={{ label: selectValue }}
                name='securities-selector'
                onChange={this.handleOptionChange}
                clearable={false}
                searchable={false}
                closeOnSelect={false}
                onClose={this.handleClose}
                options={this.getOptions()}
                menuRenderer={props => <OptionList {...props}/>}/>
        </div>);
    }
}

export default SecuritiesField;
