import TableEasy from './TableEasy';
import { mapBarValuesToData, mapBarValuesToPercent } from './utils';
import stickyFooter from './components/stickyFooter';
import totalLastCell from './components/totalLastCell';

export {
    mapBarValuesToData,
    mapBarValuesToPercent,
    stickyFooter,
    totalLastCell
};

export default TableEasy;
