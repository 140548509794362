import pageRoutes from 'Constants/page-routes';
import shareholderType from 'Constants/shareholder-types';

export default {
    [pageRoutes.ownershipShareholders]: 'pageTitle.ownershipShareholders',
    [pageRoutes.searchResult]: 'pageTitle.searchResult',
    [shareholderType.INSTITUTION]: 'pageTitle.institutionOverview',
    [pageRoutes.historicalAnalysis]: 'pageTitle.historicalAnalysis',
    [pageRoutes.peerAnalysis]: 'pageTitle.peerAnalysis',
    [pageRoutes.ownershipContactsLanding]: 'pageTitle.allContacts',
    [pageRoutes.ownershipContactsInstitutions]: 'pageTitle.allInstitutions'
};
