import PropTypes from 'prop-types';
import React from 'react';
import { compose, withState } from '@shakacode/recompose';

import LinkEx from 'Components/LinkEx';
import Logo from 'Components/Logo';
import ProfileMenu from 'Components/ProfileMenu';
import AppVersion from 'Components/AppVersion';

import './PageHeader.scss';

const PageHeader = ({ children, isOpen, setOpen, isProfileMenu = true }) => {
    return (
        <header className='page-header'>
            <div className='logo-container section'>
                <LinkEx to='/' className='ast-logo-link'>
                    <Logo/>
                </LinkEx>
            </div>
            <div className='content-section section-grow'>
                {children}
                <AppVersion/>
            </div>
            {isProfileMenu &&
            <div className='profile-section section'>
                <ProfileMenu
                    isOpen={isOpen}
                    setOpen={setOpen}/>
            </div>}
        </header>
    );
};

PageHeader.propTypes = {
    children: PropTypes.any,
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
    isProfileMenu: PropTypes.bool
};

export default compose(
    withState('isOpen', 'setOpen', false)
)(PageHeader);
