import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';

const withFormDateRangePicker = (Comp) => {
    @withProps(props => {
        const { dateRangeOptions: { startDateOptions, endDateOptions }, showYearDropdown, showMonthDropdown, disabled } = props;
        const startField = _.get(props, startDateOptions.name);
        const endField = _.get(props, endDateOptions.name);

        return {
            showYearDropdown,
            showMonthDropdown,
            startField,
            endField,
            disabled
        };
    })
    class WithFormDateRangePicker extends Component {
        static propTypes = {
            dateRangeOptions: PropTypes.shape({
                startDateOptions: PropTypes.shape({
                    label: PropTypes.string,
                    placeholder: PropTypes.string,
                    name: PropTypes.string
                }),
                endDateOptions: PropTypes.shape({
                    label: PropTypes.string,
                    placeholder: PropTypes.string,
                    name: PropTypes.string
                })
            }),
            className: PropTypes.string,
            startField: PropTypes.object,
            disabled: PropTypes.bool,
            endField: PropTypes.object,
            names: PropTypes.arrayOf(PropTypes.string),
            showYearDropdown: PropTypes.bool,
            showMonthDropdown: PropTypes.bool
        };

        render() {
            const { className, dateRangeOptions: { startDateOptions, endDateOptions }, showYearDropdown, showMonthDropdown, disabled } = this.props;
            const { startField, endField } = this.props;
            const startErrorMessage = !startField.meta.active && startField.meta.error;
            const endErrorMessage = !endField.meta.active && endField.meta.error;
            const startDateProps = {
                ...startDateOptions,
                ...startField.input,
                error: startField.meta.touched && startErrorMessage,
                disabled: disabled || startDateOptions.disabled
            };
            const endDateProps = {
                ...endDateOptions,
                ...endField.input,
                error: endField.meta.touched && endErrorMessage,
                disabled: disabled || endDateOptions.disabled
            };

            return (
                <Comp
                    className={className}
                    showYearDropdown={showYearDropdown}
                    showMonthDropdown={showMonthDropdown}
                    startDateProps={startDateProps}
                    endDateProps={endDateProps}/>
            );
        }
    }

    return WithFormDateRangePicker;
};

export default withFormDateRangePicker;
