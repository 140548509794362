import _ from 'lodash';

import { createReducer, isValidId, isExists } from 'Utils/reducer';
import { buildWidget, setWidgetsFlag, setSelectedRegisteredOverviewWidget } from './utils';

import {
    WIDGETS_ADD,
    WIDGETS_REMOVE,
    WIDGETS_SET_REMOVING,
    WIDGETS_SET_FETCHING,
    WIDGETS_SET_DATA_SOURCE_ID,
    WIDGETS_ERROR,
    WIDGETS_CLEAR_ERROR,
    WIDGETS_UPDATE_SETTINGS,
    UPDATE_REGISTERED_SELECTED_WIDGET
} from './actions';

const widgetsActionHandlers = {
    [WIDGETS_ADD]: (state, { payload }) => {
        if (_.isArray(payload)) {
            const _state = {};

            payload.forEach(item => {
                const id = item.widgetId;

                if (isValidId(id)) {
                    _state[id] = buildWidget(id, item);
                }
            });

            return { ...state, ..._state };
        }

        return state;
    },
    [WIDGETS_SET_FETCHING]: (state, { payload: { widgetId, fetching } }) => {
        if (isValidId(widgetId) && isExists(state, widgetId)) {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    isFetching: fetching
                }
            };
        }
        return state;
    },
    [WIDGETS_SET_DATA_SOURCE_ID]: (state, { payload: { widgetId, dataSourceId } }) => {
        if (isValidId(widgetId) && isExists(state, widgetId)) {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    dataSourceId
                }
            };
        }
        return state;
    },
    [WIDGETS_ERROR]: (state, { payload: { widgetId, error } }) => {
        if (isValidId(widgetId) && isExists(state, widgetId)) {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    error
                }
            };
        }
        return state;
    },
    [WIDGETS_CLEAR_ERROR]: (state, { payload: widgetId }) => {
        if (isValidId(widgetId) && isExists(state, widgetId)) {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    error: null
                }
            };
        }
        return state;
    },
    [WIDGETS_UPDATE_SETTINGS]: (state, { payload: { widgetId, settings } }) => {
        if (isValidId(widgetId) && isExists(state, widgetId)) {
            return {
                ...state,
                [widgetId]: {
                    ...state[widgetId],
                    settings
                }
            };
        }
        return state;
    },
    [WIDGETS_REMOVE]: (state, { payload: widgets }) => {
        let newState = state;

        if (_.isArray(widgets)) {
            newState = { ...state };
            widgets.forEach(item => {
                delete newState[item];
            });
        }

        return newState;
    },
    [WIDGETS_SET_REMOVING]: (state, { payload: { widgets, isRemoving } }) => {
        return setWidgetsFlag(state, widgets, 'isRemoving', isRemoving);
    },
    [UPDATE_REGISTERED_SELECTED_WIDGET]: (state, { payload: { widgetId } }) => {
        return setSelectedRegisteredOverviewWidget(state, widgetId);
    }
};

export default createReducer({}, widgetsActionHandlers);
