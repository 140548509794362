import PropTypes from 'prop-types';
import React from 'react';
import { Field, propTypes as reduxFormPropTypes } from 'redux-form';

import './SaveReportTemplateModal.scss';
import TextInput from 'Components/TextInput';

const SaveReportTemplateModalView = (props) => {
    const { onSuccess, onCancel, okText, cancelText, saveAndShareText } = props;
    const { intl: { formatMessage }, reportNameNormalize, handleSubmit, submitting, invalid } = props;
    const { canShare, onSaveAndShare } = props;

    return (
        <div className='save-new-report-modal'>
            <form onSubmit={handleSubmit(onSuccess)}>
                <Field
                    component={TextInput}
                    name='reportName'
                    placeholder={formatMessage({ id: 'modals.saveNewReport.placeholder' })}
                    label={formatMessage({ id: 'modals.saveNewReport.label' })}
                    labelType='vertical'
                    type='text'
                    tooltipMessage={formatMessage({ id: 'reporting.templateNameInputTooltip' })}
                    normalize={reportNameNormalize}/>
                <div className='text-message'>{formatMessage({ id: 'modals.saveNewReport.message' })}</div>
                <div className='btn-panel'>
                    <button
                        type='submit'
                        disabled={submitting || invalid}
                        className='btn btn-large'>
                        {okText}
                    </button>
                    {canShare && <button
                        type='button'
                        disabled={submitting || invalid}
                        className='btn btn-large'
                        onClick={handleSubmit(onSaveAndShare)}>
                        {saveAndShareText}
                    </button>}
                    <button
                        type='button'
                        className='btn btn-large'
                        onClick={onCancel}>
                        {cancelText}
                    </button>
                </div>
            </form>
        </div>
    );
};

SaveReportTemplateModalView.propTypes = {
    ...reduxFormPropTypes,
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    intl: PropTypes.object,
    reportNameNormalize: PropTypes.func,
    canShare: PropTypes.bool,
    saveAndShareText: PropTypes.string,
    onSaveAndShare: PropTypes.func
};

export default SaveReportTemplateModalView;
