import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Top25HoldingsTable from '../_commonComponents/Top25HoldingsTable';
import columnsDefinitions from './constants/columnsDefinitions';

@injectIntl
class InstitutionTop25Holdings extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    render() {
        const props = {
            ...this.props,
            widgetTitle: this.props.intl.formatMessage({ id: 'top25Holdings.table.insider.title' }),
            columns: columnsDefinitions
        };

        return (
            <Top25HoldingsTable
                {...props}/>
        );
    }
}

export default InstitutionTop25Holdings;
