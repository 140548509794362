import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import Icon from 'Components/Icon';
import { LazyPopover } from 'Components/Popover';
import uploadedDocumentStatuses from 'Constants/uploaded-document-statuses';

const CellWithStatus = (props) => {
    const { value, className, additionalData: { alignRight, alignCenter, withTitle = true, withTooltip = false } = {}, alreadySent = false, uploaded } = props;
    let normalizedValue;

    if (value === 'COMPLETE') {
        if (uploaded) {
            normalizedValue = 'UPLOADED';
        } else if (alreadySent) {
            normalizedValue = 'SENT';
        } else {
            normalizedValue = value;
        }
    } else {
        normalizedValue = value;
    }

    const cellClassName = cn(className, 'cell-with-status', { 'cell-with-align-right': alignRight, 'cell-with-align-center': alignCenter });
    const messageId = `registered.shareholder.clientUploads.status.${normalizedValue}`;

    const isFailed = normalizedValue === uploadedDocumentStatuses.FAILED;
    const isSecurityFailed = normalizedValue === uploadedDocumentStatuses.SECURITY_CHECK_FAILED;
    const showTooltip = withTooltip && (isFailed || isSecurityFailed);

    const popoverProps = {
        id: 'FILE-STATUS-POPOVER',
        trigger: <Icon className='font-icon font-icon-info-outline' />,
        triggerType: ['hover'],
        placement: 'top'
    };

    return (
        <div className={cellClassName}>
            <FormattedMessage id={messageId}>
                {message =>
                    <span title={withTitle ? message : null} className={className}>{message}</span>
                }
            </FormattedMessage>
            {showTooltip && (
                <LazyPopover {...popoverProps}>
                    <div className='cell-with-status__tooltip'>
                        {isFailed && <FormattedHTMLMessage id='tables.headingFileStatus.failed.tooltip' tagName='p' />}
                        {isSecurityFailed && <FormattedHTMLMessage id='tables.headingFileStatus.securityCheckFailed.tooltip' tagName='p' />}
                    </div>
                </LazyPopover>
            )}
        </div>
    );
};

CellWithStatus.propTypes = {
    value: PropTypes.any,
    additionalData: PropTypes.object,
    className: PropTypes.string,
    columnProps: PropTypes.object,
    alreadySent: PropTypes.bool,
    uploaded: PropTypes.bool
};
export default CellWithStatus;
