import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import ArrowScrollComponent from '../components/ArrowScrollComponent';

@injectIntl
class AdditionalGroupCell extends PureComponent {
    static propTypes = {
        onScrollGroupsLeft: PropTypes.func,
        additionalComponent: PropTypes.func,
        isMaxSelected: PropTypes.bool,
        maxSelected: PropTypes.number,
        scrollColumnWidth: PropTypes.number,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        this.formatMessage = props.intl.formatMessage;
        this.mustDeleteMessage = this.formatMessage({ id: 'peers.historical.analysis.table.mustDeleteMessage' });
    }

    render() {
        const { onScrollGroupsLeft,
            scrollColumnWidth,
            isMaxSelected,
            maxSelected } = this.props;
        const maxSelectedCountMessage = this.formatMessage(
            { id: 'peers.historical.analysis.table.maxSelectedCountMessage' },
            { maxSelected }
        );

        return (
            <div className='additional-group-cell'>
                <div className='additional-block'>
                    {isMaxSelected && (<div className='message-note'>
                        <div>
                            {maxSelectedCountMessage}
                        </div>
                        <div>
                            {this.mustDeleteMessage}
                        </div>
                    </div>)}
                </div>
                <div className='scroll-block'>
                    { !!onScrollGroupsLeft &&
                    (<ArrowScrollComponent
                        style={{ width: scrollColumnWidth }}
                        isLeft
                        onScroll={onScrollGroupsLeft} />) }
                </div>
            </div>
        );
    }
}

export default AdditionalGroupCell;
