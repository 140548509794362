import _ from 'lodash';

export const outstandingShares = state => _.get(state, 'reportingControlBooks.outstandingShares');
export const closeOfBusinessDate = state => _.get(state, 'reportingControlBooks.closeOfBusinessDate');
export const getFetchingOutstandingShares = state => _.get(state, 'reportingControlBooks.isFetching');
export const dateRange = state => _.get(state, 'form.reportingControlBooksForm.values.dateRange');
export const getSelectedSecurities = state => _.get(state, 'form.reportingControlBooksForm.values.securities');
export const includeDetailedDataSelector = state => _.get(state, 'form.reportingControlBooksForm.values.includeDetailedData');
export const includeWeightedDataSelector = state => _.get(state, 'form.reportingControlBooksForm.values.includeWeightedData');
export const dataTypeSelector = state => _.get(state, 'reportingControlBooks.dataType');
