import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';

import PieChart from 'Components/PieChart';
import { LegendGrid } from 'Components/Tables';
import stickyFooter from 'Components/Tables/TableEasy/components/stickyFooter';
import { getPieTooltipConfig } from 'Components/widgets/_commonConfigs/pieTooltipConfig';


const ShareholderAnalysisChartView = ({ widgetData: { chartView }, pieData, widgetFooterProps, columns, intl }) => {
    return (
        <div className='widget-with-pie-chart'>
            <div className='legend-container'>
                <LegendGrid
                    widgetFooterProps={widgetFooterProps}
                    columns={columns}
                    data={chartView.values}
                    aggregate={chartView.aggregate}
                    footerComponent={stickyFooter}/>
            </div>
            <div className='pie-container'>
                <PieChart
                    className='pie-content'
                    data={pieData}
                    customConfig={getPieTooltipConfig(intl)}
                    total={chartView.aggregate.osTotal}/>
            </div>
        </div>
    );
};

ShareholderAnalysisChartView.propTypes = {
    widgetData: PropTypes.object,
    pieData: PropTypes.array,
    columns: PropTypes.array,
    intl: intlShape,
    widgetFooterProps: PropTypes.object
};

export default ShareholderAnalysisChartView;
