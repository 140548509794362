import SHARE_TYPES from 'Constants/share-types';
import SHARE_STATUSES from 'Constants/share-statuses';
import reportTypes from 'Constants/report-types';

export default {
    type: reportTypes.shareDetailTemplate,
    reportTemplateId: null,
    reportName: null,
    form: {
        shareType: SHARE_TYPES.ALL,
        shareStatus: SHARE_STATUSES.ALL,
        dateRange: {
            startDate: null,
            endDate: null
        }
    }
};
