import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';

import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import columnNames from 'Constants/registered-table-column-names';
import { compareShareholders } from '../_commonComponents/RegisteredShareholderTable/hoc';
import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import { getManageableColumns } from 'Components/widgets/utils';
import { addSecurityColumn } from '../_commonComponents/RegisteredShareholderTable/utils';

const COLUMNS_MANAGER_ROWS = 6;
const MAIN_COLUMNS_NAMES = [
    columnNames.SHAREHOLDER_NAME,
    columnNames.ISSUER,
    columnNames.SECURITY,
    columnNames.ACCOUNT_NUMBER,
    columnNames.NO_DIVIDEND
];
const EXCEPTED_PROPS_IN_ROW_INFO = [
    'shareholderId',
    'shareholderName',
    'issuer',
    'issueId',
    'security',
    'securityId',
    'securityName',
    'ticker',
    'cusip',
    'accountNumber',
    '_viewIndex'
];
const getIsVisibleNoDividendCell = row => {
    const omitedRow = _.omit(row, EXCEPTED_PROPS_IN_ROW_INFO);

    return !_.some(_.values(omitedRow), prop => !_.isNil(prop));
};

@injectIntl
@withProps(({ widgetData: values }) => ({
    data: addSecurityColumn(values),
    columnsDefinitions
}))
@compareShareholders
class CompareShareholdersDRDSPP extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        columnsDefinitions: PropTypes.array
    };

    getTdProps = (finalState, rowInfo, column) => {
        return column.id === columnNames.NO_DIVIDEND
            ? {
                className: getIsVisibleNoDividendCell(rowInfo.row) ? 'no-dividend-container' : '',
                style: { padding: 0 }
            }
            : {};
    };

    getTheadThProps = (sortField, sortDirection, finalState, missProp, column) => {
        let thProps = {};

        if (column.id === columnNames.NO_DIVIDEND) {
            if (this.getIsVisibleNoDividendHeader()) {
                thProps = {
                    className: 'no-dividend-container',
                    style: { padding: 0 }
                };
            } else {
                thProps = { style: { padding: 0 } };
            }
        }
        return thProps;
    };

    getMainColumnsWidth = () => {
        const selectedColumns = _.get(this.props, 'widgetSettings.selectedColumns', []);
        let result = 0;

        for (const column of MAIN_COLUMNS_NAMES) {
            if (!this.props.isAvailableIssuerColumn && column === columnNames.ISSUER) {
                continue;
            }
            if (_.includes(selectedColumns, column)) {
                result += _.find(this.props.columnsDefinitions, col => {
                    return col.columnName === column;
                }).width;
            }
        }

        return result;
    };

    getIsVisibleNoDividendHeader = () => {
        const selectedColumns = _.get(this.props, 'widgetSettings.selectedColumns', []);

        return !_.some(selectedColumns, column => !_.includes(MAIN_COLUMNS_NAMES, column));
    };

    render() {
        const columnCount = this.props.isAvailableIssuerColumn ? 2 : 3;
        const selectedColumns = _.get(this.props, 'widgetSettings.selectedColumns', []);
        const additionalColumnsNames = _.difference(selectedColumns, MAIN_COLUMNS_NAMES);
        const isHideNoAddedHeaderText = additionalColumnsNames.length === 0 && selectedColumns.length > columnCount;
        const processedColumnsDefinitions = _.cloneDeep(this.props.columnsDefinitions);

        _.find(processedColumnsDefinitions, col => col.columnName === columnNames.NO_DIVIDEND).displayName = isHideNoAddedHeaderText
            ? ''
            : 'registered.shareholder.table.no.dividend';
        const tableProps = {
            ...this.props,
            columnsDefinitions: processedColumnsDefinitions,
            getTdProps: this.getTdProps,
            getTheadThProps: this.getTheadThProps,
            mainColumnsWidth: this.getMainColumnsWidth(),
            manageableColumns: getManageableColumns(processedColumnsDefinitions),
            secondarySorting,
            columnManagerRows: COLUMNS_MANAGER_ROWS,
            disableValidation: true
        };

        return (
            <RegisteredShareholderTable {...tableProps}/>
        );
    }
}

export default CompareShareholdersDRDSPP;
