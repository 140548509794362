import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const SessionTimeoutModal = ({ onClose }) => {
    return (
        <div className='session-timeout-modal-content'>
            <p><FormattedMessage id='modals.sessionTimeout.message1'/></p>
            <p><FormattedMessage id='modals.sessionTimeout.message2'/></p>
            <div className='modal-actions'>
                <div className='btn-panel'>
                    <button
                        type='button'
                        className='btn btn-large'
                        onClick={onClose}>
                        <FormattedMessage id='common.ok'/>
                    </button>
                </div>
            </div>
        </div>
    );
};

SessionTimeoutModal.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default SessionTimeoutModal;
