import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import shareholderTypes from 'Constants/shareholder-types';
import { LazyPopover } from 'Components/Popover';
import formatters from 'Constants/formatter-options';
import { DateFormatter, NullableFormatter } from 'Components/formatters';

const CellWithPosition = (props) => {
    const { value, className, additionalData: { source, shareholderType, id, onPositionToggle, date, onlyValueTooltip } = {} } = props;
    const { intl: { formatNumber } } = props;

    const isRegistered = shareholderType === shareholderTypes.REGISTERED;
    const isInstitution = shareholderType === shareholderTypes.INSTITUTION;
    const isFund = shareholderType === shareholderTypes.FUND;

    const positionsHistoryAvailable = (isInstitution || isFund) && onPositionToggle;

    const cellClass = classnames('cell-with-position', className);
    const valueClass = classnames('position-value', { active: positionsHistoryAvailable });
    const formattedValue = formatNumber(value,
        {
            minimumFractionDigits: formatters.DEFAULT_DECIMAL_POINTS,
            maximumFractionDigits: formatters.DEFAULT_DECIMAL_POINTS
        });
    const trigger = positionsHistoryAvailable ? (
        <a
            href='#'
            className='position-link'
            onClick={(proxy) => {
                proxy.preventDefault();
                onPositionToggle(id);
            }}>
            {formattedValue}
        </a>
    ) : (
        <span>{formattedValue}</span>
    );
    const triggerWrapper = (
        <span
            className={valueClass}>
            {trigger}
        </span>);
    const popoverProps = {
        id: 'POSITION-POPOVER',
        trigger: triggerWrapper,
        triggerType: ['click', 'hover']
    };

    return (
        <div className={cellClass}>
            <NullableFormatter value={value}>
                {!isRegistered ?
                    <LazyPopover {...popoverProps} placement='top'>
                        <div>
                            <div>{formattedValue}</div>
                            {!onlyValueTooltip && (
                            <>
                                {date && <DateFormatter value={date}/>}
                                <div>{source}</div>
                            </>
                            )}
                        </div>
                    </LazyPopover>
                    : triggerWrapper
                }
            </NullableFormatter>
        </div>
    );
};

CellWithPosition.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    additionalData: PropTypes.object,
    className: PropTypes.string,
    onPositionToggle: PropTypes.func,
    id: PropTypes.string,
    intl: intlShape.isRequired
};

export default injectIntl(CellWithPosition);
