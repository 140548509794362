import shareTypes from 'Constants/share-types';

export default [
    {
        value: shareTypes.ALL,
        label: 'shareholder.list.shareType.all'
    }, {
        value: shareTypes.CERTIFICATED,
        label: 'shareholder.list.shareType.certificated'
    }, {
        value: shareTypes.BOOK,
        label: 'shareholder.list.shareType.book'
    }, {
        value: shareTypes.DIVIDEND_REINVESTMENT,
        label: 'shareholder.list.shareType.dividendReinvestment'
    }
];
