import React from 'react';
import { FormattedMessage } from 'react-intl';

import tableData from '../constants/tableData';

const AnalysisTurnoverLegendModalTable = () => {
    const table = tableData.map(row => (
        <div className='row' key={row.key}>
            <div className='label'><FormattedMessage id={row.key}/></div>
            <span>{row.value}</span>
        </div>
    ));

    return (
        <div className='table'>
            {table}
        </div>
    );
};

export default AnalysisTurnoverLegendModalTable;
