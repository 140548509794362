import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { intlShape } from 'react-intl';

import { AssetChangeFormatter, DateFormatter } from 'Components/formatters';
import ShareholderInformationField from '../../../_commonComponents/ShareholderInformation/components/ShareholderInformationField';
import { abbreviationKeys } from 'Constants/number-abbreviations';

const BottomField = ({ widgetData, intl: { formatMessage } }) => {
    const reportedAUMValue = get(widgetData, 'reportedAUMValue');
    const reportedAUMDate = get(widgetData, 'reportedAUMDate');

    return (
        <ShareholderInformationField name={formatMessage({ id: 'shareholder.information.reportedAUM' })}>
            <AssetChangeFormatter
                value={reportedAUMValue}
                valueFormat={abbreviationKeys.thousands}
                withSymbol
                separately/>{' '}
            (
            <DateFormatter value={reportedAUMDate}/>
            )
        </ShareholderInformationField>
    );
};

BottomField.propTypes = {
    widgetData: PropTypes.object,
    intl: intlShape
};

export default BottomField;
