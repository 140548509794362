import moment from 'moment';
import { get } from 'lodash';

export function generateTableValues(data, dateFormat, getAggregationName) {
    let flatGroupValues = [];

    data.forEach((trend) => {
        const aggregate = {
            name: getAggregationName(trend),
            values: transformPeriods(get(trend, 'aggregate.values'), dateFormat)
        };

        flatGroupValues.push({
            ...aggregate,
            isAggregate: true
        });
        flatGroupValues = flatGroupValues.concat(trend.values.map(value => ({
            name: value.name,
            values: transformPeriods(value.values, dateFormat)
        })));
    });

    return flatGroupValues;
}

export function transformPeriods(values, format) {
    if (!Array.isArray(values)) return [];

    return values.map(({ period, value }) => ({
        value,
        period: moment(period).format(format)
    }));
}
