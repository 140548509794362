const postMessageTypeMap = {
    DOWNLOAD_FILE_ERROR: 'DOWNLOAD_FILE_ERROR',
    FILE_NOT_FOUND: 'FILE_NOT_FOUND',
    EXTERNAL_LINK: 'EXTERNAL_LINK'
};

const postMessageTypes = [
    postMessageTypeMap.DOWNLOAD_FILE_ERROR,
    postMessageTypeMap.FILE_NOT_FOUND,
    postMessageTypeMap.EXTERNAL_LINK
];

export {
    postMessageTypeMap,
    postMessageTypes
};
