import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import Widget from '../../Widget';
import ShareholderWidgetControlPanel from 'Components/widgets/Widget/components/ShareholderWidgetControlPanel';
import { colorizeValues } from 'Utils/colorize';
import ShareholderAnalysisContentFactory from './components/ShareholderAnalysisContentFactory';
import FooterWithInfo from '../FooterWithInfo';
import { getWidgetFooterPropsWithSignClass } from 'Components/widgets/utils';
import messageType from 'Constants/message-types';

const TOTAL_PERCENT = 100;

const chartViewSelector = widgetData => _.get(widgetData, 'chartView', {});

const createDataSelector = () => {
    return createSelector(
        widgetData => widgetData,
        chartViewSelector,
        (widgetData, widgetSettings) => _.get(widgetSettings, 'colorScheme'),
        (widgetData, chartView, colorSchemeId) => {
            return {
                ...widgetData,
                chartView: {
                    ...chartView,
                    values: simpleColorizeWidgetData(chartView.values, colorSchemeId)
                }
            };
        }
    );
};

const createNoDataSelector = () => {
    return createSelector(
        chartViewSelector,
        (chartView) => !_.some(chartView.values, rowData => rowData.os)
    );
};

const simpleColorizeWidgetData = (widgetData, colorSchemeId) => {
    if (widgetData && colorSchemeId) {
        return colorizeValues(widgetData, colorSchemeId);
    }
    return widgetData;
};

export default ({ primaryDataKey, l10nPrefix }) => {
    @injectIntl
    class ShareholderAnalysis extends Component {
        static propTypes = {
            ...commonWidgetPropTypes,
            ...commonWidgetActions,
            intl: intlShape
        };

        constructor(props) {
            super(props);

            this.widgetComponents = {
                WidgetContent: ShareholderAnalysisContentFactory({ primaryDataKey, l10nPrefix }),
                WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
                WidgetFooter: FooterWithInfo
            };

            this.widgetTitle = this.props.intl.formatMessage({ id: `shareholder.analysis.top10.${l10nPrefix}.title` });
            this.widgetControlPanelSettings = {
                widgetChartTypeIcon: 'font-icon-view-pie-chart',
                widgetTableTypeIcon: 'font-icon-view-list'
            };
            this.widgetEvents = {
                onBuildDataParams: () => ({
                    shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
                })
            };
        }

        getOsTotal = () => {
            const { widgetSettings: { mode } = {}, widgetData } = this.props;

            return _.get(widgetData, `${mode}View.aggregate.osTotal`);
        };

        getWidgetFooterProps = () => {
            const osTotal = this.getOsTotal();

            return {
                rightSide: [
                    {
                        messageKey: 'widgets.totalOver100',
                        type: messageType.MORE_THAN_100_PERCENTS,
                        hidden: !(osTotal > TOTAL_PERCENT)
                    }
                ]
            };
        };

        widgetDataSelector = createDataSelector();
        noDataSelector = createNoDataSelector();

        render() {
            const widgetNoData = this.noDataSelector(this.props.widgetData);
            const props = {
                ...this.props,
                widgetTitle: this.widgetTitle,
                widgetControlPanelSettings: this.widgetControlPanelSettings,
                widgetFooterProps: getWidgetFooterPropsWithSignClass(this.getWidgetFooterProps()),
                widgetData: widgetNoData ? null : this.widgetDataSelector(this.props.widgetData, this.props.widgetSettings),
                widgetNoData
            };

            return (
                <Widget
                    {...props}
                    events={this.widgetEvents}
                    components={this.widgetComponents}/>
            );
        }
    }

    return ShareholderAnalysis;
};
