import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import PeriodActionPanel from '../../_commonComponents/PeriodActionPanel';
import ButtonsGroup from 'Components/ButtonsGroup';
import { levelTypes } from '../constants';

const TaxCertificationStatusActionPanel = (props) => {
    const { intl: { formatMessage }, widgetData, widgetSettings, onUpdateWidgetSettings } = props;
    const buttons = [{
        value: levelTypes.SUMMARY,
        title: formatMessage({ id: 'tax.certification.status.summary' }),
        isSelected: widgetSettings.level === levelTypes.SUMMARY
    }, {
        value: levelTypes.DETAILS,
        title: formatMessage({ id: 'tax.certification.status.details' }),
        isSelected: widgetSettings.level === levelTypes.DETAILS
    }];
    const handleLevelChange = (value) => {
        const newWidgetSettings = {
            ...widgetSettings,
            level: value
        };

        onUpdateWidgetSettings(newWidgetSettings);
    };

    return (
        widgetData
            ? <div className='tax-certification-status-action-panel'>
                <PeriodActionPanel {...props}/>
                <ButtonsGroup
                    buttons={buttons}
                    onSelect={handleLevelChange}/>
            </div>
            : null
    );
};

TaxCertificationStatusActionPanel.propTypes = {
    onUpdateWidgetSettings: PropTypes.func,
    widgetSettings: PropTypes.object,
    widgetData: PropTypes.object,
    intl: intlShape
};

export default TaxCertificationStatusActionPanel;
