import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import { fileTypesString } from 'Constants/file-formats';
import Icon from 'Components/Icon';
import {
    getDropzoneInputPropsSelector,
    isUploadDocumentFetchingSelector
} from 'State/uploadDocument/selectors';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import { isWidgetFetchingSelector } from 'State/widgets/selectors';
import featureTypes from 'Constants/feature-types';

import './UploadIcon.scss';

const mapStateToProps = (state, props) => ({
    inputProps: getDropzoneInputPropsSelector(state),
    currentFeatures: getCurrentFeaturesSelector(state),
    isWidgetFetching: isWidgetFetchingSelector(state, props.documentsWidgetId),
    isUploadDocumentFetching: isUploadDocumentFetchingSelector(state)
});

@connect(mapStateToProps)
class UploadIcon extends Component {
    static propTypes = {
        message: PropTypes.string,
        inputProps: PropTypes.object,
        currentFeatures: PropTypes.object,
        documentsWidgetId: PropTypes.number,
        isWidgetFetching: PropTypes.bool,
        isUploadDocumentFetching: PropTypes.bool
    };

    handleChange = e => {
        const { inputProps } = this.props;

        inputProps.onChange([...e.target.files]);
    };

    handleClick = e => {
        const { isWidgetFetching, isUploadDocumentFetching } = this.props;

        if (isWidgetFetching || isUploadDocumentFetching) {
            e.preventDefault();
        }
    };

    render() {
        const { message, inputProps, currentFeatures, isWidgetFetching, isUploadDocumentFetching } = this.props;
        const hasPermission = currentFeatures[featureTypes.UPLOAD_DOCUMENT];
        const inactive = isWidgetFetching || isUploadDocumentFetching;

        return hasPermission
            ? (<div className='tab-with-upload-icon'>
                <span>{message}</span>
                <div className='upload-icon-container'>
                    <label className='file-input-label'>
                        <Icon
                            className={cn('font-icon font-icon-upload-file', { inactive })}
                            disabled={inactive}
                            onClick={this.handleClick}/>
                        <input
                            {...inputProps}
                            value={''}
                            onChange={this.handleChange}
                            accept={fileTypesString}/>
                    </label>
                </div>
            </div>) : <span>{message}</span>;
    }
}

export default UploadIcon;
