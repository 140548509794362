import PropTypes from 'prop-types';
import React from 'react';

import TableEasy, { totalLastCell } from '../TableEasy';
import coloredCellFactory from '../components/ColoredCell/coloredCellFactory';

const LEGEND_HEADER_HEIGHT = 24;

const LegendGrid = (props) => {
    const mappedProps = {
        ...props,
        columns: props.columns.map((col, i) => {
            // only for first column
            if (i !== 0) {
                return col;
            }
            return {
                ...col,
                bodyCellComponent: coloredCellFactory(col.bodyCellComponent, col.ellipsis)
            };
        })
    };
    const { footerComponent, renderAllRows } = props;

    return (
        <TableEasy
            className='widget-table'
            headerRowHeight={LEGEND_HEADER_HEIGHT}
            {...mappedProps}
            renderAllRows={renderAllRows}
            footerComponent={footerComponent || totalLastCell}
            transparentHeader/>
    );
};

LegendGrid.propTypes = {
    columns: PropTypes.array,
    renderAllRows: PropTypes.bool,
    footerComponent: PropTypes.func
};

export default LegendGrid;
