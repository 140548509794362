import PropTypes from 'prop-types';
import React, { Component, Children } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as featuresSelectors from 'State/features/selectors';

const mapStateToProps = (state, props) => ({
    currentFeatures: props.currentFeatures || featuresSelectors.getCurrentFeaturesSelector(state),
    currentProductSources: props.currentProductSources || featuresSelectors.getCurrentProductSourcesSelector(state)
});

const permissionShape = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]);

@connect(mapStateToProps)
class PermissionContent extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
        // if passed the replacement will be shown when content is permitted
        replacement: PropTypes.node,
        currentFeatures: PropTypes.object,
        currentProductSources: PropTypes.array,

        features: permissionShape,
        productSources: permissionShape
    };

    isPermitted() {
        if (this.props.features && this.props.productSources) {
            return this.isAllowByFeature() && this.isAllowByProducts();
        }

        return this.isAllowByFeature() || this.isAllowByProducts();
    }

    isAllowByFeature() {
        const { features, currentFeatures } = this.props;

        if (!features) return false;

        return _.every(features, feature => currentFeatures[feature]);
    }

    isAllowByProducts() {
        const { productSources, currentProductSources } = this.props;

        if (!productSources) return false;

        const productArray = [].concat(productSources);

        return _.intersection(productArray, currentProductSources).length;
    }

    render() {
        const { children, replacement } = this.props;

        if (!this.isPermitted()) {
            return replacement || null;
        }

        if (Children.count(children) > 1) {
            return <div>{children}</div>;
        }

        return (Children.only(children));
    }
}

export default PermissionContent;
