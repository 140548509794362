import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'Components/Icon';
import pageRoutes from 'Constants/page-routes';
import { pathWithSecurity } from 'Utils/routing';

export const ActionCell = ({ row: { requestId } }) => {
    const viewPagePath = pathWithSecurity(generatePath(pageRoutes.downloadsUserSecureUploadDetails, { requestId }));

    return (
        <Link to={viewPagePath} className='action-cell flexbox'>
            <Icon
                className='font-icon font-icon-view block'
                titleKey='userSecureUpload.table.viewRequest'/>
        </Link>
    );
};

ActionCell.propTypes = {
    row: PropTypes.shape({
        requestId: PropTypes.number.isRequired
    })
};
