import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';
import errorStrategies from 'Constants/error-stategies';
import httpStatus from 'Constants/http-status';

const widgetErrorStrategy = (error, responseStatus) => {
    if (responseStatus === httpStatus.HTTP_409) {
        return errorStrategies.SKIP;
    }
    return errorStrategies.DEFAULT;
};

export const addWidgetsToDataVizReport = (widgets) => {
    const payload = {
        widgets
    };
    const requestData = utils.buildRequest(payload, requestTypes.addWidgetsToDataVizReportPayload);

    return axios.post(apiRoutes.addWidgetsToDataVizReportUrl, requestData, {
        isCancelable: false
    });
};

const getWidgetDataRequest = (requestUrl, requestType) => (pageId, widgets, { redirectOn404 } = {}) => {
    const payload = {
        pageId,
        widgets
    };
    const requestData = utils.buildRequest(payload, requestType, { operationId: pageId });
    const options = {
        errorStrategy: (error, responseStatus, errOptions = {}) => {
            if (errOptions.isDataViz === true) {
                return errorStrategies.CRITICAL;
            } else if (responseStatus === httpStatus.HTTP_404 && redirectOn404) {
                return errorStrategies.CRITICAL;
            }
            return errorStrategies.DEFAULT;
        },
        isCancelable: true
    };

    return axios.post(requestUrl, requestData, options);
};

export const getWidgetData = getWidgetDataRequest(apiRoutes.getWidgetDataUrl, requestTypes.widgetDataPayload);
export const getDataVizWidgetData = getWidgetDataRequest(apiRoutes.getDataVizWidgetDataUrl, requestTypes.dataVizWidgetDataPayload);

export const addWidgets = (pageId, widgets) => {
    const payload = {
        page: {
            pageId,
            widgets,
            params: {} // backend fall without params
        }
    };
    const requestData = utils.buildRequest(payload, requestTypes.addWidgetsPayload, { operationId: pageId });
    const options = {
        isCancelable: false,
        errorStrategy: widgetErrorStrategy
    };

    return axios.post(apiRoutes.addWidgetsUrl, requestData, options);
};

export const addDataVizWidgets = (reportTemplateId, widgets) => {
    const payload = {
        reportTemplateId,
        widgets
    };
    const requestData = utils.buildRequest(payload, requestTypes.addDataVizWidgetsPayload);
    const options = {
        isCancelable: false
    };

    return axios.post(apiRoutes.addDataVizWidgetsUrl, requestData, options);
};

const removeWidgetsRequest = (requestUrl, requestType) => (widgets) => {
    const payload = {
        widgets
    };
    const requestData = utils.buildRequest(payload, requestType);
    const options = {
        isCancelable: false,
        errorStrategy: widgetErrorStrategy
    };

    return axios.post(requestUrl, requestData, options);
};

export const removeWidgets = removeWidgetsRequest(apiRoutes.removeWidgetsUrl, requestTypes.removeWidgetsPayload);
export const removeDataVizWidgets = removeWidgetsRequest(apiRoutes.removeDataVizWidgetsUrl, requestTypes.removeDataVizWidgetsPayload);

const updateWidgetsRequest = (requestUrl, requestType) => (pageId, widgets) => {
    const payload = {
        pageId,
        widgets
    };
    const requestData = utils.buildRequest(payload, requestType);
    const options = {
        isCancelable: false
    };

    return axios.post(requestUrl, requestData, options);
};

export const updateWidgets = updateWidgetsRequest(apiRoutes.updateWidgetsUrl, requestTypes.updateWidgetsPayload);
export const updateDataVizWidgets = updateWidgetsRequest(apiRoutes.updateDataVizWidgetsUrl, requestTypes.updateDataVizWidgetsPayload);
