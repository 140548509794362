import { createReducer } from '../../utils/reducer';
import {
    GET_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_ERROR,
    CHANGE_SUBSCRIPTION_STATUS,
    CHANGE_EMAIL_SUBSCRIPTION_STATUS,
    RESET_SUBSCRIPTION
} from './actions';

const initialState = {
    subscriptions: []
};

const actionHandlers = {
    [GET_SUBSCRIPTIONS]: (state, { payload }) => ({
        ...state,
        subscriptions: payload
    }),
    [GET_SUBSCRIPTIONS_ERROR]: (state, { payload }) => ({
        ...state,
        subscriptions: [],
        error: payload
    }),
    [CHANGE_SUBSCRIPTION_STATUS]: (state, { payload }) => ({
        ...state,
        subscriptions: state.subscriptions.map(subscription => subscription.type === payload.type
            ? {
                ...subscription,
                isActive: payload.isActive,
                isSubscribedEmail: !payload.isActive ? false : subscription.isSubscribedEmail
            } : subscription)
    }),
    [CHANGE_EMAIL_SUBSCRIPTION_STATUS]: (state, { payload }) => ({
        ...state,
        subscriptions: state.subscriptions.map(subscription => subscription.type === payload.type
            ? {
                ...subscription,
                isActive: payload.isActive ? true : subscription.isActive,
                isSubscribedEmail: payload.isActive
            } : subscription)
    }),
    [RESET_SUBSCRIPTION]: () => initialState
};

export default createReducer(initialState, actionHandlers);
