import _ from 'lodash';

import * as api from '../../api';
import { requestFilesSelector } from './selectors';

export const USER_SECURE_UPLOAD_GET_ALL_REPORTS_REQUEST = 'USER_SECURE_UPLOAD_GET_ALL_REPORTS_REQUEST';
export const USER_SECURE_UPLOAD_GET_ALL_REPORTS_SUCCESS = 'USER_SECURE_UPLOAD_GET_ALL_REPORTS_SUCCESS';
export const USER_SECURE_UPLOAD_GET_ALL_REPORTS_ERROR = 'USER_SECURE_UPLOAD_GET_ALL_REPORTS_ERROR';
export const USER_SECURE_UPLOAD_CREATE_DRAFT_REQUEST = 'USER_SECURE_UPLOAD_CREATE_DRAFT_REQUEST';
export const USER_SECURE_UPLOAD_CREATE_DRAFT_ERROR = 'USER_SECURE_UPLOAD_CREATE_DRAFT_ERROR';
export const USER_SECURE_UPLOAD_START_UPLOADING = 'USER_SECURE_UPLOAD_START_UPLOADING';
export const USER_SECURE_UPLOAD_FINISH_UPLOADING = 'USER_SECURE_UPLOAD_FINISH_UPLOADING';
export const USER_SECURE_UPLOAD_GET_REQUEST_FETCHING = 'USER_SECURE_UPLOAD_GET_REQUEST_FETCHING';
export const USER_SECURE_UPLOAD_GET_REQUEST_SUCCESS = 'USER_SECURE_UPLOAD_GET_REQUEST_SUCCESS';
export const USER_SECURE_UPLOAD_GET_REQUEST_ERROR = 'USER_SECURE_UPLOAD_GET_REQUEST_ERROR';
export const USER_SECURE_UPLOAD_UPDATE_UPLOAD_STATUS = 'USER_SECURE_UPLOAD_UPDATE_UPLOAD_STATUS';
export const USER_SECURE_UPLOAD_CLEAR_REQUEST_STATE = 'USER_SECURE_UPLOAD_CLEAR_REQUEST_STATE';
export const USER_SECURE_UPLOAD_DELETE_UPLOADED_DOCUMENT = 'USER_SECURE_UPLOAD_DELETE_UPLOADED_DOCUMENT';
export const USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_FETCHING = 'USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_FETCHING';
export const USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_SUCCESS = 'USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_SUCCESS';
export const USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_ERROR = 'USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_ERROR';
export const USER_SECURE_UPLOAD_UPDATE_EMAIL_RECIPIENTS = 'USER_SECURE_UPLOAD_UPDATE_EMAIL_RECIPIENTS';


export const getSecureUploadRequests = () => dispatch => {
    dispatch({ type: USER_SECURE_UPLOAD_GET_ALL_REPORTS_REQUEST });

    return api.getUploadRequestList()
        .then((res) => {
            const requestList = _.get(res, 'data.payload.uploadRequests', []);

            dispatch({
                type: USER_SECURE_UPLOAD_GET_ALL_REPORTS_SUCCESS,
                payload: requestList
            });
        })
        .catch((e) => {
            dispatch({
                type: USER_SECURE_UPLOAD_GET_ALL_REPORTS_ERROR,
                payload: e
            });
        });
};

export const createSecureUploadDraft = (requestTypeId) => dispatch => {
    dispatch({ type: USER_SECURE_UPLOAD_CREATE_DRAFT_REQUEST });

    return api.createUploadRequestDraft(requestTypeId)
        .then((res) => {
            return _.get(res, 'data.payload.uploadRequestId');
        })
        .catch((err) => {
            dispatch({ type: USER_SECURE_UPLOAD_CREATE_DRAFT_ERROR });

            return Promise.reject(err);
        });
};

export const fetchSecureUploadRequestById = (requestId) => (dispatch) => {
    dispatch({ type: USER_SECURE_UPLOAD_GET_REQUEST_FETCHING });

    return api.getUploadRequest(requestId)
        .then((res) => {
            const uploadRequest = _.get(res, 'data.payload.uploadRequest');

            dispatch({ type: USER_SECURE_UPLOAD_GET_REQUEST_SUCCESS, payload: uploadRequest });
        })
        .catch((e) => {
            dispatch({ type: USER_SECURE_UPLOAD_GET_REQUEST_ERROR });

            return Promise.reject(e);
        });
};

export const uploadDocument = (requestId, file) => dispatch => {
    dispatch({ type: USER_SECURE_UPLOAD_START_UPLOADING });

    return api.uploadDocumentRequest(requestId, file)
        .then(res => {
            const documentId  = _.get(res, 'data.payload.document.documentId');
            const documentUploadStatus  = _.get(res, 'data.payload.document.status');

            if (!documentId) {
                return Promise.reject();
            }

            dispatch({ type: USER_SECURE_UPLOAD_FINISH_UPLOADING });

            return documentUploadStatus;
        })
        .catch(err => {
            dispatch({ type: USER_SECURE_UPLOAD_FINISH_UPLOADING });

            return Promise.reject(err);
        });
};

export const deleteUploadedFile = (documentId) => dispatch => {
    dispatch({ type: USER_SECURE_UPLOAD_DELETE_UPLOADED_DOCUMENT, payload: documentId });

    return api.deleteUploadRequestDocument(documentId);
};

export const fetchUploadStatus = (requestId) => dispatch => {
    return api.getUploadRequestDocuments(requestId)
        .then((res) => {
            const documents = _.get(res, 'data.payload.documents', []);

            dispatch({ type: USER_SECURE_UPLOAD_UPDATE_UPLOAD_STATUS, payload: documents });
        });
};

export const clearRequestState = () => ({ type: USER_SECURE_UPLOAD_CLEAR_REQUEST_STATE });

export const updateUploadRequest = (requestId, { description, comment, emailRecipients }) => (__, getState) => {
    const documentIds = requestFilesSelector(getState()).map(({ id }) => id);

    const body = { requestId, comment, description,  documentIds, emailRecipients };

    return api.updateUploadRequest(body);
};

export const getSecureUploadEmailRecipients = (requestId) => (dispatch) => {
    dispatch({ type: USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_FETCHING });

    return api.getSecureUploadEmailRecipients(requestId)
        .then((res) => {
            const emailRecipients = _.get(res, 'data.payload.emailRecipients');

            dispatch({ type: USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_SUCCESS, payload: emailRecipients });
        })
        .catch((e) => {
            dispatch({ type: USER_SECURE_UPLOAD_GET_EMAIL_RECIPIENTS_ERROR });

            return Promise.reject(e);
        });
};

export const updateSecureUploadEmailRecipients = (emailRecipients) => (dispatch) => {
    dispatch({ type: USER_SECURE_UPLOAD_UPDATE_EMAIL_RECIPIENTS, payload: emailRecipients });
};
