import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DynamicPage } from '../../components/DynamicPage';
import RegisteredOverViewPageHeader from './containers/RegisteredOverviewPageHeader';
import PAGE_TYPES from '../../constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from './slots';
import recentSearchPageViewEvent from 'Hoc/recentSearchPageViewEvent';
import shareholderTypes from 'Constants/shareholder-types';

import './RegisteredOverview.scss';

const dynamicPageSettings = {
    redirect404ToHome: true,
    withoutNotification: true
};

class RegisteredOverview extends Component {
    static propTypes = {
        match: PropTypes.object
    };

    render() {
        const dynamicPageType = PAGE_TYPES.REGISTERED_OVERVIEW;

        return (
            <div className='registered-overview-page'>
                <RegisteredOverViewPageHeader/>
                <DynamicPage
                    layoutType={layoutTypes.layoutWithLeftAside}
                    slots={slots}
                    dynamicPageType={dynamicPageType}
                    settings={dynamicPageSettings}
                    params={this.props.match.params}/>
            </div>
        );
    }
}

export default recentSearchPageViewEvent({ shareholderType: shareholderTypes.REGISTERED })(RegisteredOverview);
