import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import { operationTypes } from 'Constants/peer';
import requestTypes from 'Constants/request-types';
import * as utils from './utils';

export const getPeers = () => {
    const payload = {
        params: {
            operation: operationTypes.GET_PEERS
        }
    };
    const requestData = utils.buildRequest(payload, requestTypes.peer);

    return axios.post(apiRoutes.peerUrl, requestData, {
        isCancelable: true
    });
};

export const resetPeers = () => {
    const payload = {
        params: {
            operation: operationTypes.RESET_PEERS
        }
    };
    const requestData = utils.buildRequest(payload, requestTypes.peer);

    return axios.post(apiRoutes.peerUrl, requestData, {
        isCancelable: false
    });
};

export const removePeer = (peerId) => {
    const payload = {
        params: {
            peerId,
            operation: operationTypes.DEL_PEER
        }
    };
    const requestData = utils.buildRequest(payload, requestTypes.peer);

    return axios.post(apiRoutes.peerUrl, requestData, {
        isCancelable: false
    });
};

export const addPeer = (peerId) => {
    const payload = {
        params: {
            peerId,
            operation: operationTypes.ADD_PEER
        }
    };
    const requestData = utils.buildRequest(payload, requestTypes.peer);

    return axios.post(apiRoutes.peerUrl, requestData, {
        isCancelable: false
    });
};

export const findPeerByName = (term, originalCancelToken) => {
    const payload = {
        params: {
            term,
            operation: operationTypes.FIND_PEERS
        }
    };
    const requestData = utils.buildRequest(payload, requestTypes.peer);

    return axios.post(apiRoutes.peerUrl, requestData, {
        originalCancelToken,
        isCancelable: true
    });
};
