import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import InstitutionProfilePageHeaderView from '../components/InstitutionProfilePageHeaderView';

const mapStateToProps = state => ({
    info: dynamicPageSelectors.getDynamicPageInfoSelector(state)
});

@connect(mapStateToProps)
class InstitutionProfilePageHeader extends Component {
    static propTypes = {
        info: PropTypes.object
    };

    render() {
        const { info } = this.props;

        return info ?
            <InstitutionProfilePageHeaderView pageInfo={info}/>
            : null;
    }
}

export default InstitutionProfilePageHeader;
