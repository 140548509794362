import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';
import _ from 'lodash';

const containerPadding = 75;
const HEADER_HEIGHT = 128;
let menuAmount = 0;

function getOffsetSum(elem) {
    let top = 0;
    let left = 0;
    let tempElement = elem;

    while (tempElement) {
        top = top + parseFloat(tempElement.offsetTop);
        left = left + parseFloat(tempElement.offsetLeft);
        tempElement = tempElement.offsetParent;
    }
    return { top: Math.round(top), left: Math.round(left) };
}

@injectIntl
class MenuList extends Component {
    static propTypes = {
        intl: intlShape,
        widgets: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                // is selected means that widget is selected in current or another slot
                isSelected: PropTypes.bool.isRequired
            })
        ).isRequired,
        onRemoveWidget: PropTypes.func,
        onChangeWidget: PropTypes.func,
        triggerElement: PropTypes.element,
        getPopover: PropTypes.func,
        slotProps: PropTypes.object.isRequired,
        translation: PropTypes.number
    };

    static defaultProps = {
        translation: 0
    };

    componentDidMount() {
        const { getPopover } = this.props;
        const triggerElem = ReactDOM.findDOMNode(getPopover().overlay);

        menuAmount++;

        if (triggerElem) {
            this.appElem = document.querySelector('.app');
            this.appElem.style.paddingBottom = `${containerPadding}px`;

            const triggerElBottom = getOffsetSum(triggerElem).top + triggerElem.offsetHeight;
            const widgetMenuHeight = document.getElementById('widget-menu').offsetHeight;
            const emptySpaceContainerHeight = _.get(document.getElementsByClassName('empty-space-container'), '[0].offsetHeight');
            const menuBottom = triggerElBottom + widgetMenuHeight + containerPadding;
            let emptySpace = document.documentElement.clientHeight - (HEADER_HEIGHT + emptySpaceContainerHeight + containerPadding);

            emptySpace = emptySpace > 0 ? emptySpace : 0;

            if (menuBottom > this.appElem.offsetHeight) {
                const calculatedPadding =
                    menuBottom - this.appElem.offsetHeight + containerPadding + emptySpace + this.props.translation;

                this.appElem.style.paddingBottom = `${ calculatedPadding }px`;
            }
        }
    }

    componentWillUnmount() {
        menuAmount--;

        if (menuAmount === 0) {
            this.appElem.style.paddingBottom = `${containerPadding}px`;
        }
    }

    render() {
        const { intl: { formatMessage }, onChangeWidget, onRemoveWidget, slotProps } = this.props;
        const widgets = this.props.widgets.map((widget) => ({
            label: formatMessage({ id: `widgets.type.${widget.name}` }),
            ...widget
        }));
        const sortedWidgets = widgets.sort((value, other) => {
            return value.label.toLowerCase().localeCompare(other.label.toLowerCase());
        });

        return (
            <ul className='widget-list'>
                {
                    sortedWidgets.map((widget) => (
                        <li
                            className={cn('widget-item', {
                                disabled: widget.isSelected && widget.name !== slotProps.widget
                            })}
                            key={widget.name}
                            data-widget-type={widget.name}
                            onClick={
                                widget.name === slotProps.widget
                                    ? onRemoveWidget
                                    : onChangeWidget(widget)}>
                            {
                                widget.isSelected && <i className='font-icon font-icon font-icon-checked-circle'/>
                            }
                            <span className='widget-name'>{widget.label}</span>
                        </li>
                    ))
                }
                {this.props.onRemoveWidget &&
                <li
                    className='widget-item'
                    data-remove-widget
                    onClick={onRemoveWidget}>
                    <span className='widget-name'>
                        {formatMessage({ id: 'widgets.removeTitle' })}
                    </span>
                </li>}
            </ul>
        );
    }
}

export default MenuList;
