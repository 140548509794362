import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.SALE_CLOSE_DATE,
        order: 1,
        minWidth: 110,
        displayName: 'registered.shareholder.table.saleCloseDate',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.ACQUISITION_DATE,
        order: 2,
        minWidth: 110,
        displayName: 'registered.shareholder.table.acquisitionDate',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.CLOSED_SHARES,
        order: 3,
        minWidth: 100,
        displayName: 'registered.shareholder.table.shares',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.COVERED_INDICATOR,
        order: 4,
        minWidth: 100,
        displayName: 'registered.shareholder.table.coveredIndicator',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.COST_BASIS_PER_SHARE_USD,
        order: 5,
        minWidth: 130,
        displayName: 'registered.shareholder.table.costBasisPerShareUSD',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 6
        }
    },
    {
        columnName: columnNames.GROSS_PROCEEDS_USD,
        order: 6,
        minWidth: 110,
        displayName: 'registered.shareholder.table.grossProceedsUSD',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2
        }
    },
    {
        columnName: columnNames.SHORT_LONG,
        order: 7,
        minWidth: 110,
        displayName: 'registered.shareholder.table.shortLong',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.GAINS_LOSS_AMOUNT_USD,
        order: 8,
        minWidth: 110,
        displayName: 'registered.shareholder.table.gainsLossAmountUSD',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    }
];

export const secondarySorting = {
    field: columnNames.ACQUISITION_DATE,
    direction: sortDirections.DESC
};
