import { createSelector } from 'reselect';
import _ from 'lodash';

import { TA } from 'Constants/product-source-types';
import * as featuresSelectors from 'State/features/selectors';

export const shareholderClassicIsAvailableSelector = createSelector(
    featuresSelectors.getCurrentProductSourcesSelector,
    (currentProductSources) => _.includes(currentProductSources, TA)
);
