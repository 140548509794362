import accountStatuses from 'Constants/account-statuses';

export default [
    {
        value: accountStatuses.OPEN_ONLY,
        label: 'shareholder.list.status.open'
    }, {
        value: accountStatuses.CLOSED_ONLY,
        label: 'shareholder.list.status.closed'
    }, {
        value: accountStatuses.OPEN_AND_CLOSED,
        label: 'shareholder.list.status.openAndClosed'
    }
];
