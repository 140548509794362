import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import ReactTable from 'react-table';
import { AutoSizer } from 'react-virtualized';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import {
    commonWidgetPropTypes,
    commonWidgetActions
} from 'Components/widgets/utils';
import { getRegisteredOverviewCustomComponent } from '../../utils';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import RegisteredPagination from '../RegisteredPagination';
import sortDirections from 'Constants/sort-directions';
import { getColumnsMinWidth } from 'Utils/utils';
import { withPrintModeState } from 'Hoc';

import '../RegisteredShareholderTableContent/RegisteredShareholderTableContent.scss';

@withPrintModeState
class RegisteredShareholderAccordionTableContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        columnsDefinitions: PropTypes.array.isRequired,
        data: PropTypes.array,
        getTheadThProps: PropTypes.func,
        getTdProps: PropTypes.func,
        getDefaultTdProps: PropTypes.func,
        isPrint: PropTypes.bool,
        noDataMessageId: PropTypes.string,
        onChangeSortCriteria: PropTypes.func,
        onSectionClick: PropTypes.func,
        footerProps: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.setColumnsMinWidth(props.data, props.columnsDefinitions);
    }

    handleOnSort = (sortProps) => {
        const sortBy = _.get(sortProps, '[0].id');

        this.props.onChangeSortCriteria(sortBy, this.props.columnsDefinitions);
    };

    setColumnsMinWidth = (data, columns) => {
        this.columnsWidth = {};

        _.forEach(columns, column => {
            this.columnsWidth[column.columnName] = data && data.length
                ? getColumnsMinWidth(data, column, column.minWidth || column.width, this.props.intl)
                : column.minWidth;
        });
    };

    getColumnsDefinition = (columns) => {
        const { intl: { formatMessage }, data, columnsDefinitions, footerProps, widgetNoData } = this.props;
        const isFooterVisible = !widgetNoData && _.get(this.props, 'footerProps.visible');

        this.setColumnsMinWidth(data, columnsDefinitions);

        return columns
            .map((column) => {
                const Components = getRegisteredOverviewCustomComponent(column.columnName);

                return {
                    Header: () => (
                        <Components.customHeadingComponent
                            title={column.displayName ? formatMessage({ id: column.displayName }) : ''}
                            style={column.style}/>
                    ),
                    Cell: (props) => (
                        <Components.customComponent
                            value={props.value}
                            style={column.style}/>
                    ),
                    Footer: Components.customFooterComponent && isFooterVisible ? () => (
                        <Components.customFooterComponent
                            footerProps={footerProps}/>
                    ) : null,
                    accessor: column.columnName,
                    minWidth: this.props.isPrint ? column.minWidth : this.columnsWidth[column.columnName],
                    width: column.width,
                    sortable: !column.disableSort
                };
            });
    };

    getTheadThProps = (sortField, sortDirection, finalState, missProp, column) => {
        const defaultProps = {
            sortProperty: column.id === sortField ? { sortAscending: sortDirection === sortDirections.ASC } : null
        };
        let additionalProps = {};

        if (this.props.getTheadThProps) {
            additionalProps = this.props.getTheadThProps(sortField, sortDirection, finalState, missProp, column) || {};
        }

        return {
            ...additionalProps,
            ...defaultProps
        };
    };

    getTdProps = (finalState, rowInfo, column) => {
        const defaultProps = this.props.getDefaultTdProps(column.id, rowInfo.row);
        const customProps = this.props.getTdProps && this.props.getTdProps(finalState, rowInfo, column) || {};

        return {
            ...defaultProps,
            ...customProps
        };
    };

    getNoDataProps = () => ({
        messageId: this.props.noDataMessageId || 'widgets.noDataMessage'
    });

    render() {
        const {
            data: sortedValues,
            columnsDefinitions,
            sectionsDefinitions,
            widgetSettings,
            expandedSection,
            onSectionClick
        } = this.props;
        const field = _.get(widgetSettings[expandedSection], 'sortCriteria.sortField');
        const direction = _.get(widgetSettings[expandedSection], 'sortCriteria.sortDirection');
        const columns = this.getColumnsDefinition(columnsDefinitions);

        return (
            <div className='widget-content'>
                {sectionsDefinitions.map(section => {
                    const isExpandedSection = expandedSection === section.id;
                    const titleIconType = isExpandedSection
                        ? 'arrow-drop-down'
                        : 'paging-right';

                    return (
                        <div key={section.id} className={cn({ 'expanded': isExpandedSection })}>
                            <div className='section-title-container' onClick={onSectionClick.bind(this, section.id)}>
                                <span className={`font-icon font-icon-${titleIconType}`}/>
                                <span className='section-title'>
                                    <FormattedMessage id={section.titleId}/>
                                </span>
                                {section.titleAdditionalInfoId &&
                                <span className='title-additional-info'>
                                    <FormattedMessage id={section.titleAdditionalInfoId}/>
                                </span>}
                            </div>
                            {isExpandedSection &&
                            <AutoSizer disableHeight>
                                {
                                    ({ width }) => (
                                        <div style={{ width }}>
                                            <div className='message-note'>
                                                <FormattedMessage id={section.descriptionId}/>
                                            </div>
                                            <RegisteredPagination {...this.props}/>
                                            <ReactTable
                                                showPagination={false}
                                                resizable={false}
                                                LoadingComponent={() => null}
                                                minRows={0}
                                                ThComponent={TheadComponent}
                                                TdComponent={TdComponent}
                                                getNoDataProps={this.getNoDataProps}
                                                NoDataComponent={NoResults}
                                                getTheadThProps={this.getTheadThProps.bind(this, field, direction)}
                                                getTdProps={this.getTdProps}
                                                manual
                                                defaultPageSize={0}
                                                resolvedData={sortedValues}
                                                onSortedChange={this.handleOnSort}
                                                columns={columns || []}/>
                                        </div>
                                    )
                                }
                            </AutoSizer>
                            }
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default RegisteredShareholderAccordionTableContent;
