import amountOfRecords from 'Constants/amount-of-records';

export default [
    {
        value: amountOfRecords.DISPLAYED_RECORDS,
        label: 'modals.exportReport.allDisplayedRecords'
    },
    {
        value: amountOfRecords.ALL_RECORDS_BY_DATE_RANGE,
        label: 'modals.exportReport.allRecordsByDateRange'
    },
    {
        value: amountOfRecords.ALL_ACCOUNTS,
        label: 'modals.exportReport.allAvailableRecords'
    }
];
