import layoutTypes from 'Constants/layout-types';

import LayoutEasy from './LayoutEasy';
import LayoutThreeColumns from './LayoutThreeColumns';
import LayoutWithAside from './LayoutWithAside';
import LayoutWithLeftAside from './LayoutWithLeftAside';
import HomePageLayout from './HomePageLayout';
import LayoutDataViz from './LayoutDataViz';
import LayoutWithTabsPanel from './LayoutWithTabsPanel';
import LayoutMultiSlots from './LayoutMultiSlots';
import ShareholdersOverviewLayout from './ShareholdersOverviewLayout';

export DynamicLayout from './DynamicLayout';

export const layouts = {
    [layoutTypes.layoutEasy]: LayoutEasy,
    [layoutTypes.layoutThreeColumns]: LayoutThreeColumns,
    [layoutTypes.layoutWithAside]: LayoutWithAside,
    [layoutTypes.layoutWithLeftAside]: LayoutWithLeftAside,
    [layoutTypes.homePageLayout]: HomePageLayout,
    [layoutTypes.layoutDataViz]: LayoutDataViz,
    [layoutTypes.layoutWithTabsPanel]: LayoutWithTabsPanel,
    [layoutTypes.layoutMultiSlots]: LayoutMultiSlots,
    [layoutTypes.shareholdersOverview]: ShareholdersOverviewLayout
};
