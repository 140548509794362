const weekDays = {
    MONDAY: '1',
    TUESDAY: '2',
    WEDNESDAY: '3',
    THURSDAY: '4',
    FRIDAY: '5',
    SATURDAY: '6',
    SUNDAY: '7'
};

const weekDayList = [
    {
        labelId: 'reporting.reportScheduler.monday.label',
        value: weekDays.MONDAY,
        position: 'block'
    },
    {
        labelId: 'reporting.reportScheduler.tuesday.label',
        value: weekDays.TUESDAY,
        position: 'block'
    },
    {
        labelId: 'reporting.reportScheduler.wednesday.label',
        value: weekDays.WEDNESDAY,
        position: 'block'
    },
    {
        labelId: 'reporting.reportScheduler.thursday.label',
        value: weekDays.THURSDAY,
        position: 'block'
    },
    {
        labelId: 'reporting.reportScheduler.friday.label',
        value: weekDays.FRIDAY,
        position: 'block'
    }
];

export {
    weekDays,
    weekDayList
};
