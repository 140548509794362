import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';

import dateFormat from 'Constants/date-formats';
import { isWorkDay } from 'Utils/dateHelpers';
import Icon from 'Components/Icon';

import './IconDatePicker.scss';

class IconDatePicker extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        showYearDropdown: PropTypes.bool,
        showMonthDropdown: PropTypes.bool,
        value: PropTypes.object,
        classNames: PropTypes.string,
        onDateChange: PropTypes.func.isRequired,
        checkWorkingDay: PropTypes.bool,
        isCurrentDateAvailable: PropTypes.bool
    };
    static defaultProps = {
        checkWorkingDay: false,
        isCurrentDateAvailable: true,
        showYearDropdown: true,
        showMonthDropdown: true
    };

    constructor(props) {
        super(props);
        const momentValue = moment(props.value, dateFormat.SERVER_DATE_FORMAT);

        this.state = {
            value: momentValue.isValid() ? momentValue.format(dateFormat.default) : ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.disabled !== this.props.disabled || !nextProps.value) {
            this.setState({ value: '' });
        } else if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value ? nextProps.value.format(dateFormat.default) : '' });
        }
    }

    handleSelect = momentValue => {
        this.setState({ value: momentValue ? momentValue.format(dateFormat.default) : '' });
        this.props.onDateChange(momentValue);
    };

    render() {
        const { checkWorkingDay, isCurrentDateAvailable, disabled, value, ...restProps } = this.props;
        const componentClass = cn('date-picker-component', 'icon-date-picker');
        const maxDate = isCurrentDateAvailable ? moment() : moment().add(-1, 'day');
        const filterDate = checkWorkingDay ? isWorkDay : null;

        return (
            <div className={componentClass}>
                <DatePicker
                    autoComplete='off'
                    disabledKeyboardNavigation
                    maxDate={maxDate}
                    filterDate={filterDate}
                    selected={value}
                    {...restProps}
                    customInput={<Icon/>}
                    className='font-icon font-icon-event'
                    onSelect={this.handleSelect}
                    value={this.state.value}
                    disabled={disabled}
                    dateFormat={dateFormat.default}
                    fixedHeight
                    scrollableYearDropdown
                    yearDropdownItemNumber={20}/>
            </div>
        );
    }
}

export default IconDatePicker;
