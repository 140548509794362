import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import CheckBox from 'Components/CheckBox/CheckBox';
import Icon from 'Components/Icon';
import Popup from 'Components/Popup';
import {
    isCombineAccountsDisabledSelector,
    isCompareSelectedDisabledSelector,
    countOfSelectedShareholderSelector,
    registeredResultLength,
    isAllSelectedShareholderToCompare,
    submitShareholdersToCompare,
    checkShareholdersSSN,
    toggleAllShareholdersToCompare,
    areShareholdersToCompareIndeterminate
} from 'State/advancedSearch';
import { showInfoModal } from 'State/modal';
import { MAX_SHAREHOLDERS_TO_COMPARE } from 'Constants/search';
import { getSecurityWithFeatureSelector } from 'State/securities/selectors';
import featureTypes from 'Constants/feature-types';

import './SearchActionPanel.scss';

const mapStateToProps = (state) => {
    const canCompare = !!getSecurityWithFeatureSelector(state, featureTypes.COMPARE_REGISTERED_SHAREHOLDERS);
    const canCombine = !!getSecurityWithFeatureSelector(state, featureTypes.COMBINE_ACCOUNTS);

    return {
        isSelectAll: isAllSelectedShareholderToCompare(state),
        registeredResultLength: registeredResultLength(state),
        isCombineAccountsDisabled: isCombineAccountsDisabledSelector(state),
        isCompareSelectedDisabled: isCompareSelectedDisabledSelector(state),
        countOfSelectedShareholder: countOfSelectedShareholderSelector(state),
        havePermission: canCompare || canCombine,
        canCompare,
        canCombine,
        indeterminate: areShareholdersToCompareIndeterminate(state)
    };
};

const mapDispatchToProps = {
    handleToggleAllShareholdersToCompare: toggleAllShareholdersToCompare,
    handleSubmitShareholdersToCompare: submitShareholdersToCompare,
    handleCheckShareholdersSSN: checkShareholdersSSN,
    showInfoModal
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class SearchActionPanel extends Component {
    static propTypes = {
        handleToggleAllShareholdersToCompare: PropTypes.func,
        handleSubmitShareholdersToCompare: PropTypes.func,
        handleCheckShareholdersSSN: PropTypes.func,
        onCollapseAll: PropTypes.func,
        collapseAllState: PropTypes.bool,
        isSelectAll: PropTypes.bool,
        registeredResultLength: PropTypes.number,
        isCombineAccountsDisabled: PropTypes.bool,
        isCompareSelectedDisabled: PropTypes.bool,
        countOfSelectedShareholder: PropTypes.number,
        history: PropTypes.object,
        location: PropTypes.object,
        havePermission: PropTypes.bool,
        canCompare: PropTypes.bool,
        canCombine: PropTypes.bool,
        indeterminate: PropTypes.bool,
        showInfoModal: PropTypes.func
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.countOfSelectedShareholder <= MAX_SHAREHOLDERS_TO_COMPARE &&
            nextProps.countOfSelectedShareholder > MAX_SHAREHOLDERS_TO_COMPARE) {
            this.props.showInfoModal({
                titleKey: 'search.modals.limitAccounts.title',
                messageKey: 'search.modals.limitAccounts.message',
                messageValues: { limit: MAX_SHAREHOLDERS_TO_COMPARE },
                okTextKey: 'search.modals.okText',
                modalClassName: 'limit-accounts-modal'
            });
        }
    }

    render() {
        const { havePermission, isCompareSelectedDisabled, isCombineAccountsDisabled, canCombine, canCompare } = this.props;

        return (
            <div className='search-action-panel'>
                {havePermission &&
                    this.props.registeredResultLength > 0 &&
                    <div className='compare-actions'>
                        <CheckBox
                            checked={this.props.isSelectAll}
                            indeterminate={this.props.indeterminate}
                            onChange={this.props.handleToggleAllShareholdersToCompare}/>
                        {canCombine &&
                            <Popup
                                content={<FormattedMessage id='search.tooltip.combine'/>}>
                                <Icon
                                    className={cn('font-icon font-icon-combine', { inactive: isCombineAccountsDisabled })}
                                    onClick={!isCombineAccountsDisabled ? this.props.handleCheckShareholdersSSN : null}/>
                            </Popup>}
                        {canCompare &&
                            <Popup
                                content={<FormattedMessage id='search.tooltip.compare'/>}>
                                <Icon
                                    className={cn('font-icon font-icon-compare', { inactive: isCompareSelectedDisabled })}
                                    onClick={!isCompareSelectedDisabled ? this.props.handleSubmitShareholdersToCompare : null}/>
                            </Popup>}
                        {this.props.registeredResultLength > 1 &&
                        <FormattedMessage id='search.compareLimit' values={{ limit: MAX_SHAREHOLDERS_TO_COMPARE }}>
                            {(msg) => <span className='text-muted'>({msg})</span>}
                        </FormattedMessage>}
                    </div>
                }
                <div className='expand-collapse-trigger' onClick={this.props.onCollapseAll}>
                    <FormattedMessage id={this.props.collapseAllState ? 'search.collapseSections' : 'search.expandSections'}/>
                </div>
            </div>
        );
    }
}

export default SearchActionPanel;
