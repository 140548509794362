import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IssuerResourcesPageView extends Component {
    static propTypes = {
        resources: PropTypes.array.isRequired,
        onDownloadResource: PropTypes.func.isRequired
    };

    render() {
        const { resources = [], onDownloadResource } = this.props;
        const mappedData = resources.map(({ groups = [] } = {}, i) => (
            <div key={i} className='resource-column'>
                {groups.map(({ documents = [], title } = {}, j) => (
                    <div className='resource-group' key={j}>
                        {title && <p className='resource-group-header'>{title}</p>}
                        {documents.map(document => (
                            <div key={document.id} className='resource-item'>
                                <span className='resource-item-label' onClick={() => onDownloadResource(document.id)}>
                                    {document.title}
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        ));

        return (<div className='issuer-resources-page-content'>
            {mappedData}
        </div>);
    }
}

export default IssuerResourcesPageView;
