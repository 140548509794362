import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import logsLocalStorage from '../../context/logsLocalStorage';

import './ErrorsDetails.scss';

@injectIntl
class ErrorsDetails extends PureComponent {
    static propTypes = {
        onlyLastError: PropTypes.bool,
        intl: intlShape
    };

    render() {
        const formatMessage = this.props.intl.formatMessage;
        const errorItems = logsLocalStorage.getLogItems();
        const errorsToShow = errorItems.length && this.props.onlyLastError ? [errorItems[0]] : errorItems;

        return (
            <div className='errors-details'>
                {errorsToShow.length ?
                    errorsToShow.map(item => (
                        <div
                            key={item.timestamp}
                            className='error-item'>
                            <div className='error-item-header'>
                                <div className='header-info'>
                                    <span className='header-info-title'>{formatMessage({ id: 'error.date' })}: </span>
                                    <span className='header-info-data'>{item.date}</span>
                                </div>
                                <div className='header-info'>
                                    <span
                                        className='header-info-title'>{formatMessage({ id: 'error.userLogin' })}: </span>
                                    <span
                                        className='header-info-data'>{`${item.userLogin || formatMessage({ id: 'error.noInfo' })}`}</span>
                                </div>
                                <div className='header-info'>
                                    <span className='header-info-title'>{formatMessage({ id: 'error.source' })}: </span>
                                    <span className='header-info-data'>{item.errorSource}</span>
                                </div>
                                <div className='header-info'>
                                    <span className='header-info-title'>{formatMessage({ id: 'error.url' })}: </span>
                                    <span className='header-info-data'>{item.url}</span>
                                </div>
                            </div>
                            <pre className='error-item-content' contentEditable='true' suppressContentEditableWarning>
                                {JSON.stringify(item, null, '\t')}
                            </pre>
                        </div>
                    ))
                    : <h3 className='no-errors'>{formatMessage({ id: 'error.noErrorsMessage' })}</h3>
                }
            </div>
        );
    }
}

export default ErrorsDetails;
