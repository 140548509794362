import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { injectIntl, intlShape } from 'react-intl';
import { pure } from '@shakacode/recompose';
import cn from 'classnames';
import _ from 'lodash';

import './Selector.scss';

@pure
class Selector extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string
            })
        ).isRequired,

        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]),
        onOptionChange: PropTypes.func.isRequired,
        onInputChange: PropTypes.func,
        wrapperClass: PropTypes.string,
        placeholder: PropTypes.string,
        noResults: PropTypes.any,
        openTop: PropTypes.bool,
        inputProps: PropTypes.object,
        intl: intlShape
    };

    handleChange = (e) => {
        this.props.onOptionChange(e);
    };

    handleInputChange = (e) => {
        if (this.selectContainer) {
            this.selectMenu = this.selectMenu || this.selectContainer.querySelector('.Select-menu');

            if (this.selectMenu) {
                // Reset menu scroll position on input change
                this.selectMenu.scrollTop = 0;
            }
        }
        if (_.isFunction(this.props.onInputChange)) {
            this.props.onInputChange(e);
        }
    };

    render() {
        const placeholder = this.props.placeholder || this.props.intl.formatMessage({ id: 'selector.placeholder', defaultMessage: 'PLACEHOLDER NOT FOUND' });
        const noResults = this.props.noResults || this.props.intl.formatMessage({ id: 'selector.noResults', defaultMessage: 'NORESULTS NOT FOUND' });
        const selectorClassName = cn('selector', this.props.wrapperClass, { 'open-top': this.props.openTop });
        const customInputProps = _.get(this.props, 'inputProps', {});
        const inputProps = { ...customInputProps, className: cn('selector-input-container', customInputProps.className) };

        return (
            <div
                className={selectorClassName}
                ref={ref => this.selectContainer = ref}>
                <Select
                    {...this.props}
                    inputProps={inputProps}
                    placeholder={placeholder}
                    noResultsText={noResults}
                    onInputChange={this.handleInputChange}
                    onChange={this.handleChange}/>
            </div>
        );
    }
}

export default injectIntl(Selector);
