import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';
import _ from 'lodash';

import LinkEx from 'Components/LinkEx';
import featureTypes from 'Constants/feature-types';
import pageRoutes from 'Constants/page-routes';
import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import NoDataComponent from '../../Widget/components/NoDataComponent';
import { normalizeUrlProtocol } from 'Utils/routing';

const InstitutionProfileContent = (props) => {
    const { widgetNoData, isFetching, widgetData, info, intl: { formatMessage }, currentFeatures, isDataViz } = props;
    const shareholderTitle = _.get(info, 'shareholderTitle');
    const shareholderId = _.get(info, 'shareholderId');
    const profileInfo = _.get(widgetData, 'profileInfo');

    return (
        <div className='widget-content'>
            <NoDataComponent
                message={formatMessage({ id: 'widgets.noDataMessage.simple' })}
                isFetching={isFetching}
                isNoData={widgetNoData}>
                {!widgetNoData && (<div>
                    <div className='segment'>
                        <h3 className='segment-title'>{shareholderTitle}</h3>
                        <div>{profileInfo.address}</div>
                        <div>
                            <span>{profileInfo.city} </span>
                            <span>{profileInfo.state} </span>
                            <span>{profileInfo.zip} </span>
                        </div>
                        <div>{profileInfo.country}</div>
                        <div className='row-with-indent'>{profileInfo.phone}</div>
                        {profileInfo.fax && <div>{profileInfo.fax} fax</div>}
                        <a
                            href={normalizeUrlProtocol(profileInfo.website)}
                            target='_blank'
                            rel='noopener noreferrer'>{profileInfo.website}</a>
                    </div>
                    {currentFeatures[featureTypes.CONTACTS] && !isDataViz &&
                    <LinkEx
                        className='view-contacts-link'
                        to={`${pageRoutes.ownershipContactsLanding}/${shareholderId}`}>
                        {formatMessage({ id: 'institution.profile.viewContacts' })}
                    </LinkEx>
                    }
                    {profileInfo.overview &&
                    <div className='segment'>
                        <h3 className='segment-title'>{formatMessage({ id: 'institution.profile.overview' })}</h3>
                        <div className='text-block'>
                            {profileInfo.overview.map((overviewItem, i) => {
                                return (
                                    <div className='text-block-item' key={i}>{overviewItem}</div>
                                );
                            })}
                        </div>
                    </div>
                    }
                    {profileInfo.investmentApproach &&
                    <div className='segment'>
                        <h3 className='segment-title'>{formatMessage({ id: 'institution.profile.investmentApproach' })}</h3>
                        <div className='text-block'>
                            {profileInfo.investmentApproach.map((investmentApproachItem, i) => {
                                return (
                                    <div className='text-block-item' key={i}>{investmentApproachItem}</div>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>)}
            </NoDataComponent>
        </div>
    );
};

InstitutionProfileContent.propTypes = {
    ...commonWidgetActions,
    ...commonWidgetPropTypes,
    widgetData: PropTypes.object,
    widgetNoData: PropTypes.bool,
    intl: intlShape,
    currentFeatures: PropTypes.object.isRequired
};

export default InstitutionProfileContent;

