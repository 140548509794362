import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { getFullName } from 'Utils/utils';
import { DateFormatter, NullableFormatter } from 'Components/formatters';
import dateFormats from 'Constants/date-formats';

const AlertsModalSection = props => {
    return (<div className='alerts-modal-content'>
        <div className='alert-upper-section'>
            <div className='label'>
                <FormattedMessage id='modals.alerts.label.author'/>
                {' '}
                <span>{getFullName(props.authorFirstName, props.authorLastName)}</span>
            </div>
            <div className='label'>
                <FormattedMessage id='modals.alerts.label.date'/>
                {' '}
                <DateFormatter value={props.date} format={dateFormats.withTime}/>
            </div>
        </div>
        <div className='alert-text-section' id='alertText'>
            {props.alertText}
        </div>
        <div className='alert-lower-section'>
            <div className='label'>
                <FormattedMessage id='modals.alerts.label.email'/>
                {' '}
                <NullableFormatter value={props.email}/>
            </div>
            <div className='label'>
                <FormattedMessage id='modals.alerts.label.phone'/>
                {' '}
                <NullableFormatter value={props.phone}/>
            </div>
        </div>
    </div>);
};

AlertsModalSection.propTypes = {
    authorFirstName: PropTypes.string.isRequired,
    authorLastName: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    alertText: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
};

export default memo(AlertsModalSection);
