import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import cn from 'classnames';
import { reduxForm, Field } from 'redux-form';

import TextInput from 'Components/TextInput';
import { SEARCH_FORM_NAME } from 'Constants/search';
import { toggleAllIssuers, updateSecuritiesFilter, checkIsCurrentSelected } from 'State/advancedSearch';
import { despace } from 'Utils/string';

const mapDispatchToProps = { updateSecuritiesFilter, toggleAllIssuers, checkIsCurrentSelected };
const MAX_VALUE_LENGTH = 130;

@reduxForm({
    form: SEARCH_FORM_NAME
})
@injectIntl
@connect(null, mapDispatchToProps)
class SecurityFilter extends Component {
    static propTypes = {
        updateSecuritiesFilter: PropTypes.func,
        disabled: PropTypes.bool,
        intl: intlShape,
        toggleAllIssuers: PropTypes.func,
        checkIsCurrentSelected: PropTypes.func
    };

    handleChange = (e) => {
        const filter = despace(e.target.value.toLowerCase());

        this.props.updateSecuritiesFilter(filter);
        this.props.checkIsCurrentSelected();
    };

    handleFocus = () => {
        this.props.toggleAllIssuers(true);
    };

    render() {
        const { disabled, intl: { formatMessage } } = this.props;

        return (
            <div className='security-filter'>
                <Field
                    name='securitiesFilter'
                    component={TextInput}
                    autoComplete={false}
                    disabled={disabled}
                    props={{ maxLength: MAX_VALUE_LENGTH }}
                    onFocus={this.handleFocus}
                    onChange={this.handleChange}
                    className='security-filter-input'
                    placeholder={formatMessage({ id: 'security.findSecurity' })}/>
                <i className={cn('font-icon font-icon-search', { disabled })}/>
            </div>
        );
    }
}

export default SecurityFilter;
