import React from 'react';
import InaccessibleShareholdersNote from '../InaccessibleShareholdersNote';

const WatchlistFooter = ({ widgetData }) => {
    const widgetNoData = !widgetData || widgetData.length === 0;
    const containsInAccessible = !widgetNoData && widgetData.some((shareholder) => shareholder.accessible === false);

    return containsInAccessible && (
        <div className='watchlist-footer'>
            <InaccessibleShareholdersNote />
        </div>
    );
};

export default WatchlistFooter;
