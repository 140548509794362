import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { commonWidgetPropTypes } from '../../utils';
import pageRoutes from 'Constants/page-routes';
import BackLink from 'Components/BackLink';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import ViewNotesLink from '../../Notes/components/ViewNotesLink';
import featureTypes from 'Constants/feature-types';

const mapStateToProps = state => ({
    currentFeatures: getCurrentFeaturesSelector(state)
});

const ContactDetailsHeader = ({ widgetTitle, isDataViz, match: { params }, location, currentFeatures }) => {
    const shareholderId = params.shareholderId;
    const isShareholderContactsView = _.get(location, 'state.isShareholderContactsView');
    const to = `${pageRoutes.ownershipContactsLanding}${isShareholderContactsView ? `/${shareholderId}` : ''}`;

    return (<div className='widget-header'>
        {!isDataViz &&
            <BackLink defaultTo={to}/>
        }
        <div className='widget-title-container'>
            <div className='widget-title'>{widgetTitle}</div>
            {currentFeatures[featureTypes.NOTES] && <ViewNotesLink/>}
        </div>
    </div>);
};

ContactDetailsHeader.propTypes = commonWidgetPropTypes;

export default connect(mapStateToProps)(ContactDetailsHeader);
