import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import * as dynamicPageActions from 'State/dynamicPage/actions';
import { isLoadingWatchlistSelector } from 'State/watchlist';
import * as widgetSelectors from 'State/widgets/selectors';
import widgetTypes from 'Constants/widget-types';
import * as widgetActions from 'State/widgets/actions';
import Slot from '../Slot';
import Spinner from '../Spinner';
import WatchlistTab from './components/WatchlistTab';
import WidgetTab from './components/WidgetTab';
import useValidateSelectedWidget from './hooks/useValidateSelectedWidget';

import './SlotTab.scss';

const SlotTabs = ({
    slots,
    changeSelectedSlot,
    isLoadingWatchlist,
    getWidgetById,
    widgetsSetSettings
}) => {
    if (isLoadingWatchlist) {
        return <Spinner isFetching />;
    }

    const [activeSlotId, activeSlot] = _.toPairs(slots).find(([, slot]) => slot.isSelected);
    const widgets = useMemo(() => {
        const values = _.memoize(_.values, (...args) => JSON.stringify(args));

        return values(slots).reduce((acc, slot) => [...acc, getWidgetById(slot.widgets[0])], []).filter(Boolean);
    }, [slots]);

    const handleSelectTab = useCallback((widget) => {
        changeSelectedSlot(widget, slots);
    }, [slots, changeSelectedSlot]);

    const isValid = useValidateSelectedWidget({ widgets, widgetsSetSettings });

    if (widgets.length === 1 && widgets[0].type !== widgetTypes.shareholderWatchlist) {
        return <Slot slotId={Number(activeSlotId)} {...activeSlot} />;
    }

    return (
        <div className='slot-tabs'>
            <ul className='tabs tabs-group'>
                {widgets.map(widget => {
                    if (widget.type === widgetTypes.shareholderWatchlist) {
                        return <WatchlistTab key={widget.widgetId} widget={widget} onSelectTab={handleSelectTab} />;
                    }

                    return <WidgetTab key={widget.widgetId} widget={widget} onSelectTab={handleSelectTab} />;
                })}
            </ul>
            <div className='slot-tabs__content'>
                {isValid && <Slot slotId={Number(activeSlotId)} {...activeSlot} />}
            </div>
        </div>
    );
};

SlotTabs.propTypes = {
    slots: PropTypes.object,
    changeSelectedSlot: PropTypes.func,
    isLoadingWatchlist: PropTypes.bool,
    getWidgetById: PropTypes.func,
    watchlists: PropTypes.array,
    widgetsSetSettings: PropTypes.func
};

const mapStateToProps = (state) => ({
    isLoadingWatchlist: isLoadingWatchlistSelector(state),
    getWidgetById: (id) => widgetSelectors.getWidgetSelector(state, id)
});

const mapDispatchToProps = {
    changeSelectedSlot: dynamicPageActions.changeSelectedSlot,
    widgetsSetSettings: widgetActions.widgetsSetSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(SlotTabs);
