import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LinkEx from 'Components/LinkEx';
import { contextToFeatureType } from '../../config';

export class TextCell extends Component {
    static propTypes = {
        original: PropTypes.object,
        value: PropTypes.any
    };

    constructor() {
        super();
        this.state = { title: '' };
    }

    componentDidMount() {
        this.setState({ title: this.element.innerText });
    }

    getElement = element => {
        this.element = element;
    };

    render() {
        const toLocation = this.props.original.toLocation;
        const inactiveLink = value => <span className='ellipsis' ref={this.getElement}>{value}</span>;
        const allowedDetailsPage = this.props.original.features[contextToFeatureType[this.props.original.contextType]];

        return toLocation && allowedDetailsPage ?
            <LinkEx
                title={this.state.title}
                to={toLocation}
                getElement={this.getElement}
                className='ellipsis'>
                {this.props.value}
            </LinkEx>
            : inactiveLink(this.props.value);
    }
}
