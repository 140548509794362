import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.TAX_LOT_ID,
        order: 1,
        minWidth: 120,
        displayName: 'registered.shareholder.table.taxLotId',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 0
        }
    },
    {
        columnName: columnNames.ACQUISITION_EFFECTIVE_DATE,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.acquisitionEffectiveDate',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.COVERED_INDICATOR,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.coveredIndicator',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.CERT_PREFIX,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.certPrefix',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        },
        onlyForNonfungible: true
    },
    {
        columnName: columnNames.CERT_NUMBER,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.certNumber',
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.NUMBER
        },
        onlyForNonfungible: true
    },
    {
        columnName: columnNames.CURRENT_SHARES,
        order: 6,
        minWidth: 120,
        displayName: 'registered.shareholder.table.currentShares',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.ORIGINAL_COST_BASIS,
        order: 7,
        minWidth: 130,
        displayName: 'registered.shareholder.table.originalCostBasis',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 6
        }
    },
    {
        columnName: columnNames.CURRENT_COST_BASIS,
        order: 8,
        minWidth: 120,
        displayName: 'registered.shareholder.table.currentCostBasis',
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 6
        }
    }
];
