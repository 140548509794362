import axios from 'axios';

import apiRoutes from 'Constants/api-routes';
import requestTypes from 'Constants/request-types';
import errorStrategies from 'Constants/error-stategies';
import { buildRequest } from './utils';
import pageRoutes from 'Constants/page-routes';

export const login = (code, pathname) => {
    const requestData = buildRequest({}, requestTypes.loginPayload);
    const state = pathname || pageRoutes.home;

    return axios.post(apiRoutes.loginUrl, requestData, {
        errorStrategy: errorStrategies.CRITICAL,
        isCancelable: false,
        noHandleError: true,
        headers: code ? { 'Ping-Code': code, State: state } : { State: state }
    });
};

export const logout = () => {
    const requestData = buildRequest({}, requestTypes.logoutPayload);

    return axios.post(apiRoutes.logoutUrl, requestData, {
        errorStrategy: errorStrategies.SKIP,
        isCancelable: false
    });
};
