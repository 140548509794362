import { compose, withProps } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropsTypes from 'prop-types';

import {
    notifyError,
    notifySuccess,
    notifyInfo,
    notifyWarning,
    removeNotificationByType
} from 'State/notifier/actions';
import { showInfoModal } from 'State/modal';
import { fileSizeFormatter } from 'Components/formatters/formatters';

const mapDispatchToProps = {
    notifyError,
    showInfoModal,
    notifySuccess,
    notifyInfo,
    notifyWarning,
    removeNotificationByType
};

export const withUploadNotifications = compose(
    injectIntl,
    connect(null, mapDispatchToProps),
    withProps((props) => {
        const {
            notifyInfo: info,
            notifyError: error,
            showInfoModal: infoModal,
            notifyWarning: warning,
            notifySuccess: success,
            removeNotificationByType: removeNotification,
            intl: { formatMessage }
        } = props;

        return ({
            notifyUploadInProgress: (fileName) => info(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.inProgress' },
                    { fileName }
                ),
                {
                    timeOut: 0,
                    className: 'notification-info',
                    removeOnHover: false
                }
            ),
            notifyUploadWrongType: (fileName) => error(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.wrongFileType' },
                    { fileName }
                )
            ),
            notifyUploadEmptyFile: (fileName) => error(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.emptyFile' },
                    { fileName }
                )
            ),
            notifyUploadLargeFile: (fileName, maxFileSize) => error(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.toLargeFileSize' },
                    {
                        fileName,
                        maxSize: fileSizeFormatter(maxFileSize, 0)
                    }
                )
            ),
            notifyUploadSecurityFail: (fileName) => warning(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.securityCheckFailed' },
                    { fileName }
                )
            ),
            notifyUploadFail: (fileName) => error(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.failed' },
                    { fileName }
                )
            ),
            notifyUploadSuccess: (fileName) => success(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.success' },
                    { fileName }
                )
            ),
            notifyUploadError: (fileName) => error(
                null,
                formatMessage(
                    { id: 'uploadDocuments.notifier.error' },
                    { fileName }
                )
            ),
            removeAllUploadNotification: () => removeNotification('info'),
            showUploadModal: (params = {
                titleKey: 'modals.clientUpload.title',
                messageKey: 'modals.clientUpload.message',
                okTextKey: 'modals.clientUpload.okText',
                isHtmlMessage: true
            }) => infoModal(params)
        });
    })
);

withUploadNotifications.propTypes = {
    notifyUploadInProgress: PropsTypes.func,
    notifyUploadWrongType: PropsTypes.func,
    notifyUploadEmptyFile: PropsTypes.func,
    notifyUploadLargeFile: PropsTypes.func,
    notifyUploadSecurityFail: PropsTypes.func,
    notifyUploadFail: PropsTypes.func,
    notifyUploadSuccess: PropsTypes.func,
    notifyUploadError: PropsTypes.func,
    removeAllUploadNotification: PropsTypes.func,
    showUploadModal: PropsTypes.func
};
