import store from '../context/store';
import * as userSelectors from 'State/user/selectors';
import { getUserInfo } from 'State/user/actions';
import { getQueryParameterByName } from './routing';
import { getSecurities } from 'State/user';
import { hideLoadingBlock, showLoadingBlock } from 'State/loadingBlock/actions';
import { login } from '../api/auth';
import pageRoutes from 'Constants/page-routes';
import { CODE, REDIRECT_STATE } from 'Constants/auth';
import { refreshUserSession } from '../context/session';

const { dispatch, getState } = store;

export const requireUserInfo = pathname => {
    const securityId = parseInt(getQueryParameterByName('securityId'), 10);
    const code = localStorage.getItem(CODE);
    const redirectState = localStorage.getItem(REDIRECT_STATE);

    dispatch(showLoadingBlock());
    return login(code, pathname).then(() => Promise.all([
        dispatch(getUserInfo(securityId)),
        dispatch(getSecurities())
    ])).then(() => {
        const state = getState();
        const userInfo = userSelectors.getUserInfoSelector(state);

        localStorage.removeItem(CODE);

        if (!userInfo) return;

        if (redirectState) {
            pathname !== pageRoutes.index && this.props.history.push(redirectState);
            localStorage.removeItem(REDIRECT_STATE);
        }

        refreshUserSession();
    }).finally(() => {
        dispatch(hideLoadingBlock());
    });
};
