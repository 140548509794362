import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { generatePath, withRouter } from 'react-router-dom';
import { compose, withProps } from '@shakacode/recompose';

import ScrollableReactTable from 'Components/Tables/ScrollableReactTable';
import { TheadComponent, TdComponent, NoResults } from 'Components/Tables/components/ReactTableComponents';
import NullComponent from 'Components/widgets/Widget/components/NullComponent';
import { allRequestsSelector, getSecureUploadRequests, isFetchingSelector, createSecureUploadDraft, getSecureUploadEmailRecipients } from 'State/userSecureUpload';
import { pathWithSecurity } from 'Utils/routing';
import pageRoutes from 'Constants/page-routes';

import { columnDefinitions, columnNames } from './columnDefinitions';
import { TableLoading } from './components/TableLoading';

import './UserSecureUploadPage.scss';

const mapDispatchToProps = {
    getSecureUploadRequests,
    createSecureUploadDraft,
    getSecureUploadEmailRecipients
};

const mapStateToProps = state => ({
    requests: allRequestsSelector(state),
    isLoading: isFetchingSelector(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export const UserSecureUploadPage = compose(withRouter, connector)(({
    getSecureUploadRequests: fetchRequests,
    createSecureUploadDraft: createDraft,
    getSecureUploadEmailRecipients: getEmailRecipients,
    requests,
    history,
    isLoading = false
}) => {
    useEffect(() => {
        fetchRequests();
    }, []);

    const handleClick = async () => {
        try {
            const requestId = await createDraft();

            await getEmailRecipients(requestId);

            const editPagePath = generatePath(pageRoutes.downloadsUserSecureUploadEdit, { requestId });

            history.push(pathWithSecurity(editPagePath));
        } catch {
        // Show error notification
        }
    };

    return (
        <div className='user-secure-upload-page'>
            <h2 className='user-secure-upload_page-header'>
                <FormattedMessage id='userSecureUpload.listPage.title' />
            </h2>
            <div className='user-secure-upload-list-action'>
                <button
                    type='button'
                    className='btn btn-large'
                    onClick={handleClick}>
                    <FormattedMessage id='userSecureUpload.listPage.create' />
                </button>
            </div>
            <div>
                <FormattedMessage id='userSecureUpload.listPage.note'>
                    {(msg) => <p className='message-note'>{msg}</p>}
                </FormattedMessage>
            </div>
            <ScrollableReactTable
                showPagination={false}
                resizable={false}
                columns={columnDefinitions}
                className={'scroll-handle'}
                LoadingComponent={isLoading ? TableLoading : NullComponent}
                TdComponent={TdComponent}
                ThComponent={TheadComponent}
                NoDataComponent={withProps({ messageId: 'userSecureUpload.listPage.noResults' })(NoResults)}
                manual
                defaultPageSize={0}
                resolvedData={_.orderBy(requests, [columnNames.UPDATE_DATE], ['desc'])}/>
        </div>
    );
});
