import React from 'react';
import cn from 'classnames';
import _ from 'lodash';

const WidgetHeaderCompanyDefault = (props) => {
    const companyName = _.get(props, 'widgetDataInfo.companyName');
    const hideCompanyName = _.get(props, 'widgetHeaderCompanySettings.hideCompany', false);
    const classNames = cn('widget-company', { 'visually-hidden': hideCompanyName });

    return (
        companyName ? <div className={classNames}>{companyName}</div> : null
    );
};

export default WidgetHeaderCompanyDefault;
