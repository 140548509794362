import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';
import { connect } from 'react-redux';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import * as featuresSelectors from 'State/features/selectors';
import InstitutionProfileContent from './components/InstitutionProfileContent';
import InstitutionProfileWidgetControlPanel from './components/InstitutionProfileWidgetControlPanel';

import './InstitutionProfile.scss';

const mapStateToProps = state => ({
    info: dynamicPageSelectors.getDynamicPageInfoSelector(state),
    currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state)
});

@injectIntl
@connect(mapStateToProps)
class InstitutionProfile extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: InstitutionProfileContent,
            WidgetControlPanel: props.isPrintMode ? null : InstitutionProfileWidgetControlPanel
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'institution.profile.title' });
        this.widgetEvents = {
            onBuildDataParams: () => ({
                shareholderId: _.get(this.props, 'widgetPageParams.shareholderId')
            })
        };
    }

    render() {
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetNoData: !_.get(this.props, 'widgetData.profileInfo')
        };

        return (
            <Widget
                {...props}
                events={this.widgetEvents}
                className='widget-institution-profile'
                components={this.widgetComponents}/>
        );
    }
}

export default InstitutionProfile;
