import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import TableEasy from 'Components/Tables/TableEasy';
import { commonWidgetPropTypes, commonWidgetActions, getSignClassNameFactory } from 'Components/widgets/utils';
import Tooltip from 'Components/Tooltip';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import { NullableFormatter, NumberFormatter } from 'Components/formatters';
import { columns, MAX_VISIBLE_ROWS } from '../constants';
import { valueWithTooltipCell } from './cellRenderers';
import messageType from 'Constants/message-types';

class OutstandingPaymentsContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        tableView: PropTypes.bool,
        onMonthChange: PropTypes.func,
        dates: PropTypes.array,
        selectedDate: PropTypes.string
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage } } = props;

        this.columns = [{
            dataKey: columns.COUNTRY,
            flexGrow: 3,
            title: formatMessage({ id: 'date.last.contact.column.country' }),
            headerCellComponent: () => (<Tooltip
                content={<FormattedHTMLMessage id='date.last.contact.column.tooltip.country'/>}>
                <span>{formatMessage({ id: 'date.last.contact.column.country' })}</span>
            </Tooltip>),
            bodyCellComponent: valueWithTooltipCell
        }, {
            dataKey: columns.STATE,
            minWidth: 80,
            flexGrow: 3,
            title: formatMessage({ id: 'date.last.contact.column.state' }),
            columnClassName: 'overfilling-row-container',
            headerCellComponent: () => (<Tooltip
                content={<FormattedHTMLMessage id='date.last.contact.column.tooltip.state'/>}>
                <span>{formatMessage({ id: 'date.last.contact.column.state' })}</span>
            </Tooltip>),
            bodyCellComponent: v => <NullableFormatter {...v} withTitle className='overfilling-row'/>
        }, {
            dataKey: columns.NUMBER_OF_SHAREHOLDERS,
            flexGrow: 4,
            maxWidth: 160,
            columnClassName: 'text-right',
            title: formatMessage({ id: 'date.last.contact.column.noOfShareholders' }),
            headerCellComponent: () => (<Tooltip
                content={<FormattedHTMLMessage id='date.last.contact.column.tooltip.noOfShareholders'/>}>
                <span>{formatMessage({ id: 'date.last.contact.column.noOfShareholders' })}</span>
            </Tooltip>),
            bodyCellComponent: v => {
                const getSignClassByMessageType = getSignClassNameFactory(this.props.widgetFooterProps);
                const className = v.rowData.isTotal
                    ? getSignClassByMessageType(messageType.DIFFERENT_FROM_REPORT)
                    : null;

                return <NumberFormatter {...v} className={className} withTitle/>;
            }
        }];
    }

    render() {
        const {
            data: { aggregate, values } = {},
            isFetching,
            widgetNoData,
            isDataViz,
            isPrintMode,
            widgetFooterProps
        } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        className='widget-table'
                        columns={this.columns}
                        data={values}
                        renderAllRows={isDataViz}
                        showAllRows={isPrintMode}
                        maxVisibleRows={MAX_VISIBLE_ROWS}
                        aggregate={aggregate}
                        widgetFooterProps={widgetFooterProps}/>
                </NoDataComponent>
            </div>);
    }
}

export default OutstandingPaymentsContent;
