import React, { Component } from 'react';
import { wrapDisplayName } from '@shakacode/recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import reportTypes from 'Constants/report-types';
import registeredReportTemplates from 'Components/widgets/_commonComponents/RegisteredShareholderTable/constants/registered-report-templates';
import reportExportFormats from 'Constants/export-report-formats';
import { runReport, showExportRegisteredOverviewModal, showExportWidgetModal } from 'State/exportReports';
import { getSortDirection } from 'Components/Tables/utils';
import { getRegisteredOverviewTdProps } from '../../utils';
import { showConfirmModal } from 'State/modal';
import { PAGE_SIZE } from '../../constants/page-settings';

const mapDispatchToProps = {
    showExportRegisteredOverviewModal,
    showConfirmModal,
    showExportWidgetModal,
    runReport
};

export default WrappedComponent => {
    @connect(null, mapDispatchToProps)
    class RegisteredOverview extends Component {
        static displayName = wrapDisplayName(WrappedComponent, 'registeredOverview');

        static propTypes = {
            ...WrappedComponent.propTypes,
            sectionTypes: PropTypes.arrayOf(PropTypes.string)
        };

        constructor(props) {
            super();
            this.widgetEvents = {
                onBuildDataParams: () => {
                    const params = { shareholderId: _.get(props, 'widgetPageParams.shareholderId') };

                    this.props.sectionTypes.forEach(sectionType => {
                        params[sectionType] = this.getSectionParams({ sectionType, isInitial: true });
                    });

                    return params;
                }
            };
        }

        componentDidMount() {
            const { onUpdateWidgetData, redirectOn404 } = this.props;
            const widgetDataParams = _.isFunction(this.widgetEvents.onBuildDataParams) ? this.widgetEvents.onBuildDataParams({}) : {};

            onUpdateWidgetData(widgetDataParams, { redirectOn404 });
        }

        handleExport = () => {
            const { widgetSettings = {}, widgetType, widgetPageParams } = this.props;
            const fungibleSortCriteria = _.get(widgetSettings, 'fungibleSection.sortCriteria', {});
            const nonFungibleSortCriteria = _.get(widgetSettings, 'nonFungibleSection.sortCriteria', {});
            const fungibleSelectedColumns = _.get(widgetSettings, 'fungibleSection.selectedColumns', []);
            const nonFungibleSelectedColumns = _.get(widgetSettings, 'nonFungibleSection.selectedColumns', []);
            const reportTemplateType = reportTypes[registeredReportTemplates[widgetType]];
            const shareholderId = _.get(widgetPageParams, 'shareholderId');

            this.props.runReport(
                reportTemplateType,
                null,
                reportExportFormats.EXCEL,
                {
                    shareholderId,
                    fungibleSection: {
                        sortCriteria: fungibleSortCriteria,
                        columnsToDisplay: fungibleSelectedColumns
                    },
                    nonFungibleSection: {
                        sortCriteria: nonFungibleSortCriteria,
                        columnsToDisplay: nonFungibleSelectedColumns
                    }
                }
            );
        };

        handleChangeCurrentPage = (currentPage) => {
            this.updateWidgetData({ currentPage });
        };

        handleChangeSortCriteria = (sortBy, columnsDefinitions) => {
            const { widgetSettings, expandedSection, widgetData } = this.props;
            const prevSettings = _.get(widgetSettings, expandedSection);
            const prevSortBy = _.get(prevSettings, 'sortCriteria.sortField');
            const prevDirection = _.get(prevSettings, 'sortCriteria.sortDirection');
            const currentPage = _.get(widgetData, `${expandedSection}.filterCriteria.currentPage`);
            const sortCriteria = {
                sortField: sortBy,
                sortDirection: getSortDirection(
                    sortBy,
                    prevSortBy,
                    prevDirection,
                    columnsDefinitions
                )
            };
            const newSettings = {
                ...widgetSettings,
                [expandedSection]: {
                    ...prevSettings,
                    sortCriteria
                }
            };

            this.props.onUpdateWidgetSettings(newSettings);
            this.updateWidgetData({ sortCriteria, currentPage });
        };

        getDefaultTdProps = columnType => ({
            ...getRegisteredOverviewTdProps(columnType)
        });

        getNoDataMessageId = () => {
            const { widgetType, expandedSection } = this.props;

            return `registered.shareholder.information.noData.${widgetType}.${expandedSection}`;
        };

        getSectionParams = ({ sectionType, sortCriteria, currentPage = 1, isInitial }) => {
            const { expandedSection, widgetSettings, widgetData } = this.props;
            const previousPage = _.get(widgetData, `${sectionType}.filterCriteria.currentPage`, 1);

            return {
                filterCriteria: {
                    currentPage: !isInitial && expandedSection !== sectionType ? previousPage : currentPage,
                    pageSize: PAGE_SIZE
                },
                sortCriteria: sortCriteria && expandedSection && expandedSection === sectionType
                    ? sortCriteria
                    : _.get(widgetSettings, `${sectionType}.sortCriteria`)
            };
        };

        updateWidgetData = ({ sortCriteria, currentPage }) => {
            const { sectionTypes } = this.props;
            const params = { ...this.props.widgetPageParams };

            sectionTypes.forEach(sectionType => {
                params[sectionType] = this.getSectionParams({ sectionType, sortCriteria, currentPage });
            });

            this.props.onUpdateWidgetData(params);
        };

        render() {
            return (
                <WrappedComponent
                    widgetEvents={this.widgetEvents}
                    onExport={this.handleExport}
                    getDefaultTdProps={this.getDefaultTdProps}
                    onChangeCurrentPage={this.handleChangeCurrentPage}
                    pageSize={PAGE_SIZE}
                    hideDateRangePicker
                    onChangeSortCriteria={this.handleChangeSortCriteria}
                    noDataMessageId={this.getNoDataMessageId()}
                    {...this.props}/>
            );
        }
    }

    return RegisteredOverview;
};
