import React from 'react';
import PropTypes from 'prop-types';

import TopInvestorsTooltip from '../TopInvestorsTooltip';
import { commonSortDescendingIcon, commonSortAscendingIcon } from 'Components/Tables/components/commonSortIcons';


const HeadingCellTopInvestors = ({ title, sortProperty, className, sortDescendingIcon, sortAscendingIcon }) => {
    return (
        <div className={className}>
            <TopInvestorsTooltip title={title}/>
            {sortProperty && sortProperty.sortAscending && sortAscendingIcon}
            {sortProperty && !sortProperty.sortAscending && sortDescendingIcon}
        </div>
    );
};

HeadingCellTopInvestors.propTypes = {
    title: PropTypes.string,
    sortAscendingIcon: PropTypes.object,
    sortDescendingIcon: PropTypes.object,
    sortProperty: PropTypes.object,
    className: PropTypes.string
};

HeadingCellTopInvestors.defaultProps = {
    sortAscendingIcon: commonSortAscendingIcon,
    sortDescendingIcon: commonSortDescendingIcon
};

export default HeadingCellTopInvestors;
