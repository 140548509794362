import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import * as featuresSelectors from 'State/features/selectors';
import OwnershipPageView from '../components/OwnershipPageView';
import { pathRoutesConfig } from 'Routes';
import { buildNavLinks } from 'Utils/routing';

const mapStateToProps = (state) => ({
    currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state)
});

@connect(mapStateToProps, {  })
class OwnershipPage extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        currentFeatures: PropTypes.object
    };

    getNavLinks = () => {
        const { intl: { formatMessage }, currentFeatures } = this.props;
        const { ownershipNav } = pathRoutesConfig;
        const navLinks = buildNavLinks({
            pathConfig: ownershipNav.pathConfig,
            features: currentFeatures,
            formatMessage
        });

        return navLinks;
    };

    render() {
        const navLinks = this.getNavLinks();

        return <OwnershipPageView{...this.props} links={navLinks}/>;
    }
}

export default injectIntl(OwnershipPage);
