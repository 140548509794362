import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import WidgetErrorDefault from '../WidgetError';
import WidgetLayoutEnhancer from '../WidgetLayoutEnhancer';

const WidgetLayout = ({ widgetLayoutRef, ...props }) => {
    const {
        WidgetHeaderCompany,
        WidgetHeader,
        WidgetControlPanel,
        WidgetActionPanel,
        WidgetContent,
        WidgetFooter,
        WidgetError,
        WidgetBusy,
        WidgetDataLoader,
        isError,
        isFetching,
        isRemoving,
        isDataViz,
        isAdding
    } = props;
    const className = cn('widget', props.className || '', {
        'widget-error': isError
    });
    const HeaderCompany = WidgetHeaderCompany && isDataViz ? <WidgetHeaderCompany {...props}/> : null;
    const Header = WidgetHeader ? <WidgetHeader {...props}/> : null;
    const ControlPanel = WidgetControlPanel ? <WidgetControlPanel {...props}/> : null;
    const ActionPanel = WidgetActionPanel ? <WidgetActionPanel {...props}/> : null;
    const Content = WidgetContent ? <WidgetContent {...props}/> : null;
    const Footer = WidgetFooter ? <WidgetFooter {...props}/> : null;
    const Error = WidgetError ? <WidgetError {...props}/> : <WidgetErrorDefault {...props}/>;
    const Busy = WidgetBusy ? <WidgetBusy {...props}/> : null;
    const DataLoader = WidgetDataLoader ? <WidgetDataLoader {...props}/> : null;
    const showSplash = isRemoving || isAdding;
    const showContent = !isError && !isFetching && !showSplash;
    const Splash = WidgetBusy ? <WidgetBusy isFetching={showSplash}/> : null;

    return (
        <div className={className} data-widget-type={props.widgetType} ref={widgetLayoutRef}>
            {!showSplash && DataLoader}
            {showContent && HeaderCompany}
            <div className='widget-header-container'>
                {Header}
                {ControlPanel}
            </div>
            { showContent && ActionPanel }
            { showContent && Content }
            { showContent && Footer }
            { isError && !isFetching && Error }
            { isFetching && Busy }
            { showSplash && Splash }
        </div>
    );
};

WidgetLayout.propTypes = {
    ...Component.propTypes,
    components: PropTypes.object,
    WidgetHeaderCompany: PropTypes.any,
    WidgetHeader: PropTypes.any,
    WidgetActionPanel: PropTypes.any,
    WidgetControlPanel: PropTypes.any,
    WidgetContent: PropTypes.any,
    WidgetError: PropTypes.any,
    WidgetBusy: PropTypes.any,
    WidgetDataLoader: PropTypes.any,
    isError: PropTypes.bool,
    isFetching: PropTypes.bool,
    isRemoving: PropTypes.bool,
    isAdding: PropTypes.bool,
    widgetType: PropTypes.string,
    widgetLayoutRef: PropTypes.object
};

export default WidgetLayoutEnhancer(WidgetLayout);
