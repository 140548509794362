import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.CHECK_NO,
        order: 1,
        minWidth: 130,
        displayName: 'registered.shareholder.table.checkNo',
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.ISSUE_DATE,
        order: 2,
        minWidth: 120,
        displayName: 'registered.shareholder.table.issueDate',
        manageable: false,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.PAYMENT_TYPE,
        order: 3,
        minWidth: 120,
        displayName: 'registered.shareholder.table.paymentType',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.AMOUNT,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.amount',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.STATUS,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.status',
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.STATUS_DATE,
        order: 7,
        minWidth: 120,
        displayName: 'registered.shareholder.table.statusDate',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        widthParams: {
            valueLength: 11
        }
    }
];

export const secondarySorting = {
    field: columnNames.CHECK_NO,
    direction: sortDirections.ASC
};
