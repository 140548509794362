import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import colorScheme from 'Constants/color-schemas';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';
import Icon from 'Components/Icon';
import formatters from 'Constants/formatter-options';
import ColoredSquare from 'Components/Tables/components/ColoredSquare';

import './AnalysisLegend.scss';

class AnalysisLegend extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        data: PropTypes.arrayOf(PropTypes.shape({
            shareholderId: PropTypes.string,
            shareholderName: PropTypes.string
        })),
        onDelete: PropTypes.func,
        colors: PropTypes.arrayOf(PropTypes.string),
        noDataMessage: PropTypes.string,
        isShowDeleteIcon: PropTypes.bool
    };

    static defaultProps = {
        colors: colorScheme['1']
    };

    render() {
        const { data, colors, onDelete, noDataMessage, isShowDeleteIcon } = this.props;
        const list = _.map(data, (item, key) => {
            const val = item.shareholderName || formatters.SYMBOL_EMPTY_VALUE;

            return (
                <div key={item.shareholderId} className='item'>
                    <ColoredSquare value={colors[key]}/>
                    <span className='label' title={val}>{val}</span>
                    {isShowDeleteIcon &&
                    <Icon
                        className='font-icon font-icon-delete'
                        onClick={() => onDelete(item)}/>}
                </div>
            );
        });

        return (
            <div className='analysis-legend'>
                <NoDataComponent
                    isFetching={false}
                    isNoData={!(_.isArray(data) && data.length > 0)}
                    message={noDataMessage}>
                    <div>
                        {list}
                    </div>
                </NoDataComponent>
            </div>
        );
    }
}

export default AnalysisLegend;
