export default {
    FIVE_DAYS: '5D',
    TEN_DAYS: '10D',
    ONE_MONTH: '1M',
    THREE_MONTH: '3M',
    SIX_MONTH: '6M',
    YTD: 'YTD',
    ONE_YEAR: '1Y',
    TWO_YEARS: '2Y',
    FIVE_YEARS: '5Y'
};
