import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withProps } from '@shakacode/recompose';

import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import { compareShareholders } from '../_commonComponents/RegisteredShareholderTable/hoc';
import { commonWidgetPropTypes, commonWidgetActions, getManageableColumns } from 'Components/widgets/utils';
import { addSecurityColumn } from '../_commonComponents/RegisteredShareholderTable/utils';

const COLUMNS_MANAGER_ROWS = 6;

@withProps(({ widgetData: values }) => ({
    data: addSecurityColumn(values),
    columnsDefinitions
}))
@compareShareholders
class CompareShareholders1099DIV extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        columnsDefinitions: PropTypes.array
    };

    render() {
        return (
            <RegisteredShareholderTable
                {...this.props}
                secondarySorting={secondarySorting}
                columnManagerRows={COLUMNS_MANAGER_ROWS}
                manageableColumns={getManageableColumns(this.props.columnsDefinitions)}
                disableValidation/>
        );
    }
}

export default CompareShareholders1099DIV;
