import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import WidgetAddToReport from 'Components/widgets/Widget/components/WidgetAddToReport';
import WidgetRemove from 'Components/widgets/Widget/components/WidgetRemove';
import WidgetPrint from 'Components/widgets/Widget/components/WidgetPrint';
import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';
import featureTypes from 'Constants/feature-types';

const mapStateToProps = state => ({
    blockAction: getDynamicPageBlockActionSelector(state)
});

@connect(mapStateToProps)
class ContactDetailsControlPanel extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        blockAction: PropTypes.bool
    };

    render() {
        const {
            isDataViz,
            blockAction,
            onAddWidgetToDataVizReport,
            onRemoveWidget,
            events: { onBuildDataParams } = {},
            widgetType,
            currentFeatures
        } = this.props;

        return (
            <div className='widget-control-panel'>
                {!isDataViz &&
                <WidgetPrint
                    className='action-icon'
                    widgetType={widgetType}/>
                }
                {currentFeatures[featureTypes.CUSTOM_REPORTS] && !isDataViz &&
                <WidgetAddToReport
                    onAddToReport={onAddWidgetToDataVizReport}
                    dataParamsBuilder={onBuildDataParams}/>
                }
                {isDataViz &&
                <WidgetRemove
                    blockAction={blockAction}
                    onRemoveWidget={onRemoveWidget}/>
                }
            </div>
        );
    }
}

export default ContactDetailsControlPanel;
