import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.SHAREHOLDER_NAME,
        order: 1,
        minWidth: 210,
        displayName: 'registered.shareholder.table.shareholder',
        initialSortDirection: sortDirections.ASC,
        manageable: false
    },
    {
        columnName: columnNames.ISSUER,
        order: 2,
        minWidth: 210,
        displayName: 'registered.shareholder.table.issuer',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SECURITY,
        order: 3,
        minWidth: 210,
        displayName: 'registered.shareholder.table.security',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.SECURITY_NAME
        }
    },
    {
        columnName: columnNames.ACCOUNT_NUMBER,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.accountNumber',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.STATUS,
        order: 5,
        minWidth: 90,
        displayName: 'registered.shareholder.table.status',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.ADDRESS,
        order: 6,
        minWidth: 240,
        displayName: 'registered.shareholder.table.registeredAddress',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.TOTAL,
        order: 7,
        minWidth: 90,
        displayName: 'registered.shareholder.table.totalShares',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.CERTIFICATED,
        order: 8,
        minWidth: 120,
        displayName: 'registered.shareholder.table.certificated',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.BOOK,
        order: 9,
        minWidth: 90,
        displayName: 'registered.shareholder.table.book',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.DRDSPP,
        order: 10,
        minWidth: 100,
        displayName: 'registered.shareholder.table.drdspp',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    },
    {
        columnName: columnNames.MKT_PRICE,
        order: 11,
        minWidth: 105,
        displayName: 'registered.shareholder.table.mktPrice',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.VALUE,
        order: 12,
        minWidth: 90,
        displayName: 'registered.shareholder.table.value',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.HOLDER_TYPE,
        order: 13,
        minWidth: 120,
        displayName: 'registered.shareholder.table.holderType',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SPEC_ACCT_CODE,
        order: 14,
        minWidth: 120,
        displayName: 'registered.shareholder.table.specAcctCode',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.MAIL_CODE,
        order: 15,
        minWidth: 120,
        displayName: 'registered.shareholder.table.mailCode',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.DIRECT_DEPOSIT,
        order: 16,
        minWidth: 100,
        displayName: 'registered.shareholder.table.directDeposit',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.ENROLLED_IN_DRP,
        order: 17,
        minWidth: 100,
        displayName: 'registered.shareholder.table.enrolledInDRP',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.BLOCK,
        order: 18,
        minWidth: 100,
        displayName: 'registered.shareholder.table.block',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    }
];

export const secondarySorting = {
    field: columnNames.ACCOUNT_NUMBER,
    direction: sortDirections.ASC
};
