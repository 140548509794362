import _ from 'lodash';

import * as api from '../../api/marketData';

export const GET_MARKET_DATA_SUCCESS = 'GET_MARKET_DATA_SUCCESS';
export const GET_MARKET_DATA_FAILURE = 'GET_MARKET_DATA_FAILURE';

export const getMarketData = () => dispatch => api.getMarketData()
    .then(res => {
        const payload = _.get(res, 'data.payload.marketData') || {};

        dispatch({
            type: GET_MARKET_DATA_SUCCESS,
            payload
        });
    }, error => {
        dispatch({
            type: GET_MARKET_DATA_FAILURE,
            payload: error
        });
    });
