import _ from 'lodash';

import sortDirections from 'Constants/sort-directions';

const toggleSortDirection = currentSortDirection => {
    return currentSortDirection === sortDirections.ASC ?
        sortDirections.DESC : sortDirections.ASC;
};

export const getSortDirection = (selectedSortField, currentSortField, currentSortDirection, columns, sortProperty = 'columnName') => {
    if (selectedSortField === currentSortField) {
        return toggleSortDirection(currentSortDirection);
    }

    const selectedColumn = _.find(columns, column => column[sortProperty] === selectedSortField);

    return _.get(selectedColumn, 'initialSortDirection', sortDirections.ASC);
};
