import PropTypes from 'prop-types';
import React from 'react';
import { Layout } from '../../layout';
import Slot from '../../Slot';

const LayoutEasy = (props) => {
    const { slots } = props;

    return (
        <Layout className='layout-easy'>
            <Slot
                {...slots[1]}
                slotId={1}/>
        </Layout>
    );
};

LayoutEasy.propTypes = {
    slots: PropTypes.object
};

export default LayoutEasy;
