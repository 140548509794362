import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { clearFields } from 'redux-form';
import { connect } from 'react-redux';

import { CheckBox } from 'Components/CheckBox';
import TextInput from '../../TextInput';

const mapDispatchToProps = dispatch => {
    return {
        clearFormFields: (formName, ...fieldsNames) => {
            dispatch(clearFields(formName, false, false, ...fieldsNames));
        }
    };
};

@connect(null, mapDispatchToProps)
class SharesOwnedField extends Component {
    static propTypes = {
        sharesOwned: PropTypes.shape({
            checked: PropTypes.object,
            min: PropTypes.object,
            max: PropTypes.object
        }),
        items: PropTypes.object,
        clearFormFields: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            min: '',
            max: ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { sharesOwned: { min: minField, max: maxField } } = nextProps;

        this.setState({
            min: _.isNaN(minField.input.value) ? this.state.min : minField.input.value,
            max: _.isNaN(maxField.input.value) ? this.state.max : maxField.input.value
        });
    }

    handleCheckboxChange = (e) => {
        const {
            sharesOwned: { checked: checkedField, min: minField, max: maxField },
            clearFormFields,
            items: { formName }
        } = this.props;
        const isChecked = e.target.checked;

        if (!isChecked) {
            clearFormFields(formName, minField.input.name, maxField.input.name);
        }
        minField.input.onChange('');
        maxField.input.onChange('');
        checkedField.input.onChange(isChecked);
    };

    handleTextChange = (e) => {
        const name = e.target.name.split('.').pop();
        const value = e.target.value;

        this.setState({
            ...this.state,
            [name]: value
        });
    };

    render() {
        const { sharesOwned: { checked: checkedField, min: minField, max: maxField }, items } = this.props;
        const isChecked = !!checkedField.input.value;

        return (
            <div className='shares-owned-range'>
                <CheckBox
                    {...checkedField.input}
                    onChange={this.handleCheckboxChange}
                    label={`${items.checkbox}:`}
                    disabled={items.disabled}
                    checked={isChecked}/>
                <TextInput
                    {...minField.input}
                    onChange={this.handleTextChange}
                    disabled={!isChecked}
                    label={items.min}
                    error={minField.meta.touched && minField.meta.error}
                    value={this.state.min}/>
                <TextInput
                    {...maxField.input}
                    onChange={this.handleTextChange}
                    disabled={!isChecked}
                    label={items.max}
                    error={maxField.meta.touched && maxField.meta.error}
                    value={this.state.max}/>
            </div>
        );
    }
}

SharesOwnedField.parse = (value, name) => {
    if (name === 'sharesOwned.min' || name === 'sharesOwned.max') {
        return value === '' ? null : _.toNumber(value);
    }
    return value;
};

export default SharesOwnedField;
