import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intlShape } from 'react-intl';
import { Field, propTypes as reduxFormPropTypes } from 'redux-form';

import TextInput from 'Components/TextInput';
import Icon from 'Components/Icon';
import { reportNameNormalize } from '../../common/reportNameUtils';


class ReportingDataVizReportPageControlPanelView extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        onSave: PropTypes.func,
        onExport: PropTypes.func,
        onPrint: PropTypes.func,
        isStarted: PropTypes.bool,
        templateIsDirty: PropTypes.bool,
        intl: intlShape
    };

    constructor(props) {
        super(props);
        const { intl: { formatMessage }, handleSubmit, onSave } = props;

        this.handleSubmit = handleSubmit(onSave);
        this.labelText = formatMessage({ id: 'reporting.dataVizReport.label.reportName' });
        this.saveBtnText = formatMessage({ id: 'reporting.save' });
        this.noteText = formatMessage({ id: 'reporting.dataVizReport.note' });
    }

    render() {
        const {
            submitting,
            invalid,
            pristine,
            isStarted,
            hasWidgets,
            templateIsDirty,
            // onExport,
            onPrint,
            intl: { formatMessage }
        } = this.props;
        const isPristine = isStarted ? pristine : !templateIsDirty && pristine;

        return (
            <div className='reporting-dataviz-report-control-panel'>
                <form onSubmit={this.handleSubmit} className='control-panel-form'>
                    <Field
                        className='report-template-name'
                        component={TextInput}
                        name='reportTemplateName'
                        label={this.labelText}
                        labelType='vertical'
                        type='text'
                        tooltipMessage={formatMessage({ id: 'reporting.templateNameInputTooltip' })}
                        normalize={reportNameNormalize}/>
                    <div className='control-panel-actions-container'>
                        <button
                            type='submit'
                            disabled={submitting || invalid || isPristine || !hasWidgets}
                            className='btn btn-large save-btn'>
                            {this.saveBtnText}
                        </button>
                        <div className='control-panel-actions'>
                            <Icon
                                className='font-icon font-icon-local-printshop action-icon'
                                onClick={onPrint}/>
                            {/* <Icon */}
                            {/*    className='font-icon font-icon-download action-icon' */}
                            {/*    onClick={onExport}/> */}
                        </div>
                        {/* <div className='control-panel-note message-note'> */}
                        {/*    {this.noteText} */}
                        {/* </div> */}
                    </div>
                </form>
            </div>
        );
    }
}

export default ReportingDataVizReportPageControlPanelView;
