import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';

import { showNoteDetailsModal } from 'State/modal';
import CellWithEllipsisView from './CellWithEllipsisView';

import './CellWithEllipsis.scss';

const ROW_HEIGHT = 19;
const ROW_COUNT = 2;

const mapDispatchToProps = {
    showNoteDetailsModal
};

const CellWithEllipsis = props => {
    const { value, className, additionalData: { withTitle = true } = {} } = props;
    const [cellClassName, setCellClassName] = useState(className);
    const [elem, setElem] = useState();
    const getRef = useCallback(ref => setElem(ref), []);
    const handleNoteTextClick = useCallback(() => {
        props.showNoteDetailsModal(props.row);
    });

    useEffect(() => {
        const elemHeight = elem && elem.getBoundingClientRect().height;

        if (elemHeight > ROW_HEIGHT * ROW_COUNT) {
            setCellClassName(cn(className, 'multi-row-ellipsis'));
        } else if (cellClassName !== className) {
            setCellClassName(className);
        }
    });

    return (
        <CellWithEllipsisView
            value={value}
            cellClassName={cellClassName}
            getRef={getRef}
            withTitle={withTitle}
            onNoteTextClick={handleNoteTextClick}/>
    );
};

CellWithEllipsis.propTypes = {
    value: PropTypes.any,
    additionalData: PropTypes.object,
    className: PropTypes.string,
    row: PropTypes.object,
    showNoteDetailsModal: PropTypes.func
};

export default connect(null, mapDispatchToProps)(CellWithEllipsis);

