import sortDirections from 'Constants/sort-directions';

export const columnNames = {
    NAME: 'name',
    TITLE: 'title',
    EMAIL: 'email',
    IC_LAST_LOGIN: 'icLastLogin',
    STATUS: 'status',
    EXTENDED_OI_DFK_ACCESS: 'extendedOiDfkAccess',
    DWAC_LAST_LOGIN: 'dwacLastLogin',
    DWAC_PERMISSION_LEVEL: 'dwacPermissionLevel',
    PROXY_LAST_LOGIN: 'proxyLastLogin',
    SECURITY_NAME: 'securityName',
    IC_LIMITED_ACCESS: 'icLimitedAccess'
};

const columns = [
    {
        columnName: columnNames.NAME,
        order: 1,
        displayName: 'reporting.authorizedUserList.table.name',
        flexGrow: 1,
        manageable: false,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.TITLE,
        order: 2,
        displayName: 'reporting.authorizedUserList.table.title',
        flexGrow: 1,
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.EMAIL,
        order: 3,
        displayName: 'reporting.authorizedUserList.table.email',
        flexGrow: 1,
        manageable: true,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.IC_LAST_LOGIN,
        order: 5,
        displayName: 'reporting.authorizedUserList.table.lastLogin',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        minWidth: 130
    },
    {
        columnName: columnNames.EXTENDED_OI_DFK_ACCESS,
        order: 6,
        displayName: 'reporting.authorizedUserList.table.extendedAccess',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        minWidth: 190
    },
    {
        columnName: columnNames.DWAC_LAST_LOGIN,
        order: 7,
        displayName: 'reporting.authorizedUserList.table.dwacLastLogin',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        minWidth: 130
    },
    {
        columnName: columnNames.DWAC_PERMISSION_LEVEL,
        order: 7,
        displayName: 'reporting.authorizedUserList.table.dwacPermissionLevel',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        minWidth: 150
    },
    {
        columnName: columnNames.PROXY_LAST_LOGIN,
        order: 8,
        displayName: 'reporting.authorizedUserList.table.proxyLastLogin',
        manageable: true,
        initialSortDirection: sortDirections.DESC,
        minWidth: 130
    },
    {
        columnName: columnNames.SECURITY_NAME,
        order: 9,
        displayName: 'reporting.authorizedUserList.table.securityName',
        flexGrow: 1,
        manageable: false,
        initialSortDirection: sortDirections.ASC
    },
    {
        columnName: columnNames.IC_LIMITED_ACCESS,
        order: 10,
        displayName: 'reporting.authorizedUserList.table.icLimitedAccess',
        flexGrow: 1,
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        minWidth: 150
    }
];

export const secondarySorting = {
    field: columnNames.NAME,
    direction: sortDirections.ASC
};

export default {
    columnNames,
    columns
};
