import { round } from 'Utils/utils';

const MIN_BAR_VALUE = 0.01;

const getBarValue = (value, onePercentValue) => {
    const roundValue = round(value / onePercentValue, 2);

    return value && roundValue < MIN_BAR_VALUE ? MIN_BAR_VALUE : roundValue;
};

export function mapBarValuesToData(objArray, key) {
    if (!Array.isArray(objArray)) {
        return [];
    }

    return objArray.map((v) => {
        return { barValue: v[key] > 100 ? 100 : v[key], ...v };
    });
}

export function mapBarValuesToPercent(objArray, key, aggregate) {
    if (!Array.isArray(objArray)) {
        return [];
    }

    const aggr = aggregate[`${key}Total`];
    const onePercentValue = aggr / 100;

    return objArray.map(v => ({ barValue: getBarValue(v[key], onePercentValue), ...v }));
}
