import * as api from '../../api';
import _ from 'lodash';

export const WATCHLIST_LOADING = 'WATCHLIST_LOADING';
export const WATCHLIST_ERROR = 'WATCHLIST_ERROR';
export const WATCHLIST_LOADED = 'WATCHLIST_LOADED';
export const WATCHLIST_CREATING = 'WATCHLIST_CREATING';
export const WATCHLIST_CREATED = 'WATCHLIST_CREATED';
export const WATCHLIST_CREATING_ERROR = 'WATCHLIST_CREATING_ERROR';
export const WATCHLIST_PROCESSING =  'WATCHLIST_PROCESSING';
export const WATCHLIST_PROCESSED = 'WATCHLIST_PROCESSED';
export const WATCHLIST_SHAREHOLDER_REMOVED = 'WATCHLIST_SHAREHOLDER_REMOVED';
export const WATCHLIST_PROCESSING_ERROR = 'WATCHLIST_PROCESSING_ERROR';
export const WATCHLIST_RENAMING = 'WATCHLIST_RENAMING';
export const WATCHLIST_RENAMED = 'WATCHLIST_RENAMED';
export const WATCHLIST_RENAMING_ERROR = 'WATCHLIST_RENAMING_ERROR';
export const WATCHLIST_SELECT_WATCHLIST = 'WATCHLIST_SELECT_WATCHLIST';

export const getUserWatchlists = () => (dispatch) => {
    dispatch({ type: WATCHLIST_LOADING });

    return api.getUserWatchlists()
        .then(({ data }) => {
            const list = _.get(data, 'payload', []);

            dispatch({
                type: WATCHLIST_LOADED,
                payload: list
            });

            return list;
        })
        .catch((e) => {
            dispatch({ type: WATCHLIST_ERROR, payload: e });
        });
};

export const createWatchlist = (name) => (dispatch) => {
    dispatch({ type: WATCHLIST_CREATING });

    return api.createUserWatchlist(name)
        .then(({ data }) => {
            dispatch({ type: WATCHLIST_CREATED, payload: data.payload });

            return data.payload;
        }).catch(e => {
            dispatch({ type: WATCHLIST_CREATING_ERROR, payload: e });
        });
};

export const addShareholderToSelectedWatchlist = ({ shareholderId, shareholderTitle, shareholderType }, watchlistIds = []) => (dispatch) => {
    dispatch({ type: WATCHLIST_PROCESSING, payload: { watchlistIds } });

    return api.addShareholderToWatchlist([{ id: shareholderId, name: shareholderTitle, shareholderType }], watchlistIds)
        .then(() => {
            dispatch({ type: WATCHLIST_PROCESSED, payload: { watchlistIds, shareholderId } });
        }).catch((e) => {
            dispatch({ type: WATCHLIST_PROCESSING_ERROR, payload: { watchlistIds } });

            throw e;
        });
};

export const removeShareholderFromWatchlist = (shareholderId, watchlistId) => (dispatch) => {
    dispatch({ type: WATCHLIST_PROCESSING, payload: { watchlistIds: [watchlistId] } });

    return api.removeShareholderFromWatchlist(shareholderId, watchlistId)
        .then(() => {
            dispatch({ type: WATCHLIST_SHAREHOLDER_REMOVED, payload: { watchlistIds: [watchlistId], shareholderId } });
        })
        .catch((e) => {
            dispatch({ type: WATCHLIST_PROCESSING_ERROR, payload: { watchlistIds: [watchlistId] } });

            throw e;
        });
};

export const renameWatchlist = (title, watchlistId) => (dispatch) => {
    dispatch({ type: WATCHLIST_RENAMING, payload: { watchlistId } });

    return api.renameWatchlist(title, watchlistId)
        .then(() => {
            dispatch({ type: WATCHLIST_RENAMED, payload: { watchlistId, title } });
        }).catch(e => {
            dispatch({ type: WATCHLIST_RENAMING_ERROR, payload: { watchlistId } });

            throw e;
        });
};


export const selectWatchlist = (id = null) => (dispatch) => {
    dispatch({ type: WATCHLIST_SELECT_WATCHLIST, payload: { id } });
};
