import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, propTypes as reduxFormPropTypes, SubmissionError } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { mapProps } from '@shakacode/recompose';
import { pageViewEvent } from '../../../../__ga__/events';

import { reportEnhancer } from 'Hoc';
import { filterItemsByUserProductSourcesAndFeatures, scrollTo } from 'Utils/utils';
import * as shareholderListPageSelectors from 'State/reportingShareholderList/selectors';
import { showConfirmExportLargeReportModal } from 'State/modal/actions';
import shareholderTypes from 'Constants/shareholder-types';
import shareholderTypesArray from '../constants/shareholderTypesArray';
import shareTypes from 'Constants/share-types';
import accountStatuses from 'Constants/account-statuses';
import accountTypes from 'Constants/account-types';
import directDepositTypes from 'Constants/direct-deposit-options';
import reportTypes from 'Constants/report-types';
import pdfExportColumnsLimits from 'Constants/pdf-export-columns-limits';
import reportNameErrors from 'Constants/report-name-errors';
import httpStatuses from 'Constants/http-status';
import { addressStatusMap, addressStatusOptions } from '../constants/addressStatusOptions';
import { getReportFormValues, isDisabledColumnType } from '../utils';
import { isSameSelectedSecurities } from 'Utils/securities';
import defaultReportData from 'State/reportingShareholderList/defaultValues';
import { saveShareholderListReportTemplate } from 'State/savedReportTemplates/actions';
import {
    setDefaultTemplate,
    getTemplate,
    changeTemplate
} from 'State/reportingShareholderList/actions';
import * as exportReportsSelectors from 'State/exportReports/selectors';
import { getCountriesAndStates, isFetchingSelector } from 'State/countriesAndStates/selectors';
import { getSecuritiesByIssuerId, getSecuritiesByIdsSelector } from 'State/securities/selectors';
import { getCurrentSecuritySelector } from 'State/user/selectors';
import { getCurrentFeaturesSelector } from 'State/features/selectors';
import Spinner from 'Components/Spinner/Spinner';
import { NoOfRecordsFieldValidation } from '../components/fields/NoOfRecordsField';
import { inputDateRangeValidation } from 'Components/InputDateRangePicker';
import { SharesOwnedFieldValidation } from '../components/fields/SharesOwnedField';
import { ShareholderLocationFieldValidation } from '../components/fields/ShareholderLocationField';
import { reportNameValidation } from '../../common/reportNameUtils';
import ReportingShareholderListPageView from '../components/ReportingShareholderListPageView';
import shareTypeOptions from '../constants/shareTypeOptions';
import accountTypeOptions from '../constants/accountTypeOptions';
import directDepositOptions from '../constants/directDepositOptions';
import accountStatusOptions from '../constants/accountStatusOptions';
import shareholderSortFields from '../constants/shareholderSortFields';
import dateOfLastContactOptions from '../constants/dateOfLastContactOptions';
import columnsToDisplayOptions from '../constants/columnsToDisplayOptions';
import shareholderLocationOptions from '../constants/shareholderLocationOptions';
import fieldList from '../constants/fieldList';
import featureTypes from 'Constants/feature-types';
import { dayOfMonthValidation } from 'Components/ReportScheduler/validation';
import reportExportFormats from 'Constants/export-report-formats';

const MARGIN_TOP = 24;
const MAX_SELECTED_SECURITIES_COUNT = 50;
const FORM_NAME = 'reportingShareholderListFrom';
const formValuesSelector = getFormValues(FORM_NAME);

const mapStateToProps = (state) => ({
    formValues: formValuesSelector(state),
    initialValues: shareholderListPageSelectors.getInitialValuesSelector(state),
    templateData: shareholderListPageSelectors.getReportDataSelector(state),
    isEditMode: shareholderListPageSelectors.isEditModeSelector(state),
    isFetching: shareholderListPageSelectors.isFetchingSelector(state),
    isReportFetching: exportReportsSelectors.isFetchingSelector(state),
    formatFetchingReport: exportReportsSelectors.formatFetchingReportSelector(state),
    isCountriesFetching: isFetchingSelector(state),
    countriesAndStates: getCountriesAndStates(state),
    getAvailableSecuritiesByIssuerId: getSecuritiesByIssuerId(state),
    getAvailableSecuritiesByIds: getSecuritiesByIdsSelector(state),
    currentSecurity: getCurrentSecuritySelector(state),
    currentFeatures: getCurrentFeaturesSelector(state)
});
const mapDispatchToProps = {
    showConfirmExportLargeReportModal,
    saveReportTemplate: saveShareholderListReportTemplate,
    getTemplate,
    changeTemplate,
    setDefaultTemplate
};

@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: (values) => {
        const errors = {};

        errors.noOfRecords = NoOfRecordsFieldValidation(values.noOfRecords);
        errors.sharesOwned = SharesOwnedFieldValidation(values.sharesOwned);
        errors.dateRange = inputDateRangeValidation({ ...values.dateRange, subtractDays: 1 },
            { isRequired: true });
        errors.shareholderLocation = ShareholderLocationFieldValidation(values.shareholderLocation);
        errors.formReportName = reportNameValidation(values.formReportName);

        _.set(errors, 'schedule.scheduleParam', dayOfMonthValidation(values));

        return errors;
    },
    onSubmitFail: (errors) => {
        const errorNames = _.keys(errors);
        const firstError = _.find(fieldList, field => errorNames.includes(field));
        const el = document.querySelector(`[name="${firstError}"]`);

        scrollTo(el, MARGIN_TOP);
    }
})
@injectIntl
@reportEnhancer
@mapProps(props => {
    const selectedSecurities = props.getAvailableSecuritiesByIds(_.get(props.formValues, 'securities', []))
        .filter(s => s.features[featureTypes.SHAREHOLDER_LIST]);
    const sourceTypesSet = new Set([].concat(..._.map(selectedSecurities, s => _.get(s, 'sourceTypes', []))));
    const aggregatedInfo = {
        features: _.merge({}, ..._.map(selectedSecurities, s => s.features)),
        productSources: [...sourceTypesSet],
        isSchedulerAvailable: selectedSecurities
            .filter(s => s.features[featureTypes.SCHEDULED_REPORTS]).length === selectedSecurities.length
    };
    const shareholderTypeOptions = filterItemsByUserProductSourcesAndFeatures(
        shareholderTypesArray, aggregatedInfo.productSources, aggregatedInfo.features)
        .map((item) => ({
            ...item,
            className: item.value,
            label: props.intl.formatMessage({ id: item.label })
        }));

    return {
        ...props,
        aggregatedInfo,
        shareholderTypeOptions
    };
})
class ReportingShareholderListPage extends Component {
    static propTypes = {
        ...reduxFormPropTypes,
        onSubmit: PropTypes.func.isRequired,
        onGetPDF: PropTypes.func.isRequired,
        onGetExcel: PropTypes.func.isRequired,
        showConfirmExportLargeReportModal: PropTypes.func.isRequired,
        isEditMode: PropTypes.bool,
        intl: intlShape,
        getAvailableSecuritiesByIssuerId: PropTypes.func,
        currentSecurity: PropTypes.object,
        aggregatedInfo: PropTypes.object,
        shareholderTypeOptions: PropTypes.array
    };

    constructor(props) {
        super(props);
        const {
            intl: { formatMessage },
            currentSecurity,
            getAvailableSecuritiesByIssuerId
        } = props;
        const dateRangeMaxDate = moment().add(-1, 'day');

        this.state = {
            securitiesOptions: {
                currentSecurity: {
                    ...currentSecurity,
                    label: currentSecurity.name,
                    value: currentSecurity.id.toString()
                },
                availableSecurities: getAvailableSecuritiesByIssuerId(currentSecurity.issuerId)
                    .filter(s => s.features[featureTypes.SHAREHOLDER_LIST]),
                maxSelectedSecuritiesCount: MAX_SELECTED_SECURITIES_COUNT
            },
            shareTypeOptions: shareTypeOptions.map((item) => ({
                ...item,
                label: formatMessage({ id: item.label })
            })),
            accountTypeOptions: _.map(accountTypeOptions, columnOptions => (
                columnOptions.map((item) => ({
                    ...item,
                    label: formatMessage({ id: item.label })
                }))
            )).filter(column => column.length),
            accountStatusOptions: accountStatusOptions.map((item) => ({
                ...item,
                label: formatMessage({ id: item.label })
            })),
            directDepositOptions: directDepositOptions.map((item) => ({
                ...item,
                label: formatMessage({ id: item.label })
            })),
            dateRangeOptions: {
                checkboxOptions: {
                    label: formatMessage({ id: 'datePicker.dateRange' }),
                    name: 'dateRange.checked',
                    disabled: false
                },
                startDateOptions: {
                    label: formatMessage({ id: 'datePicker.from' }),
                    name: 'dateRange.startDate',
                    maxDate: dateRangeMaxDate,
                    isCurrentDateAvailable: false
                },
                endDateOptions: {
                    label: formatMessage({ id: 'datePicker.to' }),
                    name: 'dateRange.endDate',
                    maxDate: dateRangeMaxDate,
                    isCurrentDateAvailable: false
                },
                formName: _.get(this.props, 'form')
            },
            sharesOwnedOptions: {
                checkbox: formatMessage({ id: 'shareholder.list.sharesOwned' }),
                min: formatMessage({ id: 'shareholder.list.sharesOwned.min' }),
                max: formatMessage({ id: 'shareholder.list.sharesOwned.max' }),
                disabled: false,
                formName: _.get(this.props, 'form')
            },
            dateOfLastContactOptions: {
                checkbox: formatMessage({ id: 'shareholder.list.dateOfLastContact' }),
                options: dateOfLastContactOptions.map(option => ({
                    ...option,
                    label: formatMessage({ id: option.label })
                })),
                disabled: false,
                formName: _.get(this.props, 'form')
            },
            addressStatusOptions: {
                label: formatMessage({ id: 'shareholder.list.addressStatus' }),
                options: addressStatusOptions.map(option => ({
                    ...option,
                    label: formatMessage({ id: option.label })
                })),
                formName: _.get(this.props, 'form')
            },
            columnsToDisplayOptions: _.mapValues(columnsToDisplayOptions, options => ({
                ...options,
                label: formatMessage({ id: options.label }),
                values: options.values.map(val => {
                    if (val.options) {
                        return ({
                            ...val,
                            options: val.options.map(option => ({
                                ...option,
                                label: formatMessage({ id: option.label })
                            }))
                        });
                    }
                    return ({
                        ...val,
                        label: formatMessage({ id: val.label })
                    });
                })
            })),
            shareholderSortFields: shareholderSortFields
                .map(option => ({
                    ...option,
                    label: formatMessage({ id: option.label }),
                    sortDirections: option.sortDirections.map(direction => ({
                        ...direction,
                        label: formatMessage({ id: direction.label })
                    }))
                })),
            shareholderLocationOptions
        };
    }

    componentDidMount() {
        pageViewEvent('shareholderList');
    }

    componentDidUpdate(prevProps) {
        const { formValues, change, shareholderTypeOptions, dirty } = this.props;
        const { formValues: prevFormValues } = prevProps;
        const newSecurities = _.get(formValues, 'securities');
        const prevSecurities = _.get(prevFormValues, 'securities');

        if (dirty && !isSameSelectedSecurities(newSecurities, prevSecurities)) {
            change('shareholderType', shareholderTypeOptions[0].value);

            this.handleChangeShareholderType(null, shareholderTypeOptions[0].value);
        }
    }

    handleSubmit = (formValues) => {
        const {
            onSubmit,
            isEditMode,
            countriesAndStates,
            intl: { formatMessage },
            shareholderTypeOptions,
            aggregatedInfo
        } = this.props;
        const reportFormValues = getReportFormValues(formValues, countriesAndStates, shareholderTypeOptions,
            aggregatedInfo.isSchedulerAvailable);

        if (!isEditMode) return onSubmit(reportFormValues, { isSchedulerAvailable: aggregatedInfo.isSchedulerAvailable });

        return onSubmit(reportFormValues).then(() => toastr.success(
            formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.title' }),
            formatMessage({ id: 'reporting.dataVizReport.notifier.changeSaved.message' })
        ), this.errorSaveTemplateHandler);
    };

    handleGetExcel = (e) => {
        const { formValues, onGetExcel, countriesAndStates, shareholderTypeOptions } = this.props;

        onGetExcel(e, { formValues: getReportFormValues(formValues, countriesAndStates, shareholderTypeOptions) });
    };

    handleGetPDF = (e) => {
        const { formValues, onGetPDF, countriesAndStates, shareholderTypeOptions } = this.props;
        const columnsCount = _.reduce(formValues.columnsToDisplay, (result, columns) => {
            return result + columns.length;
        }, 0);

        return columnsCount > pdfExportColumnsLimits[reportTypes.shareholderListTemplate]
            ? this.props.showConfirmExportLargeReportModal(() => onGetPDF(e,
                { formValues: getReportFormValues(formValues, countriesAndStates, shareholderTypeOptions) }))
            : onGetPDF(e, { formValues: getReportFormValues(formValues, countriesAndStates, shareholderTypeOptions) });
    };

    handleChangeShareholderType = (event, newValue, prevValue) => {
        if (newValue !== shareholderTypes.REGISTERED) {
            this.props.change('accountStatus', accountStatuses.OPEN_ONLY);
            this.props.change('directDeposit', null);
            this.props.change('shareType', shareTypes.ALL);
            this.props.change('accountType', accountTypes.ALL);
            this.props.change('dateRange', {
                checked: false,
                startDate: null,
                endDate: null
            });
            this.props.change('sharesOwned', {
                checked: false,
                min: null,
                max: null
            });
            this.props.change('dateOfLastContact.checked', false);
            this.props.change('dateOfLastContact.period', null);
            this.props.change('addressStatus', undefined);
        } else if (prevValue !== shareholderTypes.REGISTERED) {
            this.props.change('accountStatus', accountStatuses.OPEN_ONLY);
            this.props.change('directDeposit', directDepositTypes.ALL);
            this.props.change('addressStatus', addressStatusMap.ALL);
        }
    };

    handleChangeColumnsToDisplayAllSection = (event, newValues, prevValues) => {
        if (newValues.length < prevValues.length) { // it means user uncheck column
            const visibleFields = this.getShareholderVisibleSortFields(newValues);
            const selectedSortField = this.props.formValues.sortCriteria.sortField;

            if (!_.some(visibleFields, field => field.id === selectedSortField)) {
                this.props.change('sortCriteria', defaultReportData.form.sortCriteria);
            }
        }
    };

    handleSecuritiesChange = selectedSecurities => {
        const { change } = this.props;
        const selectedSecurityIds = selectedSecurities.map(s => s.id);

        change('securities', selectedSecurityIds);
        change('format', reportExportFormats.EXCEL);
    };

    getShareTypeOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');
        const hasOIPlusFeature = this.props.aggregatedInfo.features[featureTypes.OI_PLUS_OPTIONS];

        if (shareholderType && hasOIPlusFeature) {
            return this.state.shareTypeOptions.map((item) => ({
                ...item,
                disabled: shareholderType !== shareholderTypes.REGISTERED ? item.value !== shareTypes.ALL : false
            }));
        }
        return this.state.shareTypeOptions;
    };

    getDateRangeOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return {
                ...this.state.dateRangeOptions,
                checkboxOptions: {
                    ...this.state.dateRangeOptions.checkboxOptions,
                    disabled: shareholderType !== shareholderTypes.REGISTERED
                }
            };
        }
        return this.state.dateRangeOptions;
    };

    getSharesOwnedOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return {
                ...this.state.sharesOwnedOptions,
                disabled: shareholderType !== shareholderTypes.REGISTERED
            };
        }
        return this.state.sharesOwnedOptions;
    };

    getAccountTypeOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return this.state.accountTypeOptions.map(column => column.map(item => ({
                ...item,
                disabled: shareholderType !== shareholderTypes.REGISTERED
                    ? item.value !== accountTypes.ALL
                    : false
            })));
        }
        return this.state.accountTypeOptions;
    };

    getAccountStatusOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return this.state.accountStatusOptions.map((item) => ({
                ...item,
                disabled: shareholderType !== shareholderTypes.REGISTERED
                    ? item.value !== accountStatuses.OPEN_ONLY
                    : false
            }));
        }
        return this.state.accountStatusOptions;
    };

    getDirectDepositOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return this.state.directDepositOptions.map((item) => ({
                ...item,
                disabled: shareholderType !== shareholderTypes.REGISTERED
            }));
        }
        return this.state.directDepositOptions;
    };

    getDateOfLastContactOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return {
                ...this.state.dateOfLastContactOptions,
                disabled: shareholderType !== shareholderTypes.REGISTERED
            };
        }
        return this.state.dateOfLastContactOptions;
    };

    getAddressStatusOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return {
                ...this.state.addressStatusOptions,
                disabled: shareholderType !== shareholderTypes.REGISTERED
            };
        }
        return this.state.addressStatusOptions;
    };

    getColumnsToDisplayOptions = () => {
        const shareholderType = _.get(this.props.formValues, 'shareholderType');

        if (shareholderType) {
            return _.mapValues(this.state.columnsToDisplayOptions, options => ({
                ...options,
                disabled: isDisabledColumnType(options.shareholderType, shareholderType)
            }));
        }

        return this.state.columnsToDisplayOptions;
    };

    getShareholderVisibleSortFields = (selectedColumns) => {
        if (selectedColumns) {
            return this.state.shareholderSortFields.filter(field =>
                !field.enabledOn || selectedColumns.indexOf(field.enabledOn) > -1);
        }

        return this.state.shareholderSortFields;
    };

    getShareholderLocationOptions = () => {
        const { labels, showForeignAvailableFor } = this.state.shareholderLocationOptions;
        const { intl: { formatMessage } } = this.props;

        return {
            labels: _.mapValues(labels, label => formatMessage({ id: label })),
            showForeignAvailableFor
        };
    };

    errorSaveTemplateHandler = (error) => {
        const { intl: { formatMessage } } = this.props;
        const response = _.get(error, 'response', {});
        const reason = _.get(response, 'data.reasonType', '');
        const errorMessage = reportNameErrors[reason];

        if (response.status === httpStatuses.HTTP_400 && errorMessage) {
            throw new SubmissionError({ formReportName: formatMessage({ id: errorMessage }) });
        }
    };

    render() {
        const { isFetching } = this.props;
        const selectedColumnsInAllSection = _.get(this.props.formValues, 'columnsToDisplay.ALL');
        const selectedSecurities = _.get(this.props.formValues, 'securities', []);
        const isMultiSecurityReport = selectedSecurities.length > 1;

        return (
            <div>
                {isFetching
                    ? <Spinner isFetching={isFetching} isBlocker />
                    : <ReportingShareholderListPageView
                        {...this.props}
                        selectedSecurities={selectedSecurities}
                        onSecuritiesChange={this.handleSecuritiesChange}
                        securitiesOptions={this.state.securitiesOptions}
                        shareTypeOptions={this.getShareTypeOptions()}
                        accountTypeOptions={this.getAccountTypeOptions()}
                        accountStatusOptions={this.getAccountStatusOptions()}
                        directDepositOptions={this.getDirectDepositOptions()}
                        dateRangeOptions={this.getDateRangeOptions()}
                        sharesOwnedOptions={this.getSharesOwnedOptions()}
                        dateOfLastContactOptions={this.getDateOfLastContactOptions()}
                        addressStatusOptions={this.getAddressStatusOptions()}
                        columnsToDisplayOptions={this.getColumnsToDisplayOptions()}
                        shareholderSortFields={this.getShareholderVisibleSortFields(selectedColumnsInAllSection)}
                        shareholderLocationOptions={this.getShareholderLocationOptions()}
                        onChangeShareholderType={this.handleChangeShareholderType}
                        onChangeColumnsToDisplayAllSection={this.handleChangeColumnsToDisplayAllSection}
                        onGetExcel={this.handleGetExcel}
                        onGetPDF={this.handleGetPDF}
                        onSubmit={this.handleSubmit}
                        isMultiSecurityReport={isMultiSecurityReport} />
                }
            </div>
        );
    }
}

export default ReportingShareholderListPage;
