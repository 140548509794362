import { createSelector } from 'reselect';
import _ from 'lodash';

import { getSecuritiesByIdsSelector } from '../securities/selectors';
import { getCurrentSecuritySelector } from 'State/user';
import availableFieldsDefinition from '../../pages/reporting/ReportingShareholderListPage/constants/availableFieldsDefinition';
import accountStatuses from 'Constants/account-statuses';
import directDepositOptions from 'Constants/direct-deposit-options';
import { columnsDefinitions } from 'Pages/reporting/ReportingShareholderListPage/constants/columnsToDisplayOptions';

const HOLDER_TYPE = 'holderType';

export const getReportDataSelector = state => _.get(state.reportingShareholderList, 'reportData');
export const isFetchingSelector = state => _.get(state.reportingShareholderList, 'isFetching', false);

export const isEditModeSelector = createSelector(
    getReportDataSelector,
    reportData => !!_.get(reportData, 'reportTemplateId')
);

export const getInitialValuesSelector = createSelector(
    getReportDataSelector,
    getCurrentSecuritySelector,
    getSecuritiesByIdsSelector,
    (reportData = {}, currentSecurity, getSecuritiesByIds) => {
        const reportTemplate = reportData.form;

        if (!_.isNil(reportTemplate)) {
            const newReportTemplate = { ...reportTemplate };
            const selectedSecurities = getSecuritiesByIds(newReportTemplate.securities);
            const securities = selectedSecurities.length > 0 ? selectedSecurities : [currentSecurity];
            const sourceTypesSet = new Set([].concat(..._.map(securities, s => _.get(s, 'sourceTypes', []))));
            const aggregatedInfo = {
                features: _.merge({}, ..._.map(securities, s => s.features)),
                productSources: [...sourceTypesSet]
            };

            if (!selectedSecurities.length) {
                newReportTemplate.securities = [currentSecurity.id];
            }

            const pickedFields = _.pickBy(newReportTemplate, (value, key) => {
                const fieldProducts = availableFieldsDefinition[key];

                return fieldProducts && !![
                    ..._.intersection(fieldProducts, aggregatedInfo.productSources),
                    ..._.filter(fieldProducts, feature => aggregatedInfo.features[feature])
                ].length;
            });

            if (!pickedFields.shareholderType) {
                pickedFields.accountStatus = accountStatuses.OPEN_ONLY;
                pickedFields.directDeposit = directDepositOptions.ALL;
            }

            if (pickedFields.columnsToDisplay) {
                const groupedColDef = _.groupBy(columnsDefinitions.filter(column => _.includes(pickedFields.columnsToDisplay, column.columnName)),
                    HOLDER_TYPE);

                pickedFields.columnsToDisplay = _.mapValues(groupedColDef, columnsDefs => _.map(columnsDefs, column => column.columnName));
            }

            return pickedFields;
        }
        return reportTemplate;
    }
);
