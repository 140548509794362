import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getContext } from '@shakacode/recompose';
import { connect } from 'react-redux';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import WidgetAddToReport from 'Components/widgets/Widget/components/WidgetAddToReport';
import WidgetRemove from 'Components/widgets/Widget/components/WidgetRemove';
import WidgetPrint from 'Components/widgets/Widget/components/WidgetPrint';
import { getDynamicPageBlockActionSelector } from 'State/dynamicPage/selectors';
import featureTypes from 'Constants/feature-types';

const mapStateToProps = state => ({
    blockAction: getDynamicPageBlockActionSelector(state)
});

@connect(mapStateToProps)
@getContext({
    events: PropTypes.object
})
class InstitutionProfileWidgetControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        blockAction: PropTypes.bool
    };

    render() {
        const {
            isDataViz,
            blockAction,
            widgetType,
            onAddWidgetToDataVizReport,
            events: { onBuildDataParams } = {},
            onRemoveWidget,
            currentFeatures
        } = this.props;

        return (
            <div className='widget-control-panel'>
                {isDataViz
                    ? <WidgetRemove
                        blockAction={blockAction}
                        onRemoveWidget={onRemoveWidget}/>
                    : (<div>
                        <WidgetPrint
                            widgetType={widgetType}/>
                        {currentFeatures[featureTypes.CUSTOM_REPORTS] &&
                        <WidgetAddToReport
                            onAddToReport={onAddWidgetToDataVizReport}
                            dataParamsBuilder={onBuildDataParams}/>}
                    </div>)}
            </div>
        );
    }
}

export default InstitutionProfileWidgetControlPanel;
