import sortDirections from 'Constants/sort-directions';

const columnNames = {
    DATE: 'date',
    OPEN: 'open',
    CLOSE: 'close',
    HIGH: 'high',
    LOW: 'low',
    VOLUME: 'volume'
};

const columns = [
    {
        columnName: columnNames.DATE,
        order: 1,
        displayName: 'performance.table.names.date',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.OPEN,
        order: 2,
        displayName: 'performance.table.names.open',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.CLOSE,
        order: 3,
        displayName: 'performance.table.names.close',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.HIGH,
        order: 4,
        displayName: 'performance.table.names.high',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.LOW,
        order: 5,
        displayName: 'performance.table.names.low',
        initialSortDirection: sortDirections.DESC
    },
    {
        columnName: columnNames.VOLUME,
        order: 6,
        displayName: 'performance.table.names.volume',
        initialSortDirection: sortDirections.DESC
    }
];

export {
    columnNames,
    columns
};
