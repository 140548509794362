import { createSelector } from 'reselect';
import _ from 'lodash';

import * as widgetUtils from '../utils';

export const getWidgetDataValues = props => _.get(props.widgetData, 'values');

export const getDates = createSelector(
    getWidgetDataValues,
    (values) => values && _.map(values, 'date'));

export const getSelectedDate = createSelector(
    getDates,
    props => props,
    widgetUtils.getSelectedDate);

export const getSelectedContactYear = props => _.get(props.widgetSettings, 'contactYears');

export const getFilteredData = createSelector(
    getWidgetDataValues,
    getSelectedDate,
    getSelectedContactYear,
    (values, selectedDate, selectedContactYear) => {
        const filteredDataByDate = _.find(values, value => value.date === selectedDate) || {};
        const filteredDataByContactYear = _.find(filteredDataByDate.values, value => value.contactYears === selectedContactYear);

        return filteredDataByContactYear;
    }
);
