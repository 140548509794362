import _ from 'lodash';

import { successSaveNewReport } from 'State/savedReportTemplates/actions';
import defaultValues from './defaultValues';
import { getReportTemplate, changeReportTemplate } from '../../api/reportTemplate';
import scheduleTypes from 'Components/ReportScheduler/constants/schedule-types';

export const REPORTING_SHAREHOLDER_LIST_INITIAL = 'REPORTING_SHAREHOLDER_LIST_INITIAL';
export const REPORTING_SHAREHOLDER_LIST_SET_FETCHING = 'REPORTING_SHAREHOLDER_LIST_SET_FETCHING';
export const REPORTING_SHAREHOLDER_LIST_INITIAL_ERROR = 'REPORTING_SHAREHOLDER_LIST_INITIAL_ERROR';
export const REPORTING_SHAREHOLDER_LIST_CHANGE_REQUEST = 'REPORTING_SHAREHOLDER_LIST_CHANGE_REQUEST';
export const REPORTING_SHAREHOLDER_LIST_CHANGE_SUCCESS = 'REPORTING_SHAREHOLDER_LIST_CHANGE_SUCCESS';
export const REPORTING_SHAREHOLDER_LIST_CHANGE_ERROR = 'REPORTING_SHAREHOLDER_LIST_CHANGE_ERROR';

export const reportingShareholderListSetFetching = isFetching => ({
    type: REPORTING_SHAREHOLDER_LIST_SET_FETCHING,
    payload: isFetching
});

export const reportingShareholderListChangeSetChanging = isChanging => ({
    type: REPORTING_SHAREHOLDER_LIST_CHANGE_REQUEST,
    payload: isChanging
});

export const setDefaultTemplate = () => ({
    type: REPORTING_SHAREHOLDER_LIST_INITIAL,
    payload: defaultValues
});

const getFormPayload = (templateValues) => {
    const formValues = _.get(templateValues, 'form', {});

    return {
        ...templateValues,
        form: {
            ...formValues,
            accountStatus: formValues.accountStatus,
            formReportName: templateValues.reportName,
            shareholderLocation: {
                ...formValues.shareholderLocation,
                country: _.get(formValues.shareholderLocation, 'country.abbreviation'),
                state: _.get(formValues.shareholderLocation, 'state.abbreviation')
            },
            isScheduled: !!_.get(formValues, 'schedule.scheduleType'),
            isLastDayOfMonth: _.get(formValues, 'schedule.scheduleType') === scheduleTypes.MONTHLY_LAST_DAY,
            schedule: {
                scheduleParam: _.get(formValues, 'schedule.scheduleParam'),
                scheduleType: _.get(formValues, 'schedule.scheduleType') === scheduleTypes.MONTHLY_LAST_DAY
                    ? scheduleTypes.MONTHLY : _.get(formValues, 'schedule.scheduleType')
            }
        }
    };
};

export const getTemplate = (reportTemplateId) => dispatch => {
    dispatch(reportingShareholderListSetFetching(true));

    return getReportTemplate(reportTemplateId)
        .then(res => {
            const reportTemplate = _.get(res, 'data.payload.template', {});

            dispatch(reportingShareholderListSetFetching(false));
            dispatch({
                type: REPORTING_SHAREHOLDER_LIST_INITIAL,
                payload: getFormPayload(reportTemplate)
            });
        }, error => {
            dispatch(reportingShareholderListSetFetching(false));
            dispatch({
                type: REPORTING_SHAREHOLDER_LIST_INITIAL_ERROR,
                payload: error.message,
                error: true
            });

            return Promise.reject(error);
        });
};

export const changeTemplate = reportTemplate => dispatch => {
    dispatch(reportingShareholderListChangeSetChanging(true));

    return changeReportTemplate(reportTemplate)
        .then(() => {
            dispatch(reportingShareholderListChangeSetChanging(false));
            dispatch({
                type: REPORTING_SHAREHOLDER_LIST_CHANGE_SUCCESS,
                payload: getFormPayload(reportTemplate)
            });
            dispatch(successSaveNewReport({
                ...reportTemplate,
                securityCount: _.get(reportTemplate.form, 'securities.length', 0)
            }));
        }, error => {
            dispatch(reportingShareholderListChangeSetChanging(false));
            dispatch({
                type: REPORTING_SHAREHOLDER_LIST_CHANGE_ERROR,
                payload: error.message,
                error: true
            });
            return Promise.reject(error);
        });
};
