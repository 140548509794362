import { createReducer } from 'Utils/reducer';
import { SET_APP_VERSION } from './actions';

const initialState = {
    feVersion: null,
    beVersion: null,
    jsonSchemaVersion: null
};

const actionHandlers = {
    [SET_APP_VERSION]: (state, { payload: { feVersion, beVersion, jsonSchemaVersion } }) => {
        return {
            ...state,
            feVersion,
            beVersion,
            jsonSchemaVersion
        };
    }
};

export default createReducer(initialState, actionHandlers);
