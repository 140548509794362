import PropTypes from 'prop-types';
import React from 'react';
import { mapProps } from '@shakacode/recompose';
import _ from 'lodash';
import cn from 'classnames';

import { commonWidgetPropTypes, commonWidgetActions } from '../../../utils';
import Icon from 'Components/Icon';

import './NotesControlPanel.scss';

const NotesControlPanel = props => {
    const {
        onExport,
        inactive,
        className = 'notes-control-panel'
    } = props;
    const exportIconClass = cn('font-icon font-icon-download', { inactive });

    return (
        <div className={className}>
            <Icon
                className={exportIconClass}
                onClick={inactive ? null : onExport}/>
        </div>
    );
};

NotesControlPanel.propTypes = {
    ...commonWidgetActions,
    ...commonWidgetPropTypes,
    widgetControlPanelSettings: PropTypes.shape({
        onExport: PropTypes.func,
        inactive: PropTypes.bool,
        className: PropTypes.string
    })
};

export default mapProps(props => ({
    ...props,
    onExport: _.get(props, 'widgetControlPanelSettings.onExport'),
    inactive: _.get(props, 'widgetControlPanelSettings.inactive'),
    className: _.get(props, 'widgetControlPanelSettings.className')
}))(NotesControlPanel);
