import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import cancelTokensStorage from 'Context/cancelTokensStorage';
import { withRequireFeature } from 'Routes/hoc';
import withErrorBoundary from 'Hoc/withErrorBoundary';

@withErrorBoundary
@withRequireFeature
class BaseRoute extends Component {
    static propTypes = {
        location: PropTypes.object,
        component: PropTypes.func,
        path: PropTypes.string,
        layout: PropTypes.func
    };

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            cancelTokensStorage.cancelAll();
        }
    }

    componentWillUnmount() {
        cancelTokensStorage.cancelAll();
    }

    render() {
        const { layout: Layout, ...props } = this.props;

        return (
            Layout
                ? <Layout>
                    <Route {...props}/>
                </Layout>
                : <Route {...props}/>
        );
    }
}

export default BaseRoute;
