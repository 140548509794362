import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withProps } from '@shakacode/recompose';
import _ from 'lodash';

import { TableEasy } from 'Components/Tables';
import { commonWidgetPropTypes, commonWidgetActions } from '../../../utils';
import { CellWithMktValue } from 'Components/widgets/_commonComponents/TableCellComponents';
import { /* PercentFormatter, */ NullableFormatter } from '../../../../formatters';
import NoDataComponent from '../../../Widget/components/NoDataComponent';

const SELLS = 'SELLS';
const PURCHASES = 'PURCHASES';

@withProps((props) => {
    const formatMessage = props.intl.formatMessage;

    const widgetData = _.get(props.widgetData, 'data', []);
    const purchasesData = _.find(widgetData, item => item.type === PURCHASES);
    const sellsData = _.find(widgetData, item => item.type === SELLS);

    const purchasesValues = _.get(purchasesData, 'values', []);
    const purchasesAggregate = _.get(purchasesData, 'aggregate', {});
    const sellsValues = _.get(sellsData, 'values', []);
    const sellsAggregate = _.get(sellsData, 'aggregate', {});

    return {
        values: [
            {
                name: formatMessage({ id: 'shareholders.buys.and.sells.largest.purchases' }),
                portfolio: purchasesAggregate.portfolioTotal,
                mktValChg: purchasesAggregate.mktValChgTotal,
                rowClassName: 'grouped-row'
            },
            ...purchasesValues,
            {
                name: formatMessage({ id: 'shareholders.buys.and.sells.largest.sells' }),
                portfolio: sellsAggregate.portfolioTotal,
                mktValChg: sellsAggregate.mktValChgTotal,
                rowClassName: 'grouped-row'
            },
            ...sellsValues
        ]
    };
})
class ShareholderBuysAndSellsContent extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        intl: PropTypes.object,
        widgetNoData: PropTypes.bool,
        widgetData: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.formatMessage = props.intl.formatMessage;
        this.columns = [
            {
                dataKey: 'name',
                bodyCellComponent: (properties) => <NullableFormatter withTitle {...properties}/>
            },
            // {
            //     dataKey: 'portfolio',
            //     title: this.formatMessage({ id: 'shareholders.buys.and.sells.percent' }),
            //     columnClassName: 'text-right',
            //     bodyCellComponent: (properties) => <PercentFormatter withThreshold {...properties}/>,
            //     flexShrink: 0
            // },
            {
                dataKey: 'mktValChg',
                title: this.formatMessage({ id: 'shareholders.buys.and.sells.mktVal' }),
                columnClassName: 'text-right',
                bodyCellComponent: (properties) => <CellWithMktValue {...properties}/>,
                flexShrink: 0
            }
        ];
    }

    render() {
        const { values, isFetching, widgetNoData } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    <TableEasy
                        className='widget-table'
                        columns={this.columns}
                        showAllRows
                        data={values}/>
                </NoDataComponent>
            </div>
        );
    }
}

export default ShareholderBuysAndSellsContent;
