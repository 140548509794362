import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { commonSortDescendingIcon, commonSortAscendingIcon } from 'Components/Tables/components/commonSortIcons';

// from react table
// https://github.com/react-tools/react-table/blob/e3528fef38937e536784455442ec718f77c4d5d6/src/index.js#L369
const SORT_DESC_CLASSNAME = '-sort-desc';
const SORT_ASC_CLASSNAME = '-sort-asc';

const ThComponent = ({ style, children, className, toggleSort, ...rest }) => {
    let desc = null;

    if (className) {
        if (className.includes(SORT_DESC_CLASSNAME)) {
            desc = true;
        }
        if (className.includes(SORT_ASC_CLASSNAME)) {
            desc = false;
        }
    }
    const sorted = desc !== null;

    return (
        <div
            className={cn('rt-th', className)}
            style={style}
            onClick={toggleSort}>
            {React.isValidElement(children)
                ? React.cloneElement(children, {
                    ...rest
                })
                : children}
            {sorted && desc && commonSortDescendingIcon}
            {sorted && !desc && commonSortAscendingIcon}
        </div>
    );
};

ThComponent.propTypes = {
    style: PropTypes.object,
    toggleSort: PropTypes.func,
    getSortCriteria: PropTypes.func,
    column: PropTypes.shape({
        sortCriteria: PropTypes.shape({
            sortField: PropTypes.string.isRequired,
            sortColumn: PropTypes.string
        }).isRequired
    }),
    children: PropTypes.any,
    className: PropTypes.string
};

export default ThComponent;
