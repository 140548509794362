export default {
    fullPagePayload: 'full-page',
    getDataVizPayload: 'get-dataviz-page',
    addToReportPayload: 'add-to-report',
    changeLayoutPayload: 'change-layout',
    widgetDataPayload: 'widget-data',
    dataVizWidgetDataPayload: 'data-viz-widget-data',
    addWidgetsPayload: 'add-widgets',
    addDataVizWidgetsPayload: 'add-widgets-to-dataviz-report-internally',
    addWidgetsToDataVizReportPayload: 'add-widgets-to-dataviz-report',
    removeWidgetsPayload: 'remove-widgets',
    removeDataVizWidgetsPayload: 'remove-dataviz-widgets',
    updateWidgetsPayload: 'update-widgets',
    updateDataVizWidgetsPayload: 'update-dataviz-widgets',
    searchPayload: 'search',
    searchTreasuryPayload: 'search-treasury',
    getReportTemplatePayload: 'get-report-template',
    getReportTemplatesPayload: 'get-saved-report-list',
    saveReportTemplatePayload: 'save-report-template',
    changeReportTemplatePayload: 'change-report-template',
    deleteReportTemplatePayload: 'delete-report-template',
    saveDataVizTemplatePayload: 'save-dataviz-template',
    deleteDataVizTemplatePayload: 'delete-dataviz-template',
    getReportTemplateUsersPayload: 'get-report-template-users',
    shareReportTemplatePayload: 'share-report-template',
    allCountriesPayload: 'get-all-countries',
    statesPayload: 'get-states',
    getTotalOutstandingSharesByDatePayload: 'get-total-outstanding-shares',
    getLaunchedReportsPayload: 'get-launched-reports',
    deleteLaunchedReportPayload: 'delete-launched-report',
    runReportPayload: 'run-report',
    getReportStatusPayload: 'get-report-status',
    getLaunchedReportGuidPayload: 'get-launched-report-guid',
    getDocumentGuid: 'get-document-guid',
    getUserInfoPayload: 'get-user-info',
    getSecuritiesPayload: 'get-securities',
    getTermsAndConditionsPayload: 'terms-and-conditions',
    changeSecurityPayload: 'change-security',
    acceptUserTerms: 'accept-user',
    getUserNotificationsPayload: 'get-user-notifications',
    changeUserNotificationsPayload: 'change-user-notifications',
    userImage: 'get-user-image',
    peer: 'peer',
    saveLogs: 'save-logs',
    historicalAnalysisShareholders: 'historical-analysis-shareholders',
    peerAnalysisShareholders: 'peer-analysis-shareholders',
    findAnalysisShareholders: 'find-analysis-shareholders',
    getSuggestions: 'get-suggestions',
    getResourcesPayload: 'get-resources',
    checkAccountsSSNPayload: 'check-accounts-ssn',
    combineAccountsPayload: 'combine-accounts',
    getSubscriptionsPayload: 'get-subscriptions',
    changeSubscriptionStatusPayload: 'change-subscription-status',
    changeEmailSubscriptionStatusPayload: 'change-email-subscription-status',
    evictUserCachePayload: 'evict-user-cache',
    manageCachePayload: 'manage-cache',
    getMarketDataPayload: 'get-market-data',
    uploadDocumentPayload: 'upload-document',
    deleteDocumentPayload: 'delete-document',
    acceptAnnouncementPayload: 'accept-announcement',
    getTreasuryAccessPayload: 'get-treasury-access',
    requestAsOfDateReportPayload: 'request-as-of-date-report',
    deleteNotePayload: 'delete-note',
    createNotePayload: 'create-note',
    saveMeetingPlannerIndexes: 'save-meeting-planner-indexes',

    getAlertsPayload: 'get-alerts',
    dismissAlertPayload: 'dismiss-alert',

    loginPayload: 'login',
    logoutPayload: 'logout',

    getUploadRequestTypesPayload: 'get-upload-request-types',
    getUploadRequestListPayload: 'get-upload-request-list',
    getUploadRequestTemplatesPayload: 'get-upload-request-templates',
    createUploadRequestDraftPayload: 'create-upload-request-draft',
    getUploadRequestPayload: 'get-upload-request',
    updateUploadRequestPayload: 'update-upload-request',
    getUploadRequestDocumentsPayload: 'get-upload-request-documents',
    deleteUploadRequestDocumentPayload: 'delete-upload-request-document',
    getSecureUploadDocumentGuidPayload: 'get-secure-upload-document-guid',
    getShareholderRecentSearchPayload: 'get-shareholder-recent-search',
    updateShareholderRecentSearchPayload: 'update-shareholder-recent-search',

    secureUploadEmailRecipients: 'secure-upload-email-recipients'

};
