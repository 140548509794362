import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import _ from 'lodash';

import { createReducer } from 'Utils/reducer';
import {
    SEARCH_FILTER_SET_CONTEXT,
    SEARCH_FILTER_SET_COUNTRY,
    SEARCH_FILTER_SET_ISSUER,
    SEARCH_FILTER_SET_SECURITY,
    SEARCH_FILTER_SET_STATE,
    SEARCH_FILTER_SET_STATUS,
    SEARCH_RESET_SELECTED_ISSUERS_SECURITIES,
    SEARCH_SET_FETCHING,
    SEARCH_SUCCESS,
    TOGGLE_ADVANCED_SEARCH_ISSUER,
    TOGGLE_ADVANCED_SEARCH_SECURITY,
    TOGGLE_ALL_ISSUERS,
    TOGGLE_ALL_SECURITIES,
    UPDATE_SECURITIES_FILTER,
    SET_IS_NEW_GLOBAL_SEARCH_REQUEST
} from './actions';
import { updateFilters, updateTotals } from './utils';
import { CONTEXTS, MAX_SHAREHOLDERS_TO_COMPARE, FILTERS as filterConstants } from 'Constants/search';
import {
    CLEAR_TOGGLED_SHAREHOLDERS_TO_COMPARE,
    SEARCH_MORE_FETCHING,
    SEARCH_MORE_SUCCESS,
    TOGGLE_ALL_SHAREHOLDERS,
    TOGGLE_SHAREHOLDER_TO_COMPARE
} from 'State/advancedSearch/actions';
import { updateStates } from 'State/advancedSearch/utils';

const initialState = {
    issuers: [],
    securities: [],
    filter: '',
    results: [],
    total: null,
    filterTotals: {},
    searchArea: {},
    lastSearch: {
        countIssuers: null,
        query: null,
        pii: null,
        token: null, // only for TAX_ID search
        filterCriteria: {
            statuses: {
                // empty filters for initial state
                // [filterConstants.Open]: false,
                // [filterConstants.Closed]: false
            },
            countries: {
                // empty filters for initial state
                // [filterConstants.US]: false,
                // [filterConstants.Foreign]: false
            },
            state: null,
            contexts: {
                // empty filters for initial state
                // [CONTEXTS.REGISTERED]: false,
                // [CONTEXTS.INSIDERS]: false,
                // [CONTEXTS.INSTITUTIONS]: false,
                // [CONTEXTS.FUNDS]: false,
                // [CONTEXTS.CONTACTS]: false
            }
        }
    },
    fetchingStatus: {
        advanced: false,
        global: false,
        [CONTEXTS.REGISTERED]: false,
        [CONTEXTS.INSIDERS]: false,
        [CONTEXTS.INSTITUTIONS]: false,
        [CONTEXTS.FUNDS]: false,
        [CONTEXTS.CONTACTS]: false
    },
    states: [],
    selectedShareholdersToCompare: [],
    isSearchTreasury: false
};

const actionHandlers = {
    [SEARCH_RESET_SELECTED_ISSUERS_SECURITIES]: (state) => ({
        ...state,
        issuers: initialState.issuers,
        securities: initialState.securities,
        filter: initialState.filter
    }),
    [TOGGLE_ADVANCED_SEARCH_ISSUER]: (state, { payload: { toggleIssuer, value } }) => {
        if (value) {
            const issuers = [...state.issuers, toggleIssuer];

            return {
                ...state,
                issuers,
                securities: _.filter(state.securities, security => _.some(issuers, issuer => issuer.id === security.issuerId))
            };
        }
        return {
            ...state,
            issuers: _.filter(state.issuers, (issuer) => issuer.id !== toggleIssuer.id),
            securities: _.filter(state.securities, (security) => security.issuerId !== toggleIssuer.id)
        };
    },
    [TOGGLE_ALL_ISSUERS]: (state, { payload: { toggleIssuers, value } }) => (
        value ? {
            ...state,
            issuers: toggleIssuers
        } : {
            ...state,
            issuers: [],
            securities: []
        }),
    [TOGGLE_ADVANCED_SEARCH_SECURITY]: (state, { payload: { toggleSecurity, value } }) => (
        value ? {
            ...state,
            securities: [...state.securities, toggleSecurity]
        } : {
            ...state,
            securities: _.filter(state.securities, (security) => security.id !== toggleSecurity.id)
        }
    ),
    [TOGGLE_ALL_SECURITIES]: (state, { payload: { toggleSecurities, value } }) => (
        value ? {
            ...state,
            securities: toggleSecurities
        } : {
            ...state,
            securities: []
        }
    ),
    [UPDATE_SECURITIES_FILTER]: (state, { payload: { filter } }) => ({
        ...state,
        filter
    }),
    [SEARCH_SET_FETCHING]: (state, { payload: { context, status } }) => ({
        ...state,
        fetchingStatus: {
            ...state.fetchingStatus,
            [context]: status
        }
    }),
    [SEARCH_SUCCESS]: (state, {
        payload: {
            results,
            searchArea,
            total,
            totalIssuers,
            totalSecurities,
            query,
            pii,
            token,
            securities,
            issuers,
            needFiltersUpdate,
            isSearchTreasury
        }
    }) => {
        let filterCriteria;
        let filterTotals;
        let filterStates;
        const tIssuers = [];
        const filterIssuers = {};
        const tSecurities = [];
        const filterSecurities = {};

        if (needFiltersUpdate) {
            filterCriteria = updateFilters(results);
            filterTotals = updateTotals(results);
            filterStates = updateStates(results);

            issuers.forEach(issuer => {
                const totalData = _.find(totalIssuers, tIssuer => issuer.id === tIssuer.id);

                if (totalData) {
                    tIssuers.push({
                        ...totalData,
                        ...issuer
                    });
                    filterIssuers[issuer.id] = false;
                }
            });

            securities.forEach(sec => {
                const totalData = _.find(totalSecurities, tSec => sec.id === tSec.id);
                const securityBelongToIssuer = _.find(tIssuers, issuer => issuer.id === sec.issuerId);

                if (totalData && securityBelongToIssuer) {
                    tSecurities.push({
                        ...totalData,
                        ...sec
                    });
                    filterSecurities[sec.id] = false;
                }
            });
        }

        return {
            ...state,
            results,
            searchArea,
            total,
            states: filterStates || state.states,
            filterTotals: {
                ...(filterTotals || state.filterTotals),
                issuers: needFiltersUpdate ? tIssuers : state.filterTotals.issuers,
                securities: needFiltersUpdate ? tSecurities : state.filterTotals.securities
            },
            lastSearch: {
                query, pii, token,
                filterCriteria: {
                    ...(filterCriteria || state.lastSearch.filterCriteria),
                    issuers: needFiltersUpdate ? filterIssuers : state.lastSearch.filterCriteria.issuers,
                    securities: needFiltersUpdate ? filterSecurities : state.lastSearch.filterCriteria.securities
                }
            },
            isSearchTreasury
        };
    },
    [SEARCH_MORE_FETCHING]: (state, { payload: { context, status } }) => ({
        ...state,
        fetchingStatus: {
            ...state.fetchingStatus,
            [context]: status
        }
    }),
    [SEARCH_MORE_SUCCESS]: (state, { payload: { results } }) => {
        const contextResult = _.get(results, '[0]');

        if (!contextResult) {
            return state;
        }

        // appending result to current context results
        return ({
            ...state,
            results: _.map(state.results,
                res => res.type === contextResult.type
                    ? { ...contextResult, values: [...res.values, ...contextResult.values] }
                    : res)
        });
    },
    [SEARCH_FILTER_SET_CONTEXT]: (state, action) => {
        const { checked, name } = action.payload;
        const { filterCriteria } = state.lastSearch;
        const { statuses, countries, contexts } = filterCriteria;

        const newFilters = {
            ...filterCriteria,
            contexts: {
                ...contexts,
                [name]: checked
            }
        };

        if (name === CONTEXTS.REGISTERED && !checked) {
            newFilters.statuses = _.mapValues(statuses, () => false);
            newFilters.countries = _.mapValues(countries, () => false);
            newFilters.state = null;
        }

        return {
            ...state,
            lastSearch: {
                ...state.lastSearch,
                filterCriteria: newFilters
            }
        };
    },
    [SEARCH_FILTER_SET_STATUS]: (state, action) => {
        const { checked, name } = action.payload;
        const { filterCriteria } = state.lastSearch;
        const { statuses, contexts } = filterCriteria;

        return {
            ...state,
            lastSearch: {
                ...state.lastSearch,
                filterCriteria: {
                    ...filterCriteria,
                    // set registered when any country becomes checked
                    contexts: {
                        ...contexts,
                        [CONTEXTS.REGISTERED]: checked || contexts[CONTEXTS.REGISTERED]
                    },
                    statuses: {
                        ...statuses,
                        [name]: checked
                    }
                }
            }
        };
    },
    [SEARCH_FILTER_SET_COUNTRY]: (state, action) => {
        const { checked, name } = action.payload;
        const { filterCriteria } = state.lastSearch;
        const { contexts, countries } = filterCriteria;

        return {
            ...state,
            lastSearch: {
                ...state.lastSearch,
                filterCriteria: {
                    ...filterCriteria,
                    // set registered when any country becomes checked
                    contexts: {
                        ...contexts,
                        [CONTEXTS.REGISTERED]: checked || contexts[CONTEXTS.REGISTERED]
                    },
                    countries: {
                        ...countries,
                        [name]: checked
                    },
                    // reset state when US becomes unchecked
                    state: name === filterConstants.US && !checked ? null : filterCriteria.state
                }
            }
        };
    },
    [SEARCH_FILTER_SET_STATE]: (state, action) => {
        const { filterCriteria } = state.lastSearch;

        return {
            ...state,
            lastSearch: {
                ...state.lastSearch,
                filterCriteria: {
                    ...filterCriteria,
                    state: action.payload
                }
            }
        };
    },
    [SEARCH_FILTER_SET_ISSUER]: (state, { payload: { checked, id, securities } }) => {
        const { filterCriteria } = state.lastSearch;
        const issuers = { ...filterCriteria.issuers };
        const updatedSecurities = {};

        issuers[id] = checked;
        securities.forEach(sec => {
            updatedSecurities[sec.id] = checked;
        });

        return {
            ...state,
            lastSearch: {
                ...state.lastSearch,
                filterCriteria: {
                    ...filterCriteria,
                    issuers,
                    securities: {
                        ...filterCriteria.securities,
                        ...updatedSecurities
                    }
                }
            }
        };
    },
    [SEARCH_FILTER_SET_SECURITY]: (state, { payload: { checked, id, issuerSecurities } }) => {
        const { filterCriteria } = state.lastSearch;
        const securities = { ...filterCriteria.securities };
        const issuerId = issuerSecurities[0].issuerId;
        const { issuers } = filterCriteria;

        securities[id] = checked;

        const isAllChecked = _.every(issuerSecurities, sec => securities[sec.id] === checked);

        if (isAllChecked) {
            issuers[issuerId] = checked;
        }

        return {
            ...state,
            lastSearch: {
                ...state.lastSearch,
                filterCriteria: {
                    ...filterCriteria,
                    securities,
                    issuers: isAllChecked ? issuers : filterCriteria.issuers
                }
            }
        };
    },
    [TOGGLE_SHAREHOLDER_TO_COMPARE]: (state, { payload }) => {
        return {
            ...state,
            selectedShareholdersToCompare: _.xorWith(state.selectedShareholdersToCompare, [payload], _.isEqual)
        };
    },
    [TOGGLE_ALL_SHAREHOLDERS]: (state, { payload }) => {
        if (!payload) {
            return {
                ...state,
                selectedShareholdersToCompare: []
            };
        }
        // select max if max are not selected
        const registeredShareholders = _.get(_.find(state.results, r => r.type === CONTEXTS.REGISTERED), 'values');
        const topRegisteredShareholders = registeredShareholders
            .slice(0, MAX_SHAREHOLDERS_TO_COMPARE)
            .map(s => ({ shareholderId: s.id, securityId: s.security.securityId }));

        return {
            ...state,
            selectedShareholdersToCompare: topRegisteredShareholders
        };
    },
    [CLEAR_TOGGLED_SHAREHOLDERS_TO_COMPARE]: (state) => {
        return {
            ...state,
            selectedShareholdersToCompare: []
        };
    },
    [SET_IS_NEW_GLOBAL_SEARCH_REQUEST]: (state, { payload }) => ({
        ...state,
        isNewGlobalSearchRequest: payload
    })
};

const searchPersistConfig = {
    key: 'advancedSearch',
    version: FE_VERSION,
    storage,
    whitelist: ['results', 'total', 'filterTotals', 'lastSearch', 'states', 'searchArea', 'securities', 'issuers']
};

export default persistReducer(searchPersistConfig, createReducer(initialState, actionHandlers));
