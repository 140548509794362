import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cn from 'classnames';

import './CheckBox.scss';

export default class CheckBox extends Component {
    static propTypes = {
        className: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        title: PropTypes.string,
        position: PropTypes.string,
        disabled: PropTypes.bool,
        indeterminate: PropTypes.bool,
        ellipsis: PropTypes.bool
    };

    static defaultProps = {
        position: 'inline'
    };

    render() {
        const { label, className, position, disabled, title, indeterminate, ellipsis, ...rest } = this.props;
        const labelClass = cn('checkbox-component', { disabled }, position, className);

        return (
            <label className={labelClass} title={title}>
                <input
                    {...rest}
                    disabled={disabled}
                    className='checkbox-input'
                    type='checkbox'/>
                <i className={cn('font-icon', { 'font-icon-checkbox-no-checked': !indeterminate, 'font-icon-collapse': indeterminate })}/>
                <i className='font-icon font-icon-checkbox-checked'/>
                {label && <span className={cn('checkbox-label', { ellipsis })}>{label}</span>}
            </label>
        );
    }
}
