import React, { Component } from 'react';

import widgetModes from 'Constants/widget-modes';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import BadAddressesTable from './BadAddressesTable';
import BadAddressesChartView from './BadAddressesChartView';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

class BadAddresses extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    render() {
        const {
            widgetSettings: { mode, colorScheme },
            isFetching,
            widgetNoData,
            data,
            isDataViz,
            isPrintMode,
            widgetFooterProps
        } = this.props;

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}>
                    {mode === widgetModes.CHART
                        ? <BadAddressesChartView
                            intl={this.props.intl}
                            colorSchemeId={colorScheme}
                            data={data}
                            widgetFooterProps={widgetFooterProps}/>
                        : <BadAddressesTable
                            intl={this.props.intl}
                            data={data}
                            isDataViz={isDataViz}
                            isPrintMode={isPrintMode}
                            widgetFooterProps={widgetFooterProps}/>
                    }
                </NoDataComponent>
            </div>
        );
    }
}

export default BadAddresses;
