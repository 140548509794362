import React, { useState, memo } from  'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import './CreateNoteModal.scss';

const MAX_NOTE_TEXT_LENGTH = 500;

const CreateNoteModal = props => {
    const [noteText, changeNoteText] = useState('');

    return (<div className='create-note-modal'>
        <textarea
            className='modal-textarea'
            maxLength={MAX_NOTE_TEXT_LENGTH}
            value={noteText}
            rows={15}
            placeholder={props.intl.formatMessage({ id: 'modals.createNote.textarea.placeholder' })}
            onChange={e => changeNoteText(e.target.value)}/>
        <div className='note-length-counter'>{`${noteText.length}/${MAX_NOTE_TEXT_LENGTH}`}</div>
        <div className='modal-actions'>
            <button
                type='button'
                onClick={() => props.onSuccess(noteText)}
                disabled={noteText.length === 0}
                className='btn btn-large ok-btn'>
                {props.intl.formatMessage({ id: props.okTextKey })}
            </button>
            <button
                type='button'
                onClick={props.onCancel}
                className='btn btn-large cancel-btn'>
                {props.intl.formatMessage({ id: props.cancelTextKey })}
            </button>
        </div>
    </div>);
};

CreateNoteModal.propTypes = {
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    okTextKey: PropTypes.string,
    cancelTextKey: PropTypes.string,
    intl: intlShape
};

export default injectIntl(memo(CreateNoteModal));
