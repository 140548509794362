import _ from 'lodash';

import widgetTypes from 'Constants/widget-types';
import pageTypes from 'Constants/dynamic-page-types';

import * as api from '../../api';

export const FETCHING_SHAREHOLDERS_FUNDS_REQUEST = 'FETCHING_SHAREHOLDERS_FUNDS_REQUEST';
export const FETCHING_SHAREHOLDERS_FUNDS_SUCCESS = 'FETCHING_SHAREHOLDERS_FUNDS_SUCCESS';
export const FETCHING_SHAREHOLDERS_FUNDS_FAILURE = 'FETCHING_SHAREHOLDERS_FUNDS_FAILURE';
export const FETCHING_SHAREHOLDERS_POSITIONS_REQUEST = 'FETCHING_SHAREHOLDERS_POSITIONS_REQUEST';
export const FETCHING_SHAREHOLDERS_POSITIONS_SUCCESS = 'FETCHING_SHAREHOLDERS_POSITIONS_SUCCESS';
export const FETCHING_SHAREHOLDERS_POSITIONS_FAILURE = 'FETCHING_SHAREHOLDERS_POSITIONS_FAILURE';
export const SET_EXPANDED_SHAREHOLDERS = 'SET_EXPANDED_SHAREHOLDERS';
export const RESET_SHAREHOLDER_TABLE = 'RESET_SHAREHOLDER_TABLE';
export const FETCHING_SHAREHOLDERS_CONTACTS_REQUEST = 'FETCHING_SHAREHOLDERS_CONTACTS_REQUEST';
export const FETCHING_SHAREHOLDERS_CONTACTS_SUCCESS = 'FETCHING_SHAREHOLDERS_CONTACTS_SUCCESS';
export const FETCHING_SHAREHOLDERS_CONTACTS_FAILURE = 'FETCHING_SHAREHOLDERS_CONTACTS_FAILURE';
export const FETCHING_SHAREHOLDERS_REQUEST = 'FETCHING_SHAREHOLDERS_REQUEST';
export const FETCHING_SHAREHOLDERS_SUCCESS = 'FETCHING_SHAREHOLDERS_SUCCESS';
export const FETCHING_SHAREHOLDERS_FAILURE = 'FETCHING_SHAREHOLDERS_FAILURE';

function requestShareholdersFunds(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_FUNDS_REQUEST,
        payload
    };
}

function successShareholdersFunds(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_FUNDS_SUCCESS,
        payload
    };
}

function errorShareholdersFunds(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_FUNDS_FAILURE,
        payload,
        error: true
    };
}

function expandShareholders(payload) {
    return {
        type: SET_EXPANDED_SHAREHOLDERS,
        payload
    };
}

function requestShareholdersPositions(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_POSITIONS_REQUEST,
        payload
    };
}

function successShareholdersPositions(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_POSITIONS_SUCCESS,
        payload
    };
}

function errorShareholdersPositions(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_POSITIONS_FAILURE,
        payload,
        error: true
    };
}

export const resetShareholderTable = () => {
    return {
        type: RESET_SHAREHOLDER_TABLE
    };
};

export const getShareholderFunds = (shareholdersId, subProxy = false) => (dispatch) => {
    dispatch(requestShareholdersFunds({ shareholdersId }));

    let fetcherFn = api.getShareholdersFunds;

    if (subProxy) {
        fetcherFn = api.getShareholdersSubProxy;
    }

    return fetcherFn(shareholdersId)
        .then(res => {
            dispatch(successShareholdersFunds({ funds: res.data.payload.data[0].data, shareholdersId }));
        })
        .catch(error => {
            dispatch(errorShareholdersFunds({ error, shareholdersId }));
            return Promise.reject(error);
        });
};

export const getShareholderPositions = (shareholdersId) => (dispatch) => {
    dispatch(requestShareholdersPositions({ shareholdersId }));
    return api.getShareholderPositions(shareholdersId)
        .then(res => {
            dispatch(successShareholdersPositions({
                data: res.data.payload.data[0].data,
                shareholdersId
            }));
        })
        .catch(error => {
            dispatch(errorShareholdersPositions({ error, shareholdersId }));
            return Promise.reject(error);
        });
};

export const setExpandedShareholders = (expandedShareholders) => (dispatch) => {
    dispatch(expandShareholders({ expandedShareholders }));
};

function requestShareholdersContacts(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_CONTACTS_REQUEST,
        payload
    };
}

function successShareholdersContacts(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_CONTACTS_SUCCESS,
        payload
    };
}

function errorShareholdersContacts(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_CONTACTS_FAILURE,
        payload
    };
}

export const getShareholderContacts = (shareholderId) => (dispatch) => {
    dispatch(requestShareholdersContacts({ shareholderId }));

    return api.getShareholdersContacts(shareholderId)
        .then(res => {
            const contacts = _.get(res, 'data.payload.data.0.data', []);

            dispatch(successShareholdersContacts({ contacts, shareholderId }));
        })
        .catch(error => {
            dispatch(errorShareholdersContacts({ error, shareholderId }));

            return Promise.reject(error);
        });
};

function requestShareholders() {
    return {
        type: FETCHING_SHAREHOLDERS_REQUEST
    };
}

function successShareholders(payload) {
    return {
        type: FETCHING_SHAREHOLDERS_SUCCESS,
        payload
    };
}

function errorShareholders() {
    return {
        type: FETCHING_SHAREHOLDERS_FAILURE
    };
}

const getShareholdersData = (widgets) => (dispatch) => {
    dispatch(resetShareholderTable());
    dispatch(requestShareholders());

    return api.getWidgetData(pageTypes.OWNERSHIP_SHAREHOLDERS, widgets)
        .then(({ data }) => {
            let shareholders = _.get(data, ['payload', 'data', 0, 'data']);

            if (DEMO_MODE) {
                shareholders = !shareholders ? shareholders : {
                    ...shareholders,
                    values: _.map(_.get(shareholders, ['values']), (item) => ({
                        ...item,
                        'state': 'London',
                        country: 'PO16 7GZ'
                    }))
                };
            }
            dispatch(successShareholders(shareholders));
        })
        .catch(() => {
            dispatch(errorShareholders());
        });
};

export const getRegularShareholders = (params) => (dispatch) => {
    const widgets = [{
        type: widgetTypes.shareholdersTable,
        params: {
            dataSourceId: 'shareholdersTableData',
            ...params
        }
    }];

    return dispatch(getShareholdersData(widgets));
};

export const getProxyShareholders = (params) => (dispatch) => {
    const widgets = [{
        type: widgetTypes.shareholdersProxyTable,
        params: {
            dataSourceId: 'shareholdersProxyTableData',
            ...params
        }
    }];

    return dispatch(getShareholdersData(widgets));
};
