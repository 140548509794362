import { createSelector } from 'reselect';
import _ from 'lodash';

const getExportedReports = state => _.get(state, 'exportedReports.reports', []);

export const exportedReportsSelector = createSelector(
    getExportedReports,
    reports => reports
);

export const isFetchingSelector = createSelector(
    state => _.get(state, 'exportedReports.isFetching', false),
    isFetching => isFetching
);

export const formatFetchingReportSelector =  state => _.get(state, 'exportedReports.formatFetchingReport');
