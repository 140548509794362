import { withProps } from '@shakacode/recompose';
import _ from 'lodash';

import { withAdditionalPeriod, generateBarPositiveInstitutionValues } from 'Components/BarChart';
import widgetModes from 'Constants/widget-modes';
import { generateTableValues } from 'Components/Tables/TableStickyColumnGroups';
import { formatDateArray } from 'Utils/utils';
import legendTitleProps from '../../constants/legendTitleProps';
import legendBarProps from 'Components/widgets/_commonConfigs/institutionalShareholders/legendProps';
import { loadChartHandler } from '../../utils';
import ActiveVsPassiveContent from './ActiveVsPassiveContent';

const DATE_FORMAT = 'MM/YY';
const MONTH_IN_PERIOD = 3;
const BAR_DISPLAY_COEFFICIENT = 1000000;

export default withProps(
    (props) => {
        const { intl: { formatMessage } } = props;
        const data = _.get(props, 'widgetData', []).map(value => ({
            ...value,
            aggregate: {
                ...value.aggregate/* ,
            name: formatMessage({ id: `active.vs.passive.${value.aggregate.name}` })*/
            }
        }));
        const getAggregationNameForTable = trend => formatMessage({ id: `active.vs.passive.${_.get(trend, 'aggregate.name')}` });
        const getAggregationNameForChart = aggregate => formatMessage({ id: `active.vs.passive.chart.${aggregate.name}` });
        const aggregations = data.map((trend) => trend.aggregate);
        const unformattedPeriods = _.get(props, 'widgetData[0].aggregate.values', []).map((value) => value.period);
        const formattedPeriods = formatDateArray(unformattedPeriods, DATE_FORMAT);

        return {
            barPeriods: withAdditionalPeriod(formattedPeriods, MONTH_IN_PERIOD, DATE_FORMAT),
            barData: generateBarPositiveInstitutionValues(aggregations, BAR_DISPLAY_COEFFICIENT, getAggregationNameForChart),
            tableData: generateTableValues(data, DATE_FORMAT, getAggregationNameForTable),
            tablePeriods: formattedPeriods,
            chartProps: {
                events: {
                    load: loadChartHandler
                }
            },
            legendProps: {
                ...legendBarProps,
                title: {
                    ...legendTitleProps,
                    text: formatMessage({ id: legendTitleProps.text })
                }
            },
            chartMode: props.widgetSettings.mode === widgetModes.CHART,
            noData: !data.some(item => !!_.get(item, 'aggregate.values.length'))
        };
    }
)(ActiveVsPassiveContent);
