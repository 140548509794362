import React, { Component } from 'react';

import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import slots from './slots';

import './PeerAnalysisPage.scss';

class PeerAnalysisPage extends Component {
    render() {
        return (
            <div className='peer-analysis-page'>
                <DynamicPage
                    layoutType={layoutTypes.layoutEasy}
                    slots={slots}
                    dynamicPageType={PAGE_TYPES.PEER_ANALYSIS}/>
            </div>
        );
    }
}

export default PeerAnalysisPage;
