import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import AutoSuggest from 'Components/widgets/_commonComponents/AutoSuggest';

class ContactsTableSearch extends Component {
    static propTypes = {
        value: PropTypes.string,
        requestParams: PropTypes.object,
        isFetching: PropTypes.bool,
        onChange: PropTypes.func,
        onSelect: PropTypes.func,
        onFilterSubmit: PropTypes.func,
        onKeyDown: PropTypes.func,
        onMouseDown: PropTypes.func
    };

    render() {
        const { isFetching, requestParams, value, onChange, onSelect, onFilterSubmit, onKeyDown, onMouseDown } = this.props;

        return (
            <div className='contacts-table-search-input-container'>
                <AutoSuggest
                    requestParams={requestParams}
                    value={value}
                    onChange={onChange}
                    onSelect={onSelect}
                    onKeyDown={onKeyDown}
                    tabSelectsValue={false}>
                    {({ clearValue }) => {
                        this.clearSearchValue = clearValue;
                    }}
                </AutoSuggest>
                <i
                    className={cn('font-icon font-icon-search', { disabled: isFetching })}
                    onClick={!isFetching ? onFilterSubmit : null}
                    onMouseDown={onMouseDown}/>
            </div>
        );
    }
}

export default ContactsTableSearch;
