import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import cn from 'classnames';

import TextInput from '../../TextInput';

import './Search.scss';

const SearchView = ({
    name,
    placeholder = 'Search',
    validate,
    disabled,
    autoComplete,
    isLoading,
    onSearch,
    handleSubmit,
    isErrorMessageShowing = true,
    withFocus = false,
    onChange,
    onBlur,
    onFocus,
    onSearchIconClick }) => {
    const isIconDisabled = disabled || isLoading;

    return (
        <form onSubmit={handleSubmit(onSearch)}>
            <div className='search'>
                <Field
                    name={name}
                    component={TextInput}
                    isErrorMessageShowing={isErrorMessageShowing}
                    className='search-input'
                    placeholder={placeholder}
                    type='text'
                    disabled={disabled}
                    validate={validate}
                    autoComplete={autoComplete}
                    withFocus={withFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}/>
                { isLoading && <span className='spinner-container'><span className='Select-loading' /></span>}
                <i
                    className={cn('font-icon font-icon-search', { disabled: isIconDisabled })}
                    onMouseDown={!isIconDisabled ? handleSubmit(onSearch) : null}
                    onClick={!isIconDisabled ? onSearchIconClick : null}/>
            </div>
        </form>
    );
};

SearchView.propTypes = {
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    validate: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.func),
        PropTypes.func
    ]),
    onSearch: PropTypes.func,
    name: PropTypes.string,
    autoComplete: PropTypes.bool,
    handleSubmit: PropTypes.func,
    isErrorMessageShowing: PropTypes.bool,
    withFocus: PropTypes.bool,
    reset: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onSearchIconClick: PropTypes.func
};

export default SearchView;
