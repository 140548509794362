import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';

import { MktValueFormatter } from 'Components/formatters';

const CellWithMktVal = (props) => {
    const { value, className, decimalPoints, additionalData: { withTitle } = {} } = props;
    const cellClass = cn('cell-with-mkt-val', className);

    return (
        <div className={cellClass}>
            <MktValueFormatter value={value} decimalPoints={decimalPoints} withTitle={withTitle}/>
        </div>
    );
};

CellWithMktVal.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    decimalPoints: PropTypes.number,
    additionalData: PropTypes.object
};

export default CellWithMktVal;
