/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from 'Components/Tooltip';
import { LabelWithTooltip, NullableFormatter, NumberFormatter } from 'Components/formatters';

export const labelCell = ({ value, className, rowData: { className: dataClassName } = {} } = {}) => (
    <LabelWithTooltip
        value={NullableFormatter({ value })}
        className={cn(className, dataClassName)}/>
);

labelCell.propTypes = {
    value: PropTypes.any,
    className: PropTypes.string,
    rowData: PropTypes.object
};

export const numberCell = v => <NumberFormatter value={v.value} withTitle isOriginalValue/>;
export const formattedNumberCell = v => <NumberFormatter value={v.value} withTitle decimalPoints={3}/>;
export const headerCell = (title, tooltip) => (
    <span className='ellipsis'>
        <Tooltip
            content={tooltip ? tooltip : title}>
            <span>{title}</span>
        </Tooltip>
    </span>
);
