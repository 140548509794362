import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import shareholderViewTypes from 'Constants/shareholder-view-types';
import ButtonsGroup from 'Components/ButtonsGroup';
import Selector from 'Components/Selector';
import CustomViewOptionRenderer from '../CustomViewOptionRenderer';

import './ViewsGroup.scss';

export const customViewShape = PropTypes.shape({
    value: PropTypes.string,
    title: PropTypes.string
});

@injectIntl
class ViewsGroup extends Component {
    static propTypes = {
        ...Component.propTypes,
        activeView: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        defaultViews: PropTypes.arrayOf(customViewShape).isRequired,
        customViews: PropTypes.arrayOf(customViewShape).isRequired,
        createLink: PropTypes.element.isRequired,
        intl: intlShape,
        customViewsShowed: PropTypes.bool
    };

    static defaultProps = {
        activeView: null
    };

    constructor(props) {
        super(props);

        this.order = [
            shareholderViewTypes.NUMBERS,
            shareholderViewTypes.DETAILS,
            shareholderViewTypes.REGISTERED_DETAILS,
            shareholderViewTypes.PROXY
        ];
    }

    handleSelectView = (value) => {
        if (value !== this.props.activeView) {
            this.props.onChange(value);
        }
    };

    handleSelectCustomView = (item) => {
        if (item.value !== this.props.activeView) {
            this.props.onChange(item.value);
        }
    };

    _renderDefaultViews = () => {
        const buttons = this.order.map((viewid) => {
            const view = _.find(this.props.defaultViews, ['value', viewid]);

            if (!view) return;

            return {
                title: view.title,
                value: view.value,
                isSelected: this.props.activeView === view.value
            };
        });

        return (<ButtonsGroup onSelect={this.handleSelectView} buttons={buttons.filter(button => button)}/>);
    };

    _renderCustomViews = () => {
        const { isDataViz, createLink, activeView, intl: { formatMessage } } = this.props;
        const options = this.props.customViews.map(view => ({
            value: view.value,
            label: view.title
        }));

        const noResults = isDataViz ? formatMessage({ id: 'shareholders.table.views.noCustomViews' }) : createLink;

        if (!isDataViz) {
            options.push({
                disabled: true,
                createLink
            });
        }

        const value = _.find(options, ['value', activeView]);

        return (
            <Selector
                name='custom-views'
                placeholder={formatMessage({ id: 'shareholders.table.views.customViews' })}
                value={value}
                options={options}
                valueRenderer={CustomViewOptionRenderer}
                optionRenderer={CustomViewOptionRenderer}
                clearable={false}
                searchable={false}
                noResults={noResults}
                onOptionChange={this.handleSelectCustomView}/>
        );
    };

    render() {
        const { className, customViewsShowed } = this.props;

        return (
            <div className={classNames('views-group', className)}>
                {this._renderDefaultViews()}
                {customViewsShowed && this._renderCustomViews()}
            </div>
        );
    }
}

export default ViewsGroup;
