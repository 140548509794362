import * as api from '../../api';
import _ from 'lodash';

export const SET_DROPZONE_INPUT_PROPS = 'SET_DROPZONE_INPUT_PROPS';
export const UPLOAD_DOCUMENT_FETCHING = 'UPLOAD_DOCUMENT_FETCHING';

const setFetching = isFetching => ({
    type: UPLOAD_DOCUMENT_FETCHING,
    payload: isFetching
});

export const setDropzoneInputProps = inputProps => ({
    type: SET_DROPZONE_INPUT_PROPS,
    payload: inputProps
});

export const uploadDocument = (file, shareholderId) => dispatch => {
    dispatch(setFetching(true));

    return api.uploadDocument(file, shareholderId).then(
        res => {
            dispatch(setFetching(false));

            const isUploadSuccess  = _.get(res, 'data.payload.document.documentId');
            const uploadStatus  = _.get(res, 'data.payload.document.uploadStatus');

            if (!isUploadSuccess) {
                return Promise.reject();
            }

            return uploadStatus;
        },
        err => {
            dispatch(setFetching(false));

            return Promise.reject(err);
        }
    );
};

export const getUploadedDocumentsPolling = props => () => {
    props.onUpdateWidgetData({ shareholderId: _.get(props, 'pageParams.shareholderId') }, { withoutFetching: true });
};
