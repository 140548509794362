import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { Column } from 'react-virtualized';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import './stickyFooter.scss';

const stickyFooter = ({
    className,
    width,
    paddingRight,
    children,
    rowData,
    totalMessageId,
    ellipsis,
    markTotalOverflow,
    widgetFooterProps,
    markDifferenceFromReport,
    intl: { formatMessage }
}) => {
    return (
        !_.isEmpty(rowData) && <div
            className={cn('ReactVirtualized__Table__row', 'virtualized-sticky-footer', className)}
            style={{
                width,
                paddingRight
            }}>
            { React.Children.map(children, (c, index) => {
                const p = c.props;
                const cellData = rowData[p.dataKey];
                let totalMessage;

                // first is a total label
                if (index === 0) {
                    totalMessage = formatMessage({ id: totalMessageId });
                }

                const title = ellipsis ? totalMessage : null;

                return (
                    <div
                        key={c.key}
                        className={cn('ReactVirtualized__Table__rowColumn', p.className)}
                        style={{
                            flexGrow: p.flexGrow,
                            flexShrink: p.flexShrink,
                            flexBasis: p.width,
                            maxWidth: p.maxWidth,
                            minWidth: p.minWidth,
                            msFlexPositive: p.flexGrow,
                            msFlexNegative: p.flexShrink,
                            msFlexPreferredSize: p.width
                        }}>
                        {!_.isNil(totalMessage) &&
                        <span className={cn('cell-total', { ellipsis })} title={title}>{ totalMessage }</span>
                        }
                        {!_.isUndefined(cellData) &&
                        <span className='cell-value'>
                            {p.cellRenderer({ cellData, rowData: { isTotal: true, markTotalOverflow, widgetFooterProps, markDifferenceFromReport } })}
                        </span>
                        }
                    </div>
                );
            }) }
        </div>);
};

stickyFooter.propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    paddingRight: PropTypes.number,
    rowData: PropTypes.object,
    totalMessageId: PropTypes.string,
    ellipsis: PropTypes.bool,
    children: (props, propName, componentName) => {
        const children = React.Children.toArray(props.children);

        for (let i = 0; i < children.length; i++) {
            if (children[i].type !== Column) {
                return new Error(`${componentName} only accepts children of type Column`);
            }
        }
    },
    intl: intlShape
};

stickyFooter.defaultProps = {
    width: 300,
    paddingRight: 0,
    ellipsis: false,
    markTotalOverflow: true,
    totalMessageId: 'tableEasy.total'
};


export default injectIntl(stickyFooter);
