import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { withState, mapProps } from '@shakacode/recompose';
import { get } from 'lodash';

import { exportedReportsSelector, isFetchingSelector } from 'State/exportReports/selectors';
import { DateFormatter, FileSizeFormatter } from 'Components/formatters';
import downloadStatuses from 'Constants/download-statuses';
import dateFormats from 'Constants/date-formats';
import ReportingDownloadsPageView from '../components/ReportingDownloadsPageView';
import ReportingDownloadsActionView from '../components/ReportingDownloadsActionView';
import ReportingDescriptionCell from '../components/ReportingDescriptionCell';
import {
    deleteLaunchedReport,
    downloadLaunchedReport,
    getLaunchedReports,
    getLaunchedReportsPolling
} from 'State/exportReports';
import { showConfirmModal } from 'State/modal';
import { changeUserNotifications } from 'State/user/actions';
import columnDefinitions from '../constants/columnDefinitions';
import sortDirections from 'Constants/sort-directions';
import { getSortDirection } from 'Components/Tables/utils';
import { sortArray } from 'Utils/utils';
import polling from 'Components/polling';
import pollingFunctionTypes from 'Constants/polling-function-types';

let boundActionCreators;
const DEFAULT_POLLING_INTERVAL = 5000;
const mapStateToProps = state => ({
    reports: exportedReportsSelector(state),
    isFetching: isFetchingSelector(state)
});
const mapDispatchToProps = dispatch => {
    return boundActionCreators = bindActionCreators({
        showConfirmModal,
        deleteLaunchedReport,
        downloadLaunchedReport,
        changeUserNotifications,
        getLaunchedReports,
        getLaunchedReportsPolling
    }, dispatch);
};

@polling(() => boundActionCreators.getLaunchedReportsPolling(), pollingFunctionTypes.LAUNCHED_REPORTS, DEFAULT_POLLING_INTERVAL)
@connect(mapStateToProps, mapDispatchToProps)
@withState(
    'sortCriteria',
    'setSortCriteria',
    {
        field: columnDefinitions.columnNames.RUN_DATE,
        direction: sortDirections.DESC
    }
)
@mapProps(props => ({
    ...props,
    tableData: sortArray({
        array: get(props, 'reports', []),
        primaryField: props.sortCriteria.field,
        descending: props.sortCriteria.direction === sortDirections.DESC
    })
}))
@injectIntl
class ReportingDownloadsPage extends Component {
    static propTypes = {
        showConfirmModal: PropTypes.func,
        getLaunchedReports: PropTypes.func,
        deleteLaunchedReport: PropTypes.func,
        downloadLaunchedReport: PropTypes.func,
        changeUserNotifications: PropTypes.func,
        setSortCriteria: PropTypes.func,
        tableData: PropTypes.array,
        sortCriteria: PropTypes.object,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        const { formatMessage } = this.props.intl;

        this.noDataMessage = formatMessage({ id: 'tables.noResultsFound' });
    }

    componentDidMount() {
        this.props.getLaunchedReports();
        this.props.changeUserNotifications({
            newDownloads: 0
        });
    }

    handleReportDownload = ({ reportId, status, isDeleting, subscriptionId, reportType, description, runDate }) => {
        if (this.isAvailableDownload(status, isDeleting)) {
            this.props.downloadLaunchedReport(reportId, subscriptionId, reportType, description, runDate);
        }
    };

    handleReportDelete = ({ reportId, description, status, isDeleting, securityCount, reportType }, isSubscription) => {
        if (this.isAvailableDelete(status, isDeleting, isSubscription)) {
            this.props.showConfirmModal({
                titleKey: 'common.confirmDelete',
                messageKey: securityCount > 1
                    ? 'modals.confirmModal.message.delete.report.multipleSecurity'
                    : 'common.deleteMessage',
                payload: description,
                okTextKey: 'common.delete',
                cancelTextKey: 'common.cancel',
                isHtmlMessage: true,
                onSuccess: () => {
                    this.props.deleteLaunchedReport(reportId, reportType);
                }
            });
        }
    };

    handleOnSort = ({ sortBy }) => {
        const { sortCriteria: { field: prevSortBy, direction: prevDirection } } = this.props;
        const sortCriteria = {
            field: sortBy,
            direction: getSortDirection(
                sortBy,
                prevSortBy,
                prevDirection,
                columnDefinitions.columns
            )
        };

        this.props.setSortCriteria(sortCriteria);
    };

    getCellWithStatus = (status) => {
        const { formatMessage } = this.props.intl;

        switch (status) {
            case downloadStatuses.IN_PROGRESS:
                return (<span className='negative'>{formatMessage({ id: 'reporting.downloads.status.inProgress' })}</span>);
            case downloadStatuses.COMPLETE:
                return (<span className='status-complete'>{formatMessage({ id: 'reporting.downloads.status.complete' })}</span>);
            case downloadStatuses.FAILED:
                return (<span className='negative status-failed'>{formatMessage({ id: 'reporting.downloads.status.failed' })}</span>);
            case downloadStatuses.NO_RECORDS:
                return (<span className='negative status-no-records'>{formatMessage({ id: 'reporting.downloads.status.noRecords' })}</span>);
            default:
                return (<span/>);
        }
    };

    getCustomColumnsSettings = (columnName) => {
        const columnNames = columnDefinitions.columnNames;

        switch (columnName) {
            case columnNames.RUN_DATE:
                return {
                    maxWidth: 141,
                    bodyCellComponent: v => <DateFormatter value={v.value} format={dateFormats.withTime}/>
                };
            case columnNames.DESCRIPTION:
                return {
                    bodyCellComponent: v => (
                        <ReportingDescriptionCell
                            value={v.value}
                            data={v.rowData}
                            isAvailable={this.isAvailableDelete}
                            onClickAction={this.handleReportDownload}/>
                    )
                };
            case columnNames.FORMAT:
                return {
                    maxWidth: 105,
                    bodyCellComponent: v => <span>{v.value}</span>
                };
            case columnNames.STATUS:
                return {
                    maxWidth: 100,
                    bodyCellComponent: v => this.getCellWithStatus(v.value)
                };
            case columnNames.SIZE:
                return {
                    maxWidth: 85,
                    bodyCellComponent: v => (<div className='cell-with-number'>
                        <FileSizeFormatter value={v.value}/>
                    </div>)
                };
            case columnNames.DOWNLOAD:
                return {
                    columnClassName: 'text-center',
                    maxWidth: 93,
                    disableSort: true,
                    bodyCellComponent: v => (
                        <ReportingDownloadsActionView
                            className='font-icon-download'
                            isAvailable={this.isAvailableDownload}
                            titleKey='common.icon.tooltip.download'
                            data={v.rowData}
                            onClickAction={this.handleReportDownload}/>
                    )
                };
            case columnNames.DELETE:
                return {
                    columnClassName: 'text-center',
                    maxWidth: 60,
                    disableSort: true,
                    bodyCellComponent: v => (
                        <ReportingDownloadsActionView
                            className='font-icon-delete'
                            isAvailable={this.isAvailableDelete}
                            data={v.rowData}
                            onClickAction={this.handleReportDelete}/>
                    )
                };
            default:
                return {};
        }
    };

    getColumns = () => {
        return columnDefinitions.columns.map(column => {
            const customSettings = this.getCustomColumnsSettings(column.columnName);

            return {
                ...column,
                ...customSettings,
                dataKey: column.columnName,
                title: this.props.intl.formatMessage({ id: column.title })
            };
        });
    };

    isAvailableDownload = (status, isDeleting) => (status === downloadStatuses.COMPLETE && !isDeleting);
    isAvailableDelete = (status, isDeleting, isSubscription) => (status !== downloadStatuses.IN_PROGRESS && !isDeleting && !isSubscription);

    render() {
        return (
            <ReportingDownloadsPageView
                columns={this.getColumns()}
                data={this.props.tableData}
                onSort={this.handleOnSort}
                {...this.props}/>
        );
    }
}

export default ReportingDownloadsPage;
