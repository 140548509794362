import PropTypes from 'prop-types';
import React from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';


const NoDividendCell = (props) => {
    const { className, style } = props;
    const cellClassName = cn(className, 'no-dividend-content', {});

    return (
        <div className={cellClassName} style={{ left: style.left }}>
            <FormattedMessage id='registered.shareholder.information.noData.registeredShareholderDRDSPP.common'/>
        </div>
    );
};

NoDividendCell.propTypes = {
    value: PropTypes.any,
    additionalData: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object
};
export default NoDividendCell;
