import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { compose } from '@shakacode/recompose';

import AnalysisTurnoverLegendModal from './container/AnalysisTurnoverLegendModal';
import { hideModal } from 'State/modal';

export default compose(
    connect(
        (state) => ({ ...state.modal }),
        { hideModal }),
    injectIntl
)(AnalysisTurnoverLegendModal);
