import _ from 'lodash';

/**
 * Mapping geographicAnalysis widget's settings when user switches widget in the same slot
 * @param {Object} [widgetSettings] - widget's settings of new widget
 * @param {Object} [previousWidgetSettings] - widget's settings of previous widget
 * @returns {Object} - mapped widget's settings for new widget
 */
export const geographicAnalysisMapSettingsStrategy = (widgetSettings, previousWidgetSettings) => {
    const mappedPreviousWidgetSettings = _.omit(previousWidgetSettings, ['type', 'dataSourceId']);

    return {
        ...widgetSettings,
        ...mappedPreviousWidgetSettings
    };
};
