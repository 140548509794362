import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Icon from 'Components/Icon';

const AlertsModalHeader = props => {
    return (<div className='alerts-modal-header'>
        <div className='icon-pagination-container'>
            <Icon
                className='font-icon font-icon-double-paging-left icon-pagination'
                inactive={props.pageNumber === 1}
                onClick={props.onFirstAlertClick}/>
            <Icon
                className='font-icon font-icon-paging-left icon-pagination'
                inactive={props.pageNumber === 1}
                onClick={props.onPrevAlertClick}/>
        </div>
        <FormattedMessage
            id='modals.alerts.header'
            values={{ pageNumber: props.pageNumber, pageAmount: props.pageAmount }}>
            {message => <span className='header-text'>{message}</span>}
        </FormattedMessage>
        <div className='icon-pagination-container'>
            <Icon
                className='font-icon font-icon-paging-right icon-pagination'
                inactive={props.pageNumber === props.pageAmount}
                onClick={props.onNextAlertClick}/>
            <Icon
                className='font-icon font-icon-double-paging-right icon-pagination'
                inactive={props.pageNumber === props.pageAmount}
                onClick={props.onLastAlertClick}/>
        </div>
    </div>);
};

AlertsModalHeader.propTypes = {
    pageNumber: PropTypes.number,
    pageAmount: PropTypes.number,
    onFirstAlertClick: PropTypes.func,
    onPrevAlertClick: PropTypes.func,
    onNextAlertClick: PropTypes.func,
    onLastAlertClick: PropTypes.func
};

export default memo(AlertsModalHeader);
