import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getCountriesAndStates = (state) => state.countriesAndStates;

export const isFetchingSelector = createSelector(
    getCountriesAndStates,
    countriesAndStates => get(countriesAndStates, 'isFetching')
);

export const getCountryList = createSelector(
    getCountriesAndStates,
    countriesAndStates => {
        const countriesList = get(countriesAndStates, 'countriesList', []);

        return arrayToOptions(countriesList);
    }
);

export const getStateList = createSelector(
    getCountriesAndStates,
    countriesAndStates => {
        const statesList = get(countriesAndStates, 'statesList', []);

        return statesListToOptions(statesList);
    }
);


const arrayToOptions = array => array
    .filter(obj => obj && obj.id && obj.title)
    .map(obj => ({
        value: obj.id,
        label: obj.title
    }));

const statesListToOptions = (statesList) => {
    const statesOptionsList = {};
    const statesKeys = Object.keys(statesList);

    for (const state of statesKeys) {
        statesOptionsList[state] = arrayToOptions(statesList[state]);
    }

    return statesOptionsList;
};
