import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose, withProps } from '@shakacode/recompose';

import { withDownloadFileError } from 'Hoc/withDownloadFileError';
import apiRoutes from 'Constants/api-routes';
import { downloadFile } from 'Utils/utils';

import { getSecureUploadDocumentGuid } from '../../../../../api';

import './DocumentNameCell.scss';

const DocumentNameCellView = ({ row: { id }, value, notifyDownloadError }) => {
    const handleClick = () => {
        getSecureUploadDocumentGuid(id).then(res => {
            const guid = _.get(res, 'data.payload.guid');

            if (guid) {
                downloadFile(`${apiRoutes.downloadSecureUploadDocumentUrl}/${guid}`, notifyDownloadError);
            }
        });
    };

    return <div className='secure-upload-document-name' onClick={handleClick}>{value}</div>;
};

DocumentNameCellView.propTypes = {
    value: PropTypes.string.isRequired,
    row: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }),
    notifyDownloadError: PropTypes.func.isRequired
};

export const DocumentNameCell = compose(withProps(), withDownloadFileError)(DocumentNameCellView);
