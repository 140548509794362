import { PII, VALIDATION_LENGTH } from 'Constants/search';

export default {
    [PII.TAX_ID]: {
        key: 'search.by.advanced.tax.note',
        values: {
            length: VALIDATION_LENGTH.SSN_SEARCH_LENGTH
        }
    },
    [PII.CERTIFICATE_NO]: {
        key: 'search.by.advanced.cert.note'
    }
};
