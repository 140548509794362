import columnNames from 'Constants/registered-table-column-names';
import sortDirections from 'Constants/sort-directions';
import valueTypes from 'Constants/value-types';

export default [
    {
        columnName: columnNames.SHAREHOLDER_NAME,
        order: 1,
        minWidth: 210,
        displayName: 'registered.shareholder.table.shareholder',
        initialSortDirection: sortDirections.ASC,
        manageable: false
    },
    {
        columnName: columnNames.ISSUER,
        order: 2,
        minWidth: 210,
        displayName: 'registered.shareholder.table.issuer',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.SECURITY,
        order: 3,
        minWidth: 210,
        displayName: 'registered.shareholder.table.security',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.SECURITY_NAME
        }
    },
    {
        columnName: columnNames.ACCOUNT_NUMBER,
        order: 4,
        minWidth: 120,
        displayName: 'registered.shareholder.table.accountNumber',
        initialSortDirection: sortDirections.ASC,
        manageable: true,
        widthParams: {
            type: valueTypes.TEXT
        }
    },
    {
        columnName: columnNames.DATE_OF_SALE,
        order: 5,
        minWidth: 120,
        displayName: 'registered.shareholder.table.dateOfSale',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            valueLength: 11
        }
    },
    {
        columnName: columnNames.GROSS_AMOUNT,
        order: 6,
        minWidth: 120,
        displayName: 'registered.shareholder.table.grossAmount',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.FEDERAL_TAX,
        order: 7,
        minWidth: 120,
        displayName: 'registered.shareholder.table.federalTax',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 2,
            withCurrency: true
        }
    },
    {
        columnName: columnNames.SHARES,
        order: 8,
        minWidth: 120,
        displayName: 'registered.shareholder.table.shares',
        initialSortDirection: sortDirections.DESC,
        manageable: true,
        widthParams: {
            type: valueTypes.NUMBER,
            decimalPoints: 3
        }
    }
];

export const secondarySorting = {
    field: columnNames.DATE_OF_SALE,
    direction: sortDirections.DESC
};
