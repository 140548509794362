import { compose } from '@shakacode/recompose';
import { getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import SearchAcrossSelector from './SearchAcrossSelector';
import { SEARCH_FORM_NAME } from 'Constants/search';
import { checkCurrentSecurity, isFetchingAny, uncheckCurrentSecurity } from 'State/advancedSearch';

const mapStateToProps = state => ({
    checked: getFormValues(SEARCH_FORM_NAME)(state).searchAcrossCurrent,
    disabled: isFetchingAny(state)
});

const mapDispatchToProps = { checkCurrentSecurity, uncheckCurrentSecurity };

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(SearchAcrossSelector);
