import _ from 'lodash';

import * as api from '../../api';

export const CLEAR_PEERS = 'CLEAR_PEERS';
export const GET_PEERS_REQUEST = 'GET_PEERS_REQUEST';
export const GET_PEERS_SUCCESS = 'GET_PEERS_SUCCESS';
export const GET_PEERS_FAILURE = 'GET_PEERS_FAILURE';
export const FIND_PEER_REQUEST = 'FIND_PEER_REQUEST';
export const FIND_PEER_SUCCESS = 'FIND_PEER_SUCCESS';
export const FIND_PEER_FAILURE = 'FIND_PEER_FAILURE';
export const CLEAR_FOUND_PEERS = 'CLEAR_FOUND_PEERS';
export const SET_CANCEL_TOKEN_PEERS = 'SET_CANCEL_TOKEN_PEERS';
export const ADD_PEER_REQUEST = 'ADD_PEER_REQUEST';
export const ADD_PEER_SUCCESS = 'ADD_PEER_SUCCESS';
export const ADD_PEER_FAILURE = 'ADD_PEER_FAILURE';
export const REMOVE_PEER_REQUEST = 'REMOVE_PEER_REQUEST';
export const REMOVE_PEER_SUCCESS = 'REMOVE_PEER_SUCCESS';
export const REMOVE_PEER_FAILURE = 'REMOVE_PEER_FAILURE';
export const RESET_PEERS_REQUEST = 'RESET_PEERS_REQUEST';
export const RESET_PEERS_SUCCESS = 'RESET_PEERS_SUCCESS';
export const RESET_PEERS_FAILURE = 'RESET_PEERS_FAILURE';

export const clearPeers = () => ({ type: CLEAR_PEERS });

export const getPeers = () => (dispatch) => {
    dispatch({
        type: GET_PEERS_REQUEST
    });

    return api.getPeers()
        .then(
            res => {
                dispatch({
                    type: GET_PEERS_SUCCESS,
                    payload: {
                        data: res.data.payload.data
                    }
                });
            },
            error => {
                dispatch({
                    type: GET_PEERS_FAILURE,
                    payload: {
                        ...error
                    }
                });

                return Promise.reject(error);
            }
        );
};

export const findPeerByName = (term) => (dispatch, getState) => {
    const newCancelToken = api.createCancelToken();
    const cancelToken = _.get(getState(), 'peers.cancelToken');

    if (cancelToken) {
        cancelToken.cancel();
    }
    dispatch({
        type: FIND_PEER_REQUEST
    });

    dispatch({
        type: SET_CANCEL_TOKEN_PEERS,
        payload: newCancelToken
    });

    return api.findPeerByName(term, newCancelToken)
        .then(
            res => {
                dispatch({
                    type: FIND_PEER_SUCCESS,
                    payload: _.get(res, 'data.payload')
                });

                dispatch({
                    type: SET_CANCEL_TOKEN_PEERS,
                    payload: null
                });
            },
            error => {
                if (error && error.response) {
                    dispatch({
                        type: FIND_PEER_FAILURE,
                        payload: {
                            ...error,
                            error: true
                        }
                    });
                }

                return Promise.reject(error);
            }
        );
};

export const addPeer = (peer) => (dispatch) => {
    dispatch({
        type: ADD_PEER_REQUEST
    });

    return api.addPeer(peer.id)
        .then(
            () => {
                dispatch({
                    type: ADD_PEER_SUCCESS,
                    payload: {
                        peer
                    }
                });
            },
            error => {
                dispatch({
                    type: ADD_PEER_FAILURE,
                    payload: {
                        ...error
                    }
                });

                return Promise.reject(error);
            }
        );
};

export const removePeer = (peerId) => (dispatch) => {
    dispatch({
        type: REMOVE_PEER_REQUEST
    });

    return api.removePeer(peerId)
        .then(
            () => {
                dispatch({
                    type: REMOVE_PEER_SUCCESS,
                    payload: {
                        peerId
                    }
                });
            },
            error => {
                dispatch({
                    type: REMOVE_PEER_FAILURE,
                    payload: {
                        ...error
                    }
                });

                return Promise.reject(error);
            }
        );
};

export const resetPeers = () => (dispatch) => {
    dispatch({
        type: RESET_PEERS_REQUEST
    });

    return api.resetPeers()
        .then(
            res => {
                dispatch({
                    type: RESET_PEERS_SUCCESS,
                    payload: {
                        ...res.data.payload
                    }
                });
            },
            error => {
                dispatch({
                    type: RESET_PEERS_FAILURE,
                    payload: {
                        ...error
                    }
                });

                return Promise.reject(error);
            }
        );
};

export const clearFoundPeers = () => (dispatch) => {
    dispatch({
        type: CLEAR_FOUND_PEERS
    });
};
