import PropTypes from 'prop-types';
import React from 'react';

import ColoredSquare from '../ColoredSquare/ColoredSquare';
import { NullableFormatter } from 'Components/formatters';

import './ColoredCell.scss';

const ColoredCell = ({
    value,
    rowData,
    CustomFormatter,
    withTitle,
    ellipsis
}) => {
    const CustomComponent = CustomFormatter
        ? (<CustomFormatter
            withTitle={withTitle}
            value={value}
            rowData={rowData}/>)
        : (<NullableFormatter
            value={value}
            withTitle={withTitle}/>);

    return (
        <div className='colored-cell'>
            <ColoredSquare value={rowData.color}/>
            {ellipsis
                ? <div className='ellipsis'>
                    {CustomComponent}
                </div>
                : CustomComponent
            }
        </div>
    );
};

ColoredCell.propTypes = {
    value: PropTypes.any,
    rowData: PropTypes.object,
    CustomFormatter: PropTypes.func,
    withTitle: PropTypes.bool,
    ellipsis: PropTypes.bool
};
ColoredCell.defaultProps = {
    rowData: {},
    ellipsis: true
};

export default ColoredCell;
