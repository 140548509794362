import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import './AddNoteModal.scss';

const mapStateToProps = (state, ownProps) => {
    return { ...state.modal, ownProps };
};

@connect(mapStateToProps)
class AddNoteModal extends Component {
    static propTypes = {
        hideModal: PropTypes.func
    };

    render() {
        const { hideModal } = this.props;

        return (
            <form className='add-note-modal'>
                <div className='date'>today</div>
                <textarea name='text' className='modal-input' rows='8'/>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        hideModal();
                    }}
                    type='submit'
                    className='btn'>
                    <FormattedMessage id='notes.addNote'/>
                </button>
            </form>);
    }
}
export default AddNoteModal;
