import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import { injectIntl } from 'react-intl';

import ReportScheduler from './ReportScheduler';

const mapStateToProps = (state, props) => ({
    formValues: getFormValues(props.formName)(state)
});

const mapDispatchToProps = {
    change
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ReportScheduler);
