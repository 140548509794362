import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

export default value => {
    if (_.isNumber(value) && value <= 0) {
        return <FormattedMessage id='shareholder.list.noOfRecords.error'/>;
    }

    return null;
};
