import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { runReport } from 'State/exportReports';
import { showClassicShareholdersExportReportModal } from 'State/modal';
import reportTemplate from 'Constants/report-types';
import reportExportFormats from 'Constants/export-report-formats';
import Icon from 'Components/Icon';

import './ShareholderClassicPdfLink.scss';

const mapDispatchToProps = {
    showClassicShareholdersExportReportModal,
    runReport
};

@connect(null, mapDispatchToProps)
class ShareholderClassicPdfLink extends Component {
    static propTypes = {
        runReport: PropTypes.func,
        showClassicShareholdersExportReportModal: PropTypes.func
    };

    handleGetClassic = () => {
        this.props.showClassicShareholdersExportReportModal({
            titleKey: 'modals.exportReport.title',
            onSuccess: ({ isSummary }) => this.props.runReport(
                reportTemplate.classicShareholderListTemplate,
                null,
                reportExportFormats.PDF,
                { summaryOnly: isSummary })
        });
    };

    render() {
        return (
            <div className='classic-pdf-report'>
                <Icon className='font-icon font-icon-grid action-icon'/>
                <span className='classic-pdf-link' onClick={this.handleGetClassic}>
                    <FormattedMessage id='saved.reports.classicShareholderList'/>
                </span>
            </div>
        );
    }
}

export default ShareholderClassicPdfLink;
