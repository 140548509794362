import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cn from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import { despace } from 'Utils/string';
import getSuggestionsEnhancer from './getSuggestionsEnhancer';
import { searchMinLength } from 'Constants/suggestions';
import regex from 'Constants/regex';

import './AutoSuggest.scss';

const selectProps = {
    loadingPlaceholder: null,
    searchPromptText: null,
    clearable: false,
    autosize: false,
    filterOptions: false,
    removeSelected: false
};

@injectIntl
@getSuggestionsEnhancer()
class AutoSuggest extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        name: PropTypes.string,
        request: PropTypes.func,
        cancelRequest: PropTypes.func,
        isLoading: PropTypes.bool,
        onSelect: PropTypes.func,
        noResultsText: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]),
        requestParams: PropTypes.object,
        className: PropTypes.string,
        placeholder: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]),
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ]),
        disabled: PropTypes.bool,
        timeout: PropTypes.number,
        maxLength: PropTypes.number,
        optionRenderer: PropTypes.func,
        children: PropTypes.func,
        showNoResult: PropTypes.bool,
        onKeyDown: PropTypes.func,
        intl: intlShape,
        tabSelectsValue: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        placeholder: '',
        optionRenderer: undefined,
        maxLength: 130,
        showNoResult: false,
        tabSelectsValue: true
    };

    state = {
        options: undefined
    };

    handleChange = selectedOption => {
        this.select.setState({
            inputValue: selectedOption.label
        });
        this.props.onSelect(selectedOption.label);
    };

    handleInputChange = inputValue => {
        const searchString = despace(inputValue);
        const { intl: { formatMessage } } = this.props;
        const alphanumericSearchStringLength = searchString.length - searchString.replace(regex.alphanumeric, '').length;

        this.props.onChange(inputValue);

        if (!inputValue && !searchString) {
            this.setOptions([]);
            this.props.cancelRequest();
        } else if (alphanumericSearchStringLength < 3) {
            this.setOptions([
                {
                    label: formatMessage({ id: 'autoSuggest.minLength' }, { value: searchMinLength }),
                    disabled: true
                }
            ]);
            this.props.cancelRequest();
        } else {
            this.props.request({
                query: searchString,
                ...this.props.requestParams
            }).then(this.setOptions);
        }
    };

    setOptions = options => this.setState({ options });

    getSelect = select => {
        this.select = select;
    };

    clearValue = () => {
        this.props.cancelRequest();
        this.setOptions(undefined);
    };

    arrowRenderer = () => (<i className='font-icon'/>);

    render() {
        const { options } = this.state;
        const {
            isLoading,
            noResultsText,
            className,
            disabled,
            placeholder,
            showNoResult,
            label,
            optionRenderer,
            maxLength,
            children,
            onKeyDown,
            tabSelectsValue
        } = this.props;

        return (
            <div className={cn('auto-suggest', className)}>
                {label && <label className='auto-suggest-label' htmlFor={name}>{label}</label>}
                <Select
                    ref={this.getSelect}
                    disabled={disabled}
                    options={options}
                    isLoading={isLoading}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    placeholder={placeholder}
                    noResultsText={isLoading || !options || !showNoResult ? null : noResultsText}
                    arrowRenderer={this.arrowRenderer}
                    optionRenderer={optionRenderer}
                    inputProps={{ maxLength }}
                    onInputKeyDown={onKeyDown}
                    tabSelectsValue={tabSelectsValue}
                    {...selectProps}/>
                {children && children({
                    clearValue: this.clearValue
                })}
            </div>
        );
    }
}

export default AutoSuggest;
