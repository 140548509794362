import PropTypes from 'prop-types';
import React from 'react';
import { Field, propTypes as reduxFormPropTypes } from 'redux-form';
import { FormattedMessage, intlShape } from 'react-intl';

import { RadioButtonsListField } from 'Components/RadioButtonsList';
import { reportNameNormalize } from '../../common/reportNameUtils';
import { InputDateRangePickerField } from 'Components/InputDateRangePicker';
import TextInput from 'Components/TextInput';
import ReportingActionsSections from '../../common/ReportingActionSection';
import LimitNote from 'Components/LimitNote';

import './ReportingShareDetailPage.scss';

const ReportingShareDetailPageView = props => {
    const {
        isEditMode,
        dateRangeOptions,
        shareTypesOptions,
        shareStatusOptions,
        handleSubmit,
        onGetExcel,
        onSubmit,
        invalid,
        dirty,
        submitting,
        isReportFetching,
        formatFetchingReport,
        intl: { formatMessage }
    } = props;

    return (
        <div className='reporting-share-detail-page'>
            {!isEditMode &&
                <h2 className='reporting-header'>
                    <FormattedMessage id='reporting.share.detail.title'/>
                </h2>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditMode &&
                    <div className='reporting-edit'>
                        <div className='reporting-edit-title'>
                            <FormattedMessage id='reporting.share.detail.edit'/>:
                        </div>
                        <Field
                            name='formReportName'
                            component={TextInput}
                            className='reporting-edit-title-input'
                            tooltipMessage={formatMessage({ id: 'reporting.templateNameInputTooltip' })}
                            normalize={reportNameNormalize}/>
                    </div>
                }
                <div className='reporting-section'>
                    <div className='reporting-section-header'>
                        <FormattedMessage id='reporting.share.detail.shareType.title'/>
                    </div>
                    <div className='reporting-section-body'>
                        <RadioButtonsListField
                            name='shareType'
                            list={shareTypesOptions}/>
                    </div>
                </div>
                <div className='reporting-section'>
                    <div className='reporting-section-header'>
                        <FormattedMessage id='reporting.share.detail.status.title'/>
                    </div>
                    <div className='reporting-section-body'>
                        <RadioButtonsListField
                            name='shareStatus'
                            list={shareStatusOptions}/>
                    </div>
                </div>
                <div className='reporting-section'>
                    <div className='reporting-section-body date-range-wrapper'>
                        <FormattedMessage id='datePicker.dateRange'/>
                        <InputDateRangePickerField
                            className='shares-date-range'
                            dateRangeOptions={dateRangeOptions}/>
                        <span className='message-note'>
                            (<FormattedMessage id='common.required'/>)
                        </span>
                    </div>
                </div>
                <ReportingActionsSections
                    isEditMode={isEditMode}
                    dirty={dirty}
                    disabled={submitting || invalid}
                    isReportFetching={isReportFetching}
                    formatFetchingReport={formatFetchingReport}
                    handleGetExcel={onGetExcel}/>
                {onGetExcel ? <LimitNote/> : null}
            </form>
        </div>
    );
};

ReportingShareDetailPageView.propTypes = {
    ...reduxFormPropTypes,
    onSubmit: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    reportName: PropTypes.string,
    handleSubmit: PropTypes.func,
    onDateChangeStart: PropTypes.func,
    onDateChangeEnd: PropTypes.func,
    formValues: PropTypes.object,
    shareTypesOptions: PropTypes.array,
    dateRangeOptions: PropTypes.object,
    shareStatusOptions: PropTypes.array,
    isReportFetching: PropTypes.bool,
    formatFetchingReport: PropTypes.string,
    intl: intlShape
};

export default ReportingShareDetailPageView;
