import React from 'react';
import { FormattedMessage } from 'react-intl';

import './CombineShareholdersPage.scss';

export default () => (
    <div className='combine-shareholders-page'>
        <div className='nav-panel'/>
        <div className='page-content'>
            <div className='content-header'>
                <FormattedMessage id='combineShareholders.header'/>
            </div>
            <div className='message-note'>
                <FormattedMessage id='combineShareholders.successfulMessage'/>
            </div>
        </div>
    </div>
);
