import _ from 'lodash';

import slotTypes from 'Constants/slot-types';

/**
 * Generate unique id for widget, within the application
 * @return {Number} widget id
 */
export function generateWidgetId() {
    return Number(_.uniqueId());
}

/**
 * We are removing gaps between slots and move them to the end
 * @param state
 * @param slotId
 * @returns {{slots: {}, availableSlots: {}}} - updated state
 */
export function shrinkWidgets(state, { payload: { slotId } }) {
    // locked slotIds around removedSlot
    const visibleSlots = _.omit(state.slots, '-1');
    const slotsBounds = getSlotsBounds(visibleSlots, slotId);

    // get Array of objects with slots, which, potentially, can be shrinked
    const slotsSegment = getSlotsSegment(state.slots, slotsBounds);

    const shrinked = shrinkSlots(slotsSegment, slotsBounds);

    return {
        ...state,
        slots: { ...state.slots, ...shrinked }
    };
}

export function deleteEmptySlots(state) {
    const newSlots = {};
    const slots = state.slots;

    _.mapKeys(slots, (slot, i) => {
        if (slots[i].widgets.length || slots[i].isLocked) {
            newSlots[i] = slots[i];
        }
    });

    return {
        ...state,
        slots: newSlots
    };
}

export function getSlotsWithDefaultSelected(slots, widgets, defaultSelected) {
    // If no slot selected and array-like object has more than one
    // element than we set element with key '2' of slots object as
    // selected
    if (!_.some(widgets, (widget) => widget.settings && widget.settings.isSelected)) {
        return Object.assign({}, slots, {
            [defaultSelected]: {
                ...slots[defaultSelected],
                isSelected: true
            }
        });
    }

    return _.mapValues(slots, (slot) => {
        const widgetId = _.get(slot, 'widgets[0]');

        return {
            ...slot,
            isSelected: _.get(widgets, [`${widgetId}`, 'settings', 'isSelected'])
        };
    });
}

export function getDefaultSlot(widgetId) {
    return {
        type: slotTypes.SINGLE,
        isLocked: false,
        widgets: widgetId ? [widgetId] : []
    };
}

/**
 * We have to get bounds (slot Ids - left and right) - between them we will eliminate gaps
 * @param availableSlots
 * @param slotId
 * @returns {{left: (number|*), right: (number|*)}}
 */
function getSlotsBounds(availableSlots, slotId) {
    const allLockedSlotIds = _.keysIn(_.pickBy(availableSlots, (slot) => {
        return slot.isLocked;
    }));

    const leftBound = _.max(_.filter(allLockedSlotIds, (lockedSlotId) => (lockedSlotId < slotId)));
    const rightBound = _.min(_.filter(allLockedSlotIds, (lockedSlotId) => (lockedSlotId > slotId))) - 1;

    return {
        left: _.parseInt(leftBound || _.findKey(availableSlots)),
        right: _.parseInt(rightBound || _.findLastKey(availableSlots))
    };
}

/**
 * Return slots between bounds
 * @param slots
 * @param lockedSlotsBounds
 * @returns {*|{}}
 */
function getSlotsSegment(slots, lockedSlotsBounds) {
    const { left, right } = lockedSlotsBounds;

    return _.pickBy(slots, (slot, key) => {
        return key >= left && key <= right;
    });
}

/**
 * #functional programming.
 * Removing gaps between slots
 * We should reorder availableSlots too
 * @param slotsSegment
 * @param bounds
 * @returns {*}
 */
function shrinkSlots(slotsSegment, bounds) {
    const missedSlotId = getMissedSlotId(slotsSegment, bounds);

    if (missedSlotId) {
        const slots = moveToTheEnd(slotsSegment, missedSlotId);
        // const availableSlots = moveToTheEnd(shrinked.availableSlots, missedSlotId);

        return shrinkSlots(slots, bounds);
    }

    return slotsSegment;
}

/**
 * Returns missed slotId between bounds or false if not found
 * @param slots
 * @param bounds
 * @returns {*}
 */
function getMissedSlotId(slots, bounds) {
    const missedSlotId = _.findKey(_.pickBy(slots, (slot) => {
        return !slot.widgets.length && !slot.isLocked;
    }));
    const nextSlotId = _.parseInt(missedSlotId) + 1;

    if (nextSlotId <= bounds.right && !isEmptySlot(slots[nextSlotId])) {
        return missedSlotId;
    }

    return false;
}

function isEmptySlot(slot) {
    return _.get(slot, 'widgets').length === 0;
}

/**
 * Moving slot with id = slotId to the end
 * @param slots
 * @param slotId
 * @returns {{}}
 */
function moveToTheEnd(slots, slotId) {
    const tmp = slots[slotId];
    const res = {};
    const keysLength = Object.keys(slots).length;

    for (let i = 1; i <= keysLength; i++) {
        if (i < slotId) {
            res[i] = slots[i];
        } else {
            res[i] = slots[i + 1];
        }
    }
    res[keysLength] = tmp;

    return res;
}

/**
 * Checking widgets on presence of incorrect position
 * @param widgets
 * @param slots
 * @param isHiddenWidget
 * @param lastWidgetPosition
 * @returns bool
 */
export function isIncorrectPositions(widgets, slots, isHiddenWidget, lastWidgetPosition) {
    let isIncorrectWidgetPosition = true;
    const isHole = isHiddenWidget ? widgets.length !== lastWidgetPosition + 1
        : widgets.length !== lastWidgetPosition;
    const lockedSlotPosition = +_.findKey(slots, s => s.isLocked);

    if (isHole) {
        const amountOfEmptySlots = lastWidgetPosition - widgets.length;

        if (lockedSlotPosition !== -1) {
            for (let i = lockedSlotPosition - 1; i >= lockedSlotPosition - amountOfEmptySlots; i -= 1) {
                isIncorrectWidgetPosition = !!_.find(widgets, w => w.position === i);

                if (isIncorrectWidgetPosition) {
                    return isIncorrectWidgetPosition;
                }
            }
        }
    }

    return isHole && isIncorrectWidgetPosition;
}

/**
 * Fixing widget's position if we have some missing position
 * @param widgets
 * @param slots
 * @param isHiddenWidget
 * @returns []
 */
export function fixWidgetsPositions(widgets, slots, isHiddenWidget) {
    const sourceWidgets = _.sortBy(widgets, ['position']);

    for (let i = 0; i < sourceWidgets.length; i++) {
        const slot = slots[sourceWidgets[i].position];

        if (slot && slot.isLocked) continue;

        if (isHiddenWidget) {
            sourceWidgets[i].position = i || -1;
        } else {
            sourceWidgets[i].position = i + 1;
        }
    }

    return sourceWidgets;
}
