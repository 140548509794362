import Logger from 'js-logger';
import moment from 'moment';

import { isDevelopmentEnv } from '../utils/utils';
import store from './store';
import { getUserInfoSelector } from '../state/user/selectors';
import { getAppVersion } from '../state/appVersion/selectors';
import logsLocalStorage from './logsLocalStorage';
import { ISP_USER_ID } from 'Constants/logs-constants';
import dateFormats from 'Constants/date-formats';

const level = isDevelopmentEnv(__ENV__) ? Logger.DEBUG : Logger.WARN;

Logger.setLevel(level);

Logger.setHandler((messages, context) => {
    const logItem = messages[0];

    if (logItem) {
        const state = store.getState();

        logItem.timestamp = Date.now();
        logItem.date = moment().format(dateFormats.LOG_DATE_FORMAT);
        logItem.userInfo = getUserInfoSelector(state);
        logItem.userLogin = localStorage.getItem(ISP_USER_ID);
        logItem.level = context.level.value;
        logItem.userAgent = window.navigator.userAgent;
        logItem.url = window.location.href;
        logItem.isFlushedToServer = false;
        logItem.appVersion = getAppVersion(state);
        logsLocalStorage.addLogItem(logItem);
    }
});

export default Logger;
