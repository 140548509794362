import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { loadDynamicPage, destroyDynamicPage } from 'State/dynamicPage/actions';
import { DynamicLayout } from '../DynamicLayout';
import layoutTypes from 'Constants/layout-types';
import { pageViewEvent } from '../../__ga__/events';

const mapDispatchToProps = { loadDynamicPage, destroyDynamicPage };

@connect(null, mapDispatchToProps)
class DynamicPage extends React.Component {
    static propTypes = {
        dynamicPageType: PropTypes.string.isRequired,
        params: PropTypes.object,
        settings: PropTypes.object,
        layoutType: PropTypes.number,
        slots: PropTypes.object,

        canRender: PropTypes.bool,
        loadDynamicPage: PropTypes.func,
        destroyDynamicPage: PropTypes.func
    };

    static defaultProps = {
        layoutType: layoutTypes.layoutEasy
    };

    state = {
        isCanRender: false
    };

    componentDidMount() {
        this.props.loadDynamicPage(this.props.dynamicPageType, this.props.params, this.props.slots, this.props.settings)
            .then(() => {
                this.setState({ isCanRender: true });
                pageViewEvent(this.props.dynamicPageType);
            });
    }

    componentDidUpdate(prevProps) {
        const { dynamicPageType, params, settings, slots } = this.props;

        if (!_.isEqual(params, prevProps.params) || settings !== prevProps.settings) {
            this.setState({ isCanRender: false });
            this.props.destroyDynamicPage();
            this.props.loadDynamicPage(dynamicPageType, params, slots, settings).then(() => {
                this.setState({ isCanRender: true });
                pageViewEvent(this.props.dynamicPageType);
            });
        }
    }

    componentWillUnmount() {
        this.props.destroyDynamicPage();
    }

    render() {
        return this.state.isCanRender ? <DynamicLayout layoutType={this.props.layoutType}/> : null;
    }
}

export default DynamicPage;
