import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import HistoricalAnalysisTableHeader from './components/HistoricalAnalysisTableHeader';
import AnalysisTableControlPanel from '../_commonComponents/AnalysisControlPanel/AnalysisControlPanel';
import HistoricalAnalysisTableContent from './components/HistoricalAnalysisTableContent';
import { showInfoModal, showExportHistoricalAnalysisModal } from 'State/modal';
import { getTableData } from './historicalDataSelectors';
import { sortData } from '../_commonComponents/AnalysisTable/utils';
import { getWidgetDataSourceIdSelector } from 'State/widgets/selectors';

import './HistoricalAnalysisTable.scss';

const mapStateToProps = (state, props) => ({
    widgetDataSourceId: getWidgetDataSourceIdSelector(state, props.widgetName)
});

const mapDispatchToProps = {
    showExportHistoricalAnalysisModal,
    showInfoModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
@withProps(({ widgetSettings, widgetData, isPrintMode, intl }) => {
    /**
     * We need default sorting by position of last quarter.
     * Cause the last quarter is not constant,
     * we should set correct sortColumn if we can not find quarter
     */
    const newWidgetSettings = _.cloneDeep(widgetSettings);
    const sortColumn = _.get(widgetSettings, 'sortCriteria.sortColumn');
    const quarters = _.get(widgetData, 'quarters', []).map(p => p.date);

    if (quarters.length > 0
        && !quarters.includes(sortColumn)) {
        _.set(newWidgetSettings, 'sortCriteria.sortColumn', quarters[quarters.length - 1]);
    }

    return ({
        widgetTitle: intl.formatMessage({ id: 'historical.analysis.table.title' }),
        components: {
            WidgetHeader: HistoricalAnalysisTableHeader,
            WidgetControlPanel: isPrintMode ? null : AnalysisTableControlPanel,
            WidgetContent: HistoricalAnalysisTableContent
        },
        widgetSettings: newWidgetSettings
    });
})
class HistoricalAnalysisTable extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape,
        widgetDataSourceId: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.widgetEvents = {
            onBuildDataParams: this.onBuildDataParams
        };
    }

    handleExport = () => {
        const { widgetSettings, widgetData } = this.props;
        const selectedShareholders = _.get(widgetSettings, 'selectedShareholders', []);
        const sortCriteria = _.get(widgetSettings, 'sortCriteria');
        const data = getTableData(this.props);
        const sortedData = sortData(data, sortCriteria);
        const shareholderIds = sortedData.map(shareholder => shareholder.shareholderId);
        const quarters = widgetData.quarters;
        const dateParams = {
            minDate: moment(quarters[0].marketPrice[0].date),
            maxDate: moment(quarters[quarters.length - 1].date)
        };

        this.props.showExportHistoricalAnalysisModal({
            exportParams: {
                selectedShareholders,
                shareholderIds,
                maxYAxisValue: this.maxYAxisValue,
                priceAxisMinValue: this.priceAxisMinValue,
                priceAxisMaxValue: this.priceAxisMaxValue
            },
            dateParams
        });
    };

    onBuildDataParams = () => {
        return {
            selectedShareholders: this.props.widgetSettings.selectedShareholders || []
        };
    };

    render() {
        const widgetNoData = !_.get(this.props, 'widgetData.data.length');
        const noShareholders = !_.get(this.props, 'widgetSettings.selectedShareholders.length');

        const props = {
            ...this.props,
            widgetControlPanelSettings: {
                onExport: this.handleExport,
                inactive: widgetNoData || noShareholders
            },
            setAxisValues: (value, minPrice, maxPrice) => {
                this.maxYAxisValue = value;
                this.priceAxisMinValue = minPrice;
                this.priceAxisMaxValue = maxPrice;
            },
            widgetNoData
        };

        return (
            <Widget
                {...props}
                events={this.widgetEvents}
                className='widget-historical-analysis-table'/>
        );
    }
}

export default HistoricalAnalysisTable;
