import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { getContext } from '@shakacode/recompose';

import * as dynamicPageSelectors from 'State/dynamicPage/selectors';
import widgetModes from 'Constants/widget-modes';
import { commonWidgetPropTypes, commonWidgetActions } from '../../utils';
import WidgetAddToReport from './WidgetAddToReport';
import WidgetChange from './WidgetChange';
import WidgetRemove from './WidgetRemove';
import Icon from 'Components/Icon';
import featureTypes from 'Constants/feature-types';

const makeMapStateToProps = (initialState, { slotId }) => {
    const availableWidgetTypesSelector = dynamicPageSelectors.makeAvailableWidgetTypesSelector(slotId);

    return state => ({
        availableWidgets: availableWidgetTypesSelector(state, slotId),
        blockAction: dynamicPageSelectors.getDynamicPageBlockActionSelector(state)
    });
};

@getContext({
    events: PropTypes.object
})
@connect(makeMapStateToProps)
class ShareholderWidgetControlPanel extends Component {
    static propTypes = {
        ...commonWidgetActions,
        ...commonWidgetPropTypes,
        availableWidgets: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                isSelected: PropTypes.bool.isRequired
            })
        ).isRequired,
        isTogglingModeEnabled: PropTypes.bool,
        isTogglingModeBlocked: PropTypes.bool,
        isExportEnabled: PropTypes.bool
    };

    static defaultProps = {
        isTogglingModeEnabled: true,
        isTogglingModeBlocked: false,
        isExportEnabled: false
    };

    handleChangeView = () => {
        const mode = this.props.widgetSettings.mode === widgetModes.TABLE ? widgetModes.CHART : widgetModes.TABLE;

        this.props.onUpdateWidgetSettings({ ...this.props.widgetSettings, mode });
    };

    render() {
        const {
            isLocked,
            blockAction,
            onChangeWidget,
            onRemoveWidget,
            availableWidgets,
            widgetSettings: { mode },
            widgetControlPanelSettings: {
                widgetChartTypeIcon = 'font-icon-chart',
                widgetTableTypeIcon = 'font-icon-view-list',
                isWidgetTypeIconActive
            },
            isTogglingModeEnabled,
            isTogglingModeBlocked,
            isAddToReportDisabled,
            isExportEnabled,
            widgetNoData,
            isDataViz,
            widgetType,
            currentFeatures
        } = this.props;
        const isWidgetTypeIconInactive = (widgetNoData && !isWidgetTypeIconActive) || isTogglingModeBlocked;
        const activeIconClass = cn('font-icon', {
            [widgetTableTypeIcon]: mode === widgetModes.CHART,
            [widgetChartTypeIcon]: mode === widgetModes.TABLE,
            inactive: isWidgetTypeIconInactive
        });
        const exportIconClasses = cn('font-icon font-icon-download', { inactive: widgetNoData });

        return (
            <div className='widget-control-panel'>
                {!isDataViz && isExportEnabled &&
                <Icon className={exportIconClasses} onClick={widgetNoData ? null : this.props.events.onExport}/>}
                {isTogglingModeEnabled && (isWidgetTypeIconInactive
                    ? <Icon className={activeIconClass}/>
                    : <Icon onClick={this.handleChangeView} className={activeIconClass}/>)}
                {currentFeatures[featureTypes.CUSTOM_REPORTS] && !isDataViz &&
                <WidgetAddToReport
                    isDisabled={isAddToReportDisabled}
                    onAddToReport={this.props.onAddWidgetToDataVizReport}
                    dataParamsBuilder={this.props.events.onBuildDataParams}/>}
                {!isDataViz
                    ? <WidgetChange
                        isLocked={isLocked}
                        blockAction={blockAction}
                        availableWidgets={availableWidgets}
                        onChangeWidget={onChangeWidget}
                        onRemoveWidget={onRemoveWidget}
                        slotProps={{
                            widget: widgetType
                        }}/>
                    : <WidgetRemove
                        blockAction={blockAction}
                        onRemoveWidget={onRemoveWidget}/>}
            </div>
        );
    }
}

export default ShareholderWidgetControlPanel;
