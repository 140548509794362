import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { DynamicPage } from 'Components/DynamicPage';
import pageTypes from 'Constants/dynamic-page-types';
import layoutTypes from 'Constants/layout-types';
import ReportingDataVizReportPageControlPanel from './ReportingDataVizReportPageControlPanel';

import './../ReportingDataVizReportPage.scss';

const dynamicPageSettings = {
    isDataViz: true
};

export default class ReportingDataVizReportPage extends Component {
    static propTypes = {
        dynamicPageSetDataViz: PropTypes.func,
        reportTemplateId: PropTypes.number,
        match: PropTypes.object
    };

    render() {
        const params = {
            reportTemplateId: +_.get(this.props.match.params, 'id') || null
        };

        return (
            <div className='reporting-dataviz-report-page'>
                <h2 className='reporting-header'>
                    <FormattedMessage id='reporting.dataVizReport.title'/>
                </h2>
                <ReportingDataVizReportPageControlPanel
                    reportTemplateId={params.reportTemplateId}
                    isStarted={params.reportTemplateId === null}
                    {...this.props}/>
                <div className='section-title'>
                    <FormattedMessage id='reporting.dataVizReport.section.title'/>
                </div>
                <DynamicPage
                    layoutType={layoutTypes.layoutDataViz}
                    params={params}
                    settings={dynamicPageSettings}
                    dynamicPageType={pageTypes.REPORTING_DATAVIZ_REPORT}/>
            </div>
        );
    }
}
