import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const ShareTooltip = ({ reportName }) => {
    return <span><FormattedMessage id='saved.reports.share' values={{ reportName }}  /></span>;
};

ShareTooltip.propTypes = {
    reportName: PropTypes.string
};

export default ShareTooltip;
