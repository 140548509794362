/* eslint-disable react/no-multi-comp */
import React from 'react';

import { CellWithLink, CellWithNumber } from 'Components/widgets/_commonComponents/TableCellComponents';
import sortDirections from 'Constants/sort-directions';
import pageRoutes from 'Constants/page-routes';
import shareholderType from 'Constants/shareholder-types';

const columnNames = {
    NAME: 'name',
    EQUITY_AUM: 'equityAum',
    HOLDER_TYPE: 'holderType',
    STYLE: 'style',
    TURNOVER: 'turnover',
    PHONE: 'phone',
    WEBSITE: 'website',
    CITY: 'city',
    COUNTRY: 'country'
};

// this values expect DB for sorting
const sortProps = {
    NAME: 'Name',
    EQUITY_AUM: 'Equity_Aum',
    HOLDER_TYPE: 'Holder_Type',
    STYLE: 'Style',
    TURNOVER: 'Turnover',
    PHONE: 'Phone1',
    WEBSITE: 'Website',
    CITY: 'City',
    COUNTRY: 'Country'
};

const columns = [
    {
        displayName: 'institutionsTable.columns.name',
        columnName: columnNames.NAME,
        manageable: false,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.NAME,
        cellComponent: cellProps => {
            const { value: cellValue, row } = cellProps;

            return (
                <CellWithLink
                    to={`${pageRoutes[shareholderType.INSTITUTION]}/${row.shareholderId}`}
                    value={cellValue}
                    returnPath={pageRoutes.ownershipContactsInstitutions}/>
            );
        }
    },
    {
        displayName: 'institutionsTable.columns.equityAum',
        columnName: columnNames.EQUITY_AUM,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.EQUITY_AUM,
        cellComponent: CellWithNumber
    },
    {
        displayName: 'institutionsTable.columns.holderType',
        columnName: columnNames.HOLDER_TYPE,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.HOLDER_TYPE
    },
    {
        displayName: 'institutionsTable.columns.style',
        columnName: columnNames.STYLE,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.STYLE
    },
    {
        displayName: 'institutionsTable.columns.turnover',
        columnName: columnNames.TURNOVER,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.TURNOVER
    },
    {
        displayName: 'institutionsTable.columns.phone',
        columnName: columnNames.PHONE,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.PHONE
    },
    {
        displayName: 'institutionsTable.columns.website',
        columnName: columnNames.WEBSITE,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.WEBSITE,
        cellComponent: cellProps => {
            const { value: cellValue } = cellProps;
            let link;

            if (cellValue && !/(^http(s)?:\/\/)/.test(cellValue)) {
                link = `http://${cellValue}`;
            } else {
                link = cellValue;
            }

            return (
                <CellWithLink
                    to={link}
                    isExternal
                    value={cellValue}/>
            );
        }
    },
    {
        displayName: 'institutionsTable.columns.city',
        columnName: columnNames.CITY,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.CITY
    },
    {
        displayName: 'institutionsTable.columns.country',
        columnName: columnNames.COUNTRY,
        manageable: true,
        initialSortDirection: sortDirections.ASC,
        sortProp: sortProps.COUNTRY,
        additionalData: {
            alignCenter: true
        }
    }
];

const columnsOrder = {
    [columnNames.NAME]: 1,
    [columnNames.EQUITY_AUM]: 2,
    [columnNames.HOLDER_TYPE]: 3,
    [columnNames.STYLE]: 4,
    [columnNames.TURNOVER]: 5,
    [columnNames.PHONE]: 6,
    [columnNames.WEBSITE]: 7,
    [columnNames.CITY]: 8,
    [columnNames.COUNTRY]: 9
};

export default {
    columns,
    columnNames,
    columnsOrder,
    sortProps
};
