import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Icon from 'Components/Icon';
import { buildLocationWithSafeQueryParams } from 'Utils/routing';
import pageRoutes from 'Constants/page-routes';

import './WidgetAddToReport.scss';

@injectIntl
@withRouter
class WidgetAddToReport extends Component {
    static propTypes = {
        onAddToReport: PropTypes.func,
        dataParamsBuilder: PropTypes.func,
        isDisabled: PropTypes.bool,
        className: PropTypes.string,
        intl: intlShape,
        history: PropTypes.object,
        location: PropTypes.object
    };

    state = {
        isDisabled: false
    };

    handleOnClickAddToReport = () => {
        if (this.state.isDisabled || this.props.isDisabled) return;

        const { dataParamsBuilder, onAddToReport } = this.props;
        const params = _.isFunction(dataParamsBuilder) ?
            dataParamsBuilder({ isAddToDataViz: true })
            : undefined;

        this.setState({ isDisabled: true });
        onAddToReport(params)
            .then(this.onAddToReportSuccess, this.onAddToReportError);
    };

    handleRedirectToDataViz = (removeToastr) => {
        removeToastr();
        this.props.history.push(buildLocationWithSafeQueryParams(pageRoutes.dataViz, this.props.location));
    };

    onAddToReportSuccess = (res) => {
        const isWidgetReplaced = _.get(res, 'data.payload.isReplaced');
        const title = this.props.intl.formatMessage({ id: 'widgets.notifier.success' });
        const message = this.props.intl.formatMessage({
            id: isWidgetReplaced ? 'widgets.notifier.addToReportReplaced' : 'widgets.notifier.addToReportSuccess'
        });

        this.setState({ isDisabled: false });
        toastr.success(title, message, {
            component: ({ remove }) => (
                <a
                    className='dataviz-report-link'
                    onClick={() => this.handleRedirectToDataViz(remove)}>
                    <FormattedMessage id='widgets.notifier.seeDataViz'/>
                </a>
            )
        });
    };

    onAddToReportError = () => {
        this.setState({ isDisabled: false });
    };

    render() {
        return (
            <Icon
                className={cn('font-icon font-icon-add-to-report', { 'inactive': this.state.isDisabled || this.props.isDisabled }, this.props.className)}
                onClick={this.handleOnClickAddToReport}/>
        );
    }
}

export default WidgetAddToReport;
