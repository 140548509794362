export const isSameSelectedSecurities = (newSecurities = [], prevSecurities = []) => {
    if (newSecurities.length !== prevSecurities.length) {
        return false;
    }

    let isSame = true;

    newSecurities.forEach(s => {
        if (!prevSecurities.includes(s)) {
            isSame = false;
        }
    });

    return isSame;
};
