import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import { injectIntl } from 'react-intl';
import { notifyError } from 'State/notifier';
import { postMessageTypeMap } from '../constants/post-message-types';

export const withDownloadFileError = compose(
    injectIntl,
    connect(),
    mapProps((ownerProps) => {
        const { intl, dispatch, ...otherProps } = ownerProps;

        return {
            ...otherProps,
            notifyDownloadError: (err) => {
                const messageType = _.get(err, 'messageType');

                if (messageType === postMessageTypeMap.FILE_NOT_FOUND) {
                    dispatch(notifyError(
                        null,
                        intl.formatMessage({ id: 'downloadDocument.notifier.fileNotFound' })
                    ));

                    return;
                }

                if (messageType === postMessageTypeMap.EXTERNAL_LINK) {
                    const url = _.get(err, 'url');

                    if (url) {
                        window.open(url, '_blank');
                    }

                    return;
                }

                dispatch(notifyError(
                    null,
                    intl.formatMessage({ id: 'error.requestFailed' })
                ));
            }
        };
    })
);
