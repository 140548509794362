import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DynamicPage } from 'Components/DynamicPage';
import PAGE_TYPES from 'Constants/dynamic-page-types';
import InstitutionalOverViewPageHeader from './containers/InstitutionalOverviewPageHeader';
import slots from './slots';
import layoutTypes from 'Constants/layout-types';
import recentSearchPageViewEvent from 'Hoc/recentSearchPageViewEvent';

import './InstitutionalOverview.scss';

class InstitutionalOverview extends Component {
    static propTypes = {
        match: PropTypes.object
    };

    render() {
        const dynamicPageType = PAGE_TYPES.INSTITUTIONAL_OVERVIEW;

        return (
            <div className='institutional-overview-page empty-space-container'>
                <InstitutionalOverViewPageHeader/>
                <DynamicPage
                    layoutType={layoutTypes.layoutThreeColumns}
                    slots={slots}
                    dynamicPageType={dynamicPageType}
                    params={this.props.match.params}/>
            </div>
        );
    }
}

export default recentSearchPageViewEvent()(InstitutionalOverview);
