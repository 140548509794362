import PropTypes from 'prop-types';
import React from 'react';

import { NumberFormatter } from '../../../formatters';

const numberRenderer = ({ value }) => {
    return (
        <NumberFormatter value={value} isOriginalValue/>
    );
};

numberRenderer.propTypes = {
    value: PropTypes.any
};

export default numberRenderer;
