import React from 'react';
import PropTypes from 'prop-types';

const HistoricalAnalysisTableHeader = ({ widgetTitle }) => {
    return (
        <div className='widget-header'>
            <div className='widget-title'>{widgetTitle}</div>
        </div>
    );
};

HistoricalAnalysisTableHeader.propTypes = {
    widgetTitle: PropTypes.string
};

export default HistoricalAnalysisTableHeader;
