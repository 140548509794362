import React from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';

import Popover from 'Components/Popover';

import './RegisteredShareholdersNote.scss';

const RegisteredShareholdersNote = ({ intl: { formatMessage } }) => {
    const noteText = formatMessage({ id: 'shareholders.table.panel.registered.note' });

    const popoverProps = {
        id: 'REGISTERED-NOTE-POPOVER',
        trigger: (<i className='font-icon font-icon-info-outline'/>),
        triggerType: ['click', 'hover'],
        placement: 'top',
        containerPadding: 30
    };

    return (
        <div className='registered-shareholders-note'>
            <p className='message-note' title={noteText}>{noteText}</p>
            <Popover {...popoverProps}>
                <div className='registered-shareholders-note__popover-tip-content'>
                    <FormattedHTMLMessage id='shareholders.table.popover.partial.tip'/>
                </div>
            </Popover>
        </div>
    );
};

RegisteredShareholdersNote.propTypes = {
    intl: intlShape
};

export default injectIntl(RegisteredShareholdersNote);
