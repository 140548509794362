import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';
import { propTypes as reduxFormPropTypes } from 'redux-form';

import { RadioButtonsListField } from 'Components/RadioButtonsList';
import { InputDateRangePickerField } from 'Components/InputDateRangePicker';
import reportExportFormats from 'Constants/export-report-formats';
import fileFormats from 'Constants/file-formats';
import exportViewTypes from '../constants/export-view-types';
import formFields from '../constants/form-fields';

import './ExportHistoricalAnalysisModal.scss';

const formats = [{
    label: fileFormats[reportExportFormats.EXCEL],
    value: reportExportFormats.EXCEL,
    position: 'block'
}, {
    label: fileFormats[reportExportFormats.PDF],
    value: reportExportFormats.PDF,
    position: 'block'
}];

const ExportHistoricalAnalysisModalView = ({
    intl: { formatMessage },
    isDateRangeDisabled,
    dateParams,
    onChangeTypeView,
    onExport,
    onCancel,
    handleSubmit,
    submitting,
    invalid
}) => {
    const viewTypes = [
        {
            label: formatMessage({ id: 'modals.exportReport.historicalAnalysis.displayed' }),
            value: exportViewTypes.DISPLAYED,
            position: 'block'
        },
        {
            label: formatMessage({ id: 'modals.exportReport.historicalAnalysis.dateRange' }),
            value: exportViewTypes.DATE_RANGE,
            position: 'block'
        }
    ];
    const dateRangeOptions = {
        startDateOptions: {
            label: formatMessage({ id: 'datePicker.from' }),
            name: formFields.START_DATE,
            disabled: isDateRangeDisabled,
            ...dateParams,
            openToDate: dateParams.minDate
        },
        endDateOptions: {
            label: formatMessage({ id: 'datePicker.to' }),
            name: formFields.END_DATE,
            disabled: isDateRangeDisabled,
            ...dateParams
        }
    };

    return (
        <form
            onSubmit={handleSubmit(onExport)}
            className='export-historical-analysis-modal export-report-modal'>
            <div className='view-type-container'>
                <RadioButtonsListField
                    name={formFields.SELECTED_VIEW_TYPE}
                    list={viewTypes}
                    onChange={onChangeTypeView}/>
                <InputDateRangePickerField
                    showYearDropdown={false}
                    showMonthDropdown={false}
                    dateRangeOptions={dateRangeOptions}/>
            </div>
            <div className='format-container'>
                <div className='format-label'>Select:</div>
                <RadioButtonsListField
                    name={formFields.SELECTED_FORMAT}
                    list={formats}/>
            </div>
            <div className='modal-actions'>
                <button
                    className='btn btn-large'
                    type='submit'
                    disabled={submitting || invalid}>
                    {formatMessage({ id: 'modals.exportReport.exportBtn' })}
                </button>
                <button
                    type='button'
                    onClick={onCancel}
                    className='btn btn-large'>
                    {formatMessage({ id: 'common.cancel' })}
                </button>
            </div>
        </form>
    );
};

ExportHistoricalAnalysisModalView.propTypes = {
    ...reduxFormPropTypes,
    intl: intlShape.isRequired,
    isDateRangeDisabled: PropTypes.bool,
    dateParams: PropTypes.shape({
        minDate: PropTypes.object,
        maxDate: PropTypes.object
    }),
    onChangeTypeView: PropTypes.func,
    onExport: PropTypes.func,
    onCancel: PropTypes.func
};

export default ExportHistoricalAnalysisModalView;
