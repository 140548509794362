import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import pageRoutes from 'Constants/page-routes';
import { getShareholdersToComparing } from 'State/compareShareholders/selectors';
import BackLink from 'Components/BackLink';

@connect((state) => ({
    shareholdersToCompare: getShareholdersToComparing(state)
}))
class CompareShareholdersPageHeader extends Component {
    static propTypes = {
        shareholdersToCompare: PropTypes.array
    };

    render() {
        const length = this.props.shareholdersToCompare.length;

        return (
            <div className='compare-shareholders-page-header'>
                <BackLink defaultTo={pageRoutes.searchResult}/>

                <h2 className='title'>
                    <FormattedMessage
                        id={length ? 'compare.shareholders.page.title' : 'compare.shareholders.page.error'}
                        values={{ value: length }}/>
                </h2>
            </div>
        );
    }
}

export default CompareShareholdersPageHeader;
