import React, { Component } from 'react';
import _ from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import columnsDefinitions, { secondarySorting } from './constants/columnsDefinitions';
import { RegisteredShareholderTable } from '../_commonComponents/RegisteredShareholderTable';
import { withPagination } from '../_commonComponents/RegisteredShareholderTable/hoc';
import InfoPanel from './components/InfoPanel';

@withPagination
class RegisteredShareholderPayments extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions
    };

    render() {
        const values = _.get(this.props, 'widgetData.values', []);

        return (
            <RegisteredShareholderTable
                {...this.props}
                secondarySorting={secondarySorting}
                data={values}
                columnsDefinitions={columnsDefinitions}
                WidgetInfoPanel={InfoPanel}
                noDataMessageId='registered.shareholder.priorTADRSHistory.noRecords'
                hideColumnsManager/>
        );
    }
}

export default RegisteredShareholderPayments;
