import PropTypes from 'prop-types';
import React, { Component } from 'react';

import AnalysisTurnoverHeaderView from '../components/AnalysisTurnoverHeaderView';

class AnalysisTurnoverHeader extends Component {
    static propTypes = {
        widgetTitle: PropTypes.string,
        showAnalysisTurnoverLegendModal: PropTypes.func.isRequired
    };

    onInfoIconClick = () => {
        this.props.showAnalysisTurnoverLegendModal({
            titleKey: 'modals.analysis.turnover.title'
        });
    };

    render = () => {
        return (<AnalysisTurnoverHeaderView
            {...this.props}
            handleInfoIconClick={this.onInfoIconClick} />);
    }
}

export default AnalysisTurnoverHeader;
