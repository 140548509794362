import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import AppHeader from './AppHeader';
import * as featuresSelectors from 'State/features/selectors';
import { getCustomSecurityIdSelector, getGlobalSettings } from 'State/user';

export default compose(
    withRouter,
    injectIntl,
    connect((state) => ({
        currentFeatures: featuresSelectors.getCurrentFeaturesSelector(state),
        globalSettings: getGlobalSettings(state),
        customSecurityId: getCustomSecurityIdSelector(state)
    }))
)(AppHeader);

