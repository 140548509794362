import React, { Component } from 'react';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { withState } from '@shakacode/recompose';
import { get } from 'lodash';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import OutstandingPaymentsContent from './components';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import PeriodActionPanel from 'Components/widgets/_commonComponents/PeriodActionPanel';
import { getDates, getSelectedDate, getFilteredData } from './selectors';

import './OutstandingPayments.scss';

@withState(
    'selectedDate',
    'onMonthChange'
)
@injectIntl
class OutstandingPayments extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetContent: OutstandingPaymentsContent,
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetActionPanel: PeriodActionPanel
        };

        this.widgetTitle = props.intl.formatMessage({ id: 'outstanding.payments.title' });
        this.widgetTitleTooltip = <FormattedHTMLMessage id='outstanding.payments.tooltip.title'/>;
    }

    render() {
        const dates = getDates(this.props);
        const selectedDate = getSelectedDate(this.props);
        const data = getFilteredData(this.props);
        const props = {
            ...this.props,
            widgetTitle: this.widgetTitle,
            widgetTitleTooltip: this.widgetTitleTooltip,
            widgetControlPanelSettings: {
                widgetChartTypeIcon: 'font-icon-chart',
                widgetTableTypeIcon: 'font-icon-chart deactivated'
            },
            widgetNoData: !get(data, 'values.length'),
            data,
            dates,
            selectedDate
        };

        return (
            <Widget
                {...props}
                className='widget-outstanding-payments'
                components={this.widgetComponents}/>
        );
    }
}

export default OutstandingPayments;
