import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { CheckBox } from 'Components/CheckBox';

class OptionWithCheckbox extends Component {
    static propTypes = {
        option: PropTypes.object,
        onSelect: PropTypes.func,
        className: PropTypes.string
    };

    shouldComponentUpdate(nextProps) {
        const { option: prevOption } = this.props;
        const { option } = nextProps;

        return option.isSelected !== prevOption.isSelected
            || option.indeterminate !== prevOption.indeterminate
            || option.disabled !== prevOption.disabled;
    }

    handleClick = e => {
        const { option, onSelect } = this.props;

        e.stopPropagation();
        e.preventDefault();
        !(option.disabled || option.isDefault) && onSelect(option, e);
    };

    render() {
        const { option: { label, isSelected, disabled, indeterminate, isDefault }, className } = this.props;
        const optionClassName = cn(className, { 'option-default': isDefault });

        return (<div onClick={this.handleClick} className={optionClassName}>
            <CheckBox
                position='flex'
                ellipsis
                indeterminate={indeterminate}
                title={label}
                label={label}
                disabled={disabled}
                checked={isSelected}
                onChange={() => null}/>
        </div>);
    }
}

export default OptionWithCheckbox;
