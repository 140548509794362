import _ from 'lodash';
import { replace } from 'connected-react-router';

import * as api from '../../api';
import * as widgetsActions from '../widgets/actions';
import * as dataSourceActions from '../dataSource/actions';
import * as loadingBlockActions from '../loadingBlock/actions';
import * as savedReportTemplatesActions from 'State/savedReportTemplates/actions';
import * as pageSelectors from './selectors';
import * as widgetsSelectors from '../widgets/selectors';
import * as userSelectors from '../user/selectors';
import * as featuresSelectors from '../features/selectors';
import { widgetSettingsBuilder } from '../../components/widgets';
import { fixWidgetsPositions, generateWidgetId, getDefaultSlot, isIncorrectPositions } from './utils';
import { showConfirmDeleteLeastWidgetModal } from '../modal/actions';
import { buildLocationWithSecurity } from 'Utils/routing';
import { changeUserNotifications } from '../user/actions';

export const DYNAMIC_PAGE_INITIALIZE = 'DYNAMIC_PAGE_INITIALIZE';
export const FETCHING_DYNAMIC_PAGE_REQUEST = 'FETCHING_DYNAMIC_PAGE_REQUEST';
export const FETCHING_DYNAMIC_PAGE_SUCCESS = 'FETCHING_DYNAMIC_PAGE_SUCCESS';
export const FETCHING_DYNAMIC_PAGE_FAILURE = 'FETCHING_DYNAMIC_PAGE_FAILURE';
export const DYNAMIC_PAGE_DESTROY = 'DYNAMIC_PAGE_DESTROY';
// slots
export const DYNAMIC_PAGE_ADD_WIDGET = 'DYNAMIC_PAGE_ADD_WIDGET';
export const DYNAMIC_PAGE_REMOVE_WIDGET = 'DYNAMIC_PAGE_REMOVE_WIDGET';
export const DYNAMIC_PAGE_SHRINK_WIDGETS = 'DYNAMIC_PAGE_SHRINK_WIDGETS';
export const DYNAMIC_PAGE_SYNC = 'DYNAMIC_PAGE_SYNC';
export const DYNAMIC_PAGE_DATAVIZ_SYNC = 'DYNAMIC_PAGE_DATAVIZ_SYNC';
export const DYNAMIC_PAGE_SYNC_REQUEST = 'DYNAMIC_PAGE_SYNC_REQUEST';
export const DYNAMIC_PAGE_SYNC_SUCCESS = 'DYNAMIC_PAGE_SYNC_SUCCESS';
export const DYNAMIC_PAGE_SYNC_FAILURE = 'DYNAMIC_PAGE_SYNC_FAILURE';
export const DYNAMIC_PAGE_BLOCK_ACTION = 'DYNAMIC_PAGE_BLOCK_ACTION';
export const DYNAMIC_PAGE_ADD_SLOT = 'DYNAMIC_PAGE_ADD_SLOT';
export const DYNAMIC_PAGE_REMOVE_SLOT = 'DYNAMIC_PAGE_REMOVE_SLOT';
export const DYNAMIC_PAGE_DELETE_EMPTY_SLOTS = 'DYNAMIC_PAGE_DELETE_EMPTY_SLOTS';

export const initializeDynamicPage = data => ({
    type: DYNAMIC_PAGE_INITIALIZE,
    payload: data
});

export const destroyDynamicPage = () => (dispatch, getState) => {
    const state = getState();
    const pageType = pageSelectors.getDynamicPageTypeSelector(state);
    const pageWidgets = widgetsSelectors.getAllWidgetsByPageIdSelector(state, pageType);
    const dataSources = _.toArray(_.mapValues(pageWidgets, w => w.dataSourceId));
    const widgetIds = _.toArray(_.mapValues(pageWidgets, w => w.widgetId));

    dispatch({
        type: DYNAMIC_PAGE_DESTROY
    });

    dispatch(widgetsActions.widgetsRemove(widgetIds));
    dispatch(dataSourceActions.dataSourceRemove(dataSources));
};

const commonLoadDynamicPage = (type, params, slots = {}, settings = {}) => (dispatch, getState) => {
    const state = getState();
    const userFeatures = featuresSelectors.getCurrentFeaturesSelector(state);

    dispatch({
        type: FETCHING_DYNAMIC_PAGE_REQUEST,
        payload: type
    });
    dispatch(loadingBlockActions.showLoadingBlock());

    return api.getPage(type, params, {
        redirect404ToHome: settings.redirect404ToHome,
        withoutNotification: settings.withoutNotification
    })
        .then(
            res => {
                const {
                    page: {
                        compatibleWidgets = [],
                        info
                    }
                } = res.data.payload;
                let {
                    page: {
                        widgets: sourceWidgets = []
                    }
                } = res.data.payload;
                const pageData = {
                    params,
                    type,
                    info,
                    compatibleWidgets,
                    settings
                };
                const widgets = [];
                const dynamicSlots = {};
                let lastWidgetPosition = _.get(_.maxBy(sourceWidgets, w => w.position), 'position', 0);
                const isHiddenWidget = !_.isEmpty(_.find(sourceWidgets, widget => widget.position === -1));

                // checking right widget's positions
                if (isIncorrectPositions(sourceWidgets, slots, isHiddenWidget, lastWidgetPosition)) {
                    sourceWidgets = fixWidgetsPositions(sourceWidgets, slots, isHiddenWidget);

                    lastWidgetPosition = _.maxBy(sourceWidgets, w => w.position).position;

                    api.updateWidgets(type, sourceWidgets)
                        .catch(error => {
                            dispatch({
                                type: widgetsActions.WIDGETS_SETTINGS_SYNC_FAILURE,
                                payload: error,
                                error: true
                            });
                        });
                }

                if (settings.isDynamicSlots) {
                    _.map(res.data.payload.page.widgets, widget => {
                        dynamicSlots[widget.position] = getDefaultSlot();
                    });
                    dynamicSlots[lastWidgetPosition + 1 || 1] = getDefaultSlot();
                }

                pageData.slots = _.cloneDeep({ ...dynamicSlots, ...slots });

                _.filter(sourceWidgets, w => {
                    return w.type && w.widgetId && w.position;
                }).forEach(widget => {
                    const { widgetId, position } = widget;
                    const slot = pageData.slots[position];

                    widgets.push({
                        widgetId,
                        type: widget.type,
                        settings: widgetSettingsBuilder(type, widget.type, widget.settings, userFeatures),
                        dataSourceId: _.uniqueId(widget.type),
                        pageId: type,
                        dataParams: widget.params || {},
                        pageParams: params || {}
                    });

                    if (slot) {
                        slot.widgets.push(widgetId);
                    }
                });

                dispatch(widgetsActions.widgetsAdd(widgets));
                dispatch(initializeDynamicPage(pageData));
                dispatch(loadingBlockActions.hideLoadingBlock());
            },
            error => {
                dispatch(loadingBlockActions.hideLoadingBlock());

                if (!api.isCancel(error)) {
                    dispatch({
                        type: FETCHING_DYNAMIC_PAGE_FAILURE,
                        payload: error.message,
                        error: true
                    });
                }

                return Promise.reject(error);
            }
        );
};

const loadDataVizPage = (type, params, settings) => (dispatch, getState) => {
    const state = getState();
    const userFeatures = featuresSelectors.getCurrentFeaturesSelector(state);

    dispatch({
        type: FETCHING_DYNAMIC_PAGE_REQUEST,
        payload: type
    });
    dispatch(loadingBlockActions.showLoadingBlock());

    return api.getDataVizPage(type, params)
        .then(
            res => {
                const {
                    page: {
                        widgets: sourceWidgets = [],
                        compatibleWidgets = [],
                        info
                    }
                } = res.data.payload;
                const pageData = {
                    params,
                    type,
                    info,
                    compatibleWidgets,
                    settings,
                    slots: {}
                };
                const widgets = [];
                const filteredWidgets = _.filter(sourceWidgets, w => w.type && w.widgetId);
                const sortedWidgets = _.sortBy(filteredWidgets, 'position');

                sortedWidgets.forEach((widget, i) => {
                    widgets.push({
                        widgetId: widget.widgetId,
                        type: widget.type,
                        settings: widgetSettingsBuilder(type, widget.type, widget.settings, userFeatures),
                        dataSourceId: _.uniqueId(widget.type),
                        pageId: type,
                        dataParams: widget.params || {},
                        pageParams: params || {}
                    });

                    pageData.slots[i + 1] = getDefaultSlot(widget.widgetId);
                });

                // add empty slot
                pageData.slots[sortedWidgets.length + 1] = getDefaultSlot();

                dispatch(widgetsActions.widgetsAdd(widgets));
                dispatch(initializeDynamicPage(pageData));
                dispatch(loadingBlockActions.hideLoadingBlock());
            },
            error => {
                dispatch(loadingBlockActions.hideLoadingBlock());

                if (!api.isCancel(error)) {
                    dispatch({
                        type: FETCHING_DYNAMIC_PAGE_FAILURE,
                        payload: error.message,
                        error: true
                    });
                }

                return Promise.reject(error);
            }
        );
};

/**
 * Load dynamic page action
 * @param {String} type - type dynamic page see to page-types constant
 * @param {Object} params - parameters dynamic page included route params
 * @param {Array} [slots] - slots of the page. if we have dynamic slots we will set isDynamicSlots to true
 * @param {Object} [settings] - a page settings
 * @param {Object} [settings.isDynamicSlots] - a page uses no static slots
 * @param {Object} [settings.redirect404ToHome] - when we've got a error 404 we should redirect to home page
 */
export const loadDynamicPage = (type, params, slots = {}, settings = {}) => dispatch => {
    return settings.isDataViz
        ? dispatch(loadDataVizPage(type, params, settings))
        : dispatch(commonLoadDynamicPage(type, params, slots, settings));
};

const commonDynamicPageAddWidget = (pageSlotId, widgetType, addWidgetsRequest, reportTemplateId, addEmptySlot = false) => {
    return (dispatch, getState) => {
        let state = getState();
        const slotId = pageSlotId || pageSelectors.getDynamicPageLastSlotSelector(state) + 1;

        if (!pageSlotId) {
            dispatch({
                type: DYNAMIC_PAGE_ADD_SLOT,
                payload: {
                    slot: getDefaultSlot(),
                    slotId
                }
            });
            state = getState();
        }

        const pageType = pageSelectors.getDynamicPageTypeSelector(state);
        const tempWidgetId = generateWidgetId();
        const pageParams = pageSelectors.getDynamicPageParamsSelector(state) || {};
        const userFeatures = featuresSelectors.getCurrentFeaturesSelector(state);
        const widgetsAdding = [{
            type: widgetType,
            position: slotId
        }];

        if (!pageType) return Promise.resolve();

        dispatch(dynamicPageBlockAction(true));
        dispatch(widgetsActions.widgetsAdd([{
            widgetId: tempWidgetId,
            settings: widgetSettingsBuilder(pageType, widgetType, {}, userFeatures),
            type: widgetType,
            dataSourceId: _.uniqueId(widgetType),
            pageParams,
            pageId: pageType,
            isAdding: true
        }]));

        dispatch({
            type: DYNAMIC_PAGE_ADD_WIDGET,
            payload: {
                slotId,
                widgetName: tempWidgetId
            }
        });

        const requestArguments = [
            _.isUndefined(reportTemplateId) ? pageType : reportTemplateId,
            widgetsAdding
        ];

        return addWidgetsRequest(...requestArguments)
            .then(
                ({ data }) => {
                    const widgets = _.get(data, 'payload.widgets');
                    const checkPageType = pageSelectors.getDynamicPageTypeSelector(getState());

                    if (checkPageType !== pageType) {
                        return;
                    }

                    const widgetsIds = [];

                    _.forEach(widgets, widget => {
                        const { widgetId, position } = widget;
                        const slot = pageSelectors.getSlotByIdSelector(getState(), position);
                        const definedWidgetId = widgetId || generateWidgetId();

                        widgetsIds.push(definedWidgetId);

                        if (slot) {
                            dispatch(widgetsActions.widgetsAdd([{
                                settings: widgetSettingsBuilder(pageType, widgetType, widget.settings, userFeatures),
                                type: widgetType,
                                dataSourceId: _.uniqueId(widgetType),
                                pageParams,
                                pageId: pageType,
                                widgetId: definedWidgetId
                            }]));

                            dispatch({
                                type: DYNAMIC_PAGE_REMOVE_WIDGET,
                                payload: {
                                    slotId: position,
                                    widgetName: tempWidgetId
                                }
                            });

                            dispatch(widgetsActions.widgetsRemove([tempWidgetId]));

                            dispatch({
                                type: DYNAMIC_PAGE_ADD_WIDGET,
                                payload: {
                                    slotId: position,
                                    widgetName: definedWidgetId
                                }
                            });
                        }
                    });

                    if (addEmptySlot) {
                        dispatch({
                            type: DYNAMIC_PAGE_ADD_SLOT,
                            payload: {
                                slot: getDefaultSlot(),
                                slotId: pageSelectors.getDynamicPageLastSlotSelector(state) + 1
                            }
                        });
                    }

                    dispatch(dynamicPageBlockAction(false));

                    return widgetsIds;
                },
                error => {
                    dispatch({
                        type: DYNAMIC_PAGE_REMOVE_WIDGET,
                        payload: {
                            slotId,
                            widgetName: tempWidgetId
                        }
                    });
                    dispatch(widgetsActions.widgetsRemove([tempWidgetId]));
                    dispatch(dynamicPageBlockAction(false));

                    return Promise.reject(error);
                }
            );
    };
};

export const dynamicPageAddWidget = (slotId, widgetType) => (dispatch, getState) => {
    const state = getState();
    const isDataVizDynamicPage = pageSelectors.isDataVizDynamicPage(state);
    const isDynamicSlotsPage = pageSelectors.getDynamicPageSlotsTypeSelector(state);

    if (isDataVizDynamicPage || isDynamicSlotsPage) {
        const reportTemplateId = pageSelectors.getReportTemplateIdSelector(state);
        const addEmptySlot = pageSelectors.getDynamicPageLastSlotSelector(state) === slotId;

        return dispatch(commonDynamicPageAddWidget(slotId, widgetType, isDataVizDynamicPage ? api.addDataVizWidgets : api.addWidgets, reportTemplateId, addEmptySlot));
    }

    return dispatch(commonDynamicPageAddWidget(slotId, widgetType, api.addWidgets));
};

const commonDynamicPageRemoveWidget = (slotId, widgetName, shrink, bSync, newWidgetType) => (dispatch, getState) => {
    let state = getState();
    const dynamicPageType = pageSelectors.getDynamicPageTypeSelector(state);

    dispatch(dynamicPageBlockAction(true));
    dispatch(widgetsActions.widgetsSetRemoving([widgetName], true));

    return api.removeWidgets([{
        widgetId: widgetName,
        targetPage: dynamicPageType,
        position: slotId,
        newWidgetType
    }])
        .then(
            () => {
                state = getState();
                const isDynamicSlots = pageSelectors.getDynamicPageSlotsTypeSelector(state);
                const slotsLength = pageSelectors.getDynamicPageLastSlotSelector(state);

                const widgetDataSourceId = widgetsSelectors.getWidgetDataSourceIdSelector(state, widgetName);
                // TODO check that data is not in usage
                // const widgetsUseData = selectors.widgetsDataSourceIdUsagesSelector(state, widgetDataSourceId);

                // remove widget by name from dynamicPage -> widgets
                dispatch({
                    type: DYNAMIC_PAGE_REMOVE_WIDGET,
                    payload: {
                        slotId,
                        widgetName
                    }
                });

                dispatch(widgetsActions.widgetsRemove([widgetName]));

                // shrink empty widgets
                if (shrink) {
                    dispatch({
                        type: DYNAMIC_PAGE_SHRINK_WIDGETS,
                        payload: {
                            slotId
                        }
                    });
                }

                if (shrink && isDynamicSlots) {
                    dispatch({
                        type: DYNAMIC_PAGE_REMOVE_SLOT,
                        payload: {
                            slotId: slotsLength
                        }
                    });
                }

                dispatch(dataSourceActions.dataSourceRemove(widgetDataSourceId));

                if (bSync) {
                    dispatch({
                        type: DYNAMIC_PAGE_SYNC
                    });
                }

                dispatch(dynamicPageBlockAction(false));
            },
            error => {
                dispatch(widgetsActions.widgetsSetRemoving([widgetName], false));
                dispatch(dynamicPageBlockAction(false));

                return Promise.reject(error);
            });
};

const MIN_DATAVIZ_SLOTS_COUNT = 2;
const dataVizPageRemoveWidget = (slotId, widgetName, shrink = true, bSync = true) => (dispatch, getState) => {
    const state = getState();
    const slotsLength = pageSelectors.getDynamicPageLastSlotSelector(state);
    const isDataVizStartedTemplate = pageSelectors.isDataVizStartedTemplateSelector(state);
    let requestPromise;

    if (slotsLength <= MIN_DATAVIZ_SLOTS_COUNT && shrink) {
        dispatch(showConfirmDeleteLeastWidgetModal(() => {
            const reportTemplateId = pageSelectors.getReportTemplateIdSelector(state);

            dispatch(savedReportTemplatesActions.deleteDataVizTemplate(reportTemplateId))
                .then(() => {
                    const customSecurityId = userSelectors.getCustomSecurityIdSelector(getState());
                    const toLocation = buildLocationWithSecurity('reporting', customSecurityId);

                    dispatch(changeUserNotifications({ newDataVizReports: 0 }));
                    dispatch(replace(toLocation));
                });
        }));

        return Promise.resolve();
    }

    dispatch(dynamicPageBlockAction(true));
    dispatch(widgetsActions.widgetsSetRemoving([widgetName], true));

    if (isDataVizStartedTemplate) {
        requestPromise = api.removeDataVizWidgets([{ widgetId: widgetName }]);
    } else {
        requestPromise = Promise.resolve();
    }

    return requestPromise
        .then(
            () => {
                const widgetDataSourceId = widgetsSelectors.getWidgetDataSourceIdSelector(getState(), widgetName);

                // remove widget by name from dynamicPage -> widgets
                dispatch({
                    type: DYNAMIC_PAGE_REMOVE_WIDGET,
                    payload: {
                        slotId,
                        widgetName
                    }
                });

                dispatch(widgetsActions.widgetsRemove([widgetName]));
                // shrink empty widgets
                if (shrink) {
                    dispatch({
                        type: DYNAMIC_PAGE_SHRINK_WIDGETS,
                        payload: {
                            slotId
                        }
                    });
                    // remove last slot
                    dispatch({
                        type: DYNAMIC_PAGE_REMOVE_SLOT,
                        payload: {
                            slotId: slotsLength
                        }
                    });
                }

                dispatch(dataSourceActions.dataSourceRemove(widgetDataSourceId));

                if (bSync) {
                    dispatch({
                        type: DYNAMIC_PAGE_DATAVIZ_SYNC
                    });
                }

                dispatch(dynamicPageBlockAction(false));
            },
            error => {
                dispatch(widgetsActions.widgetsSetRemoving([widgetName], false));
                dispatch(dynamicPageBlockAction(false));

                return Promise.reject(error);
            });
};

export const dynamicPageRemoveWidget = (slotId, widgetName, shrink = true, bSync = true, addWidgetType) => (dispatch, getState) => {
    const state = getState();
    const isDataVizDynamicPage = pageSelectors.isDataVizDynamicPage(state);

    if (isDataVizDynamicPage) {
        const isDataVizStartedTemplate = pageSelectors.isDataVizStartedTemplateSelector(state);
        const sync = bSync && isDataVizStartedTemplate;

        return dispatch(dataVizPageRemoveWidget(slotId, widgetName, shrink, sync));
    }

    return dispatch(commonDynamicPageRemoveWidget(slotId, widgetName, shrink, bSync, addWidgetType));
};

const commonDynamicPageChangeWidget = (slotId, addWidgetType, removeWidgetId) => dispatch => {
    const promise = dispatch(dynamicPageRemoveWidget(slotId, removeWidgetId, false, false, addWidgetType));

    return promise.then(
        () => dispatch(dynamicPageAddWidget(slotId, addWidgetType))
    );
};

const dataVizPageChangeWidget = (slotId, addWidgetType, removeWidgetId) => dispatch => {
    const promise = dispatch(dataVizPageRemoveWidget(slotId, removeWidgetId, false, false));

    return promise.then(
        () => dispatch(dynamicPageAddWidget(slotId, addWidgetType))
    );
};

export const dynamicPageChangeWidget = (slotId, addWidgetType, removeWidgetId, options = {}) => (dispatch, getState) => {
    const state = getState();
    const isDataVizDynamicPage = pageSelectors.isDataVizDynamicPage(state);
    const previousWidgetSettings = widgetsSelectors.getWidgetSettingsSelector(state, removeWidgetId);
    const promise = isDataVizDynamicPage
        ? dispatch(dataVizPageChangeWidget(slotId, addWidgetType, removeWidgetId))
        : dispatch(commonDynamicPageChangeWidget(slotId, addWidgetType, removeWidgetId));

    return promise
        .then((widgetsIds) => {
            if (options.mapSettingsStrategy) {
                const newState = getState();
                const widgetId = _.get(widgetsIds, '0');
                const widgetSettings = widgetsSelectors.getWidgetSettingsSelector(newState, widgetId);
                const mapWidgetSettings = options.mapSettingsStrategy(widgetSettings, previousWidgetSettings);

                dispatch(widgetsActions.widgetsUpdateSettings(widgetId, mapWidgetSettings));
            }
        });
};

export const dynamicPageBlockAction = (value) => ({
    type: DYNAMIC_PAGE_BLOCK_ACTION,
    payload: value
});

export const changeSelectedSlot = (widget, slots) => (dispatch, getState) => {
    const state = getState();

    _.toPairs(slots).forEach(([, slot]) => {
        const slotWidgetIds = _.get(slot, 'widgets', []);

        slotWidgetIds.forEach((widgetId) => {
            const selectedWidgetSettings = widgetsSelectors.getWidgetSettingsSelector(state, widgetId);

            dispatch(widgetsActions.widgetsSetSettings(widgetId, {
                ...selectedWidgetSettings,
                isSelected: widgetId === widget.widgetId
            }));
        });
    });

    dispatch({
        type: DYNAMIC_PAGE_SYNC
    });
};
