import PropTypes from 'prop-types';
import React from 'react';

import { DynamicPage } from 'Components/DynamicPage/index';
import dynamicPageTypes from 'Constants/dynamic-page-types';
import slots from '../slots';
import layoutTypes from 'Constants/layout-types';
import reportTypes from 'Constants/report-types';

const InstitutionsPage = props => {
    const newParams = {
        ...props.params,
        reportTemplateType: reportTypes.institutionsTableTemplate
    };

    return (
        <div className='institutions-page'>
            <DynamicPage
                dynamicPageType={dynamicPageTypes.INSTITUTIONS}
                slots={slots}
                layoutType={layoutTypes.layoutEasy}
                params={newParams}/>
        </div>
    );
};

InstitutionsPage.propTypes = {
    params: PropTypes.object
};

export default InstitutionsPage;
