export default {
    SHAREHOLDER_NAME: 'shareholderName',
    POSITION: 'position',
    CURRENT_BALANCE: 'currentBalance',
    POSITION_DATE: 'positionDate',
    PREVIOUS_POSITION: 'previousPosition',
    PREVIOUS_POSITION_DATE: 'previousPositionDate',
    POSITION_CHANGE: 'positionChange',
    OS: 'os',
    MKT_VAL: 'mktVal',
    TOTAL_VALUE: 'totalValue',
    PORTFOLIO_PERCENT: 'portfolioPercent',
    F13: '13f',
    PREVIOUS13F: 'previous13f',
    F13CHANGE: '13fChange',
    YTD_CHANGE: 'ytdChange',
    COST_BASIS: 'costBasis',
    HOLDER_TYPE: 'holderType',
    CITY: 'city',
    METRO_AREA: 'metroArea',
    STATE: 'state',
    COUNTRY: 'country',
    PURCHASING_POWER: 'purchasingPower',
    ASSETS_UNDER_MANAGEMENT: 'assetsUnderManagement',
    STYLE: 'style',
    ACTIVE_VS_PASSIVE: 'activeVsPassive',
    PROXY_ADVISORS: 'proxyAdvisors',
    TURNOVER: 'turnover',
    ACCOUNT_NUMBER: 'accountNumber',
    OPENED_DATE: 'openedDate',
    STATUS: 'status',
    CLOSED_DATE: 'closedDate',
    CERTIFIED_SHARES: 'certifiedShares',
    BOOK_SHARES: 'bookShares',
    DRIP_SHARES: 'dripShares',
    FUND_NAME: 'fundName',
    DIRECT_DEPOSIT: 'directDeposit',
    SPECIAL_CODE: 'specialCode',
    SSN_TAX_ID: 'ssnTaxId',
    ADDRESS: 'address',
    REGISTERED_ADDRESS: 'registeredAddress',
    DIVIDEND_ADDRESS: 'dividendAddress',
    PROXY_ADDRESS: 'proxyAddress',
    SEASONAL_ADDRESS: 'seasonalAddress',
    K1_ADDRESS: 'k1Address',
    DATE_OF_LAST_CONTACT: 'dateOfLastContact',
    BAD_ADDRESS: 'badAddress',
    EMAIL_ADDRESS: 'emailAddress',
    CUMULATIVE_OS: 'cumulativeOs',
    ACTIVIST: 'activist',
    PROXY_VOTING_POLICY: 'proxyVotingPolicy',
    PROXY_CONTACTS: 'isContactExists',
    CHANGE: 'change'
};
