import slotTypes from 'Constants/slot-types';

export default {
    [1]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [2]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [3]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [4]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [5]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [6]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [7]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [8]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [9]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [10]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [11]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [12]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [13]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [14]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [15]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    },
    [16]: {
        'type': slotTypes.SINGLE,
        'isLocked': true,
        'widgets': []
    }
};
