import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { getContext } from '@shakacode/recompose';

import { hideModal } from 'State/modal';
import cn from 'classnames';
import Icon from 'Components/Icon';

Modal.setAppElement('body');

const mapStateToProps = (state) => {
    return { ...state.modal };
};

const mapDispatchToProps = { hideModal };

@getContext({ modals: PropTypes.object })
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class ModalRoot extends Component {
    static propTypes = {
        type: PropTypes.string,
        modals: PropTypes.object,
        shouldCloseOnOverlayClick: PropTypes.bool,
        shouldReturnFocusAfterClose: PropTypes.bool,
        showCloseButton: PropTypes.bool,
        params: PropTypes.any,
        hideModal: PropTypes.func,
        className: PropTypes.string,
        intl: intlShape.isRequired,
        noHeader: PropTypes.bool
    };

    static defaultProps = {
        shouldCloseOnOverlayClick: true,
        shouldReturnFocusAfterClose: false,
        showCloseButton: true,
        noHeader: false
    };

    handleCloseModal = () => {
        const { onClose = this.props.hideModal } = this.props.params || {};

        onClose();
    };

    getModalTitle = () => {
        const { intl: { formatMessage }, params } = this.props;

        if (params) {
            if (params.titleText) {
                return params.titleText;
            }
            if (params.titleKey) {
                return formatMessage({ id: params.titleKey });
            }
        }
        return '';
    };

    renderHeader() {
        const { showCloseButton } = this.props;
        const title = this.getModalTitle();

        return (
            <div className='modal-header'>
                <h2 className='modal-header-left'>
                    {title}
                </h2>
                {showCloseButton && <Icon
                    onClick={this.handleCloseModal}
                    className='font-icon font-icon-clear close'
                    titleKey='common.close'/>}
            </div>
        );
    }

    render() {
        const {
            type,
            shouldCloseOnOverlayClick,
            shouldReturnFocusAfterClose,
            params,
            modals,
            className,
            noHeader
        } = this.props;
        const SpecificModal = type ? modals[type] : null;

        return (
            <div className='modal-root'>
                <Modal
                    contentLabel='modalContentLabel'
                    isOpen={!!SpecificModal}
                    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                    shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
                    onRequestClose={this.handleCloseModal}
                    className={cn('modal', className)}
                    overlayClassName='modal-overlay'>
                    {!noHeader && this.renderHeader()}
                    {SpecificModal && <SpecificModal {...params} hideModal={this.props.hideModal}/>}
                </Modal>
            </div>);
    }
}

export default ModalRoot;
