import React, { Component } from 'react';
import { intlShape } from 'react-intl';

import { commonWidgetPropTypes, commonWidgetActions } from '../utils';
import Widget from '../Widget';
import ActivityTrendContent from './components/ActivityTrendContent';
import ShareholderWidgetControlPanel from '../Widget/components/ShareholderWidgetControlPanel';
import InstitutionalShareholdersActionPanel from '../_commonComponents/InstitutionalShareholdersActionPanel';

import './ActivityTrend.scss';

class ActivityTrend extends Component {
    static propTypes = {
        ...commonWidgetPropTypes,
        ...commonWidgetActions,
        intl: intlShape
    };

    constructor(props) {
        super(props);

        this.widgetComponents = {
            WidgetControlPanel: props.isPrintMode ? null : ShareholderWidgetControlPanel,
            WidgetActionPanel: InstitutionalShareholdersActionPanel,
            WidgetContent: ActivityTrendContent
        };

        this.widgetTitle = this.props.intl.formatMessage({ id: 'activity.trend.title' });
        this.widgetControlPanelSettings = {
            widgetChartTypeIcon: 'font-icon-chart',
            widgetTableTypeIcon: 'font-icon-view-list'
        };
    }

    render() {
        const props = {
            ...this.props,
            ...{
                widgetTitle: this.widgetTitle,
                widgetControlPanelSettings: this.widgetControlPanelSettings
            }
        };

        return (
            <Widget
                {...props}
                className='widget-activity-trend'
                components={this.widgetComponents}/>
        );
    }
}

export default ActivityTrend;
