import React, { Component } from 'react';
import _ from 'lodash';

import widgetModes from 'Constants/widget-modes';
import { commonWidgetPropTypes, commonWidgetActions } from 'Components/widgets/utils';
import AuthorizedSharesTableView from './AuthorizedSharesTableView';
import AuthorizedSharesChartView from './AuthorizedSharesChartView';
import NoDataComponent from 'Components/widgets/Widget/components/NoDataComponent';

class AuthorizedSharesContent extends Component {
    static propTypes = {
        ...Component.propTypes,
        ...commonWidgetActions,
        ...commonWidgetPropTypes
    };

    render() {
        const {
            widgetSettings: { mode, colorScheme },
            isFetching,
            widgetNoData,
            data,
            widgetFooterProps,
            intl: { formatMessage },
            isDataViz
        } = this.props;
        const props = {
            intl: this.props.intl,
            widgetFooterProps,
            data: _.get(data, 'dataView.data'),
            withLink: !isDataViz
        };

        return (
            <div className='widget-content'>
                <NoDataComponent
                    isFetching={isFetching}
                    isNoData={widgetNoData}
                    message={formatMessage({ id: 'widgets.noDataMessage.matchingSelectedDate' })}>
                    {mode === widgetModes.CHART
                        ? <AuthorizedSharesChartView
                            {...props}
                            colorSchemeId={colorScheme}/>
                        : <AuthorizedSharesTableView {...props}/>
                    }
                </NoDataComponent>
            </div>
        );
    }
}


export default AuthorizedSharesContent;
