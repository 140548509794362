import { createReducer } from 'Utils/reducer';
import {
    HELP_REQUEST,
    HELP_SUCCESS,
    HELP_ERROR
} from 'State/helpPage/actions';

const initialState = {
    availableSecurities: []
};

const actionHandlers = {
    [HELP_REQUEST]: state => ({
        ...state,
        isFetching: true
    }),
    [HELP_SUCCESS]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        template: payload
    }),
    [HELP_ERROR]: (state, { payload }) => ({
        ...state,
        isFetching: false,
        error: payload
    })
};

export default createReducer(initialState, actionHandlers);
